"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_Organization_EHRInstances = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const _entity_1 = require("../../../../../_entity");
require("./Editor_Organization_EHRInstances.scss");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const styles_1 = require("@app/styles");
const frontend_3 = require("@app/pathway/frontend");
const ui_components_1 = require("@app/pathway/_entity/pathway/frontend/ui-components");
const ui_components_2 = require("@app/styles/frontend/ui-manager/ui-components");
const Editor_Organization_EHROrders_1 = require("../orders/Editor_Organization_EHROrders");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_4 = require("../../../../../../_entity/artifact/frontend");
const frontend_5 = require("../../../../../../_entity/ehr-instance-kb-version/frontend");
const ViewKey_Edit = 'edit';
const ViewKey_View = 'view';
class Editor_Organization_EHRInstances extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        this.__onEHRInstanceKBVersionsUpdated = (...params) => {
            if ((0, ts_common_1.asArray)(params[1]).find(item => item.ehrInstanceId === this.state.editable.item._id))
                this.forceUpdate();
        };
        this.TypeDropDown = frontend_1.TS_DropDown.prepareEditable({
            adapter: (0, frontend_1.SimpleListAdapter)([..._entity_1.EHRInstanceTypes], item => React.createElement(React.Fragment, null, _entity_1.EHRInstance_TypeLabelMap[item.item]))
        });
        // ######################## Logic ########################
        this.getViewKey = () => this.state.editMode ? ViewKey_Edit : ViewKey_View;
        this.updateEHRInstance = async (data) => {
            var _a;
            try {
                await ((_a = this.state.editable) === null || _a === void 0 ? void 0 : _a.updateObj(data));
            }
            catch (err) {
                this.logError(err);
            }
            finally {
                this.forceUpdate();
            }
        };
        this.getPathwaysOfType = (type) => {
            var _a, _b, _c;
            const pathways = (_c = (_b = (_a = this.state.editable) === null || _a === void 0 ? void 0 : _a.item.pathwayIds) === null || _b === void 0 ? void 0 : _b.map(id => {
                const pathway = frontend_3.ModuleFE_Pathway.cache.unique(id);
                if (!pathway)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway for id ${id}`);
                return pathway;
            })) !== null && _c !== void 0 ? _c : [];
            return pathways.filter(pathway => pathway.type === type);
        };
        this.getTabs = () => {
            var _a;
            const ehrInstance = (_a = this.state.editable) === null || _a === void 0 ? void 0 : _a.item;
            const tabs = [];
            tabs.push({
                uid: 'ehr-info',
                title: 'Instance Information',
                content: this.renderForm,
            });
            if ((0, ts_common_1.exists)(ehrInstance === null || ehrInstance === void 0 ? void 0 : ehrInstance._id)) {
                tabs.push({
                    uid: 'ehr-orders',
                    title: 'Instance Orders',
                    content: () => React.createElement(Editor_Organization_EHROrders_1.Editor_Organization_EHROrders, { organizationId: this.state.organizationId, ehrInstanceId: ehrInstance._id, editMode: this.state.editMode })
                });
            }
            return tabs;
        };
        // ######################## Render - EHR Instances Editor ########################
        this.renderTabs = () => {
            if (!this.state.editable)
                return;
            return React.createElement(frontend_1.TS_Tabs, { tabs: this.getTabs() });
        };
        // ######################## Render - EHR Instances Editor ########################
        this.renderForm = () => {
            if (!this.state.editable)
                return React.createElement(React.Fragment, null);
            const className = (0, frontend_1._className)(this.state.editMode && 'edit-mode');
            return React.createElement(frontend_2.QScrollWrapper, null,
                React.createElement(frontend_1.Grid, { className: className },
                    this.renderSaveButton(),
                    this.renderLabel(),
                    this.renderType(),
                    this.renderKnowledgeBaseVersion(),
                    this.renderBackendAppId(),
                    this.renderWebAppId(),
                    this.renderSOFParams(),
                    this.renderWhitelistURLs(),
                    this.renderPathways()));
        };
        this.renderSaveButton = () => {
            const editable = this.state.editable;
            if (!editable || (editable === null || editable === void 0 ? void 0 : editable.item._id))
                return;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.TS_BusyButton, { onClick: async () => {
                        try {
                            await (editable === null || editable === void 0 ? void 0 : editable.save());
                        }
                        catch (err) {
                            this.logError(err);
                            this.forceUpdate();
                        }
                    } }, "Save"),
                React.createElement("div", null));
        };
        this.renderLabel = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Label', error: editable === null || editable === void 0 ? void 0 : editable.hasError('label') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        { key: ViewKey_View, renderer: () => React.createElement("div", { className: 'grid-data' }, editable === null || editable === void 0 ? void 0 : editable.item.label) },
                        {
                            key: ViewKey_Edit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText, { editable: editable, prop: 'label', value: editable === null || editable === void 0 ? void 0 : editable.item.label, onChange: value => this.updateEHRInstance({ label: value }) })
                        }
                    ] }));
        };
        this.renderType = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Type', error: editable === null || editable === void 0 ? void 0 : editable.hasError('type') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => {
                                var _a;
                                return React.createElement("div", { className: 'grid-data' }, (_a = _entity_1.EHRInstance_TypeLabelMap[editable === null || editable === void 0 ? void 0 : editable.item.type]) !== null && _a !== void 0 ? _a : '-');
                            }
                        },
                        {
                            key: ViewKey_Edit,
                            renderer: () => React.createElement(this.TypeDropDown, { editable: editable, prop: 'type', onSelected: type => this.updateEHRInstance({ type }) })
                        }
                    ] }));
        };
        this.renderKnowledgeBaseVersion = () => {
            const artifacts = frontend_4.ModuleFE_Artifact.cache.allMutable();
            const artifactVersionAdapter = (0, frontend_1.SimpleListAdapter)(artifacts, item => React.createElement("div", null, item.item.dataVersion));
            const correlatedEHRInstanceKBVersionObject = frontend_5.ModuleFE_EHRInstanceKBVersion.cache.unique(this.state.editable.item._id);
            this.logInfo('correlatedEHRInstanceKBVersionObject', correlatedEHRInstanceKBVersionObject);
            const correlatedArtifact = artifacts.find(_artifact => _artifact._id === (correlatedEHRInstanceKBVersionObject === null || correlatedEHRInstanceKBVersionObject === void 0 ? void 0 : correlatedEHRInstanceKBVersionObject.versionId));
            this.logInfo('correlatedArtifact', correlatedArtifact);
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Knowledge Base Version' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = correlatedArtifact === null || correlatedArtifact === void 0 ? void 0 : correlatedArtifact.dataVersion) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: ViewKey_Edit,
                            renderer: () => React.createElement(frontend_1.TS_DropDown, { adapter: artifactVersionAdapter, selected: artifacts.find(item => item._id === (correlatedEHRInstanceKBVersionObject === null || correlatedEHRInstanceKBVersionObject === void 0 ? void 0 : correlatedEHRInstanceKBVersionObject.versionId)), onSelected: async (selected) => {
                                    if (!editable.item._id)
                                        return;
                                    await frontend_5.ModuleFE_EHRInstanceKBVersion._v1['create-item']({ ehrInstanceId: editable.item._id, versionId: selected._id }).executeSync();
                                } })
                        }
                    ] }));
        };
        this.renderBackendAppId = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Backend App ID', error: editable === null || editable === void 0 ? void 0 : editable.hasError('backendAppId') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = editable === null || editable === void 0 ? void 0 : editable.item.backendAppId) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: ViewKey_Edit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText, { editable: editable, prop: 'backendAppId', onChange: value => this.updateEHRInstance({ backendAppId: value }) })
                        }
                    ] }));
        };
        this.renderWebAppId = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Web App ID', error: editable === null || editable === void 0 ? void 0 : editable.hasError('webAppId') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = editable === null || editable === void 0 ? void 0 : editable.item.webAppId) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: ViewKey_Edit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText, { editable: editable, prop: 'webAppId', onChange: value => this.updateEHRInstance({ webAppId: value }) })
                        }
                    ] }));
        };
        this.renderWhitelistURLs = () => {
            const editable = this.state.editable.editProp('whitelistUrls', []);
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Whitelist URLs' },
                React.createElement(frontend_1.LL_V_L, { className: 'editor-organization-ehr-instances__urls' },
                    React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                            {
                                key: ViewKey_View,
                                renderer: () => {
                                    return React.createElement(React.Fragment, null, editable === null || editable === void 0 ? void 0 : editable.item.map((url, index) => React.createElement("div", { className: 'grid-data', key: index }, url)));
                                }
                            },
                            {
                                key: ViewKey_Edit,
                                renderer: () => {
                                    return React.createElement(React.Fragment, null, editable === null || editable === void 0 ? void 0 :
                                        editable.item.map((url, index) => {
                                            return React.createElement("div", { className: 'editor-organization-ehr-instances__url', key: index },
                                                React.createElement(ui_components_2.DefaultEditor_InputText_Array, { editable: editable, prop: index, onChange: value => editable.updateArrayAt(value, index) }),
                                                React.createElement(styles_1.ICONS.x.component, { onClick: async () => {
                                                        await editable.removeArrayItem(index);
                                                        this.forceUpdate();
                                                    } }));
                                        }),
                                        React.createElement(styles_1.ICONS.advisorAddPlus.component, { className: 'editor-organization-ehr-instances__add-more-button', onClick: async () => {
                                                await editable.updateArrayAt('');
                                                this.forceUpdate();
                                            } }));
                                }
                            },
                        ] })));
        };
        this.renderPathways = () => {
            const editable = this.state.editable;
            const ccPathways = this.getPathwaysOfType('chief-complaint');
            const ddPathways = this.getPathwaysOfType('differential-diagnosis');
            const existingVarIds = frontend_3.ModuleFE_Pathway.cache.filter(item => { var _a; return !!((_a = editable.item.pathwayIds) === null || _a === void 0 ? void 0 : _a.includes(item._id)); }).map(pathway => pathway.variableId);
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Pathways', className: 'span-row' },
                React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Chief Complaint', className: 'indent' },
                    React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                            {
                                key: ViewKey_View,
                                renderer: () => React.createElement(frontend_1.LL_V_L, null, ccPathways.map(pathway => React.createElement("div", { className: 'grid-data', key: pathway._id }, pathway.label)))
                            },
                            {
                                key: ViewKey_Edit,
                                renderer: () => {
                                    return React.createElement(ui_components_1.MultiSelect_Pathway, { itemsDirection: 'vertical', editable: editable, prop: 'pathwayIds', selectionFilter: id => {
                                            const pathway = frontend_3.ModuleFE_Pathway.cache.unique(id);
                                            if (!pathway)
                                                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway for id ${id}`);
                                            return pathway.type === 'chief-complaint';
                                        }, itemFilter: id => {
                                            var _a;
                                            const pathway = frontend_3.ModuleFE_Pathway.cache.unique(id);
                                            if (!pathway)
                                                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway for id ${id}`);
                                            if (existingVarIds.includes(pathway.variableId)) {
                                                this.logInfo(`${(_a = frontend_2.ModuleFE_Variable.cache.unique(pathway.variableId)) === null || _a === void 0 ? void 0 : _a.name} already exists in chief complaint`);
                                                return false;
                                            }
                                            return pathway.type === 'chief-complaint';
                                        } });
                                }
                            }
                        ] })),
                React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Differential Diagnosis', className: 'indent' },
                    React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                            {
                                key: ViewKey_View,
                                renderer: () => React.createElement(frontend_1.LL_V_L, null, ddPathways.map(pathway => React.createElement("div", { className: 'grid-data', key: pathway._id }, pathway.label)))
                            },
                            {
                                key: ViewKey_Edit,
                                renderer: () => {
                                    return React.createElement(ui_components_1.MultiSelect_Pathway, { itemsDirection: 'vertical', editable: editable, prop: 'pathwayIds', selectionFilter: id => {
                                            const pathway = frontend_3.ModuleFE_Pathway.cache.unique(id);
                                            if (!pathway)
                                                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway for id ${id}`);
                                            return pathway.type === 'differential-diagnosis';
                                        }, itemFilter: id => {
                                            const pathway = frontend_3.ModuleFE_Pathway.cache.unique(id);
                                            if (!pathway)
                                                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway for id ${id}`);
                                            if (existingVarIds.includes(pathway.variableId))
                                                return false;
                                            return pathway.type === 'differential-diagnosis';
                                        } });
                                }
                            }
                        ] })));
        };
        this.renderSOFParams = () => {
            const ehrInstance = this.state.editable;
            const sofParams = ehrInstance === null || ehrInstance === void 0 ? void 0 : ehrInstance.editProp('sofParams', []);
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Smart On FHIR Params', className: 'span-row' },
                React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Tenant ID', className: 'indent' },
                    React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                            {
                                key: ViewKey_View,
                                renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = ehrInstance === null || ehrInstance === void 0 ? void 0 : ehrInstance.item.tenantId) !== null && _a !== void 0 ? _a : '-'); }
                            },
                            {
                                key: ViewKey_Edit,
                                renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText, { editable: ehrInstance, prop: 'tenantId', value: ehrInstance === null || ehrInstance === void 0 ? void 0 : ehrInstance.item.tenantId, onChange: value => ehrInstance === null || ehrInstance === void 0 ? void 0 : ehrInstance.updateObj({ tenantId: value }) })
                            }
                        ] })),
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => {
                                return sofParams.item.map(param => {
                                    return React.createElement(frontend_1.TS_PropRenderer.Flat, { key: param.key, label: param.key, className: 'indent' },
                                        React.createElement("div", { className: 'grid-data' }, param.value));
                                });
                            }
                        },
                        {
                            key: ViewKey_Edit,
                            renderer: () => {
                                return React.createElement(React.Fragment, null,
                                    sofParams.item.map((param, index) => {
                                        const editable = sofParams.editProp(index, {});
                                        return React.createElement(React.Fragment, { key: index },
                                            React.createElement(ui_components_2.DefaultEditor_InputText, { className: 'sof-param-key', editable: editable, prop: 'key', value: editable.item.key, onChange: key => editable.updateObj({ key }) }),
                                            React.createElement("div", { className: 'sof-param-value' },
                                                React.createElement(ui_components_2.DefaultEditor_InputText, { editable: editable, prop: 'value', value: editable.item.value, onChange: value => editable.updateObj({ value }) }),
                                                React.createElement(styles_1.ICONS.x.component, { onClick: async () => {
                                                        await sofParams.removeArrayItem(index);
                                                        this.forceUpdate();
                                                    } })));
                                    }),
                                    React.createElement(styles_1.ICONS.advisorAddPlus.component, { style: { marginLeft: '15px' }, className: 'editor-organization-ehr-instances__add-more-button', onClick: async () => {
                                            await sofParams.updateArrayAt({ key: '', value: '' }, sofParams.item.length);
                                            this.forceUpdate();
                                        } }),
                                    React.createElement("div", null));
                            }
                        }
                    ] }));
        };
    }
    // ######################## Lifecycle ########################
    deriveStateFromProps(nextProps, state) {
        this.logWarning('wtf wtf wtf');
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        state.organizationId = nextProps.organizationId;
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_H_C, { id: 'editor-organization-ehr-instances' }, this.renderTabs());
    }
}
exports.Editor_Organization_EHRInstances = Editor_Organization_EHRInstances;
