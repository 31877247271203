"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_DiseaseView = exports.ModuleFE_DiseaseView_Class = exports.ConfigKeyFE_DPViewOrder = exports.dispatch_onDiseaseViewChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("./shared");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
exports.dispatch_onDiseaseViewChanged = new types_1.ThunderDispatcherV3('__onDiseaseViewUpdated');
exports.ConfigKeyFE_DPViewOrder = new frontend_1.AppConfigKey_FE('dpViewsOrder');
class ModuleFE_DiseaseView_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_DiseaseView, exports.dispatch_onDiseaseViewChanged);
        //###################### On Search Requested ######################
        this.__searchFilterMapper = (item) => {
            return [item.label];
        };
        this.__searchGroups = (items, filter) => {
            return [{
                    group: this.dbDef.entityName,
                    results: items.map(view => ({ _id: view._id, uiLabel: view.label }))
                }];
        };
        this.checkVariableMatchesNoViews = (multiVar) => {
            return this.cache.all().every(dv => !this.filterMultiVarByEnforcedTag(dv, multiVar));
        };
    }
    //###################### Class Methods ######################
    canVariableBeAddedToView(diseaseView, multiVar) {
        var _a;
        const variableId = (_a = multiVar.variables[0]) === null || _a === void 0 ? void 0 : _a.variableId;
        if (!variableId)
            return false;
        const variable = frontend_2.ModuleFE_Variable.cache.unique(variableId);
        if (!variable)
            throw new ts_common_1.MUSTNeverHappenException(`Variable with id ${variableId} is missing!`);
        const sourceTagGroupId = frontend_2.ConfigKeyFE_SourceTagId.get();
        const sourceTagGroup = variable.tagIds.find(tagGroup => tagGroup.key === sourceTagGroupId);
        return (0, shared_1._canBeAddedToView)(diseaseView, variable.role, sourceTagGroup, variable._tagIds);
    }
    filterMultiVarByEnforcedTag(diseaseView, multiVar) {
        var _a, _b;
        if (!diseaseView.enforcedTags.length)
            return false;
        const dvTagId = frontend_2.ConfigKeyFE_DiseasesViewsTagId.get();
        const dvTagGroup = (_b = (_a = multiVar === null || multiVar === void 0 ? void 0 : multiVar.variables) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.tagIds.find(group => group.key === dvTagId);
        if (!dvTagGroup)
            return false;
        return diseaseView.enforcedTags.some(id => dvTagGroup.value.some(tag => tag === id));
    }
}
exports.ModuleFE_DiseaseView_Class = ModuleFE_DiseaseView_Class;
exports.ModuleFE_DiseaseView = new ModuleFE_DiseaseView_Class();
