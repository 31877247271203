"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_Discussion = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_Discussion = {
    _v1: {
        createNewDiscussion: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/discussions/create-new-discussion' },
        deleteDiscussion: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/discussions/delete-discussion' },
    }
};
