"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Book_3 = void 0;
exports.Book_3 = {
    name: 'Rosen\'s Emergency Medicine: Concepts and Clinical Practice',
    edition: 'd10th',
    year: 2022,
    isbn: '9780323757898',
    itemsType: 'part',
    items: [
        {
            name: '1 - Fundamental Clinical Concepts',
            itemsType: 'section',
            items: [
                {
                    name: '1 - Resuscitation and Analgesia',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '1 - Airway',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Principles' },
                                { name: '2 - Management' },
                                { name: '3 - Outcomes' },
                            ]
                        },
                        {
                            name: '2 - Mechanical Ventilation and Noninvasive Ventilatory Support',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Management' },
                                { name: '3 - Outcomes' },
                            ]
                        },
                        {
                            name: '3 - Shock',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Management' },
                                { name: '3 - Outcomes' },
                            ]
                        },
                        {
                            name: '4 - Brain Resuscitation',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Management' },
                                { name: '3 - Pharmacology' },
                                { name: '4 - Outcomes' },
                            ]
                        },
                        {
                            name: '5 - Adult Resuscitation',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Management' },
                                { name: '3 - Pharmacology' },
                                { name: '4 - Devices and Techniques' },
                                { name: '5 - Outcomes' },
                            ]
                        },
                        {
                            name: '6 - Pain Management',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Management' },
                                { name: '3 - Outcomes: Treatment Endpoints' },
                            ]
                        },
                        {
                            name: '7 - Procedural Sedation and Analgesia',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Management' },
                            ]
                        },
                    ]
                },
                {
                    name: '2 - Signs, Symptoms, and Presentations',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '8 - Fever in the Adult Patient',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Empirical Management' },
                                { name: '3 - Disposition' },
                            ],
                        }, {
                            name: '9 - Weakness',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Empiric Management' },
                                { name: '4 - Disposition' },
                            ],
                        }, {
                            name: '10 - Cyanosis',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empiric Management' },
                                { name: '5 - Patient Disposition' },
                            ],
                        }, {
                            name: '11 - Syncope',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic and Management Algorithm' },
                                { name: '4 - Risk-Stratification' },
                                { name: '5 - Empiric Management' },
                            ],
                        }, {
                            name: '12 - Depressed Consciousness and Coma',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                            ],
                        }, {
                            name: '13 - Confusion',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                            ],
                        }, {
                            name: '14 - Seizures',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Ancillary Testing' },
                                { name: '4 - Diagnostic Algorithm' },
                                { name: '5 - Empiric Management' },
                            ],
                        }, {
                            name: '15 - Dizziness and Vertigo',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                                { name: '5 - Disposition' },
                            ],
                        }, {
                            name: '16 - Headache',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                            ],
                        }, {
                            name: '17 - Diplopia',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                            ],
                        }, {
                            name: '18 - Red and Painful Eye',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empiric Management' },
                            ],
                        }, {
                            name: '19 - Sore Throat',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empiric Management' },
                            ],
                        }, {
                            name: '20 - Hemoptysis',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                            ],
                        }, {
                            name: '21 - Dyspnea',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                            ],
                        }, {
                            name: '22 - Chest Pain',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                            ],
                        }, {
                            name: '23 - Abdominal Pain',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empiric Management' },
                            ],
                        }, {
                            name: '24 - Jaundice',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Epidemiology' },
                                { name: '2 - Pathophysiology' },
                            ],
                        }, {
                            name: '25 - Nausea and Vomiting',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                                { name: '5 - Disposition' },
                            ],
                        }, {
                            name: '26 - Gastrointestinal Bleeding',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Management' },
                            ],
                        }, {
                            name: '27 - Diarrhea',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empiric Management' },
                                { name: '5 - Disposition' },
                            ],
                        }, {
                            name: '28 - Constipation',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Empirical Management' },
                            ],
                        }, {
                            name: '29 - Acute Pelvic Pain',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empirical Management' },
                            ],
                        }, {
                            name: '30 - Vaginal Bleeding',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Diagnostic Algorithm' },
                                { name: '4 - Empiric Management' },
                                { name: '5 - Disposition' },
                            ],
                        }, {
                            name: '31 - Back Pain',
                            itemsType: 'subject',
                            items: [
                                { name: '1 - Foundations' },
                                { name: '2 - Diagnostic Approach' },
                                { name: '3 - Ancillary Testing' },
                                { name: '4 - Diagnostic and Management Algorithms' },
                                { name: '5 - Empiric Management' },
                                { name: '6 - Disposition' },
                                { name: '7 - Trauma' },
                            ],
                        },
                    ]
                },
            ]
        },
        {
            name: '2 - Trauma',
            itemsType: 'section',
            items: [
                {
                    name: '1 - General Concepts and System Injuries',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '32 - Multiple Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '33 - Head Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Moderate and Severe Traumatic Brain Injury' },
                                { name: 'Mild Traumatic Brain Injury (Also Known as Concussion)' },
                            ]
                        },
                        {
                            name: '34 - Facial Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Differential Diagnoses Based on Anatomic Sites' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '35 - Spinal Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '36 - Neck Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '37 - Thoracic Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Chest Wall Injury' },
                                { name: 'Pulmonary Injuries' },
                                { name: 'Tracheobronchial Injury' },
                                { name: 'Diaphragmatic Injury' },
                                { name: 'Cardiovascular Trauma' },
                            ]
                        },
                        {
                            name: '38 - Abdominal Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '39 - Genitourinary Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                            ]
                        },
                        {
                            name: '40 - Peripheral Vascular Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                    ]
                },
                {
                    name: '2 - Orthopedic Injuries',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '41 - General Principles of Orthopedic Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Overall Foundations' },
                                { name: 'Fractures' },
                                { name: 'Subluxation and Dislocations' },
                                { name: 'Soft Tissue Injuries' },
                                { name: 'Strains' },
                            ]
                        },
                        {
                            name: '42 - Hand Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations: a General Approach to Hand Injuries' },
                                { name: 'Specific Hand Injuries' },
                                { name: 'Infectious Disorders of the Hand' },
                            ]
                        },
                        {
                            name: '43 - Wrist and Forearm Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Wrist' },
                                { name: 'Forearm' },
                            ]
                        },
                        {
                            name: '44 - Humerus and Elbow Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'General Clinical Features' },
                                { name: 'General Differential Diagnoses' },
                                { name: 'General Diagnostic Testing' },
                                { name: 'General Management' },
                                { name: 'General Disposition' },
                                { name: 'Acknowledgments' },
                            ]
                        },
                        {
                            name: '45 - Shoulder Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Specific Injuries' },
                            ]
                        },
                        {
                            name: '46 - Pelvic Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Deep Venous Thrombosis' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '47 - Femur and Hip Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'General Information' },
                                { name: 'Clinical Features of Hip and Femur Pathology' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                            ]
                        },
                        {
                            name: '48 - Knee and Lower Leg Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Knee Injuries—Foundations' },
                                { name: 'Knee Injuries—Clinical Features' },
                                { name: 'Knee Injuries—Differential Diagnoses' },
                                { name: 'Knee Injuries—Examination Maneuvers' },
                                { name: 'Knee Injuries—Diagnostic Testing' },
                                { name: 'Specific Knee Injuries' },
                                { name: 'Overuse Syndromes' },
                                { name: 'ARTHRITIS' },
                                { name: 'Baker Cyst' },
                                { name: 'Leg' },
                                { name: 'Specific Leg Injuries' },
                            ]
                        },
                        {
                            name: '49 - Ankle and Foot Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Ankle' },
                                { name: 'Ankle Fractures and Dislocations' },
                                { name: 'Foot' },
                                { name: 'Specific Pathologic Conditions' },
                                { name: 'Special Considerations' },
                            ]
                        },
                    ]
                },
                {
                    name: '3 - Soft Tissue Injuries',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '50 - Wound Management Principles',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '51 - Foreign Bodies',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '52 - Mammalian Bites',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Dog Bites' },
                                { name: 'Cat Bites' },
                                { name: 'Other Mammals' },
                                { name: 'Uncommon Animal Bites' },
                                { name: 'Domestic Herbivores' },
                                { name: 'Wild Animals' },
                            ]
                        },
                        {
                            name: '53 - Venomous Animal Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Venomous Reptiles' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Venomous Arthropods' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Venomous Marine Animals' },
                                { name: 'Differential Diagnoses' },
                            ]
                        },
                        {
                            name: '54 - Thermal Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '55 - Chemical Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'General Approach to a Hazmat Event' },
                                { name: 'Clinical Features and Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Ocular Injuries' },
                                { name: 'Specific Toxins' },
                                { name: 'Emergency Medicine by System' },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            name: '3 - Emergency Medicine by System',
            itemsType: 'section',
            items: [
                {
                    name: '1 - Head and Neck',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '56 - Oral Medicine',
                            itemsType: 'subject',
                            items: [
                                { name: 'Introduction' },
                                { name: 'Disorders of the Tooth' },
                                { name: 'Disorders of the Gingiva and Periodontium' },
                                { name: 'Disorders Involing Dental Procedures' },
                                { name: 'Odontogenic and Deep Neck Infections' },
                                { name: 'Dentoalveolar Trauma' },
                                { name: 'Temporomandibular Joint Disorder and Dislocation' },
                            ]
                        },
                        {
                            name: '57 - Ophthalmology',
                            itemsType: 'subject',
                            items: [
                                { name: 'Overview' },
                                { name: 'Traumatic Conditions' },
                                { name: 'Inflammatory Conditions' },
                                { name: 'Infectious Conditions' },
                                { name: 'Acute Angle-Closure Glaucoma' },
                                { name: 'Primary Disorders of Vision' },
                                { name: 'Diplopia' },
                                { name: 'Anisocoria' },
                                { name: 'Nystagmus' },
                            ]
                        },
                        {
                            name: '58 - Otolaryngology',
                            itemsType: 'subject',
                            items: [
                                { name: 'Otitis Media' },
                                { name: 'Otitis Externa' },
                                { name: 'Necrotizing (Malignant) External Otitis' },
                                { name: 'Mastoiditis' },
                                { name: 'Sudden Hearing Loss' },
                                { name: 'Epistaxis' },
                                { name: 'Sialolithiasis' },
                                { name: 'Neck Masses' },
                            ]
                        },
                    ]
                },
                {
                    name: '2 - Pulmonary System',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '59 - Asthma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Management' },
                                { name: 'Special Situations' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '60 - Chronic Obstructive Pulmonary Disease',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                            ]
                        },
                        {
                            name: '61 - Upper Respiratory Tract Infections',
                            itemsType: 'subject',
                            items: [
                                { name: 'Pharyngitis' },
                                { name: 'Laryngitis' },
                                { name: 'Epiglottitis' },
                                { name: 'Peritonsillitis: Peritonsillar Cellulitis and Abscess' },
                                { name: 'Ludwig Angina' },
                                { name: 'Retropharyngeal Abscess' },
                                { name: 'Parapharyngeal Abscess' },
                                { name: 'Rhinosinusitis' },
                            ]
                        },
                        {
                            name: '62 - Pneumonia',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '63 - Pleural Disease',
                            itemsType: 'subject',
                            items: [
                                { name: 'Introduction' },
                                { name: 'Spontaneous Pneumothorax' },
                                { name: 'Pleural Effusion' },
                            ]
                        },
                    ]
                },
                {
                    name: '3 - Cardiac System',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '64 - Acute Coronary Syndromes',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Epidemiology' },
                                { name: 'Pathophysiology' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '65 - Dysrhythmias',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Classification of Antidysrhythmic Drugs' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '66 - Implantable Cardiac Devices',
                            itemsType: 'subject',
                            items: [
                                { name: 'Permanent Pacemakers' },
                                { name: 'Implantable Cardioverter-Defibrillators' },
                                { name: 'Mechanical Circulatory Support Devices' },
                            ]
                        },
                        {
                            name: '67 - Heart Failure',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '68 - Pericardial and Myocardial Disease',
                            itemsType: 'subject',
                            items: [
                                { name: 'Myocardial Disease' },
                                { name: 'Pericardial Disease' },
                            ]
                        },
                        {
                            name: '69 - Infective Endocarditis and Valvular Heart Disease',
                            itemsType: 'subject',
                            items: [
                                { name: 'Infective Endocarditis' },
                                { name: 'Rheumatic Fever' },
                                { name: 'Valvular Heart Disease' },
                                { name: 'Aortic Stenosis' },
                                { name: 'Aortic Regurgitation' },
                            ]
                        },
                    ]
                },
                {
                    name: '4 - Vascular System',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '70 - Hypertension',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                            ]
                        },
                        {
                            name: '71 - Aortic Dissection',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '72 - Abdominal Aortic Aneurysm',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Late Complications of Repair' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '73 - Peripheral Arteriovascular Disease',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Background' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Specific Arteriovascular Diseases' },
                            ]
                        },
                        {
                            name: '74 - Pulmonary Embolism and Deep Vein Thrombosis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Deep Vein Thrombosis' },
                                { name: 'Pulmonary Embolism' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '75 - Esophagus, Stomach, and Duodenum',
                            itemsType: 'subject',
                            items: [
                                { name: 'Dysphagia' },
                                { name: 'Foreign Bodies' },
                                { name: 'Esophageal Perforation' },
                                { name: 'Esophagitis' },
                                { name: 'Gastritis and Peptic Ulcer Disease' },
                                { name: 'Gastric Volvulus' },
                            ]
                        },
                        {
                            name: '76 - Liver and Biliary Tract Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Hepatic Disorders' },
                                { name: 'Hepatitis' },
                                { name: 'Cirrhosis' },
                                { name: 'Hepatic Encephalopathy' },
                                { name: 'Spontaneous Bacterial Peritonitis' },
                                { name: 'Hepatic Abscesses' },
                                { name: 'Miscellaneous Disorders and Conditions of the Liver' },
                                { name: 'Biliary Tract Disorders' },
                                { name: 'Cholecystitis' },
                                { name: 'Cholangitis' },
                                { name: 'Sclerosing Cholangitis' },
                                { name: 'Aids Cholangiopathy' },
                            ]
                        },
                    ]
                },
                {
                    name: '5 - Gastrointestinal System',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '77 - Pancreas',
                            itemsType: 'subject',
                            items: [
                                { name: 'Pancreatitis' },
                                { name: 'Pancreatic Cancer' },
                            ]
                        },
                        {
                            name: '78 - Small Intestine',
                            itemsType: 'subject',
                            items: [
                                { name: 'Small Bowel Obstruction' },
                                { name: 'Acute Mesenteric Ischemia' },
                            ]
                        },
                        {
                            name: '79 - Acute Appendicitis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '80 - Gastroenteritis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Overview' },
                                { name: 'Clinical Features' },
                                { name: 'Bacterial Gastroenteritis' },
                                { name: 'Invasive Bacteria' },
                                { name: 'Noninvasive Toxin-Forming Bacteria' },
                                { name: 'Viral Gastroenteritis' },
                                { name: 'Parasites' },
                                { name: 'Food Poisoning' },
                                { name: 'Specific Groups With Gastroenteritis' },
                                { name: 'Gastroenteritis in the Immunocompromised Host With HIV/AIDS' },
                            ]
                        },
                        {
                            name: '81 - Large Intestine',
                            itemsType: 'subject',
                            items: [
                                { name: 'Irritable Bowel Syndrome' },
                                { name: 'Diverticular Disease' },
                                { name: 'Large Bowel Obstruction' },
                                { name: 'Volvulus' },
                                { name: 'Intussusception' },
                                { name: 'Inflammatory Bowel Disease' },
                                { name: 'Colonic Ischemia' },
                                { name: 'Stercoral Colitis' },
                                { name: 'Radiation Proctocolitis' },
                                { name: 'Neutropenic Enterocolitis' },
                            ]
                        },
                        {
                            name: '82 - Anorectum',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Specific Anorectal Problems' },
                            ]
                        },
                    ]
                },
                {
                    name: '6 - Genitourinary and Gynecologic Systems',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '83 - Renal Failure',
                            itemsType: 'subject',
                            items: [
                                { name: 'Renal Failure' },
                            ]
                        },
                        {
                            name: '84 - Sexually Transmitted Infections',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Disorders Characterized by Genital Ulcers' },
                                { name: 'Disorders Characterized by Genital Discharge' },
                                { name: 'Epithelial Cell Infections' },
                                { name: 'Ectoparasites' },
                            ]
                        },
                        {
                            name: '85 - Urologic Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Urinary Tract Infection in Adults' },
                                { name: 'Complicated Urinary Tract Infection in High-Risk Populations' },
                                { name: 'Prostatitis' },
                                { name: 'Renal Calculi' },
                                { name: 'Bladder (Vesical) Calculus' },
                                { name: 'Acute Scrotal Pain' },
                                { name: 'Specific Disorders' },
                                { name: 'Torsion of Appendages of the Testis' },
                                { name: 'Epididymitis' },
                                { name: 'Orchitis' },
                                { name: 'Testicular Tumors' },
                                { name: 'Testicular Trauma' },
                                { name: 'Inguinal Hernia, Acute Hydrocele, Varicocele, and Spermatocele' },
                                { name: 'Acute Urinary Retention' },
                                { name: 'Hematuria' },
                            ]
                        },
                        {
                            name: '86 - Gynecologic Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Gynecologic Disorders' },
                                { name: 'Adnexal Torsion' },
                                { name: 'Ovarian Cysts and Masses' },
                                { name: 'Abnormal Uterine Bleeding in the Nonpregnant Patient' },
                                { name: 'Emergency Contraception' },
                            ]
                        },
                    ]
                },
                {
                    name: '7 - Neurology',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '87 - Stroke',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Transient Ischemic Attack' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '88 - Seizure',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Special Cases' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '89 - Headache Disorders2',
                            itemsType: 'subject',
                            items: [
                                { name: 'Primary Headache Disorders' },
                                { name: 'Secondary Headache Disorders' },
                            ]
                        },
                        {
                            name: '90 - Delirium and Dementia',
                            itemsType: 'subject',
                            items: [
                                { name: 'Overview' },
                                { name: 'Delirium' },
                                { name: 'Dementia' },
                            ]
                        },
                        {
                            name: '91 - Brain and Cranial Nerve Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Multiple Sclerosis' },
                                { name: 'Cerebral Venous Thrombosis' },
                                { name: 'Cranial Nerve Disorders' },
                            ]
                        },
                        {
                            name: '92 - Spinal Cord Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Specific Disease Processes' },
                                { name: 'Chronic Complications Of Spinal Cord Injury' },
                            ]
                        },
                        {
                            name: '93 - Peripheral Nerve Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Overview' },
                                { name: 'Specific Types of Neuropathies' },
                            ]
                        },
                        {
                            name: '94 - Neuromuscular Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Disorders of the Neuromuscular Junction' },
                                { name: 'Disorders of the Muscles' },
                            ]
                        },
                        {
                            name: '95 - Central Nervous System Infections',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                    ]
                },
                {
                    name: '8 - Behavioral Disorders',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '96 - Thought Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '97 - Mood Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Epidemiology' },
                                { name: 'Pathophysiology' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '98 - Anxiety Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '99 - Somatic Symptoms and Related Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '100 - Factitious Disorders and Malingering',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '101 - Suicidal Behavior',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Additional Ethical Considerations' },
                            ]
                        },
                    ]
                },
                {
                    name: '9 - Immunologic and Inflammatory',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '102 - Arthritis',
                            itemsType: 'subject',
                            items: [
                                { name: 'General Approach to Arthritis' },
                                { name: 'Acute Monoarticular Joint Pain' },
                                { name: 'Chronic Monoarticular Arthritis' },
                                { name: 'Acute Polyarticular Joint Pain' },
                                { name: 'Chronic Polyarthritis' },
                            ]
                        },
                        {
                            name: '103 - Tendinopathy and Bursitis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Tendinopathy' },
                                { name: 'Bursitis' },
                            ]
                        },
                        {
                            name: '104 - Musculoskeletal Back Pain',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '105 - Systemic Lupus Erythematosus and the Vasculitides',
                            itemsType: 'subject',
                            items: [
                                { name: 'Systemic Lupus Erythematosus' },
                                { name: 'Vasculitis' },
                            ]
                        },
                        {
                            name: '106 - Allergy, Anaphylaxis, and Angioedema',
                            itemsType: 'subject',
                            items: [
                                { name: 'Allergy' },
                                { name: 'Anaphylaxis' },
                                { name: 'Urticaria and Angioedema' },
                            ]
                        },
                        {
                            name: '107 - Dermatologic Presentations',
                            itemsType: 'subject',
                            items: [
                                { name: 'Overview' },
                                { name: 'Infectious Disorders' },
                                { name: 'Allergic Reactions' },
                                { name: 'Drug Reactions' },
                                { name: 'Inflammatory Conditions' },
                                { name: 'Autoimmune Disorders' },
                                { name: 'Cutaneous Malignancies' },
                                { name: 'Skin Conditions Associated with Systemic Disease' },
                            ]
                        },
                    ]
                },
                {
                    name: '10 - Hematology and Oncology',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '108 - Blood and Blood Components',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Management' },
                                { name: 'Outcomes' },
                            ]
                        },
                        {
                            name: '109 - Anemia and Polycythemia',
                            itemsType: 'subject',
                            items: [
                                { name: 'Anemia' },
                                { name: 'Anemias Due To Decreased Red Blood Cell Production' },
                                { name: 'Polycythemia' },
                            ]
                        },
                        {
                            name: '110 - White Blood Cell Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '111 - Disorders of Hemostasis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '112 - Oncologic Emergencies',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Febrile Neutropenia' },
                                { name: 'Metastatic Spinal Cord Compression' },
                                { name: 'Malignant Pericardial Disease' },
                                { name: 'Hypercalcemia' },
                                { name: 'Foundations' },
                                { name: 'Tumor Lysis Syndrome' },
                                { name: 'Leukostasis' },
                                { name: 'Superior Vena Cava Syndrome' },
                                { name: 'Monoclonal Antibody Therapies and Complications' },
                                { name: 'T-Lymphocyte Checkpoint Inhibitor Therapies and Complications' },
                                { name: 'Adoptive Cell Transfer Therapies and Complications' },
                            ]
                        },
                    ]
                },
                {
                    name: '11 - Metabolism and Endocrinology',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '113 - Acid-Base Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis and Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '114 - Electrolyte Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Hyperkalemia' },
                                { name: 'Hypokalemia' },
                                { name: 'Hypernatremia' },
                                { name: 'Hyponatremia' },
                                { name: 'Hypercalcemia' },
                                { name: 'Hypocalcemia' },
                                { name: 'Hypermagnesemia' },
                                { name: 'Hypomagnesemia' },
                                { name: 'Hyperphosphatemia' },
                                { name: 'Hypophosphatemia' },
                            ]
                        },
                        {
                            name: '115 - Diabetes Mellitus and Disorders of Glucose Homeostasis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Diabetes Mellitus' },
                                { name: 'Late Complications of Diabetes' },
                                { name: 'Diabetic Ketoacidosis' },
                                { name: 'Hyperglycemic Hyperosmolar State' },
                                { name: 'Diabetes in Pregnancy' },
                                { name: 'Hypoglycemia' },
                            ]
                        },
                        {
                            name: '116 - Rhabdomyolysis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '117 - Thyroid and Adrenal Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Hyperthyroidism' },
                                { name: 'Hypothyroidism' },
                                { name: 'Adrenal Excess States' },
                                { name: 'Adrenal Insufficiency' },
                            ]
                        },
                    ]
                },
                {
                    name: '12 - Infectious Diseases',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '118 - Bacteria',
                            itemsType: 'subject',
                            items: [
                                { name: 'Diphtheria' },
                                { name: 'Pertussis' },
                                { name: 'Tetanus' },
                                { name: 'Botulism' },
                                { name: 'Pneumococcemia' },
                                { name: 'Meningococcemia' },
                                { name: 'Toxic Shock Syndrome' },
                            ]
                        },
                        {
                            name: '119 - Viruses',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Vaccine-Preventable Infections of Childhood' },
                                { name: 'Viral Infections with Vesicular Rash' },
                                { name: 'Viral Infections Causing Nonspecific Febrile Illness' },
                                { name: 'Viruses Associated with Respiratory Infections' },
                                { name: 'Viruses Associated with Diarrheal Illness' },
                                { name: 'Viral Infections with Neurologic Manifestations' },
                                { name: 'Other Arboviral Infections' },
                                { name: 'Viral Hemorrhagic Fevers' },
                            ]
                        },
                        {
                            name: '120 - Coronaviruses',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '121 - HIV',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '122 - Parasites',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Malaria' },
                                { name: 'Babesiosis' },
                                { name: 'Schistosomiasis and Katayama Fever' },
                                { name: 'Cysticercosis' },
                                { name: 'African Trypanosomiasis' },
                                { name: 'Toxoplasmosis' },
                                { name: 'Eosinophilic Meningitis' },
                                { name: 'Parasites Associated with Fever' },
                                { name: 'Whipworm And Hookworm' },
                                { name: 'Tapeworm' },
                                { name: 'Parasites Associated with Cutaneous Manifestations' },
                                { name: 'Parasites Associated with Ocular Manifestations' },
                                { name: 'Parasites Associated with Pulmonary Manifestations' },
                                { name: 'Parasitic Diseases With Cardiovascular Manifestations' },
                                { name: 'Parasitic Diseases With Gastrointestinal Manifestations' },
                                { name: 'Parasitic Coinfections in Patients with Hiv Infection And Aids' },
                            ]
                        },
                        {
                            name: '123 - Tickborne Illnesses',
                            itemsType: 'subject',
                            items: [
                                { name: 'Overview' },
                                { name: 'Lyme Disease' },
                                { name: 'Southern Tick-Associated Rash Illness' },
                                { name: 'Relapsing Fever' },
                                { name: 'Tularemia' },
                                { name: 'Rocky Mountain Spotted Fever' },
                                { name: 'Q Fever' },
                                { name: 'Ehrlichioses' },
                                { name: 'Babesiosis' },
                                { name: 'Colorado Tick Fever' },
                                { name: 'Other Tickborne Viruses' },
                                { name: 'Tick Paralysis' },
                                { name: 'Tick Bite Prophylaxis With Insect Repellents' },
                            ]
                        },
                        {
                            name: '124 - Tuberculosis',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Prevention of Transmission in the Emergency Department' },
                            ]
                        },
                        {
                            name: '125 - Bone and Joint Infections',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Osteomyelitis' },
                                { name: 'Septic Arthritis' },
                            ]
                        },
                        {
                            name: '126 - Skin and Soft Tissue Infections',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Cellulitis' },
                                { name: 'Abscess' },
                                { name: 'Impetigo' },
                                { name: 'Folliculitis' },
                                { name: 'Acne Vulgaris and Hidradenitis Suppurativa (ACNE INVERSA)' },
                                { name: 'Necrotizing Skin and Soft Tissue Infections' },
                                { name: 'Toxic Shock Syndromes' },
                                { name: 'Other Infections With Skin Manifestations' },
                            ]
                        },
                        {
                            name: '127 - Sepsis Syndrome',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Diagnostic Considerations' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            name: '4 - Environment and Toxicology',
            itemsType: 'section',
            items: [
                {
                    name: '1 - Environment',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '128 - Hypothermia, Frostbite, and Nonfreezing Cold Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Accidental Hypothermia' },
                                { name: 'Frostbite and Nonfreezing Cold Injuries' },
                                { name: 'Frostbite' },
                                { name: 'Nonfreezing Cold Injuries' },
                            ]
                        },
                        {
                            name: '129 - Heat Illness',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Minor Heat Illnesses' },
                                { name: 'Major Heat Illnesses' },
                            ]
                        },
                        {
                            name: '130 - Electrical and Lightning Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '131 - Scuba Diving and Dysbarism',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '132 - High-Altitude Medicine',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Acute Mountain Sickness' },
                                { name: 'High-Altitude Pulmonary Edema' },
                                { name: 'High-Altitude Cerebral Edema' },
                                { name: 'Special Considerations' },
                                { name: 'Altitude and Underlying Medical Conditions' },
                            ]
                        },
                        {
                            name: '133 - Drowning',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '134 - Radiation Injuries',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Additional Resources' },
                            ]
                        },
                    ]
                },
                {
                    name: '2 - Toxicology',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '135 - Care of the Poisoned Patient',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles Of Toxicity' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Syrup Of Ipecac' },
                                { name: 'Gastric Lavage' },
                                { name: 'Single-Dose Activated Charcoal' },
                                { name: 'Whole Bowel Irrigation' },
                                { name: 'Multiple-Dose Activated Charcoal' },
                                { name: 'Serum Alkalinization' },
                                { name: 'Intravenous Fat Emulsion (Intralipid)' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '136 - Toxic Alcohols',
                            itemsType: 'subject',
                            items: [
                                { name: 'Methanol' },
                                { name: 'Ethylene Glycol' },
                                { name: 'Isopropyl Alcohol' },
                                { name: 'Other Alcohols of Clinical Significance' },
                            ]
                        },
                        {
                            name: '137 - Alcohol-Related Disease',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnosis' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '138 - Acetaminophen',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '139 - Aspirin and Nonsteroidal Agents',
                            itemsType: 'subject',
                            items: [
                                { name: 'Aspirin' },
                                { name: 'Nonsteroidal Agents' },
                            ]
                        },
                        {
                            name: '140 - Anticholinergics',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles of Toxicology' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '141 - Antidepressants',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles Of Toxicity' },
                                { name: 'Monoamine Oxidase Inhibitors' },
                                { name: 'Tricyclic Antidepressants' },
                                { name: 'Selective Serotonin Reuptake Inhibitors' },
                                { name: 'Serotonin-Norepinephrine Reuptake Inhibitors And Norepinephrine Reuptake Inhibitors' },
                                { name: 'Serotonin Modulators And Stimulators' },
                                { name: 'Miscellaneous Antidepressants' },
                                { name: 'Serotonin Syndrome' },
                                { name: 'Discontinuation Syndromes' },
                            ]
                        },
                        {
                            name: '142 - Cardiovascular Drugs',
                            itemsType: 'subject',
                            items: [
                                { name: 'Cardioactive Steroids (Digoxin)' },
                                { name: 'Beta-Blockers' },
                                { name: 'Calcium Channel Blockers' },
                                { name: 'Clonidine and Other Central Alpha-2 Agonists' },
                                { name: 'Nitrates, Nitrites, and Methemoglobinemia' },
                            ]
                        },
                        {
                            name: '143 - Caustics',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Special Cases' },
                            ]
                        },
                        {
                            name: '144 - Cocaine and Other Sympathomimetics',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Special Topics' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '145 - THC and Hallucinogens',
                            itemsType: 'subject',
                            items: [
                                { name: 'Serotonergic Agents' },
                                { name: 'Dissociative Agents' },
                                { name: 'Cannabis and Synthetic Cannabinoids' },
                            ]
                        },
                        {
                            name: '146 - Iron and Heavy Metals',
                            itemsType: 'subject',
                            items: [
                                { name: 'Iron' },
                                { name: 'Lead' },
                                { name: 'Arsenic' },
                                { name: 'Mercury' },
                            ]
                        },
                        {
                            name: '147 - Hydrocarbons',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '148 - Inhaled Toxins',
                            itemsType: 'subject',
                            items: [
                                { name: 'Simple Asphyxiants' },
                                { name: 'Pulmonary Irritants' },
                                { name: 'Smoke Inhalation' },
                                { name: 'Cyanide and Hydrogen Sulfide' },
                                { name: 'Carbon Monoxide' },
                            ]
                        },
                        {
                            name: '149 - Lithium',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '150 - Antipsychotics',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '151 - Opioids',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles of Toxicity' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                                { name: 'Withdrawal' },
                            ]
                        },
                        {
                            name: '152 - Pesticides',
                            itemsType: 'subject',
                            items: [
                                { name: 'Organophosphate Insecticides' },
                                { name: 'Substituted Phenols' },
                                { name: 'Chlorophenoxy Herbicides' },
                                { name: 'Bipyridyl Herbicides' },
                                { name: 'Pyrethrin and Pyrethroid Insecticides' },
                                { name: 'Glyphosate' },
                                { name: 'DEET' },
                                { name: 'Rodenticides' },
                            ]
                        },
                        {
                            name: '153 - Plants, Herbal Medications, and Mushrooms',
                            itemsType: 'subject',
                            items: [
                                { name: 'Plants' },
                                { name: 'Mushrooms' },
                                { name: 'Herbal Medications' },
                            ]
                        },
                        {
                            name: '154 - Sedative-Hypnotics',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Benzodiazepines' },
                                { name: 'Management' },
                                { name: 'Barbiturates' },
                                { name: 'Management' },
                                { name: 'Individual Medications' },
                                { name: 'Over-The-Counter Sleep Aids' },
                                { name: 'Gamma-Hydroxybutyrate' },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            name: '5 - Special Populations',
            itemsType: 'section',
            items: [
                {
                    name: '1 - The Pediatric Patient',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '155 - Care of the Pediatric Patient',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Evaluation' },
                                { name: 'Specific Disorders' },
                                { name: 'Other Considerations' },
                            ]
                        },
                        {
                            name: '156 - Pediatric Airway Management',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Anatomy' },
                                { name: 'Physiology' },
                                { name: 'Equipment' },
                                { name: 'Management' },
                                { name: 'Devices and Techniques' },
                                { name: 'Outcomes' },
                            ]
                        },
                        {
                            name: '157 - Pediatric Sedation and Analgesia',
                            itemsType: 'subject',
                            items: [
                                { name: 'Sedation' },
                                { name: 'Pain Management' },
                            ]
                        },
                        {
                            name: '158 - Pediatric Resuscitation',
                            itemsType: 'subject',
                            items: [
                                { name: 'Cardiac Arrest' },
                                { name: 'Septic Shock in the Pediatric Patient' },
                            ]
                        },
                        {
                            name: '159 - Neonatal Resuscitation',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Transition From Fetal to Extrauterine Life' },
                                { name: 'Specific Issues' },
                                { name: 'Meconium-Stained Amniotic Fluid' },
                                { name: 'Special Anatomic Anomalies' },
                                { name: 'Disposition' },
                                { name: 'Outcomes' },
                            ]
                        },
                        {
                            name: '160 - Pediatric Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Disposition' },
                                { name: 'Specific Injuries' },
                                { name: 'Musculoskeletal Injuries' },
                            ]
                        },
                        {
                            name: '161 - Pediatric Fever',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '162 - Pediatric Upper Airway Obstruction and Infections',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                            ]
                        },
                        {
                            name: '163 - Pediatric Lower Airway Obstruction',
                            itemsType: 'subject',
                            items: [
                                { name: 'Asthma' },
                                { name: 'Bronchiolitis' },
                            ]
                        },
                        {
                            name: '164 - Pediatric Lung Disease',
                            itemsType: 'subject',
                            items: [
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '165 - Pediatric Cardiac Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '166 - Pediatric Gastrointestinal Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '167 - Pediatric Infectious Diarrheal Disease and Dehydration',
                            itemsType: 'subject',
                            items: [
                                { name: 'Diarrhea' },
                                { name: 'Dehydration' },
                            ]
                        },
                        {
                            name: '168 - Pediatric Genitourinary and Renal Tract Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '169 - Pediatric Neurologic Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Seizures' },
                                { name: 'Altered Mental Status' },
                                { name: 'Headaches' },
                                { name: 'Pediatric Ataxia' },
                                { name: 'Pediatric Vertigo' },
                                { name: 'Motor Dysfunction' },
                                { name: 'Stroke' },
                                { name: 'Disorders of the Spinal Cord' },
                                { name: 'Guillain-Barré Syndrome' },
                                { name: 'Infant Botulism' },
                                { name: 'Myasthenia Gravis' },
                            ]
                        },
                        {
                            name: '170 - Pediatric Musculoskeletal Disorders',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '171 - Pediatric Drug Therapy',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Pharmacokinetic Considerations In Children' },
                                { name: 'Other Considerations' },
                            ]
                        },
                        {
                            name: '172 - Child Abuse',
                            itemsType: 'subject',
                            items: [
                                { name: 'Physical Abuse' },
                                { name: 'Sexual Abuse' },
                            ]
                        },
                    ]
                },
                {
                    name: '2 - The Pregnant Patient',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '173 - Complications of Pregnancy',
                            itemsType: 'subject',
                            items: [
                                { name: 'Problems in Early Pregnancy' },
                                { name: 'Complications of Late Pregnancy' },
                                { name: 'Medical and Surgical Problems in the Pregnant Patient' },
                            ]
                        },
                        {
                            name: '174 - Medical Emergencies During Pregnancy',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Asthma' },
                                { name: 'Cardiovascular Disorders' },
                                { name: 'Hematologic Disorders' },
                                { name: 'Neurologic Disorders' },
                                { name: 'Renal Disorders' },
                                { name: 'Metabolic and Endocrine Disorders' },
                                { name: 'Obesity' },
                                { name: 'Thyroid Disorders' },
                                { name: 'Systemic Infections' },
                                { name: 'Inflammatory Disorders' },
                                { name: 'Psychiatric Disorders' },
                            ]
                        },
                        {
                            name: '175 - Drug Therapy in Pregnancy',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Pharmacologic Therapy' },
                            ]
                        },
                        {
                            name: '176 - Labor and Delivery',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Normal Delivery' },
                                { name: 'Third-Trimester Complications Associated With Delivery' },
                                { name: 'Complicated Delivery' },
                                { name: 'Multiple Gestations' },
                                { name: 'Umbilical Cord-Related Emergencies' },
                                { name: 'Maternal Complications Of Labor And Delivery' },
                                { name: 'Postpartum Problems' },
                            ]
                        },
                        {
                            name: '177 - Trauma in Pregnancy',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Special Procedures' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                    ]
                },
                {
                    name: '3 - The Geriatric Patient',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '178 - Care of the Geriatric Patient',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues and Disorders' },
                            ]
                        },
                        {
                            name: '179 - Geriatric Trauma',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                                { name: 'Common Injuries in Older Adults' },
                                { name: 'Disposition, End-of-Life Considerations, and Recovery' },
                            ]
                        },
                        {
                            name: '180 - Geriatric Drug Therapy',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: '181 - Geriatric Abuse and Neglect',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Specific Issues' },
                            ]
                        },
                    ]
                },
                {
                    name: '4 - Special Clinical Circumstances',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '182 - The Immunocompromised Patient',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Physiology' },
                                { name: 'Specific Disorders' },
                                { name: 'Immunosuppressive Therapy' },
                            ]
                        },
                        {
                            name: '183 - The Solid Organ Transplant Patient',
                            itemsType: 'subject',
                            items: [
                                { name: 'Introduction' },
                                { name: 'Organ-Specific Considerations' },
                                { name: 'Other Considerations' },
                                { name: 'Disposition' },
                            ]
                        },
                        {
                            name: '184 - The Morbidly Obese Patient',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Pathophysiology' },
                                { name: 'Trauma Considerations' },
                                { name: 'Bariatric Surgery' },
                                { name: 'Procedural Difficulties' },
                            ]
                        },
                        {
                            name: '185 - The Combative and Difficult Patient',
                            itemsType: 'subject',
                            items: [
                                { name: 'The Combative Patient' },
                                { name: 'The Difficult Patient' },
                            ]
                        },
                    ]
                },
                {
                    name: '5 - Underserved Populations',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: '186 - Multiculturalism, Diversity, and Care Delivery',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Rationale For Cultural Competence' },
                                { name: 'Specific Issues' },
                                { name: 'Cultural Competence' },
                                { name: 'Recommendations' },
                            ]
                        },
                        {
                            name: '187 - Human Trafficking',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Epidemiology' },
                                { name: 'Impact On Health' },
                                { name: 'Specific Issues' },
                                { name: 'Disposition' },
                                { name: 'Special Considerations' },
                            ]
                        },
                        {
                            name: '188 - Sexual Minority Populations (LGBTQ)',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                            ]
                        },
                        {
                            name: '189 - Social Determinants',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                                { name: 'Management' },
                            ]
                        },
                        {
                            name: '190 - Community Violence',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                                { name: 'Physical Trauma From Violent Injury and the Role of Hospitals, Emergency Departments, and Trauma Centers' },
                            ]
                        },
                        {
                            name: '191 - Sexual Assault',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Differential Diagnoses' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Special Populations' },
                                { name: 'Disposition' },
                                { name: 'Testifying in Court' },
                            ]
                        },
                        {
                            name: '192 - Intimate Partner Violence and Abuse',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Clinical Features' },
                                { name: 'Specific Issues' },
                                { name: 'Diagnostic Testing' },
                                { name: 'Management' },
                                { name: 'Disposition' },
                            ]
                        },
                    ]
                },
                {
                    name: '6 - Public Health and Humanitarian Emergencies',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: 'e1 - Global Emergency Medicine',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                            ]
                        },
                        {
                            name: 'e2 - Humanitarian Aid in Disaster and Conflict',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                                { name: 'Conclusion' },
                            ]
                        },
                    ]
                },
                {
                    name: '7 - The Practice of Emergency Medicine',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: 'e3 - Emergency Ultrasound',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                            ]
                        },
                        {
                            name: 'e4 - The Geriatric Emergency Department',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles' },
                                { name: 'Specific Disorders/Issues' },
                                { name: 'Conclusion' },
                            ]
                        },
                        {
                            name: 'e5 - End of Life',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Core Aspects of Palliative Care in the Emergency Department' },
                            ]
                        },
                        {
                            name: 'e6 - Bioethics',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                                { name: 'Fundamental Bioethical Principles' },
                                { name: 'Medical And Moral Imperatives In Emergency Medicine' },
                                { name: 'Codifying Professional Values: Ethical Oaths And Codes' },
                                { name: 'Applying Bioethics' },
                                { name: 'Medical Imperatives And Bioethical Dilemmas' },
                                { name: 'Rapid Ethical Decision-Making Model' },
                                { name: 'Advance Directives' },
                                { name: 'Consent, Decision-Making Capacity, And Surrogate Decision Makers' },
                                { name: 'Resuscitation Ethics' },
                                { name: 'Public Policy And Bioethics' },
                            ]
                        },
                        {
                            name: 'e7 - Emergency Medical Treatment and Labor Act and Medicolegal Issues',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles' },
                                { name: 'Specific Issues—Emergency Medical Treatment And Labor Act' },
                                { name: 'Consent For Medical Care' },
                                { name: 'Refusal Of Medical Care' },
                                { name: 'Reporting Requirements' },
                            ]
                        },
                        {
                            name: 'e8 - Quality Improvement and Patient Safety',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles' },
                                { name: 'Sources of Failure in Emergency Care' },
                                { name: 'Problem Areas in Emergency Care' },
                                { name: 'Summary' },
                            ]
                        },
                        {
                            name: 'e9 - Patient Experience in the Emergency Department',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues' },
                                { name: 'Summary' },
                            ]
                        },
                        {
                            name: 'e10 - Wellness, Stress, and the Impaired Physician',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Wellness Strategies' },
                            ]
                        },
                        {
                            name: 'e11 - Forensic Emergency Medicine',
                            itemsType: 'subject',
                            items: [
                                { name: 'Perspective' },
                                { name: 'Forensic Aspects of Gunshot Wounds' },
                                { name: 'Forensic Aspects of Physical Assault' },
                                { name: 'Forensic Aspects of Motor Vehicle Trauma' },
                                { name: 'Interfacing with the Law' },
                            ]
                        },
                    ]
                },
                {
                    name: '8 - Emergency Medical Services and Disaster Preparedness',
                    itemsType: 'chapter',
                    items: [
                        {
                            name: 'e12 - Emergency Medical Services: Overview and Ground Transport',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Oversight' },
                                { name: 'Medical Care And Controversies In Management' },
                                { name: 'Interfacility And Specialized Transports' },
                                { name: 'Acknowledgments' },
                            ]
                        },
                        {
                            name: 'e13 - Air Medical Transport',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues in Air Medical Transport' },
                                { name: 'Specific Disorders' },
                                { name: 'Lives Saved, Efficacy, and Cost-Effectiveness' },
                                { name: 'Future of Air Medical Transport' },
                            ]
                        },
                        {
                            name: 'e14 - Disaster Preparedness',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Issues In Disaster Management' },
                                { name: 'Future Directions' },
                            ]
                        },
                        {
                            name: 'e15 - Weapons of Mass Destruction',
                            itemsType: 'subject',
                            items: [
                                { name: 'Foundations' },
                                { name: 'Specific Disorders' },
                            ]
                        },
                        {
                            name: 'e16 - Tactical Emergency Medical Support and Urban Search and Rescue',
                            itemsType: 'subject',
                            items: [
                                { name: 'Principles' },
                                { name: 'Special Issues' },
                                { name: 'Urban Search And Rescue' },
                                { name: 'Index' },
                            ]
                        },
                    ]
                },
            ]
        }
    ]
};
