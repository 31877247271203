"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeOperators = exports.InSetOperators = exports.defaultFallback = exports.NumericDBValueTypes = exports.StringDBValueTypes = exports.AllowedOperators = exports.EvaluationLogicTypes = void 0;
const consts_1 = require("../../../../_entity/expression/shared/consts");
const shared_1 = require("../../../../_entity/value/shared");
//******* Atom level consts
exports.EvaluationLogicTypes = {
    self: 'self',
    notEqual: 'not-equal',
    set: 'set',
    range: 'range',
    normalRange: 'normal-range',
    exists: 'exists'
};
exports.AllowedOperators = {
    [exports.EvaluationLogicTypes.self]: consts_1.SelfOperators,
    [exports.EvaluationLogicTypes.notEqual]: [consts_1.LogicType_NotEquals],
    [exports.EvaluationLogicTypes.set]: consts_1.inSetOperators,
    [exports.EvaluationLogicTypes.range]: consts_1.inRangeOperators,
    [exports.EvaluationLogicTypes.normalRange]: consts_1.inNormalRangeOperators,
    [exports.EvaluationLogicTypes.exists]: [consts_1.LogicType_Exists]
};
exports.StringDBValueTypes = [
    shared_1.valueType_Enumerated,
    shared_1.valueType_SubEnumerated,
    shared_1.valueType_Tuple,
];
exports.NumericDBValueTypes = [
    shared_1.valueType_StandardRange,
    shared_1.valueType_Range,
    shared_1.valueType_Time
];
exports.defaultFallback = [0, 0];
exports.InSetOperators = [consts_1.LogicType_Equals, consts_1.LogicType_InSet, consts_1.LogicType_IsTrue, consts_1.LogicType_IsFalse];
exports.RangeOperators = [consts_1.LogicType_In, ...consts_1.RightHandValues_NumericOperators];
