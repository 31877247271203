"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicType_DeltaPercentageLesserEquals = exports.LogicType_DeltaPercentageGreaterEquals = exports.LogicType_DeltaIn = exports.LogicType_DeltaEquals = exports.LogicType_DeltaLesserEquals = exports.LogicType_DeltaGreaterEquals = exports.LogicType_DeltaPercentage = exports.LogicType_Delta = exports.LogicType_IsTrue = exports.LogicType_IsFalse = exports.LogicType_Add = exports.LogicType_In = exports.LogicType_GreaterOrEqual = exports.LogicType_GreaterThan = exports.LogicType_LessOrEquals = exports.LogicType_LessThan = exports.LogicType_Exists = exports.LogicType_InSet = exports.LogicType_Complex = exports.LogicType_BelowNormal = exports.LogicType_AboveNormal = exports.LogicType_InNormal = exports.LogicType_BelowRange = exports.LogicType_AboveRange = exports.LogicType_InRange = exports.LogicType_Self = exports.LogicType_And = exports.LogicType_NotEquals = exports.LogicType_Equals = exports.LogicType_Or = exports.FactorOperators = exports.LeftHandWithProp_Types = exports.LeftHandWithSingleId_Types = exports.LeftHandType_Expression = exports.LeftHandType_Atom = exports.LeftHandType_Variable = exports.LeftHandType_Attribute = exports.LeftHandType_Relieving = exports.LeftHandType_Precipitating = exports.BuilderTypesDbKeys = exports.AllExpressionTypes = exports.BuilderExpressionTypes = exports.ExpressionTypesWithVariable = exports.ExpressionType_ResolutionFunctionTerm = exports.ExpressionType_GeneralScore = exports.ExpressionType_GeneralFunction = exports.ExpressionType_NormalRange = exports.ExpressionType_ResolutionFunction = exports.ExpressionType_ClinicalFunction = exports.ExpressionType_Score = void 0;
exports.inNormalRangeOperators = exports.inRangeOperators = exports.inSetOperators = exports.SampleIndex_All = exports.SampleIndex_First = exports.SampleIndex_LatestMinusFour = exports.SampleIndex_LatestMinusThree = exports.SampleIndex_LatestMinusTwo = exports.SampleIndex_LatestMinusOne = exports.SampleIndex_Latest = exports.RightHandValues_NumericOperators = exports.LogicTypes_ToFiler = exports.Expression_OutputTypes = exports.ExpressionOutput_Boolean = exports.ExpressionOutput_Numeric = exports.ExpressionOutput_Range = exports.labsExpressionTitles = exports.LabType_InRange = exports.LabType_AboveRange = exports.LabType_BelowRange = exports.subTermsOperators = exports.factorTypes = exports.BasicAtomsLogicTypes = exports.SingleAtomLogics = exports.AtomsLogicTypes = exports.SelfOperators = exports.AllInOperators = exports.DeltaTypes = exports.AllLogics = exports.BooleanOperators = exports.TupleVarLogic = exports.EnumeratedVarLogic = exports.RangeVarLogic = exports.NormalRangeVarLogic = exports.NumericVarLogic = exports.EmptyRightHandOperators = exports.AllDeltaLogic = exports.BaseDeltaLogic = exports.RangeOperators = exports.NormalRangeOperators = exports.allLogicTypes = exports.uniqueIdsRightHandOperators = exports.rangeRightHandOperators = exports.LogicType_StructuredFreeText = exports.LogicType_DeltaPercentageIn = exports.LogicType_DeltaPercentageEquals = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.ExpressionType_Score = { dbKey: 'score', label: 'Score' };
exports.ExpressionType_ClinicalFunction = { dbKey: 'clinical-function', label: 'Clinical Function' };
exports.ExpressionType_ResolutionFunction = {
    dbKey: 'resolution-function',
    label: 'Resolution Function'
};
exports.ExpressionType_NormalRange = { dbKey: 'normal-range', label: 'Normal Range' };
exports.ExpressionType_GeneralFunction = { dbKey: 'general-function', label: 'General Function' };
exports.ExpressionType_GeneralScore = { dbKey: 'general-score', label: 'General Score' };
exports.ExpressionType_ResolutionFunctionTerm = {
    dbKey: 'resolution-function-term',
    label: 'Resolution Function Term'
};
exports.ExpressionTypesWithVariable = [exports.ExpressionType_ClinicalFunction.dbKey, exports.ExpressionType_Score.dbKey, exports.ExpressionType_NormalRange.dbKey];
exports.BuilderExpressionTypes = [exports.ExpressionType_ClinicalFunction, exports.ExpressionType_Score, exports.ExpressionType_GeneralFunction, exports.ExpressionType_GeneralScore];
exports.AllExpressionTypes = [...exports.BuilderExpressionTypes, exports.ExpressionType_NormalRange, exports.ExpressionType_ResolutionFunction];
exports.BuilderTypesDbKeys = exports.BuilderExpressionTypes.map(type => type.dbKey);
exports.LeftHandType_Precipitating = 'precipitating';
exports.LeftHandType_Relieving = 'relieving';
exports.LeftHandType_Attribute = 'attribute';
exports.LeftHandType_Variable = 'variable';
exports.LeftHandType_Atom = 'atom';
exports.LeftHandType_Expression = 'expression';
exports.LeftHandWithSingleId_Types = [exports.LeftHandType_Variable, exports.LeftHandType_Atom, exports.LeftHandType_Expression];
exports.LeftHandWithProp_Types = [exports.LeftHandType_Relieving, exports.LeftHandType_Precipitating, exports.LeftHandType_Attribute];
exports.FactorOperators = [exports.LeftHandType_Precipitating, exports.LeftHandType_Relieving];
exports.LogicType_Or = '||';
exports.LogicType_Equals = '===';
exports.LogicType_NotEquals = '!==';
exports.LogicType_And = '&&';
exports.LogicType_Self = 'self';
exports.LogicType_InRange = 'in-range';
exports.LogicType_AboveRange = '> range';
exports.LogicType_BelowRange = '< range';
exports.LogicType_InNormal = 'in-normal-range';
exports.LogicType_AboveNormal = '> normal-range';
exports.LogicType_BelowNormal = '< normal-range';
exports.LogicType_Complex = '?';
exports.LogicType_InSet = 'in-set';
exports.LogicType_Exists = 'exists';
exports.LogicType_LessThan = '<';
exports.LogicType_LessOrEquals = '<=';
exports.LogicType_GreaterThan = '>';
exports.LogicType_GreaterOrEqual = '>=';
exports.LogicType_In = 'In';
exports.LogicType_Add = '+';
exports.LogicType_IsFalse = 'is-false';
exports.LogicType_IsTrue = 'is-true';
exports.LogicType_Delta = 'Δ';
exports.LogicType_DeltaPercentage = 'Δ%';
exports.LogicType_DeltaGreaterEquals = 'Δ>=';
exports.LogicType_DeltaLesserEquals = 'Δ<=';
exports.LogicType_DeltaEquals = 'Δ===';
exports.LogicType_DeltaIn = 'ΔIn';
exports.LogicType_DeltaPercentageGreaterEquals = 'Δ%>=';
exports.LogicType_DeltaPercentageLesserEquals = 'Δ%<=';
exports.LogicType_DeltaPercentageEquals = 'Δ%===';
exports.LogicType_DeltaPercentageIn = 'Δ%In';
exports.LogicType_StructuredFreeText = '>_';
exports.rangeRightHandOperators = [
    exports.LogicType_Equals,
    exports.LogicType_LessThan,
    exports.LogicType_LessOrEquals,
    exports.LogicType_GreaterThan,
    exports.LogicType_In,
    exports.LogicType_NotEquals,
    exports.LogicType_GreaterOrEqual,
    exports.LogicType_DeltaGreaterEquals,
    exports.LogicType_DeltaLesserEquals,
    exports.LogicType_DeltaEquals,
    exports.LogicType_DeltaPercentageGreaterEquals,
    exports.LogicType_DeltaPercentageLesserEquals,
    exports.LogicType_DeltaPercentageEquals,
    exports.LogicType_DeltaIn,
    exports.LogicType_DeltaPercentageIn,
];
exports.uniqueIdsRightHandOperators = [
    ...exports.rangeRightHandOperators,
    exports.LogicType_Equals,
    exports.LogicType_InSet,
    exports.LogicType_NotEquals,
    exports.LogicType_InRange,
    exports.LogicType_AboveRange,
    exports.LogicType_BelowRange,
    exports.LogicType_InNormal,
    exports.LogicType_BelowNormal,
    exports.LogicType_AboveNormal,
    exports.LogicType_IsFalse,
    exports.LogicType_IsTrue,
    exports.LogicType_Delta,
    exports.LogicType_DeltaPercentage,
];
exports.allLogicTypes = [
    exports.LogicType_Equals,
    exports.LogicType_LessThan,
    exports.LogicType_LessOrEquals,
    exports.LogicType_GreaterThan,
    exports.LogicType_In,
    exports.LogicType_InSet,
    exports.LogicType_NotEquals,
    exports.LogicType_GreaterOrEqual,
    exports.LogicType_Self,
    exports.LogicType_InRange,
    exports.LogicType_AboveRange,
    exports.LogicType_BelowRange,
    exports.LogicType_InNormal,
    exports.LogicType_AboveNormal,
    exports.LogicType_BelowNormal,
    exports.LogicType_Exists,
    exports.LogicType_IsFalse,
    exports.LogicType_IsTrue,
    exports.LogicType_Delta,
    exports.LogicType_DeltaPercentage,
    exports.LogicType_DeltaGreaterEquals,
    exports.LogicType_DeltaLesserEquals,
    exports.LogicType_DeltaEquals,
    exports.LogicType_DeltaPercentageGreaterEquals,
    exports.LogicType_DeltaPercentageLesserEquals,
    exports.LogicType_DeltaPercentageEquals,
    exports.LogicType_DeltaIn,
    exports.LogicType_DeltaPercentageIn,
];
exports.NormalRangeOperators = [exports.LogicType_InNormal, exports.LogicType_AboveNormal, exports.LogicType_BelowNormal];
exports.RangeOperators = [exports.LogicType_InRange, exports.LogicType_AboveRange, exports.LogicType_BelowRange];
exports.BaseDeltaLogic = [exports.LogicType_DeltaPercentage, exports.LogicType_Delta];
exports.AllDeltaLogic = [...exports.BaseDeltaLogic,
    exports.LogicType_DeltaGreaterEquals,
    exports.LogicType_DeltaLesserEquals,
    exports.LogicType_DeltaEquals,
    exports.LogicType_DeltaPercentageGreaterEquals,
    exports.LogicType_DeltaPercentageLesserEquals,
    exports.LogicType_DeltaPercentageEquals,
    exports.LogicType_DeltaIn,
    exports.LogicType_DeltaPercentageIn
];
exports.EmptyRightHandOperators = [...exports.NormalRangeOperators, ...exports.BaseDeltaLogic, ...exports.RangeOperators, exports.LogicType_IsFalse, exports.LogicType_IsTrue, exports.LogicType_Self, exports.LogicType_Exists];
exports.NumericVarLogic = [exports.LogicType_LessOrEquals, exports.LogicType_GreaterThan, exports.LogicType_GreaterOrEqual, exports.LogicType_Equals, exports.LogicType_LessThan, exports.LogicType_In, exports.LogicType_Exists, exports.LogicType_Self, ...exports.BaseDeltaLogic];
exports.NormalRangeVarLogic = [...exports.NumericVarLogic, ...exports.BaseDeltaLogic, ...exports.NormalRangeOperators];
exports.RangeVarLogic = exports.RangeOperators;
exports.EnumeratedVarLogic = [exports.LogicType_Equals, exports.LogicType_NotEquals, exports.LogicType_InSet, exports.LogicType_Exists, exports.LogicType_Self];
exports.TupleVarLogic = [exports.LogicType_Equals, exports.LogicType_Exists, exports.LogicType_Self];
exports.BooleanOperators = [exports.LogicType_IsTrue, exports.LogicType_IsFalse];
exports.AllLogics = (0, ts_common_1.filterDuplicates)([...exports.BooleanOperators, ...exports.RangeOperators, ...exports.NumericVarLogic, ...exports.NormalRangeVarLogic, ...exports.EnumeratedVarLogic, ...exports.TupleVarLogic]);
exports.DeltaTypes = [exports.LogicType_Self, exports.LogicType_In, exports.LogicType_GreaterOrEqual, exports.LogicType_LessOrEquals, exports.LogicType_Equals];
exports.AllInOperators = [exports.LogicType_DeltaIn, exports.LogicType_DeltaPercentageIn, exports.LogicType_In];
exports.SelfOperators = [exports.LogicType_Self, exports.LogicType_Delta, exports.LogicType_DeltaPercentage];
exports.AtomsLogicTypes = [exports.LogicType_And, exports.LogicType_Or, exports.LogicType_Add, exports.LogicType_Complex, exports.LogicType_StructuredFreeText];
exports.SingleAtomLogics = [exports.LogicType_Complex, exports.LogicType_StructuredFreeText];
exports.BasicAtomsLogicTypes = [exports.LogicType_And];
exports.factorTypes = ['precipitating factors', 'relieving factors'];
exports.subTermsOperators = [exports.LogicType_IsTrue, exports.LogicType_IsFalse];
exports.LabType_BelowRange = 'Below Range';
exports.LabType_AboveRange = 'Above Range';
exports.LabType_InRange = 'In Range';
exports.labsExpressionTitles = [exports.LabType_InRange, exports.LabType_AboveRange, exports.LabType_BelowRange];
exports.ExpressionOutput_Range = 'range';
exports.ExpressionOutput_Numeric = 'numeric';
exports.ExpressionOutput_Boolean = 'boolean';
exports.Expression_OutputTypes = [exports.ExpressionOutput_Boolean, exports.ExpressionOutput_Numeric, exports.ExpressionOutput_Range];
exports.LogicTypes_ToFiler = [
    exports.LogicType_LessThan,
    exports.LogicType_LessOrEquals,
    exports.LogicType_GreaterThan,
    exports.LogicType_GreaterOrEqual,
    ...exports.RangeOperators,
];
exports.RightHandValues_NumericOperators = [
    exports.LogicType_GreaterOrEqual,
    exports.LogicType_GreaterThan,
    exports.LogicType_LessOrEquals,
    exports.LogicType_LessThan,
    exports.LogicType_DeltaEquals,
    exports.LogicType_DeltaGreaterEquals,
    exports.LogicType_DeltaLesserEquals,
    exports.LogicType_DeltaPercentageEquals,
    exports.LogicType_DeltaPercentageGreaterEquals,
    exports.LogicType_DeltaPercentageLesserEquals,
];
// Delta expression consts
exports.SampleIndex_Latest = 'latest';
exports.SampleIndex_LatestMinusOne = 'latest - 1';
exports.SampleIndex_LatestMinusTwo = 'latest - 2';
exports.SampleIndex_LatestMinusThree = 'latest - 3';
exports.SampleIndex_LatestMinusFour = 'latest - 4';
exports.SampleIndex_First = 'first';
exports.SampleIndex_All = [exports.SampleIndex_Latest,
    exports.SampleIndex_LatestMinusOne,
    exports.SampleIndex_LatestMinusTwo,
    exports.SampleIndex_LatestMinusThree,
    exports.SampleIndex_LatestMinusFour,
    exports.SampleIndex_First];
// py-JSON conversion consts
exports.inSetOperators = [exports.LogicType_Equals, exports.LogicType_InSet];
exports.inRangeOperators = [exports.LogicType_In, exports.LogicType_Equals, exports.LogicType_GreaterThan, exports.LogicType_GreaterOrEqual, exports.LogicType_LessThan, exports.LogicType_LessOrEquals, exports.LogicType_DeltaGreaterEquals, exports.LogicType_DeltaLesserEquals, exports.LogicType_DeltaPercentageGreaterEquals, exports.LogicType_DeltaPercentageLesserEquals, exports.LogicType_DeltaIn, exports.LogicType_DeltaPercentageIn, exports.LogicType_DeltaEquals, exports.LogicType_DeltaPercentageEquals];
exports.inNormalRangeOperators = [exports.LogicType_InNormal, exports.LogicType_AboveNormal, exports.LogicType_BelowNormal];
