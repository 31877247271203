"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_DiseaseValueList = void 0;
const React = __importStar(require("react"));
require("./Panel_DiseaseValueList.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const dialogs_1 = require("../../dialogs");
const consts_1 = require("@app/hcs/_entity/value/shared/consts");
const _enum_1 = require("../../../_enum");
const _entity_1 = require("../../../_entity");
const moreHeaders = ['indicative', 'likelihood', 'severity'];
class Panel_DiseaseValueList extends frontend_1.ProtoComponent {
    constructor() {
        super(...arguments);
        // ######################### Logic #########################
        this.updateDiseaseValue = async (diseaseValue, title, successTitle) => {
            const identifier = diseaseValue._id || 'newDV';
            this.setState({ rowLoadingIds: [...this.state.rowLoadingIds, identifier] });
            await (0, frontend_1.performAction)(async () => {
                if (!diseaseValue.likelihoodDirection)
                    diseaseValue.likelihoodDirection = _enum_1.LikelihoodDirection_vD;
                await _entity_1.ModuleFE_DiseaseValue.v1.upsert(diseaseValue).executeSync();
            }, { type: 'notification', notificationLabels: 'Upserting Disease Value' });
            this.reDeriveState({ rowLoadingIds: this.state.rowLoadingIds.filter(i => i !== identifier) });
        };
        this.onDiseaseValueSelected = async (diseaseValue, mvv, valueId) => {
            var _a;
            const updatedDv = Object.assign({}, diseaseValue);
            //If unselecting value
            if (!valueId) {
                //If picking unselect on a new row
                if (!updatedDv._id)
                    return;
                if (mvv.attributeeId)
                    (_a = updatedDv.attributes) === null || _a === void 0 ? true : delete _a[mvv.variableId];
                else
                    delete updatedDv.values[mvv.variableId];
            }
            else { //If selecting value
                if (mvv.attributeeId) {
                    updatedDv.attributes = updatedDv.attributes || {};
                    (updatedDv.attributes[mvv.variableId] || (updatedDv.attributes[mvv.variableId] = {}))[mvv.attributeeId] = valueId;
                }
                else
                    updatedDv.values[mvv.variableId] = valueId;
            }
            await this.updateDiseaseValue(updatedDv, 'Updating Disease Value', 'Updated Disease Value');
        };
        this.onDiseaseLikelihoodSelected = async (diseaseValue, likelihood) => {
            const updatedDv = Object.assign(Object.assign({}, diseaseValue), { likelihood });
            if (!diseaseValue._id && !likelihood)
                return;
            return this.updateDiseaseValue(updatedDv, 'Updating Likelihood', 'Updated Likelihood');
        };
        this.onDiseaseLikelihoodDirectionSelected = async (dv, likelihoodDirection) => {
            const updatedDv = Object.assign(Object.assign({}, dv), { likelihoodDirection });
            if (!updatedDv._id && !likelihoodDirection)
                return;
            return this.updateDiseaseValue(updatedDv, 'Updating Likelihood', 'Updated Likelihood');
        };
        this.onDiseaseLikelihoodRangeUpdated = async (diseaseValue, likelihood) => {
            let updatedDv = undefined;
            //If no range in an empty DV
            if (!likelihood && !diseaseValue._id)
                return;
            //If range wasn't changed
            if ((0, ts_common_1.compare)(likelihood, diseaseValue.likelihoodRange))
                return;
            if (likelihood)
                updatedDv = Object.assign(Object.assign({}, diseaseValue), { likelihoodRange: likelihood });
            else
                delete diseaseValue.likelihoodRange;
            return this.updateDiseaseValue(updatedDv || diseaseValue, 'Updating Likelihood Range', 'Updated Likelihood Range');
        };
        this.onDiseaseSeveritySelected = async (diseaseValue, severity) => {
            if (!diseaseValue._id && !severity)
                return;
            const updatedDv = Object.assign(Object.assign({}, diseaseValue), { severityFactor: severity });
            return this.updateDiseaseValue(updatedDv, 'Updating Severity', 'Updated Severity');
        };
        this.onIndicativeSelected = async (diseaseValue, indicative) => {
            if (!diseaseValue._id && !indicative)
                return;
            const updatedDv = (0, ts_common_1.cloneObj)(diseaseValue);
            if (!indicative) {
                delete updatedDv.indicative;
                return this.updateDiseaseValue(updatedDv, 'Updating Indicative', 'Updated Indicative');
            }
            //Indicative is either -1 or 1. No ranges in between as of right now.
            if (indicative.value !== -1 && indicative.value !== 1)
                return;
            updatedDv.indicative = indicative.value;
            return this.updateDiseaseValue(updatedDv, 'Updating Indicative', 'Updated Indicative');
        };
        this.onMultiVarRightClick = (e, mvv) => {
            var _a;
            const multiVar = this.state.multiVar;
            (0, frontend_1.stopPropagation)(e);
            //Start with option to add column
            const menuItems = [{
                    label: 'Add Column',
                    onClick: () => {
                        dialogs_1.Dialog_MultiVar_Editor.showForVariable(multiVar.item);
                    }
                },];
            if (mvv && !moreHeaders.includes(mvv.variableId)) {
                // Do not allow attribute to attribute
                if (!((_a = frontend_2.ModuleFE_Variable.cache.unique(mvv.variableId)) === null || _a === void 0 ? void 0 : _a.isAttribute))
                    menuItems.push({
                        label: 'Add Attribute',
                        onClick: () => {
                            dialogs_1.Dialog_MultiVar_Editor.showForAttributes(multiVar.item, multiVar.item.variables.indexOf(mvv));
                        }
                    });
                //Add option to remove if column is not the first var or severity/location
                if (mvv.variableId !== multiVar.item.variables[0].variableId)
                    menuItems.push({
                        label: 'Edit ' + (mvv.attributeeId ? 'Attribute' : 'Column'),
                        onClick: () => {
                            if (mvv.attributeeId) {
                                const attributeeIndex = multiVar.item.variables.findIndex(i => i.variableId === mvv.attributeeId);
                                dialogs_1.Dialog_MultiVar_Editor.showForAttributes(multiVar.item, attributeeIndex, multiVar.item.variables.indexOf(mvv));
                            }
                            else
                                dialogs_1.Dialog_MultiVar_Editor.showForVariable(multiVar.item, multiVar.item.variables.indexOf(mvv));
                        }
                    }, {
                        label: 'Remove Column',
                        onClick: async () => {
                            try {
                                await (0, frontend_1.performAction)(async () => {
                                    await _entity_1.ModuleFE_MultiVar._v1.deleteMultiVarColumn({
                                        multiVarId: multiVar.item._id,
                                        variableId: mvv.variableId
                                    }).executeSync();
                                }, { type: 'notification', notificationLabels: 'Upserting Disease Value' });
                            }
                            catch (err) {
                                this.logError(err);
                            }
                        },
                    });
            }
            const rightClickMenu = {
                id: 'multi-var-menu',
                adapter: (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label)),
                originPos: { y: e.clientY, x: e.clientX, },
                modalPos: { x: 0, y: 1 },
                onNodeClicked: (path, item) => {
                    var _a;
                    frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                    (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
                }
            };
            frontend_1.ModuleFE_MouseInteractivity.showMenu(rightClickMenu);
        };
        this.onReferenceSelect = async (ref) => {
            if (ref.type !== 'book') {
                this.state.multiVar.set('referenceIds', [...this.state.multiVar.item.referenceIds, ref._id]);
                await this.state.multiVar.save();
            }
            else {
                frontend_2.Dialog_BookEditor.copy(async (ref) => {
                    this.state.multiVar.set('referenceIds', [...this.state.multiVar.item.referenceIds, ref._id]);
                    await this.state.multiVar.save();
                }, ref);
            }
        };
        this.onReferenceRightClick = (e, ref) => {
            if (!ref)
                return;
            (0, frontend_1.stopPropagation)(e);
            const menuItems = [{
                    label: 'Remove Reference',
                    onClick: async () => {
                        const multiVar = this.state.multiVar;
                        multiVar.set('referenceIds', multiVar.item.referenceIds.filter(i => i !== ref._id));
                        await multiVar.save();
                    }
                }];
            const rightClickMenu = {
                id: 'reference-menu',
                adapter: (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label)),
                originPos: { y: e.clientY, x: e.clientX, },
                modalPos: { x: 0, y: 1 },
                onNodeClicked: (path, item) => {
                    var _a;
                    frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                    (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
                }
            };
            frontend_1.ModuleFE_MouseInteractivity.showMenu(rightClickMenu);
        };
        this.onDiseaseValueRightClick = (e, dv) => {
            if (!dv)
                return;
            const menuItems = [{
                    label: 'Remove Disease Value',
                    onClick: async () => {
                        const ids = [...this.state.rowLoadingIds, dv._id];
                        this.setState({ rowLoadingIds: ids });
                        await _entity_1.ModuleFE_DiseaseValue.v1.delete(dv).executeSync();
                        this.reDeriveState({
                            rowLoadingIds: ids.filter(i => i !== dv._id),
                            diseaseValues: this.state.diseaseValues.filter(i => i._id !== dv._id)
                        });
                    }
                }];
            const rightClickMenu = {
                id: 'disease-value-menu',
                adapter: (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label)),
                originPos: { y: e.clientY, x: e.clientX, },
                modalPos: { x: 0, y: 1 },
                onNodeClicked: (path, item) => {
                    var _a;
                    frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                    (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
                }
            };
            frontend_1.ModuleFE_MouseInteractivity.showMenu(rightClickMenu);
        };
        this.getMultiVarColumns = () => {
            const multiVar = this.state.multiVar;
            if (!(multiVar === null || multiVar === void 0 ? void 0 : multiVar.item))
                return [];
            return multiVar.item.variables;
        };
        this.getSortedDiseaseValues = () => {
            const mvvToRender = this.getMultiVarColumns();
            return (0, ts_common_1.sortArray)(this.state.diseaseValues, (dv => {
                const value = frontend_2.ModuleFE_Value.cache.unique(dv.values[mvvToRender[0].variableId]);
                if (!value)
                    return '';
                switch (value.type) {
                    case 'enum-element':
                        return value.data.value;
                    case 'range':
                    case 'sub-range':
                        return value.data.scope;
                    default:
                        return '';
                }
            }));
        };
        // ######################### Render - Values Tab #########################
        this.renderUnitsFromDbValue = (value) => {
            if ((value === null || value === void 0 ? void 0 : value.type) !== consts_1.valueType_Range && (value === null || value === void 0 ? void 0 : value.type) !== consts_1.valueType_StandardRange)
                return '';
            const unitId = value.data.unitsId;
            if (!unitId)
                return '';
            return ` (${this.state.measurementUnitsByIdMap[unitId].unitAsString})`;
        };
        this.renderNormalDistribution = (value) => {
            if ((value === null || value === void 0 ? void 0 : value.type) !== consts_1.valueType_StandardRange)
                return '';
            return React.createElement("span", Object.assign({ className: 'variable-info-tooltip-icon' }, frontend_1.openContent.tooltip.right('variable-info-tooltip', () => React.createElement("div", { className: 'variable-info-tooltip' },
                React.createElement(frontend_2.NormalValuesTable, { editable: false, value: value })), { contentHoverDelay: 200 })), "i");
        };
        this.renderTableHeader = (header) => {
            if (!this.state.multiVar)
                return '';
            //Not an id of a variable
            if (moreHeaders.includes(header))
                return React.createElement("div", { className: 'node-data', onContextMenu: e => this.onMultiVarRightClick(e) },
                    React.createElement("div", { className: 'variable-name' }, header));
            const index = header;
            const variablesArr = this.state.multiVar.item.variables || [];
            const variable = this.state.variableMap[this.state.multiVar.item.variables[index].variableId];
            if (!variable)
                throw new ts_common_1.BadImplementationException(`Can't find variable for id ${header}`);
            const value = this.state.valuesMap[variable.valueId];
            //Column is not an attribute
            if (!variablesArr[index].attributeeId)
                return React.createElement("div", { className: 'node-data', onContextMenu: e => this.onMultiVarRightClick(e, variablesArr[index]) },
                    React.createElement("div", { className: 'variable-name' },
                        `${variable.name}${this.renderUnitsFromDbValue(value)}`,
                        this.renderNormalDistribution(value)));
            const attributee = this.state.variableMap[variablesArr[index].attributeeId];
            return React.createElement("div", { className: 'node-data', onContextMenu: e => this.onMultiVarRightClick(e, variablesArr[index]) },
                React.createElement("div", { className: 'attributee' }, attributee.name),
                React.createElement("div", { className: 'variable-name' },
                    `${variable.name.replace(attributee.name, '').trim()}${this.renderUnitsFromDbValue(value)}`,
                    this.renderNormalDistribution(value)));
        };
    }
    // ######################### Life Cycle #########################
    __onDiseaseProfileUpdated(...params) {
        var _a;
        const dp = params[1];
        if ((dp === null || dp === void 0 ? void 0 : dp._id) === ((_a = this.state.diseaseProfile) === null || _a === void 0 ? void 0 : _a._id))
            this.reDeriveState({ diseaseProfile: dp, isFirstLoad: undefined });
    }
    __onMultiVarUpdated(...params) {
        var _a;
        if (![frontend_1.EventType_Update, frontend_1.EventType_Create, frontend_1.EventType_UpsertAll].includes(params[0]))
            return;
        const newMV = Array.isArray(params[1]) ? params[1][0] : params[1];
        if (newMV._id === ((_a = this.state.multiVar) === null || _a === void 0 ? void 0 : _a.item._id)) {
            //Re-derive because we have a bunch of stuff to calculate
            this.reDeriveState();
        }
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        const selected = this.getQueryParam('selected', {});
        state.diseaseProfile = _entity_1.ModuleFE_DiseaseProfile.cache.unique(selected[_entity_1.DBDef_DiseaseProfile.dbKey]);
        state.diseaseView = _entity_1.ModuleFE_DiseaseView.cache.unique(selected[_entity_1.DBDef_DiseaseView.dbKey]);
        state.selectedTabId = this.getQueryParam('diseaseValues_tabId');
        const selectedMVId = selected[_entity_1.DBDef_MultiVar.dbKey];
        const multiVar = _entity_1.ModuleFE_MultiVar.cache.unique(selectedMVId);
        if (selectedMVId && !multiVar)
            throw new ts_common_1.MUSTNeverHappenException(`Could not get MV for id ${selectedMVId}`);
        state.multiVar = multiVar ? new frontend_1.EditableDBItemV3(multiVar, _entity_1.ModuleFE_MultiVar) : undefined;
        (_a = state.rowLoadingIds) !== null && _a !== void 0 ? _a : (state.rowLoadingIds = []);
        (_b = state.diseaseValues) !== null && _b !== void 0 ? _b : (state.diseaseValues = []);
        state.valuesMap = {};
        const allVariables = frontend_2.ModuleFE_Variable.cache.all();
        state.variableMap = (0, ts_common_1.arrayToMap)(allVariables, ts_common_1.dbObjectToId);
        if (!state.multiVar || !state.diseaseProfile || !state.diseaseView)
            return state;
        state.valuesMap = (0, ts_common_1.arrayToMap)(frontend_2.ModuleFE_Value.cache.all(), ts_common_1.dbObjectToId);
        //Get ref data
        state.relatedReferences = frontend_2.ModuleFE_Reference.cache.filter(item => state.multiVar.item.referenceIds.includes(item._id));
        const variableIds = state.multiVar.item.variables.map(item => item.variableId);
        state.diseaseValues = (0, ts_common_1.sortArray)(_entity_1.ModuleFE_DiseaseValue.cache.filter(item => { var _a; return item._diseaseProfileId === ((_a = state === null || state === void 0 ? void 0 : state.diseaseProfile) === null || _a === void 0 ? void 0 : _a._id) && item.multiVarId === state.multiVar.item._id; }), ['__created']);
        //Push clean disease value in last spot
        const cleanValue = (0, _entity_1.getCleanDiseaseValue)(state.diseaseProfile._id, state.multiVar.item._id);
        state.diseaseValues.push(cleanValue);
        allVariables.filter(item => variableIds.includes(item._id)).forEach(variable => {
            state.valuesMap[variable._id] = state.valuesMap[variable.valueId];
        });
        if (state.isFirstLoad === undefined) {
            state.isFirstLoad = true;
        }
        state.measurementUnitsByIdMap = (0, ts_common_1.arrayToMap)(frontend_2.ModuleFE_MeasurementUnit.cache.all(), ts_common_1.dbObjectToId);
        return state;
    }
    // ######################### Render  #########################
    render() {
        if (!this.state.multiVar)
            return this.renderPlaceholder();
        return React.createElement(frontend_1.LL_V_L, { className: 'panel-disease-values-list' },
            this.renderHeader(),
            this.renderMain());
    }
    renderPlaceholder() {
        return React.createElement("div", { className: 'tab-placeholder' }, "Select a variable");
    }
    renderHeader() {
        var _a, _b, _c;
        return React.createElement(frontend_2.Panel_Header, { title: {
                label: ((_c = (_a = this.state.variableMap) === null || _a === void 0 ? void 0 : _a[((_b = this.state.multiVar) === null || _b === void 0 ? void 0 : _b.item.variables[0].variableId) || '']) === null || _c === void 0 ? void 0 : _c.name) || 'Values',
                className: 'header__title'
            }, qsearchId: 'disease-values-panel-search', config: { filter: this.state.filter }, onFilterChanged: (filterText) => this.setQueryParam('filterDiseaseValues', filterText) });
    }
    renderMain() {
        if (!this.state.multiVar)
            return React.createElement(frontend_2.QPageMessage, { message: 'Select a multi var' });
        const tabs = [
            { title: 'Values', uid: 'values', content: this.renderValues() },
            { title: 'References', uid: 'references', content: this.renderReferences() },
        ];
        return React.createElement(frontend_1.TS_Tabs, { tabs: tabs, selectedTabId: this.state.selectedTabId, onSelected: (tabId) => this.setQueryParam('diseaseValues_tabId', tabId) });
    }
    renderValues() {
        const mvvToRender = this.getMultiVarColumns();
        let diseaseValueRows = this.getSortedDiseaseValues();
        if (this.state.filter) {
            const filter = new ts_common_1.Filter(item => {
                // this.logWarning(item);
                const innerValueIds = (0, ts_common_1._values)(item.values);
                const innerAttributeValueIds = (0, ts_common_1.flatArray)((0, ts_common_1._values)(item.attributes).map(item => (0, ts_common_1._values)(item)));
                const enumElements = frontend_2.ModuleFE_Value.cache.filter(value => value.type === 'enum-element' && [...innerValueIds, ...innerAttributeValueIds].includes(value._id));
                return [...enumElements.map(value => value.data.value)];
            });
            diseaseValueRows = filter.filterSort(diseaseValueRows, this.state.filter);
        }
        const cleanDiseaseValue = diseaseValueRows.find(dv => !dv._id);
        (0, ts_common_1.removeItemFromArray)(diseaseValueRows, cleanDiseaseValue);
        if (cleanDiseaseValue)
            diseaseValueRows.push(cleanDiseaseValue);
        return React.createElement(frontend_2.QScrollWrapper, null,
            React.createElement(frontend_1.TS_Table, { header: [
                    ...mvvToRender.map((header, i) => ({ header: `${i}`, widthPx: 200 })),
                    ...moreHeaders.map(header => ({ header, widthPx: (header === 'likelihood' ? 240 : 100) }))
                ], headerRenderer: this.renderTableHeader, rows: diseaseValueRows, cellRenderer: (prop, item, index) => {
                    switch (prop) {
                        case 'likelihood':
                            return this.renderLikelihoodCell(item);
                        case 'severity':
                            return this.renderSeverityCell(item);
                        case 'indicative':
                            return this.renderIndicativeCell(item);
                        default:
                            return this.renderValueCell(item, mvvToRender[+prop]);
                    }
                }, tr: row => ({
                    className: (0, frontend_1._className)(row && this.state.rowLoadingIds.includes(row._id ? row._id : 'newDV') ? 'loading' : undefined),
                    onContextMenu: e => {
                        if (row === null || row === void 0 ? void 0 : row._id)
                            this.onDiseaseValueRightClick(e, row);
                    }
                }) }));
    }
    renderValueCell(dv, mvv) {
        var _a, _b;
        let innerValueIds = [];
        const variable = frontend_2.ModuleFE_Variable.cache.unique(mvv.variableId);
        if (!variable)
            throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for id ${mvv.variableId}`);
        let dbValue = this.state.valuesMap[mvv.variableId];
        if ((dbValue === null || dbValue === void 0 ? void 0 : dbValue.type) === consts_1.valueType_StandardRange) {
            dbValue = this.state.valuesMap[consts_1.ValType_IND._id];
        }
        if (!dbValue)
            return '';
        switch (dbValue.type) {
            case consts_1.valueType_Range:
                innerValueIds = dbValue.data.subsets;
                break;
            case consts_1.valueType_Enumerated:
                innerValueIds = dbValue.data.scope;
                break;
            case consts_1.valueType_Tuple:
                innerValueIds = dbValue.data.scope;
                break;
            case frontend_2.valueType_Time:
                innerValueIds = dbValue.data.timeRanges;
                break;
        }
        return React.createElement(frontend_2.DropDown_Values, { selected: !mvv.attributeeId ? dv.values[mvv.variableId] : (_b = (_a = dv.attributes) === null || _a === void 0 ? void 0 : _a[mvv.variableId]) === null || _b === void 0 ? void 0 : _b[mvv.attributeeId], queryFilter: item => innerValueIds.includes(item._id), onSelected: value => this.onDiseaseValueSelected(dv, mvv, value === null || value === void 0 ? void 0 : value._id), variable: variable, canUnselect: true });
    }
    renderLikelihoodCell(dv) {
        return React.createElement(frontend_1.LL_H_C, { className: 'likelihood-cell' },
            React.createElement(frontend_2.QRangeInput, { placeholder: 'Likelihood Range', value: dv.likelihoodRange, onValueBlur: val => this.onDiseaseLikelihoodRangeUpdated(dv, val) }),
            React.createElement(_enum_1.DropDown_Likelihood.selectable, { selected: dv.likelihood, onSelected: likelihood => this.onDiseaseLikelihoodSelected(dv, likelihood), canUnselect: true, placeholder: 'Likelihood' }),
            React.createElement(_enum_1.DropDown_LikelihoodDirection.selectable, { selected: dv.likelihoodDirection, canUnselect: true, onSelected: dir => this.onDiseaseLikelihoodDirectionSelected(dv, dir), placeholder: 'Direction' }));
    }
    renderSeverityCell(dv) {
        return React.createElement(_enum_1.DropDown_Severity.selectable, { selected: dv.severityFactor, onSelected: severity => this.onDiseaseSeveritySelected(dv, severity), canUnselect: true, placeholder: 'Severity' });
    }
    renderIndicativeCell(dv) {
        const adapter = (0, frontend_1.SimpleListAdapter)([..._entity_1.indicativeOptions], item => React.createElement("div", { className: 'node-data' }, item.item.label));
        return React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: _entity_1.indicativeOptions.find(item => item.value === dv.indicative), onSelected: option => this.onIndicativeSelected(dv, option), canUnselect: true });
    }
    // ######################### Render - References Tab #########################
    renderReferences() {
        return React.createElement(frontend_2.QScrollWrapper, null,
            React.createElement(frontend_1.TS_Table, { header: [{ header: 'type', widthPx: 100 }, 'data'], headerRenderer: header => React.createElement("div", { className: 'node-data' }, header), rows: this.state.relatedReferences, cellRenderer: (prop, item, index) => {
                    switch (prop) {
                        case 'type':
                            return React.createElement("div", null, item.type);
                        case 'data':
                            if (item.type === 'book') {
                                return React.createElement("div", null, (0, frontend_2.resolveReferenceDisplay)(item));
                            }
                            return React.createElement("div", null, (0, frontend_2.resolveReferenceDisplay)(item));
                        default:
                            return React.createElement("div", null, "ERROR");
                    }
                }, tr: (row) => ({
                    onContextMenu: (e) => this.onReferenceRightClick(e, row),
                }) }),
            React.createElement(frontend_2.HCSDropDowns.Reference, { queryFilter: ref => (this.state.diseaseProfile.referenceIds.includes(ref._id) && !this.state.multiVar.item.referenceIds.includes(ref._id)), renderer: item => {
                    if (item.type === 'book') {
                        return React.createElement("div", { className: 'node-data' }, (0, frontend_2.resolveReferenceDisplay)(item));
                    }
                    return React.createElement("div", { className: 'node-data' }, (0, frontend_2.resolveReferenceDisplay)(item));
                }, selected: undefined, onSelected: this.onReferenceSelect }));
    }
}
Panel_DiseaseValueList.defaultProps = {
    keys: ['selected', 'filterDiseaseValues', 'diseaseValues_tabId'],
};
exports.Panel_DiseaseValueList = Panel_DiseaseValueList;
