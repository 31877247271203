"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_SingleAtomLogics = exports.Props_SingleAtomTermLogic = exports.DropDown_DeltaTypes = exports.Props_DeltaTypesDropdown = exports.DropDown_Expression = exports.Props_ExpressionDropdown = exports.DropDown_SampleIndex = exports.DropDown_Variables = exports.DropDown_ExpressionOutputTypes = exports.DropDown_ExpressionType = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const consts_1 = require("../shared/consts");
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const consts_2 = require("@app/styles/frontend/ui/consts");
const ui_components_1 = require("../../variable/frontend/ui-components");
const frontend_2 = require("../../../frontend");
const RenderLabels = (0, ts_common_1.reduceToMap)(consts_1.BuilderExpressionTypes, (type) => type.dbKey, (type) => type.label);
const ExpressionTypesAdapter = (0, frontend_1.SimpleListAdapter)((0, ts_common_1._keys)(RenderLabels), (item) => React.createElement(React.Fragment, null, RenderLabels[item.item]));
const mandatoryProps_expressionType = {
    adapter: ExpressionTypesAdapter,
    placeholder: 'Select Expression Type',
    caret: consts_2.DropDown_DefaultCaret
};
exports.DropDown_ExpressionType = frontend_1.TS_DropDown.prepareEditable(mandatoryProps_expressionType);
const outputTypeAdapter = (0, frontend_1.SimpleListAdapter)(consts_1.Expression_OutputTypes, (item) => React.createElement(React.Fragment, null, item.item));
const mandatoryProps_outputType = {
    placeholder: 'Select output type',
    caret: consts_2.DropDown_DefaultCaret,
    adapter: outputTypeAdapter
};
exports.DropDown_ExpressionOutputTypes = frontend_1.TS_DropDown.prepare(mandatoryProps_outputType);
const mandatoryProps = Object.assign(Object.assign({}, ui_components_1.Props_VariableDropDownV3), { renderer: (item) => React.createElement(React.Fragment, null, item.name) });
exports.DropDown_Variables = frontend_1.GenericDropDownV3.prepareEditable(mandatoryProps);
const mandatoryProps_SampleIndex = {
    adapter: (0, frontend_1.SimpleListAdapter)(consts_1.SampleIndex_All, item => React.createElement(React.Fragment, null, item.item)),
    placeholder: 'Select Test Sample',
    caret: consts_2.DropDown_DefaultCaret,
    className: 'sample-index-dropdown'
};
exports.DropDown_SampleIndex = frontend_1.TS_DropDown.prepare(mandatoryProps_SampleIndex);
exports.Props_ExpressionDropdown = {
    module: frontend_2.ModuleFE_Expression,
    modules: [frontend_2.ModuleFE_Expression],
    mapper: item => [item.label],
    placeholder: 'Choose an Expression',
    renderer: item => React.createElement(React.Fragment, null, item.label),
    caret: consts_2.DropDown_DefaultCaret,
};
exports.DropDown_Expression = frontend_1.GenericDropDownV3.prepare(exports.Props_ExpressionDropdown);
exports.Props_DeltaTypesDropdown = {
    caret: consts_2.DropDown_DefaultCaret,
    adapter: (0, frontend_1.SimpleListAdapter)(consts_1.DeltaTypes, item => React.createElement(React.Fragment, null, item.item)),
    placeholder: 'Delta Type',
    className: 'operator-dropdown'
};
exports.DropDown_DeltaTypes = frontend_1.TS_DropDown.prepare(exports.Props_DeltaTypesDropdown);
exports.Props_SingleAtomTermLogic = {
    caret: consts_2.DropDown_DefaultCaret,
    adapter: (0, frontend_1.SimpleListAdapter)(consts_1.SingleAtomLogics, item => React.createElement(React.Fragment, null, item.item)),
    placeholder: 'Term Logic',
};
exports.DropDown_SingleAtomLogics = frontend_1.TS_DropDown.prepare(exports.Props_SingleAtomTermLogic);
