"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertExpression = void 0;
const consts_1 = require("../../../../_entity/expression/shared/consts");
/**
 * Converts a database expression level to a JSON schema representation.
 * @param expression - The basic database expression to be converted.
 * @param dependencies all expression dependencies
 * @returns The converted expression in AI team schema format.
 */
const convertExpression = (expression, dependencies) => {
    const convertedExpression = {
        name: expression.label,
        expression: expression.expression,
        terms: {},
    };
    if (expression_isClinicalFunction(expression, convertedExpression)) {
        setCommonProperties(expression, convertedExpression, 'clinical_function', 'boolean', dependencies);
    }
    else if (expression_isScore(expression, convertedExpression)) {
        setCommonProperties(expression, convertedExpression, 'score', 'number', dependencies);
    }
    else if (expression_isNormalRange(expression, convertedExpression)) {
        // @ts-ignore FIXME: type generation fails here!
        setCommonProperties(expression, convertedExpression, 'normal_range', 'tuple', dependencies);
    }
    else if (expression_isResolutionFunction(expression, convertedExpression))
        setCommonProperties(expression, convertedExpression, 'resolution_function', 'boolean', dependencies);
    return convertedExpression;
};
exports.convertExpression = convertExpression;
/**
 * Sets common properties for different expression types.
 * @param expression - The database expression.
 * @param convertedExpression - The converted expression.
 * @param expressionType - The type of the expression.
 * @param outputType - The output type of the expression.
 * @param dependencies all expression dependencies
 */
const setCommonProperties = (expression, convertedExpression, expressionType, outputType, dependencies) => {
    var _a, _b;
    convertedExpression.expression_type = expressionType;
    convertedExpression.output_type = outputType;
    convertedExpression.db_id = expression._id;
    convertedExpression._id = expression._id;
    // Assign the variable data to the expression
    Object.assign(convertedExpression, findAssociatedVariable(expression, dependencies));
    // Add expression description only if exists
    convertedExpression.description = (_b = (_a = expression.description) === null || _a === void 0 ? void 0 : _a.replace(/[\\\n\t]/g, '')) !== null && _b !== void 0 ? _b : '';
};
/**
 * Finds the associated variable data for a given expression.
 * @param expression - The database expression.
 * @param dependencies all expression dependencies
 * @returns The associated variable data.
 * @throws {MUSTNeverHappenException} Throws an exception if the associated variable is not found.
 */
const findAssociatedVariable = (expression, dependencies) => {
    var _a;
    const associatedVar = dependencies.variables.find(_var => _var._id === expression.associatedVar);
    return {
        // TODO: redundant, should make sure with Dan REMOVE WHEN POSSIBLE
        snomed_id: 'MISSING!',
        associated_var_id: (_a = associatedVar === null || associatedVar === void 0 ? void 0 : associatedVar._id) !== null && _a !== void 0 ? _a : null
    };
};
/**
 * Checks if the given expression is of type ClinicalFunction.
 * @param expression - The database expression.
 * @param convertedExpression - The converted expression.
 * @returns True if the expression is of type ClinicalFunction, otherwise false.
 */
const expression_isClinicalFunction = (expression, convertedExpression) => {
    return expression.expressionType === consts_1.ExpressionType_ClinicalFunction.dbKey || expression.expressionType === consts_1.ExpressionType_GeneralFunction.dbKey;
};
/**
 * Checks if the given expression is of type Score.
 * @param expression - The database expression.
 * @param convertedExpression - The converted expression.
 * @returns True if the expression is of type Score, otherwise false.
 */
const expression_isScore = (expression, convertedExpression) => {
    return expression.expressionType === consts_1.ExpressionType_Score.dbKey || expression.expressionType === consts_1.ExpressionType_GeneralScore.dbKey;
};
/**
 * Checks if the given expression is of type NormalRange.
 * @param expression - The database expression.
 * @param convertedExpression - The converted expression.
 * @returns True if the expression is of type NormalRange, otherwise false.
 */
const expression_isNormalRange = (expression, convertedExpression) => {
    return expression.expressionType === consts_1.ExpressionType_NormalRange.dbKey;
};
/**
 * Checks if the given expression is of type NormalRange.
 * @param expression - The database expression.
 * @param convertedExpression - The converted expression.
 * @returns True if the expression is of type NormalRange, otherwise false.
 */
const expression_isResolutionFunction = (expression, convertedExpression) => {
    return expression.expressionType === consts_1.ExpressionType_ResolutionFunction.dbKey;
};
