"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Value = exports.ModuleFE_Value_Class = exports.dispatch_onValueChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("./shared");
const ts_common_1 = require("@nu-art/ts-common");
const resolve_value_display_1 = require("../shared/resolve-value-display");
const passingTypes = [shared_1.valueType_SubRange, shared_1.valueType_EnumElement, shared_1.valueType_TimeRange];
exports.dispatch_onValueChanged = new types_1.ThunderDispatcherV3('__onValueUpdated');
class ModuleFE_Value_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Value, exports.dispatch_onValueChanged);
        this.__searchFilterMapper = (item) => {
            if (!['enum-element', 'sub-range'].includes(item.type))
                return [];
            return [(0, resolve_value_display_1.resolveValueDisplay)(item)];
        };
        this.__searchGroups = (items, filter) => {
            const validValues = items.filter(value => ['enum-element', 'sub-range'].includes(value.type));
            return [{
                    group: this.dbDef.entityName,
                    results: validValues.map(value => ({ _id: value._id, uiLabel: (0, resolve_value_display_1.resolveValueDisplay)(value) }))
                }];
        };
        this.uiValues = undefined;
        this.values = undefined;
        this._v1 = {};
        // @ts-ignore
        this[this.defaultDispatcher.method] = async () => {
            this.values = this.cache.all();
        };
    }
    getValues() {
        if (!this.values)
            this.values = this.cache.all();
        return this.values;
    }
    async getUIValues() {
        if (!this.uiValues)
            this.uiValues = await this.toUIValues(this.cache.all());
        return this.uiValues;
    }
    toUIValues(values) {
        return values.map(value => {
            return {
                _id: value._id,
                _v: value._v,
                __created: value.__created,
                __updated: value.__created,
                label: value.label,
                type: value.type,
            };
        });
    }
    getRelatedValues(variable) {
        const dbValues = this.cache.all();
        const compoundValue = dbValues.find(item => item._id === variable.valueId);
        if (!compoundValue)
            return undefined;
        let values = [];
        if (compoundValue.type === shared_1.valueType_Time)
            values = compoundValue.data.timeRanges.map(valueId => dbValues.find(item => item._id === valueId));
        else
            values = compoundValue.data[compoundValue.type === shared_1.valueType_Range ? 'subsets' : 'scope'].map(valueId => dbValues.find(item => item._id === valueId));
        while (!values.every(item => passingTypes.includes(item.type))) {
            values = (0, ts_common_1.flatArray)(values.map(value => {
                if (passingTypes.includes(value.type))
                    return value;
                return value.data[compoundValue.type === shared_1.valueType_Range ? 'subsets' : 'scope'].map(valueId => dbValues.find(item => item._id === valueId));
            }));
        }
        let variableType = 'normal';
        if (values.every(value => value.type === shared_1.valueType_SubRange))
            variableType = 'ranges';
        if (values.every(value => value.type === shared_1.valueType_EnumElement))
            variableType = 'enums';
        if (values.every(value => value.type === shared_1.valueType_TimeRange))
            variableType = 'time';
        return { variableType, values };
    }
}
exports.ModuleFE_Value_Class = ModuleFE_Value_Class;
exports.ModuleFE_Value = new ModuleFE_Value_Class();
