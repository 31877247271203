"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_ExpressionsOld = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const shared_1 = require("../../../_entity/shared");
const Validator_Expression = {
    op: (0, ts_common_1.tsValidateValue)(consts_1.AllOperands),
    params: ts_common_1.tsValidateMustExist
};
exports.DBDef_ExpressionsOld = {
    validator: Validator_Expression,
    dbKey: 'expressions',
    dbGroup: shared_1.knowledgeManagerDBGroup,
    entityName: 'expression',
    versions: ['1.0.0'],
};
