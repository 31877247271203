"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListOfFHIRQueryResources = exports.FHIR_CreateResourceExtractors = void 0;
const consts_1 = require("./types/Observation/consts");
const consts_2 = require("./types/Condition/consts");
const search_extractor_1 = require("./const-extractors/search/search-extractor");
const consts_3 = require("./types/Procedure/consts");
const consts_4 = require("./types/DocumentReference/consts");
const consts_5 = require("./types/List/consts");
const consts_6 = require("./types/MedicationRequest/consts");
exports.FHIR_CreateResourceExtractors = {
    AllergyIntolerance: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    Binary: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    Condition: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    DocumentReference: undefined,
    FamilyMemberHistory: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    Immunization: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    List: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    MedicationRequest: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    Observation: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
    Procedure: search_extractor_1.FHIR_CreateRequest_Search_Extractor,
};
exports.ListOfFHIRQueryResources = {
    AllergyIntolerance: {
        patient: {
            type: 'dynamic', // dynamic means given on runtime, can't be hardcoded.
        },
        encounter: {
            type: 'dynamic',
        },
    },
    Binary: {
        _id: {
            type: 'dynamic'
        },
        patient: {
            type: 'dynamic'
        },
        encounter: {
            type: 'dynamic'
        }
    },
    Condition: {
        category: {
            type: 'mandatory',
            values: consts_2.FHIR_Condition_CategoryOptions
        },
        patient: {
            type: 'dynamic',
        },
        encounter: {
            type: 'dynamic',
        },
    },
    DocumentReference: {
        category: {
            type: 'mandatory',
            values: consts_4.FHIR_DocumentReference_CategoryOptions
        },
        docstatus: {
            type: 'mandatory',
            values: consts_4.FHIR_DocumentReference_DocStatusOptions
        },
        type: {
            type: 'optional',
            values: consts_4.FHIR_DocumentReference_TypeOptions
        },
        patient: {
            type: 'dynamic',
        },
        encounter: {
            type: 'dynamic',
        },
    },
    FamilyMemberHistory: {
        patient: {
            type: 'dynamic',
        }
    },
    Immunization: {
        patient: {
            type: 'dynamic',
        },
        encounter: {
            type: 'dynamic',
        },
    },
    List: {
        code: {
            type: 'optional',
            values: consts_5.FHIR_List_CodeOptions
        },
        patient: {
            type: 'dynamic',
        },
        encounter: {
            type: 'dynamic',
        },
    },
    MedicationRequest: {
        category: {
            type: 'optional',
            values: consts_6.FHIR_MedicationRequest_CategoryOptions
        },
        status: {
            type: 'optional',
            values: consts_6.FHIR_MedicationRequest_StatusOptions
        },
        patient: {
            type: 'dynamic',
        },
        encounter: {
            type: 'dynamic',
        },
    },
    Observation: {
        category: {
            type: 'optional',
            values: consts_1.FHIR_Observation_CategoryOptions
        },
        code: {
            type: 'optional',
            values: consts_1.FHIR_Observation_StatusOptions
        },
        patient: {
            type: 'dynamic',
        },
        encounter: {
            type: 'dynamic',
        },
    },
    Procedure: {
        category: {
            type: 'mandatory',
            values: consts_3.FHIR_Procedure_CategoryOptions
        },
        patient: {
            type: 'dynamic',
        },
        encounter: {
            type: 'dynamic',
        },
    },
};
