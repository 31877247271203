"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_IncidenceEditor = void 0;
const React = __importStar(require("react"));
const Layouts_1 = require("@nu-art/thunderstorm/frontend/components/Layouts/Layouts");
const TS_Input_1 = require("@nu-art/thunderstorm/frontend/components/TS_Input/TS_Input");
const ts_common_1 = require("@nu-art/ts-common");
const Adapter_1 = require("@nu-art/thunderstorm/frontend/components/adapter/Adapter");
const frontend_1 = require("@app/hcs/frontend");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
class Component_IncidenceEditor extends frontend_2.ComponentSync {
    constructor() {
        super(...arguments);
        this.updateProfileIncidence = (incidence) => {
            const newIncidence = { numerator: incidence.numerator, denominator: incidence.denominator };
            if (incidence.numerator)
                newIncidence.denominator = Math.max(incidence.numerator[1], incidence.denominator);
            this.setState({ incidence: newIncidence }, () => {
                var _a;
                if (!incidence || !incidence.denominator)
                    return;
                if (Number.isNaN(incidence.denominator))
                    return;
                this.props.onChanged({
                    numerator: incidence.numerator,
                    denominator: ((_a = incidence.numerator) === null || _a === void 0 ? void 0 : _a[1]) !== undefined ? Math.max(incidence.numerator[1], incidence.denominator) : incidence.denominator
                });
            });
        };
        this.deleteIncidence = () => {
            this.props.onChanged(undefined);
            this.setState({ incidence: { numerator: undefined, denominator: 100000 } });
        };
        this.renderNumerator = () => {
            if (this.props.disableEdit)
                return this.state.incidence.numerator
                    ? (this.state.incidence.numerator[0] === this.state.incidence.numerator[1]
                        ? React.createElement("div", null, this.state.incidence.numerator[0])
                        : React.createElement("div", null,
                            this.state.incidence.numerator[0],
                            " - ",
                            this.state.incidence.numerator[1]))
                    : '';
            return React.createElement(frontend_1.QRangeInput, { placeholder: 'Numerator Range', value: this.state.incidence.numerator, onValueBlur: val => this.updateProfileIncidence({ numerator: val, denominator: this.state.incidence.denominator }) });
        };
        this.renderDenominator = () => {
            if (this.props.disableEdit)
                return this.state.incidence.denominator ? React.createElement("div", null, this.state.incidence.denominator) : '';
            return React.createElement(TS_Input_1.TS_Input, { type: 'number', placeholder: 'Denominator', value: this.state.incidence.denominator ? `${this.state.incidence.denominator}` : undefined, onBlur: value => {
                    if (Number.isNaN(value))
                        return;
                    let numberToInput = Number.parseInt(value);
                    if (this.state.incidence.numerator)
                        numberToInput = Math.max(this.state.incidence.numerator[1], numberToInput);
                    this.updateProfileIncidence({ numerator: this.state.incidence.numerator, denominator: numberToInput });
                } });
        };
    }
    deriveStateFromProps(nextProps, state) {
        const propIncidence = nextProps.incidence ? (0, ts_common_1.cloneObj)(nextProps.incidence) : undefined;
        state.incidence = state.incidence || {};
        state.incidence.numerator = state.incidence.numerator || (propIncidence === null || propIncidence === void 0 ? void 0 : propIncidence.numerator);
        state.incidence.denominator = state.incidence.denominator || (propIncidence === null || propIncidence === void 0 ? void 0 : propIncidence.denominator) || 100000; // denominator default value is 100,000.
        return state;
    }
    render() {
        return React.createElement(Layouts_1.LL_V_L, { className: 'match_width', onContextMenu: (e) => {
                if (this.props.disableEdit)
                    return;
                const menuItems = [
                    { label: 'Remove', onClick: () => this.deleteIncidence() },
                ];
                const rightClickMenu = {
                    id: 'prognosis-right-click-menu',
                    adapter: (0, Adapter_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label)),
                    originPos: { y: e.clientY, x: e.clientX, },
                    modalPos: { x: 0, y: 1 },
                    onNodeClicked: (path, item) => {
                        var _a;
                        frontend_2.ModuleFE_MouseInteractivity.hide(frontend_2.mouseInteractivity_PopUp);
                        (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
                    }
                };
                frontend_2.ModuleFE_MouseInteractivity.showMenu(rightClickMenu);
            } },
            React.createElement(Layouts_1.LL_H_C, null,
                this.renderNumerator(),
                React.createElement("span", { className: 'dialog__field-title', style: { paddingInline: '8px' } }, "out of"),
                this.renderDenominator()));
    }
}
exports.Component_IncidenceEditor = Component_IncidenceEditor;
