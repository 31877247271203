"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_EditChildrenOrder = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Dialog_EditChildrenOrder.scss");
const ts_common_1 = require("@nu-art/ts-common");
const _shared_1 = require("../../../_shared");
const Tooltip_Information_1 = require("../../shared-components/Tooltip_Information");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../_entity");
class Dialog_EditChildrenOrder extends frontend_1.TS_Dialog {
    constructor() {
        // ######################### Class Variables #########################
        super(...arguments);
        this.draggedItemIndex = { current: null };
        this.draggedOverItemIndex = { current: null };
        // ######################### Logic #########################
        this.canBeSaved = () => {
            return true;
        };
        this.handleSave = async () => {
            const config = _entity_1.ConfigKeyFE_TagsOrder.get() || {};
            config[this.props.parentCategoryId] = this.state.categories.map(category => category._id);
            await _entity_1.ConfigKeyFE_TagsOrder.set(config);
            frontend_1.ModuleFE_Dialog.close();
        };
        this.onDragStart = (e, rowIndex) => {
            this.draggedItemIndex.current = rowIndex;
        };
        this.onDragEnter = (e, rowIndex) => {
            if (this.draggedItemIndex.current === null)
                return;
            this.draggedOverItemIndex.current = rowIndex;
            const listCopy = [...this.state.categories];
            const draggingItemContent = listCopy[this.draggedItemIndex.current];
            listCopy.splice(this.draggedItemIndex.current, 1);
            listCopy.splice(this.draggedOverItemIndex.current, 0, draggingItemContent);
            this.draggedItemIndex.current = this.draggedOverItemIndex.current;
            this.draggedOverItemIndex.current = null;
            this.setState({ categories: listCopy });
        };
        this.onDragEnd = (e) => {
            this.draggedItemIndex = { current: null };
            this.forceUpdate();
        };
        // ######################### Render #########################
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'header__title' }, "Edit Categories Order"),
                React.createElement("div", { className: 'header__buttons' },
                    React.createElement(Tooltip_Information_1.Tooltip_Information, { title: 'Edit Categories Order', paragraphs: ['Hold click and drag any category to position it.', 'Click save when done.'] }),
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => frontend_1.ModuleFE_Dialog.close() })));
        };
        this.renderBody = () => {
            var _a;
            if (!((_a = this.state) === null || _a === void 0 ? void 0 : _a.categories))
                return '';
            return React.createElement(React.Fragment, null, this.state.categories.map((category, i) => {
                const className = (0, frontend_1._className)('item', i === this.draggedItemIndex.current ? 'dragged' : undefined);
                return React.createElement("div", { key: i, className: className, draggable: 'true', onDragStart: (e) => this.onDragStart(e, i), onDragOver: (e) => e.preventDefault(), onDragEnter: (e) => this.onDragEnter(e, i), onDragEnd: this.onDragEnd },
                    i + 1,
                    ". ",
                    category.label);
            }));
        };
        this.buttons = () => {
            return {
                right: [
                    { content: 'Cancel', onClick: () => this.closeDialog() },
                    {
                        content: 'Save',
                        onClick: this.handleSave,
                        renderer: frontend_1.TS_Dialog.busyButton,
                        disabled: !this.canBeSaved(),
                        className: this.canBeSaved() ? 'desired-action-button' : undefined,
                    }
                ]
            };
        };
    }
    static show(parentCategoryId = _shared_1.RootTag) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_EditChildrenOrder, { parentCategoryId: parentCategoryId }), () => true);
    }
    // ######################### Life Cycle #########################
    deriveStateFromProps(nextProps, state) {
        const config = _entity_1.ConfigKeyFE_TagsOrder.get();
        if (nextProps.parentCategoryId === _shared_1.RootTag) {
            state.categories = _entity_1.ModuleFE_Tag.cache.filter(cat => cat.tagIds.length === 0);
        }
        else {
            state.categories = _entity_1.ModuleFE_Tag.cache.filter(cat => cat.tagIds[cat.tagIds.length - 1] === nextProps.parentCategoryId);
        }
        if (config) {
            const childrenOrder = config[nextProps.parentCategoryId];
            if (childrenOrder)
                state.categories = (0, ts_common_1.sortArray)(state.categories, (category) => childrenOrder.indexOf(category._id));
        }
        return state;
    }
}
// ######################### Static #########################
Dialog_EditChildrenOrder.defaultProps = {
    dialogId: 'dialog-edit-children-order',
};
exports.Dialog_EditChildrenOrder = Dialog_EditChildrenOrder;
