"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVariableType = exports.getRelatedValues = exports.findValueMatch = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const enumOrTuple = [consts_1.valueType_Enumerated, consts_1.valueType_Tuple];
const findValueMatch = (dbVariable, rawValue, dbValues, optionalDefaultValue) => {
    if (!dbVariable) {
        ts_common_1.StaticLogger.logError('No variable given!');
        return;
    }
    //Get variable values
    let relatedValues;
    try {
        relatedValues = (0, exports.getRelatedValues)(dbVariable, dbValues);
    }
    catch (e) {
        ts_common_1.StaticLogger.logError('no item!!!!!!!!!!!!!!!!!!!!!!!', dbVariable);
        throw e;
    }
    const variableType = (0, exports.getVariableType)(relatedValues);
    //Convert rawValue from string to number if it is a number
    if (!Number.isNaN(Number(rawValue)))
        rawValue = Number(rawValue);
    //rawValue is a number
    if (typeof rawValue === 'number') {
        //If variable doesn't hold ranges
        if (variableType === 'enums') {
            ts_common_1.StaticLogger.logError('Entered a number when there are no ranges under this variable');
            return;
        }
        //If variable holds only ranges
        if (variableType === 'ranges') {
            for (const item of relatedValues) {
                const [min, max] = item.data.scope;
                if (rawValue >= min && rawValue <= max)
                    return item;
            }
            //value not in any of the ranges
            ts_common_1.StaticLogger.logError('Value not in ranges');
            return;
        }
        if (variableType === 'standard') {
            const standardVal = relatedValues[0];
            if (rawValue < standardVal.data.normalValues[0].range[0])
                return dbValues.find(i => i._id === consts_1.StaticValue_Decreased._id);
            if (rawValue > standardVal.data.normalValues[0].range[1])
                return dbValues.find(i => i._id === consts_1.StaticValue_Increased._id);
            return dbValues.find(i => i._id === consts_1.StaticValue_Normal._id);
        }
        //if variable is normal
        const rangeValues = relatedValues === null || relatedValues === void 0 ? void 0 : relatedValues.filter(item => item.type === consts_1.valueType_SubRange);
        //if lower than the first range min
        if (rawValue < rangeValues[0].data.scope[0])
            return relatedValues === null || relatedValues === void 0 ? void 0 : relatedValues[0];
        //if higher than the last range max
        if (rawValue > rangeValues[rangeValues.length - 1].data.scope[1])
            return relatedValues === null || relatedValues === void 0 ? void 0 : relatedValues[relatedValues.length - 1];
        //find the range it's in
        for (const item of rangeValues) {
            const [min, max] = item.data.scope;
            if (rawValue >= min && rawValue <= max)
                return item;
        }
        ts_common_1.StaticLogger.logError('Value not in ranges');
        return;
    }
    //rawValue is a string
    //If length is 3 or less, find exact match
    if (rawValue.length <= 3) {
        let match;
        try {
            match = relatedValues.find(item => item.data.value.toLowerCase() === rawValue.toLowerCase());
        }
        catch (e) {
            ts_common_1.StaticLogger.logError('caught error', 'rawValue', rawValue, 'relatedValues', relatedValues);
        }
        if (!match) {
            ts_common_1.StaticLogger.logError('Value does not match any related value');
            return;
        }
    }
    //Find the highest scoring match with levenshtein algorithm
    const threshold = 70;
    const options = [];
    for (const item of relatedValues) {
        let valueToCheck = '';
        if (item.type === consts_1.valueType_EnumElement)
            valueToCheck = item.data.value.toLowerCase();
        if (item.type === consts_1.valueType_SubRange)
            valueToCheck = item.data.label.toLowerCase();
        const maxLen = valueToCheck.length > rawValue.length ? valueToCheck.length : rawValue.length;
        const levenshteinScore = (0, ts_common_1.levenshteinDistance)(valueToCheck, rawValue.toLowerCase());
        const score = (1 - (levenshteinScore / maxLen)) * 100;
        if (score >= threshold)
            options.push([item, score]);
    }
    if (!options.length) {
        ts_common_1.StaticLogger.logError(`Raw value ${rawValue} does not match any related value for variable ${dbVariable.name}`);
        return;
    }
    return options.reduce((prev, curr) => {
        return prev[1] < curr[1] ? curr : prev;
    })[0];
};
exports.findValueMatch = findValueMatch;
const getRelatedValues = (variable, dbValues) => {
    const compoundValue = dbValues.find(item => item._id === variable.valueId);
    if (!compoundValue)
        return [];
    if (compoundValue.type === consts_1.valueType_Range)
        return compoundValue.data.subsets.map(id => dbValues.find(i => i._id === id));
    if (enumOrTuple.includes(compoundValue.type))
        return compoundValue.data.scope.map(id => dbValues.find(i => i._id === id));
    if (compoundValue.type === consts_1.valueType_StandardRange)
        return [compoundValue];
    ts_common_1.StaticLogger.logError('can\'t return sub values for value:', compoundValue);
    return [];
};
exports.getRelatedValues = getRelatedValues;
const getVariableType = (relatedValues) => {
    if (relatedValues.every(value => value.type === consts_1.valueType_SubRange))
        return 'ranges';
    if (relatedValues.every(value => value.type === consts_1.valueType_EnumElement))
        return 'enums';
    return 'standard';
};
exports.getVariableType = getVariableType;
