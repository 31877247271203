"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_PathwayEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@app/hcs/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const Header_Pathway_1 = require("../../../headers/Header_Pathway");
const Header_WithOptionMenu_1 = require("@app/styles/frontend/ui-manager/headers/Header_WithOptionMenu");
const consts_1 = require("@app/styles/frontend/ui/consts");
const _entity_1 = require("../../../../../_entity");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const utils_1 = require("../../../utils");
const consts_2 = require("../../../../../../_entity/pathway/shared/consts");
class Renderer_PathwayEditor extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        this.canSave = () => {
            const pathway = this.props.editable.item;
            return !!pathway.variableId && !!pathway.type;
        };
        this.onSave = async () => {
            if (!this.canSave())
                return;
            const pathway = this.props.editable;
            const variable = frontend_2.ModuleFE_Variable.cache.unique(pathway.item.variableId);
            if (!variable)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for variableId ${pathway.item.variableId} selected for pathway`);
            pathway.set('version', _entity_1.PathwayVersions[_entity_1.PathwayVersions.length - 1]);
            if (pathway.item._id) {
                await pathway.save();
                return this.props.functions.selectPathway(pathway.item._id, false);
            }
            const data = await _entity_1.ModuleFE_Pathway._v1.createPathway(pathway.item).executeSync();
            this.props.functions.selectPathway(data.pathway._id, false);
            /*FIXME: Setting timeout here because parent component can't handle the race-condition of getting props and executing the toggleMode callback.
            *  When ComponentSync finally implements a derive queue this can be removed*/
            // setTimeout(() => this.props.toggleMode(), 100);
        };
        this.getVariables = () => {
            const pathway = this.props.editable.item;
            switch (pathway.type) {
                case 'treatment':
                case 'differential-diagnosis': {
                    const sourceTagId = frontend_2.ConfigKeyFE_SourceTagId.get();
                    const tagId = frontend_2.ConfigKeyFE_DiseasesTagId.get();
                    return frontend_2.ModuleFE_Variable.cache.filter(variable => {
                        const sourceGroup = variable.tagIds.find(group => group.key === sourceTagId);
                        return !!(sourceGroup === null || sourceGroup === void 0 ? void 0 : sourceGroup.value.includes(tagId));
                    });
                }
                case 'chief-complaint': {
                    const sourceTagId = frontend_2.ConfigKeyFE_SourceTagId.get();
                    const tagId = frontend_2.ConfigKeyFE_ComplaintsTagId.get();
                    return frontend_2.ModuleFE_Variable.cache.filter(variable => {
                        const sourceGroup = variable.tagIds.find(group => group.key === sourceTagId);
                        return !!(sourceGroup === null || sourceGroup === void 0 ? void 0 : sourceGroup.value.includes(tagId));
                    });
                }
                default:
                    return [];
            }
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const pathway = this.props.editable.item;
            if (!pathway._id)
                return React.createElement(Header_WithOptionMenu_1.Header_WithOptionMenu, { title: 'Add New Pathway' });
            return React.createElement(Header_Pathway_1.Header_Pathway, { pathway: this.props.editable, allowActions: false, functions: this.props.functions });
        };
        // ######################## Render - Body ########################
        this.renderForm = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'pathway__form' },
                this.renderLabelInput(),
                this.renderTypeDropdown(),
                this.renderVariableDropdown(),
                this.renderDescription(),
                this.renderUnderConstructionCheckbox());
        };
        this.renderUnderConstructionCheckbox = () => {
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.TS_Checkbox, { checked: this.state.editable.get('underConstruction'), onCheck: async (status) => {
                        this.state.editable.set('underConstruction', status);
                        this.forceUpdate();
                    } },
                    React.createElement("div", { className: 'pathway__form__label' }, "Under Construction")));
        };
        this.renderLabelInput = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' },
                    "Pathway Name",
                    React.createElement("sup", null, "*")),
                React.createElement(frontend_1.TS_Input, { type: 'text', value: this.props.editable.item.label, onChange: value => {
                        this.props.editable.set('label', value);
                        this.forceUpdate();
                    } }));
        };
        this.renderTypeDropdown = () => {
            const adapter = (0, frontend_1.SimpleListAdapter)([..._entity_1.PathwayTypes], type => React.createElement(React.Fragment, null, consts_2.PathwayTypeToLabel[type.item]));
            const disabled = !!this.props.editable.item._id;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' },
                    "Pathway Type",
                    React.createElement("sup", null, "*")),
                React.createElement(frontend_1.TS_DropDown, { adapter: adapter, disabled: disabled, selected: this.props.editable.item.type, onSelected: type => {
                        this.props.editable.set('type', type);
                        this.forceUpdate();
                    }, caret: consts_1.DropDown_DefaultCaret }));
        };
        this.renderVariableDropdown = () => {
            const pathway = this.props.editable;
            const type = pathway.item.type;
            if (!pathway.item.type)
                return;
            const adapter = (0, frontend_1.SimpleListAdapter)(this.getVariables(), variable => React.createElement(React.Fragment, null, variable.item.name));
            const selected = frontend_2.ModuleFE_Variable.cache.unique(pathway.item.variableId);
            const filter = new ts_common_1.Filter(variable => [variable.name]);
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' },
                    type === 'chief-complaint' ? 'Chief Complaint' : 'Disease',
                    " Name",
                    React.createElement("sup", null, "*")),
                React.createElement(frontend_1.TS_DropDown, { adapter: adapter, filter: filter, selected: selected, limitItems: 100, onSelected: variable => {
                        pathway.set('variableId', variable._id);
                        this.forceUpdate();
                    }, caret: consts_1.DropDown_DefaultCaret }));
        };
        this.renderDescription = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Description"),
                React.createElement(frontend_1.TS_TextArea, { type: 'text', value: this.props.editable.item.description, onChange: value => {
                        this.props.editable.set('description', value);
                        this.forceUpdate();
                    } }));
        };
        // ######################## Render - Buttons ########################
        this.renderButtons = () => {
            const buttonText = this.props.editable.item._id ? 'Save' : 'Next';
            return React.createElement(frontend_1.LL_H_C, { className: 'pathway__bottom-buttons' },
                React.createElement(frontend_1.TS_Button, { onClick: this.props.onCancel }, "Cancel"),
                React.createElement(frontend_1.TS_BusyButton, { onClick: this.onSave, className: 'preferred-action', disabled: !this.canSave() }, buttonText));
        };
    }
    __onPathwayUpdated(...params) {
        const pathwayId = this.state.editable.get('_id');
        if (!pathwayId)
            return;
        (0, utils_1.basicSyncEventRefresh)(pathwayId, params, () => this.props.functions.selectPathway(pathwayId, true), () => this.props.functions.deselectPathway());
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent', style: { padding: '30px', gap: '10px' } },
            this.renderHeader(),
            this.renderForm(),
            this.renderButtons());
    }
}
exports.Renderer_PathwayEditor = Renderer_PathwayEditor;
