"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DPView_VariablesAndValues = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = __importStar(require("react"));
const frontend_2 = require("@nu-art/ts-workspace/frontend");
const Panel_MultiVarValList_1 = require("../panels/Panel_MultiVarValList/Panel_MultiVarValList");
const Panel_DiseaseValueList_1 = require("../panels/Panel_DiseaseValueList/Panel_DiseaseValueList");
const frontend_3 = require("@app/hcs/frontend");
class DPView_VariablesAndValues extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.workspaceKey = 'disease-profile-likelihood';
        this.likelihoodRenderer = {
            horizontalSpace: frontend_2.TS_HorizontalWorkspace,
            likelihoodVariables: Panel_MultiVarValList_1.Panel_MultiVarValList,
            likelihoodValues: Panel_DiseaseValueList_1.Panel_DiseaseValueList,
        };
    }
    // ######################### Static #########################
    deriveStateFromProps(nextProps) {
        return {
            diseaseView: nextProps.diseaseView,
        };
    }
    shouldComponentUpdate(nextProps) {
        return true;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: "disease-profile-data-renderer" },
            React.createElement(frontend_3.PanelHeader, { title: this.state.diseaseView.label || 'No Property Selected' }),
            React.createElement(frontend_2.TS_Workspace, { renderers: this.likelihoodRenderer, workspaceKey: this.workspaceKey }));
    }
}
exports.DPView_VariablesAndValues = DPView_VariablesAndValues;
