"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator_ExpressionMinimalAssertion = exports.getExpressionValidator = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const leftHand_isTypeWithProp = (leftHand) => {
    return consts_1.LeftHandWithProp_Types.includes(leftHand.type);
};
const leftHand_isTypeWithSingleId = (leftHand) => {
    return consts_1.LeftHandWithSingleId_Types.includes(leftHand.type);
};
const Validator_AssociatedVariable = (instance, parentObject) => {
    if (!(parentObject === null || parentObject === void 0 ? void 0 : parentObject.expressionType))
        return (0, ts_common_1.tsValidateResult)(instance, ts_common_1.tsValidateOptionalId);
    if (!consts_1.ExpressionTypesWithVariable.includes(parentObject.expressionType))
        return (0, ts_common_1.tsValidateResult)(instance, ts_common_1.tsValidateOptionalId);
    return (0, ts_common_1.tsValidateResult)(instance, ts_common_1.tsValidateUniqueId);
};
const getExpressionValidator = (isMandatory = false) => {
    return (instance) => {
        const expressionInstance = instance;
        // ############################# Validator Utils #############################
        const checkExpressionType = (value) => {
            if (value[0] === value[1])
                return false;
            return (expressionInstance === null || expressionInstance === void 0 ? void 0 : expressionInstance.expressionType) !== consts_1.ExpressionType_NormalRange.dbKey && value[0] !== value[1];
        };
        const Validator_ValueAsRange = (instance) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!(0, ts_common_1.exists)(instance))
                return 'missing instance for validation';
            if (!Array.isArray(instance))
                return 'instance provided is not from type array';
            if (!instance.length)
                return 'Empty value provided';
            if (instance.length > 2)
                return 'instance has too many values';
            if (checkExpressionType(instance))
                return 'can\'t use range in expression not from normal range';
            if (instance[0] !== instance[1] || instance.every(value => value === 0))
                return;
            return 'All cases should keep same kind of input, this atom expecting number but got range.';
        };
        const Validator_ValueAsNumber = (instance) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!(0, ts_common_1.exists)(instance))
                return 'missing instance for validation';
            if (!Array.isArray(instance))
                return 'instance provided is not from type array';
            if (!instance.length)
                return 'Empty value provided';
            if (instance.length > 2)
                return 'instance has too many values';
            if (instance[0] === instance[1])
                return;
            return 'All cases should keep same kind of output, this atom expecting range but got number.';
        };
        const getValueValidator = (firstContainer, valueType) => {
            return (instance) => {
                if (!isMandatory && !(0, ts_common_1.exists)(instance))
                    return;
                if (!instance)
                    return 'No instance provided for validation';
                const containerRange = firstContainer[valueType];
                if (!containerRange) {
                    if (checkExpressionType(instance))
                        return 'can\'t use range in expression not from normal range';
                    return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateRange)());
                }
                if ((0, ts_common_1.exists)(firstContainer[valueType]) && containerRange[0] === containerRange[1])
                    return (0, ts_common_1.tsValidateResult)(instance, Validator_ValueAsNumber);
                return (0, ts_common_1.tsValidateResult)(instance, Validator_ValueAsRange);
            };
        };
        const Validator_AtomsLogic = (instance, parentObject) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'No instance provided for validation';
            if (instance === consts_1.LogicType_StructuredFreeText)
                return;
            const baseAllowedOperators = [consts_1.LogicType_Complex];
            //Validate if all output types are boolean
            if (parentObject === null || parentObject === void 0 ? void 0 : parentObject.atoms.every(atom => (!(0, ts_common_1.exists)(atom.cases[0].value) && !(0, ts_common_1.exists)(atom.fallbackValue) && (atom.cases[0].valueType !== 'enum' && atom.cases[0].valueType !== 'numeric')) || atom.cases[0].valueType === 'boolean')) {
                const relevantOperators = [...baseAllowedOperators, consts_1.LogicType_Or, consts_1.LogicType_And];
                return relevantOperators.includes(instance) ? undefined : `Atoms output type doesn't fit logic.\nAvailable logics: ${relevantOperators.join(' , ')}`;
            }
            //Validate if all output types are numeric
            if (parentObject === null || parentObject === void 0 ? void 0 : parentObject.atoms.every(atom => ((0, ts_common_1.exists)(atom.cases[0].value) && (0, ts_common_1.exists)(atom.fallbackValue)) || atom.cases[0].valueType === 'numeric')) {
                const relevantOperators = [...baseAllowedOperators, consts_1.LogicType_Add];
                return relevantOperators.includes(instance) ? undefined : `Atoms output type doesn't fit logic.\nAvailable logics: ${relevantOperators.join(' , ')}`;
            }
            return baseAllowedOperators.includes(instance) ? undefined : `Atoms output type doesn't fit logic.\nAvailable logics: ${baseAllowedOperators.join(' , ')}`;
        };
        // ############################# Validate Left Hand #############################
        const Validator_BaseCaseLeftHand = {
            id: isMandatory ? ts_common_1.tsValidateUniqueId : ts_common_1.tsValidateOptionalId
        };
        const Validator_LeftHandWithProp = Object.assign(Object.assign({}, Validator_BaseCaseLeftHand), { propertyId: isMandatory ? ts_common_1.tsValidateUniqueId : ts_common_1.tsValidateOptionalId, type: (0, ts_common_1.tsValidateValue)(consts_1.LeftHandWithProp_Types, isMandatory) });
        const Validator_LeftHandWithSingleId = (instance) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'No instance provided for validation';
            if (instance.type === 'atom')
                return (0, ts_common_1.tsValidateResult)(instance, Object.assign(Object.assign({}, Validator_BaseCaseLeftHand), { id: (0, ts_common_1.tsValidateString)(1, isMandatory), type: (0, ts_common_1.tsValidateValue)(consts_1.LeftHandWithSingleId_Types, isMandatory) }));
        };
        const Validator_LeftHand = (instance) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'no instance provided for validation';
            if (leftHand_isTypeWithProp(instance))
                return (0, ts_common_1.tsValidateResult)(instance, Validator_LeftHandWithProp);
            if (leftHand_isTypeWithSingleId(instance))
                return (0, ts_common_1.tsValidateResult)(instance, Validator_LeftHandWithSingleId);
            throw new ts_common_1.ImplementationMissingException(`Missing validator for left hand  ${(0, ts_common_1.__stringify)(instance)}`);
        };
        // ############################# Validate Right Hand #############################
        const Validator_RightHandEmptyArray = (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateOptional, isMandatory, 0);
        const Validator_RightHandInSet = (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, isMandatory, 1);
        const Validator_RightHandNumericValues = (instance) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'Missing instance for validation';
            if (!Array.isArray(instance))
                return 'Instance provided is not from type array';
            if (instance.length > 2)
                return 'Instance provided includes to many values';
            if (instance[0] !== instance[1])
                return `Invalid value, equality operator can't accept range`;
            return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateRange)(isMandatory));
        };
        const Validator_EqualityOperators = (instance) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'Missing instance for validation';
            if (!Array.isArray(instance))
                return 'Instance provided is not from type array';
            if (instance.length > 2)
                return 'Instance provided includes to many values';
            if (instance.length === 1)
                return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, isMandatory));
            if (instance[0] !== instance[1])
                return `Invalid value, equality operator can't accept range`;
            return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateRange)(isMandatory));
        };
        const Validator_ValidateInRange = (instance) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!(0, ts_common_1.exists)(instance))
                return 'missing instance for validation';
            if (!Array.isArray(instance))
                return 'instance provided is not from type array';
            if (instance.length > 2)
                return 'instance has too many values';
            if (instance.length === 1)
                return (0, ts_common_1.tsValidateResult)(instance[0], ts_common_1.tsValidateUniqueId);
            if (instance[0] === instance[1])
                return `In operator is looking for a range, can't check in range over a single number`;
            return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateRange)(isMandatory));
        };
        const Validator_RightHand = (instance, parentObject) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'no instance provided for validation';
            if (!isMandatory && !(parentObject === null || parentObject === void 0 ? void 0 : parentObject.caseOperator))
                return '';
            if (!(parentObject === null || parentObject === void 0 ? void 0 : parentObject.caseOperator))
                return 'can\'t validate right hand without an operator';
            if (parentObject.caseOperator === consts_1.LogicType_Equals || parentObject.caseOperator === consts_1.LogicType_NotEquals)
                return (0, ts_common_1.tsValidateResult)(instance, Validator_EqualityOperators);
            if (consts_1.EmptyRightHandOperators.includes(parentObject.caseOperator))
                return (0, ts_common_1.tsValidateResult)(instance, Validator_RightHandEmptyArray);
            if (parentObject.caseOperator === consts_1.LogicType_InSet)
                return (0, ts_common_1.tsValidateResult)(instance, Validator_RightHandInSet);
            if (consts_1.AllInOperators.includes(parentObject.caseOperator))
                return (0, ts_common_1.tsValidateResult)(instance, Validator_ValidateInRange);
            if (consts_1.RightHandValues_NumericOperators.includes(parentObject.caseOperator))
                return (0, ts_common_1.tsValidateResult)(instance, Validator_RightHandNumericValues);
            throw new ts_common_1.ImplementationMissingException(`Missing validator for right hand value ${instance}`);
        };
        // ############################# Validate Cases #############################
        const Validator_GeneralCase = {
            rightHand: Validator_RightHand,
            caseOperator: (0, ts_common_1.tsValidateValue)(consts_1.allLogicTypes, isMandatory),
            value: (0, ts_common_1.tsValidateRange)(isMandatory),
            valueType: (0, ts_common_1.tsValidateValue)(['boolean', 'numeric', 'enum'], false),
            factor: (0, ts_common_1.tsValidateOptionalObject)({
                upper: (0, ts_common_1.tsValidateNumber)(isMandatory),
                lower: (0, ts_common_1.tsValidateNumber)(isMandatory)
            })
        };
        const Validator_Cases = (instance) => {
            if (!instance)
                return 'no instance provided for validation';
            if (instance.length > 1) {
                return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateArray)(Object.assign(Object.assign({}, Validator_GeneralCase), { caseOperator: (instance) => {
                        if (!instance && !isMandatory)
                            return;
                        if (!instance)
                            return 'No instance provided to validation';
                        if (!consts_1.allLogicTypes.filter(op => !consts_1.SelfOperators.includes(op)).includes(instance))
                            return 'Operator is not allowed in this context';
                    }, value: getValueValidator(instance[0], 'value'), factor: (0, ts_common_1.tsValidateOptionalObject)({
                        upper: (0, ts_common_1.tsValidateNumber)(isMandatory),
                        lower: (0, ts_common_1.tsValidateNumber)(isMandatory)
                    }) })));
            }
            if (instance.length === 1 && (0, ts_common_1.exists)(instance[0].value) && consts_1.NormalRangeVarLogic.includes(instance[0].caseOperator)) {
                return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateArray)(Object.assign(Object.assign({}, Validator_GeneralCase), { value: getValueValidator(instance[0], 'value'), factor: (0, ts_common_1.tsValidateOptionalObject)({
                        upper: (0, ts_common_1.tsValidateNumber)(isMandatory),
                        lower: (0, ts_common_1.tsValidateNumber)(isMandatory)
                    }) })));
            }
            return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateArray)(Object.assign(Object.assign({}, Validator_GeneralCase), { value: getValueValidator(instance[0], 'value') })));
        };
        // ############################# Validate Term And Atoms #############################
        const term_isFlatTerm = (_term) => {
            return _term.atomsLogic !== consts_1.LogicType_Complex;
        };
        const term_isWithSubTerms = (_term) => {
            return _term.atomsLogic === consts_1.LogicType_Complex;
        };
        const Validator_Atom = (instance) => {
            return (0, ts_common_1.tsValidateResult)(instance, {
                fallbackValue: getValueValidator(instance === null || instance === void 0 ? void 0 : instance.cases[0], 'value'),
                cases: Validator_Cases,
                leftHand: Validator_LeftHand,
                deltaTimeDiff: (deltaTimeDiff) => {
                    if (!deltaTimeDiff && !isMandatory)
                        return;
                    if (!deltaTimeDiff)
                        return 'No instance provided for validation';
                    if (!consts_1.AllDeltaLogic.includes(instance === null || instance === void 0 ? void 0 : instance.cases[0].caseOperator))
                        return 'Cannot apply delta time difference to a non delta atom';
                    return (0, ts_common_1.tsValidateResult)(deltaTimeDiff, (0, ts_common_1.tsValidateTimeRange)(false));
                },
                sampleInfo: (0, ts_common_1.tsValidateOptionalObject)({
                    sampleIndex: (0, ts_common_1.tsValidateValue)(consts_1.SampleIndex_All),
                    timeConstraint: (instance) => {
                        if (!instance && !isMandatory)
                            return;
                        if (!instance)
                            return 'No instance provided for validation';
                        if (!instance.length)
                            return;
                        return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateTimeRange)(false));
                    },
                    expiry: (0, ts_common_1.tsValidateTimeRange)(false),
                }),
                timestamp: ts_common_1.tsValidateOptional,
                comment: (0, ts_common_1.tsValidateString)(undefined, isMandatory)
            });
        };
        const Validator_BaseTerm = {
            expression: (0, ts_common_1.tsValidateString)(undefined, isMandatory),
            outputType: (0, ts_common_1.tsValidateValue)(consts_1.Expression_OutputTypes, false),
            structuredFreeText: (0, ts_common_1.tsValidateOptionalObject)({
                if: (0, ts_common_1.tsValidateString)(),
                then: (0, ts_common_1.tsValidateString)(),
                else: (0, ts_common_1.tsValidateString)()
            }),
            comment: (0, ts_common_1.tsValidateString)(undefined, false),
            atoms: (0, ts_common_1.tsValidateArray)(Validator_Atom)
        };
        const Validator_SubTerms = (instance, parentObject) => {
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'No instance provided for validation';
            const subTerms = parentObject === null || parentObject === void 0 ? void 0 : parentObject.subTerms;
            if (!(0, ts_common_1.exists)(subTerms) || !subTerms.length)
                return;
            return (0, ts_common_1.tsValidateResult)(instance, (0, ts_common_1.tsValidateArray)(Object.assign(Object.assign({}, Validator_BaseTerm), { atomsLogic: (0, ts_common_1.tsValidateValue)([consts_1.LogicType_And, consts_1.LogicType_Or], isMandatory), fallbackValue: getValueValidator(subTerms[0], 'fallbackValue'), value: getValueValidator(subTerms[0], 'value') })));
        };
        const Validator_Term = (instance) => {
            var _a, _b;
            if (!isMandatory && !(0, ts_common_1.exists)(instance))
                return;
            if (!instance)
                return 'No instance provided for validation';
            if (term_isWithSubTerms(instance)) {
                return (0, ts_common_1.tsValidateResult)(instance, Object.assign(Object.assign({}, Validator_BaseTerm), { subTerms: Validator_SubTerms, fallbackValue: getValueValidator((_a = instance.subTerms) === null || _a === void 0 ? void 0 : _a[0], 'value'), value: getValueValidator((_b = instance.subTerms) === null || _b === void 0 ? void 0 : _b[0], 'value'), atomsLogic: Validator_AtomsLogic }));
            }
            if (term_isFlatTerm(instance)) {
                const firstContainer = (0, ts_common_1.exists)(instance.atoms[0].cases[0].value) ? instance.atoms[0].cases[0] : instance;
                return (0, ts_common_1.tsValidateResult)(instance, Object.assign(Object.assign({}, Validator_BaseTerm), { atomsLogic: Validator_AtomsLogic, fallbackValue: (currentValue) => {
                        if (((0, ts_common_1.exists)(instance.atoms[0].cases[0].value) && instance.atoms.length === 1) && (0, ts_common_1.exists)(currentValue))
                            return 'Can\'t have term else value in a single atom term with value, it\'s redundant';
                        return (getValueValidator(firstContainer, 'value'))(currentValue);
                    }, value: (currentValue) => {
                        var _a, _b, _c;
                        if (((0, ts_common_1.exists)(instance.atoms[0].cases[0].value) && instance.atoms.length === 1) && (0, ts_common_1.exists)(currentValue))
                            return 'Can\'t have term value in a single atom term with value, it\'s redundant';
                        return (getValueValidator((_c = (_b = (_a = instance.atoms) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.cases) === null || _c === void 0 ? void 0 : _c[0], 'value'))(currentValue);
                    }, outputType: (instance, parentInstance) => {
                        if (!instance && (parentInstance === null || parentInstance === void 0 ? void 0 : parentInstance.atomsLogic) === consts_1.LogicType_StructuredFreeText)
                            return 'When using the free text logic output type must be provided';
                        if (!isMandatory && !(0, ts_common_1.exists)(instance))
                            return;
                        if (!instance)
                            return 'No instance provided for validation';
                        if (instance === consts_1.ExpressionOutput_Range && (expressionInstance === null || expressionInstance === void 0 ? void 0 : expressionInstance.expressionType) !== consts_1.ExpressionType_NormalRange.dbKey) {
                            return 'Cannot return range as output type if expression is not normal range';
                        }
                        return consts_1.Expression_OutputTypes.includes(instance) ? undefined : `output type ${instance} not in ${consts_1.Expression_OutputTypes.join(',')}`;
                    } }));
            }
            throw new ts_common_1.ImplementationMissingException(`Missing validator for term  ${instance}`);
        };
        // ############################# Validate Expression Terms #############################
        const TermsOutput_IsNumeric = (term) => {
            if (term.atomsLogic === consts_1.LogicType_Complex)
                return true;
            if ((0, ts_common_1.exists)(term.value))
                return true;
            if (term.outputType === 'numeric')
                return true;
            return term.atoms.every(atom => {
                if ((0, ts_common_1.exists)(atom.cases[0].value))
                    return true;
                return atom.cases[0].valueType === 'numeric';
            });
        };
        const TermOutput_IsBoolean = (term) => {
            if (term.atomsLogic === consts_1.LogicType_Complex)
                return false;
            if ((0, ts_common_1.exists)(term.value))
                return false;
            if (term.outputType === 'boolean')
                return true;
            return !term.atoms.every(atom => (0, ts_common_1.exists)(atom.cases[0].value)
                || atom.cases[0].valueType === 'numeric' || atom.cases[0].valueType === 'enum');
        };
        const Validator_Terms = (terms) => {
            const innerTermsValidator = (0, ts_common_1.tsValidateResult)(terms, (0, ts_common_1.tsValidateArray)(Validator_Term));
            if (terms === null || terms === void 0 ? void 0 : terms.every(term => TermsOutput_IsNumeric(term))) {
                return innerTermsValidator;
            }
            if (terms === null || terms === void 0 ? void 0 : terms.every(term => TermOutput_IsBoolean(term))) {
                return innerTermsValidator;
            }
            return 'All Terms should be with the same output type, either numeric boolean or range';
        };
        // ############################# Validate Expression #############################
        return (0, ts_common_1.tsValidateResult)(instance, {
            label: (0, ts_common_1.tsValidateString)(),
            expressionType: (0, ts_common_1.tsValidateValue)([...consts_1.AllExpressionTypes, consts_1.ExpressionType_NormalRange].map(expressionType => expressionType.dbKey)),
            associatedVar: Validator_AssociatedVariable,
            outputType: (0, ts_common_1.tsValidateValue)(consts_1.Expression_OutputTypes, isMandatory),
            advisorFormKey: (0, ts_common_1.tsValidateString)(undefined, isMandatory),
            expression: (0, ts_common_1.tsValidateString)(undefined, isMandatory),
            isEvidenceBased: (0, ts_common_1.tsValidateBoolean)(isMandatory),
            description: (0, ts_common_1.tsValidateString)(undefined, isMandatory),
            references: (0, ts_common_1.tsValidate_OptionalArray)(ts_common_1.tsValidateUniqueId),
            terms: Validator_Terms
        });
    };
};
exports.getExpressionValidator = getExpressionValidator;
exports.Validator_ExpressionMinimalAssertion = {
    label: (0, ts_common_1.tsValidateString)(),
    expressionType: (0, ts_common_1.tsValidateValue)(consts_1.AllExpressionTypes.map(expressionType => expressionType.dbKey)),
    associatedVar: Validator_AssociatedVariable,
    outputType: ts_common_1.tsValidateOptional,
    advisorFormKey: ts_common_1.tsValidateOptional,
    expression: ts_common_1.tsValidateOptional,
    isEvidenceBased: ts_common_1.tsValidateOptional,
    description: ts_common_1.tsValidateOptional,
    references: ts_common_1.tsValidateOptional,
    terms: ts_common_1.tsValidateOptional
};
