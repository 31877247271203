"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header_PathwayState = void 0;
const React = require("react");
const Header_WithOptionMenu_1 = require("@app/styles/frontend/ui-manager/headers/Header_WithOptionMenu");
const Header_PathwayState = (props) => {
    var _a;
    let subtitle = 'State';
    if (props.pathwayState.item.type)
        subtitle = `${props.pathwayState.item.type} State`;
    return React.createElement(Header_WithOptionMenu_1.Header_WithOptionMenu, { title: (_a = props.headerText) !== null && _a !== void 0 ? _a : props.pathwayState.item.name, subtitle: subtitle, className: 'small-title', onTitleClick: props.clickableTitle ? () => props.functions.selectPathwayState(props.pathwayState) : undefined, menuActions: props.actions });
};
exports.Header_PathwayState = Header_PathwayState;
