import * as React from 'react';
import {ComponentSync, LL_H_C, LL_V_L, ModuleFE_StorageCleaner, ToastBuilder, TS_BusyButton} from '@nu-art/thunderstorm/frontend';
import {Minute} from '@nu-art/ts-common';
import './NewAppVersionToast.scss';
import {ICONS} from '@app/styles';

type Props = {}

export class NewAppVersionToast
	extends ComponentSync<Props> {

	static show() {
		new ToastBuilder().setContent(<NewAppVersionToast/>).setDuration(Minute).show();
	}

	private onClick = async () => {
		await ModuleFE_StorageCleaner.cleanCache();
		location.reload();
	};

	render() {
		return <LL_H_C id={'toast__new-app-version'}>
			<ICONS.infoToast.component/>
			<LL_V_L className={'toast__new-app-version__info'}>
				<div className={'toast__new-app-version__text'}>A new version of the Knowledge Manager is available</div>
				<TS_BusyButton onClick={this.onClick}>Click here to upgrade the app version</TS_BusyButton>
			</LL_V_L>
		</LL_H_C>;
	}
}