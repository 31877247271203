"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
//Variable Forwards
__exportStar(require("../_entity/variable/shared"), exports);
__exportStar(require("../_entity/variable/frontend"), exports);
//Value Forwards
__exportStar(require("../_entity/value/shared"), exports);
__exportStar(require("../_entity/value/frontend"), exports);
//MacroTag Forwards
__exportStar(require("../_entity/macro-tag/shared"), exports);
__exportStar(require("../_entity/macro-tag/frontend"), exports);
//Measurement Unit Forwards
__exportStar(require("../_entity/discussion/shared"), exports);
__exportStar(require("../_entity/discussion/frontend"), exports);
//Measurement Unit Forwards
__exportStar(require("../_entity/measurement-unit/shared"), exports);
__exportStar(require("../_entity/measurement-unit/frontend"), exports);
//Variable Relation Forwards
__exportStar(require("../_entity/variable-relation/shared"), exports);
__exportStar(require("../_entity/variable-relation/frontend"), exports);
//User
__exportStar(require("../_entity/user/shared"), exports);
__exportStar(require("../_entity/user/frontend"), exports);
//NLPData
__exportStar(require("../_entity/nlp-data/shared"), exports);
__exportStar(require("../_entity/nlp-data/frontend"), exports);
//Attribute Set
__exportStar(require("../_entity/attribute-set/shared"), exports);
__exportStar(require("../_entity/attribute-set/frontend"), exports);
//Book
__exportStar(require("../_entity/book/shared"), exports);
__exportStar(require("../_entity/book/frontend"), exports);
//Reference
__exportStar(require("../_entity/reference/shared"), exports);
__exportStar(require("../_entity/reference/frontend"), exports);
//Tag
__exportStar(require("../_entity/tag/shared"), exports);
__exportStar(require("../_entity/tag/frontend"), exports);
//Clinical Form
__exportStar(require("../_entity/clinical-form/shared"), exports);
__exportStar(require("../_entity/clinical-form/frontend"), exports);
//Log Template
__exportStar(require("../_entity/log-template-OLD/shared"), exports);
__exportStar(require("../_entity/log-template-OLD/frontend"), exports);
//Score
__exportStar(require("../_entity/score/shared"), exports);
__exportStar(require("../_entity/score/frontend"), exports);
//Resource
__exportStar(require("../_entity/resource/shared"), exports);
__exportStar(require("../_entity/resource/frontend"), exports);
//Expression
__exportStar(require("../_entity/expression/shared"), exports);
__exportStar(require("../_entity/expression/frontend"), exports);
