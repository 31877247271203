"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_DiseaseProfile = void 0;
const React = __importStar(require("react"));
require("./Page_DiseaseProfile.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@nu-art/ts-workspace/frontend");
const Page_DiseaseProfile_Header_1 = require("../Page_DiseaseProfile_Header/Page_DiseaseProfile_Header");
const panels_1 = require("../../panels");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const permission_keys_1 = require("../../../permission-keys");
const _entity_1 = require("../../../_entity");
const module_pack_1 = require("../../../module-pack");
const frontend_3 = require("@app/hcs/frontend");
const renderers = {
    horizontalSpace: frontend_2.TS_HorizontalWorkspace,
    diseasePropertyPanel: panels_1.Panel_DiseaseViews,
    propertyDataPanel: panels_1.Panel_DPView_ContentSelector,
};
class Page_DiseaseProfile extends frontend_1.ProtoComponent {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        this.workspaceKey = 'disease-profiles';
        // ######################### Logic #########################
        this.reSelectDP = () => {
            const firstDP = _entity_1.ModuleFE_DiseaseProfile.cache.all()[0];
            const currentSelectedDP = _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.state.selectedDiseaseProfileId);
            //If starting out without a selected DP or the selected DP is not in the cache
            if (!this.state.selectedDiseaseProfileId || !currentSelectedDP) {
                const selected = Object.assign({}, this.getQueryParam('selected', {}));
                if (!firstDP)
                    delete selected[_entity_1.DBDef_DiseaseProfile.dbKey];
                else
                    selected[_entity_1.DBDef_DiseaseProfile.dbKey] = firstDP._id;
                this.setQueryParam('selected', selected);
            }
        };
        // ######################### Render #########################
        this.renderWorkspace = () => {
            if (!this.state.selectedDiseaseProfileId)
                return React.createElement("div", { className: 'dp-page-message' }, "No disease profile selected");
            return React.createElement(frontend_2.TS_Workspace, { renderers: renderers, workspaceKey: this.workspaceKey });
        };
    }
    // ######################### Life Cycle #########################
    __onDiseaseProfileUpdated(...params) {
        switch (params[0]) {
            case frontend_1.EventType_Create: {
                return this.reSelectDP();
            }
            case frontend_1.EventType_Delete:
            case frontend_1.EventType_DeleteMulti: {
                const dps = (0, ts_common_1.asArray)(params[1]);
                if (dps.find(dp => dp._id === this.state.selectedDiseaseProfileId))
                    return this.reSelectDP();
            }
        }
    }
    deriveStateFromProps(nextProps, state) {
        state.selectedDiseaseProfileId = this.getQueryParam('selected', {})[_entity_1.DBDef_DiseaseProfile.dbKey];
        if (state.selectedDiseaseProfileId)
            return state;
        //Set a disease profile if one none selected
        const firstDP = _entity_1.ModuleFE_DiseaseProfile.cache.all()[0];
        if (!firstDP)
            return state;
        const selected = Object.assign({}, this.getQueryParam('selected', {}));
        selected[_entity_1.DBDef_DiseaseProfile.dbKey] = firstDP._id;
        state.selectedDiseaseProfileId = firstDP._id;
        this.logInfo(`Setting new DP ID ${firstDP._id}`);
        this.setQueryParam('selected', selected);
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'page__disease-profile' },
            React.createElement(Page_DiseaseProfile_Header_1.Page_DiseaseProfile_Header, null),
            this.renderWorkspace());
    }
}
_a = Page_DiseaseProfile;
Page_DiseaseProfile.Route = {
    key: 'disease-profiles',
    path: 'disease-profiles',
    Component: _a,
    enabled: () => permission_keys_1.PermissionKeyFE_DiseaseProfile_General.getAccessLevel() === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess,
    modulesToAwait: [frontend_1.ModuleFE_AppConfig, ...module_pack_1.ModulePackFE_DiseaseProfile, frontend_3.ModuleFE_Variable, frontend_3.ModuleFE_Value, frontend_3.ModuleFE_Reference, frontend_3.ModuleFE_Book],
};
Page_DiseaseProfile.defaultProps = {
    keys: ['selected'],
};
exports.Page_DiseaseProfile = Page_DiseaseProfile;
