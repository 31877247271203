"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Dictionary = exports.ModuleFE_Dictionary_Class = void 0;
const rawSortedTermArray = require("./terms_array_full.json");
const trie_1 = require("@smikhalevski/trie");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const thunderstorm_1 = require("@nu-art/thunderstorm");
const dictionary_1 = require("../../shared/dictionary");
class ModuleFE_Dictionary_Class extends ts_common_1.Module {
    // public spellchecker!: typeof Spellchecker;
    constructor() {
        super();
        this.vv1 = {
            getDictionaryFiles: (0, frontend_1.apiWithBody)(dictionary_1.ApiDef_Dictionary.vv1.getDictionaryFiles),
        };
    }
    init() {
        this.initializeTrie();
        // setTimeout(async () => {
        // 	await this.initializeSpellChecker();
        // });
    }
    initializeTrie() {
        const sortedTermArray = rawSortedTermArray;
        this.trie = (0, trie_1.trieCreate)();
        sortedTermArray.forEach(term => (0, trie_1.trieSet)(this.trie, term, term));
        this.arrayTrie = (0, trie_1.arrayTrieEncode)(this.trie);
    }
    // private async initializeSpellChecker() {
    // 	this.spellchecker = new Spellchecker();
    //
    // 	// const response = await this.vv1.getDictionaryFiles({}).executeSync();
    // 	// const affFile = await this.getFileFromUrl(response.files[0].affSignedUrl);
    // 	// const dicFile = await this.getFileFromUrl(response.files[0].dicSignedUrl);
    //
    // 	const encoder = new TextEncoder();
    // 	const DICT = this.spellchecker.parse({
    // 		aff: Buffer.from(encoder.encode(affFile.content)),
    // 		dic: Buffer.from(encoder.encode(dicFile.content))
    // 	});
    //
    //
    // 	this.spellchecker.use(DICT);
    // }
    async getFileFromUrl(url) {
        return await frontend_1.ModuleFE_XHR.createRequest({ fullUrl: url, method: thunderstorm_1.HttpMethod.GET, path: '' }).executeSync();
    }
}
exports.ModuleFE_Dictionary_Class = ModuleFE_Dictionary_Class;
exports.ModuleFE_Dictionary = new ModuleFE_Dictionary_Class();
