"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertValueDBToUI = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const convertValueDBToUI = (dbValue, maps) => {
    var _a, _b, _c;
    if (!dbValue) {
        console.error('Tried to convert db-> ui value, but did not receive any DB Value!');
        return undefined;
    }
    const uiValue = {};
    //Simple Data
    uiValue._id = dbValue === null || dbValue === void 0 ? void 0 : dbValue._id;
    uiValue._v = dbValue === null || dbValue === void 0 ? void 0 : dbValue._v;
    uiValue.__created = dbValue === null || dbValue === void 0 ? void 0 : dbValue.__created;
    uiValue.__updated = dbValue === null || dbValue === void 0 ? void 0 : dbValue.__updated;
    uiValue.type = dbValue === null || dbValue === void 0 ? void 0 : dbValue.type;
    uiValue._rowId = dbValue === null || dbValue === void 0 ? void 0 : dbValue._rowId;
    //Inner Data
    switch (dbValue.type) {
        case consts_1.valueType_SubRange:
            uiValue.data = dbValue.data;
            break;
        case consts_1.valueType_SubEnumerated: {
            const _dbValue = dbValue;
            const scope = (0, ts_common_1.filterInstances)((_a = _dbValue.data.scope) === null || _a === void 0 ? void 0 : _a.map(id => {
                const value = maps.values[id];
                return (0, exports.convertValueDBToUI)(value, maps);
            }));
            uiValue.data = {
                scope,
                label: _dbValue.label,
            };
            break;
        }
        case consts_1.valueType_EnumElement:
            uiValue.data = dbValue.data;
            break;
        case consts_1.valueType_TimeRange:
            uiValue.data = dbValue.data;
            break;
        case consts_1.valueType_Enumerated: {
            const _dbValue = dbValue;
            const scope = (0, ts_common_1.filterInstances)((_b = _dbValue.data.scope) === null || _b === void 0 ? void 0 : _b.map(id => {
                const value = maps.values[id];
                return (0, exports.convertValueDBToUI)(value, maps);
            }));
            uiValue.data = {
                scope,
                subsets: [],
                subsetIds: [],
                props: _dbValue.data.props,
            };
            break;
        }
        case consts_1.valueType_Range: {
            const _dbValue = dbValue;
            const subsets = (0, ts_common_1.filterInstances)(_dbValue.data.subsets.map(id => {
                const value = maps.values[id];
                return (0, exports.convertValueDBToUI)(value, maps);
            }));
            uiValue.data = (0, ts_common_1.filterKeys)({
                subsets,
                subsetIds: (0, ts_common_1.filterInstances)(subsets.map(item => item._id)),
                scope: _dbValue.data.scope,
                measurementUnit: maps.measurementUnits[_dbValue.data.unitsId],
            }, 'measurementUnit');
            break;
        }
        case consts_1.valueType_Tuple: {
            const _dbValue = dbValue;
            const scope = (0, ts_common_1.filterInstances)((_c = _dbValue.data.scope) === null || _c === void 0 ? void 0 : _c.map(id => {
                const value = maps.values[id];
                return (0, exports.convertValueDBToUI)(value, maps);
            }));
            uiValue.data = {
                scope,
            };
            break;
        }
        case consts_1.valueType_StandardRange: {
            const _dbValue = dbValue;
            uiValue.data = {
                normalValues: _dbValue.data.normalValues || [],
                fullRange: _dbValue.data.fullRange,
                declarationVarIds: _dbValue.data.declarationVarIds,
                measurementUnit: maps.measurementUnits[_dbValue.data.unitsId],
            };
            break;
        }
        case consts_1.valueType_Time: {
            const _dbValue = dbValue;
            uiValue.data = {
                timeRangesIds: _dbValue.data.timeRanges,
                timeRanges: (0, ts_common_1.filterInstances)(_dbValue.data.timeRanges.map(valueId => maps.values[valueId]))
            };
            break;
        }
        default: {
            throw new ts_common_1.ImplementationMissingException(`No implementation for type ${dbValue.type}`);
        }
    }
    return uiValue;
};
exports.convertValueDBToUI = convertValueDBToUI;
