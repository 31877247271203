"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diseaseProfileColors = exports.diseaseProfileInitials = exports.isLight = exports.getDiseaseProfilePresetData = void 0;
const frontend_1 = require("@app/hcs/frontend");
const _enum_1 = require("../_enum");
const getDiseaseProfilePresetData = (variable) => {
    var _a;
    const uiVar = new frontend_1.VariableWrapper(variable);
    let toRet = {
        initials: '',
        criticality: _enum_1.Criticality_00,
        variableId: uiVar.dbVariable._id,
        referenceIds: [],
        chiefComplaintIds: [],
        label: uiVar.dbVariable.name.toLowerCase(),
        icd: [],
        tagIds: [],
        colorGroup: '',
        _variableIds: [],
        _valueIds: [],
        _tagIds: []
    };
    toRet = Object.assign(Object.assign(Object.assign(Object.assign({}, toRet), exports.diseaseProfileInitials[uiVar.dbVariable.name.toLowerCase()]), exports.diseaseProfileColors[((_a = uiVar.getSystemTag()) === null || _a === void 0 ? void 0 : _a.label.toLowerCase()) || '']), diseaseProfileCriticality[uiVar.dbVariable.name.toLowerCase()]);
    return toRet;
};
exports.getDiseaseProfilePresetData = getDiseaseProfilePresetData;
const isLight = (color) => {
    // Variables for red, green, blue values
    let r, g, b;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 170)
        return true;
    return false;
};
exports.isLight = isLight;
exports.diseaseProfileInitials = {
    'acute myocardial infarction': { initials: 'AMI' },
    'aortic dissection': { initials: 'AD' },
    'cardiac tamponade': { initials: 'CT' },
    'cocaine toxicity': { initials: 'CTX' },
    'takotsubo cardiomyopathy': { initials: 'TCM' },
    'pulmonary embolism': { initials: 'PE' },
    'tension pneumothorax': { initials: 'TP' },
    'esophageal obstruction': { initials: 'EO' },
    'esophageal rupture': { initials: 'ER' },
    'sepsis': { initials: 'SPS' },
    'acute coronary syndrome': { initials: 'ACS' },
    'acute decompensated heart failure': { initials: 'AHF' },
    'high altitude pulmonary edema': { initials: 'HAPE' },
    'endocarditis': { initials: 'EC' },
    'myocarditis': { initials: 'MC' },
    'hypertensive crisis': { initials: 'HC' },
    'valvular heart disease': { initials: 'VHD' },
    'aortic stenosis': { initials: 'AS' },
    'acute chest syndrome': { initials: 'CSD' },
    'asthma': { initials: 'AMI' },
    'chronic obstructive pulmonary disease': { initials: 'COP' },
    'hemothorax': { initials: 'HET' },
    'mediastinitis': { initials: 'MD' },
    'pneumothorax': { initials: 'PT' },
    'cholecystitis': { initials: 'CC' },
    'pancreatitis': { initials: 'PC' },
    'anemia': { initials: 'AN' },
    'covid 19': { initials: 'C19' },
    'illicit drug use': { initials: 'IDU' },
    'dilated cardiomyopathy': { initials: 'DCM' },
    'hypertrophic cardiomyopathy': { initials: 'HCM' },
    'restrictive cardiomyopathy': { initials: 'RCM' },
    'angina pectoris': { initials: 'AP' },
    'long covid': { initials: 'LC' },
    'mitral valve prolapse': { initials: 'MVP' },
    'pericarditis': { initials: 'PC' },
    'bronchitis': { initials: 'BR' },
    'community acquired pneumonia': { initials: 'CAP' },
    'pleuritis': { initials: 'PL' },
    'tracheitis': { initials: 'TR' },
    'lung tumor': { initials: 'LT' },
    'pleural effusion': { initials: 'PLE' },
    'pulmonary hypertension': { initials: 'PHT' },
    'cor pulmonale': { initials: 'CPU' },
    'esophagitis': { initials: 'EG' },
    'esophageal spasm': { initials: 'ES' },
    'biliary colic': { initials: 'BC' },
    'diaphragmatic hernia': { initials: 'DH' },
    'gastroesophageal reflux disease': { initials: 'GERD' },
    'peptic disorder': { initials: 'PD' },
    'costochondritis': { initials: 'CC' },
    'intercostal strain': { initials: 'IS' },
    'musculoskeletal pain': { initials: 'MSK' },
    'rib fracture': { initials: 'RF' },
    'spinal cord compression': { initials: 'SC' },
    'herpes zoster': { initials: 'HZ' },
    'post herpetic neuritis': { initials: 'PHN' },
    'anxiety': { initials: 'AX' },
    'panic disorder': { initials: 'PN' },
    'atrial fibrillation': { initials: 'AF' },
    'venous thromboembolic disease': { initials: 'VTE' },
};
exports.diseaseProfileColors = {
    'cardiovascular system': { backgroundColor: { value: '#fe7371' }, color: { value: '#FFFFFF' } },
    'central nervous system': { backgroundColor: { value: '#FFFF7F' }, color: { value: '#707070' } },
    'congenital-genetic': { backgroundColor: { value: '#D9EAD3' }, color: { value: '#707070' } },
    'drugs and toxicity': { backgroundColor: { value: '#FD29F8' }, color: { value: '' } },
    'ear nose throat system': { backgroundColor: { value: '#6D9EEB' }, color: { value: '#FFFFFF' } },
    'endocrine system': { backgroundColor: { value: '#95FFAA' }, color: { value: '#049B30' } },
    'metabolic system': { backgroundColor: { value: '#95FFAA' }, color: { value: '#049B30' } },
    'gastrointestinal tract system': { backgroundColor: { value: '#90B87D' }, color: { value: '#000000' } },
    'genitourinary system': { backgroundColor: { value: '#F3C174' }, color: { value: '#875305' } },
    'hematologic system': { backgroundColor: { value: '#D699FF' }, color: { value: '#7F01D2' } },
    'hepatobiliary system': { backgroundColor: { value: '#C99F1E' }, color: { value: '#FFFFFF' } },
    'immune system': { backgroundColor: { value: '#F4CCCC' }, color: { value: '#000000' } },
    'infectious diseases': { backgroundColor: { value: '#C55E5E' }, color: { value: '#FDF6BF' } },
    'integumentary system': { backgroundColor: { value: '#CFD4D8' }, color: { value: '#000000' } },
    'musculoskeletal system': { backgroundColor: { value: '#C7925B' }, color: { value: '#000000' } },
    'ophtalmic system': { backgroundColor: { value: '#FFF2CC' }, color: { value: '#000000' } },
    'psychiatric': { backgroundColor: { value: '#CFE2F3' }, color: { value: '#007CBE' } },
    'respiratory system': { backgroundColor: { value: '#9595FB' }, color: { value: '#2C2CFE' } },
    'lymphatic system': { backgroundColor: { value: '#000000' }, color: { value: '#FFFFFF' } },
};
const diseaseProfileCriticality = {
    'acute myocardial infarction': { criticality: _enum_1.Criticality_20 },
    'acute coronary syndrome': { criticality: _enum_1.Criticality_20 },
    'aortic dissection': { criticality: _enum_1.Criticality_20 },
    'cardiac tamponade': { criticality: _enum_1.Criticality_20 },
    'takotsubo cardiomyopathy': { criticality: _enum_1.Criticality_20 },
    'acute decompendated heart failure': { criticality: _enum_1.Criticality_20 },
    'pulmonary embolism': { criticality: _enum_1.Criticality_20 },
    'tension pneumothorax': { criticality: _enum_1.Criticality_20 },
    'esophageal obstruction': { criticality: _enum_1.Criticality_20 },
    'esophageal rupture': { criticality: _enum_1.Criticality_20 },
    'sepsis': { criticality: _enum_1.Criticality_20 },
    'high altitude pulmonary edema': { criticality: _enum_1.Criticality_10 },
    'cocaine toxicity': { criticality: _enum_1.Criticality_10 },
    'endocarditis': { criticality: _enum_1.Criticality_10 },
    'myocarditis': { criticality: _enum_1.Criticality_10 },
    'hypertensive crisis': { criticality: _enum_1.Criticality_10 },
    'valvular heart disease': { criticality: _enum_1.Criticality_10 },
    'aortic stenosis': { criticality: _enum_1.Criticality_10 },
    'acute chest syndrome': { criticality: _enum_1.Criticality_10 },
    'asthma': { criticality: _enum_1.Criticality_10 },
    'chronic obstructive pulmonary disease': { criticality: _enum_1.Criticality_10 },
    'hemothorax': { criticality: _enum_1.Criticality_10 },
    'mediatinitis': { criticality: _enum_1.Criticality_10 },
    'pneumothorax': { criticality: _enum_1.Criticality_10 },
    'cholecystitis': { criticality: _enum_1.Criticality_10 },
    'pancreatitis': { criticality: _enum_1.Criticality_10 },
    'anemia': { criticality: _enum_1.Criticality_10 },
    'covid 19': { criticality: _enum_1.Criticality_10 },
    'illicit drug use': { criticality: _enum_1.Criticality_10 },
    'dilated cardiomyopathy': { criticality: _enum_1.Criticality_00 },
    'hypertrophic cardiomyopathy': { criticality: _enum_1.Criticality_00 },
    'restrictive cardiomyopathy': { criticality: _enum_1.Criticality_00 },
    'angina pectoris': { criticality: _enum_1.Criticality_00 },
    'long covid': { criticality: _enum_1.Criticality_00 },
    'mitral valve prolapse': { criticality: _enum_1.Criticality_00 },
    'pericarditis': { criticality: _enum_1.Criticality_00 },
    'bronchitis': { criticality: _enum_1.Criticality_00 },
    'community aquaired pneumonia': { criticality: _enum_1.Criticality_00 },
    'pleuritis': { criticality: _enum_1.Criticality_00 },
    'tracheitis': { criticality: _enum_1.Criticality_00 },
    'lung tumor': { criticality: _enum_1.Criticality_00 },
    'pleural effusion': { criticality: _enum_1.Criticality_00 },
    'pulmonary hypertension': { criticality: _enum_1.Criticality_00 },
    'cor pulmonale': { criticality: _enum_1.Criticality_00 },
    'esophagitis': { criticality: _enum_1.Criticality_00 },
    'esophageal spasm': { criticality: _enum_1.Criticality_00 },
    'biliary colic': { criticality: _enum_1.Criticality_00 },
    'diaphragmatic hernia': { criticality: _enum_1.Criticality_00 },
    'gastroesophageal reflux disease': { criticality: _enum_1.Criticality_00 },
    'peptic disorder': { criticality: _enum_1.Criticality_00 },
    'costochondritis': { criticality: _enum_1.Criticality_00 },
    'intercostal strain': { criticality: _enum_1.Criticality_00 },
    'musculoskeletal pain': { criticality: _enum_1.Criticality_00 },
    'rib fracture': { criticality: _enum_1.Criticality_00 },
    'spinal cord comression': { criticality: _enum_1.Criticality_00 },
    'herpes zoster': { criticality: _enum_1.Criticality_00 },
    'post herpetic neuritis': { criticality: _enum_1.Criticality_00 },
    'anxiety': { criticality: _enum_1.Criticality_00 },
    'panic disorder': { criticality: _enum_1.Criticality_00 },
};
