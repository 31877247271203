"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QRangeInputV3 = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
class QRangeInputV3 extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.toastError = (message) => {
            frontend_1.ModuleFE_Toaster.toastError(message, 3 * ts_common_1.Second);
        };
        this.translateValueToString = (value) => {
            if (!value)
                return '';
            if (value[0] === undefined || value[1] === undefined)
                return '';
            if (value[0] === value[1])
                return `${(value[0] || value[0] === 0) ? value[0] : ''}`;
            return `${value[0]} - ${value[1]}`;
        };
        this.translateStringIntoValue = (str) => {
            if (str.length === 0)
                return undefined;
            const split = str.split('-').map(i => i.trim());
            if (split.length !== 1 && split.length !== 2) {
                this.toastError('Parsing Error');
                return undefined;
            }
            //Potential 1 number
            if (split.length === 1 && split[0].length > 0) {
                const number = Number(split[0]);
                if (isNaN(number)) {
                    this.toastError('Value given is not a number');
                    return undefined;
                }
                return [number, number];
            }
            if (split.some(i => !i)) {
                this.toastError('Value must be single number or number - number');
                return undefined;
            }
            //Potential 2 numbers
            const range = split.map(i => Number(i));
            if (range.some(i => isNaN(i))) {
                this.toastError('Value given is not a range');
                return undefined;
            }
            if (range[0] > range[1]) {
                this.toastError('Range max can\'t be lower than range min');
                return undefined;
            }
            return range;
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        return state;
    }
    render() {
        var _a;
        const prop = this.props.prop;
        const value = (_a = this.props.value) !== null && _a !== void 0 ? _a : this.state.editable.get(prop);
        const valueAsString = this.translateValueToString(value);
        return React.createElement(ui_components_1.DefaultEditor_InputText, { editable: this.state.editable, prop: prop, className: this.props.className, disabled: this.props.disabled, placeholder: this.props.placeholder, type: 'text', value: valueAsString, showErrorTooltip: this.props.showErrorTooltip, onChange: async (value) => {
                var _a, _b;
                const _value = this.translateStringIntoValue(value);
                if ((0, ts_common_1.compare)(_value, this.state.editable.get(prop)))
                    return;
                if (_value) {
                    await this.state.editable.updateObj({ [prop]: _value });
                }
                if (!_value && this.props.shouldReset)
                    await this.state.editable.updateObj({ [prop]: [0, 0] });
                (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a);
            } });
    }
}
exports.QRangeInputV3 = QRangeInputV3;
