"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.atomPrinter = void 0;
const React = require("react");
const shared_1 = require("../../../../../../_entity/expression/shared");
const consts_1 = require("../../../../../../_entity/expression/shared/consts");
const frontend_1 = require("../../../../../../_entity/variable/frontend");
const frontend_2 = require("../../../../../../_entity/expression/frontend");
const shared_2 = require("../../../../../../_entity/value/shared");
const frontend_3 = require("../../../../../../_entity/value/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_4 = require("@nu-art/thunderstorm/frontend");
// Utils
const resolveBase = (leftHand) => {
    var _a, _b;
    const base = (_a = frontend_1.ModuleFE_Variable.cache.unique(leftHand.id)) !== null && _a !== void 0 ? _a : frontend_2.ModuleFE_Expression.cache.unique(leftHand.id);
    let attribute;
    if (leftHand.type === 'attribute')
        attribute = frontend_1.ModuleFE_Variable.cache.unique(leftHand.propertyId);
    if (!base)
        return 'Left hand base value not found';
    return attribute ? `${base.name}.${attribute.name}` : (_b = base.label) !== null && _b !== void 0 ? _b : base.name;
};
// Self and exists operators
const prettyPrintTemplate_SelfExists = (_case, leftHand) => {
    return React.createElement(React.Fragment, null,
        "Value of ",
        React.createElement("i", null, resolveBase(leftHand)));
};
// equals
const prettyPrintTemplate_Equals = (_case, leftHand) => {
    const base = resolveBase(leftHand);
    if (_case.rightHand[0] === shared_2.valueID_No)
        return React.createElement(React.Fragment, null,
            React.createElement("i", null, "no"),
            " ",
            base);
    if (_case.rightHand[0] === shared_2.valueID_Yes)
        return base;
    const displayValue = typeof _case.rightHand[0] === 'number' ? _case.rightHand[0] : frontend_3.ModuleFE_Value.cache.unique(_case.rightHand[0]);
    if (!(0, ts_common_1.exists)(displayValue))
        return 'Could not resolve value to print';
    return React.createElement(React.Fragment, null,
        base,
        " is ",
        React.createElement("i", null, typeof displayValue == 'number' ? displayValue : (0, shared_2.resolveValueDisplay)(displayValue)));
};
// Numeric operators
const prettyPrintTemplate_NumericRelation = (_case, leftHand) => {
    const base = resolveBase(leftHand);
    return React.createElement(React.Fragment, null,
        base,
        " ",
        React.createElement("i", null,
            _case.caseOperator,
            " ",
            _case.rightHand[0]));
};
// In set operators
const prettyPrintTemplate_InSet = (_case, leftHand) => {
    const base = resolveBase(leftHand);
    // Resolve all values to display in set
    const valueDisplays = _case.rightHand.map(id => {
        const value = frontend_3.ModuleFE_Value.cache.unique(id);
        if (!value)
            return `Could not print value, missing value with id ${id}`;
        return (0, shared_2.resolveValueDisplay)(value);
    });
    return React.createElement(React.Fragment, null,
        base,
        " is either: ",
        React.createElement("i", null, valueDisplays.join(', ')));
};
// Delta In range
const prettyPrintTemplate_InRange = (_case, leftHand) => {
    var _a;
    const base = resolveBase(leftHand);
    let range = _case.rightHand;
    if (typeof range[0] === 'string') {
        const value = frontend_3.ModuleFE_Value.cache.unique(range[0]);
        if (!value)
            return `Could not print value, missing value with id ${range[0]}`;
        if (value.type !== shared_2.valueType_SubRange && value.type !== shared_2.valueType_TimeRange)
            return `Could not print value, invalid value type with id ${range[0]}`;
        range = (_a = value.data.scope) !== null && _a !== void 0 ? _a : (0, shared_2.resolveValueDisplay)(value);
    }
    return React.createElement(React.Fragment, null,
        base,
        " in ",
        React.createElement("i", null, Array.isArray(range) ? range.join('-') : range));
};
// Delta operators
const prettyPrintTemplate_BaseDelta = (_case, leftHand) => {
    const base = resolveBase(leftHand);
    const baseDeltaOperator = (0, shared_1.getBaseDeltaOperator)(_case.caseOperator);
    if (!baseDeltaOperator || !consts_1.BaseDeltaLogic.includes(baseDeltaOperator))
        throw new ts_common_1.BadImplementationException('cannot use this template without delta operators');
    return `${baseDeltaOperator === consts_1.LogicType_Delta ? 'Delta' : 'Delta %'} of ${base}`;
};
const prettyPrintTemplate_ComplexDelta = (_case, leftHand) => {
    if (!consts_1.AllDeltaLogic.includes(_case.caseOperator))
        throw new ts_common_1.BadImplementationException('cannot use this template if not complex delta');
    const logicType = (0, shared_1.getDeltaTypeOperator)(_case.caseOperator);
    return React.createElement(React.Fragment, null,
        prettyPrintTemplate_BaseDelta(_case, leftHand),
        " is ",
        logicType,
        React.createElement("i", null, (0, ts_common_1.filterDuplicates)(_case.rightHand).join('-')));
};
// Normal range operators - FIXME: this might need a refactor
const prettyPrintTemplate_NormalRange = (_case, leftHand) => {
    var _a, _b, _c, _d, _e, _f;
    const base = resolveBase(leftHand);
    let relation = '';
    let upperScaleFactor = '';
    let lowerScaleFactor = '';
    if (!consts_1.NormalRangeOperators.includes(_case.caseOperator))
        throw new ts_common_1.BadImplementationException(`cannot use operator ${_case.caseOperator} with this template`);
    // get relation rendering
    switch (_case.caseOperator) {
        case consts_1.LogicType_InNormal:
            relation = 'within normal range';
            break;
        case consts_1.LogicType_BelowNormal:
            relation = 'below normal range';
            break;
        case consts_1.LogicType_AboveNormal:
            relation = 'above normal range';
            break;
    }
    if ((0, ts_common_1.exists)((_a = _case.factor) === null || _a === void 0 ? void 0 : _a.lower) && ((_b = _case.factor) === null || _b === void 0 ? void 0 : _b.lower) !== 1)
        lowerScaleFactor = `${(_c = _case.factor) === null || _c === void 0 ? void 0 : _c.lower}x`;
    if ((0, ts_common_1.exists)((_d = _case.factor) === null || _d === void 0 ? void 0 : _d.upper) && ((_e = _case.factor) === null || _e === void 0 ? void 0 : _e.upper) !== 1)
        upperScaleFactor = `${(_f = _case.factor) === null || _f === void 0 ? void 0 : _f.upper}x`;
    return React.createElement(React.Fragment, null,
        base,
        " is ",
        React.createElement("i", null,
            lowerScaleFactor,
            " ",
            upperScaleFactor,
            " ",
            relation));
};
const templateMapper = {
    [consts_1.LogicType_Equals]: prettyPrintTemplate_Equals,
    [consts_1.LogicType_Self]: prettyPrintTemplate_SelfExists,
    [consts_1.LogicType_Exists]: prettyPrintTemplate_SelfExists,
    [consts_1.LogicType_GreaterOrEqual]: prettyPrintTemplate_NumericRelation,
    [consts_1.LogicType_GreaterThan]: prettyPrintTemplate_NumericRelation,
    [consts_1.LogicType_LessOrEquals]: prettyPrintTemplate_NumericRelation,
    [consts_1.LogicType_LessThan]: prettyPrintTemplate_NumericRelation,
    [consts_1.LogicType_InSet]: prettyPrintTemplate_InSet,
    [consts_1.LogicType_In]: prettyPrintTemplate_InRange,
    [consts_1.LogicType_Delta]: prettyPrintTemplate_BaseDelta,
    [consts_1.LogicType_DeltaEquals]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_DeltaGreaterEquals]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_DeltaLesserEquals]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_DeltaIn]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_DeltaPercentage]: prettyPrintTemplate_BaseDelta,
    [consts_1.LogicType_DeltaPercentageLesserEquals]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_DeltaPercentageGreaterEquals]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_DeltaPercentageEquals]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_DeltaPercentageIn]: prettyPrintTemplate_ComplexDelta,
    [consts_1.LogicType_InNormal]: prettyPrintTemplate_NormalRange,
    [consts_1.LogicType_AboveNormal]: prettyPrintTemplate_NormalRange,
    [consts_1.LogicType_BelowNormal]: prettyPrintTemplate_NormalRange,
};
function printCase(atom, index) {
    const caseOperator = atom.cases[index].caseOperator;
    if (!caseOperator)
        return 'Missing operator';
    if (!atom.leftHand)
        return 'Missing left hand base';
    if (!atom.cases[index].rightHand)
        return 'Missing value';
    return React.createElement(frontend_4.LL_H_C, { className: 'pretty-print_case-container' },
        React.createElement("div", { className: 'pretty-print__atom-container content' },
            templateMapper[caseOperator](atom.cases[index], atom.leftHand),
            (atom.fallbackValue && atom.cases.length === 1) &&
                React.createElement("div", { className: 'pretty-print__atom-container' },
                    "otherwise: ",
                    React.createElement("i", null, atom.fallbackValue[0] === atom.fallbackValue[1]
                        ? atom.fallbackValue[0] : (0, ts_common_1.__stringify)(atom.fallbackValue)))),
        (0, ts_common_1.exists)(atom.cases[index].value) &&
            React.createElement("div", { className: 'pretty-print__atom-container value' },
                "return: ",
                React.createElement("i", null, atom.cases[index].value[0] === atom.cases[index].value[1]
                    ? atom.cases[index].value[0] : (0, ts_common_1.__stringify)(atom.cases[index].value))));
}
const atomPrinter = (atom) => {
    if (atom.cases.length === 1) {
        return printCase(atom, 0);
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'pretty-print__atom-multicase-label' }, "Any of:"),
        React.createElement("ul", { className: 'pretty-print__atom-cases' },
            atom.cases.map((_case, index) => React.createElement("li", { key: index }, printCase(atom, index))),
            atom.fallbackValue &&
                React.createElement("div", { className: 'pretty-print__atom-container' },
                    "otherwise: ",
                    React.createElement("i", null, atom.fallbackValue[0] === atom.fallbackValue[1]
                        ? atom.fallbackValue[0] : (0, ts_common_1.__stringify)(atom.fallbackValue)))));
};
exports.atomPrinter = atomPrinter;
