"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_User = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Validator_ModifiableProps = {
    firstName: ts_common_1.tsValidator_nonMandatoryString,
    surname: ts_common_1.tsValidator_nonMandatoryString,
    title: ts_common_1.tsValidator_nonMandatoryString,
    thumbnailUrl: ts_common_1.tsValidator_nonMandatoryString,
};
const Validator_GeneratedProps = {};
exports.DBDef_User = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1', '1.0.0'],
    dbKey: 'users',
    entityName: 'User',
    frontend: {
        group: 'account',
        name: 'user'
    },
    backend: {
        name: 'users'
    },
    dependencies: {
        _id: {
            dbKey: 'user-account--accounts',
            fieldType: 'string'
        }
    }
};
