"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Module_ExpressionPrettierUtils = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("../../../../../_entity/value/frontend");
const frontend_2 = require("../../../../../_entity/expression/frontend");
const frontend_3 = require("../../../../../_entity/variable/frontend");
class Module_ExpressionPrettierUtils_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.prettifyExpression = (_expr, terms, withOp = true) => {
            _expr = _expr.replace(/[{}]/g, '');
            return this.replaceValuesIndex(_expr, terms, '_id', withOp);
        };
        this.unPrettifyExpression = (_expr, terms) => {
            return this.replaceValuesIndex(_expr, terms);
        };
        this.replaceValuesIndex = (_expr, terms, searchedProp, withOp = true) => {
            const allDeps = exports.Module_ExpressionPrettierUtils.findAllDependencies(_expr);
            _expr = allDeps.reduce((expression, foundResults) => {
                switch (foundResults.type) {
                    case 'enum':
                        expression = exports.Module_ExpressionPrettierUtils.replaceValues(expression, foundResults.results, searchedProp, withOp);
                        break;
                    case 'var':
                        expression = exports.Module_ExpressionPrettierUtils.replaceVars(expression, foundResults.results, searchedProp, withOp);
                        break;
                    case 'expr':
                        expression = exports.Module_ExpressionPrettierUtils.replaceExpression(expression, foundResults.results, searchedProp, withOp);
                        break;
                    case 'attr':
                        expression = exports.Module_ExpressionPrettierUtils.replaceVarsWithMultiParams(expression, foundResults.results, searchedProp, withOp, 'attr');
                        break;
                    case 'precipitating':
                        expression = exports.Module_ExpressionPrettierUtils.replaceVarsWithMultiParams(expression, foundResults.results, searchedProp, withOp, 'precipitating');
                        break;
                    case 'relieving':
                        expression = exports.Module_ExpressionPrettierUtils.replaceVarsWithMultiParams(expression, foundResults.results, searchedProp, withOp, 'relieving');
                        break;
                    case 'term':
                        expression = this.replaceTerms(expression, foundResults.results, terms);
                        break;
                }
                return expression;
            }, _expr);
            return _expr;
        };
        this.replaceValues = (expr, results, searchedProp = 'data', withOp = true) => {
            let _expr = expr;
            if (!results.length)
                return _expr;
            results.map((_val) => {
                var _a;
                const value = frontend_1.ModuleFE_Value.cache.find(item => {
                    var _a, _b;
                    if (searchedProp === '_id')
                        return item._id === _val;
                    return ((_b = (_a = item.data) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === _val.toLowerCase();
                });
                if (value) {
                    _val = withOp ? _val : `enum(${_val})`;
                    _expr = _expr.replace(_val, searchedProp === '_id' ? (_a = value.data) === null || _a === void 0 ? void 0 : _a.value : value._id);
                }
            });
            return _expr;
        };
        this.replaceExpression = (expr, results, searchedProp = 'label', withOp = true) => {
            let _expr = expr;
            if (!results.length)
                return _expr;
            results.map((currExpr) => {
                const expression = frontend_2.ModuleFE_Expression.cache.find(item => item[searchedProp] === currExpr.toLowerCase());
                if (expression) {
                    currExpr = withOp ? currExpr : `expr(${currExpr})`;
                    const replaceValue = expression[searchedProp === 'label' ? '_id' : 'label'];
                    _expr = _expr.replace(currExpr, replaceValue);
                }
            });
            return _expr;
        };
        this.replaceVars = (expr, results, searchedProp = '_nameInLowercase', withOp = true) => {
            let _expr = expr;
            if (!results.length)
                return _expr;
            results.map((_var) => {
                const variable = frontend_3.ModuleFE_Variable.cache.find(item => item[searchedProp] === _var.toLowerCase());
                if (variable) {
                    _var = withOp ? _var : `var(${_var})`;
                    _expr = _expr.replace(_var, variable[searchedProp === '_nameInLowercase' ? '_id' : 'name']);
                }
            });
            return _expr;
        };
        this.replaceVarsWithMultiParams = (expr, results, searchedProp = '_nameInLowercase', withOp = true, type) => {
            let _expr = expr;
            if (!results.length)
                return _expr;
            results.map((variablesString) => {
                let variablesIds = (0, ts_common_1.filterInstances)(variablesString.split(',').map(_var => {
                    var _a;
                    return (_a = frontend_3.ModuleFE_Variable.cache.find(item => item[searchedProp] === _var.toLowerCase().trim())) === null || _a === void 0 ? void 0 : _a[searchedProp === '_nameInLowercase' ? '_id' : 'name'];
                })).join(',');
                if (!withOp) {
                    if (searchedProp === '_id') {
                        const _midExpr = variablesIds.split(',');
                        if (type !== 'attr')
                            _midExpr.splice(_midExpr.length - 1, 0, `${type} factors`);
                        variablesIds = _midExpr.join('.');
                        variablesString = `${type}(${variablesString})`;
                    }
                }
                _expr = _expr.replace(variablesString, variablesIds);
            });
            return _expr;
        };
        this.replaceTerms = (expr, results, terms) => {
            let _expr = expr;
            if (!results.length || !terms)
                return _expr;
            results.map((termStr, index) => {
                const regex = /[TA](\d+)/i;
                let termIndex = termStr.match(regex);
                termIndex = termIndex && termIndex[1] ? parseInt(termIndex[1], 10) : null;
                if (termIndex && terms[termIndex - 1]) {
                    _expr = _expr.replace(results[index], terms[termIndex - 1].expression);
                }
                else
                    _expr = _expr.replace(results[index], 'Error');
            });
            return _expr;
        };
        this.findAllDependencies = (_expr) => [
            { results: this.findDependencies(_expr, 'var'), type: 'var' },
            { results: this.findDependencies(_expr, 'enum'), type: 'enum' },
            { results: this.findDependencies(_expr, 'attr'), type: 'attr' },
            { results: this.findDependencies(_expr, 'precipitating'), type: 'precipitating' },
            { results: this.findDependencies(_expr, 'relieving'), type: 'relieving' },
            { results: this.findDependencies(_expr, 'expr'), type: 'expr' },
            { results: this.findTerms(_expr), type: 'term' }
        ];
        this.findDependencies = (expr, type) => {
            const regex = new RegExp(`${type}\\((.*?)\\)(\\.\\w+)*`, 'g');
            let match;
            const results = [];
            while ((match = regex.exec(expr)) !== null) {
                results.push(match[1]);
            }
            return results;
        };
        this.extractOperatorsBetweenTerms = (expression) => {
            const regex = new RegExp(/{[^{}]+}/g);
            return expression.replace(regex, ' ').trim().split(/\s+/);
        };
    }
    findTerms(expr) {
        const pattern = /(T|A)\d+/gi;
        const matches = expr.match(pattern);
        return matches || [];
    }
}
exports.Module_ExpressionPrettierUtils = new Module_ExpressionPrettierUtils_Class();
