"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceConfig_ExpressionSearch = exports.workspaceKey_ExpressionSearch = void 0;
exports.workspaceKey_ExpressionSearch = 'workspace-key--expression-search';
exports.WorkspaceConfig_ExpressionSearch = {
    name: 'expression-search',
    key: 'horizontalSpace',
    visible: true,
    factor: 1,
    min: -1,
    data: {
        panels: [
            {
                name: 'LeftPanel', key: 'searchPanel', visible: true, factor: 3.5 / 5, min: 500,
                data: {}
            },
            {
                name: 'RightPanel', key: 'dataViewer', visible: true, factor: 1.5 / 5, min: 500,
                data: {}
            },
        ]
    }
};
