"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_TimeRangeValueEditor = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../../../../../../../_entity/value/frontend");
const time_range_editor_1 = require("../time-range-editor");
class Component_TimeRangeValueEditor extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.renderBody = () => {
            const editableTimeRangeData = this.state.editable.editProp('data', {});
            const editableTimeRangeValue = editableTimeRangeData.editProp('timeRange', []);
            return React.createElement(time_range_editor_1.Editor_TimeRange, { editable: editableTimeRangeValue });
        };
    }
    //FIXME: this is a real hack, there's no way to show validation results in this case cause the save is nested inside the
    // UI variable save..
    componentDidUpdate() {
        var _a, _b, _c;
        try {
            // don't validate
            if (!((_a = this.state.editable.item.data) === null || _a === void 0 ? void 0 : _a.timeRange.some(value => (0, ts_common_1.exists)(value))))
                return;
            frontend_2.ModuleFE_Value.validateImpl(this.state.editable.item);
            // @ts-ignore
            this.state.editable.validationResults = undefined;
        }
        catch (err) {
            // @ts-ignore
            if (!(0, ts_common_1.compare)((_c = (_b = this.state.editable.validationResults) === null || _b === void 0 ? void 0 : _b.results) !== null && _c !== void 0 ? _c : {}, err.result)) {
                // @ts-ignore
                this.state.editable.validationResults = {
                    autoSave: false,
                    editing: !!this.state.editable.item._id,
                    results: err.result
                };
                this.forceUpdate();
            }
        }
    }
    render() {
        return React.createElement(frontend_1.TS_Card, null,
            this.renderBody(),
            (0, ts_common_1.exists)(this.props.deleteItem) && React.createElement(styles_1.ICONS.x.component, { onClick: this.props.deleteItem }));
    }
}
exports.Component_TimeRangeValueEditor = Component_TimeRangeValueEditor;
