"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_DiseaseProfile_Header = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@app/hcs/frontend");
require("./Page_DiseaseProfile_Header.scss");
const shared_components_1 = require("../../shared-components");
const renderers_1 = require("../../renderers");
const dialogs_1 = require("../../dialogs");
const styles_1 = require("@app/styles");
const ts_common_1 = require("@nu-art/ts-common");
const _entity_1 = require("../../../_entity");
const frontend_3 = require("@nu-art/ts-workspace/frontend");
const Component_FocusedEntityRef_1 = require("@nu-art/ts-focused-object/frontend/components/Component_FocusedEntityRef");
class Page_DiseaseProfile_Header extends frontend_1.ProtoComponent {
    constructor() {
        // ######################### Life Cycle #########################
        super(...arguments);
        this.__onDiseaseProfileUpdated = (...params) => {
            const dps = (0, ts_common_1.asArray)(params[1]);
            const currentDP = dps.find(dp => dp._id === this.state.selectedDiseaseProfileId);
            if (currentDP)
                //If the current DP was updated, re-render
                this.forceUpdate();
        };
        // ######################### Logic #########################
        this.onDiseaseProfileSelected = (disease) => {
            //Picked same disease
            if (disease._id === this.state.selectedDiseaseProfileId)
                return;
            const selected = Object.assign({}, this.getQueryParam('selected', {}));
            selected[_entity_1.DBDef_DiseaseProfile.dbKey] = disease._id;
            return this.setQueryParam('selected', selected);
            //FIXME: (12/05/2024) the whole "stop before actually picking if we already have a selected item"
            // simply does not work, the dropdown shows the new item anyway and doesn't return to the
            // old one on cancel.
            // This should be a TS_Dropdown functionality, for now i'm disabling this feature.
            // //First disease picked
            // if (!this.state.selectedDiseaseProfileId) {
            // 	selected[DBDef_DiseaseProfile.dbKey] = disease._id;
            // 	return this.setQueryParam('selected', selected);
            // }
            //
            //
            // //Picking new disease
            // WarningDialog.show({
            // 	title: 'Switch Disease',
            // 	executeButtonText: 'Switch',
            // 	warningMessages: ['You are about to switch diseases', 'Are you sure?'],
            // 	onConfirm: () => {
            // 		selected[DBDef_DiseaseProfile.dbKey] = disease._id;
            // 		this.setQueryParam('selected', selected);
            // 	},
            // 	onCancel: () => {
            // 		this.forceUpdate();
            // 	}
            // });
        };
        this.onCleanWorkspace = () => {
            frontend_2.WarningDialog.showDelete({
                title: 'Clean Disease Profile Workspace',
                executeButtonText: 'Clean',
                warningMessages: ['Delete disease profile workspace?'],
                onConfirm: async () => {
                    const keys = ['disease-profiles', 'disease-profile-var-list', 'disease-profile-likelihood'];
                    for (const key of keys) {
                        await frontend_3.ModuleFE_Workspace.deleteWorkspaceByKey(key);
                    }
                    location.reload();
                }
            });
        };
        this.renderDiseaseComparatorButton = () => {
            if (!this.state.selectedDiseaseProfileId)
                return;
            const dp = _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.state.selectedDiseaseProfileId);
            if (!dp) {
                this.logError('Will not render disease profile comparator button', `Could not get DP for id ${this.state.selectedDiseaseProfileId}`);
                return;
            }
            return React.createElement(frontend_1.TS_Button, { onClick: () => {
                    dialogs_1.Dialog_DiseaseProfileComparator.show([dp]);
                } }, "Compare DP With");
        };
    }
    deriveStateFromProps(nextProps, state) {
        state.selectedDiseaseProfileId = this.getQueryParam('selected', {})[_entity_1.DBDef_DiseaseProfile.dbKey];
        return state;
    }
    // ######################### Render #########################
    renderDropdown() {
        const selectedDP = _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.state.selectedDiseaseProfileId);
        return React.createElement(shared_components_1.DPDropDowns.DiseaseProfile, { selected: selectedDP, onSelected: this.onDiseaseProfileSelected, sortBy: [(dp) => dp.label.toLowerCase()], renderer: item => {
                var _a, _b;
                const variable = frontend_2.ModuleFE_Variable.cache.unique(item.variableId);
                return React.createElement("div", { className: 'disease-node' },
                    React.createElement("div", { className: 'disease-node__initials-container' }, (0, renderers_1.renderDpIcon)(item)),
                    React.createElement("div", { className: 'disease-node__label' }, (_b = (_a = item.label) !== null && _a !== void 0 ? _a : variable === null || variable === void 0 ? void 0 : variable.name) !== null && _b !== void 0 ? _b : 'Name not found'));
            } });
    }
    renderButtons() {
        const selectedDP = _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.state.selectedDiseaseProfileId);
        return React.createElement(frontend_1.LL_H_C, { className: 'disease-dropdown__buttons' },
            React.createElement(frontend_1.Show, null,
                React.createElement(frontend_1.Show.If, { condition: (0, ts_common_1.exists)(selectedDP) },
                    React.createElement(styles_1.ICONS.edit.component, { onClick: () => dialogs_1.Dialog_DiseaseProfileEditor.show(selectedDP) })),
                React.createElement(frontend_1.Show.If, { condition: true },
                    React.createElement("div", { className: 'add-disease-profile', onClick: () => dialogs_1.Dialog_DiseaseProfileEditor.show() }, "+"),
                    React.createElement(frontend_1.TS_Toggler, { options: ['Show Alerts', 'Ignore Alerts'], alwaysSelected: true, defaultValue: _entity_1.ModuleFE_DiseaseProfile.showDiseaseProfileErrorAlerts.get() ? 'Show Alerts' : 'Ignore Alerts', onValueChange: (val) => {
                            _entity_1.ModuleFE_DiseaseProfile.showDiseaseProfileErrorAlerts.set(val === 'Show Alerts');
                            _entity_1.dispatch_onDiseaseProfilesShowErrorAlertsUpdated.dispatchUI();
                        }, value: _entity_1.ModuleFE_DiseaseProfile.showDiseaseProfileErrorAlerts.get() ? 'Show Alerts' : 'Ignore Alerts' }),
                    this.renderDiseaseComparatorButton()),
                React.createElement(frontend_1.Show.If, { condition: (0, ts_common_1.exists)(selectedDP) },
                    React.createElement(Component_FocusedEntityRef_1.Component_FocusedEntityRef, { focusedEntities: [{ dbKey: _entity_1.DBDef_DiseaseProfile.dbKey, itemId: selectedDP._id }] })),
                React.createElement(frontend_1.Show.If, { condition: true },
                    React.createElement(frontend_1.TS_Button, { style: { marginLeft: 'auto' }, onClick: () => {
                            const diseaseTagId = frontend_2.ConfigKeyFE_DiseasesTagId.get();
                            frontend_2.Dialog_VariableEditor.show(diseaseTagId);
                        } }, "Add Variable"))));
    }
    render() {
        return React.createElement(frontend_2.Component_SubHeader, { cleanWorkspace: this.onCleanWorkspace },
            React.createElement(frontend_1.LL_H_C, { className: 'disease-dropdown' },
                this.renderDropdown(),
                this.renderButtons()));
    }
}
Page_DiseaseProfile_Header.defaultProps = {
    keys: ['selected'],
};
exports.Page_DiseaseProfile_Header = Page_DiseaseProfile_Header;
