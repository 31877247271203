"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceItem_EditorRendererV2 = void 0;
const React = require("react");
require("./ResourceItem_EditorRendererV2.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
const ts_common_1 = require("@nu-art/ts-common");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const shared_components_1 = require("../../shared-components");
const app_multis_select_1 = require("../../shared-components/app-multis-select");
const consts_1 = require("@app/styles/frontend/ui/ui-manager/consts");
const _entity_1 = require("../../../_entity");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
const ui_components_2 = require("../../../../_entity/tag/frontend/ui-components");
const ui_components_3 = require("../../../../_entity/variable/frontend/ui-components");
const ui_components_4 = require("../../../../_entity/resource/frontend/ui-components");
class ResourceItem_EditorRendererV2 extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        this.multiselectPropsTags = {
            // label: 'Select Tags Properties',
            itemRenderer: (tagId) => React.createElement(React.Fragment, null,
                React.createElement(styles_1.ICONS.x.component, { onClick: async () => {
                        if (tagId)
                            await this.removeVariableId(tagId, 'tagsProperties');
                    }, className: 'ts-icon__small' }),
                _entity_1.ModuleFE_Tag.cache.unique(tagId).label),
            selectionRenderer: DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.selector(shared_components_1.HCSDropDowns.Tag)
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // private selectedIcon = (icon: Icon) => {
    // 	this.props.editable.update('icon', icon.key);
    // };
    async removeVariableId(varId, prop) {
        const propertiesId = (0, ts_common_1.cloneArr)(this.props.editable.item[prop]);
        propertiesId.splice(propertiesId.indexOf(varId), 1);
        await this.props.editable.updateObj({ [prop]: propertiesId });
        this.forceUpdate();
    }
    render() {
        const editMode = this.state.editMode;
        const sourceTagId = _entity_1.ConfigKeyFE_SourceTagId.get();
        if (!sourceTagId || sourceTagId.length === 0)
            throw new ts_common_1.BadImplementationException('Can\'t find source tag id from AppConfig collection!');
        return (React.createElement("div", { className: 'match_parent resource-editor' },
            React.createElement(frontend_1.LL_V_L, { className: 'container' },
                React.createElement("div", { className: 'form-title' }, "Resource Info"),
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Resource Name' },
                    React.createElement(ui_components_1.DefaultEditor_InputText, { disabled: !editMode, editable: this.state.editable, prop: 'label', showErrorTooltip: true })),
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Type' },
                    React.createElement(ui_components_4.DropDown_ResourceType.editable, { editable: this.state.editable, prop: 'type', showErrorTooltip: true, disabled: !editMode })),
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Tag' },
                    React.createElement(ui_components_2.DropDown_Tag.editable, { editable: this.state.editable, prop: 'tagId', queryFilter: tag => tag.tagIds.length === 1 && tag.tagIds.includes(sourceTagId), disabled: !editMode, showErrorTooltip: true, caret: consts_1.QuaiManager_DropDownCaret })),
                this.renderVarEditor(),
                this.renderTagsEditor())));
    }
    renderTagsEditor() {
        var _a, _b;
        const editable = this.props.editable;
        const editMode = this.state.editMode;
        if (!editMode)
            return React.createElement(frontend_1.LL_V_L, null,
                ((_a = editable.item.tagsProperties) === null || _a === void 0 ? void 0 : _a.length) ?
                    React.createElement("div", { className: 'checkbox-titles' }, "Tags Properties") : null,
                React.createElement(frontend_1.LL_H_C, { className: 'match_width variable-list' }, (_b = editable.item.tagsProperties) === null || _b === void 0 ? void 0 : _b.map((tagId, index) => {
                    const tag = _entity_1.ModuleFE_Tag.cache.unique(tagId);
                    if (!tag)
                        return '';
                    return (React.createElement(frontend_1.LL_H_C, { key: index, className: 'tag' }, tag.label));
                })));
        return React.createElement(app_multis_select_1.HCS_MultiSelect.Tag, { editable: editable, prop: 'tagsProperties' });
    }
    renderVarEditor() {
        var _a, _b;
        const editable = this.props.editable;
        const editMode = this.state.editMode;
        if (!editMode)
            return React.createElement(frontend_1.LL_V_L, null,
                ((_a = editable.item.variableProperties) === null || _a === void 0 ? void 0 : _a.length) ?
                    React.createElement("div", { className: 'checkbox-titles' }, "Variables Properties") : null,
                React.createElement(frontend_1.LL_H_C, { className: 'match_width variable-list' }, (_b = editable.item.variableProperties) === null || _b === void 0 ? void 0 : _b.map((varId, index) => {
                    const variable = _entity_1.ModuleFE_Variable.cache.unique(varId);
                    if (!variable)
                        return '';
                    return (React.createElement(frontend_1.LL_H_C, { key: index, className: 'tag' }, variable.name));
                })));
        return React.createElement(ui_components_3.MultiSelect_Variable, { editable: editable, prop: 'variableProperties' });
    }
}
exports.ResourceItem_EditorRendererV2 = ResourceItem_EditorRendererV2;
