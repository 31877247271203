"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip_VariableInformation = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Tooltip_VariableInformation.scss");
const resolvers_1 = require("../../resolvers");
const shared_components_1 = require("../../../shared-components");
const _entity_1 = require("../../../../_entity");
class Tooltip_VariableInformation extends frontend_1.ComponentSync {
    constructor() {
        // constructor(p: Props) {
        // 	super(p);
        // 	this.logger.setMinLevel(LogLevel.Info);
        // }
        super(...arguments);
        //######################### Logic #########################
        this.upsertRef = async (ref) => {
            await _entity_1.ModuleFE_Reference.v1.upsert(ref).executeSync();
        };
        //######################### Render #########################
        this.renderFailedIndexAlert = (ref) => {
            return React.createElement(frontend_1.TS_BusyButton, { className: 'ref-warning', onClick: () => this.upsertRef(ref) },
                React.createElement("span", { className: 'warning-icon' }, "!"),
                React.createElement("div", { className: 'warning-message' }, "Failed to index this ID"),
                React.createElement("div", { className: 'hover-message' }, "Retry to index"));
        };
        //######################### Ref Renderers
        this.renderSnomedRef = (ref) => {
            var _a, _b, _c, _d;
            let icd10 = (_a = ref.data.icd10) === null || _a === void 0 ? void 0 : _a.split('\r\n');
            if (icd10 === null || icd10 === void 0 ? void 0 : icd10.length) {
                icd10 = icd10.filter(item => item.trim() !== '').map(item => item.trim());
            }
            return React.createElement(frontend_1.LL_V_L, { key: ref._id, className: 'ref-item' },
                ref.creationStatus === 'failed' && this.renderFailedIndexAlert(ref),
                React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("div", { className: 'field-title' }, "Snomed ID:"),
                    this.renderSnomedLink(ref)),
                ((_c = (_b = ref.data.names) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.length) && React.createElement(frontend_1.LL_H_C, { style: { alignItems: 'flex-start' }, className: 'field' },
                    React.createElement("div", { className: 'field-title' }, "Snomed Name:"),
                    React.createElement("div", { className: 'field-data' }, `${(_d = ref.data.names) === null || _d === void 0 ? void 0 : _d[0]}`)),
                icd10 && React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("div", { className: 'field-title' }, "Snomed ICD10:"),
                    React.createElement(frontend_1.LL_H_C, { className: 'field-data' }, icd10.map((item, i) => React.createElement("div", { key: `${item}-${i}`, className: 'field-data__item' }, item + (i === icd10.length - 1 ? '' : ','))))));
        };
        this.renderSnomedLink = (ref) => {
            return React.createElement(frontend_1.TS_Link, { url: (0, resolvers_1.resolveSnomedLink)(ref.data.id), target: '_blank', className: 'ref-link snomed' }, ref.data.id);
        };
        this.renderLoincRef = (ref) => {
            return React.createElement(frontend_1.LL_V_L, { key: ref._id, className: 'ref-item' },
                ref.creationStatus === 'failed' && this.renderFailedIndexAlert(ref),
                React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("div", { className: 'field-title' }, "Loinc ID:"),
                    this.renderLoincLink(ref)),
                React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("div", { className: 'field-title' }, "Loinc Name:"),
                    React.createElement("div", { className: 'field-data' }, ref.data.name)));
        };
        this.renderLoincLink = (ref) => {
            return React.createElement(frontend_1.TS_Link, { url: (0, resolvers_1.resolveLoincLink)(ref.data.id), target: '_blank', className: 'ref-link loinc' }, ref.data.id);
        };
        this.renderICDRef = (ref) => {
            return React.createElement(frontend_1.LL_V_L, { key: ref._id, className: 'ref-item' },
                React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("div", { className: 'field-title' }, "ICD code:"),
                    React.createElement("div", { className: 'field-data' }, ref.data.id)));
        };
        this.renderVariableData = () => {
            var _a, _b;
            const variable = this.props.variable;
            const synonyms = (_a = this.state.nlpData) === null || _a === void 0 ? void 0 : _a.synonyms;
            const units = (_b = this.state.measurementUnit) === null || _b === void 0 ? void 0 : _b.unitAsString.split('/').map(unit => {
                return unit.trim();
            });
            // if one of the units don't translate into a dimension, don't show dimensions.
            const dimensionsArray = units === null || units === void 0 ? void 0 : units.map((unit) => {
                return _entity_1.ModuleFE_MeasurementUnit.getDimensionForUnit(unit);
            });
            const hasDimensions = dimensionsArray === null || dimensionsArray === void 0 ? void 0 : dimensionsArray.reduce((result, unit) => {
                result && (result = !!unit);
                return result;
            }, true);
            const dimensions = !units ? false : !hasDimensions ? 'WIP' : dimensionsArray === null || dimensionsArray === void 0 ? void 0 : dimensionsArray.join('/');
            const specimen = this.state.specimen;
            return React.createElement(frontend_1.LL_V_L, { className: 'variable-info-tooltip__var-data' },
                React.createElement(shared_components_1.Component_TagViewer, { tags: this.state.tags }),
                !!(synonyms === null || synonyms === void 0 ? void 0 : synonyms.length) && React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("span", { className: 'field-title' }, "Synonyms:"),
                    React.createElement("span", { className: 'field-data' }, (synonyms === null || synonyms === void 0 ? void 0 : synonyms.join(', ')) || 'N/A')),
                variable.perspective && React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("span", { className: 'field-title' }, "Perspective:"),
                    React.createElement("span", { className: 'field-data' }, variable.perspective || 'N/A')),
                variable.role && React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("span", { className: 'field-title' }, "Role:"),
                    React.createElement("span", { className: 'field-data' }, variable.role || 'N/A')),
                units && React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("span", { className: 'field-title' }, "Units:"),
                    React.createElement("span", { className: 'field-data' }, (units === null || units === void 0 ? void 0 : units.join('/')) || 'N/A')),
                dimensions && React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("span", { className: 'field-title' }, "Dimensions:"),
                    React.createElement("span", { className: 'field-data' }, dimensions || 'N/A')),
                specimen && React.createElement(frontend_1.LL_H_C, { className: 'field' },
                    React.createElement("span", { className: 'field-title' }, "Specimen:"),
                    React.createElement("span", { className: 'field-data' }, specimen || 'N/A')));
        };
        this.renderRefData = () => {
            const refs = (0, ts_common_1.filterInstances)(this.state.references);
            if (!refs || !refs.length)
                return React.createElement(frontend_1.LL_V_L, { className: 'variable-info-tooltip__refs-data' },
                    React.createElement("div", { className: 'ref-item' },
                        React.createElement("div", { className: 'ref-link' }, "No references for this variable")));
            return React.createElement(frontend_1.LL_V_L, { className: 'variable-info-tooltip__refs-data' }, refs.map(ref => {
                const type = ref === null || ref === void 0 ? void 0 : ref.type;
                switch (type) {
                    case 'snomed': {
                        return this.renderSnomedRef(ref);
                    }
                    case 'loinc': {
                        return this.renderLoincRef(ref);
                    }
                    case 'icdCode': {
                        return this.renderICDRef(ref);
                    }
                    default:
                        return '';
                }
            }));
        };
    }
    //######################### Life Cycle #########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        const variable = nextProps.variable;
        const value = _entity_1.ModuleFE_Value.cache.unique(variable.valueId);
        state.nlpData = _entity_1.ModuleFE_NLPData.cache.unique(variable.nlpDataId);
        (_a = state.references) !== null && _a !== void 0 ? _a : (state.references = _entity_1.ModuleFE_Reference.cache.filter(ref => variable.referenceIds.includes(ref._id)));
        state.specimen = (_b = nextProps.variable.otherProperties) === null || _b === void 0 ? void 0 : _b['specimen'];
        state.tags = nextProps.tagIds || variable.tagIds;
        if ((value === null || value === void 0 ? void 0 : value.type.toLowerCase()) === _entity_1.valueType_Range.toLowerCase() || (value === null || value === void 0 ? void 0 : value.type.toLowerCase()) === _entity_1.valueType_SubRange) {
            state.measurementUnit = _entity_1.ModuleFE_MeasurementUnit.cache.unique(value.data.unitsId);
        }
        return state;
    }
    render() {
        return React.createElement(React.Fragment, null,
            this.renderVariableData(),
            this.renderRefData());
    }
}
exports.Tooltip_VariableInformation = Tooltip_VariableInformation;
