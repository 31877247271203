"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_AddNormalVariable = exports.NormalValuesTable = exports.NormalRangeEditor = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const shared_components_1 = require("../../../shared-components");
const styles_1 = require("@app/styles");
const expressionToValue_1 = require("../expressionToValue/expressionToValue");
const Component_TermsAndExpression_1 = require("../../../expressions/ui/expression-builder/components/terms-and-expressions/Component_TermsAndExpression");
const normalRangeToExpressionFE_1 = require("../normalRangeToExpressionFE");
require("./Editors.scss");
const _entity_1 = require("../../../../_entity");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const fullRangeToString = (fullRange) => {
    return `${fullRange === null || fullRange === void 0 ? void 0 : fullRange[0]} - ${fullRange === null || fullRange === void 0 ? void 0 : fullRange[1]}`;
};
const stringToFullRange = (str) => {
    const values = str.split('-').map(item => item.trim()).map(item => Number(item));
    return [values[0] || 0, values[1] || 0];
};
const TabId_SimpleEditor = 'simple';
const TabId_ComplexEditor = 'complex';
class NormalRangeEditor extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        // ######################## Life Cycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.setFullRange = (range) => {
            this.props.value.data.fullRange = range;
            const fullRange = fullRangeToString(range);
            this.setState({ fullRange });
        };
        this.setFullRangeString = (fullRange) => {
            try {
                this.props.value.data.fullRange = stringToFullRange(fullRange);
            }
            finally {
                this.setState({ fullRange });
            }
        };
        this.onCreateMeasurementUnit = async (text, items) => {
            if (items.length)
                return;
            const titles = {
                inProgress: 'Creating New Measurement Unit',
                success: 'Measurement Unit Created',
                failed: 'Measurement Unit Creation Failed'
            };
            await (0, frontend_1.performAction)(async () => {
                const unit = {
                    unitAsString: text,
                };
                const newUnit = await _entity_1.ModuleFE_MeasurementUnit.v1.upsert(unit).executeSync();
                this.props.value.data.measurementUnit = newUnit;
                this.forceUpdate();
            }, { type: 'notification', notificationLabels: titles });
        };
        // ######################## Render ########################
        this.renderFullRange = () => {
            const range = stringToFullRange(this.state.fullRange);
            return React.createElement(frontend_1.LL_H_C, { className: 'dialog__main__section-item' },
                React.createElement(frontend_1.LL_V_L, null,
                    React.createElement("div", { className: 'dialog__field-title' }, "Full Range"),
                    React.createElement(frontend_1.TS_Input, { className: 'range-full', type: 'text', value: this.state.fullRange, onChange: fullRange => {
                            this.setFullRangeString(fullRange);
                        } })),
                React.createElement(frontend_1.LL_V_L, null,
                    React.createElement("div", { className: 'dialog__field-title' }, "Full Range - Min"),
                    React.createElement(frontend_1.TS_Input, { className: 'range-min', type: 'number', value: String(range[0]), onChange: value => this.setFullRange([Number(value), range[1]]) })),
                React.createElement(frontend_1.LL_V_L, null,
                    React.createElement("div", { className: 'dialog__field-title' }, "Full Range - Max"),
                    React.createElement(frontend_1.TS_Input, { className: 'range-max', type: 'number', value: String(range[1]), onChange: value => this.setFullRange([range[0], Number(value)]) })));
        };
        this.renderUnitsDropDown = () => {
            var _a;
            const data = this.props.value.data;
            return React.createElement(frontend_1.LL_H_C, { className: 'dialog__main__section-item' },
                React.createElement("div", { className: 'dialog__field-title' }, "Units"),
                React.createElement(shared_components_1.HCSDropDowns.MeasurementUnit, { selected: (_a = data.measurementUnit) === null || _a === void 0 ? void 0 : _a._id, onSelected: (units) => {
                        this.props.value.data.measurementUnit = units;
                        this.forceUpdate();
                    }, onNoMatchingSelectionForString: this.onCreateMeasurementUnit }));
        };
        this.renderValueBuildingTabs = () => {
            return React.createElement(frontend_1.TS_Tabs, { selectedTabId: this.state.selectedTag, onSelected: selectedId => this.setState({ selectedTag: selectedId }), tabs: [
                    {
                        uid: TabId_SimpleEditor,
                        title: 'Edit Simple Range',
                        content: () => React.createElement(NormalValuesTable, { onChange: () => {
                                const expression = (0, ts_common_1.merge)(this.state.editable.item, (0, normalRangeToExpressionFE_1.normalRangeToExpressionSingleFE)({ value: this.props.value }));
                                if (expression)
                                    return this.state.editable.updateItem(expression);
                                this.reDeriveState();
                            }, value: this.props.value, editable: true }),
                        disabled: this.state.disableTable
                    },
                    {
                        uid: TabId_ComplexEditor,
                        title: 'Edit Complex Range',
                        content: () => React.createElement(frontend_1.Grid, { className: 'expression-grid' },
                            React.createElement(Component_TermsAndExpression_1.Component_TermsAndExpression, { editable: this.state.editable, hideDnD: true }))
                    }
                ] });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        var _c;
        state = super.deriveStateFromProps(nextProps, state);
        state.fullRange = fullRangeToString(((_a = nextProps.value.data) === null || _a === void 0 ? void 0 : _a.fullRange) || [0, 0]);
        state.uiExpression = state.editable.item;
        state.editable.setOnChanged(async (editable) => {
            const _convertedData = (0, expressionToValue_1.expressionToValue)(editable.item);
            nextProps.value.data = Object.assign(Object.assign({}, nextProps.value.data), _convertedData);
            this.reDeriveState({ editable });
        });
        let convertedData;
        if (state.uiExpression) {
            convertedData = (0, expressionToValue_1.expressionToValue)(state.uiExpression);
        }
        (_b = (_c = nextProps.value).data) !== null && _b !== void 0 ? _b : (_c.data = Object.assign(Object.assign({}, nextProps.value.data), convertedData));
        if (!convertedData)
            state.disableTable = true;
        if (state.disableTable && convertedData)
            state.disableTable = false;
        if (state.disableTable)
            state.selectedTag = TabId_ComplexEditor;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'normal-range-editor' },
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section', style: { flexShrink: 0, maxHeight: '100px' } },
                this.renderUnitsDropDown(),
                this.renderFullRange()),
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section tabs-container' }, this.renderValueBuildingTabs()));
    }
}
exports.NormalRangeEditor = NormalRangeEditor;
class NormalValuesTable extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.normalHeader = 'Standard Range';
        // ######################## Logic ########################
        this.handleHeaderContextMenu = (e, variableId) => {
            if (!this.props.editable)
                return;
            (0, frontend_1.stopPropagation)(e);
            const menuItems = [{
                    label: 'Add Variable',
                    onClick: () => {
                        Dialog_AddNormalVariable.show(variable => {
                            this.props.value.data.declarationVarIds.push(variable._id);
                            this.reDeriveState();
                        });
                    }
                }];
            if (variableId !== this.normalHeader) {
                menuItems.push({
                    label: 'Remove Variable',
                    onClick: async () => {
                        const variableIds = this.props.value.data.declarationVarIds;
                        variableIds.splice(variableIds.indexOf(variableId), 1);
                        this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                    }
                });
            }
            const adapter = (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label));
            const modalId = 'normal-value-menu';
            const pos = (0, frontend_1.getElementCenterPos)(e.currentTarget);
            new frontend_1.MenuBuilder(adapter, pos, { x: 0, y: 1 })
                .setId(modalId)
                .setOnClick((path, item) => {
                var _a;
                frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
            })
                .setOffset({ x: 0, y: 10 })
                .show();
        };
        this.getTableHeaders = () => {
            const headers = [...this.props.value.data.declarationVarIds, this.normalHeader].map(i => ({
                header: i,
                widthPx: 200
            }));
            if (this.props.editable)
                headers.push({ header: 'delete', widthPx: 50 });
            return headers;
        };
        this.getTableRows = () => {
            var _a;
            let values = ((_a = this.props.value.data) === null || _a === void 0 ? void 0 : _a.normalValues) || [];
            values = (0, ts_common_1.sortArray)(values, val => (0, ts_common_1._keys)(val.conditions).length === 0 ? 1 : 0);
            if (this.props.editable)
                return [...values, { range: [0, 0], conditions: {} }];
            return values;
        };
        this.deleteRow = (index) => {
            this.logInfo(`Deleteing index ${index}`);
            (0, ts_common_1.removeFromArrayByIndex)(this.props.value.data.normalValues, index);
            this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
        };
        // ######################## Render ########################
        this.renderTableHeader = (header) => {
            if (header === 'delete')
                return React.createElement("div", null);
            return React.createElement("div", null, header === this.normalHeader ? this.normalHeader : this.state.variableNameMap[header]);
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        state.varIdScopeMap = {};
        state.variableNameMap = {};
        state.valueNameMap = {};
        const declarationVarIdVariables = _entity_1.ModuleFE_Variable.cache.filter(item => nextProps.value.data.declarationVarIds.includes(item._id));
        //Construct name map
        declarationVarIdVariables.forEach(variable => state.variableNameMap[variable._id] = variable.name);
        const allDbValues = _entity_1.ModuleFE_Value.cache.all();
        //Construct value connection maps
        declarationVarIdVariables.forEach(variable => {
            var _a, _b;
            const value = allDbValues.find(dbValue => dbValue._id === variable.valueId);
            if (value.type === _entity_1.valueType_Range)
                return state.varIdScopeMap[variable._id] = ((_a = value.data) === null || _a === void 0 ? void 0 : _a.subsets) || [];
            if ([_entity_1.valueType_Enumerated, _entity_1.valueType_SubEnumerated, _entity_1.valueType_Tuple].includes(value === null || value === void 0 ? void 0 : value.type))
                return state.varIdScopeMap[variable._id] = ((_b = value.data) === null || _b === void 0 ? void 0 : _b.scope) || [];
        });
        const valIdsToName = (0, ts_common_1.flatArray)((0, ts_common_1._values)(state.varIdScopeMap));
        valIdsToName.forEach(id => {
            const val = allDbValues.find(i => i._id === id);
            if (val.type === _entity_1.valueType_EnumElement)
                state.valueNameMap[id] = val.data.value;
            if (val.type === _entity_1.valueType_SubRange)
                state.valueNameMap[id] = val.data.label;
        });
        return state;
    }
    render() {
        const rows = this.getTableRows();
        return React.createElement(frontend_1.LL_V_L, { style: { width: '100%' } },
            this.props.editable &&
                React.createElement("div", { className: 'dialog__field-title' }, "Normal Table"),
            React.createElement("div", { className: 'dialog__field-content', style: { width: '100%' } },
                React.createElement(frontend_1.TS_Table, { header: this.getTableHeaders(), th: header => ({
                        onContextMenu: e => this.handleHeaderContextMenu(e, header)
                    }), headerRenderer: this.renderTableHeader, rows: rows, cellRenderer: (prop, item, index) => {
                        switch (prop) {
                            case this.normalHeader: {
                                if (this.props.editable)
                                    return React.createElement(frontend_1.TS_Input, { type: 'text', value: fullRangeToString(item.range), onBlur: val => {
                                            var _a;
                                            const range = stringToFullRange(val);
                                            if (range[0] === 0 && range[1] === 0)
                                                return;
                                            item.range = range;
                                            if (index === rows.length - 1)
                                                (_a = this.props.value.data) === null || _a === void 0 ? void 0 : _a.normalValues.push(item);
                                            this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                                        } });
                                return React.createElement("div", null, fullRangeToString(item.range));
                            }
                            case 'delete':
                                return React.createElement(styles_1.ICONS.bin.component, { onClick: () => index !== rows.length - 1 && this.deleteRow(index) });
                            default: {
                                if (this.props.editable) {
                                    const variable = _entity_1.ModuleFE_Variable.cache.unique(prop);
                                    if (!variable)
                                        throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for id ${prop}}`);
                                    return React.createElement(shared_components_1.DropDown_Values, { queryFilter: val => { var _a; return (_a = this.state.varIdScopeMap[prop]) === null || _a === void 0 ? void 0 : _a.includes(val._id); }, selected: item.conditions[prop], onSelected: val => {
                                            var _a;
                                            item.conditions[prop] = val._id;
                                            if (index === rows.length - 1)
                                                (_a = this.props.value.data) === null || _a === void 0 ? void 0 : _a.normalValues.push(item);
                                            this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                                        }, variable: variable });
                                }
                                return React.createElement("div", null, this.state.valueNameMap[item.conditions[prop]]);
                            }
                        }
                    } })));
    }
}
exports.NormalValuesTable = NormalValuesTable;
class Dialog_AddNormalVariable extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        // ######################## Logic ########################
        this.closeDialog = () => {
            frontend_1.ModuleFE_Dialog.close();
        };
        this.saveVariable = async () => {
            await (0, frontend_1.performAction)(async () => {
                try {
                    this.setState({ saveInProgress: true });
                    this.props.onSave(this.state.selectedVar);
                    this.closeDialog();
                }
                catch (e) {
                    this.logError(e);
                }
                finally {
                    this.setState({ saveInProgress: false });
                }
            }, { type: 'notification', notificationLabels: 'Saving Variable' });
        };
    }
    static show(onSave) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_AddNormalVariable, { onSave: onSave }), () => {
            return false;
        });
    }
    // ######################## Render ########################
    render() {
        return (React.createElement(frontend_1.LL_V_L, { className: "dialog dialog__add-normal-variable" },
            this.renderHeader(),
            this.renderMain(),
            this.renderButtons()));
    }
    renderMain() {
        return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main' },
            React.createElement(shared_components_1.DropDown_Variable, { queryFilter: () => true, selected: this.state.selectedVar, onSelected: selectedVar => {
                    this.setState({ selectedVar });
                } }));
    }
    renderHeader() {
        return React.createElement(frontend_1.LL_H_C, { className: "dialog__header" },
            React.createElement("span", { className: "title-text" }, "Add Normal Variable"),
            React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: this.closeDialog }));
    }
    renderButtons() {
        return React.createElement(frontend_1.LL_H_C, { className: "dialog__buttons" },
            React.createElement(frontend_1.TS_Button, { onClick: this.closeDialog }, "Cancel"),
            React.createElement(frontend_1.TS_BusyButton, { onClick: this.saveVariable, className: (0, frontend_1._className)(this.state.selectedVar ? 'desired-action-button' : undefined), disabled: this.state.saveInProgress }, "Save"));
    }
}
exports.Dialog_AddNormalVariable = Dialog_AddNormalVariable;
