"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Expression = exports.ModuleFE_Expression_Class = exports.dispatch_onExpressionChanged = void 0;
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const consts_1 = require("../shared/consts");
const shared_1 = require("../shared");
const utils_1 = require("../shared/utils");
const validator_1 = require("../shared/validator");
const ModuleFE_QuaiProtoItem_1 = require("@app/styles/frontend/modules/ModuleFE_QuaiProtoItem/ModuleFE_QuaiProtoItem");
const ts_common_1 = require("@nu-art/ts-common");
exports.dispatch_onExpressionChanged = new types_1.ThunderDispatcherV3('__onExpressionUpdated');
class ModuleFE_Expression_Class extends ModuleFE_QuaiProtoItem_1.ModuleFE_QuaiProtoItem {
    constructor() {
        super(shared_1.DBDef_Expression, exports.dispatch_onExpressionChanged, validator_1.Validator_ExpressionMinimalAssertion);
        this.__searchFilterMapper = (item) => { var _a, _b; return [(_b = (_a = item.label) !== null && _a !== void 0 ? _a : item.description) !== null && _b !== void 0 ? _b : '']; };
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.filter(i => consts_1.BuilderExpressionTypes.map(e => e.dbKey).includes(i.expressionType)).map(i => {
                    return { _id: i._id, uiLabel: i.label };
                })
            }]);
        this.isRightHand = { range: utils_1.rightHand_isRange, values: utils_1.rightHand_isValue };
        this.isTerm = { flat: utils_1.term_isFlat, withSubTerms: utils_1.term_isWithSubTerms };
        this._v1 = {};
    }
    validateInternal(_instance) {
        _instance = (0, ts_common_1.deleteKeysObject)(_instance, ['_refs', '_schema_expression', '_isDraft']);
        super.validateInternal(_instance);
    }
}
exports.ModuleFE_Expression_Class = ModuleFE_Expression_Class;
exports.ModuleFE_Expression = new ModuleFE_Expression_Class();
