"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathwaySelector_Search = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@app/styles/frontend");
const Page_PathwayEditor_1 = require("../Page_PathwayEditor");
class PathwaySelector_Search extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        state.selectedItem = nextProps.selectedItem;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'pathway-selector__search' },
            React.createElement(frontend_1.TS_Button, { className: 'button__create-new-pathway preferred-action', onClick: this.props.onCreateNewPathwayCallback }, "Add Pathway"),
            React.createElement(frontend_2.Component_SearchAndResults, { searchItems: [Page_PathwayEditor_1.Page_PathwayEditor.SearchableItem_PathwayV2], selectedItem: this.state.selectedItem, onSearchItemSelected: (item) => this.props.onSearchItemSelected(item.id, item.type) }));
    }
}
exports.PathwaySelector_Search = PathwaySelector_Search;
