"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePackFE_Organization = void 0;
const _entity_1 = require("./_entity");
const module_pack_1 = require("../_entity/ehr-instance-kb-version/frontend/module-pack");
const module_pack_2 = require("../_entity/artifact/frontend/module-pack");
exports.ModulePackFE_Organization = [
    ..._entity_1.ModulePackFE_OrganizationModule,
    ..._entity_1.ModulePackFE_EHROrderConfiguration,
    ..._entity_1.ModulePackFE_EHRInstance,
    ..._entity_1.ModulePackFE_Facility,
    ...module_pack_1.ModulePackFE_EHRInstanceKBVersionDB,
    ...module_pack_2.ModulePackFE_ArtifactDB,
];
