"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_Organization = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
require("./Editor_Organization.scss");
const frontend_2 = require("@app/hcs/frontend");
const Editor_Organization_GeneralInformation_1 = require("./inner-editors/general-information/Editor_Organization_GeneralInformation");
const frontend_3 = require("@app/styles/frontend");
const permission_keys_1 = require("../../../permission-keys");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const _entity_1 = require("../../../_entity");
const Editor_Organization_EHRInstances_1 = require("./inner-editors/ehr-instances/Editor_Organization_EHRInstances");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const ts_common_1 = require("@nu-art/ts-common");
const Component_FocusedEntityRef_1 = require("@nu-art/ts-focused-object/frontend/components/Component_FocusedEntityRef");
const views = ['general-information', 'ehr-instances', 'users'];
const viewsLabelMap = {
    'general-information': 'General Information',
    'ehr-instances': 'EHR Instances',
    'users': 'Users',
};
class Editor_Organization extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        this.organizationActions = [
            {
                label: 'Edit Organization',
                action: () => this.setState({ editMode: true }),
                permissionKey: permission_keys_1.PermissionKeyFE_OrganizationEdit
            },
            {
                label: 'Delete Organization',
                action: this.props.deleteOrganization,
                permissionKey: permission_keys_1.PermissionKeyFE_OrganizationDelete,
            },
        ];
        // ######################## Logic ########################
        this.selectView = (view) => {
            this.setState(Object.assign({ selectedView: view }, (view === 'ehr-instances' ? {} : { selectedEhrInstance: undefined })));
        };
        this.selectEhrInstance = (instance) => {
            instance !== null && instance !== void 0 ? instance : (instance = this.getCleanEHRInstance());
            this.setState({
                selectedEhrInstance: this.getEditableEHRInstance(instance),
                selectedView: 'ehr-instances',
                editMode: this.state.editMode || !instance._id
            });
        };
        this.getCleanEHRInstance = () => {
            return {
                organizationId: this.state.editable.item._id,
                label: 'New',
            };
        };
        this.getEHRInstanceActions = (instance) => {
            const actions = [];
            if (ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(permission_keys_1.PermissionKeyFE_OrganizationEdit) === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess)
                actions.push({
                    label: 'Delete Instance',
                    action: async () => {
                        frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                        frontend_3.Dialog_Confirmation.show({
                            header: 'Delete EHR Instance',
                            content: () => React.createElement(React.Fragment, null, "Delete EHR Instance and all connected EHR Orders?"),
                            buttons: [{
                                    label: 'Yes, Delete', isAsync: true, action: async () => {
                                        var _a;
                                        await _entity_1.ModuleFE_EHRInstance._v1.deleteInstance({ instanceId: instance._id }).executeSync();
                                        if (((_a = this.state.selectedEhrInstance) === null || _a === void 0 ? void 0 : _a.item._id) === instance._id)
                                            return this.setState({ selectedEhrInstance: undefined });
                                        this.forceUpdate();
                                    }
                                },
                                { label: 'Don\'t Delete', isPreferred: true, action: ts_common_1.voidFunction }]
                        });
                    }
                });
            return actions;
        };
        this.onInstanceRightClick = (e, instance) => {
            const actions = this.getEHRInstanceActions(instance);
            if (!actions.length)
                return;
            frontend_1.ModuleFE_MouseInteractivity.showContent({
                id: 'instance-pop-up-menu',
                originPos: (0, frontend_1.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 1, y: 1 },
                content: () => React.createElement(React.Fragment, null, actions.map(action => {
                    return React.createElement("div", { key: action.label, className: 'popup-action', onClick: action.action }, action.label);
                }))
            });
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const className = (0, frontend_1._className)('manager__header', this.state.editMode && 'edit-mode');
            const org = this.state.editable.item;
            const title = org.alias ? org.alias : org.name;
            const subtitle = org.alias ? org.name : undefined;
            return React.createElement(frontend_1.LL_H_C, { className: className },
                React.createElement(frontend_1.LL_H_C, { className: 'manager__header__main' },
                    React.createElement(frontend_1.LL_V_L, { className: 'manager__header__title' },
                        React.createElement(frontend_1.Show, null,
                            React.createElement(frontend_1.Show.If, { condition: !!org._id },
                                React.createElement("div", { className: 'manager__header__title__label' }, title),
                                React.createElement("div", { className: 'manager__header__sub-title' }, subtitle)),
                            React.createElement(frontend_1.Show.Else, null,
                                React.createElement("div", { className: 'manager__header__title__label' }, "Add New Organization")))),
                    this.renderHeaderTail()));
        };
        this.renderHeaderTail = () => {
            var _a;
            const editMode = !!this.state.editMode;
            const itemId = (_a = this.state.editable) === null || _a === void 0 ? void 0 : _a.item._id;
            const focusedEntity = itemId ? { dbKey: _entity_1.DBDef_Organization.dbKey, itemId: itemId } : undefined;
            return React.createElement(frontend_1.Show, null,
                React.createElement(frontend_1.Show.If, { condition: !editMode && !!itemId },
                    React.createElement(styles_1.ICONS.more.component, Object.assign({ className: 'editor-organization__header__main__more' }, frontend_1.openContent.popUp.bottom('pop-up__organization-action', this.renderActionMenu, 10)))),
                React.createElement(frontend_1.Show.If, { condition: !!itemId },
                    React.createElement(Component_FocusedEntityRef_1.Component_FocusedEntityRef, { focusedEntities: [focusedEntity], key: itemId })),
                React.createElement(frontend_1.Show.If, { condition: editMode && !!itemId },
                    React.createElement(frontend_1.LL_H_C, { className: 'editor-organization__header__tail' },
                        React.createElement(frontend_1.TS_Button, { className: 'preferred-action', onClick: () => this.setState({ editMode: false }) }, "Done"))),
                React.createElement(frontend_1.Show.If, { condition: editMode && !itemId },
                    React.createElement(frontend_1.LL_H_C, { className: 'editor-organization__header__tail' },
                        React.createElement(frontend_1.TS_Button, { onClick: this.props.deselectOrganization }, "Cancel"),
                        React.createElement(frontend_1.TS_BusyButton, { className: 'preferred-action', onClick: () => this.state.editable.save() }, "Save"))));
        };
        this.renderActionMenu = () => {
            return React.createElement(frontend_3.ActionsMenu_BaseManagerUI, { actions: this.organizationActions });
        };
        // ######################## Render - Views ########################
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'editor-organization__body' },
                this.renderViews(),
                this.renderView());
        };
        this.renderViews = () => {
            return React.createElement(frontend_2.QScrollWrapper, { className: 'editor-organization__views' }, views.map(view => {
                if (view !== 'general-information' && !this.state.editable.item._id)
                    return;
                if (view === 'ehr-instances')
                    return this.renderInstanceView();
                const className = (0, frontend_1._className)('editor-organization__view', this.state.selectedView === view && 'selected');
                return React.createElement("div", { key: view, className: className, onClick: () => this.selectView(view) }, viewsLabelMap[view]);
            }));
        };
        this.renderInstanceView = () => {
            return React.createElement(frontend_1.TS_CollapsableContainer, { key: 'ehr-instances', headerRenderer: () => React.createElement(React.Fragment, null,
                    viewsLabelMap['ehr-instances'],
                    React.createElement(styles_1.ICONS.more.component, Object.assign({}, frontend_1.openContent.popUpV2.bottom({
                        id: 'ehr-instances-menu',
                        content: this.renderEHRInstancesMenu,
                        offset: 10
                    })))), customCaret: () => React.createElement(styles_1.ICONS.advisorArrowDown.component, null), onHeaderRightClick: e => {
                    (0, frontend_1.stopPropagation)(e);
                    frontend_1.ModuleFE_MouseInteractivity.showContent({
                        id: 'ehr-instances-menu',
                        content: this.renderEHRInstancesMenu,
                        offset: { y: 10 },
                        modalPos: { x: 1, y: 1 },
                        originPos: (0, frontend_1.getElementCenterPos)(e.currentTarget),
                    });
                }, containerRenderer: () => {
                    var _a;
                    const instances = _entity_1.ModuleFE_EHRInstance.cache.filter(instance => instance.organizationId === this.state.editable.item._id);
                    const selectedInstance = (_a = this.state.selectedEhrInstance) === null || _a === void 0 ? void 0 : _a.item;
                    return React.createElement(React.Fragment, null,
                        selectedInstance && !selectedInstance._id &&
                            React.createElement("div", { className: 'editor-organization__view selected', key: '##NewInstance##' }, selectedInstance.label),
                        instances.map(instance => {
                            var _a;
                            const selected = instance._id === ((_a = this.state.selectedEhrInstance) === null || _a === void 0 ? void 0 : _a.item._id);
                            const className = (0, frontend_1._className)('editor-organization__view', selected && 'selected');
                            return React.createElement("div", { key: instance._id, className: className, onClick: () => this.selectEhrInstance(instance), onContextMenu: e => this.onInstanceRightClick(e, instance) }, instance.label);
                        }));
                } });
        };
        this.renderEHRInstancesMenu = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'popup-action', onClick: () => {
                        frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                        this.selectEhrInstance();
                    } }, "Add New Instance"));
        };
        // ######################## Render - Body ########################
        this.renderView = () => {
            switch (this.state.selectedView) {
                case 'general-information':
                    return this.renderGeneralInformation();
                case 'ehr-instances':
                    return this.renderEhrInstances();
            }
        };
        this.renderGeneralInformation = () => {
            return React.createElement(Editor_Organization_GeneralInformation_1.Editor_Organization_GeneralInformation, { editable: this.state.editable, editMode: !!this.state.editMode });
        };
        this.renderEhrInstances = () => {
            const organizationId = this.state.editable.item._id;
            if (!organizationId || !this.state.selectedEhrInstance)
                return React.createElement(React.Fragment, null);
            return React.createElement(Editor_Organization_EHRInstances_1.Editor_Organization_EHRInstances, { editable: this.state.selectedEhrInstance, organizationId: organizationId, editMode: !!this.state.editMode });
        };
    }
    // ######################## Lifecycle ########################
    __onEHRInstanceUpdated(...params) {
        var _a;
        const orgId = this.state.editable.get('_id');
        const ehrInstance = (0, ts_common_1.asArray)(params[1])[0];
        // fail fast if updated instance not related to org
        if (orgId !== ehrInstance.organizationId)
            return;
        if ((0, ts_common_1.exists)(this.state.selectedEhrInstance) && (params[0] === 'update' || params[0] === 'upsert-all'))
            (_a = this.state.selectedEhrInstance) === null || _a === void 0 ? void 0 : _a.updateItem(ehrInstance);
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        var _a;
        if (nextState.ehrEditableTag !== ((_a = nextState.selectedEhrInstance) === null || _a === void 0 ? void 0 : _a.tag))
            return true;
        return super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c, _d;
        state = super.deriveStateFromProps(nextProps, state);
        (_a = state.selectedView) !== null && _a !== void 0 ? _a : (state.selectedView = views[0]);
        //Viewing EHR Instance but the organization switched
        if (state.selectedEhrInstance && ((_b = this.state) === null || _b === void 0 ? void 0 : _b.editable.item._id) !== state.editable.item._id) {
            const instances = _entity_1.ModuleFE_EHRInstance.cache.filter(instance => instance.organizationId === state.editable.item._id);
            state.selectedEhrInstance = this.getEditableEHRInstance(instances[0]);
        }
        //If getting new organization
        if (!state.editable.item._id)
            state.editMode = true;
        else { //Getting existing organization
            if (!((_c = this.state) === null || _c === void 0 ? void 0 : _c.editable.item._id))
                state.editMode = false;
        }
        // To make sure component reRenders if needed
        state.ehrEditableTag = (_d = state.selectedEhrInstance) === null || _d === void 0 ? void 0 : _d.tag;
        return state;
    }
    getEditableEHRInstance(ehrInstance) {
        return new frontend_1.EditableDBItemV3(ehrInstance, _entity_1.ModuleFE_EHRInstance)
            .setAutoSave(!!ehrInstance._id)
            .setOnChanged(async (editable) => this.reDeriveState({ selectedEhrInstance: editable }));
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'quai-item-manager', id: 'editor-organization' },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Editor_Organization = Editor_Organization;
