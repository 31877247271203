"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalFormKey_cha2ds2VascScore = exports.ClinicalForm_PortScore = exports.ClinicalForm_CURBScore = exports.ClinicalForm_GenevaVTEScore = exports.ClinicalForm_GenevaPEScore = exports.ClinicalForm_qSOFAScore = exports.ClinicalForm_PERCScore = exports.ClinicalForm_AddScore = exports.ClinicalForm_HeartScore = exports.ClinicalForm_WellsScore = exports.ClinicalFormKey_Cha2ds2VascScore = exports.ClinicalFormKey_PortScore = exports.ClinicalFormKey_CURBScore = exports.ClinicalFormKey_GenevaVTEScore = exports.ClinicalFormKey_GenevaPEScore = exports.ClinicalFormKey_qSOFAScore = exports.ClinicalFormKey_PERCScore = exports.ClinicalFormKey_ADDScore = exports.ClinicalFormKey_HeartScore = exports.ClinicalFormKey_WellsScore = void 0;
exports.ClinicalFormKey_WellsScore = 'wells-score';
exports.ClinicalFormKey_HeartScore = 'heart-score';
exports.ClinicalFormKey_ADDScore = 'add-score';
exports.ClinicalFormKey_PERCScore = 'perc-score';
exports.ClinicalFormKey_qSOFAScore = 'qsofa-score';
exports.ClinicalFormKey_GenevaPEScore = 'geneva-pe-score';
exports.ClinicalFormKey_GenevaVTEScore = 'geneva-vte-score';
exports.ClinicalFormKey_CURBScore = 'curb-score';
exports.ClinicalFormKey_PortScore = 'psi-port-score';
exports.ClinicalFormKey_Cha2ds2VascScore = 'cha2ds2-vasc-score';
//################################### Scores ###################################
exports.ClinicalForm_WellsScore = {
    title: 'Wells Score',
    key: exports.ClinicalFormKey_WellsScore,
    contextVariableId: 'WELLS',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_HeartScore = {
    title: 'Heart Score',
    key: exports.ClinicalFormKey_HeartScore,
    contextVariableId: 'HEART',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_AddScore = {
    title: 'ADD Score',
    key: exports.ClinicalFormKey_ADDScore,
    contextVariableId: 'ADD',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_PERCScore = {
    title: 'PERC Score',
    key: exports.ClinicalFormKey_PERCScore,
    contextVariableId: 'PERC',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_qSOFAScore = {
    title: 'qSOFA Score',
    key: exports.ClinicalFormKey_qSOFAScore,
    contextVariableId: 'qSOFA',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_GenevaPEScore = {
    title: 'Geneva PE Score',
    key: exports.ClinicalFormKey_GenevaPEScore,
    contextVariableId: 'Geneva PE',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_GenevaVTEScore = {
    title: 'Geneva VTA Score',
    key: exports.ClinicalFormKey_GenevaVTEScore,
    contextVariableId: 'Geneva VTE',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_CURBScore = {
    title: 'CURB Score',
    key: exports.ClinicalFormKey_CURBScore,
    contextVariableId: 'CURB',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalForm_PortScore = {
    title: 'PORT Score',
    key: exports.ClinicalFormKey_PortScore,
    contextVariableId: 'PORT',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
exports.ClinicalFormKey_cha2ds2VascScore = {
    title: 'CHA2DS2-Vasc Score',
    key: exports.ClinicalFormKey_Cha2ds2VascScore,
    contextVariableId: 'CHA2DS2-Vasc',
    sections: [],
    _variableIds: [],
    _valueIds: []
};
