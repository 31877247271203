"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_PreconditionTimeConstraint = void 0;
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@app/hcs/_entity/expression/frontend");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
const consts_1 = require("@app/hcs/_entity/expression/shared/consts");
const frontend_3 = require("../../../../../../../../_entity/action/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const styles_1 = require("@app/styles");
require("./Editor_Precondition.scss");
const React = require("react");
class Editor_PreconditionTimeConstraint extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        //TODO: Bring it back when Shlomi wants it
        // private renderTimeConstraintEditor = () => {
        // 	if (!this.state.insertTimeConstraint)
        // 		return '';
        //
        // 	const editableTimeConstraint = this.state.editable.editProp('timeConstraint', {});
        // 	const editableTimeRange = editableTimeConstraint.editProp('timeRange', []) as EditableItem<TimeRange | [undefined, undefined]>;
        // 	return <LL_H_C className={'match_width'}>
        // 		<DropDown_PreconditionTimeConstraintIndex.editable
        // 			editable={editableTimeConstraint}
        // 			prop={'index'}
        // 		/>
        // 		<Editor_TimeRange editable={editableTimeRange}/>
        // 	</LL_H_C>;
        // };
        this.toggleTimeConstraint = () => {
            this.setState({ insertExpressionId: !this.state.insertExpressionId }, () => {
                if (!this.state.insertExpressionId)
                    return this.state.editable.updateObj({ expressionId: undefined });
            });
        };
        this.renderExpressionDropdown = () => {
            if (!this.state.insertExpressionId)
                return '';
            const expression = frontend_1.ModuleFE_Expression.cache.unique(this.state.editable.item.expressionId);
            return React.createElement(frontend_2.TS_DropDown, { adapter: (0, frontend_2.SimpleListAdapter)(frontend_1.ModuleFE_Expression.cache.allMutable(), item => React.createElement(React.Fragment, null, item.item.label)), queryFilter: expression => consts_1.BuilderTypesDbKeys.includes(expression.expressionType), limitItems: 20, placeholder: 'Select Expression', selected: expression, filter: new ts_common_1.Filter(item => [item.label.toLowerCase()]), canUnselect: true, onSelected: async (expression) => {
                    if (!expression) {
                        return this.state.editable.updateObj({ expressionId: undefined });
                    }
                    return this.state.editable.updateObj({ expressionId: expression._id });
                } });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        (_a = state.insertExpressionId) !== null && _a !== void 0 ? _a : (state.insertExpressionId = !!state.editable.item.expressionId);
        return state;
    }
    render() {
        const order = frontend_3.ModuleFE_Action.cache.unique(this.state.editable.item.orderId);
        if (!order)
            throw new ts_common_1.MUSTNeverHappenException(`cannot find order with id ${this.state.editable.item.orderId}`);
        const Icon = this.state.insertExpressionId ? styles_1.ICONS.x : styles_1.ICONS.advisorAddPlus;
        return React.createElement(frontend_2.LL_V_L, { className: 'editor-precondition' },
            React.createElement(frontend_2.LL_H_C, { className: 'value-container' },
                React.createElement("div", { className: 'order-title' }, order.name),
                React.createElement(Icon.component, { className: (0, frontend_2._className)(!this.state.insertExpressionId && 'plus'), onClick: this.toggleTimeConstraint })),
            this.renderExpressionDropdown());
    }
}
exports.Editor_PreconditionTimeConstraint = Editor_PreconditionTimeConstraint;
