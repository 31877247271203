"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const q_components_1 = require("../../../../../../q-components");
const _entity_1 = require("../../../../../../../_entity");
const frontend_2 = require("@app/styles/frontend");
function RenderEditColumn(props) {
    const [enableEdit, setEnableEdit] = React.useState(false);
    React.useEffect(() => {
        var _a;
        const dbTable = _entity_1.ModuleFE_VariableRelation.cache.unique((_a = props.row) === null || _a === void 0 ? void 0 : _a._id);
        if ((!dbTable) || !(dbTable.leftSide.some((item, index) => { var _a; return (0, ts_common_1.compare)(item, (_a = props.row) === null || _a === void 0 ? void 0 : _a.leftSide[index]); }) &&
            dbTable.rightSide.some((item, index) => { var _a; return (0, ts_common_1.compare)(item, (_a = props.row) === null || _a === void 0 ? void 0 : _a.rightSide[index]); })
            && (0, ts_common_1.compare)(dbTable.relation, props.row.relation))) {
            setEnableEdit(true);
        }
    });
    const updateTable = async () => {
        let response;
        await (0, frontend_1.performAction)(async () => {
            response = await _entity_1.ModuleFE_VariableRelation.v1
                .upsert(props.row).executeSync();
            props.onSaveRow(response, props.index);
            setEnableEdit(false);
        }, {
            type: 'toast',
            successContent: React.createElement(q_components_1.QToastContent, { content: 'Table updated successfully', toastType: 'success', iconKey: 'v' }),
        });
    };
    return (React.createElement(frontend_1.LL_H_C, { style: { width: '100%', justifyContent: 'center', gap: '10px' } },
        React.createElement(frontend_2.IconWithTooltip, { className: 'bin-size tooltip', iconKey: 'bin', content: 'Delete', onClick: () => props.removeARow(props.index) }),
        enableEdit &&
            React.createElement(frontend_2.IconWithTooltip, { className: 'inner-values__actions__accept tooltip', iconKey: 'v', content: 'Save', onClick: () => updateTable() })));
}
exports.default = RenderEditColumn;
