"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QRangeInputs = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
class QRangeInputs extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        // ######################### Life Cycle #########################
        super(...arguments);
        // ######################### Logic #########################
        this.onValueChange = async (value, index) => {
            await this.state.array.updateArrayAt(value, index);
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        state.disabled = (_a = nextProps.disabled) !== null && _a !== void 0 ? _a : false;
        state.divider = nextProps.divider;
        state.array = nextProps.editable.editProp(nextProps.prop, [0, 0]);
        return state;
    }
    // ######################### Render #########################
    render() {
        const editable = this.state.array;
        return React.createElement(frontend_1.LL_H_C, { className: 'q-range-inputs' },
            React.createElement(frontend_1.TS_Input, { type: 'number', value: editable.item[0], onBlur: value => this.onValueChange(Number(value), 0) }),
            this.state.divider && React.createElement("div", { className: 'q-range-inputs__divider' }, this.state.divider),
            React.createElement(frontend_1.TS_Input, { type: 'number', value: editable.item[1], onBlur: value => this.onValueChange(Number(value), 1) }));
    }
}
exports.QRangeInputs = QRangeInputs;
