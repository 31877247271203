"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_BaseManagerUI_SingleType_Proto = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Page_BaseManagerUI_1 = require("./Page_BaseManagerUI");
class Page_BaseManagerUI_SingleType_Proto extends Page_BaseManagerUI_1.Page_BaseManagerUI {
    constructor(p) {
        super(p);
        const method = p.searchItems[0].module.defaultDispatcher.method;
        // @ts-ignore
        this[method] = this.__onItemUpdated;
        // @ts-ignore
        this[method].bind(this);
        this.getInitialObject.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        var _a, _b;
        if (((_a = this.state) === null || _a === void 0 ? void 0 : _a.tag) !== ((_b = nextState.selectedItem) === null || _b === void 0 ? void 0 : _b.tag))
            return true;
        return super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
    __onItemUpdated(...params) {
        var _a;
        const selectedResult = this.state.selectedResult;
        if ((selectedResult === null || selectedResult === void 0 ? void 0 : selectedResult.type) !== this.getModule().dbDef.dbKey)
            return;
        switch (params[0]) {
            case 'update':
            case 'upsert-all':
                if ((0, ts_common_1.asArray)(params[1]).map(ts_common_1.dbObjectToId).includes(selectedResult.id)) {
                    (_a = this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.updateItem((0, ts_common_1.deepClone)((0, ts_common_1.asArray)(params[1]))[0]);
                }
                break;
            case 'delete':
            case 'delete-multi':
                if ((0, ts_common_1.asArray)(params[1]).map(ts_common_1.dbObjectToId).includes(selectedResult.id))
                    this.clearSelectedResult();
                break;
        }
    }
    getModule() {
        return this.props.searchItems[0].module;
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c;
        state = super.deriveStateFromProps(nextProps, state);
        const selectedItem = this.getModule().cache.unique((_a = state.selectedResult) === null || _a === void 0 ? void 0 : _a.id);
        if (((_b = state.selectedResult) === null || _b === void 0 ? void 0 : _b.id) && !selectedItem)
            throw new ts_common_1.BadImplementationException(`Received id ${(_c = state.selectedResult) === null || _c === void 0 ? void 0 : _c.id} for ${this.props.searchItems[0].type}, .`);
        if (state.selectedItem && state.selectedItem.item._id === (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem._id))
            return state;
        if (!state.selectedResult) {
            delete state.selectedItem;
            return state;
        }
        state.selectedItem = this.getEditableItem(selectedItem);
        state.tag = state.selectedItem.tag;
        return state;
    }
    clearSelectedResult() {
        this.logWarning('Clear Selected Result');
        super.clearSelectedResult();
        this.setState({ selectedItem: undefined, selectedResult: undefined });
    }
    getEditableItem(item = this.getInitialObject()) {
        const newEditable = new frontend_1.EditableDBItemV3(item, this.getModule())
            .setOnChanged(async (editable) => {
            if (editable.get('_id'))
                editable.setAutoSave(true);
            if (this.state)
                this.setState({ selectedItem: editable });
        });
        if (item._id)
            newEditable.validate();
        newEditable.setAutoSave(!!item._id);
        return newEditable;
    }
}
exports.Page_BaseManagerUI_SingleType_Proto = Page_BaseManagerUI_SingleType_Proto;
