"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDeltaTypeOperator = exports.getBaseDeltaOperator = exports.resolveValueType = exports.getAtomType = exports.getValueType = exports.getExpressionType = exports.getLeftHandType = exports.getEmptyTerm = exports.createExpressionLabel = exports.term_isWithSubTerms = exports.term_isFlat = exports.rightHand_isValue = exports.rightHand_isRange = void 0;
const consts_1 = require("./consts");
const ts_common_1 = require("@nu-art/ts-common");
const rightHand_isRange = (_case) => {
    const caseOperator = _case.get('caseOperator');
    if (!caseOperator)
        return false;
    return consts_1.rangeRightHandOperators.includes(caseOperator);
};
exports.rightHand_isRange = rightHand_isRange;
const rightHand_isValue = (_case) => {
    const caseOperator = _case.get('caseOperator');
    if (!caseOperator)
        return false;
    return consts_1.uniqueIdsRightHandOperators.includes(caseOperator);
};
exports.rightHand_isValue = rightHand_isValue;
const term_isFlat = (_term) => {
    const atomsLogic = _term.get('atomsLogic');
    if (!atomsLogic)
        return false;
    return consts_1.BasicAtomsLogicTypes.includes(atomsLogic);
};
exports.term_isFlat = term_isFlat;
const term_isWithSubTerms = (_term) => {
    const atomsLogic = _term.get('atomsLogic');
    const subTerms = _term.get('subTerms');
    if (!atomsLogic)
        return false;
    return atomsLogic === consts_1.LogicType_Complex && (0, ts_common_1.exists)(subTerms);
};
exports.term_isWithSubTerms = term_isWithSubTerms;
const createExpressionLabel = (labelList, expressionType, associatedVariable) => {
    const baseLabel = (0, ts_common_1.capitalizeAllFirstLetters)(`${associatedVariable.name} - ${expressionType}`);
    // In case it's the first state set the first label
    if (!labelList.length)
        return baseLabel;
    // Find the missing index to update the label
    for (let i = 0; i <= labelList.length; i++) {
        const currentLabel = `${baseLabel} (${i + 1})`;
        if (!labelList.includes(currentLabel) && i)
            return currentLabel;
    }
    // In case no index is missing add the list length
    return `${baseLabel} (${labelList.length + 1})`;
};
exports.createExpressionLabel = createExpressionLabel;
const getEmptyTerm = () => {
    return {
        expression: 'A1',
        atomsLogic: consts_1.LogicType_And,
        atoms: [{ leftHand: undefined, cases: [{ rightHand: [] }] }],
    };
};
exports.getEmptyTerm = getEmptyTerm;
const getLeftHandType = (lefthand, mappers, atom) => {
    switch (lefthand.type) {
        case 'atom':
            if (!atom)
                throw new ts_common_1.BadImplementationException('cannot resolve lefthand type atom without passing an atom!');
            if (!mappers.valueMap || !mappers.variableMap)
                throw new ts_common_1.BadImplementationException('missing mapper');
            return (0, exports.getAtomType)(atom, mappers.variableMap, mappers.valueMap);
        case 'attribute':
        case 'variable':
        case 'precipitating':
        case 'relieving': {
            if (!mappers.valueMap || !mappers.variableMap)
                throw new ts_common_1.BadImplementationException('missing mapper');
            return (0, exports.getValueType)(lefthand, mappers.variableMap, mappers.valueMap);
        }
        case 'expression': {
            if (!mappers.expressionMap)
                throw new ts_common_1.BadImplementationException('must get an expression mapper when trying to get expression type');
            return (0, exports.getExpressionType)(lefthand.id, mappers.expressionMap);
        }
    }
};
exports.getLeftHandType = getLeftHandType;
const getExpressionType = (expressionId, expressionsMap) => {
    const expression = expressionsMap[expressionId];
    if (!expression)
        throw new ts_common_1.MUSTNeverHappenException(`Expression with id ${expressionId} not found`);
    // As defined by the AI team the output type of an expression is not selectable but derived from expression type!
    if (consts_1.ExpressionType_GeneralFunction.dbKey === expression.expressionType)
        return 'boolean';
    return 'numeric';
};
exports.getExpressionType = getExpressionType;
const getValueType = (leftHand, variableMap, valueMap) => {
    var _a;
    const variableId = (_a = leftHand.propertyId) !== null && _a !== void 0 ? _a : leftHand.id;
    const variable = variableMap[variableId];
    const value = valueMap[variable.valueId];
    if (!value) {
        throw new ts_common_1.MUSTNeverHappenException(`could not find value with id ${variable === null || variable === void 0 ? void 0 : variable.valueId}`);
    }
    return value.type;
};
exports.getValueType = getValueType;
const getAtomType = (atom, variableMap, valueMap) => {
    // Handle numeric value cases
    if (atom.cases.every(_case => (0, ts_common_1.exists)(_case.value))) {
        return atom.cases.every(_case => _case.value[0] === _case.value[1]) ? 'numeric' : 'range';
    }
    // Handle self value
    if (consts_1.SelfOperators.includes(atom.cases[0].caseOperator)) {
        const valueType = (0, exports.getValueType)(atom.leftHand, variableMap, valueMap);
        // if it's a delta result treat it like numeric, it ain't normal range
        if (valueType === 'standard-range' && (0, exports.getBaseDeltaOperator)(atom.cases[0].caseOperator))
            return 'numeric';
        switch (valueType) {
            case 'enumerated':
                return 'enum';
            case 'tuple':
                return 'boolean';
            case 'standard-range':
                return 'normal-range';
            case 'range':
                return 'numeric';
            case 'time':
                return 'time';
        }
    }
    return 'boolean';
};
exports.getAtomType = getAtomType;
const resolveValueType = (leftHand, mappers) => {
    if (!leftHand)
        throw new ts_common_1.MUSTNeverHappenException('cannot pick case operator without left hand');
    const valueType = (0, exports.getLeftHandType)(leftHand, mappers);
    if (valueType === 'boolean' || valueType === 'tuple')
        return 'boolean';
    if (valueType === 'numeric' || valueType === 'range' || valueType === 'standard-range')
        return 'numeric';
    return 'enum';
};
exports.resolveValueType = resolveValueType;
const getBaseDeltaOperator = (caseOperator) => consts_1.BaseDeltaLogic.find(op => caseOperator === null || caseOperator === void 0 ? void 0 : caseOperator.startsWith(op));
exports.getBaseDeltaOperator = getBaseDeltaOperator;
const getDeltaTypeOperator = (caseOperator) => {
    const baseDelta = (0, exports.getBaseDeltaOperator)(caseOperator);
    if (!baseDelta)
        throw new ts_common_1.BadImplementationException('cannot get delta type if delta is not in use');
    return caseOperator.replace(baseDelta, '');
};
exports.getDeltaTypeOperator = getDeltaTypeOperator;
