"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveTimeRangeDisplay = exports.addIndexingFields = exports.getCleanPreDBValue = exports.inferMultiplier = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const types_1 = require("../../../_enum/timespan/shared/types");
const consts_2 = require("../../../_enum/timespan/shared/consts");
/**
 * Infer the multiplier type for a timestamp passed to the function
 * @param value a timestamp representing time period
 */
const inferMultiplier = (value) => {
    var _a;
    if (!value)
        return types_1.TimespanType_Second;
    return (_a = [...types_1.TimespansType].reverse().find(timespan => Number.isInteger(value / consts_2.TimespanToValue[timespan]))) !== null && _a !== void 0 ? _a : types_1.TimespanType_Second;
};
exports.inferMultiplier = inferMultiplier;
const getCleanPreDBValue = (type, parentValue) => {
    const value = { type };
    switch (type) {
        case consts_1.valueType_Enumerated: {
            value.data = {
                subsets: [],
                scope: [],
                props: {},
            };
            break;
        }
        case consts_1.valueType_Range: {
            value.data = {
                subsets: [],
                scope: [0, 0],
            };
            break;
        }
        case consts_1.valueType_SubEnumerated: {
            value.data = {
                scope: [],
                parentId: parentValue === null || parentValue === void 0 ? void 0 : parentValue._id
            };
            break;
        }
        case consts_1.valueType_SubRange: {
            let scope = [0, 0];
            if (parentValue && parentValue.type === consts_1.valueType_Range)
                scope = parentValue.data.scope;
            value.data = {
                parentId: parentValue === null || parentValue === void 0 ? void 0 : parentValue._id,
                scope,
            };
            break;
        }
        default:
            throw new ts_common_1.BadImplementationException(`Missing implementation for type: ${type}`);
    }
    return value;
};
exports.getCleanPreDBValue = getCleanPreDBValue;
function addIndexingFields(instance) {
    const __valueIds = [];
    let __unitsId = undefined;
    const __variableIds = [];
    switch (instance.type) {
        case 'enumerated':
            __valueIds.push(...instance.data.scope);
            __valueIds.push(...instance.data.subsets);
            break;
        case 'range':
            __unitsId = instance.data.unitsId;
            __valueIds.push(...instance.data.subsets);
            break;
        // case 'sub-range': break;
        case 'sub-enumerated':
            __valueIds.push(...instance.data.scope);
            break;
        // case 'enum-element': break;
        case 'tuple':
            __valueIds.push(...instance.data.scope);
            break;
        case 'standard-range':
            __unitsId = instance.data.unitsId;
            __variableIds.push(...instance.data.declarationVarIds);
            break;
    }
    if (!__unitsId)
        delete instance.__unitsId;
    else
        instance.__unitsId = __unitsId;
    instance.__valueIds = __valueIds;
    instance.__variableIds = __variableIds;
}
exports.addIndexingFields = addIndexingFields;
/**
 * Resolve UI display for a time range value and format it as human-readable value
 * @param value UI value for resolving
 */
const resolveTimeRangeDisplay = (value) => {
    if (!value.data)
        throw new ts_common_1.BadImplementationException('cannot resolve display name of a value without range data');
    // Create reusable variable of values
    const firstTimeRangeElement = value.data.timeRange[0];
    const secondTimeRangeElement = value.data.timeRange[1];
    // Infer values multiplier
    const firstValueMultiplier = (0, exports.inferMultiplier)(firstTimeRangeElement);
    const secondValueMultiplier = (0, exports.inferMultiplier)(secondTimeRangeElement);
    // resolve the label in case to values exists
    if ((0, ts_common_1.exists)(firstTimeRangeElement) && (0, ts_common_1.exists)(secondTimeRangeElement)) {
        return `${firstTimeRangeElement / consts_2.TimespanToValue[firstValueMultiplier]} ${consts_2.TimespanLabelsMap[firstValueMultiplier]} - ${secondTimeRangeElement / consts_2.TimespanToValue[secondValueMultiplier]} ${consts_2.TimespanLabelsMap[secondValueMultiplier]}`;
    }
    // resolve the label if only the first one exists
    if ((0, ts_common_1.exists)(firstTimeRangeElement)) {
        return `≥ ${firstTimeRangeElement / consts_2.TimespanToValue[firstValueMultiplier]} ${consts_2.TimespanLabelsMap[firstValueMultiplier]}`;
    }
    // resolve the value if only the second value exists
    if ((0, ts_common_1.exists)(secondTimeRangeElement)) {
        return `≤ ${secondTimeRangeElement / consts_2.TimespanToValue[secondValueMultiplier]} ${consts_2.TimespanLabelsMap[secondValueMultiplier]}`;
    }
    // this means we try to print invalid value, which cannot be done
    throw new ts_common_1.BadImplementationException('invalid value to print');
};
exports.resolveTimeRangeDisplay = resolveTimeRangeDisplay;
