"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuaiIconKeys = exports.ICONS = exports.iconsRenderer = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const icon__add_plus_svg_1 = require("./svgs/advisor/icon__add-plus.svg");
const icon__arrow_down_svg_1 = require("./svgs/advisor/icon__arrow-down.svg");
const icon__filter_svg_1 = require("./svgs/advisor/icon__filter.svg");
const icon__maximize_svg_1 = require("./svgs/advisor/icon__maximize.svg");
const icon__minimize_svg_1 = require("./svgs/advisor/icon__minimize.svg");
const icon__quai_svg_1 = require("./svgs/advisor/icon__quai.svg");
const icon__labs_svg_1 = require("./svgs/advisor/icon__labs.svg");
const icon__ekg_svg_1 = require("./svgs/advisor/icon__ekg.svg");
const icon__radiation_svg_1 = require("./svgs/advisor/icon__radiation.svg");
const icon__physician_svg_1 = require("./svgs/advisor/icon__physician.svg");
const icon__search_svg_1 = require("./svgs/advisor/icon__search.svg");
const icon__select_arrow_svg_1 = require("./svgs/advisor/icon__select-arrow.svg");
const icon__pending_action_svg_1 = require("./svgs/advisor/icon__pending-action.svg");
const icon__fill_form_svg_1 = require("./svgs/advisor/icon__fill-form.svg");
const icon__discharge_svg_1 = require("./svgs/advisor/icon__discharge.svg");
const icon__admit_svg_1 = require("./svgs/advisor/icon__admit.svg");
const icon__dd_admitted_svg_1 = require("./svgs/advisor/icon__dd-admitted.svg");
const icon__alleviatingFactor_svg_1 = require("./svgs/knowledge-manager/icon__alleviatingFactor.svg");
const icon__attention_svg_1 = require("./svgs/general/icon__attention.svg");
const icon__bell_svg_1 = require("./svgs/general/icon__bell.svg");
const icon__bin_svg_1 = require("./svgs/general/icon__bin.svg");
const icon__childhood_svg_1 = require("./svgs/knowledge-manager/icon__childhood.svg");
const icon__x_svg_1 = require("./svgs/general/icon__x.svg");
const icon__copy_svg_1 = require("./svgs/general/icon__copy.svg");
const icon__debug_svg_1 = require("./svgs/knowledge-manager/icon__debug.svg");
const icon__diseases_svg_1 = require("./svgs/knowledge-manager/icon__diseases.svg");
const icon__dnaScience_svg_1 = require("./svgs/knowledge-manager/icon__dnaScience.svg");
const icon__edit_svg_1 = require("./svgs/general/icon__edit.svg");
const icon__editorUser_svg_1 = require("./svgs/knowledge-manager/icon__editorUser.svg");
const icon__email_svg_1 = require("./svgs/knowledge-manager/icon__email.svg");
const icon__fileExport_svg_1 = require("./svgs/general/icon__fileExport.svg");
const icon__fileImport_svg_1 = require("./svgs/general/icon__fileImport.svg");
const icon__generalBackground_svg_1 = require("./svgs/knowledge-manager/icon__generalBackground.svg");
const icon__generalCategory_svg_1 = require("./svgs/knowledge-manager/icon__generalCategory.svg");
const icon__infoToast_svg_1 = require("./svgs/general/icon__infoToast.svg");
const icon__labResults_svg_1 = require("./svgs/advisor/icon__labResults.svg");
const icon__list_svg_1 = require("./svgs/general/icon__list.svg");
const icon__menu_svg_1 = require("./svgs/general/icon__menu.svg");
const icon__more_svg_1 = require("./svgs/general/icon__more.svg");
const icon__pathway_svg_1 = require("./svgs/knowledge-manager/icon__pathway.svg");
const icon__permissions_svg_1 = require("./svgs/general/icon__permissions.svg");
const icon__personalData_svg_1 = require("./svgs/general/icon__personalData.svg");
const icon__precipitatingFactor_svg_1 = require("./svgs/knowledge-manager/icon__precipitatingFactor.svg");
const icon__search_svg_2 = require("./svgs/general/icon__search.svg");
const icon__send_svg_1 = require("./svgs/general/icon__send.svg");
const icon__sidebarMaximize_svg_1 = require("./svgs/knowledge-manager/icon__sidebarMaximize.svg");
const icon__sortDown_svg_1 = require("./svgs/general/icon__sortDown.svg");
const icon__sortUp_svg_1 = require("./svgs/general/icon__sortUp.svg");
const icon__speechBubbleLine_svg_1 = require("./svgs/general/icon__speechBubbleLine.svg");
const icon__notifications_bell_svg_1 = require("./svgs/knowledge-manager/icon__notifications_bell.svg");
const icon__message_bubble_svg_1 = require("./svgs/knowledge-manager/icon__message_bubble.svg");
const icon__sync_svg_1 = require("./svgs/general/icon__sync.svg");
const icon__treatment_svg_1 = require("./svgs/knowledge-manager/icon__treatment.svg");
const icon__treeCollapse_svg_1 = require("./svgs/general/icon__treeCollapse.svg");
const icon__triangleDown_svg_1 = require("./svgs/general/icon__triangleDown.svg");
const icon__triangleUp_svg_1 = require("./svgs/general/icon__triangleUp.svg");
const icon__users_svg_1 = require("./svgs/general/icon__users.svg");
const icon__v_svg_1 = require("./svgs/general/icon__v.svg");
const icon__variable_svg_1 = require("./svgs/knowledge-manager/icon__variable.svg");
const icon__gear_svg_1 = require("./svgs/general/icon__gear.svg");
const icon__ekg_assesment_svg_1 = require("./svgs/advisor/icon__ekg-assesment.svg");
const icon__crown_svg_1 = require("./svgs/knowledge-manager/icon__crown.svg");
const icon__sort_arrows_svg_1 = require("./svgs/knowledge-manager/icon__sort-arrows.svg");
const icon__information_svg_1 = require("./svgs/general/icon__information.svg");
const icon__sort_alphabet_up_svg_1 = require("./svgs/general/icon__sort-alphabet-up.svg");
const icon__sort_alphabet_down_svg_1 = require("./svgs/general/icon__sort-alphabet-down.svg");
const icon__sort_time_up_svg_1 = require("./svgs/general/icon__sort-time-up.svg");
const icon__sort_time_down_svg_1 = require("./svgs/general/icon__sort-time-down.svg");
const icon__group_enable_svg_1 = require("./svgs/general/icon__group-enable.svg");
const icon__filter_stop_svg_1 = require("./svgs/general/icon__filter-stop.svg");
const icon__stop_watch_svg_1 = require("./svgs/advisor/icon__stop-watch.svg");
const icon__undo_svg_1 = require("./svgs/general/icon__undo.svg");
const icon__double_arrow_svg_1 = require("./svgs/general/icon__double-arrow.svg");
const icon__sigma_svg_1 = require("./svgs/knowledge-manager/icon__sigma.svg");
const icon__dragHandler_svg_1 = require("./svgs/knowledge-manager/icon__dragHandler.svg");
const icon__clear_svg_1 = require("./svgs/advisor/icon__clear.svg");
const icon__edit_svg_2 = require("./svgs/advisor/icon__edit.svg");
const icon__missingInfo_svg_1 = require("./svgs/knowledge-manager/icon__missingInfo.svg");
const icon__stopwatch_svg_1 = require("./svgs/advisor/icon__stopwatch.svg");
const icon_newAdvisorPhysician_svg_1 = require("./svgs/advisor/icon_newAdvisorPhysician.svg");
const icon__newAdvisorRadiation_svg_1 = require("./svgs/advisor/icon__newAdvisorRadiation.svg");
const icon__newAdvisorLab_svg_1 = require("./svgs/advisor/icon__newAdvisorLab.svg");
const icon__newAdvisorVitals_svg_1 = require("./svgs/advisor/icon__newAdvisorVitals.svg");
const icon__advisorFilter_svg_1 = require("./svgs/advisor/icon__advisorFilter.svg");
const icon__advisorCollapseAll_svg_1 = require("./svgs/advisor/icon__advisorCollapseAll.svg");
const icon__asvisorExpandAll_svg_1 = require("./svgs/advisor/icon__asvisorExpandAll.svg");
const icon__newAdvisorForm_svg_1 = require("./svgs/advisor/icon__newAdvisorForm.svg");
const icon__advisor_dd_actions_svg_1 = require("./svgs/advisor/icon__advisor-dd-actions.svg");
const icon__advisor_dd_pending_svg_1 = require("./svgs/advisor/icon__advisor-dd-pending.svg");
const icon__advisor_dd_no_actions_svg_1 = require("./svgs/advisor/icon__advisor-dd-no-actions.svg");
const icon__advisor_score_severity_svg_1 = require("./svgs/advisor/icon__advisor-score-severity.svg");
const icon__add_comment_svg_1 = require("./svgs/advisor/icon__add-comment.svg");
const icon__advisor_save_svg_1 = require("./svgs/advisor/icon__advisor-save.svg");
const icon__trendingArrow_svg_1 = require("./svgs/advisor/icon__trendingArrow.svg");
const icon__advisor_patient_svg_1 = require("./svgs/advisor/icon__advisor-patient.svg");
const icon__arrow_long_svg_1 = require("./svgs/general/icon__arrow-long.svg");
const icon__search_svg_3 = require("./svgs/knowledge-manager/icon__search.svg");
const icon__displayOptions_svg_1 = require("./svgs/knowledge-manager/icon__displayOptions.svg");
const icon__healthcareSpace_svg_1 = require("./svgs/knowledge-manager/icon__healthcareSpace.svg");
const icon__diseaseProfile_svg_1 = require("./svgs/knowledge-manager/icon__diseaseProfile.svg");
const icon__pathways_svg_1 = require("./svgs/knowledge-manager/icon__pathways.svg");
const icon__resouces_svg_1 = require("./svgs/knowledge-manager/icon__resouces.svg");
const icon__patients_svg_1 = require("./svgs/knowledge-manager/icon__patients.svg");
const icon__expressions_svg_1 = require("./svgs/knowledge-manager/icon__expressions.svg");
const icon__userPermissions_svg_1 = require("./svgs/knowledge-manager/icon__userPermissions.svg");
const icon__organizations_svg_1 = require("./svgs/knowledge-manager/icon__organizations.svg");
const icon__advisor_v4__arrow_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__arrow.svg");
const icon__advisor_v4__chat_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__chat.svg");
const icon__advisor_v4__editor_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__editor.svg");
const icon__advisor_v4__enter_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__enter.svg");
const icon__advisor_v4__expand_panel_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__expand-panel.svg");
const icon__advisor_v4__form_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__form.svg");
const icon__advisor_v4__related_findings_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__related-findings.svg");
const icon__advisor_v4__search_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__search.svg");
const icon__advisor_v4__view_pathway_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__view-pathway.svg");
const icon__advisor_v4__reference_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__reference.svg");
const icon__advisor_v4__link_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__link.svg");
const icon__advisor_v4__add_order_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__add-order.svg");
const icon__advisor_v4_sendArrow_svg_1 = require("./svgs/advisor-v4/icon__advisor_v4_sendArrow.svg");
const icon__advisor_v4__add_protocol_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__add-protocol.svg");
const icon__advisor_v4__shift_key_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__shift-key.svg");
const icon__advisor_v4__add_comment_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__add-comment.svg");
const icon__advisor_v4__edit_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__edit.svg");
const icon__advisor_v4__trend_arrow_svg_1 = require("./svgs/advisor-v4/icon__advisor-v4__trend-arrow.svg");
const icon__circle_arrow_svg_1 = require("./svgs/knowledge-manager/icon__circle-arrow.svg");
const icon__new_sort_svg_1 = require("./svgs/general/icon__new-sort.svg");
const icon__demo__arrows_svg_1 = require("./svgs/demo-specific/icon__demo__arrows.svg");
const icon__demo__dot_menu_svg_1 = require("./svgs/demo-specific/icon__demo__dot-menu.svg");
const icon__demo__ed_follow_up_svg_1 = require("./svgs/demo-specific/icon__demo__ed-follow-up.svg");
const icon__demo__ed_manager_svg_1 = require("./svgs/demo-specific/icon__demo__ed-manager.svg");
const icon__demo__ekg_svg_1 = require("./svgs/demo-specific/icon__demo__ekg.svg");
const icon__demo__gear_svg_1 = require("./svgs/demo-specific/icon__demo__gear.svg");
const icon__demo__help_desk_svg_1 = require("./svgs/demo-specific/icon__demo__help-desk.svg");
const icon__demo__labs_svg_1 = require("./svgs/demo-specific/icon__demo__labs.svg");
const icon__demo__lock_svg_1 = require("./svgs/demo-specific/icon__demo__lock.svg");
const icon__demo__medication_status_svg_1 = require("./svgs/demo-specific/icon__demo__medication-status.svg");
const icon__demo__my_reports_svg_1 = require("./svgs/demo-specific/icon__demo__my-reports.svg");
const icon__demo__patient_svg_1 = require("./svgs/demo-specific/icon__demo__patient.svg");
const icon__demo__patient_name_svg_1 = require("./svgs/demo-specific/icon__demo__patient-name.svg");
const icon__demo__print_svg_1 = require("./svgs/demo-specific/icon__demo__print.svg");
const icon__demo__radiology_svg_1 = require("./svgs/demo-specific/icon__demo__radiology.svg");
const icon__demo__tabs_1_svg_1 = require("./svgs/demo-specific/icon__demo__tabs-1.svg");
const icon__demo__tabs_2_svg_1 = require("./svgs/demo-specific/icon__demo__tabs-2.svg");
const icon__demo__tabs_3_svg_1 = require("./svgs/demo-specific/icon__demo__tabs-3.svg");
const icon__demo__tabs_4_svg_1 = require("./svgs/demo-specific/icon__demo__tabs-4.svg");
const icon__demo__tabs_5_svg_1 = require("./svgs/demo-specific/icon__demo__tabs-5.svg");
const icon__underConstruction_svg_1 = require("./svgs/knowledge-manager/icon__underConstruction.svg");
class RenderIcon extends React.Component {
    render() {
        const className = (0, frontend_1._className)('icon--wrapper', this.props.className);
        return React.createElement("div", Object.assign({}, this.props, { className: className, style: { WebkitMaskImage: `url(${this.props.icon})`, maskImage: `url(${this.props.icon})` } }));
    }
}
const iconsRenderer = (key, props) => {
    return React.createElement(RenderIcon, Object.assign({}, props, { icon: key.value }));
};
exports.iconsRenderer = iconsRenderer;
const genIcon = (Icon) => (props) => {
    const { innerRef } = props, rest = __rest(props, ["innerRef"]);
    return React.createElement("div", Object.assign({}, rest, { ref: innerRef, className: (0, frontend_1._className)('icon--wrapper', props.className) }),
        React.createElement(Icon, null));
};
exports.ICONS = {
    //Advisor{shared-components:Icons url:'},
    advisorAddPlus: { component: genIcon(icon__add_plus_svg_1.ReactComponent), url: icon__add_plus_svg_1.default },
    advisorArrowDown: { component: genIcon(icon__arrow_down_svg_1.ReactComponent), url: icon__arrow_down_svg_1.default },
    advisorFilter: { component: genIcon(icon__filter_svg_1.ReactComponent), url: icon__filter_svg_1.default },
    advisorMaximize: { component: genIcon(icon__maximize_svg_1.ReactComponent), url: icon__maximize_svg_1.default },
    advisorMinimize: { component: genIcon(icon__minimize_svg_1.ReactComponent), url: icon__minimize_svg_1.default },
    quai: { component: genIcon(icon__quai_svg_1.ReactComponent), url: icon__quai_svg_1.default },
    advisorSearch: { component: genIcon(icon__search_svg_1.ReactComponent), url: icon__search_svg_1.default },
    advisorSelectArrow: { component: genIcon(icon__select_arrow_svg_1.ReactComponent), url: icon__select_arrow_svg_1.default },
    advisorEKG: { component: genIcon(icon__ekg_svg_1.ReactComponent), url: icon__ekg_svg_1.default },
    advisorLabs: { component: genIcon(icon__labs_svg_1.ReactComponent), url: icon__labs_svg_1.default },
    advisorPhysician: { component: genIcon(icon__physician_svg_1.ReactComponent), url: icon__physician_svg_1.default },
    advisorRadiation: { component: genIcon(icon__radiation_svg_1.ReactComponent), url: icon__radiation_svg_1.default },
    pendingAction: { component: genIcon(icon__pending_action_svg_1.ReactComponent), url: icon__pending_action_svg_1.default },
    fillForm: { component: genIcon(icon__fill_form_svg_1.ReactComponent), url: icon__fill_form_svg_1.default },
    discharge: { component: genIcon(icon__discharge_svg_1.ReactComponent), url: icon__discharge_svg_1.default },
    admit: { component: genIcon(icon__admit_svg_1.ReactComponent), url: icon__admit_svg_1.default },
    ddAdmitted: { component: genIcon(icon__dd_admitted_svg_1.ReactComponent), url: icon__dd_admitted_svg_1.default },
    alleviatingFactor: { component: genIcon(icon__alleviatingFactor_svg_1.ReactComponent), url: icon__alleviatingFactor_svg_1.default },
    attention: { component: genIcon(icon__attention_svg_1.ReactComponent), url: icon__attention_svg_1.default },
    bell: { component: genIcon(icon__bell_svg_1.ReactComponent), url: icon__bell_svg_1.default },
    bin: { component: genIcon(icon__bin_svg_1.ReactComponent), url: icon__bin_svg_1.default },
    childhood: { component: genIcon(icon__childhood_svg_1.ReactComponent), url: icon__childhood_svg_1.default },
    copy: { component: genIcon(icon__copy_svg_1.ReactComponent), url: icon__copy_svg_1.default },
    debug: { component: genIcon(icon__debug_svg_1.ReactComponent), url: icon__debug_svg_1.default },
    diseases: { component: genIcon(icon__diseases_svg_1.ReactComponent), url: icon__diseases_svg_1.default },
    dnaScience: { component: genIcon(icon__dnaScience_svg_1.ReactComponent), url: icon__dnaScience_svg_1.default },
    edit: { component: genIcon(icon__edit_svg_1.ReactComponent), url: icon__edit_svg_1.default },
    editorUser: { component: genIcon(icon__editorUser_svg_1.ReactComponent), url: icon__editorUser_svg_1.default },
    email: { component: genIcon(icon__email_svg_1.ReactComponent), url: icon__email_svg_1.default },
    fileExport: { component: genIcon(icon__fileExport_svg_1.ReactComponent), url: icon__fileExport_svg_1.default },
    fileImport: { component: genIcon(icon__fileImport_svg_1.ReactComponent), url: icon__fileImport_svg_1.default },
    generalBackground: { component: genIcon(icon__generalBackground_svg_1.ReactComponent), url: icon__generalBackground_svg_1.default },
    generalCategory: { component: genIcon(icon__generalCategory_svg_1.ReactComponent), url: icon__generalCategory_svg_1.default },
    infoToast: { component: genIcon(icon__infoToast_svg_1.ReactComponent), url: icon__infoToast_svg_1.default },
    labResults: { component: genIcon(icon__labResults_svg_1.ReactComponent), url: icon__labResults_svg_1.default },
    list: { component: genIcon(icon__list_svg_1.ReactComponent), url: icon__list_svg_1.default },
    menu: { component: genIcon(icon__menu_svg_1.ReactComponent), url: icon__menu_svg_1.default },
    more: { component: genIcon(icon__more_svg_1.ReactComponent), url: icon__more_svg_1.default },
    pathway_old: { component: genIcon(icon__pathway_svg_1.ReactComponent), url: icon__pathway_svg_1.default },
    permissions: { component: genIcon(icon__permissions_svg_1.ReactComponent), url: icon__permissions_svg_1.default },
    personalData: { component: genIcon(icon__personalData_svg_1.ReactComponent), url: icon__personalData_svg_1.default },
    precipitatingFactor: { component: genIcon(icon__precipitatingFactor_svg_1.ReactComponent), url: icon__precipitatingFactor_svg_1.default },
    search_old: { component: genIcon(icon__search_svg_2.ReactComponent), url: icon__search_svg_2.default },
    send: { component: genIcon(icon__send_svg_1.ReactComponent), url: icon__send_svg_1.default },
    sidebarMaximize: { component: genIcon(icon__sidebarMaximize_svg_1.ReactComponent), url: icon__sidebarMaximize_svg_1.default },
    sortDown: { component: genIcon(icon__sortDown_svg_1.ReactComponent), url: icon__sortDown_svg_1.default },
    sortUp: { component: genIcon(icon__sortUp_svg_1.ReactComponent), url: icon__sortUp_svg_1.default },
    newSort: { component: genIcon(icon__new_sort_svg_1.ReactComponent), url: icon__new_sort_svg_1.default },
    speechBubbleLine: { component: genIcon(icon__speechBubbleLine_svg_1.ReactComponent), url: icon__speechBubbleLine_svg_1.default },
    notificationsBell: { component: genIcon(icon__notifications_bell_svg_1.ReactComponent), url: icon__notifications_bell_svg_1.default },
    messageBubble: { component: genIcon(icon__message_bubble_svg_1.ReactComponent), url: icon__message_bubble_svg_1.default },
    sync: { component: genIcon(icon__sync_svg_1.ReactComponent), url: icon__sync_svg_1.default },
    treatment: { component: genIcon(icon__treatment_svg_1.ReactComponent), url: icon__treatment_svg_1.default },
    treeCollapse: { component: genIcon(icon__treeCollapse_svg_1.ReactComponent), url: icon__treeCollapse_svg_1.default },
    triangleDown: { component: genIcon(icon__triangleDown_svg_1.ReactComponent), url: icon__triangleDown_svg_1.default },
    triangleUp: { component: genIcon(icon__triangleUp_svg_1.ReactComponent), url: icon__triangleUp_svg_1.default },
    users: { component: genIcon(icon__users_svg_1.ReactComponent), url: icon__users_svg_1.default },
    variable: { component: genIcon(icon__variable_svg_1.ReactComponent), url: icon__variable_svg_1.default },
    v: { component: genIcon(icon__v_svg_1.ReactComponent), url: icon__v_svg_1.default },
    x: { component: genIcon(icon__x_svg_1.ReactComponent), url: icon__x_svg_1.default },
    gear: { component: genIcon(icon__gear_svg_1.ReactComponent), url: icon__gear_svg_1.default },
    ekgAssessment: { component: genIcon(icon__ekg_assesment_svg_1.ReactComponent), url: icon__ekg_assesment_svg_1.default },
    crown: { component: genIcon(icon__crown_svg_1.ReactComponent), url: icon__crown_svg_1.default },
    sortArrows: { component: genIcon(icon__sort_arrows_svg_1.ReactComponent), url: icon__sort_arrows_svg_1.default },
    information: { component: genIcon(icon__information_svg_1.ReactComponent), url: icon__information_svg_1.default },
    sortABUp: { component: genIcon(icon__sort_alphabet_up_svg_1.ReactComponent), url: icon__sort_alphabet_up_svg_1.default },
    sortABDown: { component: genIcon(icon__sort_alphabet_down_svg_1.ReactComponent), url: icon__sort_alphabet_down_svg_1.default },
    sortTimeUp: { component: genIcon(icon__sort_time_up_svg_1.ReactComponent), url: icon__sort_time_up_svg_1.default },
    sortTimeDown: { component: genIcon(icon__sort_time_down_svg_1.ReactComponent), url: icon__sort_time_down_svg_1.default },
    groupEnable: { component: genIcon(icon__group_enable_svg_1.ReactComponent), url: icon__group_enable_svg_1.default },
    filterStop: { component: genIcon(icon__filter_stop_svg_1.ReactComponent), url: icon__filter_stop_svg_1.default },
    stopWatch: { component: genIcon(icon__stop_watch_svg_1.ReactComponent), url: icon__stop_watch_svg_1.default },
    undo: { component: genIcon(icon__undo_svg_1.ReactComponent), url: icon__undo_svg_1.default },
    doubleArrow: { component: genIcon(icon__double_arrow_svg_1.ReactComponent), url: icon__double_arrow_svg_1.default },
    sigma: { component: genIcon(icon__sigma_svg_1.ReactComponent), url: icon__sigma_svg_1.default },
    dragHandle: { component: genIcon(icon__dragHandler_svg_1.ReactComponent), url: icon__dragHandler_svg_1.default },
    clear: { component: genIcon(icon__clear_svg_1.ReactComponent), url: icon__clear_svg_1.default },
    editAdvisor: { component: genIcon(icon__edit_svg_2.ReactComponent), url: icon__edit_svg_2.default },
    missingInfo: { component: genIcon(icon__missingInfo_svg_1.ReactComponent), url: icon__missingInfo_svg_1.default },
    stopWatchAdvisor: { component: genIcon(icon__stopwatch_svg_1.ReactComponent), url: icon__stopwatch_svg_1.default },
    newAdvisorLab: { component: genIcon(icon__newAdvisorLab_svg_1.ReactComponent), url: icon__newAdvisorLab_svg_1.default },
    newAdvisorPhysician: { component: genIcon(icon_newAdvisorPhysician_svg_1.ReactComponent), url: icon_newAdvisorPhysician_svg_1.default },
    newAdvisorRadiation: { component: genIcon(icon__newAdvisorRadiation_svg_1.ReactComponent), url: icon__newAdvisorRadiation_svg_1.default },
    newAdvisorVitals: { component: genIcon(icon__newAdvisorVitals_svg_1.ReactComponent), url: icon__newAdvisorVitals_svg_1.default },
    newAdvisorFilter: { component: genIcon(icon__advisorFilter_svg_1.ReactComponent), url: icon__advisorFilter_svg_1.default },
    advisorCollapseAll: { component: genIcon(icon__advisorCollapseAll_svg_1.ReactComponent), url: icon__advisorCollapseAll_svg_1.default },
    advisorExpandAll: { component: genIcon(icon__asvisorExpandAll_svg_1.ReactComponent), url: icon__asvisorExpandAll_svg_1.default },
    newAdvisorForm: { component: genIcon(icon__newAdvisorForm_svg_1.ReactComponent), url: icon__newAdvisorForm_svg_1.default },
    advisorDDActions: { component: genIcon(icon__advisor_dd_actions_svg_1.ReactComponent), url: icon__advisor_dd_actions_svg_1.default },
    advisorDDPending: { component: genIcon(icon__advisor_dd_pending_svg_1.ReactComponent), url: icon__advisor_dd_pending_svg_1.default },
    advisorDDNoActions: { component: genIcon(icon__advisor_dd_no_actions_svg_1.ReactComponent), url: icon__advisor_dd_no_actions_svg_1.default },
    advisorScoreSeverity: { component: genIcon(icon__advisor_score_severity_svg_1.ReactComponent), url: icon__advisor_score_severity_svg_1.default },
    addComment: { component: genIcon(icon__add_comment_svg_1.ReactComponent), url: icon__add_comment_svg_1.default },
    advisorSave: { component: genIcon(icon__advisor_save_svg_1.ReactComponent), url: icon__advisor_save_svg_1.default },
    trendingArrow: { component: genIcon(icon__trendingArrow_svg_1.ReactComponent), url: icon__trendingArrow_svg_1.default },
    advisorPatient: { component: genIcon(icon__advisor_patient_svg_1.ReactComponent), url: icon__advisor_patient_svg_1.default },
    arrowLong: { component: genIcon(icon__arrow_long_svg_1.ReactComponent), url: icon__arrow_long_svg_1.default },
    healthcareSpace: { component: genIcon(icon__healthcareSpace_svg_1.ReactComponent), url: icon__healthcareSpace_svg_1.default },
    diseaseProfile: { component: genIcon(icon__diseaseProfile_svg_1.ReactComponent), url: icon__diseaseProfile_svg_1.default },
    expressions: { component: genIcon(icon__expressions_svg_1.ReactComponent), url: icon__expressions_svg_1.default },
    pathways: { component: genIcon(icon__pathways_svg_1.ReactComponent), url: icon__pathways_svg_1.default },
    resources: { component: genIcon(icon__resouces_svg_1.ReactComponent), url: icon__resouces_svg_1.default },
    organization: { component: genIcon(icon__organizations_svg_1.ReactComponent), url: icon__organizations_svg_1.default },
    patients: { component: genIcon(icon__patients_svg_1.ReactComponent), url: icon__patients_svg_1.default },
    userPermissions: { component: genIcon(icon__userPermissions_svg_1.ReactComponent), url: icon__userPermissions_svg_1.default },
    search: { component: genIcon(icon__search_svg_3.ReactComponent), url: icon__search_svg_3.default },
    displayOptions: { component: genIcon(icon__displayOptions_svg_1.ReactComponent), url: icon__displayOptions_svg_1.default },
    circleArrow: { component: genIcon(icon__circle_arrow_svg_1.ReactComponent), url: icon__circle_arrow_svg_1.default },
    //Advisor V4
    advisor_v4_arrow: { component: genIcon(icon__advisor_v4__arrow_svg_1.ReactComponent), url: icon__advisor_v4__arrow_svg_1.default },
    advisor_v4_chat: { component: genIcon(icon__advisor_v4__chat_svg_1.ReactComponent), url: icon__advisor_v4__chat_svg_1.default },
    advisor_v4_editor: { component: genIcon(icon__advisor_v4__editor_svg_1.ReactComponent), url: icon__advisor_v4__editor_svg_1.default },
    advisor_v4_enter: { component: genIcon(icon__advisor_v4__enter_svg_1.ReactComponent), url: icon__advisor_v4__enter_svg_1.default },
    advisor_v4_expandPanel: { component: genIcon(icon__advisor_v4__expand_panel_svg_1.ReactComponent), url: icon__advisor_v4__expand_panel_svg_1.default },
    advisor_v4_form: { component: genIcon(icon__advisor_v4__form_svg_1.ReactComponent), url: icon__advisor_v4__form_svg_1.default },
    advisor_v4_relatedFindings: { component: genIcon(icon__advisor_v4__related_findings_svg_1.ReactComponent), url: icon__advisor_v4__related_findings_svg_1.default },
    advisor_v4_search: { component: genIcon(icon__advisor_v4__search_svg_1.ReactComponent), url: icon__advisor_v4__search_svg_1.default },
    advisor_v4_viewPathway: { component: genIcon(icon__advisor_v4__view_pathway_svg_1.ReactComponent), url: icon__advisor_v4__view_pathway_svg_1.default },
    advisor_v4_reference: { component: genIcon(icon__advisor_v4__reference_svg_1.ReactComponent), url: icon__advisor_v4__reference_svg_1.default },
    advisor_v4_link: { component: genIcon(icon__advisor_v4__link_svg_1.ReactComponent), url: icon__advisor_v4__link_svg_1.default },
    advisor_v4_addOrder: { component: genIcon(icon__advisor_v4__add_order_svg_1.ReactComponent), url: icon__advisor_v4__add_order_svg_1.default },
    advisor_v4_sendArrow: { component: genIcon(icon__advisor_v4_sendArrow_svg_1.ReactComponent), url: icon__advisor_v4_sendArrow_svg_1.default },
    advisor_v4_addProtocol: { component: genIcon(icon__advisor_v4__add_protocol_svg_1.ReactComponent), url: icon__advisor_v4__add_protocol_svg_1.default },
    advisor_v4_shiftKey: { component: genIcon(icon__advisor_v4__shift_key_svg_1.ReactComponent), url: icon__advisor_v4__shift_key_svg_1.default },
    advisor_v4_addComment: { component: genIcon(icon__advisor_v4__add_comment_svg_1.ReactComponent), url: icon__advisor_v4__add_comment_svg_1.default },
    advisor_v4_edit: { component: genIcon(icon__advisor_v4__edit_svg_1.ReactComponent), url: icon__advisor_v4__edit_svg_1.default },
    advisor_v4_trendArrow: { component: genIcon(icon__advisor_v4__trend_arrow_svg_1.ReactComponent), url: icon__advisor_v4__trend_arrow_svg_1.default },
    //Demo Specific
    demo_arrows: { component: genIcon(icon__demo__arrows_svg_1.ReactComponent), url: icon__demo__arrows_svg_1.default },
    demo_dotMenu: { component: genIcon(icon__demo__dot_menu_svg_1.ReactComponent), url: icon__demo__dot_menu_svg_1.default },
    demo_edFollowUp: { component: genIcon(icon__demo__ed_follow_up_svg_1.ReactComponent), url: icon__demo__ed_follow_up_svg_1.default },
    demo_edManager: { component: genIcon(icon__demo__ed_manager_svg_1.ReactComponent), url: icon__demo__ed_manager_svg_1.default },
    demo_ekg: { component: genIcon(icon__demo__ekg_svg_1.ReactComponent), url: icon__demo__ekg_svg_1.default },
    demo_gear: { component: genIcon(icon__demo__gear_svg_1.ReactComponent), url: icon__demo__gear_svg_1.default },
    demo_helpDesk: { component: genIcon(icon__demo__help_desk_svg_1.ReactComponent), url: icon__demo__help_desk_svg_1.default },
    demo_labs: { component: genIcon(icon__demo__labs_svg_1.ReactComponent), url: icon__demo__labs_svg_1.default },
    demo_lock: { component: genIcon(icon__demo__lock_svg_1.ReactComponent), url: icon__demo__lock_svg_1.default },
    demo_medicationStatus: { component: genIcon(icon__demo__medication_status_svg_1.ReactComponent), url: icon__demo__medication_status_svg_1.default },
    demo_myReports: { component: genIcon(icon__demo__my_reports_svg_1.ReactComponent), url: icon__demo__my_reports_svg_1.default },
    demo_patient: { component: genIcon(icon__demo__patient_svg_1.ReactComponent), url: icon__demo__patient_svg_1.default },
    demo_patientName: { component: genIcon(icon__demo__patient_name_svg_1.ReactComponent), url: icon__demo__patient_name_svg_1.default },
    demo_print: { component: genIcon(icon__demo__print_svg_1.ReactComponent), url: icon__demo__print_svg_1.default },
    demo_radiology: { component: genIcon(icon__demo__radiology_svg_1.ReactComponent), url: icon__demo__radiology_svg_1.default },
    demo_tabs1: { component: genIcon(icon__demo__tabs_1_svg_1.ReactComponent), url: icon__demo__tabs_1_svg_1.default },
    demo_tabs2: { component: genIcon(icon__demo__tabs_2_svg_1.ReactComponent), url: icon__demo__tabs_2_svg_1.default },
    demo_tabs3: { component: genIcon(icon__demo__tabs_3_svg_1.ReactComponent), url: icon__demo__tabs_3_svg_1.default },
    demo_tabs4: { component: genIcon(icon__demo__tabs_4_svg_1.ReactComponent), url: icon__demo__tabs_4_svg_1.default },
    demo_tabs5: { component: genIcon(icon__demo__tabs_5_svg_1.ReactComponent), url: icon__demo__tabs_5_svg_1.default },
    under_construction: { component: genIcon(icon__underConstruction_svg_1.ReactComponent), url: icon__underConstruction_svg_1.default }
};
const QuaiIconKeys = () => {
    return (0, ts_common_1._keys)(exports.ICONS);
};
exports.QuaiIconKeys = QuaiIconKeys;
