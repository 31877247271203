"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_LogTemplate = exports.ModuleFE_LogTemplate_Class = exports.dispatch_onLogTemplatesUpdated = void 0;
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const frontend_1 = require("@app/styles/frontend");
const ts_common_1 = require("@nu-art/ts-common");
exports.dispatch_onLogTemplatesUpdated = new types_1.ThunderDispatcherV3('__onLogTemplatesUpdated');
class ModuleFE_LogTemplate_Class extends frontend_1.ModuleFE_QuaiProtoItem {
    constructor() {
        super(shared_1.DBDef_LogTemplate, exports.dispatch_onLogTemplatesUpdated, shared_1.Validator_MinimalAssertionLogTemplate);
        this.__searchFilterMapper = (item) => [item.label];
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => ({ _id: i._id, uiLabel: i.label }))
            }]);
        this.isParamOfTypes = (param, types) => {
            return types.includes(param.item.type);
        };
        this._v1 = {};
    }
    validateInternal(_instance) {
        _instance = (0, ts_common_1.deleteKeysObject)(_instance, ['_variableIds', '_externalParams']);
        super.validateInternal(_instance);
    }
}
exports.ModuleFE_LogTemplate_Class = ModuleFE_LogTemplate_Class;
exports.ModuleFE_LogTemplate = new ModuleFE_LogTemplate_Class();
