"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalRangeToExpressionSingleFE = void 0;
const shared_1 = require("../../../../../shared");
const frontend_1 = require("../../../../../_entity/variable/frontend");
const frontend_2 = require("../../../../../_entity/value/frontend");
const getDataMappers = () => {
    return {
        variableMap: frontend_1.ModuleFE_Variable.cache.arrayToMap(item => item._id),
        valueMap: frontend_2.ModuleFE_Value.cache.arrayToMap(item => item._id)
    };
};
const normalRangeToExpressionSingleFE = (coupling) => {
    const dataMappers = getDataMappers();
    return (0, shared_1.valueToExpressionSingle)(coupling, dataMappers);
};
exports.normalRangeToExpressionSingleFE = normalRangeToExpressionSingleFE;
