"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Metadata_Population = exports.PopulationTypeLabelMap = void 0;
const types_1 = require("./types");
exports.PopulationTypeLabelMap = {
    general: 'General',
    specific: 'Specific',
};
const Metadata_PopulationType = {
    optional: false,
    description: `Type of the population (${[...types_1.PopulationTypes].join(' / ')})`,
    valueType: 'string',
};
exports.Metadata_Population = {
    type: Object.assign({}, Metadata_PopulationType),
    specifications: {
        optional: true,
        description: 'Specifications for the population if the type is specific',
        valueType: 'object',
        metadata: {}
    }
};
