"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_AdvisorContextEditor = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const shared_1 = require("../../../../_entity/advisor-context/shared");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
require("./Component_AdvisorContextManager.scss");
const frontend_2 = require("@app/hcs/frontend");
const Editor_RuleEditor_1 = require("./editors/Editor_RuleEditor");
const styles_1 = require("@app/styles");
const ts_common_1 = require("@nu-art/ts-common");
const Renderer_Editor__FormDataGroup_1 = require("../../../../_entity/advisor-context/frontend/renderers/Renderer_Editor__FormDataGroup");
const ui_components_2 = require("@app/hcs/_entity/clinical-form/frontend/ui-components");
const Editor_FhirQuery_1 = require("./editors/Editor_FhirQuery");
class Component_AdvisorContextEditor extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const className = (0, frontend_1._className)('manager__header', 'edit-mode');
            return React.createElement(frontend_1.LL_H_C, { className: className },
                this.renderHeaderMain(),
                this.renderHeaderTail());
        };
        this.renderHeaderMain = () => {
            const advisorContext = this.state.editable;
            const title = advisorContext.get('label');
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__header__main' },
                React.createElement(frontend_1.LL_V_L, { className: 'manager__header__title' },
                    advisorContext.get('_id') && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'manager__header__title__label' }, title)),
                    !advisorContext.get('_id') && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'manager__header__title__label' }, "Add New Advisor Context"))));
        };
        this.renderHeaderTail = () => {
            const _id = this.state.editable.get('_id');
            return React.createElement(frontend_1.LL_H_C, { className: 'editor-advisor-context__header__tail' },
                _id && React.createElement(frontend_1.TS_Button, { onClick: this.props.deleteAdvisorContext }, "Delete"),
                React.createElement(frontend_1.TS_Button, { onClick: this.props.deselectAdvisorContext }, _id ? 'Close' : 'Cancel'),
                React.createElement(frontend_1.TS_BusyButton, { className: 'preferred-action', onClick: async () => this.state.editable.save() }, "Save"));
        };
        // ######################## Render - Body ########################
        this.renderLabelInput = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Label', error: editable.hasError('label') },
                React.createElement(ui_components_1.DefaultEditor_InputText, { editable: editable, prop: 'label' }));
        };
        this.renderRules = () => {
            const editable = this.state.editable;
            const editableRules = editable.editProp('rules', []);
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { className: 'rules-container', label: 'Rules' },
                React.createElement(frontend_1.LL_V_L, { className: 'rules-list' },
                    editableRules.item.map((rule, index) => {
                        const editableRule = editableRules.editProp(index, { tagIds: [] });
                        return React.createElement(frontend_1.TS_Card, { key: index, className: 'rule-container' },
                            React.createElement(frontend_1.LL_H_C, { className: 'rule-header' },
                                React.createElement("div", { className: 'rule-title' },
                                    "Rule ",
                                    index + 1),
                                React.createElement(styles_1.ICONS.x.component, { onClick: () => editableRules.removeArrayItem(index) })),
                            React.createElement(Editor_RuleEditor_1.Editor_RuleEditor, { contextId: this.state.editable.get('_id'), editable: editableRule }));
                    }),
                    React.createElement(frontend_1.TS_Button, { className: 'add-rule-button', onClick: () => editableRules.updateArrayAt({ tagIds: [] }) }, "Add Rule")));
        };
        this.renderFhirQuery = () => {
            const editable = this.state.editable;
            const editableQueryData = editable.editProp('queryData', {});
            return React.createElement(Editor_FhirQuery_1.Editor_FhirQuery, { editMode: this.state.editMode, editable: editableQueryData });
        };
        this.renderDefaultTextInput = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Default Text' },
                React.createElement(ui_components_1.DefaultEditor_InputText_Optional, { editable: editable, prop: 'defaultText' }));
        };
        this.renderAdvisorLabelInput = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Advisor label' },
                React.createElement(ui_components_1.DefaultEditor_InputText_Optional, { editable: editable, prop: 'advisorLabel' }));
        };
        this.renderClinicalFormDropdown = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Clinical Form' },
                React.createElement(ui_components_2.DropDown_ClinicalForm.editable, { editable: editable, prop: 'clinicalFormId', canUnselect: true, onSelected: async (form) => {
                        if (!form)
                            return editable.updateObj({ clinicalFormId: undefined });
                        await editable.updateObj({ clinicalFormId: form._id });
                    } }));
        };
        this.renderBody_TypeSingle = () => {
            if (this.state.editable.item.type !== shared_1.FormData_Single)
                return;
            const editable = this.state.editable;
            const rulesEditable = editable.get('rules');
            return React.createElement(frontend_1.LL_H_C, { className: 'formdata-single__container' },
                React.createElement(frontend_1.LL_V_L, { className: 'formdata-single__body' },
                    this.renderDefaultTextInput(),
                    this.renderAdvisorLabelInput(),
                    this.renderClinicalFormDropdown(),
                    this.renderRules(),
                    this.renderFhirQuery()),
                React.createElement(frontend_2.Q_VariablePanel, { filter: (item) => {
                        if (!rulesEditable)
                            return false;
                        return rulesEditable.some(rule => (0, ts_common_1.arrayIncludesAll)(item._tagIds, rule.tagIds));
                    } }));
        };
        this.renderBody_TypeMulti = () => {
            if (this.state.editable.item.type !== shared_1.FormData_Multi)
                return;
            const editable = this.state.editable;
            const groupEditable = editable.editProp('groups', []);
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'FormData Groups' },
                React.createElement(frontend_1.LL_V_L, { className: 'formdata-groups' }, groupEditable.item.map((group, index) => {
                    return React.createElement(Renderer_Editor__FormDataGroup_1.Renderer_Editor__FormDataGroup, { key: index, editable: groupEditable.editProp(index, { formDataIds: [] }), editMode: this.state.editMode, removeSelf: () => groupEditable.removeArrayItem(index) });
                })),
                this.state.editMode &&
                    React.createElement(frontend_1.TS_Button, { onClick: () => groupEditable.updateArrayAt({ formDataIds: [], label: '' }) }, "Add Group"));
        };
        this.renderBody = () => {
            return React.createElement("div", { className: 'context-manager__editor-body' },
                this.renderTypeSelector(),
                this.renderLabelInput(),
                this.state.editable.item.type === shared_1.FormData_Single && this.renderBody_TypeSingle(),
                this.state.editable.item.type === shared_1.FormData_Multi && this.renderBody_TypeMulti());
        };
        this.renderTypeSelector = () => {
            const adapter = (0, frontend_1.SimpleListAdapter)([shared_1.FormData_Single, shared_1.FormData_Multi], node => React.createElement("div", { style: { textTransform: 'capitalize' } }, node.item));
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'type' },
                React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: this.state.editable.item.type, disabled: !!this.state.editable.item._id, onSelected: async (selected) => {
                        var _a, _b;
                        const _editable = this.state.editable;
                        await _editable.updateObj({
                            type: selected,
                            groups: selected !== shared_1.FormData_Multi ? undefined : (_a = _editable.item.groups) !== null && _a !== void 0 ? _a : [],
                            rules: selected !== shared_1.FormData_Single ? undefined : (_b = _editable.item.rules) !== null && _b !== void 0 ? _b : [],
                        });
                    } }));
        };
    }
    deriveStateFromProps(nextProps, _state) {
        const state = super.deriveStateFromProps(nextProps, _state);
        state.editMode = nextProps.editMode;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'quai-item-manager', id: 'editor-advisor-context' },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Component_AdvisorContextEditor = Component_AdvisorContextEditor;
