"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_SmartExpressionEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const react_1 = require("@monaco-editor/react");
const TokenizerWrapper_1 = require("./antlr-utils/TokenizerWrapper");
const antlr4ts_1 = require("antlr4ts");
const utils_1 = require("./utils");
const ts_common_1 = require("@nu-art/ts-common");
require("./Component_SmartExpressionEditor.scss");
const Component_CalculatorButtons_1 = require("./calculator-buttons/Component_CalculatorButtons");
const Module_ExpressionPrettierUtils_1 = require("../../modules/expression-prettifier/Module_ExpressionPrettierUtils");
const QuaiExpressionsV3Lexer_1 = require("../../../../../shared/expression/grammar/QuaiExpressionsV3Lexer");
const frontend_2 = require("../../../../../_entity/variable/frontend");
const frontend_3 = require("../../../../../_entity/expression/frontend");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
const frontend_4 = require("../../../../../_entity/value/frontend");
const frontend_5 = require("../../../../../_entity/variable-relation/frontend");
class Component_SmartExpressionEditor extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.langName = 'quai-expressions';
        //######################### Initiate Monaco #########################
        this.MonacoSettings = {
            registerLanguage: () => {
                this.state.monaco.languages.register({ id: this.langName });
            },
            setTokenizers: () => {
                this.state.monaco.languages.setMonarchTokensProvider(this.langName, {
                    tokenizer: {
                        root: [
                            [/./, { token: 'invalid' }],
                            [/T\d+/, { token: 'operator' }]
                        ]
                    }
                });
                this.state.monaco.languages.setTokensProvider(this.langName, {
                    getInitialState: () => new TokenizerWrapper_1.SimpleState(),
                    tokenize: (line, state) => {
                        const lexer = new QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer(antlr4ts_1.CharStreams.fromString(line));
                        const adapter = new TokenizerWrapper_1.AntlrToMonacoAdapter(lexer);
                        return {
                            endState: state,
                            tokens: adapter.tokenize(line)
                        };
                    }
                });
            },
            defineAndSetTheme: () => {
                this.state.monaco.editor.defineTheme('quai-theme', {
                    base: 'vs',
                    inherit: true,
                    rules: [
                        { token: 'number', foreground: '#1a4957' },
                        { token: 'string', foreground: '#c95132' },
                        { token: 'boolean', foreground: '#151594' },
                        { token: 'variable', foreground: '#800080' },
                        { token: 'identifier', foreground: '#94620a', fontStyle: 'italic' },
                        { token: 'whitespace', foreground: '#FFFFFF' },
                        { token: 'operator', foreground: '#17a666', fontStyle: 'bold', background: '#ff0000' },
                    ],
                    colors: {
                        'editor.background': '#FFFFFF',
                        'editor.foreground': '#3b3a3a',
                    }
                });
                this.state.monaco.editor.setTheme('quai-theme');
            },
            setAutoCompleteProvider: () => {
                this.state.monaco.languages.registerCompletionItemProvider(this.langName, {
                    provideCompletionItems: (model, position) => {
                        var _a, _b, _c;
                        const currentWordInfo = model.getWordAtPosition(position);
                        const previousText = model.getValueInRange({
                            startLineNumber: position.lineNumber,
                            startColumn: 1,
                            endLineNumber: position.lineNumber,
                            endColumn: currentWordInfo ? currentWordInfo.startColumn : position.column
                        });
                        const regex = /(var|enum|attr|precipitating|relieving|expr)\(([^)]*?)(\).(\w*\.)*\w*)?$/;
                        const match = previousText.match(regex);
                        const prevWord = match ? match[0] : '';
                        let results = [];
                        if (prevWord.includes('expr')) {
                            results = (0, ts_common_1.filterInstances)(this.autoCompleteFunctions.expression(currentWordInfo.word.trim()));
                        }
                        else if (prevWord.includes('enum')) {
                            results = this.autoCompleteFunctions.enumerated(currentWordInfo.word.trim());
                        }
                        else if (prevWord.includes('var')) {
                            results = this.autoCompleteFunctions.variable(currentWordInfo.word.trim());
                        }
                        else if (/var\([^)]*\)(\.\w*)*/.test(prevWord)) {
                            results = (_a = this.autoCompleteFunctions.property(prevWord.trim())) !== null && _a !== void 0 ? _a : [];
                        }
                        else if (prevWord.includes('attr')) {
                            if (match)
                                results = this.autoCompleteFunctions.attribute(match, currentWordInfo.word.trim());
                        }
                        else if (prevWord.includes('precipitating')) {
                            if (match)
                                results = (_b = this.autoCompleteFunctions.precipitatingOrRelieving(match, currentWordInfo.word.trim(), 'Precipitating Factor')) !== null && _b !== void 0 ? _b : [];
                        }
                        else if (prevWord.includes('relieving')) {
                            if (match)
                                results = (_c = this.autoCompleteFunctions.precipitatingOrRelieving(match, currentWordInfo.word.trim(), 'Relieving Factor')) !== null && _c !== void 0 ? _c : [];
                        }
                        const range = {
                            startLineNumber: position.lineNumber,
                            endLineNumber: position.lineNumber,
                            startColumn: currentWordInfo ? currentWordInfo.startColumn : position.column,
                            endColumn: position.column
                        };
                        return {
                            suggestions: (0, ts_common_1.filterDuplicates)(results).map(result => ({
                                label: result,
                                kind: this.state.monaco.languages.CompletionItemKind.Text,
                                insertText: result,
                                insertTextRules: this.state.monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                range
                            }))
                        };
                    },
                });
            },
            setEditorValue: () => {
                const currExpr = this.state.value;
                this.state.editor.setValue(currExpr);
            },
            index: () => {
                this.MonacoSettings.registerLanguage();
                this.MonacoSettings.setTokenizers();
                this.MonacoSettings.defineAndSetTheme();
                this.MonacoSettings.setAutoCompleteProvider();
                this.MonacoSettings.setEditorValue();
            }
        };
        //######################### Monaco AutoComplete Functions #########################
        this.autoCompleteFunctions = {
            variable: (currWord) => {
                return (0, ts_common_1.filterDuplicates)(frontend_2.ModuleFE_Variable.cache.filter(item => { var _a; return item._nameInLowercase.includes((_a = currWord.toLowerCase()) !== null && _a !== void 0 ? _a : ''); }).map(item => item.name));
            },
            expression: (currWord) => {
                return (0, ts_common_1.filterDuplicates)(frontend_3.ModuleFE_Expression.cache.filter(item => item.label.toLowerCase()
                    .includes(currWord.toLowerCase()) && (item.expressionType === consts_1.ExpressionType_GeneralScore.dbKey || item.expressionType === consts_1.ExpressionType_GeneralFunction.dbKey)))
                    .map(item => item.label);
            },
            property: (prevWord) => {
                var _a;
                const varPattern = /\(([^)]+)\)/;
                const matchVar = (_a = varPattern.exec(prevWord)) === null || _a === void 0 ? void 0 : _a[1];
                const splitExpr = prevWord.match(/var\([^)]*\)\.(\w+(\.\w+)*)/);
                let propertiesArray = [];
                if (splitExpr && splitExpr[1]) {
                    propertiesArray = splitExpr[1].split('.');
                }
                const variable = frontend_2.ModuleFE_Variable.cache.find(item => item._nameInLowercase === (matchVar === null || matchVar === void 0 ? void 0 : matchVar.toLowerCase()));
                const currValue = propertiesArray.reduce((value, currentProp) => {
                    // @ts-ignore
                    return value[currentProp];
                }, variable);
                if (!currValue)
                    return;
                return (0, ts_common_1._keys)(currValue);
            },
            attribute: (match, currWord) => {
                if (match[2].length) {
                    const baseVar = frontend_2.ModuleFE_Variable.cache.find(item => item._nameInLowercase === match[2].split(',')[0].toLowerCase());
                    return (0, ts_common_1.filterDuplicates)(frontend_2.ModuleFE_Variable.cache.filter(item => { var _a; return !!((_a = baseVar === null || baseVar === void 0 ? void 0 : baseVar._attributeVarIds) === null || _a === void 0 ? void 0 : _a.includes(item._id)); }).map(item => item.name));
                }
                else {
                    return (0, ts_common_1.filterDuplicates)(frontend_2.ModuleFE_Variable.cache.filter(item => { var _a; return !!(item._nameInLowercase.includes(currWord) && ((_a = item._attributeVarIds) === null || _a === void 0 ? void 0 : _a.length)); })
                        .map(item => item.name));
                }
            },
            enumerated: (currWord) => {
                const values = this.resolveVariableEnumValues((0, utils_1.splitExpression)(this.state.editor.getValue()));
                if (values)
                    return values;
                else
                    return (0, ts_common_1.filterDuplicates)(frontend_4.ModuleFE_Value.cache.filter(item => {
                        var _a, _b, _c;
                        return (_b = (_a = item.data) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes((_c = currWord.toLowerCase()) !== null && _c !== void 0 ? _c : '');
                    })).map(item => item.data.value);
            },
            precipitatingOrRelieving: (match, currWord, type) => {
                if (match[2].length) {
                    const baseVar = frontend_2.ModuleFE_Variable.cache.find(item => item._nameInLowercase === match[2].split(',')[0].toLowerCase());
                    if (baseVar)
                        return this.resolvePrecipitatingOrRelievingVars(baseVar, type);
                }
                else {
                    return (0, ts_common_1.filterDuplicates)(frontend_2.ModuleFE_Variable.cache.filter(item => !!(item._nameInLowercase.includes(currWord) && this.resolvePrecipitatingOrRelievingVars(item, type).length))
                        .map(item => item.name));
                }
            }
        };
        //######################### Logic #########################
        this.resolveVariableEnumValues = (splitExpr) => {
            const suspectedVar = splitExpr[splitExpr.length - 3];
            if (!(suspectedVar === null || suspectedVar === void 0 ? void 0 : suspectedVar.includes('var')))
                return;
            const varName = Module_ExpressionPrettierUtils_1.Module_ExpressionPrettierUtils.findDependencies(suspectedVar, 'var')[0];
            const variable = frontend_2.ModuleFE_Variable.cache.find(item => item._nameInLowercase === varName.toLowerCase());
            if (!variable)
                return;
            const value = frontend_4.ModuleFE_Value.cache.unique(variable.valueId);
            return frontend_4.ModuleFE_Value.cache.filter(item => { var _a; return (_a = (value === null || value === void 0 ? void 0 : value.data).scope) === null || _a === void 0 ? void 0 : _a.includes(item._id); })
                .map(item => item.data.value);
        };
        this.resolvePrecipitatingOrRelievingVars = (baseVar, type) => {
            const relations = frontend_5.ModuleFE_VariableRelation.cache.filter(item => (item._firstVariableId === baseVar._id && item.relation === type));
            return (0, ts_common_1.filterDuplicates)((0, ts_common_1.filterInstances)(relations.map(relation => { var _a, _b; return (_b = frontend_2.ModuleFE_Variable.cache.unique((_a = relation.rightSide[0]) === null || _a === void 0 ? void 0 : _a.variableId)) === null || _b === void 0 ? void 0 : _b.name; })));
        };
        this.handleMonacoMount = (editor, monaco) => {
            this.setState({ monaco: monaco, editor: editor }, this.MonacoSettings.index);
        };
        //######################### Render #########################
        this.renderOperatorButtons = () => {
            var _a;
            if (this.props.hideButtons)
                return '';
            return React.createElement(frontend_1.LL_H_C, { className: 'buttons' },
                React.createElement(Component_CalculatorButtons_1.Component_CalculatorButtons, { width: (_a = this.props.width) !== null && _a !== void 0 ? _a : '100%', editor: this.state.editor, freeForm: this.props.freeForm }));
        };
    }
    //######################### Life Cycle #########################
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = this.state || {};
        state.terms = nextProps.terms;
        (_a = state.value) !== null && _a !== void 0 ? _a : (state.value = nextProps.value);
        if (state.editor && (state.value !== nextProps.value)) {
            state.value = nextProps.value;
            state.editor.setValue(nextProps.value);
        }
        return state;
    }
    render() {
        var _a;
        return React.createElement(frontend_1.LL_V_L, { className: 'smart-editor-container' },
            React.createElement("div", { onBlur: (event) => {
                    this.props.onEditorBlur(this.state.editor.getValue());
                } },
                React.createElement(react_1.Editor, { onMount: this.handleMonacoMount, defaultLanguage: this.langName, height: (_a = this.props.height) !== null && _a !== void 0 ? _a : '100%', width: this.props.width, options: {
                        overviewRulerBorder: false,
                        overviewRulerLanes: 0,
                        renderLineHighlight: this.props.withoutLineHighlight ? 'none' : 'line',
                        minimap: { enabled: false },
                        lineNumbers: 'off',
                        readOnly: !!this.props.disabled
                    } })),
            this.renderOperatorButtons());
    }
}
exports.Component_SmartExpressionEditor = Component_SmartExpressionEditor;
