"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_PathwayTransition = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
const shared_1 = require("../../../_enum/metapathway-action/shared");
const Validator_ModifiableProps = {
    pathwayId: ts_common_1.tsValidateUniqueId,
    sourceIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    targetIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    dds: (0, ts_common_1.tsValidateArray)({ diseaseProfileId: ts_common_1.tsValidateUniqueId, probability: (0, ts_common_1.tsValidateIsInRange)([[0, 1]], false) }),
    transitionExpression: (0, ts_common_1.tsValidateString)(undefined, false),
    resolutionFunction: {
        operator: (0, ts_common_1.tsValidateValue)([...types_1.operatorTypes], false),
        expressions: (0, ts_common_1.tsValidateArray)({ expressionId: ts_common_1.tsValidateUniqueId, expectedValue: (0, ts_common_1.tsValidateBoolean)(false) }, false)
    },
    priorityIndex: (0, ts_common_1.tsValidateNumber)(),
    resolutionFunctionId: ts_common_1.tsValidateOptionalId,
    testsIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    consultIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    nurseIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    additionalOrdersIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    treatmentsIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    label: (0, ts_common_1.tsValidateString)(undefined, false),
    alert: (0, ts_common_1.tsValidateString)(undefined, false),
    metaPathwayActions: (0, ts_common_1.tsValidateArray)(shared_1.Validator_MetaPathwayAction),
};
const Validator_GeneratedProps = {
    _interflowPathwayIds: ts_common_1.tsValidator_arrayOfUniqueIds
};
exports.DBDef_PathwayTransition = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.4', '1.0.3', '1.0.2', '1.0.1', '1.0.0'],
    dbKey: 'pathway-transitions',
    entityName: 'PathwayTransition',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'pathway-transition'
    },
    backend: {
        name: 'pathway-transitions'
    },
    dependencies: {
        'pathwayId': {
            dbKey: 'pathway',
            fieldType: 'string'
        },
        'sourceIds': {
            dbKey: 'pathway-states',
            fieldType: 'string[]'
        },
        'targetIds': {
            dbKey: 'pathway-states',
            fieldType: 'string[]'
        },
        '_interflowPathwayIds': {
            dbKey: 'pathway',
            fieldType: 'string[]'
        },
        'treatmentsIds': {
            dbKey: 'orders',
            fieldType: 'string[]'
        },
        'testsIds': {
            dbKey: 'orders',
            fieldType: 'string[]'
        },
        'consultIds': {
            dbKey: 'orders',
            fieldType: 'string[]'
        },
        'nurseIds': {
            dbKey: 'orders',
            fieldType: 'string[]'
        },
    }
};
