"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_JSONPreview = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
require("./Component_JSONPreview.scss");
const ts_common_1 = require("@nu-art/ts-common");
const q_components_1 = require("../../../../../../q-components");
class Component_JSONPreview extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    deriveStateFromProps(nextProps, state) {
        return super.deriveStateFromProps(nextProps, state);
    }
    render() {
        const editableClone = (0, ts_common_1.deepClone)(this.state.editable.item);
        return React.createElement(frontend_1.LL_V_L, { className: 'json-view' },
            React.createElement(q_components_1.QJSONViewer, { filterGenerated: true, item: editableClone }));
    }
}
exports.Component_JSONPreview = Component_JSONPreview;
