"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_AdvisorContext = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
const shared_1 = require("@app/fhir/shared");
const Validator_SnippetGroup = {
    label: (0, ts_common_1.tsValidateString)(),
    formDataIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
const Validator_QueryData = {
    resourceType: (0, ts_common_1.tsValidateValue)((0, ts_common_1._keys)(shared_1.ListOfFHIRQueryResources), false),
    params: (input, parentInput) => {
        var _a;
        if (!input && !((_a = parentInput.queryData) === null || _a === void 0 ? void 0 : _a.resourceType))
            return;
        if (!parentInput.queryData)
            return;
        const error = (0, ts_common_1.tsValidateResult)(input, (0, ts_common_1.tsValidateExists)());
        if (error)
            return error;
        const typeDefinition = shared_1.ListOfFHIRQueryResources[parentInput.queryData.resourceType];
        const keyValidator = (0, ts_common_1.tsValidateValue)((0, ts_common_1._keys)(typeDefinition));
        const valueValidator = (0, ts_common_1.tsValidateString)(2); //tsValidateValue(['TODO'])
        return (0, ts_common_1.tsValidateResult)(input, (0, ts_common_1.tsValidateDynamicObject)(valueValidator, keyValidator));
    }
};
const Validator_FormData_General = {
    label: (0, ts_common_1.tsValidateString)(),
};
const Validator_FormData_Multi = Object.assign(Object.assign({}, Validator_FormData_General), { type: (0, ts_common_1.tsValidateValue)([types_1.FormData_Multi]), groups: (0, ts_common_1.tsValidateArray)(Validator_SnippetGroup) });
const Validator_FormData_Single = Object.assign(Object.assign({}, Validator_FormData_General), { type: (0, ts_common_1.tsValidateValue)([types_1.FormData_Single]), advisorLabel: (0, ts_common_1.tsValidateString)(undefined, false), defaultText: ts_common_1.tsValidator_nonMandatoryString, clinicalFormId: ts_common_1.tsValidateOptionalId, rules: (0, ts_common_1.tsValidateArray)({ tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId) }), queryData: (0, ts_common_1.tsValidateNonMandatoryObject)(Validator_QueryData) });
const Validator_FormData = (instance, parentObject) => {
    const toValidate = (0, ts_common_1.deleteKeysObject)((0, ts_common_1.removeDBObjectKeys)(instance), ['_tagIds', '__formDataIds']);
    if (instance.type === types_1.FormData_Single)
        return (0, ts_common_1.tsValidateResult)(toValidate, Validator_FormData_Single);
    if (instance.type === types_1.FormData_Multi)
        return (0, ts_common_1.tsValidateResult)(toValidate, Validator_FormData_Multi);
    throw new ts_common_1.ImplementationMissingException(`Missing validator for form data of type ${instance.type}`);
};
const Validator_GeneratedProps = {
    _tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    __formDataIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
exports.DBDef_AdvisorContext = {
    modifiablePropsValidator: Validator_FormData,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.4', '1.0.3', '1.0.2', '1.0.1', '1.0.0'],
    dbKey: 'advisor-context',
    entityName: 'advisor-context',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'advisor-context'
    },
    backend: {
        name: 'advisor-context',
    },
    dependencies: {
        '_tagIds': {
            dbKey: 'tags',
            fieldType: 'string[]'
        },
        '__formDataIds': {
            dbKey: 'advisor-context',
            fieldType: 'string[]'
        }
    }
};
