"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_VariableRelation = exports.ModuleFE_VariableRelation_Class = exports.dispatch_onVariableRelationChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onVariableRelationChanged = new types_1.ThunderDispatcherV3('__onVariableRelationUpdated');
class ModuleFE_VariableRelation_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_VariableRelation, exports.dispatch_onVariableRelationChanged);
    }
}
exports.ModuleFE_VariableRelation_Class = ModuleFE_VariableRelation_Class;
exports.ModuleFE_VariableRelation = new ModuleFE_VariableRelation_Class();
