"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveConversionBaseEvaluationLogic = exports.getReturnValueType = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../consts");
const defaultElseValue = 0;
// Evaluation Logic Types that cannot be used in a conversion
const unacceptableConversionEvaluationLogics = [consts_1.EvaluationLogicTypes.self, consts_1.EvaluationLogicTypes.exists];
const getReturnValueType = (value) => {
    if (!value)
        throw new ts_common_1.BadImplementationException('Missing value in case!');
    return value[0] === value[1] ? 'number' : 'tuple';
};
exports.getReturnValueType = getReturnValueType;
const validateConversionAtomCases = (dbAtom, conversionType) => {
    if (unacceptableConversionEvaluationLogics.includes(conversionType))
        throw new ts_common_1.BadImplementationException(`The "${conversionType}" evaluation logic cannot be used with multiple cases!`);
    const conversionOutputType = (0, exports.getReturnValueType)(dbAtom.cases[0].value);
    for (const _case of dbAtom.cases) {
        // Validate that all case output types are the same
        if ((0, exports.getReturnValueType)(_case.value) !== conversionOutputType)
            throw new ts_common_1.BadImplementationException('All cases must have the same output type!');
        // Validate all operators are compatible
        if (!consts_1.AllowedOperators[conversionType].includes(_case.caseOperator))
            throw new ts_common_1.BadImplementationException('Atom has different cases with incompatible operators');
    }
    // Validate fallback output type - fallback value of [0,0] can fit both outputTypes
    if ((dbAtom.fallbackValue && (0, exports.getReturnValueType)(dbAtom.fallbackValue) !== conversionOutputType && !(0, ts_common_1.compare)(dbAtom.fallbackValue, consts_1.defaultFallback)) && !(0, ts_common_1.compare)(dbAtom.fallbackValue, consts_1.defaultFallback))
        throw new ts_common_1.BadImplementationException('All cases must have the same output type!');
};
const resolveConversionBaseEvaluationLogic = (dbAtom, conversionType) => {
    const evaluationLogic = {};
    validateConversionAtomCases(dbAtom, conversionType);
    evaluationLogic.output_type = (0, exports.getReturnValueType)(dbAtom.cases[0].value);
    if (!dbAtom.fallbackValue)
        evaluationLogic.else_value = defaultElseValue;
    else
        evaluationLogic.else_value = evaluationLogic.output_type === 'tuple' ? dbAtom.fallbackValue : dbAtom.fallbackValue[0];
    return evaluationLogic;
};
exports.resolveConversionBaseEvaluationLogic = resolveConversionBaseEvaluationLogic;
