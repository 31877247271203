"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateUserAcronym = exports.resolveRangeForPrint = exports.handleError = exports.showToast = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const ui_1 = require("./ui");
const showToast = (iconKey, content, toastType) => {
    new frontend_1.ToastBuilder().setDuration(6 * ts_common_1.Second)
        .setContent((0, ui_1.QToastContent)({ iconKey: iconKey, content: content, toastType: toastType }))
        .show();
};
exports.showToast = showToast;
const handleError = (error, message, targetName) => {
    if ((0, ts_common_1.isErrorOfType)(error, ts_common_1.ValidationException)) {
        message = message ? message : `Invalid input for fields: ${(0, ts_common_1.filterInstances)((0, ts_common_1._keys)(error.result)).join(', ')}`;
        (0, exports.showToast)('x', message, 'error');
    } /*else if (isErrorOfType(error, HasDependenciesException)) {
        showToast('x', QResolveDependencyToast({
            dependencyConflicts: error.body,
            deletedEntity: targetName!
        }), 'error');
    }*/
    else {
        (0, exports.showToast)('x', 'Something went wrong.. Try again later', 'error');
    }
    throw error;
};
exports.handleError = handleError;
function resolveRangeForPrint(input) {
    if (!input)
        return '';
    return input[0] === input[1] ? `${input[0]}` : `[${input}]`;
}
exports.resolveRangeForPrint = resolveRangeForPrint;
const generateUserAcronym = (user, email) => {
    if ((user === null || user === void 0 ? void 0 : user.firstName) && (user === null || user === void 0 ? void 0 : user.surname))
        return `${user.firstName.at(0).toUpperCase()}${user.surname.at(0).toUpperCase()}`;
    return email.substring(0, 2).toUpperCase();
};
exports.generateUserAcronym = generateUserAcronym;
