"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_PathwayStateEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const dispatchers_1 = require("../../../dispatchers");
const frontend_2 = require("@app/hcs/frontend");
const styles_1 = require("@app/styles");
const consts_1 = require("../../../consts");
const Header_Pathway_1 = require("../../../headers/Header_Pathway");
const Header_PathwayState_1 = require("../../../headers/Header_PathwayState");
const frontend_3 = require("@app/styles/frontend");
const _entity_1 = require("../../../../../_entity");
const utils_1 = require("../../../utils");
const frontend_4 = require("../../../../../../_enum/pathway-state-type/frontend");
const Editor_Preconditions_1 = require("./componentes/precondition-editor/Editor_Preconditions");
require("./Renderer_PathwayStateEditor.scss");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
class Renderer_PathwayStateEditor extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        this.nameUnique = () => {
            var _a;
            const currentState = this.state.editable.item;
            const currentName = (_a = currentState.name) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            if (!currentName)
                return true;
            return !this.state.statesUnderPathway.find(ps => ps.name.toLowerCase() === currentName && ps._id !== currentState._id);
        };
        this.onSave = async (createNew = false) => {
            if (!this.canSave())
                return;
            const pathwayState = this.state.editable;
            const _pathwayState = await pathwayState.save();
            //update all related resolution function label
            const associatedTransitions = _entity_1.ModuleFE_PathwayTransition.cache.filter(transition => transition.targetIds.includes(_pathwayState._id) || transition.sourceIds.includes(_pathwayState._id));
            await Promise.all(associatedTransitions.map(async (transition) => {
                return (0, utils_1.updateResolutionFunctionTitle)(transition._id, [transition.sourceIds[0], transition.targetIds[0]]);
            }));
            //Post Save logic
            if (!createNew)
                return this.props.functions.deselectPathwayState();
            const newPathwayState = new frontend_1.EditableDBItemV3((0, consts_1.Empty_PathwayState)(pathwayState.item.pathwayId), _entity_1.ModuleFE_PathwayState);
            this.props.functions.selectPathwayState(newPathwayState);
        };
        this.canSave = () => {
            const pathwayState = this.state.editable.item;
            return !!pathwayState.name && this.nameUnique();
        };
        this.onCancel = () => {
            const stateId = this.state.editable.get('_id');
            if (!stateId)
                return this.props.functions.deselectPathwayState();
            this.props.toggleMode();
            // Can assume it exists because of the condition above
            this.props.functions.selectPathwayState(stateId);
        };
        this.onStateNameChange = (value) => {
            const pathwayState = this.state.editable;
            pathwayState.set('name', value);
            this.forceUpdate();
            dispatchers_1.dispatch_PathwayStateRealtimeChanges.dispatchUI();
        };
        this.renderButtons = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'pathway__bottom-buttons' },
                React.createElement(frontend_1.TS_Button, { onClick: this.onCancel }, "Cancel"),
                React.createElement(frontend_1.TS_BusyButton, { onClick: () => this.onSave(true), disabled: !this.canSave() }, "Add Another State"),
                React.createElement(frontend_1.TS_BusyButton, { onClick: () => this.onSave(), className: 'preferred-action', disabled: !this.canSave() }, "Save"));
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const pathwayState = this.state.editable.item;
            const pathway = _entity_1.ModuleFE_Pathway.cache.unique(pathwayState.pathwayId);
            if (!pathway)
                throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway for id ${pathwayState.pathwayId}`);
            return React.createElement(React.Fragment, null,
                React.createElement(Header_Pathway_1.Header_Pathway, { pathway: new frontend_1.EditableDBItemV3(pathway, _entity_1.ModuleFE_Pathway), allowActions: false, functions: this.props.functions }),
                React.createElement(Header_PathwayState_1.Header_PathwayState, { pathwayState: this.state.editable, functions: this.props.functions }));
        };
        // ######################## Render - Form ########################
        this.renderForm = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'pathway__form' },
                this.renderStateName(),
                this.renderStateType(),
                this.renderPreconditions(),
                this.renderLogTemplates());
        };
        this.renderStateName = () => {
            const pathwayState = this.state.editable;
            const nameUniquenessError = !this.nameUnique();
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' },
                    "State Name",
                    React.createElement("sup", null, "*")),
                React.createElement(frontend_1.TS_Input, { type: 'text', className: (0, frontend_1._className)(nameUniquenessError && 'error'), value: pathwayState.item.name, onChange: this.onStateNameChange }),
                nameUniquenessError &&
                    React.createElement("div", { className: 'pathway__form__data' }, "A state with this name already exists in this pathway. Please create a unique name."));
        };
        this.renderStateType = () => {
            const pathwayState = this.state.editable;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' },
                    "State Type",
                    React.createElement("sup", null, "*")),
                React.createElement(frontend_4.Dropdown_PathwayStateType.selectable, { selected: pathwayState.item.type, onSelected: type => {
                        pathwayState.set('type', type);
                        this.forceUpdate();
                    } }));
        };
        this.renderPreconditions = () => {
            const orders = _entity_1.ModuleFE_Action.cache.filter(order => { var _a; return !((_a = this.state.editable.item.preconditions) === null || _a === void 0 ? void 0 : _a.find(precon => precon.orderId === order._id)); });
            const adapter = (0, frontend_1.SimpleListAdapter)(orders, order => React.createElement(React.Fragment, null, order.item.name));
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Preconditions"),
                React.createElement(frontend_1.TS_MultiSelect_V2, { editable: this.state.editable, prop: 'preconditions', itemsDirection: 'vertical', itemRenderer: (precondition, onDelete) => {
                        var _a;
                        const preconditionIndex = (_a = this.state.editable.item.preconditions) === null || _a === void 0 ? void 0 : _a.findIndex(precon => precon.orderId === precondition.orderId);
                        if (!(0, ts_common_1.exists)(preconditionIndex))
                            throw new ts_common_1.BadImplementationException(`Could not find order for id ${precondition}`);
                        const editablePreconditions = this.state.editable.editProp('preconditions', []);
                        return React.createElement(React.Fragment, null,
                            React.createElement(Editor_Preconditions_1.Editor_PreconditionTimeConstraint, { editable: editablePreconditions.editProp(preconditionIndex, {}).setSaveAction(async (item) => {
                                    var _a;
                                    (_a = this.state.editable.item.preconditions) === null || _a === void 0 ? void 0 : _a.splice(preconditionIndex, 1, item);
                                    this.forceUpdate();
                                    return item;
                                }) }),
                            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }));
                    }, selectionRenderer: (selector) => {
                        return React.createElement(frontend_1.TS_DropDown, { adapter: adapter, caret: frontend_3.QuaiManager_DropDownCaret, onSelected: order => {
                                selector.onSelected({ orderId: order._id });
                                this.forceUpdate();
                            } });
                    } }));
        };
        this.renderLogTemplates = () => {
            const pathwayState = this.state.editable;
            const logTemplates = frontend_2.ModuleFE_LogTemplate_OLD.cache.all();
            const adapter = (0, frontend_1.SimpleListAdapter)(logTemplates, log => React.createElement(React.Fragment, null, log.item.title));
            const selected = logTemplates.find(log => { var _a, _b; return log._id === ((_b = (_a = pathwayState.item.logTemplates) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.templateId); });
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Smart Docs"),
                React.createElement(frontend_1.TS_DropDown, { caret: frontend_3.QuaiManager_DropDownCaret, adapter: adapter, selected: selected, onSelected: logTemplate => {
                        pathwayState.set('logTemplates', [{ templateId: logTemplate._id }]);
                    } }));
        };
    }
    __onPathwayStateUpdated(...params) {
        const pathwayStateId = this.state.editable.get('_id');
        if (!pathwayStateId)
            return;
        (0, utils_1.basicSyncEventRefresh)(pathwayStateId, params, () => this.props.functions.selectPathwayState(pathwayStateId, true), () => this.props.functions.deselectPathwayState());
    }
    // ######################## Lifecycle ########################
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        const pathwayId = state.editable.item.pathwayId;
        state.statesUnderPathway = _entity_1.ModuleFE_PathwayState.cache.filter(ps => ps.pathwayId === pathwayId);
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'pathway-state-editor', style: { padding: '30px', gap: '10px' } },
            this.renderHeader(),
            this.renderForm(),
            this.renderButtons());
    }
}
exports.Renderer_PathwayStateEditor = Renderer_PathwayStateEditor;
