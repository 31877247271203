"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Section_AllPathwayOrders = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("../../../../../../../../_entity/pathway-state/frontend");
const frontend_3 = require("../../../../../../../../_entity/pathway-transition/frontend");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../../../../../_entity");
class Section_AllPathwayOrders extends frontend_1.ComponentSync {
    __onPathwayStateUpdated(...params) {
        switch (params[0]) {
            case 'delete':
            case 'delete-multi': {
                const deletedStates = (0, ts_common_1.asArray)(params[1]);
                if (deletedStates.some(deletedStateDoc => this.state.relevantOrders.some(order => {
                    const deletedState = this.state.relatedStates.find(state => state._id === deletedStateDoc._id);
                    return !!(deletedState === null || deletedState === void 0 ? void 0 : deletedState.preconditions.find(precondition => precondition.orderId === order._id));
                })))
                    return this.reDeriveState();
                break;
            }
            case 'update':
            case 'upsert-all':
            case 'create': {
                const updatedStates = (0, ts_common_1.asArray)(params[1]);
                if (updatedStates.some(updatedState => this.state.relevantOrders.some(order => { var _a; return !((_a = updatedState.preconditions) === null || _a === void 0 ? void 0 : _a.find(precondition => precondition.orderId === order._id)); })))
                    return this.reDeriveState();
                break;
            }
        }
    }
    __onPathwayTransitionUpdated(...params) {
        switch (params[0]) {
            case 'delete':
            case 'delete-multi': {
                const deletedTransitions = (0, ts_common_1.asArray)(params[1]);
                if (deletedTransitions.some(deletedTransitionDoc => this.state.relevantOrders.some(order => {
                    const deletedTransition = this.state.relatedTransitions.find(state => state._id === deletedTransitionDoc._id);
                    return (0, ts_common_1.flatArray)([deletedTransition === null || deletedTransition === void 0 ? void 0 : deletedTransition.consultIds, deletedTransition === null || deletedTransition === void 0 ? void 0 : deletedTransition.treatmentsIds, deletedTransition === null || deletedTransition === void 0 ? void 0 : deletedTransition.testsIds]).includes(order._id);
                })))
                    return this.reDeriveState();
                break;
            }
            case 'update':
            case 'upsert-all':
            case 'create': {
                const updatedTransitions = (0, ts_common_1.asArray)(params[1]);
                if (updatedTransitions.some(updatedTransition => {
                    const transitionOrderIds = [updatedTransition.consultIds, updatedTransition.treatmentsIds, updatedTransition.testsIds];
                    return this.state.relevantOrders.some(order => !(0, ts_common_1.flatArray)(transitionOrderIds).includes(order._id));
                }))
                    return this.reDeriveState();
                break;
            }
        }
    }
    deriveStateFromProps(nextProps, state) {
        state.relevantOrders = this.getOrders();
        state.relatedStates = frontend_2.ModuleFE_PathwayState.cache.filter(state => state.pathwayId === nextProps.pathwayId);
        state.relatedTransitions = frontend_3.ModuleFE_PathwayTransition.cache.filter(transition => transition.pathwayId === nextProps.pathwayId);
        return state;
    }
    getOrders() {
        const pathwayId = this.props.pathwayId;
        const states = frontend_2.ModuleFE_PathwayState.cache.filter(state => state.pathwayId === pathwayId);
        const transitions = frontend_3.ModuleFE_PathwayTransition.cache.filter(transition => transition.pathwayId === pathwayId);
        const ids = (0, ts_common_1.filterInstances)([
            ...(0, ts_common_1.flatArray)(states.flatMap(state => state.preconditions.map(precon => precon.orderId))),
            ...(0, ts_common_1.flatArray)(transitions.map(transition => [transition.consultIds, transition.treatmentsIds, transition.testsIds])),
        ]);
        const orders = ids.map(id => {
            const order = _entity_1.ModuleFE_Action.cache.find(order => order._id === id);
            if (!order)
                throw new ts_common_1.MUSTNeverHappenException(`Could not get order for id ${id}`);
            return order;
        });
        return (0, ts_common_1.sortArray)((0, ts_common_1.filterDuplicates)(orders, ts_common_1.dbObjectToId), order => order.name);
    }
    render() {
        return React.createElement(frontend_1.TS_CollapsableContainer, { customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null), headerRenderer: React.createElement(React.Fragment, null,
                this.state.relevantOrders.length,
                " Orders"), containerRenderer: React.createElement(React.Fragment, null, this.state.relevantOrders.map(order => React.createElement("div", { key: order._id }, order.name))) });
    }
}
exports.Section_AllPathwayOrders = Section_AllPathwayOrders;
