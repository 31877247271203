"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmptyResolutionFunction = void 0;
const frontend_1 = require("../../../_entity/pathway/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../../_entity/pathway-state/frontend");
const consts_1 = require("@app/hcs/_entity/expression/shared/consts");
const getEmptyResolutionFunction = (transition) => {
    const pathway = frontend_1.ModuleFE_Pathway.cache.unique(transition.pathwayId);
    if (!pathway)
        throw new ts_common_1.MUSTNeverHappenException(`pathway with id ${transition.pathwayId} not found`);
    const sourceState = frontend_2.ModuleFE_PathwayState.cache.unique(transition.sourceIds[0]);
    if (!sourceState)
        throw new ts_common_1.MUSTNeverHappenException(`state with id ${transition.sourceIds[0]} not found`);
    const targetState = frontend_2.ModuleFE_PathwayState.cache.unique(transition.targetIds[0]);
    if (!targetState)
        throw new ts_common_1.MUSTNeverHappenException(`state with id ${transition.targetIds[0]} not found`);
    return Object.freeze({
        terms: [{
                expression: 'A1',
                atoms: [{ leftHand: undefined, cases: [{}] }]
            }],
        expression: 'T1',
        isEvidenceBased: false,
        label: `${sourceState.name} => ${transition.label ? transition.label : targetState.name}`,
        outputType: consts_1.ExpressionOutput_Boolean,
        expressionType: consts_1.ExpressionType_ResolutionFunction.dbKey
    });
};
exports.getEmptyResolutionFunction = getEmptyResolutionFunction;
