"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveInSetConversion = exports.resolveInSet = void 0;
const utils_1 = require("../utils");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
const shared_1 = require("../../../../../_entity/value/shared");
const resolveInSet = (dbAtom, dependencies) => {
    const inSet = {};
    // Set default values derived from the type
    inSet.output_type = 'boolean';
    inSet.type = 'in-set-condition';
    // Convert all the db values in the array
    inSet.value_set = resolveValuesSet(dbAtom.cases[0], dependencies);
    return inSet;
};
exports.resolveInSet = resolveInSet;
const resolveInSetConversion = (dbAtom, baseConversion, dependencies) => {
    const inSetConversion = baseConversion;
    inSetConversion.type = 'in-set-conversion';
    inSetConversion.options = dbAtom.cases.map(_case => {
        return {
            value_set: resolveValuesSet(_case, dependencies),
            return_value: inSetConversion.output_type === 'tuple' ? _case.value : _case.value[0] // getConversionOutputType validates that all cases have values
        };
    });
    return inSetConversion;
};
exports.resolveInSetConversion = resolveInSetConversion;
const resolveValuesSet = (_case, dependencies) => {
    // Specific cases explanation: in subTerms boolean atoms use the isFalse and IsTrue logic operators and doesn't have a right
    // hand value. so we explicitly taking care of that. this is fine and generic enough considering sub terms can't use conversion
    // only single case
    var _a;
    // In subTerm usage of is true equals to the calculation of equals to yes
    if (_case.caseOperator === consts_1.LogicType_IsTrue)
        return [(0, utils_1.createHVValueOption)(shared_1.valueID_Yes, dependencies)];
    // In subTerm usage of is false equals to the calculation of equals to no
    if (_case.caseOperator === consts_1.LogicType_IsFalse)
        return [(0, utils_1.createHVValueOption)(shared_1.valueID_No, dependencies)];
    return (_a = _case.rightHand) === null || _a === void 0 ? void 0 : _a.map(id => (0, utils_1.createHVValueOption)(id, dependencies));
};
