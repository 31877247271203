"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Section_AllEntityVariables = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("../../../../../../_entity/pathway-transition/frontend");
const frontend_3 = require("../../../../../../_entity/pathway-state/frontend");
const utils_1 = require("../../../utils");
const styles_1 = require("@app/styles");
class Section_AllEntityVariables extends frontend_1.ComponentSync {
    __onPathwayTransitionUpdated(...params) {
        if (!this.props.pathwayId && !this.props.pathwayStateId && !this.props.pathwayTransitionId)
            return;
        (0, utils_1.checkIfPathwayElementChanged)(this.state.relevantTransitions, params, (item) => item.pathwayId === this.props.pathwayId || this.props.pathwayTransitionId === item._id || this.props.pathwayStateId === item._id, () => this.reDeriveState(), () => this.reDeriveState());
    }
    __onPathwayStateUpdated(...params) {
        if (!this.props.pathwayId && !this.props.pathwayStateId && !this.props.pathwayTransitionId)
            return;
        (0, utils_1.checkIfPathwayElementChanged)(this.state.relevantStates, params, (item) => item.pathwayId === this.props.pathwayId || this.props.pathwayTransitionId === item._id || this.props.pathwayStateId === item._id, () => this.reDeriveState(), () => this.reDeriveState());
    }
    deriveStateFromProps(nextProps, state) {
        state.relevantVariables = this.getConnectedHCSVariables();
        state.relevantStates = frontend_3.ModuleFE_PathwayState.cache.filter(state => state.pathwayId === nextProps.pathwayId);
        state.relevantTransitions = frontend_2.ModuleFE_PathwayTransition.cache.filter(transition => transition.pathwayId === nextProps.pathwayId);
        return state;
    }
    getConnectedHCSVariables() {
        //From Spec - "computed based on resolution function and precondition and transitions variables"
        let hcsVariables = {};
        let states = [];
        let transitions = [];
        if (this.props.pathwayId) {
            states = frontend_3.ModuleFE_PathwayState.cache.filter(ps => ps.pathwayId === this.props.pathwayId);
            transitions = frontend_2.ModuleFE_PathwayTransition.cache.filter(transition => transition.pathwayId === this.props.pathwayId);
        }
        else if (this.props.pathwayStateId) {
            transitions = frontend_2.ModuleFE_PathwayTransition.cache.filter(transition => transition.sourceIds[0] === this.props.pathwayStateId);
            const pathwayState = frontend_3.ModuleFE_PathwayState.cache.unique(this.props.pathwayStateId);
            if (!pathwayState)
                throw new ts_common_1.MUSTNeverHappenException(`pathway state with id ${this.props.pathwayStateId} not found`);
            states = [pathwayState];
        }
        else if (this.props.pathwayTransitionId) {
            const transition = frontend_2.ModuleFE_PathwayTransition.cache.unique(this.props.pathwayTransitionId);
            if (!transition)
                throw new ts_common_1.MUSTNeverHappenException(`pathway transition with id ${this.props.pathwayTransitionId}`);
            transitions = [transition];
        }
        hcsVariables = Object.assign(Object.assign(Object.assign({}, (0, utils_1.getStatePreconditionsVariables)(states)), (0, utils_1.getTransitionVariables)(transitions)), (0, utils_1.getAllResolutionFunctionsVariables)((0, ts_common_1.filterInstances)(transitions.map(transition => transition.resolutionFunctionId))));
        this.logInfo('vars', hcsVariables);
        this.logInfo('states', states);
        this.logInfo('transitions', transitions);
        return (0, ts_common_1.filterDuplicates)((0, ts_common_1.sortArray)(Object.values(hcsVariables).flat(), (_variable) => _variable._nameInLowercase), item => item._id);
    }
    render() {
        return React.createElement(frontend_1.TS_CollapsableContainer, { headerRenderer: React.createElement(React.Fragment, null,
                this.state.relevantVariables.length,
                " HCS Variables"), containerRenderer: React.createElement(React.Fragment, null, this.state.relevantVariables.map(variable => React.createElement("div", { key: variable._id }, variable.name))), customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null) });
    }
}
exports.Section_AllEntityVariables = Section_AllEntityVariables;
