"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATS_ExpressionBuilder = exports.WorkspaceConfig_ExpressionBuilder = exports.WorkspaceKey_ExpressionBuilder = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@nu-art/ts-workspace/frontend");
const Component_ExpressionBuilder_1 = require("../../expression-builder/Component_ExpressionBuilder");
const Panel_ExpressionSidePanel_1 = require("../../expression-builder/components/expression-side-panel/Panel_ExpressionSidePanel");
exports.WorkspaceKey_ExpressionBuilder = 'workspace-key--expression-builder';
exports.WorkspaceConfig_ExpressionBuilder = {
    name: 'expression-builder',
    key: 'horizontalSpace',
    visible: true,
    factor: 1,
    min: -1,
    data: {
        panels: [
            {
                name: 'LeftPanel', key: 'builderPanel', visible: true, factor: 2 / 3, min: 850,
                data: {
                    filter: ''
                }
            },
            {
                name: 'RightPanel', key: 'utilsPanel', visible: true, factor: 1 / 3, min: 600,
                data: {
                    filter: '',
                }
            },
        ]
    }
};
class ATS_ExpressionBuilder extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderers = {
            horizontalSpace: frontend_2.TS_HorizontalWorkspace,
            builderPanel: Component_ExpressionBuilder_1.Component_ExpressionBuilder,
            utilsPanel: Panel_ExpressionSidePanel_1.Panel_ExpressionSidePanel
        };
    }
    deriveStateFromProps(nextProps, state) {
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent' },
            React.createElement(frontend_2.TS_Workspace, { workspaceKey: exports.WorkspaceKey_ExpressionBuilder, renderers: this.renderers }),
            ";");
    }
}
_a = ATS_ExpressionBuilder;
ATS_ExpressionBuilder.screen = {
    name: 'Expressions Builder Playground',
    key: 'expressions-builder-playground',
    renderer: _a,
    group: 'Quai Dev Tools'
};
exports.ATS_ExpressionBuilder = ATS_ExpressionBuilder;
