"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_ActionData_Uneditable = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@app/styles/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_3 = require("@app/hcs/frontend");
class Renderer_ActionData_Uneditable extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        // ######################## Render ########################
        super(...arguments);
        this.renderMetaData = () => {
            var _a;
            const action = this.state.editable.item;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "MetaData"),
                React.createElement(frontend_3.QJSONViewer, { item: (_a = action.metadata) !== null && _a !== void 0 ? _a : {} }));
        };
        this.renderVariableIds = () => {
            var _a, _b;
            const action = this.state.editable.item;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "VariableIds"),
                !((_a = action.variableIds) === null || _a === void 0 ? void 0 : _a.length) && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!((_b = action.variableIds) === null || _b === void 0 ? void 0 : _b.length) && React.createElement(frontend_1.LL_V_L, null, action.variableIds.map(id => {
                    const variable = frontend_3.ModuleFE_Variable.cache.unique(id);
                    if (!variable)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for id ${id}`);
                    return React.createElement(frontend_2.DataGrid.Data, { key: id },
                        variable.name,
                        " ",
                        React.createElement("i", null,
                            "(",
                            id,
                            ")"));
                })));
        };
        this.renderResolutionType = () => {
            var _a;
            const action = this.state.editable.item;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Resolution Type"),
                React.createElement(frontend_2.DataGrid.Data, null, (_a = action.resolution) !== null && _a !== void 0 ? _a : '-'));
        };
        this.renderResolutionFindings = () => {
            var _a, _b;
            const action = this.state.editable.item;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Resolution Findings"),
                !((_a = action.resolutionFindings) === null || _a === void 0 ? void 0 : _a.length) && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!((_b = action.resolutionFindings) === null || _b === void 0 ? void 0 : _b.length) && React.createElement(frontend_1.LL_V_L, null, action.resolutionFindings.map(finding => {
                    const variable = frontend_3.ModuleFE_Variable.cache.unique(finding.variableId);
                    if (!variable)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for id ${finding.variableId}`);
                    const value = frontend_3.ModuleFE_Value.cache.unique(finding.valueId);
                    if (!value)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for id ${finding.valueId}`);
                    return React.createElement(frontend_1.LL_V_L, { key: `${finding.variableId}-${finding.valueId}` },
                        React.createElement(frontend_2.DataGrid.Data, null,
                            "Variable: ",
                            variable.name),
                        React.createElement(frontend_2.DataGrid.Data, null,
                            "Value: ",
                            (0, frontend_3.resolveValueDisplay)(value)));
                })));
        };
        this.renderResolutionDialog = () => {
            const action = this.state.editable.item;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Resolution Dialog"),
                !action.resolutionDialog && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!action.resolutionDialog &&
                    React.createElement(frontend_2.DataGrid.Data, null,
                        action.resolutionDialog.clinicalFormKey,
                        " (",
                        action.resolutionDialog.isOrderSpecific ? '' : 'Not ',
                        "Order Specific)"));
        };
        this.renderProperties = () => {
            const action = this.state.editable.item;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Properties"),
                !action.properties && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!action.properties && React.createElement(frontend_1.LL_V_L, null, (0, ts_common_1._keys)(action.properties).map((key) => {
                    const tag = frontend_3.ModuleFE_Tag.cache.unique(key);
                    const variable = frontend_3.ModuleFE_Variable.cache.unique(key);
                    if (!(0, ts_common_1.logicalXOR)(!!tag, !!variable)) {
                        if (tag)
                            throw new ts_common_1.MUSTNeverHappenException(`id ${key} points to both tag [${tag.label}] and variable [${variable === null || variable === void 0 ? void 0 : variable.name}]`);
                        throw new ts_common_1.MUSTNeverHappenException(`id ${key} doesn't point to a tag or variable`);
                    }
                    const valueId = action.properties[key];
                    const value = frontend_3.ModuleFE_Value.cache.unique(valueId);
                    if (!value)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for id ${valueId}`);
                    if (tag)
                        return React.createElement(frontend_2.DataGrid.Data, { key: key },
                            tag.label,
                            " (tag) : ",
                            (0, frontend_3.resolveValueDisplay)(value),
                            " (value)");
                    return React.createElement(frontend_2.DataGrid.Data, { key: key }, variable === null || variable === void 0 ? void 0 :
                        variable.name,
                        " (variable) : ",
                        (0, frontend_3.resolveValueDisplay)(value),
                        " (value)");
                })));
        };
    }
    render() {
        return React.createElement(frontend_2.DataGrid.Grid, { className: 'editor__order__uneditables' },
            this.renderMetaData(),
            this.renderVariableIds(),
            this.renderResolutionType(),
            this.renderResolutionFindings(),
            this.renderResolutionDialog(),
            this.renderProperties());
    }
}
exports.Renderer_ActionData_Uneditable = Renderer_ActionData_Uneditable;
