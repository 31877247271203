"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertDbDiseaseProfileToUiDiseaseProfile = exports.ModuleFE_DiseaseProfile = exports.ModuleFE_DiseaseProfile_Class = exports.dispatch_onDiseaseProfileChanged = exports.dispatch_onDiseaseProfilesShowErrorAlertsUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
exports.dispatch_onDiseaseProfilesShowErrorAlertsUpdated = new frontend_1.ThunderDispatcher('__onDiseaseProfilesShowErrorAlertsUpdated');
exports.dispatch_onDiseaseProfileChanged = new types_1.ThunderDispatcherV3('__onDiseaseProfileUpdated');
class ModuleFE_DiseaseProfile_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_DiseaseProfile, exports.dispatch_onDiseaseProfileChanged);
        this.__searchGroups = (items) => ([{ group: this.dbDef.entityName, results: items.map(i => ({ _id: i._id, uiLabel: i.label })) }]);
        this.__searchFilterMapper = (item) => [item.label, item.initials];
        this.showDiseaseProfileErrorAlerts = new frontend_1.StorageKey('show-error-alerts');
        this.onDiseaseProfileExport = async (response) => {
            frontend_1.ModuleFE_Thunderstorm.downloadFile({
                fileName: `DP_${response.dpExport.variableName}.json`,
                content: (0, ts_common_1.__stringify)(response.dpExport, true),
                mimeType: 'application/json'
            });
        };
        this._v1 = {
            export: (0, frontend_1.apiWithQuery)(shared_1.ApiDef_DiseaseProfile._v1.export, this.onDiseaseProfileExport),
            import: (0, frontend_1.apiWithBody)(shared_1.ApiDef_DiseaseProfile._v1.import),
            deleteChiefComplaint: (0, frontend_1.apiWithBody)(shared_1.ApiDef_DiseaseProfile._v1.deleteChiefComplaint),
            deleteWholeDiseaseProfile: (0, frontend_1.apiWithBody)(shared_1.ApiDef_DiseaseProfile._v1.deleteWholeDiseaseProfile),
        };
    }
}
exports.ModuleFE_DiseaseProfile_Class = ModuleFE_DiseaseProfile_Class;
exports.ModuleFE_DiseaseProfile = new ModuleFE_DiseaseProfile_Class();
const convertDbDiseaseProfileToUiDiseaseProfile = (dbDiseaseProfile, variableCollection) => {
    const variable = variableCollection.find((item) => item._id === dbDiseaseProfile.variableId);
    return Object.assign(Object.assign({}, dbDiseaseProfile), { tags: frontend_2.VariableWrapper.dbTagsToUI(dbDiseaseProfile.tagIds), variable });
};
exports.convertDbDiseaseProfileToUiDiseaseProfile = convertDbDiseaseProfileToUiDiseaseProfile;
