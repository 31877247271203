"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseVariableWrapper = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const db_def_1 = require("./db-def");
class BaseVariableWrapper {
    constructor(dbVariable) {
        this.validate = () => {
            const results = (0, ts_common_1.tsValidateResult)(this.dbVariable, Object.assign(Object.assign({}, ts_common_1.DB_Object_validator), db_def_1.Validator_ModifiableProps));
            if (results) {
                console.error('Save variable validation failed for variable:', this.dbVariable, '\ninvalid:', results);
            }
        };
        this.dbVariable = dbVariable;
    }
}
BaseVariableWrapper.getSourceSubTags = (sourceTagId, tagGroups) => {
    var _a;
    if (!sourceTagId || !tagGroups)
        return [];
    return ((_a = tagGroups === null || tagGroups === void 0 ? void 0 : tagGroups.find(pair => { var _a; return pair.key === sourceTagId && ((_a = pair.value) === null || _a === void 0 ? void 0 : _a.length); })) === null || _a === void 0 ? void 0 : _a.value) || [];
};
exports.BaseVariableWrapper = BaseVariableWrapper;
