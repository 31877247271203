"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_DiseaseProfile = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const _enum_1 = require("../../_enum");
const hcs_1 = require("@app/hcs");
const Validator_ModifiableProps = {
    //Linking fields
    variableId: ts_common_1.tsValidateUniqueId,
    referenceIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    chiefComplaintIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    variantsGroupId: ts_common_1.tsValidateOptionalId,
    //Profile Data
    label: (0, ts_common_1.tsValidateString)(),
    initials: (0, ts_common_1.tsValidateString)(),
    colorGroup: ts_common_1.tsValidateUniqueId,
    profileDescription: ts_common_1.tsValidator_nonMandatoryString,
    profileNotes: ts_common_1.tsValidator_nonMandatoryString,
    scores: ts_common_1.tsValidator_nonMandatoryString,
    icd: ts_common_1.tsValidator_arrayOfUniqueIds,
    criticality: (0, ts_common_1.tsValidateValue)([..._enum_1.criticalityValues], false),
    completed: (0, ts_common_1.tsValidateBoolean)(false),
    tagIds: (0, ts_common_1.tsValidateArray)({ key: (0, ts_common_1.tsValidateString)(-1), value: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(-1)) }),
    scoreReferences: (0, ts_common_1.tsValidate_OptionalArray)({
        variableId: ts_common_1.tsValidateOptionalId,
        mdCalcUrl: (0, ts_common_1.tsValidateString)(-1, false)
    }),
    //Epidemiology Data
    epidemiology: (0, ts_common_1.tsValidateArray)(_enum_1.Validator_Epidemiology, false),
    epidemiologyNotes: (0, ts_common_1.tsValidateString)(-1, false),
    //Prognosis Data
    prognosis: (0, ts_common_1.tsValidateArray)(_enum_1.Validator_Prognosis, false),
    prognosisNotes: (0, ts_common_1.tsValidateString)(-1, false),
};
const Validator_GeneratedProps = {
    _valueIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
exports.DBDef_DiseaseProfile = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.6', '1.0.5', '1.0.4', '1.0.3'],
    dbKey: 'disease-profiles',
    entityName: 'DiseaseProfile',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'disease-profile'
    },
    backend: {
        name: 'disease-profiles'
    },
    dependencies: {
        '_variableIds': {
            dbKey: 'variables',
            fieldType: 'string[]'
        },
        '_valueIds': {
            dbKey: 'values',
            fieldType: 'string[]'
        },
        'referenceIds': {
            dbKey: 'references',
            fieldType: 'string[]'
        },
        'icd': {
            dbKey: 'references',
            fieldType: 'string[]'
        },
        'colorGroup': {
            dbKey: 'color-groups',
            fieldType: 'string'
        },
        '_tagIds': {
            dbKey: 'tags',
            fieldType: 'string[]'
        },
    },
    metadata: {
        variableId: {
            optional: false,
            description: `ID of correlated Disease's Variable`,
            valueType: 'string',
        },
        referenceIds: {
            optional: false,
            description: '',
            valueType: 'string[]',
            metadata: {
                optional: false,
                description: 'IDs of Related Reference', valueType: 'string'
            }
        },
        chiefComplaintIds: {
            optional: false,
            description: `ID of correlated Disease's Variable`,
            valueType: 'string[]',
            metadata: {
                optional: false,
                description: 'IDs of Related Chief Complaints', valueType: 'string'
            }
        },
        variantsGroupId: {
            optional: true,
            description: '',
            valueType: 'string'
        },
        label: {
            optional: false,
            description: '',
            valueType: 'string'
        },
        initials: {
            optional: false,
            description: '',
            valueType: 'string'
        },
        colorGroup: {
            optional: false,
            description: 'An id of the color group representing the system for this disease profile',
            valueType: 'string',
        },
        profileDescription: { optional: true, description: '', valueType: 'string' },
        profileNotes: { optional: true, description: '', valueType: 'string' },
        scores: { optional: true, description: '', valueType: 'string' },
        icd: {
            optional: false,
            description: '',
            valueType: 'string[]',
            metadata: {
                optional: false,
                description: 'IDs of Related ICD Reference', valueType: 'string'
            }
        },
        criticality: {
            optional: true,
            description: '',
            valueType: 'string'
        },
        completed: {
            optional: true,
            description: 'Whether the disease profile is completed or not.',
            valueType: 'boolean'
        },
        tagIds: {
            optional: false,
            description: '',
            valueType: 'object[]',
            metadata: {
                key: {
                    optional: false,
                    description: '',
                    valueType: 'string'
                },
                value: {
                    optional: false,
                    description: '',
                    valueType: 'string[]',
                    metadata: {
                        optional: false,
                        description: '',
                        valueType: 'string'
                    }
                }
            }
        },
        scoreReferences: {
            optional: true,
            description: '',
            valueType: 'object[]',
            metadata: {
                variableId: { optional: true, description: '', valueType: 'string' },
                mdCalcUrl: { optional: true, description: '', valueType: 'string' },
            }
        },
        epidemiology: {
            optional: true,
            description: 'Array of epidemiology data',
            valueType: 'object[]',
            metadata: _enum_1.Metadata_Epidemiology
        },
        epidemiologyNotes: {
            optional: true,
            description: 'Notes on the epidemiology',
            valueType: 'string',
        },
        prognosis: {
            optional: true,
            description: 'Array of prognosis data',
            valueType: 'object[]',
            metadata: _enum_1.Metadata_Prognosis,
        },
        prognosisNotes: {
            optional: true,
            description: 'Notes on the prognosis',
            valueType: 'string',
        },
        _variableIds: {
            optional: false,
            description: 'Generated prop, array of all variable dependencies on a disease profile',
            valueType: 'string[]',
            metadata: {
                valueType: 'string',
                optional: false,
                description: 'variable Id'
            }
        },
        _valueIds: {
            optional: false,
            description: 'Generated prop, array of all values dependencies on a disease profile',
            valueType: 'string[]',
            metadata: {
                valueType: 'string',
                optional: false,
                description: 'value Id'
            }
        },
        _tagIds: {
            optional: false,
            description: 'Generated prop, array of all values dependencies on a disease profile',
            valueType: 'string[]',
            metadata: {
                valueType: 'string',
                optional: false,
                description: 'value Id'
            }
        }
    }
};
