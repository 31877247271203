"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header_ResolutionFunction = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Header_ResolutionFunctionEditor.scss");
const styles_1 = require("@app/styles");
const frontend_2 = require("@app/styles/frontend");
const frontend_3 = require("../../../../_entity/pathway-transition/frontend");
class Header_ResolutionFunction extends React.Component {
    constructor(props) {
        super(props);
        this.renderLoader = () => {
            if (!this.state.uperting)
                return '';
            return React.createElement(frontend_1.TS_ButtonLoader, null);
        };
        this.renderMenuIcon = () => {
            if (!this.props.actions)
                return;
            const expandCollapseClassName = (0, frontend_1._className)('quai-manager-ui__header__actions-icon', 'expand-button', this.props.expandStatus && 'expanded');
            return React.createElement(frontend_1.LL_H_C, { style: { gap: '10px' } },
                React.createElement(styles_1.ICONS.advisorCollapseAll.component, { className: expandCollapseClassName, onClick: this.props.expandCollapseAll }),
                React.createElement(frontend_1.TS_BusyButton, { onClick: async () => {
                        await Promise.all(this.props.editables.map(async (editable) => {
                            if (editable.hasChanges())
                                await editable.save();
                        }));
                        await this.props.deselectResolutionFunction();
                    }, className: 'preferred-action' }, "Save"),
                React.createElement(styles_1.ICONS.more.component, Object.assign({ className: 'quai-manager-ui__header__actions-icon' }, frontend_1.openContent.popUp.bottom('quai-manager-ui__header__actions', this.renderMenu, 10))));
        };
        this.resolveTransitionNames = () => {
            return this.props.availableTransitionsIds.map(transitionId => {
                const transition = frontend_3.ModuleFE_PathwayTransition.cache.unique(transitionId);
                if (!transition)
                    throw new ts_common_1.MUSTNeverHappenException(`transition with ${transitionId} not found`);
                return transition.label;
            }).join(', ');
        };
        this.renderMenu = (refreshPopup) => {
            return React.createElement(React.Fragment, null, this.props.actions.map((action, index) => {
                const className = (0, frontend_1._className)('quai-manager-ui__header__actions__action');
                return React.createElement("div", { key: action.label, className: className, onClick: () => {
                        action.action();
                    } }, action.label);
            }));
        };
        this.renderHeaderMain = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'quai-manager-ui__header__main' },
                React.createElement(frontend_1.LL_H_C, { style: { gap: '5px' }, className: 'quai-manager-ui__header__title' },
                    React.createElement("span", { onClick: async () => {
                            this.setState({ uperting: true }, async () => {
                                try {
                                    await this.props.deselectResolutionFunction();
                                }
                                catch (err) {
                                    ts_common_1.StaticLogger.logError(err);
                                    this.setState({ uperting: false });
                                }
                            });
                        }, className: 'clickable' }, this.props.state.name),
                    "Resolution Function",
                    this.props.availableTransitionsIds.length ? React.createElement(frontend_2.IconWithTooltip, { content: `The following transitions missing resolution function:\n${this.resolveTransitionNames()}`, iconKey: 'attention' }) : null,
                    this.renderLoader()),
                React.createElement("div", { className: 'quai-manager-ui__header__subtitle' }, "State"));
        };
        this.state = { uperting: false };
    }
    render() {
        return React.createElement(frontend_1.LL_H_C, { id: 'resolution-function-header', className: 'quai-manager-ui__header small-title' },
            this.renderHeaderMain(),
            this.renderMenuIcon());
    }
}
exports.Header_ResolutionFunction = Header_ResolutionFunction;
