"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Organization = exports.ModuleFE_Organization_Class = exports.dispatch_onOrganizationChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onOrganizationChanged = new types_1.ThunderDispatcherV3('__onOrganizationUpdated');
class ModuleFE_Organization_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Organization, exports.dispatch_onOrganizationChanged);
        this.__searchFilterMapper = (item) => [item.name];
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => ({ _id: i._id, uiLabel: i.name }))
            }]);
    }
}
exports.ModuleFE_Organization_Class = ModuleFE_Organization_Class;
exports.ModuleFE_Organization = new ModuleFE_Organization_Class();
