"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathwayDropdowns = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const _entity_1 = require("../../_entity");
const ts_common_1 = require("@nu-art/ts-common");
const ui_components_1 = require("../../../_entity/action/frontend/ui-components");
const Props_Pathway = {
    module: _entity_1.ModuleFE_Pathway,
    modules: [_entity_1.ModuleFE_Pathway],
    mapper: item => [item.label],
    placeholder: 'Choose a pathway',
    renderer: item => React.createElement("div", { className: 'node-data' }, item.label),
};
const Props_PathwayState = {
    module: _entity_1.ModuleFE_PathwayState,
    modules: [_entity_1.ModuleFE_PathwayState],
    mapper: item => [item.name],
    placeholder: 'Choose a state',
    renderer: item => React.createElement("div", { className: 'node-data' }, item.name),
};
const Props_PathwayTransition = {
    module: _entity_1.ModuleFE_PathwayTransition,
    modules: [_entity_1.ModuleFE_PathwayTransition],
    placeholder: 'Choose a transition',
    mapper: item => {
        const origin = _entity_1.ModuleFE_PathwayState.cache.unique(item.sourceIds[0]);
        const target = _entity_1.ModuleFE_PathwayState.cache.unique(item.targetIds[0]);
        if (!origin)
            throw new ts_common_1.MUSTNeverHappenException(`Could not find an origin state for id ${item.sourceIds[0]}`);
        if (!target)
            throw new ts_common_1.MUSTNeverHappenException(`Could not find an target state for id ${item.targetIds[0]}`);
        return [origin.name, target.name];
    },
    renderer: item => {
        const origin = _entity_1.ModuleFE_PathwayState.cache.unique(item.sourceIds[0]);
        const target = _entity_1.ModuleFE_PathwayState.cache.unique(item.targetIds[0]);
        if (!origin)
            throw new ts_common_1.MUSTNeverHappenException(`Could not find an origin state for id ${item.sourceIds[0]}`);
        if (!target)
            throw new ts_common_1.MUSTNeverHappenException(`Could not find an target state for id ${item.targetIds[0]}`);
        return React.createElement("div", { className: 'node-data' },
            origin.name,
            " ",
            '=>',
            " ",
            target.name);
    }
};
exports.PathwayDropdowns = {
    Action: ui_components_1.DropDown_Action.selectable,
    Pathway: frontend_1.GenericDropDownV3.prepareSelectable(Props_Pathway),
    PathwayState: frontend_1.GenericDropDownV3.prepareSelectable(Props_PathwayState),
    PathwayTransition: frontend_1.GenericDropDownV3.prepareSelectable(Props_PathwayTransition),
};
