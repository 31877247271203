"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Expression = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validator_1 = require("./validator");
const shared_1 = require("../../shared");
const Validator_GeneratedProps = Object.assign(Object.assign({}, ts_common_1.DB_Object_validator), { _originId: ts_common_1.tsValidateOptionalId, _isDraft: (0, ts_common_1.tsValidateBoolean)(false), _refs: (0, ts_common_1.tsValidateNonMandatoryObject)({
        _variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, true, 0),
        _valueIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, true, 0),
        _exprIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, true, 0),
    }), _schema_expression: ts_common_1.tsValidateOptional });
exports.DBDef_Expression = {
    modifiablePropsValidator: (0, validator_1.getExpressionValidator)(),
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.8', '1.0.7', '1.0.6', '1.0.5', '1.0.4', '1.0.3', '1.0.2', '1.0.1', '1.0.0'],
    dbKey: 'expressions',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'expressions'
    },
    backend: {
        name: 'expressions'
    },
    entityName: 'Expression',
    dependencies: {
        '_refs._variableIds': {
            dbKey: 'variables',
            fieldType: 'string[]'
        },
        '_refs._valueIds': {
            dbKey: 'values',
            fieldType: 'string[]'
        },
        '_refs._exprIds': {
            dbKey: 'expressions',
            fieldType: 'string[]'
        }
    }
};
