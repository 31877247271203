import * as React from 'react';
import {
	_className,
	ComponentSync,
	LL_H_C,
	ModuleFE_Notifications,
	ModuleFE_RoutingV2,
	ModuleFE_BaseApi,
	OnLocationChanged, AwaitModules, ModuleFE_BaseDB
} from '@nu-art/thunderstorm/frontend';
import {ICONS} from '@app/styles';
import {
	ModuleFE_AppModule,
	RouteDomainsMap,
	ToggleSideNavBarStateListener
} from '@modules/app-module/ModuleFE_AppModule';
import {DBProto_Discussion, DispatcherType_Discussion, DispatcherType_User, ModuleFE_Discussion, ModuleFE_User} from '@app/hcs/frontend';
import {
	ModuleFE_PermissionDomain,
	ModuleFE_PermissionGroup,
	ModuleFE_PermissionUser
} from '@nu-art/permissions/frontend';
import {DBProto_User} from '@app/hcs';
import {
	Component_DiscussionButton
} from '@km/components/Component_Header/Component_DiscussionsButton/Component_DiscussionButton';
import {Component_AccountThumbnail, PopUp_AccountMenu, PopUp_AccountMenu_Action} from '@nu-art/user-account/frontend';
import {
	Component_UserDetailsEditor
} from '@km/components/Component_Header/Component_UserDetailsEditor/Component_UserDetailsEditor';
import {UniqueId} from '@nu-art/ts-common';
import {ApiCallerEventType, DispatcherInterface} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {AccessLevel, ModuleFE_PermissionsAssert} from '@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert';
import {PermissionKeyFE_DeveloperViewer} from '@nu-art/permissions/frontend/core/permission-keys';


type Props = {}
type State = {
	routeKey?: string
}

export class Component_Header
	extends ComponentSync<Props, State>
	implements ToggleSideNavBarStateListener, OnLocationChanged, DispatcherInterface<DispatcherType_Discussion>, DispatcherInterface<DispatcherType_User> {

	// ######################### Life Cycle #########################

	__onDiscussionsUpdated(...params: ApiCallerEventType<DBProto_Discussion>) {
		this.reDeriveState();

	}

	__onLocationChanged(path: string) {
		this.reDeriveState();
	}

	__onToggleSideNavBarState(): void {
		this.forceUpdate();
	}

	__onUsersUpdated = (...params: ApiCallerEventType<DBProto_User>) => {
		this.forceUpdate();
	};

	protected deriveStateFromProps(nextProps: Props): State {
		return {
			routeKey: ModuleFE_RoutingV2.getCurrentRouteKey()?.key,
		};
	}

	// ######################### Logic #########################

	private getHeaderPageTitle = () => {
		const routeDomainsDeclaration = RouteDomainsMap[this.state.routeKey!];
		if (!routeDomainsDeclaration)
			this.logError(`Could not find route title for route key ${this.state.routeKey}`);
		return routeDomainsDeclaration?.routeTitle || 'Title not implemented for this route';
	};

	private getPermissionLevelString = () => {
		const user = ModuleFE_User.getUser();
		if (!user) {
			this.logError(`Can't show permission level in header- no user`);
			return '';
		}
		const permissionUser = ModuleFE_PermissionUser.cache.unique(user._id);
		if (!permissionUser) {
			this.logError(`Can't show permission level in header- no permissionUser`);
			return '';
		}

		const domainNamespace = RouteDomainsMap[this.state.routeKey!]?.domainNamespace;
		const relevantDomain = ModuleFE_PermissionDomain.cache.find(domain => domain.namespace === domainNamespace);
		if (!relevantDomain) {
			this.logError(`Can't show permission level in header- no relevantDomain`);
			return ``;
		}

		const userPermissionGroup = ModuleFE_PermissionGroup.cache.find(group => permissionUser.groups.map(_grp => _grp.groupId)
			.includes(group._id) && group.accessLevelIds.length === 1 && !!(group._levelsMap![relevantDomain._id]));
		if (!userPermissionGroup) {
			this.logError(`Can't show permission level in header- no userPermissionGroup`);
			return '';
		}

		return <span
			style={{fontSize: 12,}}> {userPermissionGroup.label.substring(userPermissionGroup.label.indexOf('/') + 1)}</span>;
	};

	private discussionsButton() {
		return <AwaitModules modules={[ModuleFE_Discussion] as ModuleFE_BaseDB<any>[]}>
			<Component_DiscussionButton/>
		</AwaitModules>;
	}

	private getEnvString() {
		switch (ModuleFE_AppModule.getEnv()?.toLowerCase()) {
			case 'local':
				return 'local';
			case 'dev':
				return 'dev';
			case 'staging':
				return 'staging';
			default:
				return '';
		}
	}

	private openAccountMenu = (e: React.MouseEvent) => {
		const user = ModuleFE_User.getUser()!;
		const menuActions: PopUp_AccountMenu_Action[] = [
			PopUp_AccountMenu.Action_EditPassword,
			{
				label: 'Edit My Details',
				type: 'page',
				pageKey: 'edit-my-details',
				content: (account, trigger) => <Component_UserDetailsEditor account={account}
																																		onSubmitCompleted={trigger}/>
			},
		];
		if (ModuleFE_PermissionsAssert.getAccessLevel(PermissionKeyFE_DeveloperViewer) === AccessLevel.HasAccess)
			menuActions.push(PopUp_AccountMenu.Action_AppToolsButton('/app-tools'));

		PopUp_AccountMenu.show(
			e,
			{
				accountId: user._id,
				accountDisplayModifier: (account) => {
					const user = ModuleFE_User.cache.unique(account._id);
					if (!user)
						return;

					let str = '';
					str += user.title ?? '';
					str += ` ${user.firstName ?? ''}`;
					str += ` ${user.surname ?? ''}`;
					return str.trim() || undefined;
				},
				acronymComposer: this.userAcronymComposer,
				menuActions: menuActions
			},
			{offset: {y: 20, x: 15}}
		);
	};

	private userAcronymComposer = (accountId: UniqueId): string | undefined => {
		const user = ModuleFE_User.cache.unique(accountId);
		if (!user || (!user.firstName || !user.surname))
			return;

		return user.firstName.charAt(0) + user.surname.charAt(0);
	};

	// ######################### Render #########################

	render() {
		const user = ModuleFE_User.getUser();
		return (
			<div className={_className('knowledge-manager__header ll_h_c match_width', this.getEnvString())}>
				<LL_H_C className={'header__left-container flex__grow'}>
					<ICONS.sidebarMaximize.component
						className={_className('header__side-nav-bar__open-icon', ModuleFE_AppModule.getNavBarStatus() ? 'open' : undefined)}
						onClick={(e) => {
							ModuleFE_AppModule.toggleSideNavBar();
						}}/>
					<span
						className={'header__left-container__title'}>KNOWLEDGE MANAGER - <b>{this.getHeaderPageTitle()}</b>{this.getPermissionLevelString()}</span>
				</LL_H_C>
				<LL_H_C className={'header__right-container flex__grow'}>
					<ICONS.notificationsBell.component className={'notifications-button'}
																						 onClick={() => ModuleFE_Notifications.showAllNotifications()}/>
					{this.discussionsButton()}
					{user && <Component_AccountThumbnail
						accountId={user._id}
						onClick={this.openAccountMenu}
						acronymComposer={this.userAcronymComposer}
						modulesToAwait={[ModuleFE_User as unknown as ModuleFE_BaseApi<any>]}
					/>}
					<ICONS.quai.component className={'header__logo'}/>
				</LL_H_C>
			</div>
		);
	}
}