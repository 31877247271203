"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Printable_DiseaseProfile = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
require("./Printable_DiseaseProfile.scss");
const shared_1 = require("../../../_enum/severity/shared");
const shared_2 = require("../../../_enum/likelihood/shared");
const _entity_1 = require("../../_entity");
const shared_3 = require("../../../_enum/criticality/shared");
class Printable_DiseaseProfile extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        // ######################## Logic ########################
        this.getAttributesForVarId = (varId, dv) => {
            const attributes = [];
            (0, ts_common_1._keys)(dv.attributes).forEach(attributeId => {
                if ((0, ts_common_1._keys)(dv.attributes[attributeId]).includes(varId))
                    attributes.push({
                        attributeId: attributeId,
                        varId,
                        valueId: dv.attributes[attributeId][varId],
                    });
            });
            return attributes;
        };
        this.renderTitle = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'printable__disease-profile__title' },
                React.createElement("h1", null, this.state.diseaseProfile.label),
                React.createElement("p", null,
                    "PDF produced on ",
                    (0, ts_common_1.formatTimestamp)('MMM. D, YYYY', Date.now())));
        };
        this.renderFooter = () => {
            return React.createElement("div", { className: 'printable__disease-profile__footer' },
                React.createElement("span", { className: 'printable__disease-profile__footer__disease-name' }, this.state.diseaseProfile.label),
                React.createElement("span", { className: 'printable__disease-profile__footer__copyright' }, "Quai.MD \u00A9 Copyright 2023"));
        };
        this.numberArrayRenderer = (arr, multiplier = 1) => {
            if (!arr)
                return undefined;
            if (arr[0] === arr[1])
                return `${arr[0] * multiplier}`;
            return `${arr[0] * multiplier} - ${arr[1] * multiplier}`;
        };
        // ######################## Render - General Information ########################
        this.renderGeneralInformation = () => {
            return React.createElement(frontend_1.Grid, { className: 'printable__disease-profile__general-information' },
                React.createElement("h2", null, "General Information"),
                this.renderOverview(),
                this.renderEpidemiology(),
                this.renderPrognosis(),
                this.renderScores(),
                this.renderReferences());
        };
        this.renderOverview = () => {
            var _a, _b, _c, _d, _e;
            const dp = this.state.diseaseProfile;
            const variantGroup = frontend_2.ModuleFE_Tag.cache.unique(dp.variantsGroupId);
            return React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Overview"),
                React.createElement("h4", null, "Label"),
                React.createElement("p", null, (_a = dp.label) !== null && _a !== void 0 ? _a : '-'),
                React.createElement("h4", null, "Profile Abbreviation"),
                React.createElement("p", null, (_b = dp.initials) !== null && _b !== void 0 ? _b : '-'),
                React.createElement("h4", null, "Variant Group"),
                React.createElement("p", null, (_c = variantGroup === null || variantGroup === void 0 ? void 0 : variantGroup.label) !== null && _c !== void 0 ? _c : '-'),
                React.createElement("h4", null, "Tags"),
                React.createElement("p", null, "-"),
                React.createElement("h4", null, "General Description"),
                React.createElement("p", null, (_d = dp.profileDescription) !== null && _d !== void 0 ? _d : '-'),
                React.createElement("h4", null, "General Notes"),
                React.createElement("p", null, (_e = dp.profileNotes) !== null && _e !== void 0 ? _e : '-'));
        };
        this.renderEpidemiology = () => {
            var _a;
            const dp = this.state.diseaseProfile;
            return React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Epidemiology"),
                React.createElement("h4", null, "Epidemiology Notes"),
                React.createElement("p", null, (_a = dp.epidemiologyNotes) !== null && _a !== void 0 ? _a : '-'));
        };
        this.renderPrognosis = () => {
            const dp = this.state.diseaseProfile;
            return React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Prognosis"),
                React.createElement("h4", null, "Criticality"),
                React.createElement("p", null, dp.criticality ? shared_3.CriticalityLabelMap[dp.criticality] : '-'));
        };
        this.renderScores = () => {
            var _a;
            const dp = this.state.diseaseProfile;
            return React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Scores"),
                React.createElement("h4", null, "Scores"),
                React.createElement("p", null, (_a = dp.scores) !== null && _a !== void 0 ? _a : '-'));
        };
        this.renderReferences = () => {
            const dp = this.state.diseaseProfile;
            return React.createElement(React.Fragment, null,
                React.createElement("h3", null, "References"),
                dp.referenceIds.map((refId, i) => {
                    const ref = frontend_2.ModuleFE_Reference.cache.unique(refId);
                    return React.createElement(React.Fragment, { key: refId },
                        React.createElement("h4", null,
                            i + 1,
                            ".",
                            ref.type),
                        React.createElement("p", null, (0, frontend_2.resolveReferenceDisplay)(ref)));
                }));
        };
        // ######################## Render - MultiVars ########################
        this.renderDiseaseViews = () => {
            const dp = this.state.diseaseProfile;
            const viewOrder = _entity_1.ConfigKeyFE_DPViewOrder.get();
            const diseaseViews = (0, ts_common_1.sortArray)(_entity_1.ModuleFE_DiseaseView.cache.allMutable(), i => viewOrder.indexOf(i._id));
            return diseaseViews.map(dv => {
                const mvs = _entity_1.ModuleFE_MultiVar.cache.filter(i => i.diseaseProfileId === dp._id
                    && _entity_1.ModuleFE_DiseaseView.filterMultiVarByEnforcedTag(dv, i));
                if (!mvs.length)
                    return;
                return React.createElement(frontend_1.Grid, { className: 'printable__disease-profile__view', key: dv._id },
                    React.createElement("h2", null, dv.label),
                    mvs.map(i => this.renderMultiVar(i)));
            });
        };
        this.renderMultiVar = (mv) => {
            var _a;
            const dp = this.state.diseaseProfile;
            const mainVar = frontend_2.ModuleFE_Variable.cache.unique(mv._firstVarId);
            const attribute = ((_a = mv.variables[1]) === null || _a === void 0 ? void 0 : _a.attributeeId) === mainVar._id ? frontend_2.ModuleFE_Variable.cache.unique(mv.variables[1].variableId) : undefined;
            const referenceIndexes = (0, ts_common_1.sortArray)(mv.referenceIds.map(id => dp.referenceIds.indexOf(id)));
            const dvs = _entity_1.ModuleFE_DiseaseValue.cache.filter(i => i.multiVarId === mv._id);
            return React.createElement(React.Fragment, { key: mv._id },
                React.createElement("h3", null,
                    mainVar.name,
                    attribute ? React.createElement(React.Fragment, null,
                        React.createElement("span", { className: 'dot' }, "."),
                        attribute.name) : React.createElement(React.Fragment, null),
                    referenceIndexes.map(i => React.createElement("sup", { key: i },
                        "[",
                        i + 1,
                        "]"))),
                dvs.map((dv, i) => {
                    return React.createElement(React.Fragment, { key: dv._id },
                        this.renderDiseaseValue(mv, dv),
                        i !== dvs.length - 1 && React.createElement("div", { className: 'printable__disease-profile__value-separator' }));
                }));
        };
        this.renderDiseaseValue = (mv, dv) => {
            const mainValue = frontend_2.ModuleFE_Value.cache.unique(dv.values[mv._firstVarId]);
            const likelihood = this.renderDVLikelihood(dv);
            const severity = dv.severityFactor ? shared_1.SeverityLabelMap[dv.severityFactor] : undefined;
            const columnIds = (0, ts_common_1._keys)(dv.values).filter(i => i !== mv._firstVarId);
            return React.createElement(React.Fragment, null,
                React.createElement("h4", { className: 'bold' }, "Value"),
                React.createElement("p", null, !mainValue ? '-' : (0, frontend_2.resolveValueDisplay)(mainValue)),
                this.getAttributesForVarId(mv._firstVarId, dv).map(attribute => {
                    const att = frontend_2.ModuleFE_Variable.cache.unique(attribute.attributeId);
                    const value = frontend_2.ModuleFE_Value.cache.unique(attribute.valueId);
                    return React.createElement(React.Fragment, { key: `${mv._firstVarId}-${att._id}` },
                        React.createElement("h4", null, att.name),
                        React.createElement("p", null, (0, frontend_2.resolveValueDisplay)(value)));
                }),
                columnIds.map(varId => {
                    const variable = frontend_2.ModuleFE_Variable.cache.unique(varId);
                    const value = frontend_2.ModuleFE_Value.cache.unique(dv.values[varId]);
                    if (!variable) {
                        console.log(`NO VAR ${varId}`);
                        console.log(dv);
                    }
                    return React.createElement(React.Fragment, { key: varId },
                        React.createElement("h4", null, variable.name),
                        React.createElement("p", null, (0, frontend_2.resolveValueDisplay)(value)),
                        this.getAttributesForVarId(varId, dv).map(attribute => {
                            const att = frontend_2.ModuleFE_Variable.cache.unique(attribute.attributeId);
                            const attValue = frontend_2.ModuleFE_Value.cache.unique(attribute.valueId);
                            return React.createElement(React.Fragment, { key: `${varId}-${att._id}` },
                                React.createElement("h4", null,
                                    variable.name,
                                    ".",
                                    att.name),
                                React.createElement("p", null, (0, frontend_2.resolveValueDisplay)(attValue)));
                        }));
                }),
                likelihood && React.createElement(React.Fragment, null,
                    React.createElement("h4", null, "Likelihood"),
                    React.createElement("p", null, likelihood)),
                severity && React.createElement(React.Fragment, null,
                    React.createElement("h4", null, "Severity"),
                    React.createElement("p", null, severity)));
        };
        this.renderDVLikelihood = (dv) => {
            if (!dv.likelihood && !dv.likelihoodRange)
                return undefined;
            let str = '';
            if (dv.likelihoodRange) {
                str = this.numberArrayRenderer(dv.likelihoodRange) + '%';
            }
            if (dv.likelihood) {
                if (str !== '')
                    str += ', ';
                str += shared_2.LikelihoodLabelMap[dv.likelihood];
            }
            return str;
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        var _a;
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        (_a = state.diseaseProfile) !== null && _a !== void 0 ? _a : (state.diseaseProfile = _entity_1.ModuleFE_DiseaseProfile.cache.unique(nextProps.diseaseProfileId));
        if (!state.diseaseProfile)
            throw new ts_common_1.BadImplementationException(`Could not find DP with id ${nextProps.diseaseProfileId}`);
        return state;
    }
    // ######################## Render - Structure ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'printable__disease-profile' },
            React.createElement("table", null,
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            this.renderTitle(),
                            this.renderGeneralInformation(),
                            this.renderDiseaseViews()))),
                React.createElement("tfoot", null,
                    React.createElement("tr", null,
                        React.createElement("td", null, "\u00A0")))),
            this.renderFooter());
    }
}
Printable_DiseaseProfile.defaultProps = {
    modules: [frontend_2.ModuleFE_Book, frontend_2.ModuleFE_Value]
};
exports.Printable_DiseaseProfile = Printable_DiseaseProfile;
