"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveInRangeConversion = exports.resolveRangeEvaluationLogic = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
const shared_1 = require("../../../../../_entity/value/shared");
const NumericOperatorConversion = {
    [consts_1.LogicType_Equals]: '==',
    [consts_1.LogicType_DeltaPercentageEquals]: '==',
    [consts_1.LogicType_DeltaEquals]: '==',
    [consts_1.LogicType_DeltaGreaterEquals]: '>=',
    [consts_1.LogicType_DeltaPercentageGreaterEquals]: '>=',
    [consts_1.LogicType_DeltaPercentageLesserEquals]: '<=',
    [consts_1.LogicType_DeltaLesserEquals]: '<=',
};
const resolveRangeEvaluationLogic = (dbAtom, dependencies) => {
    return getCaseRangeType(dbAtom.cases[0]) === 'range' ? resolveInRange(dbAtom) : resolveInImplicitRange(dbAtom, dependencies);
};
exports.resolveRangeEvaluationLogic = resolveRangeEvaluationLogic;
const resolveInRange = (dbAtom) => {
    const inRange = {};
    // Set default values derived from the type
    inRange.output_type = 'boolean';
    inRange.type = 'in-range-condition';
    inRange.range_expression = createRangeExpression(dbAtom.cases[0]);
    return inRange;
};
const createInImplicitRangeOption = (_case, option, dependencies) => {
    if (!(_case.rightHand))
        throw new ts_common_1.BadImplementationException(`can't convert to range case with empty right hand`);
    const value = dependencies.values.find(value => value._id === _case.rightHand[0]);
    if (!value)
        throw new ts_common_1.BadImplementationException(`Cannot find DB Value: ${_case.rightHand[0]}`);
    option.db_id = value._id;
    option.name = (0, shared_1.resolveValueDisplay)(value);
    return option;
};
const resolveInImplicitRange = (dbAtom, dependencies) => {
    const inImplicitRange = {};
    // Set default values derived from the type
    inImplicitRange.output_type = 'boolean';
    inImplicitRange.type = 'in-implicit-range-condition';
    return createInImplicitRangeOption(dbAtom.cases[0], inImplicitRange, dependencies);
};
const resolveInRangeConversion = (dbAtom, baseConversion, dependencies) => {
    const inRangeConversion = baseConversion;
    inRangeConversion.type = 'in-range-conversion';
    inRangeConversion.options = dbAtom.cases.map(_case => {
        const rangeType = getCaseRangeType(_case);
        const option = {
            return_value: inRangeConversion.output_type === 'tuple' ? _case.value : _case.value[0]
        };
        if (rangeType === 'implicit-range')
            return createInImplicitRangeOption(_case, option, dependencies);
        else
            return Object.assign(Object.assign({}, option), { range_expression: createRangeExpression(_case) });
    });
    return inRangeConversion;
};
exports.resolveInRangeConversion = resolveInRangeConversion;
const createRangeExpression = (dbAtomCase) => {
    var _a;
    if (!(dbAtomCase.rightHand))
        throw new ts_common_1.BadImplementationException(`can't convert to range case with empty right hand`);
    if (consts_1.AllInOperators.includes(dbAtomCase.caseOperator)) {
        return `${dbAtomCase.rightHand[0]} <= A <= ${dbAtomCase.rightHand[1]}`;
    }
    else if (dbAtomCase.rightHand[0] !== dbAtomCase.rightHand[1])
        throw new ts_common_1.BadImplementationException(`When using the " ${dbAtomCase.caseOperator}" operator in an Atom, the right hand value cannot be a range!`);
    const operator = (_a = NumericOperatorConversion[dbAtomCase.caseOperator]) !== null && _a !== void 0 ? _a : dbAtomCase.caseOperator;
    return `A ${operator} ${dbAtomCase.rightHand[0]}`;
};
const getCaseRangeType = (_case) => {
    if (_case.caseOperator === consts_1.LogicType_In) {
        return isRange(_case.rightHand) ? 'range' : 'implicit-range';
    }
    return 'range';
};
const isRange = (value) => {
    return ((value === null || value === void 0 ? void 0 : value.length) === 2 && typeof value[0] === "number" && typeof value[1] === "number");
};
