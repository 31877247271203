"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_OrgCountry = exports.MultiSelect_Organization = exports.DropDown_Organization = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_Organization_1 = require("./ModuleFE_Organization");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const consts_1 = require("../../../frontend/ui/Page_OrganizationEditor/consts");
const frontend_2 = require("@app/styles/frontend");
const Props_DropDown = {
    module: ModuleFE_Organization_1.ModuleFE_Organization,
    modules: [ModuleFE_Organization_1.ModuleFE_Organization],
    mapper: item => [item.name],
    placeholder: 'Choose a Organization',
    renderer: item => React.createElement("div", { className: "ll_h_c" },
        " ",
        item.name,
        " ")
};
exports.DropDown_Organization = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Organization_1.ModuleFE_Organization,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.name);
    },
    uiSelector: exports.DropDown_Organization.selectable,
});
exports.MultiSelect_Organization = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
exports.DropDown_OrgCountry = frontend_1.TS_DropDown.prepare({
    adapter: (0, frontend_1.SimpleListAdapter)(consts_1.countryOptions.map(item => item.country), op => React.createElement(React.Fragment, null, op.item)),
    caret: frontend_2.QuaiManager_DropDownCaret,
});
