"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpressionJsonVisitor = void 0;
const tree_1 = require("antlr4ts/tree");
const ts_common_1 = require("@nu-art/ts-common");
const expression_1 = require("../../../../../../shared/expression");
const consts_1 = require("../../../../../../shared/expression/expressions-quai/consts");
const frontend_1 = require("../../../../../../_entity/value/frontend");
const shared_1 = require("../../../../../../_entity/value/shared");
const variablesBlackbox = {
    '50936967ef0beed086c159c5dc09bbe2': {
        number: 3,
        boolean: true,
        string: 'zevel',
        nested: {
            anotherString: 'ashpa'
        }
    },
    '7b9c2f60a3725306be9c0b696320c4c1': {
        name: 'gender'
    },
    '7aa6b7d572ec8538e2fc35b8354e8011': {
        min: 200,
        max: 500
    }
};
class ExpressionJsonVisitor extends tree_1.AbstractParseTreeVisitor {
    constructor(withLog = false) {
        super();
    }
    defaultResult() {
        return {};
    }
    visitBooleanOperationExpr(ctx) {
        return this.visit(ctx.boolExpr0());
    }
    visitBooleanEqualityExpr(ctx) {
        return { op: expression_1.Operator_EQUAL, params: [this.visit(ctx.boolExpr0()), this.visit(ctx.boolExpr1())] };
    }
    visitArithmeticOperationExpr(ctx) {
        return this.visit(ctx.numericExpr0());
    }
    visitAtomicExpr(ctx) {
        return this.visit(ctx.stringExpr0());
    }
    visitStringExpr(ctx) {
        return ctx.STRING().text.replace(/"/g, '');
    }
    visitParenExpr(ctx) {
        return this.visit(ctx.expression());
    }
    visitParenBooleanOperation(ctx) {
        return this.visit(ctx.boolExpr0());
    }
    visitAdditionOperation(ctx) {
        return { op: expression_1.Operator_ADDITION, params: [this.visit(ctx.numericExpr0()), this.visit(ctx.numericExpr1())] };
    }
    visitSubtractionOperation(ctx) {
        return { op: expression_1.Operator_SUBTRACT, params: [this.visit(ctx.numericExpr0()), this.visit(ctx.numericExpr1())] };
    }
    visitPassThroughArithmeticTerm(ctx) {
        return this.visit(ctx.numericExpr1());
    }
    visitMultiplicationOperation(ctx) {
        return { op: expression_1.Operator_MULTIPLICATION, params: [this.visit(ctx.numericExpr1()), this.visit(ctx.numericExpr2())] };
    }
    visitDivisionOperation(ctx) {
        return { op: expression_1.Operator_DIV, params: [this.visit(ctx.numericExpr1()), this.visit(ctx.numericExpr2())] };
    }
    visitModuloOperation(ctx) {
        return { op: expression_1.Operator_MOD, params: [this.visit(ctx.numericExpr1()), this.visit(ctx.numericExpr2())] };
    }
    visitPowerOperation(ctx) {
        return { op: expression_1.Operator_POWER, params: [this.visit(ctx.numericExpr3()), this.visit(ctx.numericExpr2())] };
    }
    visitNumberLiteral(ctx) {
        return parseFloat(ctx.NUMBER().text);
    }
    visitParenArithmeticOperation(ctx) {
        return this.visit(ctx.numericExpr0());
    }
    visitAndOperation(ctx) {
        return { op: expression_1.Operator_AND, params: [[this.visit(ctx.boolExpr1()), this.visit(ctx.boolExpr2())]] };
    }
    visitNumericEqaulComparison(ctx) {
        return { op: expression_1.Operator_EQUAL, params: [this.visit(ctx.numericExpr0(0)), this.visit(ctx.numericExpr0(1))] };
    }
    visitAtomicEqualComparison(ctx) {
        return { op: expression_1.Operator_EQUAL, params: [this.visit(ctx.stringExpr0(0)), this.visit(ctx.stringExpr0(1))] };
    }
    visitOrOperation(ctx) {
        return { op: expression_1.Operator_OR, params: [[this.visit(ctx.boolExpr0()), this.visit(ctx.boolExpr1())]] };
    }
    visitNotOperation(ctx) {
        return !this.visit(ctx.boolExpr4());
    }
    visitLessThanComparison(ctx) {
        return { op: expression_1.Operator_LESSER_THAN, params: [this.visit(ctx.numericExpr0(0)), this.visit(ctx.numericExpr0(1))] };
    }
    visitGreaterThanComparison(ctx) {
        return { op: expression_1.Operator_GREATER_THAN, params: [this.visit(ctx.numericExpr0(0)), this.visit(ctx.numericExpr0(1))] };
    }
    visitNegativeExpression(ctx) {
        const value = this.visit(ctx.numericExpr3());
        return -value;
    }
    visitResolvablesExpr(ctx) {
        return this.visit(ctx.resolvableExpr());
    }
    visitResolveVar(ctx) {
        return variablesBlackbox[ctx.UNIQUE_ID().text];
    }
    visitResolveProp(ctx) {
        const props = ctx.PROPERTY().map(terminalProp => terminalProp.text);
        return {
            op: consts_1.Operator_GetPatientVar, params: [props.reduce((value, currentProp) => {
                    return value[currentProp];
                }, this.visit(ctx.resolveVar()))]
        };
    }
    visitResolveEnumerated(ctx) {
        const id = this.visit(ctx.resolveEnum());
        const enumValue = frontend_1.ModuleFE_Value.cache.unique(id);
        if (!enumValue)
            throw new ts_common_1.BadImplementationException(`no value found for id: ${id}`);
        if (enumValue.type !== shared_1.valueType_EnumElement)
            throw new ts_common_1.BadImplementationException(`value with id ${id} is not enum type, actual type: ${enumValue.type}`);
        return { op: 'enum', params: [enumValue.data.value] };
    }
    visitResolveYesNo(ctx) {
        if (ctx.resolveNo())
            return { op: 'boolean value', params: [this.visit(ctx.resolveNo())] };
        if (ctx.resolveYes())
            return { op: 'boolean value', params: [this.visit(ctx.resolveYes())] };
    }
    visitResolveEnum(ctx) {
        return ctx.UNIQUE_ID().text;
    }
    visitResolveYes(ctx) {
        return 'yes';
    }
    visitResolveNo(ctx) {
        return 'no';
    }
    visitConditionExpr(ctx) {
        return this.visit(ctx.conditionalExpr0());
    }
    visitGeneralCondition(ctx) {
        return {
            op: expression_1.Operator_Func_If,
            params: [this.visit(ctx.boolExpr0()), this.visit(ctx.expression(0)), this.visit(ctx.expression(1))]
        };
    }
    visitInRange(ctx) {
        return {
            op: expression_1.Operator_IN_RANGE,
            params: [this.visit(ctx.numericExpr0(1)), [this.visit(ctx.numericExpr0(0)), this.visit(ctx.numericExpr0(2))]]
        };
    }
    visitBooleanLiteral(ctx) {
        return ctx.BOOL().text === 'true';
    }
}
exports.ExpressionJsonVisitor = ExpressionJsonVisitor;
