"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Tags = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Panel_Tags.scss");
const _shared_1 = require("../../../_shared");
const styles_1 = require("@app/styles");
const shared_dialogs_1 = require("../../shared-dialogs");
const shared_components_1 = require("../../shared-components");
const utils_1 = require("../../../utils");
const q_components_1 = require("../../q-components");
const Dialog_MacroTagEditor_1 = require("../../macro-tags/Dialog_MacroTagEditor/Dialog_MacroTagEditor");
const frontend_2 = require("../../../../_entity/macro-tag/frontend");
const _entity_1 = require("../../../_entity");
const Dialog_EditChildrenOrder_1 = require("./Dialog_EditChildrenOrder");
// ******** Functions ********
function sort(key, config, node) {
    if (!node._children)
        return;
    const sortOrder = config === null || config === void 0 ? void 0 : config[key];
    if (sortOrder)
        node._children = (0, ts_common_1.sortArray)(node._children.filter(item => item.type === 'tag'), child => sortOrder.indexOf(child.item._id));
    node._children.filter(item => item.type === 'tag').forEach(child => sort(child.item._id, config, child));
}
// ******** Component Data ********
const newTagGroupId = '######';
const newTagId = '$$$$$$';
class Panel_Tags extends frontend_1.ProtoComponent {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        this.treeRendererMap = {
            root: (props) => React.createElement("div", null, props.item),
            tag: (props) => this.tagRenderer(props),
            variable: (props) => React.createElement("div", null, props.item.name),
        };
        this.__onTagsUpdated = (...params) => {
            this.reDeriveState();
        };
        this.onTagSelected = (path, item) => {
            if (item.type !== 'tag')
                return;
            const selected = Object.assign({}, this.getQueryParam('selected', {}));
            selected[_shared_1.DBDef_Tag.dbKey] = item.item._id;
            const query = { selected: selected };
            //Set tree expansion if this item can be expanded
            if (this.treeItemHasChildren(item.item._id)) {
                const treeExpansion = Object.assign({}, this.getQueryParam('tagTreeExpansion', { '/': true }));
                if (treeExpansion[path])
                    delete treeExpansion[path];
                else
                    treeExpansion[path] = true;
                query.tagTreeExpansion = treeExpansion;
            }
            this.setQueryParams(query);
        };
        this.onTagRightClick = (e, path, item) => {
            var _a;
            if (!item || item.type !== 'tag')
                return;
            const itemPath = e.currentTarget.getAttribute('data-path');
            const canAddChildren = ((_a = item.item.tagIds) === null || _a === void 0 ? void 0 : _a.length) === 0;
            const canDeleteChildren = !this.treeItemHasChildren(item.item._id);
            frontend_1.ModuleFE_MouseInteractivity.showContent({
                id: 'tags__menu',
                originPos: { x: e.clientX, y: e.clientY },
                modalPos: { x: 1, y: 1 },
                content: React.createElement(frontend_1.Show, null,
                    React.createElement(frontend_1.Show.If, { condition: true },
                        React.createElement("div", { className: 'popup-action', onClick: () => {
                                frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                                this.setState({ editedTagId: item.item._id });
                            } }, "Edit")),
                    React.createElement(frontend_1.Show.If, { condition: canAddChildren },
                        React.createElement("div", { className: 'popup-action', onClick: () => {
                                frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                                if (!item._children)
                                    item._children = [];
                                item._children.unshift({
                                    type: 'tag',
                                    item: {
                                        label: '',
                                        tagIds: [item.item._id],
                                        _id: newTagId,
                                    }
                                });
                                this.setState({ editedTagId: newTagId }, () => {
                                    const expanded = Object.assign({}, this.getQueryParam('tagTreeExpansion', { '/': true }));
                                    expanded[itemPath] = true;
                                    this.setQueryParam('tagTreeExpansion', expanded);
                                });
                            } }, "Add Tag")),
                    React.createElement(frontend_1.Show.If, { condition: false },
                        React.createElement("div", { className: 'popup-action', onClick: () => Dialog_EditChildrenOrder_1.Dialog_EditChildrenOrder.show(item.item._id) }, "Edit Children Order")),
                    React.createElement(frontend_1.Show.If, { condition: canDeleteChildren },
                        React.createElement("div", { className: 'popup-action', onClick: () => {
                                frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                                shared_dialogs_1.WarningDialog.showDelete({
                                    title: 'Delete Tag',
                                    executeButtonText: 'Delete Tag',
                                    warningMessages: ['Delete this tag?'],
                                    onConfirm: async () => {
                                        await (0, frontend_1.performAction)(async () => {
                                            await _entity_1.ModuleFE_Tag.v1.delete(item.item).executeSync();
                                        }, {
                                            type: 'toast',
                                            successContent: React.createElement(q_components_1.QToastContent, { content: 'Tag deleted successfully', toastType: 'success', iconKey: 'v' }),
                                            duration: 10000
                                        }, item.item.label);
                                        frontend_1.ModuleFE_Dialog.close();
                                    }
                                });
                            } }, "Remove")))
            });
        };
        this.executeHeaderAction = (key) => {
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
            switch (key) {
                case 'add-tag': {
                    this.state.tree._children.unshift({
                        type: 'tag',
                        item: {
                            label: '',
                            tagIds: [],
                            _id: newTagGroupId,
                        }
                    });
                    return this.setState({ editedTagId: newTagGroupId });
                }
                case 'add-macro-tag': {
                    return Dialog_MacroTagEditor_1.Dialog_MacroTagEditor.show();
                }
                default:
                    return this.logWarning(`Missing action implementation for key ${key}`);
            }
        };
        this.onMacroRightClick = (e, macro) => {
            frontend_1.ModuleFE_MouseInteractivity.showContent({
                id: 'tags__menu',
                originPos: { x: e.clientX, y: e.clientY },
                modalPos: { x: 1, y: 1 },
                content: React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'popup-action', onClick: () => {
                            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                            Dialog_MacroTagEditor_1.Dialog_MacroTagEditor.show(macro._id, true);
                        } }, "Edit Macro Tag")),
            });
        };
        this.treeItemHasChildren = (id) => {
            const categories = _entity_1.ModuleFE_Tag.cache.all();
            const find = categories.find((item) => {
                return item.tagIds.includes(id);
            });
            return !!find;
        };
        // ######################### Render #########################
        this.tagRenderer = (props) => {
            return React.createElement(frontend_1.TS_EditableText.Input, { className: 'node-data', renderers: {
                    cancelButton: React.createElement(styles_1.ICONS.x.component, null),
                    saveButton: React.createElement(styles_1.ICONS.v.component, null),
                    resetButton: React.createElement(styles_1.ICONS.sync.component, null)
                }, editMode: this.state.editedTagId === props.item._id, text: props.item.label || '', onCancel: () => {
                    var _a, _b;
                    if (props.item._id === newTagGroupId) {
                        this.state.tree._children = ((_a = this.state.tree._children) === null || _a === void 0 ? void 0 : _a.filter(item => item.type === 'tag')).filter(item => item.item._id !== newTagGroupId);
                    }
                    if (props.item._id === newTagId)
                        ((_b = this.state.tree._children) === null || _b === void 0 ? void 0 : _b.filter(item => item.type === 'tag')).forEach(item => {
                            var _a;
                            if (item._children)
                                item._children = ((_a = item._children) === null || _a === void 0 ? void 0 : _a.filter(item => item.type === 'tag')).filter(i => i.item._id !== newTagId);
                        });
                    this.setState({ editedTagId: undefined });
                }, onTextSaved: async (label) => {
                    const newTag = new frontend_1.EditableDBItemV3(props.item, _entity_1.ModuleFE_Tag);
                    newTag.set('label', label);
                    //Delete const id if this is a new tag or tag group
                    if ([newTagId, newTagGroupId].includes(newTag.item._id))
                        delete newTag.item._id;
                    //Set
                    if (newTag.item._id === newTagId) {
                        const parent = this.state.tree._children.find(item => { var _a; return ((_a = item._children) === null || _a === void 0 ? void 0 : _a.findIndex(tag => tag.item._id === newTagId)) !== -1; });
                        if (!parent)
                            throw new ts_common_1.BadImplementationException('Could not find tag group');
                        newTag.set('tagIds', [parent.item._id]);
                    }
                    try {
                        await newTag.save();
                        this.setState({ editedTagId: undefined });
                    }
                    catch (e) {
                        (0, utils_1.handleError)(e);
                    }
                } });
        };
        this.renderHeaderMenu = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'popup-action', onClick: () => this.executeHeaderAction('add-tag') }, "Add Tag Group"),
                React.createElement("div", { className: 'popup-action', onClick: () => this.executeHeaderAction('add-macro-tag') }, "Add Macro Tag"));
        };
        this.renderMacroTags = () => {
            const allMacroTags = frontend_2.ModuleFE_MacroTag.cache.all();
            let renderedMacroTags = [];
            const filter = this.getQueryParam('filterTags');
            if (!filter || filter.length < 3)
                renderedMacroTags = allMacroTags;
            else {
                const _filter = new ts_common_1.Filter(macro => [macro.label]);
                renderedMacroTags = _filter.filterSort(allMacroTags, filter);
            }
            if (!renderedMacroTags.length)
                return;
            return React.createElement(frontend_1.TS_CollapsableContainer, { className: 'categories-area__macro-tags', customCaret: React.createElement(styles_1.ICONS.advisor_v4_arrow.component, null), headerRenderer: 'Macro Tags', containerRenderer: () => {
                    return React.createElement(frontend_1.LL_V_L, null, renderedMacroTags.map(macroTag => {
                        return React.createElement("div", { key: macroTag._id, className: 'categories-area__macro-tag', onContextMenu: e => this.onMacroRightClick(e, macroTag), onClick: () => Dialog_MacroTagEditor_1.Dialog_MacroTagEditor.show(macroTag._id) }, macroTag.label);
                    }));
                } });
        };
    }
    // ######################### Life Cycle #########################
    __onAppConfigsUpdated(...params) {
        if (params[0] === frontend_1.EventType_Create || params[0] === frontend_1.EventType_Update) {
            if (params[1].key === 'categoriesOrder')
                this.reDeriveState();
        }
    }
    __onMacroTagUpdated(...params) {
        this.forceUpdate();
    }
    deriveStateFromProps(nextProps, state) {
        state.treeExpansion = this.getQueryParam('tagTreeExpansion', { '/': true });
        state.selectedTagId = this.getQueryParam('selected', {})[_shared_1.DBDef_Tag.dbKey];
        if (!this.treeRendererMap)
            this.treeRendererMap = {
                root: (props) => React.createElement("div", null, props.item),
                tag: (props) => this.tagRenderer(props),
                variable: (props) => React.createElement("div", null, props.item.name),
            };
        const _tree = {
            type: 'root',
            item: 'root',
            _children: []
        };
        let tags = _entity_1.ModuleFE_Tag.cache.allMutable();
        const sourceTagId = _entity_1.ConfigKeyFE_SourceTagId.get();
        // Prepare all tree nodes for all tags, source should be first... FOR NOW!!
        const tagsTreeItemsMap = (0, ts_common_1.arrayToMap)((0, ts_common_1.sortArray)(tags, option => option._id === sourceTagId ? 'AAA' : option.label).map(tag => {
            const item = {
                type: 'tag',
                item: tag,
            };
            return item;
        }), option => option.item._id);
        //Filter tags
        const filter = this.getQueryParam('filterTags');
        if (filter && filter.length >= 3) {
            //Extract Categories that contain the filter inside their label
            const parentSet = new Set();
            const childrenSet = new Set();
            tags.forEach((tag) => {
                if (tag.label.toLowerCase().includes(filter)) {
                    parentSet.add(tag._id);
                    childrenSet.add(tag._id);
                }
            });
            //Add Children of found tags
            tags.forEach(tag => {
                childrenSet.forEach(id => {
                    if (id && tag.tagIds.includes(id))
                        childrenSet.add(tag._id);
                });
            });
            //Add Parent of found tags
            parentSet.forEach(id => {
                const tag = _entity_1.ModuleFE_Tag.cache.unique(id);
                if (!tag)
                    return;
                tag.tagIds.forEach(_id => parentSet.add(_id));
            });
            const joinedSet = new Set();
            parentSet.forEach(id => joinedSet.add(id));
            childrenSet.forEach(id => joinedSet.add(id));
            tags = (0, ts_common_1.filterInstances)(Array.from(joinedSet).map(id => tags.find(el => el._id === id)));
        }
        // Populate the children in their parent node -- build a tree
        state.tree = tags.reduce((__tree, tag) => {
            var _a;
            const parentTagId = tag.tagIds[tag.tagIds.length - 1];
            if (!parentTagId) {
                (_a = _tree._children) === null || _a === void 0 ? void 0 : _a.push(tagsTreeItemsMap[tag._id]);
                return __tree;
            }
            const parentNode = tagsTreeItemsMap[parentTagId];
            ((parentNode === null || parentNode === void 0 ? void 0 : parentNode._children) || (parentNode._children = [])).push(tagsTreeItemsMap[tag._id]);
            return __tree;
        }, _tree);
        //Set tags order by config
        const tagsOrderConfig = _entity_1.ConfigKeyFE_TagsOrder.get();
        if (tagsOrderConfig)
            sort(_shared_1.RootTag, tagsOrderConfig, state.tree);
        //Set Tree Expansion
        //If the filter is sufficient && we have children in the tree
        if (filter && filter.length >= 3 && state.tree._children && state.tree._children.length < 4)
            state.tree._children.forEach((_, i) => state.treeExpansion[`/_children/${i}/`] = true);
        state.adapter = this.createAdapter(state.tree);
        return state;
    }
    // ######################### Logic #########################
    createAdapter(tree) {
        return (0, frontend_1.AdapterBuilder)()
            .tree()
            .multiRender(this.treeRendererMap)
            .setExpandCollapseRenderer((props) => {
            function resolveSymbol() {
                if (typeof props.item !== 'object')
                    return '';
                if (Object.keys(props.item).length === 0)
                    return '';
                if (props.node.adapter.isParent(props.item)) {
                    return React.createElement(styles_1.ICONS.treeCollapse.component, null);
                }
                return '';
            }
            const className = (0, frontend_1._className)('node-icon', props.node.expanded ? 'expanded' : undefined);
            return React.createElement("div", { className: className, style: { minWidth: '12px' } }, resolveSymbol());
        })
            .hideRoot()
            .setData(tree)
            .build();
    }
    renderHeader() {
        return React.createElement(shared_components_1.Panel_Header, { config: { filter: this.getQueryParam('filterTags') }, onFilterChanged: (filter) => {
                this.setQueryParam('filterTags', filter);
            }, qsearchId: 'tags-area', headerClassName: 'tags-area__header', headerTail: React.createElement("div", { className: 'categories-area__header__icon' },
                React.createElement(styles_1.ICONS.more.component, Object.assign({}, frontend_1.openContent.popUpV2.bottom({
                    id: 'tags__menu',
                    content: this.renderHeaderMenu
                })))) });
    }
    renderMessage(message) {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent', style: { justifyContent: 'center', alignItems: 'center' } }, message);
    }
    renderTree() {
        const tree = this.state.tree;
        if (!tree || !tree._children.length)
            return this.renderMessage('No categories found');
        return React.createElement("div", { className: 'categories-tree' },
            React.createElement(frontend_1.TS_Tree, { expanded: Object.assign({}, this.state.treeExpansion), adapter: this.state.adapter, isSelected: data => { var _a; return ((_a = data.item) === null || _a === void 0 ? void 0 : _a._id) === this.state.selectedTagId; }, onNodeClicked: this.onTagSelected, onContextMenuClicked: this.onTagRightClick }));
    }
    render() {
        return React.createElement(frontend_1.LL_V_C, { className: 'categories-area' },
            this.renderHeader(),
            React.createElement(q_components_1.QScrollWrapper, null,
                this.renderMacroTags(),
                this.renderTree()));
    }
}
Panel_Tags.defaultProps = {
    keys: ['selected', 'filterTags', 'tagTreeExpansion'],
};
exports.Panel_Tags = Panel_Tags;
