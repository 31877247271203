"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_SnomedEditor = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./referenceDialogs.scss");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../../_entity");
class Dialog_SnomedEditor extends frontend_1.TS_Dialog {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        // ######################### LifeCycle #########################
        this.__onReferencesUpdated = (...params) => {
            const originRef = this.state.reference;
            switch (params[0]) {
                case frontend_1.EventType_Create:
                case frontend_1.EventType_Update:
                    this.props.onRefUpsertCallback(params[1], originRef);
                    frontend_1.ModuleFE_Dialog.close();
                    break;
            }
        };
        // ######################### Logic #########################
        this.canBeSaved = () => {
            return !!this.state.reference.data.id.length;
        };
        this.handleSave = async () => {
            await (0, frontend_1.performAction)(async () => {
                this.setState({ dialogIsBusy: true });
                try {
                    const ref = this.state.reference;
                    await _entity_1.ModuleFE_Reference.v1.upsert(ref).executeSync();
                }
                catch (e) {
                    this.logError(e);
                }
                finally {
                    this.setState({ dialogIsBusy: false });
                }
            }, { type: 'notification', notificationLabels: 'Saving Snomed Reference' });
        };
        // ######################### Render #########################
        this.renderHeader = () => {
            var _a;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'header__title' },
                    ((_a = this.props.reference) === null || _a === void 0 ? void 0 : _a._id) ? 'Edit' : 'Add',
                    " Snomed"),
                React.createElement("div", { className: 'header__buttons' },
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => frontend_1.ModuleFE_Dialog.close() })));
        };
        this.renderBody = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("h2", null, "snomed ID"),
                React.createElement(frontend_1.TS_Input, { type: 'text', placeholder: 'Enter Snomed Id', value: this.state.reference.data.id, tabIndex: 0, onChange: (id) => {
                        this.state.reference.data.id = id;
                        this.forceUpdate();
                    } }));
        };
        this.buttons = () => {
            return {
                right: [
                    {
                        content: 'Cancel',
                        onClick: () => frontend_1.ModuleFE_Dialog.close(),
                    },
                    {
                        content: 'Save',
                        renderer: frontend_1.TS_Dialog.busyButton,
                        className: (0, frontend_1._className)(this.canBeSaved() ? 'desired-action-button' : ''),
                        disabled: !this.canBeSaved() || this.state.dialogIsBusy,
                        onClick: this.handleSave,
                    }
                ]
            };
        };
    }
    static show(onRefUpsertCallback, reference) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_SnomedEditor, { onRefUpsertCallback: onRefUpsertCallback, reference: reference }), () => true);
    }
    deriveStateFromProps(nextProps) {
        let reference = {
            type: 'snomed',
            data: { id: '', names: [] },
        };
        if (nextProps.reference)
            reference = (0, ts_common_1.deepClone)(nextProps.reference);
        return {
            reference: reference,
        };
    }
}
Dialog_SnomedEditor.defaultProps = {
    dialogId: 'dialog-snomed-editor',
};
exports.Dialog_SnomedEditor = Dialog_SnomedEditor;
