"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import * as React from 'react';
// import {UniqueId} from '@nu-art/ts-common';
// import {GraphvizPathwayViewer} from './GraphvizComponents';
// import {Renderer_SearchResult, SearchItemV3} from '@app/styles/frontend';
// import {DB_Pathway, DBProto_Pathway, ModuleFE_Pathway} from '../_entity';
// import {Page_PathwayEditor} from './Page_PathwayEditor';
// import {CollectionEntityMapper} from '@app/hcs';
//
// export const searchItemKey_Pathway = ModuleFE_Pathway.dbDef.dbName;
//
// export const SearchableItem_Pathway: SearchItemV3<DBProto_Pathway> = {
// 	module: ModuleFE_Pathway,
// 	type: searchItemKey_Pathway,
// 	route: Page_PathwayEditor.Route(),
// 	entityName: ModuleFE_Pathway.dbDef.entityName,
// 	entityLabel: 'Pathway',
// 	groupId: ['Advisor'],
// 	mapper: (item) => [item.label],
// 	tags: [],
// 	renderer: (item: DB_Pathway, selected?: boolean, onClickCallback?: () => void) => {
// 		return <Renderer_SearchResult
// 			itemId={item._id}
// 			itemKey={searchItemKey_Pathway}
// 			itemLabel={item.label}
// 			entityLabel={'Pathway'}
// 			hierarchy={'Pathways'}
// 			selected={selected}
// 			onClickCallback={onClickCallback}
// 		/>;
// 	},
// 	resultRenderer: (item: UniqueId) => {
// 		return <GraphvizPathwayViewer pathwayId={item}/>;
// 	}
// };
//
// export const SearchableItem_PathwayV2: SearchItemV3<DBProto_Pathway> = {
// 	...SearchableItem_Pathway,
// 	renderer: (item: DB_Pathway, selected?: boolean, onClickCallback?: () => void) => {
// 		const type = item.type === 'chief-complaint' ? 'Chief Complaint' : 'Differential Diagnosis';
// 		return <Renderer_SearchResult
// 			itemId={item._id}
// 			itemKey={searchItemKey_Pathway}
// 			itemLabel={item.label}
// 			hierarchy={type}
// 			selected={selected}
// 			onClickCallback={onClickCallback}
// 		/>;
// 	},
// };
//
const hcs_1 = require("@app/hcs");
const Page_PathwayEditor_1 = require("./Page_PathwayEditor");
hcs_1.CollectionEntityMapper.addEntities([
    Page_PathwayEditor_1.Page_PathwayEditor.SearchableItem_Pathway,
]);
