"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Facility = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const hcs_1 = require("@app/hcs");
const Validator_ModifiableProps = {
    name: (0, ts_common_1.tsValidateString)(),
    address: (0, ts_common_1.tsValidateString)(),
    organizationId: ts_common_1.tsValidateUniqueId,
    //Optionals
    parentId: ts_common_1.tsValidator_nonMandatoryString,
};
const Validator_GeneratedProps = {};
exports.DBDef_Facility = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'facility',
    entityName: 'Facility',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'facility'
    },
    backend: {
        name: 'facility'
    },
    dependencies: {
        organizationId: {
            dbKey: 'organizations',
            fieldType: 'string'
        },
        parentId: {
            dbKey: 'facility',
            fieldType: 'string',
        }
    }
};
