"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_EHRInstanceKBVersion = exports.ModuleFE_EHRInstanceKBVersion_Class = exports.dispatch_onEHRInstanceKBVersionsUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onEHRInstanceKBVersionsUpdated = new types_1.ThunderDispatcherV3('__onEHRInstanceKBVersionsUpdated');
class ModuleFE_EHRInstanceKBVersion_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_EHRInstanceKBVersion, exports.dispatch_onEHRInstanceKBVersionsUpdated);
        this._v1 = {
            'create-item': (0, frontend_1.apiWithBody)(shared_1.ApiDef_EHRInstanceKBVersion._v1['create-item']),
        };
    }
}
exports.ModuleFE_EHRInstanceKBVersion_Class = ModuleFE_EHRInstanceKBVersion_Class;
exports.ModuleFE_EHRInstanceKBVersion = new ModuleFE_EHRInstanceKBVersion_Class();
