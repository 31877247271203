"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_PathwayTransition = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const ts_common_1 = require("@nu-art/ts-common");
exports.ApiDef_PathwayTransition = {
    _v1: {
        updateResolutionFunction: {
            method: thunderstorm_1.HttpMethod.POST,
            path: 'v1/pathway-transition/update-resolution-function',
            timeout: ts_common_1.Minute
        },
        deleteResolutionFunction: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/pathway-transition/delete-resolution-function' },
        updateTransitionsPriority: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/pathway-transition/update-transitions-priority' }
    }
};
