"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.schemaConversion = void 0;
const convertExpressionLevel_1 = require("./entities/convertExpressionLevel");
const convertTermLevel_1 = require("./entities/convertTermLevel");
const convertAtomLevel_1 = require("./entities/convertAtomLevel");
const schemaConversion = (expression, dependencies) => {
    // Create the base convertedExpression
    const convertedExpression = (0, convertExpressionLevel_1.convertExpression)(expression, dependencies);
    // Convert the Terms of the expression
    (0, convertTermLevel_1.convertTermLevel)(expression.terms, convertedExpression, dependencies);
    // Go over each term atoms for conversion
    Object.values(convertedExpression.terms).forEach((term, index) => {
        const dbTerm = expression.terms[index];
        term.atoms = dbTerm.atoms.reduce((atomsMapper, currentAtom, currentIndex) => {
            // Set converted atom with its index
            atomsMapper[`A${currentIndex + 1}`] = (0, convertAtomLevel_1.convertAtomLevel)(currentAtom, dependencies);
            return atomsMapper;
        }, {});
    });
    return convertedExpression;
};
exports.schemaConversion = schemaConversion;
