"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Header = void 0;
const React = require("react");
require("./Panel_Header.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const q_components_1 = require("../../q-components");
class Panel_Header extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps) {
        return { title: nextProps.title };
    }
    render() {
        var _a;
        return React.createElement(frontend_1.LL_H_C, { className: (0, frontend_1._className)('default_panel_header', this.props.headerClassName) },
            this.state.title &&
                React.createElement("div", { className: (0, frontend_1._className)('unselectable', 'header__title', this.state.title.className) }, this.state.title.label),
            React.createElement(q_components_1.QSearch, { id: this.props.qsearchId, filter: (_a = this.props.config) === null || _a === void 0 ? void 0 : _a.filter, onSearch: (filter) => {
                    var _a, _b;
                    this.props.onFilterChanged(filter.replace(/\W+/g, ' '));
                    (_b = (_a = this.props).onConfigChanged) === null || _b === void 0 ? void 0 : _b.call(_a);
                } }),
            !!this.props.headerTail && this.props.headerTail);
    }
}
exports.Panel_Header = Panel_Header;
