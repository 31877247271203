"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Empty_NormalRangeExpression = void 0;
const consts_1 = require("../../_entity/expression/shared/consts");
const ts_common_1 = require("@nu-art/ts-common");
const Empty_NormalRangeExpression = (value, variable) => ({
    associatedVar: variable === null || variable === void 0 ? void 0 : variable._id,
    expressionType: consts_1.ExpressionType_NormalRange.dbKey,
    terms: [],
    label: variable ? `${(0, ts_common_1.capitalizeAllFirstLetters)(variable.name)} - Normal Range` : '',
    outputType: 'range',
    expression: value.data.declarationVarIds.length ? 'T1' : '[0,0]',
    isEvidenceBased: false
});
exports.Empty_NormalRangeExpression = Empty_NormalRangeExpression;
