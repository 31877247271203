"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ReferencesTable = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
require("./Component_ReferencesTable.scss");
const Dialogs_1 = require("./Dialogs");
const shared_dialogs_1 = require("../../shared-dialogs");
const _entity_1 = require("../../../_entity");
class Component_ReferencesTable extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.__onReferencesUpdated = (...params) => {
            if (['update', 'create'].includes(params[0]))
                this.reDeriveState();
        };
        // ######################## Render ########################
        this.renderDataCell = (ref) => {
            switch (ref.type) {
                case 'book': {
                    const bookRef = ref;
                    return React.createElement("div", { className: 'td-data unselectable' }, (0, _entity_1.resolveReferenceDisplay)(bookRef));
                }
                case 'article': {
                    const link = ref;
                    return React.createElement("div", { className: 'td-data' },
                        React.createElement(frontend_1.TS_Link, { url: link.data.url, target: '_blank' }, (0, _entity_1.resolveReferenceDisplay)(link)));
                }
                case 'upToDate': {
                    const upToDate = ref;
                    return React.createElement("div", { className: 'td-data' },
                        React.createElement(frontend_1.TS_Link, { url: upToDate.data.url, target: '_blank' }, (0, _entity_1.resolveReferenceDisplay)(upToDate)));
                }
                default: {
                    return React.createElement("div", { className: 'td-data unselectable' }, (0, _entity_1.resolveReferenceDisplay)(ref));
                }
            }
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps) {
        const state = {};
        state.references = [];
        if (nextProps.referenceIds.length)
            state.references = _entity_1.ModuleFE_Reference.cache.filter(ref => nextProps.referenceIds.includes(ref._id));
        return state;
    }
    render() {
        return React.createElement("div", { className: 'references-list' },
            React.createElement(frontend_1.TS_Table, { rows: this.state.references || [], header: [{ header: 'type', widthPx: 100 }, 'data'], headerRenderer: columnKey => {
                    return React.createElement("div", { className: 'th-data unselectable' }, columnKey[0].toUpperCase() + columnKey.slice(1));
                }, cellRenderer: (prop, item, index) => {
                    if (prop === 'type')
                        return React.createElement("div", { className: 'td-data unselectable' }, item.type);
                    if (prop === 'data') {
                        return this.renderDataCell(item);
                    }
                }, tr: (row, rowIndex) => (Object.assign(Object.assign({ className: (0, frontend_1._className)((row === null || row === void 0 ? void 0 : row.creationStatus) === 'failed' ? 'invalid-reference' : '', this.props.blockEdit ? 'edit-blocked' : undefined) }, frontend_1.openContent.tooltip.bottom('invalid-ref-tool-tip', () => React.createElement("div", null, (row === null || row === void 0 ? void 0 : row.creationStatus) === 'failed' ? 'Invalid Reference' : ''))), { onContextMenu: async (e) => {
                        if (this.props.blockEdit)
                            return;
                        (0, frontend_1.stopPropagation)(e);
                        e.persist();
                        return Component_ReferencesTable.handleReferencesRightClick(e, this.props.onReferenceUpsertCallback, this.props.onReferenceDeletedCallback, this.props.allowedRefs, row);
                    } })) }));
    }
}
_a = Component_ReferencesTable;
// ######################## Logic ########################
/**
 * creates a right click menu with options for references
 * @param e - Event
 * @param onReferenceUpsertCallback - Callback to be called for reference upsert
 * @param onReferenceDeletedCallback - Callback to be called for reference delete
 * @param allowedRefs - Array of allowed ref types, if empty will allow all ref types
 * @param reference - reference to be edited
 */
Component_ReferencesTable.handleReferencesRightClick = (e, onReferenceUpsertCallback, onReferenceDeletedCallback, allowedRefs, reference) => {
    (0, frontend_1.stopPropagation)(e);
    const menuItems = _a.getAllowedAddRefs(allowedRefs, onReferenceUpsertCallback);
    if (reference) {
        menuItems.unshift({
            label: 'Edit Reference',
            onClick: () => {
                switch (reference.type) {
                    case 'loinc':
                        Dialogs_1.Dialog_LoincEditor.show(onReferenceUpsertCallback, reference);
                        break;
                    case 'snomed':
                        Dialogs_1.Dialog_SnomedEditor.show(onReferenceUpsertCallback, reference);
                        break;
                    case 'book':
                        Dialogs_1.Dialog_BookEditor.show(onReferenceUpsertCallback, reference);
                        break;
                    case 'article':
                        Dialogs_1.Dialog_ArticleEditor.show(onReferenceUpsertCallback, reference);
                        break;
                    case 'upToDate':
                        Dialogs_1.Dialog_UpToDateEditor.show(onReferenceUpsertCallback, reference);
                }
            }
        });
        menuItems.unshift({
            label: 'Delete Reference',
            onClick: () => {
                shared_dialogs_1.WarningDialog.showDelete({
                    warningMessages: ['Are you sure you want to delete this reference?'],
                    title: 'Delete Reference',
                    executeButtonText: 'Delete Reference',
                    onConfirm: () => onReferenceDeletedCallback(reference._id)
                });
            }
        });
    }
    const id = 'panel-values__right-click-menu';
    const adapter = (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label));
    const pos = { x: e.clientX, y: e.clientY };
    new frontend_1.MenuBuilder(adapter, pos, { x: 0, y: 1 })
        .setId(id)
        .setOnClick((path, item) => {
        var _b;
        frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
        (_b = item.onClick) === null || _b === void 0 ? void 0 : _b.call(item);
    })
        .setOffset({ x: 0, y: 10 })
        .show();
};
Component_ReferencesTable.getAllowedAddRefs = (allowedRefs, onReferenceUpsertCallback) => {
    const menuItems = [];
    if (allowedRefs === undefined)
        return menuItems;
    const allowAll = allowedRefs.length === 0;
    if (allowAll || allowedRefs.includes('snomed'))
        menuItems.push({
            label: 'Add Snomed Reference',
            onClick: () => Dialogs_1.Dialog_SnomedEditor.show(onReferenceUpsertCallback)
        });
    if (allowAll || allowedRefs.includes('loinc'))
        menuItems.push({
            label: 'Add Loinc Reference',
            onClick: () => Dialogs_1.Dialog_LoincEditor.show(onReferenceUpsertCallback)
        });
    if (allowAll || allowedRefs.includes('book'))
        menuItems.push({
            label: 'Add Book Reference',
            onClick: () => Dialogs_1.Dialog_BookEditor.show(onReferenceUpsertCallback)
        });
    if (allowAll || allowedRefs.includes('article'))
        menuItems.push({
            label: 'Add Article Reference',
            onClick: () => Dialogs_1.Dialog_ArticleEditor.show(onReferenceUpsertCallback)
        });
    if (allowAll || allowedRefs.includes('upToDate'))
        menuItems.push({
            label: 'Add UpToDate Reference',
            onClick: () => Dialogs_1.Dialog_UpToDateEditor.show(onReferenceUpsertCallback)
        });
    return menuItems;
};
exports.Component_ReferencesTable = Component_ReferencesTable;
