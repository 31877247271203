"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_MacroTag = exports.Validator_TagGroup = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
exports.Validator_TagGroup = {
    key: (0, ts_common_1.tsValidateString)(),
    value: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)()),
};
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateString)(),
    tagGroups: (0, ts_common_1.tsValidateArray)(exports.Validator_TagGroup),
};
const Validator_GeneratedProps = {
    __tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
exports.DBDef_MacroTag = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'macro-tag',
    entityName: 'MacroTag',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'macro-tag'
    },
    backend: {
        name: 'macro-tag'
    },
    dependencies: {
        __tagIds: {
            dbKey: 'tags',
            fieldType: 'string[]'
        }
    }
};
