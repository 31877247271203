"use strict";
// export const Category_Surgeries = '387713003';
// export const Category_SurgicalHistory = '387713003';
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_Procedure_CategoryOptions_ToLabelsMap = exports.FHIR_Procedure_CategoryOptions = exports.Category_SurgeriesAndSurgicalHistory = void 0;
exports.Category_SurgeriesAndSurgicalHistory = '387713003';
exports.FHIR_Procedure_CategoryOptions = [
    exports.Category_SurgeriesAndSurgicalHistory
];
exports.FHIR_Procedure_CategoryOptions_ToLabelsMap = {
    [exports.Category_SurgeriesAndSurgicalHistory]: 'Surgeries / Surgical History',
};
