"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QScrollWrapper = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const QScrollWrapper = (props) => {
    const { className, flex } = props, _props = __rest(props, ["className", "flex"]);
    const wrapperClass = (0, frontend_1._className)('q-scroll-wrapper', flex ? 'flex' : undefined, className);
    return React.createElement("div", Object.assign({ className: wrapperClass }, _props), props.children);
};
exports.QScrollWrapper = QScrollWrapper;
