"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Section_PathwayStates = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../../../../../../../_entity/pathway-state/frontend");
const styles_1 = require("@app/styles");
const utils_1 = require("../../../../../utils");
class Section_PathwayStates extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        let pathwayStates = frontend_2.ModuleFE_PathwayState.cache.filter(state => state.pathwayId === this.props.pathwayId);
        pathwayStates = (0, ts_common_1.sortArray)(pathwayStates, state => state.name.toLowerCase());
        state.relevantStates = pathwayStates;
        return state;
    }
    __onPathwayStateUpdated(...params) {
        if (!this.props.pathwayId)
            return;
        (0, utils_1.checkIfPathwayElementChanged)(this.state.relevantStates, params, (item) => item.pathwayId === this.props.pathwayId, () => this.reDeriveState(), () => this.reDeriveState());
    }
    render() {
        return React.createElement(frontend_1.TS_CollapsableContainer, { headerRenderer: React.createElement(React.Fragment, null,
                this.state.relevantStates.length,
                " States"), containerRenderer: React.createElement(React.Fragment, null, this.state.relevantStates.map(state => React.createElement("div", { key: state._id, className: 'clickable', onClick: () => {
                    this.props.functions.selectPathwayState(state._id);
                } }, state.name))), customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null) });
    }
}
exports.Section_PathwayStates = Section_PathwayStates;
