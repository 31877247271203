"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ExpressionBuilder = void 0;
const React = require("react");
require("./Component_ExpressionBuilder.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const styles_1 = require("@app/styles");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const ts_styles_1 = require("@nu-art/ts-styles");
const Component_TermsAndExpression_1 = require("./components/terms-and-expressions/Component_TermsAndExpression");
const consts_1 = require("@app/styles/frontend/ui/consts");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const convertExpressionToSchemaFE_1 = require("../../schema-converion-fe/convertExpressionToSchemaFE");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
const TS_EditableItemStatus_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemStatus/TS_EditableItemStatus");
const _entity_1 = require("../../../../_entity");
const healthcare_space_1 = require("../../../healthcare-space");
const consts_2 = require("../../../../../_entity/expression/shared/consts");
const shared_dialogs_1 = require("../../../shared-dialogs");
const permissions_1 = require("../../../../permissions");
const ui_components_2 = require("../../../../../_entity/expression/frontend/ui-components");
const utils_1 = require("../../../../../_entity/expression/shared/utils");
const Component_FocusedEntityRef_1 = require("@nu-art/ts-focused-object/frontend/components/Component_FocusedEntityRef");
const ViewMode_Edit = 'edit';
const ViewMode_View = 'view';
class Component_ExpressionBuilder extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        //######################### Logic #########################
        this.refMenuBuilder = (e) => {
            const menuItems = [{
                    label: 'Add Article Reference',
                    onClick: () => healthcare_space_1.Dialog_ArticleEditor.show(this.createdRefCallback)
                }];
            const id = 'expression-builder-ref-menu';
            const adapter = (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label));
            const pos = { x: e.clientX, y: e.clientY };
            new frontend_1.MenuBuilder(adapter, pos, { x: 0, y: 1 })
                .setId(id)
                .setOnClick((path, item) => {
                var _a;
                frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
            })
                .setOffset({ x: 0, y: 10 })
                .show();
        };
        this.createdRefCallback = async (ref) => {
            var _a;
            await this.state.editable.update('references', [...(_a = this.state.editable.item.references) !== null && _a !== void 0 ? _a : [], ref._id]);
            this.forceUpdate();
        };
        this.deleteRef = async (ref) => {
            var _a, _b, _c;
            const expressionItem = this.state.editable.item;
            const refIndex = (_a = expressionItem.references) === null || _a === void 0 ? void 0 : _a.indexOf(ref._id);
            if (refIndex !== -1) {
                (_b = expressionItem.references) === null || _b === void 0 ? void 0 : _b.splice(refIndex, 1);
                await this.state.editable.update('references', (_c = expressionItem.references) === null || _c === void 0 ? void 0 : _c.filter((reference, index) => index !== refIndex));
                this.forceUpdate();
            }
        };
        this.getQueryFilter = () => {
            const expressionItem = this.state.editable.item;
            if (expressionItem.expressionType === consts_2.ExpressionType_Score.dbKey)
                return (item) => {
                    return item._tagIds.includes(_entity_1.ConfigKeyFE_Scores.get());
                };
            if (expressionItem.expressionType === consts_2.ExpressionType_NormalRange.dbKey)
                return (item) => {
                    var _a;
                    return ((_a = _entity_1.ModuleFE_Value.cache.unique(item.valueId)) === null || _a === void 0 ? void 0 : _a.type) === 'standard-range';
                };
            return;
        };
        this.getValuesTableHeaders = (value) => {
            return [...[...value.data.declarationVarIds, 'Standard Range'].map(i => ({
                    header: (0, ts_common_1.capitalizeAllFirstLetters)(i),
                    widthPx: 50
                }))];
        };
        this.getValuesTableRows = (value) => {
            var _a;
            let values = ((_a = value.data) === null || _a === void 0 ? void 0 : _a.normalValues) || [];
            values = (0, ts_common_1.sortArray)(values, val => (0, ts_common_1._keys)(val.conditions).length === 0 ? 0 : 1);
            return values;
        };
        this.saveAndPublish = async () => {
            const expressionItem = this.state.editable;
            await expressionItem.update('_isDraft', true);
            try {
                this.setState({ savingInstance: true }, async () => {
                    await expressionItem.save();
                    this.props.deselectExpression();
                });
            }
            catch (e) {
                frontend_1.ModuleFE_Toaster.toastError('Saving expression failed');
                if (this.state.savingInstance)
                    this.setState({ savingInstance: false });
                // validationErrors.push(..._keys(e.error.body.result) as string[]);
            }
        };
        this.delete = async () => {
            try {
                shared_dialogs_1.WarningDialog.showDelete({
                    onConfirm: async () => {
                        await this.state.editable.delete();
                        this.props.deselectExpression();
                    },
                    warningMessages: ['Are you sure you want to delete this expression?'],
                    executeButtonText: 'Delete',
                    title: 'Delete',
                });
            }
            catch (err) {
                this.logError(err);
            }
        };
        this.getViewMode = () => {
            const level = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(permissions_1.PermissionKeyFE_ExpressionEdit);
            return level === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess ? ViewMode_Edit : ViewMode_View;
        };
        this.resolveLabelDuplications = (selectedVar) => {
            return _entity_1.ModuleFE_Expression.cache.filter(expression => {
                return expression.associatedVar === selectedVar._id;
            }).map(expression => expression.label);
        };
        //######################### Render #########################
        this.renderAddRefButton = () => {
            if (this.getViewMode() !== ViewMode_Edit)
                return '';
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'References' },
                React.createElement(frontend_1.LL_H_C, { className: 'yes-no-container' },
                    React.createElement(frontend_1.TS_Button, { onClick: this.refMenuBuilder, className: 'add-ref-btn' }, "+")));
        };
        this.addReferences = () => {
            var _a;
            const item = this.state.editable.item;
            return React.createElement(frontend_1.LL_V_L, { className: 'references-list' },
                this.renderAddRefButton(), (_a = item.references) === null || _a === void 0 ? void 0 :
                _a.map((reference, index) => {
                    const dbRef = _entity_1.ModuleFE_Reference.cache.unique(reference);
                    switch (dbRef.type) {
                        case 'article':
                            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { key: `ref-${index}`, label: React.createElement(frontend_1.TS_Space, { width: 50 }) },
                                React.createElement(frontend_1.LL_H_C, { className: 'ref-row' },
                                    React.createElement(frontend_1.TS_Link, { url: dbRef.data.url, target: '_blank' }, (0, _entity_1.resolveReferenceDisplay)(dbRef)),
                                    React.createElement(styles_1.ICONS.x.component, { className: 'clear-icon', onClick: () => this.deleteRef(dbRef) })));
                    }
                }));
        };
        this.renderVarInput = () => {
            const editableExpression = this.state.editable;
            const expressionType = editableExpression.get('expressionType');
            if (!expressionType
                || expressionType === consts_2.ExpressionType_ResolutionFunction.dbKey
                || expressionType === consts_2.ExpressionType_GeneralFunction.dbKey
                || expressionType === consts_2.ExpressionType_GeneralScore.dbKey)
                return '';
            const queryFilter = this.getQueryFilter();
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Associated Variable' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => {
                                var _a, _b;
                                return React.createElement("div", { className: 'expression-builder__view-mode-label' }, (_b = (_a = _entity_1.ModuleFE_Variable.cache.unique(this.state.editable.get('associatedVar'))) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '-');
                            }
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(ui_components_2.DropDown_Variables, { key: expressionType, editable: editableExpression, prop: 'associatedVar', limitItems: 50, showErrorTooltip: true, caret: consts_1.DropDown_DefaultCaret, disabled: !expressionType, queryFilter: queryFilter, onSelected: async (_var) => {
                                    if (_var) {
                                        const _expressionType = consts_2.AllExpressionTypes.find(e => e.dbKey === expressionType).label;
                                        const labelDuplications = this.resolveLabelDuplications(_var);
                                        const label = (0, utils_1.createExpressionLabel)(labelDuplications, _expressionType, _var);
                                        await this.state.editable.updateObj({
                                            associatedVar: _var._id,
                                            label: label
                                        });
                                    }
                                } })
                        }
                    ] }));
        };
        this.renderExpressionRelationsFields = () => {
            var _a;
            const expressionType = this.state.editable.get('expressionType');
            if (!expressionType)
                return '';
            const disabled = expressionType !== consts_2.ExpressionType_GeneralFunction.dbKey && expressionType !== consts_2.ExpressionType_GeneralScore.dbKey;
            const className = (0, frontend_1._className)(disabled && 'disabled');
            return React.createElement(React.Fragment, null,
                this.renderVarInput(),
                React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Expression Name' },
                    React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                            {
                                key: ViewMode_View,
                                renderer: React.createElement("div", { className: 'expression-builder__view-mode-label' }, (_a = this.state.editable.get('label')) !== null && _a !== void 0 ? _a : '-')
                            },
                            {
                                key: ViewMode_Edit,
                                renderer: () => React.createElement(ui_components_1.DefaultEditor_InputText, { ignoreError: disabled, showErrorTooltip: true, editable: this.state.editable, prop: 'label', className: className, value: this.state.editable.get('label'), onChange: async (expressionName) => {
                                        if (expressionName.length)
                                            await this.state.editable.update('label', expressionName);
                                    }, disabled: disabled, placeholder: 'Enter expression name' })
                            }
                        ] })));
        };
        this.renderYesNo = () => {
            const item = this.state.editable.item;
            const className = (0, frontend_1._className)('yes-no', item.isEvidenceBased ? 'yes' : 'no');
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Evidence-based' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => React.createElement("div", { className: 'expression-builder__view-mode-label' }, item.isEvidenceBased ? 'Yes' : 'No')
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(frontend_1.LL_H_C, { className: 'yes-no-container' },
                                React.createElement(frontend_1.LL_V_L, { className: className, onClick: async () => {
                                        await this.state.editable.update('isEvidenceBased', !item.isEvidenceBased);
                                        this.forceUpdate();
                                    } }, item.isEvidenceBased ? 'Yes' : 'No'))
                        }
                    ] }));
        };
        this.renderAdvisorFormKey = () => {
            var _a;
            const item = this.state.editable.item;
            if (item.expressionType !== consts_2.ExpressionType_Score.dbKey)
                return '';
            const className = (0, frontend_1._className)(((_a = this.state.validationErrors) === null || _a === void 0 ? void 0 : _a.includes('formKey')) && 'validation-error');
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Advisor Form Key' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => {
                                var _a;
                                const advisorFormKey = this.state.editable.get('advisorFormKey');
                                return React.createElement("div", { className: 'expression-builder__view-mode-label' }, (_a = (advisorFormKey && advisorFormKey.length)) !== null && _a !== void 0 ? _a : '-');
                            }
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(ui_components_1.DefaultEditor_InputText_Optional, { editable: this.state.editable, prop: 'advisorFormKey', type: 'text', showErrorTooltip: true, placeholder: 'Enter advisor form key', value: item.advisorFormKey, className: className })
                        }
                    ] }));
        };
        this.renderExpressionMetaData = () => {
            return React.createElement(frontend_1.TS_Card, { className: 'score-info' },
                this.renderExpressionTypeDropDown(),
                this.renderExpressionRelationsFields(),
                this.renderAdvisorFormKey(),
                this.renderYesNo(),
                this.addReferences());
        };
        this.renderExpressionTypeDropDown = () => {
            const expressionType = this.state.editable.get('expressionType');
            const disabled = expressionType === consts_2.ExpressionType_ResolutionFunction.dbKey;
            const editableExpression = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Expression Type' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => {
                                var _a, _b;
                                return React.createElement("div", { className: 'expression-builder__view-mode-label' }, (_b = (_a = consts_2.AllExpressionTypes.find(exprType => exprType.dbKey === editableExpression.get('expressionType'))) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : 'Missing Type');
                            }
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => (React.createElement(ui_components_2.DropDown_ExpressionType, { editable: editableExpression, prop: 'expressionType', disabled: disabled, showErrorTooltip: true, onSelected: async (value) => {
                                    if (value === expressionType)
                                        return;
                                    await this.state.editable.updateObj({
                                        'expressionType': value,
                                        'associatedVar': undefined,
                                        'label': undefined,
                                    });
                                } }))
                        }
                    ] }));
        };
        this.renderLabRangesTable = () => {
            const item = this.state.editable.item;
            const associatedVar = _entity_1.ModuleFE_Variable.cache.unique(item.associatedVar);
            const value = _entity_1.ModuleFE_Value.cache.unique(associatedVar === null || associatedVar === void 0 ? void 0 : associatedVar.valueId);
            if (!associatedVar)
                return '';
            if (!value || value.type !== _entity_1.valueType_StandardRange)
                return '';
            return React.createElement(frontend_1.LL_H_C, { className: 'match_width value-table-container' },
                React.createElement(frontend_1.TS_Card, { className: 'value-table' },
                    React.createElement("div", { className: 'subtitle title' },
                        (0, ts_common_1.capitalizeAllFirstLetters)(associatedVar.name),
                        " - Standard Ranges"),
                    React.createElement("div", { className: 'subtitle' },
                        "Full Range - ",
                        value.data.fullRange[0],
                        " - ",
                        value.data.fullRange[1],
                        " "),
                    this.renderValuesTables(value)));
        };
        this.renderValuesTables = (value) => {
            return React.createElement(frontend_1.TS_Table, { cellRenderer: (prop, item, index) => {
                    var _a, _b;
                    switch (prop) {
                        case 'Standard Range':
                            return React.createElement("div", null, `${(_a = item.range) === null || _a === void 0 ? void 0 : _a[0]} - ${(_b = item.range) === null || _b === void 0 ? void 0 : _b[1]}`);
                        default: {
                            if (!item.conditions[prop])
                                return '-';
                        }
                    }
                }, headerRenderer: (header) => {
                    var _a, _b;
                    return React.createElement(React.Fragment, null, (_b = (_a = _entity_1.ModuleFE_Variable.cache.unique(header)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Normal Range');
                }, header: this.getValuesTableHeaders(value), rows: this.getValuesTableRows(value) });
        };
        this.renderEditButtons = () => {
            var _a, _b;
            const hasChanges = this.state.editable.hasChanges();
            const fileNameToSave = `${ts_common_1.DateTimeFormat_yyyyMMDD.format()}_${(_b = (_a = this.state.editable.get('label')) === null || _a === void 0 ? void 0 : _a.replace(/\s/g, () => '_')) !== null && _b !== void 0 ? _b : this.state.editable.get('_id')}`;
            return React.createElement(React.Fragment, null,
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => React.createElement(React.Fragment, null)
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(React.Fragment, null,
                                React.createElement(frontend_1.TS_BusyButton, { className: 'util-btn', disabled: this.state.savingInstance, onClick: async () => {
                                        return this.saveAndPublish();
                                    } }, "Save & Publish"),
                                React.createElement(frontend_1.TS_BusyButton, { className: 'util-btn', disabled: this.state.savingInstance, onClick: async () => {
                                        this.setState({ savingInstance: true }, async () => {
                                            await this.state.editable.save();
                                        });
                                    } }, "Save"),
                                React.createElement(frontend_1.TS_BusyButton, { disabled: !this.state.editable.item._id || this.state.savingInstance, onClick: this.delete, className: 'util-btn' }, "Delete"))
                        }
                    ] }),
                React.createElement(frontend_1.TS_BusyButton, { onClick: async () => {
                        if (!hasChanges)
                            return this.props.deselectExpression();
                        shared_dialogs_1.WarningDialog.showDelete({
                            onConfirm: () => this.props.deselectExpression(),
                            warningMessages: ['Changes won\'t be saved, are you sure you wanna quit?'],
                            title: undefined,
                            executeButtonText: 'Delete',
                        });
                    }, className: 'util-btn' }, !hasChanges ? 'Close' : 'Cancel'),
                React.createElement(frontend_1.TS_BusyButton, { className: 'util-btn', disabled: !this.state.editable.get('_id'), onClick: async () => {
                        const convertedExpression = (0, convertExpressionToSchemaFE_1.convertExpressionToSchemaFE)(this.state.editable.item);
                        frontend_1.ModuleFE_Thunderstorm.downloadFile({
                            fileName: fileNameToSave,
                            content: (0, ts_common_1.__stringify)(convertedExpression, true),
                            mimeType: 'application/json'
                        });
                    } },
                    React.createElement(ts_styles_1.TS_Icons.download.component, null)));
        };
        this.renderHeader = () => {
            var _a;
            const label = (_a = this.state.editable.item.label) !== null && _a !== void 0 ? _a : 'New Expression';
            return React.createElement(frontend_1.LL_H_C, { className: 'header' },
                React.createElement(frontend_1.LL_H_C, { className: 'flex__grow', style: { gap: '10px' } },
                    React.createElement(frontend_1.TS_Button, { className: 'util-btn', onClick: () => this.props.deselectExpression() }, '< List'),
                    React.createElement("div", { className: 'main-title' }, label),
                    React.createElement(frontend_1.LL_H_C, { className: 'status-components' },
                        React.createElement(TS_EditableItemStatus_1.TS_EditableItemStatus, { editable: this.state.editable }),
                        React.createElement(Component_FocusedEntityRef_1.Component_FocusedEntityRef, { focusedEntities: [{ dbKey: _entity_1.DBDef_Expression.dbKey, itemId: this.state.editable.item._id }] }))),
                React.createElement(frontend_1.LL_H_C, { className: 'buttons-container' }, this.renderEditButtons()));
        };
        this.renderTermsAndExpression = () => {
            return React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                    {
                        key: ViewMode_View,
                        renderer: () => React.createElement(Component_TermsAndExpression_1.Component_TermsAndExpression, { viewMode: true, editable: this.state.editable })
                    },
                    {
                        key: ViewMode_Edit,
                        renderer: () => React.createElement(Component_TermsAndExpression_1.Component_TermsAndExpression, { editable: this.state.editable })
                    }
                ] });
        };
    }
    componentDidMount() {
        window.onbeforeunload = (event) => {
            const hasChanges = this.state.editable.hasChanges();
            if (!hasChanges)
                return;
            event.preventDefault();
            return (event.returnValue = '');
        };
    }
    componentWillUnmount() {
        window.onbeforeunload = null;
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.savingInstance = state.editable.isSaving();
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'expression-builder' },
            this.renderHeader(),
            React.createElement(frontend_1.Grid, { className: 'builder-grid' },
                this.renderExpressionMetaData(),
                this.renderLabRangesTable(),
                this.renderTermsAndExpression()));
    }
}
exports.Component_ExpressionBuilder = Component_ExpressionBuilder;
