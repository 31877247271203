"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopicAllowedCollectionNames = exports.allDBTypes = exports.PageKey_ID = void 0;
const hcs_1 = require("@app/hcs");
const shared_1 = require("@app/dp/shared");
const pathway_1 = require("@app/pathway");
exports.PageKey_ID = '_id';
exports.allDBTypes = [
    'AttributeSet',
    'Tag',
    'Variable',
    'DiseaseProfile',
    'MeasurementUnit',
    'Value',
    'Reference',
    'DiseaseView',
    'Book',
    'MultiVar',
    'DiseaseValue',
    'Pathway',
    'PathwayState',
    'PathwayTransition'
];
exports.TopicAllowedCollectionNames = [
    hcs_1.DBDef_Value,
    hcs_1.DBDef_Variable,
    hcs_1.DBDef_Tag,
    hcs_1.DBDef_Reference,
    shared_1.DBDef_MultiVar,
    shared_1.DBDef_DiseaseProfile,
    shared_1.DBDef_DiseaseValue,
    hcs_1.DBDef_AttributeSet,
    hcs_1.DBDef_NLPData,
    pathway_1.DBDef_Pathway,
    pathway_1.DBDef_PathwayState,
    pathway_1.DBDef_PathwayTransition,
].map(dbDef => dbDef.dbKey);
