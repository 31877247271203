"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_PathwayEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@nu-art/ts-workspace/frontend");
const workspace_config_1 = require("./workspace-config");
require("./Page_PathwayEditor.scss");
const PathwaySelector_Search_1 = require("./selectors/PathwaySelector_Search");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_3 = require("@nu-art/permissions/frontend");
const Renderer_PathwayEditor_1 = require("./selectors/renderers/renderers-pathway/Renderer_PathwayEditor");
const Renderer_PathwayStateDetails_1 = require("./selectors/renderers/renderers-pathway-state/Renderer_PathwayStateDetails/Renderer_PathwayStateDetails");
const Renderer_PathwayStateEditor_1 = require("./selectors/renderers/renderers-pathway-state/Renderer_PathwayStateEditor");
const Renderer_PathwayDetails_1 = require("./selectors/renderers/renderers-pathway/Renderer_PathwayDetails/Renderer_PathwayDetails");
const Renderer_PathwayTransitionEditor_1 = require("./selectors/renderers/renderers-pathway-transition/Renderer_PathwayTransitionEditor");
const Renderer_PathwayTransitionDetails_1 = require("./selectors/renderers/renderers-pathway-transition/Renderer_PathwayTransitionDetails");
const consts_1 = require("./consts");
const Page_BaseManagerUI_1 = require("@app/styles/frontend/ui/ui-manager/Page/Page_BaseManagerUI");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const Presenter_GraphViewWrapper_1 = require("./presenters/Presenter_GraphViewWrapper");
const dispatchers_1 = require("../Component_ResolutionFunctionEditor/dispatchers");
const _entity_1 = require("../../_entity");
const permissions_1 = require("../../permissions");
const module_pack_1 = require("../../module-pack");
const frontend_4 = require("@app/styles/frontend");
const Component_ResolutionFunctionEditor_1 = require("../Component_ResolutionFunctionEditor/Component_ResolutionFunctionEditor");
const Pathway_GraphView_1 = require("../Pathway_GraphView/Pathway_GraphView");
const utils_1 = require("./utils");
const frontend_5 = require("@app/dp/frontend");
const frontend_6 = require("@app/hcs/_entity/variable/frontend");
const frontend_7 = require("@app/hcs/_entity/expression/frontend");
const Panel_ExpressionSidePanel_1 = require("@app/hcs/frontend/ui/expressions/ui/expression-builder/components/expression-side-panel/Panel_ExpressionSidePanel");
const consts_2 = require("../../../_entity/pathway/shared/consts");
const styles_1 = require("@app/styles");
const viewKey = 'view';
const editKey = 'edit';
const EditorKey_Pathway = 'pathway-editor';
class Page_PathwayEditor extends Page_BaseManagerUI_1.Page_BaseManagerUI {
    constructor(p) {
        super(p);
        // ######################## Props ########################
        this.workspace = () => ({
            id: EditorKey_Pathway,
            key: workspace_config_1.workspaceKey_ManagerPathway,
            instances: {
                selector: this.renderSelector.bind(this),
                presenter: this.renderPresenter.bind(this),
            },
            renderers: {
                horizontalSpace: frontend_2.TS_HorizontalWorkspace,
            }
        });
        this.forcePathwayEdit = false;
        this.permissionKey_Edit = permissions_1.PermissionKeyFE_PathwayEdit;
        // ######################## Lifecycle ########################
        this.__onPathwayStateSelected = (pathwayStateId) => {
            const pathwayState = _entity_1.ModuleFE_PathwayState.cache.unique(pathwayStateId);
            if (!pathwayState)
                throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway state for id ${pathwayStateId}`);
            this.setState({
                selectedPathwayState: new frontend_1.EditableDBItemV3(pathwayState, _entity_1.ModuleFE_PathwayState),
                selectedPathwayTransition: undefined,
                editResolutionFunction: false
            });
        };
        this.__onPathwayTransitionSelected = (pathwayTransitionId) => {
            const pathwayTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(pathwayTransitionId);
            if (!pathwayTransition)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find transition for id ${pathwayTransitionId}`);
            this.setState({
                selectedPathwayState: undefined,
                selectedPathwayTransition: new frontend_1.EditableDBItemV3(pathwayTransition, _entity_1.ModuleFE_PathwayTransition),
                editMode: false,
                editResolutionFunction: false,
            });
        };
        // ######################## Logic ########################
        this.onCreateNewPathway = () => {
            this.setState({
                selectedPathway: new frontend_1.EditableDBItemV3({}, _entity_1.ModuleFE_Pathway),
                editMode: true,
            });
        };
        this.getNewPathwayState = (pathwayId) => {
            return new frontend_1.EditableDBItemV3((0, consts_1.Empty_PathwayState)(pathwayId), _entity_1.ModuleFE_PathwayState);
        };
        this.functions = {
            selectPathway: (pathwayId, editMode) => {
                this.onSearchResultSelected(pathwayId, Page_PathwayEditor.SearchableItem_Pathway.type, {
                    editMode,
                    selectedPathwayState: undefined,
                    selectedPathwayTransition: undefined,
                });
            },
            selectPathwayState: (state, editMode = false) => {
                let editable;
                if (typeof state === 'string') {
                    const pathwayState = _entity_1.ModuleFE_PathwayState.cache.unique(state);
                    if (!pathwayState)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway state for id ${state}`);
                    editable = new frontend_1.EditableDBItemV3(pathwayState, _entity_1.ModuleFE_PathwayState);
                }
                else
                    editable = state;
                this.setState({
                    selectedPathwayState: editable,
                    selectedPathwayTransition: undefined,
                    editMode: editMode,
                });
            },
            selectPathwayTransition: (transition, editMode = false) => {
                let editable;
                if (typeof transition === 'string') {
                    const pathwayTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(transition);
                    if (!pathwayTransition)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway transition for id ${transition}`);
                    editable = new frontend_1.EditableDBItemV3(pathwayTransition, _entity_1.ModuleFE_PathwayTransition);
                }
                else
                    editable = transition;
                this.setState({ selectedPathwayTransition: editable, editMode: editMode });
            },
            selectResolutionFunctionEditor: async (transitionId) => {
                var _b, _c;
                let sourceState;
                let updateTransition = {};
                if (transitionId) {
                    const transition = _entity_1.ModuleFE_PathwayTransition.cache.unique(transitionId);
                    if (!transition)
                        throw new ts_common_1.MUSTNeverHappenException(`transition with id ${transitionId} not found`);
                    sourceState = _entity_1.ModuleFE_PathwayState.cache.unique((_b = transition.sourceIds) === null || _b === void 0 ? void 0 : _b[0]);
                    if (!sourceState)
                        throw new ts_common_1.MUSTNeverHappenException(`couldn't find pathway state with id ${(_c = transition.sourceIds) === null || _c === void 0 ? void 0 : _c[0]}`);
                    if (!this.state.selectedPathwayTransition)
                        updateTransition = {
                            selectedPathwayTransition: new frontend_1.EditableDBItemV3(transition, _entity_1.ModuleFE_PathwayTransition)
                        };
                }
                this.setState(Object.assign(Object.assign({}, updateTransition), { selectedPathwayState: sourceState ? new frontend_1.EditableDBItemV3(sourceState, _entity_1.ModuleFE_PathwayState) : this.state.selectedPathwayState, editResolutionFunction: true }));
            },
            deselectPathway: () => {
                this.clearSelectedResult();
                this.setState({
                    editResolutionFunction: undefined,
                    editMode: undefined,
                    selectedResult: undefined,
                    selectedPathway: undefined,
                    selectedPathwayState: undefined,
                    selectedPathwayTransition: undefined,
                });
            },
            deselectPathwayState: (editMode = false) => {
                this.setState({
                    editMode: editMode,
                    editResolutionFunction: false,
                    selectedPathwayState: undefined,
                    selectedPathwayTransition: undefined,
                });
            },
            deselectPathwayTransition: () => {
                this.setState({
                    editMode: false,
                    selectedPathwayTransition: undefined,
                });
            },
            deselectResolutionFunctionEditor: async () => {
                //close panel if needed
                dispatchers_1.dispatch_showResolutionFunctionEditorPanel.dispatchUI({});
                this.setState({
                    editResolutionFunction: false,
                    expressionEditable: undefined
                });
            },
            openPathwayEdit: () => {
                this.setState({
                    editMode: true,
                    selectedPathwayState: undefined,
                    selectedPathwayTransition: undefined,
                });
            },
        };
        this.getModeKey = () => {
            return this.state.editMode ? editKey : viewKey;
        };
        this.toggleEdit = () => {
            this.setState({ editMode: !this.state.editMode });
        };
        // ######################## Render - Presenters ########################
        this.renderGraphView = () => {
            var _b;
            return React.createElement(Presenter_GraphViewWrapper_1.Presenter_GraphViewWrapper, { pathwayId: (_b = this.state.selectedPathway) === null || _b === void 0 ? void 0 : _b.item._id, editablePathwayState: this.state.selectedPathwayState, editablePathwayTransition: this.state.selectedPathwayTransition });
        };
        // this.logger.setMinLevel(LogLevel.Verbose);
    }
    __onPathwayUpdated(...params) {
        var _b, _c;
        const selectedPathwayId = (_c = (_b = this.state.selectedPathway) === null || _b === void 0 ? void 0 : _b.item) === null || _c === void 0 ? void 0 : _c._id;
        if (!selectedPathwayId)
            return;
        (0, utils_1.basicSyncEventRefresh)(selectedPathwayId, params, () => this.functions.selectPathway(selectedPathwayId), () => this.functions.deselectPathway());
    }
    __onFocusResolutionFunction(transitionId, editable, postSave) {
        const transition = _entity_1.ModuleFE_PathwayTransition.cache.unique(transitionId);
        if (postSave && (this.state.selectedPathwayTransition && this.state.selectedPathwayTransition.item._id !== transitionId))
            return;
        if (!transition)
            throw new ts_common_1.MUSTNeverHappenException(`cannot find transition with Id ${transitionId}`);
        this.setState({
            selectedPathwayTransition: new frontend_1.EditableDBItemV3(transition, _entity_1.ModuleFE_PathwayTransition),
            expressionEditable: editable
        });
    }
    shouldReDeriveState(nextProps) {
        return true;
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        const selectedResult = state.selectedResult;
        if (!selectedResult)
            return state;
        let selectedPathwayId;
        let selectedPathwayStateId;
        let selectedPathwayTransitionId;
        switch (selectedResult.type) {
            case Page_PathwayEditor.SearchableItem_Pathway.type:
                selectedPathwayId = selectedResult.id;
                break;
            default:
                // return state;
                throw new ts_common_1.ImplementationMissingException(`Missing implementation for search item type ${selectedResult.type}`);
        }
        //Set selected pathway
        if (selectedPathwayId) {
            const selectedPathway = _entity_1.ModuleFE_Pathway.cache.unique(selectedPathwayId);
            if (!selectedPathway)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway for id ${selectedPathwayId}`);
            state.selectedPathway = new frontend_1.EditableDBItemV3(selectedPathway, _entity_1.ModuleFE_Pathway);
        }
        else {
            state.selectedPathway = undefined;
        }
        //Set selected pathway state
        if (selectedPathwayStateId) {
            const selectedPathwayState = _entity_1.ModuleFE_PathwayState.cache.unique(selectedPathwayStateId);
            if (!selectedPathwayState)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway state for id ${selectedPathwayStateId}`);
            state.selectedPathwayState = new frontend_1.EditableDBItemV3(selectedPathwayState, _entity_1.ModuleFE_PathwayState);
        }
        else {
            state.selectedPathwayState = undefined;
        }
        //Set selected pathway transition
        if (selectedPathwayTransitionId) {
            const selectedPathwayTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(selectedPathwayTransitionId);
            if (!selectedPathwayTransition)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway transition for id ${selectedPathwayTransitionId}`);
            state.selectedPathwayTransition = new frontend_1.EditableDBItemV3(selectedPathwayTransition, _entity_1.ModuleFE_PathwayTransition);
        }
        else {
            state.selectedPathwayTransition = undefined;
        }
        return state;
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.forcePathwayEdit)
            this.forcePathwayEdit = false;
        if (prevState.editResolutionFunction && !this.state.editResolutionFunction) {
            // Close panels if needed
            dispatchers_1.dispatch_showResolutionFunctionEditorPanel.dispatchUI({});
        }
    }
    // ######################## Render - Selectors ########################
    renderSelector() {
        if (this.state.editResolutionFunction)
            return this.renderResolutionFunctionEditor();
        if (this.state.selectedPathwayTransition)
            return this.pathwayTransitionEditor(this.state.selectedPathwayTransition);
        if (this.state.selectedPathwayState)
            return this.pathwayStateEditor(this.state.selectedPathwayState);
        if (this.state.selectedPathway)
            return this.pathwayEditor(this.state.selectedPathway);
        return React.createElement(PathwaySelector_Search_1.PathwaySelector_Search, { onCreateNewPathwayCallback: this.onCreateNewPathway, selectedItem: this.state.selectedResult, onSearchItemSelected: this.onSearchResultSelected });
    }
    pathwayEditor(selectedPathway) {
        return React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                {
                    key: viewKey,
                    renderer: () => React.createElement(Renderer_PathwayDetails_1.Renderer_PathwayDetails, { editable: selectedPathway, toggleMode: this.toggleEdit, functions: this.functions, addNewPathwayState: () => {
                            this.setState({ selectedPathwayState: this.getNewPathwayState(selectedPathway.item._id) });
                        } })
                },
                {
                    key: editKey,
                    permissionKey: this.permissionKey_Edit,
                    renderer: () => React.createElement(Renderer_PathwayEditor_1.Renderer_PathwayEditor, { editable: selectedPathway, toggleMode: this.toggleEdit, functions: this.functions, onCancel: () => {
                            const selectedPathway = this.state.selectedPathway;
                            if (!(selectedPathway === null || selectedPathway === void 0 ? void 0 : selectedPathway.item._id))
                                return this.functions.deselectPathway();
                            this.onSearchResultSelected(selectedPathway.item._id, Page_PathwayEditor.searchItemKey_Pathway);
                            this.setState({ editMode: false });
                        } })
                }
            ] });
    }
    pathwayStateEditor(selectedPathwayState) {
        return React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                {
                    key: viewKey,
                    renderer: () => React.createElement(Renderer_PathwayStateDetails_1.Renderer_PathwayStateDetails, { pathwayState: selectedPathwayState, toggleEdit: this.toggleEdit, functions: this.functions })
                },
                {
                    key: editKey,
                    permissionKey: this.permissionKey_Edit,
                    renderer: () => React.createElement(Renderer_PathwayStateEditor_1.Renderer_PathwayStateEditor, { editable: selectedPathwayState, functions: this.functions, toggleMode: this.toggleEdit })
                }
            ] });
    }
    pathwayTransitionEditor(selectedPathwayTransition) {
        return React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                {
                    key: viewKey,
                    renderer: () => React.createElement(Renderer_PathwayTransitionDetails_1.Renderer_PathwayTransitionDetails, { pathwayTransition: selectedPathwayTransition, functions: this.functions, toggleEdit: this.toggleEdit })
                },
                {
                    key: editKey,
                    permissionKey: this.permissionKey_Edit,
                    renderer: () => React.createElement(Renderer_PathwayTransitionEditor_1.Renderer_PathwayTransitionEditor, { pathwayTransition: selectedPathwayTransition, toggleEdit: this.toggleEdit, functions: this.functions })
                }
            ] });
    }
    renderResolutionFunctionEditor() {
        var _b;
        let pathwayTransitions = _entity_1.ModuleFE_PathwayTransition.cache.filter(transition => { var _b, _c; return transition.sourceIds[0] === ((_c = (_b = this.state.selectedPathwayState) === null || _b === void 0 ? void 0 : _b.item) === null || _c === void 0 ? void 0 : _c._id); });
        pathwayTransitions = (0, ts_common_1.sortArray)(pathwayTransitions, item => item.priorityIndex);
        return React.createElement(Component_ResolutionFunctionEditor_1.Component_ResolutionFunctionEditor, { selectedTransitionId: (_b = this.state.selectedPathwayTransition) === null || _b === void 0 ? void 0 : _b.get('_id'), pathwayTransitionIds: pathwayTransitions.map(_transition => _transition._id), functions: this.functions });
        // return <Component_SwitchView
        // 	mode={editKey}
        // 	modes={[{
        // 		key: editKey,
        // 		permissionKey: this.permissionKey_Edit,
        // 		renderer: () => <Component_ResolutionFunctionEditor
        // 			selectedTransitionId={this.state.selectedPathwayTransition?.get('_id')}
        // 			pathwayTransitionIds={pathwayTransitions.map(_transition => _transition._id)}
        // 			functions={this.functions}
        // 		/>
        // 	}]}/>;
    }
    renderResolutionFunctionUtils() {
        if (!this.state.expressionEditable)
            return React.createElement(React.Fragment, null);
        return React.createElement(Panel_ExpressionSidePanel_1.Panel_ExpressionSidePanel, { editable: this.state.expressionEditable, defaultTab: Panel_ExpressionSidePanel_1.UtilsTab_PrettyPrint, additionalTabs: [{
                    title: 'Pathway',
                    content: this.renderGraphView(),
                    uid: 'pathway-renderer'
                }] });
    }
    renderPresenter() {
        if (this.state.editResolutionFunction)
            return this.renderResolutionFunctionUtils();
        return this.renderGraphView();
    }
}
_a = Page_PathwayEditor;
// ######################## Static ########################
Page_PathwayEditor.Route = {
    key: EditorKey_Pathway,
    path: 'pathway-v2',
    Component: _a,
    enabled: (0, frontend_3.RoutePermissions)(permissions_1.PermissionKeyFE_PathwayView),
    modulesToAwait: [...module_pack_1.ModulePackFE_Pathway, _entity_1.ModuleFE_Action, frontend_6.ModuleFE_Variable, frontend_7.ModuleFE_Expression, frontend_5.ModuleFE_DiseaseProfile],
};
Page_PathwayEditor.searchItemKey_Pathway = _entity_1.ModuleFE_Pathway.dbDef.dbKey;
Page_PathwayEditor.SearchableItem_Pathway = {
    module: _entity_1.ModuleFE_Pathway,
    type: Page_PathwayEditor.searchItemKey_Pathway,
    route: Page_PathwayEditor.Route,
    entityName: _entity_1.ModuleFE_Pathway.dbDef.entityName,
    entityLabel: 'Pathway',
    groupId: ['Advisor'],
    mapper: (item) => [item.label],
    tags: [],
    resultRenderer: (item) => {
        return React.createElement(Pathway_GraphView_1.Pathway_GraphView, { pathwayId: item });
    },
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_4.Renderer_SearchResult, { itemId: item._id, itemKey: Page_PathwayEditor.searchItemKey_Pathway, itemLabel: item.label, entityLabel: 'Pathway', hierarchy: 'Pathways', selected: selected, onClickCallback: onClickCallback });
    }
};
Page_PathwayEditor.SearchableItem_PathwayV2 = Object.assign(Object.assign({}, Page_PathwayEditor.SearchableItem_Pathway), { renderer: (item, selected, onClickCallback) => {
        const type = consts_2.PathwayTypeToLabel[item.type];
        return React.createElement(frontend_4.Renderer_SearchResult, { itemId: item._id, itemKey: Page_PathwayEditor.searchItemKey_Pathway, itemLabel: item.label, labelIcon: item.underConstruction ?
                React.createElement(styles_1.ICONS.under_construction.component, Object.assign({}, frontend_1.openContent.tooltip.top('under-construction', React.createElement(React.Fragment, null, "Protocol under construction"), { offset: 5 }))) : null, hierarchy: type, selected: selected, onClickCallback: onClickCallback });
    } });
Page_PathwayEditor.defaultProps = {
    searchItems: [Page_PathwayEditor.SearchableItem_PathwayV2]
};
exports.Page_PathwayEditor = Page_PathwayEditor;
