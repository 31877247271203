"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_PathwayState = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
const validator_1 = require("../../../_enum/pathway-state-type/shared/validator");
const Validator_TimeConstraint = (instance) => {
    if (!instance)
        return;
    return (0, ts_common_1.tsValidateResult)(instance, {
        timeRange: (0, ts_common_1.tsValidateTimeRange)(false),
        index: (0, ts_common_1.tsValidateValue)(types_1.PreconditionIndex)
    });
};
const Validator_ModifiableProps = {
    name: (0, ts_common_1.tsValidateString)(),
    type: validator_1.Validator_PathwayStateType,
    pathwayId: ts_common_1.tsValidateUniqueId,
    dds: (0, ts_common_1.tsValidateArray)({ diseaseProfileId: ts_common_1.tsValidateUniqueId, probability: (0, ts_common_1.tsValidateIsInRange)([[0, 1]], false) }),
    logTemplates: (0, ts_common_1.tsValidateArray)({
        templateId: ts_common_1.tsValidateUniqueId,
        aliases: (0, ts_common_1.tsValidateDynamicObject)((0, ts_common_1.tsValidateString)(), (0, ts_common_1.tsValidateString)(), false)
    }, false),
    preconditions: (0, ts_common_1.tsValidateArray)({
        orderId: ts_common_1.tsValidateUniqueId,
        timeConstraint: Validator_TimeConstraint,
        expressionId: ts_common_1.tsValidateOptionalId
    }, false),
    expressionIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    expressionV2Ids: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    initial: (0, ts_common_1.tsValidateBoolean)(false),
};
const Validator_GeneratedProps = {
    __orderIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId)
};
exports.DBDef_PathwayState = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.3', '1.0.2', '1.0.1', '1.0.0'],
    dbKey: 'pathway-states',
    entityName: 'PathwayState',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'pathway-state'
    },
    backend: {
        name: 'pathway-states'
    },
    dependencies: {
        'pathwayId': {
            dbKey: 'pathway',
            fieldType: 'string'
        },
        'expressionIds': {
            dbKey: 'expressions',
            fieldType: 'string[]'
        },
        '__orderIds': {
            dbKey: 'orders',
            fieldType: 'string[]'
        },
        'expressionV2Ids': {
            dbKey: 'expressions',
            fieldType: 'string[]'
        },
    }
};
