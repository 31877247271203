"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QLoader = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
require("./QLoader.scss");
/**
 * @category Components
 */
class QLoader extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps) {
        return;
    }
    render() {
        const className = (0, frontend_1._className)('spinner', this.props.type || 'basic');
        return React.createElement("div", { className: 'qloader' },
            React.createElement("div", { className: className }));
    }
}
exports.QLoader = QLoader;
