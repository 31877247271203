"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_MultiVar = exports.ModuleFE_MultiVar_Class = exports.dispatch_onMultiVarChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("./shared");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const frontend_3 = require("../../disease-value/frontend");
exports.dispatch_onMultiVarChanged = new types_1.ThunderDispatcherV3('__onMultiVarUpdated');
class ModuleFE_MultiVar_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_MultiVar, exports.dispatch_onMultiVarChanged);
        this.__searchFilterMapper = (item) => {
            const variable = frontend_2.ModuleFE_Variable.cache.unique(item._firstVarId);
            const nlpData = frontend_2.ModuleFE_NLPData.cache.unique(variable.nlpDataId);
            return (0, ts_common_1.filterInstances)([variable.name, nlpData.synonyms.join('  ')]);
        };
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName, results: items.map(i => {
                    const firstVariable = frontend_2.ModuleFE_Variable.cache.unique(i._firstVarId);
                    return { _id: i._id, uiLabel: firstVariable.name };
                })
            }]);
        this.OnMultiVarDeleted = async (response) => {
            var _a;
            //Update known changes
            await this.onEntryDeleted(response.deletedMultiVar);
            if ((_a = response.deletedDiseaseValues) === null || _a === void 0 ? void 0 : _a.length)
                await frontend_3.ModuleFE_DiseaseValue.onEntriesDeleted(response.deletedDiseaseValues);
        };
        this.OnMultiVarColumnDeleted = async (response) => {
            //Update known changes
            await frontend_3.ModuleFE_DiseaseValue.onEntriesDeleted(response.diseaseValues);
            await this.onEntriesUpdated([response.multiVar]);
        };
        this._v1 = {
            deleteMultiVarColumn: (0, frontend_1.apiWithBody)(shared_1.ApiDef_MultiVar._v1.deleteMultiVarColumn, this.OnMultiVarColumnDeleted),
            deleteMultiVar: (0, frontend_1.apiWithQuery)(shared_1.ApiDef_MultiVar._v1.deleteMultiVar, this.OnMultiVarDeleted),
            getAttributeList: (0, frontend_1.apiWithQuery)(shared_1.ApiDef_MultiVar._v1.getAttributeList)
        };
    }
}
exports.ModuleFE_MultiVar_Class = ModuleFE_MultiVar_Class;
exports.ModuleFE_MultiVar = new ModuleFE_MultiVar_Class();
