"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEvaluationLogicType = exports.convertAtomLevel = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const range_1 = require("./evaluation-logics/range");
const set_1 = require("./evaluation-logics/set");
const self_1 = require("./evaluation-logics/self");
const generic_conversion_1 = require("./evaluation-logics/generic-conversion");
const not_equal_1 = require("./evaluation-logics/not-equal");
const normal_range_1 = require("./evaluation-logics/normal-range");
const exists_1 = require("./evaluation-logics/exists");
const consts_1 = require("./consts");
const utils_1 = require("./utils");
const shared_1 = require("../../../../_entity/expression/shared");
const consts_2 = require("../../../../_entity/expression/shared/consts");
const convertAtomLevel = (dbAtom, dependencies) => {
    const convertedAtom = {};
    // Assign the convertedAtom metadata to the atom
    Object.assign(convertedAtom, resolveAtomMetaData(dbAtom, dependencies));
    // Handle atom conversion in case of single and multiple case
    if (dbAtom.cases.length > 1 || dbAtom.cases[0].value)
        conversionAtoms(dbAtom, convertedAtom, dependencies);
    else
        singleCaseAtoms(dbAtom, convertedAtom, dependencies);
    return convertedAtom;
};
exports.convertAtomLevel = convertAtomLevel;
// ########################## Metadata Resolving ##########################
const resolveAtomMetaData = (dbAtom, dependencies) => {
    var _a, _b, _c;
    const convertedAtomMetadata = {};
    // Resolve the atom type, can be expression (general score or function) or a hcs variable
    convertedAtomMetadata.type = resolveAtomType(dbAtom);
    // Add comment if exists, currently UI doesn't support
    if ((0, ts_common_1.exists)(dbAtom.comment))
        convertedAtomMetadata.comment = dbAtom.comment;
    // Resolve related entity from the dependency object
    const associatedEntity = (0, utils_1.resolveAssociatedEntity)(dbAtom, dependencies);
    if ((associatedEntity === null || associatedEntity === void 0 ? void 0 : associatedEntity.isAttribute) && ((_a = dbAtom.leftHand) === null || _a === void 0 ? void 0 : _a.type) !== 'attribute')
        throw new ts_common_1.BadImplementationException(`Cannot use ${associatedEntity.name} as left hand because it's an attribute`);
    if (associatedEntity) {
        if (((_b = dbAtom.leftHand) === null || _b === void 0 ? void 0 : _b.type) === 'expression') {
            convertedAtomMetadata.expression_db_id = associatedEntity._id;
            convertedAtomMetadata.name = associatedEntity.label;
        }
        else {
            convertedAtomMetadata.db_id = associatedEntity._id;
            convertedAtomMetadata.hv_name = associatedEntity.name;
            if (((_c = dbAtom.leftHand) === null || _c === void 0 ? void 0 : _c.type) === 'attribute')
                convertedAtomMetadata.attributee_id = dbAtom.leftHand.id;
        }
    }
    if (convertedAtomMetadata.type === 'delta-hv-atom') {
        const baseDeltaOp = (0, shared_1.getBaseDeltaOperator)(getFirstCaseOperator(dbAtom));
        if (!baseDeltaOp)
            throw new ts_common_1.BadImplementationException('delta atom must have base delta operator');
        convertedAtomMetadata.delta_type = (baseDeltaOp === null || baseDeltaOp === void 0 ? void 0 : baseDeltaOp.includes('%')) ? 'percent_diff' : 'diff';
        if (dbAtom.deltaTimeDiff)
            convertedAtomMetadata.time_diff_constraint = dbAtom.deltaTimeDiff;
    }
    const sampleInfo = dbAtom.sampleInfo;
    if (sampleInfo && (convertedAtomMetadata.type === 'hv-normal-range-atom' || convertedAtomMetadata.type === 'hv-atom')) {
        convertedAtomMetadata.sample = resolveSampleInfo(sampleInfo);
    }
    return convertedAtomMetadata;
};
const resolveSampleInfo = (sampleInfo) => {
    var _a, _b;
    const convertedSample = {};
    // add expiry if exists
    if ((0, ts_common_1.exists)(sampleInfo.expiry) && ((_a = sampleInfo.expiry) === null || _a === void 0 ? void 0 : _a.length))
        convertedSample.expiry = sampleInfo.expiry;
    // add time constraint is existing
    if ((0, ts_common_1.exists)(sampleInfo.timeConstraint) && ((_b = sampleInfo.timeConstraint) === null || _b === void 0 ? void 0 : _b.length))
        convertedSample.time_constraint = sampleInfo.timeConstraint;
    // Convert string to numeric sample index
    switch (sampleInfo.sampleIndex) {
        case 'latest':
            convertedSample.index = sampleInfo.sampleIndex;
            break;
        case 'latest - 1':
            convertedSample.index = 1;
            break;
        case 'latest - 2':
            convertedSample.index = 2;
            break;
        case 'latest - 3':
            convertedSample.index = 3;
            break;
        case 'latest - 4':
            convertedSample.index = 4;
            break;
    }
    return convertedSample;
};
const resolveAtomType = (dbAtom) => {
    var _a;
    // Fail fast if left hand type is expression.
    if (((_a = dbAtom.leftHand) === null || _a === void 0 ? void 0 : _a.type) === 'expression')
        return 'embedded-expression-atom';
    const dbCaseOperator = getFirstCaseOperator(dbAtom);
    if (consts_2.inNormalRangeOperators.includes(dbCaseOperator))
        return 'hv-normal-range-atom';
    if (consts_2.AllDeltaLogic.includes(dbCaseOperator))
        return 'delta-hv-atom';
    return 'hv-atom';
};
const getEvaluationLogicType = (dbAtom, dependencies) => {
    var _a;
    let dbCaseOperator = getFirstCaseOperator(dbAtom);
    if ((0, shared_1.getBaseDeltaOperator)(dbCaseOperator) && !consts_2.BaseDeltaLogic.includes(dbCaseOperator))
        dbCaseOperator = (0, shared_1.getDeltaTypeOperator)(dbCaseOperator);
    if (consts_2.SelfOperators.includes(dbCaseOperator))
        return consts_1.EvaluationLogicTypes.self;
    if (dbCaseOperator === consts_2.LogicType_Exists)
        return consts_1.EvaluationLogicTypes.exists;
    if (consts_2.inNormalRangeOperators.includes(dbCaseOperator))
        return consts_1.EvaluationLogicTypes.normalRange;
    // check by left hand variable type
    if (!dbAtom.leftHand)
        throw new ts_common_1.BadImplementationException('No left hand value chosen for Atom');
    if (dbAtom.leftHand.type === 'variable' || dbAtom.leftHand.type === 'attribute') {
        const variableId = (_a = dbAtom.leftHand.propertyId) !== null && _a !== void 0 ? _a : dbAtom.leftHand.id;
        const dbVariable = dependencies.variables.find(_var => _var._id === variableId);
        if (!dbVariable)
            throw new ts_common_1.BadImplementationException(`No variable found for id ${dbAtom.leftHand.id}`);
        const dbValue = dependencies.values.find(_val => _val._id === dbVariable.valueId);
        if (!dbValue)
            throw new ts_common_1.BadImplementationException(`No value found for id ${dbVariable.valueId}`);
        if (consts_1.NumericDBValueTypes.includes((dbValue.type)))
            return consts_1.EvaluationLogicTypes.range;
        if (dbCaseOperator === consts_2.LogicType_NotEquals)
            return consts_1.EvaluationLogicTypes.notEqual;
        if (consts_1.StringDBValueTypes.includes(dbValue.type))
            return consts_1.EvaluationLogicTypes.set;
        throw new ts_common_1.BadImplementationException('Could not resolve evaluation logic type for HVAtom...');
    }
    if (dbAtom.leftHand.type === 'expression') {
        const dbExpression = dependencies.expressions.find(expr => { var _a; return expr._id === ((_a = dbAtom.leftHand) === null || _a === void 0 ? void 0 : _a.id); });
        if (!dbExpression)
            throw new ts_common_1.BadImplementationException(`No Db expression found for id ${dbAtom.leftHand.id}`);
        if (dbExpression.expressionType === consts_2.ExpressionType_GeneralScore.dbKey)
            return consts_1.EvaluationLogicTypes.range;
        if (dbExpression.expressionType === consts_2.ExpressionType_GeneralFunction.dbKey)
            return consts_1.EvaluationLogicTypes.set;
        throw new ts_common_1.BadImplementationException('Could not resolve evaluation logic type for Embedded expression atom');
    }
    if (dbAtom.leftHand.type === 'atom') {
        if (consts_1.RangeOperators.includes(dbCaseOperator))
            return consts_1.EvaluationLogicTypes.range;
        if (dbCaseOperator === consts_2.LogicType_NotEquals)
            return consts_1.EvaluationLogicTypes.notEqual;
        if (consts_1.InSetOperators.includes(dbCaseOperator))
            return consts_1.EvaluationLogicTypes.set;
    }
    throw new ts_common_1.BadImplementationException(`Cannot resolve evaluation logic type for atom:\n${(0, ts_common_1.__stringify)(dbAtom, true)}`);
};
exports.getEvaluationLogicType = getEvaluationLogicType;
// ########################## Single Case Evaluation Logic ##########################
const singleCaseAtoms = (dbAtom, convertedAtom, dependencies) => {
    switch (convertedAtom.type) {
        case 'hv-atom':
        case 'embedded-expression-atom':
        case 'delta-hv-atom':
            resolveSingleCaseAtom(dbAtom, convertedAtom, dependencies);
            break;
        case 'hv-normal-range-atom':
            resolveSingleCaseNormalRangeAtom(dbAtom, convertedAtom, dependencies);
            break;
    }
};
const validateSingleCaseAtom = (dbAtom, evaluationLogicType) => {
    const dbCaseOperator = getFirstCaseOperator(dbAtom);
    if (!consts_1.AllowedOperators[evaluationLogicType].includes(dbCaseOperator))
        throw new ts_common_1.BadImplementationException(`Cannot use the ${dbCaseOperator} operator with the selected variable!`);
};
const resolveSingleCaseAtom = (dbAtom, convertedAtom, dependencies) => {
    const evaluationLogicType = (0, exports.getEvaluationLogicType)(dbAtom, dependencies);
    validateSingleCaseAtom(dbAtom, evaluationLogicType);
    switch (evaluationLogicType) {
        case consts_1.EvaluationLogicTypes.self:
            convertedAtom.evaluation_logic = (0, self_1.resolveSelf)(dbAtom, dependencies);
            break;
        case consts_1.EvaluationLogicTypes.set:
            convertedAtom.evaluation_logic = (0, set_1.resolveInSet)(dbAtom, dependencies);
            break;
        case consts_1.EvaluationLogicTypes.range:
            convertedAtom.evaluation_logic = (0, range_1.resolveRangeEvaluationLogic)(dbAtom, dependencies);
            break;
        case consts_1.EvaluationLogicTypes.notEqual:
            convertedAtom.evaluation_logic = (0, not_equal_1.resolveNotEqual)(dbAtom, dependencies);
            break;
        case consts_1.EvaluationLogicTypes.exists:
            convertedAtom.evaluation_logic = (0, exists_1.resolveExists)();
            break;
    }
};
const resolveSingleCaseNormalRangeAtom = (dbAtom, convertedAtom, dependencies) => {
    validateSingleCaseAtom(dbAtom, 'normal-range');
    (0, normal_range_1.resolveBaseNormalRangeAtom)(dbAtom, convertedAtom, dependencies);
    convertedAtom.evaluation_logic = (0, normal_range_1.resolveNormalRange)(dbAtom);
};
// ########################## Conversion Atoms Evaluation Logic ##########################
const conversionAtoms = (dbAtom, convertedAtom, dependencies) => {
    switch (convertedAtom.type) {
        case 'delta-hv-atom':
        case 'embedded-expression-atom':
        case 'hv-atom':
            resolveConversionAtom(dbAtom, convertedAtom, dependencies);
            break;
        case 'hv-normal-range-atom':
            resolveConversionNormalRangeAtom(dbAtom, convertedAtom, dependencies);
            break;
    }
};
const resolveConversionAtom = (dbAtom, convertedAtom, dependencies) => {
    const conversionType = (0, exports.getEvaluationLogicType)(dbAtom, dependencies);
    if (!conversionType)
        return;
    const baseEvaluationLogic = (0, generic_conversion_1.resolveConversionBaseEvaluationLogic)(dbAtom, conversionType);
    switch (conversionType) {
        case consts_1.EvaluationLogicTypes.set:
            convertedAtom.evaluation_logic = (0, set_1.resolveInSetConversion)(dbAtom, baseEvaluationLogic, dependencies);
            break;
        case consts_1.EvaluationLogicTypes.notEqual:
            convertedAtom.evaluation_logic = (0, not_equal_1.resolveNotEqualConversion)(dbAtom, baseEvaluationLogic, dependencies);
            break;
        case consts_1.EvaluationLogicTypes.range:
            convertedAtom.evaluation_logic = (0, range_1.resolveInRangeConversion)(dbAtom, baseEvaluationLogic, dependencies);
            break;
    }
};
const resolveConversionNormalRangeAtom = (dbAtom, convertedAtom, dependencies) => {
    (0, normal_range_1.resolveBaseNormalRangeAtom)(dbAtom, convertedAtom, dependencies);
    const baseEvaluationLogic = (0, generic_conversion_1.resolveConversionBaseEvaluationLogic)(dbAtom, 'normal-range');
    convertedAtom.evaluation_logic = (0, normal_range_1.resolveNormalRangeConversion)(dbAtom, baseEvaluationLogic);
};
const getFirstCaseOperator = (dbAtom) => {
    const dbCase = dbAtom.cases[0];
    if (!dbCase.caseOperator)
        throw new ts_common_1.BadImplementationException(`No operator chosen for dbAtom case!`);
    return dbCase.caseOperator;
};
