"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Variable = exports.Validator_ModifiableProps = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const db_def_1 = require("../../macro-tag/shared/db-def");
const shared_1 = require("../../shared");
exports.Validator_ModifiableProps = {
    name: (0, ts_common_1.tsValidateRegexp)(/^[A-Za-zÀ-ÖØ-öø-ÿ\d\-+:'.&/ ]{2,100}$/),
    role: (0, ts_common_1.tsValidateValue)([...types_1.roles]),
    perspective: (0, ts_common_1.tsValidateValue)([...types_1.perspectives]),
    nlpDataId: ts_common_1.tsValidateUniqueId,
    tagIds: (0, ts_common_1.tsValidateArray)(db_def_1.Validator_TagGroup),
    valueId: ts_common_1.tsValidateUniqueId,
    referenceIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
    otherProperties: ts_common_1.tsValidateOptional,
    attributes: (0, ts_common_1.tsValidateArray)({ varId: ts_common_1.tsValidateUniqueId, valueId: ts_common_1.tsValidateUniqueId }, false),
    isAttribute: (0, ts_common_1.tsValidateBoolean)(false),
};
const Validator_GeneratedProps = {
    _nameInLowercase: (0, ts_common_1.tsValidateRegexp)(/^[A-Za-zÀ-ÖØ-öø-ÿ\d\-+:'.&/ ]{2,100}$/),
    _tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _rowId: ts_common_1.tsValidateOptionalId,
    _attributeVarIds: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false),
    _attributeValueIds: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false)
};
exports.DBDef_Variable = {
    modifiablePropsValidator: exports.Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1', '1.0.0'],
    dbKey: 'variables',
    entityName: 'Variable',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'variable'
    },
    backend: {
        name: 'variables'
    },
    dependencies: {
        'nlpDataId': { dbKey: 'nlp-data', fieldType: 'string' },
        'valueId': { dbKey: 'values', fieldType: 'string' },
        'referenceIds': { dbKey: 'references', fieldType: 'string[]' },
        '_tagIds': { dbKey: 'tags', fieldType: 'string[]' },
        '_attributeVarIds': { dbKey: 'variables', fieldType: 'string[]' },
        '_attributeValueIds': { dbKey: 'values', fieldType: 'string[]' },
    }
};
