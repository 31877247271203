"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_DiseaseViews = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Panel_DiseaseViews.scss");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const dialogs_1 = require("../../dialogs");
const renderers_1 = require("../../renderers");
const _entity_1 = require("../../../_entity");
class Panel_DiseaseViews extends frontend_1.ProtoComponent {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        this.renderDiseaseView = (dv) => {
            return React.createElement(frontend_1.LL_H_C, { className: (0, frontend_1._className)('node-data', dv._id === _entity_1.noConnectedViewDPView._id ? 'misc' : undefined), onContextMenu: e => this.onViewRightClick(e, dv) },
                React.createElement("label", null, dv.label),
                React.createElement(frontend_1.LL_H_C, null,
                    React.createElement(renderers_1.Renderer_AlertWarningForDP, { relatedMultiVars: this.state.multiVarsPerView[dv._id] }),
                    this.renderDiseaseCount(dv._id)));
        };
    }
    // ######################### Life Cycle #########################
    __onMultiVarUpdated(...params) {
        switch (params[0]) {
            case 'create':
            case 'upsert-all':
            case 'delete':
            case 'delete-multi':
                this.reDeriveState();
                break;
        }
    }
    __onDiseaseViewUpdated(...params) {
        this.reDeriveState();
    }
    deriveStateFromProps(nextProps, state) {
        const selected = this.getQueryParam('selected', {});
        state.selectedDiseaseViewId = selected[_entity_1.DBDef_DiseaseView.dbKey];
        state.selectedDiseaseProfileId = selected[_entity_1.DBDef_DiseaseProfile.dbKey];
        const multiVarsForSelectedDP = _entity_1.ModuleFE_MultiVar.cache.filter(mv => mv.diseaseProfileId === state.selectedDiseaseProfileId);
        state.multiVarsPerView = _entity_1.ModuleFE_DiseaseView.cache.all().reduce((acc, dv) => {
            acc[dv._id] = multiVarsForSelectedDP.filter(mv => _entity_1.ModuleFE_DiseaseView.filterMultiVarByEnforcedTag(dv, mv));
            acc[dv._id].forEach(mv => (0, ts_common_1.removeItemFromArray)(multiVarsForSelectedDP, mv));
            return acc;
        }, {});
        //Remaining multivars are ones that aren't assigned any DV
        state.multiVarsWithNoViews = multiVarsForSelectedDP;
        //Set selected disease view if none selected
        if (!state.selectedDiseaseViewId) {
            const firstDV = this.getSortedViews()[0];
            if (!firstDV)
                return state;
            const _selected = Object.assign({}, selected);
            _selected[_entity_1.DBDef_DiseaseView.dbKey] = firstDV._id;
            this.setQueryParam('selected', _selected);
            state.selectedDiseaseViewId = firstDV._id;
        }
        return state;
    }
    // ######################### Logic #########################
    onViewSelect(item) {
        const selected = Object.assign({}, this.getQueryParam('selected', {}));
        if (selected[_entity_1.DBDef_DiseaseView.dbKey] === item._id)
            return;
        selected[_entity_1.DBDef_DiseaseView.dbKey] = item._id;
        this.setQueryParam('selected', selected);
    }
    onViewRightClick(e, view) {
        (0, frontend_1.stopPropagation)(e);
        if (!e.shiftKey)
            return;
        const menuItems = [
            { label: 'Add View Config', onClick: () => dialogs_1.Dialog_ViewConfigEditor.show() },
        ];
        if (view)
            menuItems.push({ label: 'Edit View Config', onClick: () => dialogs_1.Dialog_ViewConfigEditor.show(view) });
        const rightClickMenu = {
            id: 'panel-views-menu',
            adapter: (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label)),
            originPos: { y: e.clientY, x: e.clientX, },
            modalPos: { x: 0, y: 1 },
            onNodeClicked: (path, item) => {
                var _a;
                frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
            }
        };
        frontend_1.ModuleFE_MouseInteractivity.showMenu(rightClickMenu);
    }
    getSortedViews() {
        const viewsOrder = _entity_1.ConfigKeyFE_DPViewOrder.get() || [];
        return (0, ts_common_1.sortArray)(_entity_1.ModuleFE_DiseaseView.cache.allMutable(), item => viewsOrder.indexOf(item._id));
    }
    ;
    // ######################### Render #########################
    renderDiseaseCount(diseaseViewId) {
        var _a, _b;
        const count = diseaseViewId === _entity_1.noConnectedViewDPView._id
            ? (_a = this.state.multiVarsWithNoViews) === null || _a === void 0 ? void 0 : _a.length
            : (_b = this.state.multiVarsPerView[diseaseViewId]) === null || _b === void 0 ? void 0 : _b.length;
        if (!count)
            return '';
        return React.createElement("span", { className: 'disease-value-count' }, count);
    }
    render() {
        const views = this.getSortedViews();
        if (this.state.multiVarsWithNoViews.length)
            views.push(_entity_1.noConnectedViewDPView);
        const adapter = (0, frontend_1.SimpleListAdapter)(views, (item) => this.renderDiseaseView(item.item));
        const selectedView = _entity_1.ModuleFE_DiseaseView.cache.unique(this.state.selectedDiseaseViewId);
        return React.createElement(frontend_1.LL_V_L, { className: 'panel__disease-properties' },
            React.createElement(frontend_2.PanelHeader, { title: 'Disease Views' }),
            React.createElement("div", { className: 'panel__main', onContextMenu: this.onViewRightClick },
                React.createElement(frontend_1.TS_Tree, { adapter: adapter, selectedItem: selectedView, onNodeClicked: (path, item) => this.onViewSelect(item) })));
    }
}
Panel_DiseaseViews.defaultProps = {
    keys: ['selected'],
};
exports.Panel_DiseaseViews = Panel_DiseaseViews;
