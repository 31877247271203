"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueRenderer = exports.resolveValueDisplay = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const utility_1 = require("./utility");
const resolveValueDisplay = (value) => {
    // @ts-ignore
    return '' + exports.ValueRenderer[value.type](value);
};
exports.resolveValueDisplay = resolveValueDisplay;
const defaultValueRenderer = (label) => {
    return (0, ts_common_1.capitalizeFirstLetter)(label);
};
const defaultValueTypeRenderer = (value) => {
    if (!value.label)
        console.log(value);
    return defaultValueRenderer(value.label);
};
exports.ValueRenderer = {
    [consts_1.valueType_EnumElement]: (value) => defaultValueRenderer(value.data.value),
    [consts_1.valueType_SubRange]: (value) => defaultValueRenderer(`${value.data.scope[0]} ... ${value.data.scope[1]} ${(value.data.label ? `: ${value.data.label}` : '')}`),
    [consts_1.valueType_SubEnumerated]: defaultValueTypeRenderer,
    [consts_1.valueType_Enumerated]: defaultValueTypeRenderer,
    [consts_1.valueType_Range]: defaultValueTypeRenderer,
    [consts_1.valueType_Tuple]: defaultValueTypeRenderer,
    [consts_1.valueType_StandardRange]: defaultValueTypeRenderer,
    [consts_1.valueType_Time]: (value) => '',
    [consts_1.valueType_TimeRange]: utility_1.resolveTimeRangeDisplay
};
