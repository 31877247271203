"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_EHRInstanceKBVersion = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const hcs_1 = require("@app/hcs");
const consts_1 = require("./consts");
const Validator_ModifiableProps = {
    ehrInstanceId: ts_common_1.tsValidateUniqueId,
    versionId: ts_common_1.tsValidateUniqueId,
};
const Validator_GeneratedProps = {
// 
};
exports.DBDef_EHRInstanceKBVersion = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: consts_1.EhrInstanceKbVersion_CollectionName,
    entityName: 'EHRInstanceKBVersion',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: consts_1.EhrInstanceKbVersion_CollectionName
    },
    backend: {
        name: consts_1.EhrInstanceKbVersion_CollectionName
    },
};
