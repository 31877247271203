"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_DiseaseProfileEditor = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const styles_1 = require("@app/styles");
const _shared_1 = require("../../../_shared");
const __1 = require("../../..");
require("./Dialog_DiseaseProfileEditor.scss");
const frontend_3 = require("@nu-art/permissions/frontend");
const consts_1 = require("../../../../shared/consts");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const _entity_1 = require("../../../_entity");
const Component_EpidemiologyEditor_1 = require("../../components/Component_EpidemiologyEditor/Component_EpidemiologyEditor");
const Component_PrognosisEditor_1 = require("../../components/Component_PrognosisEditor/Component_PrognosisEditor");
class Dialog_DiseaseProfileEditor extends frontend_1.TS_Dialog {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.editPermissionKey = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getPermissionKey(consts_1.PermissionKey_DiseaseProfile_Edit);
        this.deletePermissionKey = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getPermissionKey(_shared_1.PermissionKey_DiseaseProfile_Delete);
        this.canBeSaved = () => {
            return !!this.state.diseaseProfile.item.variableId && this.hasSystemTagIds() && !this.state.dialogIsBusy && !this.state.invalidInitialsDPId;
        };
        this.saveDisease = async () => {
            const dp = this.state.diseaseProfile;
            // @ts-ignore
            delete dp.item.backgroundColor;
            // @ts-ignore
            delete dp.item.color;
            await (0, frontend_1.performAction)(async () => {
                this.setState({ dialogIsBusy: true });
                await dp.updateObj({ tagIds: frontend_2.VariableWrapper.getTagsForDB(this.state.tags) });
                await dp.updateObj({ chiefComplaintIds: (0, ts_common_1.filterDuplicates)(dp.item.chiefComplaintIds || []) });
                try {
                    await _entity_1.ModuleFE_DiseaseProfile.v1.upsert(dp.item).executeSync();
                }
                catch (e) {
                    (0, frontend_2.handleError)(e);
                }
                this.closeDialog();
            }, { type: 'notification', notificationLabels: 'Saving Disease Profile' });
            if (this.mounted)
                this.setState({ dialogIsBusy: false });
        };
        this.deleteDiseaseProfile = async () => {
            const dp = this.state.diseaseProfile;
            if (!dp.item._id)
                return;
            frontend_2.WarningDialog.showDelete({
                title: `Delete Disease Profile '${dp.item.label}'`,
                executeButtonText: 'Delete',
                warningMessages: [`You are about to delete disease profile '${dp.item.label}'`, 'Are you sure?'],
                onConfirm: async () => {
                    await (0, frontend_1.performAction)(async () => {
                        this.setState({ dialogIsBusy: true });
                        await _entity_1.ModuleFE_DiseaseProfile._v1.deleteWholeDiseaseProfile({ diseaseProfileId: dp.item._id }).executeSync();
                        this.closeDialog();
                    }, {
                        type: 'notification',
                        notificationLabels: 'Deleting Disease Profile'
                    });
                    if (this.mounted)
                        this.setState({ dialogIsBusy: false });
                }
            });
        };
        this.onVariableSelected = async (variable) => {
            const dp = this.state.diseaseProfile;
            const initialDPData = (0, __1.getDiseaseProfilePresetData)(variable);
            await dp.updateObj({ variableId: variable._id });
            await dp.updateObj({ initials: initialDPData.initials || '' });
            await dp.updateObj({ criticality: initialDPData.criticality });
        };
        this.onInitialsChange = async (value) => {
            const initials = value.toUpperCase();
            await this.state.diseaseProfile.updateObj({ initials: initials });
            const dp = _entity_1.ModuleFE_DiseaseProfile.cache.find(i => i._id !== this.state.diseaseProfile.item._id
                && i.initials === initials
                && initials !== '');
            //Either way, on change close the tooltip
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_ToolTip);
            if (!dp)
                return this.setState({ invalidInitialsDPId: undefined });
            this.setState({ invalidInitialsDPId: dp._id });
        };
        this.getInitialsTooltip = () => {
            if (!this.state.invalidInitialsDPId)
                return {};
            const dp = _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.state.invalidInitialsDPId);
            return frontend_1.openContent.tooltip.right('initials-error', () => React.createElement("div", null,
                "\"",
                dp.initials,
                "\" is already in use by Disease \u201C",
                dp.label,
                "\". Please select different profile initials."));
        };
        this.hideField = (field) => {
            const dp = this.state.diseaseProfile.item;
            //Hide field if it has no data AND (either dp is completed OR user doesn't have edit permissions)
            return !dp[field]
                && (dp.completed
                    || ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(this.editPermissionKey) !== ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess);
        };
        this.isInEditMode = () => {
            const dp = this.state.diseaseProfile;
            if (dp.item.completed)
                return false;
            return ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(this.editPermissionKey) === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess;
        };
        // ######################## Render ########################
        this.buttons = () => {
            const buttons = { left: [], right: [] };
            buttons.right.push({ content: 'Cancel', onClick: () => this.closeDialog(), disabled: this.state.dialogIsBusy });
            if (ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(this.editPermissionKey) === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess)
                buttons.right.push({
                    content: 'Save',
                    className: (0, frontend_1._className)(this.canBeSaved() ? 'desired-action-button' : undefined),
                    renderer: frontend_1.TS_Dialog.busyButton,
                    onClick: this.saveDisease,
                    disabled: !this.canBeSaved(),
                });
            if (this.state.diseaseProfile.item._id && ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(this.deletePermissionKey) === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess)
                buttons.left.push({
                    content: 'Delete',
                    className: 'delete-button',
                    renderer: frontend_1.TS_Dialog.busyButton,
                    onClick: this.deleteDiseaseProfile,
                    disabled: this.state.dialogIsBusy,
                });
            return buttons;
        };
        this.renderHeader = () => {
            const dp = this.state.diseaseProfile;
            return React.createElement(React.Fragment, null,
                React.createElement("span", { className: "header__title" }, (dp.item._id ? 'Edit Disease Profile' : 'Add Disease Profile') + (dp.item.label ? (' - ' + dp.item.label) : '')),
                React.createElement(frontend_1.LL_H_C, { className: 'dialog__header__buttons' },
                    this.renderCompleted(),
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => this.closeDialog() })));
        };
        this.renderBody = () => {
            const editMode = this.isInEditMode();
            const tabs = [
                { title: 'General', content: this.renderGeneralTab, uid: 'general' },
                {
                    title: 'Epidemiology',
                    content: () => React.createElement(Component_EpidemiologyEditor_1.Component_EpidemiologyEditor, { editable: this.state.diseaseProfile, editMode: editMode }),
                    uid: 'epidemiology'
                },
                {
                    title: 'Prognosis',
                    content: () => React.createElement(Component_PrognosisEditor_1.Component_PrognosisEditor, { editable: this.state.diseaseProfile, editMode: editMode }),
                    uid: 'prognosis'
                },
                { title: 'References', content: this.renderReferencesTab, uid: 'references' },
            ];
            return React.createElement(frontend_1.TS_Tabs, { tabs: tabs, tabsContentClass: 'custom-scrollbar__vertical' });
        };
        this.renderCompleted = () => {
            const dp = this.state.diseaseProfile;
            return React.createElement(frontend_3.PermissionsComponent, { permissionKey: this.editPermissionKey },
                React.createElement(frontend_1.LL_H_C, { className: 'match_width dp-complete' },
                    React.createElement("span", { className: 'dialog__field-title' }, "Completed Disease Profile:"),
                    React.createElement(frontend_1.TS_Checkbox, { className: 'quai', icon: React.createElement(styles_1.ICONS.v.component, null), checked: dp.item.completed, onCheck: async () => {
                            await dp.updateObj({ completed: !dp.item.completed });
                            this.forceUpdate();
                        } })));
        };
        // ######################## Render - General #################
        this.renderGeneralTab = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'general-tab' },
                React.createElement(frontend_1.LL_H_C, { className: 'columns-container' },
                    React.createElement(frontend_1.LL_V_L, { className: 'col' },
                        this.renderDiseaseDropdown(),
                        this.renderLabel(),
                        this.renderInitials(),
                        this.renderICD(),
                        this.renderCriticality(),
                        this.renderVariantGroup()),
                    React.createElement(frontend_1.LL_V_L, { className: 'col' },
                        this.renderTagEditor(),
                        this.renderScores())),
                this.renderDescription(),
                this.renderNotes());
        };
        this.renderLabel = () => {
            const dp = this.state.diseaseProfile;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Label' },
                React.createElement(frontend_3.PermissionsEditableComponent, { value: dp.item.label, forceLock: dp.item.completed, permissionKey: this.editPermissionKey },
                    React.createElement(frontend_1.TS_Input, { type: 'text', value: dp.item.label || '', onChange: async (value) => {
                            await dp.updateObj({ label: value });
                            this.forceUpdate();
                        } })));
        };
        this.renderVariantGroup = () => {
            const dp = this.state.diseaseProfile;
            const selectedVariant = frontend_2.ModuleFE_Tag.cache.unique(dp.item.variantsGroupId);
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Variant Group' },
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.label },
                    React.createElement(frontend_2.HCSDropDowns.Tag, { queryFilter: (tag) => tag.tagIds.length === 0, selected: dp.item.variantsGroupId, onSelected: async (tagGroup) => dp.updateObj({ variantsGroupId: tagGroup._id }) })));
        };
        this.renderInitials = () => {
            var _a;
            const dp = this.state.diseaseProfile;
            const adapter = (0, frontend_1.SimpleListAdapter)(__1.ModuleFE_ColorGroup.cache.allMutable(), (item) => React.createElement("div", null, item.item.label));
            const selected = __1.ModuleFE_ColorGroup.cache.unique(dp.item.colorGroup);
            return React.createElement(frontend_1.LL_H_C, { className: 'disease-editor__container__disease-initials' },
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Profile Initials' },
                    React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: dp.item.initials },
                        React.createElement(frontend_1.TS_Input, Object.assign({ className: this.state.invalidInitialsDPId ? 'error' : undefined, id: `disease-editor__disease-initials`, placeholder: 'Disease initials', type: "text", maxLength: 4, value: dp.item.initials, onChange: this.onInitialsChange }, this.getInitialsTooltip())))),
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Color Group', className: 'disease-editor__container__disease-initials__previews' },
                    React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: this.state.diseaseProfile.item.completed, value: (_a = selected === null || selected === void 0 ? void 0 : selected.label) !== null && _a !== void 0 ? _a : '-' },
                        React.createElement(frontend_1.TS_DropDown, { className: 'match_width', placeholder: 'Select Related System', selected: selected, adapter: adapter, onSelected: (group) => dp.set('colorGroup', group._id) }))));
        };
        this.renderICD = () => {
            const icds = this.state.diseaseProfile.item.icd;
            if (!(icds === null || icds === void 0 ? void 0 : icds.length))
                return '';
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'ICD' },
                React.createElement("span", null, icds.map(refId => frontend_2.ModuleFE_Reference.cache.unique(refId).data.id).join(', ')));
        };
        this.renderTagEditor = () => {
            var _a;
            const disableEdit = this.state.diseaseProfile.item.completed || ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(this.editPermissionKey) !== ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess;
            if (!((_a = this.state.tags) === null || _a === void 0 ? void 0 : _a.length) && disableEdit)
                return;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Tags' },
                React.createElement(frontend_2.Component_TagEditor, { tags: this.state.tags, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id, groupTagFilter: (groupTag) => true, disableEdit: disableEdit }));
        };
        this.renderCriticality = () => {
            const dp = this.state.diseaseProfile;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Criticality' },
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: dp.item.criticality ? __1.CriticalityLabelMap[dp.item.criticality] : '-' },
                    React.createElement(__1.DropDown_Criticality.editable, { editable: dp, prop: 'criticality' })));
        };
        this.renderScores = () => {
            var _a, _b;
            const dp = this.state.diseaseProfile;
            const existingScoreVarIds = (0, ts_common_1.filterInstances)(((_a = dp.item.scoreReferences) === null || _a === void 0 ? void 0 : _a.map(i => i.variableId)) || []);
            const scoresTag = frontend_2.ConfigKeyFE_Scores.get();
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Scores' },
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: dp.item.scores, className: 'text-area' },
                    React.createElement(frontend_1.TS_TextArea, { type: 'text', placeholder: 'Scores goes here...', value: dp.item.scores, onChange: async (value) => {
                            await dp.updateObj({ scores: value });
                            this.forceUpdate();
                        } })),
                React.createElement(frontend_1.LL_V_L, { className: 'match_width' }, (_b = dp.item.scoreReferences) === null || _b === void 0 ? void 0 :
                    _b.map(score => {
                        const scoreVar = frontend_2.ModuleFE_Variable.cache.unique(score.variableId);
                        return React.createElement(frontend_1.LL_H_C, { className: 'score-reference' },
                            React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: scoreVar === null || scoreVar === void 0 ? void 0 : scoreVar.name, className: 'grid-data' },
                                React.createElement(frontend_2.DropDown_Variable, { canUnselect: true, selected: scoreVar, onSelected: async (variable) => {
                                        var _a;
                                        if (!variable)
                                            dp.item.scoreReferences = (_a = dp.item.scoreReferences) === null || _a === void 0 ? void 0 : _a.filter(i => i.variableId !== score.variableId);
                                        else
                                            score.variableId = variable._id;
                                        this.forceUpdate();
                                    }, queryFilter: i => !existingScoreVarIds.includes(i._id) && i.tagIds.some(group => group.value.includes(scoresTag)), sortBy: ['name'], placeholder: 'Score Name' })),
                            this.renderScoreLink(score));
                    }),
                    React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: '' },
                        React.createElement(frontend_2.DropDown_Variable, { selected: undefined, onSelected: async (variable) => {
                                if (!dp.item.scoreReferences)
                                    dp.item.scoreReferences = [];
                                dp.item.scoreReferences.push({
                                    variableId: variable._id,
                                });
                                this.forceUpdate();
                            }, queryFilter: i => !existingScoreVarIds.includes(i._id) && i.tagIds.some(group => group.value.includes(scoresTag)), sortBy: ['name'], placeholder: 'Score Name' }))));
        };
        this.renderScoreLink = (score) => {
            const editMode = !this.state.diseaseProfile.item.completed;
            if (!editMode) {
                if (!score.mdCalcUrl)
                    return React.createElement("div", { className: 'grid-data' });
                return React.createElement(frontend_1.TS_Link, { url: score.mdCalcUrl, target: '_blank', className: 'grid-data' }, score.mdCalcUrl);
            }
            return React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: !editMode, value: score.mdCalcUrl },
                React.createElement(frontend_1.TS_Input, { type: 'text', value: score.mdCalcUrl, onChange: val => score.mdCalcUrl = val }));
        };
        this.renderDescription = () => {
            if (this.hideField('profileDescription'))
                return '';
            const dp = this.state.diseaseProfile;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'General Description' },
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: dp.item.profileDescription, className: 'text-area' },
                    React.createElement(frontend_1.TS_TextArea, { type: 'text', placeholder: 'Disease description goes here...', value: dp.item.profileDescription, onChange: async (value) => {
                            await dp.updateObj({ profileDescription: value });
                            this.forceUpdate();
                        } })));
        };
        this.renderNotes = () => {
            if (this.hideField('profileNotes'))
                return '';
            const dp = this.state.diseaseProfile;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'General Notes' },
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, forceLock: dp.item.completed, value: dp.item.profileNotes, className: 'text-area' },
                    React.createElement(frontend_1.TS_TextArea, { type: 'text', placeholder: 'Enter notes here...', value: dp.item.profileNotes, onChange: async (value) => {
                            await dp.updateObj({ profileNotes: value });
                            this.forceUpdate();
                        } })));
        };
        // ######################## Render - References #################
        this.renderReferencesTab = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'references-tab' }, this.renderRefsTable());
        };
        this.renderRefsTable = () => {
            const dp = this.state.diseaseProfile;
            const onReferenceDeleted = (referenceId) => {
                const mvs = __1.ModuleFE_MultiVar.cache.filter(i => i.diseaseProfileId === dp.item._id
                    && i.referenceIds.includes(referenceId));
                if (mvs.length) {
                    frontend_1.ModuleFE_Notifications.create()
                        .content('MultiVars are using this reference', mvs.map(i => frontend_2.ModuleFE_Variable.cache.unique(i._firstVarId).name).join(', '))
                        .setStatus(frontend_1.NotificationStatus_Failed).show(5000);
                    return;
                }
                const index = dp.item.referenceIds.findIndex(refId => refId === referenceId);
                if (index === -1)
                    return;
                dp.item.referenceIds.splice(index, 1);
                this.forceUpdate();
            };
            const onReferenceUpsert = async (reference) => {
                if (dp.item.referenceIds.findIndex(refId => refId === reference._id) === -1)
                    dp.item.referenceIds.push(reference._id);
                this.forceUpdate();
            };
            const allowedRef = ['book', 'article', 'upToDate'];
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Profile References' },
                React.createElement("div", { className: 'list-wrapper' },
                    React.createElement("div", { className: 'custom-scrollbar__vertical', onContextMenu: e => {
                            frontend_2.Component_ReferencesTable.handleReferencesRightClick(e, onReferenceUpsert, onReferenceDeleted, allowedRef);
                        } }, dp && React.createElement(frontend_2.Component_ReferencesTable, { referenceIds: dp.item.referenceIds, onReferenceUpsertCallback: onReferenceUpsert, onReferenceDeletedCallback: onReferenceDeleted, allowedRefs: allowedRef }))));
        };
    }
    static async show(disease) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_DiseaseProfileEditor, { diseaseProfile: disease }), () => {
            return false;
        });
    }
    // ######################## Life Cycle ########################
    async __onVariableUpdated(...params) {
        const dp = this.state.diseaseProfile.item;
        if (params[0] === frontend_1.EventType_Query) {
            if (params[1][0]._id === dp.variableId) {
                await this.onVariableSelected(params[1][0]);
            }
        }
        else if (params[0] === frontend_1.EventType_Create) {
            if (params[1]._id === dp.variableId) {
                this.reDeriveState();
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.editableTag !== nextState.diseaseProfile.tag)
            return true;
        return super.shouldReDeriveState(nextProps);
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c;
        (_a = state.diseaseProfile) !== null && _a !== void 0 ? _a : (state.diseaseProfile = this.getEditableDP((_b = nextProps.diseaseProfile) !== null && _b !== void 0 ? _b : _entity_1.clean_DiseaseProfile));
        (_c = state.tags) !== null && _c !== void 0 ? _c : (state.tags = frontend_2.VariableWrapper.dbTagsToUI(state.diseaseProfile.item.tagIds || []));
        state.editableTag = state.diseaseProfile.tag;
        return state;
    }
    // ######################## Logic ########################
    getEditableDP(diseaseProfile) {
        return new frontend_1.EditableDBItemV3(diseaseProfile, _entity_1.ModuleFE_DiseaseProfile)
            .setOnChanged(async (editable) => {
            this.setState({ diseaseProfile: editable });
        });
    }
    hasSystemTagIds() {
        const systemsTagId = frontend_2.ConfigKeyFE_SystemsTagIds.get();
        const variable = frontend_2.ModuleFE_Variable.cache.unique(this.state.diseaseProfile.item.variableId);
        return !!(variable === null || variable === void 0 ? void 0 : variable._tagIds.some(tagId => {
            const tag = frontend_2.ModuleFE_Tag.cache.unique(tagId);
            if (!tag)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find tag for id ${tagId}`);
            return (0, ts_common_1.arrayIncludesAny)(tag.tagIds, systemsTagId);
        }));
    }
    renderDiseaseDropdown() {
        const dp = this.state.diseaseProfile;
        const diseaseTagId = frontend_2.ConfigKeyFE_DiseasesTagId.get();
        const hasSystemTagIds = this.hasSystemTagIds();
        const variable = frontend_2.ModuleFE_Variable.cache.unique(dp.item.variableId);
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Disease Variable' },
            React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.editPermissionKey, value: variable === null || variable === void 0 ? void 0 : variable.name, forceLock: dp.item.completed },
                React.createElement(frontend_2.Component_VariableSelector, { onVariableSelected: this.onVariableSelected, selectedVariableId: dp.item.variableId, queryFilter: item => item._tagIds.includes(diseaseTagId), placeholder: 'Select Profile Variable', allowSelection: !dp.item._id, tailRenderer: () => {
                        if (!dp.item.variableId)
                            return '';
                        return React.createElement("div", Object.assign({ className: 'variable-info-tooltip-icon' }, frontend_1.openContent.tooltip.right('variable-info-tooltip', () => React.createElement(frontend_2.Tooltip_VariableInformation, { variable: frontend_2.ModuleFE_Variable.cache.unique(dp.item.variableId), showDirection: 'right' }), { contentHoverDelay: 200 })), "i");
                    } }),
                (!!dp.item.variableId && !hasSystemTagIds) &&
                    React.createElement("div", { className: 'missing-tag' }, "This Disease Profile's variable must have an etiology or system tag")));
    }
}
Dialog_DiseaseProfileEditor.defaultProps = {
    dialogId: 'disease-profile-editor',
};
exports.Dialog_DiseaseProfileEditor = Dialog_DiseaseProfileEditor;
