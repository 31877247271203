/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const config = {
	ModuleFE_Thunderstorm: {
		appName: 'Quai.MD'
	},
	ModuleFE_XHR: {
		// origin: "http://192.168.1.5:3000",
		origin: 'https://hcs.api.quai.md',
		timeout: 30000
	},
	frontend: {
		// origin: "http://192.168.1.5:3010",
		origin: 'https://quai-md.firebaseapp.com',
	},
	FirebaseModule: {
		local: {}
	},
	PushPubSubModule: {
		publicKeyBase64: ''
	},
	ForceUpgrade: {
		assertVersionUrl: '/v1/version/assert'
	},
	ModuleFE_FirebaseListener: {
		firebaseConfig: {
			apiKey: 'AIzaSyA1SsRZen0V3_4ZTbh0O3yvOXp7MaKJF1I',
			authDomain: 'quai-md.firebaseapp.com',
			databaseURL: 'https://quai-md-default-rtdb.firebaseio.com',
			projectId: 'quai-md',
			storageBucket: 'quai-md.appspot.com',
			messagingSenderId: '939972794621',
			appId: '1:939972794621:web:9d579d2754758005f68694',
			measurementId: 'G-GNQBD29M8Q'
		}
	}
};
