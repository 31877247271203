"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header_WithOptionMenu = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Header_WithOptionMenu.scss");
const icons_1 = require("../../../../icons");
class Header_WithOptionMenu extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.executeAsyncAction = (event, asyncAction, index, refresh) => {
            if ((0, ts_common_1.exists)(this.state.actionInProgress))
                return;
            this.setState({ actionInProgress: index }, async () => {
                refresh();
                try {
                    await asyncAction(event);
                    frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                }
                catch (err) {
                    this.logError(err);
                    this.setState({ actionInProgress: undefined }, () => refresh());
                }
            });
        };
        this.executeSyncAction = (event, action) => {
            if ((0, ts_common_1.exists)(this.state.actionInProgress))
                return;
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
            action(event);
        };
        this.renderTitle = () => {
            const className = (0, frontend_1._className)('quai-manager-ui__header__title', this.props.onTitleClick && 'clickable');
            return React.createElement(frontend_1.LL_H_C, { className: className, onClick: () => {
                    if (this.props.onTitleClick) {
                        if (!this.props.asyncTitleClick)
                            this.props.onTitleClick();
                        this.setState({ asyncTitleClickInProgress: true }, async () => {
                            var _a, _b;
                            try {
                                await ((_b = (_a = this.props).onTitleClick) === null || _b === void 0 ? void 0 : _b.call(_a));
                            }
                            catch (err) {
                                this.logError(err);
                                this.setState({ asyncTitleClickInProgress: false });
                            }
                        });
                    }
                } },
                (0, ts_common_1.resolveContent)(this.props.title),
                (0, ts_common_1.resolveContent)(this.props.iconToRender),
                this.state.asyncTitleClickInProgress && React.createElement(frontend_1.TS_ButtonLoader, null));
        };
        this.renderSubtitle = () => {
            if (!this.props.subtitle)
                return;
            return React.createElement(frontend_1.LL_H_C, { className: 'quai-manager-ui__header__subtitle' }, (0, ts_common_1.resolveContent)(this.props.subtitle));
        };
        this.renderMenuIcon = () => {
            if (!this.props.menuActions)
                return;
            return React.createElement(icons_1.ICONS.more.component, Object.assign({ className: 'quai-manager-ui__header__actions-icon' }, frontend_1.openContent.popUp.bottom('quai-manager-ui__header__actions', this.renderMenu, 10)));
        };
        this.renderMenu = (refreshPopup) => {
            var _a;
            return React.createElement(React.Fragment, null, (_a = this.props.menuActions) === null || _a === void 0 ? void 0 : _a.map((action, index) => {
                const actionInProgress = this.state.actionInProgress;
                if (actionInProgress === index)
                    return React.createElement("div", { key: action.label, className: 'quai-manager-ui__loader-wrapper' },
                        React.createElement(frontend_1.TS_ButtonLoader, null));
                const className = (0, frontend_1._className)('quai-manager-ui__header__actions__action', (0, ts_common_1.exists)(actionInProgress) && 'disabled');
                return React.createElement("div", { key: action.label, className: className, onClick: async (event) => {
                        if (!action.isAsync)
                            return this.executeSyncAction(event, action.action);
                        // @ts-ignore
                        this.executeAsyncAction(event, action.action, index, refreshPopup[0]);
                    } }, action.label);
            }));
        };
    }
    shouldComponentUpdate() {
        return true;
    }
    deriveStateFromProps(nextProps, state) {
        return state;
    }
    render() {
        const className = (0, frontend_1._className)('quai-manager-ui__header', this.props.className);
        return React.createElement(frontend_1.LL_H_C, { className: className },
            React.createElement(frontend_1.LL_V_L, { className: 'quai-manager-ui__header__main' },
                this.renderTitle(),
                this.renderSubtitle()),
            this.renderMenuIcon());
    }
}
exports.Header_WithOptionMenu = Header_WithOptionMenu;
