"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_StructuredComplexLogic = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const Editor_Term_1 = require("../editors/Editor_Term");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_StructuredComplexLogic.scss");
const utils_1 = require("../../../../../../../_entity/expression/shared/utils");
class Component_StructuredComplexLogic extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        //######################### Logic #########################
        super(...arguments);
        this.deleteSubTerm = async (index) => {
            const editableSubTerms = this.state.editable.editProp('subTerms', []);
            if (editableSubTerms.item.length === 1) {
                return this.props.resetExpression();
            }
            await editableSubTerms.removeArrayItem(index);
        };
        this.addSubTerm = async () => {
            const editableSubTerms = this.state.editable.editProp('subTerms', []);
            const previousSubTerm = editableSubTerms.get(editableSubTerms.item.length - 1);
            const newSubTerm = (0, utils_1.getEmptyTerm)();
            if (!previousSubTerm)
                return editableSubTerms.updateArrayAt(newSubTerm);
            // maintain left-hand values from previous sub term
            newSubTerm.atoms = previousSubTerm.atoms.reduce((atoms, currentAtom, currentIndex) => {
                currentAtom.leftHand = previousSubTerm.atoms[currentIndex].leftHand;
                atoms.push(currentAtom);
                return atoms;
            }, []);
            await editableSubTerms.updateArrayAt(newSubTerm);
        };
        //######################### Render #########################
        this.renderSubTerms = () => {
            const subTerms = this.state.editable.editProp('subTerms', [(0, utils_1.getEmptyTerm)()]);
            return React.createElement(frontend_1.LL_V_L, { className: 'sub-term-list' }, subTerms.item.map((subTerm, index) => {
                const editableSubTerm = subTerms.editProp(index, utils_1.getEmptyTerm);
                return React.createElement(frontend_1.LL_V_L, { className: 'sub-term-list__sub-term-container', key: index },
                    React.createElement("div", { className: 'sub-term-list__title' }, `Sub-Term ${index + 1}`),
                    React.createElement(Editor_Term_1.Editor_Term, { isSubTerm: true, termAtoms: this.state.editable.get('atoms'), deleteTerm: () => this.deleteSubTerm(index), editable: editableSubTerm }));
            }));
        };
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'structured-complex-logic' },
            React.createElement("div", { className: 'structured-complex-logic__title' }, "Edit Sub-Terms"),
            this.renderSubTerms(),
            React.createElement(frontend_1.TS_Button, { className: 'add-entity-btn', onClick: this.addSubTerm }, "Add Sub-Term"));
    }
}
exports.Component_StructuredComplexLogic = Component_StructuredComplexLogic;
