"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Base_ResolutionFunctionEditorPanel = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Base_ResolutionFunctionEditorPanel.scss");
class Base_ResolutionFunctionEditorPanel extends frontend_1.ComponentSync {
    constructor() {
        //######################### Protected #########################
        super(...arguments);
        this.renderHeader = () => {
            return undefined;
        };
        this.renderBody = () => {
            return undefined;
        };
        this.renderButtons = () => {
            return undefined;
        };
        //######################### Privates #########################
        this.panelHeader = () => {
            const header = this.renderHeader();
            return React.createElement("div", { className: 'resolution-function-editor-panel__header' }, header);
        };
        this.panelBody = () => {
            const body = this.renderBody();
            return React.createElement(frontend_1.LL_V_L, { className: 'resolution-function-editor-panel__body' }, body);
        };
        this.panelButtons = () => {
            const buttons = this.renderButtons();
            return React.createElement(frontend_1.LL_H_C, { className: 'resolution-function-editor-panel__buttons' },
                React.createElement(frontend_1.TS_Button, { onClick: this.props.hidePanel }, "Close"),
                buttons);
        };
    }
    //######################### render #########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: this.panelId, className: 'resolution-function-editor-panel' },
            this.panelHeader(),
            this.panelBody(),
            this.panelButtons());
    }
}
exports.Base_ResolutionFunctionEditorPanel = Base_ResolutionFunctionEditorPanel;
