"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_VariableEditor = void 0;
const React = require("react");
require("./Dialog_VariableEditor.scss");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const _shared_1 = require("../../../../_shared");
const Editors_1 = require("../../values/Editors");
const wrappers_1 = require("../../../../wrappers");
const q_components_1 = require("../../../q-components");
const styles_1 = require("@app/styles");
const shared_dialogs_1 = require("../../../shared-dialogs");
const resolvers_1 = require("../../resolvers");
const shared_components_1 = require("../../../shared-components");
const Component_RelationsTable_1 = require("../components/Component_RelationsTable/Component_RelationsTable");
const Component_AttributeCard_1 = require("../components/Component_AttributeCard/Component_AttributeCard");
const _entity_1 = require("../../../../_entity");
const ui_components_1 = require("../../../../../_entity/macro-tag/frontend/ui-components");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
const http_codes_1 = require("@nu-art/ts-common/core/exceptions/http-codes");
const uiValueTypeRange = 'Range';
const uiValueTypeEnumerated = 'Enumerated';
const uiValueTypeYesNo = 'YesNo';
const uiValueTypePosNeg = 'PosNeg';
const uiValueTypeStandardRange = 'Standard Range';
const uiValueTypeTime = 'Time';
const dropdownOptions = [
    uiValueTypeRange,
    uiValueTypeEnumerated,
    uiValueTypeYesNo,
    uiValueTypePosNeg,
    uiValueTypeStandardRange,
    uiValueTypeTime
];
const EditorMap = {
    [_entity_1.valueType_Range]: Editors_1.CompoundRangeEditor,
    [_entity_1.valueType_Enumerated]: Editors_1.CompoundEnumeratedEditor,
    [_entity_1.valueType_StandardRange]: Editors_1.NormalRangeEditor,
    [_shared_1.valueType_Time]: Editors_1.Editor_TimeValue
};
const initialValueData = {
    [uiValueTypeEnumerated]: _entity_1.ValType_CompoundEnumerated,
    [uiValueTypeRange]: _entity_1.ValType_CompoundRange,
    [uiValueTypeStandardRange]: _entity_1.ValType_StandardRange,
    [uiValueTypeYesNo]: _entity_1.ValType_YesNo,
    [uiValueTypePosNeg]: _entity_1.ValType_PosNeg,
    [uiValueTypeTime]: _shared_1.ValType_Time
};
class Dialog_VariableEditor extends frontend_1.TS_Dialog {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        // ######################## Logic ########################
        this.saveVariable = async () => {
            var _a, _b, _c;
            this.setState({ dialogIsBusy: true });
            this.logInfo(`Trying to create variable`, this.state.variable);
            let toastContent;
            const attributes = this.state.uiAttributeCouplings ? (0, ts_common_1.cloneArr)(this.state.uiAttributeCouplings) : [];
            try {
                //Validate the variable, nlp-data and references
                // ModuleFE_Variable.validateImpl(this.state.variable as PreDB<DB_Variable>);
                _entity_1.ModuleFE_NLPData.validateImpl(this.state.preDBNlpData);
                this.state.preDBReferences.map(ref => _entity_1.ModuleFE_Reference.validateImpl(ref));
                (_a = this.state.editableExpression) === null || _a === void 0 ? void 0 : _a.validate();
                const hasError = (_b = this.state.editableExpression) === null || _b === void 0 ? void 0 : _b.hasError();
                // @ts-ignore Hack.. cannot ignore expression validation otherwise
                if (hasError && !((0, ts_common_1._keys)(hasError).length === 1 && hasError['associatedVar']))
                    throw new ts_common_1.ValidationException('Error validating object');
                //Set data
                this.state.variable.tagIds = wrappers_1.VariableWrapper.getTagsForDB(this.state.uiTags);
                const requestBody = {
                    preDBVar: this.state.variable,
                    uiAttributeCouplings: attributes,
                    preDBNlpData: this.state.preDBNlpData,
                    preDBReferences: this.state.preDBReferences,
                    uiExpression: (_c = this.state.editableExpression) === null || _c === void 0 ? void 0 : _c.item,
                    uiValue: this.state.uiValue,
                };
                //Send request
                this.logInfo(requestBody);
                const variable = await _entity_1.ModuleFE_Variable._v1.createUIVariable(requestBody).executeSync();
                this.logInfo(`Created variable`, variable);
                toastContent = (0, q_components_1.QToastContent)({
                    toastType: 'success',
                    content: `Variable ${this.state.variable._id ? 'Updated' : 'Created'} Successfully`
                });
                new frontend_1.ToastBuilder().setContent(toastContent).setDuration(5000).show();
                frontend_1.ModuleFE_Dialog.close();
            }
            catch (e) {
                if (e.responseCode === http_codes_1.HttpCodes._4XX.FAILED_VALIDATION.code) {
                    this.logError('FAILED_VALIDATION');
                    toastContent = (0, q_components_1.QToastContent)({
                        toastType: 'error',
                        content: `Variable fields failed validation: ${(0, ts_common_1.__stringify)((0, ts_common_1._keys)(e.errorResponse.error.result).filter(_key => _key.charAt(0) !== '_').join(', '))}`
                    });
                    this.logWarning('Validation error result:', e.errorResponse.error.result);
                    new frontend_1.ToastBuilder().setContent(toastContent).setDuration(5000).show();
                }
                // if ((e as any).errorResponse.error.exceptionType === 'ValidationException') {
                // 	this.logError('ValidationException');
                // 	toastContent = QToastContent({
                // 		toastType: 'error',
                // 		content: `Variable fields failed validation: ${__stringify((_keys((e as any).errorResponse.error.result) as string[]).filter(_key => _key.charAt(0) !== '_').join(', '))}`
                // 	});
                // 	this.logWarning('Validation error result:', (e as any).errorResponse.error.result);
                // 	new ToastBuilder().setContent(toastContent).setDuration(5000).show();
                // }
                this.setState({ dialogIsBusy: false });
            }
            finally {
                //todo has-dependencies will move to the defaultOnError listener in ModuleFE_AppModule
                // Need to add validation errors to ModuleFE_AppModule as well
            }
        };
        this.canBeSaved = () => {
            var _a;
            if (!wrappers_1.VariableWrapper.hasSourceSubTags(this.state.uiTags)) {
                // this.logWarning('missing source tag');
                return false;
            }
            if (!this.state.uiValue.type) {
                // this.logWarning('missing value type');
                return false;
            }
            if (!!this.state.variable._id && this.state.uiValue.type && this.state.uiValue.type !== ((_a = _entity_1.ModuleFE_Value.cache.unique(this.state.uiValue._id)) === null || _a === void 0 ? void 0 : _a.type)) {
                // this.logWarning(`value types don't match`);
                return false;
            }
            if (!this.state.variable.role) {
                return false;
            }
            //todo Need to add more rules, maybe call the validator? Though, this is called quite a lot...
            return true;
        };
        this.implementValue = (type) => {
            const uiValue = (0, ts_common_1.deepClone)(initialValueData[type]);
            let editableExpression;
            if (uiValue.type === _entity_1.valueType_StandardRange)
                editableExpression = this.getEditableDBItemV3(uiValue);
            this.setState({ uiValue: uiValue, editableExpression: editableExpression });
            this.forceUpdate();
        };
        this.canEditValue = () => {
            var _a;
            const option = this.getOptionsFromValue((_a = this.state) === null || _a === void 0 ? void 0 : _a.uiValue);
            return ['Enumerated',
                'Range',
                'Standard Range', 'Time'].includes(option);
        };
        this.toastInfo = (message) => {
            new frontend_1.ToastBuilder().setDuration(2 * ts_common_1.Second)
                .setContent((0, q_components_1.QToastContent)({ iconKey: 'attention', content: message, toastType: 'info' }))
                .show();
        };
        this.upsertReference = async (refId, refType) => {
            var _a;
            if ((_a = this.state.preDBReferences) === null || _a === void 0 ? void 0 : _a.find(item => item.type === refType && item.data.id === refId))
                return this.toastInfo(`${refType}: ${refId} - Already assigned to this variable`);
            refType === 'snomed' ? this.setState({ snomedLoad: true }) : this.setState({ loincLoad: true });
            const ref = { type: refType, data: { id: refId } };
            const newRef = await _entity_1.ModuleFE_Reference.v1.upsert(ref).executeSync();
            this.state.preDBReferences.push(newRef);
            refType === 'snomed' ? this.setState({ snomedLoad: false }) : this.setState({ loincLoad: false });
        };
        this.onDelete = async () => {
            shared_dialogs_1.WarningDialog.showDelete({
                title: 'Delete Variable',
                executeButtonText: 'Delete',
                warningMessages: [`Are you sure you want to delete '${this.state.variable.name}'?`],
                onConfirm: async () => {
                    this.setState({ dialogIsBusy: true });
                    await (0, frontend_1.performAction)(async () => {
                        await _entity_1.ModuleFE_Variable.v1.delete({ _id: this.state.variable._id }).executeSync();
                    }, {
                        type: 'toast',
                        successContent: React.createElement(q_components_1.QToastContent, { toastType: 'success', iconKey: 'v', content: 'Variable Deleted Successfully' }),
                        duration: 10000
                    }, this.state.variable.name, false, true);
                    this.setState({ dialogIsBusy: false });
                    frontend_1.ModuleFE_Dialog.close();
                }
            });
        };
        this.getFlatTagIds = () => {
            return (0, ts_common_1.filterDuplicates)((0, ts_common_1.flatArray)(this.state.uiTags.map(group => [group.key._id, ...group.value.map(ts_common_1.dbObjectToId)])));
        };
        this.implementMacroTag = (macroTag) => {
            macroTag.tagGroups.forEach(group => {
                let existing;
                //Assign existing uiGroup by key id;
                existing = this.state.uiTags.find(uiGroup => uiGroup.key._id === group.key);
                //No group with this key yet
                if (!existing) {
                    const keyTag = _entity_1.ModuleFE_Tag.cache.unique(group.key);
                    if (!keyTag)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not find tag with id ${group.key}`);
                    existing = { key: keyTag, value: [] };
                    this.state.uiTags.push(existing);
                }
                group.value.forEach(id => {
                    if (existing.value.find(tag => tag._id === id))
                        return;
                    const tag = _entity_1.ModuleFE_Tag.cache.unique(id);
                    if (!tag)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not find tag with id ${id}`);
                    existing.value.push(tag);
                });
            });
            this.forceUpdate();
        };
        this.deleteMacroTag = (macroTag) => {
            macroTag.tagGroups.forEach(group => {
                const existing = this.state.uiTags.find(uiGroup => uiGroup.key._id === group.key);
                if (!existing)
                    throw new ts_common_1.ThisShouldNotHappenException(`Could not find UITagGroup with key id ${group.key}`);
                existing.value = existing.value.filter(uiTag => !group.value.includes(uiTag._id));
                if (!existing.value.length) {
                    (0, ts_common_1.removeItemFromArray)(this.state.uiTags, existing);
                }
            });
            this.forceUpdate();
        };
        // ######################## Render ########################
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("span", { className: "header__title" }, (this.state.variable._id ? 'Edit Variable' : 'Add Variable') + ` - ${this.state.variable.name}`),
                React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => frontend_1.ModuleFE_Dialog.close() }));
        };
        this.buttons = () => {
            const leftButtons = [];
            const rightButtons = [
                {
                    content: 'Cancel',
                    onClick: () => frontend_1.ModuleFE_Dialog.close(),
                    disabled: this.state.dialogIsBusy,
                },
                {
                    content: 'Save',
                    renderer: frontend_1.TS_Dialog.busyButton,
                    disabled: this.state.dialogIsBusy || !this.canBeSaved(),
                    className: (0, frontend_1._className)(this.canBeSaved() && 'desired-action-button'),
                    onClick: this.saveVariable,
                }
            ];
            if (this.state.variable._id)
                leftButtons.push({
                    content: 'Delete',
                    renderer: frontend_1.TS_Dialog.busyButton,
                    className: 'delete-button',
                    disabled: this.state.dialogIsBusy || !this.state.variable._id,
                    onClick: this.onDelete,
                });
            return {
                left: leftButtons.length ? leftButtons : undefined,
                right: rightButtons,
            };
        };
        this.renderBody = () => {
            const tabs = [
                {
                    title: 'Variable',
                    uid: 'variable',
                    content: this.renderVariableTab(),
                },
                {
                    title: 'Values',
                    uid: 'values',
                    content: this.renderValuesTab(),
                    disabled: !this.canEditValue(),
                },
                {
                    title: 'Attributes',
                    uid: 'attributes',
                    content: this.renderAttributesTab(),
                },
                {
                    title: 'NLP and Other Properties',
                    uid: 'nlp-and-other',
                    content: this.renderNLPAndOtherTab(),
                },
                {
                    title: 'Relations',
                    uid: 'relations',
                    content: this.renderRelationsTab(),
                    disabled: !this.state.uiValue.type
                }
            ];
            return React.createElement(frontend_1.TS_Tabs, { tabs: tabs });
        };
        this.renderRefLink = (ref) => {
            return React.createElement("div", null,
                React.createElement(frontend_1.TS_Link, { url: ref.type === 'snomed' ? (0, resolvers_1.resolveSnomedLink)(ref.data.id) : (0, resolvers_1.resolveLoincLink)(ref.data.id), className: 'ref-link', target: '_blank' }, ref.data.id));
        };
        this.renderSynonyms = () => {
            var _a;
            const synonyms = (_a = this.state.preDBNlpData) === null || _a === void 0 ? void 0 : _a.synonyms;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Synonyms', className: 'dialog__main__section-item synonyms' },
                React.createElement(q_components_1.QEditableEntryList, { entries: synonyms, pasteOnly: false, placeholder: '+' }));
        };
        this.renderReferenceWarning = (ref) => {
            const variablesWithThisRef = [];
            const variables = _entity_1.ModuleFE_Variable.cache.filter(v => v._id !== this.state.variable._id && !!v.referenceIds.find(i => { var _a, _b; return ((_b = (_a = _entity_1.ModuleFE_Reference.cache.unique(i)) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id.trimEnd()) === (ref === null || ref === void 0 ? void 0 : ref.data.id.trimEnd()); }));
            variablesWithThisRef.push(...variables.map(i => i.name));
            if (!variablesWithThisRef.length)
                return;
            const renderer = () => React.createElement(frontend_1.LL_V_L, { className: 'reference-used-tooltip' },
                React.createElement("div", { className: 'reference-used-tooltip__title' }, "This id appears in other variables"),
                React.createElement(frontend_1.LL_H_C, { className: 'reference-used-tooltip__ref' },
                    React.createElement("div", { className: 'reference-used-tooltip__ref-names' }, variablesWithThisRef.join(', '))));
            return React.createElement(styles_1.ICONS.information.component, Object.assign({}, frontend_1.openContent.tooltip.top('info-tool-tip', renderer), { className: 'entry-tail' }));
        };
        this.renderMacroTags = () => {
            const tagIds = this.getFlatTagIds();
            const macroTags = _entity_1.ModuleFE_MacroTag.cache.filter(macroTag => !!macroTag.__tagIds && (0, ts_common_1.arrayIncludesAll)(tagIds, macroTag.__tagIds));
            const existingIds = macroTags.map(ts_common_1.dbObjectToId);
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Macro Tags', className: 'dialog__main__section-item' },
                React.createElement(frontend_1.LL_H_C, { className: 'macro-tags' },
                    macroTags.map(macroTag => React.createElement(frontend_1.LL_H_C, { key: macroTag._id, className: 'macro-tag' },
                        React.createElement(styles_1.ICONS.x.component, { onClick: () => this.deleteMacroTag(macroTag) }),
                        macroTag.label)),
                    React.createElement(ui_components_1.DropDown_MacroTag.selectable, { queryFilter: macroTags => !existingIds.includes(macroTags._id), onSelected: this.implementMacroTag })));
        };
    }
    static show(selectedCategoryId, variable) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_VariableEditor, { tagId: selectedCategoryId, variable: variable }), () => {
            return false;
        });
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c, _d, _e;
        state.attributesIds = [];
        //Gather Value Options
        state.categoryMap = (0, ts_common_1.arrayToMap)(_entity_1.ModuleFE_Tag.cache.all(), ts_common_1.dbObjectToId);
        state.measurementUnitsMap = (0, ts_common_1.arrayToMap)(_entity_1.ModuleFE_MeasurementUnit.cache.all(), ts_common_1.dbObjectToId);
        state.valuesMap = (0, ts_common_1.arrayToMap)(_entity_1.ModuleFE_Value.cache.all(), ts_common_1.dbObjectToId);
        state.sourceTagId = _entity_1.ConfigKeyFE_SourceTagId.get();
        const getTemplateVariable = () => {
            //Find the group tag
            let groupTag;
            const tag = state.categoryMap[this.props.tagId];
            if (tag.tagIds.length === 0)
                groupTag = tag;
            else
                groupTag = state.categoryMap[tag.tagIds.find(item => state.categoryMap[item].tagIds.length === 0)];
            if (!groupTag)
                throw new ts_common_1.BadImplementationException('Gave a tag which is not a group or belongs to no group');
            const tagsIds = [...tag.tagIds, tag._id].filter(i => i !== groupTag._id);
            return {
                name: '',
                tagIds: [{ key: groupTag._id, value: tagsIds }],
                perspective: _shared_1.perspectives[0],
                role: undefined,
                otherProperties: {},
                _nameInLowercase: '',
                _tagIds: [groupTag._id, ...tagsIds]
            };
        };
        //Retain current variable
        state.variable = ((_a = this.state) === null || _a === void 0 ? void 0 : _a.variable) || nextProps.variable ? (0, ts_common_1.cloneObj)(nextProps.variable) : getTemplateVariable();
        const variableWrapper = new wrappers_1.VariableWrapper(state.variable);
        state.uiTags || (state.uiTags = variableWrapper.getUITags());
        state.preDBNlpData || (state.preDBNlpData = variableWrapper.getNlpData() || (0, ts_common_1.deepClone)(_shared_1.Template_BasicNLP));
        state.preDBReferences || (state.preDBReferences = variableWrapper.getReferences() || []);
        state.uiAttributeCouplings || (state.uiAttributeCouplings = variableWrapper.getAttributes() || []);
        state.uiValue || (state.uiValue = variableWrapper.getUIValue() || {});
        //Initialize dimensions if value has units
        if (!state.variable.otherProperties.dimensions && state.uiValue && [_entity_1.valueType_Range,
            _entity_1.valueType_SubRange,
            _entity_1.valueType_StandardRange].includes(state.uiValue.type)) {
            const _value = state.uiValue;
            state.variable.otherProperties.dimensions = _entity_1.ModuleFE_MeasurementUnit.getDimensionForUnit(((_c = (_b = _value.data) === null || _b === void 0 ? void 0 : _b.measurementUnit) === null || _c === void 0 ? void 0 : _c.unitAsString) || '');
        }
        state.selectedOption = ((_d = this.state) === null || _d === void 0 ? void 0 : _d.selectedOption) || this.getOptionsFromValue(state.uiValue);
        const attSets = _entity_1.ModuleFE_AttributeSet.cache.all();
        attSets.forEach(set => {
            var _a, _b;
            switch (set.type) {
                case 'Tag':
                    if ((_b = (_a = state.variable) === null || _a === void 0 ? void 0 : _a._tagIds) === null || _b === void 0 ? void 0 : _b.includes(set.entityId))
                        state.attributesIds.push(...set.attributeIds);
                    break;
                case 'Variable':
                    if (set.entityId === state.variable._id)
                        state.attributesIds.push(...set.attributeIds);
            }
        });
        if (state.uiValue.type === _entity_1.valueType_StandardRange) {
            const relatedExpression = _entity_1.ModuleFE_Expression.cache.find(expr => expr.associatedVar === state.variable._id
                && expr.expressionType === consts_1.ExpressionType_NormalRange.dbKey);
            (_e = state.editableExpression) !== null && _e !== void 0 ? _e : (state.editableExpression = this.getEditableDBItemV3(state.uiValue, relatedExpression));
        }
        return state;
    }
    getEditableDBItemV3(uiValue, relatedExpression) {
        return new frontend_1.EditableDBItemV3(relatedExpression !== null && relatedExpression !== void 0 ? relatedExpression : (0, _shared_1.Empty_NormalRangeExpression)(uiValue), _entity_1.ModuleFE_Expression);
    }
    getOptionsFromValue(value) {
        if (!value)
            return undefined;
        switch (value.type) {
            case _entity_1.valueType_Tuple: {
                if (value._id === _entity_1.valueID_YesNo)
                    return 'YesNo';
                if (value._id === _entity_1.valueID_PosNeg)
                    return 'PosNeg';
                break;
            }
            case _entity_1.valueType_Enumerated: {
                return 'Enumerated';
            }
            case _entity_1.valueType_Range: {
                return 'Range';
            }
            case _entity_1.valueType_StandardRange:
                return 'Standard Range';
            case _shared_1.valueType_Time:
                return 'Time';
            default:
                return undefined;
        }
    }
    //######################## Render - Variable Tab
    renderVariableTab() {
        return React.createElement(frontend_1.LL_H_C, { className: 'match_parent', style: { padding: '20px', gap: '20px' } },
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section' },
                this.renderVariableName(),
                this.renderValuesType(),
                this.renderIsAttribute(),
                React.createElement(frontend_1.LL_H_C, { className: 'match_width dialog__main__section-item' },
                    this.renderVariableRole(),
                    this.renderVariablePerspective()),
                this.renderRefList('snomed', this.state.snomedLoad),
                this.renderRefList('loinc', this.state.loincLoad),
                this.renderSynonyms()),
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section' },
                this.renderMacroTags(),
                this.renderTags()));
    }
    renderVariableName() {
        var _a;
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Variable Name', className: 'dialog__main__section-item' },
            React.createElement(frontend_1.TS_Input, { id: `add-variable__variable-name`, placeholder: 'Enter Variable Name', type: "text", value: ((_a = this.state.variable) === null || _a === void 0 ? void 0 : _a.name) || '', onChange: (value => {
                    this.state.variable.name = value;
                    this.forceUpdate();
                }) }));
    }
    renderIsAttribute() {
        var _a;
        return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Is Attribute', className: 'dialog__main__section-item', style: { justifyContent: 'flex-start' } },
            React.createElement(q_components_1.Q_Checkbox, { checked: (_a = this.state.variable) === null || _a === void 0 ? void 0 : _a.isAttribute, onCheck: () => {
                    this.state.variable.isAttribute = !this.state.variable.isAttribute;
                    this.forceUpdate();
                } }));
    }
    renderValuesType() {
        var _a;
        const adapter = (0, frontend_1.SimpleListAdapter)(dropdownOptions, (item) => React.createElement("div", null, item.item));
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Values Type', className: 'dialog__main__section-item' },
            React.createElement(frontend_1.TS_DropDown, { disabled: !!this.state.variable._id || (!!this.state.variable._id && this.state.uiValue.type && this.state.uiValue.type === ((_a = _entity_1.ModuleFE_Value.cache.unique(this.state.uiValue._id)) === null || _a === void 0 ? void 0 : _a.type)), adapter: adapter, selected: this.getOptionsFromValue(this.state.uiValue), onSelected: this.implementValue }));
    }
    renderVariableRole() {
        var _a;
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Role', className: 'dialog__main__section-item' },
            React.createElement(frontend_1.TS_Radio, { className: 'label-radio', values: [..._shared_1.roles], groupName: 'roles', checked: (_a = this.state.variable) === null || _a === void 0 ? void 0 : _a.role, onCheck: (value) => {
                    this.state.variable.role = value;
                    this.forceUpdate();
                } }));
    }
    renderVariablePerspective() {
        var _a;
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Perspective', className: 'dialog__main__section-item' },
            React.createElement(frontend_1.TS_Radio, { className: 'label-radio', values: [..._shared_1.perspectives], groupName: 'perspectives', checked: (_a = this.state.variable) === null || _a === void 0 ? void 0 : _a.perspective, onCheck: (value) => {
                    this.state.variable.perspective = value;
                    this.forceUpdate();
                } }));
    }
    renderRefList(refType, isLoading) {
        var _a;
        const refs = (_a = this.state.preDBReferences) === null || _a === void 0 ? void 0 : _a.filter(i => i.type === refType);
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: `${refType.charAt(0).toUpperCase()}${refType.slice(1)} Ids`, className: 'dialog__main__section-item reference' },
            React.createElement(q_components_1.QEditableEntryList, { entries: refs, removeEntry: _ref => {
                    this.setState({ preDBReferences: this.state.preDBReferences.filter(item => item.data.id !== _ref.data.id ? true : item.type !== _ref.type) });
                    this.forceUpdate();
                }, addEntry: val => this.upsertReference(val, refType), isLoading: isLoading, pasteOnly: true, render: this.renderRefLink, tailRenderer: this.renderReferenceWarning, placeholder: '+' }));
    }
    renderTags() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Tags', className: 'dialog__main__section-item tags' },
            React.createElement(q_components_1.QScrollWrapper, null,
                React.createElement(shared_components_1.Component_TagEditor, { tags: this.state.uiTags, mandatoryRootTag: true, groupTagFilter: () => true, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id, onChange: () => this.forceUpdate() })));
    }
    //######################## Render - Values Tab
    renderValuesTab() {
        var _a;
        const type = (_a = this.state.uiValue) === null || _a === void 0 ? void 0 : _a.type;
        if (!type || (type !== _entity_1.valueType_Enumerated && type !== _entity_1.valueType_Range && type !== _entity_1.valueType_StandardRange && type !== _shared_1.valueType_Time))
            return React.createElement("div", { className: 'edit-not-allowed' },
                "Edit not allowed for value of type ",
                type);
        // this.logInfo('-----uiValue!!!!!------', this.state.uiValue);
        const Renderer = EditorMap[type];
        return React.createElement(Renderer, { editable: this.state.editableExpression, value: this.state.uiValue });
    }
    //######################## Render - Attributes Tab
    // @ts-ignore
    renderAttributesTab() {
        const attributes = this.state.uiAttributeCouplings || [];
        if (!attributes)
            return '';
        return React.createElement(frontend_1.LL_H_C, { className: 'dialog__main__section attribute-list' }, attributes.map(att => {
            return React.createElement(Component_AttributeCard_1.Component_AttributeCard, { key: att.variable._id, attribute: att });
        }));
    }
    //######################## Render - Nlp & Other Tab
    renderNLPAndOtherTab() {
        return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main nlp-and-other-tab', style: { padding: '20px' } },
            this.renderDimensions(),
            this.renderSpecimen());
    }
    renderDimensions() {
        var _a;
        const dimensions = (_a = this.state.variable.otherProperties) === null || _a === void 0 ? void 0 : _a.dimensions;
        return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section-item' },
            React.createElement("div", { className: 'dialog__field-title' }, "Dimensions"),
            React.createElement("div", { className: 'dialog__field-content' }, dimensions));
    }
    renderSpecimen() {
        var _a;
        const specimen = (_a = this.state.variable.otherProperties) === null || _a === void 0 ? void 0 : _a.specimen;
        return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section-item' },
            React.createElement("div", { className: 'dialog__field-title' }, "Specimen"),
            React.createElement("div", { className: 'dialog__field-content' }, specimen));
    }
    renderRelationsTab() {
        const variableValue = _entity_1.ModuleFE_Value.cache.find((v) => {
            return v._id ===
                this.state.uiValue._id;
        });
        if (!variableValue)
            return React.createElement("div", { className: 'match_parent' }, "value not found");
        return React.createElement(Component_RelationsTable_1.default, { variable: this.state.variable, value: variableValue });
    }
}
Dialog_VariableEditor.defaultProps = {
    dialogId: 'variable-editor',
};
exports.Dialog_VariableEditor = Dialog_VariableEditor;
