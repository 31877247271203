"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip_Information = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Tooltip_Information.scss");
const ts_common_1 = require("@nu-art/ts-common");
class Tooltip_Information extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderContent = () => {
            return React.createElement("div", { className: 'info-tooltip__content' },
                this.props.title && this.renderTitle(this.props.title),
                this.props.paragraphs && this.props.paragraphs.map(paragraph => {
                    return this.renderParagraph(paragraph);
                }));
        };
        this.renderTitle = (title) => {
            return React.createElement("span", { className: 'info-tooltip__title' }, title);
        };
        this.renderParagraph = (paragraph) => {
            return React.createElement("p", { key: (0, ts_common_1.generateHex)(8), className: 'info-tooltip__paragraph' }, paragraph);
        };
    }
    deriveStateFromProps(nextProps) {
    }
    render() {
        return React.createElement("div", Object.assign({ className: 'info-tooltip' }, frontend_1.openContent.tooltip.left('tooltip-info', this.renderContent)), "i");
    }
}
exports.Tooltip_Information = Tooltip_Information;
