"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_MacroTagEditor = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const _entity_1 = require("../../../_entity");
const styles_1 = require("@app/styles");
require("./Dialog_MacroTagEditor.scss");
const Component_MacroTagEditor_1 = require("../Component_MacroTagEditor/Component_MacroTagEditor");
class Dialog_MacroTagEditor extends frontend_1.TS_Dialog {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        this.createNewMacroTag = async () => {
            const action = async () => {
                try {
                    await this.state.macroTag.save();
                    this.closeDialog();
                }
                catch (err) {
                    this.logError(err);
                }
            };
            this.performAction(action);
        };
        this.deleteMacro = async () => {
            this.performAction(async () => {
                try {
                    await this.state.macroTag.delete();
                    this.closeDialog();
                }
                catch (err) {
                    this.logError(err);
                }
            });
        };
        // ######################### Header #########################
        this.renderHeader = () => {
            const macroTag = this.state.macroTag.item;
            const title = !macroTag._id ? 'Create New Macro Tag' : `${this.state.editMode ? 'Edit' : 'View'} ${macroTag.label}`;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'header__title' }, title),
                React.createElement(styles_1.ICONS.x.component, { onClick: () => this.closeDialog() }));
        };
        // ######################### Body #########################
        this.renderBody = () => {
            return React.createElement(Component_MacroTagEditor_1.Component_MacroTagEditor, { editMode: this.state.editMode, editable: this.state.macroTag });
        };
        // ######################### Buttons #########################
        this.buttons = () => {
            const macroTag = this.state.macroTag.item;
            const editMode = this.state.editMode;
            return {
                right: (0, ts_common_1.filterInstances)([
                    (!macroTag._id ? { content: 'Cancel', onClick: () => this.closeDialog() } : undefined),
                    (!macroTag._id ? {
                        content: 'Create New',
                        onClick: this.createNewMacroTag,
                        renderer: frontend_1.TS_Dialog.busyButton
                    } : undefined)
                ]),
                left: (0, ts_common_1.filterInstances)([
                    ((macroTag._id && editMode) ? {
                        content: 'Delete',
                        onClick: this.deleteMacro,
                        renderer: frontend_1.TS_Dialog.busyButton
                    } : undefined)
                ])
            };
        };
    }
    static show(macroTagId, editMode) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_MacroTagEditor, { macroTagId: macroTagId, editMode: editMode }), () => false);
    }
    // ######################### Lifecycle #########################
    deriveStateFromProps(nextProps, state) {
        var _a;
        (_a = state.macroTag) !== null && _a !== void 0 ? _a : (state.macroTag = this.getEditableMacro(nextProps.macroTagId));
        state.editMode = state.macroTag.item._id ? !!nextProps.editMode : true;
        return state;
    }
    // ######################### Logic #########################
    getEditableMacro(macroId) {
        if (!macroId)
            return new frontend_1.EditableDBItemV3({ tagGroups: [] }, _entity_1.ModuleFE_MacroTag)
                .setOnChanged(async (editable) => this.setState({ macroTag: editable }));
        const macroTag = _entity_1.ModuleFE_MacroTag.cache.unique(macroId);
        if (!macroTag)
            throw new ts_common_1.MUSTNeverHappenException(`Could not get macro tag for id ${macroId}`);
        return new frontend_1.EditableDBItemV3(macroTag, _entity_1.ModuleFE_MacroTag)
            .setOnChanged(async (editable) => this.setState({ macroTag: editable }))
            .setAutoSave(true);
    }
}
Dialog_MacroTagEditor.defaultProps = {
    dialogId: 'dialog__macro-tags-editor',
};
exports.Dialog_MacroTagEditor = Dialog_MacroTagEditor;
