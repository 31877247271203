"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_ClinicalForm = exports.ModuleFE_ClinicalForm_Class = exports.dispatch_onClinicalFormsUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onClinicalFormsUpdated = new types_1.ThunderDispatcherV3('__onClinicalFormsUpdated');
class ModuleFE_ClinicalForm_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_ClinicalForm, exports.dispatch_onClinicalFormsUpdated);
    }
}
exports.ModuleFE_ClinicalForm_Class = ModuleFE_ClinicalForm_Class;
exports.ModuleFE_ClinicalForm = new ModuleFE_ClinicalForm_Class();
