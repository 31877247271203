"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ResolutionFunctionCard = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_ResolutionFunctionCard.scss");
const ts_common_1 = require("@nu-art/ts-common");
const styles_1 = require("@app/styles");
const frontend_2 = require("@app/styles/frontend");
const Renderer_Transition_1 = require("../../Page_PathwayEditor/renderers/Renderer_Transition");
const dispatchers_1 = require("../dispatchers");
const _entity_1 = require("../../../_entity");
const TS_EditableItemStatus_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemStatus/TS_EditableItemStatus");
const Component_TermsAndExpression_1 = require("@app/hcs/frontend/ui/expressions/ui/expression-builder/components/terms-and-expressions/Component_TermsAndExpression");
const dispatchers_2 = require("../../Page_PathwayEditor/dispatchers");
class Component_ResolutionFunctionCard extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        this.deleteTerm = () => {
            frontend_2.Dialog_Confirmation.show({
                header: 'Are You Sure?',
                buttons: [{
                        label: 'Yes, Delete',
                        isAsync: true,
                        action: async () => {
                            await this.state.editable.delete();
                        }
                    },
                    {
                        label: 'Don\'t Delete',
                        isPreferred: true,
                        action: ts_common_1.voidFunction,
                    }],
                content: () => {
                    const transition = _entity_1.ModuleFE_PathwayTransition.cache.unique(this.props.transitionId);
                    if (!transition)
                        throw new ts_common_1.MUSTNeverHappenException(`transition with id ${this.props.transitionId}`);
                    return React.createElement(React.Fragment, null,
                        React.createElement("div", null, "You're about to delete transition resolution function"),
                        React.createElement(Renderer_Transition_1.Renderer_Transition, { transition: transition, className: 'green' }));
                }
            });
        };
        this.renderTermEditor = () => {
            if (this.state.collapse)
                return '';
            return React.createElement(Component_TermsAndExpression_1.Component_TermsAndExpression, { hideCards: true, editable: this.state.editable });
        };
        this.renderActionMenu = () => {
            const menuActions = [
                {
                    label: 'Remove Transition Term',
                    action: this.deleteTerm
                },
                {
                    label: 'Duplicate Transition Term',
                    action: () => {
                        dispatchers_1.dispatch_onDuplicateTransitionTerm.dispatchUI(this.state.editable.item);
                    }
                },
            ];
            return React.createElement(styles_1.ICONS.more.component, Object.assign({}, frontend_1.openContent.popUp.bottom('pop-up__resolution-function-card-actions', () => React.createElement(frontend_2.ActionsMenu_BaseManagerUI, { actions: menuActions }), 10)));
        };
        this.renderTransitionInfo = () => {
            const transition = _entity_1.ModuleFE_PathwayTransition.cache.unique(this.state.transitionId);
            if (!transition)
                throw new ts_common_1.MUSTNeverHappenException(`transition with id ${this.props.transitionId} not found`);
            const arrowClassName = (0, frontend_1._className)('collapse-arrow', this.state.collapse && 'collapsed');
            return React.createElement(frontend_1.LL_H_C, { className: 'match_width', style: { gap: '7px' } },
                React.createElement("div", null, this.props.index + 1),
                React.createElement(styles_1.ICONS.dragHandle.component, { className: 'transition-drag-handle', draggable: true, onDragStart: e => {
                        const container = e.currentTarget.parentNode.parentNode;
                        container.style.width = `${container.offsetWidth}px`;
                        container.style.height = `${container.offsetHeight}px`;
                        container.style.maxWidth = '';
                        e.dataTransfer.effectAllowed = 'move';
                        e.dataTransfer.setData('text/html', container.outerHTML);
                        e.dataTransfer.setDragImage(container, 40, 30);
                        this.props.onDragStart(e, this.props.index);
                    }, onDragEnd: e => {
                        const container = e.currentTarget.parentNode.parentNode;
                        container.style.height = '';
                        container.style.width = '';
                        container.style.maxWidth = '100%';
                        this.props.onDragEnd(e);
                    } }),
                React.createElement(styles_1.ICONS.advisor_v4_arrow.component, { className: arrowClassName, onClick: () => this.props.onCollapse(this.state.transitionId) }),
                React.createElement(frontend_1.LL_H_C, { className: 'match_width flex__space-between' },
                    React.createElement("div", { className: 'card-title' }, transition.label),
                    React.createElement(frontend_1.LL_H_C, { style: { gap: 10 } },
                        React.createElement(TS_EditableItemStatus_1.TS_EditableItemStatus, { editable: this.state.editable }),
                        this.renderActionMenu())));
        };
        this.renderSaveButton = () => {
            if (!this.state.focused || this.state.collapse)
                return '';
            const className = (0, frontend_1._className)('preferred-action', 'resolution-function-card__save-button');
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.TS_BusyButton, { className: className, onClick: async () => await this.state.editable.save() }, "Save"));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.className = nextProps.className;
        state.collapse = nextProps.collapsed;
        state.transitionId = nextProps.transitionId;
        state.focused = true;
        return state;
    }
    componentDidMount() {
        const element = document.getElementById(this.state.transitionId);
        if (element)
            element.addEventListener('click', this.dispatchFocusResolutionFunction.bind(this), true);
    }
    componentWillUnmount() {
        const element = document.getElementById(this.state.transitionId);
        if (element)
            element.removeEventListener('click', this.dispatchFocusResolutionFunction.bind(this), true);
    }
    dispatchFocusResolutionFunction() {
        dispatchers_2.dispatch_FocusResolutionFunction.dispatchUI(this.state.transitionId, this.state.editable);
    }
    render() {
        const className = (0, frontend_1._className)('resolution-function-card', this.props.className);
        return React.createElement(frontend_1.TS_Card, { id: this.state.transitionId, onDragOver: e => this.props.onDragOver(e, this.props.index), onDragLeave: e => this.props.onDragLeave(e, this.props.index), "data-term-item": this.props.transitionId, className: className },
            this.renderTransitionInfo(),
            this.renderTermEditor(),
            this.renderSaveButton());
    }
}
exports.Component_ResolutionFunctionCard = Component_ResolutionFunctionCard;
