"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_ExpressionManager = void 0;
const React = require("react");
const frontend_1 = require("@app/styles/frontend");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
const frontend_3 = require("@nu-art/ts-workspace/frontend");
const Panel_ExpressionSidePanel_1 = require("../expression-builder/components/expression-side-panel/Panel_ExpressionSidePanel");
const frontend_4 = require("@nu-art/permissions/frontend");
const workspace_config_1 = require("../../workspace-config");
const Component_ExpressionBuilder_1 = require("../expression-builder/Component_ExpressionBuilder");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const permissions_1 = require("../../../../permissions");
const frontend_5 = require("../../../../../_entity/variable/frontend");
const frontend_6 = require("../../../../../_entity/value/frontend");
const frontend_7 = require("../../../../../_entity/expression/frontend");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
const q_components_1 = require("../../../q-components");
const ManagerKey_Expression = 'expression-manager';
class Page_ExpressionManager extends frontend_1.Page_BaseManagerUI_SingleType_Proto {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.workspace = () => ({
            id: ManagerKey_Expression,
            key: workspace_config_1.workspaceKey_ExpressionSearch,
            renderers: {
                horizontalSpace: frontend_3.TS_HorizontalWorkspace,
            },
            instances: {
                // search instances
                searchPanel: this.renderLeftPanel(),
                dataViewer: this.renderRightPanel(),
            }
        });
        this.renderLeftPanel = () => {
            if (this.state.selectedItem)
                return React.createElement(Component_ExpressionBuilder_1.Component_ExpressionBuilder, { deselectExpression: this.deselectExpression, editable: this.state.selectedItem });
            const accessLevel = this.getAccessLevel();
            const buttons = ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess === accessLevel ? [{
                    label: 'Add Expression',
                    action: this.onCreateNewExpression
                }] : [];
            return React.createElement(frontend_1.SearchSelector_BaseManagerUI, { selectedItem: this.state.selectedResult, onSearchItemSelected: (item) => this.onSearchResultSelected(item.id, item.type), searchItems: [Page_ExpressionManager.SearchableItem_Expression], buttons: buttons });
        };
        this.renderRightPanel = () => {
            if (this.state.selectedItem)
                return React.createElement(Panel_ExpressionSidePanel_1.Panel_ExpressionSidePanel, { editable: this.state.selectedItem });
            return React.createElement(q_components_1.QPageMessage, { message: 'Expression Manager' });
        };
        // ######################## Logic ########################
        this.onCreateNewExpression = () => {
            this.clearSelectedResult();
            const newExpression = this.getEditableItem();
            this.setState({
                selectedItem: newExpression,
                selectedResult: undefined
            });
        };
        this.deselectExpression = () => {
            this.setState({
                selectedItem: undefined,
                selectedResult: undefined
            }, () => {
                this.clearSelectedResult();
            });
        };
    }
    // ######################## Abstract Implementations ########################
    getInitialObject() {
        return Object.freeze({
            terms: [{
                    expression: 'A1',
                    atoms: [{ leftHand: undefined, cases: [{}] }]
                }],
            expression: 'T1',
            isEvidenceBased: false
        });
    }
    getAccessLevel() {
        return ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(permissions_1.PermissionKeyFE_ExpressionEdit);
    }
}
_a = Page_ExpressionManager;
Page_ExpressionManager.Route = {
    key: ManagerKey_Expression,
    path: 'expressions',
    Component: _a,
    enabled: (0, frontend_4.RoutePermissions)(permissions_1.PermissionKeyFE_ExpressionView),
    modulesToAwait: [...frontend_7.ModulePackFE_Expression, frontend_5.ModuleFE_Variable, frontend_6.ModuleFE_Value],
};
Page_ExpressionManager.searchItemKey_Expression = frontend_7.ModuleFE_Expression.dbDef.dbKey;
Page_ExpressionManager.SearchableItem_Expression = {
    module: frontend_7.ModuleFE_Expression,
    type: Page_ExpressionManager.searchItemKey_Expression,
    route: Page_ExpressionManager.Route,
    entityName: frontend_7.ModuleFE_Expression.dbDef.entityName,
    entityLabel: 'Expression',
    groupId: ['Advisor'],
    mapper: (item) => [(item.label && item.label.length) ? item.label : 'Missing Label'],
    tags: [],
    filter: (expression) => consts_1.BuilderTypesDbKeys.includes(expression.expressionType),
    renderer: (item, selected, onClickCallback) => {
        var _b, _c, _d, _e;
        const editableExpression = new frontend_2.EditableDBItemV3(item, frontend_7.ModuleFE_Expression);
        try {
            editableExpression.validate();
        }
        catch (err) {
            console.error(err);
        }
        return React.createElement(frontend_1.Renderer_SearchResult, { itemId: item._id, error: editableExpression.hasErrors(), itemKey: Page_ExpressionManager.searchItemKey_Expression, itemLabel: (_c = (_b = item.label) !== null && _b !== void 0 ? _b : item.description) !== null && _c !== void 0 ? _c : 'Missing Label', entityLabel: 'Expression', hierarchy: (_e = (_d = consts_1.AllExpressionTypes.find(type => type.dbKey === item.expressionType)) === null || _d === void 0 ? void 0 : _d.label) !== null && _e !== void 0 ? _e : 'Missing Type', onClickCallback: onClickCallback, selected: selected });
    },
    resultRenderer: (item) => {
        return React.createElement(React.Fragment, null);
    }
};
Page_ExpressionManager.defaultProps = {
    searchItems: [Page_ExpressionManager.SearchableItem_Expression]
};
exports.Page_ExpressionManager = Page_ExpressionManager;
