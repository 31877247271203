"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logTypes = void 0;
exports.logTypes = [
    'Personal Data',
    'Triage',
    'Labs',
    'EKG',
    'Preliminary Evaluation',
    'HPI',
    'Risk Factors',
    'Past Surgical History',
    'Medications',
    'ROS',
    'Physical Exam',
    'ADD-RS ',
    'Wells Score',
    'Working Diagnosis',
    'Imaging results'
];
