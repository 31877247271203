"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_Pathway = exports.DropDown_Pathway = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const ModuleFE_Pathway_1 = require("./ModuleFE_Pathway");
const Props_DropDown = {
    module: ModuleFE_Pathway_1.ModuleFE_Pathway,
    modules: [ModuleFE_Pathway_1.ModuleFE_Pathway],
    mapper: item => [item.label],
    placeholder: 'Choose a pathway',
    renderer: item => React.createElement(React.Fragment, null, item.label)
};
exports.DropDown_Pathway = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Pathway_1.ModuleFE_Pathway,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.label);
    },
    uiSelector: exports.DropDown_Pathway.selectable,
});
exports.MultiSelect_Pathway = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
