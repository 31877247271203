"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchableItem_MultiVars = exports.SearchableItem_DiseaseProfiles = exports.SearchableItem_DiseaseViews = exports.searchItemKey_DiseaseViews = exports.searchItemKey_MultiVars = exports.searchItemKey_DiseaseProfiles = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@app/styles/frontend");
const components_1 = require("./components");
const Manager_DiseaseProfile_1 = require("./Manager_DiseaseProfile/Manager_DiseaseProfile");
const hcs_1 = require("@app/hcs");
const Manager_DiseaseView_1 = require("./Manager_DiseaseView/Manager_DiseaseView");
const _entity_1 = require("../_entity");
const frontend_2 = require("@app/hcs/frontend");
const frontend_3 = require("@nu-art/thunderstorm/frontend");
exports.searchItemKey_DiseaseProfiles = _entity_1.ModuleFE_DiseaseProfile.dbDef.dbKey;
exports.searchItemKey_MultiVars = _entity_1.ModuleFE_MultiVar.dbDef.dbKey;
exports.searchItemKey_DiseaseViews = 'disease-views';
exports.SearchableItem_DiseaseViews = {
    module: _entity_1.ModuleFE_DiseaseView,
    type: exports.searchItemKey_DiseaseViews,
    entityName: _entity_1.ModuleFE_DiseaseView.dbDef.dbKey,
    route: components_1.Page_DiseaseProfile.Route,
    entityLabel: 'Disease View',
    groupId: ['Disease Profiles'],
    mapper: (item) => [item.label],
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_1.Renderer_SearchResult, { itemId: item._id, itemKey: exports.searchItemKey_DiseaseViews, itemLabel: item.label, entityLabel: 'Disease View', hierarchy: 'Disease Profiles > Views', selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (item) => {
        return React.createElement(Manager_DiseaseView_1.Manager_DiseaseView, { viewId: item });
    },
    tags: [],
};
exports.SearchableItem_DiseaseProfiles = {
    module: _entity_1.ModuleFE_DiseaseProfile,
    type: exports.searchItemKey_DiseaseProfiles,
    route: components_1.Page_DiseaseProfile.Route,
    entityName: _entity_1.ModuleFE_DiseaseProfile.dbDef.dbKey,
    entityLabel: 'Disease Profile',
    groupId: ['Disease Profiles'],
    mapper: (item) => [item.label],
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_1.Renderer_SearchResult, { itemId: item._id, itemKey: exports.searchItemKey_DiseaseProfiles, itemLabel: item.label, entityLabel: 'Disease Profile', hierarchy: 'Disease Profiles', selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (itemId) => {
        return React.createElement(frontend_3.AwaitModules, { modules: components_1.Page_DiseaseProfile.Route.modulesToAwait },
            React.createElement(Manager_DiseaseProfile_1.Manager_DiseaseProfile, { diseaseProfileId: itemId }));
    },
    tags: [],
};
exports.SearchableItem_MultiVars = {
    module: _entity_1.ModuleFE_MultiVar,
    type: exports.searchItemKey_MultiVars,
    route: components_1.Page_DiseaseProfile.Route,
    entityName: _entity_1.ModuleFE_MultiVar.dbDef.dbKey,
    entityLabel: 'Multi Var',
    groupId: ['Disease Profiles'],
    mapper: (item) => {
        const firstVar = frontend_2.ModuleFE_Variable.cache.unique(item._firstVarId);
        return [firstVar.name];
    },
    renderer: (item, selected, onClickCallback) => {
        const firstVar = frontend_2.ModuleFE_Variable.cache.unique(item._firstVarId);
        const dp = _entity_1.ModuleFE_DiseaseProfile.cache.unique(item.diseaseProfileId);
        return React.createElement(frontend_1.Renderer_SearchResult, { itemId: item._id, itemKey: exports.searchItemKey_MultiVars, itemLabel: firstVar.name, entityLabel: 'Multi Var', hierarchy: `Disease Profiles > ${dp.label}`, selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (itemId) => {
        const mv = _entity_1.ModuleFE_MultiVar.cache.unique(itemId);
        return React.createElement(Manager_DiseaseProfile_1.Manager_DiseaseProfile, { diseaseProfileId: mv.diseaseProfileId, multiVarId: itemId });
    },
    tags: [],
};
hcs_1.CollectionEntityMapper.addEntities([
    exports.SearchableItem_DiseaseProfiles,
    exports.SearchableItem_MultiVars,
]);
