"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MISSING_OPTIONAL = exports.ExpressionsVisitors = void 0;
const tree_1 = require("antlr4ts/tree");
const ts_common_1 = require("@nu-art/ts-common");
const antlr4ts_1 = require("antlr4ts");
const shared_1 = require("../../../../../../_entity/value/shared");
const QuaiExpressionsV3Parser_1 = require("../../../../../../shared/expression/grammar/QuaiExpressionsV3Parser");
const QuaiExpressionsV3Lexer_1 = require("../../../../../../shared/expression/grammar/QuaiExpressionsV3Lexer");
const blackbox = {
    'variable': {
        '50936967ef0beed086c159c5dc09bbe2': {
            'number': 3,
            'boolean': true,
            'string': 'zevel',
            'nested': {
                'anotherString': 'ashpa'
            }
        },
        '7b9c2f60a3725306be9c0b696320c4c1': {
            'name': 'gender',
            'rawValue': 'male'
        },
        '7b9c2f60a3725306be9c0b696320c4c3': {
            'name': 'gender',
            'rawValue': true
        },
        '7b9c2f60a3725306be9c0b696320c4c2.7b9c2f60a3725306be9c0b696320c4c3': {
            'name': 'gender',
            'rawValue': 'chest pain.location'
        },
        '7b9c2f60a3725306be9c0b696320c4c4.7b9c2f60a3725306be9c0b696320c4c5': {
            'name': 'gender',
            'rawValue': 'chest pain.relieving'
        },
        '7b9c2f60a3725306be9c0b696320c4c6.7b9c2f60a3725306be9c0b696320c4c7': {
            'name': 'gender',
            'rawValue': 'chest pain.precipitating'
        }
    },
    'value': {
        '0706221faa0faae9d835bf70293089d0': {
            'data': {
                'scope': [2, 20]
            }
        },
        '2e705178c0ae98bfd045d6d438481aea': {
            type: shared_1.valueType_EnumElement,
            'data': {
                'value': 'male'
            }
        },
        '2e705178c0ae98bfd045d6d438481aaa': {
            'data': {
                'value': 'gender'
            }
        }
    },
    'expression': {
        '2e705178c0ae98bfd045d6d438481aeb': {
            'expressions': ['var(7b9c2f60a3725306be9c0b696320c4c1) === enum(2e705178c0ae98bfd045d6d438481aea)']
        }
    }
};
class ExpressionsVisitors extends tree_1.AbstractParseTreeVisitor {
    constructor(withLog = false, getUnique) {
        super();
        this.getUnique = getUnique;
        this.withLog = withLog;
    }
    defaultResult() {
        return 'failed to parse';
    }
    //######################### Visit passthroughs #########################
    visitArithmeticOperationExpr(ctx) {
        if (this.withLog)
            console.log(`resolve arithmetic operation: ${this.visit(ctx.numericExpr0())}`);
        return this.visit(ctx.numericExpr0());
    }
    visitPassThroughArithmeticTerm(ctx) {
        if (this.withLog)
            console.log(`resolve pass through arithmetic term: ${this.visit(ctx.numericExpr1())}`);
        return this.visit(ctx.numericExpr1());
    }
    visitResolveDbExpression(ctx) {
        return this.visit(ctx.expressionResolver());
    }
    visitAtomicExpr(ctx) {
        if (this.withLog)
            console.log(`resolve atomic expression: ${this.visit(ctx.stringExpr0())}`);
        return this.visit(ctx.stringExpr0());
    }
    visitParenBooleanOperation(ctx) {
        if (this.withLog)
            console.log(`resolve boolean paren expression: ${this.visit(ctx.boolExpr0())}`);
        return this.visit(ctx.boolExpr0());
    }
    visitParenArithmeticOperation(ctx) {
        if (this.withLog)
            console.log(`visit arithmetic paren: ${this.visit(ctx.numericExpr0())}`);
        return this.visit(ctx.numericExpr0());
    }
    visitConditionExpr(ctx) {
        return this.visit(ctx.conditionalExpr0());
    }
    visitArrayExpr(ctx) {
        return this.visit(ctx.arrayLiteral0());
    }
    visitResolvablesExpr(ctx) {
        if (this.withLog)
            console.log(`resolvable expression value: ${this.visit(ctx.resolvableExpr())}`);
        return this.visit(ctx.resolvableExpr());
    }
    visitArrayFuncExpr(ctx) {
        return this.visit(ctx.arrayFunctions());
    }
    visitTermWrapperNumeric(ctx) {
        return this.visit(ctx.numericExpr0());
    }
    visitTermWrapperBoolean(ctx) {
        return this.visit(ctx.boolExpr0());
    }
    visitOptionalTermExpr(ctx) {
        return this.visit(ctx.optionalTerm());
    }
    visitParenNumericCondition(ctx) {
        return this.visit(ctx.conditionalExpr0());
    }
    visitBooleanOperationExpr(ctx) {
        if (this.withLog)
            console.log(`resolve boolean operation: ${this.visit(ctx.boolExpr0())}`);
        return this.visit(ctx.boolExpr0());
    }
    visitParenExpr(ctx) {
        if (this.withLog)
            console.log(`resolve paren expression: ${this.visit(ctx.expression())}`);
        return this.visit(ctx.expression());
    }
    //######################### Visit literals #########################
    visitStringExpr(ctx) {
        if (this.withLog)
            console.log(`resolve string expression: ${ctx.STRING().text}`);
        return ctx.STRING().text.replace(/"/g, '');
    }
    visitNegativeExpression(ctx) {
        if (this.withLog)
            console.log(`resolve negative number: -${this.visit(ctx.numericExpr3())}`);
        const value = this.visit(ctx.numericExpr3());
        return -value;
    }
    visitNumberLiteral(ctx) {
        if (this.withLog)
            console.log(`parse Number value: ${parseFloat(ctx.NUMBER().text)}`);
        return parseFloat(ctx.NUMBER().text);
    }
    visitResolveVar(ctx) {
        if (this.withLog)
            console.log(`resolved var: ${blackbox.variable[ctx.UNIQUE_ID().text]}`);
        return blackbox.variable[ctx.UNIQUE_ID().text].rawValue;
    }
    visitResolveRange(ctx) {
        return ctx.UNIQUE_ID().text;
    }
    visitBooleanLiteral(ctx) {
        if (this.withLog)
            console.log(`resolvee boolean value: ${ctx.BOOL().text === 'true'}`);
        return ctx.BOOL().text === 'true';
    }
    visitResolveEnum(ctx) {
        return ctx.UNIQUE_ID().text;
    }
    visitResolveYes(ctx) {
        return 'yes';
    }
    visitResolveNo(ctx) {
        return 'no';
    }
    visitArrayLiteral(ctx) {
        return ctx.expression().map(innerCtx => this.visit(innerCtx));
    }
    //######################### Visit logic #########################
    visitBooleanEqualityExpr(ctx) {
        if (this.withLog)
            console.log(`resolve boolean comparison: ${this.visit(ctx.boolExpr0())} === ${this.visit(ctx.boolExpr1())}`);
        return this.visit(ctx.boolExpr0()) === this.visit(ctx.boolExpr1());
    }
    visitAdditionOperation(ctx) {
        if (this.withLog)
            console.log(`resolve addition operation: ${this.visit(ctx.numericExpr0())} + ${this.visit(ctx.numericExpr1())}`);
        return this.visit(ctx.numericExpr0()) + this.visit(ctx.numericExpr1());
    }
    visitSubtractionOperation(ctx) {
        if (this.withLog)
            console.log(`resolve subtraction operation: ${this.visit(ctx.numericExpr0())} - ${this.visit(ctx.numericExpr1())}`);
        return this.visit(ctx.numericExpr0()) - this.visit(ctx.numericExpr1());
    }
    visitMultiplicationOperation(ctx) {
        if (this.withLog)
            console.log(`resolve multiplication operation: ${this.visit(ctx.numericExpr1())} * ${this.visit(ctx.numericExpr2())}`);
        return this.visit(ctx.numericExpr1()) * this.visit(ctx.numericExpr2());
    }
    visitDivisionOperation(ctx) {
        if (this.withLog)
            console.log(`resolve division operation: ${this.visit(ctx.numericExpr1())} / ${this.visit(ctx.numericExpr2())}`);
        return this.visit(ctx.numericExpr1()) / this.visit(ctx.numericExpr2());
    }
    visitModuloOperation(ctx) {
        if (this.withLog)
            console.log(`resolve modulo operation: ${this.visit(ctx.numericExpr1())} % ${this.visit(ctx.numericExpr2())}`);
        return this.visit(ctx.numericExpr1()) % this.visit(ctx.numericExpr2());
    }
    visitPowerOperation(ctx) {
        if (this.withLog)
            console.log(`resolve power operation: ${(this.visit(ctx.numericExpr3()))} ^ ${this.visit(ctx.numericExpr2())}`);
        return this.visit(ctx.numericExpr3()) ** this.visit(ctx.numericExpr2());
    }
    visitAndOperation(ctx) {
        if (this.withLog)
            console.log(`boolean and: ${this.visit(ctx.boolExpr1())} && ${this.visit(ctx.boolExpr2())}`);
        return this.visit(ctx.boolExpr1()) && this.visit(ctx.boolExpr2());
    }
    visitNumericEqaulComparison(ctx) {
        if (this.withLog)
            console.log(`numeric equal comparison: ${this.visit(ctx.numericExpr0(0))} === ${this.visit(ctx.numericExpr0(1))}`);
        return this.visit(ctx.numericExpr0(0)) === this.visit(ctx.numericExpr0(1));
    }
    visitAtomicEqualComparison(ctx) {
        if (this.withLog)
            console.log(`String comparison: ${this.visit(ctx.stringExpr0(0))} === ${this.visit(ctx.stringExpr0(1))}`);
        return this.visit(ctx.stringExpr0(0)) === this.visit(ctx.stringExpr0(1));
    }
    visitOrOperation(ctx) {
        if (this.withLog)
            console.log(`boolean or: ${this.visit(ctx.boolExpr0())} || ${this.visit(ctx.boolExpr1())}`);
        return this.visit(ctx.boolExpr0()) || this.visit(ctx.boolExpr1());
    }
    visitNotOperation(ctx) {
        if (this.withLog)
            console.log(`resolve not operation: !${this.visit(ctx.boolExpr4())}`);
        return !this.visit(ctx.boolExpr4());
    }
    visitLessThanComparison(ctx) {
        if (this.withLog)
            console.log(`resolve less than operation: ${this.visit(ctx.numericExpr0(0))} < ${this.visit(ctx.numericExpr0(1))}`);
        return this.visit(ctx.numericExpr0(0)) < this.visit(ctx.numericExpr0(1));
    }
    visitGreaterThanComparison(ctx) {
        if (this.withLog)
            console.log(`resolve greater than operation: ${this.visit(ctx.numericExpr0(0))} > ${this.visit(ctx.numericExpr0(1))}`);
        return this.visit(ctx.numericExpr0(0)) > this.visit(ctx.numericExpr0(1));
    }
    visitGreaterOrEqualsComparison(ctx) {
        return this.visit(ctx.numericExpr0(0)) >= this.visit(ctx.numericExpr0(1));
    }
    visitLesserOrEqualsComparison(ctx) {
        return this.visit(ctx.numericExpr0(0)) <= this.visit(ctx.numericExpr0(1));
    }
    visitResolveProp(ctx) {
        const props = ctx.PROPERTY().map(terminalProp => terminalProp.text);
        return props.reduce((value, currentProp) => {
            return value[currentProp];
        }, this.visit(ctx.resolveVar()));
    }
    visitResolveEnumerated(ctx) {
        var _a;
        const id = this.visit(ctx.resolveEnum());
        const enumValue = (_a = this.getUnique(id)) !== null && _a !== void 0 ? _a : blackbox.value[id];
        if (!enumValue)
            throw new ts_common_1.BadImplementationException(`no value found for id: ${id}`);
        if (enumValue.type !== shared_1.valueType_EnumElement)
            throw new ts_common_1.BadImplementationException(`value with id ${id} is not enum type, actual type: ${enumValue.type}`);
        return enumValue.data.value;
    }
    visitResolveYesNo(ctx) {
        if (ctx.resolveNo())
            return this.visit(ctx.resolveNo());
        if (ctx.resolveYes())
            return this.visit(ctx.resolveYes());
    }
    visitGeneralCondition(ctx) {
        return this.visit(ctx.boolExpr0()) ? this.visit(ctx.expression(0)) : this.visit(ctx.expression(1));
    }
    visitInRange(ctx) {
        var _a, _b;
        const operators = [(_a = ctx.children) === null || _a === void 0 ? void 0 : _a[1].text, (_b = ctx.children) === null || _b === void 0 ? void 0 : _b[3].text];
        const nums = [this.visit(ctx.numericExpr0(0)), this.visit(ctx.numericExpr0(1)), this.visit(ctx.numericExpr0(2))];
        return (operators[0] === '<' ? nums[0] < nums[1] : nums[0] <= nums[1]) && (operators[1] === '<' ? nums[1] < nums[2] : nums[1] <= nums[2]);
    }
    visitArrayIncludes(ctx) {
        const arr = this.visit(ctx.arrayLiteral0());
        const expr = this.visit(ctx.expression());
        return arr.includes(expr);
    }
    visitArrayEvery(ctx) {
        const arr = this.visit(ctx.arrayLiteral0());
        const expr = this.visit(ctx.expression());
        return arr.every((item) => item === expr);
    }
    visitResolveAttr(ctx) {
        const complaintId = ctx.UNIQUE_ID()[0].text;
        const attrId = ctx.UNIQUE_ID()[1].text;
        return blackbox.variable[`${complaintId}.${attrId}`].rawValue;
    }
    visitResolvePrecipitating(ctx) {
        const complaintId = ctx.UNIQUE_ID()[0].text;
        const precipitatingId = ctx.UNIQUE_ID()[1].text;
        return blackbox.variable[`${complaintId}.${precipitatingId}`].rawValue;
    }
    visitResolveRelieving(ctx) {
        const complaintId = ctx.UNIQUE_ID()[0].text;
        const relivingFactorId = ctx.UNIQUE_ID()[1].text;
        return blackbox.variable[`${complaintId}.${relivingFactorId}`].rawValue;
    }
    visitResolveRangeMinValue(ctx) {
        var _a, _b;
        const rangeValue = (_a = this.getUnique(this.visit(ctx.resolveRange()))) !== null && _a !== void 0 ? _a : blackbox.value[this.visit(ctx.resolveRange())];
        if (!rangeValue)
            return this.defaultResult();
        return (_b = rangeValue.data.scope[0]) !== null && _b !== void 0 ? _b : blackbox.value[this.visit(ctx.resolveRange())];
    }
    visitResolveRangeMaxValue(ctx) {
        var _a;
        const rangeValue = (_a = this.getUnique(this.visit(ctx.resolveRange()))) !== null && _a !== void 0 ? _a : blackbox.value[this.visit(ctx.resolveRange())];
        if (!rangeValue)
            return this.defaultResult();
        return rangeValue.data.scope[1];
    }
    visitArrayAtLeast(ctx) {
        const wantedLength = ctx.NUMBER().text;
        const valueToCompare = this.visit(ctx.expression());
        const arr = this.visit(ctx.arrayLiteral0());
        return arr.filter((item) => item === valueToCompare).length >= wantedLength;
    }
    visitOptionalTerm(ctx) {
        const expressionResult = this.visit(ctx.expression());
        if (!expressionResult) {
            const defaultValue = this.visit(ctx.primitives());
            return defaultValue !== null && defaultValue !== void 0 ? defaultValue : exports.MISSING_OPTIONAL;
        }
        return expressionResult;
    }
    visitExpressionResolver(ctx) {
        const expression = blackbox.expression[ctx.UNIQUE_ID().text];
        const inputStream = new antlr4ts_1.ANTLRInputStream(expression.expressions[0]);
        const lexer = new QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer(inputStream);
        const tokenStream = new antlr4ts_1.CommonTokenStream(lexer);
        const parser = new QuaiExpressionsV3Parser_1.QuaiExpressionsV3Parser(tokenStream);
        return new ExpressionsVisitors(false, this.getUnique).visit(parser.expression());
    }
}
exports.ExpressionsVisitors = ExpressionsVisitors;
exports.MISSING_OPTIONAL = Object.freeze({ MISSING_OPTIONAL: 'MISSING_OPTIONAL' });
