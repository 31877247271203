"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_TermsAndExpression = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const styles_1 = require("@app/styles");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Editor_Term_1 = require("../editors/Editor_Term");
require("./Component_TermsAndExpression.scss");
const Editor_TopExpression_1 = require("../editors/top-expression/Editor_TopExpression");
const frontend_2 = require("@app/styles/frontend");
const TS_ListOrganizer_1 = require("@nu-art/thunderstorm/frontend/components/TS_ListOrganizer");
const shared_1 = require("../../../../../../../_entity/expression/shared");
const consts_1 = require("../../../../../../../_entity/expression/shared/consts");
const ui_components_1 = require("../../../../../../../_entity/expression/frontend/ui-components");
const utils_1 = require("../editors/utils");
class Component_TermsAndExpression extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        //######################### Lifecycle #########################
        super(...arguments);
        //######################### Logic #########################
        this.addTerm = async (term) => {
            const emptyTerm = {
                expression: 'A1',
                atoms: [{ leftHand: undefined, cases: [{}] }],
            };
            //every new term must be created with at least one empty atom and case in it
            const editableTerms = this.props.editable.editProp('terms', []);
            await editableTerms.updateArrayAt(term !== null && term !== void 0 ? term : emptyTerm);
            this.forceUpdate();
        };
        this.deleteTerm = async (index) => {
            const editableTerms = this.props.editable.editProp('terms', []);
            await editableTerms.removeArrayItem(index);
            this.forceUpdate();
        };
        this.hasValidationError = (index) => {
            const editableTerms = this.state.editable.editProp('terms', []);
            const currentTerm = editableTerms.editProp(index, {});
            if (currentTerm.hasError('atomsLogic'))
                return currentTerm.hasError('atomsLogic');
            const termsHasError = this.state.editable.hasError('terms');
            return typeof termsHasError === 'string' ? termsHasError : undefined;
        };
        this.reorganizeTerms = async (labelSwaps, newOrder) => {
            const stringExpression = (0, utils_1.updateExpressionString)(labelSwaps, this.state.editable.get('expression'));
            await this.state.editable.updateObj({
                expression: stringExpression,
                terms: newOrder.map(item => item.editable.item)
            });
        };
        //######################### Render #########################
        this.renderAddTermButton = () => {
            var _a;
            const item = this.props.editable.item;
            if (this.props.viewMode || (item.expressionType === consts_1.ExpressionType_NormalRange.dbKey && ((_a = item.terms) === null || _a === void 0 ? void 0 : _a.length)))
                return '';
            return React.createElement(frontend_1.TS_Button, { className: 'add-term-btn', onClick: () => this.addTerm() }, "Add Term");
        };
        this.renderActionMenu = (index) => {
            if (this.props.viewMode)
                return '';
            const menuActions = [
                {
                    label: 'Remove Term',
                    action: () => this.deleteTerm(index)
                }
            ];
            if (this.state.editable.get('expressionType') !== consts_1.ExpressionType_NormalRange.dbKey)
                menuActions.push({
                    label: 'Duplicate',
                    action: () => { var _a; return this.addTerm((_a = this.state.editable.item.terms) === null || _a === void 0 ? void 0 : _a[index]); }
                });
            return React.createElement(styles_1.ICONS.more.component, Object.assign({}, frontend_1.openContent.popUp.bottom('pop-up__term-actions', () => React.createElement(frontend_2.ActionsMenu_BaseManagerUI, { actions: menuActions }), 10)));
        };
        this.renderTerm = (props) => {
            const className = (0, frontend_1._className)('expression-card', 'term-card', this.props.hideCards && 'hide-card');
            return React.createElement(frontend_1.TS_Card, { onDragOver: e => props.onDragOver(e, props.index), onDragLeave: e => props.onDragLeave(e, props.index), className: className, key: props.index },
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: this.renderTermLabel(props.index, props.onDragStart, props.onDragEnd) },
                    React.createElement(Editor_Term_1.Editor_Term, { editable: props.item.editable, deleteTerm: () => this.deleteTerm(props.index), viewMode: this.props.viewMode, expressionType: this.state.editable.get('expressionType') })));
        };
        this.renderTermsList = () => {
            const editableTerms = this.props.editable.editProp('terms', []);
            const terms = editableTerms.item.map((term, index) => ({
                editable: editableTerms.editProp(index, {}),
                label: `T${index + 1}`
            }));
            return React.createElement(React.Fragment, null,
                React.createElement(TS_ListOrganizer_1.TS_ListOrganizer, { items: terms, renderer: this.renderTerm, onOrderChanged: newTermOrder => this.reorganizeTerms((0, utils_1.CollectLabelSwaps)(newTermOrder, terms), newTermOrder) }),
                this.renderAddTermButton());
        };
        this.renderTopExpression = () => {
            return React.createElement(Editor_TopExpression_1.Editor_TopExpression, { hideCards: this.props.hideCards, viewMode: !!this.props.viewMode, editable: this.state.editable });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        (_a = state.cardWidth) !== null && _a !== void 0 ? _a : (state.cardWidth = 700);
        return state;
    }
    renderTermLabel(index, onDragStart, onDragEnd) {
        var _a;
        const validationErrorMessage = this.hasValidationError(index);
        const editableTerms = this.state.editable.editProp('terms', []);
        const currentTerm = editableTerms.editProp(index, {});
        const currentLogic = currentTerm.get('atomsLogic');
        return React.createElement(React.Fragment, null,
            !this.props.hideDnD ? React.createElement(styles_1.ICONS.dragHandle.component, { onDragStart: e => {
                    const container = e.currentTarget.parentNode.parentNode;
                    e.dataTransfer.effectAllowed = 'move';
                    e.dataTransfer.setData('text/html', container);
                    e.dataTransfer.setDragImage(container, 30, 30);
                    onDragStart(e, index);
                }, onDragEnd: onDragEnd, className: 'term-drag-handle', draggable: true }) : null,
            React.createElement("div", { className: 'term-header' }, `Term (T${index + 1})`),
            ((_a = currentTerm.item.atoms) === null || _a === void 0 ? void 0 : _a.length) === 1 &&
                React.createElement(ui_components_1.DropDown_SingleAtomLogics.editable, { editable: currentTerm, prop: 'atomsLogic', canUnselect: true, onSelected: value => {
                        const changes = {
                            atomsLogic: value
                        };
                        // return if there's no change
                        if (currentLogic === value)
                            return;
                        //Handle complex changes
                        if (currentLogic === consts_1.LogicType_Complex) {
                            changes.fallbackValue = undefined;
                            changes.subTerms = undefined;
                        }
                        if (value === consts_1.LogicType_Complex) {
                            changes.subTerms = [(0, shared_1.getEmptyTerm)()];
                            changes.fallbackValue = [0, 0];
                        }
                        //Handle free text changes
                        if (currentLogic === consts_1.LogicType_StructuredFreeText) {
                            changes.structuredFreeText = undefined;
                            changes.outputType = undefined;
                        }
                        if (value === consts_1.LogicType_StructuredFreeText) {
                            changes.structuredFreeText = { if: undefined, then: undefined, else: undefined };
                        }
                        return currentTerm.updateObj(changes);
                    } }),
            !!validationErrorMessage && React.createElement(frontend_2.IconWithTooltip, { content: validationErrorMessage, iconKey: 'attention' }),
            this.renderActionMenu(index));
    }
    render() {
        return React.createElement(React.Fragment, null,
            this.renderTermsList(),
            this.renderTopExpression());
    }
}
exports.Component_TermsAndExpression = Component_TermsAndExpression;
