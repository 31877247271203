"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_BaseManagerUI = exports.ManagerUI_ItemType = exports.ManagerUI_ItemId = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@nu-art/ts-workspace/frontend");
require("./Page_BaseManagerUI.scss");
const React = require("react");
exports.ManagerUI_ItemId = 'id';
exports.ManagerUI_ItemType = 'type';
class Page_BaseManagerUI extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.onSearchResultSelected = (selectedResultId, entityType, state) => {
            const searchItem = this.props.searchItems.find(searchItem => searchItem.type === entityType);
            if (!searchItem)
                throw new ts_common_1.MUSTNeverHappenException(`Cannot find search item with entity type: ${entityType}`);
            const selectedResult = searchItem.module.cache.unique(selectedResultId);
            if (!selectedResult)
                throw new ts_common_1.BadImplementationException(`Could not find item for selectedResultId ${selectedResultId} and entity type: ${entityType}`);
            // this.logInfo(`search reached end and setting params`);
            frontend_1.ModuleFE_BrowserHistory.setQuery({ [exports.ManagerUI_ItemId]: selectedResultId, [exports.ManagerUI_ItemType]: entityType });
            frontend_1.dispatcher_urlParamsChanged.dispatchUI();
            this.reDeriveState(state);
        };
    }
    deriveStateFromProps(nextProps, state) {
        const id = frontend_1.ModuleFE_BrowserHistory.getQueryParameter(exports.ManagerUI_ItemId);
        const type = frontend_1.ModuleFE_BrowserHistory.getQueryParameter(exports.ManagerUI_ItemType);
        if ((0, ts_common_1.logicalXOR)(!!id, !!type))
            this.clearSelectedResult();
        if (id && type)
            state.selectedResult = { id, type };
        return state;
    }
    clearSelectedResult() {
        frontend_1.ModuleFE_BrowserHistory.removeQueryParam(exports.ManagerUI_ItemId);
        frontend_1.ModuleFE_BrowserHistory.removeQueryParam(exports.ManagerUI_ItemType);
        frontend_1.dispatcher_urlParamsChanged.dispatchUI();
    }
    render() {
        const workspace = (0, ts_common_1.resolveContent)(this.workspace);
        return React.createElement(frontend_1.TS_ErrorBoundary, null,
            React.createElement(frontend_2.TS_Workspace, { id: workspace.id, className: 'quai-manager-ui', workspaceKey: workspace.key, renderers: workspace.renderers, instances: Object.assign({}, workspace.instances) }));
    }
}
exports.Page_BaseManagerUI = Page_BaseManagerUI;
