"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_DiseaseVariableData = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../../../shared");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const frontend_2 = require("@app/hcs/frontend");
const frontend_3 = require("@nu-art/permissions/frontend");
const styles_1 = require("@app/styles");
const consts_1 = require("@app/hcs/_entity/value/shared/consts");
const _enum_1 = require("../../../_enum");
const _entity_1 = require("../../../_entity");
const displaySections = ['Values', 'References', 'Disease Info', 'Snomed Info'];
const dd_carets = {
    open: React.createElement(styles_1.ICONS.advisorArrowDown.component, null),
    close: React.createElement(styles_1.ICONS.advisorArrowDown.component, { className: 'close' })
};
class Renderer_DiseaseVariableData extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.dpPermissionKey_Edit = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getPermissionKey(shared_1.PermissionKey_DiseaseProfile_Edit);
        this.getVariants = () => {
            var _a, _b, _c;
            const variantIds = (_c = (_b = (_a = this.state.diseaseVariable.item.variables) === null || _a === void 0 ? void 0 : _a[0].tagIds.find(group => group.key === this.state.diseaseProfile.variantsGroupId)) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : [];
            return frontend_2.ModuleFE_Tag.cache.filter(i => variantIds.includes(i._id));
        };
        this.saveDV = async (dv) => {
            await dv.save();
            this.forceUpdate();
        };
        this.getLikelihoodString = (dv) => {
            let toRet = '';
            if (dv.item.likelihoodRange) {
                toRet += dv.item.likelihoodRange[0] === dv.item.likelihoodRange[1] ? dv.item.likelihoodRange[0] : `${dv.item.likelihoodRange[0]}-${dv.item.likelihoodRange[1]}`;
                toRet += '%';
            }
            if (dv.item.likelihood) {
                if (toRet !== '')
                    toRet += ', ';
                toRet += _enum_1.LikelihoodLabelMap[dv.item.likelihood];
            }
            if (dv.item.likelihoodDirection) {
                if (toRet !== '')
                    toRet += ', ';
                toRet += dv.item.likelihoodDirection;
            }
            return toRet;
        };
        this.onDiseaseLikelihoodRangeUpdated = async (diseaseValue, likelihood) => {
            //If no range in an empty DV
            if (!likelihood && !diseaseValue.item._id)
                return;
            //If range wasn't changed
            if ((0, ts_common_1.compare)(likelihood, diseaseValue.item.likelihoodRange))
                return;
            if (likelihood)
                diseaseValue.set('likelihoodRange', likelihood);
            else
                delete diseaseValue.item.likelihoodRange;
            return this.saveDV(diseaseValue);
        };
        this.onDiseaseLikelihoodSelected = async (diseaseValue, likelihood) => {
            if (likelihood === diseaseValue.item.likelihood)
                return;
            diseaseValue.set('likelihood', likelihood);
            return this.saveDV(diseaseValue);
        };
        this.onDiseaseLikelihoodDirectionSelected = async (diseaseValue, likelihoodDirection) => {
            if (likelihoodDirection === diseaseValue.item.likelihoodDirection)
                return;
            diseaseValue.set('likelihoodDirection', likelihoodDirection);
            return this.saveDV(diseaseValue);
        };
        this.renderHeader = () => {
            const variable = frontend_2.ModuleFE_Variable.cache.unique(this.state.diseaseVariable.item._firstVarId);
            const variantTags = this.getVariants();
            return React.createElement(frontend_1.LL_V_L, { className: 'manager__disease-profile__disease-variable-data__header' },
                React.createElement("div", { className: 'manager__disease-profile__disease-variable-data__header__title' }, variable.name),
                React.createElement("div", { className: 'manager__disease-profile__disease-variable-data__header__sub-title' }, variantTags.map(i => i.label).join(' | ')));
        };
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__disease-profile__disease-variable-data__body' },
                this.renderBodySectionSelector(),
                React.createElement(frontend_1.LL_V_L, { style: { height: '100%', flexGrow: 1, gap: '10px' } },
                    this.renderEditModeActions(),
                    React.createElement(frontend_2.QScrollWrapper, null,
                        React.createElement(frontend_1.Grid, { className: 'manager__data-grid manager__disease-profile__disease-variable-data__section-data' },
                            this.renderValuesSection(),
                            this.renderReferencesSection(),
                            this.renderDiseaseInfoSection(),
                            this.renderSnomedInfoSection()))));
        };
        this.renderBodySectionSelector = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'manager__disease-profile__disease-variable-data__section-selector' }, displaySections.map((section, i) => {
                const className = (0, frontend_1._className)('manager__disease-profile__disease-variable-data__section-selector__item', this.state.displayedSection === section && 'selected');
                return React.createElement("div", { key: i, className: className, onClick: () => this.setState({ displayedSection: section }) }, section);
            }));
        };
        // ######################## Render - Sections ########################
        this.renderValuesSection = () => {
            if (this.state.displayedSection !== 'Values')
                return;
            const editMode = this.state.editMode;
            const mv = this.state.diseaseVariable;
            const diseaseValues = _entity_1.ModuleFE_DiseaseValue.cache.filter(i => i.multiVarId === mv.item._id);
            (0, ts_common_1.sortArray)(diseaseValues, i => i.__created);
            return diseaseValues.map((_dv, i) => {
                var _a;
                const dv = new frontend_1.EditableDBItemV3(_dv, _entity_1.ModuleFE_DiseaseValue);
                return React.createElement(React.Fragment, { key: dv.item._id }, (_a = mv.item.variables) === null || _a === void 0 ? void 0 :
                    _a.map(mvv => {
                        var _a, _b, _c;
                        const variable = frontend_2.ModuleFE_Variable.cache.unique(mvv.variableId);
                        const valueId = mvv.attributeeId ? (_b = (_a = dv.item.attributes) === null || _a === void 0 ? void 0 : _a[mvv.variableId]) === null || _b === void 0 ? void 0 : _b[mvv.attributeeId] : (_c = dv.item.values) === null || _c === void 0 ? void 0 : _c[mvv.variableId];
                        const value = frontend_2.ModuleFE_Value.cache.unique(valueId);
                        if (!value && !editMode)
                            return;
                        if (editMode) {
                            let innerValueIds = [];
                            const containerValue = frontend_2.ModuleFE_Value.cache.unique(variable.valueId);
                            switch (containerValue.type) {
                                case consts_1.valueType_Range:
                                    innerValueIds = containerValue.data.subsets;
                                    break;
                                case consts_1.valueType_Enumerated:
                                    innerValueIds = containerValue.data.scope;
                                    break;
                                case consts_1.valueType_Tuple:
                                    innerValueIds = containerValue.data.scope;
                                    break;
                            }
                            return React.createElement(React.Fragment, { key: variable._id },
                                React.createElement("div", { className: 'data-grid__label' }, variable._id === mv.item._firstVarId ? 'Value' : variable.name),
                                React.createElement(frontend_2.DropDown_Values, { variable: variable, placeholder: 'Select', caret: dd_carets, selected: value, canUnselect: true, queryFilter: item => innerValueIds.includes(item._id), onSelected: () => {
                                    } }));
                        }
                        return React.createElement(React.Fragment, { key: variable._id },
                            React.createElement("div", { className: 'data-grid__label' }, variable._id === mv.item._firstVarId ? 'Value' : variable.name),
                            React.createElement("div", { className: 'data-grid__data' }, (0, frontend_2.resolveValueDisplay)(value)));
                    }),
                    this.renderLikelihood(dv),
                    this.renderSeverity(dv),
                    i !== diseaseValues.length - 1 && React.createElement("div", { className: 'grid-separator' }));
            });
        };
        this.renderReferencesSection = () => {
            var _a;
            if (this.state.displayedSection !== 'References')
                return;
            const mv = this.state.diseaseVariable;
            return React.createElement(React.Fragment, null, (_a = mv.item.referenceIds) === null || _a === void 0 ? void 0 : _a.map(refId => {
                const ref = frontend_2.ModuleFE_Reference.cache.unique(refId);
                return React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'data-grid__label' }, ref.type),
                    React.createElement("div", { className: 'data-grid__data' }, (0, frontend_2.resolveReferenceDisplay)(ref)));
            }));
        };
        this.renderDiseaseInfoSection = () => {
            var _a, _b;
            if (this.state.displayedSection !== 'Disease Info')
                return;
            const variable = frontend_2.ModuleFE_Variable.cache.unique(this.state.diseaseVariable.item._firstVarId);
            const variantTags = this.getVariants();
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'data-grid__label' }, "Role"),
                React.createElement("div", { className: 'data-grid__data' }, (_a = variable.role) !== null && _a !== void 0 ? _a : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "Perspective"),
                React.createElement("div", { className: 'data-grid__data' }, (_b = variable.perspective) !== null && _b !== void 0 ? _b : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "Variants"),
                variantTags.length
                    ? React.createElement(frontend_1.LL_V_L, null, variantTags.map(tag => React.createElement("div", { className: 'data-grid__data' }, tag.label)))
                    : React.createElement("div", { className: 'data-grid__data' }, "-"));
        };
        this.renderSnomedInfoSection = () => {
            var _a, _b, _c, _d, _e;
            if (this.state.displayedSection !== 'Snomed Info')
                return;
            const variable = frontend_2.ModuleFE_Variable.cache.unique(this.state.diseaseVariable.item._firstVarId);
            const references = variable.referenceIds.map(i => frontend_2.ModuleFE_Reference.cache.unique(i));
            const snomedRef = references.find(i => i.type === 'snomed');
            const icdRef = references.find(i => i.type === 'icdCode');
            let icd10 = (_a = snomedRef === null || snomedRef === void 0 ? void 0 : snomedRef.data.icd10) === null || _a === void 0 ? void 0 : _a.trim();
            if ((icd10 === null || icd10 === void 0 ? void 0 : icd10.toLowerCase()) !== 'not in scope.')
                icd10 = icd10 === null || icd10 === void 0 ? void 0 : icd10.replace(/\s+/g, ', ');
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'data-grid__label' }, "Snomed ID"),
                React.createElement("div", { className: 'data-grid__data' }, (_b = snomedRef === null || snomedRef === void 0 ? void 0 : snomedRef.data.id) !== null && _b !== void 0 ? _b : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "Snomed Name"),
                React.createElement("div", { className: 'data-grid__data' }, (_d = (_c = snomedRef === null || snomedRef === void 0 ? void 0 : snomedRef.data.names) === null || _c === void 0 ? void 0 : _c.join(', ')) !== null && _d !== void 0 ? _d : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "Snomed ICD10"),
                React.createElement("div", { className: 'data-grid__data' }, icd10 !== null && icd10 !== void 0 ? icd10 : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "ICD Code"),
                React.createElement("div", { className: 'data-grid__data' }, (_e = icdRef === null || icdRef === void 0 ? void 0 : icdRef.data.id) !== null && _e !== void 0 ? _e : '-'));
        };
        // ######################## Render - Sections ########################
        this.renderEditModeActions = () => {
            if (this.state.displayedSection !== 'Values' || !this.state.editMode)
                return;
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__disease-profile__disease-variable-data__edit-actions' },
                React.createElement("div", { className: 'manager__disease-profile__disease-variable-data__edit-action' }, "+ Add Value"),
                React.createElement("div", { className: 'manager__disease-profile__disease-variable-data__edit-action' }, "+ Add Attribute"),
                React.createElement("div", { className: 'manager__disease-profile__disease-variable-data__edit-action' }, "+ Add Variable"));
        };
        // ######################## Render - Specific Parts ########################
        this.renderLikelihood = (dv) => {
            const likelihoodStr = this.getLikelihoodString(dv);
            const editMode = this.state.editMode;
            if (!likelihoodStr && !editMode)
                return;
            return React.createElement(React.Fragment, { key: `${dv.item._id}-likelihood` },
                React.createElement("div", { className: 'data-grid__label' }, "Likelihood"),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, value: likelihoodStr, forceLock: !editMode, className: 'data-grid__data' },
                    React.createElement(frontend_1.LL_V_L, { className: 'dv-likelihood' },
                        React.createElement(frontend_1.LL_H_C, { className: 'dv-likelihood__range' },
                            React.createElement(frontend_2.QRangeInput, { value: dv.item.likelihoodRange, onValueBlur: val => this.onDiseaseLikelihoodRangeUpdated(dv, val) }),
                            React.createElement("span", { className: 'dv-likelihood__range__percent' }, "%")),
                        React.createElement(_enum_1.DropDown_Likelihood.selectable, { selected: dv.item.likelihood, onSelected: likelihood => this.onDiseaseLikelihoodSelected(dv, likelihood), canUnselect: true }),
                        React.createElement(_enum_1.DropDown_LikelihoodDirection.selectable, { selected: dv.item.likelihoodDirection, canUnselect: true, onSelected: direction => this.onDiseaseLikelihoodDirectionSelected(dv, direction) }))));
        };
        this.renderSeverity = (dv) => {
            const editMode = this.state.editMode;
            if (!dv.item.severityFactor && !editMode)
                return;
            if (editMode) {
                return React.createElement(React.Fragment, { key: `${dv.item._id}-severity` },
                    React.createElement("div", { className: 'data-grid__label' }, "Severity"),
                    React.createElement(_enum_1.DropDown_Severity.selectable, { selected: dv.item.severityFactor, canUnselect: true, onSelected: severity => {
                            dv.set('severityFactor', severity);
                            this.saveDV(dv);
                        } }));
            }
            return React.createElement(React.Fragment, { key: `${dv.item._id}-severity` },
                React.createElement("div", { className: 'data-grid__label' }, "Severity"),
                React.createElement("div", { className: 'data-grid__data' }, _enum_1.SeverityLabelMap[dv.item.severityFactor]));
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        if (((_a = state.diseaseVariable) === null || _a === void 0 ? void 0 : _a.item._id) !== nextProps.diseaseVariableId)
            state.diseaseVariable = this.getEditableDiseaseVariable(_entity_1.ModuleFE_MultiVar.cache.unique(nextProps.diseaseVariableId));
        state.diseaseProfile = _entity_1.ModuleFE_DiseaseProfile.cache.unique(nextProps.diseaseProfileId);
        state.editMode = nextProps.editMode;
        (_b = state.displayedSection) !== null && _b !== void 0 ? _b : (state.displayedSection = displaySections[0]);
        return state;
    }
    // ######################## Logic ########################
    getEditableDiseaseVariable(mv) {
        return new frontend_1.EditableDBItemV3(mv, _entity_1.ModuleFE_MultiVar)
            .setOnChanged(async (editable) => this.setState({ diseaseVariable: editable }));
    }
    // ######################## Render - Structure ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'manager__disease-profile__disease-variable-data' },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Renderer_DiseaseVariableData = Renderer_DiseaseVariableData;
