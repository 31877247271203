"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorWrapper_Order = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const OrderItem_EditorRenderer_1 = require("./editor/OrderItem_EditorRenderer");
const frontend_2 = require("@app/styles/frontend");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const shared_1 = require("../../../../_entity/action/shared");
const ts_common_1 = require("@nu-art/ts-common");
require("./EditorWrapper_Order.scss");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const editKey = 'edit';
class EditorWrapper_Order extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.saveAction = async () => {
            const order = this.state.editable;
            if (!order)
                return;
            try {
                await order.save();
                this.setState({ editMode: false });
            }
            catch (err) {
                this.logError(err);
            }
        };
        this.deleteAction = () => {
            const order = this.state.editable;
            if (!order)
                return;
            frontend_2.Dialog_Confirmation.show({
                header: 'Are you sure?',
                content: this.renderDeleteDialog,
                buttons: [
                    {
                        label: 'Yes, Delete',
                        isAsync: true,
                        action: async () => {
                            var _a, _b;
                            try {
                                await order.delete();
                                (_b = (_a = this.props).deselectItem) === null || _b === void 0 ? void 0 : _b.call(_a);
                            }
                            catch (err) {
                                this.logError(err);
                                frontend_1.ModuleFE_Toaster.toastError(`Order ${order.item.name} has other entities depending on it`);
                            }
                        }
                    },
                    {
                        label: 'Don\'t Delete',
                        isPreferred: true,
                        action: () => {
                        }
                    }
                ]
            });
        };
        // ######################## Render ########################
        this.renderDeleteDialog = () => {
            const order = this.state.editable;
            return React.createElement(React.Fragment, null,
                React.createElement("div", null, "You're about to delete the order"),
                React.createElement("div", null, order.item.name));
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            if (!this.state.editMode)
                return this.renderViewHeader();
            return this.renderEditHeader();
        };
        this.renderViewHeader = () => {
            var _a;
            const action = this.state.editable.item;
            let subtitle = (0, ts_common_1.capitalizeFirstLetter)((_a = action.type) !== null && _a !== void 0 ? _a : '');
            if (action.subType)
                subtitle += ` | ${shared_1.actionSubTypeLabelMap[action.subType]}`;
            return React.createElement(frontend_2.Header_WithOptionMenu, { title: action.name, subtitle: subtitle, className: 'underline', menuActions: [
                    {
                        label: 'Edit Action',
                        action: () => this.setState({ editMode: true })
                    },
                    {
                        label: 'Delete Action',
                        action: this.deleteAction,
                        // permissionKey: PermissionKeyFE_ActionEdit
                    },
                ] });
        };
        this.renderEditHeader = () => {
            const action = this.state.editable.item;
            const title = action._id ? `Edit ${action.name}` : 'Add New Action';
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__header edit-mode' },
                React.createElement(frontend_1.LL_H_C, { className: 'manager__header__main' },
                    React.createElement(frontend_1.LL_V_L, { className: 'manager__header__title' },
                        React.createElement("div", { className: 'manager__header__title__label' }, title))),
                this.renderHeaderTail());
        };
        this.renderHeaderTail = () => {
            var _a;
            if (!this.state.editMode)
                return;
            if (!((_a = this.state.editable) === null || _a === void 0 ? void 0 : _a.item._id))
                return React.createElement(frontend_1.LL_H_C, { className: 'manager__header__tail' },
                    React.createElement(frontend_1.TS_Button, { onClick: this.props.deselectItem }, "Cancel"),
                    React.createElement(frontend_1.TS_BusyButton, { className: 'preferred-action', onClick: this.saveAction }, "Save"));
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__header__tail' },
                React.createElement(frontend_1.TS_Button, { className: 'preferred-action', onClick: () => this.setState({ editMode: false }) }, "Done"));
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        if (!state.editMode)
            state.editMode = !state.editable.item._id;
        else
            state.editMode = state.editable.item._id !== ((_a = this.state) === null || _a === void 0 ? void 0 : _a.editable.item._id) ? false : state.editMode;
        return state;
    }
    render() {
        this.state.editable;
        return React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: editKey, modes: [
                {
                    key: editKey,
                    renderer: () => React.createElement(frontend_1.LL_V_L, { className: 'quai-item-manager', id: 'editor-wrapper__order' },
                        this.renderHeader(),
                        React.createElement(OrderItem_EditorRenderer_1.OrderItem_EditorRenderer, { editable: this.state.editable, editMode: this.state.editMode }))
                }
            ] });
    }
}
exports.EditorWrapper_Order = EditorWrapper_Order;
