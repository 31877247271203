"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscussionsView = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./DiscussionsView.scss");
const Discussion_Renderer_1 = require("../discussion/Discussion_Renderer");
const ts_common_1 = require("@nu-art/ts-common");
const styles_1 = require("@app/styles");
const q_components_1 = require("../../q-components");
const DiscussionView_FilterOptionsMenu_1 = require("./DiscussionView_FilterOptionsMenu");
const Renderer_NewDiscussion_1 = require("../discussion/Renderer_NewDiscussion");
const _entity_1 = require("../../../_entity");
const frontend_2 = require("@app/styles/frontend");
const frontend_3 = require("@nu-art/ts-messaging/_entity/topic/frontend");
const frontend_4 = require("@nu-art/ts-messaging/_entity/message/frontend");
const modulesToAwait = [_entity_1.ModuleFE_Discussion, frontend_3.ModuleFE_Topic, frontend_4.ModuleFE_Message];
class DiscussionsView_Class extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.transitionTimeout = 300;
        this.renderNewDiscussion = () => {
            if (!this.state.newDiscussion)
                return;
            return React.createElement(Renderer_NewDiscussion_1.Renderer_NewDiscussion, { type: this.state.newDiscussion.type, refId: this.state.newDiscussion.refId });
        };
        this.renderLoader = () => React.createElement(React.Fragment, null);
    }
    // ######################### Life Cycle #########################
    __onLoginStatusUpdated() {
        this.reDeriveState({ showDiscussionsPanel: false });
    }
    __onMessagesUpdated(...params) {
        this.reDeriveState();
    }
    __onDiscussionsUpdated(...params) {
        this.reDeriveState({ newDiscussion: undefined });
    }
    __discussionsViewOptionsChanged() {
        this.reDeriveState();
    }
    __showDiscussions(props) {
        if (props.topicId) {
            const topic = frontend_3.ModuleFE_Topic.cache.unique(props.topicId);
            if (!topic)
                throw new ts_common_1.MUSTNeverHappenException(`Was given a topicId, for a topic that does not exist! ${props.topicId}`);
            return this.reDeriveState({
                showDiscussionsPanel: true,
                focusedObject: topic
            });
        }
        if (props.newDiscussion) {
            return this.setState({
                newDiscussion: props.newDiscussion,
                showDiscussionsPanel: true
            });
        }
        if (this.state.showDiscussionsPanel)
            return this.reDeriveState({ showDiscussionsPanel: false });
        const _focusedObject = _entity_1.storage_FilterSelected.get(false) ? this.state.focusedObject : undefined;
        this.reDeriveState({ showDiscussionsPanel: true, focusedObject: _focusedObject, newDiscussion: undefined });
    }
    __onUrlParamsChanged() {
        const refId = frontend_1.ModuleFE_BrowserHistory.getQueryParameter(frontend_2.ManagerUI_ItemId);
        const type = frontend_1.ModuleFE_BrowserHistory.getQueryParameter(frontend_2.ManagerUI_ItemType);
        const focusedObject = (0, ts_common_1.logicalXOR)(!!refId, !!type) ? undefined : { type, refId };
        this.reDeriveState({ focusedObject: focusedObject });
    }
    deriveStateFromProps(nextProps, state) {
        state.discussions = this.getDiscussionsToShow(state.focusedObject);
        return state;
    }
    getDiscussionsToShow(focusedObject) {
        let discussions = _entity_1.ModuleFE_Discussion.cache.all();
        //Filter discussions by focused object(specific variable, or another db object) - show only and all discussions about this object
        const focusedTopicIds = this.getTopicsFromFocusedObject(focusedObject).map(ts_common_1.dbObjectToId);
        if (focusedTopicIds.length > 0)
            // if()
            discussions = discussions.filter(discussion => focusedTopicIds.includes(discussion.topicId));
        //Filter discussions by resolved status, and sort by last message shown on top.
        const shouldShowResolvedDiscussions = _entity_1.ModuleFE_Discussion.shouldShowResolvedDiscussions();
        return (0, ts_common_1.sortArray)(discussions.filter(discussion => shouldShowResolvedDiscussions ? true : discussion.status !== 'resolved'), discussion => { var _a; return (_a = _entity_1.ModuleFE_Discussion.getLastMessageForDiscussion(discussion)) === null || _a === void 0 ? void 0 : _a.__created; });
    }
    getTopicsFromFocusedObject(focusedObject) {
        if (!focusedObject)
            return [];
        return _entity_1.ModuleFE_Discussion.getTopicsByObjectRef(focusedObject.type, focusedObject.refId);
    }
    renderDiscussionsSearch() {
        return React.createElement("div", null);
        //todo replace with search dropdown
        return React.createElement(frontend_1.LL_H_C, null,
            React.createElement(styles_1.ICONS.search.component, null),
            "Search");
    }
    renderSortPopUp(e) {
        const discussionView = {
            id: 'display-options-menu',
            content: () => {
                this.logInfo(`popup render content, filterSelected: ${this.state.filterSelected}`);
                return React.createElement(DiscussionView_FilterOptionsMenu_1.DiscussionsView_FilterOptionsMenu, null);
            },
            modalPos: { x: -1, y: 1 },
            offset: { y: 20, x: 15 },
            originPos: (0, frontend_1.getElementCenterPos)(e.currentTarget)
        };
        try {
            frontend_1.ModuleFE_MouseInteractivity.showContent(discussionView);
            this.logInfo('Clicking on display options');
        }
        catch (e) {
            this.logWarning(e);
        }
    }
    renderDisplayOptionsMenu() {
        return React.createElement(frontend_1.LL_H_C, { className: 'display-options-menu' },
            this.renderDiscussionsSearch(),
            React.createElement(styles_1.ICONS.displayOptions.component, { className: 'sort-discussion-button', onClick: (e) => this.renderSortPopUp(e) }));
    }
    render() {
        return React.createElement(frontend_1.TS_ComponentTransition, { trigger: this.state.showDiscussionsPanel, transitionTimeout: this.transitionTimeout },
            React.createElement(frontend_1.LL_V_L, { className: 'quai-discussions-container' },
                this.renderDisplayOptionsMenu(),
                React.createElement(q_components_1.QScrollWrapper, { className: 'discussions-scrolling-container' },
                    this.renderNewDiscussion(),
                    this.state.discussions.map(discussionToRender => React.createElement(Discussion_Renderer_1.Discussion_Renderer, { key: discussionToRender._id, discussion: discussionToRender })).reverse())));
    }
}
const DiscussionsView = () => {
    return React.createElement(frontend_1.AwaitModules, { modules: modulesToAwait },
        React.createElement(DiscussionsView_Class, null));
};
exports.DiscussionsView = DiscussionsView;
