"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.valueToExpressionSingle = exports.valueToExpressionBulk = void 0;
const shared_1 = require("../../_entity/expression/shared");
const consts_1 = require("./consts");
const ts_common_1 = require("@nu-art/ts-common");
const consts_2 = require("../../_entity/expression/shared/consts");
/**
 * Converts a bulk of variable-value couplings into expressions.
 * Iterates over each coupling to convert them individually and collects conversion outcomes.
 *
 * @param dependencyMapper A mapping of dependencies to facilitate conversion.
 * @param dataMappers An object containing mappings to assist with value and variable resolution.
 * @returns An object containing arrays of successfully converted expressions and variables that failed conversion.
 */
const valueToExpressionBulk = (dependencyMapper, dataMappers) => {
    const convertedExpressions = [];
    const failedToConvert = [];
    (0, ts_common_1._keys)(dependencyMapper).forEach(key => {
        dependencyMapper[key].forEach(coupling => {
            const expression = (0, exports.valueToExpressionSingle)(coupling, dataMappers, key);
            if (expression)
                return convertedExpressions.push(expression);
            if (coupling.variable)
                failedToConvert.push(coupling.variable);
        });
    });
    return { expressions: convertedExpressions, failedToConvert: failedToConvert };
};
exports.valueToExpressionBulk = valueToExpressionBulk;
/**
 * Converts a single variable-value coupling into an expression.
 * Determines the conversion function based on the dependency amount.
 *
 * @param coupling The coupling of a variable and its corresponding value to be converted.
 * @param dataMappers An object containing mappings for values and variables to assist with conversion.
 * @param _dependencyAmount The amount of dependencies as a string, which influences the conversion logic.
 * @returns A UI_Expression representing the converted expression, or undefined if conversion fails.
 */
const valueToExpressionSingle = (coupling, dataMappers, _dependencyAmount) => {
    const dependencyAmount = (0, ts_common_1.exists)(_dependencyAmount) ? Number(_dependencyAmount) : coupling.value.data.declarationVarIds.length;
    switch (dependencyAmount) {
        case 0:
            return convertCoupling(coupling, dataMappers, convertToNoDependencyExpression);
        case 1:
            return convertCoupling(coupling, dataMappers, convertToSingleAtom);
        default:
            return convertCoupling(coupling, dataMappers, convertToSubTerms);
    }
};
exports.valueToExpressionSingle = valueToExpressionSingle;
/**
 * A wrapper function to handle conversion of a coupling to an expression, logging errors if they occur.
 *
 * @param coupling The variable-value coupling to convert.
 * @param dataMappers Mappings for resolving variables and values during conversion.
 * @param conversionFunction The specific conversion logic to use based on the coupling context.
 * @returns A UI_Expression representing the converted expression, or undefined if an error occurs.
 */
const convertCoupling = (coupling, dataMappers, conversionFunction) => {
    var _a, _b;
    try {
        return conversionFunction(coupling, dataMappers);
    }
    catch (err) {
        ts_common_1.StaticLogger.logErrorBold(`error in variable ${(_b = (_a = coupling.variable) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'No var name'}`, err);
    }
};
/**
 * Converts a coupling into an expression with no dependencies.
 *
 * @param coupling The variable-value coupling to convert.
 * @param dataMappers Not used in this context but included for consistency in the conversion function interface.
 * @returns A UI_Expression with no dependencies.
 * @throws MUSTNeverHappenException If more than one normal range is present without dependencies.
 */
const convertToNoDependencyExpression = (coupling, dataMappers) => {
    if (coupling.value.data.normalValues.length > 1)
        throw new ts_common_1.MUSTNeverHappenException('cannot implement more than one normal range without variable dependencies');
    const normalRangeExpression = (0, consts_1.Empty_NormalRangeExpression)(coupling.value, coupling.variable);
    normalRangeExpression.expression = `${(0, ts_common_1.__stringify)(coupling.value.data.normalValues[0].range)}`;
    return normalRangeExpression;
};
/**
 * Converts a coupling into an expression with a single atom.
 *
 * @param coupling The variable-value coupling to convert.
 * @param dataMappers Mappings for resolving variables and values to construct cases for the atom.
 * @returns A UI_Expression with a single atom derived from the coupling's value.
 */
const convertToSingleAtom = (coupling, dataMappers) => {
    const normalRangeExpression = (0, consts_1.Empty_NormalRangeExpression)(coupling.value, coupling.variable);
    const variableId = coupling.value.data.declarationVarIds[0];
    let elseRange = [0, 0];
    const cases = (0, ts_common_1.filterInstances)(coupling.value.data.normalValues.map(normalRange => {
        const value = dataMappers.valueMap[(0, ts_common_1._values)(normalRange.conditions)[0]];
        const variable = dataMappers.variableMap[variableId];
        const variableValue = dataMappers.valueMap[variable.valueId];
        if (!(0, ts_common_1._keys)(normalRange.conditions).length) {
            elseRange = normalRange.range;
            return;
        }
        return {
            rightHand: (value === null || value === void 0 ? void 0 : value._id) ? [value._id] : [],
            value: normalRange.range,
            caseOperator: variableValue.type === 'range' ? consts_2.LogicType_In : consts_2.LogicType_Equals
        };
    }));
    normalRangeExpression.terms = [{
            expression: 'A1',
            atoms: [{
                    leftHand: {
                        type: 'variable',
                        id: variableId
                    },
                    cases: cases,
                    fallbackValue: elseRange
                }],
        }];
    return normalRangeExpression;
};
/**
 * Converts a coupling into an expression with multiple sub-terms, each potentially containing multiple atoms.
 *
 * @param coupling The variable-value coupling to convert.
 * @param dataMappers Mappings for resolving variables and values, crucial for creating the complex structure of sub-terms.
 * @returns A UI_Expression representing a complex expression derived from the coupling's value.
 */
const convertToSubTerms = (coupling, dataMappers) => {
    const normalRangeExpression = (0, consts_1.Empty_NormalRangeExpression)(coupling.value, coupling.variable);
    const atoms = coupling.value.data.declarationVarIds.map(varId => ({
        leftHand: {
            type: 'variable',
            id: varId
        },
        cases: [{
                caseOperator: consts_2.LogicType_Self,
                rightHand: [],
                valueType: (0, shared_1.resolveValueType)({ type: 'variable', id: varId }, dataMappers)
            }]
    }));
    let elseCase = [0, 0];
    const subTerms = (0, ts_common_1.filterInstances)(coupling.value.data.normalValues.map(normalRange => {
        if (!(0, ts_common_1._keys)(normalRange.conditions).length) {
            elseCase = normalRange.range;
            return;
        }
        return {
            expression: (0, ts_common_1._keys)(normalRange.conditions).map((condition, index) => `A${index + 1}`).join(` ${consts_2.LogicType_And} `),
            atomsLogic: consts_2.LogicType_And,
            atoms: (0, ts_common_1.filterInstances)((0, ts_common_1._keys)(normalRange.conditions).map(variableId => {
                const atomId = coupling.value.data.declarationVarIds.indexOf(variableId);
                const variable = dataMappers.variableMap[variableId];
                const variableValue = dataMappers.valueMap[variable.valueId];
                if (atomId === -1)
                    return;
                return {
                    leftHand: {
                        type: 'atom',
                        id: String(atomId + 1)
                    },
                    cases: [{
                            rightHand: [normalRange.conditions[variableId]],
                            caseOperator: variableValue.type === 'range' ? consts_2.LogicType_In : consts_2.LogicType_Equals,
                        }]
                };
            })),
            value: normalRange.range
        };
    }));
    normalRangeExpression.terms = [{
            expression: 'A1',
            atomsLogic: consts_2.LogicType_Complex,
            subTerms: subTerms,
            fallbackValue: elseCase,
            value: [0, 0],
            atoms: atoms
        }];
    return normalRangeExpression;
};
