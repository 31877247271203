"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Section_AllPathwayTransitions = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../../../../../../../_entity/pathway-transition/frontend");
const frontend_3 = require("../../../../../../../../_entity/pathway-state/frontend");
const Renderer_Transition_1 = require("../../../../../renderers/Renderer_Transition");
const styles_1 = require("@app/styles");
const utils_1 = require("../../../../../utils");
class Section_AllPathwayTransitions extends frontend_1.ComponentSync {
    __onPathwayTransitionUpdated(...params) {
        if (!this.props.pathwayId)
            return;
        (0, utils_1.checkIfPathwayElementChanged)(this.state.relevantTransitions, params, (item) => item.pathwayId === this.props.pathwayId, () => this.reDeriveState(), () => this.reDeriveState());
    }
    deriveStateFromProps(nextProps, state) {
        state.relevantTransitions = this.getTransitions();
        return state;
    }
    getTransitions() {
        let pathwayTransitions = frontend_2.ModuleFE_PathwayTransition.cache.filter(transition => transition.pathwayId === this.props.pathwayId);
        pathwayTransitions = (0, ts_common_1.sortArray)(pathwayTransitions, transition => {
            const target = frontend_3.ModuleFE_PathwayState.cache.unique(transition.targetIds[0]);
            const origin = frontend_3.ModuleFE_PathwayState.cache.unique(transition.sourceIds[0]);
            if (!origin)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find state for id ${transition.sourceIds[0]}`);
            if (!target)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find state for id ${transition.targetIds[0]}`);
            return [origin.name.toLowerCase(), target.name.toLowerCase()];
        });
        return pathwayTransitions;
    }
    render() {
        return React.createElement(frontend_1.TS_CollapsableContainer, { headerRenderer: React.createElement(React.Fragment, null,
                this.state.relevantTransitions.length,
                " Transitions"), containerRenderer: React.createElement(React.Fragment, null, this.state.relevantTransitions.map(transition => {
                return React.createElement(Renderer_Transition_1.Renderer_Transition, { key: transition._id, transition: transition, onClick: () => this.props.functions.selectPathwayTransition(transition._id) });
            })), customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null) });
    }
}
exports.Section_AllPathwayTransitions = Section_AllPathwayTransitions;
