"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Facility = exports.ModuleFE_Facility_Class = exports.dispatch_onFacilityChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onFacilityChanged = new types_1.ThunderDispatcherV3('__onFacilityUpdated');
class ModuleFE_Facility_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Facility, exports.dispatch_onFacilityChanged);
        this.__searchFilterMapper = (item) => [item.name];
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => ({ _id: i._id, uiLabel: i.name }))
            }]);
    }
}
exports.ModuleFE_Facility_Class = ModuleFE_Facility_Class;
exports.ModuleFE_Facility = new ModuleFE_Facility_Class();
