"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WarningDialog = exports.warningDialogTypes = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./WarningDialog.scss");
const styles_1 = require("@app/styles");
exports.warningDialogTypes = ['normal', 'delete'];
class WarningDialog extends frontend_1.TS_Dialog {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        // ######################## Logic ########################
        this.closeDialog = () => {
            if (!this.state.dialogIsBusy)
                frontend_1.ModuleFE_Dialog.close();
        };
        this.execute = async () => {
            try {
                this.props.onInProgress(true);
                this.setState({ dialogIsBusy: true });
                await this.props.options.onConfirm();
                frontend_1.ModuleFE_Dialog.close();
            }
            catch (err) {
                this.logError(err);
                this.setState({ dialogIsBusy: false });
            }
        };
        this.onCancel = () => {
            var _a, _b;
            (_b = (_a = this.props.options).onCancel) === null || _b === void 0 ? void 0 : _b.call(_a);
            this.closeDialog();
        };
        // ######################## Render ########################
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("span", { className: "header__title" }, this.props.options.title),
                React.createElement("div", { className: 'header__buttons' },
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: this.closeDialog })));
        };
        this.renderBody = () => {
            return React.createElement(React.Fragment, null,
                React.createElement(styles_1.ICONS.attention.component, { className: 'warning-icon' }),
                React.createElement(frontend_1.LL_V_L, null, this.props.options.warningMessages.map((message, i) => React.createElement("div", { key: `message-${i}`, className: 'dialog-main__text' }, message))));
        };
        this.buttons = () => {
            return {
                right: [
                    { content: 'Cancel', className: 'cancel-button', onClick: this.onCancel },
                    {
                        content: this.props.options.executeButtonText,
                        renderer: frontend_1.TS_Dialog.busyButton,
                        onClick: this.execute,
                        className: 'desired-action-button',
                        associatedKeys: ['Enter'],
                    }
                ]
            };
        };
    }
    static show(options) {
        var _a, _b, _c;
        let _inProgress = false;
        (_a = options.title) !== null && _a !== void 0 ? _a : (options.title = 'Warning');
        (_b = options.executeButtonText) !== null && _b !== void 0 ? _b : (options.executeButtonText = 'Save');
        (_c = options.type) !== null && _c !== void 0 ? _c : (options.type = 'normal');
        frontend_1.ModuleFE_Dialog.show(React.createElement(WarningDialog, { className: options.type, options: options, onInProgress: inProgress => _inProgress = inProgress }), () => {
            return !_inProgress;
        });
    }
    static showDelete(options) {
        var _a, _b;
        (_a = options.title) !== null && _a !== void 0 ? _a : (options.title = 'Warning');
        (_b = options.executeButtonText) !== null && _b !== void 0 ? _b : (options.executeButtonText = 'Delete');
        options.type = 'delete';
        this.show(options);
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps) {
        this.props.onInProgress(false);
        return { dialogIsBusy: false };
    }
}
WarningDialog.defaultProps = {
    dialogId: 'warning-dialog',
};
exports.WarningDialog = WarningDialog;
