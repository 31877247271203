"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePackFE_HealthcareSpace = void 0;
const _entity_1 = require("./_entity");
const ModuleFE_Dictionary_1 = require("./dictionary/ModuleFE_Dictionary");
exports.ModulePackFE_HealthcareSpace = [
    ModuleFE_Dictionary_1.ModuleFE_Dictionary,
    ..._entity_1.ModulePackFE_DiscussionDB,
    ..._entity_1.ModulePackFE_VariableRelation,
    ..._entity_1.ModulePackFE_MeasurementUnit,
    ..._entity_1.ModulePackFE_Value,
    ..._entity_1.ModulePackFE_Variable,
    ..._entity_1.ModulePackFE_MacroTag,
    ..._entity_1.ModulePackFE_UserDB,
    ..._entity_1.ModulePackFE_NLPDataDB,
    ..._entity_1.ModulePackFE_AttributeSetDB,
    ..._entity_1.ModulePackFE_BookDB,
    ..._entity_1.ModulePackFE_ReferenceDB,
    ..._entity_1.ModulePackFE_TagDB,
    ..._entity_1.ModulePackFE_ClinicalFormDB,
    ..._entity_1.ModulePackFE_LogTemplateDB_OLD,
    ..._entity_1.ModulePackFE_ScoreDB,
    ..._entity_1.ModulePackFE_ResourceDB,
    ..._entity_1.ModulePackFE_Expression
];
