"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_LogTemplateEditor = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../../../../_entity/log-template/shared");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
require("./Component_LogTemplateEditor.scss");
const ts_common_1 = require("@nu-art/ts-common");
const Editor_LogTemplatesParams_1 = require("./editors/Editor_LogTemplatesParams");
const TS_EditableItemStatus_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemStatus/TS_EditableItemStatus");
const Editor_ExternalParamsEditor_1 = require("./editors/Editor_ExternalParamsEditor");
class Component_LogTemplateEditor extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        /**
         * Collect all parameters from the updated value and update editable instance with new parameters
         * @param newValue - the value updated in the text area
         * @param otherString - the other prop string value
         */
        this.getUpdatedParamsArray = (newValue, otherString) => {
            var _a;
            //Collect all params in string using regular expression
            const allParamsInString = (0, ts_common_1.filterDuplicates)([...this.matchAllResults(newValue === null || newValue === void 0 ? void 0 : newValue.toLowerCase()), ...this.matchAllResults(otherString === null || otherString === void 0 ? void 0 : otherString.toLowerCase())]);
            // Fail fast in case no parameters found
            if (!allParamsInString.length)
                return [];
            //Current params in object
            const paramsInTemplate = (_a = this.state.editable.get('params')) !== null && _a !== void 0 ? _a : [];
            //Filter only new params (params that doesn't exists in the editable instance)
            const filterNewParams = allParamsInString.filter(param => !paramsInTemplate.find(_param => _param.paramKey === param));
            //Create partial template param object for all new params
            const partialObjParams = filterNewParams.map(paramKey => ({ paramKey: paramKey }));
            //Filter out removed params
            return [...paramsInTemplate, ...partialObjParams].filter(param => allParamsInString.includes(param.paramKey));
        };
        /**
         * Find all params in a string
         * @param stringToMatch The string to find all params in
         */
        this.matchAllResults = (stringToMatch) => {
            return [...(`${stringToMatch}`).matchAll(/\{([^}]+)\}/g)].map(m => m[1].trim());
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const className = (0, frontend_1._className)('manager__header', 'edit-mode');
            return React.createElement(frontend_1.LL_H_C, { className: className },
                this.renderHeaderMain(),
                this.renderHeaderTail());
        };
        this.renderHeaderMain = () => {
            const logTemplate = this.state.editable;
            const title = logTemplate.get('label');
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__header__main' },
                React.createElement(frontend_1.LL_V_L, { className: 'manager__header__title' },
                    logTemplate.get('_id') && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'manager__header__title__label' }, title)),
                    !logTemplate.get('_id') && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'manager__header__title__label' }, "Add New Log Template"))));
        };
        this.renderHeaderTail = () => {
            const _id = this.state.editable.get('_id');
            return React.createElement(frontend_1.LL_H_C, { className: 'editor-log-template__header__tail' },
                React.createElement(TS_EditableItemStatus_1.TS_EditableItemStatus, { editable: this.state.editable }),
                this.state.editable.get('_id') && React.createElement(frontend_1.TS_Button, { onClick: async () => frontend_1.ModuleFE_Thunderstorm.downloadFile({
                        content: (0, ts_common_1.__stringify)(this.state.editable.item, true),
                        fileName: `log-template-export-${this.state.editable.get('label')}`,
                        mimeType: 'application/json'
                    }) }, "Download"),
                _id && React.createElement(frontend_1.TS_Button, { onClick: this.props.deleteLogTemplate }, "Delete"),
                React.createElement(frontend_1.TS_Button, { onClick: this.props.deselectLogTemplate }, _id ? 'Close' : 'Cancel'),
                React.createElement(frontend_1.TS_BusyButton, { className: 'preferred-action', onClick: async () => this.state.editable.save() }, "Save"));
        };
        // ######################## Render - Body ########################
        this.renderLabelInput = () => {
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Template Label', error: this.state.editable.hasError('label') },
                React.createElement(ui_components_1.DefaultEditor_InputText, { editable: this.state.editable, prop: 'label', onChange: async (value) => {
                        if (value.length) {
                            return await this.state.editable.update('label', value);
                        }
                        await this.state.editable.update('label', undefined);
                    } }));
        };
        this.renderTitleTextArea = () => {
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Template Title', error: this.state.editable.hasError('title') },
                React.createElement(ui_components_1.DefaultEditor_TextArea, { className: 'template-title', editable: this.state.editable, prop: 'title', saveEvent: ['change', 'blur'], onChange: async (value) => {
                        return await this.state.editable.updateObj({
                            title: value.length ? value : undefined,
                            params: this.getUpdatedParamsArray(value, this.state.editable.get('body'))
                        });
                    } }));
        };
        this.renderBodyTextArea = () => {
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Template Body', error: this.state.editable.hasError('body') },
                React.createElement(ui_components_1.DefaultEditor_TextArea, { editable: this.state.editable, prop: 'body', saveEvent: ['change', 'blur'], onChange: async (value) => {
                        return await this.state.editable.updateObj({
                            body: value.length ? value : undefined,
                            params: this.getUpdatedParamsArray(value, this.state.editable.get('title'))
                        });
                    } }));
        };
        this.renderParamsEditor = () => {
            const editableParams = this.state.editable.editProp('params', []);
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Template Parameters' },
                React.createElement(Editor_LogTemplatesParams_1.Editor_LogTemplatesParams, { externalParams: this.state.externalParams, editable: editableParams }));
        };
        this.renderExternalParametersEditor = () => {
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'External Parameters' },
                React.createElement(Editor_ExternalParamsEditor_1.Editor_ExternalParamsEditor, { externalParams: this.state.externalParams }));
        };
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'log-template-manager__editor-body' },
                React.createElement(frontend_1.Grid, null,
                    this.renderLabelInput(),
                    this.renderTitleTextArea(),
                    this.renderBodyTextArea()),
                this.renderParamsEditor(),
                this.renderExternalParametersEditor());
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        state.externalParams = (0, shared_1.collectExternalParams)((_a = state.editable.item.params) !== null && _a !== void 0 ? _a : []);
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'quai-item-manager', id: 'editor-log-template' },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Component_LogTemplateEditor = Component_LogTemplateEditor;
