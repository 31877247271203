"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTransitionLabel = void 0;
/**
 * Get transition label over the relevant transitions pointing on the same 2 states
 * @param labelList All the transitions labels of the existing transitions pointing on the same transitions
 * @param targetState The target state for name base context
 */
const createTransitionLabel = (labelList, targetState) => {
    // In case it's the first state set the first label
    if (!labelList.length)
        return targetState.name;
    // Find the missing index to update the label
    for (let i = 0; i <= labelList.length; i++) {
        const currentLabel = `${targetState.name} (${i + 1})`;
        if (!labelList.includes(currentLabel) && i)
            return currentLabel;
    }
    // In case no index is missing add the list length
    return `${targetState.name} (${labelList.length + 1})`;
};
exports.createTransitionLabel = createTransitionLabel;
