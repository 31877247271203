"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Metadata_Epidemiology = exports.epidemiologyOldConverter = exports.initialEpidemiology = exports.EpidemiologyRateLabelMap = exports.EpidemiologyTypeLabelMap = void 0;
const types_1 = require("./types");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../population/shared");
exports.EpidemiologyTypeLabelMap = {
    incidence: 'Incidence',
    prevalence: 'Prevalence',
};
exports.EpidemiologyRateLabelMap = {
    ratio: 'Ratio',
    percentage: 'Percentage',
};
exports.initialEpidemiology = Object.freeze({
    type: types_1.EpidemiologyType_Prevalence,
    data: {
        rate: types_1.EpidemiologyRate_Percentage,
        rateValue: [0, 0]
    },
    population: {
        type: shared_1.PopulationType_General,
    }
});
const translateRateValue = (old) => {
    if (old.rate === types_1.EpidemiologyRate_Percentage)
        return old.rateValue;
    if (old.rate === types_1.EpidemiologyRate_Ratio)
        return {
            numerator: [old.rateValue[0], old.rateValue[0]],
            denominator: old.rateValue[1],
        };
    throw new ts_common_1.ImplementationMissingException(`Missing implementation for epidemiology of rate ${old.rate}`);
};
const epidemiologyOldConverter = (old) => {
    return {
        type: old.type,
        population: old.population,
        // @ts-ignore
        data: {
            rate: old.rate,
            rateValue: translateRateValue(old)
        }
    };
};
exports.epidemiologyOldConverter = epidemiologyOldConverter;
exports.Metadata_Epidemiology = {
    type: {
        optional: false,
        description: `Type of the epidemiology (${[...types_1.EpidemiologyTypes].join(' / ')})`,
        valueType: 'string',
    },
    rate: {
        optional: false,
        description: `Rate display of the epidemiology (${[...types_1.EpidemiologyRates].join(' / ')})`,
        valueType: 'string',
    },
    rateValue: {
        optional: false,
        description: 'Rate value of the epidemiology',
        valueType: 'number[]',
        metadata: {
            optional: false,
            description: 'number value in the rate',
            valueType: 'number',
        }
    },
    // @ts-ignore
    population: Object.assign({}, shared_1.Metadata_Population),
};
