"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_MacroTag = exports.ModuleFE_MacroTag_Class = exports.dispatch_onMacroTagChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onMacroTagChanged = new types_1.ThunderDispatcherV3('__onMacroTagUpdated');
class ModuleFE_MacroTag_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_MacroTag, exports.dispatch_onMacroTagChanged);
        this.__searchFilterMapper = (item) => [item.label];
        this.__searchGroups = (items, filter) => {
            return [{
                    group: this.dbDef.entityName,
                    results: items.map(item => ({ _id: item._id, uiLabel: item.label }))
                }];
        };
        this._v1 = {};
    }
}
exports.ModuleFE_MacroTag_Class = ModuleFE_MacroTag_Class;
exports.ModuleFE_MacroTag = new ModuleFE_MacroTag_Class();
