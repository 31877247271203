"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockGladosErrors_Resolving = exports.mockGladosErrors_Creation = exports.issueStatus = void 0;
exports.issueStatus = ['created', 'resolved'];
exports.mockGladosErrors_Creation = {
    "type": "test_creation_error",
    "id": "???",
    "message": "?????",
    "payload": [
        {
            "status": "created",
            "id": "00001a9d77dab5f2f3fc14efdec7ffff",
            "type": "Variable",
            "entityId": "00d47a9d77dab5f2f3fc14efdec79fcb",
            "description": "[HV] angina"
        },
        {
            "status": "created",
            "id": "000025b1414a2be7a012ab2df575ffff",
            "type": "val",
            "entityId": "012a65b1414a2be7a012ab2df5758429",
            "description": "Value OCT, but bad entity name"
        }
    ]
};
exports.mockGladosErrors_Resolving = {
    "type": "test_resolving_error",
    "id": "???",
    "message": "?????",
    "payload": [
        {
            "status": "resolved",
            "id": "00001a9d77dab5f2f3fc14efdec7ffff",
            "type": "Variable",
            "entityId": "00d47a9d77dab5f2f3fc14efdec79fcb",
            "description": "[HV] angina"
        },
        {
            "status": "resolved",
            "id": "000025b1414a2be7a012ab2df575ffff",
            "type": "val",
            "entityId": "012a65b1414a2be7a012ab2df5758429",
            "description": "Value OCT, but bad entity name"
        }
    ]
};
