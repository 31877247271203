"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_ResourceEditor_V2 = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/ts-workspace/frontend");
require("./Page_ResourceEditor_V2.scss");
const frontend_2 = require("@nu-art/permissions/frontend");
const permission_keys_1 = require("./permissions/permission-keys");
const workspace_config_1 = require("./workspace-config");
const EditorWrapper_ResourcesV2_1 = require("./presenter/EditorWrapper_ResourcesV2");
const SearchSelector_BaseManagerUI_1 = require("@app/styles/frontend/ui/ui-manager/Selector_Base/SearchSelector_BaseManagerUI");
const frontend_3 = require("@app/styles/frontend");
const _entity_1 = require("../../_entity");
const q_components_1 = require("../q-components");
class Page_ResourceEditor_V2 extends frontend_3.Page_BaseManagerUI_SingleType_Proto {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.workspace = () => ({
            id: 'resources-editor',
            key: workspace_config_1.workspaceKey_ResourceEditor,
            renderers: {
                horizontalSpace: frontend_1.TS_HorizontalWorkspace,
            },
            instances: {
                selector: () => {
                    return React.createElement(SearchSelector_BaseManagerUI_1.SearchSelector_BaseManagerUI, { searchItems: [Page_ResourceEditor_V2.SearchableItem_Resource], onSearchItemSelected: (item) => this.onSearchResultSelected(item.id, item.type), selectedItem: this.state.selectedResult, buttons: [{
                                label: 'Add Resource',
                                action: this.onCreateNewResource.bind(this)
                            }] });
                },
                presenter: () => {
                    if (!this.state.selectedItem)
                        return React.createElement(q_components_1.QPageMessage, { message: 'Resource Manager' });
                    return React.createElement(EditorWrapper_ResourcesV2_1.EditorWrapper_ResourcesV2, { editable: this.state.selectedItem });
                },
            }
        });
    }
    onCreateNewResource() {
        this.clearSelectedResult();
        this.setState({ selectedItem: this.getEditableItem() });
    }
    getInitialObject() {
        return {};
    }
}
_a = Page_ResourceEditor_V2;
Page_ResourceEditor_V2.Route = {
    key: 'resource-editor',
    path: 'resource-editor',
    Component: _a,
    enabled: (0, frontend_2.RoutePermissions)(permission_keys_1.PermissionKeyFE_ResourceV2Viewer),
    modulesToAwait: [_entity_1.ModuleFE_Resource],
};
Page_ResourceEditor_V2.searchItemKey_Resource = _entity_1.ModuleFE_Resource.dbDef.dbKey;
Page_ResourceEditor_V2.SearchableItem_Resource = {
    module: _entity_1.ModuleFE_Resource,
    type: Page_ResourceEditor_V2.searchItemKey_Resource,
    route: Page_ResourceEditor_V2.Route,
    entityName: _entity_1.ModuleFE_Resource.dbDef.entityName,
    entityLabel: 'Resource',
    groupId: ['Advisor'],
    mapper: (item) => [item.label],
    tags: [],
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_3.Renderer_SearchResult, { itemId: item._id, itemKey: Page_ResourceEditor_V2.searchItemKey_Resource, itemLabel: item.label, entityLabel: 'Resource', hierarchy: 'Resource', selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (item) => {
        const dbItem = _entity_1.ModuleFE_Resource.cache.unique(item);
        if (!dbItem)
            return React.createElement(React.Fragment, null);
        return React.createElement(React.Fragment, null);
    }
};
Page_ResourceEditor_V2.defaultProps = {
    searchItems: [Page_ResourceEditor_V2.SearchableItem_Resource]
};
exports.Page_ResourceEditor_V2 = Page_ResourceEditor_V2;
