"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expressionToValue = void 0;
const shared_1 = require("../../../../../_entity/value/shared");
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
/**
 * Converts an expression to a standard range value data.
 * If the expression has no terms, it converts it based on no dependencies.
 * If there's exactly one term with a single atom, it converts that single atom.
 * @param expression The database expression to convert.
 * @returns A partial `ValueData_StandardRange` object representing the converted value.
 */
const expressionToValue = (expression) => {
    // Fail fast if expression in invalid
    if (expression.terms.length > 1)
        throw new ts_common_1.BadImplementationException('Normal range expression cannot have more than one term');
    try {
        // Convert an expression with no terms
        if (!expression.terms.length)
            return convertNoDependencyValue(expression);
        const expressionTerm = expression.terms[0];
        // Convert a term with only single atom
        if (expressionTerm.atoms.length === 1)
            return convertSingleAtomExpression(expressionTerm.atoms[0]);
        // Convert a complex term with sub-terms
        if ('subTerms' in expressionTerm)
            return convertFromSubTerms(expressionTerm);
    }
    catch (err) {
        ts_common_1.StaticLogger.logErrorBold(`conversion to value failed with error ${err}`);
        return undefined;
    }
    ts_common_1.StaticLogger.logErrorBold(`expression with id ${expression._id} is invalid and need to be checked`);
};
exports.expressionToValue = expressionToValue;
/**
 * Converts expressions with no dependencies to a standard range value.
 * Assumes the expression's range is stored in an array format and verifies it before conversion.
 * @param expression The expression to convert.
 * @returns A partial `ValueData_StandardRange` object with no dependency values.
 * @throws `BadImplementationException` if the expression cannot be converted.
 */
const convertNoDependencyValue = (expression) => {
    const range = JSON.parse(expression.expression);
    if (!isParsedExpressionRange(range))
        throw new ts_common_1.BadImplementationException('cannot convert a non range expression value to standard range value');
    return {
        declarationVarIds: [],
        normalValues: [{ range: range, conditions: {} }]
    };
};
/**
 * Converts a single atom expression to a standard range value.
 * Validates the left-hand side as a unique ID and processes each case of the atom.
 * @param atom The atom to convert.
 * @returns A partial `ValueData_StandardRange` object based on the atom's cases.
 * @throws `BadImplementationException` if the atom cannot be properly converted.
 */
const convertSingleAtomExpression = (atom) => {
    var _a, _b;
    if (!atom.leftHand || !isValueUniqueId(atom.leftHand.id))
        throw new ts_common_1.BadImplementationException('left hand must exists for conversion');
    const tableRow = {
        declarationVarIds: [atom.leftHand.id],
        normalValues: atom.cases.map(_case => {
            if (!_case.value)
                throw new ts_common_1.BadImplementationException('cannot convert into a value table row without normal range value');
            if (_case.rightHand.length > 1)
                throw new ts_common_1.BadImplementationException('right hand must include a single value id');
            if (!isValueUniqueId(_case.rightHand[0]))
                throw new ts_common_1.BadImplementationException(`invalid right hand value ${_case.rightHand[0]}`);
            return {
                range: _case.value,
                conditions: { [atom.leftHand.id]: _case.rightHand[0] }
            };
        })
    };
    if (atom.fallbackValue && (((_a = atom.fallbackValue) === null || _a === void 0 ? void 0 : _a[0]) !== 0 && ((_b = atom.fallbackValue) === null || _b === void 0 ? void 0 : _b[1]) !== 0))
        tableRow.normalValues.push({ range: atom.fallbackValue, conditions: {} });
    return tableRow;
};
/**
 * Converts a term with sub-terms into a standard range value.
 * This function iterates over all atoms within the term to collect their left-hand identifiers,
 * ensuring each is a valid unique ID. It then constructs a `ValueData_StandardRange` object
 * by mapping over the sub-terms and generating `normalValues` entries for each, based on their values
 * and a conditions object derived from the term's atoms.
 *
 * @param term The term with sub-terms to be converted. Expected to contain atoms with valid left-hand
 * identifiers and sub-terms with defined values.
 * @returns A partial `ValueData_StandardRange` object, including the declaration variable IDs
 * derived from the term's atoms and normal values constructed from the sub-terms.
 * @throws `BadImplementationException` if an atom's left hand is missing or invalid, if a sub-term
 * does not have a range value, if any sub-term atom is considered invalid, or if any rightHand value
 * of the atom's cases is invalid. These checks ensure that the conversion process is based on
 * fully defined and valid data structures.
 */
const convertFromSubTerms = (term) => {
    var _a, _b;
    const variableIds = term.atoms.map(atom => {
        if (!atom.leftHand || !isValueUniqueId(atom.leftHand.id))
            throw new ts_common_1.BadImplementationException('missing or invalid left hand value');
        return atom.leftHand.id;
    });
    const normalValues = term.subTerms.map(subTerm => {
        if (!subTerm.value)
            throw new ts_common_1.BadImplementationException('cannot convert without a range');
        return {
            range: subTerm.value,
            conditions: subTerm.atoms.reduce((conditions, atom, currentIndex) => {
                if (!atom.leftHand || atom.leftHand.type !== 'atom') {
                    throw new ts_common_1.BadImplementationException('sub-term atom is invalid');
                    return conditions;
                }
                let rightHandValue = atom.cases[0].rightHand[0];
                if (rightHandValue && !isValueUniqueId(rightHandValue))
                    throw new ts_common_1.BadImplementationException('invalid rightHand value');
                if (!rightHandValue && consts_1.subTermsOperators.includes(atom.cases[0].caseOperator))
                    rightHandValue = atom.cases[0].caseOperator === consts_1.LogicType_IsFalse ? shared_1.valueID_No : shared_1.valueID_Yes;
                if (!rightHandValue)
                    throw new ts_common_1.BadImplementationException('must have right hand value');
                const id = variableIds[Number(atom.leftHand.id) - 1];
                conditions[id] = rightHandValue;
                return conditions;
            }, {})
        };
    });
    if (term.fallbackValue && (((_a = term.fallbackValue) === null || _a === void 0 ? void 0 : _a[0]) !== 0 && ((_b = term.fallbackValue) === null || _b === void 0 ? void 0 : _b[1]) !== 0))
        normalValues.push({ range: term.fallbackValue, conditions: {} });
    return {
        declarationVarIds: variableIds,
        normalValues: normalValues
    };
};
// Utility Functions
/**
 * Type guard to check if a value is a valid uniqueId.
 * @param value The value to check.
 * @returns `true` if the value is a valid `UniqueId`, `false` otherwise.
 */
const isValueUniqueId = (value) => {
    return !(0, ts_common_1.tsValidateResult)(value, ts_common_1.tsValidateUniqueId);
};
/**
 * Type guard to verify if an array can be considered a valid `Range`.
 * Checks if the array consists of exactly two numbers.
 * @param possibleRange The array to check.
 * @returns `true` if the array is a valid `Range`, `false` otherwise.
 */
const isParsedExpressionRange = (possibleRange) => {
    if (possibleRange.length > 2)
        return false;
    return typeof possibleRange[0] === 'number' && typeof possibleRange[1] === 'number';
};
