"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_MacroTagEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_components_1 = require("../../shared-components");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const ts_common_1 = require("@nu-art/ts-common");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
require("./Component_MacroTagEditor.scss");
const _entity_1 = require("../../../_entity");
const ViewMode_Edit = 'edit';
const ViewMode_View = 'view';
class Component_MacroTagEditor extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        // ######################### Lifecycle #########################
        super(...arguments);
        // ######################### Logic #########################
        this.getViewMode = () => this.state.editMode ? ViewMode_Edit : ViewMode_View;
        this.updateMacroTagGroups = async () => {
            const tagGroups = this.translateFromUITagGroups(this.state.uiTagGroups);
            await this.state.editable.updateObj({ tagGroups });
        };
        // ######################### Render #########################
        this.renderLabelInput = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Label', error: editable.hasError('label') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        { key: ViewMode_View, renderer: () => React.createElement("div", { className: '' }, editable.item.label) },
                        { key: ViewMode_Edit, renderer: () => React.createElement(ui_components_1.DefaultEditor_InputText, { editable: editable, prop: 'label' }) }
                    ] }));
        };
        this.renderGroups = () => {
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Tag Groups' },
                React.createElement(shared_components_1.Component_TagEditor, { tags: this.state.uiTagGroups, groupTagFilter: () => true, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id, disableEdit: !this.state.editMode, onChange: this.updateMacroTagGroups }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        state.uiTagGroups = this.translateIntoUITagGroups(state.editable.item.tagGroups);
        return state;
    }
    translateFromUITagGroups(uiTagGroups) {
        return uiTagGroups.map(uiTagGroup => {
            return {
                key: uiTagGroup.key._id,
                value: uiTagGroup.value.map(ts_common_1.dbObjectToId),
            };
        });
    }
    translateIntoUITagGroups(tagGroups) {
        return tagGroups.map(tagGroup => {
            const key = _entity_1.ModuleFE_Tag.cache.unique(tagGroup.key);
            if (!key)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find tag for id ${tagGroup.key}`);
            const value = tagGroup.value.map(id => {
                const tag = _entity_1.ModuleFE_Tag.cache.unique(id);
                if (!tag)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find tag for id ${id}`);
                return tag;
            });
            return {
                key,
                value
            };
        });
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'macro-tag-editor' },
            this.renderLabelInput(),
            this.renderGroups());
    }
}
exports.Component_MacroTagEditor = Component_MacroTagEditor;
