"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Book_2 = void 0;
exports.Book_2 = {
    name: 'Tintinalli\'s Emergency Medicine',
    edition: '9th',
    year: 2019,
    isbn: '9781260019933',
    itemsType: 'section',
    items: [
        {
            name: '1 - Section 1',
            itemsType: 'chapter',
            items: [
                {
                    name: '1 - Chapter 1',
                }, {
                    name: '2 - Chapter 2',
                }
            ]
        },
        {
            name: '2 - Section 2',
            itemsType: 'part',
            items: [
                {
                    name: '1 - Part 1',
                }, {
                    name: '2 - Part 2',
                }
            ]
        }
    ]
};
