"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigKeyFE_LabsTagId = exports.ConfigKeyFE_sourceAdministrativeTagId = exports.ConfigKeyFE_sourceHistoryTagId = exports.ConfigKeyFE_SystemsTagIds = exports.ConfigKeyFE_SourceVitalSignsTag = exports.ConfigKeyFE_Scores = exports.ConfigKeyFE_TagsOrder = exports.ConfigKeyFE_DiseasesViewsTagId = exports.ConfigKeyFE_DiseasesTagId = exports.ConfigKeyFE_SourceExamTagId = exports.ConfigKeyFE_SourceTagId = exports.ConfigKeyFE_ComplaintsTagId = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
exports.ConfigKeyFE_ComplaintsTagId = new frontend_1.AppConfigKey_FE('complaintsTag');
exports.ConfigKeyFE_SourceTagId = new frontend_1.AppConfigKey_FE('sourceTag');
exports.ConfigKeyFE_SourceExamTagId = new frontend_1.AppConfigKey_FE('sourceExamTag');
exports.ConfigKeyFE_DiseasesTagId = new frontend_1.AppConfigKey_FE('diseaseCategory');
exports.ConfigKeyFE_DiseasesViewsTagId = new frontend_1.AppConfigKey_FE('diseaseViewTagId');
exports.ConfigKeyFE_TagsOrder = new frontend_1.AppConfigKey_FE('categoriesOrder');
exports.ConfigKeyFE_Scores = new frontend_1.AppConfigKey_FE('scoresTag');
exports.ConfigKeyFE_SourceVitalSignsTag = new frontend_1.AppConfigKey_FE('vitalsSignsTagId');
exports.ConfigKeyFE_SystemsTagIds = new frontend_1.AppConfigKey_FE('systemsTagIds');
exports.ConfigKeyFE_sourceHistoryTagId = new frontend_1.AppConfigKey_FE('sourceHistoryTagId');
exports.ConfigKeyFE_sourceAdministrativeTagId = new frontend_1.AppConfigKey_FE('sourceAdministrativeTagId');
exports.ConfigKeyFE_LabsTagId = new frontend_1.AppConfigKey_FE('labsTagId');
