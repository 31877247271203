import * as React from 'react';
import {
	LL_V_L,
	ModuleFE_Dialog,
	ModuleFE_Thunderstorm,
	Props_TSDialog,
	State_TSDialog,
	TS_Dialog,
	TS_Input,
	TS_PropRenderer
} from '@nu-art/thunderstorm/frontend';
import {ICONS} from '@app/styles';
import {Week} from '@nu-art/ts-common';
import {ModuleFE_Account} from '@nu-art/user-account/frontend';
import './Dialog_GenerateToken.scss';


type Props = Props_TSDialog & {
	accountId: string
}
type State = State_TSDialog & {
	ttl: number,
	token?: string
}

export class Dialog_GenerateToken
	extends TS_Dialog<Props, State> {
	static defaultProps = {
		dialogId: 'generate-token',
	};

	static show(accountId: string) {
		ModuleFE_Dialog.show(
			<Dialog_GenerateToken accountId={accountId} className={'dialog'}/>,
			() => false);
	}

	// ######################## Life Cycle ########################sc

	protected deriveStateFromProps(nextProps: Props): State {
		return {dialogIsBusy: false, ttl: 0};
	}

	// ######################## Logic ########################

	protected closeDialog = () => {
		if (!this.state.dialogIsBusy)
			ModuleFE_Dialog.close();
	};

	private canCreate = () => {
		return !!this.state.ttl;
	};

	private execute = async () => {
		if (!this.canCreate())
			return;

		if (this.state.token)
			return ModuleFE_Thunderstorm.copyToClipboard(this.state.token);

		try {
			const {token} = await ModuleFE_Account._v1.createToken({
				accountId: this.props.accountId,
				ttl: this.state.ttl,
				label: 'why-from-dialog'
			}).executeSync();
			await ModuleFE_Thunderstorm.copyToClipboard(token);
			this.setState({token: token});
		} catch (err: any) {
			this.logError(err);
			this.setState({error: err});
		}
	};

	// ######################## Render ########################

	private renderErrorGenerating = () => {
		return <LL_V_L className={'created-title'}>
			<ICONS.x.component/>
			<div className={'title'}>Couldn't Generate Token, Please Try Again.</div>
		</LL_V_L>;
	};

	protected renderHeader = () => {
		return <>
			<span className="header__title">Generated Token</span>
			<div className={'header__buttons'}>
				<ICONS.x.component className="exit_icon" onClick={this.closeDialog}/>
			</div>
		</>;
	};

	protected renderBody = () => {
		if (this.state.error)
			return this.renderErrorGenerating();

		if (this.state.token)
			return <LL_V_L className={'created-title'}>
				<ICONS.v.component/>
				<div className={'title'}>Token Generated Successfully</div>
			</LL_V_L>;

		return <>
			<TS_PropRenderer.Vertical label={'TTL (In years)'}>
				<TS_Input type={'number'} onKeyDown={(e) => {
					if (e.key === '.' || e.key === ',' || ((e.target as HTMLInputElement).value.length === 0 && e.key === '0')) {
						e.preventDefault();
					}
				}} onBlur={(val) => {
					this.setState({ttl: (Week * 52) * Number(val)});
				}}/>
			</TS_PropRenderer.Vertical>
		</>;
	};

	protected buttons = () => {
		return {
			center: [
				{
					content: this.state.token ? 'Copy To Clipboard' : 'Generate Token',
					renderer: TS_Dialog.busyButton,
					onClick: this.execute,
					className: 'desired-action-button',
					associatedKeys: ['Enter'],
				}
			]
		};
	};
}
