"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_TimeFormats = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../shared");
const consts_1 = require("@app/styles/frontend/ui/consts");
const mandatoryProps_TimeFormats = {
    adapter: (0, frontend_1.SimpleListAdapter)([...shared_1.TimeFormats], item => React.createElement(React.Fragment, null, item.item)),
    caret: consts_1.DropDown_DefaultCaret,
    placeholder: 'Select Time Format'
};
exports.DropDown_TimeFormats = frontend_1.TS_DropDown.prepare(mandatoryProps_TimeFormats);
