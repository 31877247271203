"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_EHRInstance = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
const Validator_ModifiableProps = {
    //Mandatory
    label: (0, ts_common_1.tsValidateString)(),
    type: (0, ts_common_1.tsValidateValue)([...types_1.EHRInstanceTypes]),
    organizationId: ts_common_1.tsValidateUniqueId,
    //Required
    backendAppId: (0, ts_common_1.tsValidateString)(-1, false),
    webAppId: (0, ts_common_1.tsValidateString)(-1, false),
    whitelistUrls: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false),
    tenantId: (0, ts_common_1.tsValidateString)(),
    sofParams: (0, ts_common_1.tsValidateArray)({
        key: (0, ts_common_1.tsValidateString)([1, -1]),
        value: (0, ts_common_1.tsValidateString)([1, -1]),
    }, false),
    pathwayIds: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false),
};
const Validator_GeneratedProps = {};
exports.DBDef_EHRInstance = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1', '1.0.0'],
    dbKey: 'ehr-instances',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'ehr-instance'
    },
    backend: {
        name: 'ehr-instances'
    },
    entityName: 'EHRInstance',
    dependencies: {
        pathwayIds: {
            dbKey: 'pathway',
            fieldType: 'string[]',
        },
        organizationId: {
            dbKey: 'organizations',
            fieldType: 'string',
        }
    }
};
