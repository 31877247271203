"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_OrganizationEditor = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@nu-art/permissions/frontend");
const React = require("react");
const frontend_3 = require("@nu-art/ts-workspace/frontend");
const permission_keys_1 = require("../../permission-keys");
const workspace_config_1 = require("./workspace-config");
const frontend_4 = require("@app/styles/frontend");
const frontend_5 = require("@app/hcs/frontend");
const Editor_Organization_1 = require("./presenter/Editor_Organization");
const ts_common_1 = require("@nu-art/ts-common");
require("./Page_OrganizationEditor.scss");
const _entity_1 = require("../../_entity");
const module_pack_1 = require("../../module-pack");
const frontend_6 = require("@app/pathway/_entity/action/frontend");
const frontend_7 = require("@app/pathway/_entity/pathway/frontend");
const EditorKey_Organization = 'organization-editor';
const modulesThisPageUses = [
    ...module_pack_1.ModulePackFE_Organization,
    frontend_6.ModuleFE_Action,
    frontend_5.ModuleFE_Variable,
    frontend_7.ModuleFE_Pathway,
];
class Page_OrganizationEditor extends frontend_4.Page_BaseManagerUI_SingleType_Proto {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.workspace = {
            id: EditorKey_Organization,
            key: workspace_config_1.workspaceKey_OrganizationEditor,
            renderers: {
                horizontalSpace: frontend_3.TS_HorizontalWorkspace,
            },
            instances: {
                selector: this.renderSelector.bind(this),
                presenter: this.renderPresenter.bind(this),
            }
        };
        // ######################## Logic ########################
        this.onCreateNewOrganization = () => {
            this.clearSelectedResult();
            const newOrg = this.getEditableItem();
            newOrg.set('address', {});
            this.setState({
                selectedItem: newOrg,
                selectedResult: undefined
            });
        };
        this.deleteOrganization = () => {
            const org = this.state.selectedItem;
            if (!org)
                throw new ts_common_1.MUSTNeverHappenException('Triggered deleting org without selected org');
            frontend_4.Dialog_Confirmation.show({
                header: 'Are you sure?',
                content: React.createElement(React.Fragment, null,
                    React.createElement("div", null, "You are about to delete an organization"),
                    React.createElement("div", null, org.item.name)),
                buttons: [
                    {
                        label: 'Yes, Delete', isAsync: true, action: async () => {
                            await org.delete();
                            this.deselectOrganization();
                        }
                    },
                    { label: 'Don\'t Delete', isPreferred: true, action: ts_common_1.voidFunction }
                ]
            });
        };
        this.deselectOrganization = () => {
            this.clearSelectedResult();
            this.setState({
                selectedItem: undefined,
                selectedResult: undefined
            });
        };
    }
    // ######################## Abstract Implementations ########################
    getInitialObject() {
        return {};
    }
    // ######################## Render ########################
    renderSelector() {
        return React.createElement(frontend_4.SearchSelector_BaseManagerUI, { selectedItem: this.state.selectedResult, onSearchItemSelected: (item) => this.onSearchResultSelected(item.id, item.type), searchItems: [Page_OrganizationEditor.SearchableItem_OrganizationV2], buttons: [{
                    label: 'Add Organizations',
                    action: this.onCreateNewOrganization
                }] });
    }
    renderPresenter() {
        if (!this.state.selectedItem)
            return React.createElement(frontend_5.QPageMessage, { message: 'Organization Manager' });
        return React.createElement(Editor_Organization_1.Editor_Organization, { editable: this.state.selectedItem, deleteOrganization: this.deleteOrganization, deselectOrganization: this.deselectOrganization });
    }
}
_a = Page_OrganizationEditor;
Page_OrganizationEditor.Route = {
    key: EditorKey_Organization,
    path: 'organization-v2',
    Component: _a,
    enabled: (0, frontend_2.RoutePermissions)(permission_keys_1.PermissionKeyFE_OrganizationV2Viewer),
    modulesToAwait: modulesThisPageUses,
};
Page_OrganizationEditor.searchItemKey_Organization = _entity_1.ModuleFE_Organization.dbDef.dbKey;
Page_OrganizationEditor.SearchableItem_Organization = {
    module: _entity_1.ModuleFE_Organization,
    type: Page_OrganizationEditor.searchItemKey_Organization,
    route: Page_OrganizationEditor.Route,
    entityName: _entity_1.ModuleFE_Organization.dbDef.entityName,
    entityLabel: 'Organization',
    groupId: ['Organizations'],
    tags: [],
    mapper: (item) => [item.name],
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_4.Renderer_SearchResult, { itemId: item._id, itemKey: Page_OrganizationEditor.searchItemKey_Organization, itemLabel: item.name, entityLabel: 'Organization', hierarchy: 'Organizations', selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (item) => {
        const organization = _entity_1.ModuleFE_Organization.cache.unique(item);
        return React.createElement(Editor_Organization_1.Editor_Organization, { editable: new frontend_1.EditableDBItemV3(organization, _entity_1.ModuleFE_Organization).setAutoSave(true), deselectOrganization: ts_common_1.voidFunction, deleteOrganization: ts_common_1.voidFunction });
    }
};
Page_OrganizationEditor.SearchableItem_OrganizationV2 = Object.assign(Object.assign({}, Page_OrganizationEditor.SearchableItem_Organization), { renderer: (item, selected, onClickCallback) => {
        var _b, _c;
        const className = (0, frontend_1._className)('search-result', 'organization__search-item', selected && 'selected');
        const addressString = `${item.address.city}${item.address.state ? `, ${(_b = item.address.state.initials) !== null && _b !== void 0 ? _b : item.address.state.label}` : ''}`;
        return React.createElement(frontend_1.LL_V_L, { className: className, onClick: e => {
                (0, frontend_1.stopPropagation)(e);
                onClickCallback === null || onClickCallback === void 0 ? void 0 : onClickCallback();
                frontend_4.dispatch_searchResultSelected.dispatchUI(item._id, Page_OrganizationEditor.searchItemKey_Organization);
            } },
            ((_c = item.alias) === null || _c === void 0 ? void 0 : _c.length) ? React.createElement("div", null, item.alias) : null,
            React.createElement("div", null, item.name),
            React.createElement("div", null, addressString));
    } });
Page_OrganizationEditor.defaultProps = {
    searchItems: [Page_OrganizationEditor.SearchableItem_OrganizationV2]
};
exports.Page_OrganizationEditor = Page_OrganizationEditor;
