"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Book_1 = void 0;
exports.Book_1 = {
    name: 'Harrison’s Principles of Internal Medicine',
    edition: '21st',
    year: 2022,
    isbn: '9781264268511',
    itemsType: 'part',
    items: [
        {
            name: '1 - The Profession of Medicine', itemsType: 'chapter', items: [
                { name: '1 - The Practice of Medicine' },
                { name: '2 - Promoting Good Health' },
                { name: '3 - Vaccine Opposition and Hesitancy' },
                { name: '4 - Decision-Making in Clinical Medicine' },
                { name: '5 - Precision Medicine and Clinical Care' },
                { name: '6 - Screening and Prevention of Disease' },
                { name: '7 - Global Diversity of Health System Financing and Delivery' },
                { name: '8 - The Safety and Quality of Health Care' },
                { name: '9 - Diagnosis: Reducing Errors and Improving Quality' },
                { name: '10 - Racial and Ethnic Disparities in Health Care' },
                { name: '11 - Ethical Issues in Clinical Medicine' },
                { name: '12 - Palliative and End-of-Life Care' }
            ]
        },
        {
            name: '2 - Cardinal Manifestations and Presentation of Diseases', itemsType: 'section', items: [
                {
                    name: '1 - Pain', itemsType: 'chapter', items: [
                        { name: '13 - Pain: Pathophysiology and Management' },
                        { name: '14 - Chest Discomfort' },
                        { name: '15 - Abdominal Pain' },
                        { name: '16 - Headache' },
                        { name: '17 - Back and Neck Pain' },
                    ]
                },
                {
                    name: '2 - Alterations in Body Temperature', itemsType: 'chapter', items: [
                        { name: '18 - Fever' },
                        { name: '19 - Fever and Rash' },
                        { name: '20 - Fever of Unknown Origin' },
                    ]
                },
                {
                    name: '3 - Nervous System Dysfunction', itemsType: 'chapter', items: [
                        { name: '21 - Syncope' },
                        { name: '22 - Dizziness and Vertigo' },
                        { name: '24 - Neurologic Causes of Weakness and Paralysis' },
                        { name: '25 - Numbness, Tingling, and Sensory Loss' },
                        { name: '23 - Fatigue' },
                        { name: '24 - Neurologic Causes of Weakness and Paralysis' },
                        { name: '25 - Numbness, Tingling, and Sensory Loss' },
                        { name: '26 - Gait Disorders, Imbalance, and Falls' },
                        { name: '27 - Confusion and Delirium' },
                        { name: '28 - Coma' },
                        { name: '29 - Dementia' },
                        { name: '30 - Aphasia, Memory Loss, and Other Cognitive Disorders' },
                        { name: '31 - Sleep Disorders' },
                    ]
                },
                {
                    name: '4 - Disorders of Eyes, Ears, Nose, and Throat', itemsType: 'chapter', items: [
                        { name: '32 - Disorders of the Eye' },
                        { name: '33 - Disorders of Smell and Taste ' },
                        { name: '34 - Disorders of Hearing' },
                        { name: '35 - Upper Respiratory Symptoms, Including Earache, Sinus Symptor' },
                        { name: '36 - Oral Manifestations of Disease' },
                    ]
                },
                {
                    name: '5 - Alterations in Circulatory and Respiratory Functions', itemsType: 'chapter', items: [
                        { name: '37 - Dyspnea' },
                        { name: '38 - Cough' },
                        { name: '39 - Hemoptysis' },
                        { name: '40 - Hypoxia and Cyanosis' },
                        { name: '41 - Edema' },
                        { name: '42 - Approach to the Patient with a Heart Murmur' },
                        { name: '43 - Palpitations' },
                    ]
                },
                {
                    name: '6 - Alterations in Gastrointestinal Function', itemsType: 'chapter', items: [
                        { name: '44 - Dysphagia' },
                        { name: '45 - Nausea, Vomiting, and Indigestion' },
                        { name: '46 - Diarrhea and Constipation' },
                        { name: '47 - Unintentional Weight Loss' },
                        { name: '48 - Gastrointestinal Bleeding' },
                        { name: '49 - Jaundice' },
                        { name: '50 - Abdominal Swelling and Ascites' },
                    ]
                },
                {
                    name: '7 - Alterations in Renal and Urinary Tract Function', itemsType: 'chapter', items: [
                        { name: '51 - Interstitial Cystitis/Bladder Pain Syndrome' },
                        { name: '52 - Azotemia and Urinary Abnormalities' },
                        { name: '53 - Fluid and Electrolyte Disturbances' },
                        { name: '54 - Hypercalcemia and Hypocalcemia' },
                        { name: '55 - Acidosis and Alkalosis' },
                    ]
                },
                {
                    name: '8 - Alterations in the Skin', itemsType: 'chapter', items: [
                        { name: '56 - Approach to the Patient with a Skin Disorder' },
                        { name: '57 - Eczema, Psoriasis, Cutaneous Infections, Acne, and Other Comm' },
                        { name: '58 - Skin Manifestations of Internal Disease' },
                        { name: '59 - Immunologically Mediated Skin Diseases' },
                        { name: '60 - Cutaneous Drug Reactions.' },
                        { name: '61 - Photosensitivity and Other Reactions to Sunlight' },
                    ]
                },
                {
                    name: '9 - Hematologic Alterations 62 Interpreting Peripheral Blood Smears', itemsType: 'chapter', items: [
                        { name: '62 - Interpreting Peripheral Blood Smears' },
                        { name: '63 - Anemia and Polycythemia' },
                        { name: '64 - Disorders of Granulocytes and Monocytes' },
                        { name: '65 - Bleeding and Thrombosis' },
                        { name: '66 - Enlargement of Lymph Nodes and Spleen' },
                    ]
                },
            ]
        },
        {
            name: '3 - Pharmacology', itemsType: 'chapter', items: [
                { name: '67 - Principles of Clinical Pharmacology' },
                { name: '68 - Pharmacogenomics' },
            ]
        },
        {
            name: '4 - Oncology and Hematology', itemsType: 'section', items: [
                {
                    name: '1 - Neoplastic Disorders', itemsType: 'chapter', items: [
                        { name: '69 - Approach to the Patient with Cancer' },
                        { name: '70 - Prevention and Early Detection of Cancer' },
                        { name: '71 - Cancer Genetics' },
                        { name: '72 - Cancer Cell Biology' },
                        { name: '73 - Principles of Cancer Treatment' },
                        { name: '74 - Infections in Patients with Cancer' },
                        { name: '75 - Oncologic Emergencies.' },
                        { name: '76 - Cancer of the Skin' },
                        { name: '77 - Head and Neck Cancer' },
                        { name: '78 - Neoplasms of the Lung' },
                        { name: '79 - Breast Cancer.' },
                        { name: '80 - Upper Gastrointestinal Tract Cancers' },
                        { name: '81 - Lower Gastrointestinal Cancers' },
                        { name: '82 - Tumors of the Liver and Biliary Tree' },
                        { name: '83 - Pancreatic Cancer' },
                        { name: '84 - Gastrointestinal Neuroendocrine Tumors' },
                        { name: '85 - Renal Cell Carcinoma' },
                        { name: '86 - Cancer of the Bladder and Urinary Tract' },
                        { name: '87 - Benign and Malignant Diseases of the Prostate' },
                        { name: '88 - Testicular Cancer' },
                        { name: '89 - Gynecologic Malignancies' },
                        { name: '90 - Primary and Metastatic Tumors of the Nervous System' },
                        { name: '91 - Soft Tissue and Bone Sarcomas and Bone Metastases' },
                        { name: '92 - Carcinoma of Unknown Primary' },
                        { name: '93 - Paraneoplastic Syndromes: Endocrinologic/Hematologic' },
                        { name: '94 - Paraneoplastic Neurologic Syndromes and Autoimmune Encephalitis' },
                        { name: '95 - Cancer Survivorship and the Long-Term Impact of Cancer and Its Treatment' },
                    ]
                },
                {
                    name: '2 - Hematopoietic Disorders', itemsType: 'chapter', items: [
                        { name: '96 - Hematopoietic Stem Cells' },
                        { name: '97 - Iron Deficiency and Other Hypoproliferative Anemias' },
                        { name: '98 - Disorders of Hemoglobin' },
                        { name: '99 - Megaloblastic Anemias' },
                        { name: '100 - Hemolytie Anemias' },
                        { name: '101 - Anemia Due to Acute Blood Loss' },
                        { name: '102 - Bone Marrow Failure Syndromes Including Aplastic Anemia and Myelodysplasia' },
                        { name: '103 - Polycythemia Vera and Other Myeloproliferative Neoplasms' },
                        { name: '104 - Acute Myeloid Leukemia' },
                        { name: '105 - Chronic Myeloid Leukemia' },
                        { name: '106 - Acute Lymphoid Leukemia' },
                        { name: '107 - Chronic Lymphocytic Leukemia' },
                        { name: '108 - Non-Hodgkin\'s Lymphoma' },
                        { name: '109 - Hodgkin\'s Lymphoma' },
                        { name: '110 - Less Common Lymphoid and Myeloid Malignancies' },
                        { name: '111 - Plasma Cell Disorders' },
                        { name: '112 - Amyloidosis' },
                        { name: '113 - Transfusion Therapy and Biology' },
                        { name: '114 - Hematopoietic Cell Transplantation' },
                    ]
                },
                {
                    name: '3 - Disorders of Hemostasis', itemsType: 'chapter', items: [
                        { name: '115 - Disorders of Platelets and Vessel Wall' },
                        { name: '116 - Coagulation Disorders' },
                        { name: '117 - Arterial and Venous Thrombosis' },
                        { name: '118 - Antiplatelet, Anticoagulant, and Fibrinolytic Drugs' },
                    ]
                },
            ]
        },
        {
            name: '5 - Infectious Diseases', itemsType: 'section', items: [
                {
                    name: '1 Basic Considerations in Infectious Diseases', itemsType: 'chapter', items: [
                        { name: '119 - Approach to the Patient with an Infectious Disease' },
                        { name: '120 - Molecular Mechanisms of Microbial Pathogenesis' },
                        { name: '121 - Microbial Genomics and Infectious Disease' },
                        { name: '121 - Microbial Genomics and Infectious Disease Patient' },
                        { name: '122 - Approach to the Acutely III Infected Febrile' },
                        { name: '123 - Immunization Principles and Vaccine Use' },
                        { name: '124 - Health Recommendations for International Travel' },
                        { name: '125 - Climate Change and Infectious Disease' },
                    ]
                },
                {
                    name: '2 Clinical Syndromes: Community-Acquired Infections', itemsType: 'chapter', items: [
                        { name: '126 - Pneumonia' },
                        { name: '127 - Lung Abscess' },
                        { name: '128 - Infective Endocarditis' },
                        { name: '129 - Infections of the Skin, Muscles, and Soft Tissues' },
                        { name: '130 - Infectious Arthritis' },
                        { name: '131 - Osteomyelitis' },
                        { name: '132 - Intraabdominal Infections and Abscesses' },
                        { name: '133 - Acute Infectious Diarrheal Diseases and Bacterial Food Poisoning' },
                        { name: '134 - Clostridioides difficile Infection, Including Pseudomembranous Colitis' },
                        { name: '135 - Urinary Tract Infections, Pyelonephritis, and Prostatitis' },
                        { name: '136 - Sexually Transmitted Infections: Overview and Clinical Approach' },
                        { name: '137 - Encephalitis' },
                        { name: '138 - Acute Meningitis' },
                        { name: '139 - Chronic and Recurrent Meningitis:' },
                        { name: '140 - Brain Abscess and Empyema' },
                        { name: '141 - Infectious Complications of Bites' },
                    ]
                },
                {
                    name: '3 Clinical Syndromes: Health Care-Associated Infections', itemsType: 'chapter', items: [
                        { name: '142 - Infections Acquired in Health Care Facilities' },
                        { name: '143 - Infections in Transplant Recipients' },
                    ]
                },
                {
                    name: '4 Therapy for Bacterial Diseases', itemsType: 'chapter', items: [
                        { name: '144 - Treatment and Prophylaxis of Bacterial Infections' },
                        { name: '145 - Bacterial Resistance to Antimicrobial Agents' },
                    ]
                },
                {
                    name: '5 Diseases Caused by Gram-Positive Bacteria', itemsType: 'chapter', items: [
                        { name: '146 - Pneumococcal Infections' },
                        { name: '147 - Staphylococcal Infections' },
                        { name: '148 - Streptococcal Infections' },
                        { name: '149 - Enterococcal Infections' },
                        { name: '150 - Diphtheria and Other Corynebacterial Infections' },
                        { name: '151 - Listeria monocytogenes Infections' },
                        { name: '152 - Tetanus' },
                        { name: '153 - Botulism' },
                        { name: '154 - Car Gangeons and Other Clostridial Infections' },
                    ]
                },
                {
                    name: '6 Diseases Caused by Gram-Negative Bacteria', itemsType: 'chapter', items: [
                        { name: '155 - Meningococcal Infections' },
                        { name: '156 - Gonococcal Infections' },
                        { name: '157 - Haemophilus and Moraxella Infections' },
                        { name: '158 - Infections Due to the HACEK Group and Miscellaneous Gram-Negative Bacter' },
                        { name: '159 - Legionella Infections' },
                        { name: '160 - Pertussis and Other Bordetella Infections' },
                        { name: '161 - Diseases Caused by Gram-Negative Enteric Bacilli' },
                        { name: '162 - Acinetobacter Infections' },
                        { name: '163 - Helicobacter pylori Infections' },
                        { name: '164 - Infections Due to Pseudomonas, Burkholderia, and Stenotrophomonas Specie' },
                        { name: '165 - Salmonellosis' },
                        { name: '166 - Shigellosis' },
                        { name: '167 - Infections Due to Campylobacter and Related Organisms' },
                        { name: '168 - Cholera and Other Vibrioses' },
                        { name: '169 - Brucellosis' },
                        { name: '170 - Tularemia' },
                        { name: '171 - Plague and Other Yersinia Infections' },
                        { name: '172 - Bartonella Infections, Including Cat-Scratch Disease' },
                        { name: '173 - Donovanosis' },
                    ]
                },
                {
                    name: '7 Miscellaneous Bacterial Infections', itemsType: 'chapter', items: [
                        { name: '174 - Nocardiosis' },
                        { name: '175 - Actinomycosis' },
                        { name: '176 - Whipple\'s Disease ' },
                        { name: '177 - Infections Due to Mixed Anaerobic Organisms' },
                    ]
                },
                {
                    name: '8 Mycobacterial Diseases', itemsType: 'chapter', items: [
                        { name: '178 - Tuberculosis' },
                        { name: '179 - Leprosy' },
                        { name: '180 - Nontuberculous Mycobacterial Infections' },
                        { name: '181 - Antimycobacterial Agents' },
                    ]
                },
                {
                    name: '9 Spirochetal Diseases', itemsType: 'chapter', items: [
                        { name: '182 - Syphilis' },
                        { name: '183 - Endemic Treponematoses' },
                        { name: '184 - Leptospirosis' },
                        { name: '185 - Relapsing Fever and Borrelia miyamotoi Disease' },
                        { name: '186 - Lyme Borreliosis' },
                    ]
                },
                {
                    name: '10 Diseases Caused by Rickettsiae, Mycoplasmas, and Chlamydiae', itemsType: 'chapter', items: [
                        { name: '187 - Rickettsial Diseases' },
                        { name: '188 - Infections Due to Mycoplasmas' },
                        { name: '189 - Chlamydial Infections' },
                    ]
                },
                {
                    name: '11 Viral Diseases: General Considerations', itemsType: 'chapter', items: [
                        { name: '190 - Principles of Medical Virology' },
                        { name: '191 - Antiviral Chemotherapy, Excluding Antiretroviral Drugs' },
                    ]
                },
                {
                    name: '12 Infections Due to DNA Viruses', itemsType: 'chapter', items: [
                        { name: '192 - Herpes Simplex Virus Infections' },
                        { name: '193 - Varicella-Zoster Virus Infections' },
                        { name: '194 - Epstein-Barr Virus Infections, Including Infectious Mononucleosis' },
                        { name: '195 - Cytomegalovirus and Human Herpesvirus Types 6, 7, and 8' },
                        { name: '196 - Molluscum Contagiosum, Monkeypox, and Other Poxvirus Infections' },
                        { name: '197 - Parvovirus Infections' },
                        { name: '198 - Human Papillomavirus Infections' },
                    ]
                },
                {
                    name: '13 Infections Due to DNA and RNA Respiratory Viruses', itemsType: 'chapter', items: [
                        { name: '199 - Common Viral Respiratory Infections, Including COVID-19' },
                        { name: '200 - Influenza' },
                    ]
                },
                {
                    name: '14 Infections Due to Human Immunodeficiency Virus and Other Human Ret', itemsType: 'chapter', items: [
                        { name: '201 - The Human Retroviruses' },
                        { name: '202 - Human Immunodeficiency Virus Disease: AIDS and Related Disorders' },
                    ]
                },
                {
                    name: '15 Infections Due to RNA Viruses', itemsType: 'chapter', items: [
                        { name: '203 - Viral Gastroenteritis' },
                        { name: '204 - Enterovirus, Parechovirus, and Reovirus Infections' },
                        { name: '205 - Measles (Rubeola)' },
                        { name: '206 - Rubella (German Measles)' },
                        { name: '207 - Mumps' },
                        { name: '208 - Rabies and Other Rhabdovirus Infections' },
                        { name: '209 - Arthropod-Borne and Rodent-Borne Virus Infections' },
                        { name: '210 - Ebolavirus and Marburgvirus Infections' },
                    ]
                },
                {
                    name: '16 Fungal Infections', itemsType: 'chapter', items: [
                        { name: '211 - Pathogenesis, Diagnosis, and Treatment of Fungal Infections.' },
                        { name: '212 - Histoplasmosis' },
                        { name: '213 - Coccidioidomycosis' },
                        { name: '214 - Blastomycosis' },
                        { name: '215 - Cryptococcosis 216 Candidiasis' },
                        { name: '217 - Aspergillosis' },
                        { name: '218 - Mucormycosis' },
                        { name: '219 - Less Common Systemic Mycoses and Superficial Mycoses' },
                        { name: '220 - Pneumocystis Infections' },
                    ]
                },
                {
                    name: '17 Protozoal and Helminthic Infections: General Considerations', itemsType: 'chapter', items: [
                        { name: '221 - Introduction to Parasitic Infections.' },
                        { name: '222 - Agents Used to Treat Parasitic Infections ' },
                    ]
                },
                {
                    name: '18 Protozoal Infections', itemsType: 'chapter', items: [
                        { name: '223 - Amebiasis and Infection with Free-Living Amebae' },
                        { name: '224 - Malaria' },
                        { name: '225 - Babesiosis' },
                        { name: '226 - Leishmaniasis' },
                        { name: '227 - Chagas Disease and African Trypanosomiasis' },
                        { name: '228 - Toxoplasma Infections' },
                        { name: '229 - Protozoal Intestinal Infections and Trichomoniasis' },
                    ]
                },
                {
                    name: '19 Helminthic Infections', itemsType: 'chapter', items: [
                        { name: '230 - Introduction to Helminthic Infections' },
                        { name: '231 - Trichinellosis and Other Tissue Nematode Infections.' },
                        { name: '232 - Intestinal Nematode Infections' },
                        { name: '233 - Filarial and Related Infections' },
                        { name: '234 - Schistosomiasis and Other Trematode Infections' },
                        { name: '235 - Cestode Infections' },
                    ]
                },
            ]
        },
        {
            name: '6 - Disorders of the Cardiovascular System', itemsType: 'section', items: [
                {
                    name: '1 Introduction to Cardiovascular Disorders', itemsType: 'chapter', items: [
                        { name: '236 - Approach to the Patient with Possible Cardiovascular Disease' },
                        { name: '237 - Basic Biology of the Cardiovascular System' },
                        { name: '238 - Epidemiology of Cardiovascular Disease' },
                    ]
                },
                {
                    name: '2 Diagnosis of Cardiovascular Disorders', itemsType: 'chapter', items: [
                        { name: '239 - Physical Examination of the Cardiovascular System' },
                        { name: '240 - Electrocardiography' },
                        { name: '241 - Noninvasive Cardiac Imaging: Echocardiography, Nuclear Cardiology, and Ma' },
                        { name: '242 - Diagnostic Cardiac Catheterization and Coronary Angiography ' },
                    ]
                },
                {
                    name: '3 Disorders of Rhythm', itemsType: 'chapter', items: [
                        { name: '243 - Principles of Clinical Cardiac Electrophysiology' },
                        { name: '244 - The Bradyarrhythmias: Disorders of the Sinoatrial Node ' },
                        { name: '245 - The Bradyarrhythmias: Disorders of the Atrioventricular Node' },
                        { name: '246 - Approach to Supraventricular Tachyarrhythmias ' },
                        { name: '247 - Physiologic and Nonphysiologic Sinus Tachycardia' },
                        { name: '248 - Focal Atrial Tachycardia' },
                        { name: '249 - Paroxysmal Supraventricular Tachycardias' },
                        { name: '250 - Common Atrial Flutter and Macroreentrant and Multifocal Atrial Tachycarchas' },
                        { name: '251 - Atrial Fibrillation' },
                        { name: '252 - Approach to Ventricular Arrhythmias ' },
                        { name: '253 - Premature Ventricular Contractions, Nonsustained Ventricular Tachycardia' },
                        { name: '254 - Sustained Ventricular Tachycardia' },
                        { name: '255 - Polymorphic Ventricular Tachycardia and Ventricular Fibrillation' },
                        { name: '256 - Electrical Storm and Incessant Ventricular Tachycardia' },
                    ]
                },
                {
                    name: '4 Disorders of the Heart, Muscles, Valves, and Pericardium', itemsType: 'chapter', items: [
                        { name: '257 - Heart Failure: Pathophysiology and Diagnosis' },
                        { name: '258 - Heart Failure: Management' },
                        { name: '259 - Cardiomyopathy and Myocarditis ' },
                        { name: '260 - Cardiac Transplantation and Prolonged Assisted Circulation' },
                        { name: '261 - Aortic Stenosis' },
                        { name: '262 - Aortic Regurgitation' },
                        { name: '263 - Mitral Stenosis' },
                        { name: '264 - Mitral Regurgitation' },
                        { name: '265 - Mitral Valve Prolapse' },
                        { name: '266 - Tricuspid Valve Disease' },
                        { name: '267 - Pulmonic Valve Disease' },
                        { name: '268 - Multiple and Mixed Valvular Heart Disease' },
                        { name: '269 - Congenital Heart Disease in the Adult' },
                        { name: '270 - Pericardial Disease' },
                        { name: '271 - Atrial Myxoma and Other Cardiac Tumors' },
                        { name: '272 - Cardiac Trauma' },
                    ]
                },
                {
                    name: '5 Coronary and Peripheral Vascular Disease', itemsType: 'chapter', items: [
                        { name: '273 - Ischemic Heart Disease' },
                        { name: '274 - Non-ST-Segment Elevation Acute Coronary Syndrome (Non-ST-Segment ' },
                        { name: '275 - ST-Segment Elevation Myocardial Infarction' },
                        { name: '276 - Percutaneous Coronary Interventions and Other Interventional Procedure:' },
                        { name: '277 - Hypertension' },
                        { name: '278 - Renovascular Disease' },
                        { name: '279 - Deep-Venous Thrombosis and Pulmonary Thromboembolism' },
                        { name: '280 - Diseases of the Aorta' },
                        { name: '281 - Arterial Diseases of the Extremities' },
                        { name: '282 - Chronic Venous Disease and Lymphedema' },
                        { name: '283 - Pulmonary Hypertension.' },
                    ]
                },
            ]
        },
        {
            name: '7 - Disorders of the Respiratory System', itemsType: 'section', items: [
                {
                    name: '1 - Diagnosis of Respiratory Disorders', itemsType: 'chapter', items: [
                        { name: '284 - Approach to the Patient with Disease of the Respiratory System' },
                        { name: '285 - Disturbances of Respiratory Function' },
                        { name: '286 - Diagnostic Procedures in Respiratory Disease' },
                    ]
                },
                {
                    name: '2 - Diseases of the Respiratory System', itemsType: 'chapter', items: [
                        { name: '287 - Asthma' },
                        { name: '288 - Hypersensitivity Pneumonitis and Pulmonary Infiltrates with Eosinophilia ' },
                        { name: '289 - Occupational and Environmental Lung Disease' },
                        { name: '290 - Bronchiectasis' },
                        { name: '291 - Cystic Fibrosis' },
                        { name: '292 - Chronic Obstructive Pulmonary Disease' },
                        { name: '293 - Interstitial Lung Disease' },
                        { name: '294 - Disorders of the Pleura' },
                        { name: '295 - Disorders of the Mediastinum' },
                        { name: '296 - Disorders of Ventilation' },
                        { name: '297 - Sleep Apnea' },
                        { name: '298 - Lung Transplantation' },
                        { name: '299 - Interventional Pulmonary Medicine' },
                    ]
                },
            ]
        },
        {
            name: '8 - Critical Care Medicine', itemsType: 'section', items: [
                {
                    name: '1 - Respiratory Critical Care', itemsType: 'chapter', items: [
                        { name: '300 - Approach to the Patient with Critical Illness' },
                        { name: '301 - Acute Respiratory Distress Syndrome' },
                        { name: '302 - Mechanical Ventilatory Support' },
                    ]
                },
                {
                    name: '2 - Shock and Cardiac Arrest', itemsType: 'chapter', items: [
                        { name: '303 - Approach to the Patient with Shock' },
                        { name: '304 - Sepsis and Septic Shock' },
                        { name: '305 - Cardiogenic Shock and Pulmonary Edema' },
                        { name: '306 - Cardiovascular Collapse, Cardiac Arrest, and Sudden Cardiac Death' },
                    ]
                },
                {
                    name: '3 - Neurologic Critical Care', itemsType: 'chapter', items: [
                        { name: '307 - Nervous System Disorders in Critical Care' },
                    ]
                },
            ]
        },
        {
            name: '9 - Disorders of the Kidney and Urinary Tract', itemsType: 'chapter', items: [
                { name: '308 - Approach to the Patient with Renal Disease or Urinary Tract Disease ' },
                { name: '309 - Cell Biology and Physiology of the Kidney' },
                { name: '310 - Acute Kidney Injury' },
                { name: '311 - Chronic Kidney Disease' },
                { name: '312 - Dialysis in the Treatment of Kidney Failure ' },
                { name: '313 - Transplantation in the Treatment of Renal Failure' },
                { name: '314 - Glomerular Diseases' },
                { name: '315 - Polycystic Kidney Disease and Other Inherited Disorders of Tubule Growth an' },
                { name: '316 - Tubulointerstitial Diseases of the Kidney' },
                { name: '317 - Vascular Injury to the Kidney' },
                { name: '318 - Nephrolithiasis' },
                { name: '319 - Urinary Tract Obstruction' },
                { name: '320 - Interventional Nephrology' },
            ]
        },
        {
            name: '10 - Disorders of the Gastrointestinal System', itemsType: 'section', items: [
                {
                    name: '1 - Disorders of the Alimentary Tract', itemsType: 'chapter', items: [
                        { name: '321 - Approach to the Patient with Gastrointestinal Disease' },
                        { name: '322 - Gastrointestinal Endoscopy' },
                        { name: '323 - Diseases of the Esophagus' },
                        { name: '324 - Peptic Ulcer Disease and Related Disorders' },
                        { name: '325 - Disorders of Absorption' },
                        { name: '326 - Inflammatory Bowel Disease' },
                        { name: '327 - Irritable Bowel Syndrome' },
                        { name: '328 - Diverticular Disease and Common Anorectal Disorders' },
                        { name: '329 - Mesenteric Vascular Insufficiency' },
                        { name: '330 - Acute Intestinal Obstruction' },
                        { name: '331 - Acute Appendicitis and Peritonitis' },
                    ]
                },
                {
                    name: '2 - Nutrition', itemsType: 'chapter', items: [
                        { name: '332 - Nutrient Requirements and Dietary Assessment' },
                        { name: '333 - Vitamin and Trace Mineral Deficiency and Excess' },
                        { name: '334 - Malnutrition and Nutritional Assessment' },
                        { name: '335 - Enteral and Parenteral Nutrition' },
                    ]
                },
                {
                    name: '3 - Liver and Biliary Tract Disease', itemsType: 'chapter', items: [
                        { name: '336 - Approach to the Patient with Liver Disease' },
                        { name: '337 - Evaluation of Liver Function.' },
                        { name: '338 - The Hyperbilirubinemias' },
                        { name: '339 - Acute Viral Hepatitis' },
                        { name: '340 - Toxic and Drug-Induced Hepatitis' },
                        { name: '341 - Chronic Hepatitis' },
                        { name: '342 - Alcohol-Associated Liver Disease ' },
                        { name: '343 - Nonalcoholic Fatty Liver Diseases and Nonalcoholic Steatohepatitis' },
                        { name: '344 - Cirrhosis and Its Complications' },
                        { name: '345 - Liver Transplantation' },
                        { name: '346 - Diseases of the Gallbladder and Bile Ducts' },
                    ]
                },
                {
                    name: '4 - Disorders of the Pancreas', itemsType: 'chapter', items: [
                        { name: '347 - Approach to the Patient with Pancreatic Disease ' },
                        { name: '348 - Acute and Chronic Pancreatitis' },
                    ]
                },
            ]
        },
        {
            name: '11 - Immune-Mediated, Inflammatory, and Rheumatologic Disorders', itemsType: 'section', items: [
                {
                    name: '1 - The Immune System in Health and Disease', itemsType: 'chapter', items: [
                        { name: '349 - Introduction to the Immune System' },
                        { name: '350 - Mechanisms of Regulation and Dysregulation of the Immune System' },
                        { name: '351 - Primary Immune Deficiency Diseases' },
                    ]
                },
                {
                    name: '2 - Disorders of Immune-Mediated Injury', itemsType: 'chapter', items: [
                        { name: '352 - Urticaria, Angioedema, and Allergic Rhinitis' },
                        { name: '353 - Anaphylaxis' },
                        { name: '354 - Mastocytosis ' },
                        { name: '355 - Autoimmunity and Autoimmune Diseases' },
                        { name: '356 - Systemic Lupus Erythematosus' },
                        { name: '357 - Antiphospholipid Syndrome' },
                        { name: '358 - Rheumatoid Arthritis' },
                        { name: '359 - Acute Rheumatic.Fever' },
                        { name: '360 - Systemic Sclerosis (Scleroderma) and Related Disorders' },
                        { name: '361 - Sjögren\'s Syndrome' },
                        { name: '362 - Spondyloarthritis' },
                        { name: '363 - The Vasculitis Syndromes' },
                        { name: '364 - Behçet Syndrome' },
                        { name: '365 - Inflammatory Myopathies' },
                        { name: '366 - Relapsing Polychondritis' },
                        { name: '367 - Sarcoidosis' },
                        { name: '368 - IgG4-Related Disease' },
                        { name: '369 - Familial Mediterranean Fever and Other Hereditary Autoinflammatory Dis ' },
                    ]
                },
                {
                    name: '3 - Disorders of the Joints and Adjacent Tissues', itemsType: 'chapter', items: [
                        { name: '370 - Approach to Articular and Musculoskeletal Disorders' },
                        { name: '371 - Osteoarthritis ' },
                        { name: '372 - Gout and Other Crystal-Associated Arthropathies' },
                        { name: '373 - Fibromyalgia' },
                        { name: '374 - Arthritis Associated with Systemic Disease, and Other Arthritides ' },
                        { name: '375 - Periarticular Disorders of the Extremities' },
                    ]
                },
            ]
        },
        {
            name: '12 - Endocrinology and Metabolism', itemsType: 'section', items: [
                {
                    name: '1 - Endocrinology', itemsType: 'chapter', items: [
                        { name: '376 - Approach to the Patient with Endocrine Disorders' },
                        { name: '377 - Mechanisms of Hormone Action ' },
                        { name: '378 - Physiology of Anterior Pituitary Hormones' },
                        { name: '379 - Hypopituitarism' },
                        { name: '380 - Pituitary Tumor Syndromes' },
                        { name: '381 - Disorders of the Neurohypophysis' },
                        { name: '382 - Thyroid Gland Physiology and Testing' },
                        { name: '383 - Hypothyroidism' },
                        { name: '384 - Hyperthyroidism and Other Causes of Thyrotoxicosis' },
                        { name: '385 - Thyroid Nodular Disease and Thyroid Cancer ' },
                        { name: '386 - Disorders of the Adrenal Cortex' },
                        { name: '387 - Pheochromocytoma' },
                        { name: '388 - Multiple Endocrine Neoplasia Syndromes' },
                        { name: '389 - Autoimmune Polyendocrine Syndromes' },
                    ]
                },
                {
                    name: '2 - Sex- and Gender-Based Medicine', itemsType: 'chapter', items: [
                        { name: '390 - Sex Development 391 Disorders of the Testes and Male Reproductive System' },
                        { name: '392 - Disorders of the Female Reproductive System' },
                        { name: '393 - Menstrual Disorders and Pelvic Pain' },
                        { name: '394 - Hirsutism' },
                        { name: '395 - Menopause and Postmenopausal Hormone Therapy' },
                        { name: '396 - Infertility and Contraception' },
                        { name: '397 - Sexual Dysfunction' },
                        { name: '398 - Women\'s Health' },
                        { name: '399 - Men\'s Health' },
                        { name: '400 - Lesbian, Gay, Bisexual, and Transgender (LGBT) Health' },
                    ]
                },
                {
                    name: '3 - Obesity, Diabetes Mellitus, and Metabolic Syndrome', itemsType: 'chapter', items: [
                        { name: '401 - Pathobiology of Obesity' },
                        { name: '402 - Evaluation and Management of Obesity' },
                        { name: '403 - Diabetes Mellitus: Diagnosis, Classification, and Pathophysiology' },
                        { name: '404 - Diabetes Mellitus: Management and Therapies ' },
                        { name: '405 - Diabetes Mellitus: Complications' },
                        { name: '406 - Hypoglycemia' },
                        { name: '407 - Disorders of Lipoprotein Metabolism' },
                        { name: '408 - The Metabolic Syndrome' },
                    ]
                },
                {
                    name: '4 - Disorders of Bone and Mineral Metabolism', itemsType: 'chapter', items: [
                        { name: '409 - Bone and Mineral Metabolism in Health and Disease' },
                        { name: '410 - Disorders of the Parathyroid Gland and Calcium Homeostasis' },
                        { name: '411 - Osteoporosis' },
                        { name: '412 - Paget\'s Disease and Other Dysplasias of Bone' },
                    ]
                },
                {
                    name: '5 - Disorders of Intermediary Metabolism', itemsType: 'chapter', items: [
                        { name: '413 - Heritable Disorders of Connective Tissue' },
                        { name: '414 - Hemochromatosis.' },
                        { name: '415 - Wilson\'s Disease' },
                        { name: '416 - The Porphyrias' },
                        { name: '417 - Disorders of Purine and Pyrimidine Metabolism' },
                        { name: '418 - Lysosomal Storage Diseases' },
                        { name: '419 - Glycogen Storage Diseases and Other Inherited Disorders of Carbohydrat' },
                        { name: '420 - Inherited Disorders of Amino Acid Metabolism in Adults' },
                        { name: '421 - Inherited Defects of Membrane Transport' },
                    ]
                },
            ]
        },
        {
            name: '13 - Neurologic Disorders', itemsType: 'section', items: [
                {
                    name: '1 - Diagnosis of Neurologic Disorders', itemsType: 'chapter', items: [
                        { name: '422 - Approach to the Patient with Neurologic Disease' },
                        { name: '423 - Neuroimaging in Neurologic Disorders ' },
                        { name: '424 - Pathobiology of Neurologic Diseases' },
                    ]
                },
                {
                    name: '2 - Diseases of the Central Nervous System', itemsType: 'chapter', items: [
                        { name: '425 - Seizures and Epilepsy' },
                        { name: '426 - Introduction to Cerebrovascular Diseases' },
                        { name: '427 - Ischemic Stroke' },
                        { name: '428 - Intracranial Hemorrhage' },
                        { name: '429 - Subarachnoid Hemorrhage' },
                        { name: '430 - Migraine and Other Primary Headache Disorders' },
                        { name: '431 - Alzheimer\'s Disease' },
                        { name: '432 - Frontotemporal Dementia' },
                        { name: '433 - Vascular Dementia' },
                        { name: '434 - Dementia with Lewy Bodies' },
                        { name: '435 - Parkinson\'s Disease' },
                        { name: '436 - Tremor, Chorea, and Other Movement Disorders:' },
                        { name: '437 - Amyotrophic Lateral Sclerosis and Other Motor Neuron Diseases' },
                        { name: '438 - Prion Diseases' },
                        { name: '439 - Ataxic Disorders' },
                        { name: '440 - Disorders of the Autonomic Nervous System' },
                        { name: '441 - Trigeminal Neuralgia, Bell\'s Palsy, and Other Cranial Nerve Disorders' },
                        { name: '442 - Diseases of the Spinal Cord' },
                        { name: '443 - Concussion and Other Traumatic Brain Injuries' },
                        { name: '444 - Multiple Sclerosis' },
                        { name: '445 - Neuromyelitis Optica' },
                    ]
                },
                {
                    name: '3 - Nerve and Muscle Disorders', itemsType: 'chapter', items: [
                        { name: '446 - Peripheral Neuropathy' },
                        { name: '447 - Guillain-Barré Syndrome and Other Immune-Mediated Neuropathies' },
                        { name: '448 - Myasthenia Gravis and Other Diseases of the Neuromuscular Junction' },
                        { name: '449 - Muscular Dystrophies and Other Muscle Diseases ' },
                    ]
                },
                {
                    name: '4 - Myalgic Encephalomyelitis/Chronic Fatigue Syndrome', itemsType: 'chapter', items: [
                        { name: '450 - Myalgic Encephalomyelitis/Chronic Fatigue Syndrome\n' },
                    ]
                },
                {
                    name: '5 - Psychiatric and Addiction Disorders', itemsType: 'chapter', items: [
                        { name: '451 - Biology of Psychiatric Disorders' },
                        { name: '452 - Psychiatric Disorders' },
                        { name: '453 - Alcohol and Alcohol Use Disorders' },
                        { name: '454 - Nicotine Addiction' },
                        { name: '455 - Marijuana and Marijuana Use Disorders' },
                        { name: '456 - Opioid-Related Disorders' },
                        { name: '457 - Cocaine, Other Psychostimulants, and Hallucinogens' },
                    ]
                },
            ]
        },
        {
            name: '14 - Poisoning, Drug Overdose, and Envenomation', itemsType: 'chapter', items: [
                { name: '458 - Heavy Metal Poisoning' },
                { name: '459 - Poisoning and Drug Overdose' },
                { name: '460 - Disorders Caused by Venomous Snakebites and Marine Animal Exposures' },
                { name: '461 - Ectoparasite Infestations and Arthropod Injuries' },
            ]
        },
        {
            name: '15 - Disorders Associated with Environmental Exposures', itemsType: 'chapter', items: [
                { name: '462 - Altitude Illness' },
                { name: '463 - Hyperbaric and Diving Medicine' },
                { name: '464 - Hypothermia and Peripheral Cold Injuries' },
                { name: '465 - Heat-Related Illnesses' },
            ]
        },
        {
            name: '16 - Genes, the Environment, and Disease', itemsType: 'chapter', items: [
                { name: '466 - Principles of Human Genetics' },
                { name: '467 - The Practice of Genetics in Clinical Medicine' },
                { name: '468 - Mitochondrial DNA and Heritable Traits and Diseases' },
                { name: '469 - Telomere Disease' },
                { name: '470 - Gene- and Cell-Based Therapy in Clinical Medicine' },
                { name: '471 - The Human Microbiome' },
            ]
        },
        {
            name: '17 - Global Medicine', itemsType: 'chapter', items: [
                { name: '472 - Global Issues in Medicine.' },
                { name: '473 - Emerging and Reemerging Infectious Diseases' },
                { name: '474 - Primary Care and Global Health' },
                { name: '475 - Health Effects of Climate Change' },
            ]
        },
        {
            name: '18 - Aging', itemsType: 'chapter', items: [
                { name: '476 - Biology of Aging' },
                { name: '477 - Caring for the Geriatric Patient' },
            ]
        },
        {
            name: '19 - Consultative Medicine', itemsType: 'chapter', items: [
                { name: '478 - Approach to Medical Consultation' },
                { name: '479 - Medical Disorders During Pregnancy' },
                { name: '480 - Medical Evaluation of the Surgical Patient' },
            ]
        },
        {
            name: '20 - Frontiers', itemsType: 'chapter', items: [
                { name: '481 - Behavioral Economics and Health' },
                { name: '482 - Complementary and Integrative Therapies and Practices ' },
                { name: '483 - The Role of Epigenetics in Disease and Treatment' },
                { name: '484 - Applications of Stem Cell Biology in Clinical Medicine' },
                { name: '485 - The Role of Circadian Biology in Health and Disease' },
                { name: '486 - Network Medicine: Systems Biology in Health and Disease' },
                { name: '487 - Emerging Neurotherapeutic Technologies' },
                { name: '488 - Machine Learning and Augmented Intelligence in Clinical Medicine' },
                { name: '489 - Metabolomics' },
                { name: '490 - Circulating Nucleic Acids as Liquid Biopsies and Noninvasive Disease Biomark' },
                { name: '491 - Protein Folding Disorders' },
                { name: '492 - Novel Approaches to Diseases of Unknown Etiology' },
            ]
        },
    ]
};
