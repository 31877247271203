"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Theme = exports.AppTheme = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
exports.AppTheme = {
    theme: ['light', 'dark'],
    device: ['desktop', 'tablet', 'mobile']
};
class ModuleFE_Theme_Class extends frontend_1.ModuleFE_BaseTheme {
    constructor() {
        super(exports.AppTheme);
    }
}
exports.ModuleFE_Theme = new ModuleFE_Theme_Class();
