"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.variableMapper = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const _entity_1 = require("../../../_entity");
const variableMapper = (item) => {
    var _a;
    const nlp = _entity_1.ModuleFE_NLPData.cache.unique(item.nlpDataId);
    const attIds = ((_a = item.attributes) === null || _a === void 0 ? void 0 : _a.map(i => i.varId)) || [];
    const attributes = _entity_1.ModuleFE_Variable.cache.filter(i => attIds.includes(i._id));
    const parentValue = _entity_1.ModuleFE_Value.cache.unique(item.valueId);
    if (!parentValue)
        throw new ts_common_1.BadImplementationException('Variable with no value');
    let values = [];
    switch (parentValue.type) {
        case _entity_1.valueType_Enumerated:
            values = parentValue.data.scope.map(id => _entity_1.ModuleFE_Value.cache.unique(id));
            break;
        case _entity_1.valueType_Range:
            values = parentValue.data.subsets.map(id => _entity_1.ModuleFE_Value.cache.unique(id));
            break;
    }
    const valuesNames = values.map(i => i.type === _entity_1.valueType_EnumElement ? i.data.value : i.label || '');
    return (0, ts_common_1.filterInstances)([item.name, ...(nlp ? nlp.synonyms : []), ...attributes.map(i => i.name), ...valuesNames]);
};
exports.variableMapper = variableMapper;
