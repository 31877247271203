"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator_Prognosis = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const validator_1 = require("../../population/shared/validator");
const types_2 = require("@app/hcs/_enum/timespan/shared/types");
// ########################### Prognosis Rate Validation ###########################
const Validator_PrognosisValue = (0, ts_common_1.tsValidateValue)([...types_1.PrognosisValues]);
const Validator_PrognosisRate_Qualitative = {
    type: (0, ts_common_1.tsValidateValue)([types_1.PrognosisRateType_Qualitative]),
    value: Validator_PrognosisValue,
};
const Validator_PrognosisRate_Survival = {
    type: (0, ts_common_1.tsValidateValue)([types_1.PrognosisRateType_Survival]),
    value: (0, ts_common_1.tsValidateRange)(),
};
const Validator_PrognosisRate_Mortality = {
    type: (0, ts_common_1.tsValidateValue)([types_1.PrognosisRateType_Mortality]),
    value: (0, ts_common_1.tsValidateRange)(),
};
const Validator_PrognosisRate = (rate) => {
    if (!rate)
        return (0, ts_common_1.tsValidate)(rate, ts_common_1.tsValidateMustExist);
    switch (rate.type) {
        case types_1.PrognosisRateType_Qualitative:
            return (0, ts_common_1.tsValidate)(rate, Validator_PrognosisRate_Qualitative);
        case types_1.PrognosisRateType_Survival:
            return (0, ts_common_1.tsValidate)(rate, Validator_PrognosisRate_Survival);
        case types_1.PrognosisRateType_Mortality:
            return (0, ts_common_1.tsValidate)(rate, Validator_PrognosisRate_Mortality);
        default:
            // @ts-ignore
            return `Missing implementation for rate of type ${rate.type}`;
    }
};
// ########################### Time Period Validation ###########################
const Validator_TimePeriodType = (0, ts_common_1.tsValidateValue)(types_2.TimespansType);
const Validator_TimePeriod = {
    type: Validator_TimePeriodType,
    value: (0, ts_common_1.tsValidateNumber)(),
};
// ########################### Prognosis Validation ###########################
exports.Validator_Prognosis = {
    rate: Validator_PrognosisRate,
    timePeriod: Validator_TimePeriod,
    population: validator_1.Validator_Population,
};
