"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator_Epidemiology = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const shared_1 = require("../../population/shared");
const Validator_EpidemiologyData_Percentage = {
    rate: (0, ts_common_1.tsValidateValue)([types_1.EpidemiologyRate_Percentage]),
    rateValue: (0, ts_common_1.tsValidateRange)(),
};
const Validator_EpidemiologyData_Ratio = {
    rate: (0, ts_common_1.tsValidateValue)([types_1.EpidemiologyRate_Ratio]),
    rateValue: {
        numerator: (0, ts_common_1.tsValidateRange)(),
        denominator: (0, ts_common_1.tsValidateNumber)(),
    },
};
const Validator_EpidemiologyData = (data) => {
    const error = (0, ts_common_1.tsValidate)(data, ts_common_1.tsValidateMustExist);
    if (error)
        return error;
    if ((data === null || data === void 0 ? void 0 : data.rate) === types_1.EpidemiologyRate_Percentage)
        return (0, ts_common_1.tsValidate)(data, Validator_EpidemiologyData_Percentage);
    if ((data === null || data === void 0 ? void 0 : data.rate) === types_1.EpidemiologyRate_Ratio)
        return (0, ts_common_1.tsValidate)(data, Validator_EpidemiologyData_Ratio);
    // @ts-ignore
    return `Missing validator for epidemiology data with rate ${data.rate}`;
};
exports.Validator_Epidemiology = {
    type: (0, ts_common_1.tsValidateValue)([...types_1.EpidemiologyTypes]),
    population: shared_1.Validator_Population,
    data: Validator_EpidemiologyData,
};
