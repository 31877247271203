"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_NLPData = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    synonyms: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateRegexp)(/^[A-Za-zÀ-ÖØ-öø-ÿ\d\-+:'.&/ ]{2,100}$/)),
    phrases: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false),
    keywords: (0, ts_common_1.tsValidateArray)({ label: (0, ts_common_1.tsValidateString)(), score: (0, ts_common_1.tsValidateIsInRange)([[-1, 1]]) }, false),
    corpus: (0, ts_common_1.tsValidateArray)({ label: (0, ts_common_1.tsValidateString)(), options: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)()) }, false),
    _rowId: (0, ts_common_1.tsValidateId)(ts_common_1.dbIdLength, false),
};
const Validator_GeneratedProps = {};
exports.DBDef_NLPData = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1'],
    dbKey: 'nlp-data',
    entityName: 'NLPData',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'nlp-data'
    },
    backend: {
        name: 'nlp-data'
    },
    metadata: {
        synonyms: {
            optional: false,
            description: '',
            valueType: 'string[]',
            metadata: { optional: false, description: 'Array of synonyms to this data', valueType: 'string' }
        },
        phrases: {
            optional: false,
            description: '',
            valueType: 'string[]',
            metadata: { optional: true, description: '', valueType: 'string' }
        },
        keywords: {
            optional: false,
            description: '',
            valueType: 'object[]',
            metadata: {
                label: { optional: false, description: '', valueType: 'string' },
                score: { optional: false, description: '1 / 0 / -1', valueType: 'number' }
            }
        },
        corpus: {
            optional: false,
            description: '',
            valueType: 'object[]',
            metadata: {
                label: { optional: false, valueType: 'string', description: '' },
                options: {
                    optional: false,
                    description: '',
                    valueType: 'string[]',
                    metadata: { optional: false, description: '', valueType: 'string' }
                }
            }
        },
        _rowId: { optional: true, description: 'exists only for excel-origin objects', valueType: 'string' },
    },
};
