"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_ExpressionLeftHand = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const consts_1 = require("@app/styles/frontend/ui/consts");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_2 = require("../../../../../_entity/variable/frontend");
const frontend_3 = require("../../../../../_entity/expression/frontend");
const consts_2 = require("../../../../../_entity/expression/shared/consts");
const frontend_4 = require("../../../../../_entity/variable-relation/frontend");
const wrappers_1 = require("../../../../wrappers");
class DropDown_ExpressionLeftHand extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.getItems = () => {
            var _a, _b, _c;
            if (this.state.selectingAttribute) {
                let attributes = (_c = (_b = (_a = this.state.selectedVariable) === null || _a === void 0 ? void 0 : _a._attributeVarIds) === null || _b === void 0 ? void 0 : _b.map(attrId => frontend_2.ModuleFE_Variable.cache.unique(attrId))) !== null && _c !== void 0 ? _c : [];
                attributes = attributes.filter(attr => !(attr === null || attr === void 0 ? void 0 : attr._nameInLowercase.includes(consts_2.LeftHandType_Precipitating)) && !(attr === null || attr === void 0 ? void 0 : attr._nameInLowercase.includes(consts_2.LeftHandType_Relieving)));
                return attributes;
            }
            if (this.state.selectingFactor) {
                const relations = frontend_4.ModuleFE_VariableRelation.cache.filter(item => { var _a, _b, _c; return (item._firstVariableId === ((_a = this.state.selectedVariable) === null || _a === void 0 ? void 0 : _a._id) && ((_b = item.relation) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === ((_c = this.state.selectedAttribute) === null || _c === void 0 ? void 0 : _c._nameInLowercase.slice(0, -1))); });
                return (0, ts_common_1.filterDuplicates)((0, ts_common_1.filterInstances)(relations.map(relation => { var _a; return frontend_2.ModuleFE_Variable.cache.unique((_a = relation.rightSide[0]) === null || _a === void 0 ? void 0 : _a.variableId); })));
            }
            return this.state.values;
        };
        this.handleInputChange = (inputValue, dropDown) => {
            var _a;
            if (!inputValue.length)
                this.setState({ selectingAttribute: false, selectedAttribute: undefined, selectedVariable: undefined });
            if (inputValue.endsWith('.')) {
                const splitByDots = inputValue.split('.');
                const variable = this.state.values.find(v => { var _a; return v._nameInLowercase === ((_a = splitByDots[0]) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
                const attribute = this.state.values.find(v => { var _a; return v._nameInLowercase === ((_a = splitByDots[1]) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
                if (variable && ((_a = variable._attributeVarIds) === null || _a === void 0 ? void 0 : _a.length)) {
                    this.setState({ selectingAttribute: true, selectedVariable: variable }, this.forceUpdate);
                }
                if (attribute && consts_2.factorTypes.includes(attribute._nameInLowercase)) {
                    this.setState({ selectingFactor: true, selectingAttribute: false, selectedAttribute: attribute });
                }
            }
            const splitInput = inputValue.split('.');
            // @ts-ignore
            dropDown.reDeriveState({ filterText: splitInput[splitInput.length - 1] });
        };
        this.isValueAtom = (value) => {
            if (!value.item)
                return false;
            return 'leftHand' in value.item;
        };
        this.handleSelection = async (value) => {
            var _a;
            const variable = this.state.selectedVariable;
            const attribute = this.state.selectedAttribute;
            if (!value) {
                return;
            }
            let leftHand = {};
            if (this.isValueAtom(value)) {
                const id = this.props.atoms.findIndex(atom => (0, ts_common_1.compare)(value.item, atom)) + 1;
                leftHand = { id: String(id), type: 'atom' };
            }
            else {
                // If the item is from type expression (embedded expression)
                if ('label' in value)
                    leftHand = { id: value._id, type: 'expression' };
                // If the user picked variable only
                else if (!attribute && !variable)
                    leftHand = { id: value._id, type: 'variable' };
                // If the user picked variable with its attribute
                else if (variable && !attribute)
                    leftHand = { id: variable._id, propertyId: value._id, type: 'attribute' };
                //if the user selected a factor
                else if (variable && attribute)
                    leftHand = {
                        id: variable._id,
                        propertyId: value._id,
                        type: consts_2.FactorOperators.find(factor => attribute._nameInLowercase.includes(factor))
                    };
            }
            const cases = (_a = this.state.editable.item.cases) !== null && _a !== void 0 ? _a : [];
            cases[this.props.index] = {
                caseOperator: undefined,
            };
            await this.state.editable.updateObj({
                leftHand: leftHand,
                cases: cases
            });
            this.setState({ selectingAttribute: false, selectingFactor: false });
        };
        this.resolveTagTitle = (value) => {
            if (!value)
                return;
            if (('valueId' in value))
                return 'HV';
            return 'Ex';
        };
        this.resolveSelectedItem = () => {
            if (this.props.selected.type === 'atom')
                return;
            if (this.props.selected.type !== 'expression') {
                const variable = frontend_2.ModuleFE_Variable.cache.unique(this.props.selected.id);
                if (!variable)
                    throw new ts_common_1.MUSTNeverHappenException(`variable with id ${this.props.selected.id} not found`);
                return variable;
            }
            const expression = frontend_3.ModuleFE_Expression.cache.unique(this.props.selected.id);
            if (!expression)
                throw new ts_common_1.MUSTNeverHappenException(`expression with id ${this.props.selected.id} not found`);
            return expression;
        };
        this.renderSelectedItem = () => {
            const selectedTitle = this.state.selectedTitle;
            if (!selectedTitle)
                return React.createElement("div", { className: "ts-dropdown__placeholder" }, 'Pick A Variable');
            const selectedItem = this.resolveSelectedItem();
            return React.createElement(frontend_1.LL_H_C, { style: { gap: '15px' }, className: 'ts-dropdown__selected' },
                selectedTitle,
                selectedItem && React.createElement("div", { className: 'lefthand-type-tag' }, this.resolveTagTitle(selectedItem)));
        };
        this.renderSearchInput = (dropDown) => {
            return React.createElement("div", { className: 'input-with-label' },
                React.createElement(frontend_1.TS_Input, { type: "text", value: dropDown.props.inputValue, onChange: (filterText) => this.handleInputChange(filterText, dropDown), focus: true, style: { width: '100%' }, placeholder: dropDown.props.placeholder || '', onCancel: () => {
                        // @ts-ignore
                        dropDown.reDeriveState({ open: false, filterText: undefined });
                    }, onKeyDown: (e) => {
                        // @ts-ignore
                        dropDown.inputKeyEventHandler(e);
                    } }),
                this.state.selectedTitle);
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        const allVars = frontend_2.ModuleFE_Variable.cache.filter(item => !item.isAttribute);
        const allGeneralExpressions = frontend_3.ModuleFE_Expression.cache.filter(item => item.expressionType === consts_2.ExpressionType_GeneralScore.dbKey || item.expressionType === consts_2.ExpressionType_GeneralFunction.dbKey);
        const allValues = [...allVars, ...allGeneralExpressions];
        if (nextProps.queryFilter)
            state.values = allValues.filter(nextProps.queryFilter);
        else
            state.values = allValues;
        if (nextProps.sortBy)
            state.values = (0, ts_common_1.sortArray)(state.values, nextProps.sortBy[0]);
        if (nextProps.selected)
            state.selectedTitle = this.getTitle(nextProps.selected);
        else
            state.selectedTitle = undefined;
        return state;
    }
    /**
     * Generate dropdown label from the selected values
     * @param selected the latest selected value coming from props
     * @private
     */
    getTitle(selected) {
        var _a, _b;
        if (selected.type === 'atom')
            return `A${selected.id}`;
        let base;
        if (selected.type === 'expression') {
            const expression = frontend_3.ModuleFE_Expression.cache.unique(selected.id);
            base = (_a = expression === null || expression === void 0 ? void 0 : expression.label) !== null && _a !== void 0 ? _a : 'Entity Deleted';
        }
        else {
            const variable = frontend_2.ModuleFE_Variable.cache.unique(selected.id);
            base = (_b = variable === null || variable === void 0 ? void 0 : variable.name) !== null && _b !== void 0 ? _b : 'Entity Deleted';
        }
        let property;
        if ('propertyId' in selected) {
            property = frontend_2.ModuleFE_Variable.cache.unique(selected.propertyId).name;
        }
        return React.createElement("div", { className: 'ts-dropdown__selected', style: { color: base === 'Entity Deleted' ? 'red' : '#4f4f4f' } }, `${base}${(selected.type === 'precipitating' || selected.type === 'relieving') ? `.${selected.type}` : ''}${property ? `.${property}` : ''}`);
    }
    render() {
        var _a, _b;
        const items = this.getItems();
        const filter = new ts_common_1.Filter(i => {
            var _a;
            if (!i)
                return [];
            if (frontend_2.ModuleFE_Variable.cache.unique(i._id)) {
                return new wrappers_1.VariableWrapper(i).getSearchableText();
            }
            return [(_a = i.label) !== null && _a !== void 0 ? _a : ''];
        });
        let adapter = () => (0, frontend_1.SimpleListAdapter)(items, i => {
            var _a, _b;
            return React.createElement(frontend_1.LL_H_C, { className: 'variable-dropdown-row' }, (_b = (_a = i.item) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : i.item.label,
                React.createElement("div", { className: 'lefthand-type-tag' }, this.resolveTagTitle(i.item)));
        });
        if ((0, ts_common_1.exists)(this.props.atoms)) {
            const items = (_a = this.props.atoms) === null || _a === void 0 ? void 0 : _a.map((atom, index) => ({ label: `A${index + 1}`, item: atom }));
            adapter = () => (0, frontend_1.SimpleListAdapter)(items, item => {
                return React.createElement(React.Fragment, null, item.item.label);
            });
        }
        const leftHandProps = {
            adapter,
            selectedItemRenderer: this.renderSelectedItem,
            renderSearch: this.renderSearchInput,
            limitItems: (_b = this.props.limitItems) !== null && _b !== void 0 ? _b : 50,
            caret: this.props.disabled ? undefined : consts_1.DropDown_DefaultCaret,
            className: 'variable-dropdown',
            filter: filter
        };
        const DropDown_LeftHand = frontend_1.TS_DropDown.prepareEditable(leftHandProps);
        return DropDown_LeftHand({
            editable: this.state.editable,
            prop: 'leftHand',
            disabled: this.props.disabled,
            onSelected: this.handleSelection,
            showErrorTooltip: true
        });
    }
}
exports.DropDown_ExpressionLeftHand = DropDown_ExpressionLeftHand;
