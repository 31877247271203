"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_NLPData = exports.ModuleFE_NLPData_Class = exports.dispatch_onNLPDatasUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onNLPDatasUpdated = new types_1.ThunderDispatcherV3('__onNLPDatasUpdated');
class ModuleFE_NLPData_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_NLPData, exports.dispatch_onNLPDatasUpdated);
    }
}
exports.ModuleFE_NLPData_Class = ModuleFE_NLPData_Class;
exports.ModuleFE_NLPData = new ModuleFE_NLPData_Class();
