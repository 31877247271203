"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_MeasurementUnit = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    unitAsString: (0, ts_common_1.tsValidateString)(),
    multiply: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false),
    divide: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false)
};
const Validator_GeneratedProps = {};
exports.DBDef_MeasurementUnit = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'measurement-units',
    entityName: 'MeasurementUnit',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'measurement-unit'
    },
    backend: {
        name: 'measurement-units'
    },
};
