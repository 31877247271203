"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Q_Checkbox = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
const Q_Checkbox = (props) => {
    const { className } = props, rest = __rest(props, ["className"]);
    const cbClassName = (0, frontend_1._className)('quai', className);
    return React.createElement(frontend_1.TS_Checkbox, Object.assign({}, rest, { className: cbClassName, icon: React.createElement(styles_1.ICONS.v.component, null) }));
};
exports.Q_Checkbox = Q_Checkbox;
