"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Resource = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateString)(),
    type: (0, ts_common_1.tsValidateValue)([...types_1.ResourceTypes]),
    tagId: ts_common_1.tsValidateUniqueId,
    variableProperties: ts_common_1.tsValidator_arrayOfUniqueIds,
    tagsProperties: ts_common_1.tsValidator_arrayOfUniqueIds
};
const Validator_GeneratedProps = {};
exports.DBDef_Resource = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'resources',
    entityName: 'Resource',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'resource'
    },
    backend: {
        name: 'resources'
    },
    dependencies: {
        tagId: {
            dbKey: 'tags',
            fieldType: 'string'
        },
        variableProperties: {
            dbKey: 'variables',
            fieldType: 'string[]'
        },
        tagsProperties: {
            dbKey: 'tags',
            fieldType: 'string[]'
        }
    }
};
