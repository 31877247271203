"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultColorGroups = void 0;
exports.defaultColorGroups = [
    {
        'label': 'Cardiovascular',
        'criticality-00': [
            '#ED5E5E',
            '#EE6D6B',
            '#F07C78',
            '#F18B85',
            '#F29992',
            '#F4A69F',
            '#F5B4AD'
        ],
        'criticality-10': [
            '#CC3232',
            '#CE413E',
            '#D14E4A',
            '#D45C55',
            '#D76961',
            '#DA766D',
            '#DD8278'
        ],
        'criticality-20': [
            '#6B2D2D',
            '#753332',
            '#7F3936',
            '#893E3B',
            '#93453F',
            '#9E4B43',
            '#A85148'
        ]
    },
    {
        'label': 'Musculoskeletal System',
        'criticality-00': [
            '#ED8B5E',
            '#EE976B',
            '#F0A278',
            '#F1AD85',
            '#F2B792',
            '#F4C19F',
            '#F5CBAD'
        ],
        'criticality-10': [
            '#CC6332',
            '#CE6E3E',
            '#D1794A',
            '#D48455',
            '#D78E61',
            '#DA986D',
            '#DDA278'
        ],
        'criticality-20': [
            '#6B412D',
            '#754832',
            '#7F5036',
            '#89573B',
            '#935F3F',
            '#9E6743',
            '#A87048'
        ]
    },
    {
        'label': 'Genito-Urinary',
        'criticality-00': [
            '#EDB85E',
            '#EEC06B',
            '#F0C878',
            '#F1CF85',
            '#F2D592',
            '#F4DC9F',
            '#F5E2AD'
        ],
        'criticality-10': [
            '#CC9332',
            '#CE9C3E',
            '#D1A44A',
            '#D4AC55',
            '#D7B461',
            '#DABB6D',
            '#DDC278'
        ],
        'criticality-20': [
            '#6B542D',
            '#755D32',
            '#7F6736',
            '#89703B',
            '#937A3F',
            '#9E8443',
            '#A88E48'
        ]
    },
    {
        'label': 'Central Nerves System',
        'criticality-00': [
            '#EDE55E',
            '#EEE96B',
            '#F0ED78',
            '#F1F185',
            '#F1F292',
            '#F1F49F',
            '#F2F5AD'
        ],
        'criticality-10': [
            '#CCC332',
            '#CEC93E',
            '#D1CF4A',
            '#D4D455',
            '#D6D761',
            '#D7DA6D',
            '#D8DD78'
        ],
        'criticality-20': [
            '#6B672D',
            '#757232',
            '#7F7E36',
            '#89893B',
            '#92933F',
            '#9B9E43',
            '#A3A848'
        ]
    },
    {
        'label': 'Hepatobiliary System',
        'criticality-00': [
            '#C7ED5E',
            '#C9EE6B',
            '#CCF078',
            '#CFF185',
            '#D3F292',
            '#D7F49F',
            '#DBF5AD'
        ],
        'criticality-10': [
            '#A3CC32',
            '#A6CE3E',
            '#A9D14A',
            '#ADD455',
            '#B0D761',
            '#B4DA6D',
            '#B8DD78'
        ],
        'criticality-20': [
            '#5A6B2D',
            '#627532',
            '#697F36',
            '#71893B',
            '#78933F',
            '#7E9E43',
            '#85A848'
        ]
    },
    {
        'label': 'Ophthalmic',
        'criticality-00': [
            '#9AED5E',
            '#A0EE6B',
            '#A6F078',
            '#ADF185',
            '#B4F292',
            '#BCF49F',
            '#C4F5AD'
        ],
        'criticality-10': [
            '#73CC32',
            '#78CE3E',
            '#7ED14A',
            '#85D455',
            '#8BD761',
            '#92DA6D',
            '#99DD78'
        ],
        'criticality-20': [
            '#476B2D',
            '#4D7532',
            '#527F36',
            '#58893B',
            '#5D933F',
            '#629E43',
            '#67A848'
        ]
    },
    {
        'label': 'Gastrointestinal Tract',
        'criticality-00': [
            '#6DED5E',
            '#77EE6B',
            '#81F078',
            '#8BF185',
            '#96F292',
            '#A1F49F',
            '#ADF5AD'
        ],
        'criticality-10': [
            '#43CC32',
            '#4BCE3E',
            '#54D14A',
            '#5CD455',
            '#66D761',
            '#6FDA6D',
            '#79DD78'
        ],
        'criticality-20': [
            '#346B2D',
            '#387532',
            '#3B7F36',
            '#3F893B',
            '#42933F',
            '#459E43',
            '#48A848'
        ]
    },
    {
        'label': 'Endocrine/Metabolic',
        'criticality-00': [
            '#5EED7C',
            '#6BEE89',
            '#78F095',
            '#85F1A1',
            '#92F2AD',
            '#9FF4B8',
            '#ADF5C3'
        ],
        'criticality-10': [
            '#32CC53',
            '#3ECE5F',
            '#4AD16B',
            '#55D476',
            '#61D782',
            '#6DDA8D',
            '#78DD98'
        ],
        'criticality-20': [
            '#2D6B3A',
            '#327541',
            '#367F48',
            '#3B894F',
            '#3F9356',
            '#439E5E',
            '#48A865'
        ]
    },
    {
        'label': 'Congenital/Genetic',
        'criticality-00': [
            '#5EEDA9',
            '#6BEEB2',
            '#78F0BB',
            '#85F1C3',
            '#92F2CB',
            '#9FF4D3',
            '#ADF5DA'
        ],
        'criticality-10': [
            '#32CC83',
            '#3ECE8C',
            '#4AD196',
            '#55D49F',
            '#61D7A7',
            '#6DDAAF',
            '#78DDB7'
        ],
        'criticality-20': [
            '#2D6B4E',
            '#327556',
            '#367F5F',
            '#3B8968',
            '#3F9371',
            '#439E7A',
            '#48A884'
        ]
    },
    {
        'label': 'Integumentary System',
        'criticality-00': [
            '#5EEDD6',
            '#6BEEDC',
            '#78F0E1',
            '#85F1E5',
            '#92F2EA',
            '#9FF4EE',
            '#ADF5F1'
        ],
        'criticality-10': [
            '#32CCB3',
            '#3ECEBA',
            '#4AD1C0',
            '#55D4C7',
            '#61D7CC',
            '#6DDAD2',
            '#78DDD7'
        ],
        'criticality-20': [
            '#2D6B61',
            '#32756B',
            '#367F76',
            '#3B8981',
            '#3F938C',
            '#439E97',
            '#48A8A2'
        ]
    },
    {
        'label': 'Psychiatric',
        'criticality-00': [
            '#5ED6ED',
            '#6BD7EE',
            '#78D9F0',
            '#85DBF1',
            '#92DDF2',
            '#9FE0F4',
            '#ADE3F5'
        ],
        'criticality-10': [
            '#32B3CC',
            '#3EB5CE',
            '#4AB7D1',
            '#55BAD4',
            '#61BDD7',
            '#6DC0DA',
            '#78C3DD'
        ],
        'criticality-20': [
            '#2D616B',
            '#326975',
            '#36717F',
            '#3B7989',
            '#3F8093',
            '#43889E',
            '#488FA8'
        ]
    },
    {
        'label': 'Ear Nose Throat',
        'criticality-00': [
            '#5EA9ED',
            '#6BAEEE',
            '#78B3F0',
            '#85B9F1',
            '#92BFF2',
            '#9FC5F4',
            '#ADCCF5'
        ],
        'criticality-10': [
            '#3283CC',
            '#3E88CE',
            '#4A8DD1',
            '#5592D4',
            '#6197D7',
            '#6D9DDA',
            '#78A3DD'
        ],
        'criticality-20': [
            '#2D4E6B',
            '#325475',
            '#365A7F',
            '#3B6089',
            '#3F6693',
            '#436B9E',
            '#4871A8'
        ]
    },
    {
        'label': 'Respiratory',
        'criticality-00': [
            '#6D5EED',
            '#7B6BEE',
            '#8978F0',
            '#9685F1',
            '#A392F2',
            '#AF9FF4',
            '#BBADF5'
        ],
        'criticality-10': [
            '#4332CC',
            '#503ECE',
            '#5D4AD1',
            '#6955D4',
            '#7561D7',
            '#816DDA',
            '#8D78DD'
        ],
        'criticality-20': [
            '#342D6B',
            '#3A3275',
            '#40367F',
            '#473B89',
            '#4D3F93',
            '#54439E',
            '#5B48A8'
        ]
    },
    {
        'label': 'Hematologic System',
        'criticality-00': [
            '#9A5EED',
            '#A46BEE',
            '#AE78F0',
            '#B885F1',
            '#C192F2',
            '#CA9FF4',
            '#D2ADF5'
        ],
        'criticality-10': [
            '#7332CC',
            '#7D3ECE',
            '#874AD1',
            '#9155D4',
            '#9B61D7',
            '#A46DDA',
            '#AD78DD'
        ],
        'criticality-20': [
            '#472D6B',
            '#4F3275',
            '#57367F',
            '#603B89',
            '#683F93',
            '#71439E',
            '#7A48A8'
        ]
    },
    {
        'label': 'Immune, Inflammatory & Lymphatic',
        'criticality-00': [
            '#C75EED',
            '#CE6BEE',
            '#D478F0',
            '#DA85F1',
            '#E092F2',
            '#E59FF4',
            '#EAADF5'
        ],
        'criticality-10': [
            '#A332CC',
            '#AB3ECE',
            '#B24AD1',
            '#B955D4',
            '#C061D7',
            '#C66DDA',
            '#CD78DD'
        ],
        'criticality-20': [
            '#5A2D6B',
            '#643275',
            '#6E367F',
            '#783B89',
            '#833F93',
            '#8D439E',
            '#9848A8'
        ]
    },
    {
        'label': 'Toxicity',
        'criticality-00': [
            '#ED5EE5',
            '#EE6BE5',
            '#F078E5',
            '#F185E6',
            '#F292E7',
            '#F49FE8',
            '#F5ADEA'
        ],
        'criticality-10': [
            '#CC32C3',
            '#CE3EC4',
            '#D14AC6',
            '#D455C7',
            '#D761C9',
            '#DA6DCB',
            '#DD78CE'
        ],
        'criticality-20': [
            '#6B2D67',
            '#753270',
            '#7F3679',
            '#893B81',
            '#933F89',
            '#9E4391',
            '#A84899'
        ]
    },
    {
        'label': 'Drugs',
        'criticality-00': [
            '#ED5EB8',
            '#EE6BBC',
            '#F078C0',
            '#F185C4',
            '#F292C9',
            '#F49FCE',
            '#F5ADD3'
        ],
        'criticality-10': [
            '#CC3293',
            '#CE3E97',
            '#D14A9B',
            '#D4559F',
            '#D761A4',
            '#DA6DA9',
            '#DD78AE'
        ],
        'criticality-20': [
            '#6B2D54',
            '#75325B',
            '#7F3662',
            '#893B68',
            '#933F6F',
            '#9E4375',
            '#A8487B'
        ]
    },
    {
        'label': 'Infectious Diseases',
        'criticality-00': [
            '#ED5E8B',
            '#EE6B92',
            '#F0789A',
            '#F185A2',
            '#F292AA',
            '#F49FB3',
            '#F5ADBC'
        ],
        'criticality-10': [
            '#CC3263',
            '#CE3E69',
            '#D14A70',
            '#D45577',
            '#D7617F',
            '#DA6D86',
            '#DD788E'
        ],
        'criticality-20': [
            '#6B2D41',
            '#753246',
            '#7F364B',
            '#893B4F',
            '#933F54',
            '#9E4358',
            '#A8485C'
        ]
    }
    // {
    // 	'label': '12',
    // 	'criticality-00': [
    // 		'#5E7CED',
    // 		'#6B84EE',
    // 		'#788DF0',
    // 		'#8597F1',
    // 		'#92A0F2',
    // 		'#9FAAF4',
    // 		'#ADB5F5'
    // 	],
    // 	'criticality-10': [
    // 		'#3253CC',
    // 		'#3E5ACE',
    // 		'#4A62D1',
    // 		'#556AD4',
    // 		'#6172D7',
    // 		'#6D7BDA',
    // 		'#7884DD'
    // 	],
    // 	'criticality-20': [
    // 		'#2D3A6B',
    // 		'#323F75',
    // 		'#36437F',
    // 		'#3B4789',
    // 		'#3F4B93',
    // 		'#434F9E',
    // 		'#4852A8'
    // 	]
    // },
];
