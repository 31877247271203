"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_EHROrderConfiguration = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
const Validator_GeneratedProps = {};
const Validator_EHR_OrderConfigAvailability_Partial_Data = {
    start: (0, ts_common_1.tsValidateIsInRange)([[0, 1440]]),
    end: (0, ts_common_1.tsValidateIsInRange)([[0, 1440]]),
};
const Validator_EHR_OrderConfigAvailability_Always = {
    type: (0, ts_common_1.tsValidateValue)([types_1.ehrOrderAvailability_Always]),
};
const Validator_EHR_OrderConfigAvailability_Partial = {
    type: (0, ts_common_1.tsValidateValue)([types_1.ehrOrderAvailability_Partial]),
    data: {
        sun: (0, ts_common_1.tsValidateArray)(Validator_EHR_OrderConfigAvailability_Partial_Data),
        mon: (0, ts_common_1.tsValidateArray)(Validator_EHR_OrderConfigAvailability_Partial_Data),
        tue: (0, ts_common_1.tsValidateArray)(Validator_EHR_OrderConfigAvailability_Partial_Data),
        wed: (0, ts_common_1.tsValidateArray)(Validator_EHR_OrderConfigAvailability_Partial_Data),
        thu: (0, ts_common_1.tsValidateArray)(Validator_EHR_OrderConfigAvailability_Partial_Data),
        fri: (0, ts_common_1.tsValidateArray)(Validator_EHR_OrderConfigAvailability_Partial_Data),
        sat: (0, ts_common_1.tsValidateArray)(Validator_EHR_OrderConfigAvailability_Partial_Data),
    }
};
const Validator_EHR_OrderConfigAvailability = (instance) => {
    if ((instance === null || instance === void 0 ? void 0 : instance.type) === types_1.ehrOrderAvailability_Always)
        return (0, ts_common_1.tsValidateResult)(instance, Validator_EHR_OrderConfigAvailability_Always);
    if ((instance === null || instance === void 0 ? void 0 : instance.type) === types_1.ehrOrderAvailability_Partial)
        return (0, ts_common_1.tsValidateResult)(instance, Validator_EHR_OrderConfigAvailability_Partial);
    // @ts-ignore
    throw new ts_common_1.MUSTNeverHappenException(`Unknown availability type ${instance === null || instance === void 0 ? void 0 : instance.type}`);
};
const Validator_ModifiableProps = {
    ehrInstanceId: ts_common_1.tsValidateUniqueId,
    orderId: ts_common_1.tsValidateUniqueId,
    ehrOrderId: (0, ts_common_1.tsValidateString)(-1, false),
    source: (0, ts_common_1.tsValidateValue)([...types_1.EHR_OrderConfigSources], false),
    availability: Validator_EHR_OrderConfigAvailability
};
exports.DBDef_EHROrderConfiguration = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'ehr-order-configuration',
    entityName: 'EHROrderConfiguration',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'ehr-order-configuration'
    },
    backend: {
        name: 'ehr-order-configuration'
    },
    dependencies: {
        orderId: {
            dbKey: 'orders',
            fieldType: 'string'
        },
        ehrInstanceId: {
            dbKey: 'ehr-instances',
            fieldType: 'string'
        }
    }
};
