"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_DPView_ContentSelector = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Panel_DPView_ContentSelector.scss");
const DPView_VariablesAndValues_1 = require("../../renderers/DPView_VariablesAndValues");
const _entity_1 = require("../../../_entity");
const ts_common_1 = require("@nu-art/ts-common");
const Panel_MultiVarList_1 = require("../Panel_MultiVarList/Panel_MultiVarList");
class Panel_DPView_ContentSelector extends frontend_1.ProtoComponent {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        // ######################### Render #########################
        this.renderPanelMain = () => {
            if (!this.state.selectedDiseaseViewId)
                return React.createElement("div", { className: 'no-prop-message' }, "No Disease View Selected");
            const selectedDiseaseView = this.state.selectedDiseaseViewId === _entity_1.noConnectedViewDPView._id
                ? _entity_1.noConnectedViewDPView
                : _entity_1.ModuleFE_DiseaseView.cache.unique(this.state.selectedDiseaseViewId);
            if (!selectedDiseaseView)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find disease view for id ${this.state.selectedDiseaseViewId}`);
            switch (selectedDiseaseView.type) {
                case _entity_1.dpViewType_MultivarAndValuesList:
                    return React.createElement(DPView_VariablesAndValues_1.DPView_VariablesAndValues, { diseaseView: selectedDiseaseView });
                case _entity_1.dpViewType_MultivarList:
                    return React.createElement(Panel_MultiVarList_1.Panel_MultiVarList, null);
                default:
                    return React.createElement("div", { className: 'no-prop-message' },
                        "No Implementation for type ",
                        selectedDiseaseView.type);
            }
        };
    }
    // ######################### Life Cycle #########################
    __onDiseaseViewUpdated(...params) {
        if ((params[0] === frontend_1.EventType_Update || params[0] === frontend_1.EventType_Create) && params[1]._id === this.state.selectedDiseaseViewId)
            this.forceUpdate();
    }
    deriveStateFromProps(nextProps, state) {
        state.selectedDiseaseViewId = this.getQueryParam('selected', {})[_entity_1.DBDef_DiseaseView.dbKey];
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'panel__disease-properties-data' }, this.renderPanelMain());
    }
}
Panel_DPView_ContentSelector.defaultProps = {
    keys: ['selected'],
};
exports.Panel_DPView_ContentSelector = Panel_DPView_ContentSelector;
