"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveNormalRangeConversion = exports.resolveNormalRange = exports.resolveBaseNormalRangeAtom = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
const NormalRangeOperatorConversion = {
    [consts_1.LogicType_InNormal]: 'in',
    [consts_1.LogicType_AboveNormal]: 'above',
    [consts_1.LogicType_BelowNormal]: 'below',
};
const resolveBaseNormalRangeAtom = (dbAtom, convertedAtom, dependencies) => {
    var _a;
    const dbVariable = dependencies.variables.find(_var => _var._id === dbAtom.leftHand.id);
    if (!dbVariable)
        throw new ts_common_1.BadImplementationException(`Cannot find DB Variable: ${(_a = dbAtom.leftHand) === null || _a === void 0 ? void 0 : _a.id}`);
    const dbExpression = dependencies.expressions.find(_expr => _expr.associatedVar === dbVariable._id);
    if (!dbExpression)
        throw new ts_common_1.BadImplementationException(`Cannot find DB Expression with associated variable: ${dbVariable._id}`);
    convertedAtom.hv_name = dbVariable.name;
    convertedAtom.db_id = dbVariable._id;
    convertedAtom.expr_db_id = dbExpression._id;
};
exports.resolveBaseNormalRangeAtom = resolveBaseNormalRangeAtom;
const resolveNormalRangeParams = (_case) => {
    var _a, _b, _c, _d;
    return {
        relation: NormalRangeOperatorConversion[_case.caseOperator],
        upper_scale: (_b = (_a = _case.factor) === null || _a === void 0 ? void 0 : _a.upper) !== null && _b !== void 0 ? _b : 1,
        lower_scale: (_d = (_c = _case.factor) === null || _c === void 0 ? void 0 : _c.lower) !== null && _d !== void 0 ? _d : 1
    };
};
const resolveNormalRange = (dbAtom) => {
    const normalRange = {};
    // Set default values derived from the type
    normalRange.output_type = 'boolean';
    normalRange.type = 'in-normal-range-condition';
    // Convert the db value
    normalRange.params = resolveNormalRangeParams(dbAtom.cases[0]);
    return normalRange;
};
exports.resolveNormalRange = resolveNormalRange;
const resolveNormalRangeConversion = (dbAtom, baseConversion) => {
    const normalRangeConversion = baseConversion;
    // Set default values derived from the type
    normalRangeConversion.type = 'in-normal-range-conversion';
    normalRangeConversion.options = dbAtom.cases.map(_case => ({
        return_value: normalRangeConversion.output_type === 'tuple' ? _case.value : _case.value[0],
        params: resolveNormalRangeParams(_case)
    }));
    return normalRangeConversion;
};
exports.resolveNormalRangeConversion = resolveNormalRangeConversion;
