"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHROrder_DaysLabelMap = exports.EHROrder_DaysOrder = exports.EHROrder_AvailabilityLabelMap = exports.EHROrder_SourceLabelMap = void 0;
exports.EHROrder_SourceLabelMap = {
    fhir: 'FHIR',
    'manually-in-advisor': 'Manually In Advisor',
};
exports.EHROrder_AvailabilityLabelMap = {
    always: 'Always',
    partial: 'Partial'
};
exports.EHROrder_DaysOrder = [
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
];
exports.EHROrder_DaysLabelMap = {
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tues',
    wed: 'Wed',
    thu: 'Thurs',
    fri: 'Fri',
    sat: 'Sat'
};
