"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Tag = exports.ModuleFE_Tag_Class = exports.dispatch_onTagsUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const config_keys_1 = require("./config-keys");
exports.dispatch_onTagsUpdated = new types_1.ThunderDispatcherV3('__onTagsUpdated');
class ModuleFE_Tag_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Tag, exports.dispatch_onTagsUpdated);
        this.__searchFilterMapper = (item) => [item.label];
        this.__searchGroups = (items, filter) => {
            const diseaseViews = config_keys_1.ConfigKeyFE_DiseasesViewsTagId.get();
            const diseaseViewsGroup = { group: 'Disease View', results: [] };
            const tagsGroup = { group: this.dbDef.entityName, results: [] };
            items.map(i => {
                if (i.tagIds.includes(diseaseViews) && (filter === 'DISEASE PROFILES' || !filter)) {
                    diseaseViewsGroup.results.push({ _id: i._id, uiLabel: i.label });
                }
                else if (!filter || filter !== 'DISEASE PROFILES') {
                    tagsGroup.results.push({ _id: i._id, uiLabel: i.label });
                }
            });
            return [diseaseViewsGroup, tagsGroup];
        };
    }
}
exports.ModuleFE_Tag_Class = ModuleFE_Tag_Class;
exports.ModuleFE_Tag = new ModuleFE_Tag_Class();
