"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_ActionData_Order = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@app/styles/frontend");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const _entity_1 = require("../../../../../_entity");
const frontend_2 = require("@app/hcs/frontend");
const ui_components_1 = require("@app/hcs/_entity/variable/frontend/ui-components");
const frontend_3 = require("@app/advisor-content/_entity/advisor-context/frontend");
const ui_components_2 = require("@app/advisor-content/_entity/advisor-context/frontend/ui-components");
const shared_1 = require("@app/advisor-content/_entity/advisor-context/shared");
const RenderKey_Edit = 'edit';
const RenderKey_View = 'view';
class Renderer_ActionData_Order extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        this.getRenderKey = () => {
            return this.state.editMode ? RenderKey_Edit : RenderKey_View;
        };
        this.renderRelatedResource = () => {
            const order = this.state.editable;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.DataGrid.Label, null, "Related Resource"),
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getRenderKey(), modes: [
                        {
                            key: RenderKey_View,
                            renderer: () => {
                                var _a;
                                const resource = frontend_2.ModuleFE_Resource.cache.unique(order.item.resourceId);
                                if (order.item.resourceId && !resource)
                                    throw new ts_common_1.MUSTNeverHappenException(`Could not get resource for resourceId ${order.item.resourceId}`);
                                return React.createElement(frontend_1.DataGrid.Data, null, (_a = resource === null || resource === void 0 ? void 0 : resource.label) !== null && _a !== void 0 ? _a : '-');
                            }
                        },
                        {
                            key: RenderKey_Edit,
                            renderer: () => React.createElement(frontend_2.HCSDropDowns.Resource, { selected: order.item.resourceId, onSelected: async (resource) => {
                                    await this.state.editable.updateObj({
                                        resourceId: resource._id,
                                        variableId: undefined,
                                    });
                                    this.forceUpdate();
                                }, caret: frontend_1.QuaiManager_DropDownCaret })
                        }
                    ] }));
        };
        this.renderRelatedSnippet = () => {
            const order = this.state.editable;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.DataGrid.Label, null, "Related Snippet"),
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getRenderKey(), modes: [
                        {
                            key: RenderKey_View,
                            renderer: () => {
                                var _a;
                                const snippet = frontend_3.ModuleFE_AdvisorContext.cache.unique(order.item.snippetId);
                                return React.createElement(frontend_1.DataGrid.Data, null, (_a = snippet === null || snippet === void 0 ? void 0 : snippet.label) !== null && _a !== void 0 ? _a : '-');
                            }
                        },
                        {
                            key: RenderKey_Edit,
                            renderer: () => React.createElement(ui_components_2.DropDown_AdvisorContext.editable, { editable: order, prop: 'snippetId', sortBy: ['label'], canUnselect: true, limitItems: 50, queryFilter: snippet => snippet.type === shared_1.FormData_Single, onSelected: snippet => {
                                    if (!snippet)
                                        return order.updateObj({ snippetId: undefined });
                                    return order.updateObj({ snippetId: snippet._id });
                                }, caret: frontend_1.QuaiManager_DropDownCaret })
                        }
                    ] }));
        };
        this.renderRelatedVariable = () => {
            const action = this.state.editable;
            const resource = frontend_2.ModuleFE_Resource.cache.unique(action.item.resourceId);
            if (action.item.resourceId && !resource)
                throw new ts_common_1.MUSTNeverHappenException(`could not find a resource for id ${action.item.resourceId}`);
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.DataGrid.Label, null, "HCS Variable"),
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getRenderKey(), modes: [
                        {
                            key: RenderKey_View,
                            renderer: () => {
                                var _a;
                                const variable = frontend_2.ModuleFE_Variable.cache.unique(action.item.variableId);
                                if (action.item.variableId && !variable)
                                    throw new ts_common_1.MUSTNeverHappenException(`Could not get variable for variableId ${action.item.variableId}`);
                                return React.createElement(frontend_1.DataGrid.Data, null, (_a = variable === null || variable === void 0 ? void 0 : variable.name) !== null && _a !== void 0 ? _a : '-');
                            }
                        },
                        {
                            key: RenderKey_Edit,
                            renderer: () => React.createElement(ui_components_1.DropDown_VariableV3WithSynonyms.editable, { editable: action, prop: 'variableId', disabled: !resource, sortBy: ['_nameInLowercase'], queryFilter: variable => (0, ts_common_1.exists)(resource) && variable._tagIds.includes(resource.tagId), caret: frontend_1.QuaiManager_DropDownCaret })
                        }
                    ] }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        if (!_entity_1.ModuleFE_Action.is.order(nextProps.editable.item))
            throw new ts_common_1.BadImplementationException('Passed non order action!');
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(React.Fragment, null,
            this.renderRelatedResource(),
            this.renderRelatedSnippet(),
            this.renderRelatedVariable());
    }
}
exports.Renderer_ActionData_Order = Renderer_ActionData_Order;
