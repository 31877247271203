"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_TagEditor = void 0;
const React = require("react");
require("./Component_TagEditor.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const styles_1 = require("@app/styles");
const app_dropdowns_1 = require("../app-dropdowns");
const consts_1 = require("@app/styles/frontend/ui/consts");
const _entity_1 = require("../../../_entity");
class Component_TagEditor extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderTagGroups = () => {
            var _a;
            return (_a = this.state.tagGroupKeys) === null || _a === void 0 ? void 0 : _a.map(tagGroupKey => {
                const uiTagGroup = this.props.tags.find(tagGroup => tagGroup.key._id === tagGroupKey._id);
                const pair = uiTagGroup !== null && uiTagGroup !== void 0 ? uiTagGroup : {
                    key: tagGroupKey,
                    value: []
                };
                if (!uiTagGroup)
                    this.props.tags.push(pair);
                return React.createElement(frontend_1.LL_V_L, { className: 'tag-group', key: pair.key._id },
                    React.createElement(frontend_1.LL_H_C, { className: 'tag-group__title' },
                        React.createElement("span", null, pair.key.label),
                        !this.state.disableEdit && (!this.props.mandatoryRootTag || this.props.mandatoryRootTag && pair.key._id !== this.state.sourceTagId)
                            && React.createElement(styles_1.ICONS.x.component, { onClick: () => {
                                    //remove tag group
                                    (0, ts_common_1.removeFromArray)(this.props.tags, item => item.key._id === pair.key._id);
                                    (0, ts_common_1.removeFromArray)(this.state.tagGroupKeys, item => item._id === pair.key._id);
                                    this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                                } })),
                    this.renderInnerTags(pair));
            });
        };
        this.renderAddGroupDropDown = () => {
            if (this.state.disableEdit)
                return React.createElement(React.Fragment, null);
            return React.createElement(app_dropdowns_1.HCSDropDowns.Tag, { selected: undefined, onSelected: cat => {
                    var _a;
                    let existingPair = (_a = this.props.tags) === null || _a === void 0 ? void 0 : _a.find(pair => pair.key._id === cat._id);
                    if (!existingPair)
                        existingPair = { key: cat, value: [] };
                    this.props.tags.push(existingPair);
                    this.state.tagGroupKeys.push(cat);
                    this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                }, queryFilter: item => {
                    if (this.props.tags.findIndex(group => group.key._id === item._id) !== -1)
                        return false; // don't allow adding the same group twice
                    if (item.tagIds[0])
                        return false; // don't allow non-root related groups
                    return this.props.groupTagFilter(item);
                }, placeholder: 'New Tag Group', caret: consts_1.DropDown_DefaultCaret, boundingParentSelector: '.ts-tabs__content' });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.disableEdit = nextProps.disableEdit;
        state.groupTagFilter = nextProps.groupTagFilter;
        state.sourceTagId = _entity_1.ConfigKeyFE_SourceTagId.get();
        state.tagGroupKeys = (0, ts_common_1.filterDuplicates)([...nextProps.tags.map(tagGroup => tagGroup.key), ...((_a = state.tagGroupKeys) !== null && _a !== void 0 ? _a : [])], tagGroup => tagGroup._id);
        return state;
    }
    renderTag(tag, pair) {
        return React.createElement("div", { className: 'tag', key: tag._id },
            !this.state.disableEdit && React.createElement(frontend_1.TS_Button, { onClick: () => {
                    pair.value = pair.value.filter(item => item._id !== tag._id);
                    this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                } },
                React.createElement(styles_1.ICONS.x.component, null)),
            React.createElement("span", null, tag.label));
    }
    renderInnerTags(pair) {
        const tagDDClassName = (0, frontend_1._className)(!this.props.hideDropdownDesign && 'tag-dropdown');
        return React.createElement(frontend_1.LL_H_C, { className: 'tags' },
            pair.value.map(tag => this.renderTag(tag, pair)),
            !this.state.disableEdit &&
                React.createElement(app_dropdowns_1.HCSDropDowns.Tag, { className: tagDDClassName, selected: undefined, onSelected: item => {
                        pair.value.push(item);
                        this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                    }, placeholder: 'New Tag', onNoMatchingSelectionForString: async (label) => {
                        const tag = _entity_1.ModuleFE_Tag.cache.find(t => t.label === label);
                        if (tag)
                            return this.logWarning('Tag with label already exists: ', tag);
                        const newTag = await _entity_1.ModuleFE_Tag.v1.upsert({ label, tagIds: [pair.key._id] }).executeSync();
                        pair.value.push(newTag);
                        this.forceUpdate(() => { var _a, _b; return (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a); });
                    }, queryFilter: item => {
                        if (pair.value.findIndex(val => val._id === item._id) !== -1)
                            return false; // don't allow adding the same tag twice
                        return this.props.singleTagFilter(item, pair.key);
                    }, caret: consts_1.DropDown_DefaultCaret, boundingParentSelector: '.ts-tabs__content' }));
    }
    render() {
        const className = (0, frontend_1._className)('tags-section', this.state.disableEdit && 'edit-disabled');
        return (React.createElement(frontend_1.LL_V_L, { className: className },
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__field-content' },
                this.renderTagGroups(),
                this.renderAddGroupDropDown())));
    }
}
exports.Component_TagEditor = Component_TagEditor;
