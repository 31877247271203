"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchItem_MacroTag = void 0;
const React = require("react");
const frontend_1 = require("@app/styles/frontend");
const _entity_1 = require("../../_entity");
const healthcare_space_1 = require("../healthcare-space");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
const Component_MacroTagEditor_1 = require("./Component_MacroTagEditor/Component_MacroTagEditor");
exports.SearchItem_MacroTag = {
    route: healthcare_space_1.Component_HealthcareSpace.Route,
    module: _entity_1.ModuleFE_MacroTag,
    type: _entity_1.ModuleFE_MacroTag.dbDef.entityName,
    entityName: _entity_1.ModuleFE_MacroTag.dbDef.dbKey,
    groupId: ['Healthcare Space'],
    tags: [],
    mapper: macro => [macro.label],
    entityLabel: 'Macro Tag',
    renderer: (macro, selected, onClickCallback) => {
        return React.createElement(frontend_1.Renderer_SearchResult, { itemId: macro._id, itemKey: _entity_1.ModuleFE_MacroTag.dbDef.entityName, entityLabel: 'Macro Tag', itemLabel: macro.label, hierarchy: 'Healthcare Space', selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (macroId) => {
        const macro = _entity_1.ModuleFE_MacroTag.cache.unique(macroId);
        if (!macro)
            throw new ts_common_1.MUSTNeverHappenException(`Could not get macro for id ${macroId}`);
        const editable = new frontend_2.EditableDBItemV3(macro, _entity_1.ModuleFE_MacroTag);
        return React.createElement(Component_MacroTagEditor_1.Component_MacroTagEditor, { editable: editable, editMode: false });
    }
};
