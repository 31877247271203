"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_ExpressionSidePanel = exports.UtilsTab_PyJsonPreview = exports.UtilsTab_PrettyPrint = exports.UtilsTab_JsonPreview = exports.UtilsTab_CalculationSimulator = exports.UtilsTab_ExpressionDescription = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Panel_ExpressionSidePanel.scss");
const Component_ExpressionDescription_1 = require("./expression-description/Component_ExpressionDescription");
const Component_JSONPreview_1 = require("./expression-json-preview/Component_JSONPreview");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const Component_PyJsonPreview_1 = require("./py-json-preview/Component_PyJsonPreview");
const Component_ExpressionPrettyPrinter_1 = require("../../../expression-pretty-print/Component_ExpressionPrettyPrinter");
exports.UtilsTab_ExpressionDescription = 'Description';
exports.UtilsTab_CalculationSimulator = 'Simulation';
exports.UtilsTab_JsonPreview = 'JSON';
exports.UtilsTab_PrettyPrint = 'Pretty Print';
exports.UtilsTab_PyJsonPreview = 'PY-JSON';
class Panel_ExpressionSidePanel extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.editable)
            return true;
        return super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
    shouldReDeriveState(nextProps) {
        if (!nextProps.editable)
            return true;
        return super.shouldReDeriveState(nextProps);
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c, _d;
        state = nextProps.editable ? super.deriveStateFromProps(nextProps, state) : Object.assign({}, this.state);
        (_a = state.selectedTab) !== null && _a !== void 0 ? _a : (state.selectedTab = (_d = (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.selectedTab) !== null && _c !== void 0 ? _c : nextProps.defaultTab) !== null && _d !== void 0 ? _d : exports.UtilsTab_ExpressionDescription);
        return state;
    }
    render() {
        var _a;
        const tabs = [
            ...((_a = this.props.additionalTabs) !== null && _a !== void 0 ? _a : []),
            {
                uid: exports.UtilsTab_ExpressionDescription,
                disabled: !this.state.editable,
                content: () => React.createElement(Component_ExpressionDescription_1.Component_ExpressionDescription, { editable: this.state.editable }),
                title: exports.UtilsTab_ExpressionDescription,
            },
            {
                uid: exports.UtilsTab_JsonPreview,
                title: exports.UtilsTab_JsonPreview,
                disabled: !this.state.editable,
                content: () => React.createElement(Component_JSONPreview_1.Component_JSONPreview, { editable: this.state.editable }),
            },
            {
                uid: exports.UtilsTab_PyJsonPreview,
                title: exports.UtilsTab_PyJsonPreview,
                disabled: !this.state.editable,
                content: () => React.createElement(Component_PyJsonPreview_1.Component_PyJsonPreview, { editable: this.state.editable })
            },
            {
                uid: exports.UtilsTab_PrettyPrint,
                title: exports.UtilsTab_PrettyPrint,
                disabled: !this.state.editable,
                content: () => React.createElement(Component_ExpressionPrettyPrinter_1.Component_ExpressionPrettyPrinter, { showHeader: true, editable: this.state.editable })
            },
        ];
        return React.createElement(frontend_1.LL_V_L, { className: 'utils-panel' },
            React.createElement(frontend_1.TS_Tabs, { selectedTabId: this.state.selectedTab, onSelected: (selectedTabId) => this.setState({ selectedTab: selectedTabId }), tabs: tabs }));
    }
}
exports.Panel_ExpressionSidePanel = Panel_ExpressionSidePanel;
