"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_RelationsTable.scss");
const RenderLeftSide_1 = require("./componnents/RenderLeftSide/RenderLeftSide");
const RenderRIghtSide_1 = require("./componnents/RenderRightSide/RenderRIghtSide");
const RenderEditColumn_1 = require("./componnents/RenderEditColumn/RenderEditColumn");
const useForceUpdate_1 = require("./hooks/useForceUpdate");
const shared_components_1 = require("../../../../shared-components");
const styles_1 = require("@app/styles");
const q_components_1 = require("../../../../q-components");
const __1 = require("../../../../..");
const TABLE_HEADERS = [
    'Selected Variable Values',
    'Relation',
    'Variable To Compare'
];
function Component_RelationsTable(props) {
    const [tableData, setTableData] = React.useState();
    const [tableRows, setTableRows] = React.useState([{}]);
    const [isFetching, setFetching] = React.useState(true);
    const forceUpdate = (0, useForceUpdate_1.default)();
    React.useEffect(() => {
        const updatedDataFromCache = __1.ModuleFE_VariableRelation.cache
            .filter(row => (row._firstVariableId === props.variable._id))
            .sort((a, b) => {
            return Number(a.__created) - Number(b.__created);
        });
        if (!tableData) {
            setTableData(updatedDataFromCache.length > 0 ? structuredClone(updatedDataFromCache)
                : [initiateDB_VariableRelations()]);
        }
        if (updatedDataFromCache.length > 0) {
            const rowRepresentation = [];
            updatedDataFromCache.map(item => rowRepresentation.push({}));
            setTableRows(rowRepresentation);
        }
        setFetching(false);
    }, []);
    const initiateDB_VariableRelations = () => {
        return {
            _firstVariableId: props.variable._id,
            leftSide: [newSideElement(true)],
            relation: '',
            rightSide: [newSideElement()]
        };
    };
    const newSideElement = (isLeftSide) => {
        return {
            variableId: isLeftSide ? props.variable._id : undefined,
            valueId: undefined
        };
    };
    const addRow = () => {
        setTableData(prevState => {
            prevState.push(initiateDB_VariableRelations());
            return prevState;
        });
        setTableRows((prev) => [...prev, {}]);
    };
    const onChangeVariableToCompare = (tableIndex, inputIndex, variable) => {
        setTableData((prev) => {
            prev[tableIndex].rightSide[inputIndex].variableId = variable._id;
            return prev;
        });
        setTableRows([...tableRows]);
    };
    const onChangeValueToCompare = (tableIndex, inputIndex, value) => {
        setTableData((prev) => {
            prev[tableIndex].rightSide[inputIndex].valueId = value._id;
            return prev;
        });
        setTableRows([...tableRows]);
    };
    const onChangeVariableValue = (tableIndex, inputIndex, value) => {
        setTableData((prev) => {
            prev[tableIndex].leftSide[inputIndex].valueId = value._id;
            return prev;
        });
        setTableRows([...tableRows]);
    };
    const onSaveRow = (newRow, index) => {
        setTableData(prevState => {
            prevState[index]._id = newRow._id;
            return prevState;
        });
        setTableRows([...tableRows]);
    };
    const onSelectRelation = (selectedRelation, index) => {
        setTableData(prevState => {
            prevState[index].relation = selectedRelation;
            return prevState;
        });
        setTableRows([...tableRows]);
    };
    const removeARow = (index) => {
        const dbTable = __1.ModuleFE_VariableRelation.cache
            .filter(item => item._firstVariableId === props.variable._id);
        setTableRows(prevState => {
            prevState.splice(index, 1);
            return prevState;
        });
        setTableData((prevState) => {
            prevState.splice(index, 1);
            if (dbTable && dbTable[index]) {
                (0, frontend_1.performAction)(async () => {
                    await __1.ModuleFE_VariableRelation.v1.deleteQuery({ where: { _id: dbTable[index]._id } }).executeSync();
                }, {
                    type: 'toast',
                    successContent: React.createElement(q_components_1.QToastContent, { content: 'Table updated successfully', iconKey: 'v', toastType: 'success' }),
                });
            }
            return prevState;
        });
        forceUpdate();
    };
    const getTableHeaders = () => {
        return [...TABLE_HEADERS.map(i => {
                if (i === 'Variable To Compare')
                    return { header: i, widthPx: 200 };
                return { header: i, widthPx: 100 };
            }), { header: '', widthPx: 50 }];
    };
    if (isFetching)
        return React.createElement(frontend_1.LL_H_C, { style: { width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' } },
            React.createElement(frontend_1.TS_Loader, null));
    return React.createElement(frontend_1.LL_V_L, { id: 'relations-tab-content' },
        React.createElement(frontend_1.TS_Button, { id: 'add-relation-button', onClick: addRow },
            React.createElement(styles_1.ICONS.advisorAddPlus.component, null),
            React.createElement("span", null, "Add Relation")),
        React.createElement(q_components_1.QScrollWrapper, { id: 'relations-table-wrapper' },
            React.createElement(frontend_1.TS_Table, { rows: tableRows, header: getTableHeaders(), headerRenderer: item => React.createElement("div", null, item), cellRenderer: (prop, item, index) => {
                    var _a, _b;
                    switch (prop) {
                        case 'Selected Variable Values':
                            return React.createElement(RenderLeftSide_1.default, { variable: props.variable, setTableData: setTableData, index: index, initialValue: props.value, item: tableData && tableData[index].leftSide, onChangeVariableValue: onChangeVariableValue });
                        case 'Relation':
                            return React.createElement(shared_components_1.DropDown_Relations, { index: index, relation: (tableData && tableData[index].relation.length > 0)
                                    ? tableData[index].relation : undefined, onSelectRelation: onSelectRelation });
                        case 'Variable To Compare':
                            return React.createElement(RenderRIghtSide_1.default, { setTableData: setTableData, variable: props.variable, index: index, item: tableData && tableData[index].rightSide, onChangeVariableToCompare: onChangeVariableToCompare, onChangeValueToCompare: onChangeValueToCompare });
                        default:
                            if (['Relieving Factor', 'Precipitating Factor'].includes((_b = (_a = (tableData === null || tableData === void 0 ? void 0 : tableData[index])) === null || _a === void 0 ? void 0 : _a.relation) !== null && _b !== void 0 ? _b : ''))
                                return '';
                            return React.createElement(RenderEditColumn_1.default, { row: tableData && tableData[index], variable: props.variable, onSaveRow: onSaveRow, removeARow: removeARow, index: index });
                    }
                } })));
}
exports.default = Component_RelationsTable;
