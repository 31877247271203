"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertTermLevel = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const generic_conversion_1 = require("./evaluation-logics/generic-conversion");
const convertAtomLevel_1 = require("./convertAtomLevel");
const consts_1 = require("./consts");
const set_1 = require("./evaluation-logics/set");
const range_1 = require("./evaluation-logics/range");
const not_equal_1 = require("./evaluation-logics/not-equal");
const exists_1 = require("./evaluation-logics/exists");
const consts_2 = require("../../../../_entity/expression/shared/consts");
/**
 * Converts an array of terms to their corresponding AI team schema representation within the converted expression.
 * @param terms - An array of database terms to be converted.
 * @param convertedExpression - The converted expression to which the terms will be added.
 * @param dependencies - db dependencies mapper
 */
const convertTermLevel = (terms, convertedExpression, dependencies) => {
    convertedExpression.terms = terms.reduce((convertedTerms, currentTerm, currentIndex) => {
        const schemaTerm = {};
        schemaTerm.atoms = {};
        // Add comment if it exists
        if ((0, ts_common_1.exists)(currentTerm.comment))
            schemaTerm.comment = currentTerm.comment;
        // Set term weight to null because of no actual example of a usage
        schemaTerm.term_weight = null;
        schemaTerm.evaluation_logic = composeEvaluationLogic(currentTerm, dependencies);
        convertedTerms[`T${currentIndex + 1}`] = schemaTerm;
        return convertedTerms;
    }, convertedExpression.terms);
};
exports.convertTermLevel = convertTermLevel;
const isTermWithSubTerms = (term) => term.subTerms !== undefined;
/**
 * Composes the evaluation logic for a term.
 * @param dbTerm - The database term to be converted.
 * @param dependencies - Dependencies mapper
 * @returns The basic expression representing the evaluation logic of the term.
 */
const composeEvaluationLogic = (dbTerm, dependencies) => {
    if (isTermWithSubTerms(dbTerm))
        return resolveSubTermConversion(dbTerm, dependencies);
    if (dbTerm.value || dbTerm.atomsLogic === consts_2.LogicType_StructuredFreeText)
        return resolveSimpleExpressionConversion(dbTerm);
    else
        return resolveSimpleExpression(dbTerm);
};
const resolveSimpleExpressionOutputType = (dbTerm) => {
    let outputType = 'boolean';
    if (dbTerm.atomsLogic === consts_2.LogicType_Add)
        outputType = 'number';
    const firstAtom = dbTerm.atoms[0];
    if (dbTerm.atoms.length === 1 && firstAtom.cases.every(_case => (0, ts_common_1.exists)(_case.value))) {
        if (firstAtom.cases.every(_case => (0, generic_conversion_1.getReturnValueType)(_case.value) === 'number'))
            outputType = 'number';
        if (firstAtom.cases.every(_case => (0, generic_conversion_1.getReturnValueType)(_case.value) === 'tuple'))
            outputType = 'tuple';
    }
    const firstCase = firstAtom.cases[0];
    if (dbTerm.atoms.length === 1 && firstCase.caseOperator === consts_2.LogicType_Self) {
        if (firstCase.valueType === 'boolean' || firstCase.valueType === 'enum')
            outputType = 'string';
        if (firstCase.valueType === 'numeric')
            outputType = 'number';
    }
    return outputType;
};
const resolveSimpleExpression = (dbTerm) => {
    const simpleExpr = {};
    simpleExpr.type = 'simple-expression';
    simpleExpr.output_type = resolveSimpleExpressionOutputType(dbTerm);
    simpleExpr.expression = dbTerm.expression;
    return simpleExpr;
};
const resolveSimpleExpressionConversion = (dbTerm) => {
    var _a, _b, _c, _d, _e, _f;
    const simpleExprConversion = {};
    simpleExprConversion.type = 'simple-expression-conversion';
    // Handle free structured text conversion
    if (dbTerm.atomsLogic === consts_2.LogicType_StructuredFreeText) {
        if (!((_a = dbTerm.structuredFreeText) === null || _a === void 0 ? void 0 : _a.if) || !((_b = dbTerm.structuredFreeText) === null || _b === void 0 ? void 0 : _b.then) || !((_c = dbTerm.structuredFreeText) === null || _c === void 0 ? void 0 : _c.else))
            throw new ts_common_1.BadImplementationException('cannot convert to simple expression conversion with missing values');
        simpleExprConversion.expression = (_d = dbTerm.structuredFreeText) === null || _d === void 0 ? void 0 : _d.if;
        simpleExprConversion.return_value = (_e = dbTerm.structuredFreeText) === null || _e === void 0 ? void 0 : _e.then;
        simpleExprConversion.else_value = (_f = dbTerm.structuredFreeText) === null || _f === void 0 ? void 0 : _f.else;
        switch (dbTerm.outputType) {
            case 'numeric':
                simpleExprConversion.output_type = 'number';
                break;
            case 'range':
                simpleExprConversion.output_type = 'tuple';
                break;
            default:
                simpleExprConversion.output_type = dbTerm.outputType;
                break;
        }
    }
    else {
        // Handle term value conversion
        simpleExprConversion.expression = dbTerm.expression;
        simpleExprConversion.output_type = (0, generic_conversion_1.getReturnValueType)(dbTerm.value);
        simpleExprConversion.return_value = (0, generic_conversion_1.getReturnValueType)(dbTerm.value) === 'tuple' ? dbTerm.value : dbTerm.value[0];
        simpleExprConversion.else_value = (0, generic_conversion_1.getReturnValueType)(dbTerm.fallbackValue) === 'tuple' ? dbTerm.fallbackValue : dbTerm.fallbackValue[0];
    }
    return simpleExprConversion;
};
const resolveSubTermConversion = (dbTerm, dependencies) => {
    const subTermConversion = {};
    subTermConversion.type = 'sub-terms-conversion';
    const isTuple = dbTerm.subTerms.every(subTerm => (0, generic_conversion_1.getReturnValueType)(subTerm.value) === 'tuple');
    subTermConversion.output_type = isTuple ? 'tuple' : 'number';
    subTermConversion.else_value = isTuple ? dbTerm.fallbackValue : dbTerm.fallbackValue[0];
    subTermConversion.options = resolveSubTermOptions(dbTerm.subTerms, dependencies);
    return subTermConversion;
};
const resolveSubTermOptions = (subTerms, dependencies) => {
    return subTerms.map(dbSubTerm => {
        // Create basic meta data for schema sub term
        const schemaSubTerm = {
            type: 'sub-term',
            return_value: (0, generic_conversion_1.getReturnValueType)(dbSubTerm.value) === 'tuple' ? dbSubTerm.value : dbSubTerm.value[0]
        };
        //
        schemaSubTerm.atoms_conditions = dbSubTerm.atoms.map(atom => {
            var _a;
            if (!atom.leftHand)
                throw new ts_common_1.BadImplementationException('cannot use empty atom in expression');
            const convertedSubTerm = { atom_var: `A${(_a = atom.leftHand) === null || _a === void 0 ? void 0 : _a.id}` };
            convertedSubTerm.evaluation_logic = resolveAtomWithVarEvalLogic(atom, dependencies);
            if (atom) {
                return convertedSubTerm;
            }
        });
        return schemaSubTerm;
    });
};
const resolveAtomWithVarEvalLogic = (dbAtom, dependencies) => {
    const evaluationLogicType = (0, convertAtomLevel_1.getEvaluationLogicType)(dbAtom, dependencies);
    switch (evaluationLogicType) {
        case consts_1.EvaluationLogicTypes.set:
            return (0, set_1.resolveInSet)(dbAtom, dependencies);
        case consts_1.EvaluationLogicTypes.range:
            return (0, range_1.resolveRangeEvaluationLogic)(dbAtom, dependencies);
        case consts_1.EvaluationLogicTypes.notEqual:
            return (0, not_equal_1.resolveNotEqual)(dbAtom, dependencies);
        case consts_1.EvaluationLogicTypes.exists:
            return (0, exists_1.resolveExists)();
    }
    throw new ts_common_1.BadImplementationException(`could not resolve eval logic for db atom ${(0, ts_common_1.__stringify)(dbAtom, true)}`);
};
