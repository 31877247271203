"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Metadata_Prognosis = exports.initialPrognosis = exports.PrognosisRateTypeLabelMap = exports.PrognosisValueLabelMap = void 0;
const types_1 = require("./types");
const shared_1 = require("../../population/shared");
const types_2 = require("@app/hcs/_enum/timespan/shared/types");
exports.PrognosisValueLabelMap = {
    [types_1.PrognosisValue_00]: 'Poor',
    [types_1.PrognosisValue_10]: 'Moderate',
    [types_1.PrognosisValue_20]: 'Good',
    [types_1.PrognosisValue_30]: 'Excellent',
};
exports.PrognosisRateTypeLabelMap = {
    [types_1.PrognosisRateType_Survival]: 'Survival',
    [types_1.PrognosisRateType_Mortality]: 'Mortality',
    [types_1.PrognosisRateType_Qualitative]: 'Qualitative',
};
exports.initialPrognosis = Object.freeze({
    rate: {
        type: types_1.PrognosisRateType_Survival,
        value: [0, 0],
    },
    timePeriod: {
        type: types_2.TimespanType_Day,
        value: 0,
    },
    population: {
        type: shared_1.PopulationType_General,
    }
});
exports.Metadata_Prognosis = {
    rate: {
        optional: false,
        valueType: 'object',
        description: 'Rate of the prognosis',
        metadata: {
            type: {
                optional: false,
                valueType: 'string',
                description: 'rate type'
            },
            value: {
                optional: false,
                description: 'rate value',
                valueType: 'string' || 'array',
            }
        }
    },
    timePeriod: {
        optional: false,
        description: 'time period of the prognosis',
        valueType: 'object',
        metadata: {
            type: {
                optional: false,
                description: 'a time period',
                valueType: 'string',
            },
            value: {
                optional: false,
                description: 'numeric value to describe the amount of the time period',
                valueType: 'number',
            }
        }
    },
    // @ts-ignore
    population: shared_1.Metadata_Population,
};
