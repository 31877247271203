"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Presenter_GraphViewWrapper = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Pathway_GraphView_1 = require("../../Pathway_GraphView/Pathway_GraphView");
const Panel_HcsVariables_1 = require("../../Component_ResolutionFunctionEditor/panels/hcs-variables/Panel_HcsVariables");
class Presenter_GraphViewWrapper extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderGraphView = () => {
            if (!this.props.pathwayId)
                return React.createElement(React.Fragment, null);
            return React.createElement(Pathway_GraphView_1.Pathway_GraphView, { pathwayId: this.props.pathwayId, editablePathwayState: this.props.editablePathwayState, editablePathwayTransition: this.props.editablePathwayTransition });
        };
        this.renderHcsVariablesPanel = () => {
            var _a;
            if (!((_a = this.state.panelOptions) === null || _a === void 0 ? void 0 : _a.resolutionFunctions))
                return '';
            return React.createElement(Panel_HcsVariables_1.Panel_HcsVariables, { hidePanel: () => this.setState({ panelOptions: undefined }), resolutionFunctionItems: this.state.panelOptions.resolutionFunctions });
        };
    }
    __showResolutionFunctionEditorPanel(options) {
        if (options.resolutionFunctions)
            return this.setState({ panelOptions: { resolutionFunctions: options.resolutionFunctions } });
        if (options.validationItems)
            return this.setState({ panelOptions: { validationItems: options.validationItems } });
        if (options.prettyPrint)
            return this.setState({ panelOptions: { prettyPrint: options.prettyPrint } });
        if (options.editDescription)
            return this.setState({ panelOptions: { editDescription: options.editDescription } });
        this.setState({ panelOptions: undefined });
    }
    deriveStateFromProps(nextProps, state) {
        state.editablePathwayState = nextProps.editablePathwayState;
        state.pathwayId = nextProps.pathwayId;
        state.editablePathwayTransition = nextProps.editablePathwayTransition;
        return state;
    }
    render() {
        return React.createElement(React.Fragment, null,
            this.renderGraphView(),
            this.renderHcsVariablesPanel());
    }
}
exports.Presenter_GraphViewWrapper = Presenter_GraphViewWrapper;
