"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_SearchAndResults = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Component_SearchAndResults.scss");
const search_1 = require("../../modules/search");
const icons_1 = require("../../../icons");
const ActionsMenu_BaseManagerUI_1 = require("../../ui-manager/headers/ActionsMenu/ActionsMenu_BaseManagerUI");
const consts_1 = require("./consts");
class Component_SearchAndResults extends frontend_1.ProtoComponent {
    // ######################### Life Cycle #########################
    constructor(p) {
        super(p);
        // ######################### Logic #########################
        this.onSearch = (searchTerm, immediate = false) => {
            this.setState({ searchTerm }, () => {
                clearTimeout(this.debounceTimeout);
                if (immediate)
                    return this.onSearchImpl();
                this.debounceTimeout = setTimeout(() => this.onSearchImpl(), 500);
            });
        };
        this.onSearchImpl = async () => {
            const searchTerm = this.state.searchTerm;
            if (!(0, ts_common_1.exists)(searchTerm) || (searchTerm.length >= 1 && searchTerm.length < 3))
                return [];
            const start = performance.now();
            // filter on which module we perform the search on
            const toSearchIn = this.props.searchItems.find(i => i.type === this.state.searchFilters.searchItemKey);
            if (!toSearchIn)
                return;
            search_1.ModuleFE_SearchV2.query([toSearchIn], searchTerm)
                .then((results) => {
                this.setState({ results });
            }).catch((err) => {
                this.logError(err);
                this.setState({ err });
            });
            const elapsed = performance.now() - start;
            this.setState({ searchTime: (elapsed !== 0 && searchTerm.length) ? elapsed : undefined });
        };
        this.getResultAmount = () => {
            return this.state.results.reduce((acc, curr) => {
                return acc + curr.results.length;
            }, 0);
        };
        this.onSearchClear = () => {
            this.setState({
                searchTerm: '',
                results: []
            }, () => {
                this.onFiltersUpdated();
                this.onSearch('', true);
            });
        };
        this.onSearchResultClick = (resultId, resultKey) => {
            this.props.onSearchItemSelected({ type: resultKey, id: resultId });
        };
        this.onFiltersUpdated = () => {
            const query = {};
            query.searchTerm = this.state.searchTerm ? this.state.searchTerm : undefined;
            query.searchGroup = this.state.searchFilters.selectedSearchGroup ? this.state.searchFilters.selectedSearchGroup : undefined;
            query.searchItem = this.state.searchFilters.searchItemKey ? this.state.searchFilters.searchItemKey : undefined;
            this.setQueryParams(query);
        };
        this.updateSelectedSort = (selectedSort) => {
            this.setState({ selectedSort: selectedSort }, () => {
                consts_1.storageKey_SearchAndResult_SelectedSort.set(selectedSort);
            });
        };
        this.renderSearchTimer = () => {
            return React.createElement("span", { className: 'search-timer' }, this.state.searchTime ? `${this.state.searchTime.toFixed(3)} ms` : ' ');
        };
        this.renderActionMenu = () => {
            if (!this.state.results.length)
                return '';
            const sortMenuItems = [
                {
                    label: this.renderActionMenuTitle(consts_1.sortType_RecentlyAdded.label),
                    action: () => this.updateSelectedSort(consts_1.sortType_RecentlyAdded)
                },
                {
                    label: this.renderActionMenuTitle(consts_1.sortType_Ascending.label),
                    action: () => this.updateSelectedSort(consts_1.sortType_Ascending)
                },
                {
                    label: this.renderActionMenuTitle(consts_1.sortType_Descending.label),
                    action: () => this.updateSelectedSort(consts_1.sortType_Descending)
                }
            ];
            return React.createElement(frontend_1.LL_H_C, Object.assign({}, frontend_1.openContent.popUp.bottom('search-and-results__action-list', (refresh) => React.createElement(ActionsMenu_BaseManagerUI_1.ActionsMenu_BaseManagerUI, { actions: sortMenuItems })), { className: 'search-and-results__sort-list' }),
                React.createElement(icons_1.ICONS.newSort.component, { className: 'sort-icon' }),
                React.createElement("div", { className: 'search-and-results__sort-list-title' }, "Sort List"));
        };
        this.renderActionMenuTitle = (title) => {
            const isSelected = title === this.state.selectedSort.label;
            return React.createElement(frontend_1.LL_H_C, { className: 'match_parent flex__space-between' },
                title,
                isSelected && React.createElement(icons_1.ICONS.v.component, null));
        };
        this.renderResultCount = () => {
            const resultAmount = this.getResultAmount();
            if (!resultAmount)
                return;
            return React.createElement("div", { className: 'search-results-amount' },
                resultAmount,
                " Results");
        };
        this.renderSearchAndFilters = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'search-area' },
                this.renderSearchTimer(),
                this.renderSearchBox(),
                this.renderFilters(),
                React.createElement(frontend_1.LL_H_C, { className: 'match_width search-area__utils-bar' },
                    this.renderActionMenu(),
                    this.renderResultCount()));
        };
        // ######################### Render - Results #########################
        this.renderResults = () => {
            var _a, _b;
            const selectedSort = this.state.selectedSort;
            const itemsToRender = (0, ts_common_1.flatArray)(this.state.results.map(resultGroup => {
                const searchItem = this.props.searchItems.find(item => item.type === resultGroup.type);
                if (!searchItem)
                    return [];
                const sortedResults = (0, ts_common_1.sortArray)(resultGroup.results, (item) => {
                    if (selectedSort.objectSortingProp && selectedSort.objectSortingProp.some(_prop => !!item[_prop])) {
                        const foundKey = selectedSort.objectSortingProp.find(_prop => !!item[_prop]);
                        if (typeof item[foundKey] === 'string')
                            return item[foundKey].trim().toLowerCase();
                        return item[foundKey];
                    }
                    const mapperValues = searchItem.mapper(item).map(_item => _item.toLowerCase());
                    //if mapper returns one value return it
                    if (mapperValues.length === 1)
                        return mapperValues[0];
                    // if not use the same sort logic to return the strongest value in the string array (the one fitting the most of what we look for)
                    return (0, ts_common_1.sortArray)(mapperValues, i => i, selectedSort.invert);
                }, selectedSort.invert);
                this.logInfo(sortedResults.map(item => item.label));
                return sortedResults.map(result => {
                    var _a;
                    return searchItem.renderer(result, result._id === ((_a = this.state.selectedItem) === null || _a === void 0 ? void 0 : _a.id), () => this.onSearchResultClick(result._id, searchItem.type));
                });
            }));
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'search-result-list-container', ref: this.state.listContainerRef },
                    React.createElement(frontend_1.VirtualizedList, { className: 'search-results', listToRender: itemsToRender, itemHeight: 110, height: (_b = (_a = this.state.listContainerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) !== null && _b !== void 0 ? _b : 0 })));
        };
        //Start listening on relevant modules
        this.props.searchItems.forEach(searchItem => {
            const module = searchItem.module;
            //@ts-ignore
            this[module.defaultDispatcher.method] = (...params) => {
                this.onSearch(this.state.searchTerm, true);
            };
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //Stop listening on old modules
        this.props.searchItems.forEach(searchItem => {
            const module = searchItem.module;
            // @ts-ignore
            delete this[module.defaultDispatcher.method];
        });
        super.UNSAFE_componentWillReceiveProps(nextProps);
        //Start listening on relevant modules
        nextProps.searchItems.forEach(searchItem => {
            const module = searchItem.module;
            // @ts-ignore
            this[module.defaultDispatcher.method] = async (...params) => {
                this.onSearch(this.state.searchTerm, true);
            };
        });
    }
    deriveStateFromProps(nextProps, _state) {
        var _a, _b, _c, _d;
        _state = super.deriveStateFromProps(nextProps, _state);
        (_a = _state.results) !== null && _a !== void 0 ? _a : (_state.results = []);
        (_b = _state.listContainerRef) !== null && _b !== void 0 ? _b : (_state.listContainerRef = React.createRef());
        _state.selectedItem = nextProps.selectedItem;
        _state.selectedSort = consts_1.storageKey_SearchAndResult_SelectedSort.get(consts_1.sortType_Ascending);
        //Get initial search term from url param
        (_c = _state.searchTerm) !== null && _c !== void 0 ? _c : (_state.searchTerm = this.getQueryParam('searchTerm', ''));
        //If showing single search item, set it as selection in filter
        if (nextProps.searchItems.length === 1) {
            _state.searchFilters = {
                searchItemKey: nextProps.searchItems[0].type,
                selectedSearchGroup: nextProps.searchItems[0].groupId[0],
            };
        }
        else { //Not showing a single search item, get the selected group and item from url params
            (_d = _state.searchFilters) !== null && _d !== void 0 ? _d : (_state.searchFilters = {
                selectedSearchGroup: this.getQueryParam('searchGroup', ''),
                searchItemKey: this.getQueryParam('searchItem', ''),
            });
            if (_state.selectedItem && !_state.searchFilters.selectedSearchGroup) {
                const searchItem = nextProps.searchItems.find(searchItem => { var _a; return searchItem.type === ((_a = _state.selectedItem) === null || _a === void 0 ? void 0 : _a.type); });
                if (!searchItem)
                    throw new ts_common_1.MUSTNeverHappenException(`search item for type ${_state.selectedItem.type} not found`);
                _state.searchFilters.selectedSearchGroup = searchItem.groupId[0];
            }
        }
        return _state;
    }
    componentDidMount() {
        this.onSearch(this.state.searchTerm, true);
        this.onFiltersUpdated();
    }
    // ######################### Render - Search #########################
    renderClear() {
        return '';
        // return <span onClick={() => this.onSearch('')}>CLEAR</span>;
    }
    renderSearchBox() {
        return React.createElement(frontend_1.LL_H_C, { className: 'search-and-results__search' },
            React.createElement(frontend_1.TS_Input, { type: 'text', placeholder: 'Search', value: this.state.searchTerm, onChange: value => this.onSearch(value), onBlur: () => this.onFiltersUpdated() }),
            React.createElement(icons_1.ICONS.search.component, { className: 'search-and-results__search__glass' }),
            React.createElement(icons_1.ICONS.x.component, { className: 'search-and-results__search__clear', onClick: this.onSearchClear }));
    }
    renderFilters() {
        if (this.props.searchItems.length === 1)
            return;
        return (React.createElement(frontend_1.LL_V_L, { className: 'search-filters' },
            this.renderClear(),
            this.renderEntityFilters()));
    }
    renderEntityFilters() {
        const groups = this.props.searchItems.reduce((acc, curr) => {
            curr.groupId.forEach(id => {
                if (!acc.includes(id))
                    acc.push(id);
            });
            return acc;
        }, []);
        const searchItems = this.props.searchItems.filter(item => item.groupId.includes(this.state.searchFilters.selectedSearchGroup));
        return React.createElement(frontend_1.LL_V_L, null,
            React.createElement(frontend_1.LL_H_C, { className: 'search-filters__by-search-groups' }, groups.map(group => {
                const className = (0, frontend_1._className)('search-filters__by-search-groups__item', this.state.searchFilters.selectedSearchGroup === group && 'selected');
                return React.createElement("div", { key: group, onClick: () => {
                        this.setState({
                            searchFilters: { selectedSearchGroup: group, searchItemKey: '' },
                            results: [],
                        }, () => this.onFiltersUpdated());
                    }, className: className }, group);
            })),
            React.createElement(frontend_1.LL_H_C, { className: 'search-filters__by-search-item' }, searchItems.map(searchItem => {
                const className = (0, frontend_1._className)('search-filters__by-search-item__item', searchItem.type === this.state.searchFilters.searchItemKey && 'selected');
                return React.createElement("div", { key: searchItem.type, className: className, onClick: () => {
                        this.setState({
                            searchFilters: Object.assign(Object.assign({}, this.state.searchFilters), { searchItemKey: searchItem.type })
                        }, () => {
                            this.onFiltersUpdated();
                            this.onSearch(this.state.searchTerm);
                        });
                    } }, searchItem.entityLabel);
            })));
    }
    // ######################### Render #########################
    render() {
        return (React.createElement(frontend_1.LL_V_L, { className: 'search-and-results' },
            this.renderSearchAndFilters(),
            this.renderResults()));
    }
}
// ######################### Properties #########################
Component_SearchAndResults.defaultProps = {
    keys: ['searchGroup', 'searchTerm', 'searchItem']
};
exports.Component_SearchAndResults = Component_SearchAndResults;
