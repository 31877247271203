"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultExpressionOptions = exports.ExpressionCore_FunctionalOptions = exports.ExpressionCore_BooleanOptions = exports.ExpressionCore_BasicMathOptions = exports.AllOperands = exports.BasicMatchOperators = exports.ExpressionCore_BasicMathValidation = exports.ExpressionCore_BasicMathPrettyPrinting = exports.ExpressionsCore_BasicMath = exports.ExpressionCore_BooleanValidation = exports.BooleanOperators = exports.ExpressionCore_BooleanPrettyPrinting = exports.ExpressionCore_BooleanActions = exports.FunctionalOperators = exports.ExpressionCore_FunctionalValidation = exports.ExpressionCore_FunctionalPrettyPrinting = exports.ExpressionCore_FunctionalActions = exports.validateType = exports.Operator_CONDITION_LIST = exports.Operator_AVERAGE = exports.Operator_SUM = exports.Operator_EQUAL = exports.Operator_CONTAINS = exports.Operator_IN_RANGE = exports.Operator_LESSER_THAN = exports.Operator_GREATER_THAN = exports.Operator_ADDITION = exports.Operator_SUBTRACT = exports.Operator_MULTIPLICATION = exports.Operator_DIV = exports.Operator_MOD = exports.Operator_POWER = exports.Operator_NOT = exports.Operator_OR = exports.Operator_AND = exports.Operator_Func_If = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.Operator_Func_If = '?';
exports.Operator_AND = '&&';
exports.Operator_OR = '||';
exports.Operator_NOT = '!';
exports.Operator_POWER = '^';
exports.Operator_MOD = '%';
exports.Operator_DIV = '/';
exports.Operator_MULTIPLICATION = '*';
exports.Operator_SUBTRACT = '-';
exports.Operator_ADDITION = '+';
exports.Operator_GREATER_THAN = '>';
exports.Operator_LESSER_THAN = '<';
exports.Operator_IN_RANGE = '∈';
exports.Operator_CONTAINS = 'in';
exports.Operator_EQUAL = '===';
exports.Operator_SUM = 'Σ';
exports.Operator_AVERAGE = 'x̄';
exports.Operator_CONDITION_LIST = '?[]';
const validateType = (value, type, expectedType) => {
    if (typeof value !== expectedType)
        throw new ts_common_1.BadImplementationException(`The ${type} hand param is not a ${expectedType}!\n found type: ${typeof value} - "${value}"`);
};
exports.validateType = validateType;
exports.ExpressionCore_FunctionalActions = {
    [exports.Operator_Func_If]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) ? _expressionModule.params.eval(params[1]) : _expressionModule.params.eval(params[2]);
    },
    [exports.Operator_CONDITION_LIST]: (_expressionModule, ...params) => {
        for (let i = 0; i < params[0].length; i++) {
            if (_expressionModule.params.eval(params[0][i]))
                return _expressionModule.params.eval(params[1][i]);
        }
    }
};
exports.ExpressionCore_FunctionalPrettyPrinting = {
    [exports.Operator_Func_If]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_Func_If} ${params[1]} : ${params[2]})`,
    [exports.Operator_CONDITION_LIST]: (_expressionModule, indent, ...params) => `(${params[0].map((result, index) => `(${result} -> ${params[1][index]})`)})`
    // [Operator_GetPatientVar]: (indent: string, ...params: Params<Op_GetPatientVar>) => `(PV{${params[0]}})`
};
exports.ExpressionCore_FunctionalValidation = {
    [exports.Operator_Func_If]: (_expressionModule, ...params) => {
        if (typeof params[0] !== 'boolean')
            throw new ts_common_1.BadImplementationException('condition function must return boolean');
    },
    [exports.Operator_CONDITION_LIST]: (_expressionModule, ...params) => {
        params[0].map(item => (0, exports.validateType)(item, 'left', 'boolean'));
        if (params[0].length !== params[1].length)
            throw new ts_common_1.BadImplementationException('Both lists must be in the same length');
    }
    // [Operator_GetPatientVar]: (...params: Params<Op_GetPatientVar>) => {
    // 	validateType(params[0], 'left', 'string');
    // }
};
exports.FunctionalOperators = [exports.Operator_Func_If];
exports.ExpressionCore_BooleanActions = {
    [exports.Operator_EQUAL]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) === _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_OR]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]).reduce((toReturn, value) => toReturn || value, params[0][0]);
    },
    [exports.Operator_AND]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]).reduce((toReturn, value) => toReturn && value, params[0][0]);
    },
    [exports.Operator_IN_RANGE]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) > _expressionModule.params.eval(params[1])[0] && _expressionModule.params.eval(params[0]) < _expressionModule.params.eval(params[1])[1];
    },
    [exports.Operator_NOT]: (_expressionModule, ...params) => {
        return !_expressionModule.params.eval(params[0]);
    },
    [exports.Operator_GREATER_THAN]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) > _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_LESSER_THAN]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) < _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_CONTAINS]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]).includes(_expressionModule.params.eval(params[1]));
    }
};
exports.ExpressionCore_BooleanPrettyPrinting = {
    [exports.Operator_OR]: (_expressionModule, indent, ...params) => `(${params[0].join(` ${exports.Operator_OR} `)})`,
    [exports.Operator_AND]: (_expressionModule, indent, ...params) => `(${params[0].join(` ${exports.Operator_AND} `)})`,
    [exports.Operator_IN_RANGE]: (_expressionModule, indent, ...params) => `(${params[1][0]} ${exports.Operator_LESSER_THAN} ${params[0]} ${exports.Operator_LESSER_THAN} ${params[1][1]})`,
    [exports.Operator_NOT]: (_expressionModule, indent, ...params) => `(${exports.Operator_NOT}${params[0]})`,
    [exports.Operator_GREATER_THAN]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_GREATER_THAN} ${params[1]})`,
    [exports.Operator_LESSER_THAN]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_LESSER_THAN} ${params[1]})`,
    [exports.Operator_CONTAINS]: (_expressionModule, indent, ...params) => `(${JSON.stringify(params[1])}.include(${params[0]}))`,
    [exports.Operator_EQUAL]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_EQUAL} ${params[1]})`,
};
exports.BooleanOperators = [exports.Operator_EQUAL, exports.Operator_OR, exports.Operator_NOT, exports.Operator_AND, exports.Operator_IN_RANGE, exports.Operator_CONTAINS, exports.Operator_LESSER_THAN, exports.Operator_GREATER_THAN];
exports.ExpressionCore_BooleanValidation = {
    [exports.Operator_IN_RANGE]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        params[1].map(item => (0, exports.validateType)(item, 'right', 'number'));
        if (params[1][0] > params[1][1])
            throw new ts_common_1.MUSTNeverHappenException(`Right side of the range can't bigger than the left side got - [${params[1][0]}], ${params[1][1]}`);
    },
    [exports.Operator_NOT]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'boolean');
    },
    [exports.Operator_GREATER_THAN]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_LESSER_THAN]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_CONTAINS]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'string');
        params[1].map(item => (0, exports.validateType)(item, 'right', 'string'));
    },
    [exports.Operator_EQUAL]: (_expressionModule, ...params) => {
    },
    [exports.Operator_OR]: (_expressionModule, ...params) => {
        params.map(item => (0, exports.validateType)(item, 'right', 'boolean'));
    },
    [exports.Operator_AND]: (_expressionModule, ...params) => {
        params.map(item => (0, exports.validateType)(item, 'right', 'boolean'));
    },
};
exports.ExpressionsCore_BasicMath = {
    [exports.Operator_ADDITION]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) + _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_SUBTRACT]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) - _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_MOD]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) % _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_POWER]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) ** _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_DIV]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) / _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_MULTIPLICATION]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]) * _expressionModule.params.eval(params[1]);
    },
    [exports.Operator_SUM]: (_expressionModule, ...params) => {
        return _expressionModule.params.eval(params[0]).reduce((sum, currItem) => sum += currItem, 0);
    },
    [exports.Operator_AVERAGE]: (_expressionModule, ...params) => {
        return exports.ExpressionsCore_BasicMath[exports.Operator_SUM](_expressionModule, _expressionModule.params.eval(params[0])) / _expressionModule.params.eval(params[0]).length;
    }
};
exports.ExpressionCore_BasicMathPrettyPrinting = {
    [exports.Operator_ADDITION]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_ADDITION} ${params[1]})`,
    [exports.Operator_SUBTRACT]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_SUBTRACT} ${params[1]})`,
    [exports.Operator_MOD]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_MOD} ${params[1]})`,
    [exports.Operator_POWER]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_POWER} ${params[1]})`,
    [exports.Operator_DIV]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_DIV} ${params[1]})`,
    [exports.Operator_MULTIPLICATION]: (_expressionModule, indent, ...params) => `(${params[0]} ${exports.Operator_MULTIPLICATION} ${params[1]})`,
    [exports.Operator_SUM]: (_expressionModule, indent, ...params) => `(${params[0].join(` ${exports.Operator_ADDITION} `)})`,
    [exports.Operator_AVERAGE]: (_expressionModule, indent, ...params) => `((${params[0].join(` ${exports.Operator_ADDITION} `)}) ${exports.Operator_DIV} ${params[0].length})`
};
exports.ExpressionCore_BasicMathValidation = {
    [exports.Operator_ADDITION]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_SUBTRACT]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_MOD]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_POWER]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_DIV]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_MULTIPLICATION]: (_expressionModule, ...params) => {
        (0, exports.validateType)(params[0], 'left', 'number');
        (0, exports.validateType)(params[1], 'right', 'number');
    },
    [exports.Operator_SUM]: (_expressionModule, ...params) => {
        params[0].map(param => (0, exports.validateType)(param, 'left', 'number'));
    },
    [exports.Operator_AVERAGE]: (_expressionModule, ...params) => {
        params[0].map(param => (0, exports.validateType)(param, 'left', 'number'));
    }
};
exports.BasicMatchOperators = [exports.Operator_POWER, exports.Operator_ADDITION, exports.Operator_MOD, exports.Operator_SUBTRACT, exports.Operator_MULTIPLICATION, exports.Operator_DIV, exports.Operator_SUM, exports.Operator_AVERAGE];
exports.AllOperands = [...exports.BasicMatchOperators, ...exports.BooleanOperators, ...exports.FunctionalOperators];
exports.ExpressionCore_BasicMathOptions = {
    Operators: exports.BasicMatchOperators,
    OperatorPrinter: exports.ExpressionCore_BasicMathPrettyPrinting,
    OperatorProcessors: exports.ExpressionsCore_BasicMath,
    OperatorValidation: exports.ExpressionCore_BasicMathValidation
};
exports.ExpressionCore_BooleanOptions = {
    Operators: exports.BooleanOperators,
    OperatorPrinter: exports.ExpressionCore_BooleanPrettyPrinting,
    OperatorProcessors: exports.ExpressionCore_BooleanActions,
    OperatorValidation: exports.ExpressionCore_BooleanValidation
};
exports.ExpressionCore_FunctionalOptions = {
    Operators: exports.FunctionalOperators,
    OperatorPrinter: exports.ExpressionCore_FunctionalPrettyPrinting,
    OperatorProcessors: exports.ExpressionCore_FunctionalActions,
    OperatorValidation: exports.ExpressionCore_FunctionalValidation
};
exports.defaultExpressionOptions = [
    exports.ExpressionCore_BasicMathOptions,
    exports.ExpressionCore_BooleanOptions,
    exports.ExpressionCore_FunctionalOptions
];
