"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_MultiVar_Editor = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Dialog_MultiVar_Editor.scss");
const frontend_2 = require("@app/hcs/frontend");
const shared_components_1 = require("../../shared-components");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../_entity");
class Dialog_MultiVar_Editor extends frontend_1.TS_Dialog {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        //######################### Logic #########################
        this.onVariableSelected = (variable) => {
            var _a;
            this.props.multiVar.variables[this.state.editingIndex] = {
                variableId: variable._id,
                role: ((_a = this.state.diseaseView) === null || _a === void 0 ? void 0 : _a.enforcedRole) || variable.role,
                tagIds: [],
            };
            if (this.state.editingIndex !== 0)
                return this.reDeriveState({ tags: undefined });
            this.props.multiVar._firstVarId = variable._id;
            const matchingViews = _entity_1.ModuleFE_DiseaseView.cache.filter(item => _entity_1.ModuleFE_DiseaseView.canVariableBeAddedToView(item, this.props.multiVar));
            const diseaseView = matchingViews.length ? matchingViews[0] : undefined;
            if (diseaseView)
                this.applyDiseaseViewOnMultiVar(diseaseView);
            this.reDeriveState({ tags: undefined, diseaseView, isChief: Dialog_MultiVar_Editor.isChief(this.props) });
        };
        this.onAttributeSelected = (variable) => {
            this.props.multiVar.variables[this.state.editingIndex] = {
                variableId: variable._id,
                role: variable.role,
                tagIds: [],
            };
            if (this.props.attributeeIndex !== -1)
                this.props.multiVar.variables[this.state.editingIndex].attributeeId = this.props.multiVar.variables[this.props.attributeeIndex].variableId;
            this.reDeriveState({ tags: undefined });
        };
        this.onDiseaseViewSelected = (diseaseView) => {
            this.applyDiseaseViewOnMultiVar(diseaseView);
            this.reDeriveState({ diseaseView });
        };
        this.closeDialog = () => {
            frontend_1.ModuleFE_Dialog.close();
        };
        this.canBeSaved = () => {
            var _a;
            if (((_a = this.getSelectedMultivarVariable()) === null || _a === void 0 ? void 0 : _a.variableId) === undefined)
                return false;
            if (this.state.editingIndex === 0 && !this.state.diseaseView)
                return false;
            return true;
        };
        this.getSelectedVariable = () => {
            if (!this.getSelectedMultivarVariable())
                return undefined;
            return frontend_2.ModuleFE_Variable.cache.unique(this.getSelectedMultivarVariable().variableId);
        };
        this.getSelectedAttribute = () => {
            var _a;
            if (this.state.editingIndex !== 0)
                return undefined;
            return frontend_2.ModuleFE_Variable.cache.unique((_a = this.props.multiVar.variables[1]) === null || _a === void 0 ? void 0 : _a.variableId);
        };
        this.onDelete = async () => {
            var _a;
            //Set busy dialog
            this.setState({ dialogIsBusy: true });
            //If removing a column
            if (this.state.editingIndex > 0) {
                await (0, frontend_1.performAction)(async () => {
                    try {
                        (0, ts_common_1.removeFromArrayByIndex)(this.props.multiVar.variables, this.state.editingIndex);
                        await this.saveImpl();
                        this.closeDialog();
                    }
                    catch (e) {
                        this.setState({ dialogIsBusy: false });
                    }
                }, { type: 'notification', notificationLabels: 'Removing Attribute' });
                return;
            }
            //If deleting the multivar
            this.logInfo('MV to Delete:', this.props.multiVar);
            let multivarTextualView = '';
            multivarTextualView += _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.props.multiVar.diseaseProfileId).label;
            multivarTextualView += ' - ' + ((_a = this.props.diseaseView) === null || _a === void 0 ? void 0 : _a.label);
            multivarTextualView += ' - ' + frontend_2.ModuleFE_Variable.cache.unique(this.props.multiVar._firstVarId).name;
            await (0, frontend_1.performAction)(async () => {
                try {
                    await this.deleteImpl();
                    this.closeDialog();
                }
                catch (e) {
                    console.log('Im HERE');
                    this.setState({ dialogIsBusy: false });
                    throw e;
                }
            }, {
                type: 'toast',
                successContent: React.createElement(frontend_2.QToastContent, { content: 'MultiVar deleted successfully', toastType: 'success', iconKey: 'v' }),
                duration: 10000,
            }, multivarTextualView, true);
        };
        this.onSave = async () => {
            this.logInfo('MV to save:', this.props.multiVar);
            await (0, frontend_1.performAction)(async () => {
                this.setState({ dialogIsBusy: true });
                await this.saveImpl();
                this.setState({ dialogIsBusy: false }, () => frontend_1.ModuleFE_Dialog.close());
                this.setState({ dialogIsBusy: false });
            }, { type: 'notification', notificationLabels: 'Updating Multivar' });
        };
        this.onDisabledClick = async (e) => {
            if (!this.state.diseaseView)
                frontend_1.ModuleFE_Toaster.toastError('Please pick a disease view');
        };
        //######################### Render #########################
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("span", { className: 'header__title' }, this.isEditing() ? 'Edit Multivar' : 'Add MultiVar'),
                React.createElement("div", { className: 'header__buttons' },
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: this.closeDialog })));
        };
        this.renderBody = () => {
            if (this.props.attributeeIndex !== undefined)
                return React.createElement(React.Fragment, null,
                    this.renderAttributeeVariable(),
                    this.renderColumnOrAttributeDropdown(),
                    this.renderRoles(),
                    this.renderTags(this.state.tags, this.getSelectedVariable()));
            return React.createElement(React.Fragment, null,
                this.renderVariableDropDown(),
                this.renderDP_ViewConfig(),
                this.renderAttributeDropdown(),
                this.renderChiefCheckbox(),
                this.renderViews(),
                this.renderRoles(),
                this.renderTags(this.state.tags, this.getSelectedVariable()));
        };
        this.buttons = () => {
            var _a;
            const buttons = {
                right: [
                    { content: 'Cancel', onClick: this.closeDialog },
                    {
                        content: 'Save',
                        onClick: this.onSave,
                        onDisabledClick: this.onDisabledClick,
                        className: 'desired-action-button',
                        disabled: !this.canBeSaved() || this.state.dialogIsBusy,
                        renderer: frontend_1.TS_Dialog.busyButton,
                    }
                ]
            };
            if ((_a = this.props.multiVar) === null || _a === void 0 ? void 0 : _a._id)
                buttons.left = [{
                        content: 'Delete',
                        renderer: frontend_1.TS_Dialog.busyButton,
                        className: 'delete-button',
                        disabled: this.state.dialogIsBusy,
                        onClick: this.onDelete
                    }];
            return buttons;
        };
        this.renderRoles = () => {
            const mvVar = this.getSelectedMultivarVariable();
            if (!mvVar)
                return '';
            return React.createElement(frontend_1.LL_V_L, { className: 'match_width', style: { gap: '10px' } },
                React.createElement("div", { className: 'dialog__field-title' }, "Role"),
                React.createElement("div", { className: 'dialog__field-content' }, mvVar.role));
        };
        this.renderVariableDropDown = () => {
            var _a;
            return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
                React.createElement("div", { className: 'dialog__field-title' }, "Select Variable"),
                React.createElement(frontend_2.Component_VariableSelector, { onVariableSelected: this.onVariableSelected, selectedVariableId: (_a = this.getSelectedVariable()) === null || _a === void 0 ? void 0 : _a._id, queryFilter: (variable) => {
                        // do not allow attributes
                        if (variable.isAttribute)
                            return false;
                        // do not allow variables that already exists in this multivar)
                        if (this.props.multiVar.variables.find(mvv => mvv.variableId === variable._id))
                            return false;
                        // do not allow variables that already have multivars associated with them)
                        // return !ModuleFE_MultiVar.cache.find(item => item.variables[0].variableId === variable._id && item.diseaseProfileId === this.props.multiVar.diseaseProfileId);
                        return true;
                    }, allowSelection: !this.isEditing() }));
        };
        this.renderAttributeDropdown = () => {
            var _a, _b, _c;
            const variable = frontend_2.ModuleFE_Variable.cache.unique((_a = this.props.multiVar.variables[this.state.editingIndex]) === null || _a === void 0 ? void 0 : _a.variableId);
            //Don't render if not dealing with first variable or if the first variable doesn't have attributes
            if (this.state.editingIndex !== 0 || !variable || !variable.attributes)
                return '';
            //Don't render if editing and the second variable doesn't exist OR is not an attribute of the first
            if (this.isEditing() && ((_b = this.props.multiVar.variables[1]) === null || _b === void 0 ? void 0 : _b.attributeeId) !== variable._id)
                return '';
            const attributes = variable.attributes.map(att => att.varId);
            return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
                React.createElement("div", { className: 'dialog__field-title' }, "Select Attribute"),
                React.createElement(frontend_2.Component_VariableSelector, { onVariableSelected: item => {
                        this.props.multiVar.variables[1] = {
                            variableId: item._id,
                            role: item.role,
                            attributeeId: variable._id,
                            tagIds: item.tagIds,
                        };
                        this.forceUpdate();
                    }, selectedVariableId: (_c = this.getSelectedAttribute()) === null || _c === void 0 ? void 0 : _c._id, queryFilter: item => attributes.includes(item._id), allowSelection: !this.isEditing() }));
        };
        this.renderViews = () => {
            if (this.state.editingIndex !== 0)
                return '';
            const multiVar = this.props.multiVar;
            const variable = this.getSelectedVariable();
            if (!multiVar || !variable)
                return '';
            let views = _entity_1.ModuleFE_DiseaseView.cache.all();
            views = views.filter(view => _entity_1.ModuleFE_DiseaseView.canVariableBeAddedToView(view, multiVar));
            return React.createElement(frontend_1.LL_V_L, { className: 'match_width', style: { gap: '10px' } },
                React.createElement("div", { className: 'dialog__field-title' }, "Matching Views"),
                React.createElement(frontend_1.LL_H_C, { className: 'dialog__field-content views' }, views.map(view => {
                    return React.createElement("div", { className: 'view', key: view._id }, view.label);
                })));
        };
        this.renderChiefCheckbox = () => {
            const variable = this.getSelectedVariable();
            // TODO replace with app config
            const complaintTagId = frontend_2.ModuleFE_Tag.cache.find(item => item.label.toLowerCase() === 'complaints')._id;
            if (!(variable === null || variable === void 0 ? void 0 : variable._tagIds.includes(complaintTagId)))
                return '';
            return React.createElement(frontend_1.LL_H_C, { className: 'dialog__main__section horizontal match_width no-grow', style: { justifyContent: 'space-between' } },
                React.createElement("label", null, "Is this complaint a chief complaint?"),
                React.createElement(frontend_1.TS_Radio, { className: 'label-radio', values: ['No', 'Yes'], groupName: 'is-chief-complaint', checked: this.state.isChief ? 'Yes' : 'No', onCheck: val => {
                        this.setState({ isChief: val === 'Yes' });
                    } }));
        };
        //######################### Render - Editing Column / Attribute #########################
        this.renderAttributeeVariable = () => {
            if (this.props.attributeeIndex === -1)
                return '';
            const multiVar = this.props.multiVar;
            return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
                React.createElement("div", { className: 'dialog__field-title' }, "Attributee"),
                React.createElement(frontend_2.Component_VariableSelector, { onVariableSelected: ts_common_1.voidFunction, selectedVariableId: multiVar.variables[this.props.attributeeIndex].variableId, allowSelection: false }));
        };
        this.renderColumnOrAttributeDropdown = () => {
            var _a;
            const multiVar = this.props.multiVar;
            return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
                React.createElement("div", { className: 'dialog__field-title' }, "Select Variable"),
                React.createElement(frontend_2.Component_VariableSelector, { onVariableSelected: this.onAttributeSelected, selectedVariableId: (_a = multiVar.variables[this.state.editingIndex]) === null || _a === void 0 ? void 0 : _a.variableId, queryFilter: v => {
                        if (multiVar.variables.find(mvv => { var _a; return mvv.variableId === v._id && mvv.attributeeId === ((_a = this.props.multiVar.variables[this.props.attributeeIndex]) === null || _a === void 0 ? void 0 : _a.variableId); }))
                            return false;
                        if (this.props.attributeeIndex === -1)
                            return !v.isAttribute;
                        return !!v.isAttribute;
                    }, allowSelection: !this.props.attributeOrColumnIndex }));
        };
    }
    static showForVariable(multiVar, columnIndex) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_MultiVar_Editor, { multiVar: (0, ts_common_1.cloneObj)(multiVar), attributeeIndex: -1, attributeOrColumnIndex: columnIndex }));
    }
    static showForAttributes(multiVar, attributeeIndex, attributeIndex) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_MultiVar_Editor, { multiVar: (0, ts_common_1.cloneObj)(multiVar), attributeeIndex: attributeeIndex, attributeOrColumnIndex: attributeIndex }));
    }
    //######################### Life Cycle #########################
    __onDeleteConflicts(entity, conflictingIds) {
        this.closeDialog();
    }
    __onVariableUpdated(...params) {
        var _a;
        if (params[0] !== frontend_1.EventType_Update)
            return;
        if (((_a = this.getSelectedVariable()) === null || _a === void 0 ? void 0 : _a._id) !== params[1]._id)
            return;
        this.forceUpdate();
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.editingIndex = nextProps.attributeeIndex === undefined ? 0 : state.editingIndex || nextProps.attributeOrColumnIndex || nextProps.multiVar.variables.length;
        state.isChief || (state.isChief = Dialog_MultiVar_Editor.isChief(nextProps));
        if (!this.state && this.isEditing())
            state.diseaseView = nextProps.diseaseView;
        state.diseaseCategoryId = frontend_2.ConfigKeyFE_DiseasesTagId.get();
        state.sourceTagId = frontend_2.ConfigKeyFE_SourceTagId.get();
        state.tags = frontend_2.VariableWrapper.dbTagsToUI(((_a = nextProps.multiVar.variables[state.editingIndex]) === null || _a === void 0 ? void 0 : _a.tagIds) || []);
        return state;
    }
    applyDiseaseViewOnMultiVar(diseaseView) {
        this.props.multiVar.variables[this.state.editingIndex].role = diseaseView.enforcedRole;
        const dvTagId = frontend_2.ConfigKeyFE_DiseasesViewsTagId.get();
        const dvGroup = this.props.multiVar.variables[this.state.editingIndex].tagIds.find(i => i.key === dvTagId);
        if (!dvGroup) {
            this.props.multiVar.variables[this.state.editingIndex].tagIds.push({ key: dvTagId, value: diseaseView.enforcedTags });
        }
        else {
            dvGroup.value = diseaseView.enforcedTags;
        }
    }
    isEditing() {
        return this.props.multiVar._id || !!this.props.attributeOrColumnIndex;
    }
    getSelectedMultivarVariable() {
        return this.props.multiVar.variables[this.state.editingIndex];
    }
    async deleteImpl() {
        return _entity_1.ModuleFE_MultiVar.v1.delete(this.props.multiVar).executeSync();
    }
    async saveImpl() {
        const variables = this.props.multiVar.variables;
        // if we are dealing with adding an attribute to another var in the multivar
        const attributeeIndex = this.props.attributeeIndex;
        if (attributeeIndex && attributeeIndex >= 0)
            variables[this.state.editingIndex].attributeeId = variables[attributeeIndex].variableId;
        variables[this.state.editingIndex].tagIds = frontend_2.VariableWrapper.getTagsForDB(this.state.tags);
        let multivar;
        try {
            multivar = await _entity_1.ModuleFE_MultiVar.v1.upsert(this.props.multiVar).executeSync();
        }
        catch (e) {
            (0, frontend_2.handleError)(e);
        }
        if (this.state.isChief !== undefined) {
            const diseaseProfile = (0, ts_common_1.cloneObj)(_entity_1.ModuleFE_DiseaseProfile.cache.unique(this.props.multiVar.diseaseProfileId));
            diseaseProfile.chiefComplaintIds = diseaseProfile.chiefComplaintIds || [];
            if (this.state.isChief) {
                //If multivar is already chief - return
                if (diseaseProfile.chiefComplaintIds.includes(multivar._firstVarId))
                    return;
                diseaseProfile.chiefComplaintIds.push(multivar._firstVarId);
                (0, ts_common_1.filterDuplicates)(diseaseProfile.chiefComplaintIds);
            }
            else {
                //If multivar is already not chief - return
                if (!diseaseProfile.chiefComplaintIds.includes(multivar._firstVarId))
                    return;
                diseaseProfile.chiefComplaintIds = diseaseProfile.chiefComplaintIds.filter(id => id !== multivar._firstVarId);
            }
            try {
                await _entity_1.ModuleFE_DiseaseProfile.v1.upsert(diseaseProfile).executeSync();
            }
            catch (e) {
                (0, frontend_2.handleError)(e);
            }
        }
    }
    renderTags(tags, variable) {
        const tagIds = variable === null || variable === void 0 ? void 0 : variable.tagIds;
        if (!tagIds)
            return;
        const allowedGroupTags = tagIds.map(group => group.key);
        const allowedTags = (0, ts_common_1.flatArray)(tagIds.map(group => group.value));
        const dp = _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.props.multiVar.diseaseProfileId);
        if (dp === null || dp === void 0 ? void 0 : dp.variantsGroupId) {
            const group = frontend_2.ModuleFE_Tag.cache.unique(dp.variantsGroupId);
            if (!group)
                throw new ts_common_1.BadImplementationException(`Variant group with id ${dp.variantsGroupId} does not exist`);
            const tags = frontend_2.ModuleFE_Tag.cache.filter(i => i.tagIds.includes(group._id));
            allowedGroupTags.push(group._id);
            allowedTags.push(...tags.map(ts_common_1.dbObjectToId));
        }
        const diseaseViewTagId = frontend_2.ConfigKeyFE_DiseasesViewsTagId.get();
        const diseaseProfile = _entity_1.ModuleFE_DiseaseProfile.cache.unique(this.props.multiVar.diseaseProfileId);
        const globalTagGroups = (0, ts_common_1.filterInstances)([diseaseProfile.variantsGroupId, diseaseViewTagId]);
        return React.createElement(frontend_2.Component_TagEditor, { tags: tags, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id && (allowedTags.includes(tag._id) || globalTagGroups.includes(tag.tagIds[0])), groupTagFilter: (groupTag) => allowedGroupTags.includes(groupTag._id) || globalTagGroups.includes(groupTag._id) });
    }
    //######################### Render - Editing Main Variable #########################
    renderDP_ViewConfig() {
        if (this.state.editingIndex !== 0)
            return '';
        if (this.isEditing() && this.state.diseaseView)
            return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
                React.createElement("div", { className: 'dialog__field-title' }, "Selected Disease View"),
                React.createElement("div", null, this.state.diseaseView.label));
        return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
            React.createElement("div", { className: 'dialog__field-title' }, "Select Disease View"),
            React.createElement(shared_components_1.DPDropDowns.DiseaseView, { selected: this.state.diseaseView, onSelected: this.onDiseaseViewSelected, queryFilter: view => {
                    return _entity_1.ModuleFE_DiseaseView.canVariableBeAddedToView(view, this.props.multiVar);
                } }));
    }
}
Dialog_MultiVar_Editor.defaultProps = {
    dialogId: 'dialog-multivar-editor',
};
Dialog_MultiVar_Editor.show = (diseaseView, diseaseProfileId, mv) => {
    const multiVar = mv ? (0, ts_common_1.cloneObj)(mv) : {
        diseaseProfileId: diseaseProfileId,
        referenceIds: [],
        variables: [],
        _firstVarId: '',
        _variableIds: [],
        _tagIds: []
    };
    frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_MultiVar_Editor, { diseaseView: diseaseView, multiVar: multiVar }), () => false);
};
Dialog_MultiVar_Editor.isChief = (props) => {
    return _entity_1.ModuleFE_DiseaseProfile.cache.unique(props.multiVar.diseaseProfileId).chiefComplaintIds.includes(props.multiVar._firstVarId);
};
exports.Dialog_MultiVar_Editor = Dialog_MultiVar_Editor;
