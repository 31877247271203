"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataGrid = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_DataGrid.scss");
const _DataGrid = (props) => {
    const className = (0, frontend_1._className)('quai-manager-ui__data-grid', props.className);
    return React.createElement(frontend_1.Grid, { className: className }, props.children);
};
const DataGridLabel = (props) => {
    const className = (0, frontend_1._className)('quai-manager-ui__data-grid__label', props.className);
    return React.createElement("div", { className: className, onClick: props.onClick }, props.children);
};
const DataGridData = (props) => {
    const className = (0, frontend_1._className)('quai-manager-ui__data-grid__data', props.className);
    return React.createElement("div", { className: className, onClick: props.onClick }, props.children);
};
exports.DataGrid = {
    Grid: _DataGrid,
    Label: DataGridLabel,
    Data: DataGridData,
};
