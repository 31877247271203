"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_Term = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
require("./editors.scss");
const Editor_Atom_1 = require("./Editor_Atom");
const ts_common_1 = require("@nu-art/ts-common");
const Component_StructuredComplexLogic_1 = require("../structured-complex-logic/Component_StructuredComplexLogic");
const consts_1 = require("../../../../../../../_entity/expression/shared/consts");
const _entity_1 = require("../../../../../../_entity");
const utils_1 = require("../../../../../../../_entity/expression/shared/utils");
const QRangeInputV3_1 = require("../../../../../q-components/QRangeInputV3");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
const ui_components_2 = require("../../../../../../../_entity/expression/frontend/ui-components");
const styles_1 = require("@app/styles");
const TS_ListOrganizer_1 = require("@nu-art/thunderstorm/frontend/components/TS_ListOrganizer");
const utils_2 = require("./utils");
const EmptyString = '';
class Editor_Term extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        //######################### Logic #########################
        this.setAtomsLogic = async () => {
            var _a, _b;
            if (this.props.viewMode)
                return;
            const _term = this.state.editable;
            const atomsLogic = _term.get('atomsLogic');
            const availableOperators = this.props.isSubTerm ? consts_1.BasicAtomsLogicTypes : consts_1.AtomsLogicTypes;
            const changes = {};
            //Set the next available atoms logic in the changes object
            const index = availableOperators.indexOf(atomsLogic);
            changes.atomsLogic = availableOperators[index + 1] ? availableOperators[index + 1] : availableOperators[0];
            if (_entity_1.ModuleFE_Expression.isTerm.withSubTerms(_term)) {
                // When not using complex logic output type is redundant
                if (_term.get('outputType'))
                    changes.outputType = undefined;
                changes.subTerms = undefined;
                changes.fallbackValue = undefined;
            }
            //Make sure to use previous sub terms if not deleted when next atom logic is complex
            if (changes.atomsLogic === consts_1.LogicType_Complex) {
                changes.subTerms = (_a = this.state.prevSubTerms) !== null && _a !== void 0 ? _a : [(0, utils_1.getEmptyTerm)()];
                changes.fallbackValue = [0, 0];
            }
            //Remove term value if next atom is "+" and value exists
            if (changes.atomsLogic === consts_1.LogicType_Add && (0, ts_common_1.exists)(_term.get('value'))) {
                changes.value = undefined;
                changes.fallbackValue = undefined;
                this.setState({ toggleValue: false });
            }
            //Add empty free text object in case it's the next logic type
            if (changes.atomsLogic === consts_1.LogicType_StructuredFreeText) {
                changes.structuredFreeText = { if: undefined, then: undefined, else: undefined };
            }
            // Make sure to remove all irrelevant data when switching from structured free text
            if (atomsLogic === consts_1.LogicType_StructuredFreeText) {
                changes.structuredFreeText = undefined;
                changes.outputType = undefined;
            }
            // update the expression after the change
            changes.expression = this.updateTerm((_b = (0, ts_common_1.deepClone)(_term.get('atoms'))) !== null && _b !== void 0 ? _b : [], changes.atomsLogic);
            await this.state.editable.updateObj(changes);
        };
        this.deleteAtom = async (index) => {
            var _a;
            const atoms = ((_a = this.state.editable.get('atoms')) !== null && _a !== void 0 ? _a : []);
            atoms === null || atoms === void 0 ? void 0 : atoms.splice(index, 1);
            if (!(atoms === null || atoms === void 0 ? void 0 : atoms.length))
                return this.props.deleteTerm();
            const changes = {
                atoms: atoms,
                expression: this.updateTerm(atoms)
            };
            // No atomLogic when there's only one atom
            if (atoms.length === 1) {
                changes.value = undefined;
                changes.fallbackValue = undefined;
                changes.atomsLogic = undefined;
                if ((0, ts_common_1.exists)(this.state.editable.item.subTerms)) {
                    changes.subTerms = undefined;
                }
            }
            await this.state.editable.updateObj(changes);
            //FIXME: hack, don't manage to understand why this updateObj call doesn't trigger the re-derive
            this.forceUpdate(() => this.state.editable.save());
        };
        this.addEmptyAtom = async () => {
            var _a;
            const termItem = this.state.editable;
            const changes = {};
            //Add empty atom to the changes object
            changes.atoms = [...(_a = (0, ts_common_1.deepClone)(termItem.get('atoms'))) !== null && _a !== void 0 ? _a : [], {
                    cases: [{}],
                    leftHand: undefined
                }];
            //Add atomLogic when there's no previous one and there's more than one atom
            if (changes.atoms.length > 1 && !(0, ts_common_1.exists)(this.state.editable.get('atomsLogic')))
                changes.atomsLogic = consts_1.LogicType_And;
            //Update the string expression
            changes.expression = this.updateTerm(changes.atoms, changes.atomsLogic);
            await termItem.updateObj(changes);
        };
        this.updateTerm = (atomsArray, atomsLogic) => {
            const termItem = this.state.editable;
            const _atomsLogic = atomsLogic !== null && atomsLogic !== void 0 ? atomsLogic : termItem.get('atomsLogic');
            const expression = termItem.get('expression');
            if (!atomsArray || _atomsLogic === consts_1.LogicType_Complex || _atomsLogic === consts_1.LogicType_StructuredFreeText)
                return EmptyString;
            const atoms = atomsArray.map((atom, index) => {
                return `A${index + 1}`;
            });
            if (!atoms.length)
                return expression;
            //make sure to force update only when there are atoms
            return `${atoms.join(` ${_atomsLogic} `)}`;
        };
        this.toggleAddValue = () => {
            const value = this.state.editable.get('value');
            const fallbackValue = this.state.editable.get('fallbackValue');
            this.setState({ toggleValue: !this.state.toggleValue }, async () => {
                if (!this.state.toggleValue && ((0, ts_common_1.exists)(value) || (0, ts_common_1.exists)(fallbackValue)))
                    return this.state.editable.updateObj({ value: undefined, fallbackValue: undefined });
                return this.state.editable.updateObj({ fallbackValue: [0, 0] });
            });
        };
        this.getUnusedAtoms = () => {
            //Fail fast in case it's not term with sub-terms, check is irrelevant
            if (!_entity_1.ModuleFE_Expression.isTerm.withSubTerms(this.state.editable))
                return undefined;
            const atoms = this.state.editable.get('atoms');
            const subTerms = this.state.editable.get('subTerms');
            return atoms.filter((atom, index) => {
                return !subTerms.some(subTerm => !!subTerm.atoms.find(atom => { var _a; return ((_a = atom.leftHand) === null || _a === void 0 ? void 0 : _a.id) === String(index + 1); }));
            });
        };
        this.canAddNewAtom = () => {
            //If the expression is not normal range this restriction is irrelevant
            if (this.props.expressionType !== consts_1.ExpressionType_NormalRange.dbKey)
                return true;
            //If there's no value to the first atom
            const firstAtomValue = this.state.editable.get('atoms')[0].cases[0].value;
            if (!firstAtomValue)
                return true;
            //Can add new atom only when the value is not range
            return firstAtomValue[0] === firstAtomValue[1];
        };
        this.canAddTermValue = () => {
            //SubTerm can't toggle term value, it's always on
            if (this.props.isSubTerm)
                return false;
            //Add and free text logic can't have term value, value determined by the atoms values and in free text return and else values are strings
            const atomsLogic = this.state.editable.get('atomsLogic');
            if (atomsLogic === consts_1.LogicType_Add || atomsLogic === consts_1.LogicType_StructuredFreeText)
                return false;
            //Term with one atom that has value can't add term value it's redundant
            const atoms = this.state.editable.get('atoms');
            const termValue = this.state.editable.get('value');
            const termFallbackValue = this.state.editable.get('fallbackValue');
            if (atoms.length === 1 && (0, ts_common_1.exists)(atoms[0].cases[0].value) && (!(0, ts_common_1.exists)(termValue) && !(0, ts_common_1.exists)(termFallbackValue)))
                return false;
            //Term with sub terms can't change this filed as well
            return !_entity_1.ModuleFE_Expression.isTerm.withSubTerms(this.state.editable);
        };
        this.handleDnD = async (newOrder, oldOrder) => {
            const atoms = newOrder.map(orderElement => orderElement.editable.item);
            const atomsLogic = this.state.editable.get('atomsLogic');
            const expression = this.updateTerm(atoms);
            //Handle free text edge case
            if (consts_1.LogicType_StructuredFreeText === atomsLogic) {
                const structuredFreeText = (0, ts_common_1.deepClone)(this.state.editable.get('structuredFreeText'));
                // update each prop with the new label order
                (0, ts_common_1._keys)(structuredFreeText).forEach(key => {
                    structuredFreeText[key] = (0, utils_2.updateExpressionString)((0, utils_2.CollectLabelSwaps)(newOrder, oldOrder), structuredFreeText[key]);
                });
                await this.state.editable.updateObj({ atoms, structuredFreeText, expression });
                return;
            }
            //Handle sub-terms edge case
            if (consts_1.LogicType_Complex === atomsLogic) {
                const labelSwap = (0, utils_2.CollectLabelSwaps)(newOrder, oldOrder).map(swap => (0, ts_common_1.reduceToMap)((0, ts_common_1._keys)(swap), item => item, item => swap[item].replace(/([A-Za-z]+)(\d+)/g, '$2')));
                const newSubTerms = (0, ts_common_1.deepClone)(this.state.editable.item.subTerms).map(subTerm => {
                    subTerm.atoms = subTerm.atoms.map(atom => {
                        const swapNew = labelSwap.find(swap => swap.oldLabel === atom.leftHand.id);
                        const swapOld = labelSwap.find(swap => swap.newLabel === atom.leftHand.id);
                        if (!swapNew && !swapOld)
                            return atom;
                        if (swapNew)
                            atom.leftHand.id = swapNew.newLabel;
                        if (swapOld)
                            atom.leftHand.id = swapOld.oldLabel;
                        return atom;
                    });
                    return subTerm;
                });
                await this.state.editable.updateObj({
                    subTerms: newSubTerms,
                    atoms,
                    expression
                });
                return;
            }
            await this.state.editable.updateObj({ atoms, expression });
        };
        //######################### Render - Atoms #########################
        this.renderAtomLabel = (index, isUnused, isDragged, onDragStart, onDragEnd) => {
            var _a;
            if (this.props.isSubTerm)
                return '';
            const className = (0, frontend_1._className)('atom-label-container', isDragged && 'grabbing ');
            const labelClassName = (0, frontend_1._className)('atom-label', isUnused && 'warning');
            const shouldRender = ((_a = this.state.editable.item.atoms) === null || _a === void 0 ? void 0 : _a.length) > 1 && !this.state.viewMode;
            return React.createElement(frontend_1.LL_H_C, { className: className },
                shouldRender && React.createElement(styles_1.ICONS.dragHandle.component, { onDragStart: e => {
                        const container = e.currentTarget.parentNode.parentNode.parentNode;
                        e.dataTransfer.effectAllowed = 'move';
                        e.dataTransfer.setData('text/html', container);
                        e.dataTransfer.setDragImage(container, 30, 30);
                        onDragStart(e, index);
                    }, onDragEnd: onDragEnd, draggable: true }),
                React.createElement("div", Object.assign({ className: labelClassName }, (isUnused) && Object.assign({}, frontend_1.openContent.tooltip.top('warning-tooltip', () => React.createElement(React.Fragment, null, "Atom is not in used in the sub terms"), { offset: 6 }))), `A${index + 1}:`));
        };
        this.renderAtom = (props) => {
            const atoms = (this.props.isSubTerm && this.props.termAtoms) ? this.props.termAtoms : undefined;
            const unusedAtoms = this.getUnusedAtoms();
            const isUnused = !!(unusedAtoms === null || unusedAtoms === void 0 ? void 0 : unusedAtoms.find(atoms => { var _a, _b; return ((_a = atoms.leftHand) === null || _a === void 0 ? void 0 : _a.id) === ((_b = props.item.editable.get('leftHand')) === null || _b === void 0 ? void 0 : _b.id); }));
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { onDragOver: e => props.onDragOver(e, props.index), onDragLeave: e => props.onDragLeave(e, props.index), label: this.renderAtomLabel(props.index, isUnused, props.dragged, props.onDragStart, props.onDragEnd), key: props.index, className: "match_width" },
                React.createElement(frontend_1.LL_V_L, { className: 'match_parent flex__grow', style: { gap: '5px' } },
                    React.createElement(Editor_Atom_1.Editor_Atom, { deleteAtom: () => this.deleteAtom(props.index), key: `atom-${props.index}`, editable: props.item.editable, atoms: atoms, viewMode: this.state.viewMode })));
        };
        this.renderAtoms = () => {
            const editableAtomsArray = this.state.editable.editProp('atoms', []);
            const editableAtoms = editableAtomsArray.item.map((term, index) => {
                return { editable: editableAtomsArray.editProp(index, {}), label: `A${index + 1}` };
            });
            return React.createElement(frontend_1.Grid, { className: 'condition-container' },
                React.createElement(TS_ListOrganizer_1.TS_ListOrganizer, { items: editableAtoms, renderer: this.renderAtom, onOrderChanged: (newOrder) => this.handleDnD(newOrder, editableAtoms) }),
                this.renderTermValue(),
                this.renderButtons());
        };
        //######################### Render - Editor Body #########################
        this.renderButtons = () => {
            if (this.state.viewMode)
                return '';
            const addButtonLabel = this.state.toggleValue ? 'Remove Term Value' : 'Add Term Value';
            const addAtomLabel = this.props.isSubTerm ? 'Add Option' : 'Add Atom';
            const className = (0, frontend_1._className)('button-container', this.props.isSubTerm && 'no-margin');
            return React.createElement(frontend_1.LL_H_C, { className: className },
                this.canAddNewAtom() && React.createElement(frontend_1.TS_Button, { onClick: this.addEmptyAtom, className: 'add-entity-btn' }, addAtomLabel),
                this.canAddTermValue() && React.createElement(frontend_1.TS_Button, { onClick: this.toggleAddValue, className: 'add-entity-btn' }, addButtonLabel));
        };
        this.renderTermValue = () => {
            if (!this.state.toggleValue && this.state.editable.get('atomsLogic') !== consts_1.LogicType_Complex)
                return '';
            const termValueLabel = this.props.isSubTerm ? 'Sub-Term Value:' : 'Term Value:';
            const termFallbackValueLabel = 'Term Else Value:';
            const rowClassName = (0, frontend_1._className)('term-editor__value-input', this.props.isSubTerm && 'no-margin');
            const containerClassName = (0, frontend_1._className)('value-input__input-container');
            const atomsLogic = this.state.editable.get('atomsLogic');
            return React.createElement(frontend_1.LL_V_L, { style: { gap: '5px' } },
                atomsLogic !== consts_1.LogicType_Complex && React.createElement(frontend_1.LL_H_C, { className: rowClassName },
                    React.createElement("div", { className: 'value-input__value-label' }, termValueLabel),
                    React.createElement(frontend_1.LL_H_C, { className: containerClassName },
                        React.createElement("div", { className: 'divider' }),
                        React.createElement(QRangeInputV3_1.QRangeInputV3, { placeholder: "Value", editable: this.state.editable, prop: 'value', showErrorTooltip: true }))),
                !this.props.isSubTerm && React.createElement(frontend_1.LL_H_C, { className: rowClassName },
                    React.createElement("div", { className: 'value-input__value-label' }, termFallbackValueLabel),
                    React.createElement(frontend_1.LL_H_C, { className: containerClassName },
                        React.createElement("div", { className: 'divider' }),
                        React.createElement(QRangeInputV3_1.QRangeInputV3, { placeholder: "Value", editable: this.state.editable, prop: 'fallbackValue', showErrorTooltip: true }))));
        };
        this.renderSubTermsEditor = () => {
            if (!_entity_1.ModuleFE_Expression.isTerm.withSubTerms(this.state.editable))
                return '';
            return React.createElement(Component_StructuredComplexLogic_1.Component_StructuredComplexLogic, { resetExpression: () => this.setState({ prevSubTerms: undefined }, async () => {
                    const changes = {};
                    const atoms = this.state.editable.get('atoms');
                    if (atoms.length > 1)
                        changes.atomsLogic = consts_1.LogicType_And;
                    changes.subTerms = undefined;
                    changes.fallbackValue = undefined;
                    changes.expression = this.updateTerm((atoms !== null && atoms !== void 0 ? atoms : []), changes.atomsLogic);
                    await this.state.editable.updateObj(changes);
                }), editable: this.state.editable });
        };
        this.renderFreeTextInput = (editableFreeText, keyToEdit) => {
            return React.createElement(ui_components_1.DefaultEditor_InputText_Optional, { editable: editableFreeText, prop: keyToEdit, saveEvent: ['blur'], onChange: value => {
                    return editableFreeText.update(keyToEdit, (value === null || value === void 0 ? void 0 : value.length) ? value : undefined);
                }, showErrorTooltip: true });
        };
        this.renderStructuredFreeText = () => {
            const atomsLogic = this.state.editable.get('atomsLogic');
            if (atomsLogic !== consts_1.LogicType_StructuredFreeText)
                return '';
            const editableFreeText = this.state.editable.editProp('structuredFreeText', {});
            return React.createElement(frontend_1.LL_H_C, { className: 'term-editor__structured-free-text' },
                React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'If:' }, this.renderFreeTextInput(editableFreeText, 'if')),
                React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Then:' }, this.renderFreeTextInput(editableFreeText, 'then')),
                React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Else:' }, this.renderFreeTextInput(editableFreeText, 'else')),
                React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Output Type:' },
                    React.createElement(ui_components_2.DropDown_ExpressionOutputTypes.editable, { showErrorTooltip: true, queryFilter: item => {
                            if (this.props.expressionType === consts_1.ExpressionType_NormalRange.dbKey)
                                return item === consts_1.ExpressionOutput_Range;
                            return item !== consts_1.ExpressionOutput_Range;
                        }, editable: this.state.editable, prop: 'outputType' })));
        };
        this.renderAtomsLogic = () => {
            var _a, _b;
            const termItem = this.state.editable.item;
            const AtomsLength = (_b = (_a = termItem.atoms) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
            const operator = termItem.atomsLogic;
            if (AtomsLength <= 1)
                return '';
            const className = (0, frontend_1._className)('logic-picker', termItem.atomsLogic === consts_1.LogicType_And ? 'and' : 'or', this.props.viewMode && 'disabled');
            return React.createElement(frontend_1.LL_V_L, { className: 'bridge' },
                React.createElement(frontend_1.LL_H_C, { className: className, onClick: this.setAtomsLogic }, operator));
        };
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'match_parent flex__grow' },
                this.renderAtomsLogic(),
                this.renderAtoms());
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        state = super.deriveStateFromProps(nextProps, state);
        state.viewMode = (_a = nextProps.viewMode) !== null && _a !== void 0 ? _a : false;
        const value = state.editable.get('value');
        const fallbackValue = state.editable.get('fallbackValue');
        state.toggleValue = ((0, ts_common_1.exists)(value) || (0, ts_common_1.exists)(fallbackValue)) ? true : nextProps.isSubTerm ? nextProps.isSubTerm : false;
        state.prevSubTerms = (_b = state.editable.item.subTerms) !== null && _b !== void 0 ? _b : state.prevSubTerms;
        return state;
    }
    render() {
        const className = (0, frontend_1._className)('term');
        return (React.createElement(frontend_1.LL_V_L, { className: className },
            this.renderBody(),
            this.renderSubTermsEditor(),
            this.renderStructuredFreeText()));
    }
}
exports.Editor_Term = Editor_Term;
