"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Score = exports.ModuleFE_Score_Class = exports.dispatch_onScoresUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const frontend_2 = require("../../variable/frontend");
exports.dispatch_onScoresUpdated = new types_1.ThunderDispatcherV3('__onScoresUpdated');
class ModuleFE_Score_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Score, exports.dispatch_onScoresUpdated);
        this.__searchFilterMapper = (item) => [frontend_2.ModuleFE_Variable.cache.unique(item.variableId).name];
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => {
                    const variable = frontend_2.ModuleFE_Variable.cache.unique(i.variableId);
                    return { _id: i._id, uiLabel: variable.name };
                })
            }]);
    }
}
exports.ModuleFE_Score_Class = ModuleFE_Score_Class;
exports.ModuleFE_Score = new ModuleFE_Score_Class();
