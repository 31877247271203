"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_TimeRange = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const time_range_input_1 = require("../time-range-input");
const frontend_2 = require("@app/styles/frontend");
require("./Editor_TimeRange.scss");
class Editor_TimeRange extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.resolveMiddleIndication = () => {
            if (this.state.editable.hasErrors())
                return React.createElement(frontend_1.LL_H_C, { style: { minWidth: '20px', justifyContent: 'center' } },
                    React.createElement(frontend_2.IconWithTooltip, { content: this.state.editable.hasError(), iconKey: 'attention' }));
            return React.createElement(frontend_1.LL_H_C, { style: {
                    fontFamily: 'monospace',
                    minWidth: '20px',
                    justifyContent: 'center'
                } }, "-");
        };
    }
    render() {
        return React.createElement(frontend_1.LL_H_C, { className: 'time-range-editor' },
            React.createElement(time_range_input_1.Component_TimeRangeInput, { placeholder: '0', editable: this.state.editable, index: 0 }),
            this.resolveMiddleIndication(),
            React.createElement(time_range_input_1.Component_TimeRangeInput, { placeholder: '∞', className: 'infinity', editable: this.state.editable, index: 1 }));
    }
}
exports.Editor_TimeRange = Editor_TimeRange;
