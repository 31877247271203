"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_MedicationRequest_StatusOptions_ToLabelsMap = exports.FHIR_MedicationRequest_StatusOptions = exports.Status_Active = exports.Status_Stopped = exports.Status_Completed = exports.Status_OnHold = exports.FHIR_MedicationRequest_CategoryOptions_ToLabelsMap = exports.FHIR_MedicationRequest_CategoryOptions = exports.Category_Discharge = exports.Category_Community = exports.Category_Outpatient = exports.Category_Inpatient = void 0;
exports.Category_Inpatient = 'Inpatient';
exports.Category_Outpatient = 'Outpatient';
exports.Category_Community = 'Community';
exports.Category_Discharge = 'Discharge';
exports.FHIR_MedicationRequest_CategoryOptions = [
    exports.Category_Inpatient,
    exports.Category_Outpatient,
    exports.Category_Community,
    exports.Category_Discharge,
];
exports.FHIR_MedicationRequest_CategoryOptions_ToLabelsMap = {
    [exports.Category_Inpatient]: 'Inpatient',
    [exports.Category_Outpatient]: 'Outpatient',
    [exports.Category_Community]: 'Community',
    [exports.Category_Discharge]: 'Discharge',
};
exports.Status_OnHold = 'on-hold';
exports.Status_Completed = 'completed';
exports.Status_Stopped = 'stopped';
exports.Status_Active = 'active';
exports.FHIR_MedicationRequest_StatusOptions = [
    exports.Status_OnHold,
    exports.Status_Completed,
    exports.Status_Stopped,
    exports.Status_Active,
];
exports.FHIR_MedicationRequest_StatusOptions_ToLabelsMap = {
    [exports.Status_OnHold]: 'On Hold',
    [exports.Status_Completed]: 'Completed',
    [exports.Status_Stopped]: 'Stopped',
    [exports.Status_Active]: 'Active',
};
