import {
	WorkspaceConfig_HealthcareSpace
} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_HealthcareSpace';
import {
	WorkspaceConfig_DiseaseProfile
} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_DiseaseProfile';
import {
	WorkspaceConfig_DiseaseProfile_Likelihood
} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_DiseaseProfile_Likelihood';
import {
	WorkspaceConfig_DiseaseProfile_ChiefComplaint
} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_DiseaseProfile_ChiefComplaint';
import {WorkspaceConfig_Advisor} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_Advisor';
import {WorkspaceConfig_PatientViewer} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_PatientViewer';
import {WorkspaceConfig_Search} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_Search';
import {
	WorkspaceConfig_ExpressionSearch,
	WorkspaceKey_ExpressionSearch
} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_ExpressionBuilder';
import {WorkspaceConfig_SearchV2} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_SearchV2';
import {WorkspaceConfig_ManagerPathway, workspaceKey_ManagerPathway,} from '@app/pathway/frontend';
import {
	WorkspaceConfig_ResourceEditor,
	workspaceKey_ResourceEditor
} from '@app/hcs/frontend/ui/Page_ResourceEditor/workspace-config';
import {WorkspaceConfig_OrganizationEditor, workspaceKey_OrganizationEditor} from '@app/org/frontend';
import {workspaceKey_AdvisorContext} from '@app/advisor-content/frontend/ui/advisor-context-manager/workspace-config';
import {WorkspaceConfig_PermissionsEditor, WorkspaceKey_Permissions} from '@km/managers/Manager_Permissions/workspace';
import {
	WorkspaceConfig_OrderEditor,
	workspaceKey_OrderEditor
} from '@app/pathway/frontend/ui/Page_OrderEditor/workspace-config';
import {
	WorkspaceConfig_LogTemplateManager,
	workspaceKey_LogTemplate
} from '@app/advisor-content/frontend/ui/log-template-manager/workspace-config';
import {
	WorkspaceConfig_DiseaseProfile_VarList
} from '@modules/WorkspaceModuleFE/workspaceConfigs/WorkspaceConfig_DiseaseProfile_VarList';

export const WorkspaceKey_Search = 'workspace-key--search';

export const defaultWorkspaceConfigs = {
	'healthcare-space': WorkspaceConfig_HealthcareSpace,
	'disease-profiles': WorkspaceConfig_DiseaseProfile,
	'disease-profile-likelihood': WorkspaceConfig_DiseaseProfile_Likelihood,
	'disease-profile-chief-complaint': WorkspaceConfig_DiseaseProfile_ChiefComplaint,
	'disease-profile-var-list': WorkspaceConfig_DiseaseProfile_VarList,
	'advisor': WorkspaceConfig_Advisor,
	'patient-viewer': WorkspaceConfig_PatientViewer,
	[WorkspaceKey_ExpressionSearch]: WorkspaceConfig_ExpressionSearch,
	'search': WorkspaceConfig_Search,
	[WorkspaceKey_Search]: WorkspaceConfig_SearchV2,
	[workspaceKey_ManagerPathway]: WorkspaceConfig_ManagerPathway,
	[workspaceKey_ResourceEditor]: WorkspaceConfig_ResourceEditor,
	[workspaceKey_OrganizationEditor]: WorkspaceConfig_OrganizationEditor,
	[workspaceKey_OrderEditor]: WorkspaceConfig_OrderEditor,
	[workspaceKey_AdvisorContext]: WorkspaceConfig_OrganizationEditor,
	[WorkspaceKey_Permissions]: WorkspaceConfig_PermissionsEditor,
	[workspaceKey_LogTemplate]: WorkspaceConfig_LogTemplateManager
};
