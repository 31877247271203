"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorWrapper_ResourcesV2 = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Component_ManagerSubHeader_1 = require("../../shared-components/Component_ManagerSubHeader/Component_ManagerSubHeader");
const ResourceItem_EditorRendererV2_1 = require("./ResourceItem_EditorRendererV2");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
class EditorWrapper_ResourcesV2 extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.isInEditMode || (state.isInEditMode = state.editable && !state.editable.get('_id'));
        return state;
    }
    render() {
        return this.state.editable && React.createElement(frontend_1.LL_V_L, { className: 'match_parent' },
            React.createElement(Component_ManagerSubHeader_1.Component_ManagerSubHeader, { isInEditMode: this.state.isInEditMode, buttons: [
                    React.createElement(frontend_1.TS_Button, { style: { display: this.state.editable.item._id ? 'flex' : 'none' }, className: `${this.state.isInEditMode ? 'selected' : ''}`, onClick: () => this.setState({ isInEditMode: !this.state.isInEditMode }) }, this.state.isInEditMode ? 'VIEW MODE' : 'EDIT MODE'),
                    React.createElement(frontend_1.TS_Button, { className: 'selected', style: { display: this.state.editable.item._id ? 'flex' : 'none' }, onClick: async () => {
                            if (this.state.editable.get('_id'))
                                await this.state.editable.delete();
                        } }, "Delete Resource"),
                    React.createElement(frontend_1.TS_Button, { className: 'selected', style: { display: !this.state.editable.item._id ? 'flex' : 'none' }, onClick: async () => {
                            if (!this.state.editable.get('_id'))
                                await this.state.editable.save();
                        } }, "Save")
                ] }),
            React.createElement(ResourceItem_EditorRendererV2_1.ResourceItem_EditorRendererV2, { editable: this.state.editable, editMode: !!this.state.isInEditMode }));
    }
}
exports.EditorWrapper_ResourcesV2 = EditorWrapper_ResourcesV2;
