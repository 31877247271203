"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Action = exports.ModuleFE_Action_Class = exports.dispatch_onActionChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onActionChanged = new types_1.ThunderDispatcherV3('__onActionUpdated');
class ModuleFE_Action_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Action, exports.dispatch_onActionChanged);
        this.__searchFilterMapper = (item) => [item.name];
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => ({ _id: i._id, uiLabel: i.name }))
            }]);
        this.is = { order: shared_1.pathwayAction_isOrder, interflow: shared_1.pathwayAction_isInterflow };
        this._v1 = {};
    }
}
exports.ModuleFE_Action_Class = ModuleFE_Action_Class;
exports.ModuleFE_Action = new ModuleFE_Action_Class();
