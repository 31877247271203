"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_DiseaseView = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
const validateRole = (0, ts_common_1.tsValidateValue)([...hcs_1.roles]);
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateString)(),
    type: (0, ts_common_1.tsValidateValue)([...types_1.dpViewTypes]),
    enforcedRole: validateRole,
    enforcedTags: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    filter: {
        roles: (0, ts_common_1.tsValidateArray)(validateRole, false),
        tagIds: {
            whitelist: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
            blacklist: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
        },
    },
};
const Validator_GeneratedProps = {};
exports.DBDef_DiseaseView = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1'],
    dbKey: 'disease-profile-view-configs',
    entityName: 'DiseaseView',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'disease-view'
    },
    backend: {
        name: 'disease-profile-view-configs'
    },
    dependencies: {
        'enforcedTags': {
            dbKey: 'tags',
            fieldType: 'string[]'
        },
        'filter.tagIds.blacklist': {
            dbKey: 'tags',
            fieldType: 'string[]'
        },
        'filter.tagIds.whitelist': {
            dbKey: 'tags',
            fieldType: 'string[]'
        }
    }
};
