"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathwayStateTypeLabelMap = void 0;
exports.PathwayStateTypeLabelMap = {
    admit: 'Admit',
    initial: 'Initial',
    reassess: 'Reassess',
    discharge: 'Discharge',
    assessment: 'Assessment',
};
