"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_PrevalenceEditor = void 0;
const React = __importStar(require("react"));
const ts_common_1 = require("@nu-art/ts-common");
const Layouts_1 = require("@nu-art/thunderstorm/frontend/components/Layouts/Layouts");
const Adapter_1 = require("@nu-art/thunderstorm/frontend/components/adapter/Adapter");
const frontend_1 = require("@app/hcs/frontend");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
class Component_PrevalenceEditor extends frontend_2.ComponentSync {
    constructor() {
        super(...arguments);
        this.updateProfilePrevalence = (prevalence) => {
            this.logInfo(prevalence);
            if (!prevalence) {
                this.deletePrevalence();
                return;
            }
            const normalizePrevalenceValue = (value) => {
                value = Math.min(value, 100);
                value = Math.max(value, 0);
                return value;
            };
            if (prevalence) {
                prevalence[0] = normalizePrevalenceValue(prevalence[0]);
                prevalence[1] = normalizePrevalenceValue(prevalence[1]);
                this.setState({ prevalence }, () => {
                    this.props.onChanged([normalizePrevalenceValue(prevalence[0] / 100), normalizePrevalenceValue(prevalence[1] / 100)]);
                });
            }
        };
        this.deletePrevalence = () => {
            this.props.onChanged(undefined);
            this.setState({ prevalence: undefined });
        };
        this.renderInput = () => {
            if (this.props.disableEdit)
                return this.state.prevalence ? React.createElement("div", null,
                    this.state.prevalence[0],
                    " - ",
                    this.state.prevalence[0]) : '';
            return React.createElement(frontend_1.QRangeInput, { placeholder: 'Range between 0-100', value: this.state.prevalence ? [+this.state.prevalence[0].toFixed(2), +this.state.prevalence[1].toFixed(2)] : undefined, onValueBlur: val => this.updateProfilePrevalence(val) });
        };
    }
    deriveStateFromProps(nextProps, state) {
        const propPrevalence = nextProps.prevalence ? (0, ts_common_1.cloneObj)(nextProps.prevalence) : undefined;
        state.prevalence = state.prevalence || propPrevalence ? [propPrevalence[0] * 100, propPrevalence[1] * 100] : undefined;
        return state;
    }
    render() {
        return React.createElement(Layouts_1.LL_V_L, { className: 'match_width', onContextMenu: (e) => {
                if (this.props.disableEdit)
                    return;
                const menuItems = [
                    { label: 'Remove', onClick: () => this.deletePrevalence() },
                ];
                const rightClickMenu = {
                    id: 'prognosis-right-click-menu',
                    adapter: (0, Adapter_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label)),
                    originPos: { y: e.clientY, x: e.clientX, },
                    modalPos: { x: 0, y: 1 },
                    onNodeClicked: (path, item) => {
                        var _a;
                        frontend_2.ModuleFE_MouseInteractivity.hide(frontend_2.mouseInteractivity_PopUp);
                        (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
                    }
                };
                frontend_2.ModuleFE_MouseInteractivity.showMenu(rightClickMenu);
            } }, this.renderInput());
    }
}
exports.Component_PrevalenceEditor = Component_PrevalenceEditor;
