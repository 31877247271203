"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveResolutionFunctionVariables = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@app/hcs/_entity/expression/frontend");
/**
 * Resolve all variables from the resolution functions
 * @param resolutionFunctions All resolution functions defined in the editor
 * @private
 */
const resolveResolutionFunctionVariables = (resolutionFunctions) => {
    return resolutionFunctions.reduce((variableIds, currentResolutionFunction) => {
        let collectedVariables = [];
        if (currentResolutionFunction._refs) {
            collectedVariables = collectedVariables.concat(resolveExpressionVariables(currentResolutionFunction));
        }
        return [...variableIds, ...collectedVariables];
    }, []);
};
exports.resolveResolutionFunctionVariables = resolveResolutionFunctionVariables;
const resolveExpressionVariables = (expression) => {
    var _a, _b;
    const variableIds = [];
    // first collect the resolution function itself variable ids from refs
    if ((_a = expression._refs) === null || _a === void 0 ? void 0 : _a._variableIds)
        variableIds.push(...expression._refs._variableIds);
    // // then check if the expression reference another expressions
    if ((_b = expression._refs) === null || _b === void 0 ? void 0 : _b._exprIds.length) {
        // run over all the expression ids and recursively collect all ids from the sub expressions
        for (const exprId of expression._refs._exprIds) {
            const subExpression = frontend_1.ModuleFE_Expression.cache.unique(exprId);
            if (!subExpression)
                throw new ts_common_1.MUSTNeverHappenException(`expression with id ${exprId} not found`);
            variableIds.push(...resolveExpressionVariables(subExpression));
        }
    }
    return variableIds;
};
