"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_AdvisorContextManager = void 0;
const React = require("react");
const frontend_1 = require("@app/styles/frontend");
const workspace_config_1 = require("./workspace-config");
const frontend_2 = require("@nu-art/ts-workspace/frontend");
const frontend_3 = require("../../../_entity/advisor-context/frontend");
const frontend_4 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const module_pack_1 = require("../../../_entity/advisor-context/frontend/module-pack");
const frontend_5 = require("@app/hcs/frontend");
const Component_AdvisorContextEditor_1 = require("./presenter/Component_AdvisorContextEditor");
const EditorKey_AdvisorContext = 'advisor-context-manager';
class Page_AdvisorContextManager extends frontend_1.Page_BaseManagerUI_SingleType_Proto {
    constructor() {
        super(...arguments);
        this.workspace = {
            id: EditorKey_AdvisorContext,
            key: workspace_config_1.workspaceKey_AdvisorContext,
            renderers: {
                horizontalSpace: frontend_2.TS_HorizontalWorkspace,
            },
            instances: {
                selector: this.renderSelector.bind(this),
                presenter: this.renderPresenter.bind(this),
            }
        };
        this.onCreateNewAdvisorContext = () => {
            this.clearSelectedResult();
            const newContext = this.getEditableItem();
            this.setState({
                selectedItem: newContext,
                selectedResult: undefined
            });
        };
        this.deselectAdvisorContext = () => {
            this.clearSelectedResult();
            this.setState({
                selectedItem: undefined,
                selectedResult: undefined
            });
        };
        this.saveAdvisorContext = async () => {
            const editable = this.state.selectedItem;
            if (!editable)
                return;
            editable.validate();
            if (editable.hasErrors())
                return this.setState({ selectedItem: editable });
            try {
                const newItem = await editable.save(false);
                this.onSearchResultSelected(newItem._id, Page_AdvisorContextManager.SearchableItem_AdvisorContext.type);
            }
            catch (e) {
                this.logError(e);
            }
        };
        this.deleteAdvisorContext = () => {
            const advisorContext = this.state.selectedItem;
            if (!advisorContext)
                throw new ts_common_1.MUSTNeverHappenException('Triggered deleting Advisor Context without selecting any');
            frontend_1.Dialog_Confirmation.show({
                header: 'Are you sure?',
                content: React.createElement(React.Fragment, null,
                    React.createElement("div", null, "You are about to delete an advisor context"),
                    React.createElement("div", null, advisorContext.get('label'))),
                buttons: [
                    {
                        label: 'Yes, Delete', isAsync: true, action: async () => {
                            await advisorContext.delete();
                            this.deselectAdvisorContext();
                        }
                    },
                    { label: 'Don\'t Delete', isPreferred: true, action: ts_common_1.voidFunction }
                ]
            });
        };
    }
    getInitialObject() {
        return {};
    }
    renderSelector() {
        return React.createElement(frontend_1.SearchSelector_BaseManagerUI, { selectedItem: this.state.selectedResult, onSearchItemSelected: (item) => this.onSearchResultSelected(item.id, item.type), searchItems: [Page_AdvisorContextManager.SearchableItem_AdvisorContext], buttons: [{
                    label: 'Add Advisor Context',
                    action: this.onCreateNewAdvisorContext
                }] });
    }
    renderPresenter() {
        if (!this.state.selectedItem)
            return React.createElement(frontend_5.QPageMessage, { message: 'Advisor Context Manager' });
        return React.createElement(Component_AdvisorContextEditor_1.Component_AdvisorContextEditor, { deleteAdvisorContext: this.deleteAdvisorContext, saveAdvisorContext: this.saveAdvisorContext, deselectAdvisorContext: this.deselectAdvisorContext, editable: this.state.selectedItem, editMode: true });
    }
}
_a = Page_AdvisorContextManager;
Page_AdvisorContextManager.Route = {
    key: EditorKey_AdvisorContext,
    path: 'nlp-context',
    Component: _a,
    modulesToAwait: [...module_pack_1.ModulePackFE_AdvisorContext, frontend_5.ModuleFE_Tag, frontend_5.ModuleFE_Variable],
};
Page_AdvisorContextManager.searchItemKey_AdvisorContext = frontend_3.ModuleFE_AdvisorContext.dbDef.dbKey;
Page_AdvisorContextManager.SearchableItem_AdvisorContext = {
    module: frontend_3.ModuleFE_AdvisorContext,
    type: Page_AdvisorContextManager.searchItemKey_AdvisorContext,
    route: Page_AdvisorContextManager.Route,
    entityName: frontend_3.ModuleFE_AdvisorContext.dbDef.entityName,
    entityLabel: 'Advisor Context',
    groupId: ['Advisor-Content'],
    tags: [],
    mapper: (item) => [item.label],
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_1.Renderer_SearchResult, { itemId: item._id, itemKey: Page_AdvisorContextManager.searchItemKey_AdvisorContext, itemLabel: `${item.label}${item.type === 'multi' ? ' (Multi-Snippet)' : ''}`, entityLabel: 'Advisor Context', hierarchy: 'Advisor Content', selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (item) => {
        const advisorContext = frontend_3.ModuleFE_AdvisorContext.cache.unique(item);
        return React.createElement(Component_AdvisorContextEditor_1.Component_AdvisorContextEditor, { editable: new frontend_4.EditableDBItemV3(advisorContext, frontend_3.ModuleFE_AdvisorContext).setAutoSave(true), deselectAdvisorContext: ts_common_1.voidFunction, saveAdvisorContext: ts_common_1.voidFunction, deleteAdvisorContext: ts_common_1.voidFunction, editMode: true });
    }
};
Page_AdvisorContextManager.defaultProps = {
    searchItems: [Page_AdvisorContextManager.SearchableItem_AdvisorContext]
};
exports.Page_AdvisorContextManager = Page_AdvisorContextManager;
