"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pathwayAction_isInterflow = exports.pathwayAction_isOrder = void 0;
const pathwayAction_isOrder = (action) => {
    return (action === null || action === void 0 ? void 0 : action.type) === 'order';
};
exports.pathwayAction_isOrder = pathwayAction_isOrder;
const pathwayAction_isInterflow = (action) => {
    return (action === null || action === void 0 ? void 0 : action.type) === 'interflow';
};
exports.pathwayAction_isInterflow = pathwayAction_isInterflow;
