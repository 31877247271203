"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator_MetaPathwayAction = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const utils_1 = require("./utils");
const Validator_MetaPathwayAction_WithProtocol = {
    type: (0, ts_common_1.tsValidateValue)([...types_1.MetaPathwayAction_Types_WithProtocols]),
    protocolIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
const Validator_MetaPathwayAction_WithoutProtocol = {
    type: (0, ts_common_1.tsValidateValue)([...types_1.MetaPathwayAction_Types_WithoutProtocols]),
};
const Validator_MetaPathwayAction = (instance) => {
    const error = (0, ts_common_1.tsValidate)(instance, ts_common_1.tsValidateMustExist);
    if (error)
        return error;
    if ((0, utils_1.isMetaPathwayAction_WithProtocol)(instance))
        return (0, ts_common_1.tsValidate)(instance, Validator_MetaPathwayAction_WithProtocol);
    if ((0, utils_1.isMetaPathwayAction_WithoutProtocol)(instance))
        return (0, ts_common_1.tsValidate)(instance, Validator_MetaPathwayAction_WithoutProtocol);
    // @ts-ignore
    return `Missing validator for meta pathway action of type ${instance.type}`;
};
exports.Validator_MetaPathwayAction = Validator_MetaPathwayAction;
