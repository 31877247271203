"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_HcsVariables = void 0;
const React = require("react");
const Base_ResolutionFunctionEditorPanel_1 = require("../Base_ResolutionFunctionEditorPanel");
require("./Panel_HcsVariables.scss");
const frontend_1 = require("@app/hcs/frontend");
const exceptions_1 = require("@nu-art/ts-common/core/exceptions/exceptions");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
const utils_1 = require("../../utils");
const ts_common_1 = require("@nu-art/ts-common");
class Panel_HcsVariables extends Base_ResolutionFunctionEditorPanel_1.Base_ResolutionFunctionEditorPanel {
    constructor() {
        super(...arguments);
        this.panelId = 'hcs-variables';
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null, "HCS Variables");
        };
        this.renderBody = () => {
            if (!this.state.hcsVariables.length)
                return React.createElement("div", { className: 'not-found-title' }, "No variables found...");
            return React.createElement(React.Fragment, null, this.state.hcsVariables.map((_varId, index) => {
                const variable = frontend_1.ModuleFE_Variable.cache.unique(_varId);
                if (!variable)
                    throw new exceptions_1.MUSTNeverHappenException(`variable with id ${_varId} not found`);
                const params = { id: _varId, searchItem: 'variables' };
                return React.createElement("div", { key: `var-${index}`, onClick: () => frontend_2.ModuleFE_Thunderstorm.openUrl({ url: '/km/search-v2', params }, '_blank'), className: 'var-name' }, variable.name);
            }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        let hcsVariables = (0, ts_common_1.filterDuplicates)((0, utils_1.resolveResolutionFunctionVariables)(nextProps.resolutionFunctionItems));
        hcsVariables = (0, ts_common_1.sortArray)(hcsVariables, (id) => { var _a; return (_a = frontend_1.ModuleFE_Variable.cache.unique(id)) === null || _a === void 0 ? void 0 : _a._nameInLowercase; });
        state.hcsVariables = hcsVariables;
        return state;
    }
}
exports.Panel_HcsVariables = Panel_HcsVariables;
