"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_Book = exports.DropDown_Book = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_Book_1 = require("./ModuleFE_Book");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_Book_1.ModuleFE_Book,
    modules: [ModuleFE_Book_1.ModuleFE_Book],
    mapper: item => [item.name],
    placeholder: 'Choose a Book',
    renderer: item => React.createElement(React.Fragment, null, item.name)
};
exports.DropDown_Book = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Book_1.ModuleFE_Book,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }),
            item.name);
    },
    uiSelector: exports.DropDown_Book.selectable,
});
exports.MultiSelect_Book = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
