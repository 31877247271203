"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionKeyFE_OrganizationV2Viewer = exports.PermissionKeyFE_OrganizationV1Viewer = exports.PermissionKeyFE_OrganizationDelete = exports.PermissionKeyFE_OrganizationEdit = exports.PermissionKeyFE_OrganizationView = exports.PermissionKeyFE_OrganizationGeneral = void 0;
const PermissionKey_FE_1 = require("@nu-art/permissions/frontend/PermissionKey_FE");
const shared_1 = require("../shared");
exports.PermissionKeyFE_OrganizationGeneral = new PermissionKey_FE_1.PermissionKey_FE(shared_1.PermissionKey_OrganizationGeneral);
exports.PermissionKeyFE_OrganizationView = new PermissionKey_FE_1.PermissionKey_FE(shared_1.PermissionKey_OrganizationView);
exports.PermissionKeyFE_OrganizationEdit = new PermissionKey_FE_1.PermissionKey_FE(shared_1.PermissionKey_OrganizationEdit);
exports.PermissionKeyFE_OrganizationDelete = new PermissionKey_FE_1.PermissionKey_FE(shared_1.PermissionKey_OrganizationDelete);
exports.PermissionKeyFE_OrganizationV1Viewer = new PermissionKey_FE_1.PermissionKey_FE(shared_1.PermissionKey_OrganizationV1Viewer);
exports.PermissionKeyFE_OrganizationV2Viewer = new PermissionKey_FE_1.PermissionKey_FE(shared_1.PermissionKey_OrganizationV2Viewer);
