"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultEditor_TextArea = exports.DefaultEditor_InputTime = exports.DefaultEditor_InputText_Array = exports.DefaultEditor_InputText_Optional = exports.DefaultEditor_InputText = exports.DefaultEditor_InputNumeric_Optional = exports.DefaultEditor_InputNumeric = void 0;
const TS_V2_Input_1 = require("@nu-art/thunderstorm/frontend/components/TS_V2_Input");
const TS_V2_TextArea_1 = require("@nu-art/thunderstorm/frontend/components/TS_V2_TextArea");
exports.DefaultEditor_InputNumeric = TS_V2_Input_1.TS_InputV2.editableNumber({ type: 'number', saveEvent: ['blur', 'accept'] });
exports.DefaultEditor_InputNumeric_Optional = TS_V2_Input_1.TS_InputV2.editableNumberOptional({ type: 'number', saveEvent: ['blur', 'accept'] });
exports.DefaultEditor_InputText = TS_V2_Input_1.TS_InputV2.editable({ type: 'text', saveEvent: ['blur', 'accept'] });
exports.DefaultEditor_InputText_Optional = TS_V2_Input_1.TS_InputV2.editableOptional({ type: 'text', saveEvent: ['blur', 'accept'] });
exports.DefaultEditor_InputText_Array = TS_V2_Input_1.TS_InputV2.editableArray({ type: 'text', saveEvent: ['blur', 'accept'] });
exports.DefaultEditor_InputTime = TS_V2_Input_1.TS_InputV2.editableTime({ type: 'time', saveEvent: ['blur'] });
exports.DefaultEditor_TextArea = TS_V2_TextArea_1.TS_TextAreaV2.editable({ saveEvent: ['blur'], trim: true });
