"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Section_StateTransitions = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../../../../../../../_entity/pathway-transition/frontend");
const frontend_3 = require("@app/styles/frontend");
const frontend_4 = require("../../../../../../../../_entity/pathway-state/frontend");
const Component_CollapsablePrettyPrint_1 = require("../../../common-components/Component_CollapsablePrettyPrint");
const styles_1 = require("@app/styles");
const frontend_5 = require("../../../../../../../../_entity/action/frontend");
const OrderTypeToLabel = {
    'nurseIds': 'Nurse',
    'testsIds': 'Tests',
    'consultIds': 'Consult',
    'treatmentsIds': 'Treatments',
};
class Section_StateTransitions extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        /**
         * Render the transition order list by types
         * @param transition DB pathway transition to visualize its orders
         */
        this.renderTransitionOrders = (transition) => {
            const orderKeys = ['treatmentsIds', 'nurseIds', 'testsIds', 'consultIds'];
            const transitionOrderMapper = (0, ts_common_1._keys)(transition).reduce((mapper, currKey) => {
                if (orderKeys.includes(currKey)) {
                    mapper[currKey] = transition[currKey];
                }
                return mapper;
            }, {});
            return React.createElement(frontend_1.LL_H_C, { className: 'section-value' },
                React.createElement(frontend_1.TS_CollapsableContainer, { headerRenderer: () => React.createElement("div", { className: 'collapsable-label' }, "Orders"), containerRenderer: () => {
                        if (!(0, ts_common_1._values)(transitionOrderMapper).flat().length)
                            return React.createElement("div", { style: {
                                    lineHeight: '20px',
                                }, className: 'no-result-line' }, "No orders...");
                        return React.createElement(frontend_3.DataGrid.Grid, { className: 'order-grid' }, (0, ts_common_1._keys)(transitionOrderMapper).map((orderTypeKey, index) => {
                            const ordersToRender = transitionOrderMapper[orderTypeKey].map(orderId => {
                                const DBOrder = frontend_5.ModuleFE_Action.cache.unique(orderId);
                                if (!DBOrder)
                                    throw new ts_common_1.MUSTNeverHappenException(`order with id ${orderId} not found`);
                                return DBOrder;
                            });
                            return React.createElement(React.Fragment, { key: index },
                                React.createElement(frontend_3.DataGrid.Label, null, OrderTypeToLabel[orderTypeKey]),
                                React.createElement(frontend_1.LL_V_L, null, !ordersToRender.length ?
                                    React.createElement("div", { style: {
                                            lineHeight: '20px',
                                        } }, "-") : ordersToRender.map((order, index) => {
                                    return React.createElement("div", { key: index, style: {
                                            lineHeight: '20px',
                                        } }, order.name);
                                })));
                        }));
                    }, customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null) }));
        };
    }
    __onPathwayTransitionUpdated(...params) {
        const pathwayStateId = this.props.pathwayStateId;
        if (!pathwayStateId)
            return;
        switch (params[0]) {
            case 'delete':
            case 'delete-multi': {
                const deletedTransition = (0, ts_common_1.asArray)(params[1]);
                if (deletedTransition === null || deletedTransition === void 0 ? void 0 : deletedTransition.some(deletedTransition => this.state.relevantTransitions.find(transition => transition._id === deletedTransition._id)))
                    return this.reDeriveState();
                break;
            }
            case 'update':
            case 'upsert-all':
            case 'create': {
                const updatedTransitions = (0, ts_common_1.asArray)(params[1]);
                if (updatedTransitions === null || updatedTransitions === void 0 ? void 0 : updatedTransitions.some(transition => { var _a; return (_a = transition.sourceIds) === null || _a === void 0 ? void 0 : _a.includes(pathwayStateId); }))
                    return this.reDeriveState();
                break;
            }
        }
    }
    deriveStateFromProps(nextProps, state) {
        const pathwayStateId = nextProps.pathwayStateId;
        state.relevantTransitions = (0, ts_common_1.sortArray)(frontend_2.ModuleFE_PathwayTransition.cache.filter(pt => { var _a; return ((_a = pt.sourceIds) === null || _a === void 0 ? void 0 : _a[0]) === pathwayStateId; }), item => item.priorityIndex);
        return state;
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement(frontend_3.DataGrid.Label, null, "Transitions"),
            !this.state.relevantTransitions.length && React.createElement(frontend_3.DataGrid.Data, null, "-"),
            !!this.state.relevantTransitions.length && React.createElement(frontend_1.LL_V_L, { style: { padding: '6px' } }, this.state.relevantTransitions.map(transition => {
                var _a, _b;
                const targetState = frontend_4.ModuleFE_PathwayState.cache.unique((_a = transition.targetIds) === null || _a === void 0 ? void 0 : _a[0]);
                if (!targetState)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find target state with id ${(_b = transition.targetIds) === null || _b === void 0 ? void 0 : _b[0]}`);
                return React.createElement(frontend_3.DataGrid.Data, { key: transition._id },
                    React.createElement(frontend_1.LL_V_L, { className: 'match_parent' },
                        React.createElement("div", { className: 'grid-label-with-items', onClick: () => this.props.functions.selectPathwayTransition(transition._id) }, (0, ts_common_1.exists)(transition.label) ? transition.label : targetState.name),
                        React.createElement(Component_CollapsablePrettyPrint_1.Component_CollapsablePrettyPrint, { functions: this.props.functions, transitionId: transition._id }),
                        this.renderTransitionOrders(transition)));
            })));
    }
}
exports.Section_StateTransitions = Section_StateTransitions;
