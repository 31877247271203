"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Manager_DiseaseView = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
require("./Manager_DiseaseView.scss");
const hcs_1 = require("@app/hcs");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../_entity");
class Manager_DiseaseView extends frontend_1.ComponentSync {
    constructor() {
        // ######################### Life Cycle #########################
        super(...arguments);
        // ######################### Logic #########################
        this.convertTagListToTagGroup = (tagList) => {
            const map = tagList.reduce((acc, tagId) => {
                const tag = frontend_2.ModuleFE_Tag.cache.unique(tagId);
                if (!tag)
                    throw new ts_common_1.BadImplementationException(`Could not find tag for id ${tagId}`);
                if (!acc[tag.tagIds[0]])
                    acc[tag.tagIds[0]] = [];
                acc[tag.tagIds[0]].push(tag);
                return acc;
            }, {});
            return (0, ts_common_1._keys)(map).reduce((acc, key) => {
                const tag = frontend_2.ModuleFE_Tag.cache.unique(key);
                if (!tag)
                    throw new ts_common_1.BadImplementationException(`Could not find tag for id ${key}`);
                acc.push({ key: tag, value: map[key] });
                return acc;
            }, []);
        };
        // ######################### Render - Header #########################
        this.renderHeader = () => {
            const view = this.state.view;
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__header' },
                React.createElement(frontend_1.LL_V_L, { className: 'manager__header__main' },
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__title' },
                        React.createElement("span", { className: 'manager__header__title__label' }, view.item.label)),
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__sub-title' },
                        React.createElement("span", { className: 'manager__header__sub-title__item' }, "Disease View"),
                        "in",
                        React.createElement("span", { className: 'manager__header__sub-title__hierarchy' }, 'Disease Profiles > Views'))));
        };
        // ######################### Render - Body #########################
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__disease-view__body' },
                this.renderLeft(),
                React.createElement("div", { className: 'data-separator' }),
                this.renderRight());
        };
        this.renderLeft = () => {
            var _a, _b, _c;
            const view = this.state.view;
            return React.createElement(frontend_1.Grid, { className: 'manager__data-grid' },
                React.createElement("div", { className: 'data-grid__label' }, "Label"),
                React.createElement("div", { className: 'data-grid__data' }, view.item.label),
                React.createElement("div", { className: 'data-grid__label' }, "Type"),
                React.createElement("div", { className: 'data-grid__data' }, view.item.type),
                React.createElement("div", { className: 'data-grid__label' }, "Enforced Tags"),
                React.createElement("div", { className: 'data-grid__data' }, (_b = (_a = view.item.enforcedTags) === null || _a === void 0 ? void 0 : _a.map(id => frontend_2.ModuleFE_Tag.cache.unique(id).label).join(', ')) !== null && _b !== void 0 ? _b : 'N/A'),
                React.createElement("div", { className: 'data-grid__label' }, "Enforced Role"),
                React.createElement("div", { className: 'data-grid__data' }, (_c = view.item.enforcedRole) !== null && _c !== void 0 ? _c : 'N/A'),
                React.createElement("div", { className: 'data-grid__label full-span' }, "Filter Roles"),
                hcs_1.roles.map(role => {
                    var _a, _b;
                    return React.createElement("div", { key: role, className: 'data-grid__data', style: { gap: '5px' } },
                        React.createElement(frontend_1.TS_Checkbox, { className: 'quai', icon: React.createElement(styles_1.ICONS.v.component, null), checked: (_b = (_a = view.item.filter) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes(role) }),
                        role);
                }),
                React.createElement("div", { className: 'data-grid__data' }));
        };
        this.renderRight = () => {
            var _a, _b, _c, _d;
            const view = this.state.view;
            const whitelist = this.convertTagListToTagGroup((_b = (_a = view.item.filter) === null || _a === void 0 ? void 0 : _a.tagIds.whitelist) !== null && _b !== void 0 ? _b : []);
            const blacklist = this.convertTagListToTagGroup((_d = (_c = view.item.filter) === null || _c === void 0 ? void 0 : _c.tagIds.blacklist) !== null && _d !== void 0 ? _d : []);
            return React.createElement(frontend_1.Grid, { className: 'manager__data-grid' },
                React.createElement("div", { className: 'data-grid__label full-span' }, "White List"),
                React.createElement(frontend_2.Component_TagEditor, { tags: whitelist, disableEdit: true, groupTagFilter: () => true, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id }),
                React.createElement("div", { className: 'data-grid__label full-span' }, "Black List"),
                React.createElement(frontend_2.Component_TagEditor, { tags: blacklist, disableEdit: true, groupTagFilter: () => true, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        if (!state.view || nextProps.viewId !== state.view.item._id) {
            //@ts-ignore
            delete state.view;
            const view = _entity_1.ModuleFE_DiseaseView.cache.unique(nextProps.viewId);
            if (!view)
                throw new ts_common_1.BadImplementationException(`Could not find view for id ${nextProps.viewId}`);
            (_a = state.view) !== null && _a !== void 0 ? _a : (state.view = new frontend_1.EditableDBItemV3(view, _entity_1.ModuleFE_DiseaseView));
        }
        return state;
    }
    // ######################### Render #########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'manager__disease-view', className: 'quai-item-manager' },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Manager_DiseaseView = Manager_DiseaseView;
