"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QToastContent = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
require("./QToaastContent.scss");
const QToastContent = (props) => {
    const renderIcon = () => {
        if (!props.iconKey)
            return;
        const Icon = styles_1.ICONS[props.iconKey];
        return React.createElement(Icon.component, null);
    };
    return React.createElement("div", { className: (0, frontend_1._className)('q-toast', props.toastType) },
        renderIcon(),
        React.createElement("span", { className: 'toast-text' }, props.content));
};
exports.QToastContent = QToastContent;
