"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Value = exports.Validator_ValueData = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const shared_1 = require("../../shared");
const labelValidator = (0, ts_common_1.tsValidateRegexp)(/^[A-Za-zÀ-ÖØ-öø-ÿ\d><=\-+'%:.&/ ]{2,100}$/, false);
const validator_subEnumerated = {
    value: labelValidator
};
const validator_range = {
    subsets: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    unitsId: ts_common_1.tsValidateOptionalId,
    scope: (0, ts_common_1.tsValidateRange)(),
};
const validator_enumerated = {
    scope: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    subsets: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    props: {
        isOrdered: (0, ts_common_1.tsValidateBoolean)(false),
        isExclusive: (0, ts_common_1.tsValidateBoolean)(false)
    },
};
const validator_subEnumeratedData = {
    scope: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    parentId: ts_common_1.tsValidateOptionalId,
    label: labelValidator,
};
const validator_TupleData = {
    scope: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    length: (0, ts_common_1.tsValidateNumber)()
};
const validator_normalRangeV2Data = {
    unitsId: ts_common_1.tsValidateOptionalId,
    fullRange: (0, ts_common_1.tsValidateRange)(),
    declarationVarIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    normalValues: (0, ts_common_1.tsValidateArray)({
        conditions: (0, ts_common_1.tsValidateDynamicObject)(ts_common_1.tsValidateUniqueId, ts_common_1.tsValidateUniqueId),
        range: (0, ts_common_1.tsValidateRange)()
    }),
};
const validator_subRangeData = {
    scope: (0, ts_common_1.tsValidateRange)(),
    parentId: ts_common_1.tsValidateOptionalId,
    label: ts_common_1.tsValidator_nonMandatoryString,
};
const validator_timeData = {
    timeRanges: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId)
};
const validator_timeRangeData = {
    timeRange: (0, ts_common_1.tsValidateTimeRange)()
};
exports.Validator_ValueData = {
    [consts_1.valueType_EnumElement]: validator_subEnumerated,
    [consts_1.valueType_SubRange]: validator_subRangeData,
    [consts_1.valueType_SubEnumerated]: validator_subEnumeratedData,
    [consts_1.valueType_Enumerated]: validator_enumerated,
    [consts_1.valueType_Range]: validator_range,
    [consts_1.valueType_Tuple]: validator_TupleData,
    [consts_1.valueType_StandardRange]: validator_normalRangeV2Data,
    [consts_1.valueType_Time]: validator_timeData,
    [consts_1.valueType_TimeRange]: validator_timeRangeData
};
const Validator_ModifiableProps = {
    type: (0, ts_common_1.tsValidateValue)(consts_1.ListOf_VariableTypes),
    data: (0, ts_common_1.tsValidator_valueByKey)(exports.Validator_ValueData),
    nlpDataId: ts_common_1.tsValidateOptionalId,
    _rowId: ts_common_1.tsValidateOptionalId,
    label: ts_common_1.tsValidator_nonMandatoryString
};
const Validator_GeneratedProps = {
    __unitsId: ts_common_1.tsValidateOptionalId,
    __valueIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    __variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
exports.DBDef_Value = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1', '1.0.0'],
    dbKey: 'values',
    entityName: 'Value',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'value'
    },
    backend: {
        name: 'values'
    },
    dependencies: {
        __unitsId: { dbKey: 'measurement-units', fieldType: 'string' },
        __valueIds: { dbKey: 'values', fieldType: 'string[]' },
        __variableIds: { dbKey: 'variables', fieldType: 'string[]' },
    }
};
