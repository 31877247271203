"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHVValueOption = exports.resolveAssociatedEntity = void 0;
const shared_1 = require("../../../../_entity/value/shared");
//******* Atom level generic utils
const resolveAssociatedEntity = (dbAtom, dependencies) => {
    var _a, _b, _c;
    if (((_a = dbAtom.leftHand) === null || _a === void 0 ? void 0 : _a.type) === 'expression') {
        return dependencies.expressions.find(expr => { var _a; return expr._id === ((_a = dbAtom.leftHand) === null || _a === void 0 ? void 0 : _a.id); });
    }
    // Resolve entity from referenced variables
    const varId = (_b = dbAtom.leftHand.propertyId) !== null && _b !== void 0 ? _b : (_c = dbAtom.leftHand) === null || _c === void 0 ? void 0 : _c.id;
    return dependencies.variables.find(variable => variable._id === varId);
};
exports.resolveAssociatedEntity = resolveAssociatedEntity;
const createHVValueOption = (id, dependencies) => {
    const schemaValue = {};
    schemaValue.db_id = id;
    const value = dependencies.values.find(value => value._id === id);
    if (value)
        // FIXME: maybe we should move this function to shared
        schemaValue.value = (0, shared_1.resolveValueDisplay)(value);
    return schemaValue;
};
exports.createHVValueOption = createHVValueOption;
