"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QColorPicker_BackgroundForeground = void 0;
const React = require("react");
require("./QColorPicker_BackgroundForeground.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
/**
 * A React shared-components that generates a tool for picking foreground and background colors
 * @param props - Component props
 */
function QColorPicker_BackgroundForeground(props) {
    const pickerRef = React.createRef();
    const previewStyle = {
        background: `linear-gradient(135deg,
			 ${props.backgroundColor} 0%,
			  ${props.backgroundColor} 49%,
			   ${props.foregroundColor} 49%,
			    ${props.foregroundColor} 100%)`
    };
    const backgroundPalette = props.backgroundColors || ['#000000', '#9651E5', '#009EDA', '#7A7878', '#EE67AF', '#CF9E44', '#56A199'];
    const foregroundPalette = props.colors || ['#000000', '#FFFFFF'];
    const getPopUpLogic = () => {
        return {
            onClick: (e) => {
                const model = {
                    id: 'color-picker-pop-up',
                    content: React.createElement("div", { className: 'colors-picker', ref: pickerRef },
                        React.createElement(frontend_1.TS_Tabs, { tabs: tabs })),
                    originPos: (0, frontend_1.getElementCenterPos)(e.currentTarget),
                    modalPos: { x: 0, y: 1.2 },
                    overlayClass: 'transparent'
                };
                if (!props.disabled)
                    frontend_1.ModuleFE_MouseInteractivity.showContent(model);
            },
        };
    };
    const tabs = [
        {
            uid: 'background',
            title: 'Background',
            content: () => {
                return React.createElement(frontend_1.LL_H_T, { className: 'colors-container' }, backgroundPalette.map((color, i) => {
                    const className = (0, frontend_1._className)('color-preview');
                    return React.createElement("div", { key: i, style: { background: color }, className: className, onClick: () => props.onBackgroundChange(color) });
                }));
            }
        },
        {
            uid: 'foreground',
            title: 'Foreground',
            content: () => {
                return React.createElement(frontend_1.LL_H_T, { className: 'colors-container' }, foregroundPalette.map(color => {
                    const className = (0, frontend_1._className)('color-preview');
                    return React.createElement("div", { key: color, style: { background: color }, className: className, onClick: () => props.onForegroundChange(color) });
                }));
            }
        }
    ];
    return React.createElement("div", { className: 'q-color-picker-background-foreground' },
        React.createElement("div", Object.assign({ className: 'colors-preview', style: previewStyle }, getPopUpLogic())));
}
exports.QColorPicker_BackgroundForeground = QColorPicker_BackgroundForeground;
