"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_VariableRelation = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    leftSide: (0, ts_common_1.tsValidateArray)({ valueId: ts_common_1.tsValidateOptionalId, variableId: ts_common_1.tsValidateUniqueId }),
    relation: (0, ts_common_1.tsValidateValue)(['Contains', 'Member Of', 'Derivative', 'Convert To Enum', 'Relieving Factor', 'Precipitating Factor']),
    rightSide: (0, ts_common_1.tsValidateArray)({ valueId: ts_common_1.tsValidateOptionalId, variableId: ts_common_1.tsValidateUniqueId }),
};
const Validator_GeneratedProps = {
    _firstVariableId: ts_common_1.tsValidateUniqueId,
    _valueIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    _variableIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    _origin: ts_common_1.tsValidateOptionalId,
};
exports.DBDef_VariableRelation = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'variable-relation',
    entityName: 'VariableRelation',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'variable-relation'
    },
    backend: {
        name: 'variable-relation'
    },
    dependencies: {
        _variableIds: { dbKey: 'variables', fieldType: 'string[]' },
        _valueIds: { dbKey: 'values', fieldType: 'string[]' },
        _firstVariableId: { dbKey: 'variables', fieldType: 'string' },
    }
};
