"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanelHeader = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./PanelHeader.scss");
/**
 * Returns a panel header with a title that renders provided children
 *
 * @category Components
 */
class PanelHeader extends React.Component {
    render() {
        return React.createElement(frontend_1.LL_H_C, { className: 'panel-header' },
            React.createElement("span", { className: 'panel-header__title' }, this.props.title),
            this.props.children);
    }
}
exports.PanelHeader = PanelHeader;
