"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_AlertWarningForDP = void 0;
const React = __importStar(require("react"));
const ts_common_1 = require("@nu-art/ts-common");
require("./Renderer_AlertWarningForDP.scss");
const frontend_1 = require("@app/hcs/frontend");
const styles_1 = require("@app/styles");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
const _entity_1 = require("../../../_entity");
class Renderer_AlertWarningForDP extends frontend_2.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderAlertErrorIcon = () => {
            if (!this.state.errorCount)
                return '';
            return React.createElement(styles_1.ICONS.attention.component, { className: 'warning-icon' });
        };
    }
    __onDiseaseProfilesShowErrorAlertsUpdated() {
        this.reDeriveState();
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        this.logDebug('Instance Check');
        if (!_entity_1.ModuleFE_DiseaseProfile.showDiseaseProfileErrorAlerts.get()) {
            state.errorCount = 0;
            return state;
        }
        if (!this.props.relatedMultiVars) {
            state.errorCount = 0;
            return state;
        }
        const multiVarsIds = ((_a = (typeof nextProps.relatedMultiVars === 'function' ? _entity_1.ModuleFE_MultiVar.cache.filter(nextProps.relatedMultiVars) : nextProps.relatedMultiVars)) === null || _a === void 0 ? void 0 : _a.map(ts_common_1.dbObjectToId)) || [];
        const valueIds = (0, ts_common_1.filterDuplicates)((0, ts_common_1.flatArray)(_entity_1.ModuleFE_DiseaseValue.cache.filter(dv => multiVarsIds === null || multiVarsIds === void 0 ? void 0 : multiVarsIds.includes(dv.multiVarId)).map(dv => (0, ts_common_1._values)(dv.values))));
        const values = frontend_1.ModuleFE_Value.cache.filter(v => valueIds.includes(v._id));
        state.errorCount = valueIds.length - values.length;
        return state;
    }
    render() {
        return this.renderAlertErrorIcon();
    }
}
exports.Renderer_AlertWarningForDP = Renderer_AlertWarningForDP;
