"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionsMenu_BaseManagerUI = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/permissions/frontend");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
const ActionsMenu_BaseManagerUI = (props) => {
    return React.createElement(React.Fragment, null, props.actions.map((action, index) => {
        if (!action.permissionKey)
            return React.createElement("div", { key: index, className: 'popup-action', onClick: () => {
                    frontend_2.ModuleFE_MouseInteractivity.hide(frontend_2.mouseInteractivity_PopUp);
                    action.action();
                } }, action.label);
        return React.createElement(frontend_1.PermissionsComponent, { permissionKey: action.permissionKey, key: index },
            React.createElement("div", { className: 'popup-action', onClick: () => {
                    frontend_2.ModuleFE_MouseInteractivity.hide(frontend_2.mouseInteractivity_PopUp);
                    action.action();
                } }, action.label));
    }));
};
exports.ActionsMenu_BaseManagerUI = ActionsMenu_BaseManagerUI;
