"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePackFE_DiseaseProfile = void 0;
const _entity_1 = require("./_entity");
exports.ModulePackFE_DiseaseProfile = [
    ..._entity_1.ModulePackFE_DiseaseValueDB,
    ..._entity_1.ModulePackFE_DiseaseViewDB,
    ..._entity_1.ModulePackFE_DiseaseProfileDB,
    ..._entity_1.ModulePackFE_ColorGroup,
    ..._entity_1.ModulePackFE_MultiVar,
];
