"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompoundRangeEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Editors.scss");
const ts_common_1 = require("@nu-art/ts-common");
const shared_components_1 = require("../../../shared-components");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../../_entity");
class CompoundRangeEditor extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Life Cycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.onFullRangeStringChange = (fullRangeString) => {
            const minMax = this.parseRangeString(fullRangeString);
            //If min or max could not be parsed
            if (!minMax)
                return;
            this.props.value.data.scope = minMax;
            const valid = this.isValidFullRange(minMax);
            this.setState({
                validFullRange: valid,
                fullRangeString: valid ? this.getFullRangeString(minMax) : fullRangeString,
            }, this.forceUpdate);
        };
        this.parseRangeString = (rangeStr) => {
            const [min, max] = rangeStr.split('-').map(item => item.trim()).map(item => Number(item));
            //If min or max could not be parsed
            if ((!min && min !== 0) || (!max && max !== 0))
                return;
            return [min, max];
        };
        this.onFullRangeMinMaxChange = (newMinMax, pos) => {
            const fullRange = this.props.value.data.scope;
            fullRange[(pos === 'min' ? 0 : 1)] = Number(newMinMax);
            this.setState({
                validFullRange: this.isValidFullRange(fullRange),
                fullRangeString: this.getFullRangeString(fullRange),
            });
        };
        this.getTableHeaders = () => {
            return [...['Label', 'Range', 'Min Value', 'Max Value'].map(i => ({ header: i, widthPx: 150 })), { header: 'Delete', widthPx: 30 }];
        };
        this.onCreateMeasurementUnit = async (text, items) => {
            if (items.length)
                return;
            const titles = { inProgress: 'Creating New Measurement Unit', success: 'Measurement Unit Created', failed: 'Measurement Unit Creation Failed' };
            await (0, frontend_1.performAction)(async () => {
                const unit = {
                    unitAsString: text,
                };
                const newUnit = await _entity_1.ModuleFE_MeasurementUnit.v1.upsert(unit).executeSync();
                this.props.value.data.measurementUnit = newUnit;
                this.forceUpdate();
            }, { type: 'notification', notificationLabels: titles });
        };
        // ######################## Render ########################
        this.renderAddNewRangedButton = () => {
            return React.createElement("div", { onClick: () => {
                    this.state.newInnerValues.push({ type: _entity_1.valueType_SubRange, data: { scope: [0, 0] } });
                    this.forceUpdate();
                }, className: 'add-value-button unselectable', tabIndex: 0 }, "Add Ranged Value");
        };
        this.renderFullRange = () => {
            var _a, _b;
            return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section-item' },
                React.createElement("div", { className: 'dialog__field-title' }, "Full Range"),
                React.createElement(frontend_1.LL_H_C, { className: 'match_width full-range' },
                    React.createElement("div", { className: 'title' }, "Full Range"),
                    React.createElement(frontend_1.TS_Input, { className: 'range-full', type: 'text', value: this.state.fullRangeString, onChange: this.onFullRangeStringChange }),
                    React.createElement(frontend_1.TS_Input, { className: 'range-min', type: 'number', value: String((_a = this.props.value.data) === null || _a === void 0 ? void 0 : _a.scope[0]), onChange: value => this.onFullRangeMinMaxChange(value, 'min') }),
                    React.createElement(frontend_1.TS_Input, { className: 'range-max', type: 'number', value: String((_b = this.props.value.data) === null || _b === void 0 ? void 0 : _b.scope[1]), onChange: value => this.onFullRangeMinMaxChange(value, 'max') })));
        };
        this.renderUnitsDropDown = () => {
            const data = this.props.value.data;
            return React.createElement(frontend_1.LL_H_C, { className: 'dialog__main__section-item' },
                React.createElement("div", { className: 'dialog__field-title' }, "Units"),
                React.createElement(shared_components_1.HCSDropDowns.MeasurementUnit, { selected: data.measurementUnit, onSelected: (units) => {
                        this.props.value.data.measurementUnit = units;
                        this.forceUpdate();
                    }, onNoMatchingSelectionForString: this.onCreateMeasurementUnit }));
        };
        this.renderInnerValues = () => {
            var _a;
            const values = [...this.state.newInnerValues, ...((_a = this.props.value.data) === null || _a === void 0 ? void 0 : _a.subsets) || []];
            return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section-item inner-values' },
                React.createElement(frontend_1.LL_H_C, { className: 'match_width', style: { justifyContent: 'space-between' } },
                    React.createElement("div", { className: 'dialog__field-title' }, "Inner Values"),
                    this.renderAddNewRangedButton()),
                React.createElement(frontend_1.LL_H_C, { className: 'match_width inner-values' },
                    React.createElement(frontend_1.TS_Table, { header: this.getTableHeaders(), rows: values, headerRenderer: header => header === 'Delete' ? '' : header, cellRenderer: (prop, item, index) => {
                            switch (prop) {
                                case 'Label':
                                    return this.renderInnerValueLabel(item);
                                case 'Range':
                                    return this.renderInnerValueRange(item);
                                case 'Min Value':
                                    return this.renderInnerValueMin(item);
                                case 'Max Value':
                                    return this.renderInnerValueMax(item);
                                case 'Delete':
                                    return this.renderValueActions(item);
                                default:
                                    return '';
                            }
                        } })));
        };
        this.renderInnerValueLabel = (value) => {
            var _a;
            return React.createElement(frontend_1.TS_Input, { type: 'text', placeholder: 'Enter label for value', className: 'inner-values__label', value: (_a = value.data) === null || _a === void 0 ? void 0 : _a.label, onChange: str => {
                    value.data.label = str;
                    this.forceUpdate();
                } });
        };
        this.renderInnerValueRange = (value) => {
            return React.createElement(frontend_1.TS_Input, { type: 'text', value: this.getFullRangeString(value.data.scope), onChange: newVal => {
                    const minMax = this.parseRangeString(newVal);
                    if (!minMax)
                        return;
                    //Changing regular scope value
                    value.data.scope = minMax;
                    this.forceUpdate();
                } });
        };
        this.renderInnerValueMin = (value) => {
            return React.createElement(frontend_1.TS_Input, { type: 'number', value: String(value.data.scope[0]), onChange: newVal => {
                    //Changing regular scope value
                    value.data.scope[0] = Number(newVal);
                    this.forceUpdate();
                } });
        };
        this.renderInnerValueMax = (value) => {
            return React.createElement(frontend_1.TS_Input, { type: 'number', value: String(value.data.scope[1]), onChange: newVal => {
                    //Changing regular scope value
                    value.data.scope[1] = Number(newVal);
                    this.forceUpdate();
                } });
        };
        this.renderValueActions = (value) => {
            if (!this.state.newInnerValues.includes(value)) {
                const index = this.props.value.data.subsets.indexOf(value);
                return React.createElement(frontend_1.LL_H_C, { className: 'inner-values__actions' },
                    React.createElement(styles_1.ICONS.bin.component, { className: 'inner-values__actions__delete', onClick: () => {
                            (0, ts_common_1.removeFromArrayByIndex)(this.props.value.data.subsets, index);
                            this.forceUpdate();
                        } }));
            }
            const index = this.state.newInnerValues.indexOf(value);
            return React.createElement(frontend_1.LL_H_C, { className: 'inner-values__actions' },
                React.createElement(styles_1.ICONS.x.component, { className: 'inner-values__actions__cancel', onClick: () => {
                        (0, ts_common_1.removeFromArrayByIndex)(this.state.newInnerValues, index);
                        this.forceUpdate();
                    } }),
                React.createElement(styles_1.ICONS.v.component, { className: 'inner-values__actions__accept', onClick: () => {
                        this.props.value.data.subsets.push(this.state.newInnerValues.splice(index, 1)[0]);
                        this.forceUpdate();
                    } }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        if (!nextProps.value.data)
            nextProps.value.data = { subsets: [], scope: [0, 0], subsetIds: [] };
        state.units = _entity_1.ModuleFE_MeasurementUnit.cache.all();
        state.validFullRange = this.isValidFullRange((_a = nextProps.value.data) === null || _a === void 0 ? void 0 : _a.scope);
        state.fullRangeString = this.getFullRangeString(nextProps.value.data.scope) || '';
        if (!state.newInnerValues)
            state.newInnerValues = [];
        return state;
    }
    getFullRangeString(fullRange) {
        return `${fullRange[0]} - ${fullRange[1]}`;
    }
    isValidFullRange(fullRange) {
        if (!fullRange)
            return false;
        return fullRange[0] < fullRange[1];
    }
    render() {
        return React.createElement(frontend_1.LL_H_C, { className: 'dialog__main' },
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section compound-range-editor' },
                this.renderUnitsDropDown(),
                this.renderFullRange(),
                this.renderInnerValues()));
    }
}
exports.CompoundRangeEditor = CompoundRangeEditor;
