"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_EpidemiologyItemEditor = void 0;
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const _enum_1 = require("../../../_enum");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = __importStar(require("react"));
const ts_common_1 = require("@nu-art/ts-common");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const frontend_2 = require("@app/hcs/frontend");
require("./Component_EpidemiologyItemEditor.scss");
const Component_PopulationEditor_1 = require("../Component_PopulationEditor/Component_PopulationEditor");
const ViewMode_View = 'view';
const ViewMode_Edit = 'edit';
class Component_EpidemiologyItemEditor extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.getViewMode = () => this.state.editMode ? ViewMode_Edit : ViewMode_View;
        this.composeRate = () => {
            var _a, _b, _c, _d;
            const epidemiology = this.state.editable.item;
            if (!epidemiology.data || !epidemiology.data.rate || !epidemiology.data.rateValue)
                return '';
            //EpidemiologyRate is percentage
            if (((_a = epidemiology.data) === null || _a === void 0 ? void 0 : _a.rate) === _enum_1.EpidemiologyRate_Percentage) {
                if (!(0, ts_common_1.exists)(epidemiology.data.rateValue[0]) || !(0, ts_common_1.exists)(epidemiology.data.rateValue[1]))
                    return '';
                if (epidemiology.data.rateValue[0] === epidemiology.data.rateValue[1])
                    return `${epidemiology.data.rateValue[0]}%`;
                return `${epidemiology.data.rateValue[0]} - ${epidemiology.data.rateValue[1]}%`;
            }
            //EpidemiologyRate is ratio
            if (epidemiology.data.rate === _enum_1.EpidemiologyRate_Ratio) {
                const numerator = epidemiology.data.rateValue.numerator;
                const denominator = epidemiology.data.rateValue.denominator;
                if (!(0, ts_common_1.exists)(numerator === null || numerator === void 0 ? void 0 : numerator[0]) || !(0, ts_common_1.exists)(numerator === null || numerator === void 0 ? void 0 : numerator[1]) || !(0, ts_common_1.exists)(denominator))
                    return '';
                if (numerator[0] === numerator[1])
                    return `${(_b = numerator[0]) !== null && _b !== void 0 ? _b : 0} / ${denominator !== null && denominator !== void 0 ? denominator : 0}`;
                return `${(_c = numerator[0]) !== null && _c !== void 0 ? _c : 0} - ${(_d = numerator[1]) !== null && _d !== void 0 ? _d : 0} / ${denominator !== null && denominator !== void 0 ? denominator : 0}`;
            }
            // @ts-ignore
            throw new ts_common_1.ImplementationMissingException(`No composer for epidemiology rate of type ${epidemiology.data.rate}`);
        };
        // ######################## Render - Rate ########################
        this.renderRate = () => {
            const epidemiology = this.state.editable;
            const data = epidemiology.editProp('data', {});
            return React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                    {
                        key: ViewMode_View,
                        renderer: () => React.createElement(frontend_1.TS_PropRenderer.Flat, { label: _enum_1.EpidemiologyTypeLabelMap[epidemiology.item.type] },
                            React.createElement("div", { className: 'epidemiology-item-editor__data span-row' }, this.composeRate()))
                    },
                    {
                        key: ViewMode_Edit,
                        renderer: () => React.createElement(React.Fragment, null,
                            React.createElement(_enum_1.DropDown_EpidemiologyType.editable, { editable: epidemiology, prop: 'type' }),
                            React.createElement(_enum_1.DropDown_EpidemiologyRate.editable, { editable: data, prop: 'rate', onSelected: rate => {
                                    // @ts-ignore
                                    data.updateObj({ rate, rateValue: undefined });
                                } }),
                            this.renderRateValue())
                    }
                ] });
        };
        this.renderRateValue = () => {
            var _a;
            const epidemiology = this.state.editable;
            if (((_a = epidemiology.item.data) === null || _a === void 0 ? void 0 : _a.rate) === _enum_1.EpidemiologyRate_Ratio)
                return this.renderRatioInput();
            return this.renderPercentageInput();
        };
        this.renderRatioInput = () => {
            const epidemiology = this.state.editable;
            const data = epidemiology.editProp('data', {});
            const rate = data.editProp('rateValue', {});
            return React.createElement(frontend_1.LL_H_C, { className: 'q-range-inputs' },
                React.createElement(frontend_2.QRangeInputV3, { editable: rate, prop: 'numerator' }),
                React.createElement("div", { className: 'q-range-inputs__divider' }, "/"),
                React.createElement(frontend_1.TS_Input, { type: 'number', value: String(rate.item.denominator), onBlur: async (value) => {
                        await rate.updateObj({ denominator: Number(value) });
                    } }));
        };
        this.renderPercentageInput = () => {
            const epidemiology = this.state.editable;
            const data = epidemiology.editProp('data', {});
            return React.createElement(frontend_1.LL_H_C, { className: 'epidemiology-item-editor__percentage-input' },
                React.createElement(frontend_2.QRangeInputV3, { editable: data, prop: 'rateValue' }),
                React.createElement("div", { className: 'epidemiology-item-editor__percentage-input__icon' }, "%"));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        if (!state.editable.item.type)
            throw new ts_common_1.BadImplementationException('Component_EpidemiologyItemEditor does not support rendering Epidemiology item without already existing type');
        return state;
    }
    // ######################## Render ########################
    render() {
        const population = this.state.editable.editProp('population', {});
        const className = (0, frontend_1._className)('epidemiology-item-editor', this.state.editMode && 'edit-mode');
        return React.createElement(frontend_1.Grid, { className: className },
            this.renderRate(),
            React.createElement(Component_PopulationEditor_1.Component_PopulationEditor, { editable: population, editMode: this.state.editMode }));
    }
}
exports.Component_EpidemiologyItemEditor = Component_EpidemiologyItemEditor;
