"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorPage_Values = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./ErrorPage_Values.scss");
const q_components_1 = require("../q-components");
const _entity_1 = require("../../_entity");
class ErrorPage_Values extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderErrorParagraph = () => {
            return React.createElement("p", null,
                "Value \"",
                (0, _entity_1.resolveValueDisplay)(this.state.value),
                "\" is a floating value, meaning it is not connected to any parent value or variable. Consider deleting this value.");
        };
        this.renderDeleteButton = () => {
            return React.createElement(frontend_1.TS_BusyButton, { onClick: async () => {
                    await (0, frontend_1.performAction)(async () => {
                        await _entity_1.ModuleFE_Value.v1.delete(this.state.value).executeSync();
                    }, {
                        type: 'toast',
                        successContent: React.createElement(q_components_1.QToastContent, { toastType: 'success', iconKey: 'v', content: 'Value Deleted Successfully' }),
                        duration: 10000
                    }, (0, _entity_1.resolveValueDisplay)(this.state.value));
                } }, "Delete Value");
        };
    }
    deriveStateFromProps(nextProps, state) {
        state.value = _entity_1.ModuleFE_Value.cache.unique(nextProps.valueId);
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'error-page__values' },
            this.renderErrorParagraph(),
            this.renderDeleteButton());
    }
}
exports.ErrorPage_Values = ErrorPage_Values;
