"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allOperators = exports.booleanExpressions = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const calculatorOperators = [
    {
        operand: '===',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "==="),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '+',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "+"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '-',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "-"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '/',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "/"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '*',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "*"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '^',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "^"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '?',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "?"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: ':',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, ":"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '""',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "\"\""),
        cursorLocationLogic: (cursorPosition, offset = 1) => {
            return cursorPosition - offset;
        }
    },
];
// const hcsOperators: CalculatorButton[] = [
// 	{
// 		operand: 'var()',
// 		element: (onClick) => <div tabIndex={0} key={generateUUID()} onClick={onClick} className={'calc-btn'}>var()</div>,
// 		cursorLocationLogic: (cursorPosition, offset = 1) => {
// 			return cursorPosition - offset;
// 		}
// 	},
// 	{
// 		operand: 'enum()',
// 		element: (onClick) => <div tabIndex={0} key={generateUUID()} onClick={onClick} className={'calc-btn'}>enum()</div>,
// 		cursorLocationLogic: (cursorPosition, offset = 1) => {
// 			return cursorPosition - offset;
// 		}
// 	},
// 	{
// 		operand: 'attr()',
// 		element: (onClick) => <div tabIndex={0} key={generateUUID()} onClick={onClick} className={'calc-btn'}>attr()</div>,
// 		cursorLocationLogic: (cursorPosition, offset = 1) => {
// 			return cursorPosition - offset;
// 		}
// 	},
// 	{
// 		operand: 'precipitating()',
// 		element: (onClick) => <div tabIndex={0} key={generateUUID()} onClick={onClick} className={'calc-btn'}>precipitating()</div>,
// 		cursorLocationLogic: (cursorPosition, offset = 1) => {
// 			return cursorPosition - offset;
// 		}
// 	},
// 	{
// 		operand: 'relieving()',
// 		element: (onClick) => <div tabIndex={0} key={generateUUID()} onClick={onClick} className={'calc-btn'}>relieving()</div>,
// 		cursorLocationLogic: (cursorPosition, offset = 1) => {
// 			return cursorPosition - offset;
// 		}
// 	},
// 	{
// 		operand: 'yes',
// 		element: (onClick) => <div tabIndex={0} key={generateUUID()} onClick={onClick} className={'calc-btn'}>yes</div>,
// 		cursorLocationLogic: (cursorPosition, offset = 0) => {
// 			return cursorPosition + offset;
// 		}
// 	},
// 	{
// 		operand: 'no',
// 		element: (onClick) => <div tabIndex={0} key={generateUUID()} onClick={onClick} className={'calc-btn'}>no</div>,
// 		cursorLocationLogic: (cursorPosition, offset = 0) => {
// 			return cursorPosition + offset;
// 		}
// 	}
// ];
const numericComparisons = [
    {
        operand: '<',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, '<'),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '>',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, '>'),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '<=',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, '<='),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '>=',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, '>='),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
];
const parenthesisOperators = [
    {
        operand: '[]',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "[]"),
        cursorLocationLogic: (cursorPosition, offset = 1) => {
            return cursorPosition - offset;
        }
    },
    {
        operand: '(',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "("),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition - offset;
        }
    },
    {
        operand: ')',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, ")"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition - offset;
        }
    },
];
const expressionOperators = [
    {
        operand: '||',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "||"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '&&',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "&&"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
    {
        operand: '+',
        element: (onClick) => React.createElement("div", { tabIndex: 0, key: (0, ts_common_1.generateUUID)(), onClick: onClick, className: 'calc-btn' }, "+"),
        cursorLocationLogic: (cursorPosition, offset = 0) => {
            return cursorPosition + offset;
        }
    },
];
exports.booleanExpressions = [
    ...expressionOperators,
    ...parenthesisOperators
];
exports.allOperators = [
    ...expressionOperators,
    ...numericComparisons,
    ...parenthesisOperators,
    ...calculatorOperators,
    // ...hcsOperators,
];
