"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_MultiVar = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const hcs_1 = require("@app/hcs");
const Validator_ModifiableProps = {
    diseaseProfileId: ts_common_1.tsValidateUniqueId,
    referenceIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    variables: (0, ts_common_1.tsValidateArray)({
        variableId: ts_common_1.tsValidateUniqueId,
        attributeeId: ts_common_1.tsValidateOptionalId,
        tagIds: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateDynamicObject)((0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)()), (0, ts_common_1.tsValidateString)())),
        role: (0, ts_common_1.tsValidateValue)([...hcs_1.roles])
    }),
};
const Validator_GeneratedProps = {
    _firstVarId: ts_common_1.tsValidateUniqueId,
    _variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
exports.DBDef_MultiVar = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'multi-vars',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'multi-var'
    },
    backend: {
        name: 'multi-vars'
    },
    entityName: 'MultiVar',
    dependencies: {
        'diseaseProfileId': {
            dbKey: 'disease-profiles',
            fieldType: 'string'
        },
        'referenceIds': {
            dbKey: 'references',
            fieldType: 'string[]'
        },
        '_firstVarId': {
            dbKey: 'variables',
            fieldType: 'string'
        },
        '_variableIds': {
            dbKey: 'variables',
            fieldType: 'string[]'
        },
        '_tagIds': {
            dbKey: 'tags',
            fieldType: 'string[]'
        },
    }
};
