"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_EHRInstance = exports.ModuleFE_EHRInstance_Class = exports.dispatch_onEHRInstanceChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../ehr-order-configuration/frontend");
exports.dispatch_onEHRInstanceChanged = new types_1.ThunderDispatcherV3('__onEHRInstanceUpdated');
class ModuleFE_EHRInstance_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_EHRInstance, exports.dispatch_onEHRInstanceChanged);
        this.__searchFilterMapper = (item) => [item.label];
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: (0, ts_common_1.filterInstances)(items.map(i => ({ _id: i._id, uiLabel: i.label })))
            }]);
        this.onInstanceDeleted = async (response) => {
            await frontend_2.ModuleFE_EHROrderConfiguration.onEntriesDeleted(response.ehrOrders);
            await this.onEntryDeleted(response.instance);
        };
        this.onOrdersSyncedFromPathways = async (response) => {
            await frontend_2.ModuleFE_EHROrderConfiguration.onEntriesUpdated(response.orderConfigs);
        };
        this._v1 = {
            deleteInstance: (0, frontend_1.apiWithBody)(shared_1.ApiDef_EHRInstance._v1.deleteInstance, this.onInstanceDeleted),
            syncOrdersFromPathways: (0, frontend_1.apiWithBody)(shared_1.ApiDef_EHRInstance._v1.syncOrdersFromPathways, this.onOrdersSyncedFromPathways)
        };
    }
}
exports.ModuleFE_EHRInstance_Class = ModuleFE_EHRInstance_Class;
exports.ModuleFE_EHRInstance = new ModuleFE_EHRInstance_Class();
