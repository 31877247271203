"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Discussion = exports.ModuleFE_Discussion_Class = exports.storage_FilterSelected = exports.dispatch_discussionViewOptionsChanged = exports.dispatch_onDiscussionsUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const shared_2 = require("../../../shared");
const frontend_2 = require("@nu-art/ts-messaging/_entity/message/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_3 = require("@nu-art/ts-messaging/_entity/topic/frontend");
const consts_1 = require("../shared/consts");
exports.dispatch_onDiscussionsUpdated = new types_1.ThunderDispatcherV3('__onDiscussionsUpdated');
exports.dispatch_discussionViewOptionsChanged = new frontend_1.ThunderDispatcher('__discussionsViewOptionsChanged');
const dispatch_showDiscussions = new frontend_1.ThunderDispatcher('__showDiscussions');
exports.storage_FilterSelected = new frontend_1.StorageKey('messaging_discussions-view_filter-selected').withstandDeletion();
class ModuleFE_Discussion_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Discussion, exports.dispatch_onDiscussionsUpdated);
        this.dbEntityToLabel = {};
        this.showResolvedDiscussions = false;
        this.getLabelFromEntity = (dbName, ref) => {
            const converter = this.dbEntityToLabel[dbName];
            if (!converter)
                throw new ts_common_1.BadImplementationException(`A converter function for ${dbName} type messages was not provided! Should be added in ModuleFE's init function, see ModuleFE_Variables for reference.`);
            return converter(ref);
        };
        this.getLabelFromEntityId = (dbName, refId) => {
            const collectionModule = (0, ts_common_1.RuntimeModules)().find(module => { var _a; return ((_a = module.dbDef) === null || _a === void 0 ? void 0 : _a.dbKey) === dbName; });
            if (!collectionModule)
                throw new ts_common_1.BadImplementationException(`Could not find the collection module of type ${dbName}!`);
            const ref = collectionModule.cache.unique(refId);
            if (!ref)
                throw new ts_common_1.BadImplementationException(`Could not find ref to get label from!`);
            return this.getLabelFromEntity(dbName, ref);
        };
        this.getLabelFromTopicId = (topicId) => {
            const dbTopic = frontend_3.ModuleFE_Topic.cache.unique(topicId);
            if (!dbTopic)
                throw new ts_common_1.BadImplementationException(`Could not find topic to get label from!`);
            return this.getLabelFromEntityId(dbTopic.type, dbTopic.refId);
        };
        this.hasUnresolvedDiscussions = (refId, dbName) => {
            const topicIds = frontend_3.ModuleFE_Topic.cache.filter(topic => topic.refId === refId && topic.type === dbName).map(ts_common_1.dbObjectToId);
            const discussions = this.cache.filter(discussion => topicIds.includes(discussion.topicId));
            return discussions.some(discussion => discussion.status !== 'resolved');
        };
        this._v1 = {
            createNewDiscussion: (0, frontend_1.apiWithBody)(shared_1.ApiDef_Discussion._v1.createNewDiscussion, async (response) => {
                await frontend_3.ModuleFE_Topic.onEntryUpdated(response.topic, response.topic);
                await this.onEntryUpdated(response.discussion, response.discussion);
                await frontend_2.ModuleFE_Message.onEntryUpdated(response.message, response.message);
            }),
            deleteDiscussion: (0, frontend_1.apiWithBody)(shared_1.ApiDef_Discussion._v1.deleteDiscussion, async (response) => {
                if (response.deletedDiscussion)
                    await this.onEntryDeleted(response.deletedDiscussion);
                if (response.deletedTopic)
                    await frontend_3.ModuleFE_Topic.onEntriesDeleted([response.deletedTopic]);
                if (response.deletedMessages)
                    await frontend_2.ModuleFE_Message.onEntriesDeleted(response.deletedMessages);
            }),
        };
    }
    getFirstMessageForDiscussion(discussion) {
        return discussion && frontend_2.ModuleFE_Message.cache.filter(msg => msg.topicId === discussion.topicId).reduce((firstMsg, currentMsg) => {
            return firstMsg && firstMsg.__created < currentMsg.__created ? firstMsg : currentMsg;
        }, undefined);
    }
    getLastMessageForDiscussion(discussion) {
        return discussion && frontend_2.ModuleFE_Message.cache.filter(msg => msg.topicId === discussion.topicId).reduce((lastMsg, currentMsg) => {
            return lastMsg && lastMsg.__created > currentMsg.__created ? lastMsg : currentMsg;
        }, undefined);
    }
    getRelevantModuleByCollectionName(collectionName) {
        const relevantModuleByCollectionName = (0, ts_common_1.RuntimeModules)()
            .find(module => !!module.getCollectionName && module.getCollectionName() === collectionName);
        if (!relevantModuleByCollectionName) {
            this.logErrorBold(`Failed to find collection module for collectionName: ${collectionName}!`);
            return;
        }
        return relevantModuleByCollectionName;
    }
    getReferencedObjectByCollectionRef(collectionName, refId) {
        const collectionModule = this.getRelevantModuleByCollectionName(collectionName);
        return collectionModule === null || collectionModule === void 0 ? void 0 : collectionModule.cache.unique(refId);
    }
    getTopicsByObjectRef(collectionName, refId) {
        return frontend_3.ModuleFE_Topic.cache.filter(topic => topic.type === collectionName && topic.refId === refId);
    }
    async addMessageToDiscussion(discussionId, msg) {
        const discussion = this.cache.unique(discussionId);
        let topic;
        if (!discussion) {
            topic = await frontend_3.ModuleFE_Topic.v1.upsert({ refId: 'test-refId', type: 'test-type' }).executeSync();
            await this.v1.upsert({
                _id: discussionId,
                topicId: topic._id,
                type: consts_1.DiscussionType_Chat
            }).executeSync();
        }
        else
            topic = frontend_3.ModuleFE_Topic.cache.unique(discussion.topicId);
        if (!topic)
            throw new ts_common_1.ThisShouldNotHappenException('Discussions should always have topicId.');
        await frontend_2.ModuleFE_Message.createMessage(topic._id, msg);
    }
    openDiscussions(props) {
        dispatch_showDiscussions.dispatchUI(props);
    }
    registerLabelFromEntityConverter(dbName, converter) {
        if (this.dbEntityToLabel[dbName])
            throw new ts_common_1.BadImplementationException(`A converter function for ${dbName} type was already provided, can't provide another!`);
        this.dbEntityToLabel[dbName] = converter;
    }
    getEntityNameFromTopic(topicId) {
        const dbTopic = frontend_3.ModuleFE_Topic.cache.unique(topicId);
        if (!dbTopic)
            throw new ts_common_1.BadImplementationException(`Could not find topic to get collection name from!`);
        return shared_2.CollectionEntityMapper.getEntityMapByCollectionName(dbTopic.type).entityName;
    }
    shouldShowResolvedDiscussions() {
        return this.showResolvedDiscussions;
    }
    /*
    Returns boolean for state changed.
     */
    setShouldShowResolvedDiscussions(show) {
        if (show === this.showResolvedDiscussions)
            return false;
        this.showResolvedDiscussions = show;
        return true;
    }
}
exports.ModuleFE_Discussion_Class = ModuleFE_Discussion_Class;
exports.ModuleFE_Discussion = new ModuleFE_Discussion_Class();
