"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionEntityMapper = void 0;
const ts_common_1 = require("@nu-art/ts-common");
class CollectionEntityMapper {
    static addEntities(newEntities) {
        if (newEntities.length === 0)
            return;
        this.entities = newEntities.reduce((toRet, currentEntity) => {
            const foundDuplicateEntityIndex = toRet.findIndex(entity => entity.type === currentEntity.type);
            if (foundDuplicateEntityIndex !== -1)
                throw new ts_common_1.MUSTNeverHappenException(`The same collection entity with type '${toRet[foundDuplicateEntityIndex].type}', entityName '${toRet[foundDuplicateEntityIndex].entityName}'- is declared twice!`);
            toRet.push(currentEntity);
            return toRet;
        }, this.entities);
    }
    static getEntityMap(findFunction, entityIdentifier) {
        const foundEntity = CollectionEntityMapper.entities.find(findFunction);
        if (!foundEntity)
            throw new ts_common_1.ImplementationMissingException(`Looking for entity with identifier '${entityIdentifier}', but such entity could not be found! Please verify such an entity is added to CollectionEntityMapper in the module packs.`);
        return foundEntity;
    }
    static getEntityMapByEntityName(entityName) {
        return CollectionEntityMapper.getEntityMap((ent) => ent.entityName === entityName, entityName);
    }
    static getEntityMapByCollectionName(collectionName) {
        return CollectionEntityMapper.getEntityMap((ent) => ent.type === collectionName, collectionName);
    }
}
CollectionEntityMapper.entities = [];
exports.CollectionEntityMapper = CollectionEntityMapper;
