"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertExpressionToSchemaFE = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const expression_1 = require("../../../../shared/expression");
const frontend_1 = require("../../../../_entity/variable/frontend");
const frontend_2 = require("../../../../_entity/value/frontend");
const shared_1 = require("../../../../_entity/value/shared");
const frontend_3 = require("../../../../_entity/expression/frontend");
/**
 * Converts a database expression to the json schema representation.
 * @param expression - The database expression to be converted.
 * @returns The AI team schema of the expression.
 */
const convertExpressionToSchemaFE = (expression) => {
    const expressionDependencies = collectDependenciesFE(expression._refs);
    return (0, expression_1.schemaConversion)(expression, expressionDependencies);
};
exports.convertExpressionToSchemaFE = convertExpressionToSchemaFE;
/**
 * Gets a collection mapper for a specific module API.
 * @param module - The frontend module API.
 * @returns A function that maps an ID to the corresponding entity in the module's cache.
 */
const getCollectionMapperFE = (module) => {
    return (id) => {
        const entity = module.cache.unique(id);
        if (!entity)
            throw new ts_common_1.MUSTNeverHappenException(`${module.dbDef.entityName} with id ${id} not found!`);
        return entity;
    };
};
// /**TODO: Remove when possible
//  * Gets an array of SNOMED references associated with the given variables.
//  * @param variables - An array of database variables.
//  * @returns An array of SNOMED references related to the provided variables.
//  */
// const getVariableSnomedRefs = (variables: DB_Variable[]) => {
// 	return variables.map(_variable => {
// 		return ModuleFE_Reference.cache.filter(ref => _variable.referenceIds.includes(ref._id) && ref.type === 'snomed');
// 	}).flat();
// };
const getDefaultYesNo = () => {
    const defaultValues = frontend_2.ModuleFE_Value.cache.filter(value => value._id === shared_1.valueID_No || value._id === shared_1.valueID_Yes);
    if (defaultValues.some(value => !(0, ts_common_1.exists)(value)))
        throw new ts_common_1.MUSTNeverHappenException('yes/no value is missing!');
    return (0, ts_common_1.filterInstances)(defaultValues);
};
const collectNormalRangeExpressions = (variables) => {
    return (0, ts_common_1.filterInstances)(variables.map(_variable => {
        const value = frontend_2.ModuleFE_Value.cache.unique(_variable.valueId);
        if (!value)
            return;
        if (value.type === shared_1.valueType_StandardRange)
            return frontend_3.ModuleFE_Expression.cache.find(expr => expr.associatedVar === _variable._id);
    }));
};
/**
 * Collects dependencies from a refs object.
 * @param refs - The references object containing variable, value, and expression IDs.
 * @returns The collected dependencies.
 */
const collectDependenciesFE = (refs) => {
    const dependencies = {};
    // Collect variables
    dependencies.variables = refs._variableIds.map(getCollectionMapperFE(frontend_1.ModuleFE_Variable));
    // Collect values
    dependencies.values = (0, ts_common_1.filterDuplicates)([...refs._valueIds.map(getCollectionMapperFE(frontend_2.ModuleFE_Value)), ...getDefaultYesNo()], item => item._id);
    // Collect expressions
    dependencies.expressions = (0, ts_common_1.filterDuplicates)([...collectNormalRangeExpressions(dependencies.variables), ...refs._exprIds.map(getCollectionMapperFE(frontend_3.ModuleFE_Expression))], item => item._id);
    return dependencies;
};
