"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_PathwayTransitionDetails = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const Renderer_Transition_1 = require("../../../renderers/Renderer_Transition");
const Header_Pathway_1 = require("../../../headers/Header_Pathway");
const Header_PathwayTransition_1 = require("../../../headers/Header_PathwayTransition");
const ConfirmationDialog_1 = require("@app/styles/frontend/ui-manager/dialogs/ConfirmationDialog");
const frontend_2 = require("@app/styles/frontend");
const _entity_1 = require("../../../../../_entity");
const utils_1 = require("../../../utils");
const Section_AllEntityVariables_1 = require("../common-components/Section_AllEntityVariables");
const frontend_3 = require("@app/hcs/_entity/expression/frontend");
const Component_CollapsablePrettyPrint_1 = require("../common-components/Component_CollapsablePrettyPrint");
class Renderer_PathwayTransitionDetails extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.deleteTransition = async () => {
            const pathwayTransition = this.state.pathwayTransition;
            const sourceId = pathwayTransition.item.sourceIds[0];
            ConfirmationDialog_1.Dialog_Confirmation.show({
                header: 'Are you sure?',
                buttons: [
                    {
                        label: 'Yes, Delete',
                        isAsync: true,
                        action: async () => {
                            const resolutionFunction = frontend_3.ModuleFE_Expression.cache.unique(pathwayTransition.item.resolutionFunctionId);
                            await pathwayTransition.delete();
                            if (resolutionFunction)
                                await frontend_3.ModuleFE_Expression.v1.delete(resolutionFunction).executeSync();
                            this.props.functions.selectPathwayState(sourceId);
                        }
                    },
                    {
                        label: 'Don\'t Delete',
                        isPreferred: true,
                        action: ts_common_1.voidFunction,
                    }
                ],
                content: () => React.createElement(React.Fragment, null,
                    React.createElement("div", null, "You're about to delete transition"),
                    React.createElement(Renderer_Transition_1.Renderer_Transition, { transition: pathwayTransition.item, className: 'green' }))
            });
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const pathwayTransition = this.state.pathwayTransition.item;
            const pathwayId = pathwayTransition.pathwayId;
            const pathway = _entity_1.ModuleFE_Pathway.cache.unique(pathwayId);
            if (!pathway)
                throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway for id ${pathwayId}`);
            return React.createElement(React.Fragment, null,
                this.renderTopButtons(),
                React.createElement(Header_Pathway_1.Header_Pathway, { pathway: new frontend_1.EditableDBItemV3(pathway, _entity_1.ModuleFE_Pathway), allowActions: true, functions: this.props.functions, clickableTitle: true }),
                React.createElement(Header_PathwayTransition_1.Header_PathwayTransition, { pathwayTransition: this.state.pathwayTransition, functions: this.props.functions, clickableTitle: true, actions: [
                        { label: 'Edit Transition', action: this.props.toggleEdit },
                        { label: 'Delete Transition', action: this.deleteTransition },
                        {
                            label: 'Edit Resolution Function',
                            action: () => this.props.functions.selectResolutionFunctionEditor(this.state.pathwayTransition.item._id),
                        }
                    ] }));
        };
        this.renderTopButtons = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'pathway__top-buttons' },
                React.createElement(frontend_1.TS_Button, { className: 'pathway__top-buttons__back', onClick: this.props.functions.deselectPathway }, '< Back to list'));
        };
        // ######################## Render - Body ########################
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'details-renderer__wrapper' },
                React.createElement(frontend_2.DataGrid.Grid, null,
                    this.renderTargetPrecondition(),
                    this.renderAlert(),
                    this.renderResolutionFunction(),
                    this.renderTests(),
                    this.renderTreatments(),
                    this.renderNurse(),
                    this.renderConsult(),
                    this.renderMetapathwayActions()),
                this.renderHcsVariables());
        };
        this.renderTargetPrecondition = () => {
            var _a;
            const targetState = this.state.targetState;
            if (!targetState)
                return;
            const preconditions = (_a = targetState.preconditions) === null || _a === void 0 ? void 0 : _a.map(_precondition => {
                const precondition = _entity_1.ModuleFE_Action.cache.unique(_precondition.orderId);
                if (!precondition)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find precondition for id ${precondition}`);
                return precondition;
            });
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Target State Preconditions"),
                React.createElement(frontend_1.LL_V_L, null, preconditions === null || preconditions === void 0 ? void 0 : preconditions.map(precondition => React.createElement(frontend_2.DataGrid.Data, { key: precondition._id }, precondition.name))));
        };
        this.renderAlert = () => {
            const transitionAlert = this.state.pathwayTransition.get('alert');
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Alert"),
                React.createElement(frontend_2.DataGrid.Data, null, transitionAlert !== null && transitionAlert !== void 0 ? transitionAlert : '-'));
        };
        this.renderResolutionFunction = () => {
            const transition = this.state.pathwayTransition.item;
            return React.createElement(React.Fragment, null,
                React.createElement(Component_CollapsablePrettyPrint_1.Component_CollapsablePrettyPrint, { removeIndentation: true, functions: this.props.functions, transitionId: transition._id }),
                React.createElement(frontend_2.DataGrid.Data, null));
        };
        this.renderTests = () => {
            var _a;
            const tests = (_a = this.state.pathwayTransition.item.testsIds) === null || _a === void 0 ? void 0 : _a.map(id => {
                const order = _entity_1.ModuleFE_Action.cache.unique(id);
                if (!order)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find order for id ${id}`);
                return order;
            });
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Tests"),
                !(tests === null || tests === void 0 ? void 0 : tests.length) && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!(tests === null || tests === void 0 ? void 0 : tests.length) && React.createElement(frontend_1.LL_V_L, null, tests.map(test => React.createElement(frontend_2.DataGrid.Data, { key: test._id }, test.name))));
        };
        this.renderTreatments = () => {
            var _a;
            const treatments = (_a = this.state.pathwayTransition.item.treatmentsIds) === null || _a === void 0 ? void 0 : _a.map(id => {
                const order = _entity_1.ModuleFE_Action.cache.unique(id);
                if (!order)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find order for id ${id}`);
                return order;
            });
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Treatments"),
                !(treatments === null || treatments === void 0 ? void 0 : treatments.length) && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!(treatments === null || treatments === void 0 ? void 0 : treatments.length) && React.createElement(frontend_1.LL_V_L, null, treatments.map(treatment => React.createElement(frontend_2.DataGrid.Data, { key: treatment._id }, treatment.name))));
        };
        this.renderConsult = () => {
            var _a;
            const consults = (_a = this.state.pathwayTransition.item.consultIds) === null || _a === void 0 ? void 0 : _a.map(id => {
                const order = _entity_1.ModuleFE_Action.cache.unique(id);
                if (!order)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find order for id ${id}`);
                return order;
            });
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Consult"),
                !(consults === null || consults === void 0 ? void 0 : consults.length) && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!(consults === null || consults === void 0 ? void 0 : consults.length) && React.createElement(frontend_1.LL_V_L, null, consults.map(consult => React.createElement(frontend_2.DataGrid.Data, { key: consult._id }, consult.name))));
        };
        this.renderNurse = () => {
            var _a;
            const nurses = (_a = this.state.pathwayTransition.item.nurseIds) === null || _a === void 0 ? void 0 : _a.map(id => {
                const order = _entity_1.ModuleFE_Action.cache.unique(id);
                if (!order)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find order for id ${id}`);
                return order;
            });
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Nurse"),
                !(nurses === null || nurses === void 0 ? void 0 : nurses.length) && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!(nurses === null || nurses === void 0 ? void 0 : nurses.length) && React.createElement(frontend_1.LL_V_L, null, nurses.map(nurse => React.createElement(frontend_2.DataGrid.Data, { key: nurse._id }, nurse.name))));
        };
        this.renderMetapathwayActions = () => {
            var _a, _b;
            const transition = this.state.pathwayTransition.item;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Metapathway Actions "),
                !((_a = transition.metaPathwayActions) === null || _a === void 0 ? void 0 : _a.length) && React.createElement(frontend_2.DataGrid.Data, null, "-"),
                !!((_b = transition.metaPathwayActions) === null || _b === void 0 ? void 0 : _b.length) && React.createElement(frontend_1.LL_V_L, null, transition.metaPathwayActions.map((action, index) => {
                    var _a;
                    return React.createElement(React.Fragment, { key: index },
                        React.createElement(frontend_2.DataGrid.Data, null, action.type), (_a = action.protocolIds) === null || _a === void 0 ? void 0 :
                        _a.map(protocolId => {
                            const protocol = _entity_1.ModuleFE_Pathway.cache.unique(protocolId);
                            if (!protocol)
                                throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway protocol for id ${protocolId}`);
                            return React.createElement(frontend_2.DataGrid.Data, { key: protocolId, className: 'indent' },
                                "- ",
                                protocol.label);
                        }));
                })));
        };
        this.renderHcsVariables = () => {
            return React.createElement(Section_AllEntityVariables_1.Section_AllEntityVariables, { functions: this.props.functions, pathwayTransitionId: this.state.pathwayTransition.get('_id') });
        };
    }
    __onPathwayTransitionUpdated(...params) {
        const pathwayTransitionId = this.state.pathwayTransition.get('_id');
        if (!pathwayTransitionId)
            return;
        (0, utils_1.basicSyncEventRefresh)(pathwayTransitionId, params, () => this.props.functions.selectPathwayTransition(pathwayTransitionId), () => this.props.functions.deselectPathwayTransition());
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.pathwayTransition = nextProps.pathwayTransition;
        state.targetState = _entity_1.ModuleFE_PathwayState.cache.unique((_a = state.pathwayTransition.item.targetIds) === null || _a === void 0 ? void 0 : _a[0]);
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent pathway-transition-details', style: { padding: '30px', gap: '10px' } },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Renderer_PathwayTransitionDetails = Renderer_PathwayTransitionDetails;
