"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_Relations = exports.DropDown_VariableAttribute = exports.DropDown_Variable = exports.DropDown_Values = exports.HCSDropDowns = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const _shared_1 = require("../../_shared");
const wrappers_1 = require("../../wrappers");
const _entity_1 = require("../../_entity");
const ui_components_1 = require("../../../_entity/variable/frontend/ui-components");
const ui_components_2 = require("../../../_entity/measurement-unit/frontend/ui-components");
const ui_components_3 = require("../../../_entity/attribute-set/frontend/ui-components");
const ui_components_4 = require("../../../_entity/book/frontend/ui-components");
const ui_components_5 = require("../../../_entity/reference/frontend/ui-components");
const ui_components_6 = require("../../../_entity/value/frontend/ui-components");
const ui_components_7 = require("../../../_entity/tag/frontend/ui-components");
const consts_1 = require("@app/styles/frontend/ui/consts");
const ui_components_8 = require("../../../_entity/resource/frontend/ui-components");
const ui_components_9 = require("../../../_entity/expression/frontend/ui-components");
exports.HCSDropDowns = {
    Variable: ui_components_1.DropDown_VariableV3.selectable,
    MeasurementUnit: ui_components_2.DropDown_MeasurementUnit.selectable,
    Value: ui_components_6.DropDown_Value.selectable,
    Reference: ui_components_5.DropDown_Reference.selectable,
    AttributeSet: ui_components_3.DropDown_AttributeSet.selectable,
    Book: ui_components_4.DropDown_Book.selectable,
    Tag: ui_components_7.DropDown_Tag.selectable,
    LogTemplate: _entity_1.DropDown_LogTemplate_OLD.selectable,
    Resource: ui_components_8.DropDown_Resource.selectable,
    NLPData: () => React.createElement(React.Fragment, null),
    VariableRelation: () => React.createElement(React.Fragment, null),
    Score: () => React.createElement(React.Fragment, null),
    Expression: ui_components_9.DropDown_Expression.selectable
};
const DropDown_Values = (props) => {
    var _a;
    const { variable } = props, restOfProps = __rest(props, ["variable"]);
    let containerValue = _entity_1.ModuleFE_Value.cache.unique(variable.valueId);
    if (!containerValue)
        throw new ts_common_1.MUSTNeverHappenException(`Could not find child value with id ${variable.valueId} for variable with id ${variable._id}`);
    let childrenValueIds = [];
    //Fix for standard range
    if ((containerValue === null || containerValue === void 0 ? void 0 : containerValue.type) === 'standard-range') {
        containerValue = _entity_1.ModuleFE_Value.cache.unique(_shared_1.ValType_IND._id);
        if (!containerValue)
            throw new ts_common_1.MUSTNeverHappenException(`Could not find child value with id ${variable.valueId} for variable with id ${variable._id}`);
        childrenValueIds = containerValue.data.scope;
    }
    else {
        childrenValueIds = (_a = _entity_1.ModuleFE_Value.getRelatedValues(variable)) === null || _a === void 0 ? void 0 : _a.values.map(ts_common_1.dbObjectToId);
    }
    return React.createElement(exports.HCSDropDowns.Value, Object.assign({ queryFilter: value => childrenValueIds ? childrenValueIds.includes(value._id) : true, caret: consts_1.DropDown_DefaultCaret, sortBy: [(item) => {
                var _a, _b;
                switch (item.type) {
                    case _entity_1.valueType_SubRange:
                        return item.data.scope[0];
                    case _shared_1.valueType_TimeRange:
                        return (0, _shared_1.resolveValueDisplay)(item);
                    case _entity_1.valueType_EnumElement: {
                        // @ts-ignore
                        if (![_entity_1.valueType_Enumerated, _entity_1.valueType_Tuple, _entity_1.valueType_StandardRange].includes(containerValue.type))
                            return item.data.value;
                        const containerValueAsEnumerated = containerValue;
                        if (containerValue.type === _entity_1.valueType_Enumerated) {
                            const valIndex = containerValueAsEnumerated.data.scope.findIndex(val => val === item._id);
                            if (valIndex === -1)
                                return 0;
                            if ((_a = containerValueAsEnumerated.data.props) === null || _a === void 0 ? void 0 : _a.isOrdered)
                                return valIndex;
                            return ((_b = _entity_1.ModuleFE_Value.cache.unique(containerValueAsEnumerated.data.scope[valIndex])) === null || _b === void 0 ? void 0 : _b.data).value;
                        }
                        if (containerValue.type === _entity_1.valueType_StandardRange) {
                            return _entity_1.StaticValues_EnumElements.findIndex(value => item._id === value._id);
                        }
                        return item._id;
                    }
                    default:
                        return item.type;
                }
            }] }, restOfProps));
};
exports.DropDown_Values = DropDown_Values;
//If you use this component without an ancestor to await ModuleFE_Variable, THAT'S ON YOU!
class DropDown_Variable extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.getSelectedItem = () => {
            const selected = this.props.selected;
            switch (typeof selected) {
                case 'function':
                    return selected();
                case 'string':
                    return _entity_1.ModuleFE_Variable.cache.unique(selected);
                case 'object':
                    return selected;
            }
            return undefined;
        };
    }
    deriveStateFromProps(nextProps, state) {
        if (nextProps.queryFilter)
            state.variables = _entity_1.ModuleFE_Variable.cache.filter(nextProps.queryFilter);
        else
            state.variables = _entity_1.ModuleFE_Variable.cache.allMutable();
        if (nextProps.sortBy)
            state.variables = (0, ts_common_1.sortArray)(state.variables, nextProps.sortBy[0]);
        return state;
    }
    render() {
        var _a;
        const className = (0, frontend_1._className)('variable-dropdown', this.props.className);
        const filter = new ts_common_1.Filter(i => new wrappers_1.VariableWrapper(_entity_1.ModuleFE_Variable.cache.unique(i._id)).getSearchableText());
        const adapter = (filterText) => (0, frontend_1.SimpleListAdapter)(this.state.variables, i => React.createElement(frontend_1.LL_V_L, { className: 'node-data' },
            React.createElement("span", { className: 'variable-name' }, i.item.name),
            filterText &&
                React.createElement("span", { className: 'pass-reason' }, new wrappers_1.VariableWrapper(_entity_1.ModuleFE_Variable.cache.unique(i.item._id)).renderVarFilterPassReason(filterText))));
        return React.createElement(frontend_1.TS_DropDown, Object.assign({}, this.props, { limitItems: (_a = this.props.limitItems) !== null && _a !== void 0 ? _a : 50, className: className, adapter: adapter, selected: this.getSelectedItem(), placeholder: 'Select a variable', filter: filter }));
    }
}
exports.DropDown_Variable = DropDown_Variable;
class DropDown_VariableAttribute extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.handleInputChange = (inputValue, dropDown) => {
            if (!inputValue.length)
                this.setState({ selectedAttribute: undefined, selectedVariable: undefined });
            if (inputValue.endsWith('.')) {
                const variableName = inputValue.slice(0, -1);
                const variable = this.state.variables.find(v => v._nameInLowercase === variableName.toLowerCase());
                if (variable) {
                    this.setState({ selectingAttribute: true, selectedVariable: variable });
                }
            }
            else {
                const variable = this.state.variables.find(v => v._nameInLowercase === inputValue.toLowerCase());
                if (variable) {
                    this.setState({ selectedVariable: variable });
                }
            }
            const splitInput = inputValue.split('.');
            // @ts-ignore
            dropDown.reDeriveState({ filterText: splitInput[splitInput.length - 1] });
        };
        this.handleSelection = (item) => {
            const _variable = this.state.selectedVariable;
            if (!item || !_variable)
                return;
            if ((_variable === null || _variable === void 0 ? void 0 : _variable._nameInLowercase) === (item === null || item === void 0 ? void 0 : item._nameInLowercase)) {
                this.props.onSelected(_variable, 'variable');
                if (this.state.selectingAttribute)
                    this.props.onSelected(undefined, 'attribute');
            }
            else {
                this.props.onSelected(_variable, 'variable');
                this.props.onSelected(item, 'attribute');
            }
        };
        this.renderSelectedItem = () => {
            var _a;
            if (!this.state.selectedAttribute && !this.state.selectedVariable)
                return React.createElement("div", { className: "ts-dropdown__placeholder" }, 'Pick A Variable');
            return React.createElement("div", { className: 'ts-dropdown__selected' }, `${(_a = this.state.selectedVariable) === null || _a === void 0 ? void 0 : _a.name}${this.state.selectedAttribute ? `.${this.state.selectedAttribute.name}` : ''}`);
        };
        this.renderSearchInput = (dropDown) => {
            return React.createElement(frontend_1.TS_Input, { type: "text", value: dropDown.props.inputValue, onChange: (filterText) => this.handleInputChange(filterText, dropDown), focus: true, style: { width: '100%' }, placeholder: dropDown.props.placeholder || '', onCancel: () => {
                    // @ts-ignore
                    dropDown.reDeriveState({ open: false, filterText: undefined });
                }, onKeyDown: (e) => {
                    // @ts-ignore
                    dropDown.inputKeyEventHandler(e);
                } });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = (_a = this.state) !== null && _a !== void 0 ? _a : {};
        if (nextProps.queryFilter)
            state.variables = _entity_1.ModuleFE_Variable.cache.filter(nextProps.queryFilter);
        else
            state.variables = _entity_1.ModuleFE_Variable.cache.allMutable();
        if (nextProps.sortBy)
            state.variables = (0, ts_common_1.sortArray)(state.variables, nextProps.sortBy[0]);
        state.selectedVariable = nextProps.selected.variable;
        state.selectedAttribute = nextProps.selected.attribute;
        return state;
    }
    render() {
        var _a, _b, _c, _d;
        const items = this.state.selectingAttribute
            ? (_c = (_b = (_a = this.state.selectedVariable) === null || _a === void 0 ? void 0 : _a._attributeVarIds) === null || _b === void 0 ? void 0 : _b.map(attrId => _entity_1.ModuleFE_Variable.cache.unique(attrId))) !== null && _c !== void 0 ? _c : []
            : this.state.variables;
        const filter = new ts_common_1.Filter(i => new wrappers_1.VariableWrapper(_entity_1.ModuleFE_Variable.cache.unique(i._id)).getSearchableText());
        const adapter = (filterText) => (0, frontend_1.SimpleListAdapter)(items, i => {
            var _a, _b;
            return React.createElement(frontend_1.LL_V_L, null,
                React.createElement("span", { className: 'variable-name' }, (_a = i.item) === null || _a === void 0 ? void 0 : _a.name),
                filterText &&
                    React.createElement("span", { className: 'pass-reason' }, new wrappers_1.VariableWrapper(_entity_1.ModuleFE_Variable.cache.unique((_b = i.item) === null || _b === void 0 ? void 0 : _b._id)).renderVarFilterPassReason(filterText)));
        });
        return React.createElement(frontend_1.TS_DropDown, { selectedItemRenderer: this.renderSelectedItem, renderSearch: this.renderSearchInput, limitItems: (_d = this.props.limitItems) !== null && _d !== void 0 ? _d : 50, className: 'variable-dropdown', adapter: adapter, filter: filter, selected: this.state.selectedVariable, onSelected: this.handleSelection });
    }
}
DropDown_VariableAttribute.defaultProps = {
    modules: [_entity_1.ModuleFE_Variable]
};
exports.DropDown_VariableAttribute = DropDown_VariableAttribute;
function DropDown_Relations(props) {
    const dropdownValues = [
        'Contains',
        'Member Of',
        'Derivative',
        'Convert To Enum'
    ];
    const generateAdapterList = () => ((0, frontend_1.SimpleListAdapter)(dropdownValues, i => React.createElement("div", { className: 'node-data' },
        React.createElement("span", { className: 'variable-name' }, i.item))));
    return React.createElement(frontend_1.TS_DropDown, { adapter: generateAdapterList(), placeholder: 'Select a relation', selected: props.relation ? props.relation : undefined, onSelected: selectedRelation => props.onSelectRelation(selectedRelation, props.index) });
}
exports.DropDown_Relations = DropDown_Relations;
