"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Pathway = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateString)(),
    type: (0, ts_common_1.tsValidateValue)([...types_1.PathwayTypes]),
    variableId: ts_common_1.tsValidateUniqueId,
    description: (0, ts_common_1.tsValidateString)(-1, false),
    version: (0, ts_common_1.tsValidateString)(),
    underConstruction: (0, ts_common_1.tsValidateBoolean)(false),
    diseaseProfileIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false),
};
const Validator_GeneratedProps = {
    _originalPathway: ts_common_1.tsValidateOptionalId
};
exports.DBDef_Pathway = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'pathway',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'pathway'
    },
    backend: {
        name: 'pathway'
    },
    entityName: 'Pathway',
    dependencies: {
        'variableId': {
            dbKey: 'variables',
            fieldType: 'string'
        }
    }
};
