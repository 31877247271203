"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PgDev_DPReport_MultiVars = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const _entity_1 = require("../../../../_entity");
const _enum_1 = require("../../../../_enum");
class PgDev_DPReport_MultiVars extends frontend_1.ComponentSync {
    constructor() {
        //######################### Life Cycle #########################
        super(...arguments);
        //######################### Logic #########################
        this.refsAreEmpty = () => {
            return (0, ts_common_1._values)(this.state.viewConfigRefs).some(ref => !(ref === null || ref === void 0 ? void 0 : ref.current));
        };
        this.compareHeights = (hm1, hm2) => {
            for (const key of (0, ts_common_1._keys)(hm1)) {
                if (!hm2[key] || hm1[key] > hm2[key])
                    return true;
            }
            return false;
        };
        this.getMultivarsForView = (view) => {
            return _entity_1.ModuleFE_MultiVar.cache.filter(mv => mv.diseaseProfileId === this.props.diseaseProfile._id &&
                _entity_1.ModuleFE_DiseaseView.filterMultiVarByEnforcedTag(view, mv));
        };
        this.getDiseaseValuesForMultiVar = (mv) => {
            return _entity_1.ModuleFE_DiseaseValue.cache.filter(dv => dv.multiVarId === mv._id);
        };
        //######################### Render #########################
        this.renderTabHeader = () => {
            const profile = this.props.diseaseProfile;
            return React.createElement("span", { className: 'profile-title' }, profile.label);
        };
        this.renderMultiVar = (multiVar, key, pref) => {
            if (pref.mustHaveDiseaseValues && this.getDiseaseValuesForMultiVar(multiVar).length === 0)
                return '';
            return React.createElement(frontend_1.LL_V_L, { className: 'multivar', key: key },
                this.renderMultiVarTable(multiVar, key, pref),
                this.renderMultiVarRefs(multiVar));
        };
        this.renderMultiVarRefs = (multiVar) => {
            if (multiVar.referenceIds.length === 0)
                return '';
            return React.createElement(frontend_1.LL_V_L, { className: 'multivar-references' },
                React.createElement("div", { className: 'field-title' }, "References"),
                React.createElement(frontend_2.Component_ReferencesTable, { blockEdit: true, referenceIds: multiVar.referenceIds || [], onReferenceUpsertCallback: ts_common_1.voidFunction, onReferenceDeletedCallback: ts_common_1.voidFunction, allowedRefs: undefined }));
        };
        this.renderMultiVarTable = (multiVar, key, pref) => {
            const length = multiVar.variables.length + (pref.ignoreLikelihood ? 0 : 1) + (pref.ignoreSeverity ? 0 : 1);
            const diseaseValues = this.getDiseaseValuesForMultiVar(multiVar);
            return React.createElement(frontend_1.LL_V_L, { className: 'multivar-table', key: key, style: { gridTemplateColumns: `repeat(${length - 2},1fr) repeat(2,100px)` } },
                multiVar.variables.map((item, i) => {
                    if (i === 0 && pref.ignoreFirstVar)
                        return '';
                    const variable = frontend_2.ModuleFE_Variable.cache.unique(item.variableId);
                    return React.createElement("div", { className: 'grid-cell header', key: `header-${i}` },
                        variable.name,
                        " (",
                        item.role[0].toUpperCase(),
                        ")");
                }),
                !pref.ignoreLikelihood && React.createElement("div", { className: 'grid-cell header' }, "Likelihood"),
                !pref.ignoreSeverity && React.createElement("div", { className: 'grid-cell header' }, "Severity"),
                React.createElement("div", { className: 'grid-separator' }), diseaseValues === null || diseaseValues === void 0 ? void 0 :
                diseaseValues.map((dv, dvi) => {
                    const arr = [];
                    multiVar.variables.forEach((item, i) => {
                        var _a;
                        if (i === 0 && pref.ignoreFirstVar)
                            return;
                        //Find the value id in either the values map or the attributes map
                        const valueId = dv.values[item.variableId] || ((_a = dv.attributes[item.variableId]) === null || _a === void 0 ? void 0 : _a[item.attributeeId]);
                        if (!valueId)
                            arr.push(React.createElement("div", { className: 'grid-cell', key: `${dv._id}-${item.variableId}-${dvi}` }));
                        else {
                            const value = frontend_2.ModuleFE_Value.cache.unique(valueId);
                            arr.push(React.createElement("div", { className: 'grid-cell', key: `${dv._id}-${item.variableId}-${dvi}` }, (0, frontend_2.resolveValueDisplay)(value)));
                        }
                    });
                    if (!pref.ignoreLikelihood)
                        arr.push(React.createElement("div", { className: 'grid-cell', key: `${dvi}-likelihood` }, `${dv.likelihood ? _enum_1.LikelihoodLabelMap[dv.likelihood] : ''} ${(0, frontend_2.resolveRangeForPrint)(dv.likelihoodRange)}`));
                    if (!pref.ignoreSeverity)
                        arr.push(React.createElement("div", { className: 'grid-cell', key: `${dvi}-severity` }, dv.severityFactor ? _enum_1.SeverityLabelMap[dv.severityFactor] : ''));
                    arr.push(React.createElement("div", { className: 'grid-separator', key: `${dvi}-sep` }));
                    return arr;
                }));
        };
    }
    componentDidUpdate() {
        const heightMap = this.state.diseaseViews.reduce((hm, view) => {
            const current = this.state.viewConfigRefs[view._id].current;
            if (!current)
                return hm;
            hm[view._id] = current.getBoundingClientRect().height;
            return hm;
        }, {});
        if (this.compareHeights(heightMap, this.props.heightMap)) {
            this.props.reportHeights(heightMap);
        }
        else {
            this.setState({ heightMap: this.props.heightMap });
        }
    }
    deriveStateFromProps(nextProps) {
        var _a, _b;
        const state = {};
        state.heightMap = ((_a = this.state) === null || _a === void 0 ? void 0 : _a.heightMap) || {};
        //If new height map received, delete current for re-calculate
        if (!(0, ts_common_1.compare)(nextProps.heightMap, state.heightMap))
            state.heightMap = {};
        state.diseaseViews = _entity_1.ModuleFE_DiseaseView.cache.all();
        state.viewConfigRefs = ((_b = this.state) === null || _b === void 0 ? void 0 : _b.viewConfigRefs) || {};
        state.diseaseViews.forEach(view => {
            if (!state.viewConfigRefs[view._id])
                state.viewConfigRefs[view._id] = React.createRef();
        });
        return state;
    }
    render() {
        if (this.refsAreEmpty()) {
            this.forceUpdate();
        }
        return React.createElement(frontend_1.LL_V_L, { className: 'profile' },
            this.renderTabHeader(),
            React.createElement(frontend_1.LL_V_L, { className: 'profile-data' }, this.state.diseaseViews.map((view) => {
                const height = this.state.heightMap[view._id];
                const multiVars = this.getMultivarsForView(view);
                const ignoreTail = ['treatments', 'differential diagnosis'].includes(view.label.toLowerCase());
                return React.createElement("div", { className: 'view-container', key: view._id, ref: this.state.viewConfigRefs[view._id], style: { height: height ? `${height}px` : '' } },
                    React.createElement("div", { className: 'view-container__header' }, view.label),
                    React.createElement(frontend_1.LL_H_C, { className: 'view-container__multivars' }, multiVars === null || multiVars === void 0 ? void 0 : multiVars.map((mv, j) => {
                        return this.renderMultiVar(mv, j, { ignoreSeverity: ignoreTail, ignoreLikelihood: ignoreTail });
                    })));
            })));
    }
}
exports.PgDev_DPReport_MultiVars = PgDev_DPReport_MultiVars;
