"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_VarResolverTypes = exports.FHIR_QueryKeys = void 0;
exports.FHIR_QueryKeys = [
    'AllergyIntolerance',
    'Diagnoses',
    'Infection',
    'MedicalHistory',
    'DiagnosticReportResults',
    'ClinicalNotes',
    'EncounterResource',
    'FamilyMemberHistory',
    'Immunization',
    'ListAllergies',
    'ListFamilyHistory',
    'ListImmunizations',
    'ListMedications',
    'ListProblems',
    'MedicationResource',
    'LabResults',
    'VitalSigns',
    'PatientResource',
    'ProcedureOrders',
    'ProcedureSurgeries',
    'SurgicalHistory',
    'ServiceRequestOrders',
];
exports.FHIR_VarResolverTypes = [
    'Resolver_Codeable',
    'Resolver_Profile',
];
