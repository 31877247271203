"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_AttributeSet = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateRegexp)(/^[\w\W\s&/,\\-]{2,100}$/),
    type: (0, ts_common_1.tsValidateValue)(['Variable', 'Tag']),
    entityId: ts_common_1.tsValidateUniqueId,
    attributeIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
const Validator_GeneratedProps = {
    _variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _tagId: ts_common_1.tsValidateOptionalId
};
exports.DBDef_AttributeSet = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1', '1.0.0'],
    dbKey: 'attribute-set',
    entityName: 'AttributeSet',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'attribute-set'
    },
    backend: {
        name: 'attribute-set'
    },
    dependencies: {
        _variableIds: {
            dbKey: 'variables',
            fieldType: 'string[]'
        },
        _tagId: {
            dbKey: 'tags',
            fieldType: 'string'
        }
    }
};
