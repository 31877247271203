"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header_Pathway = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../consts");
const Header_WithOptionMenu_1 = require("@app/styles/frontend/ui-manager/headers/Header_WithOptionMenu");
const ConfirmationDialog_1 = require("@app/styles/frontend/ui-manager/dialogs/ConfirmationDialog");
const frontend_2 = require("@app/hcs/frontend");
const _entity_1 = require("../../../_entity");
const consts_2 = require("../../../../_entity/pathway/shared/consts");
const styles_1 = require("@app/styles");
const Header_Pathway = (props) => {
    var _a, _b;
    const pathwayVariable = frontend_2.ModuleFE_Variable.cache.unique(props.pathway.item.variableId);
    const titleClickAction = (_a = props.titleClickAction) !== null && _a !== void 0 ? _a : (() => props.functions.deselectPathwayState());
    if (!pathwayVariable)
        throw new ts_common_1.MUSTNeverHappenException(`pathway variable with id ${props.pathway.item.variableId} not found`);
    const subtitle = `${consts_2.PathwayTypeToLabel[props.pathway.item.type]} Pathway | ${pathwayVariable.name}`;
    return React.createElement(Header_WithOptionMenu_1.Header_WithOptionMenu, { title: props.pathway.item.label, iconToRender: props.pathway.item.underConstruction &&
            React.createElement(styles_1.ICONS.under_construction.component, Object.assign({}, frontend_1.openContent.tooltip.top('under-construction', React.createElement(React.Fragment, null, "Protocol under construction"), { offset: 5 }))), subtitle: subtitle, className: 'underline', asyncTitleClick: props.isClickAsync, onTitleClick: props.clickableTitle ? titleClickAction : undefined, menuActions: !props.allowActions ? undefined : (0, ts_common_1.filterInstances)([
            ((_b = props.pathway.item) === null || _b === void 0 ? void 0 : _b._id) ? {
                label: 'Add Note', action: (e) => {
                    const discussionView = {
                        id: 'discussion-right-click-menu',
                        content: React.createElement(frontend_2.CreateDiscussionPopup, { entityName: _entity_1.ModuleFE_Pathway.dbDef.entityName, refId: props.pathway.item._id }),
                        originPos: {
                            y: e.clientY,
                            x: e.clientX,
                        },
                        modalPos: { x: 0, y: 1 },
                    };
                    try {
                        frontend_1.ModuleFE_MouseInteractivity.showContent(discussionView);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            } : undefined,
            { label: 'Edit Pathway', action: props.functions.openPathwayEdit },
            {
                label: 'Delete Pathway',
                action: (e) => {
                    ConfirmationDialog_1.Dialog_Confirmation.show({
                        header: 'Are you sure?',
                        content: () => React.createElement(React.Fragment, null,
                            React.createElement("div", null, "You're about to delete a pathway"),
                            React.createElement("div", null, props.pathway.item.label)),
                        buttons: [
                            {
                                label: 'Yes, Delete',
                                isAsync: true,
                                action: async () => {
                                    const pathwayId = props.pathway.item._id;
                                    if (!pathwayId)
                                        throw new ts_common_1.MUSTNeverHappenException('Trying to delete a pathway without an _id');
                                    props.functions.deselectPathway();
                                    await _entity_1.ModuleFE_Pathway._v1.deletePathway({ pathwayId }).executeSync();
                                }
                            },
                            {
                                label: 'Don\'t Delete',
                                isPreferred: true,
                                action: () => {
                                }
                            }
                        ]
                    });
                }
            },
            {
                label: 'Duplicate Pathway',
                action: (e) => {
                    ConfirmationDialog_1.Dialog_Confirmation.show({
                        header: 'Duplicate Pathway',
                        content: () => React.createElement(React.Fragment, null,
                            React.createElement("div", null, "You're about to duplicate a pathway"),
                            React.createElement("div", null, props.pathway.item.label)),
                        buttons: [
                            {
                                label: 'Yes, Duplicate',
                                isAsync: true,
                                action: async () => {
                                    const pathwayId = props.pathway.item._id;
                                    if (!pathwayId)
                                        throw new ts_common_1.MUSTNeverHappenException('Trying to duplicate a pathway without an _id');
                                    const { newPathway } = await _entity_1.ModuleFE_Pathway._v1.duplicatePathway({ pathwayId }).executeSync();
                                    props.functions.selectPathway(newPathway._id);
                                }
                            },
                            {
                                label: 'Don\'t Duplicate',
                                isPreferred: true,
                                action: () => {
                                }
                            }
                        ]
                    });
                }
            },
            {
                label: 'Add New State',
                action: (e) => {
                    const pathwayId = props.pathway.item._id;
                    if (!pathwayId)
                        throw new ts_common_1.MUSTNeverHappenException('Trying to set a new state for pathway with no id');
                    const newState = (0, consts_1.Empty_PathwayState)(pathwayId);
                    props.functions.selectPathwayState(new frontend_1.EditableDBItemV3(newState, _entity_1.ModuleFE_PathwayState), true);
                }
            }
        ]) });
};
exports.Header_Pathway = Header_Pathway;
