"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_MeasurementUnit = exports.ModuleFE_MeasurementUnit_Class = exports.dispatch_onMeasurementUnitChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onMeasurementUnitChanged = new types_1.ThunderDispatcherV3('__onMeasurementUnitUpdated');
class ModuleFE_MeasurementUnit_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_MeasurementUnit, exports.dispatch_onMeasurementUnitChanged);
        this.measurementToDimensionDictionary = {};
        this.initializeMeasurementUnitsMap = () => {
            this.measurementToDimensionDictionary['g'] = 'mass';
            this.measurementToDimensionDictionary['mg'] = 'mass';
            this.measurementToDimensionDictionary['mcg'] = 'mass';
            this.measurementToDimensionDictionary['ng'] = 'mass';
            this.measurementToDimensionDictionary['pg'] = 'mass';
            this.measurementToDimensionDictionary['µg'] = 'mass';
            this.measurementToDimensionDictionary['l'] = 'volume';
            this.measurementToDimensionDictionary['dl'] = 'volume';
            this.measurementToDimensionDictionary['ml'] = 'volume';
            this.measurementToDimensionDictionary['fl'] = 'volume';
            this.measurementToDimensionDictionary['s'] = 'time';
            this.measurementToDimensionDictionary['min'] = 'time';
            this.measurementToDimensionDictionary['h'] = 'time';
            this.measurementToDimensionDictionary['24h'] = 'time';
            this.measurementToDimensionDictionary['mmol'] = 'moles';
            this.measurementToDimensionDictionary['µmol'] = 'moles';
            this.measurementToDimensionDictionary['nmol'] = 'moles';
            this.measurementToDimensionDictionary['mEq'] = 'moles';
            this.measurementToDimensionDictionary['µ'] = 'enzymatic activity';
            // this.measurementToDimensionDictionary[''] = '';
        };
        this.getDimensionForUnit = (unit) => {
            return this.measurementToDimensionDictionary[unit.toLowerCase()];
        };
        this.initializeMeasurementUnitsMap();
    }
}
exports.ModuleFE_MeasurementUnit_Class = ModuleFE_MeasurementUnit_Class;
exports.ModuleFE_MeasurementUnit = new ModuleFE_MeasurementUnit_Class();
