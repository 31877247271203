"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComponents = exports.getSystemValue = exports.getSystemCoding = exports.getCodableConcept = exports.FHIR_CreateRequest_Search_Extractor = exports.extractSearchResponse = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const extractSearchResponse = (entry) => {
    var _a, _b;
    return {
        reference: (0, exports.getCodableConcept)(entry.resource.code),
        values: (0, exports.getComponents)(entry.resource.component),
        issued: entry.resource.issued,
        recordedDate: entry.resource.recordedDate,
        abatementDateTime: entry.resource.recordedDate,
        performedDateTime: entry.resource.performedDateTime,
        clinicalStatus: entry.resource.clinicalStatus,
        verificationStatus: entry.resource.verificationStatus,
        status: entry.resource.status,
        reasonCode: (0, exports.getCodableConcept)(entry.resource.reasonCode),
        performedPeriod: entry.resource.performedPeriod,
        bodySite: (0, exports.getCodableConcept)(entry.resource.bodySite),
        issue: (0, exports.getCodableConcept)((_a = entry.resource.issue) === null || _a === void 0 ? void 0 : _a[0].details),
        severity: (_b = entry.resource.issue) === null || _b === void 0 ? void 0 : _b[0].severity,
        valueQuantity: (0, exports.getSystemValue)(entry.resource.valueQuantity),
    };
};
exports.extractSearchResponse = extractSearchResponse;
/**
 * Note that all optional fields are most likely always-existing for a specific category, and not existing for most others.
 * Right now we don't yet have category-specific fields to extract for the specific categories that require it.
 */
const FHIR_CreateRequest_Search_Extractor = async (response) => {
    var _a;
    return (_a = response.patient_data[0].entry) === null || _a === void 0 ? void 0 : _a.map(exports.extractSearchResponse);
};
exports.FHIR_CreateRequest_Search_Extractor = FHIR_CreateRequest_Search_Extractor;
const getCodableConcept = (item) => (!item ? undefined : {
    text: item.text,
    coding: (0, exports.getSystemCoding)(item === null || item === void 0 ? void 0 : item.coding)
});
exports.getCodableConcept = getCodableConcept;
const getSystemCoding = (codes = []) => {
    return codes === null || codes === void 0 ? void 0 : codes.map((code) => (0, ts_common_1.filterKeys)({
        refSystem: code === null || code === void 0 ? void 0 : code.system,
        refCode: code === null || code === void 0 ? void 0 : code.code,
        text: code === null || code === void 0 ? void 0 : code.display, // label of variable
    }, 'text'));
};
exports.getSystemCoding = getSystemCoding;
const getSystemValue = (code) => {
    if (!code)
        return undefined;
    return {
        refSystem: code.system,
        refCode: code.code,
        value: code.value, // label of variable
    };
};
exports.getSystemValue = getSystemValue;
const getComponents = (components) => {
    if (!components)
        return undefined;
    return components === null || components === void 0 ? void 0 : components.map(component => {
        var _a;
        return ({
            reference: {
                text: (_a = component.code) === null || _a === void 0 ? void 0 : _a.text,
                coding: (0, exports.getSystemCoding)(component.code.coding)
            },
            value: {
                refSystem: component.valueQuantity.system,
                refCode: component.valueQuantity.code,
                value: component.valueQuantity.value, // value, e.g 120
            }
        });
    });
};
exports.getComponents = getComponents;
