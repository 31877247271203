"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QSearch = void 0;
require("./QSearch.scss");
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
/**
 * @category Components
 */
class QSearch extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.onClear = () => {
            this.setState({ searchText: '' }, () => { var _a, _b; return (_b = (_a = this.props).onClear) === null || _b === void 0 ? void 0 : _b.call(_a); });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.searchText = (_a = nextProps.filter) !== null && _a !== void 0 ? _a : '';
        return state;
    }
    render() {
        const _a = this.props, { onSearch, onClear } = _a, props = __rest(_a, ["onSearch", "onClear"]);
        return (React.createElement(frontend_1.LL_H_C, { className: "qsearch" },
            React.createElement(styles_1.ICONS.search.component, { className: 'qsearch-icon' }),
            this.state.searchText.length ?
                React.createElement("div", { className: 'clear-icon', onClick: () => {
                        this.props.onSearch('');
                        this.setState({ searchText: '' });
                    } },
                    React.createElement(styles_1.ICONS.x.component, { onClick: this.onClear }))
                : null,
            React.createElement(frontend_1.TS_Input, Object.assign({}, props, { id: `search-bar-${this.props.id}`, type: "text", value: this.state.searchText, placeholder: this.props.placeholder || 'Search', onChange: (searchText) => {
                    this.props.onSearch(searchText);
                    this.setState({ searchText });
                }, onBlur: this.props.onBlur }))));
    }
}
exports.QSearch = QSearch;
