"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCleanDiseaseValue = exports.cleanDiseaseValue = exports.indicativeOptions = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.indicativeOptions = Object.freeze([
    { label: 'Rule In', value: 1 },
    { label: 'Rule Out', value: -1 },
]);
exports.cleanDiseaseValue = Object.freeze({
    multiVarId: '',
    _diseaseProfileId: '',
    values: {},
    attributes: {},
    _values: [],
    _variables: [],
    _attributeIds: [],
});
const getCleanDiseaseValue = (diseaseProfileId, multiVarId) => {
    const dv = (0, ts_common_1.deepClone)(exports.cleanDiseaseValue);
    dv._diseaseProfileId = diseaseProfileId;
    dv.multiVarId = multiVarId;
    return dv;
};
exports.getCleanDiseaseValue = getCleanDiseaseValue;
