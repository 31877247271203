"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Manager_Variable = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Manager_Variable.scss");
const q_components_1 = require("../q-components");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../_entity");
const sectionKeys = ['general', 'references', 'values', 'attributes', 'relations'];
const sectionLabelMap = {
    general: 'General',
    references: 'References',
    values: 'Values',
    attributes: 'Attributes',
    relations: 'Relations'
};
class Manager_Variable extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Life Cycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.onSectionClick = (sectionKey) => {
            if (sectionKey === this.state.selectedSectionKey)
                return;
            this.setState({ selectedSectionKey: sectionKey });
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const variable = this.state.variable.item;
            let hierarchy = 'Healthcare Space';
            const sourceGroup = variable.tagIds.find(group => group.key === _entity_1.ConfigKeyFE_SourceTagId.get());
            hierarchy += ` > ${_entity_1.ModuleFE_Tag.cache.unique(sourceGroup.value[0]).label}`;
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__header' },
                React.createElement(frontend_1.LL_V_L, { className: 'manager__header__main' },
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__title' },
                        React.createElement("div", { className: 'manager__header__title__label' }, variable.name)),
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__sub-title' },
                        React.createElement("span", { className: 'manager__header__sub-title__item' }, "Variable"),
                        "in",
                        React.createElement("span", { className: 'manager__header__sub-title__hierarchy' }, hierarchy))),
                React.createElement(frontend_1.LL_H_C, { className: 'manager__header__tail' },
                    React.createElement(styles_1.ICONS.messageBubble.component, { className: 'discussion-icon', onClick: () => {
                            _entity_1.ModuleFE_Discussion.openDiscussions({ newDiscussion: { type: _entity_1.ModuleFE_Variable.dbDef.dbKey, refId: variable._id } });
                        } })));
        };
        // ######################## Render - Sections ########################
        this.renderSections = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'manager__sections' }, sectionKeys.map(this.renderSection));
        };
        this.renderSection = (sectionKey) => {
            const className = (0, frontend_1._className)('manager__section', sectionKey === this.state.selectedSectionKey && 'selected');
            return React.createElement("div", { key: sectionKey, className: className, onClick: () => this.onSectionClick(sectionKey) }, sectionLabelMap[sectionKey]);
        };
        // ######################## Render - Section Data ########################
        this.renderSectionInfo = () => {
            return React.createElement(q_components_1.QScrollWrapper, null,
                React.createElement(frontend_1.Grid, { className: 'manager__data-grid manager__variable__info' },
                    this.renderGeneralInfo(),
                    this.renderReferencesInfo(),
                    this.renderValuesInfo(),
                    this.renderAttributesInfo(),
                    this.renderRelationsInfo()));
        };
        this.renderGeneralInfo = () => {
            var _a, _b, _c;
            if (this.state.selectedSectionKey !== 'general')
                return;
            const variable = this.state.variable;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'data-grid__label' }, "Variable Name"),
                React.createElement("div", { className: 'data-grid__data' }, variable.item.name),
                React.createElement("div", { className: 'data-grid__label' }, "Is Attribute"),
                React.createElement("div", { className: 'data-grid__data' }, variable.item.isAttribute ? 'Yes' : 'No'),
                React.createElement("div", { className: 'data-grid__label' }, "Role"),
                React.createElement("div", { className: 'data-grid__data' }, (_a = variable.item.role) !== null && _a !== void 0 ? _a : 'N/A'),
                React.createElement("div", { className: 'data-grid__label' }, "Perspective"),
                React.createElement("div", { className: 'data-grid__data' }, (_b = variable.item.perspective) !== null && _b !== void 0 ? _b : 'N/A'),
                React.createElement("div", { className: 'data-grid__label full-span' }, "Tags"), (_c = variable.item.tagIds) === null || _c === void 0 ? void 0 :
                _c.map(group => {
                    const parentTag = _entity_1.ModuleFE_Tag.cache.unique(group.key);
                    const tags = group.value.map(id => _entity_1.ModuleFE_Tag.cache.unique(id));
                    return React.createElement(React.Fragment, { key: parentTag._id },
                        React.createElement("div", { className: 'data-grid__label sub-title' }, parentTag.label),
                        React.createElement(frontend_1.LL_V_L, null, tags.map(tag => React.createElement("div", { key: tag._id, className: 'data-grid__data' }, tag.label))));
                }));
        };
        this.renderReferencesInfo = () => {
            if (this.state.selectedSectionKey !== 'references')
                return;
            const variable = this.state.variable;
            const references = _entity_1.ModuleFE_Reference.cache.filter(ref => { var _a; return ((_a = variable.item.referenceIds) !== null && _a !== void 0 ? _a : []).includes(ref._id); });
            const snomedRefs = references.filter(_entity_1.ModuleFE_Reference.is.snomed);
            const loincRefs = references.filter(_entity_1.ModuleFE_Reference.is.loinc);
            const icdRefs = references.filter(_entity_1.ModuleFE_Reference.is.icdCode);
            return React.createElement(React.Fragment, null,
                !!snomedRefs.length && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'data-grid__label' }, "Snomed"),
                    React.createElement(frontend_1.LL_V_L, null, snomedRefs.map(ref => {
                        var _a;
                        return React.createElement("div", { key: ref._id, className: 'data-grid__data' },
                            ref.data.id,
                            " (", (_a = ref.data.names) === null || _a === void 0 ? void 0 :
                            _a.join(', '),
                            ")");
                    }))),
                !!loincRefs.length && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'data-grid__label' }, "Loinc"),
                    React.createElement(frontend_1.LL_V_L, null, loincRefs.map(ref => {
                        return React.createElement("div", { key: ref._id, className: 'data-grid__data' },
                            ref.data.id,
                            " (",
                            ref.data.name,
                            ")");
                    }))),
                !!icdRefs.length && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'data-grid__label' }, "ICD Codes"),
                    React.createElement(frontend_1.LL_V_L, null, icdRefs.map(ref => {
                        return React.createElement("div", { key: ref._id, className: 'data-grid__data' }, ref.data.id);
                    }))));
        };
        this.renderValuesInfo = () => {
            var _a;
            if (this.state.selectedSectionKey !== 'values')
                return;
            const variable = this.state.variable;
            const parentValue = _entity_1.ModuleFE_Value.cache.unique(variable.item.valueId);
            const values = (_a = _entity_1.ModuleFE_Value.getRelatedValues(variable.item)) === null || _a === void 0 ? void 0 : _a.values;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'data-grid__label' }, "Value Type"),
                React.createElement("div", { className: 'data-grid__data' }, parentValue.type),
                React.createElement("div", { className: 'data-grid__label full-span' }, "Values"),
                React.createElement(frontend_1.LL_H_C, { className: 'section-point__values' }, values === null || values === void 0 ? void 0 : values.map(value => {
                    return React.createElement("div", { className: 'data-grid__data', key: value._id }, (0, _entity_1.resolveValueDisplay)(value));
                })));
        };
        this.renderAttributesInfo = () => {
            var _a;
            if (this.state.selectedSectionKey !== 'attributes')
                return;
            const variable = this.state.variable;
            return React.createElement(React.Fragment, null, (_a = variable.item.attributes) === null || _a === void 0 ? void 0 : _a.map(coupling => {
                const variable = _entity_1.ModuleFE_Variable.cache.unique(coupling.varId);
                const parentValue = _entity_1.ModuleFE_Value.cache.unique(coupling.valueId);
                const values = parentValue.data.scope.map(id => _entity_1.ModuleFE_Value.cache.unique(id));
                return React.createElement(React.Fragment, { key: variable._id },
                    React.createElement("div", { className: 'data-grid__label' }, variable.name),
                    React.createElement(frontend_1.LL_H_C, { className: 'section-point__values' }, values === null || values === void 0 ? void 0 : values.map(value => {
                        return React.createElement("div", { className: 'data-grid__data', key: value._id }, (0, _entity_1.resolveValueDisplay)(value));
                    })));
            }));
        };
        this.renderRelationsInfo = () => {
            if (this.state.selectedSectionKey !== 'relations')
                return;
            const relations = _entity_1.ModuleFE_VariableRelation.cache.filter(relation => relation._firstVariableId === this.state.variable.item._id);
            return relations.map(relation => {
                const firstVal = _entity_1.ModuleFE_Value.cache.unique(relation.leftSide[0].valueId);
                const secondVar = _entity_1.ModuleFE_Variable.cache.unique(relation.rightSide[0].variableId);
                const secondVal = _entity_1.ModuleFE_Value.cache.unique(relation.rightSide[0].valueId);
                return React.createElement("div", { className: 'data-grid__data full-span' },
                    `${this.state.variable.item.name}: ${(0, _entity_1.resolveValueDisplay)(firstVal)}`,
                    React.createElement("b", { style: { padding: '0 5px' } }, relation.relation),
                    `${secondVar.name}: ${(0, _entity_1.resolveValueDisplay)(secondVal)}`);
            });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        (_a = state.selectedSectionKey) !== null && _a !== void 0 ? _a : (state.selectedSectionKey = 'general');
        const currVariable = _entity_1.ModuleFE_Variable.cache.unique(nextProps.varId);
        if (!state.variable || state.variable.item._id !== currVariable._id) {
            state.variable = new frontend_1.EditableDBItemV3(currVariable, _entity_1.ModuleFE_Variable);
        }
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.Grid, { id: 'manager__variable', className: 'quai-item-manager' },
            this.renderHeader(),
            this.renderSections(),
            this.renderSectionInfo());
    }
}
exports.Manager_Variable = Manager_Variable;
