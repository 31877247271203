"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_MultiVarList = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Panel_MultiVarList.scss");
const frontend_2 = require("@app/hcs/frontend");
const _shared_1 = require("../../../_shared");
const dialogs_1 = require("../../dialogs");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../_entity");
class Panel_MultiVarList extends frontend_1.ProtoComponent {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        this.handleAddVariableClick = () => {
            dialogs_1.Dialog_MultiVar_Editor.show(this.state.diseaseView, this.state.diseaseProfile._id);
        };
        this.toggleSortingMethod = () => {
            this.setState({ sortMethod: this.state.sortMethod === 'abc' ? 'created' : 'abc' });
            this.forceUpdate();
            this.logInfo(this.state.sortMethod);
        };
        this.getDVCountMap = () => {
            const mvIds = this.state.multiVars.map(ts_common_1.dbObjectToId);
            return _entity_1.ModuleFE_DiseaseValue.cache.all().reduce((map, dv) => {
                if (!mvIds.includes(dv.multiVarId))
                    return map;
                if (!map[dv.multiVarId])
                    map[dv.multiVarId] = 1;
                else
                    map[dv.multiVarId]++;
                return map;
            }, {});
        };
        this.getSortedMultiVars = () => {
            switch (this.state.sortMethod) {
                case 'abc':
                    return (0, ts_common_1.sortArray)(this.state.multiVars, mv => this.resolveDisplayText(mv));
                case 'created':
                    return (0, ts_common_1.sortArray)(this.state.multiVars, mv => mv.__created);
                default:
                    throw new ts_common_1.BadImplementationException(`No implementation for sort type ${this.state.sortMethod}`);
            }
        };
        this.renderSortingButton = () => {
            return this.state.sortMethod === 'abc' ? styles_1.ICONS.sortABUp : styles_1.ICONS.sortTimeUp;
        };
        //######################### Render - Multi Var #########################
        this.renderMultiVar = (mv, dvCount) => {
            const className = (0, frontend_1._className)('multivar', this.state.selectedMultiVarId === mv._id && 'selected');
            return React.createElement(frontend_1.LL_H_C, { key: mv._id, className: className, onClick: () => this.onMultiVarSelected(mv) },
                this.renderMultiVarLabel(mv),
                React.createElement(frontend_1.LL_H_C, { className: 'multivar-tail' },
                    this.renderEditButton(mv),
                    this.renderVariant(mv),
                    React.createElement("div", { className: 'disease-value-count' }, dvCount),
                    this.renderVariableToolTip(frontend_2.ModuleFE_Variable.cache.unique(mv.variables[0].variableId), mv)));
        };
        this.renderMultiVarLabel = (mv) => {
            const toShow = this.resolveDisplayText(mv).split('\.')
                .map((text, i, full) => React.createElement(React.Fragment, { key: text },
                React.createElement("span", { className: 'text' }, text),
                i < full.length - 1 && React.createElement("span", { className: 'dot' }, ".")));
            return React.createElement("div", { className: (0, frontend_1._className)('multivar-name') }, toShow);
        };
        this.renderVariableToolTip = (variable, mv) => {
            return React.createElement("div", Object.assign({ className: 'variable-info-tooltip-icon' }, frontend_1.openContent.tooltip.right('variable-info-tooltip', () => React.createElement(frontend_2.Tooltip_VariableInformation, { variable: variable, tagIds: mv.variables[0].tagIds }), { contentHoverDelay: 200 })), "i");
        };
        this.renderEditButton = (mv) => {
            return React.createElement("div", { className: 'edit-button', onClick: (e) => {
                    (0, frontend_1.stopPropagation)(e);
                    dialogs_1.Dialog_MultiVar_Editor.show(this.state.diseaseView, this.state.diseaseProfile._id, mv);
                } },
                React.createElement(styles_1.ICONS.edit.component, null));
        };
        this.renderVariant = (mv) => {
            var _a;
            if (!((_a = this.state.diseaseProfile) === null || _a === void 0 ? void 0 : _a.variantsGroupId))
                return '';
            const variantGroup = mv.variables[0].tagIds.find(i => i.key === this.state.diseaseProfile.variantsGroupId);
            if (!variantGroup)
                return '';
            return React.createElement(frontend_1.LL_H_C, { className: 'variants' }, variantGroup.value.map(id => {
                const label = frontend_2.ModuleFE_Tag.cache.unique(id).label;
                return React.createElement("div", { className: 'variant', key: id }, label);
            }));
        };
    }
    //######################### Life Cycle #########################
    __onVariableUpdated() {
        //Re-render to show updated names of multivars
        this.forceUpdate();
    }
    __onDiseaseProfileUpdated(...params) {
        //TODO: i'm not sure why we care about an updated in DP
        this.reDeriveState();
    }
    __onMultiVarUpdated(...params) {
        //Derive to get updated list of multi vars
        this.reDeriveState();
    }
    __onDiseaseValueUpdated(...params) {
        //Re-render to show updated counts
        this.forceUpdate();
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        const selected = this.getQueryParam('selected', {});
        state.filter = this.getQueryParam('filterMultiVar');
        state.multiVars = [];
        state.attributeMVIds = [];
        (_a = state.sortMethod) !== null && _a !== void 0 ? _a : (state.sortMethod = 'abc');
        state.selectedMultiVarId = selected[_shared_1.DBDef_MultiVar.dbKey];
        state.diseaseProfile = _entity_1.ModuleFE_DiseaseProfile.cache.unique(selected[_shared_1.DBDef_DiseaseProfile.dbKey]);
        state.diseaseView = selected[_shared_1.DBDef_DiseaseView.dbKey] === _entity_1.noConnectedViewDPView._id
            ? _entity_1.noConnectedViewDPView
            : _entity_1.ModuleFE_DiseaseView.cache.unique(selected[_shared_1.DBDef_DiseaseView.dbKey]);
        //If missing a selected DP or DV, return here
        if (!state.diseaseProfile || !state.diseaseView)
            return state;
        this.logInfo('Getting MVs for DV', state.diseaseView);
        //Get Related Multivars
        state.multiVars = _entity_1.ModuleFE_MultiVar.cache.filter(mv => {
            if (mv.diseaseProfileId !== state.diseaseProfile._id)
                return false;
            if (state.diseaseView._id === _entity_1.noConnectedViewDPView._id)
                return _entity_1.ModuleFE_DiseaseView.checkVariableMatchesNoViews(mv);
            return _entity_1.ModuleFE_DiseaseView.filterMultiVarByEnforcedTag(state.diseaseView, mv);
        });
        //Find multiVars where the 2nd variable is an attribute of the first
        state.attributeMVIds = state.multiVars.reduce((result, mv) => {
            var _a;
            const variable = frontend_2.ModuleFE_Variable.cache.unique(mv.variables[0].variableId);
            if ((_a = variable.attributes) === null || _a === void 0 ? void 0 : _a.find(item => { var _a; return item.varId === ((_a = mv.variables[1]) === null || _a === void 0 ? void 0 : _a.variableId); })) {
                result.push(mv._id);
            }
            return result;
        }, []);
        //Filter multivars
        if (state.filter && state.filter.length >= 3) {
            const filter = new ts_common_1.Filter(mv => [this.resolveDisplayText(mv, state)]);
            state.multiVars = filter.filter(state.multiVars, state.filter);
        }
        return state;
    }
    //######################### Logic #########################
    onMultiVarSelected(multiVar) {
        if (multiVar._id === this.state.selectedMultiVarId)
            return;
        const selected = Object.assign({}, this.getQueryParam('selected', {}));
        selected[_shared_1.DBDef_MultiVar.dbKey] = multiVar._id;
        this.setQueryParam('selected', selected);
    }
    resolveDisplayText(mv, state = this.state) {
        const addSecond = state.attributeMVIds.includes(mv._id);
        return `${frontend_2.ModuleFE_Variable.cache.unique(mv.variables[0].variableId).name}${addSecond ? `.${frontend_2.ModuleFE_Variable.cache.unique(mv.variables[1].variableId).name}` : ''}`;
    }
    //######################### Render #########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'panel__multivar-list' },
            this.renderHeader(),
            this.renderList());
    }
    renderHeader() {
        var _a;
        const SortRenderer = this.renderSortingButton();
        return React.createElement(frontend_2.Panel_Header, { config: { filter: this.state.filter }, onFilterChanged: (filter) => this.setQueryParam('filterMultiVar', filter), qsearchId: 'panel-variable-search', headerClassName: 'panel__multivar-list__header', title: { label: ((_a = this.state.diseaseView) === null || _a === void 0 ? void 0 : _a.label) || 'Variables', className: 'panel__variables__header__title' }, headerTail: React.createElement(React.Fragment, null,
                React.createElement(SortRenderer.component, { className: 'tail-icon', onClick: this.toggleSortingMethod }),
                React.createElement("div", { className: 'add-button', onClick: this.handleAddVariableClick }, "+")) });
    }
    renderList() {
        const dvCountMap = this.getDVCountMap();
        const multiVars = this.getSortedMultiVars();
        return React.createElement(frontend_1.LL_V_L, { className: 'multivar-list' }, multiVars.map(mv => { var _a; return this.renderMultiVar(mv, (_a = dvCountMap[mv._id]) !== null && _a !== void 0 ? _a : 0); }));
    }
}
Panel_MultiVarList.defaultProps = {
    keys: ['selected', 'filterMultiVar'],
};
exports.Panel_MultiVarList = Panel_MultiVarList;
