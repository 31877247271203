"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_SearchResult = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
require("./Renderer_SearchResult.scss");
const search_1 = require("../../modules/search");
class Renderer_SearchResult extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.getClassName = () => {
            return (0, frontend_1._className)('search-result', this.state.selected && 'selected', this.props.error && 'error', this.props.className);
        };
        // ######################## Render ########################
        this.renderItemData = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'search-result__data' },
                !!this.props.entityLabel &&
                    React.createElement("div", { className: 'search-result__type' }, this.props.entityLabel),
                !!this.props.entityLabel && !!this.props.hierarchy && 'in',
                !!this.props.hierarchy &&
                    React.createElement("div", { className: 'search-result__hierarchy' }, this.props.hierarchy));
        };
        this.renderItemLabel = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'search-result__item' },
                this.props.itemLabel,
                this.props.labelIcon);
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.selected = (_a = nextProps.selected) !== null && _a !== void 0 ? _a : false;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: this.getClassName(), style: this.props.style, onClick: e => {
                var _a, _b;
                (0, frontend_1.stopPropagation)(e);
                (_b = (_a = this.props).onClickCallback) === null || _b === void 0 ? void 0 : _b.call(_a);
                search_1.dispatch_searchResultSelected.dispatchUI(this.props.itemId, this.props.itemKey);
            } },
            this.renderItemLabel(),
            this.renderItemData());
    }
}
exports.Renderer_SearchResult = Renderer_SearchResult;
