"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Organization = exports.Validator_Contact = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const hcs_1 = require("@app/hcs");
exports.Validator_Contact = {
    firstName: (0, ts_common_1.tsValidateString)(undefined, false),
    lastName: (0, ts_common_1.tsValidateString)(undefined, false),
    email: ts_common_1.tsValidateEmail
};
const Validator_Address = {
    country: (0, ts_common_1.tsValidateString)(),
    city: (0, ts_common_1.tsValidateString)(),
    address: (0, ts_common_1.tsValidateString)(-1, false),
    zipCode: (0, ts_common_1.tsValidateNumber)(false),
    stateRequired: (0, ts_common_1.tsValidateBoolean)(false),
    state: (0, ts_common_1.tsValidateNonMandatoryObject)({
        label: (0, ts_common_1.tsValidateString)(),
        initials: (0, ts_common_1.tsValidateString)(-1, false),
    }),
};
const Validator_ModifiableProps = {
    name: (0, ts_common_1.tsValidateString)(),
    alias: (0, ts_common_1.tsValidateString)(-1, false),
    address: Validator_Address,
    webUrl: (0, ts_common_1.tsValidateString)(undefined, false),
};
const Validator_GeneratedProps = {
// 
};
exports.DBDef_Organization = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1', '1.0.0'],
    dbKey: 'organizations',
    entityName: 'Organization',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'organization'
    },
    backend: {
        name: 'organizations'
    },
};
