"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_DiseaseValue = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const _enum_1 = require("../../_enum");
const hcs_1 = require("@app/hcs");
const Validator_ModifiableProps = {
    multiVarId: ts_common_1.tsValidateUniqueId,
    severityFactor: (0, ts_common_1.tsValidateValue)([..._enum_1.SeverityValues], false),
    indicative: (0, ts_common_1.tsValidateValue)([-1, 1], false),
    likelihood: (0, ts_common_1.tsValidateValue)([..._enum_1.LikelihoodValues], false),
    likelihoodNumeric: ts_common_1.tsValidator_nonMandatoryString,
    likelihoodRange: (0, ts_common_1.tsValidateRange)(false),
    likelihoodDirection: (0, ts_common_1.tsValidateValue)([..._enum_1.LikelihoodDirections], false),
    values: (0, ts_common_1.tsValidateDynamicObject)((0, ts_common_1.tsValidateString)(), ts_common_1.tsValidateUniqueId),
    attributes: (0, ts_common_1.tsValidateDynamicObject)((0, ts_common_1.tsValidateDynamicObject)((0, ts_common_1.tsValidateString)(), ts_common_1.tsValidateUniqueId), ts_common_1.tsValidateUniqueId),
    _diseaseProfileId: ts_common_1.tsValidateUniqueId,
};
const Validator_GeneratedProps = {
    _values: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)()),
    _variables: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)()),
    _attributeIds: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)()),
};
exports.DBDef_DiseaseValue = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.4', '1.0.3', '1.0.2'],
    dbKey: 'disease-values',
    entityName: 'DiseaseValue',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'disease-value'
    },
    backend: {
        name: 'disease-values'
    },
    dependencies: {
        'multiVarId': {
            dbKey: 'multi-vars',
            fieldType: 'string'
        },
        '_values': {
            dbKey: 'values',
            fieldType: 'string[]'
        },
        '_variables': {
            dbKey: 'variables',
            fieldType: 'string[]'
        },
        '_attributeIds': {
            dbKey: 'variables',
            fieldType: 'string[]'
        },
        '_diseaseProfileId': {
            dbKey: 'disease-profiles',
            fieldType: 'string'
        },
    }
};
