"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_ColorGroup = exports.ModuleFE_ColorGroup_Class = exports.dispatch_onColorGroupChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onColorGroupChanged = new types_1.ThunderDispatcherV3('__onColorGroupUpdated');
class ModuleFE_ColorGroup_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_ColorGroup, exports.dispatch_onColorGroupChanged);
    }
}
exports.ModuleFE_ColorGroup_Class = ModuleFE_ColorGroup_Class;
exports.ModuleFE_ColorGroup = new ModuleFE_ColorGroup_Class();
