import * as React from 'react';
import {AppToolsScreen, ModuleFE_Dialog} from '@nu-art/thunderstorm/frontend';
import {TestDialog} from './TestDialog';
import './PgDev_DialogIndexing.scss';

const Render_PgDev_DialogIndexing = () => {

	const openDialog = () => {
		ModuleFE_Dialog.show(<TestDialog/>, () => false);
	};

	return <div className={'pgdev-dialog-indexing'}>
		<div className={'open-dialog-button'} onClick={openDialog}>Click to open dialog</div>
	</div>;
};

export const PgDev_DialogIndexing: AppToolsScreen = {
	name: 'PgDev - Dialog Indexing',
	key: 'dialog-indexing',

	renderer: Render_PgDev_DialogIndexing
};