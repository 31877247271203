"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QRangeInput = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
class QRangeInput extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.toastError = (message) => {
            frontend_1.ModuleFE_Toaster.toastError(message, 3 * ts_common_1.Second);
        };
        this.translateValueToString = (value) => {
            if (!value)
                return '';
            if (value[0] === undefined || value[1] === undefined)
                return '';
            if (value[0] === value[1])
                return `${(value[0] || value[0] === 0) ? value[0] : ''}`;
            return `${value[0]} - ${value[1]}`;
        };
        this.translateStringIntoValue = (str) => {
            if (str.length === 0)
                return undefined;
            const split = str.split('-').map(i => i.trim());
            if (split.length !== 1 && split.length !== 2) {
                this.toastError('Parsing Error');
                return undefined;
            }
            //Potential 1 number
            if (split.length === 1 && split[0].length > 0) {
                const number = Number(split[0]);
                if (isNaN(number)) {
                    this.toastError('Value given is not a number');
                    return undefined;
                }
                return [number, number];
            }
            if (split.some(i => !i)) {
                this.toastError('Value must be single number or number - number');
                return undefined;
            }
            //Potential 2 numbers
            const range = split.map(i => Number(i));
            if (range.some(i => isNaN(i))) {
                this.toastError('Value given is not a range');
                return undefined;
            }
            if (range[0] > range[1]) {
                this.toastError('Range max can\'t be lower than range min');
                return undefined;
            }
            return range;
        };
    }
    deriveStateFromProps(nextProps) {
        const state = this.state ? Object.assign({}, this.state) : {};
        if (!(0, ts_common_1.compare)(nextProps.value, state.value))
            state.value = nextProps.value || [undefined, undefined];
        return state;
    }
    render() {
        const valueAsString = this.translateValueToString(this.state.value);
        return React.createElement(frontend_1.TS_Input, { disabled: this.props.disabled, placeholder: this.props.placeholder, type: 'text', value: valueAsString, onBlur: (str) => {
                var _a, _b;
                const value = this.translateStringIntoValue(str);
                // if (!value)
                // 	return;
                if ((0, ts_common_1.compare)(value, this.state.value))
                    return;
                this.setState({ value: value ? [value[0], value[1]] : [undefined, undefined] });
                (_b = (_a = this.props).onValueBlur) === null || _b === void 0 ? void 0 : _b.call(_a, value);
            } });
    }
}
exports.QRangeInput = QRangeInput;
