/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {LoggedStatus, ModuleFE_Account, OnLoginStatusUpdated} from '@nu-art/user-account/frontend';
import {
	ModuleFE_Dialog,
	ModuleFE_SyncManager,
	ModuleFE_Thunderstorm,
	ModuleFE_XHR,
	StorageKey,
	Thunder,
	ThunderDispatcher,
	ToastBuilder,
	TS_Route
} from '@nu-art/thunderstorm/frontend';
import {arrayToMap, Module, Second} from '@nu-art/ts-common';
import {Component_HealthcareSpace, QToastContent, Toast_DependencyResolutionNotification} from '@app/hcs/frontend';
import {Page_DiseaseProfile} from '@app/dp/frontend';
import {Panel_ResourceManager_V1_old} from '@km/managers/ResourceEditor';
import {DomainNamespace_Organization, PermissionKey_OrganizationGeneral} from '@app/org';
import {
	DomainNamespace_Actions,
	DomainNamespace_Pathway,
	PermissionKey_ActionView,
	PermissionKey_PathwayView
} from '@app/pathway';
import {DomainNamespace_DiseaseProfile, PermissionKey_DiseaseProfile_General} from '@app/dp/shared';
import {DomainNamespace_HealthcareSpace, DomainNamespace_Resource, PermissionKey_ResourceGeneral, PermissionKey_SearchV2Viewer} from '@app/hcs';
import {QuaiIconsType} from '@app/styles';
import {Page_OrganizationEditor} from '@app/org/frontend';
import {Page_ResourceEditor_V2} from '@app/hcs/frontend/ui/Page_ResourceEditor/Page_ResourceEditor_V2';
import {Page_PathwayEditor} from '@app/pathway/frontend';
import {Page_AdvisorContextManager, Page_LogTemplateManager} from '@app/advisor-content/frontend/ui';
import {Manager_Permissions} from '@km/managers/Manager_Permissions/Manager_Permissions';
import {Page_OrderEditor} from '@app/pathway/frontend/ui/Page_OrderEditor/Page_OrderEditor';
import {
	DomainNamespace_LogTemplate,
	PermissionKey_LogTemplateView
} from '@app/advisor-content/_entity/log-template/shared';
import {DomainNamespace_Expression, PermissionKey_ExpressionView} from '@app/hcs/shared/expression/permissions';
import {Page_ExpressionManager} from '@app/hcs/frontend/ui/expressions/ui/expression-manager/Page_ExpressionManager';
import {Component_SearchV2} from '../../knowledge-manager/components/search/Component_SearchV2';

export interface ToggleSideNavBarStateListener {
	__onToggleSideNavBarState: () => void;
}

export const dispatch_onToggleSideNavBarState = new ThunderDispatcher<ToggleSideNavBarStateListener, '__onToggleSideNavBarState'>('__onToggleSideNavBarState');

export type RouteDomainDeclaration = {
	route: TS_Route
	icon: keyof QuaiIconsType; // 'pathway'
	routeTitle: string // 'Pathway Manager'
	domainNamespace?: string // 'Pathway'
	permissionKey?: string // 'pathway-general'
	designator?: string; // 'V2'
}
const RouteDomainsArray: RouteDomainDeclaration[] = [
	{
		route: Component_SearchV2.Route,
		icon: 'search',
		routeTitle: 'Search V2',
		domainNamespace: 'Search V2',
		permissionKey: PermissionKey_SearchV2Viewer,
		designator: 'v2',
	},
	{
		icon: 'healthcareSpace',
		route: Component_HealthcareSpace.Route,
		routeTitle: 'Healthcare Space',
		domainNamespace: DomainNamespace_HealthcareSpace,
	},
	{
		route: Page_DiseaseProfile.Route,
		icon: 'diseaseProfile',
		routeTitle: 'Disease Profile',
		domainNamespace: DomainNamespace_DiseaseProfile,
		permissionKey: PermissionKey_DiseaseProfile_General
	},
	{
		route: Page_PathwayEditor.Route,
		icon: 'pathways',
		routeTitle: 'Pathway Manager',
		domainNamespace: DomainNamespace_Pathway,
		permissionKey: PermissionKey_PathwayView,
	},
	{
		route: Page_LogTemplateManager.Route,
		icon: 'fillForm',
		routeTitle: 'Log Template Manager',
		domainNamespace: DomainNamespace_LogTemplate,
		permissionKey: PermissionKey_LogTemplateView
	},
	{
		route: Page_ExpressionManager.Route,
		icon: 'expressions',
		routeTitle: 'Expression Manager',
		domainNamespace: DomainNamespace_Expression,
		permissionKey: PermissionKey_ExpressionView,
	},
	{
		route: Panel_ResourceManager_V1_old.Route,
		icon: 'resources',
		routeTitle: 'Resource Manager',
		domainNamespace: DomainNamespace_Resource,
		permissionKey: PermissionKey_ResourceGeneral,
		designator: 'RES',
	},
	{
		route: Page_ResourceEditor_V2.Route,
		icon: 'resources',
		routeTitle: 'Resource Manager V2',
		domainNamespace: DomainNamespace_Resource,
		permissionKey: PermissionKey_ResourceGeneral,
		designator: 'v2',
	},
	{
		route: Page_OrganizationEditor.Route,
		icon: 'organization',
		routeTitle: 'Organization Manager V2',
		domainNamespace: DomainNamespace_Organization,
		permissionKey: PermissionKey_OrganizationGeneral,
		designator: 'v2',
	},
	{
		route: Page_OrderEditor.Route,
		icon: 'resources',
		routeTitle: 'Order Manager',
		domainNamespace: DomainNamespace_Actions,
		permissionKey: PermissionKey_ActionView
	},
	{
		route: Manager_Permissions.Route,
		icon: 'userPermissions',
		routeTitle: 'Permissions Manager',
	},
	// {
	// 	route: Panel_PermissionManager.Route,
	// 	icon: 'userPermissions',
	// 	routeTitle: 'Permission Manager',
	// },
	{
		route: Page_AdvisorContextManager.Route,
		routeTitle: 'Advisor Context Manager',
		icon: 'newAdvisorForm'
	},
];
export const RouteDomainsMap = arrayToMap(RouteDomainsArray, item => item.route.key);

export class ModuleFE_AppModule_Class
	extends Module
	implements OnLoginStatusUpdated {

	async __onLoginStatusUpdated() {
		if (ModuleFE_Account.isStatus(LoggedStatus.LOGGED_IN)) {
			return ModuleFE_SyncManager.startListening();
		} else if (ModuleFE_Account.isStatus(LoggedStatus.LOGGED_OUT)) {
			ModuleFE_SyncManager.stopListening();
			ModuleFE_Dialog.close();
			await ModuleFE_Thunderstorm.clearWebsiteData();
		}
	}

	//NavBar Related Things
	public getNavBarStatus(): boolean {
		return this.navBarStatus.get(false);
	}

	private navBarStatus: StorageKey<boolean> = new StorageKey<boolean>('nav-bar__is-open');

	protected init(): void {
		this.navBarStatus.set(false);

		ModuleFE_XHR.setDefaultOnError(async (errorResponse, input, request) => {
			if (errorResponse.errorResponse?.error?.type === 'has-dependencies') {
				new ToastBuilder()
					.setDuration(6 * Second)
					.setContent(QToastContent({
						iconKey: 'x',
						content: Toast_DependencyResolutionNotification({errorData: errorResponse.errorResponse.error.data}),
						toastType: 'error'
					})).show();
			}
		});
	}

	toggleSideNavBar() {
		const lastNavBarStatus = this.navBarStatus.get();
		this.navBarStatus.set(!lastNavBarStatus);
		dispatch_onToggleSideNavBarState.dispatchUI();
	}

	getEnv() {
		return Thunder.getInstance().getConfig().label; // LOCAL/DEV/STAGING/ empty string for prod
	}
}

export const ModuleFE_AppModule = new ModuleFE_AppModule_Class();
