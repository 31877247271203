"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelatedVariables = void 0;
const frontend_1 = require("@app/hcs/_entity/variable/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const getRelatedVariables = (variableId, variableModule = frontend_1.ModuleFE_Variable) => {
    if (!variableId)
        return [];
    const variable = variableModule.cache.unique(variableId);
    if (!variable)
        throw new ts_common_1.MUSTNeverHappenException(`Could not find variable for id ${variableId}`);
    if (variable.role === 'test') {
        const tagIds = variable._tagIds;
        return variableModule.cache.filter(_variable => _variable._id !== variable._id && (0, ts_common_1.arrayIncludesAll)(_variable._tagIds, tagIds));
    }
    return [variable];
};
exports.getRelatedVariables = getRelatedVariables;
