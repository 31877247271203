"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast_DependencyResolutionNotification = void 0;
const React = require("react");
require("./Toast_DependencyConflictNotification.scss");
const dispatchers_1 = require("../../dispatchers");
const Toast_DependencyResolutionNotification = (props) => {
    const renderResolveDependenciesPanel = () => {
        dispatchers_1.dispatch_onDeleteConflicts.dispatchUI(props.errorData);
    };
    return React.createElement("div", { className: 'flex-toast-base' },
        React.createElement("div", null, "Entity has dependencies."),
        React.createElement("div", { onClick: () => renderResolveDependenciesPanel() },
            React.createElement("span", { className: 'click-here-label' }, "Click here"),
            " to resolve"));
};
exports.Toast_DependencyResolutionNotification = Toast_DependencyResolutionNotification;
