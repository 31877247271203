"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_AttributeSet = exports.ModuleFE_AttributeSet_Class = exports.dispatch_onAttributeSetsUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../shared");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
exports.dispatch_onAttributeSetsUpdated = new types_1.ThunderDispatcherV3('__onAttributeSetsUpdated');
class ModuleFE_AttributeSet_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_AttributeSet, exports.dispatch_onAttributeSetsUpdated);
    }
}
exports.ModuleFE_AttributeSet_Class = ModuleFE_AttributeSet_Class;
exports.ModuleFE_AttributeSet = new ModuleFE_AttributeSet_Class();
