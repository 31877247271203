"use strict";
// Generated from src/main/shared/grammar/QuaiExpressionsV3.g4 by ANTLR 4.9.0-SNAPSHOT
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuaiExpressionsV3Lexer = void 0;
const ATNDeserializer_1 = require("antlr4ts/atn/ATNDeserializer");
const Lexer_1 = require("antlr4ts/Lexer");
const LexerATNSimulator_1 = require("antlr4ts/atn/LexerATNSimulator");
const VocabularyImpl_1 = require("antlr4ts/VocabularyImpl");
const Utils = require("antlr4ts/misc/Utils");
class QuaiExpressionsV3Lexer extends Lexer_1.Lexer {
    // @Override
    // @NotNull
    get vocabulary() {
        return QuaiExpressionsV3Lexer.VOCABULARY;
    }
    // tslint:enable:no-trailing-whitespace
    constructor(input) {
        super(input);
        this._interp = new LexerATNSimulator_1.LexerATNSimulator(QuaiExpressionsV3Lexer._ATN, this);
    }
    // @Override
    get grammarFileName() { return "QuaiExpressionsV3.g4"; }
    // @Override
    get ruleNames() { return QuaiExpressionsV3Lexer.ruleNames; }
    // @Override
    get serializedATN() { return QuaiExpressionsV3Lexer._serializedATN; }
    // @Override
    get channelNames() { return QuaiExpressionsV3Lexer.channelNames; }
    // @Override
    get modeNames() { return QuaiExpressionsV3Lexer.modeNames; }
    static get _ATN() {
        if (!QuaiExpressionsV3Lexer.__ATN) {
            QuaiExpressionsV3Lexer.__ATN = new ATNDeserializer_1.ATNDeserializer().deserialize(Utils.toCharArray(QuaiExpressionsV3Lexer._serializedATN));
        }
        return QuaiExpressionsV3Lexer.__ATN;
    }
}
QuaiExpressionsV3Lexer.T__0 = 1;
QuaiExpressionsV3Lexer.T__1 = 2;
QuaiExpressionsV3Lexer.T__2 = 3;
QuaiExpressionsV3Lexer.T__3 = 4;
QuaiExpressionsV3Lexer.T__4 = 5;
QuaiExpressionsV3Lexer.T__5 = 6;
QuaiExpressionsV3Lexer.T__6 = 7;
QuaiExpressionsV3Lexer.T__7 = 8;
QuaiExpressionsV3Lexer.T__8 = 9;
QuaiExpressionsV3Lexer.T__9 = 10;
QuaiExpressionsV3Lexer.T__10 = 11;
QuaiExpressionsV3Lexer.T__11 = 12;
QuaiExpressionsV3Lexer.T__12 = 13;
QuaiExpressionsV3Lexer.T__13 = 14;
QuaiExpressionsV3Lexer.T__14 = 15;
QuaiExpressionsV3Lexer.T__15 = 16;
QuaiExpressionsV3Lexer.T__16 = 17;
QuaiExpressionsV3Lexer.T__17 = 18;
QuaiExpressionsV3Lexer.T__18 = 19;
QuaiExpressionsV3Lexer.T__19 = 20;
QuaiExpressionsV3Lexer.T__20 = 21;
QuaiExpressionsV3Lexer.T__21 = 22;
QuaiExpressionsV3Lexer.T__22 = 23;
QuaiExpressionsV3Lexer.T__23 = 24;
QuaiExpressionsV3Lexer.T__24 = 25;
QuaiExpressionsV3Lexer.T__25 = 26;
QuaiExpressionsV3Lexer.T__26 = 27;
QuaiExpressionsV3Lexer.T__27 = 28;
QuaiExpressionsV3Lexer.T__28 = 29;
QuaiExpressionsV3Lexer.T__29 = 30;
QuaiExpressionsV3Lexer.T__30 = 31;
QuaiExpressionsV3Lexer.T__31 = 32;
QuaiExpressionsV3Lexer.T__32 = 33;
QuaiExpressionsV3Lexer.T__33 = 34;
QuaiExpressionsV3Lexer.T__34 = 35;
QuaiExpressionsV3Lexer.T__35 = 36;
QuaiExpressionsV3Lexer.T__36 = 37;
QuaiExpressionsV3Lexer.MIN = 38;
QuaiExpressionsV3Lexer.MAX = 39;
QuaiExpressionsV3Lexer.NUMBER = 40;
QuaiExpressionsV3Lexer.UNIQUE_ID = 41;
QuaiExpressionsV3Lexer.BOOL = 42;
QuaiExpressionsV3Lexer.STRING = 43;
QuaiExpressionsV3Lexer.PROPERTY = 44;
QuaiExpressionsV3Lexer.WS = 45;
// tslint:disable:no-trailing-whitespace
QuaiExpressionsV3Lexer.channelNames = [
    "DEFAULT_TOKEN_CHANNEL", "HIDDEN",
];
// tslint:disable:no-trailing-whitespace
QuaiExpressionsV3Lexer.modeNames = [
    "DEFAULT_MODE",
];
QuaiExpressionsV3Lexer.ruleNames = [
    "T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", "T__7", "T__8",
    "T__9", "T__10", "T__11", "T__12", "T__13", "T__14", "T__15", "T__16",
    "T__17", "T__18", "T__19", "T__20", "T__21", "T__22", "T__23", "T__24",
    "T__25", "T__26", "T__27", "T__28", "T__29", "T__30", "T__31", "T__32",
    "T__33", "T__34", "T__35", "T__36", "MIN", "MAX", "NUMBER", "UNIQUE_ID",
    "BOOL", "STRING", "PROPERTY", "WS",
];
QuaiExpressionsV3Lexer._LITERAL_NAMES = [
    undefined, "'?('", "','", "')'", "'expr('", "'.includes('", "'.every('",
    "' .atLeast('", "'['", "']'", "'?'", "':'", "'.'", "'var('", "'enum('",
    "'range('", "'attr('", "'precipitating('", "'relieving('", "'yes'", "'no'",
    "'('", "'+'", "'-'", "'*'", "'/'", "'%'", "'^'", "'{'", "'}'", "'==='",
    "'||'", "'&&'", "'<'", "'>'", "'<='", "'>='", "'!'", "'min'", "'max'",
];
QuaiExpressionsV3Lexer._SYMBOLIC_NAMES = [
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, "MIN", "MAX", "NUMBER", "UNIQUE_ID",
    "BOOL", "STRING", "PROPERTY", "WS",
];
QuaiExpressionsV3Lexer.VOCABULARY = new VocabularyImpl_1.VocabularyImpl(QuaiExpressionsV3Lexer._LITERAL_NAMES, QuaiExpressionsV3Lexer._SYMBOLIC_NAMES, []);
QuaiExpressionsV3Lexer._serializedATN = "\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02/\u012F\b\x01" +
    "\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
    "\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
    "\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
    "\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
    "\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
    "\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t" +
    "\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x04(\t(\x04)\t)\x04*\t*\x04" +
    "+\t+\x04,\t,\x04-\t-\x04.\t.\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03" +
    "\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x06\x03" +
    "\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03" +
    "\x06\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
    "\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\t\x03" +
    "\t\x03\n\x03\n\x03\v\x03\v\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03" +
    "\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03" +
    "\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x11\x03\x11\x03" +
    "\x11\x03\x11\x03\x11\x03\x11\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03" +
    "\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03" +
    "\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03" +
    "\x13\x03\x13\x03\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03\x15\x03\x15\x03" +
    "\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03\x18\x03\x18\x03\x19\x03\x19\x03" +
    "\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03\x1C\x03\x1D\x03\x1D\x03\x1E\x03" +
    "\x1E\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03 \x03 \x03 \x03!\x03!\x03!\x03" +
    "\"\x03\"\x03#\x03#\x03$\x03$\x03$\x03%\x03%\x03%\x03&\x03&\x03\'\x03\'" +
    "\x03\'\x03\'\x03(\x03(\x03(\x03(\x03)\x06)\xFD\n)\r)\x0E)\xFE\x03)\x03" +
    ")\x06)\u0103\n)\r)\x0E)\u0104\x05)\u0107\n)\x03*\x06*\u010A\n*\r*\x0E" +
    "*\u010B\x03+\x03+\x03+\x03+\x03+\x03+\x03+\x03+\x03+\x05+\u0117\n+\x03" +
    ",\x03,\x07,\u011B\n,\f,\x0E,\u011E\v,\x03,\x03,\x03-\x03-\x07-\u0124\n" +
    "-\f-\x0E-\u0127\v-\x03.\x06.\u012A\n.\r.\x0E.\u012B\x03.\x03.\x02\x02" +
    "\x02/\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b" +
    "\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02" +
    "\x0F\x1D\x02\x10\x1F\x02\x11!\x02\x12#\x02\x13%\x02\x14\'\x02\x15)\x02" +
    "\x16+\x02\x17-\x02\x18/\x02\x191\x02\x1A3\x02\x1B5\x02\x1C7\x02\x1D9\x02" +
    "\x1E;\x02\x1F=\x02 ?\x02!A\x02\"C\x02#E\x02$G\x02%I\x02&K\x02\'M\x02(" +
    "O\x02)Q\x02*S\x02+U\x02,W\x02-Y\x02.[\x02/\x03\x02\b\x03\x022;\x04\x02" +
    "2;ch\x03\x02$$\x05\x02C\\aac|\x06\x022;C\\aac|\x05\x02\v\f\x0F\x0F\"\"" +
    "\x02\u0136\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03" +
    "\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02" +
    "\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02" +
    "\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02" +
    "\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02" +
    "\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02" +
    "\x02\'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02-" +
    "\x03\x02\x02\x02\x02/\x03\x02\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02" +
    "\x02\x02\x025\x03\x02\x02\x02\x027\x03\x02\x02\x02\x029\x03\x02\x02\x02" +
    "\x02;\x03\x02\x02\x02\x02=\x03\x02\x02\x02\x02?\x03\x02\x02\x02\x02A\x03" +
    "\x02\x02\x02\x02C\x03\x02\x02\x02\x02E\x03\x02\x02\x02\x02G\x03\x02\x02" +
    "\x02\x02I\x03\x02\x02\x02\x02K\x03\x02\x02\x02\x02M\x03\x02\x02\x02\x02" +
    "O\x03\x02\x02\x02\x02Q\x03\x02\x02\x02\x02S\x03\x02\x02\x02\x02U\x03\x02" +
    "\x02\x02\x02W\x03\x02\x02\x02\x02Y\x03\x02\x02\x02\x02[\x03\x02\x02\x02" +
    "\x03]\x03\x02\x02\x02\x05`\x03\x02\x02\x02\x07b\x03\x02\x02\x02\td\x03" +
    "\x02\x02\x02\vj\x03\x02\x02\x02\ru\x03\x02\x02\x02\x0F}\x03\x02\x02\x02" +
    "\x11\x88\x03\x02\x02\x02\x13\x8A\x03\x02\x02\x02\x15\x8C\x03\x02\x02\x02" +
    "\x17\x8E\x03\x02\x02\x02\x19\x90\x03\x02\x02\x02\x1B\x92\x03\x02\x02\x02" +
    "\x1D\x97\x03\x02\x02\x02\x1F\x9D\x03\x02\x02\x02!\xA4\x03\x02\x02\x02" +
    "#\xAA\x03\x02\x02\x02%\xB9\x03\x02\x02\x02\'\xC4\x03\x02\x02\x02)\xC8" +
    "\x03\x02\x02\x02+\xCB\x03\x02\x02\x02-\xCD\x03\x02\x02\x02/\xCF\x03\x02" +
    "\x02\x021\xD1\x03\x02\x02\x023\xD3\x03\x02\x02\x025\xD5\x03\x02\x02\x02" +
    "7\xD7\x03\x02\x02\x029\xD9\x03\x02\x02\x02;\xDB\x03\x02\x02\x02=\xDD\x03" +
    "\x02\x02\x02?\xE1\x03\x02\x02\x02A\xE4\x03\x02\x02\x02C\xE7\x03\x02\x02" +
    "\x02E\xE9\x03\x02\x02\x02G\xEB\x03\x02\x02\x02I\xEE\x03\x02\x02\x02K\xF1" +
    "\x03\x02\x02\x02M\xF3\x03\x02\x02\x02O\xF7\x03\x02\x02\x02Q\xFC\x03\x02" +
    "\x02\x02S\u0109\x03\x02\x02\x02U\u0116\x03\x02\x02\x02W\u0118\x03\x02" +
    "\x02\x02Y\u0121\x03\x02\x02\x02[\u0129\x03\x02\x02\x02]^\x07A\x02\x02" +
    "^_\x07*\x02\x02_\x04\x03\x02\x02\x02`a\x07.\x02\x02a\x06\x03\x02\x02\x02" +
    "bc\x07+\x02\x02c\b\x03\x02\x02\x02de\x07g\x02\x02ef\x07z\x02\x02fg\x07" +
    "r\x02\x02gh\x07t\x02\x02hi\x07*\x02\x02i\n\x03\x02\x02\x02jk\x070\x02" +
    "\x02kl\x07k\x02\x02lm\x07p\x02\x02mn\x07e\x02\x02no\x07n\x02\x02op\x07" +
    "w\x02\x02pq\x07f\x02\x02qr\x07g\x02\x02rs\x07u\x02\x02st\x07*\x02\x02" +
    "t\f\x03\x02\x02\x02uv\x070\x02\x02vw\x07g\x02\x02wx\x07x\x02\x02xy\x07" +
    "g\x02\x02yz\x07t\x02\x02z{\x07{\x02\x02{|\x07*\x02\x02|\x0E\x03\x02\x02" +
    "\x02}~\x07\"\x02\x02~\x7F\x070\x02\x02\x7F\x80\x07c\x02\x02\x80\x81\x07" +
    "v\x02\x02\x81\x82\x07N\x02\x02\x82\x83\x07g\x02\x02\x83\x84\x07c\x02\x02" +
    "\x84\x85\x07u\x02\x02\x85\x86\x07v\x02\x02\x86\x87\x07*\x02\x02\x87\x10" +
    "\x03\x02\x02\x02\x88\x89\x07]\x02\x02\x89\x12\x03\x02\x02\x02\x8A\x8B" +
    "\x07_\x02\x02\x8B\x14\x03\x02\x02\x02\x8C\x8D\x07A\x02\x02\x8D\x16\x03" +
    "\x02\x02\x02\x8E\x8F\x07<\x02\x02\x8F\x18\x03\x02\x02\x02\x90\x91\x07" +
    "0\x02\x02\x91\x1A\x03\x02\x02\x02\x92\x93\x07x\x02\x02\x93\x94\x07c\x02" +
    "\x02\x94\x95\x07t\x02\x02\x95\x96\x07*\x02\x02\x96\x1C\x03\x02\x02\x02" +
    "\x97\x98\x07g\x02\x02\x98\x99\x07p\x02\x02\x99\x9A\x07w\x02\x02\x9A\x9B" +
    "\x07o\x02\x02\x9B\x9C\x07*\x02\x02\x9C\x1E\x03\x02\x02\x02\x9D\x9E\x07" +
    "t\x02\x02\x9E\x9F\x07c\x02\x02\x9F\xA0\x07p\x02\x02\xA0\xA1\x07i\x02\x02" +
    "\xA1\xA2\x07g\x02\x02\xA2\xA3\x07*\x02\x02\xA3 \x03\x02\x02\x02\xA4\xA5" +
    "\x07c\x02\x02\xA5\xA6\x07v\x02\x02\xA6\xA7\x07v\x02\x02\xA7\xA8\x07t\x02" +
    "\x02\xA8\xA9\x07*\x02\x02\xA9\"\x03\x02\x02\x02\xAA\xAB\x07r\x02\x02\xAB" +
    "\xAC\x07t\x02\x02\xAC\xAD\x07g\x02\x02\xAD\xAE\x07e\x02\x02\xAE\xAF\x07" +
    "k\x02\x02\xAF\xB0\x07r\x02\x02\xB0\xB1\x07k\x02\x02\xB1\xB2\x07v\x02\x02" +
    "\xB2\xB3\x07c\x02\x02\xB3\xB4\x07v\x02\x02\xB4\xB5\x07k\x02\x02\xB5\xB6" +
    "\x07p\x02\x02\xB6\xB7\x07i\x02\x02\xB7\xB8\x07*\x02\x02\xB8$\x03\x02\x02" +
    "\x02\xB9\xBA\x07t\x02\x02\xBA\xBB\x07g\x02\x02\xBB\xBC\x07n\x02\x02\xBC" +
    "\xBD\x07k\x02\x02\xBD\xBE\x07g\x02\x02\xBE\xBF\x07x\x02\x02\xBF\xC0\x07" +
    "k\x02\x02\xC0\xC1\x07p\x02\x02\xC1\xC2\x07i\x02\x02\xC2\xC3\x07*\x02\x02" +
    "\xC3&\x03\x02\x02\x02\xC4\xC5\x07{\x02\x02\xC5\xC6\x07g\x02\x02\xC6\xC7" +
    "\x07u\x02\x02\xC7(\x03\x02\x02\x02\xC8\xC9\x07p\x02\x02\xC9\xCA\x07q\x02" +
    "\x02\xCA*\x03\x02\x02\x02\xCB\xCC\x07*\x02\x02\xCC,\x03\x02\x02\x02\xCD" +
    "\xCE\x07-\x02\x02\xCE.\x03\x02\x02\x02\xCF\xD0\x07/\x02\x02\xD00\x03\x02" +
    "\x02\x02\xD1\xD2\x07,\x02\x02\xD22\x03\x02\x02\x02\xD3\xD4\x071\x02\x02" +
    "\xD44\x03\x02\x02\x02\xD5\xD6\x07\'\x02\x02\xD66\x03\x02\x02\x02\xD7\xD8" +
    "\x07`\x02\x02\xD88\x03\x02\x02\x02\xD9\xDA\x07}\x02\x02\xDA:\x03\x02\x02" +
    "\x02\xDB\xDC\x07\x7F\x02\x02\xDC<\x03\x02\x02\x02\xDD\xDE\x07?\x02\x02" +
    "\xDE\xDF\x07?\x02\x02\xDF\xE0\x07?\x02\x02\xE0>\x03\x02\x02\x02\xE1\xE2" +
    "\x07~\x02\x02\xE2\xE3\x07~\x02\x02\xE3@\x03\x02\x02\x02\xE4\xE5\x07(\x02" +
    "\x02\xE5\xE6\x07(\x02\x02\xE6B\x03\x02\x02\x02\xE7\xE8\x07>\x02\x02\xE8" +
    "D\x03\x02\x02\x02\xE9\xEA\x07@\x02\x02\xEAF\x03\x02\x02\x02\xEB\xEC\x07" +
    ">\x02\x02\xEC\xED\x07?\x02\x02\xEDH\x03\x02\x02\x02\xEE\xEF\x07@\x02\x02" +
    "\xEF\xF0\x07?\x02\x02\xF0J\x03\x02\x02\x02\xF1\xF2\x07#\x02\x02\xF2L\x03" +
    "\x02\x02\x02\xF3\xF4\x07o\x02\x02\xF4\xF5\x07k\x02\x02\xF5\xF6\x07p\x02" +
    "\x02\xF6N\x03\x02\x02\x02\xF7\xF8\x07o\x02\x02\xF8\xF9\x07c\x02\x02\xF9" +
    "\xFA\x07z\x02\x02\xFAP\x03\x02\x02\x02\xFB\xFD\t\x02\x02\x02\xFC\xFB\x03" +
    "\x02\x02\x02\xFD\xFE\x03\x02\x02\x02\xFE\xFC\x03\x02\x02\x02\xFE\xFF\x03" +
    "\x02\x02\x02\xFF\u0106\x03\x02\x02\x02\u0100\u0102\x070\x02\x02\u0101" +
    "\u0103\t\x02\x02\x02\u0102\u0101\x03\x02\x02\x02\u0103\u0104\x03\x02\x02" +
    "\x02\u0104\u0102\x03\x02\x02\x02\u0104\u0105\x03\x02\x02\x02\u0105\u0107" +
    "\x03\x02\x02\x02\u0106\u0100\x03\x02\x02\x02\u0106\u0107\x03\x02\x02\x02" +
    "\u0107R\x03\x02\x02\x02\u0108\u010A\t\x03\x02\x02\u0109\u0108\x03\x02" +
    "\x02\x02\u010A\u010B\x03\x02\x02\x02\u010B\u0109\x03\x02\x02\x02\u010B" +
    "\u010C\x03\x02\x02\x02\u010CT\x03\x02\x02\x02\u010D\u010E\x07v\x02\x02" +
    "\u010E\u010F\x07t\x02\x02\u010F\u0110\x07w\x02\x02\u0110\u0117\x07g\x02" +
    "\x02\u0111\u0112\x07h\x02\x02\u0112\u0113\x07c\x02\x02\u0113\u0114\x07" +
    "n\x02\x02\u0114\u0115\x07u\x02\x02\u0115\u0117\x07g\x02\x02\u0116\u010D" +
    "\x03\x02\x02\x02\u0116\u0111\x03\x02\x02\x02\u0117V\x03\x02\x02\x02\u0118" +
    "\u011C\x07$\x02\x02\u0119\u011B\n\x04\x02\x02\u011A\u0119\x03\x02\x02" +
    "\x02\u011B\u011E\x03\x02\x02\x02\u011C\u011A\x03\x02\x02\x02\u011C\u011D" +
    "\x03\x02\x02\x02\u011D\u011F\x03\x02\x02\x02\u011E\u011C\x03\x02\x02\x02" +
    "\u011F\u0120\x07$\x02\x02\u0120X\x03\x02\x02\x02\u0121\u0125\t\x05\x02" +
    "\x02\u0122\u0124\t\x06\x02\x02\u0123\u0122\x03\x02\x02\x02\u0124\u0127" +
    "\x03\x02\x02\x02\u0125\u0123\x03\x02\x02\x02\u0125\u0126\x03\x02\x02\x02" +
    "\u0126Z\x03\x02\x02\x02\u0127\u0125\x03\x02\x02\x02\u0128\u012A\t\x07" +
    "\x02\x02\u0129\u0128\x03\x02\x02\x02\u012A\u012B\x03\x02\x02\x02\u012B" +
    "\u0129\x03\x02\x02\x02\u012B\u012C\x03\x02\x02\x02\u012C\u012D\x03\x02" +
    "\x02\x02\u012D\u012E\b.\x02\x02\u012E\\\x03\x02\x02\x02\v\x02\xFE\u0104" +
    "\u0106\u010B\u0116\u011C\u0125\u012B\x03\b\x02\x02";
exports.QuaiExpressionsV3Lexer = QuaiExpressionsV3Lexer;
