"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.quaiOptions = exports.QuaiExpressions_ResolveVariablesOptions = exports.QuaiExpressions_ResolveVariableOperators = exports.QuaiExpressions_ResolveVariablesValidation = exports.QuaiExpressions_ResolveVariablesPrinter = exports.QuaiExpressions_ResolveVariablesProcessors = exports.Operator_GetPatientVar = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const expressions_core_1 = require("../expressions-core");
exports.Operator_GetPatientVar = 'PV({id})';
const mockResolvePatientDataMapper = {
    'f0c83c5387e2d526d04adb578093b46f': 'yes',
    'c75c8e4d316eb0ce95421c42a2309adb': 6
};
const mockResolvePatientInterface = (varId) => {
    return mockResolvePatientDataMapper[varId];
};
exports.QuaiExpressions_ResolveVariablesProcessors = {
    [exports.Operator_GetPatientVar]: (_expressionModule, ...params) => {
        const value = mockResolvePatientInterface(_expressionModule.params.eval(params[0]));
        if (!value)
            throw new ts_common_1.MUSTNeverHappenException('patient variable with this id doesn\'t exist');
        return value;
    }
};
exports.QuaiExpressions_ResolveVariablesPrinter = {
    [exports.Operator_GetPatientVar]: (_expressionModule, indent, ...params) => `(PV{${params[0]}})`
};
exports.QuaiExpressions_ResolveVariablesValidation = {
    [exports.Operator_GetPatientVar]: (_expressionModule, ...params) => {
        (0, expressions_core_1.validateType)(params[0], 'left', 'string');
    }
};
exports.QuaiExpressions_ResolveVariableOperators = [exports.Operator_GetPatientVar];
exports.QuaiExpressions_ResolveVariablesOptions = {
    Operators: exports.QuaiExpressions_ResolveVariableOperators,
    OperatorPrinter: exports.QuaiExpressions_ResolveVariablesPrinter,
    OperatorProcessors: exports.QuaiExpressions_ResolveVariablesProcessors,
    OperatorValidation: exports.QuaiExpressions_ResolveVariablesValidation
};
exports.quaiOptions = [
    exports.QuaiExpressions_ResolveVariablesOptions
];
