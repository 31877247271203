"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Reference = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const consts_1 = require("./consts");
const shared_1 = require("../../shared");
const Validator_ReferenceData = {
    snomed: {
        id: (0, ts_common_1.tsValidateString)(),
        names: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateString)(), false),
        icd10: ts_common_1.tsValidator_nonMandatoryString
    },
    loinc: { id: (0, ts_common_1.tsValidateString)(), name: ts_common_1.tsValidator_nonMandatoryString },
    book: {
        bookId: (0, ts_common_1.tsValidateString)(),
        path: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateNumber)()),
        page: (0, ts_common_1.tsValidateNumber)(),
        paragraph: (0, ts_common_1.tsValidateString)()
    },
    article: {
        url: (0, ts_common_1.tsValidateString)(),
        name: (0, ts_common_1.tsValidateString)(),
        journal: (0, ts_common_1.tsValidateString)(),
        publicationYear: (0, ts_common_1.tsValidateNumber)()
    },
    upToDate: { url: (0, ts_common_1.tsValidateString)(), name: (0, ts_common_1.tsValidateString)(), lastUpdate: (0, ts_common_1.tsValidateNumber)() },
    icdCode: { id: (0, ts_common_1.tsValidateString)() }
};
const Validator_ModifiableProps = {
    type: (0, ts_common_1.tsValidateValue)(consts_1.ListOf_ReferenceTypes),
    data: (0, ts_common_1.tsValidator_valueByKey)(Validator_ReferenceData),
    creationStatus: (0, ts_common_1.tsValidateValue)([...types_1.creationStatuses], false)
};
const Validator_GeneratedProps = {
    uid: (0, ts_common_1.tsValidateMD5)(),
    __bookId: ts_common_1.tsValidateOptionalId,
};
exports.DBDef_Reference = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.2', '1.0.1'],
    dbKey: 'references',
    entityName: 'Reference',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'reference'
    },
    backend: {
        name: 'references'
    },
    dependencies: {
        '__bookId': {
            dbKey: 'books',
            fieldType: 'string'
        }
    }
};
