"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconWithTooltip = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const icons_1 = require("../../../icons");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const IconWithTooltip = (props) => {
    var _a, _b;
    const Icon = icons_1.ICONS[props.iconKey];
    if (!Icon)
        throw new ts_common_1.BadImplementationException(`no icon for key ${props.iconKey}`);
    const direction = (_a = props.tooltipDir) !== null && _a !== void 0 ? _a : 'top';
    const offset = (_b = props.offset) !== null && _b !== void 0 ? _b : 6;
    return React.createElement(Icon.component, Object.assign({ className: props.className, onClick: props.onClick }, frontend_1.openContent.tooltip[direction]('icon-with-tooltip', (0, ts_common_1.resolveContent)(props.content), { offset })));
};
exports.IconWithTooltip = IconWithTooltip;
