"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_AdvisorContext = exports.DropDown_AdvisorContext = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_AdvisorContext_1 = require("./ModuleFE_AdvisorContext");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_AdvisorContext_1.ModuleFE_AdvisorContext,
    modules: [ModuleFE_AdvisorContext_1.ModuleFE_AdvisorContext],
    mapper: item => [item.label],
    placeholder: 'Choose a advisor context',
    renderer: item => React.createElement("div", { className: "ll_h_c" },
        " ",
        item.label,
        " ")
};
exports.DropDown_AdvisorContext = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_AdvisorContext_1.ModuleFE_AdvisorContext,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.label);
    },
    uiSelector: exports.DropDown_AdvisorContext.selectable,
});
exports.MultiSelect_AdvisorContext = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
