"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ExpressionDescription = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_ExpressionDescription.scss");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const permissions_1 = require("../../../../../../../permissions");
class Component_ExpressionDescription extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.getAccessLevel = () => {
            return ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(permissions_1.PermissionKeyFE_ExpressionEdit);
        };
        this.renderTextLimit = () => {
            const overTheLimit = this.getDescription().length > 10000;
            const className = (0, frontend_1._className)('description-length', overTheLimit && 'over-limit');
            return React.createElement(frontend_1.LL_H_C, { className: 'length-info-container' },
                React.createElement("div", { className: className },
                    this.getDescription().length,
                    "/10000"),
                overTheLimit && React.createElement("div", { className: className }, "Description too long!"));
        };
    }
    render() {
        const className = (0, frontend_1._className)(this.getDescription().length > 10000 && 'over-limit');
        return React.createElement(frontend_1.LL_V_L, { className: 'expression-description' },
            React.createElement(frontend_1.TS_TextArea, { className: className, width: '100%', disabled: this.getAccessLevel() !== ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess, type: 'text', value: this.getDescription() || '', onBlur: async (description) => await this.props.editable.updateObj({ description: description }), onChange: async (description) => await this.props.editable.updateObj({ description: description }) }),
            this.renderTextLimit());
    }
    getDescription() {
        return this.props.editable.item.description || '';
    }
}
exports.Component_ExpressionDescription = Component_ExpressionDescription;
