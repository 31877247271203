"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_PathwayDetails = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
const Renderer_CriticalityTable_1 = require("../../Renderer_CriticalityTable/Renderer_CriticalityTable");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const Header_Pathway_1 = require("../../../../headers/Header_Pathway");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_3 = require("@app/dp/frontend");
const Section_PathwayStates_1 = require("./panel-sections/Section_PathwayStates");
const Section_AllPathwayTransitions_1 = require("./panel-sections/Section_AllPathwayTransitions");
const Section_AllPathwayOrders_1 = require("./panel-sections/Section_AllPathwayOrders");
const Section_AllEntityVariables_1 = require("../../common-components/Section_AllEntityVariables");
class Renderer_PathwayDetails extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        // ######################## Logic ########################
        super(...arguments);
        this.getDDs = () => {
            const pathway = this.props.editable.item;
            if (pathway.type !== 'chief-complaint' || !pathway.variableId)
                return [];
            const complaintsTagId = this.getComplaintsTagId();
            if (!complaintsTagId)
                return [];
            const dds = frontend_3.ModuleFE_DiseaseProfile.cache.filter(dp => {
                return !!frontend_3.ModuleFE_MultiVar.cache.find(mv => {
                    return mv.diseaseProfileId === dp._id
                        && mv._firstVarId === pathway.variableId
                        && !!mv.variables[0].tagIds.find(tagGroup => tagGroup.value.includes(complaintsTagId));
                });
            });
            return (0, ts_common_1.sortArray)(dds, dd => dd.label.toLowerCase());
        };
        this.getComplaintsTagId = () => {
            const dvTagId = frontend_2.ConfigKeyFE_DiseasesViewsTagId.get();
            const complaintsTag = frontend_2.ModuleFE_Tag.cache.find(tag => tag.tagIds.includes(dvTagId) && tag.label.toLowerCase() === 'complaints');
            if (!complaintsTag)
                this.logError('Could not find complaints tag');
            return complaintsTag === null || complaintsTag === void 0 ? void 0 : complaintsTag._id;
        };
        this.setCriticalityTableView = (view, e) => {
            (0, frontend_1.stopPropagation)(e);
            this.setState({ criticalityTableView: view });
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                this.renderTopButtons(),
                React.createElement(Header_Pathway_1.Header_Pathway, { pathway: this.props.editable, functions: this.props.functions, allowActions: true }));
        };
        this.renderTopButtons = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'pathway__top-buttons' },
                !this.state.criticalityTableView &&
                    React.createElement(frontend_1.TS_Button, { className: 'pathway__top-buttons__back', onClick: this.props.functions.deselectPathway }, '< Back to list'),
                this.state.criticalityTableView &&
                    React.createElement(frontend_1.TS_Button, { className: 'pathway__top-buttons__back', onClick: e => this.setCriticalityTableView(false, e) }, '< Back to Pathway Overview'));
        };
        // ######################## Render - Body ########################
        this.renderBody = () => {
            if (this.state.criticalityTableView)
                return React.createElement(Renderer_CriticalityTable_1.Renderer_CriticalityTable, { pathway: this.props.editable.item });
            return React.createElement(frontend_2.QScrollWrapper, { className: 'match_width' },
                this.renderDescription(),
                this.renderStates(),
                this.renderTransitions(),
                this.renderDDs(),
                this.renderOrders(),
                this.renderHCSVariables());
        };
        this.renderDescription = () => {
            const pathway = this.props.editable.item;
            if (!pathway.description)
                return;
            return React.createElement("p", { className: 'pathway__description' }, pathway.description);
        };
        this.renderStates = () => {
            return React.createElement(Section_PathwayStates_1.Section_PathwayStates, { pathwayId: this.state.editable.get('_id'), functions: this.props.functions });
        };
        this.renderTransitions = () => {
            return React.createElement(Section_AllPathwayTransitions_1.Section_AllPathwayTransitions, { functions: this.props.functions, pathwayId: this.state.editable.get('_id') });
        };
        this.renderDDs = () => {
            const dds = this.getDDs();
            if (!dds.length)
                return;
            return React.createElement(frontend_1.TS_CollapsableContainer, { headerRenderer: React.createElement(React.Fragment, null,
                    dds.length,
                    " DDs",
                    React.createElement("div", { className: 'criticality-table-button', onClick: e => this.setCriticalityTableView(true, e) }, "See Criticality Table")), containerRenderer: React.createElement(React.Fragment, null, dds.map(dd => React.createElement("div", { key: dd._id }, dd.label))), customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null) });
        };
        this.renderHCSVariables = () => {
            return React.createElement(Section_AllEntityVariables_1.Section_AllEntityVariables, { functions: this.props.functions, pathwayId: this.state.editable.get('_id') });
        };
        this.renderOrders = () => {
            return React.createElement(Section_AllPathwayOrders_1.Section_AllPathwayOrders, { functions: this.props.functions, pathwayId: this.state.editable.get('_id') });
        };
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent', style: { padding: '30px', gap: '10px' } },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Renderer_PathwayDetails = Renderer_PathwayDetails;
