"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionKey_OrganizationV2Viewer = exports.PermissionKey_OrganizationV1Viewer = exports.PermissionKey_OrganizationDelete = exports.PermissionKey_OrganizationView = exports.PermissionKey_OrganizationEdit = exports.PermissionKey_OrganizationGeneral = exports.DomainNamespace_OrganizationV2 = exports.DomainNamespace_OrganizationV1 = exports.DomainNamespace_Organization = void 0;
exports.DomainNamespace_Organization = 'Organization';
exports.DomainNamespace_OrganizationV1 = 'OrganizationV1';
exports.DomainNamespace_OrganizationV2 = 'OrganizationV2';
exports.PermissionKey_OrganizationGeneral = 'organization-general';
exports.PermissionKey_OrganizationEdit = 'permission-key--organization-edit';
exports.PermissionKey_OrganizationView = 'permission-key--organization-view';
exports.PermissionKey_OrganizationDelete = 'permission-key--organization-delete';
exports.PermissionKey_OrganizationV1Viewer = 'permission-key--organization-v1-viewer';
exports.PermissionKey_OrganizationV2Viewer = 'permission-key--organization-v2-viewer';
