"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_PathwayTransitionEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const dispatchers_1 = require("../../../dispatchers");
const styles_1 = require("@app/styles");
const consts_1 = require("../../../consts");
const Header_Pathway_1 = require("../../../headers/Header_Pathway");
const Header_PathwayTransition_1 = require("../../../headers/Header_PathwayTransition");
const frontend_2 = require("@app/styles/frontend");
const _entity_1 = require("../../../../../_entity");
const utils_1 = require("../../../../../../shared/utils");
const utils_2 = require("../../../utils");
const app_dropdowns_1 = require("../../../../shared-components/app-dropdowns");
const shared_1 = require("../../../../../../_enum/metapathway-action/shared");
const _enum_1 = require("../../../../../_enum");
const ui_components_1 = require("../../../../../../_entity/pathway/frontend/ui-components");
class Renderer_PathwayTransitionEditor extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        this.getTargetStates = () => {
            var _a;
            const pathwayId = this.state.pathwayTransition.item.pathwayId;
            const originId = (_a = this.state.pathwayTransition.item.sourceIds) === null || _a === void 0 ? void 0 : _a[0];
            return _entity_1.ModuleFE_PathwayState.cache.filter(ps => ps.pathwayId === pathwayId && ps._id !== originId);
        };
        this.canSave = () => {
            var _a;
            const pathwayTransition = this.state.pathwayTransition.item;
            return !!((_a = pathwayTransition.targetIds) === null || _a === void 0 ? void 0 : _a[0]);
        };
        this.createDefaultLabel = (transition) => {
            const targetStateId = transition.get('targetIds')[0];
            const targetState = _entity_1.ModuleFE_PathwayState.cache.unique(targetStateId);
            if (!targetState)
                throw new ts_common_1.MUSTNeverHappenException(`Pathway state with id ${targetStateId} not found`);
            const sameTargetTransition = this.getSameSourceAndTargetStateLabels(transition);
            this.state.pathwayTransition.set('label', (0, utils_1.createTransitionLabel)(sameTargetTransition, targetState));
        };
        this.getSameSourceAndTargetStateLabels = (transition) => {
            const targetStateId = transition.get('targetIds')[0];
            const sourceStateId = transition.get('sourceIds')[0];
            return (0, ts_common_1.filterInstances)(_entity_1.ModuleFE_PathwayTransition.cache.filter(_transition => {
                if (_transition._id === transition.get('_id'))
                    return false;
                return _transition.targetIds[0] === targetStateId && _transition.sourceIds[0] === sourceStateId;
            }).map(_transition => _transition.label));
        };
        this.onSave = async (createNew = false) => {
            const transition = this.state.pathwayTransition;
            if (!this.canSave())
                return;
            const label = transition.get('label');
            // Get other labels to check if a label index reset is needed
            const sameSourceAndTargetStateLabels = this.getSameSourceAndTargetStateLabels(transition);
            // Reset or create label if needed
            if ((!label || !label.length) || !transition.get('_id') || sameSourceAndTargetStateLabels.includes(label.trim())) {
                this.createDefaultLabel(transition);
            }
            // Add new transition last in priority
            if (!(0, ts_common_1.exists)(transition.get('priorityIndex'))) {
                const stateTransitions = _entity_1.ModuleFE_PathwayTransition.cache.filter(_transition => { var _a; return _transition.sourceIds[0] === ((_a = transition.get('sourceIds')) === null || _a === void 0 ? void 0 : _a[0]); });
                transition.set('priorityIndex', stateTransitions.length);
            }
            try {
                const _transition = await transition.save();
                // update resolution function label
                if (_transition.resolutionFunctionId)
                    await (0, utils_2.updateResolutionFunctionTitle)(_transition._id, [_transition.sourceIds[0], _transition.targetIds[0]]);
                //Post save logic
                if (!createNew)
                    return this.props.functions.selectPathwayTransition(transition);
                //No problem to assume properties on transition exist because we wouldn't get to this line if they didn't
                const newTransition = (0, consts_1.Empty_PathwayTransition)(transition.item.pathwayId, transition.item.sourceIds[0]);
                this.props.functions.selectPathwayTransition(new frontend_1.EditableDBItemV3(newTransition, _entity_1.ModuleFE_PathwayTransition));
            }
            catch (e) {
                this.logError(e);
            }
        };
        this.onCancel = () => {
            const transitionId = this.state.pathwayTransition.get('_id');
            if (!transitionId)
                return this.props.functions.deselectPathwayTransition();
            this.props.toggleEdit();
            // Re-select the transition to revert changes, can assume existence because of the condition above
            this.props.functions.selectPathwayTransition(transitionId);
        };
        this.renderButtons = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'pathway__bottom-buttons' },
                React.createElement(frontend_1.TS_Button, { onClick: this.onCancel }, "Cancel"),
                React.createElement(frontend_1.TS_BusyButton, { onClick: () => this.onSave(true), disabled: !this.canSave() }, "Add Another Transition"),
                React.createElement(frontend_1.TS_BusyButton, { onClick: () => this.onSave(), className: 'preferred-action', disabled: !this.canSave() }, "Save"));
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const pathwayTransition = this.state.pathwayTransition.item;
            const pathwayId = pathwayTransition.pathwayId;
            const pathway = _entity_1.ModuleFE_Pathway.cache.unique(pathwayId);
            if (!pathway)
                throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway for id ${pathwayId}`);
            return React.createElement(React.Fragment, null,
                React.createElement(Header_Pathway_1.Header_Pathway, { pathway: new frontend_1.EditableDBItemV3(pathway, _entity_1.ModuleFE_Pathway), allowActions: false, functions: this.props.functions }),
                React.createElement(Header_PathwayTransition_1.Header_PathwayTransition, { pathwayTransition: this.state.pathwayTransition, functions: this.props.functions }));
        };
        // ######################## Render - Form ########################
        this.renderForm = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'pathway__form' },
                this.renderTargetDropdown(),
                this.renderLabelInput(),
                this.renderAlertInput(),
                this.renderTests(),
                this.renderTreatments(),
                this.renderConsult(),
                this.renderNurse(),
                this.renderMetapathwayActions());
        };
        this.renderOrdersMultiSelect = (prop, subType) => {
            return React.createElement(frontend_1.TS_MultiSelect_V2, { editable: this.state.pathwayTransition, prop: prop, itemsDirection: 'vertical', selectionRenderer: selector => {
                    return React.createElement(app_dropdowns_1.PathwayDropdowns.Action, { placeholder: 'Add an action', caret: frontend_2.QuaiManager_DropDownCaret, queryFilter: action => !selector.existingItems.includes(action._id)
                            && _entity_1.ModuleFE_Action.is.order(action)
                            && action.subType === subType, onSelected: action => {
                            selector.onSelected(action._id);
                            this.forceUpdate();
                        } });
                }, itemRenderer: (id, onDelete) => {
                    const order = _entity_1.ModuleFE_Action.cache.unique(id);
                    if (!order)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not get order for id ${id}`);
                    return React.createElement(React.Fragment, null,
                        order.name,
                        " ",
                        React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }));
                } });
        };
        this.renderTargetDropdown = () => {
            const pathwayTransition = this.state.pathwayTransition;
            const targetStates = this.getTargetStates();
            const adapter = (0, frontend_1.SimpleListAdapter)(targetStates, ps => React.createElement(React.Fragment, null, ps.item.name));
            const selected = targetStates.find(ps => { var _a; return ps._id === ((_a = pathwayTransition.item.targetIds) === null || _a === void 0 ? void 0 : _a[0]); });
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' },
                    "Target State Name",
                    React.createElement("sup", null, "*")),
                React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: selected, caret: frontend_2.QuaiManager_DropDownCaret, onSelected: async (ps) => {
                        pathwayTransition.set('targetIds', [ps._id]);
                        this.createDefaultLabel(pathwayTransition);
                        this.forceUpdate();
                        dispatchers_1.dispatch_PathwayStateRealtimeChanges.dispatchUI();
                    } }));
        };
        this.renderLabelInput = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Label"),
                React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.pathwayTransition.item.label, onChange: (value) => {
                        this.state.pathwayTransition.set('label', value);
                        this.forceUpdate();
                    } }));
        };
        this.renderAlertInput = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Alert"),
                React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.pathwayTransition.item.alert, onChange: (value) => {
                        this.state.pathwayTransition.set('alert', value);
                        this.forceUpdate();
                    } }));
        };
        this.renderTests = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Tests"),
                this.renderOrdersMultiSelect('testsIds', 'test'));
        };
        this.renderTreatments = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Treatments"),
                this.renderOrdersMultiSelect('treatmentsIds', 'treatment'));
        };
        this.renderConsult = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Consult"),
                this.renderOrdersMultiSelect('consultIds', 'consult'));
        };
        this.renderNurse = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Nurse"),
                this.renderOrdersMultiSelect('nurseIds', 'poc'));
        };
        this.renderMetapathwayActions = () => {
            const transition = this.state.pathwayTransition;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'pathway__form__label' }, "Metapathway Actions"),
                React.createElement(frontend_1.TS_MultiSelect_V2, { editable: transition, prop: 'metaPathwayActions', selectionRenderer: this.renderMetapathwayActionSelector, itemRenderer: this.renderMetapathwayAction }));
        };
        this.renderMetapathwayActionSelector = (selector) => {
            return React.createElement(_enum_1.Dropdown_MetaPathwayAction_Type.selectable, { onSelected: async (type) => {
                    const action = (0, shared_1.getCleanMetaPathwayAction)(type);
                    selector.onSelected(action);
                    this.forceUpdate();
                }, queryFilter: type => {
                    return !selector.existingItems.find(action => action.type === type);
                } });
        };
        this.renderMetapathwayAction = (action, onDelete) => {
            return React.createElement(frontend_1.LL_V_L, { className: 'pathway__transition__metapathway-action' },
                React.createElement(frontend_1.LL_H_C, { className: 'pathway__transition__metapathway-action__label' },
                    shared_1.metaPathwayAction_Type_LabelMap[action.type],
                    React.createElement(styles_1.ICONS.x.component, { onClick: async () => {
                            await (onDelete === null || onDelete === void 0 ? void 0 : onDelete());
                            this.forceUpdate();
                        } })),
                (0, shared_1.isMetaPathwayAction_WithProtocol)(action) && this.renderMetapathwayActionProtocols(action));
        };
        this.renderMetapathwayActionProtocols = (action) => {
            return React.createElement(React.Fragment, null,
                "Protocols:",
                React.createElement(frontend_1.LL_V_L, { className: 'pathway__transition__metapathway-action__protocols' },
                    action.protocolIds.map(id => {
                        const protocol = _entity_1.ModuleFE_Pathway.cache.unique(id);
                        if (!protocol)
                            throw new ts_common_1.MUSTNeverHappenException(`Could not find pathway for id ${id}`);
                        return React.createElement(frontend_1.LL_H_C, { className: 'pathway__transition__metapathway-action__protocol' },
                            protocol.label,
                            React.createElement(styles_1.ICONS.x.component, { onClick: () => {
                                    (0, ts_common_1.removeItemFromArray)(action.protocolIds, id);
                                    this.forceUpdate();
                                } }));
                    }),
                    React.createElement(ui_components_1.DropDown_Pathway.selectable, { queryFilter: pathway => !action.protocolIds.includes(pathway._id), onSelected: pathway => {
                            action.protocolIds.push(pathway._id);
                            this.forceUpdate();
                        } })));
        };
    }
    __onPathwayTransitionUpdated(...params) {
        const pathwayTransitionId = this.state.pathwayTransition.get('_id');
        if (!pathwayTransitionId)
            return;
        (0, utils_2.basicSyncEventRefresh)(pathwayTransitionId, params, () => this.props.functions.selectPathwayTransition(pathwayTransitionId, true), () => this.props.functions.deselectPathwayTransition());
    }
    // ######################## Lifecycle ########################
    deriveStateFromProps(nextProps, state) {
        state.pathwayTransition = nextProps.pathwayTransition;
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent', style: { padding: '30px', gap: '10px' } },
            this.renderHeader(),
            this.renderForm(),
            this.renderButtons());
    }
}
exports.Renderer_PathwayTransitionEditor = Renderer_PathwayTransitionEditor;
