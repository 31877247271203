"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_FhirQuery = void 0;
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const fhir_1 = require("@app/fhir");
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
class Editor_FhirQuery extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        /**
         * Fhir query data editor
         */
        this.renderQueryData = () => {
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Query Data' },
                this.renderQueryDataTypeSelector(),
                this.renderQueryDataParamEditor());
        };
        /**
         * Fhir query data params editor
         */
        this.renderQueryDataParamEditor = () => {
            const editableQueryData = this.state.editable;
            const resourceType = editableQueryData.item.resourceType;
            if (!resourceType)
                return;
            const editableParams = editableQueryData.editProp('params', {});
            const existingParamKeys = (0, ts_common_1._keys)(editableParams.item);
            return React.createElement(frontend_1.LL_V_C, null,
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Params' },
                    existingParamKeys.map(pairKey => {
                        var _a;
                        const typeDefinition = fhir_1.ListOfFHIRQueryResources[resourceType];
                        const _pairKey = pairKey;
                        // Data to select keys
                        const possibleKeys = (0, ts_common_1._keys)(typeDefinition).filter(_key => !existingParamKeys.includes(_key));
                        const keyAdapter = (0, frontend_1.SimpleListAdapter)(possibleKeys, node => React.createElement("div", { style: { textTransform: 'capitalize' } }, node.item));
                        // Data to select values
                        const renderValues = (_pairKey) => {
                            const item = typeDefinition[_pairKey];
                            if ((item === null || item === void 0 ? void 0 : item.type) === 'dynamic')
                                return React.createElement("div", null, "Auto-filled");
                            const valueAdapter = (0, frontend_1.SimpleListAdapter)(item.values, node => {
                                var _a;
                                const labelMap = (0, fhir_1.getFhirParamsLabelsMap)(_pairKey, resourceType);
                                this.logInfo('???????', _pairKey, labelMap, labelMap === null || labelMap === void 0 ? void 0 : labelMap[node.item], node.item);
                                return React.createElement("div", { style: { textTransform: 'capitalize' } }, (_a = labelMap === null || labelMap === void 0 ? void 0 : labelMap[node.item]) !== null && _a !== void 0 ? _a : node.item);
                            });
                            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Value' },
                                React.createElement(frontend_1.TS_DropDown, { selected: editableParams.item[pairKey], adapter: valueAdapter, onSelected: async (value) => {
                                        const objToUpdate = {};
                                        objToUpdate[pairKey] = value;
                                        await editableParams.updateObj(objToUpdate);
                                    } }));
                        };
                        return React.createElement(frontend_1.LL_H_C, { key: `pairkey ${pairKey}` },
                            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Key' },
                                React.createElement(frontend_1.TS_DropDown, { selected: pairKey, adapter: keyAdapter, 
                                    // @ts-ignore
                                    disabled: ((_a = typeDefinition[_pairKey]) === null || _a === void 0 ? void 0 : _a.type) === 'mandatory', onSelected: async (key) => {
                                        const objToUpdate = {};
                                        objToUpdate[pairKey] = undefined;
                                        objToUpdate[key] = editableParams.item[pairKey];
                                        await editableParams.updateObj(objToUpdate);
                                    } })),
                            renderValues(_pairKey));
                    }),
                    this.renderAddNewParam()));
        };
        this.renderAddNewParam = () => {
            var _a, _b, _c;
            //todo
            // add new params to state
            const editableQueryData = this.state.editable;
            const resourceType = editableQueryData.item.resourceType;
            if (!resourceType)
                return React.createElement(React.Fragment, null);
            const editableParams = editableQueryData.editProp('params', {});
            const existingParamKeys = (0, ts_common_1._keys)(editableParams.item);
            const typeDefinition = fhir_1.ListOfFHIRQueryResources[resourceType];
            const possibleKeys = (0, ts_common_1._keys)(typeDefinition).filter(_key => {
                if (existingParamKeys.includes(_key))
                    return false;
                if (typeDefinition[_key].type === 'dynamic') // patient/encounter - these shouldn't be shown as they are provided by the system and not the user anyway.
                    return false;
                return true;
            });
            if (possibleKeys.length === 0)
                return React.createElement(React.Fragment, null);
            const keyAdapter = (0, frontend_1.SimpleListAdapter)(possibleKeys, node => React.createElement("div", { style: { textTransform: 'capitalize' } }, node.item));
            const renderPossibleValues = (key) => {
                //todo to be fixed- using a difficult type- 2024 april demo- not demo code, just fallout.
                var _a, _b;
                const labelMap = (0, fhir_1.getFhirParamsLabelsMap)(key, resourceType);
                const parameterDef = !key ? undefined : typeDefinition[key];
                const valueAdapter = (0, frontend_1.SimpleListAdapter)(!parameterDef ? [] : parameterDef.values, node => { var _a; return React.createElement("div", { style: { textTransform: 'capitalize' } }, (_a = labelMap === null || labelMap === void 0 ? void 0 : labelMap[node.item]) !== null && _a !== void 0 ? _a : node.item); });
                return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Value' },
                    React.createElement(frontend_1.TS_DropDown, { selected: ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.newParamData) === null || _b === void 0 ? void 0 : _b.key) && editableParams.item[this.state.newParamData.key], adapter: valueAdapter, disabled: !key, placeholder: key ? undefined : 'Choose A Key First', onSelected: async (selected) => {
                            this.setState({ newParamData: Object.assign(Object.assign({}, this.state.newParamData), { value: selected }) }, async () => await this.saveNewParamWhenPossible());
                        } }));
            };
            return React.createElement(frontend_1.LL_H_C, null,
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'New Key' },
                    React.createElement(frontend_1.TS_DropDown, { selected: (_a = this.state.newParamData) === null || _a === void 0 ? void 0 : _a.key, adapter: keyAdapter, placeholder: ((_b = this.state.newParamData) === null || _b === void 0 ? void 0 : _b.key) ? undefined : 'Add A Key', onSelected: async (selected) => {
                            this.setState({ newParamData: Object.assign(Object.assign({}, this.state.newParamData), { key: selected }) }, async () => await this.saveNewParamWhenPossible());
                        } })),
                renderPossibleValues((_c = this.state.newParamData) === null || _c === void 0 ? void 0 : _c.key));
        };
        this.saveNewParamWhenPossible = async () => {
            var _a, _b;
            //todo
            // saves the new param data to the editable item
            // cleans the new param data from state
            if (!this.state.newParamData || !this.state.newParamData.key || !this.state.newParamData.value) {
                this.logInfo(`key:${(_a = this.state.newParamData) === null || _a === void 0 ? void 0 : _a.key}, value:${(_b = this.state.newParamData) === null || _b === void 0 ? void 0 : _b.value}`);
                return;
            }
            const editableQueryData = this.state.editable;
            const resourceType = editableQueryData.item.resourceType;
            if (!resourceType)
                return;
            const editableParams = editableQueryData.editProp('params', {});
            //Save new param in query data
            await editableParams.updateObj({ [this.state.newParamData.key]: this.state.newParamData.value });
            //Remove new param data from component state, to allow another param to be added if possible
            this.reDeriveState({ newParamData: undefined });
        };
        /**
         * Fhir query data resource type editor
         */
        this.renderQueryDataTypeSelector = () => {
            const editableQueryData = this.state.editable;
            const selectedItem = editableQueryData.item.resourceType;
            const resourceTypes = (0, ts_common_1._keys)(fhir_1.ListOfFHIRQueryResources);
            const adapter = (0, frontend_1.SimpleListAdapter)(resourceTypes, node => React.createElement("div", { style: { textTransform: 'capitalize' } }, node.item));
            return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: 'Resource Type' },
                React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: selectedItem, onSelected: async (selected) => {
                        if (selectedItem === selected)
                            return;
                        if (!selected)
                            return editableQueryData.updateObj({ resourceType: undefined, params: undefined });
                        const objToUpdate = { resourceType: selected, params: {} };
                        const typeDefinition = fhir_1.ListOfFHIRQueryResources[selected];
                        const mandatoryKeys = (0, ts_common_1._keys)(typeDefinition).filter(_key => typeDefinition[_key].type === 'mandatory');
                        mandatoryKeys.forEach(_key => objToUpdate.params[_key] = '');
                        return await editableQueryData.updateObj(objToUpdate);
                    } }));
        };
    }
    render() {
        return this.renderQueryData();
    }
}
exports.Editor_FhirQuery = Editor_FhirQuery;
