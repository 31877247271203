"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_Observation_CodeOptions_ToLabelsMap = exports.FHIR_Observation_StatusOptions = exports.SocialHistory_Code_PregnancyIntention = exports.SocialHistory_Code_LastMenstrualPeriod = exports.SocialHistory_Code_EstimatedDeliveryDate = exports.SocialHistory_Code_PhysicalFindingsOfUterus = exports.SocialHistory_Code_SmokingHistory = exports.SocialHistory_Code_Lactation = exports.SocialHistory_Code_Menstrual = exports.SocialHistory_Code_Pregnancy = exports.FHIR_Observation_CategoryOptions_ToLabelsMap = exports.FHIR_Observation_CategoryOptions = exports.Category_SocialHistory = exports.Category_VitalSigns = void 0;
exports.Category_VitalSigns = 'vital-signs';
exports.Category_SocialHistory = 'social-history';
exports.FHIR_Observation_CategoryOptions = [
    exports.Category_VitalSigns,
    exports.Category_SocialHistory
];
exports.FHIR_Observation_CategoryOptions_ToLabelsMap = {
    [exports.Category_VitalSigns]: 'Vital Signs',
    [exports.Category_SocialHistory]: 'Social History',
};
exports.SocialHistory_Code_Pregnancy = '82810-3';
exports.SocialHistory_Code_Menstrual = '3146-8';
exports.SocialHistory_Code_Lactation = '63895-7';
exports.SocialHistory_Code_SmokingHistory = '72166-2';
exports.SocialHistory_Code_PhysicalFindingsOfUterus = '32486-3';
exports.SocialHistory_Code_EstimatedDeliveryDate = '11778-8';
exports.SocialHistory_Code_LastMenstrualPeriod = '8665-2';
exports.SocialHistory_Code_PregnancyIntention = '86645-9';
exports.FHIR_Observation_StatusOptions = [
    exports.SocialHistory_Code_Pregnancy,
    exports.SocialHistory_Code_Menstrual,
    exports.SocialHistory_Code_Lactation,
    exports.SocialHistory_Code_SmokingHistory,
    exports.SocialHistory_Code_PhysicalFindingsOfUterus,
    exports.SocialHistory_Code_EstimatedDeliveryDate,
    exports.SocialHistory_Code_LastMenstrualPeriod,
    exports.SocialHistory_Code_PregnancyIntention
];
exports.FHIR_Observation_CodeOptions_ToLabelsMap = {
    [exports.SocialHistory_Code_Pregnancy]: 'Pregnancy Status',
    [exports.SocialHistory_Code_Menstrual]: 'Menstrual Status',
    [exports.SocialHistory_Code_Lactation]: 'Lactation Status',
    [exports.SocialHistory_Code_SmokingHistory]: 'Smoking History',
    [exports.SocialHistory_Code_PhysicalFindingsOfUterus]: 'Physical Findings of Uterus',
    [exports.SocialHistory_Code_EstimatedDeliveryDate]: 'Estimated Delivery Date',
    [exports.SocialHistory_Code_LastMenstrualPeriod]: 'Last Menstrual Period',
    [exports.SocialHistory_Code_PregnancyIntention]: 'Pregnancy Intention',
};
