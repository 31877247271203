"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ResourceManager = void 0;
const React = require("react");
require("./Component_ResourceManager.scss");
class Component_ResourceManager extends React.Component {
    render() {
        return React.createElement("div", { className: 'placeholder-page-message' }, "Resource Manager");
    }
}
exports.Component_ResourceManager = Component_ResourceManager;
