"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_DatabaseIntegrityIssue = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const hcs_1 = require("@app/hcs");
// const validator_EntityReference: ValidatorTypeResolver<OmitDBObject<EntityReference>> = {
// 	entityType: tsValidateValue(allDBTypes),
// 	entityId: tsValidateUniqueId,
// };
const validator_DatabaseIntegrityIssue = {
    // type: tsValidateValue(issueTypes),
    type: (0, ts_common_1.tsValidateString)(),
    status: (0, ts_common_1.tsValidateValue)(consts_1.issueStatus),
    // entityReferences: tsValidateArray(validator_EntityReference),
    issueId: (0, ts_common_1.tsValidateString)(),
    topicId: (0, ts_common_1.tsValidateString)(),
};
exports.DBDef_DatabaseIntegrityIssue = {
    validator: validator_DatabaseIntegrityIssue,
    entityName: 'entity-reference',
    dbKey: 'entity-references',
    dbGroup: hcs_1.knowledgeManagerDBGroup,
    versions: ['1.0.0'],
};
