"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_PathwayTransition = exports.ModuleFE_PathwayTransition_Class = exports.dispatch_onPathwayTransitionChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const frontend_2 = require("@app/hcs/_entity/expression/frontend");
exports.dispatch_onPathwayTransitionChanged = new types_1.ThunderDispatcherV3('__onPathwayTransitionUpdated');
class ModuleFE_PathwayTransition_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_PathwayTransition, exports.dispatch_onPathwayTransitionChanged);
        this.getLastUpdateTransitionTimestamp = (transitions) => {
            return transitions.reduce((updatedTimestamp, currentTransition) => {
                if (currentTransition.__updated > updatedTimestamp)
                    updatedTimestamp = currentTransition.__updated;
                return updatedTimestamp;
            }, transitions[0].__updated);
        };
        this._v1 = {
            updateResolutionFunction: (0, frontend_1.apiWithBody)(shared_1.ApiDef_PathwayTransition._v1.updateResolutionFunction, async (response) => {
                await frontend_2.ModuleFE_Expression.onEntriesUpdated([response.resolutionFunction]);
                frontend_2.ModuleFE_Expression.IDB.setLastUpdated(response.resolutionFunction.__updated);
            }),
            updateTransitionsPriority: (0, frontend_1.apiWithBody)(shared_1.ApiDef_PathwayTransition._v1.updateTransitionsPriority, async ({ transitions }) => {
                const latestTransitionUpdated = this.getLastUpdateTransitionTimestamp(transitions);
                await this.onEntriesUpdated(transitions);
                this.IDB.setLastUpdated(latestTransitionUpdated);
            }),
            deleteResolutionFunction: (0, frontend_1.apiWithBody)(shared_1.ApiDef_PathwayTransition._v1.deleteResolutionFunction)
        };
    }
}
exports.ModuleFE_PathwayTransition_Class = ModuleFE_PathwayTransition_Class;
exports.ModuleFE_PathwayTransition = new ModuleFE_PathwayTransition_Class();
