"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_TimeValue = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Editor_TimeValue.scss");
const frontend_2 = require("../../../../../../_entity/value/frontend");
const componentes_1 = require("./componentes");
const consts_1 = require("../../../../../../_entity/value/shared/consts");
class Editor_TimeValue extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderTimeRanges = (editableTimeRanges) => {
            return React.createElement(frontend_1.LL_V_L, { className: 'value-editor__time__time-ranges' }, editableTimeRanges.item.map((item, index) => {
                const editableTimeRange = editableTimeRanges.editProp(index, {});
                return React.createElement(componentes_1.Component_TimeRangeValueEditor, { key: index, deleteItem: () => editableTimeRanges.removeArrayItem(index), editable: editableTimeRange });
            }));
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState.editableValue.tag !== nextState.editableTag)
            return true;
        return super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        (_a = state.editableValue) !== null && _a !== void 0 ? _a : (state.editableValue = new frontend_1.EditableDBItemV3(nextProps.value, frontend_2.ModuleFE_Value)
            .setOnChanged(async (editable) => this.reDeriveState({ editableValue: editable })));
        state.editableTag = state.editableValue.tag;
        return state;
    }
    render() {
        //FIXME: Hack, honestly I'm not sure how to work with the data as generic and value with editable item
        const UiTimeDataEditable = this.state.editableValue.editProp('data', {});
        const timeRangesEditable = UiTimeDataEditable.editProp('timeRanges', []);
        return React.createElement(frontend_1.LL_V_L, { className: 'value-editor__time' },
            React.createElement("div", { className: 'editor-title' }, "Create/Edit Time Ranges"),
            this.renderTimeRanges(timeRangesEditable),
            React.createElement(frontend_1.TS_Button, { onClick: () => timeRangesEditable.updateArrayAt(consts_1.ValType_TimeRange) }, "Add new time Range"));
    }
}
exports.Editor_TimeValue = Editor_TimeValue;
