"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Reference = exports.ModuleFE_Reference_Class = exports.dispatch_onReferencesUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("./shared");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../variable/frontend");
exports.dispatch_onReferencesUpdated = new types_1.ThunderDispatcherV3('__onReferencesUpdated');
class ModuleFE_Reference_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Reference, exports.dispatch_onReferencesUpdated);
        this.__searchFilterMapper = (item) => {
            var _a, _b, _c, _d, _e;
            return (0, ts_common_1.filterInstances)([(_a = item.data) === null || _a === void 0 ? void 0 : _a.id, (_b = item.data) === null || _b === void 0 ? void 0 : _b.bookId, (_c = item.data) === null || _c === void 0 ? void 0 : _c.name, (_e = (_d = item.data) === null || _d === void 0 ? void 0 : _d.names) === null || _e === void 0 ? void 0 : _e.join(' ')]);
        };
        this.__searchGroups = (items) => {
            return [];
        };
        this.is = {
            snomed: shared_1.reference_isSnomed,
            loinc: shared_1.reference_isLoinc,
            icdCode: shared_1.reference_isICD,
            article: shared_1.reference_isArticle,
            book: shared_1.reference_isBook,
            upToDate: shared_1.reference_isUpToDate,
        };
        this.__searchGroups = frontend_2.ModuleFE_Variable.__searchGroupsRefs;
    }
}
exports.ModuleFE_Reference_Class = ModuleFE_Reference_Class;
exports.ModuleFE_Reference = new ModuleFE_Reference_Class();
