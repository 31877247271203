"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_AttributeCard = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_AttributeCard.scss");
const ts_common_1 = require("@nu-art/ts-common");
const _entity_1 = require("../../../../../_entity");
class Component_AttributeCard extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        // ######################## Render ########################
        this.renderHeader = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'variable-attribute-card__header' },
                React.createElement("div", { className: 'variable-attribute-card__header__name' }, this.props.attribute.variable.name));
        };
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'variable-attribute-card__body' }, this.renderValueList());
        };
        this.renderValueList = () => {
            if (!this.props.attribute.value)
                return '';
            if (this.props.attribute.value.type === _entity_1.valueType_Range) {
                const scope = this.props.attribute.value.data.subsets;
                return React.createElement(React.Fragment, null, scope.map(id => {
                    const value = _entity_1.ModuleFE_Value.cache.unique(id);
                    return React.createElement("div", { key: id, className: 'value-tag' }, (0, _entity_1.resolveValueDisplay)(value));
                }));
            }
            if ([_entity_1.valueType_Enumerated, _entity_1.valueType_SubEnumerated].includes(this.props.attribute.value.type)) {
                const scope = this.props.attribute.value.data.scope;
                return React.createElement(React.Fragment, null, scope.map(id => {
                    const value = _entity_1.ModuleFE_Value.cache.unique(id);
                    return React.createElement("div", { key: id, className: 'value-tag' }, (0, _entity_1.resolveValueDisplay)(value));
                }));
            }
            throw new ts_common_1.BadImplementationException(`Scope value type not supported: ${this.props.attribute.value.type}`);
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        if (!nextProps.attribute.value) {
            const parentValue = _entity_1.ModuleFE_Value.cache.unique(nextProps.attribute.variable.valueId);
            if (parentValue.type === _entity_1.valueType_Range) {
                nextProps.attribute.value = (0, _entity_1.getCleanPreDBValue)(_entity_1.valueType_SubRange, parentValue);
            }
            else if (parentValue.type === _entity_1.valueType_Enumerated)
                nextProps.attribute.value = (0, _entity_1.getCleanPreDBValue)(_entity_1.valueType_SubEnumerated, parentValue);
        }
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'variable-attribute-card' },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Component_AttributeCard = Component_AttributeCard;
