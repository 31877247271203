"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Book = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const shared_1 = require("../../shared");
const Validator_Structure = () => {
    return (input) => {
        return (0, ts_common_1.tsValidateResult)(input, {
            name: (0, ts_common_1.tsValidateString)(),
            itemsType: (0, ts_common_1.tsValidateValue)([...types_1.itemTypeValues], false),
            items: (0, ts_common_1.tsValidateArray)(Validator_Structure(), false)
        });
    };
};
const Validator_ModifiableProps = {
    name: (0, ts_common_1.tsValidateString)(),
    edition: ts_common_1.tsValidator_nonMandatoryString,
    year: (0, ts_common_1.tsValidateNumber)(),
    isbn: (0, ts_common_1.tsValidateString)(),
    eisbn: ts_common_1.tsValidator_nonMandatoryString,
    itemsType: (0, ts_common_1.tsValidateValue)([...types_1.itemTypeValues], false),
    // TODO: add a recursive validation for items
    items: (0, ts_common_1.tsValidateArray)(Validator_Structure(), false),
};
const Validator_GeneratedProps = {
// 
};
exports.DBDef_Book = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'books',
    entityName: 'Book',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'book'
    },
    backend: {
        name: 'books'
    },
};
