"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_LogTemplateManager = void 0;
const React = require("react");
const frontend_1 = require("@app/styles/frontend");
const workspace_config_1 = require("./workspace-config");
const frontend_2 = require("@nu-art/ts-workspace/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_3 = require("@app/hcs/frontend");
const frontend_4 = require("../../../_entity/log-template/frontend");
const Component_LogTemplateEditor_1 = require("./presenter/Component_LogTemplateEditor");
const frontend_5 = require("@nu-art/permissions/frontend");
const EditorKey_LogTemplate = 'log-template-manager';
class Page_LogTemplateManager extends frontend_1.Page_BaseManagerUI_SingleType_Proto {
    constructor() {
        super(...arguments);
        this.workspace = {
            id: EditorKey_LogTemplate,
            key: workspace_config_1.workspaceKey_LogTemplate,
            renderers: {
                horizontalSpace: frontend_2.TS_HorizontalWorkspace,
            },
            instances: {
                selector: this.renderSelector.bind(this),
                presenter: this.renderPresenter.bind(this),
            }
        };
        this.onCreateNewLogTemplate = () => {
            this.clearSelectedResult();
            const newLogTemplate = this.getEditableItem();
            this.setState({
                selectedItem: newLogTemplate,
                selectedResult: undefined
            });
        };
        this.deselectLogTemplate = () => {
            this.clearSelectedResult();
            this.setState({
                selectedItem: undefined,
                selectedResult: undefined
            });
        };
        this.saveLogTemplate = async () => {
            const editable = this.state.selectedItem;
            if (!editable)
                return;
            editable.validate();
            if (editable.hasErrors())
                return this.setState({ selectedItem: editable });
            try {
                const newItem = await editable.save(false);
                this.onSearchResultSelected(newItem._id, Page_LogTemplateManager.SearchableItem_LogTemplate.type);
            }
            catch (e) {
                this.logError(e);
            }
        };
        this.deleteLogTemplate = () => {
            const logTemplate = this.state.selectedItem;
            if (!logTemplate)
                throw new ts_common_1.MUSTNeverHappenException('Triggered deleting Log Template without selecting any');
            frontend_1.Dialog_Confirmation.show({
                header: 'Are you sure?',
                content: React.createElement(React.Fragment, null,
                    React.createElement("div", null, "You are about to delete a Log Template"),
                    React.createElement("div", null, logTemplate.get('label'))),
                buttons: [
                    {
                        label: 'Yes, Delete', isAsync: true, action: async () => {
                            await logTemplate.delete();
                            this.deselectLogTemplate();
                        }
                    },
                    { label: 'Don\'t Delete', isPreferred: true, action: ts_common_1.voidFunction }
                ]
            });
        };
    }
    getInitialObject() {
        return {};
    }
    renderSelector() {
        return React.createElement(frontend_1.SearchSelector_BaseManagerUI, { selectedItem: this.state.selectedResult, onSearchItemSelected: (item) => this.onSearchResultSelected(item.id, item.type), searchItems: [Page_LogTemplateManager.SearchableItem_LogTemplate], buttons: [{
                    label: 'Add Log Template',
                    action: this.onCreateNewLogTemplate
                }] });
    }
    renderPresenter() {
        if (!this.state.selectedItem)
            return React.createElement(frontend_3.QPageMessage, { message: 'Log Template Manager' });
        return React.createElement(Component_LogTemplateEditor_1.Component_LogTemplateEditor, { editable: this.state.selectedItem, deleteLogTemplate: this.deleteLogTemplate, saveLogTemplate: this.saveLogTemplate, deselectLogTemplate: this.deselectLogTemplate });
    }
}
_a = Page_LogTemplateManager;
Page_LogTemplateManager.Route = {
    key: EditorKey_LogTemplate,
    path: 'log-templates',
    Component: _a,
    enabled: (0, frontend_5.RoutePermissions)(frontend_4.PermissionKeyFE_LogTemplateView),
    modulesToAwait: [...frontend_4.ModulePackFE_LogTemplateDB, frontend_3.ModuleFE_Variable],
};
Page_LogTemplateManager.searchItemKey_LogTemplate = frontend_4.ModuleFE_LogTemplate.dbDef.dbKey;
Page_LogTemplateManager.SearchableItem_LogTemplate = {
    module: frontend_4.ModuleFE_LogTemplate,
    type: Page_LogTemplateManager.searchItemKey_LogTemplate,
    route: Page_LogTemplateManager.Route,
    entityName: frontend_4.ModuleFE_LogTemplate.dbDef.entityName,
    entityLabel: 'Advisor Context',
    groupId: ['Advisor-Content'],
    tags: [],
    mapper: (item) => [item.label],
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_1.Renderer_SearchResult, { itemId: item._id, itemKey: Page_LogTemplateManager.searchItemKey_LogTemplate, itemLabel: item.label, entityLabel: 'Log Template', hierarchy: 'Advisor Content', selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (item) => {
        return React.createElement(React.Fragment, null);
    }
};
Page_LogTemplateManager.defaultProps = {
    searchItems: [Page_LogTemplateManager.SearchableItem_LogTemplate]
};
exports.Page_LogTemplateManager = Page_LogTemplateManager;
