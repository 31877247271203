"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ExpressionPrettyPrinter = void 0;
require("./Component_ExpressionPrettyPrinter.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const termPrinter_1 = require("./printers/termPrinter");
const termRelationParser_1 = require("./printers/termRelationParser");
const consts_1 = require("../../../../../_entity/expression/shared/consts");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const ts_common_1 = require("@nu-art/ts-common");
class Component_ExpressionPrettyPrinter extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        this.shouldRenderExpressionInTitle = () => {
            return !this.state.parsedTermRelation.every(relation => relation.type !== 'unknown');
        };
        this.renderFlat = () => {
            if (this.state.parsedTermRelation.every(relation => relation.type !== 'unknown'))
                return '';
            return (0, ts_common_1.deepClone)(this.state.editable.item).terms.map((term, index) => this.renderTerm(term, index, true));
        };
        this.renderTerm = (term, index, renderTitle = false) => {
            return React.createElement(frontend_1.LL_V_L, { className: 'pretty-print__term-list-item', key: index },
                renderTitle && React.createElement("div", { className: 'term-label' },
                    "Term ",
                    index + 1),
                (0, termPrinter_1.termPrinter)(term));
        };
        this.renderWithRelation = () => {
            if (this.state.parsedTermRelation.some(relation => relation.type === 'unknown'))
                return '';
            return this.state.parsedTermRelation.map((parsedStep, _index) => {
                if (parsedStep.type === 'term') {
                    const index = Number(parsedStep.value.split('T')[1]) - 1;
                    return this.renderTerm((0, ts_common_1.deepClone)(this.state.editable.item).terms[index], _index);
                }
                if (parsedStep.type === 'operator') {
                    switch (parsedStep.value) {
                        case consts_1.LogicType_Or:
                            return React.createElement("div", { key: _index, className: 'pretty-print__sub-title' }, "Or:");
                        case consts_1.LogicType_And:
                            return React.createElement("div", { key: _index, className: 'pretty-print__sub-title' }, "And:");
                    }
                }
                if (parsedStep.type === 'sumGroup') {
                    return React.createElement(React.Fragment, { key: _index },
                        React.createElement("div", { className: 'pretty-print__sub-title' }, "Sum of the following:"),
                        parsedStep.value.map((term, __index) => {
                            const index = Number(term.split('T')[1]) - 1;
                            return this.renderTerm((0, ts_common_1.deepClone)(this.state.editable.item).terms[index], __index);
                        }));
                }
            });
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.parsedTermRelation = (0, termRelationParser_1.parseTermRelation)(state.editable.item.expression);
        state.className = nextProps.className;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: (0, frontend_1._className)(this.state.className), id: 'expression-pretty-printer' },
            this.props.showHeader && React.createElement("div", { className: 'pretty-print__title' }, this.state.editable.item.label),
            this.shouldRenderExpressionInTitle() &&
                React.createElement("div", { className: 'pretty-print__sub-title' },
                    "Relation ",
                    this.state.editable.item.expression,
                    " of:"),
            React.createElement(frontend_1.LL_V_L, { className: 'pretty-print__term-list' },
                this.renderFlat(),
                this.renderWithRelation()));
    }
}
exports.Component_ExpressionPrettyPrinter = Component_ExpressionPrettyPrinter;
