"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathwayVariableContext_ResolutionFunctions = exports.PathwayVariableContext_StatePreconditions = exports.PathwayVariableContext_TransitionNurse = exports.PathwayVariableContext_TransitionTreatments = exports.PathwayVariableContext_TransitionConsults = exports.PathwayVariableContext_TransitionTests = exports.Empty_PathwayTransition = exports.Empty_PathwayState = void 0;
const Empty_PathwayState = (pathwayId) => Object.freeze({
    name: 'New',
    pathwayId,
    dds: [],
});
exports.Empty_PathwayState = Empty_PathwayState;
const Empty_PathwayTransition = (pathwayId, originStateId) => Object.freeze({
    pathwayId,
    sourceIds: [originStateId],
    targetIds: [],
    dds: [],
    resolutionFunction: {},
});
exports.Empty_PathwayTransition = Empty_PathwayTransition;
exports.PathwayVariableContext_TransitionTests = 'transitions-tests';
exports.PathwayVariableContext_TransitionConsults = 'transitions-consults';
exports.PathwayVariableContext_TransitionTreatments = 'transitions-treatments';
exports.PathwayVariableContext_TransitionNurse = 'transitions-nurse';
exports.PathwayVariableContext_StatePreconditions = 'state-preconditions';
exports.PathwayVariableContext_ResolutionFunctions = 'resolution-function-variables';
