"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_Organization_EHROrders = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const _entity_1 = require("../../../../../_entity");
const frontend_2 = require("@app/hcs/frontend");
require("./Editor_Organization_EHROrder.scss");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const Renderer_EHROrderTime_1 = require("./Renderer_EHROrderTime");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const permission_keys_1 = require("../../../../../permission-keys");
const frontend_3 = require("@app/styles/frontend");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
const styles_1 = require("@app/styles");
const frontend_4 = require("@app/pathway/frontend");
const frontend_5 = require("@app/pathway/_entity/action/frontend");
const ui_components_2 = require("@app/pathway/_entity/action/frontend/ui-components");
const ViewKey_Edit = 'edit';
const ViewKey_View = 'view';
const missingKeysLabelMap = {
    ehrInstanceId: 'EHR Instance ID',
    availability: 'Availability',
    source: 'Source',
    orderId: 'Order',
    ehrOrderId: 'EHR Order ID'
};
class Editor_Organization_EHROrders extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.SourceDropDown = frontend_1.TS_DropDown.prepareEditable({
            adapter: (0, frontend_1.SimpleListAdapter)([..._entity_1.EHR_OrderConfigSources], item => React.createElement(React.Fragment, null, _entity_1.EHROrder_SourceLabelMap[item.item]))
        });
        // ######################## Logic ########################
        this.createNewOrderConfig = () => {
            const currentEditable = this.state.editable;
            //If currently editing a new order config
            if (currentEditable && !currentEditable.item._id)
                return;
            this.setState({
                editable: this.getEditableOrderConfig({
                    ehrInstanceId: this.state.ehrInstanceId,
                    availability: { type: _entity_1.ehrOrderAvailability_Always },
                })
            });
        };
        this.selectOrderConfig = (orderConfig) => {
            if (!orderConfig._id)
                return;
            this.setState({
                editable: this.getEditableOrderConfig(orderConfig),
            });
        };
        this.getViewKey = () => this.state.editMode ? ViewKey_Edit : ViewKey_View;
        this.onOrderTimeSaved = async (item, index, day) => {
            var _a;
            const editable = this.state.editable;
            if (((_a = editable === null || editable === void 0 ? void 0 : editable.item.availability) === null || _a === void 0 ? void 0 : _a.type) !== _entity_1.ehrOrderAvailability_Partial)
                throw new ts_common_1.BadImplementationException('Saving an order time when availability is no partial!');
            if (index === -1) {
                if (!editable.item.availability.data[day])
                    editable.item.availability.data[day] = [];
                editable.item.availability.data[day].push(item);
            }
            else {
                editable.item.availability.data[day][index] = item;
            }
            await editable.save(true);
            this.forceUpdate();
        };
        this.getOrderTimeEditable = (config, index, day) => {
            return new frontend_1.EditableItem(config, async (item) => {
                await this.onOrderTimeSaved(item, index, day);
                return item;
            }, ts_common_1.voidFunction).setAutoSave(true);
        };
        this.onOrderRightClick = (e, order) => {
            if (!order._id)
                return;
            const actions = this.getOrderActions(order);
            if (!actions.length)
                return;
            frontend_1.ModuleFE_MouseInteractivity.showContent({
                id: 'order-pop-up-menu',
                originPos: (0, frontend_1.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 1, y: 1 },
                content: () => React.createElement(React.Fragment, null, actions.map(action => {
                    return React.createElement("div", { key: action.label, className: 'popup-action', onClick: action.action }, action.label);
                }))
            });
        };
        this.getOrderActions = (order) => {
            const actions = [];
            if (ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(permission_keys_1.PermissionKeyFE_OrganizationEdit) === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess) {
                actions.push({
                    label: 'Delete Order',
                    action: () => {
                        frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                        frontend_3.Dialog_Confirmation.show({
                            header: 'Delete EHR Order',
                            content: () => React.createElement(React.Fragment, null, "Delete EHR Order?"),
                            buttons: [
                                {
                                    label: 'Yes, Delete', isAsync: true, action: async () => {
                                        var _a;
                                        await _entity_1.ModuleFE_EHROrderConfiguration.v1.delete(order).executeSync();
                                        if (((_a = this.state.editable) === null || _a === void 0 ? void 0 : _a.item._id) === order._id)
                                            return this.setState({ editable: undefined });
                                        this.forceUpdate();
                                    }
                                },
                                { label: 'Don\'t Delete', isPreferred: true, action: ts_common_1.voidFunction }
                            ]
                        });
                    }
                });
            }
            return actions;
        };
        this.orderIsIncomplete = (order) => {
            const keys = (0, ts_common_1._keys)(_entity_1.DBDef_EHROrderConfiguration.modifiablePropsValidator);
            return keys.filter(key => !order[key]);
        };
        this.getSyncFromPathwayCounts = () => {
            const ehrInstance = _entity_1.ModuleFE_EHRInstance.cache.unique(this.state.ehrInstanceId);
            if (!ehrInstance)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find EHRInstance for id ${this.state.ehrInstanceId}`);
            const pathways = ehrInstance.pathwayIds.map(id => {
                const pathway = frontend_4.ModuleFE_Pathway.cache.unique(id);
                if (!pathway)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find Pathway for id ${id}`);
                return pathway;
            });
            let orderIds = (0, ts_common_1.filterDuplicates)((0, ts_common_1.filterInstances)((0, ts_common_1.flatArray)(ehrInstance.pathwayIds.map(pathwayId => {
                const states = frontend_4.ModuleFE_PathwayState.cache.filter(state => state.pathwayId === pathwayId);
                const transitions = frontend_4.ModuleFE_PathwayTransition.cache.filter(transition => transition.pathwayId === pathwayId);
                return [
                    ...states.map(state => state.preconditions),
                    ...transitions.map(transition => [
                        transition.testsIds,
                        transition.treatmentsIds,
                        transition.consultIds,
                        transition.nurseIds,
                    ])
                ];
            }))));
            orderIds = orderIds.filter(id => !_entity_1.ModuleFE_EHROrderConfiguration.cache.find(order => order.ehrInstanceId === ehrInstance._id && order.orderId === id));
            return {
                pathways: pathways.length,
                orders: orderIds.length,
            };
        };
        this.syncFromPathwaysDialog = () => {
            const counts = this.getSyncFromPathwayCounts();
            frontend_3.Dialog_Confirmation.show({
                header: 'Sync Orders From Pathways',
                content: React.createElement(React.Fragment, null,
                    "Sync orders from ",
                    counts.pathways,
                    " pathways?",
                    React.createElement("br", null),
                    "This action will create ",
                    counts.orders,
                    " new order configurations"),
                buttons: [
                    { label: 'Cancel', action: ts_common_1.voidFunction },
                    {
                        label: 'Sync',
                        isPreferred: true,
                        isAsync: true,
                        action: async () => {
                            await _entity_1.ModuleFE_EHRInstance._v1.syncOrdersFromPathways({ ehrInstanceId: this.state.ehrInstanceId }).executeSync();
                        }
                    },
                ]
            });
        };
        // ######################## Render - Order List ########################
        this.renderOrdersList = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'editor-organization-ehr-orders__orders' },
                this.renderListButtons(),
                this.renderOrders());
        };
        this.renderListButtons = () => {
            if (!this.state.editMode)
                return React.createElement(React.Fragment, null);
            return React.createElement(frontend_1.LL_V_L, { className: 'editor-organization-ehr-orders__orders__buttons' },
                React.createElement(frontend_1.TS_Button, { onClick: this.createNewOrderConfig }, "Add Another Order"),
                React.createElement(frontend_1.TS_Button, { onClick: this.syncFromPathwaysDialog }, "Sync From Pathways"));
        };
        this.renderOrders = () => {
            let orderConfigs = _entity_1.ModuleFE_EHROrderConfiguration.cache.filter(oc => oc.ehrInstanceId === this.state.ehrInstanceId);
            orderConfigs = (0, ts_common_1.sortArray)(orderConfigs, config => {
                var _a;
                const order = frontend_5.ModuleFE_Action.cache.unique(config.orderId);
                return (_a = order === null || order === void 0 ? void 0 : order.name) !== null && _a !== void 0 ? _a : '_new';
            });
            return React.createElement(frontend_2.QScrollWrapper, null,
                this.renderSelectedOrder(),
                orderConfigs.map(this.renderOrder));
        };
        this.renderSelectedOrder = () => {
            const editable = this.state.editable;
            if (!editable || editable.item._id)
                return;
            return this.renderOrder(editable.item);
        };
        this.renderOrder = (orderConfig) => {
            var _a, _b;
            const editable = this.state.editable;
            const order = frontend_5.ModuleFE_Action.cache.unique(orderConfig.orderId);
            const missingKeys = this.orderIsIncomplete(orderConfig);
            const className = (0, frontend_1._className)('editor-organization-ehr-orders__order', (editable === null || editable === void 0 ? void 0 : editable.item._id) === orderConfig._id && 'selected');
            return React.createElement("div", { key: (_a = orderConfig._id) !== null && _a !== void 0 ? _a : 'new-config', className: className, onClick: () => this.selectOrderConfig(orderConfig), onContextMenu: e => this.onOrderRightClick(e, orderConfig) },
                !!missingKeys.length && React.createElement(styles_1.ICONS.missingInfo.component, Object.assign({ className: 'editor-organization-ehr-orders__order__incomplete' }, frontend_1.openContent.tooltip.top('editor-organization-ehr-orders__order__incomplete-tooltip', () => this.renderMissingKeysTooltipContent(missingKeys), { offset: -5 }))), (_b = order === null || order === void 0 ? void 0 : order.name) !== null && _b !== void 0 ? _b : 'New');
        };
        this.renderMissingKeysTooltipContent = (missingKeys) => {
            return React.createElement(React.Fragment, null,
                "Instance Order is missing the following data:",
                React.createElement("br", null),
                missingKeys.map(key => missingKeysLabelMap[key]).join(', '));
        };
        // ######################## Render - Order Editor ########################
        this.renderForm = () => {
            if (!this.state.editable)
                return React.createElement(React.Fragment, null);
            const className = (0, frontend_1._className)(this.state.editMode && 'edit-mode');
            return React.createElement(frontend_1.Grid, { className: className },
                this.renderSaveButton(),
                this.renderDBOrder(),
                this.renderEHROrderId(),
                this.renderSource(),
                this.renderAvailabilityDropdown(),
                this.renderPartialAvailabilityTimes());
        };
        this.renderDBOrder = () => {
            var _a;
            const editable = this.state.editable;
            const order = frontend_5.ModuleFE_Action.cache.unique(editable === null || editable === void 0 ? void 0 : editable.item.orderId);
            if ((editable === null || editable === void 0 ? void 0 : editable.item.orderId) && !order)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find order for id ${editable.item.orderId}`);
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Order', error: editable === null || editable === void 0 ? void 0 : editable.hasError('orderId') },
                    React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                            { key: ViewKey_View, renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = order === null || order === void 0 ? void 0 : order.name) !== null && _a !== void 0 ? _a : '-'); } },
                            {
                                key: ViewKey_Edit,
                                renderer: () => React.createElement(ui_components_2.DropDown_Action.editable, { editable: editable, prop: 'orderId', queryFilter: frontend_5.ModuleFE_Action.is.order })
                            }
                        ] })),
                React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Internal ID' },
                    React.createElement("div", { className: 'grid-data' }, (_a = editable === null || editable === void 0 ? void 0 : editable.item.orderId) !== null && _a !== void 0 ? _a : '-')));
        };
        this.renderEHROrderId = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'EHR Order ID' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = editable === null || editable === void 0 ? void 0 : editable.item.ehrOrderId) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: ViewKey_Edit, renderer: () => React.createElement(ui_components_1.DefaultEditor_InputText_Optional, { editable: editable, prop: 'ehrOrderId' })
                        }
                    ] }));
        };
        this.renderSource = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Source', error: editable === null || editable === void 0 ? void 0 : editable.hasError('source') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => {
                                var _a;
                                return React.createElement("div", { className: 'grid-data' }, (_a = _entity_1.EHROrder_SourceLabelMap[editable === null || editable === void 0 ? void 0 : editable.item.source]) !== null && _a !== void 0 ? _a : '-');
                            }
                        },
                        {
                            key: ViewKey_Edit,
                            renderer: () => {
                                return React.createElement(this.SourceDropDown, { editable: editable, prop: 'source' });
                            }
                        }
                    ] }));
        };
        this.renderAvailabilityDropdown = () => {
            const editable = this.state.editable;
            const error = editable === null || editable === void 0 ? void 0 : editable.hasError('availability');
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Availability', error: typeof error === 'string' ? error : undefined },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewKey(), modes: [
                        {
                            key: ViewKey_View,
                            renderer: () => {
                                var _a, _b;
                                return React.createElement("div", { className: 'grid-data' }, (_b = _entity_1.EHROrder_AvailabilityLabelMap[(_a = editable === null || editable === void 0 ? void 0 : editable.item.availability) === null || _a === void 0 ? void 0 : _a.type]) !== null && _b !== void 0 ? _b : '-');
                            }
                        },
                        {
                            key: ViewKey_Edit,
                            renderer: () => {
                                var _a;
                                const adapter = (0, frontend_1.SimpleListAdapter)([..._entity_1.EHR_OrderConfigAvailabilities], item => React.createElement(React.Fragment, null, _entity_1.EHROrder_AvailabilityLabelMap[item.item]));
                                return React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: (_a = editable === null || editable === void 0 ? void 0 : editable.item.availability) === null || _a === void 0 ? void 0 : _a.type, onSelected: availability => {
                                        var _a;
                                        if (availability === ((_a = editable === null || editable === void 0 ? void 0 : editable.item.availability) === null || _a === void 0 ? void 0 : _a.type))
                                            return;
                                        if (availability === _entity_1.ehrOrderAvailability_Always)
                                            return editable.updateObj({ availability: { type: _entity_1.ehrOrderAvailability_Always } });
                                        editable.updateObj({
                                            availability: {
                                                type: _entity_1.ehrOrderAvailability_Partial,
                                                data: {
                                                    sun: [],
                                                    mon: [],
                                                    tue: [],
                                                    wed: [],
                                                    thu: [],
                                                    fri: [],
                                                    sat: [],
                                                }
                                            }
                                        });
                                    } });
                            }
                        }
                    ] }));
        };
        this.renderPartialAvailabilityTimes = () => {
            var _a;
            const editable = this.state.editable;
            if (((_a = editable === null || editable === void 0 ? void 0 : editable.item.availability) === null || _a === void 0 ? void 0 : _a.type) !== _entity_1.ehrOrderAvailability_Partial)
                return React.createElement(React.Fragment, null);
            const data = editable.item.availability.data;
            return React.createElement(React.Fragment, null, (0, ts_common_1.sortArray)((0, ts_common_1._keys)(data), key => _entity_1.EHROrder_DaysOrder.indexOf(key)).map(day => {
                if (data[day].length === 0) {
                    if (!this.state.editMode)
                        return React.createElement(frontend_1.TS_PropRenderer.Flat, { key: `${day}`, label: `${_entity_1.EHROrder_DaysLabelMap[day]}.`, className: 'indent' },
                            React.createElement("div", { className: 'grid-data span-row' }, "-"));
                    const time = this.getOrderTimeEditable({}, -1, day);
                    return React.createElement(frontend_1.TS_PropRenderer.Flat, { key: `${day}`, label: `${_entity_1.EHROrder_DaysLabelMap[day]}.`, className: 'indent' },
                        React.createElement(Renderer_EHROrderTime_1.EHROrderTimeInput, { editable: time, editMode: this.state.editMode }));
                }
                return data[day].map((timeConfig, i) => {
                    const time = this.getOrderTimeEditable(timeConfig, i, day);
                    return React.createElement(frontend_1.TS_PropRenderer.Flat, { key: `${day}-${i}`, label: `${_entity_1.EHROrder_DaysLabelMap[day]}.`, className: 'indent' },
                        React.createElement(Renderer_EHROrderTime_1.EHROrderTimeInput, { editable: time, editMode: this.state.editMode }));
                });
            }));
        };
        this.renderSaveButton = () => {
            const editable = this.state.editable;
            if (!editable || (editable === null || editable === void 0 ? void 0 : editable.item._id))
                return;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.TS_BusyButton, { onClick: async () => {
                        try {
                            await (editable === null || editable === void 0 ? void 0 : editable.save());
                        }
                        catch (err) {
                            this.logError(err);
                            this.forceUpdate();
                        }
                    } }, "Save"),
                React.createElement("div", null));
        };
    }
    // ######################## Lifecycle ########################
    __onEHROrderConfigurationUpdated(...params) {
        var _a, _b;
        // If sync update the selected item
        if (params[0] === 'update' || params[0] === 'upsert-all')
            if ((0, ts_common_1.asArray)(params[1])[0]._id === ((_a = this.state.editable) === null || _a === void 0 ? void 0 : _a.get('_id')))
                return (_b = this.state.editable) === null || _b === void 0 ? void 0 : _b.updateItem((0, ts_common_1.asArray)(params[1])[0]);
        this.forceUpdate();
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        var _a;
        if (nextState.ehrOrderEditableTag !== ((_a = nextState.editable) === null || _a === void 0 ? void 0 : _a.tag))
            return true;
        return super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        state.editMode = nextProps.editMode;
        state.organizationId = nextProps.organizationId;
        state.ehrInstanceId = nextProps.ehrInstanceId;
        //Looking at new / different instance
        if (((_a = this.state) === null || _a === void 0 ? void 0 : _a.ehrInstanceId) !== state.ehrInstanceId) {
            const orderConfigs = _entity_1.ModuleFE_EHROrderConfiguration.cache.filter(oc => oc.ehrInstanceId === state.ehrInstanceId);
            if (orderConfigs.length)
                state.editable = this.getEditableOrderConfig(orderConfigs[0]);
            else
                state.editable = undefined;
        }
        state.ehrOrderEditableTag = (_b = state.editable) === null || _b === void 0 ? void 0 : _b.tag;
        return state;
    }
    getEditableOrderConfig(_dbItem) {
        return new frontend_1.EditableDBItemV3(_dbItem, _entity_1.ModuleFE_EHROrderConfiguration)
            .setOnChanged(async (editable) => this.reDeriveState({ editable }))
            .setAutoSave(!!_dbItem._id)
            .setDebounceTimeout(100);
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_H_C, { className: 'editor-organization-ehr-orders' },
            this.renderOrdersList(),
            this.renderForm());
    }
}
exports.Editor_Organization_EHROrders = Editor_Organization_EHROrders;
