"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lynxEnvTypes = exports.tenantIdTypes = void 0;
exports.tenantIdTypes = [
    'MUSC',
    'FHIR.EPIC_QUAI_SANDBOX',
    'FHIR.EPIC_LYNX_SANDBOX',
    'MUSC_SANDBOX_1',
    'MUSC_SANDBOX_2',
    'MUSC_SANDBOX_3',
    'APP_MARKET_SANDBOX',
    'APP_MARKET_PROD'
];
exports.lynxEnvTypes = ['LYNX_LOCAL', 'LYNX_PROD', 'MOCK'];
