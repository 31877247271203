"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariableWrapper = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const wrapper_1 = require("../../_entity/variable/shared/wrapper");
const _entity_1 = require("../_entity");
// const __filter = new Filter<string>(s => [s]);
class VariableWrapper extends wrapper_1.BaseVariableWrapper {
    constructor() {
        super(...arguments);
        this.save = async () => {
            this.validate();
            return await _entity_1.ModuleFE_Variable.v1.upsert(this.dbVariable).executeSync();
        };
        this.getNlpData = () => {
            return _entity_1.ModuleFE_NLPData.cache.unique(this.dbVariable.nlpDataId);
        };
        this.getReferences = () => {
            var _a;
            return (0, ts_common_1.filterInstances)((_a = this.dbVariable.referenceIds) === null || _a === void 0 ? void 0 : _a.map(refId => (_entity_1.ModuleFE_Reference.cache.unique(refId)))) || [];
        };
        this.getValue = () => {
            return _entity_1.ModuleFE_Value.cache.unique(this.dbVariable.valueId);
        };
        this.getUIValue = () => {
            var _a;
            if (!((_a = this.dbVariable) === null || _a === void 0 ? void 0 : _a.valueId))
                return;
            return (0, _entity_1.convertValueDBToUI)(_entity_1.ModuleFE_Value.cache.unique(this.dbVariable.valueId), {
                values: _entity_1.ModuleFE_Value.cache.arrayToMap(ts_common_1.dbObjectToId),
                measurementUnits: _entity_1.ModuleFE_MeasurementUnit.cache.arrayToMap(ts_common_1.dbObjectToId)
            });
        };
        this.getInnerValues = () => {
            const parentValue = this.getValue();
            if (!parentValue)
                return [];
            switch (parentValue.type) {
                case _entity_1.valueType_Enumerated: {
                    return (0, ts_common_1.filterInstances)(parentValue.data.scope.map(id => _entity_1.ModuleFE_Value.cache.unique(id)));
                }
                case _entity_1.valueType_Range: {
                    return (0, ts_common_1.filterInstances)(parentValue.data.subsets.map(id => _entity_1.ModuleFE_Value.cache.unique(id)));
                }
                default:
                    return [];
            }
        };
        this.getUITags = () => {
            return this.dbVariable.tagIds && VariableWrapper.dbTagsToUI(this.dbVariable.tagIds, this.dbVariable) || [];
        };
        this.canBeSaved = () => {
            return VariableWrapper.hasSourceSubTags(this.dbVariable.tagIds);
        };
        this.getSystemTag = () => {
            var _a;
            return (_a = this.getUITags().find(pair => pair.key.label.toLowerCase() === 'Systems'.toLowerCase())) === null || _a === void 0 ? void 0 : _a.value[0];
        };
        this.getAttributes = () => {
            var _a;
            return ((_a = this.dbVariable.attributes) === null || _a === void 0 ? void 0 : _a.map(att => {
                const variable = _entity_1.ModuleFE_Variable.cache.unique(att.varId);
                if (!variable)
                    throw new ts_common_1.MUSTNeverHappenException(`Failed getting attributes for variable ${this.dbVariable.name}, missing variable for _id: ${att.varId}`);
                const value = _entity_1.ModuleFE_Value.cache.unique(att.valueId);
                if (!value)
                    throw new ts_common_1.MUSTNeverHappenException(`Failed getting attributes for variable ${this.dbVariable.name}, missing value for _id: ${att.valueId}`);
                return {
                    variable: variable,
                    value: value,
                };
            })) || [];
        };
        this.getSearchableText = () => {
            var _a, _b;
            const innerValues = this.getInnerValues();
            const valueNames = ((_a = innerValues[0]) === null || _a === void 0 ? void 0 : _a.type) === _entity_1.valueType_EnumElement ? innerValues.map(i => i.data.value) : innerValues.map(i => i.data.label);
            const synonyms = ((_b = this.getNlpData()) === null || _b === void 0 ? void 0 : _b.synonyms) || [];
            // const attributes = this.getAttributes()?.map(att => att.variable.name) || [];
            return (0, ts_common_1.filterInstances)([this.dbVariable._nameInLowercase, ...synonyms, ...valueNames]);
        };
        this.renderVarFilterPassReason = (filter) => {
            var _a;
            if (!filter)
                return '';
            //If synonyms match
            const matchingSynonyms = ((_a = this.getNlpData()) === null || _a === void 0 ? void 0 : _a.synonyms.map(s => s.toLowerCase())) || [];
            //If values match
            // const innerValues = this.getInnerValues()
            // 	.map(value => value.type === valueType_EnumElement ? (value as DB_Value<ValueType_EnumElement>).data.value : (value as DB_Value<ValueType_SubRange>).data.label || '');
            // const matchingValues = __filter.filterSort(innerValues, filter);
            if (matchingSynonyms.length) {
                return `(${matchingSynonyms.length ? `synonym: ${matchingSynonyms[0]}` : ''})`;
            }
            return '';
        };
    }
    static fromId(_id) {
        if (!_id)
            throw new ts_common_1.BadImplementationException(`VariableWrapper fromId did not receive an _id!`);
        const _dbVariable = _entity_1.ModuleFE_Variable.cache.unique(_id);
        if (!_dbVariable)
            throw new ts_common_1.BadImplementationException(`VariableWrapper fromId received an id for a non-existent variable! ${_id}`);
        return new VariableWrapper(_dbVariable);
    }
}
VariableWrapper.getTagsForDB = (tags) => {
    return tags === null || tags === void 0 ? void 0 : tags.map(pair => ({ key: pair.key._id, value: pair.value.map(ts_common_1.dbObjectToId) }));
};
VariableWrapper.dbTagsToUI = (tagIds, dbVariable) => {
    return (tagIds === null || tagIds === void 0 ? void 0 : tagIds.map(tagGroupPair => {
        const groupTag = _entity_1.ModuleFE_Tag.cache.unique(tagGroupPair.key);
        if (!groupTag)
            throw new ts_common_1.MUSTNeverHappenException(`Failed getting tags for variable ${dbVariable === null || dbVariable === void 0 ? void 0 : dbVariable.name}, missing category/tag for _id: ${tagGroupPair.key}`);
        const tagsInGroup = tagGroupPair.value.map(tagId => {
            const _tagInGroup = _entity_1.ModuleFE_Tag.cache.unique(tagId);
            if (!_tagInGroup)
                throw new ts_common_1.MUSTNeverHappenException(`Failed getting tags for variable ${dbVariable === null || dbVariable === void 0 ? void 0 : dbVariable.name}, missing category/tag for _id: ${tagGroupPair.key}`);
            return _tagInGroup;
        });
        return { key: groupTag, value: tagsInGroup };
    })) || [];
};
VariableWrapper.hasSourceSubTags = (tagGroups) => {
    if (!tagGroups)
        return false;
    const sourceTagId = _entity_1.ConfigKeyFE_SourceTagId.get();
    const _tagGroups = typeof tagGroups[0].key === 'string' ? tagGroups : VariableWrapper.getTagsForDB(tagGroups);
    return !!wrapper_1.BaseVariableWrapper.getSourceSubTags(sourceTagId, _tagGroups).length;
};
exports.VariableWrapper = VariableWrapper;
