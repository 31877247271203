"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_EHRInstance = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_EHRInstance = {
    _v1: {
        deleteInstance: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/delete-instance' },
        syncOrdersFromPathways: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/sync-orders-from-pathways' }
    }
};
