"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_EHROrderConfiguration = exports.ModuleFE_EHROrderConfiguration_Class = exports.dispatch_onEHROrderConfigurationChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onEHROrderConfigurationChanged = new types_1.ThunderDispatcherV3('__onEHROrderConfigurationUpdated');
class ModuleFE_EHROrderConfiguration_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_EHROrderConfiguration, exports.dispatch_onEHROrderConfigurationChanged);
        this._v1 = {};
    }
}
exports.ModuleFE_EHROrderConfiguration_Class = ModuleFE_EHROrderConfiguration_Class;
exports.ModuleFE_EHROrderConfiguration = new ModuleFE_EHROrderConfiguration_Class();
