"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_CollapsablePrettyPrint = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Component_ExpressionPrettyPrinter_1 = require("@app/hcs/frontend/ui/expressions/ui/expression-pretty-print/Component_ExpressionPrettyPrinter");
const frontend_2 = require("@app/hcs/_entity/expression/frontend");
const styles_1 = require("@app/styles");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_3 = require("../../../../../../_entity/pathway-transition/frontend");
/**
 * Reusable component for pathway manager, will be used to render a collapsable container in the context
 * of a pathway transition rendering the resolution function pretty print
 */
class Component_CollapsablePrettyPrint extends frontend_1.ComponentSync {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    render() {
        const transition = frontend_3.ModuleFE_PathwayTransition.cache.unique(this.props.transitionId);
        if (!transition)
            throw new ts_common_1.MUSTNeverHappenException(`pathway transition with id ${this.props.transitionId} not found`);
        const resolutionFunction = frontend_2.ModuleFE_Expression.cache.unique(transition.resolutionFunctionId);
        let editableItem;
        if (resolutionFunction) {
            editableItem = new frontend_1.EditableDBItemV3(resolutionFunction, frontend_2.ModuleFE_Expression);
            editableItem.validate();
        }
        return React.createElement(frontend_1.LL_H_C, { className: 'section-value' },
            React.createElement(frontend_1.TS_CollapsableContainer, { className: (0, frontend_1._className)(this.props.removeIndentation && 'remove-indentation'), headerRenderer: React.createElement(frontend_1.LL_H_C, { className: (0, frontend_1._className)('collapsable-label', this.props.removeIndentation && 'main-title') },
                    "Resolution",
                    (editableItem === null || editableItem === void 0 ? void 0 : editableItem.hasErrors()) && React.createElement(styles_1.ICONS.attention.component, null)), containerRenderer: () => {
                    if (!editableItem)
                        return React.createElement("div", { onClick: () => this.props.functions.selectResolutionFunctionEditor(this.props.transitionId), className: (0, frontend_1._className)('no-result-line clickable', this.props.removeIndentation && 'main-section') }, "Create New");
                    return React.createElement(Component_ExpressionPrettyPrinter_1.Component_ExpressionPrettyPrinter, { className: (0, frontend_1._className)(this.props.removeIndentation && 'main-section'), editable: editableItem, showHeader: false });
                }, customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null) }));
    }
}
exports.Component_CollapsablePrettyPrint = Component_CollapsablePrettyPrint;
