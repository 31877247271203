"use strict";
// Generated from src/main/shared/grammar/QuaiExpressionsV3.g4 by ANTLR 4.9.0-SNAPSHOT
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuloOperationContext = exports.DivisionOperationContext = exports.MultiplicationOperationContext = exports.NumericExpr1Context = exports.PassThroughArithmeticTermContext = exports.SubtractionOperationContext = exports.AdditionOperationContext = exports.NumericExpr0Context = exports.ParenExprContext = exports.ResolvableStringContext = exports.StringExprContext = exports.StringExpr0Context = exports.ResolveNoContext = exports.ResolveYesContext = exports.ResolveRelievingContext = exports.ResolvePrecipitatingContext = exports.ResolveAttrContext = exports.ResolveRangeContext = exports.ResolveEnumContext = exports.ResolveVarContext = exports.ResolveYesNoContext = exports.ResolveReilivingFactorContext = exports.ResolvePrecipitatingFactorContext = exports.ResolveAttributeContext = exports.ResolveEnumeratedContext = exports.ResolveRangeMaxValueContext = exports.ResolveRangeMinValueContext = exports.ResolvePropContext = exports.ResolveDbExpressionContext = exports.ResolvableExprContext = exports.GeneralConditionContext = exports.ConditionalExpr0Context = exports.ArrayLiteralContext = exports.ArrayLiteral0Context = exports.ArrayAtLeastContext = exports.ArrayEveryContext = exports.ArrayIncludesContext = exports.ArrayFunctionsContext = exports.ExpressionResolverContext = exports.OptionalTermContext = exports.OptionalTermExprContext = exports.ConditionExprContext = exports.AtomicExprContext = exports.ArithmeticOperationExprContext = exports.BooleanOperationExprContext = exports.ArrayExprContext = exports.ArrayFuncExprContext = exports.ResolvablesExprContext = exports.ExpressionContext = exports.QuaiExpressionsV3Parser = void 0;
exports.PrimitivesContext = exports.TermWrapperBooleanContext = exports.ParenBooleanOperationContext = exports.BooleanArrayFunctionsContext = exports.ResolvableBooleanContext = exports.BooleanLiteralContext = exports.BoolExpr5Context = exports.PassThroughAtomicBooleanContext = exports.NotOperationContext = exports.BoolExpr4Context = exports.PassThroughBooleanFactorContext = exports.AtomicEqualComparisonContext = exports.NumericEqaulComparisonContext = exports.BoolExpr3Context = exports.PassThroughBooleanEqualityContext = exports.InRangeContext = exports.GreaterOrEqualsComparisonContext = exports.LesserOrEqualsComparisonContext = exports.GreaterThanComparisonContext = exports.LessThanComparisonContext = exports.BoolExpr2Context = exports.PassThroughBooleanComparisonContext = exports.AndOperationContext = exports.BoolExpr1Context = exports.PassThroughOrContext = exports.OrOperationContext = exports.BooleanEqualityExprContext = exports.BoolExpr0Context = exports.TermWrapperNumericContext = exports.ParenNumericConditionContext = exports.ParenArithmeticOperationContext = exports.ResolvableNumberContext = exports.NumberLiteralContext = exports.NumericExpr3Context = exports.AtomicNumberLiteralContext = exports.PowerOperationContext = exports.NegativeExpressionContext = exports.NumericExpr2Context = exports.PassThroughArithmeticFactorContext = void 0;
const ATN_1 = require("antlr4ts/atn/ATN");
const ATNDeserializer_1 = require("antlr4ts/atn/ATNDeserializer");
const FailedPredicateException_1 = require("antlr4ts/FailedPredicateException");
const NoViableAltException_1 = require("antlr4ts/NoViableAltException");
const Parser_1 = require("antlr4ts/Parser");
const ParserRuleContext_1 = require("antlr4ts/ParserRuleContext");
const ParserATNSimulator_1 = require("antlr4ts/atn/ParserATNSimulator");
const RecognitionException_1 = require("antlr4ts/RecognitionException");
const Token_1 = require("antlr4ts/Token");
const VocabularyImpl_1 = require("antlr4ts/VocabularyImpl");
const Utils = require("antlr4ts/misc/Utils");
class QuaiExpressionsV3Parser extends Parser_1.Parser {
    // @Override
    // @NotNull
    get vocabulary() {
        return QuaiExpressionsV3Parser.VOCABULARY;
    }
    // tslint:enable:no-trailing-whitespace
    // @Override
    get grammarFileName() { return "QuaiExpressionsV3.g4"; }
    // @Override
    get ruleNames() { return QuaiExpressionsV3Parser.ruleNames; }
    // @Override
    get serializedATN() { return QuaiExpressionsV3Parser._serializedATN; }
    createFailedPredicateException(predicate, message) {
        return new FailedPredicateException_1.FailedPredicateException(this, predicate, message);
    }
    constructor(input) {
        super(input);
        this._interp = new ParserATNSimulator_1.ParserATNSimulator(QuaiExpressionsV3Parser._ATN, this);
    }
    // @RuleVersion(0)
    expression() {
        let _localctx = new ExpressionContext(this._ctx, this.state);
        this.enterRule(_localctx, 0, QuaiExpressionsV3Parser.RULE_expression);
        try {
            this.state = 62;
            this._errHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this._input, 0, this._ctx)) {
                case 1:
                    _localctx = new ResolvablesExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 54;
                        this.resolvableExpr();
                    }
                    break;
                case 2:
                    _localctx = new ArrayFuncExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 55;
                        this.arrayFunctions();
                    }
                    break;
                case 3:
                    _localctx = new ArrayExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 56;
                        this.arrayLiteral0();
                    }
                    break;
                case 4:
                    _localctx = new BooleanOperationExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 4);
                    {
                        this.state = 57;
                        this.boolExpr0(0);
                    }
                    break;
                case 5:
                    _localctx = new ArithmeticOperationExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 5);
                    {
                        this.state = 58;
                        this.numericExpr0(0);
                    }
                    break;
                case 6:
                    _localctx = new AtomicExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 6);
                    {
                        this.state = 59;
                        this.stringExpr0();
                    }
                    break;
                case 7:
                    _localctx = new ConditionExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 7);
                    {
                        this.state = 60;
                        this.conditionalExpr0();
                    }
                    break;
                case 8:
                    _localctx = new OptionalTermExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 8);
                    {
                        this.state = 61;
                        this.optionalTerm();
                    }
                    break;
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    optionalTerm() {
        let _localctx = new OptionalTermContext(this._ctx, this.state);
        this.enterRule(_localctx, 2, QuaiExpressionsV3Parser.RULE_optionalTerm);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 64;
                this.match(QuaiExpressionsV3Parser.T__0);
                this.state = 65;
                this.expression();
                this.state = 66;
                this.match(QuaiExpressionsV3Parser.T__1);
                this.state = 67;
                this.primitives();
                this.state = 68;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    expressionResolver() {
        let _localctx = new ExpressionResolverContext(this._ctx, this.state);
        this.enterRule(_localctx, 4, QuaiExpressionsV3Parser.RULE_expressionResolver);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 70;
                this.match(QuaiExpressionsV3Parser.T__3);
                this.state = 71;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 72;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    arrayFunctions() {
        let _localctx = new ArrayFunctionsContext(this._ctx, this.state);
        this.enterRule(_localctx, 6, QuaiExpressionsV3Parser.RULE_arrayFunctions);
        try {
            this.state = 91;
            this._errHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this._input, 1, this._ctx)) {
                case 1:
                    _localctx = new ArrayIncludesContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 74;
                        this.arrayLiteral0();
                        this.state = 75;
                        this.match(QuaiExpressionsV3Parser.T__4);
                        this.state = 76;
                        this.expression();
                        this.state = 77;
                        this.match(QuaiExpressionsV3Parser.T__2);
                    }
                    break;
                case 2:
                    _localctx = new ArrayEveryContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 79;
                        this.arrayLiteral0();
                        this.state = 80;
                        this.match(QuaiExpressionsV3Parser.T__5);
                        this.state = 81;
                        this.expression();
                        this.state = 82;
                        this.match(QuaiExpressionsV3Parser.T__2);
                    }
                    break;
                case 3:
                    _localctx = new ArrayAtLeastContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 84;
                        this.arrayLiteral0();
                        this.state = 85;
                        this.match(QuaiExpressionsV3Parser.T__6);
                        this.state = 86;
                        this.expression();
                        this.state = 87;
                        this.match(QuaiExpressionsV3Parser.T__1);
                        this.state = 88;
                        this.match(QuaiExpressionsV3Parser.NUMBER);
                        this.state = 89;
                        this.match(QuaiExpressionsV3Parser.T__2);
                    }
                    break;
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    arrayLiteral0() {
        let _localctx = new ArrayLiteral0Context(this._ctx, this.state);
        this.enterRule(_localctx, 8, QuaiExpressionsV3Parser.RULE_arrayLiteral0);
        let _la;
        try {
            _localctx = new ArrayLiteralContext(_localctx);
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 93;
                this.match(QuaiExpressionsV3Parser.T__7);
                this.state = 94;
                this.expression();
                this.state = 99;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                while (_la === QuaiExpressionsV3Parser.T__1) {
                    {
                        {
                            this.state = 95;
                            this.match(QuaiExpressionsV3Parser.T__1);
                            this.state = 96;
                            this.expression();
                        }
                    }
                    this.state = 101;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                }
                this.state = 102;
                this.match(QuaiExpressionsV3Parser.T__8);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    conditionalExpr0() {
        let _localctx = new ConditionalExpr0Context(this._ctx, this.state);
        this.enterRule(_localctx, 10, QuaiExpressionsV3Parser.RULE_conditionalExpr0);
        try {
            _localctx = new GeneralConditionContext(_localctx);
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 104;
                this.boolExpr0(0);
                this.state = 105;
                this.match(QuaiExpressionsV3Parser.T__9);
                this.state = 106;
                this.expression();
                this.state = 107;
                this.match(QuaiExpressionsV3Parser.T__10);
                this.state = 108;
                this.expression();
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolvableExpr() {
        let _localctx = new ResolvableExprContext(this._ctx, this.state);
        this.enterRule(_localctx, 12, QuaiExpressionsV3Parser.RULE_resolvableExpr);
        try {
            let _alt;
            this.state = 135;
            this._errHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this._input, 5, this._ctx)) {
                case 1:
                    _localctx = new ResolveDbExpressionContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 110;
                        this.expressionResolver();
                    }
                    break;
                case 2:
                    _localctx = new ResolvePropContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 111;
                        this.resolveVar();
                        this.state = 116;
                        this._errHandler.sync(this);
                        _alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
                        while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
                            if (_alt === 1) {
                                {
                                    {
                                        this.state = 112;
                                        this.match(QuaiExpressionsV3Parser.T__11);
                                        this.state = 113;
                                        this.match(QuaiExpressionsV3Parser.PROPERTY);
                                    }
                                }
                            }
                            this.state = 118;
                            this._errHandler.sync(this);
                            _alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
                        }
                    }
                    break;
                case 3:
                    _localctx = new ResolveRangeMinValueContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 119;
                        this.resolveRange();
                        {
                            this.state = 120;
                            this.match(QuaiExpressionsV3Parser.T__11);
                            this.state = 121;
                            this.match(QuaiExpressionsV3Parser.MIN);
                        }
                    }
                    break;
                case 4:
                    _localctx = new ResolveRangeMaxValueContext(_localctx);
                    this.enterOuterAlt(_localctx, 4);
                    {
                        this.state = 123;
                        this.resolveRange();
                        {
                            this.state = 124;
                            this.match(QuaiExpressionsV3Parser.T__11);
                            this.state = 125;
                            this.match(QuaiExpressionsV3Parser.MAX);
                        }
                    }
                    break;
                case 5:
                    _localctx = new ResolveEnumeratedContext(_localctx);
                    this.enterOuterAlt(_localctx, 5);
                    {
                        this.state = 127;
                        this.resolveEnum();
                    }
                    break;
                case 6:
                    _localctx = new ResolveAttributeContext(_localctx);
                    this.enterOuterAlt(_localctx, 6);
                    {
                        this.state = 128;
                        this.resolveAttr();
                    }
                    break;
                case 7:
                    _localctx = new ResolvePrecipitatingFactorContext(_localctx);
                    this.enterOuterAlt(_localctx, 7);
                    {
                        this.state = 129;
                        this.resolvePrecipitating();
                    }
                    break;
                case 8:
                    _localctx = new ResolveReilivingFactorContext(_localctx);
                    this.enterOuterAlt(_localctx, 8);
                    {
                        this.state = 130;
                        this.resolveRelieving();
                    }
                    break;
                case 9:
                    _localctx = new ResolveYesNoContext(_localctx);
                    this.enterOuterAlt(_localctx, 9);
                    {
                        this.state = 133;
                        this._errHandler.sync(this);
                        switch (this._input.LA(1)) {
                            case QuaiExpressionsV3Parser.T__18:
                                {
                                    this.state = 131;
                                    this.resolveYes();
                                }
                                break;
                            case QuaiExpressionsV3Parser.T__19:
                                {
                                    this.state = 132;
                                    this.resolveNo();
                                }
                                break;
                            default:
                                throw new NoViableAltException_1.NoViableAltException(this);
                        }
                    }
                    break;
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolveVar() {
        let _localctx = new ResolveVarContext(this._ctx, this.state);
        this.enterRule(_localctx, 14, QuaiExpressionsV3Parser.RULE_resolveVar);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 137;
                this.match(QuaiExpressionsV3Parser.T__12);
                this.state = 138;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 139;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolveEnum() {
        let _localctx = new ResolveEnumContext(this._ctx, this.state);
        this.enterRule(_localctx, 16, QuaiExpressionsV3Parser.RULE_resolveEnum);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 141;
                this.match(QuaiExpressionsV3Parser.T__13);
                this.state = 142;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 143;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolveRange() {
        let _localctx = new ResolveRangeContext(this._ctx, this.state);
        this.enterRule(_localctx, 18, QuaiExpressionsV3Parser.RULE_resolveRange);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 145;
                this.match(QuaiExpressionsV3Parser.T__14);
                this.state = 146;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 147;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolveAttr() {
        let _localctx = new ResolveAttrContext(this._ctx, this.state);
        this.enterRule(_localctx, 20, QuaiExpressionsV3Parser.RULE_resolveAttr);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 149;
                this.match(QuaiExpressionsV3Parser.T__15);
                this.state = 150;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 151;
                this.match(QuaiExpressionsV3Parser.T__1);
                this.state = 152;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 153;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolvePrecipitating() {
        let _localctx = new ResolvePrecipitatingContext(this._ctx, this.state);
        this.enterRule(_localctx, 22, QuaiExpressionsV3Parser.RULE_resolvePrecipitating);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 155;
                this.match(QuaiExpressionsV3Parser.T__16);
                this.state = 156;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 157;
                this.match(QuaiExpressionsV3Parser.T__1);
                this.state = 158;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 159;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolveRelieving() {
        let _localctx = new ResolveRelievingContext(this._ctx, this.state);
        this.enterRule(_localctx, 24, QuaiExpressionsV3Parser.RULE_resolveRelieving);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 161;
                this.match(QuaiExpressionsV3Parser.T__17);
                this.state = 162;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 163;
                this.match(QuaiExpressionsV3Parser.T__1);
                this.state = 164;
                this.match(QuaiExpressionsV3Parser.UNIQUE_ID);
                this.state = 165;
                this.match(QuaiExpressionsV3Parser.T__2);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolveYes() {
        let _localctx = new ResolveYesContext(this._ctx, this.state);
        this.enterRule(_localctx, 26, QuaiExpressionsV3Parser.RULE_resolveYes);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 167;
                this.match(QuaiExpressionsV3Parser.T__18);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    resolveNo() {
        let _localctx = new ResolveNoContext(this._ctx, this.state);
        this.enterRule(_localctx, 28, QuaiExpressionsV3Parser.RULE_resolveNo);
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 169;
                this.match(QuaiExpressionsV3Parser.T__19);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    stringExpr0() {
        let _localctx = new StringExpr0Context(this._ctx, this.state);
        this.enterRule(_localctx, 30, QuaiExpressionsV3Parser.RULE_stringExpr0);
        try {
            this.state = 177;
            this._errHandler.sync(this);
            switch (this._input.LA(1)) {
                case QuaiExpressionsV3Parser.STRING:
                    _localctx = new StringExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 171;
                        this.match(QuaiExpressionsV3Parser.STRING);
                    }
                    break;
                case QuaiExpressionsV3Parser.T__3:
                case QuaiExpressionsV3Parser.T__12:
                case QuaiExpressionsV3Parser.T__13:
                case QuaiExpressionsV3Parser.T__14:
                case QuaiExpressionsV3Parser.T__15:
                case QuaiExpressionsV3Parser.T__16:
                case QuaiExpressionsV3Parser.T__17:
                case QuaiExpressionsV3Parser.T__18:
                case QuaiExpressionsV3Parser.T__19:
                    _localctx = new ResolvableStringContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 172;
                        this.resolvableExpr();
                    }
                    break;
                case QuaiExpressionsV3Parser.T__20:
                    _localctx = new ParenExprContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 173;
                        this.match(QuaiExpressionsV3Parser.T__20);
                        this.state = 174;
                        this.expression();
                        this.state = 175;
                        this.match(QuaiExpressionsV3Parser.T__2);
                    }
                    break;
                default:
                    throw new NoViableAltException_1.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    numericExpr0(_p) {
        if (_p === undefined) {
            _p = 0;
        }
        let _parentctx = this._ctx;
        let _parentState = this.state;
        let _localctx = new NumericExpr0Context(this._ctx, _parentState);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let _prevctx = _localctx;
        let _startState = 32;
        this.enterRecursionRule(_localctx, 32, QuaiExpressionsV3Parser.RULE_numericExpr0, _p);
        try {
            let _alt;
            this.enterOuterAlt(_localctx, 1);
            {
                {
                    _localctx = new PassThroughArithmeticTermContext(_localctx);
                    this._ctx = _localctx;
                    _prevctx = _localctx;
                    this.state = 180;
                    this.numericExpr1(0);
                }
                this._ctx._stop = this._input.tryLT(-1);
                this.state = 190;
                this._errHandler.sync(this);
                _alt = this.interpreter.adaptivePredict(this._input, 8, this._ctx);
                while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
                    if (_alt === 1) {
                        if (this._parseListeners != null) {
                            this.triggerExitRuleEvent();
                        }
                        _prevctx = _localctx;
                        {
                            this.state = 188;
                            this._errHandler.sync(this);
                            switch (this.interpreter.adaptivePredict(this._input, 7, this._ctx)) {
                                case 1:
                                    {
                                        _localctx = new AdditionOperationContext(new NumericExpr0Context(_parentctx, _parentState));
                                        this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_numericExpr0);
                                        this.state = 182;
                                        if (!(this.precpred(this._ctx, 3))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
                                        }
                                        this.state = 183;
                                        this.match(QuaiExpressionsV3Parser.T__21);
                                        this.state = 184;
                                        this.numericExpr1(0);
                                    }
                                    break;
                                case 2:
                                    {
                                        _localctx = new SubtractionOperationContext(new NumericExpr0Context(_parentctx, _parentState));
                                        this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_numericExpr0);
                                        this.state = 185;
                                        if (!(this.precpred(this._ctx, 2))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
                                        }
                                        this.state = 186;
                                        this.match(QuaiExpressionsV3Parser.T__22);
                                        this.state = 187;
                                        this.numericExpr1(0);
                                    }
                                    break;
                            }
                        }
                    }
                    this.state = 192;
                    this._errHandler.sync(this);
                    _alt = this.interpreter.adaptivePredict(this._input, 8, this._ctx);
                }
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.unrollRecursionContexts(_parentctx);
        }
        return _localctx;
    }
    // @RuleVersion(0)
    numericExpr1(_p) {
        if (_p === undefined) {
            _p = 0;
        }
        let _parentctx = this._ctx;
        let _parentState = this.state;
        let _localctx = new NumericExpr1Context(this._ctx, _parentState);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let _prevctx = _localctx;
        let _startState = 34;
        this.enterRecursionRule(_localctx, 34, QuaiExpressionsV3Parser.RULE_numericExpr1, _p);
        try {
            let _alt;
            this.enterOuterAlt(_localctx, 1);
            {
                {
                    _localctx = new PassThroughArithmeticFactorContext(_localctx);
                    this._ctx = _localctx;
                    _prevctx = _localctx;
                    this.state = 194;
                    this.numericExpr2();
                }
                this._ctx._stop = this._input.tryLT(-1);
                this.state = 207;
                this._errHandler.sync(this);
                _alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
                while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
                    if (_alt === 1) {
                        if (this._parseListeners != null) {
                            this.triggerExitRuleEvent();
                        }
                        _prevctx = _localctx;
                        {
                            this.state = 205;
                            this._errHandler.sync(this);
                            switch (this.interpreter.adaptivePredict(this._input, 9, this._ctx)) {
                                case 1:
                                    {
                                        _localctx = new MultiplicationOperationContext(new NumericExpr1Context(_parentctx, _parentState));
                                        this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_numericExpr1);
                                        this.state = 196;
                                        if (!(this.precpred(this._ctx, 4))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
                                        }
                                        this.state = 197;
                                        this.match(QuaiExpressionsV3Parser.T__23);
                                        this.state = 198;
                                        this.numericExpr2();
                                    }
                                    break;
                                case 2:
                                    {
                                        _localctx = new DivisionOperationContext(new NumericExpr1Context(_parentctx, _parentState));
                                        this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_numericExpr1);
                                        this.state = 199;
                                        if (!(this.precpred(this._ctx, 3))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
                                        }
                                        this.state = 200;
                                        this.match(QuaiExpressionsV3Parser.T__24);
                                        this.state = 201;
                                        this.numericExpr2();
                                    }
                                    break;
                                case 3:
                                    {
                                        _localctx = new ModuloOperationContext(new NumericExpr1Context(_parentctx, _parentState));
                                        this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_numericExpr1);
                                        this.state = 202;
                                        if (!(this.precpred(this._ctx, 2))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
                                        }
                                        this.state = 203;
                                        this.match(QuaiExpressionsV3Parser.T__25);
                                        this.state = 204;
                                        this.numericExpr2();
                                    }
                                    break;
                            }
                        }
                    }
                    this.state = 209;
                    this._errHandler.sync(this);
                    _alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
                }
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.unrollRecursionContexts(_parentctx);
        }
        return _localctx;
    }
    // @RuleVersion(0)
    numericExpr2() {
        let _localctx = new NumericExpr2Context(this._ctx, this.state);
        this.enterRule(_localctx, 36, QuaiExpressionsV3Parser.RULE_numericExpr2);
        try {
            this.state = 217;
            this._errHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this._input, 11, this._ctx)) {
                case 1:
                    _localctx = new NegativeExpressionContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 210;
                        this.match(QuaiExpressionsV3Parser.T__22);
                        this.state = 211;
                        this.numericExpr3();
                    }
                    break;
                case 2:
                    _localctx = new PowerOperationContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 212;
                        this.numericExpr3();
                        this.state = 213;
                        this.match(QuaiExpressionsV3Parser.T__26);
                        this.state = 214;
                        this.numericExpr2();
                    }
                    break;
                case 3:
                    _localctx = new AtomicNumberLiteralContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 216;
                        this.numericExpr3();
                    }
                    break;
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    numericExpr3() {
        let _localctx = new NumericExpr3Context(this._ctx, this.state);
        this.enterRule(_localctx, 38, QuaiExpressionsV3Parser.RULE_numericExpr3);
        try {
            this.state = 233;
            this._errHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this._input, 12, this._ctx)) {
                case 1:
                    _localctx = new NumberLiteralContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 219;
                        this.match(QuaiExpressionsV3Parser.NUMBER);
                    }
                    break;
                case 2:
                    _localctx = new ResolvableNumberContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 220;
                        this.resolvableExpr();
                    }
                    break;
                case 3:
                    _localctx = new ParenArithmeticOperationContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 221;
                        this.match(QuaiExpressionsV3Parser.T__20);
                        this.state = 222;
                        this.numericExpr0(0);
                        this.state = 223;
                        this.match(QuaiExpressionsV3Parser.T__2);
                    }
                    break;
                case 4:
                    _localctx = new ParenNumericConditionContext(_localctx);
                    this.enterOuterAlt(_localctx, 4);
                    {
                        this.state = 225;
                        this.match(QuaiExpressionsV3Parser.T__20);
                        this.state = 226;
                        this.conditionalExpr0();
                        this.state = 227;
                        this.match(QuaiExpressionsV3Parser.T__2);
                    }
                    break;
                case 5:
                    _localctx = new TermWrapperNumericContext(_localctx);
                    this.enterOuterAlt(_localctx, 5);
                    {
                        this.state = 229;
                        this.match(QuaiExpressionsV3Parser.T__27);
                        this.state = 230;
                        this.numericExpr0(0);
                        this.state = 231;
                        this.match(QuaiExpressionsV3Parser.T__28);
                    }
                    break;
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    boolExpr0(_p) {
        if (_p === undefined) {
            _p = 0;
        }
        let _parentctx = this._ctx;
        let _parentState = this.state;
        let _localctx = new BoolExpr0Context(this._ctx, _parentState);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let _prevctx = _localctx;
        let _startState = 40;
        this.enterRecursionRule(_localctx, 40, QuaiExpressionsV3Parser.RULE_boolExpr0, _p);
        try {
            let _alt;
            this.enterOuterAlt(_localctx, 1);
            {
                {
                    _localctx = new PassThroughOrContext(_localctx);
                    this._ctx = _localctx;
                    _prevctx = _localctx;
                    this.state = 236;
                    this.boolExpr1(0);
                }
                this._ctx._stop = this._input.tryLT(-1);
                this.state = 246;
                this._errHandler.sync(this);
                _alt = this.interpreter.adaptivePredict(this._input, 14, this._ctx);
                while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
                    if (_alt === 1) {
                        if (this._parseListeners != null) {
                            this.triggerExitRuleEvent();
                        }
                        _prevctx = _localctx;
                        {
                            this.state = 244;
                            this._errHandler.sync(this);
                            switch (this.interpreter.adaptivePredict(this._input, 13, this._ctx)) {
                                case 1:
                                    {
                                        _localctx = new BooleanEqualityExprContext(new BoolExpr0Context(_parentctx, _parentState));
                                        this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_boolExpr0);
                                        this.state = 238;
                                        if (!(this.precpred(this._ctx, 3))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
                                        }
                                        this.state = 239;
                                        this.match(QuaiExpressionsV3Parser.T__29);
                                        this.state = 240;
                                        this.boolExpr1(0);
                                    }
                                    break;
                                case 2:
                                    {
                                        _localctx = new OrOperationContext(new BoolExpr0Context(_parentctx, _parentState));
                                        this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_boolExpr0);
                                        this.state = 241;
                                        if (!(this.precpred(this._ctx, 2))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
                                        }
                                        this.state = 242;
                                        this.match(QuaiExpressionsV3Parser.T__30);
                                        this.state = 243;
                                        this.boolExpr1(0);
                                    }
                                    break;
                            }
                        }
                    }
                    this.state = 248;
                    this._errHandler.sync(this);
                    _alt = this.interpreter.adaptivePredict(this._input, 14, this._ctx);
                }
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.unrollRecursionContexts(_parentctx);
        }
        return _localctx;
    }
    // @RuleVersion(0)
    boolExpr1(_p) {
        if (_p === undefined) {
            _p = 0;
        }
        let _parentctx = this._ctx;
        let _parentState = this.state;
        let _localctx = new BoolExpr1Context(this._ctx, _parentState);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let _prevctx = _localctx;
        let _startState = 42;
        this.enterRecursionRule(_localctx, 42, QuaiExpressionsV3Parser.RULE_boolExpr1, _p);
        try {
            let _alt;
            this.enterOuterAlt(_localctx, 1);
            {
                {
                    _localctx = new PassThroughBooleanComparisonContext(_localctx);
                    this._ctx = _localctx;
                    _prevctx = _localctx;
                    this.state = 250;
                    this.boolExpr2();
                }
                this._ctx._stop = this._input.tryLT(-1);
                this.state = 257;
                this._errHandler.sync(this);
                _alt = this.interpreter.adaptivePredict(this._input, 15, this._ctx);
                while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
                    if (_alt === 1) {
                        if (this._parseListeners != null) {
                            this.triggerExitRuleEvent();
                        }
                        _prevctx = _localctx;
                        {
                            {
                                _localctx = new AndOperationContext(new BoolExpr1Context(_parentctx, _parentState));
                                this.pushNewRecursionContext(_localctx, _startState, QuaiExpressionsV3Parser.RULE_boolExpr1);
                                this.state = 252;
                                if (!(this.precpred(this._ctx, 2))) {
                                    throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
                                }
                                this.state = 253;
                                this.match(QuaiExpressionsV3Parser.T__31);
                                this.state = 254;
                                this.boolExpr2();
                            }
                        }
                    }
                    this.state = 259;
                    this._errHandler.sync(this);
                    _alt = this.interpreter.adaptivePredict(this._input, 15, this._ctx);
                }
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.unrollRecursionContexts(_parentctx);
        }
        return _localctx;
    }
    // @RuleVersion(0)
    boolExpr2() {
        let _localctx = new BoolExpr2Context(this._ctx, this.state);
        this.enterRule(_localctx, 44, QuaiExpressionsV3Parser.RULE_boolExpr2);
        let _la;
        try {
            this.state = 283;
            this._errHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this._input, 16, this._ctx)) {
                case 1:
                    _localctx = new LessThanComparisonContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 260;
                        this.numericExpr0(0);
                        this.state = 261;
                        this.match(QuaiExpressionsV3Parser.T__32);
                        this.state = 262;
                        this.numericExpr0(0);
                    }
                    break;
                case 2:
                    _localctx = new GreaterThanComparisonContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 264;
                        this.numericExpr0(0);
                        this.state = 265;
                        this.match(QuaiExpressionsV3Parser.T__33);
                        this.state = 266;
                        this.numericExpr0(0);
                    }
                    break;
                case 3:
                    _localctx = new LesserOrEqualsComparisonContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 268;
                        this.numericExpr0(0);
                        this.state = 269;
                        this.match(QuaiExpressionsV3Parser.T__34);
                        this.state = 270;
                        this.numericExpr0(0);
                    }
                    break;
                case 4:
                    _localctx = new GreaterOrEqualsComparisonContext(_localctx);
                    this.enterOuterAlt(_localctx, 4);
                    {
                        this.state = 272;
                        this.numericExpr0(0);
                        this.state = 273;
                        this.match(QuaiExpressionsV3Parser.T__35);
                        this.state = 274;
                        this.numericExpr0(0);
                    }
                    break;
                case 5:
                    _localctx = new InRangeContext(_localctx);
                    this.enterOuterAlt(_localctx, 5);
                    {
                        this.state = 276;
                        this.numericExpr0(0);
                        this.state = 277;
                        _la = this._input.LA(1);
                        if (!(_la === QuaiExpressionsV3Parser.T__32 || _la === QuaiExpressionsV3Parser.T__34)) {
                            this._errHandler.recoverInline(this);
                        }
                        else {
                            if (this._input.LA(1) === Token_1.Token.EOF) {
                                this.matchedEOF = true;
                            }
                            this._errHandler.reportMatch(this);
                            this.consume();
                        }
                        this.state = 278;
                        this.numericExpr0(0);
                        this.state = 279;
                        _la = this._input.LA(1);
                        if (!(_la === QuaiExpressionsV3Parser.T__32 || _la === QuaiExpressionsV3Parser.T__34)) {
                            this._errHandler.recoverInline(this);
                        }
                        else {
                            if (this._input.LA(1) === Token_1.Token.EOF) {
                                this.matchedEOF = true;
                            }
                            this._errHandler.reportMatch(this);
                            this.consume();
                        }
                        this.state = 280;
                        this.numericExpr0(0);
                    }
                    break;
                case 6:
                    _localctx = new PassThroughBooleanEqualityContext(_localctx);
                    this.enterOuterAlt(_localctx, 6);
                    {
                        this.state = 282;
                        this.boolExpr3();
                    }
                    break;
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    boolExpr3() {
        let _localctx = new BoolExpr3Context(this._ctx, this.state);
        this.enterRule(_localctx, 46, QuaiExpressionsV3Parser.RULE_boolExpr3);
        try {
            this.state = 294;
            this._errHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this._input, 17, this._ctx)) {
                case 1:
                    _localctx = new NumericEqaulComparisonContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 285;
                        this.numericExpr0(0);
                        this.state = 286;
                        this.match(QuaiExpressionsV3Parser.T__29);
                        this.state = 287;
                        this.numericExpr0(0);
                    }
                    break;
                case 2:
                    _localctx = new AtomicEqualComparisonContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 289;
                        this.stringExpr0();
                        this.state = 290;
                        this.match(QuaiExpressionsV3Parser.T__29);
                        this.state = 291;
                        this.stringExpr0();
                    }
                    break;
                case 3:
                    _localctx = new PassThroughBooleanFactorContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 293;
                        this.boolExpr4();
                    }
                    break;
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    boolExpr4() {
        let _localctx = new BoolExpr4Context(this._ctx, this.state);
        this.enterRule(_localctx, 48, QuaiExpressionsV3Parser.RULE_boolExpr4);
        try {
            this.state = 299;
            this._errHandler.sync(this);
            switch (this._input.LA(1)) {
                case QuaiExpressionsV3Parser.T__36:
                    _localctx = new NotOperationContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 296;
                        this.match(QuaiExpressionsV3Parser.T__36);
                        this.state = 297;
                        this.boolExpr4();
                    }
                    break;
                case QuaiExpressionsV3Parser.T__3:
                case QuaiExpressionsV3Parser.T__7:
                case QuaiExpressionsV3Parser.T__12:
                case QuaiExpressionsV3Parser.T__13:
                case QuaiExpressionsV3Parser.T__14:
                case QuaiExpressionsV3Parser.T__15:
                case QuaiExpressionsV3Parser.T__16:
                case QuaiExpressionsV3Parser.T__17:
                case QuaiExpressionsV3Parser.T__18:
                case QuaiExpressionsV3Parser.T__19:
                case QuaiExpressionsV3Parser.T__20:
                case QuaiExpressionsV3Parser.T__27:
                case QuaiExpressionsV3Parser.BOOL:
                    _localctx = new PassThroughAtomicBooleanContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 298;
                        this.boolExpr5();
                    }
                    break;
                default:
                    throw new NoViableAltException_1.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    boolExpr5() {
        let _localctx = new BoolExpr5Context(this._ctx, this.state);
        this.enterRule(_localctx, 50, QuaiExpressionsV3Parser.RULE_boolExpr5);
        try {
            this.state = 312;
            this._errHandler.sync(this);
            switch (this._input.LA(1)) {
                case QuaiExpressionsV3Parser.BOOL:
                    _localctx = new BooleanLiteralContext(_localctx);
                    this.enterOuterAlt(_localctx, 1);
                    {
                        this.state = 301;
                        this.match(QuaiExpressionsV3Parser.BOOL);
                    }
                    break;
                case QuaiExpressionsV3Parser.T__3:
                case QuaiExpressionsV3Parser.T__12:
                case QuaiExpressionsV3Parser.T__13:
                case QuaiExpressionsV3Parser.T__14:
                case QuaiExpressionsV3Parser.T__15:
                case QuaiExpressionsV3Parser.T__16:
                case QuaiExpressionsV3Parser.T__17:
                case QuaiExpressionsV3Parser.T__18:
                case QuaiExpressionsV3Parser.T__19:
                    _localctx = new ResolvableBooleanContext(_localctx);
                    this.enterOuterAlt(_localctx, 2);
                    {
                        this.state = 302;
                        this.resolvableExpr();
                    }
                    break;
                case QuaiExpressionsV3Parser.T__7:
                    _localctx = new BooleanArrayFunctionsContext(_localctx);
                    this.enterOuterAlt(_localctx, 3);
                    {
                        this.state = 303;
                        this.arrayFunctions();
                    }
                    break;
                case QuaiExpressionsV3Parser.T__20:
                    _localctx = new ParenBooleanOperationContext(_localctx);
                    this.enterOuterAlt(_localctx, 4);
                    {
                        this.state = 304;
                        this.match(QuaiExpressionsV3Parser.T__20);
                        this.state = 305;
                        this.boolExpr0(0);
                        this.state = 306;
                        this.match(QuaiExpressionsV3Parser.T__2);
                    }
                    break;
                case QuaiExpressionsV3Parser.T__27:
                    _localctx = new TermWrapperBooleanContext(_localctx);
                    this.enterOuterAlt(_localctx, 5);
                    {
                        this.state = 308;
                        this.match(QuaiExpressionsV3Parser.T__27);
                        this.state = 309;
                        this.boolExpr0(0);
                        this.state = 310;
                        this.match(QuaiExpressionsV3Parser.T__28);
                    }
                    break;
                default:
                    throw new NoViableAltException_1.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    // @RuleVersion(0)
    primitives() {
        let _localctx = new PrimitivesContext(this._ctx, this.state);
        this.enterRule(_localctx, 52, QuaiExpressionsV3Parser.RULE_primitives);
        let _la;
        try {
            this.enterOuterAlt(_localctx, 1);
            {
                this.state = 314;
                _la = this._input.LA(1);
                if (!(((((_la - 40)) & ~0x1F) === 0 && ((1 << (_la - 40)) & ((1 << (QuaiExpressionsV3Parser.NUMBER - 40)) | (1 << (QuaiExpressionsV3Parser.BOOL - 40)) | (1 << (QuaiExpressionsV3Parser.STRING - 40)))) !== 0))) {
                    this._errHandler.recoverInline(this);
                }
                else {
                    if (this._input.LA(1) === Token_1.Token.EOF) {
                        this.matchedEOF = true;
                    }
                    this._errHandler.reportMatch(this);
                    this.consume();
                }
            }
        }
        catch (re) {
            if (re instanceof RecognitionException_1.RecognitionException) {
                _localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return _localctx;
    }
    sempred(_localctx, ruleIndex, predIndex) {
        switch (ruleIndex) {
            case 16:
                return this.numericExpr0_sempred(_localctx, predIndex);
            case 17:
                return this.numericExpr1_sempred(_localctx, predIndex);
            case 20:
                return this.boolExpr0_sempred(_localctx, predIndex);
            case 21:
                return this.boolExpr1_sempred(_localctx, predIndex);
        }
        return true;
    }
    numericExpr0_sempred(_localctx, predIndex) {
        switch (predIndex) {
            case 0:
                return this.precpred(this._ctx, 3);
            case 1:
                return this.precpred(this._ctx, 2);
        }
        return true;
    }
    numericExpr1_sempred(_localctx, predIndex) {
        switch (predIndex) {
            case 2:
                return this.precpred(this._ctx, 4);
            case 3:
                return this.precpred(this._ctx, 3);
            case 4:
                return this.precpred(this._ctx, 2);
        }
        return true;
    }
    boolExpr0_sempred(_localctx, predIndex) {
        switch (predIndex) {
            case 5:
                return this.precpred(this._ctx, 3);
            case 6:
                return this.precpred(this._ctx, 2);
        }
        return true;
    }
    boolExpr1_sempred(_localctx, predIndex) {
        switch (predIndex) {
            case 7:
                return this.precpred(this._ctx, 2);
        }
        return true;
    }
    static get _ATN() {
        if (!QuaiExpressionsV3Parser.__ATN) {
            QuaiExpressionsV3Parser.__ATN = new ATNDeserializer_1.ATNDeserializer().deserialize(Utils.toCharArray(QuaiExpressionsV3Parser._serializedATN));
        }
        return QuaiExpressionsV3Parser.__ATN;
    }
}
QuaiExpressionsV3Parser.T__0 = 1;
QuaiExpressionsV3Parser.T__1 = 2;
QuaiExpressionsV3Parser.T__2 = 3;
QuaiExpressionsV3Parser.T__3 = 4;
QuaiExpressionsV3Parser.T__4 = 5;
QuaiExpressionsV3Parser.T__5 = 6;
QuaiExpressionsV3Parser.T__6 = 7;
QuaiExpressionsV3Parser.T__7 = 8;
QuaiExpressionsV3Parser.T__8 = 9;
QuaiExpressionsV3Parser.T__9 = 10;
QuaiExpressionsV3Parser.T__10 = 11;
QuaiExpressionsV3Parser.T__11 = 12;
QuaiExpressionsV3Parser.T__12 = 13;
QuaiExpressionsV3Parser.T__13 = 14;
QuaiExpressionsV3Parser.T__14 = 15;
QuaiExpressionsV3Parser.T__15 = 16;
QuaiExpressionsV3Parser.T__16 = 17;
QuaiExpressionsV3Parser.T__17 = 18;
QuaiExpressionsV3Parser.T__18 = 19;
QuaiExpressionsV3Parser.T__19 = 20;
QuaiExpressionsV3Parser.T__20 = 21;
QuaiExpressionsV3Parser.T__21 = 22;
QuaiExpressionsV3Parser.T__22 = 23;
QuaiExpressionsV3Parser.T__23 = 24;
QuaiExpressionsV3Parser.T__24 = 25;
QuaiExpressionsV3Parser.T__25 = 26;
QuaiExpressionsV3Parser.T__26 = 27;
QuaiExpressionsV3Parser.T__27 = 28;
QuaiExpressionsV3Parser.T__28 = 29;
QuaiExpressionsV3Parser.T__29 = 30;
QuaiExpressionsV3Parser.T__30 = 31;
QuaiExpressionsV3Parser.T__31 = 32;
QuaiExpressionsV3Parser.T__32 = 33;
QuaiExpressionsV3Parser.T__33 = 34;
QuaiExpressionsV3Parser.T__34 = 35;
QuaiExpressionsV3Parser.T__35 = 36;
QuaiExpressionsV3Parser.T__36 = 37;
QuaiExpressionsV3Parser.MIN = 38;
QuaiExpressionsV3Parser.MAX = 39;
QuaiExpressionsV3Parser.NUMBER = 40;
QuaiExpressionsV3Parser.UNIQUE_ID = 41;
QuaiExpressionsV3Parser.BOOL = 42;
QuaiExpressionsV3Parser.STRING = 43;
QuaiExpressionsV3Parser.PROPERTY = 44;
QuaiExpressionsV3Parser.WS = 45;
QuaiExpressionsV3Parser.RULE_expression = 0;
QuaiExpressionsV3Parser.RULE_optionalTerm = 1;
QuaiExpressionsV3Parser.RULE_expressionResolver = 2;
QuaiExpressionsV3Parser.RULE_arrayFunctions = 3;
QuaiExpressionsV3Parser.RULE_arrayLiteral0 = 4;
QuaiExpressionsV3Parser.RULE_conditionalExpr0 = 5;
QuaiExpressionsV3Parser.RULE_resolvableExpr = 6;
QuaiExpressionsV3Parser.RULE_resolveVar = 7;
QuaiExpressionsV3Parser.RULE_resolveEnum = 8;
QuaiExpressionsV3Parser.RULE_resolveRange = 9;
QuaiExpressionsV3Parser.RULE_resolveAttr = 10;
QuaiExpressionsV3Parser.RULE_resolvePrecipitating = 11;
QuaiExpressionsV3Parser.RULE_resolveRelieving = 12;
QuaiExpressionsV3Parser.RULE_resolveYes = 13;
QuaiExpressionsV3Parser.RULE_resolveNo = 14;
QuaiExpressionsV3Parser.RULE_stringExpr0 = 15;
QuaiExpressionsV3Parser.RULE_numericExpr0 = 16;
QuaiExpressionsV3Parser.RULE_numericExpr1 = 17;
QuaiExpressionsV3Parser.RULE_numericExpr2 = 18;
QuaiExpressionsV3Parser.RULE_numericExpr3 = 19;
QuaiExpressionsV3Parser.RULE_boolExpr0 = 20;
QuaiExpressionsV3Parser.RULE_boolExpr1 = 21;
QuaiExpressionsV3Parser.RULE_boolExpr2 = 22;
QuaiExpressionsV3Parser.RULE_boolExpr3 = 23;
QuaiExpressionsV3Parser.RULE_boolExpr4 = 24;
QuaiExpressionsV3Parser.RULE_boolExpr5 = 25;
QuaiExpressionsV3Parser.RULE_primitives = 26;
// tslint:disable:no-trailing-whitespace
QuaiExpressionsV3Parser.ruleNames = [
    "expression", "optionalTerm", "expressionResolver", "arrayFunctions",
    "arrayLiteral0", "conditionalExpr0", "resolvableExpr", "resolveVar", "resolveEnum",
    "resolveRange", "resolveAttr", "resolvePrecipitating", "resolveRelieving",
    "resolveYes", "resolveNo", "stringExpr0", "numericExpr0", "numericExpr1",
    "numericExpr2", "numericExpr3", "boolExpr0", "boolExpr1", "boolExpr2",
    "boolExpr3", "boolExpr4", "boolExpr5", "primitives",
];
QuaiExpressionsV3Parser._LITERAL_NAMES = [
    undefined, "'?('", "','", "')'", "'expr('", "'.includes('", "'.every('",
    "' .atLeast('", "'['", "']'", "'?'", "':'", "'.'", "'var('", "'enum('",
    "'range('", "'attr('", "'precipitating('", "'relieving('", "'yes'", "'no'",
    "'('", "'+'", "'-'", "'*'", "'/'", "'%'", "'^'", "'{'", "'}'", "'==='",
    "'||'", "'&&'", "'<'", "'>'", "'<='", "'>='", "'!'", "'min'", "'max'",
];
QuaiExpressionsV3Parser._SYMBOLIC_NAMES = [
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, "MIN", "MAX", "NUMBER", "UNIQUE_ID",
    "BOOL", "STRING", "PROPERTY", "WS",
];
QuaiExpressionsV3Parser.VOCABULARY = new VocabularyImpl_1.VocabularyImpl(QuaiExpressionsV3Parser._LITERAL_NAMES, QuaiExpressionsV3Parser._SYMBOLIC_NAMES, []);
QuaiExpressionsV3Parser._serializedATN = "\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03/\u013F\x04\x02" +
    "\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
    "\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
    "\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04" +
    "\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x04" +
    "\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C\x03" +
    "\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x05\x02A" +
    "\n\x02\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x04\x03\x04" +
    "\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
    "\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
    "\x03\x05\x05\x05^\n\x05\x03\x06\x03\x06\x03\x06\x03\x06\x07\x06d\n\x06" +
    "\f\x06\x0E\x06g\v\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03\x07" +
    "\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\b\x07\bu\n\b\f\b\x0E\bx\v\b\x03" +
    "\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03" +
    "\b\x03\b\x05\b\x88\n\b\x05\b\x8A\n\b\x03\t\x03\t\x03\t\x03\t\x03\n\x03" +
    "\n\x03\n\x03\n\x03\v\x03\v\x03\v\x03\v\x03\f\x03\f\x03\f\x03\f\x03\f\x03" +
    "\f\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0E\x03\x0E" +
    "\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x11\x03\x11\x03\x11" +
    "\x03\x11\x03\x11\x03\x11\x05\x11\xB4\n\x11\x03\x12\x03\x12\x03\x12\x03" +
    "\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x07\x12\xBF\n\x12\f\x12\x0E" +
    "\x12\xC2\v\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13" +
    "\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x07\x13\xD0\n\x13\f\x13\x0E\x13" +
    "\xD3\v\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x05" +
    "\x14\xDC\n\x14\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15" +
    "\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x05\x15\xEC\n" +
    "\x15\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03" +
    "\x16\x07\x16\xF7\n\x16\f\x16\x0E\x16\xFA\v\x16\x03\x17\x03\x17\x03\x17" +
    "\x03\x17\x03\x17\x03\x17\x07\x17\u0102\n\x17\f\x17\x0E\x17\u0105\v\x17" +
    "\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18" +
    "\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18" +
    "\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x05\x18\u011E\n\x18\x03\x19\x03" +
    "\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x05\x19\u0129" +
    "\n\x19\x03\x1A\x03\x1A\x03\x1A\x05\x1A\u012E\n\x1A\x03\x1B\x03\x1B\x03" +
    "\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x05" +
    "\x1B\u013B\n\x1B\x03\x1C\x03\x1C\x03\x1C\x02\x02\x06\"$*,\x1D\x02\x02" +
    "\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x12\x02\x14\x02\x16" +
    "\x02\x18\x02\x1A\x02\x1C\x02\x1E\x02 \x02\"\x02$\x02&\x02(\x02*\x02,\x02" +
    ".\x020\x022\x024\x026\x02\x02\x04\x04\x02##%%\x04\x02**,-\x02\u0153\x02" +
    "@\x03\x02\x02\x02\x04B\x03\x02\x02\x02\x06H\x03\x02\x02\x02\b]\x03\x02" +
    "\x02\x02\n_\x03\x02\x02\x02\fj\x03\x02\x02\x02\x0E\x89\x03\x02\x02\x02" +
    "\x10\x8B\x03\x02\x02\x02\x12\x8F\x03\x02\x02\x02\x14\x93\x03\x02\x02\x02" +
    "\x16\x97\x03\x02\x02\x02\x18\x9D\x03\x02\x02\x02\x1A\xA3\x03\x02\x02\x02" +
    "\x1C\xA9\x03\x02\x02\x02\x1E\xAB\x03\x02\x02\x02 \xB3\x03\x02\x02\x02" +
    "\"\xB5\x03\x02\x02\x02$\xC3\x03\x02\x02\x02&\xDB\x03\x02\x02\x02(\xEB" +
    "\x03\x02\x02\x02*\xED\x03\x02\x02\x02,\xFB\x03\x02\x02\x02.\u011D\x03" +
    "\x02\x02\x020\u0128\x03\x02\x02\x022\u012D\x03\x02\x02\x024\u013A\x03" +
    "\x02\x02\x026\u013C\x03\x02\x02\x028A\x05\x0E\b\x029A\x05\b\x05\x02:A" +
    "\x05\n\x06\x02;A\x05*\x16\x02<A\x05\"\x12\x02=A\x05 \x11\x02>A\x05\f\x07" +
    "\x02?A\x05\x04\x03\x02@8\x03\x02\x02\x02@9\x03\x02\x02\x02@:\x03\x02\x02" +
    "\x02@;\x03\x02\x02\x02@<\x03\x02\x02\x02@=\x03\x02\x02\x02@>\x03\x02\x02" +
    "\x02@?\x03\x02\x02\x02A\x03\x03\x02\x02\x02BC\x07\x03\x02\x02CD\x05\x02" +
    "\x02\x02DE\x07\x04\x02\x02EF\x056\x1C\x02FG\x07\x05\x02\x02G\x05\x03\x02" +
    "\x02\x02HI\x07\x06\x02\x02IJ\x07+\x02\x02JK\x07\x05\x02\x02K\x07\x03\x02" +
    "\x02\x02LM\x05\n\x06\x02MN\x07\x07\x02\x02NO\x05\x02\x02\x02OP\x07\x05" +
    "\x02\x02P^\x03\x02\x02\x02QR\x05\n\x06\x02RS\x07\b\x02\x02ST\x05\x02\x02" +
    "\x02TU\x07\x05\x02\x02U^\x03\x02\x02\x02VW\x05\n\x06\x02WX\x07\t\x02\x02" +
    "XY\x05\x02\x02\x02YZ\x07\x04\x02\x02Z[\x07*\x02\x02[\\\x07\x05\x02\x02" +
    "\\^\x03\x02\x02\x02]L\x03\x02\x02\x02]Q\x03\x02\x02\x02]V\x03\x02\x02" +
    "\x02^\t\x03\x02\x02\x02_`\x07\n\x02\x02`e\x05\x02\x02\x02ab\x07\x04\x02" +
    "\x02bd\x05\x02\x02\x02ca\x03\x02\x02\x02dg\x03\x02\x02\x02ec\x03\x02\x02" +
    "\x02ef\x03\x02\x02\x02fh\x03\x02\x02\x02ge\x03\x02\x02\x02hi\x07\v\x02" +
    "\x02i\v\x03\x02\x02\x02jk\x05*\x16\x02kl\x07\f\x02\x02lm\x05\x02\x02\x02" +
    "mn\x07\r\x02\x02no\x05\x02\x02\x02o\r\x03\x02\x02\x02p\x8A\x05\x06\x04" +
    "\x02qv\x05\x10\t\x02rs\x07\x0E\x02\x02su\x07.\x02\x02tr\x03\x02\x02\x02" +
    "ux\x03\x02\x02\x02vt\x03\x02\x02\x02vw\x03\x02\x02\x02w\x8A\x03\x02\x02" +
    "\x02xv\x03\x02\x02\x02yz\x05\x14\v\x02z{\x07\x0E\x02\x02{|\x07(\x02\x02" +
    "|\x8A\x03\x02\x02\x02}~\x05\x14\v\x02~\x7F\x07\x0E\x02\x02\x7F\x80\x07" +
    ")\x02\x02\x80\x8A\x03\x02\x02\x02\x81\x8A\x05\x12\n\x02\x82\x8A\x05\x16" +
    "\f\x02\x83\x8A\x05\x18\r\x02\x84\x8A\x05\x1A\x0E\x02\x85\x88\x05\x1C\x0F" +
    "\x02\x86\x88\x05\x1E\x10\x02\x87\x85\x03\x02\x02\x02\x87\x86\x03\x02\x02" +
    "\x02\x88\x8A\x03\x02\x02\x02\x89p\x03\x02\x02\x02\x89q\x03\x02\x02\x02" +
    "\x89y\x03\x02\x02\x02\x89}\x03\x02\x02\x02\x89\x81\x03\x02\x02\x02\x89" +
    "\x82\x03\x02\x02\x02\x89\x83\x03\x02\x02\x02\x89\x84\x03\x02\x02\x02\x89" +
    "\x87\x03\x02\x02\x02\x8A\x0F\x03\x02\x02\x02\x8B\x8C\x07\x0F\x02\x02\x8C" +
    "\x8D\x07+\x02\x02\x8D\x8E\x07\x05\x02\x02\x8E\x11\x03\x02\x02\x02\x8F" +
    "\x90\x07\x10\x02\x02\x90\x91\x07+\x02\x02\x91\x92\x07\x05\x02\x02\x92" +
    "\x13\x03\x02\x02\x02\x93\x94\x07\x11\x02\x02\x94\x95\x07+\x02\x02\x95" +
    "\x96\x07\x05\x02\x02\x96\x15\x03\x02\x02\x02\x97\x98\x07\x12\x02\x02\x98" +
    "\x99\x07+\x02\x02\x99\x9A\x07\x04\x02\x02\x9A\x9B\x07+\x02\x02\x9B\x9C" +
    "\x07\x05\x02\x02\x9C\x17\x03\x02\x02\x02\x9D\x9E\x07\x13\x02\x02\x9E\x9F" +
    "\x07+\x02\x02\x9F\xA0\x07\x04\x02\x02\xA0\xA1\x07+\x02\x02\xA1\xA2\x07" +
    "\x05\x02\x02\xA2\x19\x03\x02\x02\x02\xA3\xA4\x07\x14\x02\x02\xA4\xA5\x07" +
    "+\x02\x02\xA5\xA6\x07\x04\x02\x02\xA6\xA7\x07+\x02\x02\xA7\xA8\x07\x05" +
    "\x02\x02\xA8\x1B\x03\x02\x02\x02\xA9\xAA\x07\x15\x02\x02\xAA\x1D\x03\x02" +
    "\x02\x02\xAB\xAC\x07\x16\x02\x02\xAC\x1F\x03\x02\x02\x02\xAD\xB4\x07-" +
    "\x02\x02\xAE\xB4\x05\x0E\b\x02\xAF\xB0\x07\x17\x02\x02\xB0\xB1\x05\x02" +
    "\x02\x02\xB1\xB2\x07\x05\x02\x02\xB2\xB4\x03\x02\x02\x02\xB3\xAD\x03\x02" +
    "\x02\x02\xB3\xAE\x03\x02\x02\x02\xB3\xAF\x03\x02\x02\x02\xB4!\x03\x02" +
    "\x02\x02\xB5\xB6\b\x12\x01\x02\xB6\xB7\x05$\x13\x02\xB7\xC0\x03\x02\x02" +
    "\x02\xB8\xB9\f\x05\x02\x02\xB9\xBA\x07\x18\x02\x02\xBA\xBF\x05$\x13\x02" +
    "\xBB\xBC\f\x04\x02\x02\xBC\xBD\x07\x19\x02\x02\xBD\xBF\x05$\x13\x02\xBE" +
    "\xB8\x03\x02\x02\x02\xBE\xBB\x03\x02\x02\x02\xBF\xC2\x03\x02\x02\x02\xC0" +
    "\xBE\x03\x02\x02\x02\xC0\xC1\x03\x02\x02\x02\xC1#\x03\x02\x02\x02\xC2" +
    "\xC0\x03\x02\x02\x02\xC3\xC4\b\x13\x01\x02\xC4\xC5\x05&\x14\x02\xC5\xD1" +
    "\x03\x02\x02\x02\xC6\xC7\f\x06\x02\x02\xC7\xC8\x07\x1A\x02\x02\xC8\xD0" +
    "\x05&\x14\x02\xC9\xCA\f\x05\x02\x02\xCA\xCB\x07\x1B\x02\x02\xCB\xD0\x05" +
    "&\x14\x02\xCC\xCD\f\x04\x02\x02\xCD\xCE\x07\x1C\x02\x02\xCE\xD0\x05&\x14" +
    "\x02\xCF\xC6\x03\x02\x02\x02\xCF\xC9\x03\x02\x02\x02\xCF\xCC\x03\x02\x02" +
    "\x02\xD0\xD3\x03\x02\x02\x02\xD1\xCF\x03\x02\x02\x02\xD1\xD2\x03\x02\x02" +
    "\x02\xD2%\x03\x02\x02\x02\xD3\xD1\x03\x02\x02\x02\xD4\xD5\x07\x19\x02" +
    "\x02\xD5\xDC\x05(\x15\x02\xD6\xD7\x05(\x15\x02\xD7\xD8\x07\x1D\x02\x02" +
    "\xD8\xD9\x05&\x14\x02\xD9\xDC\x03\x02\x02\x02\xDA\xDC\x05(\x15\x02\xDB" +
    "\xD4\x03\x02\x02\x02\xDB\xD6\x03\x02\x02\x02\xDB\xDA\x03\x02\x02\x02\xDC" +
    "\'\x03\x02\x02\x02\xDD\xEC\x07*\x02\x02\xDE\xEC\x05\x0E\b\x02\xDF\xE0" +
    "\x07\x17\x02\x02\xE0\xE1\x05\"\x12\x02\xE1\xE2\x07\x05\x02\x02\xE2\xEC" +
    "\x03\x02\x02\x02\xE3\xE4\x07\x17\x02\x02\xE4\xE5\x05\f\x07\x02\xE5\xE6" +
    "\x07\x05\x02\x02\xE6\xEC\x03\x02\x02\x02\xE7\xE8\x07\x1E\x02\x02\xE8\xE9" +
    "\x05\"\x12\x02\xE9\xEA\x07\x1F\x02\x02\xEA\xEC\x03\x02\x02\x02\xEB\xDD" +
    "\x03\x02\x02\x02\xEB\xDE\x03\x02\x02\x02\xEB\xDF\x03\x02\x02\x02\xEB\xE3" +
    "\x03\x02\x02\x02\xEB\xE7\x03\x02\x02\x02\xEC)\x03\x02\x02\x02\xED\xEE" +
    "\b\x16\x01\x02\xEE\xEF\x05,\x17\x02\xEF\xF8\x03\x02\x02\x02\xF0\xF1\f" +
    "\x05\x02\x02\xF1\xF2\x07 \x02\x02\xF2\xF7\x05,\x17\x02\xF3\xF4\f\x04\x02" +
    "\x02\xF4\xF5\x07!\x02\x02\xF5\xF7\x05,\x17\x02\xF6\xF0\x03\x02\x02\x02" +
    "\xF6\xF3\x03\x02\x02\x02\xF7\xFA\x03\x02\x02\x02\xF8\xF6\x03\x02\x02\x02" +
    "\xF8\xF9\x03\x02\x02\x02\xF9+\x03\x02\x02\x02\xFA\xF8\x03\x02\x02\x02" +
    "\xFB\xFC\b\x17\x01\x02\xFC\xFD\x05.\x18\x02\xFD\u0103\x03\x02\x02\x02" +
    "\xFE\xFF\f\x04\x02\x02\xFF\u0100\x07\"\x02\x02\u0100\u0102\x05.\x18\x02" +
    "\u0101\xFE\x03\x02\x02\x02\u0102\u0105\x03\x02\x02\x02\u0103\u0101\x03" +
    "\x02\x02\x02\u0103\u0104\x03\x02\x02\x02\u0104-\x03\x02\x02\x02\u0105" +
    "\u0103\x03\x02\x02\x02\u0106\u0107\x05\"\x12\x02\u0107\u0108\x07#\x02" +
    "\x02\u0108\u0109\x05\"\x12\x02\u0109\u011E\x03\x02\x02\x02\u010A\u010B" +
    "\x05\"\x12\x02\u010B\u010C\x07$\x02\x02\u010C\u010D\x05\"\x12\x02\u010D" +
    "\u011E\x03\x02\x02\x02\u010E\u010F\x05\"\x12\x02\u010F\u0110\x07%\x02" +
    "\x02\u0110\u0111\x05\"\x12\x02\u0111\u011E\x03\x02\x02\x02\u0112\u0113" +
    "\x05\"\x12\x02\u0113\u0114\x07&\x02\x02\u0114\u0115\x05\"\x12\x02\u0115" +
    "\u011E\x03\x02\x02\x02\u0116\u0117\x05\"\x12\x02\u0117\u0118\t\x02\x02" +
    "\x02\u0118\u0119\x05\"\x12\x02\u0119\u011A\t\x02\x02\x02\u011A\u011B\x05" +
    "\"\x12\x02\u011B\u011E\x03\x02\x02\x02\u011C\u011E\x050\x19\x02\u011D" +
    "\u0106\x03\x02\x02\x02\u011D\u010A\x03\x02\x02\x02\u011D\u010E\x03\x02" +
    "\x02\x02\u011D\u0112\x03\x02\x02\x02\u011D\u0116\x03\x02\x02\x02\u011D" +
    "\u011C\x03\x02\x02\x02\u011E/\x03\x02\x02\x02\u011F\u0120\x05\"\x12\x02" +
    "\u0120\u0121\x07 \x02\x02\u0121\u0122\x05\"\x12\x02\u0122\u0129\x03\x02" +
    "\x02\x02\u0123\u0124\x05 \x11\x02\u0124\u0125\x07 \x02\x02\u0125\u0126" +
    "\x05 \x11\x02\u0126\u0129\x03\x02\x02\x02\u0127\u0129\x052\x1A\x02\u0128" +
    "\u011F\x03\x02\x02\x02\u0128\u0123\x03\x02\x02\x02\u0128\u0127\x03\x02" +
    "\x02\x02\u01291\x03\x02\x02\x02\u012A\u012B\x07\'\x02\x02\u012B\u012E" +
    "\x052\x1A\x02\u012C\u012E\x054\x1B\x02\u012D\u012A\x03\x02\x02\x02\u012D" +
    "\u012C\x03\x02\x02\x02\u012E3\x03\x02\x02\x02\u012F\u013B\x07,\x02\x02" +
    "\u0130\u013B\x05\x0E\b\x02\u0131\u013B\x05\b\x05\x02\u0132\u0133\x07\x17" +
    "\x02\x02\u0133\u0134\x05*\x16\x02\u0134\u0135\x07\x05\x02\x02\u0135\u013B" +
    "\x03\x02\x02\x02\u0136\u0137\x07\x1E\x02\x02\u0137\u0138\x05*\x16\x02" +
    "\u0138\u0139\x07\x1F\x02\x02\u0139\u013B\x03\x02\x02\x02\u013A\u012F\x03" +
    "\x02\x02\x02\u013A\u0130\x03\x02\x02\x02\u013A\u0131\x03\x02\x02\x02\u013A" +
    "\u0132\x03\x02\x02\x02\u013A\u0136\x03\x02\x02\x02\u013B5\x03\x02\x02" +
    "\x02\u013C\u013D\t\x03\x02\x02\u013D7\x03\x02\x02\x02\x16@]ev\x87\x89" +
    "\xB3\xBE\xC0\xCF\xD1\xDB\xEB\xF6\xF8\u0103\u011D\u0128\u012D\u013A";
exports.QuaiExpressionsV3Parser = QuaiExpressionsV3Parser;
class ExpressionContext extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_expression; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.ExpressionContext = ExpressionContext;
class ResolvablesExprContext extends ExpressionContext {
    resolvableExpr() {
        return this.getRuleContext(0, ResolvableExprContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolvablesExpr) {
            listener.enterResolvablesExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolvablesExpr) {
            listener.exitResolvablesExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolvablesExpr) {
            return visitor.visitResolvablesExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolvablesExprContext = ResolvablesExprContext;
class ArrayFuncExprContext extends ExpressionContext {
    arrayFunctions() {
        return this.getRuleContext(0, ArrayFunctionsContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterArrayFuncExpr) {
            listener.enterArrayFuncExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitArrayFuncExpr) {
            listener.exitArrayFuncExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitArrayFuncExpr) {
            return visitor.visitArrayFuncExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ArrayFuncExprContext = ArrayFuncExprContext;
class ArrayExprContext extends ExpressionContext {
    arrayLiteral0() {
        return this.getRuleContext(0, ArrayLiteral0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterArrayExpr) {
            listener.enterArrayExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitArrayExpr) {
            listener.exitArrayExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitArrayExpr) {
            return visitor.visitArrayExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ArrayExprContext = ArrayExprContext;
class BooleanOperationExprContext extends ExpressionContext {
    boolExpr0() {
        return this.getRuleContext(0, BoolExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterBooleanOperationExpr) {
            listener.enterBooleanOperationExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitBooleanOperationExpr) {
            listener.exitBooleanOperationExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitBooleanOperationExpr) {
            return visitor.visitBooleanOperationExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.BooleanOperationExprContext = BooleanOperationExprContext;
class ArithmeticOperationExprContext extends ExpressionContext {
    numericExpr0() {
        return this.getRuleContext(0, NumericExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterArithmeticOperationExpr) {
            listener.enterArithmeticOperationExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitArithmeticOperationExpr) {
            listener.exitArithmeticOperationExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitArithmeticOperationExpr) {
            return visitor.visitArithmeticOperationExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ArithmeticOperationExprContext = ArithmeticOperationExprContext;
class AtomicExprContext extends ExpressionContext {
    stringExpr0() {
        return this.getRuleContext(0, StringExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterAtomicExpr) {
            listener.enterAtomicExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitAtomicExpr) {
            listener.exitAtomicExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitAtomicExpr) {
            return visitor.visitAtomicExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.AtomicExprContext = AtomicExprContext;
class ConditionExprContext extends ExpressionContext {
    conditionalExpr0() {
        return this.getRuleContext(0, ConditionalExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterConditionExpr) {
            listener.enterConditionExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitConditionExpr) {
            listener.exitConditionExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitConditionExpr) {
            return visitor.visitConditionExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ConditionExprContext = ConditionExprContext;
class OptionalTermExprContext extends ExpressionContext {
    optionalTerm() {
        return this.getRuleContext(0, OptionalTermContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterOptionalTermExpr) {
            listener.enterOptionalTermExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitOptionalTermExpr) {
            listener.exitOptionalTermExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitOptionalTermExpr) {
            return visitor.visitOptionalTermExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.OptionalTermExprContext = OptionalTermExprContext;
class OptionalTermContext extends ParserRuleContext_1.ParserRuleContext {
    expression() {
        return this.getRuleContext(0, ExpressionContext);
    }
    primitives() {
        return this.getRuleContext(0, PrimitivesContext);
    }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_optionalTerm; }
    // @Override
    enterRule(listener) {
        if (listener.enterOptionalTerm) {
            listener.enterOptionalTerm(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitOptionalTerm) {
            listener.exitOptionalTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitOptionalTerm) {
            return visitor.visitOptionalTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.OptionalTermContext = OptionalTermContext;
class ExpressionResolverContext extends ParserRuleContext_1.ParserRuleContext {
    UNIQUE_ID() { return this.getToken(QuaiExpressionsV3Parser.UNIQUE_ID, 0); }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_expressionResolver; }
    // @Override
    enterRule(listener) {
        if (listener.enterExpressionResolver) {
            listener.enterExpressionResolver(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitExpressionResolver) {
            listener.exitExpressionResolver(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitExpressionResolver) {
            return visitor.visitExpressionResolver(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ExpressionResolverContext = ExpressionResolverContext;
class ArrayFunctionsContext extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_arrayFunctions; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.ArrayFunctionsContext = ArrayFunctionsContext;
class ArrayIncludesContext extends ArrayFunctionsContext {
    arrayLiteral0() {
        return this.getRuleContext(0, ArrayLiteral0Context);
    }
    expression() {
        return this.getRuleContext(0, ExpressionContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterArrayIncludes) {
            listener.enterArrayIncludes(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitArrayIncludes) {
            listener.exitArrayIncludes(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitArrayIncludes) {
            return visitor.visitArrayIncludes(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ArrayIncludesContext = ArrayIncludesContext;
class ArrayEveryContext extends ArrayFunctionsContext {
    arrayLiteral0() {
        return this.getRuleContext(0, ArrayLiteral0Context);
    }
    expression() {
        return this.getRuleContext(0, ExpressionContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterArrayEvery) {
            listener.enterArrayEvery(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitArrayEvery) {
            listener.exitArrayEvery(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitArrayEvery) {
            return visitor.visitArrayEvery(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ArrayEveryContext = ArrayEveryContext;
class ArrayAtLeastContext extends ArrayFunctionsContext {
    arrayLiteral0() {
        return this.getRuleContext(0, ArrayLiteral0Context);
    }
    expression() {
        return this.getRuleContext(0, ExpressionContext);
    }
    NUMBER() { return this.getToken(QuaiExpressionsV3Parser.NUMBER, 0); }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterArrayAtLeast) {
            listener.enterArrayAtLeast(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitArrayAtLeast) {
            listener.exitArrayAtLeast(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitArrayAtLeast) {
            return visitor.visitArrayAtLeast(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ArrayAtLeastContext = ArrayAtLeastContext;
class ArrayLiteral0Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_arrayLiteral0; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.ArrayLiteral0Context = ArrayLiteral0Context;
class ArrayLiteralContext extends ArrayLiteral0Context {
    expression(i) {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }
        else {
            return this.getRuleContext(i, ExpressionContext);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterArrayLiteral) {
            listener.enterArrayLiteral(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitArrayLiteral) {
            listener.exitArrayLiteral(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitArrayLiteral) {
            return visitor.visitArrayLiteral(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ArrayLiteralContext = ArrayLiteralContext;
class ConditionalExpr0Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_conditionalExpr0; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.ConditionalExpr0Context = ConditionalExpr0Context;
class GeneralConditionContext extends ConditionalExpr0Context {
    boolExpr0() {
        return this.getRuleContext(0, BoolExpr0Context);
    }
    expression(i) {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }
        else {
            return this.getRuleContext(i, ExpressionContext);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterGeneralCondition) {
            listener.enterGeneralCondition(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitGeneralCondition) {
            listener.exitGeneralCondition(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitGeneralCondition) {
            return visitor.visitGeneralCondition(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.GeneralConditionContext = GeneralConditionContext;
class ResolvableExprContext extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolvableExpr; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.ResolvableExprContext = ResolvableExprContext;
class ResolveDbExpressionContext extends ResolvableExprContext {
    expressionResolver() {
        return this.getRuleContext(0, ExpressionResolverContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveDbExpression) {
            listener.enterResolveDbExpression(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveDbExpression) {
            listener.exitResolveDbExpression(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveDbExpression) {
            return visitor.visitResolveDbExpression(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveDbExpressionContext = ResolveDbExpressionContext;
class ResolvePropContext extends ResolvableExprContext {
    resolveVar() {
        return this.getRuleContext(0, ResolveVarContext);
    }
    PROPERTY(i) {
        if (i === undefined) {
            return this.getTokens(QuaiExpressionsV3Parser.PROPERTY);
        }
        else {
            return this.getToken(QuaiExpressionsV3Parser.PROPERTY, i);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveProp) {
            listener.enterResolveProp(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveProp) {
            listener.exitResolveProp(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveProp) {
            return visitor.visitResolveProp(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolvePropContext = ResolvePropContext;
class ResolveRangeMinValueContext extends ResolvableExprContext {
    resolveRange() {
        return this.getRuleContext(0, ResolveRangeContext);
    }
    MIN() { return this.tryGetToken(QuaiExpressionsV3Parser.MIN, 0); }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveRangeMinValue) {
            listener.enterResolveRangeMinValue(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveRangeMinValue) {
            listener.exitResolveRangeMinValue(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveRangeMinValue) {
            return visitor.visitResolveRangeMinValue(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveRangeMinValueContext = ResolveRangeMinValueContext;
class ResolveRangeMaxValueContext extends ResolvableExprContext {
    resolveRange() {
        return this.getRuleContext(0, ResolveRangeContext);
    }
    MAX() { return this.tryGetToken(QuaiExpressionsV3Parser.MAX, 0); }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveRangeMaxValue) {
            listener.enterResolveRangeMaxValue(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveRangeMaxValue) {
            listener.exitResolveRangeMaxValue(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveRangeMaxValue) {
            return visitor.visitResolveRangeMaxValue(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveRangeMaxValueContext = ResolveRangeMaxValueContext;
class ResolveEnumeratedContext extends ResolvableExprContext {
    resolveEnum() {
        return this.getRuleContext(0, ResolveEnumContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveEnumerated) {
            listener.enterResolveEnumerated(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveEnumerated) {
            listener.exitResolveEnumerated(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveEnumerated) {
            return visitor.visitResolveEnumerated(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveEnumeratedContext = ResolveEnumeratedContext;
class ResolveAttributeContext extends ResolvableExprContext {
    resolveAttr() {
        return this.getRuleContext(0, ResolveAttrContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveAttribute) {
            listener.enterResolveAttribute(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveAttribute) {
            listener.exitResolveAttribute(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveAttribute) {
            return visitor.visitResolveAttribute(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveAttributeContext = ResolveAttributeContext;
class ResolvePrecipitatingFactorContext extends ResolvableExprContext {
    resolvePrecipitating() {
        return this.getRuleContext(0, ResolvePrecipitatingContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolvePrecipitatingFactor) {
            listener.enterResolvePrecipitatingFactor(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolvePrecipitatingFactor) {
            listener.exitResolvePrecipitatingFactor(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolvePrecipitatingFactor) {
            return visitor.visitResolvePrecipitatingFactor(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolvePrecipitatingFactorContext = ResolvePrecipitatingFactorContext;
class ResolveReilivingFactorContext extends ResolvableExprContext {
    resolveRelieving() {
        return this.getRuleContext(0, ResolveRelievingContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveReilivingFactor) {
            listener.enterResolveReilivingFactor(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveReilivingFactor) {
            listener.exitResolveReilivingFactor(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveReilivingFactor) {
            return visitor.visitResolveReilivingFactor(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveReilivingFactorContext = ResolveReilivingFactorContext;
class ResolveYesNoContext extends ResolvableExprContext {
    resolveYes() {
        return this.tryGetRuleContext(0, ResolveYesContext);
    }
    resolveNo() {
        return this.tryGetRuleContext(0, ResolveNoContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveYesNo) {
            listener.enterResolveYesNo(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveYesNo) {
            listener.exitResolveYesNo(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveYesNo) {
            return visitor.visitResolveYesNo(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveYesNoContext = ResolveYesNoContext;
class ResolveVarContext extends ParserRuleContext_1.ParserRuleContext {
    UNIQUE_ID() { return this.getToken(QuaiExpressionsV3Parser.UNIQUE_ID, 0); }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolveVar; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveVar) {
            listener.enterResolveVar(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveVar) {
            listener.exitResolveVar(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveVar) {
            return visitor.visitResolveVar(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveVarContext = ResolveVarContext;
class ResolveEnumContext extends ParserRuleContext_1.ParserRuleContext {
    UNIQUE_ID() { return this.getToken(QuaiExpressionsV3Parser.UNIQUE_ID, 0); }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolveEnum; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveEnum) {
            listener.enterResolveEnum(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveEnum) {
            listener.exitResolveEnum(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveEnum) {
            return visitor.visitResolveEnum(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveEnumContext = ResolveEnumContext;
class ResolveRangeContext extends ParserRuleContext_1.ParserRuleContext {
    UNIQUE_ID() { return this.getToken(QuaiExpressionsV3Parser.UNIQUE_ID, 0); }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolveRange; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveRange) {
            listener.enterResolveRange(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveRange) {
            listener.exitResolveRange(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveRange) {
            return visitor.visitResolveRange(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveRangeContext = ResolveRangeContext;
class ResolveAttrContext extends ParserRuleContext_1.ParserRuleContext {
    UNIQUE_ID(i) {
        if (i === undefined) {
            return this.getTokens(QuaiExpressionsV3Parser.UNIQUE_ID);
        }
        else {
            return this.getToken(QuaiExpressionsV3Parser.UNIQUE_ID, i);
        }
    }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolveAttr; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveAttr) {
            listener.enterResolveAttr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveAttr) {
            listener.exitResolveAttr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveAttr) {
            return visitor.visitResolveAttr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveAttrContext = ResolveAttrContext;
class ResolvePrecipitatingContext extends ParserRuleContext_1.ParserRuleContext {
    UNIQUE_ID(i) {
        if (i === undefined) {
            return this.getTokens(QuaiExpressionsV3Parser.UNIQUE_ID);
        }
        else {
            return this.getToken(QuaiExpressionsV3Parser.UNIQUE_ID, i);
        }
    }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolvePrecipitating; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolvePrecipitating) {
            listener.enterResolvePrecipitating(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolvePrecipitating) {
            listener.exitResolvePrecipitating(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolvePrecipitating) {
            return visitor.visitResolvePrecipitating(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolvePrecipitatingContext = ResolvePrecipitatingContext;
class ResolveRelievingContext extends ParserRuleContext_1.ParserRuleContext {
    UNIQUE_ID(i) {
        if (i === undefined) {
            return this.getTokens(QuaiExpressionsV3Parser.UNIQUE_ID);
        }
        else {
            return this.getToken(QuaiExpressionsV3Parser.UNIQUE_ID, i);
        }
    }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolveRelieving; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveRelieving) {
            listener.enterResolveRelieving(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveRelieving) {
            listener.exitResolveRelieving(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveRelieving) {
            return visitor.visitResolveRelieving(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveRelievingContext = ResolveRelievingContext;
class ResolveYesContext extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolveYes; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveYes) {
            listener.enterResolveYes(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveYes) {
            listener.exitResolveYes(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveYes) {
            return visitor.visitResolveYes(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveYesContext = ResolveYesContext;
class ResolveNoContext extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_resolveNo; }
    // @Override
    enterRule(listener) {
        if (listener.enterResolveNo) {
            listener.enterResolveNo(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolveNo) {
            listener.exitResolveNo(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolveNo) {
            return visitor.visitResolveNo(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolveNoContext = ResolveNoContext;
class StringExpr0Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_stringExpr0; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.StringExpr0Context = StringExpr0Context;
class StringExprContext extends StringExpr0Context {
    STRING() { return this.getToken(QuaiExpressionsV3Parser.STRING, 0); }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterStringExpr) {
            listener.enterStringExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitStringExpr) {
            listener.exitStringExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitStringExpr) {
            return visitor.visitStringExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.StringExprContext = StringExprContext;
class ResolvableStringContext extends StringExpr0Context {
    resolvableExpr() {
        return this.getRuleContext(0, ResolvableExprContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolvableString) {
            listener.enterResolvableString(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolvableString) {
            listener.exitResolvableString(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolvableString) {
            return visitor.visitResolvableString(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolvableStringContext = ResolvableStringContext;
class ParenExprContext extends StringExpr0Context {
    expression() {
        return this.getRuleContext(0, ExpressionContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterParenExpr) {
            listener.enterParenExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitParenExpr) {
            listener.exitParenExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitParenExpr) {
            return visitor.visitParenExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ParenExprContext = ParenExprContext;
class NumericExpr0Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_numericExpr0; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.NumericExpr0Context = NumericExpr0Context;
class AdditionOperationContext extends NumericExpr0Context {
    numericExpr0() {
        return this.getRuleContext(0, NumericExpr0Context);
    }
    numericExpr1() {
        return this.getRuleContext(0, NumericExpr1Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterAdditionOperation) {
            listener.enterAdditionOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitAdditionOperation) {
            listener.exitAdditionOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitAdditionOperation) {
            return visitor.visitAdditionOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.AdditionOperationContext = AdditionOperationContext;
class SubtractionOperationContext extends NumericExpr0Context {
    numericExpr0() {
        return this.getRuleContext(0, NumericExpr0Context);
    }
    numericExpr1() {
        return this.getRuleContext(0, NumericExpr1Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterSubtractionOperation) {
            listener.enterSubtractionOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitSubtractionOperation) {
            listener.exitSubtractionOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitSubtractionOperation) {
            return visitor.visitSubtractionOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.SubtractionOperationContext = SubtractionOperationContext;
class PassThroughArithmeticTermContext extends NumericExpr0Context {
    numericExpr1() {
        return this.getRuleContext(0, NumericExpr1Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPassThroughArithmeticTerm) {
            listener.enterPassThroughArithmeticTerm(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPassThroughArithmeticTerm) {
            listener.exitPassThroughArithmeticTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPassThroughArithmeticTerm) {
            return visitor.visitPassThroughArithmeticTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PassThroughArithmeticTermContext = PassThroughArithmeticTermContext;
class NumericExpr1Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_numericExpr1; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.NumericExpr1Context = NumericExpr1Context;
class MultiplicationOperationContext extends NumericExpr1Context {
    numericExpr1() {
        return this.getRuleContext(0, NumericExpr1Context);
    }
    numericExpr2() {
        return this.getRuleContext(0, NumericExpr2Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterMultiplicationOperation) {
            listener.enterMultiplicationOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitMultiplicationOperation) {
            listener.exitMultiplicationOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitMultiplicationOperation) {
            return visitor.visitMultiplicationOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.MultiplicationOperationContext = MultiplicationOperationContext;
class DivisionOperationContext extends NumericExpr1Context {
    numericExpr1() {
        return this.getRuleContext(0, NumericExpr1Context);
    }
    numericExpr2() {
        return this.getRuleContext(0, NumericExpr2Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterDivisionOperation) {
            listener.enterDivisionOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitDivisionOperation) {
            listener.exitDivisionOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitDivisionOperation) {
            return visitor.visitDivisionOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.DivisionOperationContext = DivisionOperationContext;
class ModuloOperationContext extends NumericExpr1Context {
    numericExpr1() {
        return this.getRuleContext(0, NumericExpr1Context);
    }
    numericExpr2() {
        return this.getRuleContext(0, NumericExpr2Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterModuloOperation) {
            listener.enterModuloOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitModuloOperation) {
            listener.exitModuloOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitModuloOperation) {
            return visitor.visitModuloOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ModuloOperationContext = ModuloOperationContext;
class PassThroughArithmeticFactorContext extends NumericExpr1Context {
    numericExpr2() {
        return this.getRuleContext(0, NumericExpr2Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPassThroughArithmeticFactor) {
            listener.enterPassThroughArithmeticFactor(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPassThroughArithmeticFactor) {
            listener.exitPassThroughArithmeticFactor(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPassThroughArithmeticFactor) {
            return visitor.visitPassThroughArithmeticFactor(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PassThroughArithmeticFactorContext = PassThroughArithmeticFactorContext;
class NumericExpr2Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_numericExpr2; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.NumericExpr2Context = NumericExpr2Context;
class NegativeExpressionContext extends NumericExpr2Context {
    numericExpr3() {
        return this.getRuleContext(0, NumericExpr3Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterNegativeExpression) {
            listener.enterNegativeExpression(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitNegativeExpression) {
            listener.exitNegativeExpression(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitNegativeExpression) {
            return visitor.visitNegativeExpression(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.NegativeExpressionContext = NegativeExpressionContext;
class PowerOperationContext extends NumericExpr2Context {
    numericExpr3() {
        return this.getRuleContext(0, NumericExpr3Context);
    }
    numericExpr2() {
        return this.getRuleContext(0, NumericExpr2Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPowerOperation) {
            listener.enterPowerOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPowerOperation) {
            listener.exitPowerOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPowerOperation) {
            return visitor.visitPowerOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PowerOperationContext = PowerOperationContext;
class AtomicNumberLiteralContext extends NumericExpr2Context {
    numericExpr3() {
        return this.getRuleContext(0, NumericExpr3Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterAtomicNumberLiteral) {
            listener.enterAtomicNumberLiteral(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitAtomicNumberLiteral) {
            listener.exitAtomicNumberLiteral(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitAtomicNumberLiteral) {
            return visitor.visitAtomicNumberLiteral(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.AtomicNumberLiteralContext = AtomicNumberLiteralContext;
class NumericExpr3Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_numericExpr3; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.NumericExpr3Context = NumericExpr3Context;
class NumberLiteralContext extends NumericExpr3Context {
    NUMBER() { return this.getToken(QuaiExpressionsV3Parser.NUMBER, 0); }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterNumberLiteral) {
            listener.enterNumberLiteral(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitNumberLiteral) {
            listener.exitNumberLiteral(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitNumberLiteral) {
            return visitor.visitNumberLiteral(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.NumberLiteralContext = NumberLiteralContext;
class ResolvableNumberContext extends NumericExpr3Context {
    resolvableExpr() {
        return this.getRuleContext(0, ResolvableExprContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolvableNumber) {
            listener.enterResolvableNumber(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolvableNumber) {
            listener.exitResolvableNumber(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolvableNumber) {
            return visitor.visitResolvableNumber(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolvableNumberContext = ResolvableNumberContext;
class ParenArithmeticOperationContext extends NumericExpr3Context {
    numericExpr0() {
        return this.getRuleContext(0, NumericExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterParenArithmeticOperation) {
            listener.enterParenArithmeticOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitParenArithmeticOperation) {
            listener.exitParenArithmeticOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitParenArithmeticOperation) {
            return visitor.visitParenArithmeticOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ParenArithmeticOperationContext = ParenArithmeticOperationContext;
class ParenNumericConditionContext extends NumericExpr3Context {
    conditionalExpr0() {
        return this.getRuleContext(0, ConditionalExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterParenNumericCondition) {
            listener.enterParenNumericCondition(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitParenNumericCondition) {
            listener.exitParenNumericCondition(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitParenNumericCondition) {
            return visitor.visitParenNumericCondition(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ParenNumericConditionContext = ParenNumericConditionContext;
class TermWrapperNumericContext extends NumericExpr3Context {
    numericExpr0() {
        return this.getRuleContext(0, NumericExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterTermWrapperNumeric) {
            listener.enterTermWrapperNumeric(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitTermWrapperNumeric) {
            listener.exitTermWrapperNumeric(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTermWrapperNumeric) {
            return visitor.visitTermWrapperNumeric(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.TermWrapperNumericContext = TermWrapperNumericContext;
class BoolExpr0Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_boolExpr0; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.BoolExpr0Context = BoolExpr0Context;
class BooleanEqualityExprContext extends BoolExpr0Context {
    boolExpr0() {
        return this.getRuleContext(0, BoolExpr0Context);
    }
    boolExpr1() {
        return this.getRuleContext(0, BoolExpr1Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterBooleanEqualityExpr) {
            listener.enterBooleanEqualityExpr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitBooleanEqualityExpr) {
            listener.exitBooleanEqualityExpr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitBooleanEqualityExpr) {
            return visitor.visitBooleanEqualityExpr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.BooleanEqualityExprContext = BooleanEqualityExprContext;
class OrOperationContext extends BoolExpr0Context {
    boolExpr0() {
        return this.getRuleContext(0, BoolExpr0Context);
    }
    boolExpr1() {
        return this.getRuleContext(0, BoolExpr1Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterOrOperation) {
            listener.enterOrOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitOrOperation) {
            listener.exitOrOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitOrOperation) {
            return visitor.visitOrOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.OrOperationContext = OrOperationContext;
class PassThroughOrContext extends BoolExpr0Context {
    boolExpr1() {
        return this.getRuleContext(0, BoolExpr1Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPassThroughOr) {
            listener.enterPassThroughOr(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPassThroughOr) {
            listener.exitPassThroughOr(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPassThroughOr) {
            return visitor.visitPassThroughOr(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PassThroughOrContext = PassThroughOrContext;
class BoolExpr1Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_boolExpr1; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.BoolExpr1Context = BoolExpr1Context;
class AndOperationContext extends BoolExpr1Context {
    boolExpr1() {
        return this.getRuleContext(0, BoolExpr1Context);
    }
    boolExpr2() {
        return this.getRuleContext(0, BoolExpr2Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterAndOperation) {
            listener.enterAndOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitAndOperation) {
            listener.exitAndOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitAndOperation) {
            return visitor.visitAndOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.AndOperationContext = AndOperationContext;
class PassThroughBooleanComparisonContext extends BoolExpr1Context {
    boolExpr2() {
        return this.getRuleContext(0, BoolExpr2Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPassThroughBooleanComparison) {
            listener.enterPassThroughBooleanComparison(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPassThroughBooleanComparison) {
            listener.exitPassThroughBooleanComparison(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPassThroughBooleanComparison) {
            return visitor.visitPassThroughBooleanComparison(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PassThroughBooleanComparisonContext = PassThroughBooleanComparisonContext;
class BoolExpr2Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_boolExpr2; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.BoolExpr2Context = BoolExpr2Context;
class LessThanComparisonContext extends BoolExpr2Context {
    numericExpr0(i) {
        if (i === undefined) {
            return this.getRuleContexts(NumericExpr0Context);
        }
        else {
            return this.getRuleContext(i, NumericExpr0Context);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterLessThanComparison) {
            listener.enterLessThanComparison(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitLessThanComparison) {
            listener.exitLessThanComparison(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitLessThanComparison) {
            return visitor.visitLessThanComparison(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.LessThanComparisonContext = LessThanComparisonContext;
class GreaterThanComparisonContext extends BoolExpr2Context {
    numericExpr0(i) {
        if (i === undefined) {
            return this.getRuleContexts(NumericExpr0Context);
        }
        else {
            return this.getRuleContext(i, NumericExpr0Context);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterGreaterThanComparison) {
            listener.enterGreaterThanComparison(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitGreaterThanComparison) {
            listener.exitGreaterThanComparison(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitGreaterThanComparison) {
            return visitor.visitGreaterThanComparison(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.GreaterThanComparisonContext = GreaterThanComparisonContext;
class LesserOrEqualsComparisonContext extends BoolExpr2Context {
    numericExpr0(i) {
        if (i === undefined) {
            return this.getRuleContexts(NumericExpr0Context);
        }
        else {
            return this.getRuleContext(i, NumericExpr0Context);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterLesserOrEqualsComparison) {
            listener.enterLesserOrEqualsComparison(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitLesserOrEqualsComparison) {
            listener.exitLesserOrEqualsComparison(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitLesserOrEqualsComparison) {
            return visitor.visitLesserOrEqualsComparison(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.LesserOrEqualsComparisonContext = LesserOrEqualsComparisonContext;
class GreaterOrEqualsComparisonContext extends BoolExpr2Context {
    numericExpr0(i) {
        if (i === undefined) {
            return this.getRuleContexts(NumericExpr0Context);
        }
        else {
            return this.getRuleContext(i, NumericExpr0Context);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterGreaterOrEqualsComparison) {
            listener.enterGreaterOrEqualsComparison(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitGreaterOrEqualsComparison) {
            listener.exitGreaterOrEqualsComparison(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitGreaterOrEqualsComparison) {
            return visitor.visitGreaterOrEqualsComparison(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.GreaterOrEqualsComparisonContext = GreaterOrEqualsComparisonContext;
class InRangeContext extends BoolExpr2Context {
    numericExpr0(i) {
        if (i === undefined) {
            return this.getRuleContexts(NumericExpr0Context);
        }
        else {
            return this.getRuleContext(i, NumericExpr0Context);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterInRange) {
            listener.enterInRange(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitInRange) {
            listener.exitInRange(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitInRange) {
            return visitor.visitInRange(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.InRangeContext = InRangeContext;
class PassThroughBooleanEqualityContext extends BoolExpr2Context {
    boolExpr3() {
        return this.getRuleContext(0, BoolExpr3Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPassThroughBooleanEquality) {
            listener.enterPassThroughBooleanEquality(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPassThroughBooleanEquality) {
            listener.exitPassThroughBooleanEquality(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPassThroughBooleanEquality) {
            return visitor.visitPassThroughBooleanEquality(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PassThroughBooleanEqualityContext = PassThroughBooleanEqualityContext;
class BoolExpr3Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_boolExpr3; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.BoolExpr3Context = BoolExpr3Context;
class NumericEqaulComparisonContext extends BoolExpr3Context {
    numericExpr0(i) {
        if (i === undefined) {
            return this.getRuleContexts(NumericExpr0Context);
        }
        else {
            return this.getRuleContext(i, NumericExpr0Context);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterNumericEqaulComparison) {
            listener.enterNumericEqaulComparison(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitNumericEqaulComparison) {
            listener.exitNumericEqaulComparison(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitNumericEqaulComparison) {
            return visitor.visitNumericEqaulComparison(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.NumericEqaulComparisonContext = NumericEqaulComparisonContext;
class AtomicEqualComparisonContext extends BoolExpr3Context {
    stringExpr0(i) {
        if (i === undefined) {
            return this.getRuleContexts(StringExpr0Context);
        }
        else {
            return this.getRuleContext(i, StringExpr0Context);
        }
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterAtomicEqualComparison) {
            listener.enterAtomicEqualComparison(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitAtomicEqualComparison) {
            listener.exitAtomicEqualComparison(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitAtomicEqualComparison) {
            return visitor.visitAtomicEqualComparison(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.AtomicEqualComparisonContext = AtomicEqualComparisonContext;
class PassThroughBooleanFactorContext extends BoolExpr3Context {
    boolExpr4() {
        return this.getRuleContext(0, BoolExpr4Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPassThroughBooleanFactor) {
            listener.enterPassThroughBooleanFactor(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPassThroughBooleanFactor) {
            listener.exitPassThroughBooleanFactor(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPassThroughBooleanFactor) {
            return visitor.visitPassThroughBooleanFactor(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PassThroughBooleanFactorContext = PassThroughBooleanFactorContext;
class BoolExpr4Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_boolExpr4; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.BoolExpr4Context = BoolExpr4Context;
class NotOperationContext extends BoolExpr4Context {
    boolExpr4() {
        return this.getRuleContext(0, BoolExpr4Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterNotOperation) {
            listener.enterNotOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitNotOperation) {
            listener.exitNotOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitNotOperation) {
            return visitor.visitNotOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.NotOperationContext = NotOperationContext;
class PassThroughAtomicBooleanContext extends BoolExpr4Context {
    boolExpr5() {
        return this.getRuleContext(0, BoolExpr5Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterPassThroughAtomicBoolean) {
            listener.enterPassThroughAtomicBoolean(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPassThroughAtomicBoolean) {
            listener.exitPassThroughAtomicBoolean(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPassThroughAtomicBoolean) {
            return visitor.visitPassThroughAtomicBoolean(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PassThroughAtomicBooleanContext = PassThroughAtomicBooleanContext;
class BoolExpr5Context extends ParserRuleContext_1.ParserRuleContext {
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_boolExpr5; }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
exports.BoolExpr5Context = BoolExpr5Context;
class BooleanLiteralContext extends BoolExpr5Context {
    BOOL() { return this.getToken(QuaiExpressionsV3Parser.BOOL, 0); }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterBooleanLiteral) {
            listener.enterBooleanLiteral(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitBooleanLiteral) {
            listener.exitBooleanLiteral(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitBooleanLiteral) {
            return visitor.visitBooleanLiteral(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.BooleanLiteralContext = BooleanLiteralContext;
class ResolvableBooleanContext extends BoolExpr5Context {
    resolvableExpr() {
        return this.getRuleContext(0, ResolvableExprContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterResolvableBoolean) {
            listener.enterResolvableBoolean(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitResolvableBoolean) {
            listener.exitResolvableBoolean(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitResolvableBoolean) {
            return visitor.visitResolvableBoolean(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ResolvableBooleanContext = ResolvableBooleanContext;
class BooleanArrayFunctionsContext extends BoolExpr5Context {
    arrayFunctions() {
        return this.getRuleContext(0, ArrayFunctionsContext);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterBooleanArrayFunctions) {
            listener.enterBooleanArrayFunctions(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitBooleanArrayFunctions) {
            listener.exitBooleanArrayFunctions(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitBooleanArrayFunctions) {
            return visitor.visitBooleanArrayFunctions(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.BooleanArrayFunctionsContext = BooleanArrayFunctionsContext;
class ParenBooleanOperationContext extends BoolExpr5Context {
    boolExpr0() {
        return this.getRuleContext(0, BoolExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterParenBooleanOperation) {
            listener.enterParenBooleanOperation(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitParenBooleanOperation) {
            listener.exitParenBooleanOperation(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitParenBooleanOperation) {
            return visitor.visitParenBooleanOperation(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.ParenBooleanOperationContext = ParenBooleanOperationContext;
class TermWrapperBooleanContext extends BoolExpr5Context {
    boolExpr0() {
        return this.getRuleContext(0, BoolExpr0Context);
    }
    constructor(ctx) {
        super(ctx.parent, ctx.invokingState);
        this.copyFrom(ctx);
    }
    // @Override
    enterRule(listener) {
        if (listener.enterTermWrapperBoolean) {
            listener.enterTermWrapperBoolean(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitTermWrapperBoolean) {
            listener.exitTermWrapperBoolean(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTermWrapperBoolean) {
            return visitor.visitTermWrapperBoolean(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.TermWrapperBooleanContext = TermWrapperBooleanContext;
class PrimitivesContext extends ParserRuleContext_1.ParserRuleContext {
    BOOL() { return this.tryGetToken(QuaiExpressionsV3Parser.BOOL, 0); }
    STRING() { return this.tryGetToken(QuaiExpressionsV3Parser.STRING, 0); }
    NUMBER() { return this.tryGetToken(QuaiExpressionsV3Parser.NUMBER, 0); }
    constructor(parent, invokingState) {
        super(parent, invokingState);
    }
    // @Override
    get ruleIndex() { return QuaiExpressionsV3Parser.RULE_primitives; }
    // @Override
    enterRule(listener) {
        if (listener.enterPrimitives) {
            listener.enterPrimitives(this);
        }
    }
    // @Override
    exitRule(listener) {
        if (listener.exitPrimitives) {
            listener.exitPrimitives(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitPrimitives) {
            return visitor.visitPrimitives(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
exports.PrimitivesContext = PrimitivesContext;
