"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_BookFullName = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Renderer_BookFullName = (bookRef, book) => {
    const parts = [book];
    for (let i = 0; i <= bookRef.data.path.length - 1; i++) {
        const pathElement = bookRef.data.path[i];
        parts.push(parts[i].items[pathElement]);
    }
    let str = '';
    parts.forEach((item, i) => {
        var _a;
        //0 reads from book
        if (i === 0) {
            str += book.name.split(' ')[0];
            return;
        }
        const type = parts[i - 1].itemsType;
        if (type)
            str += ` - ${(0, ts_common_1.capitalizeFirstLetter)(type)} ${((_a = parts[i - 1].items) === null || _a === void 0 ? void 0 : _a.findIndex(_item => _item.name === item.name)) + 1}`;
    });
    return str;
};
exports.Renderer_BookFullName = Renderer_BookFullName;
