"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_Value = exports.DropDown_Value = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const shared_1 = require("../shared");
const ModuleFE_Value_1 = require("./ModuleFE_Value");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_Value_1.ModuleFE_Value,
    modules: [ModuleFE_Value_1.ModuleFE_Value],
    mapper: item => [(0, shared_1.resolveValueDisplay)(item)],
    placeholder: 'Choose a Value',
    renderer: item => React.createElement(React.Fragment, null, (0, shared_1.resolveValueDisplay)(item))
};
exports.DropDown_Value = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Value_1.ModuleFE_Value,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            (0, shared_1.resolveValueDisplay)(item));
    },
    uiSelector: exports.DropDown_Value.selectable,
});
exports.MultiSelect_Value = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
