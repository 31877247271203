"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveNotEqualConversion = exports.resolveNotEqual = void 0;
const utils_1 = require("../utils");
const resolveNotEqual = (dbAtom, dependencies) => {
    const notEqual = {};
    // Set default values derived from the type
    notEqual.output_type = 'boolean';
    notEqual.type = 'not-equal';
    // Convert the db value
    notEqual.value = (0, utils_1.createHVValueOption)(dbAtom.cases[0].rightHand[0], dependencies);
    return notEqual;
};
exports.resolveNotEqual = resolveNotEqual;
const resolveNotEqualConversion = (dbAtom, baseConversion, dependencies) => {
    const notEqualConversion = baseConversion;
    notEqualConversion.type = 'not-equal-conversion';
    notEqualConversion.value = (0, utils_1.createHVValueOption)(dbAtom.cases[0].rightHand[0], dependencies);
    notEqualConversion.return_value = notEqualConversion.output_type === 'tuple' ? dbAtom.cases[0].value : dbAtom.cases[0].value[0];
    notEqualConversion.else_value = notEqualConversion.output_type === 'tuple' ? dbAtom.fallbackValue : dbAtom.fallbackValue[0];
    return notEqualConversion;
};
exports.resolveNotEqualConversion = resolveNotEqualConversion;
