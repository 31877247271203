"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_MacroTag = exports.DropDown_MacroTag = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_MacroTag_1 = require("./ModuleFE_MacroTag");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_MacroTag_1.ModuleFE_MacroTag,
    modules: [ModuleFE_MacroTag_1.ModuleFE_MacroTag],
    mapper: item => [item.label],
    placeholder: 'Choose a MacroTag',
    renderer: item => React.createElement("div", { className: "ll_h_c" },
        " ",
        item.label,
        " ")
};
exports.DropDown_MacroTag = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_MacroTag_1.ModuleFE_MacroTag,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.label);
    },
    uiSelector: exports.DropDown_MacroTag.selectable,
});
exports.MultiSelect_MacroTag = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
