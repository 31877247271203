"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectExternalParams = void 0;
const shared_1 = require("../../../_enum/log-template/shared");
const ts_common_1 = require("@nu-art/ts-common");
const collectExternalParams = (instanceParams) => {
    return (0, ts_common_1.filterDuplicates)((0, ts_common_1.filterInstances)(instanceParams.map(param => {
        const keysWithNoType = [shared_1.LogTemplateType_Timestamp.dbKey];
        if (!param.params || !param.type || keysWithNoType.includes(param.type))
            return;
        const paramType = shared_1.LogTemplatesTypes.find(type => type.dbKey === param.type);
        if (!(paramType === null || paramType === void 0 ? void 0 : paramType.type))
            return;
        if ('externalResolver' in param.params) {
            return {
                externalParamKey: param.params.externalResolver,
                paramType: paramType.type
            };
        }
    })), item => item === null || item === void 0 ? void 0 : item.externalParamKey);
};
exports.collectExternalParams = collectExternalParams;
