"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_AdvisorContext = exports.ModuleFE_AdvisorContext_Class = exports.dispatch_onAdvisorContextChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onAdvisorContextChanged = new types_1.ThunderDispatcherV3('__onAdvisorContextUpdated');
class ModuleFE_AdvisorContext_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_AdvisorContext, exports.dispatch_onAdvisorContextChanged);
        this.__searchFilterMapper = (item) => [item.label];
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => ({ _id: i._id, uiLabel: i.label }))
            }]);
        this._v1 = {};
    }
}
exports.ModuleFE_AdvisorContext_Class = ModuleFE_AdvisorContext_Class;
exports.ModuleFE_AdvisorContext = new ModuleFE_AdvisorContext_Class();
