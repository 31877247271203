"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_PathwayState = exports.ModuleFE_PathwayState_Class = exports.dispatch_onPathwayStateChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const frontend_2 = require("../../pathway-transition/frontend");
const frontend_3 = require("@app/hcs/_entity/expression/frontend");
exports.dispatch_onPathwayStateChanged = new types_1.ThunderDispatcherV3('__onPathwayStateUpdated');
class ModuleFE_PathwayState_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_PathwayState, exports.dispatch_onPathwayStateChanged);
        this.onDeletePathwayStateComplete = async (response) => {
            await frontend_2.ModuleFE_PathwayTransition.onEntriesDeleted(response.pathwayTransitions);
            await frontend_3.ModuleFE_Expression.onEntriesDeleted(response.resolutionFunctions);
            await this.onEntriesDeleted([response.pathwayState]);
        };
        this._v1 = {
            deletePathwayState: (0, frontend_1.apiWithQuery)(shared_1.ApiDef_PathwayState._v1.deletePathwayState, this.onDeletePathwayStateComplete),
        };
    }
}
exports.ModuleFE_PathwayState_Class = ModuleFE_PathwayState_Class;
exports.ModuleFE_PathwayState = new ModuleFE_PathwayState_Class();
