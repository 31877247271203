"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormDataUtil = void 0;
const types_1 = require("./types");
exports.FormDataUtil = {
    is: {
        single: (instance) => {
            return instance.type === types_1.FormData_Single;
        },
        multi: (instance) => {
            return instance.type === types_1.FormData_Multi;
        },
    }
};
