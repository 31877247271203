"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_LogTemplate = exports.Validator_MinimalAssertionLogTemplate = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../../_enum/log-template/shared");
const hcs_1 = require("@app/hcs");
const Validator_ParamsOnlyVariableId = (instance) => {
    if (!instance)
        return 'No instance provided for validation';
    if (instance.variableId && instance.externalResolver)
        return 'cannot have both varId and external resolver';
    return (0, ts_common_1.tsValidateResult)(instance, {
        variableId: ts_common_1.tsValidateOptionalId,
        externalResolver: (0, ts_common_1.tsValidateString)(undefined, false),
    });
};
const Validator_ParamsTimeFormat = (instance) => {
    if (!instance)
        return 'No instance provided for validation';
    if (instance.variableId && instance.externalResolver)
        return 'cannot have both varId and external resolver';
    return (0, ts_common_1.tsValidateResult)(instance, {
        variableId: ts_common_1.tsValidateOptionalId,
        externalResolver: (0, ts_common_1.tsValidateString)(undefined, false),
        timeFormat: (0, ts_common_1.tsValidateString)()
    });
};
const Validator_TimestampParamType = { timeFormat: (0, ts_common_1.tsValidateString)() };
const Validator_ParamsTypeString = {
    externalResolver: (0, ts_common_1.tsValidateString)(undefined, false),
};
const Validator_ParamsTypeToValidator = {
    [shared_1.LogTemplateType_UnitsOf.dbKey]: Validator_ParamsOnlyVariableId,
    [shared_1.LogTemplateType_ValueOf.dbKey]: Validator_ParamsOnlyVariableId,
    [shared_1.LogTemplateType_NameOf.dbKey]: Validator_ParamsOnlyVariableId,
    [shared_1.LogTemplateType_String.dbKey]: Validator_ParamsTypeString,
    [shared_1.LogTemplateType_Timestamp.dbKey]: Validator_TimestampParamType,
    [shared_1.LogTemplateType_TimeOf.dbKey]: Validator_ParamsTimeFormat,
};
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateString)(),
    body: (0, ts_common_1.tsValidateString)(),
    title: (0, ts_common_1.tsValidateString)(),
    params: (0, ts_common_1.tsValidateArray)({
        paramKey: (0, ts_common_1.tsValidateString)(),
        type: (0, ts_common_1.tsValidateValue)([...shared_1.LogTemplateTypesKeys]),
        params: (0, ts_common_1.tsValidator_valueByKey)(Validator_ParamsTypeToValidator)
    }),
};
const Validator_GeneratedProps = {
    _variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _externalParams: (0, ts_common_1.tsValidateArray)({
        externalParamKey: (0, ts_common_1.tsValidateString)(),
        paramType: (0, ts_common_1.tsValidateValue)([...shared_1.LogTemplateExternalParams])
    }),
};
exports.Validator_MinimalAssertionLogTemplate = {
    label: (0, ts_common_1.tsValidateString)(),
    body: ts_common_1.tsValidateOptional,
    title: ts_common_1.tsValidateOptional,
    params: ts_common_1.tsValidateOptional,
};
exports.DBDef_LogTemplate = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'log-template-v2',
    entityName: 'LogTemplateV2',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'log-template-v2'
    },
    backend: {
        name: 'log-template-v2'
    },
    dependencies: {
        '_variableIds': {
            dbKey: 'variables',
            fieldType: 'string[]'
        }
    }
};
