"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_PathwayStateDetails = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@app/hcs/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const Renderer_Transition_1 = require("../../../../renderers/Renderer_Transition");
const consts_1 = require("../../../../consts");
const Header_Pathway_1 = require("../../../../headers/Header_Pathway");
const Header_PathwayState_1 = require("../../../../headers/Header_PathwayState");
const ConfirmationDialog_1 = require("@app/styles/frontend/ui-manager/dialogs/ConfirmationDialog");
const DataGrid_1 = require("@app/styles/frontend/ui-manager/compnents/DataGrid");
const _entity_1 = require("../../../../../../_entity");
const utils_1 = require("../../../../utils");
const Section_StateTransitions_1 = require("./panel-sections/Section_StateTransitions");
const Section_AllEntityVariables_1 = require("../../common-components/Section_AllEntityVariables");
class Renderer_PathwayStateDetails extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.deleteState = async () => {
            ConfirmationDialog_1.Dialog_Confirmation.show({
                header: 'Are you sure?',
                content: this.renderDeleteDialog,
                buttons: [
                    {
                        label: 'Yes, Delete',
                        isAsync: true,
                        action: async () => {
                            const stateId = this.props.pathwayState.item._id;
                            if (!stateId)
                                throw new ts_common_1.MUSTNeverHappenException('Trying to delete a pathway state with no _id');
                            await _entity_1.ModuleFE_PathwayState._v1.deletePathwayState({ pathwayStateId: stateId }).executeSync();
                            this.props.functions.deselectPathwayState();
                        }
                    },
                    {
                        label: 'Don\'t Delete',
                        isPreferred: true,
                        action: () => {
                        }
                    }
                ]
            });
        };
        this.getConnectedTransitions = () => {
            const stateId = this.props.pathwayState.item._id;
            if (!stateId)
                return [];
            return _entity_1.ModuleFE_PathwayTransition.cache.filter(pt => pt.sourceIds[0] === stateId || pt.targetIds[0] === stateId);
        };
        this.renderDeleteDialog = () => {
            const transitions = this.getConnectedTransitions();
            return React.createElement(React.Fragment, null,
                React.createElement("div", null, "You're about to delete the state"),
                React.createElement("div", null, this.props.pathwayState.item.name),
                transitions.length !== 0 && React.createElement(React.Fragment, null,
                    React.createElement("div", null, "By deleting this state, the following transitions will also be deleted"),
                    transitions.map(transition => {
                        return React.createElement(Renderer_Transition_1.Renderer_Transition, { key: transition._id, transition: transition });
                    })),
                React.createElement("div", null,
                    "Are you sure you want to delete this state",
                    transitions.length ? ' and its transitions' : '',
                    "?"));
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const pathwayState = this.props.pathwayState.item;
            const pathway = _entity_1.ModuleFE_Pathway.cache.unique(pathwayState.pathwayId);
            const stateTransitions = _entity_1.ModuleFE_PathwayTransition.cache.filter(transition => transition.sourceIds[0] === pathwayState._id);
            if (!pathway)
                throw new ts_common_1.MUSTNeverHappenException(`Could not get pathway for id ${pathwayState.pathwayId}`);
            const actions = [
                { label: 'Edit State', action: this.props.toggleEdit },
                { label: 'Delete State', action: this.deleteState },
                {
                    label: 'Add Transition',
                    action: () => {
                        const pathwayState = this.state.pathwayState.item;
                        const pathwayId = pathwayState.pathwayId;
                        const pathwayStateId = pathwayState._id;
                        if (!pathwayId)
                            throw new ts_common_1.MUSTNeverHappenException('No set pathway id');
                        if (!pathwayStateId)
                            throw new ts_common_1.MUSTNeverHappenException('No set pathway state id');
                        const newTransition = (0, consts_1.Empty_PathwayTransition)(pathwayId, pathwayStateId);
                        this.props.functions.selectPathwayTransition(new frontend_1.EditableDBItemV3(newTransition, _entity_1.ModuleFE_PathwayTransition), true);
                    }
                },
            ];
            if (stateTransitions.length)
                actions.push({
                    label: 'Edit Resolution Function',
                    action: () => {
                        this.props.functions.selectResolutionFunctionEditor();
                    },
                });
            return React.createElement(React.Fragment, null,
                this.renderTopButtons(),
                React.createElement(Header_Pathway_1.Header_Pathway, { pathway: new frontend_1.EditableDBItemV3(pathway, _entity_1.ModuleFE_Pathway), allowActions: true, functions: this.props.functions, clickableTitle: true }),
                React.createElement(Header_PathwayState_1.Header_PathwayState, { pathwayState: this.props.pathwayState, functions: this.props.functions, actions: actions }));
        };
        this.renderTopButtons = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'pathway__top-buttons' },
                React.createElement(frontend_1.TS_Button, { className: 'pathway__top-buttons__back', onClick: this.props.functions.deselectPathway }, '< Back to list'));
        };
        // ######################## Render - Body ########################
        this.renderBody = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'details-renderer__wrapper' },
                React.createElement(DataGrid_1.DataGrid.Grid, null,
                    this.renderSelectedStatePreconditions(),
                    this.renderSelectedStateSmartDoc(),
                    this.renderSelectedStateTransitions()),
                this.renderSelectedStateHCSVariables());
        };
        this.renderSelectedStatePreconditions = () => {
            const pathwayState = this.state.pathwayState.item;
            const preconditions = _entity_1.ModuleFE_Action.cache.filter(order => { var _a; return !!((_a = pathwayState.preconditions) === null || _a === void 0 ? void 0 : _a.find(precon => precon.orderId === order._id)); });
            return React.createElement(React.Fragment, null,
                React.createElement(DataGrid_1.DataGrid.Label, null, "Preconditions"),
                React.createElement(frontend_1.LL_V_L, null,
                    !preconditions.length && React.createElement(DataGrid_1.DataGrid.Data, null, "-"),
                    preconditions.map(precondition => React.createElement(DataGrid_1.DataGrid.Data, { key: precondition._id }, precondition.name))));
        };
        this.renderSelectedStateSmartDoc = () => {
            var _a, _b, _c;
            const pathwayState = this.state.pathwayState.item;
            const smartDoc = frontend_2.ModuleFE_LogTemplate_OLD.cache.unique((_b = (_a = pathwayState.logTemplates) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.templateId);
            return React.createElement(React.Fragment, null,
                React.createElement(DataGrid_1.DataGrid.Label, null, "Smart Doc"),
                React.createElement(DataGrid_1.DataGrid.Data, null, (_c = smartDoc === null || smartDoc === void 0 ? void 0 : smartDoc.title) !== null && _c !== void 0 ? _c : '-'));
        };
        this.renderSelectedStateTransitions = () => {
            return React.createElement(Section_StateTransitions_1.Section_StateTransitions, { pathwayStateId: this.state.pathwayState.get('_id'), functions: this.props.functions });
        };
        this.renderSelectedStateHCSVariables = () => {
            return React.createElement(Section_AllEntityVariables_1.Section_AllEntityVariables, { functions: this.props.functions, pathwayStateId: this.state.pathwayState.get('_id') });
        };
    }
    __onPathwayStateUpdated(...params) {
        const pathwayStateId = this.state.pathwayState.get('_id');
        if (!pathwayStateId)
            return;
        (0, utils_1.basicSyncEventRefresh)(pathwayStateId, params, () => this.props.functions.selectPathwayState(pathwayStateId), () => this.props.functions.deselectPathwayState());
    }
    deriveStateFromProps(nextProps, state) {
        state.pathwayState = nextProps.pathwayState;
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent pathway-state-details', style: { padding: '30px', gap: '10px' } },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Renderer_PathwayStateDetails = Renderer_PathwayStateDetails;
