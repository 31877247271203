"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscussionsView_FilterOptionsMenu = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const _entity_1 = require("../../../_entity");
const Const_ShowResolvedDiscussions = 'All comments';
const Const_HideResolvedDiscussions = 'Unresolved only';
class DiscussionsView_FilterOptionsMenu extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'filtering-options' },
            React.createElement(frontend_1.TS_Checkbox, { className: 'filtering-options__show-only-selected', checked: _entity_1.storage_FilterSelected.get(false), onCheck: () => {
                    const newValue = !_entity_1.storage_FilterSelected.get(false);
                    _entity_1.storage_FilterSelected.set(newValue);
                    _entity_1.dispatch_discussionViewOptionsChanged.dispatchUI();
                    this.forceUpdate();
                } }, "Remember Selection"),
            React.createElement(frontend_1.TS_Radio, { className: 'filtering-options-radio', values: [Const_ShowResolvedDiscussions, Const_HideResolvedDiscussions], groupName: 'resolved-option', checked: _entity_1.ModuleFE_Discussion.shouldShowResolvedDiscussions() ? Const_ShowResolvedDiscussions : Const_HideResolvedDiscussions, onCheck: (value) => {
                    const stateDidChange = _entity_1.ModuleFE_Discussion.setShouldShowResolvedDiscussions(value === Const_ShowResolvedDiscussions);
                    if (stateDidChange)
                        _entity_1.dispatch_discussionViewOptionsChanged.dispatchUI();
                } }));
    }
}
exports.DiscussionsView_FilterOptionsMenu = DiscussionsView_FilterOptionsMenu;
