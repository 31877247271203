"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimespanToValue = exports.TimespanLabelsMap = void 0;
const types_1 = require("./types");
const ts_common_1 = require("@nu-art/ts-common");
exports.TimespanLabelsMap = {
    [types_1.TimespanType_Second]: 'Seconds',
    [types_1.TimespanType_Minute]: 'Minutes',
    [types_1.TimespanType_Hour]: 'Hours',
    [types_1.TimespanType_Day]: 'Days',
    [types_1.TimespanType_Week]: 'Weeks',
    [types_1.TimespanType_Month]: 'Months',
    [types_1.TimespanType_Year]: 'Years',
};
exports.TimespanToValue = {
    [types_1.TimespanType_Second]: ts_common_1.Second,
    [types_1.TimespanType_Minute]: ts_common_1.Minute,
    [types_1.TimespanType_Hour]: ts_common_1.Hour,
    [types_1.TimespanType_Day]: ts_common_1.Day,
    [types_1.TimespanType_Week]: ts_common_1.Week,
    [types_1.TimespanType_Month]: ts_common_1.Month,
    [types_1.TimespanType_Year]: ts_common_1.Year,
};
