"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_TopExpression = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Component_SmartExpressionEditor_1 = require("../../../../expression-smart-editor/Component_SmartExpressionEditor");
require("./Editor_TopExpression.scss");
const consts_1 = require("@app/styles/frontend/ui/consts");
const consts_2 = require("../../../../../../../../_entity/expression/shared/consts");
class Editor_TopExpression extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        this.cardRef = React.createRef();
        this.renderExpressionEditorTitle = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'editor-title-container' }, "E:");
        };
        this.getOutputTypeDropDown = () => {
            const outputTypeAdapter = (0, frontend_1.SimpleListAdapter)(consts_2.Expression_OutputTypes, (item) => React.createElement(React.Fragment, null, item.item));
            const mandatoryProps_outputType = {
                placeholder: 'Select output type',
                caret: this.props.viewMode ? undefined : consts_1.DropDown_DefaultCaret,
                adapter: outputTypeAdapter
            };
            return frontend_1.TS_DropDown.prepare(mandatoryProps_outputType);
        };
        this.renderOutputTypeDropdown = () => {
            if (this.state.context === 'pathway')
                return '';
            const DropDown_ExpressionOutputTypes = this.getOutputTypeDropDown();
            return React.createElement(DropDown_ExpressionOutputTypes.editable, { editable: this.state.editable, prop: 'outputType', disabled: this.props.viewMode, onSelected: async (value) => {
                    await this.state.editable.update('outputType', value);
                }, showErrorTooltip: true });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        state.context = frontend_1.ModuleFE_BrowserHistory.getQueryParameter('type');
        // In case there's no output dropdown make the editor a bit larger
        const cardWidth = state.context === 'pathways' ? 600 : 400;
        (_a = state.cardWidth) !== null && _a !== void 0 ? _a : (state.cardWidth = cardWidth);
        state.viewMode = nextProps.viewMode;
        return state;
    }
    componentDidMount() {
        // observe changes in editor card size in order to resize it correctly
        if (this.cardRef.current) {
            this.resizeObserver = new ResizeObserver((entries) => {
                this.setState({ cardWidth: entries[0].contentRect.width });
            });
            this.resizeObserver.observe(this.cardRef.current);
        }
    }
    componentWillUnmount() {
        if (this.resizeObserver)
            this.resizeObserver.disconnect();
    }
    render() {
        var _a;
        const className = (0, frontend_1._className)('expression-card', 'top-expression', this.props.hideCards && 'hide-card');
        const expressionEditable = this.state.editable;
        return React.createElement(frontend_1.TS_Card, { className: className, innerRef: this.cardRef },
            React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: this.renderExpressionEditorTitle() },
                React.createElement(frontend_1.LL_H_C, { className: 'match_parent' },
                    this.renderOutputTypeDropdown(),
                    React.createElement(Component_SmartExpressionEditor_1.Component_SmartExpressionEditor, { height: 30, disabled: this.state.viewMode, width: this.state.cardWidth, hideButtons: true, value: (_a = expressionEditable.item.expression) !== null && _a !== void 0 ? _a : '', onEditorBlur: async (value) => {
                            await expressionEditable.update('expression', value);
                            this.forceUpdate();
                        } }))));
    }
}
exports.Editor_TopExpression = Editor_TopExpression;
