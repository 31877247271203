"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_User = exports.ModuleFE_User_Class = exports.dispatch_onMyUserReceived = exports.dispatch_onUsersUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const frontend_2 = require("@nu-art/user-account/frontend");
const ts_common_1 = require("@nu-art/ts-common");
exports.dispatch_onUsersUpdated = new types_1.ThunderDispatcherV3('__onUsersUpdated');
exports.dispatch_onMyUserReceived = new frontend_1.ThunderDispatcher('__OnUserLoggedIn');
class ModuleFE_User_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_User, exports.dispatch_onUsersUpdated);
        this.__searchFilterMapper = (user) => [user.firstName || user._id];
        this.__searchGroups = (users) => ([{
                group: this.dbDef.entityName,
                results: users.map(user => ({ _id: user._id, uiLabel: user.firstName || user._id }))
            }]);
        this.__onUsersUpdated = (...params) => {
            switch (params[0]) {
                case frontend_1.EventType_Update:
                case frontend_1.EventType_Create:
                case frontend_1.EventType_UpsertAll: {
                    // Currently we only listen in order to update the logged in user if data about it has changed. The system is supposed to not hold any user data besides the logged in user.
                    const selfUser = (0, ts_common_1.asArray)(params[1]).find(_user => { var _a; return _user._id === ((_a = this.loggedUser) === null || _a === void 0 ? void 0 : _a._id); });
                    if (selfUser)
                        this.loggedUser = selfUser;
                }
            }
        };
        this.__onLoginStatusUpdated = async () => {
            if (!frontend_2.ModuleFE_Account.isStatus(frontend_2.LoggedStatus.LOGGED_IN)) {
                return;
            }
            await this.populateLoggedUser();
        };
        this.getUser = () => {
            return this.loggedUser;
        };
        this.setLoggedUser = (user) => {
            this.loggedUser = user;
            exports.dispatch_onMyUserReceived.dispatchUI();
        };
        this.populateLoggedUser = async () => {
            const sessionId = frontend_2.ModuleFE_Account.getSessionId();
            if (!(sessionId === null || sessionId === void 0 ? void 0 : sessionId.length)) {
                throw new ts_common_1.BadImplementationException('missing session id');
            }
            this._v1.getMyUser({}).execute(this.setLoggedUser);
        };
        this._v1 = {
            getMyUser: (0, frontend_1.apiWithQuery)(shared_1.ApiDef_User._v1.getMyUser)
        };
    }
}
exports.ModuleFE_User_Class = ModuleFE_User_Class;
exports.ModuleFE_User = new ModuleFE_User_Class();
