"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_ClinicalForm = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const shared_1 = require("../../shared");
const subSection_Validator = (instance) => {
    const type = instance === null || instance === void 0 ? void 0 : instance.type;
    switch (type) {
        case types_1.clinicalForm_SubSectionType_Values:
            return (0, ts_common_1.tsValidate)(instance, subSection_Values_Validator);
        case types_1.clinicalForm_SubSectionType_Variables:
            return (0, ts_common_1.tsValidate)(instance, subSection_Variables_Validator);
        default:
            return `No sub-section validator defined for type ${type}`;
    }
};
const subSection_Values_Validator = {
    context: ts_common_1.tsValidateOptionalId,
    title: (0, ts_common_1.tsValidateString)(),
    type: (0, ts_common_1.tsValidateValue)([types_1.clinicalForm_SubSectionType_Values]),
    variableId: ts_common_1.tsValidateUniqueId,
    valueIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    attributes: (0, ts_common_1.tsValidate_OptionalArray)(subSection_Validator),
    selectionType: (0, ts_common_1.tsValidateValue)([types_1.clinicalForm_SelectionType_Many, types_1.clinicalForm_SelectionType_Single]),
};
const subSection_Variables_Validator = {
    context: ts_common_1.tsValidateOptionalId,
    title: (0, ts_common_1.tsValidateString)(),
    type: (0, ts_common_1.tsValidateValue)([types_1.clinicalForm_SubSectionType_Variables]),
    variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    attributes: (0, ts_common_1.tsValidate_OptionalArray)(subSection_Validator),
    selectionType: (0, ts_common_1.tsValidateValue)([types_1.clinicalForm_SelectionType_Many, types_1.clinicalForm_SelectionType_Single]),
};
const assessmentDialogSectionValidator = {
    title: (0, ts_common_1.tsValidateString)(),
    subSections: (0, ts_common_1.tsValidateArray)(subSection_Validator)
};
const Validator_ModifiableProps = {
    title: (0, ts_common_1.tsValidateString)(),
    key: (0, ts_common_1.tsValidateString)(),
    contextVariableId: ts_common_1.tsValidateUniqueId,
    sections: (0, ts_common_1.tsValidateArray)(assessmentDialogSectionValidator),
};
const Validator_GeneratedProps = {
    _valueIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId)
};
exports.DBDef_ClinicalForm = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'clinical-forms',
    entityName: 'ClinicalForm',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'clinical-form'
    },
    backend: {
        name: 'clinical-forms'
    },
    dependencies: {
        '_valueIds': {
            dbKey: 'values',
            fieldType: 'string[]'
        },
        '_variableIds': {
            dbKey: 'variables',
            fieldType: 'string[]'
        }
    }
};
