/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import {
	ComponentSync,
	stopPropagation,
	Thunder,
	TS_DialogOverlay,
	TS_MemoryMonitor,
	TS_Notifications,
	TS_PopUp,
	TS_Route,
	TS_ToastOverlay,
	TS_ToolTip
} from '@nu-art/thunderstorm/frontend';
import './App.scss';
import {generateUUID} from '@nu-art/ts-common';
import {LoginComponent} from '../app/pages/Page_Main/LoginComponent';
import {DiscussionsView} from '@app/hcs/frontend';


export class App
	extends ComponentSync<{ rootRoute: TS_Route, additionalOverlays?: React.ElementType[] }> {

	public static blockEvent<T>(ev: React.DragEvent<T>) {
		ev.preventDefault();
		ev.stopPropagation();
	}
	public getAdditionalOverlayRenderer(overlay: React.ElementType) {
		const Renderer = overlay;

		return <Renderer key={generateUUID()}/>;
	}

	protected deriveStateFromProps(nextProps: {}) {
		return {};
	}
	render() {
		// @ts-ignore
		const blockRightClick = !Thunder.getInstance().config.isDebug;

		return (
			<div id="app" onDrop={stopPropagation} onDragOver={stopPropagation}
					 onContextMenu={blockRightClick ? stopPropagation : undefined}>
				<LoginComponent rootRoute={this.props.rootRoute}/>
				<TS_DialogOverlay/>
				<TS_PopUp/>
				<TS_ToolTip/>
				<TS_ToastOverlay/>
				<DiscussionsView/>
				<TS_Notifications/>
				<TS_MemoryMonitor/>
				{this.props.additionalOverlays?.map(Overlay => this.getAdditionalOverlayRenderer(Overlay))}
			</div>);
	}
}