"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storageKey_SearchAndResult_SelectedSort = exports.sortType_Descending = exports.sortType_Ascending = exports.sortType_RecentlyAdded = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
exports.sortType_RecentlyAdded = {
    label: 'Recently Added',
    objectSortingProp: ['__created']
};
exports.sortType_Ascending = {
    label: 'Ascending',
    objectSortingProp: ['label', 'name']
};
exports.sortType_Descending = {
    label: 'Descending',
    objectSortingProp: ['label', 'name'],
    invert: true,
};
exports.storageKey_SearchAndResult_SelectedSort = new frontend_1.StorageKey('storageKey_selectedSort');
