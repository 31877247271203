"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_Glados = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const ApiDef_Glados = (dbDef) => {
    return {
        glados: {
            query: { method: thunderstorm_1.HttpMethod.GET, path: `v1/${dbDef.dbKey}/query/glados` }
        }
    };
};
exports.ApiDef_Glados = ApiDef_Glados;
