"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_ApiResourceResponseKeys = exports.FHIR_QueryTypes = void 0;
exports.FHIR_QueryTypes = ['search', 'read'];
exports.FHIR_ApiResourceResponseKeys = [
    'Bundle',
    'OperationOutcome',
    'AdverseEvent',
    'AllergyIntolerance',
    'Binary',
    'BodyStructure',
    'CarePlan',
    'CareTeam',
    'Communication',
    'ConceptMap',
    'Condition',
    'Consent',
    'Coverage',
    'Device',
    'DeviceRequest',
    'DeviceUseStatement',
    'DiagnosticReport',
    'DocumentReference',
    'Encounter',
    'Endpoint',
    'EpisodeOfCare',
    'ExplanationOfBenefit',
    'FamilyMemberHistory',
    'Flag',
    'Goal',
    'Group',
    'ImagingStudy',
    'Immunization',
    'ImmunizationRecommendation',
    'List',
    'Location',
    'Measure',
    'MeasureReport',
    'Medication',
    'MedicationDispense',
    'MedicationRequest',
    'MedicationStatement',
    'NutritionOrder',
    'Observation',
    'Organization',
    'Patient',
    'Practitioner',
    'PractitionerRole',
    'Procedure',
    'Provenance',
    'Questionnaire',
    'QuestionnaireResponse',
    'RelatedPerson',
    'RequestGroup',
    'ResearchStudy',
    'ResearchSubject',
    'Specimen',
    'Substance',
    'Task',
    'ValueSet',
];
