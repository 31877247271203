"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_Pathway = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const ts_common_1 = require("@nu-art/ts-common");
exports.ApiDef_Pathway = {
    _v1: {
        duplicatePathway: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/pathway/duplicate', timeout: ts_common_1.Minute * 5 },
        deletePathway: { method: thunderstorm_1.HttpMethod.DELETE, path: '/v1/pathway/delete-pathway' },
        createPathway: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/pathway/create-pathway' },
    }
};
