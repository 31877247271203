"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimespansType = exports.TimespanType_Year = exports.TimespanType_Month = exports.TimespanType_Week = exports.TimespanType_Day = exports.TimespanType_Hour = exports.TimespanType_Minute = exports.TimespanType_Second = void 0;
exports.TimespanType_Second = 'second';
exports.TimespanType_Minute = 'minute';
exports.TimespanType_Hour = 'hour';
exports.TimespanType_Day = 'day';
exports.TimespanType_Week = 'week';
exports.TimespanType_Month = 'month';
exports.TimespanType_Year = 'year';
exports.TimespansType = [exports.TimespanType_Second,
    exports.TimespanType_Minute,
    exports.TimespanType_Hour,
    exports.TimespanType_Day,
    exports.TimespanType_Week,
    exports.TimespanType_Month,
    exports.TimespanType_Year
];
