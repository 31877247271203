"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_LogTemplatesParams = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const shared_1 = require("../../../../../_enum/log-template/shared");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("../../../../../_enum/log-template/frontend");
const ui_components_1 = require("@app/hcs/_entity/variable/frontend/ui-components");
const frontend_3 = require("../../../../../_entity/log-template/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_4 = require("../../../../../_enum/time-formats/frontend");
const consts_1 = require("@app/styles/frontend/ui/consts");
class Editor_LogTemplatesParams extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.rendererMap = {
            [shared_1.LogTemplateType_ValueOf.dbKey]: this.renderVariableOnlyDropdown.bind(this),
            [shared_1.LogTemplateType_TimeOf.dbKey]: this.renderTimeFormat.bind(this).bind(this),
            [shared_1.LogTemplateType_UnitsOf.dbKey]: this.renderVariableOnlyDropdown.bind(this),
            [shared_1.LogTemplateType_NameOf.dbKey]: this.renderVariableOnlyDropdown.bind(this),
            [shared_1.LogTemplateType_Timestamp.dbKey]: this.renderTimestamp.bind(this),
            [shared_1.LogTemplateType_String.dbKey]: () => React.createElement(React.Fragment, null)
        };
        this.renderVariableDropdown = (editableParams) => {
            const editableParamParams = editableParams.editProp('params', {});
            if (this.state.paramTypeMapper[editableParams.get('paramKey')] === 'Dynamic Param') {
                return this.renderExternalParamsDropdown(editableParamParams);
            }
            return React.createElement(ui_components_1.DropDown_VariableV3.editable, { editable: editableParamParams, prop: 'variableId', limitItems: 50, showErrorTooltip: true });
        };
        this.renderTimeFormatDropdown = (editableParams) => {
            const editableParamParams = editableParams.editProp('params', {});
            return React.createElement(frontend_4.DropDown_TimeFormats.editable, { editable: editableParamParams, prop: 'timeFormat', showErrorTooltip: true });
        };
        this.renderExternalParamsDropdown = (editableParams) => {
            const externalParamsDropdown = frontend_1.TS_DropDown.prepare({
                adapter: (0, frontend_1.SimpleListAdapter)(this.props.externalParams, item => React.createElement(React.Fragment, null, item.item.externalParamKey)),
                placeholder: 'Select External Params',
                caret: consts_1.DropDown_DefaultCaret,
                queryFilter: param => param.paramType !== shared_1.ParamTypeValue_String,
                filter: new ts_common_1.Filter(externalParam => {
                    return [externalParam.externalParamKey];
                })
            });
            return externalParamsDropdown.selectable({
                selected: this.props.externalParams.find(externalParam => {
                    if ('externalResolver' in editableParams.item)
                        return externalParam.externalParamKey === editableParams.item.externalResolver;
                }),
                showErrorTooltip: true,
                onNoMatchingSelectionForString: async (filterText, matchingItems, e) => {
                    if (e.code === 'Enter' && !matchingItems.length)
                        await editableParams.updateObj({
                            variableId: undefined,
                            externalResolver: filterText
                        });
                },
                onSelected: async (value) => {
                    await editableParams.updateObj({
                        variableId: undefined,
                        externalResolver: value.externalParamKey
                    });
                }
            });
        };
        this.renderParamsEditor = (editableParam) => {
            if (!editableParam.get('type'))
                return '';
            return this.rendererMap[editableParam.get('type')](editableParam);
        };
        this.renderTypeDropdown = (param) => {
            return React.createElement(frontend_2.Dropdown_LogTemplateParamsType.editable, { editable: param, prop: 'type', queryFilter: (item) => {
                    if (this.state.paramTypeMapper[param.item.paramKey] === 'Static Param')
                        return true;
                    const newVar = [shared_1.LogTemplateType_String.dbKey, shared_1.LogTemplateType_Timestamp.dbKey];
                    return !newVar.includes(item);
                }, showErrorTooltip: true, onSelected: async (type) => {
                    const changes = {
                        type
                    };
                    if (param.get('params'))
                        changes.params = undefined;
                    if (type === shared_1.LogTemplateType_String.dbKey) {
                        changes.params = {
                            externalResolver: param.get('paramKey')
                        };
                        this.state.paramTypeMapper[param.get('paramKey')] = 'Dynamic Param';
                    }
                    await param.updateObj(changes);
                } });
        };
        this.renderParamDeclarationDropdown = (param) => {
            const paramType = param.get('type');
            const isDisabled = !paramType || paramType === shared_1.LogTemplateType_Timestamp.dbKey || shared_1.LogTemplateType_String.dbKey === paramType;
            return React.createElement(frontend_2.DropDown_ParamDeclaration.selectable, { selected: this.state.paramTypeMapper[param.item.paramKey], disabled: isDisabled, onSelected: (value) => {
                    this.setState({
                        paramTypeMapper: Object.assign(Object.assign({}, this.state.paramTypeMapper), { [param.item.paramKey]: value })
                    }, async () => {
                        await param.updateObj({ params: undefined });
                    });
                } });
        };
        this.renderGridRow = (param) => {
            return React.createElement(frontend_1.LL_H_C, { key: param.get('paramKey'), className: 'params-editor__row' },
                React.createElement("div", { className: 'parameter-key' }, param.get('paramKey')),
                React.createElement(frontend_1.LL_H_C, { className: 'row-params' },
                    this.renderTypeDropdown(param),
                    this.renderParamDeclarationDropdown(param),
                    this.renderParamsEditor(param)));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.paramTypeMapper = (0, ts_common_1.reduceToMap)(state.editable.item, item => item.paramKey, item => {
            var _a, _b;
            if ((_a = state.paramTypeMapper) === null || _a === void 0 ? void 0 : _a[(_b = item === null || item === void 0 ? void 0 : item.paramKey) !== null && _b !== void 0 ? _b : ''])
                return state.paramTypeMapper[item === null || item === void 0 ? void 0 : item.paramKey];
            if (!item || !item.params)
                return 'Static Param';
            if ('externalResolver' in item.params)
                return 'Dynamic Param';
            return 'Static Param';
        });
        return state;
    }
    renderVariableOnlyDropdown(editableParam) {
        if (!frontend_3.ModuleFE_LogTemplate.isParamOfTypes(editableParam, [shared_1.LogTemplateType_UnitsOf.dbKey, shared_1.LogTemplateType_ValueOf.dbKey, shared_1.LogTemplateType_NameOf.dbKey]))
            throw new ts_common_1.BadImplementationException('should not render this renderer for a non variable only param type');
        return this.renderVariableDropdown(editableParam);
    }
    renderTimeFormat(editableParam) {
        if (!frontend_3.ModuleFE_LogTemplate.isParamOfTypes(editableParam, [shared_1.LogTemplateType_TimeOf.dbKey]))
            throw new ts_common_1.BadImplementationException('should not render this renderer for a non time format only param type');
        return React.createElement(frontend_1.LL_H_C, { className: 'time-format-editor' },
            this.renderVariableDropdown(editableParam),
            this.renderTimeFormatDropdown(editableParam));
    }
    renderTimestamp(editableParam) {
        if (!frontend_3.ModuleFE_LogTemplate.isParamOfTypes(editableParam, [shared_1.LogTemplateType_Timestamp.dbKey]))
            throw new ts_common_1.BadImplementationException(`cannot use ${editableParam.get('type')} param type with this renderer`);
        return this.renderTimeFormatDropdown(editableParam);
    }
    render() {
        if (!this.state.editable.item.length)
            return React.createElement("div", null, "No parameters found for template");
        return React.createElement(frontend_1.Grid, { id: 'log-templates-params-editor' }, this.state.editable.item.map((param, index) => {
            const editableParam = this.state.editable.editProp(index, {});
            return this.renderGridRow(editableParam);
        }));
    }
}
exports.Editor_LogTemplatesParams = Editor_LogTemplatesParams;
