"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_LogTemplate = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    step: (0, ts_common_1.tsValidateString)(),
    title: (0, ts_common_1.tsValidateString)(),
    body: (0, ts_common_1.tsValidateString)(),
    isMutable: (0, ts_common_1.tsValidateBoolean)(),
    aliases: (0, ts_common_1.tsValidateDynamicObject)((0, ts_common_1.tsValidateString)(), (0, ts_common_1.tsValidateString)())
};
const Validator_GeneratedProps = {};
exports.DBDef_LogTemplate = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'log-templates',
    entityName: 'LogTemplate',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'log-template'
    },
    backend: {
        name: 'log-templates'
    },
};
