"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_Confirmation = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Dialog_Confirmation.scss");
class Dialog_Confirmation extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderHeader = () => {
            if (!this.props.header)
                return;
            return React.createElement(frontend_1.LL_H_C, { className: 'quai-manager-ui__confirmation-dialog__header' }, (0, ts_common_1.resolveContent)(this.props.header));
        };
        this.renderContent = () => {
            if (!this.props.content)
                return;
            return React.createElement(frontend_1.LL_V_L, { className: 'quai-manager-ui__confirmation-dialog__content' }, (0, ts_common_1.resolveContent)(this.props.content));
        };
        this.renderButtons = () => {
            var _a;
            if (!((_a = this.props.buttons) === null || _a === void 0 ? void 0 : _a.length))
                return;
            return React.createElement(frontend_1.LL_H_C, { className: 'quai-manager-ui__confirmation-dialog__buttons' }, this.props.buttons.map((button, i) => {
                const className = (0, frontend_1._className)('quai-manager-ui__confirmation-dialog__button', button.isPreferred && 'preferred-action');
                if (button.isAsync)
                    return React.createElement(frontend_1.TS_BusyButton, { key: i, className: className, onClick: async () => {
                            await button.action();
                            frontend_1.ModuleFE_Dialog.close();
                        } }, button.label);
                return React.createElement(frontend_1.TS_Button, { key: i, className: className, onClick: () => {
                        button.action();
                        frontend_1.ModuleFE_Dialog.close();
                    } }, button.label);
            }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        return state;
    }
    render() {
        const className = (0, frontend_1._className)('quai-manager-ui__confirmation-dialog', this.props.className);
        return React.createElement(frontend_1.LL_V_L, { className: className, id: this.props.id },
            this.renderHeader(),
            this.renderContent(),
            this.renderButtons());
    }
}
Dialog_Confirmation.show = (data) => {
    frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_Confirmation, Object.assign({}, data)));
};
exports.Dialog_Confirmation = Dialog_Confirmation;
