"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page_OrderEditor = void 0;
const frontend_1 = require("@nu-art/ts-workspace/frontend");
const workspace_config_1 = require("./workspace-config");
const React = require("react");
const EditorWrapper_Order_1 = require("./presenters/EditorWrapper_Order");
const SearchSelector_BaseManagerUI_1 = require("@app/styles/frontend/ui/ui-manager/Selector_Base/SearchSelector_BaseManagerUI");
const frontend_2 = require("@app/styles/frontend");
const frontend_3 = require("../../../_entity/action/frontend");
const frontend_4 = require("@app/hcs/_entity/resource/frontend");
const frontend_5 = require("@app/hcs/_entity/variable/frontend");
const frontend_6 = require("@app/hcs/_entity/tag/frontend");
const frontend_7 = require("@app/hcs/_entity/value/frontend");
const frontend_8 = require("@app/hcs/frontend");
const frontend_9 = require("@app/advisor-content/_entity/advisor-context/frontend");
class Page_OrderEditor extends frontend_2.Page_BaseManagerUI_SingleType_Proto {
    constructor() {
        super(...arguments);
        this.workspace = {
            id: 'order-editor',
            key: workspace_config_1.workspaceKey_OrderEditor,
            renderers: {
                horizontalSpace: frontend_1.TS_HorizontalWorkspace,
            },
            instances: {
                selector: this.renderSelector.bind(this),
                presenter: this.renderPresenter.bind(this),
            }
        };
        // ######################## Logic ########################
        this.onCreateNewOrder = () => {
            this.clearSelectedResult();
            this.setState({ selectedItem: this.getEditableItem(), selectedResult: undefined });
        };
    }
    getInitialObject() {
        return { variableIds: [], resolution: 'manual', type: 'order' };
    }
    // ######################## Render ########################
    renderSelector() {
        return React.createElement(SearchSelector_BaseManagerUI_1.SearchSelector_BaseManagerUI, { searchItems: this.props.searchItems, selectedItem: this.state.selectedResult, onSearchItemSelected: (item) => this.onSearchResultSelected(item.id, item.type), buttons: [{
                    label: 'Add Action',
                    action: this.onCreateNewOrder.bind(this)
                }] });
    }
    renderPresenter() {
        const selectedItem = this.state.selectedItem;
        if (!selectedItem)
            return React.createElement(frontend_8.QPageMessage, { message: 'Order Manager' });
        return React.createElement(EditorWrapper_Order_1.EditorWrapper_Order, { editable: selectedItem, deselectItem: () => {
                this.clearSelectedResult();
                this.setState({ selectedItem: undefined, selectedResult: undefined });
            } });
    }
}
_a = Page_OrderEditor;
Page_OrderEditor.Route = {
    key: 'action-manager',
    path: 'action-manager',
    Component: _a,
    modulesToAwait: [frontend_3.ModuleFE_Action, frontend_4.ModuleFE_Resource, frontend_5.ModuleFE_Variable, frontend_6.ModuleFE_Tag, frontend_7.ModuleFE_Value, frontend_9.ModuleFE_AdvisorContext],
};
Page_OrderEditor.searchItemKey_Action = frontend_3.ModuleFE_Action.dbDef.dbKey;
Page_OrderEditor.SearchableItem_Action = {
    module: frontend_3.ModuleFE_Action,
    type: Page_OrderEditor.searchItemKey_Action,
    route: Page_OrderEditor.Route,
    entityName: frontend_3.ModuleFE_Action.dbDef.entityName,
    entityLabel: 'Action',
    groupId: ['Actions'],
    tags: [],
    mapper: (item) => [item.name],
    renderer: (item, selected, onClickCallback) => {
        return React.createElement(frontend_2.Renderer_SearchResult, { itemId: item._id, itemKey: Page_OrderEditor.searchItemKey_Action, itemLabel: item.name, entityLabel: item.type === 'order' ? 'Order' : 'Workflow', hierarchy: 'Actions', selected: selected, onClickCallback: onClickCallback });
    },
    resultRendererV2: ({ item }) => {
        return React.createElement(EditorWrapper_Order_1.EditorWrapper_Order, { editable: item });
    },
    resultRenderer: (itemId) => {
        return React.createElement(React.Fragment, null);
    }
};
Page_OrderEditor.defaultProps = {
    searchItems: [Page_OrderEditor.SearchableItem_Action]
};
exports.Page_OrderEditor = Page_OrderEditor;
