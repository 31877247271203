"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_NewDiscussion = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Renderer_NewDiscussion.scss");
const _entity_1 = require("../../../_entity");
const consts_1 = require("../../../../_entity/discussion/shared/consts");
class Renderer_NewDiscussion extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.saveDiscussion = async () => {
            await _entity_1.ModuleFE_Discussion._v1.createNewDiscussion({
                toCreateTopic: {
                    type: this.props.type,
                    refId: this.props.refId,
                },
                toCreateDiscussion: {
                    type: consts_1.DiscussionType_Chat,
                },
                toCreateMessage: {
                    type: 'text',
                    text: this.state.discussionText.trim(),
                }
            }).executeSync();
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.discussionLabel = _entity_1.ModuleFE_Discussion.getLabelFromEntityId(nextProps.type, nextProps.refId);
        if (((_a = this.state) === null || _a === void 0 ? void 0 : _a.discussionLabel) !== state.discussionLabel)
            state.discussionText = '';
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'new-discussion' },
            React.createElement("div", { className: 'new-discussion__title' }, this.state.discussionLabel),
            React.createElement(frontend_1.TS_TextArea, { type: 'text', value: this.state.discussionText, onChange: value => this.setState({ discussionText: value }) }),
            React.createElement(frontend_1.TS_BusyButton, { onClick: this.saveDiscussion }, "Save"));
    }
}
exports.Renderer_NewDiscussion = Renderer_NewDiscussion;
