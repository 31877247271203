"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_TagViewer = void 0;
const React = require("react");
require("./Component_TagViewer.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const wrappers_1 = require("../../../wrappers");
class Component_TagViewer extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderTagGroups = (pair) => {
            return React.createElement(React.Fragment, null,
                React.createElement("span", { className: 'field-title' },
                    pair.key.label,
                    ":"),
                React.createElement("span", { className: 'field-data' }, this.renderTags(pair)));
        };
        this.renderTags = (pair) => {
            if (this.props.horizontal)
                return React.createElement(frontend_1.LL_H_C, { className: 'variable-tags' }, pair.value.map(item => React.createElement("div", { className: 'variable-tag orange', key: item.label }, item.label)));
            return React.createElement(frontend_1.LL_V_L, { className: 'variable-tags' }, pair.value.map(item => React.createElement("div", { className: 'variable-tag orange', key: item.label }, item.label)));
        };
    }
    deriveStateFromProps(nextProps) {
        return {
            tags: wrappers_1.VariableWrapper.dbTagsToUI(nextProps.tags || [])
        };
    }
    render() {
        return this.state.tags.map(pair => {
            return React.createElement(frontend_1.LL_V_L, { className: 'field', key: pair.key.label }, this.renderTagGroups(pair));
        });
    }
}
exports.Component_TagViewer = Component_TagViewer;
