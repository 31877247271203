"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const shared_components_1 = require("../../../../../../shared-components");
const utils_1 = require("../../utils/utils");
function RenderLeftSide(props) {
    const relevantValues = (0, utils_1.filterRelevantValues)(props.initialValue);
    return (React.createElement("div", null, props.item && props.item.map((currInput, inputIndex) => {
        var _a;
        return (React.createElement(shared_components_1.DropDown_Values, { key: inputIndex, selected: props.item && ((_a = props.item[inputIndex]) === null || _a === void 0 ? void 0 : _a.valueId), onSelected: newValue => {
                props.onChangeVariableValue(props.index, inputIndex, newValue);
            }, queryFilter: item => relevantValues.includes(item._id), variable: props.variable }));
    })));
}
exports.default = RenderLeftSide;
