"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QEditableEntryList = void 0;
const React = require("react");
require("./QEditableEntryList.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const QToast_1 = require("../QToast");
const styles_1 = require("@app/styles");
class QEditableEntryList extends React.Component {
    constructor() {
        // ######################## Logic ########################
        super(...arguments);
        this.toastWarning = (message) => {
            new frontend_1.ToastBuilder().setDuration(2 * ts_common_1.Second)
                .setContent((0, QToast_1.QToastContent)({ iconKey: 'attention', content: message, toastType: 'warning' }))
                .show();
        };
        this.onKeyDown = (e) => {
            if (!this.props.pasteOnly)
                return;
            if (e.key === 'Enter')
                return;
            if (!((e.ctrlKey || e.metaKey) && e.key === 'v')) {
                (0, frontend_1.stopPropagation)(e);
                if (!(e.ctrlKey || e.metaKey))
                    this.toastWarning('Can only paste id into this field');
            }
        };
        this.onAccept = (val, e) => {
            val = val.trim();
            if (this.props.addEntry)
                return this.props.addEntry(val);
            this.props.entries.push(val);
            this.forceUpdate();
        };
        // ######################## Render ########################
        this.renderEntryItem = (entry) => {
            if (this.props.render) {
                return this.props.render(entry);
            }
            return React.createElement("div", { className: 'entry-display' }, entry);
        };
    }
    renderLoader() {
        return React.createElement("div", { className: 'entry-loader' },
            React.createElement(frontend_1.TS_Loader, null));
    }
    renderEntry(entry, index) {
        var _a, _b;
        return React.createElement("div", { className: 'entry', key: index },
            React.createElement(styles_1.ICONS.x.component, { onClick: this.props.removeEntry ?
                    () => this.props.removeEntry(entry) :
                    () => {
                        this.props.entries.splice(index, 1);
                        this.forceUpdate();
                    } }),
            this.renderEntryItem(entry), (_b = (_a = this.props).tailRenderer) === null || _b === void 0 ? void 0 :
            _b.call(_a, entry));
    }
    render() {
        var _a;
        return React.createElement(frontend_1.LL_H_C, { className: 'q-editable-entry-list' }, (_a = this.props.entries) === null || _a === void 0 ? void 0 :
            _a.map((entry, index) => this.renderEntry(entry, index)),
            this.props.isLoading ? this.renderLoader() : null,
            React.createElement(frontend_1.TS_Input, { key: this.props.entries.length, placeholder: this.props.placeholder, value: '', type: 'text', onKeyDown: this.onKeyDown, onAccept: this.onAccept }));
    }
}
exports.QEditableEntryList = QEditableEntryList;
