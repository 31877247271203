"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_Condition_CategoryOptions_ToLabelsMap = exports.FHIR_Condition_CategoryOptions = exports.Category_MedicalHistory = exports.Category_ReasonForVisit = exports.Category_Infection = exports.Category_EncounterDiagnosis = void 0;
exports.Category_EncounterDiagnosis = 'encounter-diagnosis';
exports.Category_Infection = 'infection';
exports.Category_ReasonForVisit = 'reason-for-visit';
exports.Category_MedicalHistory = 'medical-history';
exports.FHIR_Condition_CategoryOptions = [
    exports.Category_ReasonForVisit,
    exports.Category_EncounterDiagnosis,
    exports.Category_Infection,
    exports.Category_MedicalHistory,
];
exports.FHIR_Condition_CategoryOptions_ToLabelsMap = {
    [exports.Category_ReasonForVisit]: 'Reason For Visit',
    [exports.Category_EncounterDiagnosis]: 'Encounter Diagnosis',
    [exports.Category_Infection]: 'Infection',
    [exports.Category_MedicalHistory]: 'Medical History',
};
