"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._canBeAddedToView = void 0;
const ts_common_1 = require("@nu-art/ts-common");
function _canBeAddedToView(diseaseView, role, sourceTagGroup, otherTags, roles) {
    var _a, _b;
    if (!roles)
        roles = diseaseView.filter.roles;
    //If we filter by roles and they don't include the role
    if (roles && roles.length > 0 && !roles.includes(role))
        return false;
    //If source tag group was not provided
    if (!sourceTagGroup)
        return false;
    const whiteList = (_a = diseaseView.filter.tagIds) === null || _a === void 0 ? void 0 : _a.whitelist;
    const blackList = (_b = diseaseView.filter.tagIds) === null || _b === void 0 ? void 0 : _b.blacklist;
    //If we are not filtering by tags by this point instant true
    if (!(whiteList === null || whiteList === void 0 ? void 0 : whiteList.length) && !(blackList === null || blackList === void 0 ? void 0 : blackList.length))
        return true;
    //If any tag id is in the black list the answer is no
    if (sourceTagGroup.value.some(id => blackList === null || blackList === void 0 ? void 0 : blackList.includes(id)))
        return false;
    //Either whitelist doesn't exist or the variable passes at least one whitelist id
    return !(whiteList === null || whiteList === void 0 ? void 0 : whiteList.length) || (0, ts_common_1.arrayIncludesAny)(whiteList, sourceTagGroup.value);
}
exports._canBeAddedToView = _canBeAddedToView;
