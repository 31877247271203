"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Q_VariablePanel = void 0;
const React = require("react");
require("./Q_VariableByTagsPanel.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("../../../../_entity/variable/frontend");
const ts_common_1 = require("@nu-art/ts-common");
class Q_VariablePanel extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderListItems = () => {
            const variables = this.state.variables;
            const className = (0, frontend_1._className)('variables-panel__panel-body');
            if (!variables.length)
                return React.createElement(frontend_1.LL_V_L, { className: className },
                    React.createElement("div", { className: 'variables-panel__list-item' }, "No Variables Found..."));
            return React.createElement(frontend_1.LL_V_L, { className: className }, variables.map((_var, index) => React.createElement("div", { key: index, className: 'variables-panel__list-item' }, _var.name)));
        };
        this.renderHeader = () => {
            var _a;
            const variables = this.state.variables;
            return React.createElement("div", { className: 'variables-panel__header' }, `${(_a = this.props.header) !== null && _a !== void 0 ? _a : 'Tags Variables'} (${variables.length})`);
        };
    }
    deriveStateFromProps(nextProps, state) {
        // Update relevant variables
        if (nextProps.filter)
            state.variables = nextProps.module.cache.filter(nextProps.filter);
        else
            state.variables = nextProps.module.cache.allMutable();
        state.variables = (0, ts_common_1.sortArray)(state.variables, item => item._nameInLowercase);
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'variables-panel' },
            this.renderHeader(),
            this.renderListItems());
    }
}
Q_VariablePanel.defaultProps = {
    module: frontend_2.ModuleFE_Variable
};
exports.Q_VariablePanel = Q_VariablePanel;
