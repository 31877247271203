"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_RuleEditor = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@app/hcs/frontend");
class Editor_RuleEditor extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        const groups = (0, ts_common_1.groupArrayBy)(((_a = state.editable.item.tagIds) !== null && _a !== void 0 ? _a : []), tagId => {
            const tag = frontend_1.ModuleFE_Tag.cache.unique(tagId);
            if (!tag)
                throw new ts_common_1.MUSTNeverHappenException(`tag with id ${tagId} not found`);
            return tag.tagIds[0];
        });
        state.tagGroups = groups.map(group => {
            const key = frontend_1.ModuleFE_Tag.cache.unique(group.key);
            if (!key)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find tag for id ${group.key}`);
            const value = group.values.map(id => {
                const tag = frontend_1.ModuleFE_Tag.cache.unique(id);
                if (!tag)
                    throw new ts_common_1.MUSTNeverHappenException(`Could not find tag for id ${id}`);
                return tag;
            });
            return {
                key,
                value
            };
        });
        return state;
    }
    render() {
        return React.createElement(frontend_1.Component_TagEditor, { key: this.props.contextId, tags: this.state.tagGroups, groupTagFilter: () => true, singleTagFilter: (tag, groupTag) => {
                return tag.tagIds[0] === groupTag._id;
            }, onChange: async () => {
                const tagIds = this.state.tagGroups.map(tagGroup => tagGroup.value.map(tag => tag._id)).flat();
                await this.state.editable.update('tagIds', tagIds);
            }, hideDropdownDesign: true });
    }
}
exports.Editor_RuleEditor = Editor_RuleEditor;
