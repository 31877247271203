"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tupleValueMap = exports.ValType_TimeRange = exports.ValType_Time = exports.ValType_CompoundEnumerated = exports.ValType_CompoundRange = exports.ValType_StandardRange = exports.StaticValues_Tuple = exports.ValType_IND = exports.ValType_PosNeg = exports.ValType_YesNo = exports.StaticValues_EnumElements = exports.StaticValue_Increased = exports.StaticValue_Normal = exports.StaticValue_Decreased = exports.valueID_Neg = exports.valueID_Pos = exports.valueID_No = exports.valueID_Yes = exports.valueID_PosNeg = exports.valueID_YesNo = exports.ListOf_VariableTypes = exports.valueType_TimeRange = exports.valueType_Time = exports.valueType_StandardRange = exports.valueType_Tuple = exports.valueType_EnumElement = exports.valueType_SubEnumerated = exports.valueType_Enumerated = exports.valueType_SubRange = exports.valueType_Range = exports.defaultMissingValue = exports.valueType_SortedEnumerated = void 0;
exports.valueType_SortedEnumerated = 'SortedEnumerated';
exports.defaultMissingValue = 'xxx';
exports.valueType_Range = 'range';
exports.valueType_SubRange = 'sub-range';
exports.valueType_Enumerated = 'enumerated';
exports.valueType_SubEnumerated = 'sub-enumerated';
exports.valueType_EnumElement = 'enum-element';
exports.valueType_Tuple = 'tuple';
exports.valueType_StandardRange = 'standard-range';
exports.valueType_Time = 'time';
exports.valueType_TimeRange = 'time-range';
exports.ListOf_VariableTypes = [exports.valueType_Enumerated, exports.valueType_Range, exports.valueType_SubRange, exports.valueType_SubEnumerated, exports.valueType_EnumElement, exports.valueType_Tuple, exports.valueType_StandardRange, exports.valueType_TimeRange, exports.valueType_Time];
//Initial Data Exports
exports.valueID_YesNo = '7d8ad7b50ed7dd2fc6fb746132eb79d2';
exports.valueID_PosNeg = 'bdce9de53baecc6b88ebdae117ac1d5d';
exports.valueID_Yes = '1d7e2d4da55f315eac0030f6ee0d53b0';
exports.valueID_No = '56d79ad5401071daccd40675029b86f0';
exports.valueID_Pos = '01b8a4529c2c39e607020d3c991af81c';
exports.valueID_Neg = '3a4045d2f629e7a45383d3203b09ad2a';
const StaticValue_Yes = {
    _id: exports.valueID_Yes,
    type: exports.valueType_EnumElement, data: { value: 'yes' }
};
const StaticValue_No = {
    _id: exports.valueID_No,
    type: exports.valueType_EnumElement, data: { value: 'no' }
};
exports.StaticValue_Decreased = {
    _id: 'bdce9de53baecc6b88ebdae117aaaaa1',
    type: exports.valueType_EnumElement, data: { value: 'decreased' }
};
exports.StaticValue_Normal = {
    _id: 'bdce9de53baecc6b88ebdae117aaaaa2',
    type: exports.valueType_EnumElement, data: { value: 'normal' }
};
exports.StaticValue_Increased = {
    _id: 'bdce9de53baecc6b88ebdae117aaaaa3',
    type: exports.valueType_EnumElement, data: { value: 'increased' }
};
const StaticValue_Positive = {
    _id: exports.valueID_Pos,
    type: exports.valueType_EnumElement, data: { value: 'positive' }
};
const StaticValue_Negative = {
    _id: exports.valueID_Neg,
    type: exports.valueType_EnumElement, data: { value: 'negative' }
};
exports.StaticValues_EnumElements = [
    StaticValue_Yes,
    StaticValue_No,
    exports.StaticValue_Decreased,
    exports.StaticValue_Normal,
    exports.StaticValue_Increased,
    StaticValue_Positive,
    StaticValue_Negative,
];
exports.ValType_YesNo = {
    _id: exports.valueID_YesNo,
    type: exports.valueType_Tuple,
    data: {
        scope: [
            StaticValue_Yes,
            StaticValue_No
        ],
    }
};
exports.ValType_PosNeg = {
    _id: exports.valueID_PosNeg,
    type: exports.valueType_Tuple,
    data: {
        scope: [
            StaticValue_Positive,
            StaticValue_Negative
        ],
    }
};
exports.ValType_IND = {
    _id: 'bdce9de53baecc6b88ebdae117aaaaa0',
    type: exports.valueType_Tuple,
    data: {
        scope: [
            exports.StaticValue_Decreased,
            exports.StaticValue_Normal,
            exports.StaticValue_Increased
        ],
    }
};
exports.StaticValues_Tuple = [
    Object.assign(Object.assign({}, exports.ValType_YesNo), { type: exports.valueType_Tuple, data: { scope: exports.ValType_YesNo.data.scope.map(v => v._id), length: 2 } }),
    Object.assign(Object.assign({}, exports.ValType_PosNeg), { type: exports.valueType_Tuple, data: { scope: exports.ValType_PosNeg.data.scope.map(v => v._id), length: 2 } }),
    Object.assign(Object.assign({}, exports.ValType_IND), { type: exports.valueType_Tuple, data: { scope: exports.ValType_IND.data.scope.map(v => v._id), length: 3 } }),
];
exports.ValType_StandardRange = {
    type: exports.valueType_StandardRange,
    data: {
        fullRange: [0, 0],
        declarationVarIds: [],
        normalValues: [],
    }
};
exports.ValType_CompoundRange = {
    type: exports.valueType_Range,
    data: {
        subsets: [],
        subsetIds: [],
        scope: [0, 0],
    }
};
exports.ValType_CompoundEnumerated = {
    type: exports.valueType_Enumerated,
    data: {
        scope: [],
        subsets: [],
        subsetIds: [],
        props: {
            isOrdered: false,
            isExclusive: false,
        }
    }
};
exports.ValType_Time = {
    type: exports.valueType_Time,
    data: {
        timeRangesIds: [],
        timeRanges: []
    }
};
exports.ValType_TimeRange = {
    type: exports.valueType_TimeRange,
    data: {
        timeRange: [undefined, undefined]
    }
};
exports.tupleValueMap = {
    'yesno': exports.ValType_YesNo,
    'posneg': exports.ValType_PosNeg,
    'decnorinc': exports.ValType_IND,
};
