"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_CriticalityTable = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
const frontend_2 = require("@app/hcs/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Renderer_CriticalityTable.scss");
const frontend_3 = require("@app/dp/frontend");
class Renderer_CriticalityTable extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.setSort = (type) => {
            var _a;
            //If no sort or sort type is not the same
            if (((_a = this.state.sort) === null || _a === void 0 ? void 0 : _a.type) !== type)
                return this.setState({ sort: { type, dir: 'asc' } });
            //Sort type is the same
            if (this.state.sort.dir === 'asc') {
                return this.setState({ sort: { type, dir: 'dsc' } });
            }
            this.setState({ sort: undefined });
        };
        this.getDDs = () => {
            const pathway = this.state.pathway;
            const complaintsTagId = this.getComplaintsTagId();
            if (!complaintsTagId)
                return [];
            return frontend_3.ModuleFE_DiseaseProfile.cache.filter(dp => {
                return !!frontend_3.ModuleFE_MultiVar.cache.find(mv => {
                    return mv.diseaseProfileId === dp._id
                        && mv._firstVarId === pathway.variableId
                        && !!mv.variables[0].tagIds.find(tagGroup => tagGroup.value.includes(complaintsTagId));
                });
            });
        };
        this.getComplaintsTagId = () => {
            const dvTagId = frontend_2.ConfigKeyFE_DiseasesViewsTagId.get();
            const complaintsTag = frontend_2.ModuleFE_Tag.cache.find(tag => tag.tagIds.includes(dvTagId) && tag.label.toLowerCase() === 'complaints');
            if (!complaintsTag)
                this.logError('Could not find complaints tag');
            return complaintsTag === null || complaintsTag === void 0 ? void 0 : complaintsTag._id;
        };
        this.getSortedDDData = () => {
            const sort = this.state.sort;
            const dds = this.getDDs();
            let ddData = dds.map(dd => this.generateDDData(dd));
            if (this.state.filterTerm) {
                const filter = new ts_common_1.Filter(data => [data.label]);
                ddData = filter.filter(ddData, this.state.filterTerm);
            }
            if (!sort)
                return ddData;
            return (0, ts_common_1.sortArray)(ddData, dd => sort.type === 'criticality' ? dd.criticality : dd[sort.type].toLowerCase(), sort.dir === 'dsc');
        };
        this.generateDDData = (dd) => {
            var _a, _b;
            const variable = frontend_2.ModuleFE_Variable.cache.unique(dd.variableId);
            if (!variable)
                throw new ts_common_1.BadImplementationException(`Could not get variable for id ${dd.variableId}`);
            const systemsTagId = frontend_2.ConfigKeyFE_SystemsTagIds.get()[0];
            const systemGroup = variable.tagIds.find(group => group.key === systemsTagId);
            const tag = frontend_2.ModuleFE_Tag.cache.unique(systemGroup === null || systemGroup === void 0 ? void 0 : systemGroup.value[0]);
            const icdRef = frontend_2.ModuleFE_Reference.cache.filter(ref => ref.type === 'icdCode' && variable.referenceIds.includes(ref._id))[0];
            return {
                id: dd._id,
                label: dd.label,
                criticality: dd.criticality,
                icd: (_a = icdRef === null || icdRef === void 0 ? void 0 : icdRef.data.id) !== null && _a !== void 0 ? _a : '',
                system: (_b = tag === null || tag === void 0 ? void 0 : tag.label) !== null && _b !== void 0 ? _b : '',
            };
        };
        this.renderFilter = () => {
            return React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.filterTerm, onChange: value => this.setState({ filterTerm: value }) });
        };
        // ######################## Render - Table Headers ########################
        this.renderTableHeaders = () => {
            return React.createElement(frontend_1.Grid, { className: 'pathway__criticality-table__headers' },
                this.renderTableHeader('DD Name', 'label'),
                this.renderTableHeader('SnomedId', 'icd'),
                this.renderTableHeader('System', 'system'),
                this.renderTableHeader('Criticality', 'criticality'));
        };
        this.renderTableHeader = (header, type) => {
            var _a;
            const iconClass = (0, frontend_1._className)(type === ((_a = this.state.sort) === null || _a === void 0 ? void 0 : _a.type) ? this.state.sort.dir : undefined);
            return React.createElement("div", { className: 'criticality-table__header', onClick: () => this.setSort(type) },
                React.createElement("div", { className: 'criticality-table__header__text' }, header),
                React.createElement(styles_1.ICONS.sortArrows.component, { className: iconClass }));
        };
        // ######################## Render - Table Body ########################
        this.renderTableBody = () => {
            const dds = this.getSortedDDData();
            return React.createElement(frontend_2.QScrollWrapper, null,
                React.createElement(frontend_1.Grid, { className: 'criticality-table' }, dds.map(dd => {
                    return React.createElement(React.Fragment, { key: dd.id },
                        React.createElement("div", { className: 'criticality-table__data' }, dd.label),
                        React.createElement("div", { className: 'criticality-table__data' }, dd.icd || '-'),
                        React.createElement("div", { className: 'criticality-table__data' }, dd.system || '-'),
                        React.createElement("div", { className: 'criticality-table__data' }, dd.criticality ? frontend_3.CriticalityLabelMap[dd.criticality] : '-'));
                })));
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.pathway = nextProps.pathway;
        (_a = state.sort) !== null && _a !== void 0 ? _a : (state.sort = {
            type: 'criticality',
            dir: 'dsc',
        });
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'pathway__criticality-table' },
            this.renderFilter(),
            this.renderTableHeaders(),
            this.renderTableBody());
    }
}
exports.Renderer_CriticalityTable = Renderer_CriticalityTable;
