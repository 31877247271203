"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_Tag = exports.DropDown_Tag = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_Tag_1 = require("./ModuleFE_Tag");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_Tag_1.ModuleFE_Tag,
    modules: [ModuleFE_Tag_1.ModuleFE_Tag],
    mapper: item => [item.label],
    placeholder: 'Choose a Tag',
    renderer: item => React.createElement(React.Fragment, null, item.label)
};
exports.DropDown_Tag = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Tag_1.ModuleFE_Tag,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }),
            item.label);
    },
    uiSelector: exports.DropDown_Tag.selectable,
});
exports.MultiSelect_Tag = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
