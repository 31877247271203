"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_DataInputLog = exports.DataInputLog_SourceTypes = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const hcs_1 = require("@app/hcs");
exports.DataInputLog_SourceTypes = ['epic-fhir', 'epic-sql', 'physician', 'demo2.0-data'];
const Validator_ModifiableProps = {
    patientId: ts_common_1.tsValidateUniqueId,
    encounterId: ts_common_1.tsValidateUniqueId,
    source: (0, ts_common_1.tsValidateValue)(exports.DataInputLog_SourceTypes),
    provider: (0, ts_common_1.tsValidateValue)([...types_1.providers]),
    result: ts_common_1.tsValidateMustExist,
    rawQuery: ts_common_1.tsValidateMustExist,
    _parsingFailed: (0, ts_common_1.tsValidateBoolean)(false),
};
const Validator_GeneratedProps = {};
exports.DBDef_DataInputLog = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'data-input-logs',
    entityName: 'data-input-log',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'data-input-log'
    },
    backend: {
        name: 'data-input-logs'
    }
};
