"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Pathway = exports.ModuleFE_Pathway_Class = exports.dispatch_onPathwayChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const frontend_2 = require("../../pathway-state/frontend");
const frontend_3 = require("@app/hcs/frontend");
const frontend_4 = require("../../pathway-transition/frontend");
exports.dispatch_onPathwayChanged = new types_1.ThunderDispatcherV3('__onPathwayUpdated');
class ModuleFE_Pathway_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Pathway, exports.dispatch_onPathwayChanged);
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => ({ _id: i._id, uiLabel: i.label }))
            }]);
        this.__searchFilterMapper = (item) => [item.label];
        this.onPathwayDeleted = async (response) => {
            await this.onEntryDeleted(response.pathway);
            await frontend_2.ModuleFE_PathwayState.onEntriesDeleted(response.states);
            await frontend_4.ModuleFE_PathwayTransition.onEntriesDeleted(response.transitions);
            await frontend_3.ModuleFE_Expression.onEntriesDeleted(response.resolutionFunctions);
        };
        this.onPathwayCreated = async (response) => {
            await this.onEntriesUpdated([response.pathway]);
            await frontend_2.ModuleFE_PathwayState.onEntriesUpdated(response.states);
        };
        this._v1 = {
            duplicatePathway: (0, frontend_1.apiWithBody)(shared_1.ApiDef_Pathway._v1.duplicatePathway, async (res) => {
                await this.onEntriesUpdated([res.newPathway]);
                await frontend_2.ModuleFE_PathwayState.onEntriesUpdated(res.newStates);
                await frontend_4.ModuleFE_PathwayTransition.onEntriesUpdated(res.newTransitions);
                await frontend_3.ModuleFE_Expression.onEntriesUpdated(res.newResolutionFunctions);
            }),
            deletePathway: (0, frontend_1.apiWithQuery)(shared_1.ApiDef_Pathway._v1.deletePathway, this.onPathwayDeleted),
            createPathway: (0, frontend_1.apiWithBody)(shared_1.ApiDef_Pathway._v1.createPathway, this.onPathwayCreated),
        };
    }
    init() {
        super.init();
        frontend_3.ModuleFE_Discussion.registerLabelFromEntityConverter(this.dbDef.dbKey, (pathway) => pathway.label);
    }
}
exports.ModuleFE_Pathway_Class = ModuleFE_Pathway_Class;
exports.ModuleFE_Pathway = new ModuleFE_Pathway_Class();
