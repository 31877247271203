"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ResolutionFunctionEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const Header_Pathway_1 = require("../Page_PathwayEditor/headers/Header_Pathway");
const Header_ResolutionFunctionEditor_1 = require("./headers/Header_ResolutionFunctionEditor");
require("./Component_ResolutionFunctionEditor.scss");
const dispatchers_1 = require("./dispatchers");
const _entity_1 = require("../../_entity");
const Component_ResolutionFunctionCard_1 = require("./components/Component_ResolutionFunctionCard");
const const_1 = require("./const");
const TS_ListOrganizer_1 = require("@nu-art/thunderstorm/frontend/components/TS_ListOrganizer");
const frontend_2 = require("@app/hcs/_entity/expression/frontend");
const dispatchers_2 = require("../Page_PathwayEditor/dispatchers");
class Component_ResolutionFunctionEditor extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.termsListRef = React.createRef();
        this.headerMenuActions = [
            {
                label: 'HCS Variables', action: () => {
                    const resolutionFunctions = Object.values(this.state.mapTransitionIdToEditableExpression).map(editable => editable.item);
                    dispatchers_1.dispatch_showResolutionFunctionEditorPanel.dispatchUI({ resolutionFunctions: resolutionFunctions });
                }
            },
            //TODO: add when possible
            // {
            // 	label: 'Validate',
            // 	action: () => {
            // 	}
            // },
        ];
        this.addListItem = async (_resolutionFunction) => {
            const availableTransitionsIds = this.getAvailableTransitionIds();
            if (_resolutionFunction === null || _resolutionFunction === void 0 ? void 0 : _resolutionFunction._id)
                _resolutionFunction = (0, ts_common_1.removeDBObjectKeys)(_resolutionFunction);
            if (!availableTransitionsIds.length)
                return frontend_1.ModuleFE_Toaster.toastError('Cannot add another term, all transitions are already in use');
            const transition = _entity_1.ModuleFE_PathwayTransition.cache.unique(availableTransitionsIds[0]);
            const resolutionFunction = this.createEditableItem(transition, _resolutionFunction);
            this.state.transitionIds.push(availableTransitionsIds[0]);
            this.state.mapTransitionIdToEditableExpression[availableTransitionsIds[0]] = resolutionFunction;
            this.forceUpdate();
        };
        this.onCollapse = (transitionId) => {
            this.state.collapsedMap[transitionId] = !this.state.collapsedMap[transitionId];
            this.forceUpdate();
        };
        this.collapseExpandAll = () => {
            const shouldCollapse = (0, ts_common_1._values)(this.state.collapsedMap).some(value => !value);
            const newCollapseMap = (0, ts_common_1.reduceToMap)((0, ts_common_1._keys)(this.state.collapsedMap), item => item, item => {
                return shouldCollapse;
            });
            this.setState({ collapsedMap: newCollapseMap });
        };
        this.handleBrowserNavigation = async (e) => {
            e.preventDefault();
            if (this.props.selectedTransitionId) {
                this.props.functions.selectPathwayTransition(this.props.selectedTransitionId);
            }
            const pathwayTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(this.props.pathwayTransitionIds[0]);
            if (pathwayTransition && !this.props.selectedTransitionId) {
                console.log(this.props.selectedTransitionId);
                this.props.functions.selectPathwayState(pathwayTransition.sourceIds[0]);
            }
            await this.props.functions.deselectResolutionFunctionEditor();
        };
        // ######################## Render ########################
        this.renderTopButtons = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'pathway__top-buttons' },
                React.createElement(frontend_1.TS_BusyButton, { className: 'pathway__top-buttons__back', onClick: async () => {
                        this.props.functions.deselectPathway();
                    } }, '< Back to list'));
        };
        this.renderHeader = () => {
            const pathwayTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(this.props.pathwayTransitionIds[0]);
            const pathwayState = _entity_1.ModuleFE_PathwayState.cache.unique(pathwayTransition === null || pathwayTransition === void 0 ? void 0 : pathwayTransition.sourceIds[0]);
            const pathway = _entity_1.ModuleFE_Pathway.cache.unique(pathwayState === null || pathwayState === void 0 ? void 0 : pathwayState.pathwayId);
            return React.createElement(React.Fragment, null,
                this.renderTopButtons(),
                React.createElement(Header_Pathway_1.Header_Pathway, { pathway: new frontend_1.EditableDBItemV3(pathway, _entity_1.ModuleFE_Pathway), allowActions: false, functions: this.props.functions, clickableTitle: true, titleClickAction: async () => {
                        this.props.functions.deselectPathwayState();
                    }, isClickAsync: true }),
                React.createElement(Header_ResolutionFunctionEditor_1.Header_ResolutionFunction, { expandCollapseAll: this.collapseExpandAll, expandStatus: (0, ts_common_1._values)(this.state.collapsedMap).some(value => !value), editables: (0, ts_common_1._values)(this.state.mapTransitionIdToEditableExpression), availableTransitionsIds: this.getAvailableTransitionIds(), deselectResolutionFunction: this.props.functions.deselectResolutionFunctionEditor, state: pathwayState, actions: this.headerMenuActions, allowActions: true }));
        };
        this.renderListCard = (props) => {
            const originTransitionId = props.item;
            const resolutionClassName = (0, frontend_1._className)(this.state.selectedTransitionId === originTransitionId && this.state.focusEditor && 'attention-grabber');
            const editable = this.state.mapTransitionIdToEditableExpression[originTransitionId];
            return React.createElement(Component_ResolutionFunctionCard_1.Component_ResolutionFunctionCard, { className: resolutionClassName, key: `term-${props.index}`, index: props.index, collapsed: this.state.collapsedMap[originTransitionId], onCollapse: this.onCollapse, onDragStart: props.onDragStart, onDragEnd: props.onDragEnd, onDragLeave: props.onDragLeave, onDragOver: props.onDragOver, onTransitionChanged: (newTransitionId => {
                    if (newTransitionId) {
                        const index = this.state.transitionIds.indexOf(originTransitionId);
                        const transition = _entity_1.ModuleFE_PathwayTransition.cache.unique(newTransitionId);
                        if (!transition)
                            throw new ts_common_1.MUSTNeverHappenException(`transition with id ${newTransitionId} not found`);
                        const newEditable = this.createEditableItem(transition, editable.item);
                        const newTransitionsId = [...this.state.transitionIds];
                        const newMapper = Object.assign({}, this.state.mapTransitionIdToEditableExpression);
                        newTransitionsId.splice(index, 1, newTransitionId);
                        delete newMapper[originTransitionId];
                        newMapper[newTransitionId] = newEditable;
                        this.setState({
                            transitionIds: newTransitionsId,
                            mapTransitionIdToEditableExpression: newMapper
                        }, () => newEditable.save());
                    }
                }), availableTransitionIds: this.getAvailableTransitionIds(), transitionId: originTransitionId, editable: editable });
        };
        this.renderBody = () => {
            const pathwayTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(this.props.pathwayTransitionIds[0]);
            const pathwayState = _entity_1.ModuleFE_PathwayState.cache.unique(pathwayTransition === null || pathwayTransition === void 0 ? void 0 : pathwayTransition.sourceIds[0]);
            return React.createElement(TS_ListOrganizer_1.TS_ListOrganizer, { items: this.state.transitionIds, renderer: this.renderListCard, onOrderChanged: async (list) => {
                    if (list.every((id, index) => id === this.state.transitionIds[index]))
                        return;
                    await _entity_1.ModuleFE_PathwayTransition._v1.updateTransitionsPriority({
                        stateTransitionIds: list,
                        stateId: pathwayState._id
                    }).executeSync();
                } });
        };
    }
    // ######################## Life Cycle ########################
    __onExpressionUpdated(...params) {
        const selectedTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(this.props.selectedTransitionId);
        if (!selectedTransition || !selectedTransition.resolutionFunctionId)
            return;
        switch (params[0]) {
            case 'delete':
            case 'delete-multi':
                this.updateUIState();
                break;
            case 'update':
            case 'upsert-all':
            case 'create':
                this.updateUIState();
        }
    }
    __onPathwayTransitionUpdated(...params) {
        switch (params[0]) {
            case 'delete':
            case 'delete-multi': {
                this.updateUIState();
                break;
            }
            case 'update':
            case 'upsert-all':
            case 'create':
                this.updateUIState();
        }
    }
    updateUIState() {
        const relevantTransitionIds = (0, ts_common_1.sortArray)(this.state.transitionIds, item => {
            const transitionDoc = _entity_1.ModuleFE_PathwayTransition.cache.unique(item);
            if (!transitionDoc)
                throw new ts_common_1.MUSTNeverHappenException(`transition with id ${item} not found`);
            return transitionDoc.priorityIndex;
        });
        this.setState({
            mapTransitionIdToEditableExpression: this.getEditableItemsMapper(relevantTransitionIds),
            transitionIds: relevantTransitionIds
        });
    }
    async __onDuplicateTransitionTerm(term) {
        await this.addListItem(term);
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c;
        (_a = state.mapTransitionIdToEditableExpression) !== null && _a !== void 0 ? _a : (state.mapTransitionIdToEditableExpression = this.getEditableItemsMapper(nextProps.pathwayTransitionIds));
        (_b = state.transitionIds) !== null && _b !== void 0 ? _b : (state.transitionIds = nextProps.pathwayTransitionIds);
        state.selectedTransitionId = nextProps.selectedTransitionId;
        (_c = state.collapsedMap) !== null && _c !== void 0 ? _c : (state.collapsedMap = (0, ts_common_1.reduceToMap)(state.transitionIds, item => item, item => true));
        if (state.selectedTransitionId)
            state.focusEditor = true;
        return state;
    }
    getEditableItemsMapper(editingTransitionIds) {
        return (0, ts_common_1.reduceToMap)(editingTransitionIds, id => id, transitionId => {
            const transition = _entity_1.ModuleFE_PathwayTransition.cache.unique(transitionId);
            const expression = frontend_2.ModuleFE_Expression.cache.unique(transition === null || transition === void 0 ? void 0 : transition.resolutionFunctionId);
            return this.createEditableItem(transition, expression);
        });
    }
    createEditableItem(transition, expression) {
        if (!transition || !transition._id)
            throw new ts_common_1.MUSTNeverHappenException('can not create new resolution function without transition');
        const editableExpression = new frontend_1.EditableDBItemV3(expression || (0, const_1.getEmptyResolutionFunction)(transition), frontend_2.ModuleFE_Expression)
            .setAutoSave(!!(expression === null || expression === void 0 ? void 0 : expression._id));
        const onChange = async (editable) => {
            this.setState({
                mapTransitionIdToEditableExpression: Object.assign(Object.assign({}, this.state.mapTransitionIdToEditableExpression), { [transition._id]: editable })
            }, () => dispatchers_2.dispatch_FocusResolutionFunction.dispatchUI(transition._id, editable, true));
        };
        const saveAction = async (item) => {
            const currentItem = this.state.mapTransitionIdToEditableExpression[transition._id];
            // In order to save with errors we should catch the potential validation error and continue with the save
            //process. it's a bit of a hack so as saving with errors which is bad anyway
            try {
                currentItem.validate();
            }
            catch (err) {
                this.logError(err);
            }
            try {
                const body = {
                    transitionId: transition._id,
                    resolutionFunction: item
                };
                const { resolutionFunction } = await _entity_1.ModuleFE_PathwayTransition._v1.updateResolutionFunction(body).executeSync();
                return resolutionFunction;
            }
            catch (e) {
                return item;
            }
        };
        const deleteAction = async (item) => {
            // If needed delete from the db, otherwise only remove in the ui
            const resolutionFunctionId = item._id;
            if (resolutionFunctionId) {
                await _entity_1.ModuleFE_PathwayTransition._v1.deleteResolutionFunction({ transitionId: transition._id }).executeSync();
                frontend_1.ModuleFE_Toaster.toastSuccess('Term deleted successfully');
            }
            this.state.mapTransitionIdToEditableExpression[transition._id] = this.createEditableItem(transition);
            this.forceUpdate();
        };
        if (editableExpression.get('_id'))
            editableExpression.validate();
        editableExpression
            .setOnChanged(onChange)
            .setSaveAction(saveAction)
            .setOnDelete(deleteAction);
        return editableExpression;
    }
    componentDidMount() {
        const pathwayTransition = _entity_1.ModuleFE_PathwayTransition.cache.unique(this.props.pathwayTransitionIds[0]);
        if (!pathwayTransition)
            throw new ts_common_1.MUSTNeverHappenException(`transition with id ${this.props.pathwayTransitionIds[0]} not found`);
        history.pushState({ page: 1 }, 'pathway-v2', `?id=${pathwayTransition.pathwayId}&type=pathway`);
        // select resolution function editable in case theres a selected transition when mounting
        if (this.state.selectedTransitionId) {
            const editable = this.state.mapTransitionIdToEditableExpression[this.state.selectedTransitionId];
            dispatchers_2.dispatch_FocusResolutionFunction.dispatchUI(this.state.selectedTransitionId, editable);
        }
        if (this.state.focusEditor)
            if (this.props.selectedTransitionId && this.termsListRef.current) {
                const termItem = document.querySelector(`[data-term-item="${this.props.selectedTransitionId}"]`);
                if (termItem)
                    (0, frontend_1.scrollToTop)(termItem, this.termsListRef, 'smooth');
            }
        window.addEventListener('popstate', this.handleBrowserNavigation);
    }
    componentWillUnmount() {
        window.removeEventListener('popstate', this.handleBrowserNavigation);
    }
    getAvailableTransitionIds() {
        return this.props.pathwayTransitionIds.filter(transitionId => !this.state.transitionIds.includes(transitionId));
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { innerRef: this.termsListRef, className: 'match_parent resolution-function-editor', style: { padding: '30px', gap: '10px' } },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Component_ResolutionFunctionEditor = Component_ResolutionFunctionEditor;
