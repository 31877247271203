"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceConfig_LogTemplateManager = exports.workspaceKey_LogTemplate = void 0;
exports.workspaceKey_LogTemplate = 'workspace__manager-log-template';
exports.WorkspaceConfig_LogTemplateManager = {
    name: exports.workspaceKey_LogTemplate,
    key: 'horizontalSpace',
    visible: true,
    factor: 1,
    min: -1,
    data: {
        panels: [
            {
                name: 'LeftPanel', key: 'selector', visible: true, factor: 1.5 / 5, min: 450,
                data: {}
            },
            {
                name: 'RightPanel', key: 'presenter', visible: true, factor: 3.5 / 5, min: 1100,
                data: {}
            },
        ]
    }
};
