"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitExpression = void 0;
const splitExpression = (expression) => {
    const result = [];
    const regex = /\b(var|enum)\([^)]+\)/g;
    let match;
    let lastEnd = 0;
    while ((match = regex.exec(expression)) !== null) {
        const beforeMatch = expression.slice(lastEnd, match.index);
        result.push(...beforeMatch.split(/\s*(===|\+|\-|\*|\/|%|\^|!|\||&|<|>|<=|>=)\s*/).filter(part => part.trim() !== ''));
        result.push(match[0]);
        lastEnd = match.index + match[0].length;
    }
    const afterMatch = expression.slice(lastEnd);
    result.push(...afterMatch.split(/\s*(===|\+|\-|\*|\/|%|\^|!|\||&|<|>|<=|>=)\s*/).filter(part => part.trim() !== ''));
    return result;
};
exports.splitExpression = splitExpression;
