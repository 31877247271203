"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_ParamDeclaration = exports.DropDown_LogTemplateExternalParamType = exports.Dropdown_LogTemplateParamsType = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../shared");
const consts_1 = require("@app/styles/frontend/ui/consts");
const mandatoryProps_LogTemplateTypeDropdown = {
    adapter: (0, frontend_1.SimpleListAdapter)([...shared_1.LogTemplateTypesKeys], item => React.createElement(React.Fragment, null, shared_1.LogTemplateType_ValueToLabelMapper[item.item])),
    caret: consts_1.DropDown_DefaultCaret,
    placeholder: 'Select parameter type',
    className: 'log-templates__type-dropdown'
};
exports.Dropdown_LogTemplateParamsType = frontend_1.TS_DropDown.prepare(mandatoryProps_LogTemplateTypeDropdown);
const mandatoryProps_LogTemplateExternalParamTypeDropdown = {
    adapter: (0, frontend_1.SimpleListAdapter)([...shared_1.LogTemplateExternalParams], item => React.createElement(React.Fragment, null, shared_1.LogTemplateExternalParams_ValueToLabelMapper[item.item])),
    caret: consts_1.DropDown_DefaultCaret,
    placeholder: 'Select external param type',
    className: 'log-templates__type-dropdown'
};
exports.DropDown_LogTemplateExternalParamType = frontend_1.TS_DropDown.prepare(mandatoryProps_LogTemplateExternalParamTypeDropdown);
const mandatoryProps_LogTemplateParamDeclaration = {
    adapter: (0, frontend_1.SimpleListAdapter)(['Static Param', 'Dynamic Param'], item => React.createElement(React.Fragment, null, item.item)),
    caret: consts_1.DropDown_DefaultCaret,
    className: 'log-templates__declaration-dropdown'
};
exports.DropDown_ParamDeclaration = frontend_1.TS_DropDown.prepare(mandatoryProps_LogTemplateParamDeclaration);
