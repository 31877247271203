"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_MultiVar = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_MultiVar = {
    _v1: {
        deleteMultiVarColumn: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/multi-var/delete-multi-var-column' },
        deleteMultiVar: { method: thunderstorm_1.HttpMethod.GET, path: '/v1/multi-var/delete-multi-var' },
        getAttributeList: { method: thunderstorm_1.HttpMethod.GET, path: '/v1/multi-var/get-attribute-list' }
    }
};
