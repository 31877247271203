"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Dialog_ResolveDependenciesConflict.scss");
const React = __importStar(require("react"));
const styles_1 = require("@app/styles");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const _entity_1 = require("../../../_entity");
/**
 * This entire component is garbage and in desperate need of refactor...
 * Address asap
 */
class Dialog_ResolveDependenciesConflicts extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderTargetToDelete = (targetDbKey, targetId) => {
            const targetDbModule = this.getModuleByDbKey(targetDbKey);
            if (!targetDbModule)
                return `Failed to find dbModule for dbKey ${targetDbKey}`;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Deletion Target' },
                React.createElement(frontend_1.LL_H_C, { className: 'target_header' },
                    React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Collection' },
                        React.createElement("div", { className: 'target-collection-name' }, targetDbKey)),
                    this.renderCollectionItem(targetDbKey, targetDbModule.cache.unique(targetId))));
        };
    }
    deriveStateFromProps(nextProps, state) {
        const mappedConflicts = nextProps.conflictData.reduce((sum, current) => {
            current.issues.forEach(conflict => {
                var _a;
                //If somehow received empty list of issues, ignore
                if (conflict.conflicts.ids.length === 0)
                    return sum;
                const targetStringIdentifier = `${conflict.target.dbKey}${conflict.target.id}`;
                const currentConflict = (_a = sum[targetStringIdentifier]) !== null && _a !== void 0 ? _a : {};
                //First TypedMap is target hashed dbKey/id, to target and map of issues by dbKey.
                //The target is always the same target.
                currentConflict.target = conflict.target;
                //Initialize if doesn't exist...
                if (!currentConflict.mappedDbKeyToIssues)
                    currentConflict.mappedDbKeyToIssues = {};
                //2nd TypedMap is dbKey to EntityRefs
                currentConflict.mappedDbKeyToIssues[conflict.conflicts.dbKey] = conflict.conflicts;
                sum[targetStringIdentifier] = currentConflict;
            });
            return sum;
        }, {});
        //Spread the conflicts best for rendering, array of target to array of issues by dbKey to object ids
        state.conflicts = (0, ts_common_1._values)(mappedConflicts).map(conflictItem => ({
            target: conflictItem.target,
            issues: (0, ts_common_1._values)(conflictItem.mappedDbKeyToIssues)
        }));
        //todo Why do I need DV tag group id???
        state.diseaseViewTagGroupId = frontend_1.ModuleFE_AppConfig.cache.find(config => config.key === 'diseaseViewTagId').data;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'resolve-dependency-conflicts-panel', className: 'dialog' },
            this.renderHeader(),
            this.state.conflicts.length ?
                React.createElement(frontend_1.LL_V_L, { className: 'target-container' }, this.state.conflicts.map(conflictSum => {
                    const targetDbKey = conflictSum.target.dbKey;
                    return React.createElement("div", { key: `${conflictSum.target.dbKey}${conflictSum.target.id}`, className: 'target-to-delete' },
                        this.renderTargetToDelete(targetDbKey, conflictSum.target.id),
                        conflictSum.issues.map(issue => {
                            const issueModule = this.getModuleByDbKey(issue.dbKey);
                            if (!issueModule)
                                return `Failed to find dbModule for dbKey ${issue.dbKey}`;
                            return React.createElement(frontend_1.TS_CollapsableContainer, { key: `${conflictSum.target.dbKey}${conflictSum.target.id}${issue.dbKey}`, className: 'collapsable', showCaret: true, customCaret: React.createElement(styles_1.ICONS.advisor_v4_arrow.component, null), headerRenderer: this.renderCollectionName(issue.dbKey), containerRenderer: this.renderCollectionIssuesByIds(issueModule, issue.ids) });
                        }));
                })) :
                React.createElement(frontend_1.LL_V_L, { className: 'match_width' }, "Nothing to show here, good job!"));
    }
    getModuleByDbKey(dbKey) {
        return (0, ts_common_1.RuntimeModules)()
            .filter(module => !!module.getCollectionName)
            .find(module => module.dbDef.dbKey === dbKey);
    }
    getLabelForDbItem(dbKey, dbItem) {
        switch (dbKey) {
            case frontend_2.ModuleFE_Value.dbDef.dbKey:
                return this.getValueTextualView(dbItem);
            case frontend_2.ModuleFE_Variable.dbDef.dbKey:
                return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Variable' }, dbItem.name);
            case _entity_1.ModuleFE_DiseaseValue.dbDef.dbKey:
                return this.getDiseaseValueTextualView(dbItem);
            case _entity_1.ModuleFE_DiseaseProfile.dbDef.dbKey:
                return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Disease Profile' }, dbItem.label);
            case _entity_1.ModuleFE_MultiVar.dbDef.dbKey:
                return this.getMultiVarTextualView(dbItem);
            case frontend_2.ModuleFE_VariableRelation.dbDef.dbKey:
                return this.getVariableRelationTextualView(dbItem);
            case frontend_2.ModuleFE_Expression.dbDef.dbKey:
                return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Expression' }, dbItem.label);
            default:
                break;
        }
    }
    renderCollectionItem(dbKey, dbItem) {
        return this.getLabelForDbItem(dbKey, dbItem);
    }
    renderCollectionName(dbKey) {
        //todo translate dbKeys to presentable names
        return React.createElement("div", { className: 'ts-collapsable-container__header__text' }, dbKey);
    }
    renderCollectionIssuesByIds(collectionModule, ids) {
        const dbItems = ids.map(id => collectionModule.cache.unique(id));
        return dbItems.map(item => {
            return this.renderCollectionItem(collectionModule.dbDef.dbKey, item);
        });
    }
    renderHeader() {
        return React.createElement(frontend_1.LL_H_C, { className: "dialog__header header-position" },
            React.createElement("span", { className: "header-title" }, this.state.conflicts.length ? 'Deletion Conflicts'
                : 'All clean and tidy'),
            React.createElement(styles_1.ICONS.x.component, { className: 'exit_icon', onClick: (e) => {
                    e.stopPropagation();
                    frontend_2.dispatch_onTriggerPanel.dispatchUI();
                } }));
    }
    getVariableRelationTextualView(variableRelation) {
        if (!variableRelation)
            return 'Not Found';
        const baseVariable = frontend_2.ModuleFE_Variable.cache.unique(variableRelation._firstVariableId);
        const comparedVariables = variableRelation.rightSide.map(item => frontend_2.ModuleFE_Variable.cache.unique(item.variableId));
        return `${baseVariable === null || baseVariable === void 0 ? void 0 : baseVariable.name} ${variableRelation.relation} ${comparedVariables.map((currVar, index) => currVar.name + (index !== comparedVariables.length - 1 ? ',' : ''))}`;
    }
    getValueTextualView(value) {
        if (!value)
            return 'Value Not Found';
        let variable;
        if (value.type === 'enum-element') {
            const parentValue = frontend_2.ModuleFE_Value.cache.find(_val => _val.__valueIds.includes(value._id));
            if (!parentValue)
                return 'Value Not Found';
            variable = frontend_2.ModuleFE_Variable.cache.find(variable => variable.valueId === parentValue._id);
        }
        else if (value.type === 'enumerated') {
            variable = frontend_2.ModuleFE_Variable.cache.find(variable => variable.valueId === value._id);
        }
        if (!variable)
            return 'Correlated Variable Not Found';
        return React.createElement(frontend_1.LL_H_T, { className: 'value-textual-row' },
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Variable' }, `${variable.name}, `),
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Value' }, value.type === 'enum-element' ? value.data.value : ''));
    }
    getDiseaseValueTextualView(diseaseValue) {
        if (!diseaseValue)
            return 'Disease Value Not Found';
        const diseaseProfile = _entity_1.ModuleFE_DiseaseProfile.cache.unique(diseaseValue._diseaseProfileId);
        if (!diseaseProfile) //A DiseaseValue is meaningless without a Disease Profile
            return 'Correlated Disease Profile Not Found';
        const multivar = _entity_1.ModuleFE_MultiVar.cache.unique(diseaseValue.multiVarId);
        if (!multivar) //A DiseaseValue is meaningless without a Variable
            return 'Correlated MultiVar Not Found';
        const variable = frontend_2.ModuleFE_Variable.cache.unique(multivar === null || multivar === void 0 ? void 0 : multivar._firstVarId);
        if (!variable)
            return 'Correlated Variable Not Found';
        return React.createElement(frontend_1.LL_H_T, { className: 'diseasevalue-textual-row' },
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Disease Profile' }, `${diseaseProfile.label}, `),
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'MultiVar' }, `${variable.name}, `),
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Value' }, ''));
    }
    getMultiVarTextualView(multiVar) {
        if (!multiVar)
            return 'Not Found';
        const diseaseProfile = _entity_1.ModuleFE_DiseaseProfile.cache.unique(multiVar.diseaseProfileId);
        if (!diseaseProfile) //A MultiVar is meaningless without a Disease Profile
            return 'Correlated Disease Profile Not Found';
        const variable = frontend_2.ModuleFE_Variable.cache.unique(multiVar._firstVarId);
        if (!variable) //A MultiVar is meaningless without a Variable
            return 'Correlated Variable Not Found';
        const tags = multiVar.variables[0].tagIds;
        const tagGroupIndex = tags.findIndex(_tg => _tg.key === this.state.diseaseViewTagGroupId);
        let diseaseView;
        if (tagGroupIndex !== -1)
            diseaseView = frontend_2.ModuleFE_Tag.cache.unique(tags[tagGroupIndex].value[0]);
        return React.createElement(frontend_1.LL_H_C, { className: 'multivar-textual-row' },
            React.createElement(frontend_1.Show, null,
                React.createElement(frontend_1.Show.If, { condition: true },
                    React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Disease Profile' }, `${diseaseProfile.label}${diseaseView ? ', ' : ''}`)),
                React.createElement(frontend_1.Show.If, { condition: !!diseaseView },
                    React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Disease View' }, `${diseaseView === null || diseaseView === void 0 ? void 0 : diseaseView.label}, `)),
                React.createElement(frontend_1.Show.If, { condition: true },
                    React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Variable' }, variable.name))));
    }
}
exports.default = Dialog_ResolveDependenciesConflicts;
