"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Variables = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Panel_Variables.scss");
const wrappers_1 = require("../../../../wrappers");
const q_components_1 = require("../../../q-components");
const Dialogs_1 = require("../Dialogs");
const shared_components_1 = require("../../../shared-components");
const styles_1 = require("@app/styles");
const Tooltip_VariableInformation_1 = require("../Tooltip_VariableInformation");
const messaging_1 = require("../../../messaging");
const _entity_1 = require("../../../../_entity");
const frontend_2 = require("@nu-art/permissions/frontend");
const permissions_1 = require("../../../../permissions");
class Panel_Variables extends frontend_1.ProtoComponent {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        //######################### Logic #########################
        this.onVariableSelected = (selectedVariable) => {
            const selected = Object.assign({}, this.getQueryParam('selected', {}));
            if (!selectedVariable)
                delete selected[_entity_1.DBDef_Variable.dbKey];
            else
                selected[_entity_1.DBDef_Variable.dbKey] = selectedVariable._id;
            this.setQueryParam('selected', selected);
        };
        this.openHeaderMenu = (e) => {
            (0, frontend_1.stopPropagation)(e);
            const menuItems = [
                {
                    label: 'Add Variable',
                    onClick: () => {
                        const selectedTagId = this.getQueryParam('selected', {})[_entity_1.DBDef_Tag.dbKey];
                        if (!selectedTagId) {
                            new frontend_1.ToastBuilder().setDuration(3 * ts_common_1.Second)
                                .setContent((0, q_components_1.QToastContent)({
                                iconKey: 'infoToast',
                                content: 'Please select a category first!',
                                toastType: 'info'
                            }))
                                .show();
                            return;
                        }
                        Dialogs_1.Dialog_VariableEditor.show(selectedTagId);
                    }
                },
                {
                    label: 'Export Variables',
                    onClick: () => {
                        const patientData = {
                            fileName: `hsc-variables`,
                            content: (0, ts_common_1.__stringify)(this.state.variables.reduce((toRet, v) => {
                                toRet[v.name] = v._id;
                                return toRet;
                            }, {}), true),
                            charset: 'UTF-8',
                            mimeType: 'application/json'
                        };
                        return frontend_1.ModuleFE_Thunderstorm.downloadFile(patientData);
                    }
                }
            ];
            const adapter = (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label));
            const modalId = 'panel-variables__right-click-menu';
            const pos = (0, frontend_1.getElementCenterPos)(e.currentTarget);
            new frontend_1.MenuBuilder(adapter, pos, { x: 0, y: 1 })
                .setId(modalId)
                .setOffset({ x: 0, y: 10 })
                .setOnClick((path, item) => {
                var _a;
                frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
            })
                .show();
        };
    }
    //######################### Life Cycle #########################
    __onVariableUpdated(...params) {
        switch (params[0]) {
            case frontend_1.EventType_Query:
            case frontend_1.EventType_Create:
                this.onVariableSelected(Array.isArray(params[1]) ? params[1][0] : params[1]);
                this.reDeriveState();
                break;
            case frontend_1.EventType_Delete:
            case frontend_1.EventType_DeleteMulti:
                this.onVariableSelected();
                this.reDeriveState();
                break;
            // case EventType_Update:
            // 	this.reDeriveState({selectedVariable: undefined});
            // 	return;
        }
    }
    deriveStateFromProps(nextProps, state) {
        const selected = Object.assign({}, this.getQueryParam('selected', {}));
        const selectedTagId = selected[_entity_1.DBDef_Tag.dbKey];
        const selectedVariableId = selected[_entity_1.DBDef_Variable.dbKey];
        const selectedVariable = _entity_1.ModuleFE_Variable.cache.unique(selectedVariableId);
        const filter = this.getQueryParam('filterVariables');
        //Define variables
        if (!selectedTagId) {
            state.variables = [];
            return state;
        }
        state.variables = _entity_1.ModuleFE_Variable.cache.filter(variable => variable._tagIds.includes(selectedTagId));
        //No selected variable
        if (!selectedVariableId || !selectedVariable) {
            if (selectedVariableId && !selectedVariable)
                this.logError('Missing Variable', `tried to get a variable for id ${selectedVariableId} but it wasn't in the cache`, state.variables.length ? `Setting new selected variable id ${state.variables[0]._id}` : 'Clearing selected variable id from URL params');
            if (state.variables.length)
                selected[_entity_1.DBDef_Variable.dbKey] = state.variables[0]._id;
            else
                delete selected[_entity_1.DBDef_Variable.dbKey];
            this.setQueryParam('selected', selected);
            //returning state here. next derive run should continue with the new data
            return state;
        }
        //Filter variables
        if (filter && filter.length >= 3) {
            const _filter = new ts_common_1.Filter(variable => new wrappers_1.VariableWrapper(variable).getSearchableText());
            state.variables = _filter.filterSort(state.variables, filter);
        }
        //If the selected variable did not pass the filter
        if (selectedVariable && state.variables.findIndex(variable => variable._id === selectedVariable._id) === -1) {
            (0, ts_common_1.addItemToArrayAtIndex)(state.variables, selectedVariable, 0);
        }
        return state;
    }
    //######################### Render #########################
    renderHeader() {
        const selectedTagId = this.getQueryParam('selected', {})[_entity_1.DBDef_Tag.dbKey];
        const selectedTag = _entity_1.ModuleFE_Tag.cache.unique(selectedTagId);
        const filter = this.getQueryParam('filterVariables');
        return React.createElement(shared_components_1.Panel_Header, { config: { filter }, onFilterChanged: (filter) => {
                this.setQueryParam('filterVariables', filter);
            }, qsearchId: 'panel-variable-search', headerClassName: 'panel__variables__header', title: { label: (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.label) || 'Variables', className: 'panel__variables__header__title' }, headerTail: React.createElement("div", { className: 'panel__variables__header__icon' },
                React.createElement(styles_1.ICONS.more.component, { onClick: e => {
                        return this.openHeaderMenu(e);
                    } })) });
    }
    renderList() {
        if (!this.state.variables.length)
            return this.renderMessage('No variables to show');
        const selected = this.getQueryParam('selected', {});
        const selectedTagId = selected[_entity_1.DBDef_Tag.dbKey];
        const selectedVariableId = selected[_entity_1.DBDef_Variable.dbKey];
        return (React.createElement("div", { className: 'list-wrapper' },
            React.createElement(frontend_1.TS_VirtualizedList, { itemHeight: 50, className: 'variable-list custom-scrollbar__vertical', listToRender: this.state.variables.map((variable, i) => {
                    var _a;
                    const uiVariableObject = new wrappers_1.VariableWrapper(variable);
                    const value = uiVariableObject === null || uiVariableObject === void 0 ? void 0 : uiVariableObject.getValue();
                    let measurementUnitString;
                    if ((value === null || value === void 0 ? void 0 : value.type) === _entity_1.valueType_Range)
                        measurementUnitString = (_a = value.data.measurementUnit) === null || _a === void 0 ? void 0 : _a.unitAsString;
                    const className = (0, frontend_1._className)('var-item', variable._id === selectedVariableId ? 'selected' : undefined);
                    return React.createElement(frontend_1.LL_H_C, { className: className, style: { justifyContent: 'space-between' }, key: `${variable._id}-${i}`, onClick: () => this.onVariableSelected(variable), onContextMenu: async (e) => {
                            const discussionView = {
                                id: 'discussion-right-click-menu',
                                content: React.createElement(messaging_1.CreateDiscussionPopup, { entityName: _entity_1.ModuleFE_Variable.dbDef.entityName, refId: variable._id }),
                                originPos: {
                                    y: e.clientY,
                                    x: e.clientX,
                                },
                                modalPos: { x: 0, y: 1 },
                            };
                            try {
                                frontend_1.ModuleFE_MouseInteractivity.showContent(discussionView);
                            }
                            catch (e) {
                                this.logWarning(e);
                            }
                        } },
                        React.createElement(frontend_1.LL_V_L, { className: (0, frontend_1._className)('var-name') },
                            `${variable.name}${measurementUnitString ? ` (${measurementUnitString})` : ''}`,
                            React.createElement("span", { className: 'filter-pass-reason' }, uiVariableObject.renderVarFilterPassReason(variable.name))),
                        React.createElement(frontend_1.LL_H_C, { className: (0, frontend_1._className)('var-item-tail') },
                            React.createElement(frontend_2.PermissionsComponent, { permissionKey: permissions_1.PermissionKeyFE_HCSEdit },
                                React.createElement("div", { className: 'edit-button', onClick: (e) => {
                                        (0, frontend_1.stopPropagation)(e);
                                        Dialogs_1.Dialog_VariableEditor.show(selectedTagId, variable);
                                    } },
                                    React.createElement(styles_1.ICONS.edit.component, null))),
                            React.createElement("div", Object.assign({ className: 'variable-info-tooltip-icon' }, frontend_1.openContent.tooltip.right('variable-info-tooltip', () => React.createElement(Tooltip_VariableInformation_1.Tooltip_VariableInformation, { variable: variable }), {
                                overlayClass: 'transparent',
                                contentHoverDelay: 200,
                                offset: 10
                            })), "i")));
                }) })));
    }
    renderMessage(message) {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_width', style: { justifyContent: 'center', alignItems: 'center', flex: 1 } }, message);
    }
    render() {
        return React.createElement(frontend_1.LL_V_C, { className: 'panel__variables' },
            this.renderHeader(),
            this.renderList());
    }
}
Panel_Variables.defaultProps = {
    keys: ['selected', 'filterVariables'],
};
exports.Panel_Variables = Panel_Variables;
