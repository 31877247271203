"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleState = exports.AntlrToMonacoAdapter = void 0;
const antlr4ts_1 = require("antlr4ts");
const QuaiExpressionsV3Lexer_1 = require("../../../../../../shared/expression/grammar/QuaiExpressionsV3Lexer");
/**
 * Wraps the ANTLR lexer to produce tokens for Monaco
 * @param antlrLexer - The ANTLR lexer for your language
 */
class AntlrToMonacoAdapter {
    constructor(antlrLexer) {
        this.antlrLexer = antlrLexer;
    }
    /**
     * Translates ANTLR tokens into Monaco tokens
     * @param line - The line of text to tokenize
     * @returns An array of Monaco tokens
     */
    tokenize(line) {
        this.antlrLexer.inputStream = antlr4ts_1.CharStreams.fromString(line);
        const tokens = [];
        let token = this.antlrLexer.nextToken();
        while (token.type !== QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.EOF) {
            tokens.push({
                startIndex: token.startIndex,
                scopes: this.getScopesForToken(token.type)
            });
            token = this.antlrLexer.nextToken();
        }
        return tokens;
    }
    /**
     * Maps an ANTLR token type to a Monaco scope
     * @param tokenType - The ANTLR token type
     * @returns The corresponding Monaco scope
     */
    getScopesForToken(tokenType) {
        // Implement a mapping from ANTLR token types to Monaco scopes
        // based on your specific language's grammar
        switch (tokenType) {
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.NUMBER:
                return 'number';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.STRING:
                return 'string';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.BOOL:
                return 'boolean';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.UNIQUE_ID:
                return 'variable';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.PROPERTY:
                return 'identifier';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.WS:
                return 'whitespace';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__0:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__1:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__2:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__3:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__4:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__5:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__6:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__7:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__8:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__9:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__10:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__11:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__12:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__13:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__14:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__15:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__16:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__17:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__18:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__19:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__20:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__21:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__22:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__23:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__24:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__25:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__26:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__27:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__28:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__29:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__30:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__31:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__32:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__33:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.MAX:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.MIN:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__34:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__35:
                return 'operator';
            case QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer.T__36:
                return 'operator';
            default:
                return '';
        }
    }
}
exports.AntlrToMonacoAdapter = AntlrToMonacoAdapter;
class SimpleState {
    clone() {
        return this;
    }
    equals(other) {
        return this === other;
    }
}
exports.SimpleState = SimpleState;
