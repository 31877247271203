"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTermRelation = void 0;
const parseTermRelation = (input) => {
    const elements = [];
    let buffer = '';
    let isSumGroup = false;
    const recognizedOperators = ['||', '&&']; // Define recognized operators
    // Helper function to add current buffer as term, sum group, or unknown
    const pushBufferAsElement = () => {
        if (buffer) {
            if (isSumGroup) {
                elements.push({ type: 'sumGroup', value: buffer.split('+').map(t => t.trim()) });
            }
            else {
                if (buffer.trim().length !== 1)
                    elements.push({ type: 'term', value: buffer.trim() });
                else
                    handleOperatorOrUnknown(buffer);
            }
            buffer = '';
            isSumGroup = false;
        }
    };
    // Function to handle operators and unknown segments
    const handleOperatorOrUnknown = (op) => {
        if (recognizedOperators.includes(op)) {
            elements.push({ type: 'operator', value: op });
        }
        else {
            elements.push({ type: 'unknown', value: op }); // Handle as unknown
        }
    };
    for (let i = 0; i < input.length; i++) {
        const char = input[i];
        if (char === '+' || char === 'T') {
            isSumGroup = isSumGroup || char === '+';
            buffer += char;
        }
        else if (['|', '&', '>'].includes(char)) { // Include '>' in the checks for simplicity
            pushBufferAsElement();
            const potentialOperator = input.substring(i, i + 2); // Look ahead for two-character operators
            handleOperatorOrUnknown(potentialOperator);
            i++; // Assume two-character operator, adjust if more complex logic needed
        }
        else if (char.match(/\d/)) {
            buffer += char; // Part of a term index
        }
        else if (char.trim() === '') {
            pushBufferAsElement(); // Whitespace indicates potential end of a segment
        }
    }
    pushBufferAsElement(); // Ensure any remaining buffer content is processed
    return elements;
};
exports.parseTermRelation = parseTermRelation;
