"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_EHRInstanceKBVersion = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const db_def_1 = require("./db-def");
exports.ApiDef_EHRInstanceKBVersion = {
    _v1: {
        'create-item': { method: thunderstorm_1.HttpMethod.POST, path: `_v1/${db_def_1.DBDef_EHRInstanceKBVersion.dbKey}/create-item` },
    }
};
