"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_Atom = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
require("./editors.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Editor_Case_1 = require("./Editor_Case");
const ts_common_1 = require("@nu-art/ts-common");
const app_dropdowns_1 = require("../../../shared-components/app-dropdowns");
const utils_1 = require("./utils");
const shared_1 = require("../../../../../../../_entity/expression/shared");
const frontend_2 = require("../../../../../../../_entity/variable/frontend");
const frontend_3 = require("../../../../../../../_entity/value/frontend");
const frontend_4 = require("../../../../../../../_entity/expression/frontend");
const consts_1 = require("../../../../../../../_entity/expression/shared/consts");
const utils_2 = require("../../../../../../../_entity/expression/shared/utils");
const shared_2 = require("../../../../../../../_entity/value/shared");
const QRangeInputV3_1 = require("../../../../../q-components/QRangeInputV3");
const ui_components_1 = require("../../../../../../../_entity/expression/frontend/ui-components");
const healthcare_space_1 = require("../../../../../healthcare-space");
class Editor_Atom extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        //######################### Logic #########################
        this.addEmptyCase = async () => {
            var _a;
            const atomItem = this.state.editable.item;
            const cases = (_a = atomItem.cases) === null || _a === void 0 ? void 0 : _a.map((_case) => {
                if (!(0, ts_common_1.exists)(_case.value))
                    _case.value = [];
                return _case;
            });
            const deltaCaseOperator = (0, shared_1.getBaseDeltaOperator)(cases === null || cases === void 0 ? void 0 : cases[0].caseOperator);
            const newCases = [...cases, { caseOperator: deltaCaseOperator, value: [] }];
            await this.state.editable.updateObj({
                cases: newCases,
                fallbackValue: [0, 0]
            });
        };
        this.toggleAddValue = () => {
            this.setState({ toggleCasesValue: !this.state.toggleCasesValue }, async () => {
                var _a, _b;
                const changes = {
                    cases: (_a = (0, ts_common_1.deepClone)(this.state.editable.get('cases'))) !== null && _a !== void 0 ? _a : [],
                    fallbackValue: [0, 0]
                };
                (_b = changes.cases) === null || _b === void 0 ? void 0 : _b.forEach((_case) => _case.value = []);
                if (!this.state.toggleCasesValue) {
                    // Remove the value from the case object
                    if (changes.cases)
                        delete changes.cases[0].value;
                    changes.fallbackValue = undefined;
                }
                await this.state.editable.updateObj(changes);
            });
        };
        this.toggleAddSample = () => {
            this.setState({ toggleSampleRestrictions: !this.state.toggleSampleRestrictions }, async () => {
                //set default value for sample object to make sure ui renders correctly
                const changes = {
                    sampleInfo: { sampleIndex: 'latest' }
                };
                //If click removes the value just make it undefined to delete it
                if (!this.state.toggleSampleRestrictions)
                    changes.sampleInfo = undefined;
                //Update the editable instance
                await this.state.editable.updateObj(changes);
            });
        };
        this.toggleAddExpiry = () => {
            this.setState({ toggleExpiry: !this.state.toggleExpiry }, async () => {
                var _a, _b, _c;
                const sampleInfo = this.state.editable.get('sampleInfo');
                //set default value for expiry to make sure ui renders correctly
                const changes = {
                    sampleInfo: {
                        sampleIndex: sampleInfo.sampleIndex,
                        timeConstraint: sampleInfo.timeConstraint,
                        expiry: [undefined, undefined]
                    }
                };
                //If click removes the value just make it undefined to delete it
                if (!this.state.toggleExpiry) {
                    if (!((_a = changes.sampleInfo) === null || _a === void 0 ? void 0 : _a.timeConstraint))
                        (_b = changes.sampleInfo) === null || _b === void 0 ? true : delete _b.timeConstraint;
                    (_c = changes.sampleInfo) === null || _c === void 0 ? true : delete _c.expiry;
                }
                //Update the editable instance
                await this.state.editable.updateObj(changes);
            });
        };
        this.toggleDeltaTime = () => {
            this.setState({ toggleDeltaTimeDiff: !this.state.toggleDeltaTimeDiff }, async () => {
                if (!this.state.toggleDeltaTimeDiff)
                    return this.state.editable.update('deltaTimeDiff', undefined);
                await this.state.editable.update('deltaTimeDiff', [undefined, undefined]);
            });
        };
        /**
         * this function will be passed as a prop to the case in order to be triggered on delete
         * @param index the index of the deleted case in the cases array.
         */
        this.deleteCase = async (index) => {
            var _a, _b, _c;
            const atomItem = this.state.editable;
            const changes = {};
            changes.cases = (_a = (0, ts_common_1.deepClone)(atomItem.get('cases'))) !== null && _a !== void 0 ? _a : [];
            (_b = changes.cases) === null || _b === void 0 ? void 0 : _b.splice(index, 1);
            // If the atom has 0 cases delete it from the term and break the execution
            if (!((_c = changes.cases) === null || _c === void 0 ? void 0 : _c.length))
                return this.props.deleteAtom();
            await this.state.editable.updateObj(changes);
            this.forceUpdate();
        };
        /**
         * Numeric, Range and self operators needs to be filtered upon creation of multiple cases to avoid contradictions
         */
        this.getOperatorsToFilter = () => {
            const cases = this.state.editable.get('cases');
            const usedOperators = (0, ts_common_1.filterInstances)(cases.map(_case => _case.caseOperator));
            const operatorsToFilter = [];
            if (cases.length > 1) {
                //Atoms with more than one case can't use self and exists
                operatorsToFilter.push(consts_1.LogicType_Self, consts_1.LogicType_Exists, consts_1.LogicType_NotEquals);
                const valueType = (0, utils_2.getLeftHandType)(this.state.editable.get('leftHand'), this.state.mappers);
                if (valueType === 'standard-range' && cases[0].caseOperator) {
                    if (consts_1.NormalRangeOperators.includes(cases[0].caseOperator)) {
                        operatorsToFilter.push(...consts_1.NormalRangeVarLogic.filter(logic => !consts_1.NormalRangeOperators.includes(logic)));
                    }
                    else
                        operatorsToFilter.push(...consts_1.NormalRangeVarLogic.filter(logic => consts_1.NormalRangeOperators.includes(logic)));
                }
            }
            usedOperators.forEach(operator => {
                if (consts_1.LogicTypes_ToFiler.includes(operator))
                    operatorsToFilter.push(operator);
            });
            return (0, ts_common_1.filterDuplicates)(operatorsToFilter);
        };
        this.shouldAllowSampleConstrains = () => {
            const leftHand = this.state.editable.get('leftHand');
            if (!leftHand)
                return false;
            const firstCase = this.state.editable.get('cases')[0];
            return (0, utils_2.getLeftHandType)(leftHand, this.state.mappers) === shared_2.valueType_StandardRange && !consts_1.AllDeltaLogic.includes(firstCase === null || firstCase === void 0 ? void 0 : firstCase.caseOperator);
        };
        //######################### Render #########################
        this.renderButtons = () => {
            var _a;
            if (this.state.viewMode || (0, ts_common_1.exists)(this.props.atoms))
                return '';
            const editableAtom = this.state.editable;
            const addValueLabel = this.state.toggleCasesValue ? 'Remove Value' : 'Add Value';
            const addSampleLabel = this.state.toggleSampleRestrictions ? 'Remove Sample Constrains' : 'Add Sample Constrains';
            const addExpiryLabel = this.state.toggleExpiry ? 'Remove Expiry' : 'Add Expiry';
            const addDeltaTimeLabel = this.state.toggleDeltaTimeDiff ? 'Remove Delta Time Constrain' : 'Add Delta Time Constrain';
            const cases = editableAtom.get('cases');
            const isSingleCaseAtom = consts_1.SelfOperators.includes(cases === null || cases === void 0 ? void 0 : cases[0].caseOperator) || (cases === null || cases === void 0 ? void 0 : cases[0].caseOperator) === consts_1.LogicType_Exists;
            const disabled = !editableAtom.get('leftHand') || isSingleCaseAtom || !(0, utils_1.validateLeftHandBaseExists)(editableAtom.get('leftHand'));
            const isAddCaseDisabled = disabled || (cases === null || cases === void 0 ? void 0 : cases[0].caseOperator) === consts_1.LogicType_NotEquals;
            const shouldRenderDeltaTimeDiff = consts_1.AllDeltaLogic.includes(cases === null || cases === void 0 ? void 0 : cases[0].caseOperator) || this.state.toggleDeltaTimeDiff;
            const shouldRenderSampleConstrain = this.shouldAllowSampleConstrains();
            const sampleInfo = this.state.editable.get('sampleInfo');
            return React.createElement(frontend_1.LL_H_C, { style: { gap: 5 } },
                React.createElement(frontend_1.TS_Button, { disabled: isAddCaseDisabled, onClick: this.addEmptyCase, className: 'add-entity-btn' }, "Add Case"),
                ((_a = cases === null || cases === void 0 ? void 0 : cases.length) !== null && _a !== void 0 ? _a : 0) <= 1 &&
                    React.createElement(frontend_1.TS_Button, { disabled: disabled, onClick: this.toggleAddValue, className: 'add-entity-btn' }, addValueLabel),
                shouldRenderSampleConstrain &&
                    React.createElement(frontend_1.TS_Button, { onClick: this.toggleAddSample, className: 'add-entity-btn' }, addSampleLabel),
                (0, ts_common_1.exists)(sampleInfo) &&
                    React.createElement(frontend_1.TS_Button, { onClick: this.toggleAddExpiry, className: 'add-entity-btn' }, addExpiryLabel),
                shouldRenderDeltaTimeDiff &&
                    React.createElement(frontend_1.TS_Button, { onClick: this.toggleDeltaTime, className: 'add-entity-btn' }, addDeltaTimeLabel));
        };
        this.renderAddElseValue = () => {
            if (!this.state.toggleCasesValue)
                return '';
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.LL_H_C, { className: 'match_width condition-container else-value' },
                    React.createElement("div", { className: 'label' }, "Else:"),
                    React.createElement(frontend_1.LL_H_C, { className: 'condition-value' },
                        React.createElement("div", { className: 'divider' }),
                        React.createElement(QRangeInputV3_1.QRangeInputV3, { editable: this.state.editable, prop: 'fallbackValue', className: 'term-value-input', placeholder: 'Value', shouldReset: true, showErrorTooltip: true }))));
        };
        this.renderSampleConstrains = () => {
            if (!this.state.toggleSampleRestrictions)
                return '';
            const editableSample = this.state.editable.editProp('sampleInfo', {});
            const editableTimeRange = editableSample.editProp('timeConstraint', []);
            return React.createElement(frontend_1.LL_H_C, { className: 'match_width condition-container else-value' },
                React.createElement(frontend_1.LL_H_C, { className: 'label-container' },
                    React.createElement("div", { className: 'label' }, "Sample Constrains:"),
                    React.createElement(ui_components_1.DropDown_SampleIndex.editable, { canUnselect: true, showErrorTooltip: true, editable: editableSample, onSelected: async (value) => {
                            if (value)
                                return editableSample.updateObj({ sampleIndex: value });
                            await editableSample.updateObj({ sampleIndex: undefined });
                        }, prop: 'sampleIndex' })),
                React.createElement(frontend_1.LL_H_C, { className: 'condition-value sample-constrains' },
                    React.createElement("div", { className: 'divider' }),
                    React.createElement(healthcare_space_1.Editor_TimeRange, { editable: editableTimeRange })));
        };
        this.renderExpiry = () => {
            if (!this.state.toggleExpiry)
                return '';
            const editableSample = this.state.editable.editProp('sampleInfo', {});
            const editableExpiry = editableSample.editProp('expiry', []);
            return React.createElement(frontend_1.LL_H_C, { className: 'match_width condition-container else-value' },
                React.createElement(frontend_1.LL_H_C, { className: 'label-container' },
                    React.createElement("div", { className: 'label' }, "Expiry:")),
                React.createElement(frontend_1.LL_H_C, { className: 'condition-value expiry-container' },
                    React.createElement("div", { className: 'divider' }),
                    React.createElement(healthcare_space_1.Editor_TimeRange, { editable: editableExpiry })));
        };
        this.renderDeltaTimeDiff = () => {
            if (!this.state.toggleDeltaTimeDiff)
                return '';
            const editableDeltaTimeDiff = this.state.editable.editProp('deltaTimeDiff', []);
            return React.createElement(frontend_1.LL_H_C, { className: 'match_width condition-container else-value' },
                React.createElement(frontend_1.LL_H_C, { className: 'label-container' },
                    React.createElement("div", { className: 'label' }, "Time Constraint:")),
                React.createElement(frontend_1.LL_H_C, { className: 'condition-value expiry-container' },
                    React.createElement("div", { className: 'divider' }),
                    React.createElement(healthcare_space_1.Editor_TimeRange, { editable: editableDeltaTimeDiff })));
        };
        this.renderDeltaInfoContainer = () => {
            if (!this.state.toggleExpiry && !this.state.toggleSampleRestrictions)
                return '';
            return React.createElement(frontend_1.LL_V_L, { className: 'atom-editor__delta-info' },
                this.renderSampleConstrains(),
                this.renderExpiry());
        };
        this.renderLeftHandDropdown = (index) => {
            const editableAtom = this.state.editable;
            return React.createElement(app_dropdowns_1.DropDown_ExpressionLeftHand, { index: index, atoms: this.props.atoms, editable: editableAtom, disabled: editableAtom.get('cases').length > 1 || this.props.viewMode, selected: editableAtom.get('leftHand') });
        };
        this.renderCases = () => {
            var _a, _b, _c, _d;
            const atomItem = this.state.editable.item;
            const editableCases = this.state.editable.editProp('cases', []);
            const withValue = this.state.toggleCasesValue || ((_b = (_a = atomItem.cases) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 1;
            const leftHand = this.state.editable.get('leftHand');
            const atom = (_c = this.props.atoms) === null || _c === void 0 ? void 0 : _c.find((atom, index) => (leftHand === null || leftHand === void 0 ? void 0 : leftHand.id) === String(index + 1));
            return React.createElement(React.Fragment, null, (_d = atomItem.cases) === null || _d === void 0 ? void 0 : _d.map((_case, index) => {
                const editableCase = editableCases.editProp(index, {});
                return React.createElement(frontend_1.LL_H_C, { style: { alignItems: 'flex-start', gap: '10px' }, className: "match_width flex__grow flex__space-between", key: `case-${index}` },
                    this.renderLeftHandDropdown(index),
                    React.createElement(Editor_Case_1.Editor_Case, { leftHand: this.state.editable.get('leftHand'), deleteCase: () => this.deleteCase(index), operatorsToFilter: this.getOperatorsToFilter(), editable: editableCase, atom: atom, viewMode: this.props.viewMode, disableOperatorSelection: this.state.editable.get('cases').length > 1, withValue: withValue }));
            }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c, _d, _e;
        state = super.deriveStateFromProps(nextProps, state);
        const cases = state.editable.get('cases');
        const fallbackValue = state.editable.get('fallbackValue');
        const sampleInfo = state.editable.get('sampleInfo');
        const deltaTimeDiff = state.editable.get('deltaTimeDiff');
        state.toggleCasesValue = (_a = ((0, ts_common_1.exists)(cases === null || cases === void 0 ? void 0 : cases[0].value) || (0, ts_common_1.exists)(fallbackValue))) !== null && _a !== void 0 ? _a : false;
        state.toggleSampleRestrictions = (_b = (0, ts_common_1.exists)(sampleInfo)) !== null && _b !== void 0 ? _b : false;
        state.toggleExpiry = (_c = (0, ts_common_1.exists)(sampleInfo === null || sampleInfo === void 0 ? void 0 : sampleInfo.expiry)) !== null && _c !== void 0 ? _c : false;
        state.toggleDeltaTimeDiff = (_d = (0, ts_common_1.exists)(deltaTimeDiff)) !== null && _d !== void 0 ? _d : false;
        state.viewMode = nextProps.viewMode;
        (_e = state.mappers) !== null && _e !== void 0 ? _e : (state.mappers = {
            variableMap: frontend_2.ModuleFE_Variable.cache.arrayToMap((_var) => _var._id),
            valueMap: frontend_3.ModuleFE_Value.cache.arrayToMap((_var) => _var._id),
            expressionMap: frontend_4.ModuleFE_Expression.cache.arrayToMap((_var) => _var._id),
        });
        return state;
    }
    render() {
        return React.createElement(React.Fragment, null,
            this.renderCases(),
            this.renderAddElseValue(),
            this.renderDeltaInfoContainer(),
            this.renderDeltaTimeDiff(),
            this.renderButtons());
    }
}
exports.Editor_Atom = Editor_Atom;
