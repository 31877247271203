"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_SearchV2 = exports.dispatch_searchResultSelected = exports.dispatch_globalSearch = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
exports.dispatch_globalSearch = new ts_common_1.Processor('__searchFilterMapper');
exports.dispatch_searchResultSelected = new frontend_1.ThunderDispatcher('__onSearchResultSelected');
class ModuleFE_SearchV2_Class extends ts_common_1.Module {
    async query(toSearchIn, searchTerm) {
        const searchResults = exports.dispatch_globalSearch.processModules((module) => {
            const searchItem = toSearchIn.find(searchItem => searchItem.module === module);
            if (!searchItem)
                return;
            const filter = new ts_common_1.Filter(module.__searchFilterMapper);
            filter.setRegexp(false);
            filter.prepareFilter(searchTerm);
            let items;
            if ('filter' in searchItem && searchItem.filter)
                items = module.cache.filter(searchItem.filter);
            else
                items = module.cache.all();
            return {
                type: searchItem.type,
                results: items.filter(filter.filterImpl)
            };
        });
        return (0, ts_common_1.filterInstances)(searchResults);
    }
}
exports.ModuleFE_SearchV2 = new ModuleFE_SearchV2_Class();
