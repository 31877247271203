"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_PopulationEditor = void 0;
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const _enum_1 = require("../../../_enum");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const React = __importStar(require("react"));
const ts_common_1 = require("@nu-art/ts-common");
const ui_components_1 = require("@app/hcs/_entity/variable/frontend/ui-components");
const frontend_2 = require("@app/hcs/frontend");
const styles_1 = require("@app/styles");
require("./Component_PopulationEditor.scss");
const ViewMode_View = 'view';
const ViewMode_Edit = 'edit';
class Component_PopulationEditor extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.getViewMode = () => this.state.editMode ? ViewMode_Edit : ViewMode_View;
        this.addSpecification = async () => {
            const population = this.state.editable;
            population.item.specifications[''] = '';
            await population.save();
        };
        this.renderPopulationType = () => {
            const population = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Population' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => React.createElement("div", { className: 'population-item-editor__data span-row' }, _enum_1.PopulationTypeLabelMap[population.item.type])
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(React.Fragment, null,
                                React.createElement(_enum_1.DropDown_PopulationType.editable, { editable: population, prop: 'type', onSelected: type => {
                                        if (type === population.item.type)
                                            return;
                                        if (type === _enum_1.PopulationType_General) {
                                            //@ts-ignore
                                            delete population.item.specifications;
                                            population.updateObj({ type: type });
                                        }
                                        if (type === _enum_1.PopulationType_Specific) {
                                            population.updateObj({ type: type, specifications: {} });
                                        }
                                    } }),
                                React.createElement("div", null))
                        }
                    ] }));
        };
        this.renderPopulationSpecifications = () => {
            const population = this.state.editable;
            if (population.item.type !== _enum_1.PopulationType_Specific)
                return;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Specifications' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => this.renderSpecificationsView()
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => this.renderSpecificationsEdit(),
                        }
                    ] }));
        };
        this.renderSpecificationsView = () => {
            var _a;
            const population = this.state.editable;
            const variableIds = (0, ts_common_1._keys)((_a = population.item.specifications) !== null && _a !== void 0 ? _a : {});
            return variableIds.map((variableId, index) => {
                var _a;
                const variable = frontend_2.ModuleFE_Variable.cache.unique(variableId);
                const value = frontend_2.ModuleFE_Value.cache.unique((_a = population.item.specifications) === null || _a === void 0 ? void 0 : _a[variableId]);
                return React.createElement(React.Fragment, { key: variableId },
                    React.createElement("div", { className: 'population-editor__variable' }, variable === null || variable === void 0 ? void 0 : variable.name),
                    React.createElement("div", { className: 'population-editor__value' }, value ? (0, frontend_2.resolveValueDisplay)(value) : ''));
            });
        };
        this.renderSpecificationsEdit = () => {
            var _a;
            const population = this.state.editable;
            const variableIds = (0, ts_common_1._keys)((_a = population.item.specifications) !== null && _a !== void 0 ? _a : {});
            return React.createElement(React.Fragment, null,
                variableIds.map((variableId, index) => {
                    const variable = frontend_2.ModuleFE_Variable.cache.unique(variableId);
                    return React.createElement(React.Fragment, { key: variableId },
                        React.createElement(ui_components_1.DropDown_VariableV3.selectable, { className: 'population-editor__variable-dropdown', selected: variableId, limitItems: 50, queryFilter: variable => !variableIds.includes(variable._id), onSelected: async (variable) => {
                                delete population.item.specifications[variableId];
                                population.item.specifications[variable._id] = '';
                                await population.save();
                            } }),
                        !!variable &&
                            React.createElement(frontend_2.DropDown_Values, { className: 'population-editor__value-dropdown', variable: variable, selected: population.item.specifications[variableId] || undefined, onSelected: async (value) => {
                                    population.item.specifications[variableId] = value._id;
                                    await population.save();
                                } }));
                }),
                React.createElement(frontend_1.TS_Button, { onClick: this.addSpecification, className: 'population-editor__add-specification' },
                    React.createElement(styles_1.ICONS.advisorAddPlus.component, null)));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(React.Fragment, null,
            this.renderPopulationType(),
            this.renderPopulationSpecifications());
    }
}
exports.Component_PopulationEditor = Component_PopulationEditor;
