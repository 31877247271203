"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrognosisRateTypes = exports.PrognosisRateType_Qualitative = exports.PrognosisRateType_Mortality = exports.PrognosisRateType_Survival = exports.PrognosisValues = exports.PrognosisValue_30 = exports.PrognosisValue_20 = exports.PrognosisValue_10 = exports.PrognosisValue_00 = void 0;
// ########################### Prognosis Qualitative Value ###########################
exports.PrognosisValue_00 = 'prognosis-00';
exports.PrognosisValue_10 = 'prognosis-10';
exports.PrognosisValue_20 = 'prognosis-20';
exports.PrognosisValue_30 = 'prognosis-30';
exports.PrognosisValues = [exports.PrognosisValue_00, exports.PrognosisValue_10, exports.PrognosisValue_20, exports.PrognosisValue_30];
// ########################### Prognosis Rate Type ###########################
exports.PrognosisRateType_Survival = 'survival';
exports.PrognosisRateType_Mortality = 'mortality';
exports.PrognosisRateType_Qualitative = 'qualitative';
exports.PrognosisRateTypes = [exports.PrognosisRateType_Survival, exports.PrognosisRateType_Mortality, exports.PrognosisRateType_Qualitative];
