"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDpIconsContainerWidth = exports.renderDpIcon = exports.renderDpIcons = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const renderDpIcons = (associatedDps, maxDpCount, onHover) => {
    let inStyle = {};
    if (maxDpCount > 0)
        inStyle = { width: (0, exports.calculateDpIconsContainerWidth)(maxDpCount) };
    const className = (0, frontend_1._className)('dp_container', associatedDps.length === 0 ? 'empty' : undefined);
    return React.createElement(frontend_1.LL_H_C, { className: className, style: inStyle, onMouseLeave: e => onHover === null || onHover === void 0 ? void 0 : onHover(e, 'leave'), onMouseEnter: e => onHover === null || onHover === void 0 ? void 0 : onHover(e, 'enter') }, associatedDps.length > 0 && associatedDps.map(item => (0, exports.renderDpIcon)(item)));
};
exports.renderDpIcons = renderDpIcons;
const renderDpIcon = (dp) => {
    return React.createElement("div", { key: dp._id, className: 'dp-icon' }, dp.initials);
};
exports.renderDpIcon = renderDpIcon;
const calculateDpIconsContainerWidth = (maxDpCount) => {
    const singleSize = 36;
    return 2 + maxDpCount * (singleSize + 2);
};
exports.calculateDpIconsContainerWidth = calculateDpIconsContainerWidth;
