"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Discussion_Renderer = void 0;
const React = require("react");
require("./Discussion_Renderer.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const _shared_1 = require("../../../_shared");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@nu-art/user-account/frontend");
const Message_Renderer_1 = require("../message/Message_Renderer");
const styles_1 = require("@app/styles");
const const_1 = require("../renderer-utils/const");
const TS_SimpleDialogue_1 = require("@nu-art/thunderstorm/frontend/components/TS_Dialog/TS_SimpleDialogue");
const DeleteConfirmation_Body_1 = require("./delete-confirmation/DeleteConfirmation_Body");
const _entity_1 = require("../../../_entity");
const frontend_3 = require("@nu-art/ts-messaging/_entity/message/frontend");
const frontend_4 = require("@nu-art/ts-messaging/_entity/topic/frontend");
class Discussion_Renderer extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.focusManagerOfSelectedTopic = () => {
            const dbTopic = frontend_4.ModuleFE_Topic.cache.unique(this.props.discussion.topicId);
            if (!dbTopic)
                throw new ts_common_1.BadImplementationException(`Couldn't find topic for id ${this.props.discussion.topicId}.`);
            if (dbTopic.type === 'variable')
                return;
            const searchItem = _shared_1.CollectionEntityMapper.getEntityMapByCollectionName(dbTopic.type);
            frontend_1.ModuleFE_RoutingV2.goToRoute(searchItem.route, { id: dbTopic.refId, type: dbTopic.type });
            frontend_1.dispatcher_urlParamsChanged.dispatchUI();
        };
        this.onContextMenuClicked = (e) => {
            if (!this.props.discussion) {
                this.logWarning('Right-clicked, but did not receive the discussion!');
                return;
            }
            (0, frontend_1.stopPropagation)(e);
            const menuItems = [
                {
                    label: 'Delete', onClick: () => {
                        TS_SimpleDialogue_1.TS_SimpleDialog.show({
                            title: 'Delete this discussion?',
                            body: React.createElement(DeleteConfirmation_Body_1.DeleteConfirmation_Body, { discussion: this.props.discussion }),
                            negativeButtonProps: { label: 'Cancel' },
                            actionButtonProps: {
                                label: 'Delete', action: async () => {
                                    await _entity_1.ModuleFE_Discussion._v1.deleteDiscussion({ discussionId: this.props.discussion._id }).executeSync();
                                }
                            },
                        });
                    }
                }
            ];
            const rightClickMenu = {
                id: 'discussion-right-click-menu',
                adapter: (0, frontend_1.SimpleListAdapter)(menuItems, renderer => React.createElement("div", { className: 'node-data' }, renderer.item.label)),
                originPos: { y: e.clientY, x: e.clientX, },
                modalPos: { x: 0, y: 1 },
                onNodeClicked: (path, item) => {
                    var _a;
                    frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                    (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
                }
            };
            frontend_1.ModuleFE_MouseInteractivity.showMenu(rightClickMenu);
        };
    }
    __onDiscussionsUpdated(...params) {
        if (params[0] === frontend_1.EventType_Create || params[0] === frontend_1.EventType_Update)
            if (params[1].topicId === this.props.discussion.topicId)
                this.reDeriveState();
    }
    __onMessagesUpdated(...params) {
        if (params[0] === frontend_1.EventType_Create || params[0] === frontend_1.EventType_Update)
            if (params[1].topicId === this.props.discussion.topicId)
                this.reDeriveState();
    }
    deriveStateFromProps(nextProps, state) {
        const participants = this.getParticipantsByAuditorEmails(frontend_3.ModuleFE_Message.cache.filter(msg => msg.topicId === nextProps.discussion.topicId).map(msg => msg._auditorId));
        const dbMessages = (0, ts_common_1.sortArray)(frontend_3.ModuleFE_Message.cache.filter(msg => msg.topicId === nextProps.discussion.topicId), msg => msg.__created, false);
        return Object.assign(Object.assign({}, state), { participants: participants, accountsMap: (0, ts_common_1.arrayToMap)((0, ts_common_1.filterInstances)(participants.accounts), ts_common_1.dbObjectToId), messages: dbMessages, discussionOpened: state.discussionOpened || false, addingNewMessage: state.addingNewMessage || false });
    }
    getParticipantsByAuditorEmails(emails) {
        const accounts = emails.reduce((accounts, email) => {
            const dbAccount = frontend_2.ModuleFE_Account.cache.find(acc => email === acc.email);
            if (!dbAccount || accounts.find(account => account.email === (dbAccount === null || dbAccount === void 0 ? void 0 : dbAccount.email)))
                return accounts;
            accounts.push(dbAccount);
            return accounts;
        }, []);
        // const accounts = emails.map(email => ModuleFE_Account.cache.find(acc => email === acc.email));
        const users = accounts.map(acc => _entity_1.ModuleFE_User.cache.unique(acc === null || acc === void 0 ? void 0 : acc._id));
        return { accounts, users };
    }
    renderParticipants() {
        return React.createElement(frontend_1.LL_H_C, { className: 'container-participants' }, this.state.participants.accounts.map((account, index) => (0, const_1.renderParticipantIcon)(this.state.participants.users[index], account)));
    }
    renderTopicIdentifiers() {
        let topicEntity;
        try {
            topicEntity = _entity_1.ModuleFE_Discussion.getEntityNameFromTopic(this.props.discussion.topicId);
        }
        catch (e) {
            this.logError(`renderTopicLabel -> Failed rendering entity name for topic ${this.props.discussion.topicId} of discussion ${(0, ts_common_1.__stringify)(this.props.discussion)}`, e);
            topicEntity = `Failed rendering topic entity name ${this.props.discussion.topicId}`;
        }
        let topicLabel;
        try {
            topicLabel = _entity_1.ModuleFE_Discussion.getLabelFromTopicId(this.props.discussion.topicId);
        }
        catch (e) {
            this.logError(`renderTopicLabel -> Failed rendering label for topic ${this.props.discussion.topicId} of discussion ${(0, ts_common_1.__stringify)(this.props.discussion)}`, e);
            topicLabel = `Failed rendering topic label ${this.props.discussion.topicId}`;
        }
        return React.createElement(frontend_1.LL_V_L, null,
            React.createElement("div", { className: 'topic-entity-name' }, topicEntity),
            React.createElement("div", { className: 'topic-label', onClick: this.focusManagerOfSelectedTopic }, topicLabel));
    }
    renderMessages() {
        const messagesToShow = this.state.discussionOpened ? this.state.messages : this.state.messages.slice(0, 1);
        if (!messagesToShow.length)
            return React.createElement("div", { className: 'error-message-text' }, "Failed to find the first msg!");
        return messagesToShow.map(message => React.createElement(Message_Renderer_1.Message_Renderer, { key: message._id, messageId: message._id }));
    }
    renderShowRepliesButton() {
        const messageCount = frontend_3.ModuleFE_Message.cache.filter(msg => msg.topicId === this.props.discussion.topicId).length;
        const replyCount = messageCount - 1;
        return replyCount > 0 &&
            React.createElement("div", { className: 'replies', onClick: () => {
                    this.reDeriveState({ discussionOpened: !this.state.discussionOpened });
                } }, this.state.addingNewMessage ? React.createElement(React.Fragment, null) : this.state.discussionOpened ? 'Show less' : `${replyCount} ${(replyCount > 1) ? 'replies' : 'reply'}`);
    }
    renderAddReply() {
        return React.createElement(frontend_1.LL_V_R, { className: 'add-message-container' },
            React.createElement(frontend_1.TS_TextArea, { className: 'add-message-input', type: 'text', value: this.state.newMessageDraft, onChange: (value) => {
                    // @ts-ignore
                    this.setState({ newMessageDraft: value });
                } }),
            React.createElement(frontend_1.LL_H_C, { className: 'add-message-button-container' },
                React.createElement(frontend_1.TS_Button, { className: 'add-message-button-cancel', onClick: () => this.setState({ addingNewMessage: false, newMessageDraft: undefined }) }, "Cancel"),
                React.createElement(frontend_1.TS_BusyButton, { className: 'add-message-button-save', onClick: async () => {
                        if (!this.state.newMessageDraft)
                            return;
                        await _entity_1.ModuleFE_Discussion.addMessageToDiscussion(this.props.discussion._id, this.state.newMessageDraft.trim());
                        this.setState({ newMessageDraft: undefined });
                    } }, "Save")));
    }
    /////////////////////////////////// Main sections render ///////////////////////////////////
    renderLeft() {
        return React.createElement(frontend_1.LL_V_C, { className: 'container-left' },
            React.createElement("div", { className: (0, frontend_1._className)('resolved-status', this.props.discussion.status === 'resolved' ? 'resolved' : 'unresolved') }));
    }
    renderMid() {
        return React.createElement(frontend_1.LL_V_L, { className: 'container-mid' },
            this.renderParticipants(),
            this.renderTopicIdentifiers(),
            this.renderMessages(),
            this.renderShowRepliesButton(),
            !this.props.disabled && this.state.addingNewMessage && this.renderAddReply());
    }
    renderRight() {
        return React.createElement(frontend_1.LL_V_L, { className: 'container-right' },
            React.createElement(styles_1.ICONS.v.component, { className: 'resolve-button', onClick: async () => {
                    if (!this.props.discussion)
                        return;
                    const newStatus = this.props.discussion.status === 'resolved' ? 'open' : 'resolved';
                    await _entity_1.ModuleFE_Discussion.v1.upsert(Object.assign(Object.assign({}, this.props.discussion), { status: newStatus })).executeSync();
                } }),
            !this.state.addingNewMessage && React.createElement(styles_1.ICONS.messageBubble.component, { className: 'add-message-bubble', onClick: () => {
                    this.reDeriveState({
                        addingNewMessage: true,
                        discussionOpened: true
                    });
                } }));
    }
    /////////////////////////////////// Render ///////////////////////////////////
    render() {
        if (!this.props.discussion)
            return;
        return React.createElement(frontend_1.LL_V_L, { className: 'discussion' },
            React.createElement(frontend_1.LL_H_C, { onContextMenu: this.onContextMenuClicked },
                this.renderLeft(),
                this.renderMid(),
                !this.props.disabled && this.renderRight()),
            React.createElement("div", { className: 'container-bottom-border-for-padding' },
                React.createElement("div", { className: 'discussion-bottom-border' })));
    }
}
exports.Discussion_Renderer = Discussion_Renderer;
