"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_CalculatorButtons = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const EditorOperators_1 = require("./EditorOperators");
class Component_CalculatorButtons extends frontend_1.ComponentSync {
    constructor() {
        // __contextChange(context?: string, firstTime?: boolean) {
        // 	if (!this.props.freeForm)
        // 		this.setState({expressionType: context, firstLoad: firstTime!});
        // }
        super(...arguments);
        this.updateValue = (button) => {
            const position = this.props.editor.getPosition();
            if (position) {
                const model = this.props.editor.getModel();
                if (model) {
                    const offset = model.getOffsetAt(position);
                    const fullText = model.getValue();
                    const newText = fullText.slice(0, offset) + button.operand + fullText.slice(offset);
                    model.setValue(newText);
                    this.props.editor.focus();
                    const newPosition = model.getPositionAt(offset + button.operand.length);
                    // @ts-ignore
                    newPosition.column = button.cursorLocationLogic(newPosition.column);
                    this.props.editor.setPosition(newPosition);
                }
            }
        };
        this.renderButtons = () => {
            if (this.state.firstLoad)
                return '';
            if (!this.state.expressionType)
                return EditorOperators_1.allOperators.map(button => button.element(() => {
                    this.updateValue(button);
                }));
            return EditorOperators_1.booleanExpressions.map(button => button.element(() => {
                this.updateValue(button);
            }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = (_a = this.state) !== null && _a !== void 0 ? _a : {};
        state.firstLoad = !nextProps.freeForm;
        return state;
    }
    render() {
        return React.createElement(frontend_1.Grid, { className: 'buttons', style: { width: this.props.width } }, this.renderButtons());
    }
}
exports.Component_CalculatorButtons = Component_CalculatorButtons;
