"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveReferenceDisplay = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Renderer_BookFullName_1 = require("../../book/frontend/Renderer_BookFullName");
const utils_1 = require("../shared/utils");
const frontend_1 = require("../../book/frontend");
const resolveReferenceDisplay = (ref) => {
    if ((0, utils_1.reference_isArticle)(ref))
        return articleRenderer(ref);
    if ((0, utils_1.reference_isUpToDate)(ref))
        return upToDateRenderer(ref);
    if ((0, utils_1.reference_isSnomed)(ref) || (0, utils_1.reference_isLoinc)(ref) || (0, utils_1.reference_isICD)(ref))
        return refDataIdRenderer(ref);
    if ((0, utils_1.reference_isBook)(ref)) {
        const book = frontend_1.ModuleFE_Book.cache.unique(ref.data.bookId);
        if (!book)
            throw new ts_common_1.MUSTNeverHappenException(`Could not find book for id ${ref.data.bookId}`);
        return bookRenderer(ref, book);
    }
    throw new ts_common_1.ImplementationMissingException(`No reference display resolver for reference of type ${ref.type}`);
};
exports.resolveReferenceDisplay = resolveReferenceDisplay;
const bookRenderer = (v, b) => {
    if (!b)
        throw new ts_common_1.BadImplementationException('Must pass book to render book display');
    const bookRef = v;
    let str = '';
    if (bookRef.data.page && bookRef.data.paragraph) {
        str += `${b.name.split(' ')[0]} `;
        if (bookRef.data.page && bookRef.data.page >= 0)
            str += `(Pg.${bookRef.data.page}) `;
        if (bookRef.data.paragraph && bookRef.data.paragraph.length > 0)
            str += `- ${bookRef.data.paragraph} `;
    }
    else
        str = (0, Renderer_BookFullName_1.Renderer_BookFullName)(bookRef, b);
    return str;
};
const refDataIdRenderer = (ref) => ref.data.id;
const articleRenderer = (ref) => `${ref.data.journal} - ${ref.data.publicationYear}`;
const upToDateRenderer = (ref) => ref.data.name;
