"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clean_DiseaseProfile = void 0;
exports.clean_DiseaseProfile = Object.freeze({
    referenceIds: [],
    chiefComplaintIds: [],
    label: '',
    initials: '',
    backgroundColor: { value: '' },
    color: { value: '' },
    icd: [],
    tagIds: [],
});
