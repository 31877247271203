"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Discussion = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("./consts");
const Validator_ModifiableProps = {
    topicId: (0, ts_common_1.tsValidateString)(),
    type: (0, ts_common_1.tsValidateValue)([consts_1.DiscussionType_Chat, consts_1.DiscussionType_GladosIssue]),
    status: ts_common_1.tsValidator_nonMandatoryString,
};
const Validator_GeneratedProps = {
    _auditorId: (0, ts_common_1.tsValidateString)(),
};
exports.DBDef_Discussion = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'discussions',
    entityName: 'discussion',
    frontend: {
        group: 'messaging',
        name: 'discussion'
    },
    backend: {
        name: 'discussions'
    },
    dependencies: {
        topicId: {
            dbKey: 'topics',
            fieldType: 'string'
        }
    }
};
