"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_Variable = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_Variable = {
    _v1: {
        getVariables: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/variables/get-variables' },
        getAttributes: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/variables/get-attributes' },
        createUIVariable: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/variables/create-ui-variable' },
    }
};
