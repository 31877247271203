"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const utils_1 = require("../../utils/utils");
const shared_components_1 = require("../../../../../../shared-components");
const _entity_1 = require("../../../../../../../_entity");
function RenderRightSide(props) {
    var _a;
    const [relevantIds, setRelevantId] = React.useState([]);
    React.useEffect(() => {
        var _a;
        (_a = props.item) === null || _a === void 0 ? void 0 : _a.map(currInput => {
            if (currInput.variableId) {
                setRelevantId([...relevantIds,
                    (0, utils_1.filterRelevantValues)(getValueOfSelectedVariable(currInput.variableId))]);
            }
        });
    }, []);
    const getValueOfSelectedVariable = (id) => {
        const variable = _entity_1.ModuleFE_Variable.cache
            .unique(id);
        return _entity_1.ModuleFE_Value.cache
            .find((v) => {
            return v._id === (variable === null || variable === void 0 ? void 0 : variable.valueId);
        });
    };
    return React.createElement(React.Fragment, null, props.item && ((_a = props.item) === null || _a === void 0 ? void 0 : _a.map((currInputMenu, inputIndex) => {
        var _a, _b;
        return (React.createElement("div", { key: inputIndex, style: { width: '100%' }, className: 'due-dropdown-flex' },
            React.createElement(shared_components_1.DropDown_Variable, { selected: ((_a = props.item[inputIndex]) === null || _a === void 0 ? void 0 : _a.variableId) ? props.item[inputIndex].variableId : '', onSelected: variable => {
                    props.onChangeVariableToCompare(props.index, inputIndex, variable);
                    setRelevantId(prevState => {
                        const newIds = (0, utils_1.filterRelevantValues)(getValueOfSelectedVariable(variable._id));
                        if (prevState[inputIndex]) {
                            prevState[inputIndex] = newIds;
                        }
                        else {
                            prevState.push(newIds);
                        }
                        return prevState;
                    });
                }, sortBy: ['name'] }),
            ((_b = props.item[inputIndex]) === null || _b === void 0 ? void 0 : _b.variableId) ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'separator' }),
                    React.createElement(shared_components_1.DropDown_Values, { onSelected: value => {
                            props.onChangeValueToCompare(props.index, inputIndex, value);
                        }, queryFilter: item => { var _a; return (_a = relevantIds[inputIndex]) === null || _a === void 0 ? void 0 : _a.includes(item._id); }, selected: props.item && props.item[inputIndex].valueId, variable: props.variable })) : null));
    })));
}
exports.default = RenderRightSide;
