"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Artifact = exports.ModuleFE_Artifact_Class = exports.dispatch_onArtifactsUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onArtifactsUpdated = new types_1.ThunderDispatcherV3('__onArtifactsUpdated');
class ModuleFE_Artifact_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Artifact, exports.dispatch_onArtifactsUpdated);
        this._v1 = {
            'get-artifact-data': (0, frontend_1.apiWithBody)(shared_1.ApiDef_Artifact._v1['get-artifact-data']),
            'register-version': (0, frontend_1.apiWithBody)(shared_1.ApiDef_Artifact._v1['register-version']),
            'get-artifact-backup-path': (0, frontend_1.apiWithBody)(shared_1.ApiDef_Artifact._v1['get-artifact-backup-path']),
            'check-version-exists': (0, frontend_1.apiWithBody)(shared_1.ApiDef_Artifact._v1['check-version-exists']),
            'get-backup-stream': (0, frontend_1.apiWithBody)(shared_1.ApiDef_Artifact._v1['get-backup-stream']),
        };
    }
}
exports.ModuleFE_Artifact_Class = ModuleFE_Artifact_Class;
exports.ModuleFE_Artifact = new ModuleFE_Artifact_Class();
