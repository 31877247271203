"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_Case = void 0;
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
require("./editors.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const styles_1 = require("@app/styles");
const ts_common_1 = require("@nu-art/ts-common");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
const shared_1 = require("../../../../../../../_entity/expression/shared");
const consts_1 = require("../../../../../../../_entity/expression/shared/consts");
const _entity_1 = require("../../../../../../_entity");
const utils_1 = require("./utils");
const consts_2 = require("@app/styles/frontend/ui/consts");
const utils_2 = require("../../../../../../../_entity/expression/shared/utils");
const QRangeInputV3_1 = require("../../../../../q-components/QRangeInputV3");
const ui_components_2 = require("../../../../../../../_entity/value/frontend/ui-components");
const utils_3 = require("../../../../../healthcare-space/variables/components/Component_RelationsTable/utils/utils");
const ui_components_3 = require("../../../../../../../_entity/expression/frontend/ui-components");
class Editor_Case extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.Renderers = {
            [consts_1.LogicType_InSet]: this.renderInSetDropdownList.bind(this),
            [consts_1.LogicType_In]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_Self]: () => React.createElement(React.Fragment, null),
            [consts_1.LogicType_InRange]: this.renderFactorInput.bind(this),
            [consts_1.LogicType_BelowRange]: this.renderFactorInput.bind(this),
            [consts_1.LogicType_AboveRange]: this.renderFactorInput.bind(this),
            [consts_1.LogicType_InNormal]: this.renderFactorInput.bind(this),
            [consts_1.LogicType_BelowNormal]: this.renderFactorInput.bind(this),
            [consts_1.LogicType_AboveNormal]: this.renderFactorInput.bind(this),
            [consts_1.LogicType_Equals]: this.renderEqualityByType.bind(this).bind(this),
            [consts_1.LogicType_NotEquals]: this.renderEqualityByType.bind(this).bind(this),
            [consts_1.LogicType_LessOrEquals]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaGreaterEquals]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaLesserEquals]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaEquals]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaPercentageGreaterEquals]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaPercentageLesserEquals]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaPercentageEquals]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaIn]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_DeltaPercentageIn]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_LessThan]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_GreaterOrEqual]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_GreaterThan]: this.renderNumericInput.bind(this),
            [consts_1.LogicType_Exists]: () => React.createElement(React.Fragment, null),
            [consts_1.LogicType_IsFalse]: () => React.createElement(React.Fragment, null),
            [consts_1.LogicType_IsTrue]: () => React.createElement(React.Fragment, null),
            [consts_1.LogicType_Delta]: () => React.createElement(React.Fragment, null),
            [consts_1.LogicType_DeltaPercentage]: () => React.createElement(React.Fragment, null),
        };
        this.filterCaseByType = (item) => {
            const leftHand = this.props.leftHand;
            if (!leftHand)
                return true;
            const variable = _entity_1.ModuleFE_Variable.cache.unique(leftHand.id);
            const expression = _entity_1.ModuleFE_Expression.cache.unique(leftHand.id);
            if (!variable && !expression && leftHand.type !== 'atom')
                return false;
            if (leftHand.type === 'expression') {
                return (0, utils_1.getExpressionQueryFilter)(leftHand.id, item, this.state.mappers, this.props.operatorsToFilter);
            }
            if (leftHand.type === 'atom') {
                if (!this.props.atom)
                    return true;
                return (0, utils_1.getAtomQueryFilter)(this.props.atom, item, this.state.mappers, this.props.operatorsToFilter);
            }
            return (0, utils_1.getValueQueryFilter)(leftHand, item, this.state.mappers, this.props.operatorsToFilter);
        };
        /**
         * Resolve the right variable id to use in the value dropdown from all cases
         * In case of sub-term resolve it from the passed atom prop if the logic is self
         * in all other cases resolve it right from this atom left hand
         */
        this.resolveVariableId = () => {
            var _a, _b;
            const leftHandItem = this.props.leftHand;
            const atomItem = this.props.atom;
            // This condition ensure that we're in a sub-term context and we're using self logic
            if (atomItem && atomItem.cases[0].caseOperator === consts_1.LogicType_Self)
                return (_a = atomItem.leftHand) === null || _a === void 0 ? void 0 : _a.id;
            return (_b = leftHandItem.propertyId) !== null && _b !== void 0 ? _b : leftHandItem === null || leftHandItem === void 0 ? void 0 : leftHandItem.id;
        };
        this.getCaret = () => {
            return !this.props.viewMode ? consts_2.DropDown_DefaultCaret : undefined;
        };
        this.resolveRangeFromValue = (valueId) => {
            var _a;
            const value = _entity_1.ModuleFE_Value.cache.unique(valueId);
            if (!value)
                throw new ts_common_1.BadImplementationException(`value with id ${valueId} doesn't exists`);
            return (_a = value.data) === null || _a === void 0 ? void 0 : _a.scope;
        };
        this.setDeltaTypeOperator = async (deltaType) => {
            const baseDeltaOp = (0, shared_1.getBaseDeltaOperator)(this.state.editable.get('caseOperator'));
            const deltaTypeOperator = consts_1.AllDeltaLogic.find(op => op === `${baseDeltaOp}${deltaType}`);
            if (deltaType === consts_1.LogicType_Self)
                return this.state.editable.updateObj({
                    caseOperator: baseDeltaOp,
                    valueType: 'numeric',
                    rightHand: []
                });
            if (!deltaTypeOperator)
                throw new ts_common_1.MUSTNeverHappenException(`Invalid delta operators ${baseDeltaOp}${deltaType}`);
            await this.state.editable.updateObj({
                caseOperator: deltaTypeOperator,
                valueType: undefined
            });
        };
        // ######################### Render #########################
        this.renderBody = () => {
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.LL_H_T, { style: { gap: 10 } },
                    this.renderOperatorDropdown(),
                    this.renderDeltaTypeDropdown(),
                    this.renderRightHand()),
                React.createElement(frontend_1.LL_H_C, { className: "match_parent", style: { gap: 10, alignItems: 'flex-start', justifyContent: 'flex-end' } },
                    this.renderValueInput(),
                    !this.props.viewMode &&
                        React.createElement(frontend_1.LL_H_C, { onClick: () => this.props.deleteCase(), className: 'delete-wrapper match_height' },
                            React.createElement(styles_1.ICONS.x.component, { className: 'expression-editor__close-icon' }))));
        };
        this.renderDeltaTypeDropdown = () => {
            var _a;
            const caseOperator = this.state.editable.get('caseOperator');
            if (!consts_1.AllDeltaLogic.includes(caseOperator))
                return '';
            return React.createElement(ui_components_3.DropDown_DeltaTypes.selectable
            //this is very arabic, I dislike that we need to find a better solution to apply error handling on selectable stuff
            , { 
                //this is very arabic, I dislike that we need to find a better solution to apply error handling on selectable stuff
                error: (0, ts_common_1.exists)(this.state.editable.hasError('caseOperator')) ? {
                    message: this.state.editable.hasError('caseOperator'),
                    level: 'error'
                } : undefined, onSelected: this.setDeltaTypeOperator, showErrorTooltip: true, queryFilter: op => { var _a; return !((_a = this.props.operatorsToFilter) === null || _a === void 0 ? void 0 : _a.includes(op)); }, selected: (_a = consts_1.DeltaTypes.find(type => caseOperator.includes(type))) !== null && _a !== void 0 ? _a : consts_1.LogicType_Self });
        };
        this.getRightHandValuesDropdown = (unusedValues) => {
            const adapter = (0, frontend_1.SimpleListAdapter)(unusedValues, item => {
                const selected = Array.isArray(item.item) ? item.item[0] : item.item;
                if (!selected)
                    return React.createElement("div", { className: 'ts-dropdown__placeholder' }, "Pick A Value");
                const value = _entity_1.ModuleFE_Value.cache.unique(selected);
                if (!value)
                    throw new ts_common_1.MUSTNeverHappenException(`value with id ${selected} is missing`);
                return React.createElement(React.Fragment, null, (0, _entity_1.resolveValueDisplay)(value));
            });
            const mandatoryProps_ValueDropdown = {
                adapter: adapter,
                caret: this.getCaret()
            };
            return frontend_1.TS_DropDown.prepareEditable(mandatoryProps_ValueDropdown);
        };
        this.renderDbRangesDropDown = (editable) => {
            const leftHand = this.props.leftHand;
            // Expression doesn't have a value related to it so no need for the check.
            let variable;
            let variableVal;
            if ((leftHand === null || leftHand === void 0 ? void 0 : leftHand.type) !== 'expression' && !variableVal) {
                const varId = this.resolveVariableId();
                variable = _entity_1.ModuleFE_Variable.cache.unique(varId);
                variableVal = _entity_1.ModuleFE_Value.cache.unique(variable === null || variable === void 0 ? void 0 : variable.valueId);
            }
            if (!variableVal || variableVal.type === _entity_1.valueType_StandardRange || this.state.editable.get('caseOperator') !== consts_1.LogicType_In)
                return '';
            return React.createElement(ui_components_2.DropDown_Value.selectable, { placeholder: 'Pick Range (Optional)', onSelected: async (val) => {
                    if (val)
                        await this.state.editable.update('rightHand', [val._id]);
                    else
                        await this.state.editable.update('rightHand', []);
                }, selected: editable.item[0], caret: this.getCaret(), disabled: this.props.viewMode, showErrorTooltip: true, queryFilter: (val) => (0, utils_3.filterRelevantValues)(variableVal).includes(val._id) });
        };
        this.renderOperatorDropdown = () => {
            var _a;
            if (!(0, utils_1.validateLeftHandBaseExists)(this.props.leftHand) && this.props.leftHand)
                return;
            const adapter = (0, frontend_1.SimpleListAdapter)(consts_1.AllLogics.filter(logic => this.filterCaseByType(logic)), item => React.createElement(React.Fragment, null, item.item));
            const DropDown_CaseOperators = frontend_1.TS_DropDown.prepare({
                className: 'operator-dropdown',
                adapter: adapter,
                placeholder: 'Pick Operator',
                caret: this.getCaret()
            });
            const caseOperator = this.state.editable.get('caseOperator');
            const selectedOperator = (_a = (0, shared_1.getBaseDeltaOperator)(caseOperator)) !== null && _a !== void 0 ? _a : caseOperator;
            return DropDown_CaseOperators.selectable({
                showErrorTooltip: true,
                disabled: !this.props.leftHand || this.props.viewMode || (this.props.disableOperatorSelection
                    && consts_1.AllDeltaLogic.includes(caseOperator)),
                selected: selectedOperator,
                onSelected: async (value) => {
                    const changes = {};
                    if (value) {
                        changes.caseOperator = value;
                        changes.factor = undefined;
                        if (consts_1.EmptyRightHandOperators.includes(value))
                            changes.rightHand = [];
                        if (consts_1.SelfOperators.includes(value)) {
                            changes.valueType = (0, utils_2.resolveValueType)(this.props.leftHand, this.state.mappers);
                        }
                        else if ((0, ts_common_1.exists)(this.state.editable.get('valueType')))
                            changes.valueType = undefined;
                        await this.state.editable.updateObj(changes);
                    }
                }
            });
        };
        this.renderRightHand = () => {
            var _a, _b;
            const caseItem = this.state.editable.item;
            if (!caseItem.caseOperator || !(0, utils_1.validateLeftHandBaseExists)(this.props.leftHand))
                return '';
            const editableRightHand = this.state.editable.editProp('rightHand', []);
            const Renderer = (_b = (_a = this.Renderers)[caseItem.caseOperator]) === null || _b === void 0 ? void 0 : _b.call(_a, editableRightHand);
            if (!Renderer)
                return 'to be created';
            return React.createElement(React.Fragment, null, Renderer);
        };
        this.renderValueInput = () => {
            const editableCase = this.state.editable;
            return React.createElement(frontend_1.LL_H_C, { className: 'condition-value', style: { display: !this.props.withValue ? 'none' : 'flex' } },
                React.createElement("div", { className: 'divider' }),
                React.createElement(QRangeInputV3_1.QRangeInputV3, { editable: editableCase, prop: 'value', disabled: this.props.viewMode, className: 'term-value-input', placeholder: 'Value', shouldReset: true, showErrorTooltip: true }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state = super.deriveStateFromProps(nextProps, state);
        (_a = state.mappers) !== null && _a !== void 0 ? _a : (state.mappers = {
            variableMap: _entity_1.ModuleFE_Variable.cache.arrayToMap((_var) => _var._id),
            valueMap: _entity_1.ModuleFE_Value.cache.arrayToMap((_var) => _var._id),
            expressionMap: _entity_1.ModuleFE_Expression.cache.arrayToMap((_var) => _var._id),
        });
        return state;
    }
    /**
     * Retrieves relevant values for a given case right hand values in context with left and right hand items.
     * @param editable - The editable item of the right-hand array.
     * @returns An array of relevant values, excluding those already present in the right hand array.
     * @throws {MUSTNeverHappenException} If no value or variable is found.
     */
    getRelevantValues(editable) {
        const leftHandItem = this.props.leftHand;
        // Determine the variable ID based on left-hand item's type and properties.
        const variableId = this.resolveVariableId();
        const variable = _entity_1.ModuleFE_Variable.cache.unique(variableId);
        // Choose the appropriate value ID based on the left-hand item's type and the variable's existence.
        const valueId = (leftHandItem === null || leftHandItem === void 0 ? void 0 : leftHandItem.type) === 'expression' ||
            ((leftHandItem === null || leftHandItem === void 0 ? void 0 : leftHandItem.type) === 'atom' && !variable)
            ? _entity_1.valueID_YesNo
            : variable === null || variable === void 0 ? void 0 : variable.valueId;
        const value = _entity_1.ModuleFE_Value.cache.unique(valueId);
        if (!value) {
            throw new ts_common_1.MUSTNeverHappenException(`No value or variable found for variableId: ${variableId}`);
        }
        // Filter out the relevant values that are not already part of the editable item.
        // @ts-ignore
        return (0, utils_3.filterRelevantValues)(value).filter(val => !editable.item.includes(val));
    }
    renderVariableValueDropdown(editable) {
        const unusedValues = this.getRelevantValues(editable);
        const DropDown_RightHandValues = this.getRightHandValuesDropdown(unusedValues);
        return React.createElement(frontend_1.LL_V_L, { className: 'expression-sublist' }, DropDown_RightHandValues({
            editable: this.state.editable,
            prop: 'rightHand',
            disabled: this.props.viewMode,
            showErrorTooltip: true,
            onSelected: async (value) => {
                if (value)
                    await this.state.editable.update('rightHand', [value]);
            }
        }));
    }
    renderFactorInput() {
        const editableFactor = this.state.editable.editProp('factor', { upper: 1, lower: 1 });
        return React.createElement(frontend_1.LL_H_C, { className: 'case-editor__factor-container' },
            React.createElement(styles_1.ICONS.x.component, null),
            React.createElement("div", { className: 'factor-label' }, "Upper:"),
            React.createElement(ui_components_1.DefaultEditor_InputNumeric, { editable: editableFactor, placeholder: 'Upper Factor', prop: 'upper', disabled: this.props.viewMode, onChange: value => {
                    if (!value) {
                        return editableFactor.update('upper', 1);
                    }
                    return editableFactor.update('upper', value);
                }, showErrorTooltip: true }),
            React.createElement("div", { className: 'factor-label' }, "Lower:"),
            React.createElement(ui_components_1.DefaultEditor_InputNumeric, { editable: editableFactor, placeholder: 'Lower Factor', prop: 'lower', disabled: this.props.viewMode, onChange: value => {
                    if (!value) {
                        return editableFactor.update('lower', 1);
                    }
                    return editableFactor.update('lower', value);
                }, showErrorTooltip: true }));
    }
    renderNumericInput(editable) {
        if (this.state.editable.get('caseOperator') === consts_1.LogicType_In && (0, utils_2.getLeftHandType)(this.props.leftHand, this.state.mappers, this.props.atom) === 'time')
            return this.renderVariableValueDropdown(this.state.editable.editProp('rightHand', []));
        if (!_entity_1.ModuleFE_Expression.isRightHand.range(this.state.editable))
            throw new ts_common_1.BadImplementationException(`case operator ${this.state.editable.get('caseOperator')} doesn't fit renderer`);
        // First check if the value already exists and classify if it's range value id or a range tuple
        let valueRange;
        if (editable.item.length === 1) {
            valueRange = this.resolveRangeFromValue(editable.get(0));
        }
        return React.createElement(frontend_1.LL_H_C, { className: 'expression-sublist' },
            this.renderDbRangesDropDown(editable),
            React.createElement(QRangeInputV3_1.QRangeInputV3, { editable: this.state.editable, prop: 'rightHand', value: valueRange, placeholder: 'Value', disabled: this.props.viewMode, showErrorTooltip: true }));
    }
    renderInSetDropdownList(editable) {
        const unusedValues = this.getRelevantValues(editable);
        const adapter = (0, frontend_1.SimpleListAdapter)(unusedValues, (item) => {
            const value = _entity_1.ModuleFE_Value.cache.unique(item.item);
            if (!value || Array.isArray(item.item))
                return React.createElement(React.Fragment, null);
            return React.createElement(React.Fragment, null, (0, _entity_1.resolveValueDisplay)(value));
        });
        const DropDown_InSetMultiselect = frontend_1.TS_DropDown.prepareSelectable({
            adapter: adapter,
            caret: this.getCaret(),
            placeholder: 'Select in-set values',
        });
        return React.createElement(frontend_1.LL_V_L, { className: 'expression-sublist' },
            React.createElement(frontend_1.TS_MultiSelect_V2, { itemRenderer: (id, onDelete) => {
                    const value = _entity_1.ModuleFE_Value.cache.unique(id);
                    if (!value)
                        throw new ts_common_1.MUSTNeverHappenException(`Could not get value for id ${id}`);
                    return React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'value-label' }, (0, _entity_1.resolveValueDisplay)(value)),
                        !this.props.viewMode &&
                            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }));
                }, selectionRenderer: selector => {
                    return DropDown_InSetMultiselect({
                        disabled: this.props.viewMode,
                        showErrorTooltip: true,
                        queryFilter: item => !selector.existingItems.includes(item._id),
                        onSelected: (value) => {
                            if (value)
                                selector.onSelected(value);
                        },
                        error: (0, ts_common_1.exists)(this.state.editable.hasError('rightHand')) ? {
                            message: this.state.editable.hasError('rightHand'),
                            level: 'error'
                        } : undefined
                    });
                }, editable: this.state.editable, prop: 'rightHand' }));
    }
    /**
     * The equality operator can be used for numeric and enumerated values
     * this function will behave as an index making sure what's the value type of our left hand and conditionally render
     * the right component for the right hand
     * @param editable
     * @private
     */
    renderEqualityByType(editable) {
        var _a, _b;
        const leftHand = this.props.leftHand;
        if (!leftHand)
            return '';
        //If variable or expression not found return nothing
        const leftHandId = leftHand.type === 'atom' ? (_b = (_a = this.props.atom) === null || _a === void 0 ? void 0 : _a.leftHand) === null || _b === void 0 ? void 0 : _b.id : leftHand.id;
        const variable = _entity_1.ModuleFE_Variable.cache.unique(leftHandId);
        const expression = _entity_1.ModuleFE_Expression.cache.unique(leftHandId);
        if (!variable && !expression)
            return '';
        const type = (0, utils_2.getLeftHandType)(leftHand, this.state.mappers, this.props.atom);
        if (type === consts_1.ExpressionOutput_Numeric || type === _entity_1.valueType_StandardRange || type === _entity_1.valueType_Range)
            return this.renderNumericInput(editable);
        else
            return this.renderVariableValueDropdown(editable);
    }
    render() {
        return React.createElement(React.Fragment, null, this.renderBody());
    }
}
exports.Editor_Case = Editor_Case;
