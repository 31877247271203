"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateUserAcronym = exports.renderParticipantIcon = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
function renderParticipantIcon(user, account) {
    if (user) {
        const acronym = generateUserAcronym(user, account === null || account === void 0 ? void 0 : account.email);
        return React.createElement("div", { key: (0, ts_common_1.generateUUID)(), className: 'participant-icon' }, acronym);
    }
    if (account) {
        return React.createElement("div", { key: (0, ts_common_1.generateUUID)(), className: 'participant-icon' }, account.email.substring(0, 2).toUpperCase());
    }
    return React.createElement("div", { key: (0, ts_common_1.generateUUID)(), className: 'participant-icon' }, "n/a");
}
exports.renderParticipantIcon = renderParticipantIcon;
function generateUserAcronym(user, email) {
    if ((user === null || user === void 0 ? void 0 : user.firstName) && (user === null || user === void 0 ? void 0 : user.surname))
        return `${user.firstName.at(0).toUpperCase()}${user.surname.at(0).toUpperCase()}`;
    if (!email)
        return 'n/a';
    return email.substring(0, 2).toUpperCase();
}
exports.generateUserAcronym = generateUserAcronym;
