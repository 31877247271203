"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_PyJsonPreview = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const convertExpressionToSchemaFE_1 = require("../../../../../schema-converion-fe/convertExpressionToSchemaFE");
const ts_common_1 = require("@nu-art/ts-common");
const q_components_1 = require("../../../../../../q-components");
class Component_PyJsonPreview extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.getConvertedExpression = () => {
            return (0, convertExpressionToSchemaFE_1.convertExpressionToSchemaFE)((0, ts_common_1.deepClone)(this.state.editable.item));
        };
    }
    deriveStateFromProps(nextProps, state) {
        return super.deriveStateFromProps(nextProps, state);
    }
    renderErrorMsg(msg) {
        return React.createElement(frontend_1.LL_V_L, null,
            React.createElement("div", { style: { whiteSpace: 'break-spaces' } }, msg));
    }
    render() {
        if (!this.state.editable.get('_id'))
            return React.createElement(frontend_1.LL_V_L, null,
                React.createElement("div", { style: { whiteSpace: 'break-spaces' } }, "Save expression in order to see py json preview..."));
        let editableClone;
        try {
            editableClone = this.getConvertedExpression();
            if (!editableClone)
                return this.renderErrorMsg(`Couldn't render expression, some features not implemented yet`);
        }
        catch (err) {
            if (err instanceof ts_common_1.BadImplementationException) {
                return this.renderErrorMsg(`Couldn't render expression:\n${err.message}`);
            }
            else
                throw err;
        }
        return React.createElement(frontend_1.LL_V_L, { className: 'json-view' },
            React.createElement(q_components_1.QJSONViewer, { item: editableClone }));
    }
}
exports.Component_PyJsonPreview = Component_PyJsonPreview;
