"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message_Renderer = void 0;
const React = require("react");
require("./Message_Renderer.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@nu-art/user-account/frontend");
const _entity_1 = require("../../../_entity");
const shared_1 = require("@nu-art/ts-messaging/_entity/message/shared");
const frontend_3 = require("@nu-art/ts-messaging/_entity/message/frontend");
class Message_Renderer extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        const message = frontend_3.ModuleFE_Message.cache.unique(nextProps.messageId);
        if (!message)
            throw new ts_common_1.BadImplementationException(`Couldn't find message with id: ${nextProps.messageId}`);
        state.message = message;
        return state;
    }
    renderMessageContent(message) {
        if (shared_1.MessageTools.isText(message))
            return this.renderTextMessage(message);
        return React.createElement("div", null,
            "Unsupported message type: ",
            this.state.message.type);
    }
    renderTextMessage(message) {
        return React.createElement("div", { className: 'message-text' }, message.text);
    }
    render() {
        if (!this.state.message)
            return;
        const account = frontend_2.ModuleFE_Account.cache.unique(this.state.message._auditorId);
        let name;
        if (account) {
            const user = _entity_1.ModuleFE_User.cache.unique(account._id);
            if (user && user.firstName)
                name = `${user.firstName}${user.surname ? ` ${user.surname}` : ``}`;
            else
                name = `${account.email}`;
        }
        else {
            name = `${this.state.message._auditorId}(deleted)`;
        }
        const dateTimeFormat = (0, ts_common_1.formatTimestamp)('MM/D/YY HH:mm', this.state.message.__created);
        return React.createElement(frontend_1.LL_V_L, { className: 'message-container' },
            React.createElement(frontend_1.LL_H_C, { className: 'message-header' },
                React.createElement("div", { className: 'sender-name' }, name),
                React.createElement("div", { className: 'date-format' }, dateTimeFormat)),
            this.renderMessageContent(this.state.message));
    }
}
exports.Message_Renderer = Message_Renderer;
