"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceTypesArray = exports.ResourceTypeToIconMap = exports.PermissionKey_ResourceV2Viewer = exports.PermissionKey_ResourceV1Viewer = exports.PermissionKey_ResourceGeneral = exports.DomainNamespace_ResourceV2 = exports.DomainNamespace_ResourceV1 = exports.DomainNamespace_Resource = void 0;
exports.DomainNamespace_Resource = 'Resource';
exports.DomainNamespace_ResourceV1 = 'ResourceV1';
exports.DomainNamespace_ResourceV2 = 'ResourceV2';
exports.PermissionKey_ResourceGeneral = 'resource-general';
exports.PermissionKey_ResourceV1Viewer = 'permission-key--resource-v1-viewer';
exports.PermissionKey_ResourceV2Viewer = 'permission-key--resource-v2-viewer';
exports.ResourceTypeToIconMap = {
    'Laboratory': 'Laboratory',
    'Imaging': 'Imaging',
    'Procedures': 'Vital Signs',
    'Consultations': 'Physician',
    'EKG': 'Physician'
};
exports.ResourceTypesArray = ['Laboratory', 'Imaging', 'Procedures', 'Consultations', 'EKG'];
