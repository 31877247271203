"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATS_ExpressionBuildingUtils = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const antlr4ts_1 = require("antlr4ts");
const ErrorsListener_1 = require("../../expression-smart-editor/antlr-utils/ErrorsListener");
require("./ATS_ExpressionBuildingUtils.scss");
const ExpressionJsonVisitor_1 = require("../../expression-smart-editor/antlr-utils/ExpressionJsonVisitor");
const QuaiExpressionsV3Parser_1 = require("../../../../../../shared/expression/grammar/QuaiExpressionsV3Parser");
const ExpressionsVisitors_1 = require("../../expression-smart-editor/antlr-utils/ExpressionsVisitors");
const frontend_2 = require("../../../../../../_entity/variable/frontend");
const frontend_3 = require("../../../../../../_entity/value/frontend");
const QuaiExpressionsV3Lexer_1 = require("../../../../../../shared/expression/grammar/QuaiExpressionsV3Lexer");
class ATS_ExpressionBuildingUtils extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.startAntlr = (expression, isJson = false, withLogs) => {
            const inputStream = new antlr4ts_1.ANTLRInputStream(expression);
            const lexer = new QuaiExpressionsV3Lexer_1.QuaiExpressionsV3Lexer(inputStream);
            const tokenStream = new antlr4ts_1.CommonTokenStream(lexer);
            const parser = new QuaiExpressionsV3Parser_1.QuaiExpressionsV3Parser(tokenStream);
            console.log(expression);
            let myQuaiExpressionsBooleansVisitor;
            if (!isJson)
                myQuaiExpressionsBooleansVisitor = new ExpressionsVisitors_1.ExpressionsVisitors(withLogs, frontend_3.ModuleFE_Value.cache.unique);
            else
                myQuaiExpressionsBooleansVisitor = new ExpressionJsonVisitor_1.ExpressionJsonVisitor();
            const errorListener = new ErrorsListener_1.ExpressionsErrorListener();
            // //lexer syntax listener
            // lexer.removeErrorListeners();
            // // @ts-ignore
            // lexer.addErrorListener(errorListener);
            //
            // //parser syntax listener
            // parser.removeErrorListeners();
            // parser.addErrorListener(errorListener);
            return { resolver: myQuaiExpressionsBooleansVisitor, parser: parser, errorListener: errorListener };
        };
    }
    // parseExpression = (isJson = false) => {
    // 	const value = this.state.editor.getValue().trim();
    // 	const variableNames = this.findVarsOrValues(value, 'var');
    // 	const valueNames = this.findVarsOrValues(value, 'enum');
    // 	const _expr = this.replaceValues(value, valueNames);
    // 	const expressionToParse = this.replaceVars(_expr, variableNames).trim();
    //
    // 	const {resolver, parser} = this.startAntlr(expressionToParse, isJson, true);
    //
    // 	const currResult = resolver.visit(parser.expression());
    //
    // 	if (!isJson)
    // 		this.setState({currResult: currResult.toString()});
    // 	else {
    // 		this.setState({json: __stringify(currResult, true)});
    // 	}
    // };
    // private handleMonacoMount = (editor: any, monaco: Monaco) => {
    //
    // 	monaco.languages.register({id: this.langName});
    //
    // 	monaco.languages.setMonarchTokensProvider(this.langName, {
    // 		tokenizer: {
    // 			root: [
    // 				[/./, {token: 'invalid'}]
    // 			]
    // 		}
    // 	});
    //
    // 	monaco.languages.setTokensProvider(this.langName, {
    // 		getInitialState: () => new SimpleState(),
    // 		tokenize: (line: string, state: any) => {
    // 			const lexer = new QuaiExpressionsV3Lexer(CharStreams.fromString(line));
    // 			const adapter = new AntlrToMonacoAdapter(lexer);
    // 			return {
    // 				endState: state,
    // 				tokens: adapter.tokenize(line)
    // 			};
    // 		}
    // 	});
    //
    // 	monaco.editor.defineTheme('quai-theme', {
    // 		base: 'vs',
    // 		inherit: true,
    // 		rules: [
    // 			{token: 'number', foreground: '#1a4957'},
    // 			{token: 'string', foreground: '#c95132'},
    // 			{token: 'boolean', foreground: '#151594'},
    // 			{token: 'variable', foreground: '#800080'},
    // 			{token: 'identifier', foreground: '#94620a', fontStyle: 'italic'},
    // 			{token: 'whitespace', foreground: '#FFFFFF'},
    // 			{token: 'operator', foreground: '#17a666', fontStyle: 'bold', background: '#ff0000'},
    // 		],
    // 		colors: {
    // 			'editor.background': '#FFFFFF',
    // 			'editor.foreground': '#3b3a3a',
    // 		}
    // 	});
    //
    // 	monaco.editor.setTheme('quai-theme');
    //
    // 	editor.onDidChangeModelContent((event: any) => {
    // 		const value = editor.getValue();
    //
    // 		if (!value.length)
    // 			this.setState({variableDependenciesList: []});
    //
    //
    // 		const variableNames = this.findVarsOrValues(value, 'var');
    //
    // 		if (!variableNames.length) {
    // 			this.setState({variableDependenciesList: []});
    // 		}
    //
    //
    // 		if (variableNames.length)
    // 			this.updateVarDependencies(variableNames);
    //
    // 	});
    //
    // 	monaco.languages.registerCompletionItemProvider(this.langName, {
    // 		provideCompletionItems: (model, position) => {
    // 			const currentWordInfo = model.getWordAtPosition(position);
    //
    // 			const previousText = model.getValueInRange({
    // 				startLineNumber: position.lineNumber,
    // 				startColumn: 1,
    // 				endLineNumber: position.lineNumber,
    // 				endColumn: currentWordInfo ? currentWordInfo.startColumn : position.column
    // 			});
    //
    // 			const regex = /(var|enum)\(([^)]*?)(\).(\w*\.)*\w*)?$/;
    // 			const match = previousText.match(regex);
    // 			const prevWord = match ? match[0] : '';
    //
    // 			let results: string[] = [];
    // 			if (prevWord === 'enum(') {
    // 				const values = this.resolveVariableEnumValues(splitExpression(model.getValue()));
    //
    // 				if (values)
    // 					results = values;
    // 				else
    // 					results = ModuleFE_Value.cache.filter(item => (item.data as ValueData_EnumElement)?.value?.toLowerCase().includes(currentWordInfo?.word.toLowerCase() ?? '')).map(item => (item.data as ValueData_EnumElement).value);
    // 			} else if (prevWord === 'var(')
    // 				results = ModuleFE_Variable.cache.filter(item => item._nameInLowercase.includes(currentWordInfo?.word.toLowerCase() ?? '')).map(item => item.name);
    // 			else if (/var\([^)]*\)(\.\w*)*/.test(prevWord)) {
    // 				const varPattern = /\(([^)]+)\)/;
    // 				const matchVar = varPattern.exec(prevWord)?.[1];
    //
    // 				const splitExpr = prevWord.match(/var\([^)]*\)\.(\w+(\.\w+)*)/);
    // 				let propertiesArray: string[] = [];
    //
    // 				if (splitExpr && splitExpr[1]) {
    // 					propertiesArray = splitExpr[1].split('.');
    // 				}
    //
    // 				const variable = ModuleFE_Variable.cache.find(item => item._nameInLowercase === matchVar?.toLowerCase());
    // 				const currValue = propertiesArray.reduce((value, currentProp) => {
    // 					// @ts-ignore
    // 					return value[currentProp];
    // 				}, variable!);
    //
    // 				if (!currValue)
    // 					return;
    //
    // 				results = _keys(currValue);
    // 			}
    //
    //
    // 			const range = {
    // 				startLineNumber: position.lineNumber,
    // 				endLineNumber: position.lineNumber,
    // 				startColumn: currentWordInfo ? currentWordInfo.startColumn : position.column,
    // 				endColumn: position.column
    // 			};
    //
    // 			return {
    // 				suggestions: filterDuplicates(results).map(result => ({
    // 					label: result,
    // 					kind: monaco.languages.CompletionItemKind.Text,
    // 					insertText: result,
    // 					insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    // 					range
    // 				}))
    // 			};
    // 		},
    // 	});
    // 	this.setState({monaco: monaco, editor: editor});
    // };
    // private renderDDValues = (dbValue: DB_Value, index: number) => {
    // 	const relevantValues = filterRelevantValues(dbValue);
    // 	return <DropDown_Values
    // 		selected={this.state.variableDependenciesList[index].value}
    // 		onSelected={(value) => {
    // 			this.state.variableDependenciesList[index].value = value._id;
    // 			this.forceUpdate();
    // 		}}
    // 		containerValue={dbValue}
    // 		queryFilter={(item) => relevantValues.includes(item._id)}
    // 	/>;
    //
    // };
    // private replaceValues = (expr: string, results: string[], isCalc = true) => {
    // 	let _expr = expr;
    //
    // 	if (!results.length)
    // 		return _expr;
    //
    // 	results.map((_val, index) => {
    // 		const value = ModuleFE_Value.cache.find(item => (item.data as ValueData_EnumElement)?.value?.toLowerCase() === _val.toLowerCase())!;
    //
    // 		if (value)
    // 			_expr = _expr.replace(_val, value._id);
    // 	});
    //
    // 	return _expr;
    // };
    // private replaceVars = (expr: string, results: string[], isCalc = true) => {
    // 	let _expr = expr;
    //
    // 	results.map((_var, index) => {
    // 		const variable = ModuleFE_Variable.cache.find(item => item._nameInLowercase === _var.toLowerCase())!;
    //
    // 		const variableDependenciesListElement = structuredClone(this.state.variableDependenciesList?.[index]);
    //
    // 		if (isCalc && variableDependenciesListElement?.value && variableDependenciesListElement.value !== '') {
    // 			if (/^[a-fA-F0-9]{32}$/.test(variableDependenciesListElement.value))
    // 				variableDependenciesListElement.value = `"${(ModuleFE_Value.cache.unique(variableDependenciesListElement.value)!.data as ValueData_EnumElement).value}"`;
    //
    // 			const findVar = new RegExp(`var\\(${_var}\\)(\\.\\w+)*`);
    // 			_expr = _expr.replace(findVar, variableDependenciesListElement.value);
    // 		} else if (variable)
    // 			_expr = _expr.replace(_var, variable._id);
    // 	});
    //
    // 	return _expr;
    // };
    // private renderInputType = (variable: DB_Variable, index: number) => {
    // 	const dbValue = ModuleFE_Value.cache.unique(variable.valueId);
    //
    // 	if (!dbValue)
    // 		throw new BadImplementationException(`value with id ${variable.valueId} not found`);
    //
    //
    // 	switch (dbValue.type) {
    // 		case 'enumerated':
    // 			return this.renderDDValues(dbValue, index);
    // 		case 'tuple':
    // 			return this.renderDDValues(dbValue, index);
    // 		default:
    // 			return <TS_Input
    // 				type={'number'}
    // 				placeholder={'Insert Value'}
    // 				value={this.state.variableDependenciesList[index].value}
    // 				onBlur={(value) => {
    // 					this.state.variableDependenciesList[index].value = value;
    // 					this.forceUpdate();
    // 				}}
    // 			/>;
    // 	}
    //
    // };
    // private renderVarDep = (varDep: VarDep, index: number) => {
    // 	const _var = ModuleFE_Variable.cache.find(_varId => _varId._id === varDep.varId);
    //
    // 	if (!_var)
    // 		return '';
    //
    // 	return <TS_PropRenderer.Horizontal label={_var.name}
    // 									   key={generateUUID()}>
    // 		{this.renderInputType(_var, index)}
    // 	</TS_PropRenderer.Horizontal>;
    // };
    // private renderSimulatorPanel = () => {
    // 	return <LL_V_L className={'simulator-panel'}>
    // 		<div className={'title'}>Simulate Calculation</div>
    // 		<LL_V_L className={'var-list'}>
    // 			{
    // 				this.state.variableDependenciesList?.map((item, index) => this.renderVarDep(item, index))
    // 			}
    // 		</LL_V_L>
    // 		<LL_V_L className={'calculation-panel'}>
    // 			{this.state.currResult && <div>Result: {this.state.currResult.toString()}</div>}
    // 			<LL_H_C>
    // 				<TS_Button onClick={() => this.parseExpression()}>Calculate</TS_Button>
    // 				<TS_Button onClick={() => this.parseExpression(true)}>Export Json</TS_Button>
    // 			</LL_H_C>
    // 		</LL_V_L>
    // 	</LL_V_L>;
    // };
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent screen' },
            React.createElement(frontend_1.LL_H_C, { className: 'expression_composer' }));
    }
}
_a = ATS_ExpressionBuildingUtils;
ATS_ExpressionBuildingUtils.screen = {
    name: 'Expressions Utils Playground',
    key: 'expressions-utils-playground',
    renderer: _a,
    group: 'Quai Dev Tools',
    modulesToAwait: [frontend_2.ModuleFE_Variable, frontend_3.ModuleFE_Value]
};
exports.ATS_ExpressionBuildingUtils = ATS_ExpressionBuildingUtils;
