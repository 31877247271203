"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Tag = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateRegexp)(/^[\w\W\s&/,\\-]{2,100}$/),
    tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    _origin: ts_common_1.tsValidator_nonMandatoryString
};
const Validator_GeneratedProps = {};
exports.DBDef_Tag = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.1'],
    dbKey: 'tags',
    entityName: 'Tag',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'tag'
    },
    backend: {
        name: 'tags'
    },
    dependencies: {
        tagIds: {
            dbKey: 'tags',
            fieldType: 'string[]'
        }
    }
};
