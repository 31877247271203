"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PgDev_DPReport = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./PgDev_DPReport.scss");
const ts_common_1 = require("@nu-art/ts-common");
const PgDev_DPReport_MultiVars_1 = require("./PgDev_DPReport_MultiVars");
const frontend_2 = require("@app/hcs/frontend");
const shared_components_1 = require("../../../shared-components");
const _entity_1 = require("../../../../_entity");
const shared_1 = require("../../../../../_enum/criticality/shared");
class PgDev_DPReport_Class extends frontend_1.ComponentSync {
    constructor() {
        //######################### Life Cycle #########################
        super(...arguments);
        //######################### Logic #########################
        this.addDP = (dp) => {
            this.state.diseaseProfiles.push(dp);
            this.forceUpdate();
        };
        this.getTabs = () => {
            return [
                {
                    uid: 'general',
                    title: 'General',
                    content: this.renderGeneralTab,
                },
                {
                    uid: 'multiVars',
                    title: 'Multi Vars',
                    content: this.renderMultiVarsTab,
                },
            ];
        };
        this.updateHeights = (heightMap) => {
            if ((0, ts_common_1.compare)(this.state.heightMap, heightMap))
                return;
            const hm = (0, ts_common_1._keys)(heightMap).reduce((hm, key) => {
                hm[key] = Math.max(heightMap[key], this.state.heightMap[key] || 0);
                return hm;
            }, {});
            this.setState({ heightMap: hm });
        };
        //######################### Render #########################
        this.renderTabHeader = (profile) => {
            return React.createElement("span", { className: 'profile-title' }, profile.label);
        };
        this.renderEmptyPanel = () => {
            const existingProfileIds = this.state.diseaseProfiles.map(ts_common_1.dbObjectToId);
            return React.createElement("div", { className: 'profile empty' },
                React.createElement(shared_components_1.DPDropDowns.DiseaseProfile, { selected: undefined, onSelected: this.addDP, placeholder: 'Pick Disease Profile To Compare', queryFilter: item => !existingProfileIds.includes(item._id) }));
        };
        //######################### Render - Tabs #########################
        this.renderGeneralTab = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'dp-report__tab', id: 'dp-report' },
                this.state.diseaseProfiles.map((profile, i) => {
                    var _a;
                    const variable = frontend_2.ModuleFE_Variable.cache.unique(profile.variableId);
                    return React.createElement(frontend_1.LL_V_L, { className: 'profile', key: i },
                        this.renderTabHeader(profile),
                        React.createElement(frontend_1.LL_V_L, { className: 'profile-data' },
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "Disease Name"),
                                React.createElement("div", { className: 'field-data' }, variable.name)),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "Initials"),
                                React.createElement("div", { className: 'field-data' }, profile.initials)),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "ICDs"),
                                React.createElement("div", { className: 'field-data' }, (_a = profile.icd) === null || _a === void 0 ? void 0 : _a.map(id => frontend_2.ModuleFE_Reference.cache.unique(id).data.id))),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "General Description"),
                                React.createElement("div", { className: 'field-data' }, profile.profileDescription)),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "Notes"),
                                React.createElement("div", { className: 'field-data' }, profile.profileNotes)),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "Criticality"),
                                React.createElement("div", { className: 'field-data' }, profile.criticality ? shared_1.CriticalityLabelMap[profile.criticality] : '-')),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "Scores"),
                                React.createElement("div", { className: 'field-data' }, profile.scores)),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "Epidemiology Notes"),
                                React.createElement("div", { className: 'field-data' }, profile.epidemiologyNotes)),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "Prognosis Notes"),
                                React.createElement("div", { className: 'field-data' }, profile.prognosisNotes)),
                            React.createElement(frontend_1.LL_V_L, { className: 'field' },
                                React.createElement("div", { className: 'field-title' }, "References"),
                                React.createElement(frontend_2.Component_ReferencesTable, { blockEdit: true, referenceIds: profile.referenceIds || [], onReferenceUpsertCallback: ts_common_1.voidFunction, onReferenceDeletedCallback: ts_common_1.voidFunction, allowedRefs: undefined }))));
                }),
                this.state.diseaseProfiles.length < 2 && this.renderEmptyPanel());
        };
        this.renderMultiVarsTab = () => {
            return React.createElement("div", { className: 'dp-report__tab', id: 'dp-report' },
                this.state.diseaseProfiles.map((dp, i) => {
                    return React.createElement(PgDev_DPReport_MultiVars_1.PgDev_DPReport_MultiVars, { diseaseProfile: dp, key: dp._id, heightMap: this.state.heightMap, reportHeights: this.updateHeights });
                }),
                this.state.diseaseProfiles.length < 2 && this.renderEmptyPanel());
        };
    }
    deriveStateFromProps(nextProps, state) {
        state.diseaseProfiles = (state === null || state === void 0 ? void 0 : state.diseaseProfiles) || nextProps.diseaseProfiles || [];
        state.diseaseViews = _entity_1.ModuleFE_DiseaseView.cache.all();
        state.heightMap = state.heightMap || {};
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'pg-dev-dp-report' },
            React.createElement(frontend_1.LL_H_C, { className: 'dp-report' },
                React.createElement(frontend_1.TS_Tabs, { tabs: this.getTabs() })));
    }
}
exports.PgDev_DPReport = { name: 'DP Report', renderer: PgDev_DPReport_Class };
