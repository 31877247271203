"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_LogTemplate = exports.DropDown_LogTemplate_OLD = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_LogTemplate_OLD_1 = require("./ModuleFE_LogTemplate_OLD");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_LogTemplate_OLD_1.ModuleFE_LogTemplate_OLD,
    modules: [ModuleFE_LogTemplate_OLD_1.ModuleFE_LogTemplate_OLD],
    mapper: item => [item.title],
    placeholder: 'Choose a Log Template',
    renderer: item => React.createElement(React.Fragment, null, item.title)
};
exports.DropDown_LogTemplate_OLD = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_LogTemplate_OLD_1.ModuleFE_LogTemplate_OLD,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }),
            item.title);
    },
    uiSelector: exports.DropDown_LogTemplate_OLD.selectable,
});
exports.MultiSelect_LogTemplate = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
