"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Variable = exports.ModuleFE_Variable_Class = exports.dispatch_onVariableChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_NLPData_1 = require("../../nlp-data/frontend/ModuleFE_NLPData");
const ModuleFE_Value_1 = require("../../value/frontend/ModuleFE_Value");
const frontend_2 = require("../../discussion/frontend");
const frontend_3 = require("../../expression/frontend");
exports.dispatch_onVariableChanged = new types_1.ThunderDispatcherV3('__onVariableUpdated');
class ModuleFE_Variable_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Variable, exports.dispatch_onVariableChanged);
        this.__searchGroups = (items) => ([{
                group: this.dbDef.entityName,
                results: items.map(i => ({ _id: i._id, uiLabel: i.name }))
            }]);
        this.__searchFilterMapper = (item) => {
            const nlpData = ModuleFE_NLPData_1.ModuleFE_NLPData.cache.unique(item.nlpDataId);
            return (0, ts_common_1.filterInstances)([item.name, nlpData === null || nlpData === void 0 ? void 0 : nlpData.synonyms.join('  ')]);
        };
        this.__searchGroupsRefs = (items) => {
            let results = items.map(reference => (this.cache.find(variable => variable.referenceIds.includes(reference._id))));
            results = results.filter(item => item);
            return [{
                    group: this.dbDef.entityName,
                    results: results.map(item => ({ _id: item === null || item === void 0 ? void 0 : item._id, uiLabel: item === null || item === void 0 ? void 0 : item.name }))
                }];
        };
        this.getVariableIdForValueId = (valueId) => {
            const value = ModuleFE_Value_1.ModuleFE_Value.cache.unique(valueId);
            if (!value)
                throw new ts_common_1.BadImplementationException(`Could not find value for id ${valueId}`);
            switch (value.type) {
                case 'tuple': {
                    this.logWarning('Trying to get a variable for a tuple value, too many answers!');
                    return undefined;
                }
                case 'enum-element': {
                    const parent = ModuleFE_Value_1.ModuleFE_Value.cache.find(parent => parent.type === 'enumerated' && parent.data.scope.includes(value._id));
                    if (!parent)
                        throw new ts_common_1.BadImplementationException(`Could not find parent value for value with id ${valueId}`);
                    const variable = this.cache.find(variable => variable.valueId === parent._id);
                    if (!variable)
                        throw new ts_common_1.BadImplementationException(`Could not find variable for value with id ${parent._id}`);
                    return variable._id;
                }
                case 'sub-range': {
                    const parent = ModuleFE_Value_1.ModuleFE_Value.cache.find(parent => parent.type === 'range' && parent.data.subsets.includes(valueId));
                    if (!parent)
                        throw new ts_common_1.BadImplementationException(`Could not find parent value for value with id ${valueId}`);
                    const variable = this.cache.find(variable => variable.valueId === parent._id);
                    if (!variable)
                        throw new ts_common_1.BadImplementationException(`Could not find variable for value with id ${parent._id}`);
                    return variable._id;
                }
                case 'range':
                case 'enumerated': {
                    const variable = this.cache.find(variable => variable.valueId === valueId);
                    if (!variable)
                        throw new ts_common_1.BadImplementationException(`Could not find variable for value with id ${valueId}`);
                    return variable._id;
                }
            }
        };
        this._v1 = {
            getVariables: (0, frontend_1.apiWithBody)(shared_1.ApiDef_Variable._v1.getVariables),
            getAttributes: (0, frontend_1.apiWithBody)(shared_1.ApiDef_Variable._v1.getAttributes),
            createUIVariable: (0, frontend_1.apiWithBody)(shared_1.ApiDef_Variable._v1.createUIVariable, async (response, uiVar) => {
                /*FIXME: reading original like this is wrong, also onEntryUpdated does not assume that there might not be an original,
                                                 so an empty object is sent instead.
                                                 fix this when response is fixed to send the body as an object and not as a string!
                */
                const original = JSON.parse(uiVar).db || {};
                //Updated known changes
                await this.onEntryUpdated(response.dbVar, original);
                await ModuleFE_Value_1.ModuleFE_Value.onEntriesUpdated(response.dbValues);
                if (response.expression) {
                    await frontend_3.ModuleFE_Expression.onEntriesUpdated([response.expression]);
                }
            }),
        };
        // ModuleFE_References.__searchGroups = this.__searchGroupsRefs;
    }
    init() {
        super.init();
        frontend_2.ModuleFE_Discussion.registerLabelFromEntityConverter(this.dbDef.dbKey, (variable) => variable.name);
    }
}
exports.ModuleFE_Variable_Class = ModuleFE_Variable_Class;
exports.ModuleFE_Variable = new ModuleFE_Variable_Class();
