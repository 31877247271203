import * as React from 'react';
import {
	DBProto_PermissionUser,
	ModuleFE_PermissionAccessLevel,
	ModuleFE_PermissionDomain,
	ModuleFE_PermissionGroup,
	UI_PermissionUser
} from '@nu-art/permissions/frontend';
import {
	EditableItem,
	EditableRef,
	LL_V_L,
	SimpleListAdapter,
	TS_DropDown,
	TS_PropRenderer
} from '@nu-art/thunderstorm/frontend';
import {MUSTNeverHappenException, UniqueId} from '@nu-art/ts-common';
import {UI_User} from '@app/hcs';
import {ICONS} from '@app/styles';
import {Component_UserTypeInfo} from '../components/Component_UserTypeInfo';
import {PermissionGroupData_All} from '@app/shared/permission-groups';
import {
	TS_EditableItemComponentProto
} from '@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent';
import {InferProps, InferState} from '@nu-art/thunderstorm/frontend/utils/types';

type Props = EditableRef<UI_PermissionUser> & {
	user: EditableItem<UI_User>;
};

type State = EditableRef<UI_PermissionUser> & {
	user: EditableItem<UI_User>;
};

export class Component_UserPermissions
	extends TS_EditableItemComponentProto<DBProto_PermissionUser, Props, State> {

	readonly ddCarets = {
		open: <ICONS.advisorArrowDown.component className={'open'}/>,
		close: <ICONS.advisorArrowDown.component className={'close'}/>,
	};

	// ######################## Lifecycle ########################

	protected deriveStateFromProps(nextProps: InferProps<this>, state: InferState<this>) {
		state = super.deriveStateFromProps(nextProps, state);
		state.user = nextProps.user;
		return state;
	}

	// ######################## Logic ########################

	private setGroup = async (groupId: UniqueId) => {
		//Clear all other group ids from user
		const user = this.state.editable;
		const permissionGroupDataIds = PermissionGroupData_All.map(i => i._id);
		const groups = user.item.groups?.filter(group => !permissionGroupDataIds.includes(group.groupId)) ?? [];
		groups.push({groupId});

		try {
			await user.updateObj({groups});
		} catch (err: any) {
			this.logError(err);
		}
	};

	private getSelectedPermissionGroup = () => {
		return PermissionGroupData_All.find(group => this.state.editable.item.groups?.find(_group => _group.groupId === group._id));
	};

	// ######################## Render ########################

	private renderHCSAccess = () => {
		const adapter = SimpleListAdapter(PermissionGroupData_All, i => <>{i.item.name}</>);
		const selectedGroupData = this.getSelectedPermissionGroup();
		const selectedPermissionGroup = ModuleFE_PermissionGroup.cache.unique(selectedGroupData?._id);
		if (selectedGroupData && !selectedPermissionGroup)
			throw new MUSTNeverHappenException(`Could not get permission group for id ${selectedGroupData._id}`);

		return <LL_V_L className={'match_width'} style={{gap: '8px'}}>
			<TS_PropRenderer.Horizontal label={'Knowledge Manager Access'} className={'user-permissions__role'}>
				<Component_UserTypeInfo scope={'knowledgeManager'}/>
				<TS_DropDown adapter={adapter} selected={selectedGroupData}
										 onSelected={item => this.setGroup(item._id)} caret={this.ddCarets}/>
			</TS_PropRenderer.Horizontal>
			{selectedPermissionGroup && selectedPermissionGroup.accessLevelIds.map(accessLevelId => {
				const accessLevel = ModuleFE_PermissionAccessLevel.cache.unique(accessLevelId);
				if (!accessLevel)
					throw new MUSTNeverHappenException(`Could not get access level for id ${accessLevelId}`);

				const domain = ModuleFE_PermissionDomain.cache.unique(accessLevel.domainId);
				if (!domain)
					throw new MUSTNeverHappenException(`Could not get domain for id ${accessLevel.domainId}`);

				return <TS_PropRenderer.Horizontal label={domain.namespace} key={domain._id}>
					{accessLevel.name}
				</TS_PropRenderer.Horizontal>;
			})}
		</LL_V_L>;
	};

	render() {
		return <div className={'user-permissions'}>
			{this.renderHCSAccess()}
		</div>;
	}
}