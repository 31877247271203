"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_Binary_CategoryOptions_ToLabelsMap = exports.FHIR_Binary_CategoryOptions = exports.Category_ClinicalNotes = void 0;
exports.Category_ClinicalNotes = 'clinical-notes';
exports.FHIR_Binary_CategoryOptions = [
    exports.Category_ClinicalNotes,
];
exports.FHIR_Binary_CategoryOptions_ToLabelsMap = {
    [exports.Category_ClinicalNotes]: 'Clinical Notes',
};
