"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_UpToDateEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const styles_1 = require("@app/styles");
const q_components_1 = require("../../../q-components");
const _entity_1 = require("../../../../_entity");
class Dialog_UpToDateEditor extends frontend_1.TS_Dialog {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        // ######################### Logic #########################
        this.linkIsUpToDate = (link) => {
            return link.toLowerCase().includes('uptodate.com');
        };
        this.handleSave = async () => {
            await (0, frontend_1.performAction)(async () => {
                this.setState({ dialogIsBusy: true });
                try {
                    const ref = this.props.reference || {
                        type: 'upToDate',
                    };
                    ref.data = this.state.data;
                    const newRef = await _entity_1.ModuleFE_Reference.v1.upsert(ref).executeSync();
                    this.props.onRefUpsertCallback(newRef, newRef);
                    frontend_1.ModuleFE_Dialog.close();
                }
                catch (e) {
                    this.logError(e);
                }
                finally {
                    this.setState({ dialogIsBusy: false });
                }
            }, { type: 'notification', notificationLabels: 'Saving UpToDate Reference' });
        };
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'header__title' },
                    this.props.reference ? 'Edit' : 'Add',
                    " Up To Date"),
                React.createElement("div", { className: 'header__buttons' },
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => frontend_1.ModuleFE_Dialog.close() })));
        };
        this.renderBody = () => {
            return React.createElement(React.Fragment, null,
                this.renderLinkInput(),
                this.renderName(),
                this.renderDate());
        };
        this.buttons = () => {
            return {
                right: [
                    {
                        content: 'Cancel',
                        onClick: () => frontend_1.ModuleFE_Dialog.close(),
                    },
                    {
                        content: 'Save',
                        className: (0, frontend_1._className)(this.canBeSaved() ? 'desired-action-button' : ''),
                        disabled: !this.canBeSaved() || this.state.dialogIsBusy,
                        onClick: this.handleSave,
                        renderer: frontend_1.TS_Dialog.busyButton,
                    }
                ]
            };
        };
    }
    static show(onRefUpsertCallback, reference) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_UpToDateEditor, { onRefUpsertCallback: onRefUpsertCallback, reference: reference }), () => true);
    }
    // ######################### Life Cycle #########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        state.data = ((_a = this.state) === null || _a === void 0 ? void 0 : _a.data) || ((_b = this.props.reference) === null || _b === void 0 ? void 0 : _b.data) || {
            name: '',
            lastUpdate: Date.now(),
            url: '',
        };
        return state;
    }
    canBeSaved() {
        return this.linkIsUpToDate(this.state.data.url);
    }
    // ######################### Render #########################
    renderLinkInput() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
            React.createElement("span", { className: 'dialog__field-title' }, "Link"),
            React.createElement(frontend_1.TS_Input, { type: 'text', placeholder: 'Enter Link', value: this.state.data.url, tabIndex: 0, onKeyDown: (e) => {
                    if (!((e.ctrlKey || e.metaKey) && e.key === 'v')) {
                        (0, frontend_1.stopPropagation)(e);
                    }
                    if (e.key === 'Backspace') {
                        this.state.data.url = '';
                        this.forceUpdate();
                    }
                }, onChange: link => {
                    if (!this.linkIsUpToDate(link)) {
                        new frontend_1.ToastBuilder().setDuration(6 * ts_common_1.Second)
                            .setContent((0, q_components_1.QToastContent)({ iconKey: 'x', content: 'Link is not an UpToDate link', toastType: 'error' }))
                            .show();
                        this.forceUpdate();
                    }
                    else {
                        const cleanLink = link.split('?')[0];
                        const split = cleanLink.split('/');
                        this.state.data.url = cleanLink;
                        this.state.data.name = split[split.length - 1];
                        this.forceUpdate();
                    }
                } }));
    }
    renderName() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
            React.createElement("span", { className: 'dialog__field-title' }, "Name"),
            React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.data.name, onChange: (value) => {
                    this.state.data.name = value;
                    this.forceUpdate();
                } }));
    }
    renderDate() {
        return React.createElement(frontend_1.LL_V_L, null,
            React.createElement("span", { className: 'dialog__field-title' }, "Last Updated"),
            React.createElement(q_components_1.QDatePicker, { selected: new Date(this.state.data.lastUpdate), onChange: (value) => {
                    this.state.data.lastUpdate = value;
                    this.forceUpdate();
                } }));
    }
}
Dialog_UpToDateEditor.defaultProps = {
    dialogId: 'dialog-up-to-date-editor',
};
exports.Dialog_UpToDateEditor = Dialog_UpToDateEditor;
