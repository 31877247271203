"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCleanMetaPathwayAction = exports.isMetaPathwayAction_WithoutProtocol = exports.isMetaPathwayAction_WithProtocol = void 0;
const types_1 = require("./types");
const ts_common_1 = require("@nu-art/ts-common");
const isMetaPathwayAction_WithProtocol = (instance) => {
    return [...types_1.MetaPathwayAction_Types_WithProtocols].includes(instance === null || instance === void 0 ? void 0 : instance.type);
};
exports.isMetaPathwayAction_WithProtocol = isMetaPathwayAction_WithProtocol;
const isMetaPathwayAction_WithoutProtocol = (instance) => {
    return [...types_1.MetaPathwayAction_Types_WithoutProtocols].includes(instance === null || instance === void 0 ? void 0 : instance.type);
};
exports.isMetaPathwayAction_WithoutProtocol = isMetaPathwayAction_WithoutProtocol;
const getCleanMetaPathwayAction = (type) => {
    if ([...types_1.MetaPathwayAction_Types_WithProtocols].includes(type))
        return {
            type: type,
            protocolIds: [],
        };
    if ([...types_1.MetaPathwayAction_Types_WithoutProtocols].includes(type))
        return {
            type: type,
        };
    throw new ts_common_1.ImplementationMissingException(`No implementation for MetaPathwayAction type ${type}`);
};
exports.getCleanMetaPathwayAction = getCleanMetaPathwayAction;
