"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QExpandingSearch = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const styles_1 = require("@app/styles");
require("./QExpandingSearch.scss");
class QExpandingSearch extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.onAccept = () => {
            var _a, _b;
            (_b = (_a = this.props).onSearchAccept) === null || _b === void 0 ? void 0 : _b.call(_a, this.state.searchText);
        };
        this.onChange = (searchText) => {
            if (this.props.onSearchChange)
                this.props.onSearchChange(searchText);
            else
                this.setState({ searchText });
        };
        this.onBlur = (e) => {
            var _a, _b;
            const container = this.state.containerRef.current;
            if (!container)
                return;
            if (!!e.relatedTarget && container.contains(e.relatedTarget))
                return;
            (_b = (_a = this.props).onBlur) === null || _b === void 0 ? void 0 : _b.call(_a);
        };
        this.renderClearButton = () => {
            if (!this.props.onClear || !this.state.searchText || this.state.searchText.length < 2)
                return '';
            return React.createElement(styles_1.ICONS.clear.component, { className: 'clear', onClick: this.props.onClear });
        };
    }
    deriveStateFromProps(nextProps) {
        var _a, _b, _c, _d;
        const state = this.state ? Object.assign({}, this.state) : {};
        //If props gave different search text
        if (((_a = this.props) === null || _a === void 0 ? void 0 : _a.searchText) !== nextProps.searchText) {
            state.searchText = nextProps.searchText;
        }
        else {
            state.searchText = (_c = (_b = nextProps.searchText) !== null && _b !== void 0 ? _b : state.searchText) !== null && _c !== void 0 ? _c : '';
        }
        state.expanded = (_d = nextProps.expanded) !== null && _d !== void 0 ? _d : false;
        state.containerRef || (state.containerRef = React.createRef());
        return state;
    }
    componentDidMount() {
        var _a, _b;
        // @ts-ignore - focus the input
        (_b = (_a = this.state.containerRef.current) === null || _a === void 0 ? void 0 : _a.children[1]) === null || _b === void 0 ? void 0 : _b.focus();
    }
    render() {
        const className = (0, frontend_1._className)('q-expanding-search', this.state.expanded ? 'expanded' : undefined);
        return React.createElement("div", { className: className, onBlurCapture: this.onBlur, tabIndex: 0, ref: this.state.containerRef },
            React.createElement(styles_1.ICONS.advisorSearch.component, { onClick: () => { var _a, _b; return (_b = (_a = this.props).onExpandToggle) === null || _b === void 0 ? void 0 : _b.call(_a); }, className: 'search' }),
            React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.searchText, onAccept: this.onAccept, onChange: this.onChange }),
            this.renderClearButton(),
            React.createElement(styles_1.ICONS.v.component, { onClick: this.onAccept, className: 'accept' }));
    }
}
exports.QExpandingSearch = QExpandingSearch;
