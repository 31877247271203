"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_DiseaseProfileComparator = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Dialog_DiseaseProfileComparator.scss");
const PgDev_DPReport_1 = require("./PgDev_DPReport");
const styles_1 = require("@app/styles");
class Dialog_DiseaseProfileComparator extends frontend_1.TS_Dialog {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        // ######################## Render ########################
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("span", { className: "header__title" }, "Disease Profile Comparator"),
                React.createElement("div", { className: 'header__buttons' },
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => this.closeDialog() })));
        };
        this.renderBody = () => {
            return React.createElement(PgDev_DPReport_1.PgDev_DPReport.renderer, { diseaseProfiles: this.props.diseaseProfiles });
        };
        this.buttons = () => {
            return {
                right: [
                    { content: 'Close', onClick: () => this.closeDialog() },
                    {
                        content: 'Print',
                        onClick: () => {
                            const div = document.getElementById('dp-report');
                            frontend_1.ModuleFE_Thunderstorm.printDiv(div, [{ key: 'theme', value: 'light' }]);
                        }
                    }
                ],
            };
        };
    }
    static show(diseaseProfiles) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_DiseaseProfileComparator, { diseaseProfiles: diseaseProfiles }), () => false);
    }
}
Dialog_DiseaseProfileComparator.defaultProps = {
    dialogId: 'disease-profile-comparator',
};
exports.Dialog_DiseaseProfileComparator = Dialog_DiseaseProfileComparator;
