"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeDateByFormat = exports.QDatePicker = void 0;
const React = require("react");
const react_datepicker_1 = require("react-datepicker");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("react-datepicker/dist/react-datepicker.css");
const Format_DateByDayPattern = 'DD/MM/YYYY';
class QDatePicker extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps) {
        return undefined;
    }
    render() {
        return React.createElement(react_datepicker_1.default, { selected: this.props.selected, dateFormat: "dd/MM/yyyy", onChange: (date) => {
                const timestampString = (0, ts_common_1.formatTimestamp)(Format_DateByDayPattern, date.getTime());
                this.props.onChange((0, ts_common_1.parseTimeString)(timestampString, Format_DateByDayPattern));
                this.forceUpdate();
            } });
    }
}
exports.QDatePicker = QDatePicker;
const normalizeDateByFormat = (timeAsMillis, pattern = Format_DateByDayPattern) => {
    return (0, ts_common_1.parseTimeString)((0, ts_common_1.formatTimestamp)(pattern, timeAsMillis), pattern);
};
exports.normalizeDateByFormat = normalizeDateByFormat;
