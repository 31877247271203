"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip_References = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Tooltip_References.scss");
class Tooltip_References extends React.Component {
    constructor() {
        super(...arguments);
        this.resolveSnomedLink = (snomedId) => {
            const baseUrl = 'https://snomedbrowser.com/Codes/Details/';
            return baseUrl + snomedId;
        };
        this.renderSnomedLink = (ref) => {
            return React.createElement("a", { href: this.resolveSnomedLink(ref.data.id), target: '_blank', className: 'ref-link snomed' }, ref.data.id);
        };
    }
    render() {
        const refs = this.props.refs;
        if (!(0, ts_common_1.filterInstances)(refs).length)
            return '';
        const failedIndexAlert = () => {
            return React.createElement(frontend_1.LL_H_C, { className: 'ref-warning' },
                React.createElement("span", { className: 'warning-icon' }, "!"),
                React.createElement("div", { className: 'warning-message' }, "Failed to index this ID"),
                React.createElement("span", { className: 'warning-icon' }, "!"));
        };
        const tooltipContent = () => (React.createElement(frontend_1.LL_V_L, { className: 'refs-tooltip__content' }, refs.map(ref => {
            var _a, _b, _c, _d, _e;
            const type = ref === null || ref === void 0 ? void 0 : ref.type;
            if (type !== 'snomed' && type !== 'loinc')
                return '';
            switch (type) {
                case 'snomed': {
                    const _ref = ref;
                    return React.createElement(React.Fragment, { key: _ref._id },
                        ref.creationStatus === 'failed' && failedIndexAlert(),
                        React.createElement(frontend_1.LL_H_C, { className: 'ref-id' },
                            React.createElement("div", null,
                                React.createElement("b", null, "Snomed ID:")),
                            this.renderSnomedLink(_ref)),
                        ((_b = (_a = _ref.data.names) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.length) && React.createElement(frontend_1.LL_H_C, { style: { alignItems: 'flex-start' }, className: 'ref-name' },
                            React.createElement("div", null,
                                React.createElement("b", null, "Snomed Name:")),
                            React.createElement("div", null, `${(_c = _ref.data.names) === null || _c === void 0 ? void 0 : _c[0]}`)));
                }
                case 'loinc': {
                    const _ref = ref;
                    return React.createElement(React.Fragment, { key: _ref._id },
                        React.createElement(frontend_1.LL_H_C, { className: 'ref-id' },
                            React.createElement("div", null,
                                React.createElement("b", null, "Loinc ID:")),
                            React.createElement("div", null, _ref.data.id)));
                }
            }
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.LL_H_C, { className: 'ref-id' },
                    React.createElement("div", null,
                        React.createElement("b", null, `${type === 'snomed' ? 'Snomed' : 'Loinc'} ID:`)),
                    React.createElement("div", null, ref.data.id)),
                React.createElement(frontend_1.LL_H_C, { style: { alignItems: 'flex-start' }, className: 'ref-name' },
                    React.createElement("div", null,
                        React.createElement("b", null, "Snomed Name:")),
                    React.createElement("div", null, ((_d = ref.data.names) === null || _d === void 0 ? void 0 : _d[0].length) && `${(_e = ref.data.names) === null || _e === void 0 ? void 0 : _e[0]}`)));
        })));
        const tooltip = frontend_1.openContent.tooltip.right('ref-tool-tip', tooltipContent, { contentHoverDelay: 500 });
        return React.createElement("div", Object.assign({ className: (0, frontend_1._className)('refs-tooltip', refs.some(ref => ref.creationStatus === 'failed') ? 'error' : '') }, tooltip), "R");
    }
}
exports.Tooltip_References = Tooltip_References;
