"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderTypeMap = exports.orderTypes = exports.OrderType_ProviderInputNeeded = exports.OrderType_EDConsult = exports.OrderType_EKG = exports.OrderType_Imaging = exports.OrderType_Labs = exports.resolutionTypes = exports.OrderResolutionType_Parallel = exports.OrderResolutionType_Automatic = exports.OrderResolutionType_Manual = exports.OrderLifecycleStates = exports.OrderLifecycle_Revoked = exports.OrderLifecycle_Deleted = exports.OrderLifecycle_Seen = exports.OrderLifecycle_Resulted = exports.OrderLifecycle_ProviderInputNeeded = exports.OrderLifecycle_InProcess = exports.OrderLifecycle_Collected = exports.OrderLifecycle_Pending = exports.OrderLifecycle_PendingSignature = exports.OrderLabels = exports.OrderLabel_Canceled = exports.OrderLabel_Resulted = exports.OrderLabel_ProviderInputNeeded = exports.OrderLabel_InProcess = exports.OrderLabel__Collected = exports.OrderLabel_Pending = exports.OrderLabel_PendingSignature = exports.OrderStatuses = exports.OrderStatus_Revoked = exports.OrderStatus_Deleted = exports.OrderStatus_Seen = exports.OrderStatus_Resulted = exports.OrderStatus_ProviderInputNeeded = exports.OrderStatus_InProcess = exports.OrderStatus_Collected = exports.OrderStatus_Pending = exports.OrderStatus_PendingSignature = exports.actionSubTypeLabelMap = exports.actionTypes = exports.PermissionKey_ActionEdit = exports.PermissionKey_ActionView = exports.DomainNamespace_Actions = void 0;
const types_1 = require("./types");
exports.DomainNamespace_Actions = 'Action';
exports.PermissionKey_ActionView = 'permission-key--action-view';
exports.PermissionKey_ActionEdit = 'permission-key--action-edit';
exports.actionTypes = [types_1.ActionType_Order, types_1.ActionType_Interflow];
exports.actionSubTypeLabelMap = {
    'activate-protocol': 'Activate Protocol',
    admit: 'Admit',
    'consider-protocol': 'Consider Protocol',
    consult: 'Consult',
    discharge: 'Discharge',
    'h&p': 'H&P',
    poc: 'POC',
    restart: 'Restart',
    test: 'Test',
    treatment: 'Treatment',
};
exports.OrderStatus_PendingSignature = 'pending-signature';
exports.OrderStatus_Pending = 'pending';
exports.OrderStatus_Collected = 'collected';
exports.OrderStatus_InProcess = 'in-process';
exports.OrderStatus_ProviderInputNeeded = 'provider-input-needed';
exports.OrderStatus_Resulted = 'resulted';
exports.OrderStatus_Seen = 'seen';
exports.OrderStatus_Deleted = 'deleted';
exports.OrderStatus_Revoked = 'revoked';
exports.OrderStatuses = [
    exports.OrderStatus_PendingSignature,
    exports.OrderStatus_Pending,
    exports.OrderStatus_Collected,
    exports.OrderStatus_InProcess,
    exports.OrderStatus_ProviderInputNeeded,
    exports.OrderStatus_Resulted,
    exports.OrderStatus_Seen,
    exports.OrderStatus_Deleted,
    exports.OrderStatus_Revoked,
];
exports.OrderLabel_PendingSignature = 'Pending Signature';
exports.OrderLabel_Pending = 'Pending';
exports.OrderLabel__Collected = 'Collected';
exports.OrderLabel_InProcess = 'In Process';
exports.OrderLabel_ProviderInputNeeded = 'Provider Input Needed';
exports.OrderLabel_Resulted = 'Resulted';
exports.OrderLabel_Canceled = 'Canceled';
exports.OrderLabels = [
    exports.OrderLabel_PendingSignature,
    exports.OrderLabel_Pending,
    exports.OrderLabel__Collected,
    exports.OrderLabel_InProcess,
    exports.OrderLabel_ProviderInputNeeded,
    exports.OrderLabel_Resulted,
    exports.OrderLabel_Canceled
];
exports.OrderLifecycle_PendingSignature = {
    status: exports.OrderStatus_PendingSignature,
    label: exports.OrderLabel_PendingSignature
};
exports.OrderLifecycle_Pending = { status: exports.OrderStatus_Pending, label: exports.OrderLabel_Pending };
exports.OrderLifecycle_Collected = { status: exports.OrderStatus_Collected, label: exports.OrderLabel__Collected };
exports.OrderLifecycle_InProcess = { status: exports.OrderStatus_InProcess, label: exports.OrderLabel_InProcess };
exports.OrderLifecycle_ProviderInputNeeded = {
    status: exports.OrderStatus_ProviderInputNeeded,
    label: exports.OrderLabel_ProviderInputNeeded
};
exports.OrderLifecycle_Resulted = { status: exports.OrderStatus_Resulted, label: exports.OrderLabel_Resulted };
exports.OrderLifecycle_Seen = { status: exports.OrderStatus_Seen, label: exports.OrderLabel_Resulted };
exports.OrderLifecycle_Deleted = { status: exports.OrderStatus_Deleted, label: exports.OrderLabel_Canceled };
exports.OrderLifecycle_Revoked = { status: exports.OrderStatus_Revoked, label: exports.OrderLabel_Canceled };
exports.OrderLifecycleStates = {
    statuses: [
        exports.OrderLifecycle_PendingSignature,
        exports.OrderLifecycle_Pending,
        exports.OrderLifecycle_Collected,
        exports.OrderLifecycle_InProcess,
        exports.OrderLifecycle_ProviderInputNeeded,
        exports.OrderLifecycle_Resulted,
        exports.OrderLifecycle_Seen,
        exports.OrderLifecycle_Deleted,
        exports.OrderLifecycle_Revoked,
    ],
    indexOf: (status, limitedList) => (limitedList !== null && limitedList !== void 0 ? limitedList : exports.OrderLifecycleStates.statuses).findIndex(orderLifecycle => orderLifecycle.status === status)
};
exports.OrderResolutionType_Manual = 'manual';
exports.OrderResolutionType_Automatic = 'automatic';
exports.OrderResolutionType_Parallel = 'parallel';
exports.resolutionTypes = [exports.OrderResolutionType_Manual, exports.OrderResolutionType_Automatic, exports.OrderResolutionType_Parallel];
exports.OrderType_Labs = 'labs';
exports.OrderType_Imaging = 'imaging';
exports.OrderType_EKG = 'ekg';
exports.OrderType_EDConsult = 'ed-consult';
exports.OrderType_ProviderInputNeeded = 'provider-input-needed';
exports.orderTypes = [exports.OrderType_Labs, exports.OrderType_Imaging, exports.OrderType_EKG, exports.OrderType_EDConsult, exports.OrderType_ProviderInputNeeded];
exports.OrderTypeMap = {
    [exports.OrderType_Labs]: [
        exports.OrderLifecycle_PendingSignature,
        exports.OrderLifecycle_Pending,
        exports.OrderLifecycle_Collected,
        exports.OrderLifecycle_InProcess,
        exports.OrderLifecycle_Resulted,
        exports.OrderLifecycle_Seen
    ],
    [exports.OrderType_Imaging]: [
        exports.OrderLifecycle_PendingSignature,
        exports.OrderLifecycle_Pending,
        exports.OrderLifecycle_InProcess,
        exports.OrderLifecycle_Resulted,
        exports.OrderLifecycle_Seen,
    ],
    [exports.OrderType_EKG]: [
        exports.OrderLifecycle_PendingSignature,
        exports.OrderLifecycle_Pending,
        exports.OrderLifecycle_InProcess,
        exports.OrderLifecycle_ProviderInputNeeded,
        exports.OrderLifecycle_Seen,
    ],
    [exports.OrderType_EDConsult]: [
        exports.OrderLifecycle_Pending,
        exports.OrderLifecycle_ProviderInputNeeded,
        exports.OrderLifecycle_Seen,
    ],
    [exports.OrderType_ProviderInputNeeded]: [
        exports.OrderLifecycle_ProviderInputNeeded,
        exports.OrderLifecycle_Seen,
    ],
};
