"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_EpidemiologyEditor = void 0;
const React = __importStar(require("react"));
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
require("./Component_EpidemiologyEditor.scss");
const frontend_2 = require("@app/hcs/frontend");
const styles_1 = require("@app/styles");
const _enum_1 = require("../../../_enum");
const Component_EpidemiologyItemEditor_1 = require("./Component_EpidemiologyItemEditor");
const ts_common_1 = require("@nu-art/ts-common");
const ViewMode_View = 'view';
const ViewMode_Edit = 'edit';
class Component_EpidemiologyEditor extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        // ######################## Life Cycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.getViewMode = () => this.state.editMode ? ViewMode_Edit : ViewMode_View;
        this.addEpidemiology = async () => {
            const dp = this.state.editable;
            const epidemiology = dp.editProp('epidemiology', []);
            await epidemiology.updateArrayAt((0, ts_common_1.cloneObj)(_enum_1.initialEpidemiology));
        };
        this.renderEpidemiology = () => {
            const epidemiology = this.state.editable.editProp('epidemiology', []);
            const editMode = this.state.editMode;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Epidemiology' },
                editMode && React.createElement(frontend_1.TS_Button, { onClick: this.addEpidemiology },
                    React.createElement(styles_1.ICONS.advisorAddPlus.component, null),
                    " Add Epidemiology"),
                React.createElement(frontend_2.QScrollWrapper, null, epidemiology.item.map((_item, index) => {
                    const item = epidemiology.editProp(index, {});
                    const className = (0, frontend_1._className)('epidemiology-card', editMode && 'edit-mode');
                    return React.createElement("div", { className: className },
                        editMode && React.createElement(styles_1.ICONS.bin.component, { className: 'epidemiology-card__delete', onClick: async () => {
                                await epidemiology.removeArrayItem(index);
                            } }),
                        React.createElement(Component_EpidemiologyItemEditor_1.Component_EpidemiologyItemEditor, { editable: item, editMode: this.state.editMode, key: index }));
                })));
        };
        this.renderEpidemiologyNotes = () => {
            const editable = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Epidemiology Notes', error: editable.hasError('epidemiologyNotes') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => React.createElement("p", null, editable.item.epidemiologyNotes)
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(ui_components_1.DefaultEditor_TextArea, { editable: editable, prop: 'epidemiologyNotes' })
                        }
                    ] }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'epidemiology-editor' },
            this.renderEpidemiology(),
            this.renderEpidemiologyNotes());
    }
}
exports.Component_EpidemiologyEditor = Component_EpidemiologyEditor;
