"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dropdown_MetaPathwayAction_Type = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("./shared");
const consts_1 = require("@app/styles/frontend/ui/consts");
const MetaPathwayAction_Type_MandatoryProps = {
    adapter: () => (0, frontend_1.SimpleListAdapter)([...shared_1.MetaPathwayAction_Types], item => React.createElement(React.Fragment, null, shared_1.metaPathwayAction_Type_LabelMap[item.item])),
    caret: consts_1.DropDown_DefaultCaret,
    placeholder: 'Pick A Type'
};
exports.Dropdown_MetaPathwayAction_Type = frontend_1.TS_DropDown.prepare(MetaPathwayAction_Type_MandatoryProps);
