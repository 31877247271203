"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogTemplateExternalParams = exports.LogTemplatesTypes = exports.LogTemplateTypesKeys = exports.LogTemplateType_String = exports.LogTemplateType_NameOf = exports.LogTemplateType_UnitsOf = exports.LogTemplateType_Timestamp = exports.LogTemplateType_TimeOf = exports.LogTemplateType_ValueOf = exports.ParamTypeValue_String = exports.ParamTypeValue_VarId = void 0;
exports.ParamTypeValue_VarId = 'variableId';
exports.ParamTypeValue_String = 'string';
exports.LogTemplateType_ValueOf = { dbKey: 'value-of', type: exports.ParamTypeValue_VarId };
exports.LogTemplateType_TimeOf = { dbKey: 'time-of', type: exports.ParamTypeValue_VarId };
exports.LogTemplateType_Timestamp = { dbKey: 'timestamp', type: undefined };
exports.LogTemplateType_UnitsOf = { dbKey: 'units-of', type: exports.ParamTypeValue_VarId };
exports.LogTemplateType_NameOf = { dbKey: 'name-of', type: exports.ParamTypeValue_VarId };
exports.LogTemplateType_String = { dbKey: 'string', type: exports.ParamTypeValue_String };
exports.LogTemplateTypesKeys = [
    exports.LogTemplateType_ValueOf.dbKey,
    exports.LogTemplateType_TimeOf.dbKey,
    exports.LogTemplateType_UnitsOf.dbKey,
    exports.LogTemplateType_NameOf.dbKey,
    exports.LogTemplateType_Timestamp.dbKey,
    exports.LogTemplateType_String.dbKey,
];
exports.LogTemplatesTypes = [
    exports.LogTemplateType_ValueOf,
    exports.LogTemplateType_TimeOf,
    exports.LogTemplateType_UnitsOf,
    exports.LogTemplateType_NameOf,
    exports.LogTemplateType_Timestamp,
    exports.LogTemplateType_String,
];
exports.LogTemplateExternalParams = [
    exports.ParamTypeValue_VarId,
    exports.ParamTypeValue_String
];
