"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_TimeRangeInput = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const utility_1 = require("../../../../../../../../_entity/value/shared/utility");
const ts_common_1 = require("@nu-art/ts-common");
const ui_components_1 = require("../../../../../../../../_enum/timespan/frontend/ui-components");
require("./Component_TimeRangeInput.scss");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const consts_1 = require("../../../../../../../../_enum/timespan/shared/consts");
class Component_TimeRangeInput extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        this.updateValue = (value) => {
            var _a;
            let _value = (_a = value !== null && value !== void 0 ? value : this.state.inputValue) !== null && _a !== void 0 ? _a : 0;
            if (!_value) {
                const newArray = [...this.state.editable.item];
                newArray[this.props.index] = undefined;
                this.state.editable.updateItem(newArray);
                //  FIXME: save me from this hackkkkk
                return this.state.editable.save();
            }
            _value = Number(_value);
            return this.state.editable.updateArrayAt(_value * consts_1.TimespanToValue[this.state.multiplier], this.props.index);
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        // Set the correct multiplier according to the value received from the editable item
        const value = state.editable.get(nextProps.index);
        const multiplier = (0, utility_1.inferMultiplier)(value);
        state.multiplier = multiplier;
        // Make sure to initiate input value according to the multiplier
        state.inputValue = (0, ts_common_1.exists)(value) ? String(value / (consts_1.TimespanToValue[multiplier])) : undefined;
        return state;
    }
    render() {
        var _a;
        return React.createElement(frontend_1.LL_H_C, { className: (0, frontend_1._className)('time-range-input', this.props.className) },
            React.createElement(frontend_1.TS_Input, { placeholder: (_a = this.props.placeholder) !== null && _a !== void 0 ? _a : 'Time', type: 'number', onBlur: (value) => {
                    this.setState({ inputValue: value }, () => this.updateValue(value));
                }, value: this.state.inputValue }),
            React.createElement(ui_components_1.Dropdown_Timespans.selectable, { onSelected: value => this.setState({ multiplier: value }, () => this.updateValue(this.state.inputValue)), selected: this.state.multiplier }));
    }
}
exports.Component_TimeRangeInput = Component_TimeRangeInput;
