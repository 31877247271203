"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ManagerSubHeader = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_ManagerSubHeader.scss");
class Component_ManagerSubHeader extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        return { isInEditMode: nextProps.isInEditMode, buttons: nextProps.buttons };
    }
    render() {
        return React.createElement(frontend_1.LL_H_C, { className: 'manager-subheader match_width' }, this.state.buttons);
    }
}
exports.Component_ManagerSubHeader = Component_ManagerSubHeader;
