"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteConfirmation_Body = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
require("./DeleteConfirmation_Body.scss");
const Discussion_Renderer_1 = require("../Discussion_Renderer");
const _entity_1 = require("../../../../_entity");
const frontend_2 = require("@nu-art/user-account/frontend");
const frontend_3 = require("@nu-art/ts-messaging/_entity/message/frontend");
const modulesToAwait = [_entity_1.ModuleFE_Discussion, _entity_1.ModuleFE_User, frontend_2.ModuleFE_Account, frontend_3.ModuleFE_Message];
class DeleteConfirmation_Body extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        return state;
    }
    render() {
        return React.createElement(frontend_1.AwaitModules, { modules: modulesToAwait },
            React.createElement("div", { className: 'dialogue-body' },
                React.createElement(Discussion_Renderer_1.Discussion_Renderer, { discussion: this.props.discussion, disabled: true })));
    }
}
exports.DeleteConfirmation_Body = DeleteConfirmation_Body;
