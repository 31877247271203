"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_Variable = exports.DropDown_VariableV3 = exports.DropDown_VariableV3WithSynonyms = exports.Props_VariableDropDownV3WithSynonyms = exports.Props_VariableDropDownV3 = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_Variable_1 = require("./ModuleFE_Variable");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const frontend_2 = require("../../../frontend");
const consts_1 = require("@app/styles/frontend/ui/consts");
exports.Props_VariableDropDownV3 = {
    module: ModuleFE_Variable_1.ModuleFE_Variable,
    modules: [ModuleFE_Variable_1.ModuleFE_Variable],
    mapper: item => new frontend_2.VariableWrapper(item).getSearchableText(),
    placeholder: 'Choose a Variable',
    renderer: item => React.createElement(React.Fragment, null, item.name),
    caret: consts_1.DropDown_DefaultCaret,
};
exports.Props_VariableDropDownV3WithSynonyms = Object.assign(Object.assign({}, exports.Props_VariableDropDownV3), { selectedItemRenderer: (item) => {
        var _a;
        return React.createElement("div", { style: { color: !item ? 'red' : 'inherit' }, className: 'ts-dropdown__selected' }, (_a = item === null || item === void 0 ? void 0 : item.name) !== null && _a !== void 0 ? _a : 'Entity Deleted');
    }, renderer: item => {
        var _a, _b;
        return React.createElement(React.Fragment, null,
            item.name,
            ((_a = new frontend_2.VariableWrapper(item).getNlpData()) === null || _a === void 0 ? void 0 : _a.synonyms.length) ? React.createElement("div", { style: { fontSize: 12 } },
                "(", (_b = new frontend_2.VariableWrapper(item).getNlpData()) === null || _b === void 0 ? void 0 :
                _b.synonyms.join(', '),
                ")") : null);
    } });
exports.DropDown_VariableV3WithSynonyms = frontend_1.GenericDropDownV3.prepare(exports.Props_VariableDropDownV3WithSynonyms);
exports.DropDown_VariableV3 = frontend_1.GenericDropDownV3.prepare(exports.Props_VariableDropDownV3);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Variable_1.ModuleFE_Variable,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.name);
    },
    uiSelector: exports.DropDown_VariableV3.selectable,
});
exports.MultiSelect_Variable = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
