"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_BookEditor = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../../_entity");
class Dialog_BookEditor extends frontend_1.TS_Dialog {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        // ######################### LifeCycle #########################
        this.__onReferencesUpdated = async (...params) => {
            const originRef = this.props.reference;
            switch (params[0]) {
                case frontend_1.EventType_Create:
                case frontend_1.EventType_Update:
                    await this.props.onRefUpsertCallback(params[1], originRef);
                    frontend_1.ModuleFE_Dialog.close();
                    break;
            }
        };
        // ######################### Logic #########################
        this.canBeSaved = () => {
            return true;
        };
        this.handleSave = async () => {
            await (0, frontend_1.performAction)(async () => {
                this.setState({ dialogIsBusy: true });
                try {
                    const path = this.state.selected.slice(1, this.state.selected.length - 1);
                    const data = {
                        bookId: this.state.rootStruct.items[this.state.selected[0]]._id,
                        path: path,
                        paragraph: this.state.paragraph || '',
                        page: this.state.page || -1,
                    };
                    const ref = this.props.reference || {
                        type: 'book',
                        uid: '',
                    };
                    ref.data = data;
                    _entity_1.ModuleFE_Reference.v1.upsert(ref).execute();
                }
                catch (e) {
                    this.logError(e);
                }
                finally {
                    this.setState({ dialogIsBusy: false });
                }
            }, { type: 'notification', notificationLabels: 'Saving Book Reference' });
        };
        this.renderHeader = () => {
            var _a;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'header__title' },
                    ((_a = this.props.reference) === null || _a === void 0 ? void 0 : _a._id) ? 'Edit' : 'Add',
                    " Reference"),
                React.createElement("div", { className: 'header__buttons' },
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => frontend_1.ModuleFE_Dialog.close() })));
        };
        this.renderBody = () => {
            var _a;
            const rootStruct = this.state.rootStruct;
            const reduce = (_a = this.state.selected) === null || _a === void 0 ? void 0 : _a.reduce((toRet, selectedIndex, pathIndex) => {
                var _a;
                if (!((_a = toRet.struct) === null || _a === void 0 ? void 0 : _a.items))
                    return toRet;
                const parentStruct = toRet.struct;
                toRet.struct = toRet.struct.items[selectedIndex];
                toRet.renderer.push(React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section', key: pathIndex }, this.renderSection(pathIndex, parentStruct, toRet.struct)));
                return toRet;
            }, { struct: rootStruct, renderer: [] });
            return React.createElement(React.Fragment, null, reduce === null || reduce === void 0 ? void 0 :
                reduce.renderer,
                React.createElement(frontend_1.LL_H_C, { className: 'lower-inputs' },
                    this.renderPageInput(),
                    this.renderParagraphInput()));
        };
        this.buttons = () => {
            return {
                right: [
                    {
                        content: 'Cancel',
                        onClick: () => frontend_1.ModuleFE_Dialog.close(),
                    },
                    {
                        content: 'Save',
                        className: (0, frontend_1._className)(this.canBeSaved() ? 'desired-action-button' : ''),
                        disabled: !this.canBeSaved() || this.state.dialogIsBusy,
                        onClick: this.handleSave,
                        renderer: frontend_1.TS_Dialog.busyButton,
                    }
                ]
            };
        };
    }
    static show(onRefUpsertCallback, reference) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_BookEditor, { onRefUpsertCallback: onRefUpsertCallback, reference: reference }), () => false);
    }
    static copy(onRefUpsertCallback, reference) {
        const ref = Object.assign({}, reference);
        delete ref._id;
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_BookEditor, { onRefUpsertCallback: onRefUpsertCallback, reference: ref }), () => false);
    }
    deriveStateFromProps(nextProps) {
        var _a, _b, _c, _d;
        const state = this.state ? Object.assign({}, this.state) : {};
        const books = _entity_1.ModuleFE_Book.cache.all();
        if (!state.selected && nextProps.reference) {
            state.selected = [books.findIndex(book => book._id === nextProps.reference.data.bookId), ...nextProps.reference.data.path, -1];
        }
        else {
            state.selected = [-1];
        }
        (_a = state.page) !== null && _a !== void 0 ? _a : (state.page = ((_b = nextProps.reference) === null || _b === void 0 ? void 0 : _b.data.page) || 0);
        state.paragraph = ((_c = nextProps.reference) === null || _c === void 0 ? void 0 : _c.data.paragraph) || '';
        (_d = state.rootStruct) !== null && _d !== void 0 ? _d : (state.rootStruct = {
            name: 'Books',
            items: books,
            itemsType: 'book'
        });
        return state;
    }
    // ######################### Render #########################
    renderSection(i, structure, selected) {
        const items = structure.items;
        if (!structure.itemsType || !items)
            return '';
        const adapter = (0, frontend_1.SimpleListAdapter)(items, item => React.createElement("div", { className: 'node-data' }, item.item.name));
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { className: 'dialog__main__section-content', label: 'Select ' + structure.itemsType },
            React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: selected, onSelected: val => {
                    const newSelected = this.state.selected.slice(0, i);
                    const matchingVal = items.find(item => (0, ts_common_1.compare)(item, val));
                    newSelected.push(items.indexOf(matchingVal), -1);
                    this.setState({ selected: newSelected });
                } }));
    }
    renderPageInput() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Enter page', className: 'dialog__main__section' },
            React.createElement(frontend_1.TS_Input, { type: 'number', value: this.state.page ? `${this.state.page}` : '', onChange: val => {
                    this.setState({ page: Number(val) });
                } }));
    }
    renderParagraphInput() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Enter paragraph', className: 'dialog__main__section' },
            React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.paragraph || '', onChange: val => {
                    this.setState({ paragraph: val });
                } }));
    }
}
Dialog_BookEditor.defaultProps = {
    dialogId: 'dialog-book-editor',
};
exports.Dialog_BookEditor = Dialog_BookEditor;
