"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIfPathwayElementChanged = exports.basicSyncEventRefresh = exports.updateResolutionFunctionTitle = exports.getAllResolutionFunctionsVariables = exports.getTransitionVariables = exports.getStatePreconditionsVariables = exports.groupAndSortTransitions = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@app/hcs/frontend");
const utils_1 = require("../Component_ResolutionFunctionEditor/utils");
const consts_1 = require("./consts");
const frontend_2 = require("../../../_entity/action/frontend");
const frontend_3 = require("../../../_entity/pathway-transition/frontend");
const frontend_4 = require("../../../_entity/pathway-state/frontend");
/**
 * Group transition by their target id and sort them by creation date
 * @param transitions Array of DB_Transitions
 * @returns transitions mapper grouped by targetId and sorted by created
 */
const groupAndSortTransitions = (transitions) => {
    const targetIdMapper = {};
    transitions.forEach(transition => {
        if (!targetIdMapper[transition.targetIds[0]])
            targetIdMapper[transition.targetIds[0]] = [];
        targetIdMapper[transition.targetIds[0]].push(transition);
    });
    (0, ts_common_1._keys)(targetIdMapper).forEach(transitionGroup => (0, ts_common_1.sortArray)(targetIdMapper[transitionGroup], (item) => item.__created));
    return targetIdMapper;
};
exports.groupAndSortTransitions = groupAndSortTransitions;
/**
 * Resolve variable id from an action from type order
 * @param id the id of the action item
 */
const resolveOrderActionVarId = (id) => {
    const action = frontend_2.ModuleFE_Action.cache.unique(id);
    if (!action)
        throw new ts_common_1.MUSTNeverHappenException(`Could not find order for id ${id}`);
    if (!frontend_2.ModuleFE_Action.is.order(action))
        return;
    return action.variableId;
};
/**
 * Get all precondition variables ids
 * @returns a mapper mapping variable ids to their context
 */
const getStatePreconditionsVariables = (states) => {
    const preconditionIds = (0, ts_common_1.filterFlatInstances)(states.flatMap(state => state.preconditions.map(precon => precon.orderId)));
    const variableIds = (0, ts_common_1.filterInstances)(preconditionIds.map(preconditionId => resolveOrderActionVarId(preconditionId)));
    const variables = frontend_1.ModuleFE_Variable.cache.filter(variable => variableIds.includes(variable._id));
    return { [consts_1.PathwayVariableContext_StatePreconditions]: (0, ts_common_1.sortArray)(variables, variable => variable.name.toLowerCase()) };
};
exports.getStatePreconditionsVariables = getStatePreconditionsVariables;
/**
 * Generic function to resolve all variables from the relevant transition fields
 * @param transitions all of the transitions
 * @param key the key in the transition db entity
 */
const resolveTransitionActionVars = (transitions, key) => {
    const varIds = transitions.reduce((variableIds, transition) => {
        if (Array.isArray(transition[key])) {
            variableIds = [...variableIds, ...(0, ts_common_1.filterInstances)(transition[key].map(id => resolveOrderActionVarId(id)))];
        }
        return variableIds;
    }, []);
    const variables = frontend_1.ModuleFE_Variable.cache.filter(variable => varIds.includes(variable._id));
    return (0, ts_common_1.sortArray)(variables, (item) => item._nameInLowercase);
};
/**
 * Get all transitions variables
 * @returns a mapper mapping variable ids to their context
 */
const getTransitionVariables = (pathwayTransitions) => {
    return {
        [consts_1.PathwayVariableContext_TransitionTests]: resolveTransitionActionVars(pathwayTransitions, 'testsIds'),
        [consts_1.PathwayVariableContext_TransitionTreatments]: resolveTransitionActionVars(pathwayTransitions, 'treatmentsIds'),
        [consts_1.PathwayVariableContext_TransitionConsults]: resolveTransitionActionVars(pathwayTransitions, 'consultIds'),
        [consts_1.PathwayVariableContext_TransitionNurse]: resolveTransitionActionVars(pathwayTransitions, 'nurseIds')
    };
};
exports.getTransitionVariables = getTransitionVariables;
/**
 * Get all resolution functions variables
 * @returns a mapper mapping variable ids to their context
 */
const getAllResolutionFunctionsVariables = (resolutionFunctionsIds) => {
    const variableIds = (0, utils_1.resolveResolutionFunctionVariables)((0, ts_common_1.filterInstances)(resolutionFunctionsIds.map(id => frontend_1.ModuleFE_Expression.cache.unique(id))));
    const variables = frontend_1.ModuleFE_Variable.cache.filter(variable => variableIds.includes(variable._id));
    return {
        [consts_1.PathwayVariableContext_ResolutionFunctions]: (0, ts_common_1.sortArray)(variables, (item) => item._nameInLowercase)
    };
};
exports.getAllResolutionFunctionsVariables = getAllResolutionFunctionsVariables;
/**
 * Update resolution function post state/transition changes
 * @param transitionId The current transition id to update
 * @param statesIds The source and target state ids for this target
 */
const updateResolutionFunctionTitle = async (transitionId, statesIds) => {
    var _a;
    // resolve and assert relevant transition
    const transition = frontend_3.ModuleFE_PathwayTransition.cache.unique(transitionId);
    if (!transition)
        throw new ts_common_1.MUSTNeverHappenException(`pathway transition with id ${transitionId} not found`);
    // resolve and assert pathway states
    const states = statesIds.map(id => {
        const pathwayState = frontend_4.ModuleFE_PathwayState.cache.unique(id);
        if (!pathwayState)
            throw new ts_common_1.MUSTNeverHappenException('pathway state with id ${id} not found');
        return pathwayState;
    });
    // resolve and assert resolution function
    const resolutionFunction = (0, ts_common_1.deepClone)(frontend_1.ModuleFE_Expression.cache.unique(transition.resolutionFunctionId));
    if (!resolutionFunction)
        throw new ts_common_1.MUSTNeverHappenException(`could not find resolution function with id ${transition.resolutionFunctionId}`);
    // update label according the spec
    const labelToUpdate = `${states[0].name} => ${(_a = transition.label) !== null && _a !== void 0 ? _a : states[1].name}`;
    // make sure not to upsert stuff with no changes
    if (labelToUpdate === resolutionFunction.label.trim())
        return;
    // set the expression label
    resolutionFunction.label = labelToUpdate;
    // upsert the updated label
    await frontend_1.ModuleFE_Expression.v1.upsert(resolutionFunction).executeSync();
};
exports.updateResolutionFunctionTitle = updateResolutionFunctionTitle;
const basicSyncEventRefresh = (entityId, params, updateCallback, deleteCallback) => {
    switch (params[0]) {
        case 'delete':
        case 'delete-multi': {
            const deletedEntities = (0, ts_common_1.asArray)(params[1]).map(ts_common_1.dbObjectToId);
            if (deletedEntities.includes(entityId))
                return deleteCallback();
            break;
        }
        case 'update':
        case 'upsert-all':
        case 'create': {
            const updatedEntities = (0, ts_common_1.asArray)(params[1]).map(ts_common_1.dbObjectToId);
            if (updatedEntities.includes(entityId))
                return updateCallback();
            break;
        }
    }
};
exports.basicSyncEventRefresh = basicSyncEventRefresh;
const checkIfPathwayElementChanged = (previousSet, params, isNewCallBack, updateCallback, deleteCallback) => {
    switch (params[0]) {
        case 'delete':
        case 'delete-multi': {
            const deletedEntities = (0, ts_common_1.asArray)(params[1]);
            if (deletedEntities.some(deletedEntity => previousSet.some(entity => entity._id === deletedEntity._id)))
                return deleteCallback();
            break;
        }
        case 'update':
        case 'upsert-all':
        case 'create': {
            const updatedEntities = (0, ts_common_1.asArray)(params[1]);
            if (updatedEntities.some(updatedEntity => previousSet.some(entity => entity._id === updatedEntity._id) || isNewCallBack(updatedEntity)))
                return updateCallback();
            break;
        }
    }
};
exports.checkIfPathwayElementChanged = checkIfPathwayElementChanged;
