"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_HealthcareSpace = void 0;
const React = require("react");
require("./Component_HealthcareSpace.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@nu-art/ts-workspace/frontend");
const values_1 = require("../../values");
const variables_1 = require("../../variables");
const shared_components_1 = require("../../../shared-components");
const shared_dialogs_1 = require("../../../shared-dialogs");
const _entity_1 = require("../../../../_entity");
const tags_1 = require("../../tags");
const renderers = {
    horizontalSpace: frontend_2.TS_HorizontalWorkspace,
    categoriesPanel: tags_1.Panel_Tags,
    variablesPanel: variables_1.Panel_Variables,
    variableValuesPanel: values_1.Panel_Values,
};
const modulesToAwait = [
    frontend_1.ModuleFE_AppConfig,
    _entity_1.ModuleFE_Tag,
    _entity_1.ModuleFE_MacroTag,
    _entity_1.ModuleFE_Variable,
    _entity_1.ModuleFE_Value,
    _entity_1.ModuleFE_Expression
];
class Component_HealthcareSpace extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.workspaceKey = 'healthcare-space';
    }
    render() {
        return (React.createElement(frontend_1.TS_ErrorBoundary, { onClick: async (e) => {
                await frontend_2.ModuleFE_Workspace.deleteWorkspaceByKey(this.workspaceKey);
                this.forceUpdate();
            } },
            React.createElement(frontend_1.LL_V_L, { className: 'health-care-space' },
                React.createElement(shared_components_1.Component_SubHeader, { cleanWorkspace: () => {
                        shared_dialogs_1.WarningDialog.showDelete({
                            onConfirm: async () => {
                                await frontend_2.ModuleFE_Workspace.deleteWorkspaceByKey(this.workspaceKey);
                                location.reload();
                            },
                            warningMessages: ['Delete healthcare space workspace?'],
                            executeButtonText: 'Delete Workspace',
                        });
                    } },
                    React.createElement("div", { style: { flexGrow: 1 } })),
                React.createElement(frontend_2.TS_Workspace, { workspaceKey: this.workspaceKey, renderers: renderers }))));
    }
}
_a = Component_HealthcareSpace;
Component_HealthcareSpace.Route = {
    key: 'healthcare-space',
    path: 'healthcare-space',
    Component: _a,
    index: true,
    modulesToAwait: modulesToAwait
};
exports.Component_HealthcareSpace = Component_HealthcareSpace;
