"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_DocumentReference_TypeOptions_ToLabelsMap = exports.FHIR_DocumentReference_TypeOptions = exports.Type_RiskAssessmentAndScreeningNote = exports.Type_DischargeInstructions = exports.Type_NurseNote = exports.Type_EmergencyDepartmentNote = exports.Type_ProcedureNote = exports.Type_ProgressNote = exports.Type_HistoryAndPhysical = exports.Type_Consultation = exports.Type_DischargeDocumentation = exports.FHIR_DocumentReference_DocStatusOptions_ToLabelsMap = exports.FHIR_DocumentReference_DocStatusOptions = exports.DocStatus_EnteredInError = exports.DocStatus_Amended = exports.DocStatus_Final = exports.DocStatus_Preliminary = exports.FHIR_DocumentReference_CategoryOptions_ToLabelsMap = exports.FHIR_DocumentReference_CategoryOptions = exports.Category_ClinicalNote = void 0;
exports.Category_ClinicalNote = 'clinical-note';
exports.FHIR_DocumentReference_CategoryOptions = [
    exports.Category_ClinicalNote,
];
exports.FHIR_DocumentReference_CategoryOptions_ToLabelsMap = {
    [exports.Category_ClinicalNote]: 'Clinical Note',
};
exports.DocStatus_Preliminary = 'preliminary';
exports.DocStatus_Final = 'final';
exports.DocStatus_Amended = 'amended';
exports.DocStatus_EnteredInError = 'entered-in-error';
exports.FHIR_DocumentReference_DocStatusOptions = [
    exports.DocStatus_Preliminary,
    exports.DocStatus_Final,
    exports.DocStatus_Amended,
    exports.DocStatus_EnteredInError,
];
exports.FHIR_DocumentReference_DocStatusOptions_ToLabelsMap = {
    [exports.DocStatus_Preliminary]: 'Preliminary',
    [exports.DocStatus_Final]: 'Final',
    [exports.DocStatus_Amended]: 'Amended',
    [exports.DocStatus_EnteredInError]: 'Entered In Error',
};
exports.Type_DischargeDocumentation = '18842-5';
exports.Type_Consultation = '11488-4';
exports.Type_HistoryAndPhysical = '34117-2';
exports.Type_ProgressNote = '11506-3';
exports.Type_ProcedureNote = '28570-0';
exports.Type_EmergencyDepartmentNote = '34111-5';
exports.Type_NurseNote = '34746-8';
exports.Type_DischargeInstructions = '74213-0';
exports.Type_RiskAssessmentAndScreeningNote = '75492-9';
exports.FHIR_DocumentReference_TypeOptions = [
    exports.Type_DischargeDocumentation,
    exports.Type_Consultation,
    exports.Type_HistoryAndPhysical,
    exports.Type_ProgressNote,
    exports.Type_ProcedureNote,
    exports.Type_EmergencyDepartmentNote,
    exports.Type_NurseNote,
    exports.Type_DischargeInstructions,
    exports.Type_RiskAssessmentAndScreeningNote,
];
exports.FHIR_DocumentReference_TypeOptions_ToLabelsMap = {
    [exports.Type_DischargeDocumentation]: 'Discharge Documentation',
    [exports.Type_Consultation]: 'Consultation',
    [exports.Type_HistoryAndPhysical]: 'History & Physical',
    [exports.Type_ProgressNote]: 'Progress Note',
    [exports.Type_ProcedureNote]: 'Procedure Note',
    [exports.Type_EmergencyDepartmentNote]: 'Emergency Department Note',
    [exports.Type_NurseNote]: 'Nurse Note',
    [exports.Type_DischargeInstructions]: 'Discharge Instructions',
    [exports.Type_RiskAssessmentAndScreeningNote]: 'Risk assessment and screening note',
};
