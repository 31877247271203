"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHROrderTimeInput = void 0;
const React = require("react");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
require("./Renderer_EHROrderTime.scss");
class EHROrderTimeInput extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        this.onValueChange = (prop, _value) => {
            const value = this.translateToNumber(_value);
            this.state.editable.updateObj({ [prop]: value });
        };
        this.translateToNumber = (value) => {
            if (!value)
                return;
            const split = value.split(':');
            return Number(split[0]) * 60 + Number(split[1]);
        };
        this.translateToString = (value) => {
            let meridiem = 'am';
            if (!(0, ts_common_1.exists)(value))
                return {
                    time: !this.state.editMode ? 'N/A' : undefined,
                    meridiem,
                };
            //Translate 24hour format into 12hour format
            if (value >= 12 * 60) {
                value -= 12 * 60;
                meridiem = 'pm';
            }
            const hours = Math.floor(value / 60);
            value -= hours * 60;
            return {
                time: `${String(hours).padStart(2, '0')}:${String(value).padStart(2, '0')}`,
                meridiem,
            };
        };
        this.onMeridiemChange = async (check, prop) => {
            const editable = this.state.editable;
            const currentTime = editable.item[prop];
            //Time not set yet
            if (!(0, ts_common_1.exists)(currentTime))
                return;
            //Setting AM
            if (check === 'am') {
                //less than midday
                if (currentTime < 12 * 60)
                    return;
                return editable.updateObj({ [prop]: currentTime - (12 * 60) });
            }
            else { //Setting PM
                //More or equal to midday
                if (currentTime >= 12 * 60)
                    return;
                return editable.updateObj({ [prop]: currentTime + (12 * 60) });
            }
        };
        this.renderView = () => {
            const start = this.translateToString(this.state.editable.item.start);
            const end = this.translateToString(this.state.editable.item.end);
            return React.createElement(React.Fragment, null,
                start.time,
                start.meridiem.toUpperCase(),
                " - ",
                end.time,
                end.meridiem.toUpperCase());
        };
        this.renderTime = (prop) => {
            var _a;
            const timeData = this.translateToString(this.state.editable.get(prop));
            return React.createElement(frontend_1.LL_H_C, { className: 'ehr-order-time__input' },
                React.createElement(ui_components_1.DefaultEditor_InputTime, { value: timeData.time, editable: this.state.editable, prop: prop, onChange: value => this.onValueChange(prop, value) }),
                React.createElement(frontend_1.TS_Radio, { className: 'label-radio', values: ['am', 'pm'], groupName: 'meridiem', checked: (_a = timeData.meridiem) !== null && _a !== void 0 ? _a : 'am', onCheck: check => this.onMeridiemChange(check, prop) }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Render ########################
    render() {
        const className = (0, frontend_1._className)('ehr-order-time', this.state.editMode && 'edit-mode');
        return React.createElement(frontend_1.LL_H_C, { className: className },
            !this.state.editMode && this.renderView(),
            this.state.editMode && React.createElement(React.Fragment, null,
                this.renderTime('start'),
                React.createElement("div", { className: 'ehr-order-time__separator' }, "-"),
                this.renderTime('end')));
    }
}
exports.EHROrderTimeInput = EHROrderTimeInput;
