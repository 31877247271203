"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_QuaiProtoItem = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
class ModuleFE_QuaiProtoItem extends frontend_1.ModuleFE_BaseApi {
    constructor(dbDef, defaultDispatcher, minimalAssertionValidator, version) {
        super(dbDef, defaultDispatcher, version);
        this.minimalAssertionValidator = minimalAssertionValidator;
    }
    validateInternal(_instance) {
        const instance = (0, ts_common_1.removeDBObjectKeys)(_instance);
        const result = (0, ts_common_1.tsValidateResult)(instance, this.minimalAssertionValidator);
        if (result)
            this.onValidationError(_instance, result);
        return;
    }
}
exports.ModuleFE_QuaiProtoItem = ModuleFE_QuaiProtoItem;
