"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_DiseaseProfile = exports.DropDown_DiseaseProfile = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = __importStar(require("react"));
const ModuleFE_DiseaseProfile_1 = require("./ModuleFE_DiseaseProfile");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_DiseaseProfile_1.ModuleFE_DiseaseProfile,
    modules: [ModuleFE_DiseaseProfile_1.ModuleFE_DiseaseProfile],
    mapper: item => [item.label],
    placeholder: 'Choose a disease profile',
    renderer: item => React.createElement(React.Fragment, null, item.label)
};
exports.DropDown_DiseaseProfile = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_DiseaseProfile_1.ModuleFE_DiseaseProfile,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }),
            item.label);
    },
    uiSelector: exports.DropDown_DiseaseProfile.selectable,
});
exports.MultiSelect_DiseaseProfile = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
