"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveExists = void 0;
const resolveExists = () => {
    return {
        output_type: 'boolean',
        type: 'exists-condition',
    };
};
exports.resolveExists = resolveExists;
