"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectLabelSwaps = exports.updateExpressionString = exports.validateLeftHandBaseExists = exports.getAtomQueryFilter = exports.getValueQueryFilter = exports.getExpressionQueryFilter = void 0;
const shared_1 = require("../../../../../../../_entity/expression/shared");
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../../../../../../../_entity/expression/shared/consts");
const frontend_1 = require("../../../../../../../_entity/variable/frontend");
const frontend_2 = require("../../../../../../../_entity/expression/frontend");
const getFilteredBase = (operators, operatorsToFilter) => operators.filter(logic => !(operatorsToFilter === null || operatorsToFilter === void 0 ? void 0 : operatorsToFilter.includes(logic)));
const getExpressionQueryFilter = (expressionId, item, mappers, operatorsToFilter) => {
    const expressionType = (0, shared_1.getExpressionType)(expressionId, mappers.expressionMap);
    switch (expressionType) {
        case 'boolean': {
            return getFilteredBase(consts_1.TupleVarLogic, operatorsToFilter).includes(item);
        }
        case 'numeric': {
            return getFilteredBase(consts_1.NumericVarLogic, operatorsToFilter).includes(item);
        }
        default:
            return true;
    }
};
exports.getExpressionQueryFilter = getExpressionQueryFilter;
const getValueQueryFilter = (leftHand, item, mappers, operatorsToFilter) => {
    const valType = (0, shared_1.getValueType)(leftHand, mappers.variableMap, mappers.valueMap);
    switch (valType) {
        case 'enumerated': {
            return getFilteredBase(consts_1.EnumeratedVarLogic, operatorsToFilter).includes(item);
        }
        case 'tuple': {
            return getFilteredBase(consts_1.TupleVarLogic, operatorsToFilter).includes(item);
        }
        case 'standard-range':
            return getFilteredBase(consts_1.NormalRangeVarLogic, operatorsToFilter).includes(item);
        case 'range':
            return getFilteredBase(consts_1.NumericVarLogic, operatorsToFilter).includes(item);
        case 'time':
            return getFilteredBase([consts_1.LogicType_In], operatorsToFilter).includes(item);
        default:
            return true;
    }
};
exports.getValueQueryFilter = getValueQueryFilter;
const getAtomQueryFilter = (atom, item, mappers, operatorsToFilter) => {
    const atomType = (0, shared_1.getAtomType)(atom, mappers.variableMap, mappers.valueMap);
    const unusedInSubTerms = [consts_1.LogicType_Self, consts_1.LogicType_Exists];
    switch (atomType) {
        case 'numeric':
            return getFilteredBase(consts_1.NumericVarLogic.filter(logic => !unusedInSubTerms.includes(logic)), operatorsToFilter).includes(item);
        case 'range':
            return getFilteredBase(consts_1.RangeVarLogic, operatorsToFilter).includes(item);
        case 'boolean':
            return getFilteredBase(consts_1.BooleanOperators.filter(logic => !unusedInSubTerms.includes(logic)), operatorsToFilter).includes(item);
        case 'enum':
            return getFilteredBase(consts_1.EnumeratedVarLogic.filter(logic => !unusedInSubTerms.includes(logic)), operatorsToFilter).includes(item);
        case 'normal-range':
            return getFilteredBase(consts_1.NormalRangeVarLogic.filter(logic => !unusedInSubTerms.includes(logic)), operatorsToFilter).includes(item);
        default:
            return true;
    }
};
exports.getAtomQueryFilter = getAtomQueryFilter;
const validateLeftHandBaseExists = (leftHand) => {
    const variable = frontend_1.ModuleFE_Variable.cache.unique(leftHand === null || leftHand === void 0 ? void 0 : leftHand.id);
    const expression = frontend_2.ModuleFE_Expression.cache.unique(leftHand === null || leftHand === void 0 ? void 0 : leftHand.id);
    return (0, ts_common_1.exists)(expression) || (0, ts_common_1.exists)(variable) || (leftHand === null || leftHand === void 0 ? void 0 : leftHand.type) === 'atom';
};
exports.validateLeftHandBaseExists = validateLeftHandBaseExists;
const updateExpressionString = (labelSwaps, expression) => {
    let stringExpression = expression;
    labelSwaps.forEach(labelSwap => {
        const placeholder1 = `{{sub1-${Math.random()}}}`;
        const placeholder2 = `{{sub2-${Math.random()}}}`;
        stringExpression = stringExpression.replace(labelSwap.oldLabel, placeholder1);
        stringExpression = stringExpression.replace(labelSwap.newLabel, placeholder2);
        stringExpression = stringExpression.replace(placeholder1, labelSwap.newLabel);
        stringExpression = stringExpression.replace(placeholder2, labelSwap.oldLabel);
    });
    return stringExpression;
};
exports.updateExpressionString = updateExpressionString;
const CollectLabelSwaps = (newItemsOrder, oldOrder) => {
    return newItemsOrder.reduce((swapArray, currentValue, _index) => {
        if (currentValue.label === oldOrder[_index].label)
            return swapArray;
        const swap = { oldLabel: currentValue.label, newLabel: oldOrder[_index].label };
        if (swapArray.some(_swap => (0, ts_common_1.arrayIncludesAll)((0, ts_common_1._values)(_swap), (0, ts_common_1._values)(swap))))
            return swapArray;
        swapArray.push(swap);
        return swapArray;
    }, []);
};
exports.CollectLabelSwaps = CollectLabelSwaps;
