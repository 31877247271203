"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_PrognosisItemEditor = void 0;
const React = __importStar(require("react"));
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const Component_PopulationEditor_1 = require("../Component_PopulationEditor/Component_PopulationEditor");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const _enum_1 = require("../../../_enum");
require("./Component_PrognosisItemEditor.scss");
const ui_components_1 = require("@app/styles/frontend/ui-manager/ui-components");
const consts_1 = require("@app/hcs/_enum/timespan/shared/consts");
const ui_components_2 = require("@app/hcs/_enum/timespan/frontend/ui-components");
const frontend_2 = require("@app/hcs/frontend");
const ViewMode_View = 'view';
const ViewMode_Edit = 'edit';
class Component_PrognosisItemEditor extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.getViewMode = () => this.state.editMode ? ViewMode_Edit : ViewMode_View;
        this.composeRate = () => {
            var _a;
            const prognosis = this.state.editable.item;
            if (!prognosis.rate)
                return;
            if (((_a = prognosis.rate) === null || _a === void 0 ? void 0 : _a.type) === _enum_1.PrognosisRateType_Qualitative)
                return _enum_1.PrognosisValueLabelMap[prognosis.rate.value];
            const percentage = prognosis.rate.value[0] === prognosis.rate.value[1] ? `${prognosis.rate.value[0]}%` : `${prognosis.rate.value[0]} - ${prognosis.rate.value[1]}%`;
            return `${_enum_1.PrognosisRateTypeLabelMap[prognosis.rate.type]} of ${percentage}`;
        };
        this.composeTimePeriod = () => {
            const timePeriod = this.state.editable.item.timePeriod;
            if (!timePeriod)
                return;
            return `${timePeriod.value} ${consts_1.TimespanLabelsMap[timePeriod.type]}`;
        };
        // ######################## Render - Rate ########################
        this.renderRate = () => {
            const editable = this.state.editable;
            const rate = editable.editProp('rate', {});
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Rate' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => React.createElement("div", { className: 'prognosis-item-editor__data span-row' }, this.composeRate())
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(React.Fragment, null,
                                React.createElement(_enum_1.DropDown_PrognosisRateType.editable, { editable: rate, prop: 'type' }),
                                this.renderRateInput())
                        }
                    ] }));
        };
        this.renderRateInput = () => {
            const rate = this.state.editable.editProp('rate', {});
            if (!rate.item.type)
                return;
            if (rate.item.type === _enum_1.PrognosisRateType_Qualitative)
                return React.createElement(_enum_1.DropDown_PrognosisValue.editable, { editable: rate, prop: 'value' });
            return this.renderPercentageInput();
        };
        this.renderPercentageInput = () => {
            const rate = this.state.editable.editProp('rate', {});
            return React.createElement(frontend_1.LL_H_C, { className: 'prognosis-item-editor__percentage-input' },
                React.createElement(frontend_2.QRangeInputV3, { editable: rate, prop: 'value' }),
                React.createElement("div", { className: 'prognosis-item-editor__percentage-input__icon' }, "%"));
        };
        // ######################## Render - Time Period ########################
        this.renderTimePeriod = () => {
            const timePeriod = this.state.editable.editProp('timePeriod', {});
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Time Period' },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getViewMode(), modes: [
                        {
                            key: ViewMode_View,
                            renderer: () => React.createElement("div", { className: 'prognosis-item-editor__data span-row' }, this.composeTimePeriod()),
                        },
                        {
                            key: ViewMode_Edit,
                            renderer: () => React.createElement(React.Fragment, null,
                                React.createElement(ui_components_2.Dropdown_Timespans.editable, { editable: timePeriod, prop: 'type' }),
                                React.createElement(ui_components_1.DefaultEditor_InputNumeric, { editable: timePeriod, prop: 'value' }))
                        }
                    ] }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Render ########################
    render() {
        const population = this.state.editable.editProp('population', {});
        const className = (0, frontend_1._className)('prognosis-item-editor', this.state.editMode && 'edit-mode');
        return React.createElement(frontend_1.Grid, { className: className },
            this.renderRate(),
            this.renderTimePeriod(),
            React.createElement(Component_PopulationEditor_1.Component_PopulationEditor, { editable: population, editMode: this.state.editMode }));
    }
}
exports.Component_PrognosisItemEditor = Component_PrognosisItemEditor;
