"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvisorIconTypes = exports.AdvisorIconType_Physician = exports.AdvisorIconType_VitalSigns = exports.AdvisorIconType_Imaging = exports.AdvisorIconType_Laboratory = exports.ResourceTypes = exports.ResourceType_EKG = exports.ResourceType_Consultations = exports.ResourceType_Procedures = exports.ResourceType_Imaging = exports.ResourceType_Laboratory = void 0;
exports.ResourceType_Laboratory = 'Laboratory';
exports.ResourceType_Imaging = 'Imaging';
exports.ResourceType_Procedures = 'Procedures';
exports.ResourceType_Consultations = 'Consultations';
exports.ResourceType_EKG = 'EKG';
exports.ResourceTypes = [exports.ResourceType_Laboratory, exports.ResourceType_Imaging, exports.ResourceType_Procedures, exports.ResourceType_Consultations,];
exports.AdvisorIconType_Laboratory = 'Laboratory';
exports.AdvisorIconType_Imaging = 'Imaging';
exports.AdvisorIconType_VitalSigns = 'Vital Signs';
exports.AdvisorIconType_Physician = 'Physician';
exports.AdvisorIconTypes = [exports.AdvisorIconType_Laboratory, exports.AdvisorIconType_Imaging, exports.AdvisorIconType_VitalSigns, exports.AdvisorIconType_Physician];
