"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveSelf = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../consts");
const utils_1 = require("../utils");
const consts_2 = require("../../../../../_entity/expression/shared/consts");
const resolveSelf = (dbAtom, dependencies) => {
    const selfEvalLogic = {};
    selfEvalLogic.type = 'default (self)';
    const entity = (0, utils_1.resolveAssociatedEntity)(dbAtom, dependencies);
    if (entity) {
        if ('name' in entity)
            resolveSelfForHVAtom(entity, selfEvalLogic, dependencies);
        else
            resolveSelfForEmbeddedExpression(entity, selfEvalLogic, dependencies);
    }
    return selfEvalLogic;
};
exports.resolveSelf = resolveSelf;
const resolveSelfForHVAtom = (entity, selfEvalLogic, dependencies) => {
    const dbValue = dependencies.values.find(_val => _val._id === entity.valueId);
    if (!dbValue)
        throw new ts_common_1.BadImplementationException(`No value found for id ${entity.valueId}`);
    if (consts_1.StringDBValueTypes.includes(dbValue.type))
        selfEvalLogic.output_type = 'string';
    else if (consts_1.NumericDBValueTypes.includes((dbValue.type)))
        selfEvalLogic.output_type = 'number';
    else
        throw new ts_common_1.BadImplementationException(`Cannot resolve output type for value type ${dbValue.type}`);
};
const resolveSelfForEmbeddedExpression = (entity, selfEvalLogic, dependencies) => {
    if (entity.expressionType === consts_2.ExpressionType_GeneralFunction.dbKey)
        selfEvalLogic.output_type = 'string';
    else if (entity.expressionType === consts_2.ExpressionType_GeneralScore.dbKey)
        selfEvalLogic.output_type = 'number';
};
