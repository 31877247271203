"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_ColorGroup = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const hcs_1 = require("@app/hcs");
const validateColorValue = (0, ts_common_1.tsValidateRegexp)(/^#([0-9A-Fa-f]{3,4}){1,2}$/);
const Validator_ModifiableProps = {
    label: (0, ts_common_1.tsValidateString)(),
    'criticality-00': (0, ts_common_1.tsValidateArray)(validateColorValue),
    'criticality-10': (0, ts_common_1.tsValidateArray)(validateColorValue),
    'criticality-20': (0, ts_common_1.tsValidateArray)(validateColorValue),
};
const Validator_GeneratedProps = {};
exports.DBDef_ColorGroup = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'color-groups',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'color-group'
    },
    backend: {
        name: 'color-groups'
    },
    entityName: 'ColorGroup',
};
