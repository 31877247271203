"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHIR_List_CodeOptions_ToLabelsMap = exports.FHIR_List_CodeOptions = exports.Code_Medications = void 0;
exports.Code_Medications = 'medications';
exports.FHIR_List_CodeOptions = [
    exports.Code_Medications,
];
exports.FHIR_List_CodeOptions_ToLabelsMap = {
    [exports.Code_Medications]: 'Medications',
};
