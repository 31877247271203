"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_FHIR = exports.ModuleFE_FHIR_Class = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
class ModuleFE_FHIR_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.vv1 = {
            fetchFhir: (0, frontend_1.apiWithBody)(shared_1.ApiDef_FHIR.vv1.fetchFhir)
        };
    }
    init() {
    }
}
exports.ModuleFE_FHIR_Class = ModuleFE_FHIR_Class;
exports.ModuleFE_FHIR = new ModuleFE_FHIR_Class();
