"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterRelevantValues = void 0;
const _entity_1 = require("../../../../../../_entity");
function filterRelevantValues(value) {
    let relevantValues = [];
    if (!value)
        return relevantValues;
    switch (value.type) {
        case _entity_1.valueType_Range:
            relevantValues = value.data.subsets;
            break;
        case _entity_1.valueType_Enumerated:
            relevantValues = value.data.scope;
            break;
        case _entity_1.valueType_Tuple:
            relevantValues = value.data.scope;
            break;
        case _entity_1.valueType_Time:
            relevantValues = value.data.timeRanges;
            break;
        case _entity_1.valueType_StandardRange:
            relevantValues = [_entity_1.StaticValue_Decreased._id, _entity_1.StaticValue_Normal._id, _entity_1.StaticValue_Increased._id];
            break;
    }
    return relevantValues;
}
exports.filterRelevantValues = filterRelevantValues;
