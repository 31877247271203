"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QJSONViewer = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./QJSONViewer.scss");
const styles_1 = require("@app/styles");
const treeRendererMap = {
    root: (props) => React.createElement("div", null, "root"),
    item: (props) => React.createElement(JSONViewerNodeRenderer, Object.assign({}, props))
};
class QJSONViewer extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.prepareTree = () => {
            const tree = {
                type: 'root',
                item: this.state.item,
            };
            const items = (0, ts_common_1.sortArray)((0, ts_common_1._keys)(this.state.item)).map(key => ({ key, value: this.state.item[key] }));
            tree._children = items.map(item => this.prepareTreeItem(item));
            return tree;
        };
        this.prepareTreeItem = (item) => {
            const treeItem = {
                type: 'item',
                item
            };
            if (typeof item.value === 'object') {
                if (Array.isArray(item.value))
                    treeItem._children = item.value.map((child, i) => ({
                        key: `${i}`,
                        value: child
                    })).map(child => this.prepareTreeItem(child));
                else {
                    if (item.value === null)
                        return { type: 'item', item };
                    treeItem._children = (0, ts_common_1._keys)(item.value).map(key => ({
                        key: String(key),
                        value: item.value[key]
                    })).map(child => this.prepareTreeItem(child));
                }
            }
            return treeItem;
        };
        this.resolveCollapseIcon = (props) => {
            if (!props.node.adapter.isParent(props.item))
                return React.createElement(React.Fragment, null);
            const className = (0, frontend_1._className)('node-icon', props.node.expanded ? 'expanded' : undefined);
            return React.createElement(styles_1.ICONS.treeCollapse.component, { className: className });
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    deriveStateFromProps(nextProps, state) {
        state.item = nextProps.item;
        if (nextProps.filterGenerated) {
            (0, ts_common_1._keys)(state.item).map(key => key.startsWith('_') && delete state.item[key]);
        }
        return state;
    }
    render() {
        const tree = this.prepareTree();
        const treeAdapter = (0, frontend_1.AdapterBuilder)()
            .tree()
            .multiRender(treeRendererMap)
            .setExpandCollapseRenderer(this.resolveCollapseIcon)
            .hideRoot()
            .setData(tree)
            .build();
        return React.createElement(frontend_1.TS_Tree, { adapter: treeAdapter, className: 'q-json-viewer' });
    }
}
exports.QJSONViewer = QJSONViewer;
class JSONViewerNodeRenderer extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps) {
        return { expanded: nextProps.node.expanded };
    }
    constructor(props) {
        super(props);
        this.resolveDataStyle = (value) => {
            const style = {
                'width': 0,
                'flexGrow': 1,
                'WhiteSpace': 'nowrap',
                'overflow': 'hidden',
                'textOverflow': 'ellipsis',
                'color': '#000000',
            };
            switch (typeof value) {
                case 'string':
                    style.color = '#e67e22';
                    break;
                case 'boolean':
                    style.color = '#bf95d0';
                    break;
                case 'number':
                    if (isNaN(value))
                        style.color = '#e0e0e0';
                    else
                        style.color = '#2ecc71';
                    break;
                case 'undefined':
                    style.color = '#000';
                    break;
                case 'object':
                    if (value === null)
                        style.color = '#f1c40f';
                    break;
                default:
                    style.color = '#000000';
                    break;
            }
            return style;
        };
    }
    render() {
        const { key, value } = this.props.item;
        const style = this.resolveDataStyle(value);
        if (typeof value !== 'object') {
            return React.createElement(frontend_1.LL_H_C, { className: 'match_width', style: { gap: '8px' } },
                React.createElement("span", { style: { flexShrink: 0 } },
                    key,
                    ":"),
                React.createElement("span", { style: style }, `${value}`));
        }
        return React.createElement(frontend_1.LL_H_C, { className: 'match_width', style: { gap: '8px' } },
            React.createElement("span", { style: { flexShrink: 0 } },
                key,
                ":"),
            !this.state.expanded && React.createElement("span", { style: style }, JSON.stringify(value)));
    }
}
