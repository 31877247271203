"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalForm_EKGAssessment = exports.ClinicalFormKey_EKGAssessment = void 0;
const types_1 = require("../types");
const wallsAttributeCreator = () => ({
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Walls',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'heart walls',
    valueIds: []
});
// ######################### Section - Overview Interpretation #########################
const subSection_OverallInterpretation = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Overall interpretation',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'overall EKG interpretation',
    valueIds: []
};
const section_overallInterpretation = {
    title: 'Overall Interpretation',
    subSections: [
        subSection_OverallInterpretation,
    ],
};
// ######################### Section - Overview Interpretation #########################
const subSection_Rate = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Rate',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'rate',
    valueIds: [],
};
const subSection_Rhythm = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Rhythm',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'rhythm',
    valueIds: [],
};
const subSection_Axis = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Axis',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'axis',
    valueIds: []
};
const subSection_PRInterval = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'PR Interval',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'PR Interval',
    valueIds: []
};
const subSection_QTInterval = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'QT Interval',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'QT Interval',
    valueIds: []
};
const subSection_STSegment = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'ST Segment',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Single,
    variableId: 'ST Segment',
    attributes: [wallsAttributeCreator()],
    valueIds: [],
};
const subSection_TWaves = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'T Waves',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Single,
    variableId: 'T Wave',
    attributes: [wallsAttributeCreator()],
    valueIds: [],
};
const subSection_QRSComplex = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'QRS Complex',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Single,
    variableId: 'QRS Complex',
    attributes: [wallsAttributeCreator()],
    valueIds: [],
};
const subSection_PWave = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'P Wave',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Single,
    variableId: 'P Wave',
    attributes: [wallsAttributeCreator()],
    valueIds: [],
};
const subSection_QWave = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Q Wave',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Single,
    variableId: 'Q Wave',
    attributes: [wallsAttributeCreator()],
    valueIds: [],
};
const subSection_RWave = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'R Wave',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Single,
    variableId: 'R Wave',
    attributes: [wallsAttributeCreator()],
    valueIds: [],
};
const section_overviewInterpretation = {
    title: 'Overview Interpretation',
    subSections: [
        subSection_Rate,
        subSection_Rhythm,
        subSection_Axis,
        subSection_PRInterval,
        subSection_QTInterval,
        subSection_STSegment,
        subSection_TWaves,
        subSection_QRSComplex,
        subSection_PWave,
        subSection_QWave,
        subSection_RWave,
    ],
};
// ######################### Section - Detailed Interpretation #########################
const subSection_BradyarrhythmiaRhythm = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Bradyarrhythmia Rhythm',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'Bradyarrhythmia Rhythm',
    valueIds: []
};
const subSection_AtrialTachyarrhythmia = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'Atrial Tachyarhythmia',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'atrial tachyarhythmia',
    valueIds: []
};
const subSection_VentricularTachyarrhythmia = {
    type: types_1.clinicalForm_SubSectionType_Values,
    title: 'ventricular tachyarrhythmia',
    context: 'EKG',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'ventricular tachyarrhythmia',
    valueIds: []
};
const section_detailedInterpretation = {
    title: 'Detailed Interpretation',
    subSections: [
        subSection_BradyarrhythmiaRhythm,
        subSection_AtrialTachyarrhythmia,
        subSection_VentricularTachyarrhythmia
    ],
};
// ######################### Form #########################
exports.ClinicalFormKey_EKGAssessment = 'ekg-assessment';
exports.ClinicalForm_EKGAssessment = {
    title: 'EKG Assessment',
    key: exports.ClinicalFormKey_EKGAssessment,
    contextVariableId: 'EKG',
    sections: [section_overallInterpretation, section_overviewInterpretation, section_detailedInterpretation],
    _variableIds: [],
    _valueIds: []
};
