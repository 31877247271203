"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFhirParamsLabelsMap = exports.FHIR_ResourceToParamOptionsLabelMaps_Map = exports.FHIR_ResourceToTypeOptionsLabelMap = exports.FHIR_ResourceToDocStatusOptionsLabelMap = exports.FHIR_ResourceToStatusOptionsLabelMap = exports.FHIR_ResourceToCodeOptionsLabelMap = exports.FHIR_ResourceToCategoryLabelMap = void 0;
const consts_1 = require("./types/Binary/consts");
const consts_2 = require("./types/Condition/consts");
const consts_3 = require("./types/DocumentReference/consts");
const consts_4 = require("./types/MedicationRequest/consts");
const consts_5 = require("./types/Observation/consts");
const consts_6 = require("./types/Procedure/consts");
const consts_7 = require("./types/List/consts");
exports.FHIR_ResourceToCategoryLabelMap = {
    AllergyIntolerance: undefined,
    Binary: consts_1.FHIR_Binary_CategoryOptions_ToLabelsMap,
    Condition: consts_2.FHIR_Condition_CategoryOptions_ToLabelsMap,
    DocumentReference: consts_3.FHIR_DocumentReference_CategoryOptions_ToLabelsMap,
    FamilyMemberHistory: undefined,
    Immunization: undefined,
    List: undefined,
    MedicationRequest: consts_4.FHIR_MedicationRequest_CategoryOptions_ToLabelsMap,
    Observation: consts_5.FHIR_Observation_CategoryOptions_ToLabelsMap,
    Procedure: consts_6.FHIR_Procedure_CategoryOptions_ToLabelsMap,
};
exports.FHIR_ResourceToCodeOptionsLabelMap = {
    AllergyIntolerance: undefined,
    Binary: undefined,
    Condition: undefined,
    DocumentReference: undefined,
    FamilyMemberHistory: undefined,
    Immunization: undefined,
    List: consts_7.FHIR_List_CodeOptions_ToLabelsMap,
    MedicationRequest: undefined,
    Observation: consts_5.FHIR_Observation_CodeOptions_ToLabelsMap,
    Procedure: undefined,
};
exports.FHIR_ResourceToStatusOptionsLabelMap = {
    AllergyIntolerance: undefined,
    Binary: undefined,
    Condition: undefined,
    DocumentReference: undefined,
    FamilyMemberHistory: undefined,
    Immunization: undefined,
    List: undefined,
    MedicationRequest: consts_4.FHIR_MedicationRequest_StatusOptions_ToLabelsMap,
    Observation: undefined,
    Procedure: undefined,
};
exports.FHIR_ResourceToDocStatusOptionsLabelMap = {
    AllergyIntolerance: undefined,
    Binary: undefined,
    Condition: undefined,
    DocumentReference: consts_3.FHIR_DocumentReference_DocStatusOptions_ToLabelsMap,
    FamilyMemberHistory: undefined,
    Immunization: undefined,
    List: undefined,
    MedicationRequest: undefined,
    Observation: undefined,
    Procedure: undefined,
};
exports.FHIR_ResourceToTypeOptionsLabelMap = {
    AllergyIntolerance: undefined,
    Binary: undefined,
    Condition: undefined,
    DocumentReference: consts_3.FHIR_DocumentReference_TypeOptions_ToLabelsMap,
    FamilyMemberHistory: undefined,
    Immunization: undefined,
    List: undefined,
    MedicationRequest: undefined,
    Observation: undefined,
    Procedure: undefined,
};
exports.FHIR_ResourceToParamOptionsLabelMaps_Map = {
    category: exports.FHIR_ResourceToCategoryLabelMap,
    code: exports.FHIR_ResourceToCodeOptionsLabelMap,
    docstatus: exports.FHIR_ResourceToDocStatusOptionsLabelMap,
    status: exports.FHIR_ResourceToStatusOptionsLabelMap,
    type: exports.FHIR_ResourceToTypeOptionsLabelMap,
};
const getFhirParamsLabelsMap = (_pairKey, resourceType) => {
    var _a;
    if (!resourceType || !_pairKey)
        return;
    return (_a = exports.FHIR_ResourceToParamOptionsLabelMaps_Map[_pairKey]) === null || _a === void 0 ? void 0 : _a[resourceType];
};
exports.getFhirParamsLabelsMap = getFhirParamsLabelsMap;
