"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default_DPViewConfigs = exports.key_DiseaseViews = exports.cleanDPView = exports.noConnectedViewDPView = void 0;
const types_1 = require("./types");
const ts_common_1 = require("@nu-art/ts-common");
exports.noConnectedViewDPView = Object.freeze({
    label: 'Multivars with no connected views',
    _id: 'misc-dp-view',
    type: 'multivar-list',
    enforcedRole: 'factor',
    enforcedTags: [],
    filter: { roles: [], tagIds: {} },
    __created: 0,
    __updated: 0,
});
exports.cleanDPView = Object.freeze({
    label: '',
    type: 'multivar-list',
    enforcedRole: 'factor',
    enforcedTags: [],
    filter: { roles: [], tagIds: {} },
});
exports.key_DiseaseViews = 'Disease Views';
const defaultVPName_Complaints = 'Complaints';
const defaultVPName_Exam = 'Exam';
const defaultVPName_DifferentialDiagnosis = 'Differential Diagnosis';
const defaultVPName_Administrative = 'Administrative';
const defaultVPName_RiskFactors = 'Risk Factors';
const defaultVPName_VitalSigns = 'Vital Signs';
const defaultVPName_EKG = 'EKG';
const defaultVPName_Complications = 'Complications';
const defaultVPName_Laboratory = 'Laboratory';
const defaultVPName_Imaging = 'Imaging';
const defaultVPName_Treatments = 'Treatments';
const defaultVPName_Procedures = 'Procedures';
const defaultVPName_PrecipitatingFactors = 'Precipitating Factors';
const defaultVPName_RelievingFactors = 'Relieving Factors';
const DP_ViewConfig__Complaints = {
    _id: (0, ts_common_1.md5)(defaultVPName_Complaints),
    label: defaultVPName_Complaints,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom', 'factor'], tagIds: { whitelist: ['complaints'] } }
};
const DP_ViewConfig__Exam = {
    _id: (0, ts_common_1.md5)(defaultVPName_Exam),
    label: defaultVPName_Exam,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: { whitelist: ['exam'] } }
};
const DP_ViewConfig__VitalSigns = {
    _id: (0, ts_common_1.md5)(defaultVPName_VitalSigns),
    label: defaultVPName_VitalSigns,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: { whitelist: ['vital signs'] } }
};
const DP_ViewConfig__EKG = {
    _id: (0, ts_common_1.md5)(defaultVPName_EKG),
    label: defaultVPName_EKG,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: { whitelist: ['ekg'] } }
};
const DP_ViewConfig__Complications = {
    _id: (0, ts_common_1.md5)(defaultVPName_Complications),
    label: defaultVPName_Complications,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: { whitelist: ['diseases'] } }
};
const DP_ViewConfig__Laboratory = {
    _id: (0, ts_common_1.md5)(defaultVPName_Laboratory),
    label: defaultVPName_Laboratory,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: { whitelist: ['laboratory'] } }
};
const DP_ViewConfig__Imaging = {
    _id: (0, ts_common_1.md5)(defaultVPName_Imaging),
    label: defaultVPName_Imaging,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: { whitelist: ['imaging'] } }
};
const DP_ViewConfig__Administrative = {
    _id: (0, ts_common_1.md5)(defaultVPName_Administrative),
    label: defaultVPName_Administrative,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'factor',
    enforcedTags: [],
    filter: { roles: ['factor'], tagIds: { whitelist: ['administrative'] } }
};
const DP_ViewConfig__RiskFactors = {
    _id: (0, ts_common_1.md5)(defaultVPName_RiskFactors),
    label: defaultVPName_RiskFactors,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'factor',
    enforcedTags: [],
    filter: { roles: ['factor', 'condition', 'treatment'], tagIds: { whitelist: ['history', 'diseases', 'therapy', 'procedure'] } }
};
const DP_ViewConfig__DifferentialDiagnosis = {
    _id: (0, ts_common_1.md5)(defaultVPName_DifferentialDiagnosis),
    label: defaultVPName_DifferentialDiagnosis,
    type: types_1.dpViewType_MultivarList,
    enforcedRole: 'condition',
    enforcedTags: [],
    filter: { roles: ['condition'], tagIds: { whitelist: ['diseases'] } }
};
const DP_ViewConfig__Treatments = {
    _id: (0, ts_common_1.md5)(defaultVPName_Treatments),
    label: defaultVPName_Treatments,
    type: types_1.dpViewType_MultivarList,
    enforcedRole: 'treatment',
    enforcedTags: [],
    filter: { roles: ['treatment'], tagIds: { whitelist: ['therapy'] } }
};
const DP_ViewConfig__Procedures = {
    _id: (0, ts_common_1.md5)(defaultVPName_Procedures),
    label: defaultVPName_Procedures,
    type: types_1.dpViewType_MultivarList,
    enforcedRole: 'treatment',
    enforcedTags: [],
    filter: { roles: ['treatment'], tagIds: { whitelist: ['procedure'] } }
};
const DP_ViewConfig__PrecipitatingFactors = {
    _id: (0, ts_common_1.md5)(defaultVPName_PrecipitatingFactors),
    label: defaultVPName_PrecipitatingFactors,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: {} }
};
const DP_ViewConfig__RelievingFactors = {
    _id: (0, ts_common_1.md5)(defaultVPName_RelievingFactors),
    label: defaultVPName_RelievingFactors,
    type: types_1.dpViewType_MultivarAndValuesList,
    enforcedRole: 'symptom',
    enforcedTags: [],
    filter: { roles: ['symptom'], tagIds: {} }
};
exports.default_DPViewConfigs = [
    DP_ViewConfig__Administrative,
    DP_ViewConfig__Complaints,
    DP_ViewConfig__Exam,
    DP_ViewConfig__VitalSigns,
    DP_ViewConfig__EKG,
    DP_ViewConfig__Complications,
    DP_ViewConfig__RiskFactors,
    DP_ViewConfig__Laboratory,
    DP_ViewConfig__Imaging,
    DP_ViewConfig__DifferentialDiagnosis,
    DP_ViewConfig__Treatments,
    DP_ViewConfig__Procedures,
    DP_ViewConfig__PrecipitatingFactors,
    DP_ViewConfig__RelievingFactors
];
