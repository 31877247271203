"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Manager_HealthcareSpace = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Manager_HealthcareSpace.scss");
const Manager_Variable_1 = require("../Manager_Variable/Manager_Variable");
const _entity_1 = require("../../_entity");
const modulesToAwait = [_entity_1.ModuleFE_Tag, _entity_1.ModuleFE_Variable, _entity_1.ModuleFE_Value, _entity_1.ModuleFE_Reference];
class Manager_HealthcareSpace_Class extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.variablesContainerRef = React.createRef();
        this.selectedVarRef = React.createRef();
        // ######################## Logic ########################
        this.getVariablesForTagId = (tagId) => {
            return (0, ts_common_1.sortArray)(_entity_1.ModuleFE_Variable.cache.filter(variable => {
                return !!variable.tagIds.find(group => group.key === tagId || group.value.includes(tagId));
            }), variable => variable.name.toLowerCase());
        };
        this.onVarClick = (varId) => {
            this.setState({ selectedVarId: varId });
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const tag = _entity_1.ModuleFE_Tag.cache.unique(this.state.tagId);
            let hierarchy = 'Healthcare Space';
            tag.tagIds.forEach(tagId => {
                const tag = _entity_1.ModuleFE_Tag.cache.unique(tagId);
                hierarchy += ` > ${tag.label}`;
            });
            return React.createElement(frontend_1.LL_H_C, { className: 'manager__header' },
                React.createElement(frontend_1.LL_V_L, { className: 'manager__header__main' },
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__title' },
                        React.createElement("div", { className: 'manager__header__title__label' }, tag === null || tag === void 0 ? void 0 : tag.label)),
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__sub-title' },
                        React.createElement("span", { className: 'manager__header__sub-title__item' }, "Tag"),
                        "in",
                        React.createElement("span", { className: 'manager__header__sub-title__hierarchy' }, hierarchy))));
        };
        // ######################## Render - Body ########################
        this.renderVariables = () => {
            const variables = this.getVariablesForTagId(this.state.tagId);
            return React.createElement(frontend_1.LL_V_L, { className: 'manager__hcs__variables', innerRef: this.variablesContainerRef }, variables.map(this.renderVariable));
        };
        this.renderVariable = (variable) => {
            const className = (0, frontend_1._className)('manager__hcs__variable', this.state.selectedVarId === variable._id && 'selected', _entity_1.ModuleFE_Discussion.hasUnresolvedDiscussions(variable._id, _entity_1.ModuleFE_Variable.dbDef.dbKey) && 'open-discussion');
            return React.createElement("div", { key: variable._id, className: className, onClick: () => this.onVarClick(variable._id), ref: variable._id === this.state.selectedVarId ? this.selectedVarRef : undefined }, variable.name);
        };
        this.renderVariableInfo = () => {
            if (!this.state.selectedVarId)
                return;
            return React.createElement(Manager_Variable_1.Manager_Variable, { varId: this.state.selectedVarId });
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c, _d, _e;
        const firstDerive = !((_a = this.state) === null || _a === void 0 ? void 0 : _a.tagId);
        state.tagId = nextProps.tagId;
        if (((_b = this.state) === null || _b === void 0 ? void 0 : _b.tagId) !== state.tagId)
            state.selectedVarId = undefined;
        state.variableContainerHeight = (_e = (_d = (_c = this.variablesContainerRef) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.clientHeight) !== null && _e !== void 0 ? _e : 0;
        //If a var id is given
        if (nextProps.varId) {
            //If not the first derive or the var id hasn't changed
            if (!firstDerive && this.props.varId === nextProps.varId)
                return state;
            state.selectedVarId = nextProps.varId;
        }
        return state;
    }
    componentDidUpdate(prevProps, prevState) {
        var _a;
        //If this is the first render after getting a ref for the variables container
        if (!this.state.variableContainerHeight && ((_a = this.variablesContainerRef) === null || _a === void 0 ? void 0 : _a.current)) {
            this.setState({ variableContainerHeight: this.variablesContainerRef.current.clientHeight });
        }
        //If selected var has changed, scroll
        if (this.state.selectedVarId && this.state.selectedVarId !== prevState.selectedVarId) {
            (0, frontend_1.scrollIntoView)(this.selectedVarRef, this.variablesContainerRef);
        }
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.Grid, { id: 'manager__hcs', className: 'quai-item-manager' },
            this.renderHeader(),
            this.renderVariables(),
            this.renderVariableInfo());
    }
}
const Manager_HealthcareSpace = (props) => {
    return React.createElement(frontend_1.AwaitModules, { modules: modulesToAwait },
        React.createElement(Manager_HealthcareSpace_Class, Object.assign({}, props)));
};
exports.Manager_HealthcareSpace = Manager_HealthcareSpace;
