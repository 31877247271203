"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Artifact = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const hcs_1 = require("@app/hcs");
const Validator_ModifiableProps = {
    codeVersion: (0, ts_common_1.tsValidateString)(),
    dataVersion: (0, ts_common_1.tsValidateString)(),
    pathToOriginalBackup: (0, ts_common_1.tsValidateString)(),
    pathToProcessedBackup: (0, ts_common_1.tsValidateString)(),
    pathToKnowledgeArtifact: (0, ts_common_1.tsValidateString)(),
    pathToServiceArtifacts: (0, ts_common_1.tsValidateString)(),
    bucket: (0, ts_common_1.tsValidateString)(),
};
const Validator_GeneratedProps = {};
exports.DBDef_Artifact = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'artifacts',
    entityName: 'artifact',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'artifacts'
    },
    backend: {
        name: 'artifacts'
    }
};
