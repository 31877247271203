"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePackFE_Pathway = void 0;
const frontend_1 = require("../_entity/pathway/frontend");
const frontend_2 = require("../_entity/pathway-state/frontend");
const frontend_3 = require("../_entity/pathway-transition/frontend");
const frontend_4 = require("../_entity/action/frontend");
exports.ModulePackFE_Pathway = [
    ...frontend_4.ModulePackFE_ActionDB,
    frontend_1.ModuleFE_Pathway,
    frontend_2.ModuleFE_PathwayState,
    frontend_3.ModuleFE_PathwayTransition,
];
