"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDiscussionPopup = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
require("./CreateDiscussionPopup.scss");
const shared_1 = require("../../../shared");
const q_components_1 = require("../q-components");
const frontend_2 = require("@nu-art/ts-messaging/_entity/message/frontend");
const frontend_3 = require("@nu-art/ts-messaging/_entity/topic/frontend");
const frontend_4 = require("../../../_entity/discussion/frontend");
const consts_1 = require("../../../_entity/discussion/shared/consts");
class CreateDiscussionPopup extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.clearInput = () => this.setState({ newDiscussionTitle: undefined });
        this.shouldShowInput = () => { var _a; return ((_a = this.state.newDiscussionTitle) === null || _a === void 0 ? void 0 : _a.length) !== undefined; };
        this.addDiscussion = async () => {
            if (this.state.busy) {
                this.logError('Can\'t add another discussion, popup is currently busy adding a discussion!!!');
                return;
            }
            const newTitle = this.state.newDiscussionTitle;
            if (!newTitle)
                return;
            this.setState({ busy: true });
            await (0, frontend_1.performAction)(async () => {
                const response = await frontend_4.ModuleFE_Discussion._v1.createNewDiscussion({
                    toCreateTopic: {
                        type: this.getRelevantCollectionName(),
                        refId: this.props.refId
                    },
                    toCreateDiscussion: {
                        type: consts_1.DiscussionType_Chat
                    },
                    toCreateMessage: {
                        type: 'text',
                        text: newTitle.trim()
                    }
                }).executeSync();
                this.reDeriveState({
                    busy: false
                });
                frontend_4.ModuleFE_Discussion.openDiscussions({ topicId: response.topic._id, discussionId: response.discussion._id });
                frontend_1.ModuleFE_MouseInteractivity.hide('pop-up');
            }, {
                type: 'toast',
                successContent: React.createElement(q_components_1.QToastContent, { toastType: 'success', iconKey: 'v', content: `Discussion created Successfully` }),
                duration: 10000
            }, newTitle, true);
        };
    }
    __onDiscussionsUpdated(...params) {
        switch (params[0]) {
            case 'update':
            case 'delete':
            case 'delete-multi':
                this.reDeriveState();
                break;
        }
    }
    __onMessagesUpdated(...params) {
        switch (params[0]) {
            case 'create':
                this.reDeriveState();
                break;
        }
    }
    renderNewDiscussionInput() {
        return React.createElement(frontend_1.LL_V_L, { className: 'message__new_comment' }, this.shouldShowInput()
            ? React.createElement(frontend_1.TS_Input, { key: 'comment_msg', type: 'text', placeholder: 'New discussion title...', value: this.state.newDiscussionTitle, onChange: value => this.reDeriveState({ newDiscussionTitle: value }), onKeyDown: async (ev) => {
                    let value = ev.currentTarget.value;
                    value = value.trim();
                    if (ev.key === 'Enter') {
                        //@ts-ignore - despite what typescript says, ev.target does have a blur function.
                        ev.target.blur();
                        await this.addDiscussion();
                        ev.stopPropagation();
                    }
                    if (ev.key === 'Escape') {
                        this.clearInput();
                        ev.stopPropagation();
                    }
                } })
            : React.createElement(frontend_1.TS_Button, { className: 'button_msg_add', onClick: () => this.reDeriveState({ newDiscussionTitle: '' }) }, "Create new discussion"));
    }
    getRelevantEntity() {
        return shared_1.CollectionEntityMapper.getEntityMapByEntityName(this.props.entityName);
    }
    getRelevantCollectionName() {
        return this.getRelevantEntity().type;
    }
    getEntityType() {
        return this.getRelevantEntity().entityName;
    }
    getEntityLabel() {
        const collectionName = this.getRelevantCollectionName();
        const dbObject = frontend_4.ModuleFE_Discussion.getReferencedObjectByCollectionRef(collectionName, this.props.refId);
        if (!dbObject)
            return `Error- can't find entity for entityName ${this.props.entityName}.`;
        return frontend_4.ModuleFE_Discussion.getLabelFromEntity(collectionName, dbObject);
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'create-discussion__content' },
            React.createElement(frontend_1.LL_V_L, { className: 'create-discussion__header' },
                React.createElement("div", { className: 'entity-type' }, this.getEntityType()),
                React.createElement("div", { className: 'entity-label' }, this.getEntityLabel())),
            this.renderNewDiscussionInput());
    }
}
CreateDiscussionPopup.defaultProps = {
    modules: [frontend_4.ModuleFE_Discussion, frontend_3.ModuleFE_Topic, frontend_2.ModuleFE_Message]
};
exports.CreateDiscussionPopup = CreateDiscussionPopup;
