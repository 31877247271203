"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchableItem_Values = exports.SearchableItem_Variables = exports.SearchableItem_Tags = exports.searchItemKey_Values = exports.searchItemKey_Tags = exports.searchItemKey_Variables = void 0;
const frontend_1 = require("../../_entity/variable/frontend");
const frontend_2 = require("../../_entity/value/frontend");
const React = require("react");
const frontend_3 = require("@app/styles/frontend");
const healthcare_space_1 = require("./healthcare-space");
const shared_1 = require("../../shared");
const Manager_HealthcareSpace_1 = require("./Manager_HealthcareSpace/Manager_HealthcareSpace");
const ErrorPage_Values_1 = require("./ErrorPage_Values/ErrorPage_Values");
const _entity_1 = require("../_entity");
exports.searchItemKey_Variables = frontend_1.ModuleFE_Variable.dbDef.dbKey;
exports.searchItemKey_Tags = _entity_1.ModuleFE_Tag.dbDef.dbKey;
exports.searchItemKey_Values = frontend_2.ModuleFE_Value.dbDef.dbKey;
exports.SearchableItem_Tags = {
    module: _entity_1.ModuleFE_Tag,
    type: exports.searchItemKey_Tags,
    route: healthcare_space_1.Component_HealthcareSpace.Route,
    entityName: _entity_1.ModuleFE_Tag.dbDef.dbKey,
    entityLabel: 'Tag',
    groupId: ['Healthcare Space'],
    mapper: (item) => [item.label],
    renderer: (item, selected, onClickCallback) => {
        let hierarchy = 'Healthcare Space';
        item.tagIds.forEach(tagId => {
            const tag = _entity_1.ModuleFE_Tag.cache.unique(tagId);
            hierarchy += ` > ${tag.label}`;
        });
        return React.createElement(frontend_3.Renderer_SearchResult, { itemId: item._id, itemKey: exports.searchItemKey_Tags, itemLabel: item.label, entityLabel: 'Tag', hierarchy: hierarchy, selected: selected, onClickCallback: onClickCallback });
    },
    resultRenderer: (itemId) => {
        return React.createElement(Manager_HealthcareSpace_1.Manager_HealthcareSpace, { tagId: itemId });
    },
    tags: [],
};
exports.SearchableItem_Variables = {
    module: frontend_1.ModuleFE_Variable,
    type: exports.searchItemKey_Variables,
    route: healthcare_space_1.Component_HealthcareSpace.Route,
    entityName: frontend_1.ModuleFE_Variable.dbDef.entityName,
    entityLabel: 'Variable',
    groupId: ['Healthcare Space'],
    mapper: (item) => [item.name],
    renderer: (item, selected, onClickCallback) => {
        var _a;
        let hierarchy = 'Healthcare Space';
        (_a = item.tagIds.find(tagGroup => tagGroup.key === _entity_1.ConfigKeyFE_SourceTagId.get())) === null || _a === void 0 ? void 0 : _a.value.forEach(tagId => {
            const tag = _entity_1.ModuleFE_Tag.cache.unique(tagId);
            if (!tag)
                return;
            hierarchy += ` > ${tag.label}`;
        });
        return React.createElement(frontend_3.Renderer_SearchResult, { itemId: item._id, itemKey: exports.searchItemKey_Variables, itemLabel: item.name, entityLabel: 'Variable', hierarchy: hierarchy, selected: selected, onClickCallback: onClickCallback, className: _entity_1.ModuleFE_Discussion.hasUnresolvedDiscussions(item._id, frontend_1.ModuleFE_Variable.dbDef.dbKey) ? 'open-discussion' : undefined });
    },
    resultRenderer: (itemId) => {
        const variable = frontend_1.ModuleFE_Variable.cache.unique(itemId);
        const id = variable.tagIds.find(group => group.key === _entity_1.ConfigKeyFE_SourceTagId.get()).value[0];
        return React.createElement(Manager_HealthcareSpace_1.Manager_HealthcareSpace, { tagId: id, varId: itemId });
    },
    tags: [],
};
exports.SearchableItem_Values = {
    module: frontend_2.ModuleFE_Value,
    type: exports.searchItemKey_Values,
    route: healthcare_space_1.Component_HealthcareSpace.Route,
    entityName: frontend_2.ModuleFE_Value.dbDef.dbKey,
    entityLabel: 'Value',
    groupId: ['Healthcare Space'],
    mapper: (item) => [(0, shared_1.resolveValueDisplay)(item)],
    renderer: (item, selected, onClickCallback) => {
        var _a;
        let hierarchy = 'Healthcare Space';
        let error = false;
        try {
            const variableId = frontend_1.ModuleFE_Variable.getVariableIdForValueId(item._id);
            const variable = frontend_1.ModuleFE_Variable.cache.unique(variableId);
            (_a = variable.tagIds.find(tagGroup => tagGroup.key === _entity_1.ConfigKeyFE_SourceTagId.get())) === null || _a === void 0 ? void 0 : _a.value.forEach(tagId => {
                const tag = _entity_1.ModuleFE_Tag.cache.unique(tagId);
                if (!tag)
                    return;
                hierarchy += ` > ${tag.label}`;
            });
            hierarchy += ` > ${variable.name}`;
        }
        catch (err) {
            error = true;
        }
        return React.createElement(frontend_3.Renderer_SearchResult, { itemId: item._id, itemKey: exports.searchItemKey_Values, itemLabel: (0, shared_1.resolveValueDisplay)(item), entityLabel: 'Value', hierarchy: hierarchy, selected: selected, onClickCallback: onClickCallback, error: error });
    },
    resultRenderer: (item) => {
        try {
            const variableId = frontend_1.ModuleFE_Variable.getVariableIdForValueId(item);
            const variable = frontend_1.ModuleFE_Variable.cache.unique(variableId);
            const tagId = variable.tagIds.find(group => group.key === _entity_1.ConfigKeyFE_SourceTagId.get()).value[0];
            return React.createElement(Manager_HealthcareSpace_1.Manager_HealthcareSpace, { tagId: tagId, varId: variableId });
        }
        catch (err) {
            return React.createElement(ErrorPage_Values_1.ErrorPage_Values, { valueId: item });
        }
    },
    tags: [],
};
shared_1.CollectionEntityMapper.addEntities([
    exports.SearchableItem_Variables,
    exports.SearchableItem_Values,
    exports.SearchableItem_Tags,
]);
