"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Metadata_MetaPathwayAction = exports.metaPathwayAction_Type_LabelMap = void 0;
exports.metaPathwayAction_Type_LabelMap = {
    admit: 'Admit',
    restart: 'Restart',
    discharge: 'Discharge',
    'h&p': 'H&P',
    'consider-protocol': 'Consider Protocol',
    'activate-protocol': 'Activate Protocol',
};
exports.Metadata_MetaPathwayAction = {
    type: {
        optional: false,
        valueType: 'string',
        description: 'The type of the action'
    },
    protocolIds: {
        optional: false,
        valueType: 'string[]',
        description: 'An array of ids pointing to pathways',
        metadata: {
            optional: false,
            valueType: 'string',
            description: 'An id of a pathway',
        }
    }
};
