"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_SubHeader = void 0;
const React = require("react");
require("./Component_SubHeader.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const styles_1 = require("@app/styles");
const dispatchers_1 = require("../../../dispatchers");
class Component_SubHeader extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps) {
        return {};
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    render() {
        return (React.createElement(frontend_1.LL_H_C, { className: 'knowledge-manager__sub-header sub-header__status' },
            React.createElement(frontend_1.LL_H_C, { style: { flexGrow: '1', justifyContent: 'space-between' } }, this.props.children),
            React.createElement(frontend_1.TS_Button, { onClick: () => dispatchers_1.dispatch_onTriggerPanel.dispatchUI() }, "Toggle Conflicts Panel"),
            this.props.cleanWorkspace && React.createElement(styles_1.ICONS.bin.component, { onClick: this.props.cleanWorkspace, className: 'delete-workspace' })));
    }
}
exports.Component_SubHeader = Component_SubHeader;
