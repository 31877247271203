"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalForm_ChestPainForm = exports.ClinicalFormKey_preliminaryChestPainEvaluation = void 0;
const types_1 = require("../types");
// ######################### Section - Description #########################
const subSection_Description = {
    type: types_1.clinicalForm_SubSectionType_Values,
    context: 'chest pain',
    title: 'Chest Pain Description',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'qualitative description',
    valueIds: ['Pressure', 'Squeezing', 'Gripping', 'Heaviness', 'Tightness', 'Crushing',
        'Burning', 'Stabbing', 'Ripping and Tearing', 'Pinching', 'Sharp', 'Discomfort',
        'Dull', 'similar to the pain in a previous heart event']
};
const section_Description = {
    title: 'Description',
    subSections: [
        subSection_Description,
    ],
};
// ######################### Section - Location #########################
const subSection_Location = {
    type: types_1.clinicalForm_SubSectionType_Values,
    context: 'chest pain',
    title: 'Chest Pain Location',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'location',
    valueIds: ['substernal', 'Lt. sided', 'Rt. sided', 'Under the left breast', 'Under the right breast', 'Retrosternal']
};
const subSection_Radiation = {
    type: types_1.clinicalForm_SubSectionType_Values,
    context: 'chest pain',
    title: 'Radiation To (Optional)',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableId: 'radiation',
    valueIds: ['Shoulder', 'Lt. Shoulder', 'Rt. Shoulder', 'Neck', 'Jaw', 'Intrascapular', 'Arms',
        'Lt. Arm', 'Rt. Arm', 'Back', 'Upper Back', 'Epigastric Area']
};
const section_Location = {
    title: 'Location',
    subSections: [
        subSection_Location,
        subSection_Radiation
    ]
};
// ######################### Section - Factors #########################
const subSection_PrecipitatingFactors = {
    type: types_1.clinicalForm_SubSectionType_Variables,
    title: 'Precipitating Factors',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableIds: ['physical exertion', 'emotional distress', 'exposure to cold', 'heavy meal', 'cocaine use', 'illicit drug use'],
};
const subSection_RelievingFactors = {
    type: types_1.clinicalForm_SubSectionType_Variables,
    title: 'Relieving Factors',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableIds: ['rest', 'Nitroglycerin'],
};
const section_Factors = {
    title: 'Factors',
    subSections: [
        subSection_PrecipitatingFactors,
        subSection_RelievingFactors
    ],
};
// ######################### Section - Impression #########################
const subSection_ClinicalImpressions = {
    type: types_1.clinicalForm_SubSectionType_Variables,
    title: 'Clinical Impression',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableIds: ['cardiac chest pain', 'possible cardiac chest pain', 'non cardiac chest pain'],
};
const section_Impression = {
    title: 'Impression',
    subSections: [
        subSection_ClinicalImpressions,
    ],
};
// ######################### Section - Events #########################
const subSection_RecentEvents = {
    type: types_1.clinicalForm_SubSectionType_Variables,
    title: 'Recent Events',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableIds: ['recent surgery', 'Cocaine use', 'sickle cell disease', 'recent air travel', 'infectious disease',
        'traumatic injury', 'immobilization', 'fever', 'calf pain', 'calf swelling', 'hemoptysis', 'productive cough',
        'non productive cough']
};
const section_Events = {
    title: 'Events',
    subSections: [subSection_RecentEvents],
};
// ######################### Section - History #########################
const subSection_RelevantHistory = {
    type: types_1.clinicalForm_SubSectionType_Variables,
    title: 'Relevant History',
    selectionType: types_1.clinicalForm_SelectionType_Many,
    variableIds: ['family history of sickle cell', 'Family history of cardiovascular disease']
};
const section_History = {
    title: 'History',
    subSections: [subSection_RelevantHistory],
};
// ######################### Form #########################
exports.ClinicalFormKey_preliminaryChestPainEvaluation = 'preliminary-chest-pain-evaluation';
exports.ClinicalForm_ChestPainForm = {
    title: 'Chest Pain Form',
    key: exports.ClinicalFormKey_preliminaryChestPainEvaluation,
    contextVariableId: 'chest pain',
    sections: [
        section_Description,
        section_Location,
        section_Factors,
        section_Impression,
        section_Events,
        section_History,
    ],
    _variableIds: [],
    _valueIds: []
};
