"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator_Population = exports.Validator_Population_Specific = exports.Validator_Population_General = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
exports.Validator_Population_General = {
    type: (0, ts_common_1.tsValidateValue)([types_1.PopulationType_General]),
};
exports.Validator_Population_Specific = {
    type: (0, ts_common_1.tsValidateValue)([types_1.PopulationType_Specific]),
    specifications: (0, ts_common_1.tsValidateDynamicObject)(ts_common_1.tsValidateUniqueId, ts_common_1.tsValidateUniqueId)
};
const Validator_Population = (instance) => {
    const error = (0, ts_common_1.tsValidate)(instance, ts_common_1.tsValidateMustExist);
    if (error)
        return error;
    if (instance.type === types_1.PopulationType_General)
        return (0, ts_common_1.tsValidate)(instance, exports.Validator_Population_General);
    if (instance.type === types_1.PopulationType_Specific)
        return (0, ts_common_1.tsValidate)(instance, exports.Validator_Population_Specific);
    // @ts-ignore
    return `Missing validator for population of type ${instance === null || instance === void 0 ? void 0 : instance.type}`;
};
exports.Validator_Population = Validator_Population;
