"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderItem_EditorRenderer = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const frontend_2 = require("@app/styles/frontend");
require("./OrderItem_EditorRenderer.scss");
const _entity_1 = require("../../../../_entity");
const Renderer_ActionData_Order_1 = require("./data-renderers/Renderer_ActionData_Order");
const Renderer_ActionData_Uneditable_1 = require("./data-renderers/Renderer_ActionData_Uneditable");
const frontend_3 = require("@nu-art/permissions/frontend");
const permission_keys_1 = require("@nu-art/permissions/frontend/core/permission-keys");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_4 = require("@app/hcs/frontend");
const utils_1 = require("../../utils");
const RenderKey_Edit = 'edit';
const RenderKey_View = 'view';
class OrderItem_EditorRenderer extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        this.getRenderKey = () => {
            return this.state.editMode ? RenderKey_Edit : RenderKey_View;
        };
        this.renderName = () => {
            const action = this.state.editable;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Order Name"),
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getRenderKey(), modes: [
                        { key: RenderKey_View, renderer: () => React.createElement(frontend_2.DataGrid.Data, null, action.item.name) },
                        {
                            key: RenderKey_Edit,
                            renderer: () => React.createElement(frontend_1.TS_Input, { type: 'text', value: action.item.name, onBlur: async (value) => {
                                    await action.updateObj({ name: value });
                                } })
                        }
                    ] }));
        };
        this.renderType = () => {
            const action = this.state.editable;
            const adapter = (0, frontend_1.SimpleListAdapter)(_entity_1.actionTypes, type => React.createElement(React.Fragment, null, type.item));
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Type"),
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getRenderKey(), modes: [
                        { key: RenderKey_View, renderer: () => React.createElement(frontend_2.DataGrid.Data, null, action.item.type) },
                        {
                            key: RenderKey_Edit,
                            renderer: () => React.createElement(frontend_1.TS_DropDown, { caret: frontend_2.QuaiManager_DropDownCaret, adapter: adapter, selected: action.item.type, disabled: (0, ts_common_1.exists)(action.item._id), onSelected: async (type) => {
                                    if (action.item.type === type)
                                        return;
                                    await action.updateObj({ type: type, subType: undefined });
                                    this.forceUpdate();
                                } })
                        },
                    ] }));
        };
        this.renderSubType = () => {
            const action = this.state.editable;
            if (!action.item.type)
                return;
            const subTypes = _entity_1.ModuleFE_Action.is.order(action.item) ? _entity_1.ActionSubTypes_Order : _entity_1.ActionSubTypes_Interflow;
            const adapter = (0, frontend_1.SimpleListAdapter)([...subTypes], type => React.createElement(React.Fragment, null, type.item));
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_2.DataGrid.Label, null, "Sub Type"),
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getRenderKey(), modes: [
                        {
                            key: RenderKey_View, renderer: () => React.createElement(frontend_2.DataGrid.Data, null, _entity_1.actionSubTypeLabelMap[action.item.subType])
                        },
                        {
                            key: RenderKey_Edit,
                            renderer: () => React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: action.item.subType, caret: frontend_2.QuaiManager_DropDownCaret, onSelected: subType => {
                                    action.updateObj({ subType });
                                } })
                        },
                    ] }));
        };
        this.renderData = () => {
            const action = this.state.editable;
            if (!action.item.type)
                return;
            switch (action.item.type) {
                case 'order': {
                    return React.createElement(Renderer_ActionData_Order_1.Renderer_ActionData_Order, { editable: action, editMode: this.state.editMode });
                }
                default:
                    return;
            }
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Render ########################
    render() {
        const relatedVariables = (0, utils_1.getRelatedVariables)(this.state.editable.item.variableId).map(ts_common_1.dbObjectToId);
        return React.createElement(frontend_1.LL_H_C, { className: 'match_parent', style: { alignItems: 'flex-start', justifyContent: 'space-between', gap: '10px' } },
            React.createElement(frontend_2.DataGrid.Grid, { className: 'editor__order' },
                this.renderName(),
                this.renderType(),
                this.renderSubType(),
                this.renderData()),
            React.createElement(frontend_4.Q_VariablePanel, { filter: (variable) => {
                    return relatedVariables.includes(variable._id);
                }, header: 'Related Variables' }),
            React.createElement(frontend_3.PermissionsComponent, { permissionKey: permission_keys_1.PermissionKeyFE_DeveloperViewer },
                React.createElement(Renderer_ActionData_Uneditable_1.Renderer_ActionData_Uneditable, { editable: this.state.editable })));
    }
}
exports.OrderItem_EditorRenderer = OrderItem_EditorRenderer;
