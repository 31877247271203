"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_ViewConfigEditor = void 0;
const React = __importStar(require("react"));
const hcs_1 = require("@app/hcs");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("@app/hcs/frontend");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../_entity");
class Dialog_ViewConfigEditor extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        // ######################## Logic ########################
        this.saveView = async () => {
            const title = (this.props.view._id ? 'Updating' : 'Creating') + ' View Config';
            await (0, frontend_1.performAction)(async () => {
                this.setState({ dialogIsBusy: true });
                const whitelist = this.convertTagGroupToTagList(this.state.whitelist);
                const blacklist = this.convertTagGroupToTagList(this.state.blacklist);
                this.props.view.filter.tagIds = { whitelist, blacklist };
                try {
                    await _entity_1.ModuleFE_DiseaseView.v1.upsert(this.props.view).executeSync();
                }
                catch (e) {
                    (0, frontend_2.handleError)(e);
                }
                frontend_1.ModuleFE_Dialog.close();
            }, { type: 'notification', notificationLabels: title });
            if (this.mounted)
                this.setState({ dialogIsBusy: false });
        };
        this.deleteView = async () => {
            this.setState({ dialogIsBusy: true });
            await (0, frontend_1.performAction)(async () => {
                await _entity_1.ModuleFE_DiseaseView.v1.delete(this.props.view).executeSync();
                frontend_1.ModuleFE_Dialog.close();
            }, { type: 'notification', notificationLabels: 'Deleting View Config' });
            if (this.mounted)
                this.setState({ dialogIsBusy: false });
        };
    }
    static show(_view) {
        const view = _view ? (0, ts_common_1.cloneObj)(_view) : (0, ts_common_1.cloneObj)(_entity_1.cleanDPView);
        //TODO: this is a correction for views that don't have enforced tags yet, remove eventually
        if (!view.enforcedTags)
            view.enforcedTags = [];
        return frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_ViewConfigEditor, { view: view }), ts_common_1.functionThatReturnsFalse);
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps) {
        const state = this.state ? Object.assign({}, this.state) : {};
        const dvTagId = frontend_2.ConfigKeyFE_DiseasesViewsTagId.get();
        state.viewTagsIds || (state.viewTagsIds = frontend_2.ModuleFE_Tag.cache.filter(item => item.tagIds.includes(dvTagId)).map(ts_common_1.dbObjectToId));
        this.fixViewFilter(nextProps.view);
        state.whitelist || (state.whitelist = this.convertTagListToTagGroup(nextProps.view.filter.tagIds.whitelist));
        state.blacklist || (state.blacklist = this.convertTagListToTagGroup(nextProps.view.filter.tagIds.blacklist));
        return state;
    }
    fixViewFilter(view) {
        if (!view.filter)
            view.filter = { tagIds: {}, roles: [] };
        if (!view.filter.tagIds)
            view.filter.tagIds = {};
        if (!view.filter.tagIds.whitelist)
            view.filter.tagIds.whitelist = [];
        if (!view.filter.tagIds.blacklist)
            view.filter.tagIds.blacklist = [];
        if (!view.filter.roles)
            view.filter.roles = [];
    }
    convertTagListToTagGroup(tagList) {
        const map = tagList.reduce((acc, tagId) => {
            const tag = frontend_2.ModuleFE_Tag.cache.unique(tagId);
            if (!tag)
                throw new ts_common_1.BadImplementationException(`Could not find tag for id ${tagId}`);
            if (!acc[tag.tagIds[0]])
                acc[tag.tagIds[0]] = [];
            acc[tag.tagIds[0]].push(tag);
            return acc;
        }, {});
        return (0, ts_common_1._keys)(map).reduce((acc, key) => {
            const tag = frontend_2.ModuleFE_Tag.cache.unique(key);
            if (!tag)
                throw new ts_common_1.BadImplementationException(`Could not find tag for id ${key}`);
            acc.push({ key: tag, value: map[key] });
            return acc;
        }, []);
    }
    convertTagGroupToTagList(tagGroup) {
        return tagGroup.reduce((acc, group) => {
            const tagIds = group.value.map(tag => tag._id);
            acc.push(...tagIds);
            return (0, ts_common_1.filterDuplicates)(acc);
        }, []);
    }
    // ######################## Render ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'dialog' },
            this.renderHeader(),
            this.renderMain(),
            this.renderButtons());
    }
    renderHeader() {
        return React.createElement(frontend_1.LL_H_C, { className: "dialog__header" },
            React.createElement("span", { className: "title-text" }, (this.props.view._id ? 'Edit' : 'Add') + 'View' + ` - ${this.props.view.label}`),
            React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => frontend_1.ModuleFE_Dialog.close() }));
    }
    renderDeleteButton() {
        if (!this.props.view._id)
            return '';
        return React.createElement(frontend_1.TS_BusyButton, { onClick: this.deleteView, disabled: this.state.dialogIsBusy, className: 'delete-button' }, "Delete");
    }
    renderButtons() {
        return React.createElement(frontend_1.LL_H_C, { className: "dialog__buttons" },
            this.renderDeleteButton(),
            React.createElement(frontend_1.TS_Button, { onClick: () => frontend_1.ModuleFE_Dialog.close() }, "Cancel"),
            React.createElement(frontend_1.TS_BusyButton, { onClick: this.saveView, disabled: this.state.dialogIsBusy, className: 'desired-action-button' }, "Save"));
    }
    // ######################## Render - Main ########################
    renderMain() {
        return React.createElement(frontend_1.LL_H_C, { className: 'dialog__main', style: { gap: '16px' } },
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section' },
                this.renderLabel(),
                this.renderEnforcedRole(),
                this.renderWhitelist()),
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section' },
                this.renderType(),
                this.renderEnforcedTags(),
                this.renderRoleFilter(),
                this.renderBlacklist()));
    }
    renderLabel() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Label', className: 'match_width' },
            React.createElement(frontend_1.TS_Input, { type: 'text', value: this.props.view.label, onChange: val => {
                    this.props.view.label = val;
                    this.forceUpdate();
                } }));
    }
    renderType() {
        const adapter = (0, frontend_1.SimpleListAdapter)([..._entity_1.dpViewTypes], item => React.createElement("div", { className: 'node-data' }, item.item));
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Type', className: 'match_width' },
            React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: this.props.view.type, onSelected: val => {
                    this.props.view.type = val;
                    this.forceUpdate();
                } }));
    }
    renderEnforcedRole() {
        const adapter = (0, frontend_1.SimpleListAdapter)([...hcs_1.roles], item => React.createElement("div", { className: 'node-data' }, item.item));
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Enforced Role', className: 'match_width' },
            React.createElement(frontend_1.TS_DropDown, { adapter: adapter, selected: this.props.view.enforcedRole, onSelected: val => {
                    this.props.view.enforcedRole = val;
                    this.forceUpdate();
                } }));
    }
    renderEnforcedTags() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Enforced Tag', className: 'match_width' },
            React.createElement(frontend_2.HCSDropDowns.Tag, { className: 'tag-dropdown', queryFilter: item => this.state.viewTagsIds.includes(item._id), selected: this.props.view.enforcedTags[0], onSelected: tag => {
                    this.props.view.enforcedTags[0] = tag._id;
                    this.forceUpdate();
                } }));
    }
    renderWhitelist() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { className: 'match_width', label: 'Whitelist' },
            React.createElement(frontend_2.Component_TagEditor, { tags: this.state.whitelist, groupTagFilter: () => true, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id }));
    }
    renderBlacklist() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { className: 'match_width', label: 'Blacklist' },
            React.createElement(frontend_2.Component_TagEditor, { tags: this.state.blacklist, groupTagFilter: () => true, singleTagFilter: (tag, groupTag) => tag.tagIds[0] === groupTag._id }));
    }
    renderRoleFilter() {
        return React.createElement(frontend_1.TS_PropRenderer.Vertical, { className: 'match_width', label: 'Filter Roles' },
            React.createElement(frontend_1.LL_H_C, null, hcs_1.roles.map(role => {
                var _a;
                return React.createElement(frontend_1.TS_PropRenderer.Horizontal, { label: role },
                    React.createElement(frontend_2.Q_Checkbox, { checked: (_a = this.props.view.filter.roles) === null || _a === void 0 ? void 0 : _a.includes(role), onCheck: () => {
                            var _a;
                            const index = (_a = this.props.view.filter.roles) === null || _a === void 0 ? void 0 : _a.indexOf(role);
                            if (index === -1)
                                this.props.view.filter.roles.push(role);
                            else {
                                (0, ts_common_1.removeFromArrayByIndex)(this.props.view.filter.roles, index);
                            }
                            this.forceUpdate();
                        } }));
            })));
    }
}
exports.Dialog_ViewConfigEditor = Dialog_ViewConfigEditor;
