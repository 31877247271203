"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_Interflow = exports.Props_InterflowDropDown = exports.MultiSelect_Action = exports.DropDown_Action = exports.Props_ActionDropDown = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const ModuleFE_Action_1 = require("./ModuleFE_Action");
const consts_1 = require("@app/styles/frontend/ui/consts");
exports.Props_ActionDropDown = {
    module: ModuleFE_Action_1.ModuleFE_Action,
    modules: [ModuleFE_Action_1.ModuleFE_Action],
    mapper: item => [item.name],
    placeholder: 'Choose an action',
    renderer: item => React.createElement("div", { className: "node-data" }, item.name)
};
exports.DropDown_Action = frontend_1.GenericDropDownV3.prepare(exports.Props_ActionDropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Action_1.ModuleFE_Action,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.name);
    },
    uiSelector: exports.DropDown_Action.selectable,
});
exports.MultiSelect_Action = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
exports.Props_InterflowDropDown = {
    module: ModuleFE_Action_1.ModuleFE_Action,
    modules: [ModuleFE_Action_1.ModuleFE_Action],
    mapper: item => [item.name],
    placeholder: 'Choose An Interflow',
    caret: consts_1.DropDown_DefaultCaret,
    queryFilter: (item) => item.type === 'interflow',
    renderer: item => React.createElement("div", { className: "node-data" }, item.name)
};
exports.DropDown_Interflow = frontend_1.GenericDropDownV3.prepareEditable(exports.Props_InterflowDropDown);
