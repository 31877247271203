"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pathway_GraphView = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_graphviz_1 = require("ts-graphviz");
const shared_1 = require("../../../shared");
const ts_common_1 = require("@nu-art/ts-common");
require("./Pathway_GraphView.scss");
const d3_graphviz_1 = require("d3-graphviz");
const dispatchers_1 = require("../Page_PathwayEditor/dispatchers");
const _entity_1 = require("../../_entity");
const util_1 = require("./util");
const d3 = require("d3");
const Component_FocusedEntityRef_1 = require("@nu-art/ts-focused-object/frontend/components/Component_FocusedEntityRef");
const newItemId = '##NEW##';
const RectMaxWidth = 200;
class Pathway_GraphView extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Properties ########################
        super(...arguments);
        this.graphContainerRef = React.createRef();
        this.nodeClass = 'pathway-graph-view__pathway-state';
        this.transitionNodeClass = 'pathway-graph-view__transition-node';
        this.edgeClass = 'pathway-graph-view__pathway-transition';
        this.__onPathwayStateRealtimeChanges = () => {
            this.forceUpdate();
        };
        // ######################## Logic - Graph Manipulation ########################
        this.setSelectedById = (id, action) => {
            const element = document.getElementById(id);
            if (!element)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find element with id ${id}`);
            element.classList[action]('selected');
        };
        // ######################## Logic - Graph Creation ########################
        this.generateId = (prefix, id, suffix) => {
            let str = `${prefix}${id}`;
            if (suffix)
                str += `${suffix}`;
            return str;
        };
        this.generateItemsUpdatedMap = (items) => {
            return items.reduce((acc, curr) => {
                acc[curr._id] = curr.__updated;
                return acc;
            }, {});
        };
        this.getGraphString = () => {
            var _a, _b;
            const graph = new ts_graphviz_1.Digraph('', { bgcolor: 'transparent' });
            const subgraph = new ts_graphviz_1.Subgraph('Container');
            graph.addSubgraph(subgraph);
            const stateNodes = {};
            //Create State Nodes
            this.state.pathwayStates.forEach(pathwayState => {
                const pathwayStateNode = this.getPathwayStateNode(pathwayState);
                stateNodes[pathwayState._id] = pathwayStateNode;
                subgraph.addNode(pathwayStateNode);
            });
            //Create Transitions
            this.state.pathwayTransitions.forEach(pathwayTransition => {
                const midNode = this.getTransitionNode(pathwayTransition);
                const pathwayTransitionEdges = this.getPathwayTransitionNode(pathwayTransition, stateNodes, midNode);
                if (!pathwayTransitionEdges)
                    return;
                if (this.state.transitionMidPoints) { //If rendering with mid points
                    subgraph.addNode(midNode);
                    subgraph.addEdge(pathwayTransitionEdges[0]);
                    subgraph.addEdge(pathwayTransitionEdges[1]);
                }
                else {
                    subgraph.addEdge(pathwayTransitionEdges[0]);
                }
            });
            //Render the selected pathwayState if we didn't get it from the cache (new state)
            const selectedState = (_a = this.state.editablePathwayState) === null || _a === void 0 ? void 0 : _a.item;
            if (selectedState) {
                const existing = this.state.pathwayStates.find(ps => ps._id === selectedState._id);
                if (!existing) {
                    const newStateNode = this.getPathwayStateNode(selectedState);
                    subgraph.addNode(newStateNode);
                }
            }
            //Render the selected pathwayTransition if we didn't get it from the cache (new transition)
            const selectedTransition = (_b = this.state.editablePathwayTransition) === null || _b === void 0 ? void 0 : _b.item;
            if (selectedTransition && (!(selectedTransition === null || selectedTransition === void 0 ? void 0 : selectedTransition._id) || !this.state.pathwayTransitions.find(pt => pt._id === selectedTransition._id))) {
                const midNode = this.getTransitionNode(selectedTransition);
                const pathwayTransitionEdges = this.getPathwayTransitionNode(selectedTransition, stateNodes, midNode);
                if (pathwayTransitionEdges) {
                    if (this.state.transitionMidPoints) {
                        subgraph.addNode(midNode);
                        subgraph.addEdge(pathwayTransitionEdges[0]);
                        subgraph.addEdge(pathwayTransitionEdges[1]);
                    }
                    else {
                        subgraph.addEdge(pathwayTransitionEdges[0]);
                    }
                }
            }
            return (0, ts_graphviz_1.toDot)(graph);
        };
        this.getTransitionNode = (transition) => {
            var _a, _b;
            const id = this.generateId(util_1.IDGeneratorPrefix_TransitionPoint, (_a = transition._id) !== null && _a !== void 0 ? _a : newItemId);
            return new ts_graphviz_1.Node(id, {
                id: id,
                shape: 'circle',
                width: 0.2,
                label: '',
                style: 'filled',
                fillcolor: '#ffffff',
                class: this.transitionNodeClass,
                tooltip: (_b = transition.label) !== null && _b !== void 0 ? _b : 'New Transition'
            });
        };
        this.getPathwayStateNode = (_pathwayState) => {
            var _a, _b, _c, _d;
            const selected = _pathwayState._id === ((_a = this.state.editablePathwayState) === null || _a === void 0 ? void 0 : _a.item._id);
            const selectedTransition = !!this.state.editablePathwayTransition;
            const pathwayState = selected ? (_b = this.state.editablePathwayState) === null || _b === void 0 ? void 0 : _b.item : _pathwayState;
            const className = (0, frontend_1._className)(this.nodeClass, selected && !selectedTransition && 'selected');
            const id = this.generateId(util_1.IDGeneratorPrefix_State, (_c = pathwayState._id) !== null && _c !== void 0 ? _c : newItemId);
            return new ts_graphviz_1.Node(id, {
                id: id,
                shape: 'rect',
                style: 'filled',
                label: this.createHtmlLabel(pathwayState.name),
                fillcolor: '#ffffff',
                fontsize: 13,
                fontcolor: '#333333',
                tooltip: (_d = _pathwayState.name) !== null && _d !== void 0 ? _d : 'New State',
                class: className,
            });
        };
        this.getPathwayTransitionNode = (_pathwayTransition, stateNodes, midNode) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const selected = _pathwayTransition._id === ((_a = this.state.editablePathwayTransition) === null || _a === void 0 ? void 0 : _a.item._id);
            const pathwayTransition = selected ? this.state.editablePathwayTransition.item : _pathwayTransition;
            const sourceId = (_b = pathwayTransition.sourceIds) === null || _b === void 0 ? void 0 : _b[0];
            const targetId = (_c = pathwayTransition.targetIds) === null || _c === void 0 ? void 0 : _c[0];
            const className = (0, frontend_1._className)(this.edgeClass, selected && 'selected');
            //Could not find a source or target id
            if (!sourceId || !targetId) {
                //If no id (new transition) that's okay, return
                if (!pathwayTransition._id)
                    return;
                //id exists, this is a problem
                throw new ts_common_1.MUSTNeverHappenException(`Trying to render a pathway transition ${pathwayTransition._id} with no source or target`);
            }
            const origin = stateNodes[sourceId];
            if (!origin)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find an origin with id ${(_d = pathwayTransition.sourceIds) === null || _d === void 0 ? void 0 : _d[0]}`);
            const target = stateNodes[targetId];
            if (!target)
                throw new ts_common_1.MUSTNeverHappenException(`Could not find an target with id ${(_e = pathwayTransition.targetIds) === null || _e === void 0 ? void 0 : _e[0]}`);
            if (this.state.transitionMidPoints) {
                const startId = this.generateId(util_1.IDGeneratorPrefix_Transition, (_f = pathwayTransition._id) !== null && _f !== void 0 ? _f : newItemId, util_1.IDGeneratorSuffix_Start);
                const endId = this.generateId(util_1.IDGeneratorPrefix_Transition, (_g = pathwayTransition._id) !== null && _g !== void 0 ? _g : newItemId, util_1.IDGeneratorSuffix_End);
                return [new ts_graphviz_1.Edge([origin, midNode], {
                        class: className,
                        arrowhead: 'none',
                        id: startId
                    }), new ts_graphviz_1.Edge([midNode, target], { class: className, id: endId })];
            }
            const id = this.generateId(util_1.IDGeneratorPrefix_Transition, (_h = pathwayTransition._id) !== null && _h !== void 0 ? _h : newItemId);
            return [new ts_graphviz_1.Edge([origin, target], { class: className, id: id })];
        };
        // ######################## Logic ########################
        this.convertPolygonsToRects = () => {
            const nodes = Array.from(document.getElementsByClassName(this.nodeClass));
            nodes.forEach(node => {
                const polygon = node.querySelector('polygon');
                if (!polygon)
                    return;
                const rectData = this.getRectDataFromPolygon(polygon);
                const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                rect.setAttribute('width', rectData.width);
                rect.setAttribute('height', rectData.height);
                rect.setAttribute('rx', '4');
                rect.setAttribute('ry', '4');
                rect.setAttribute('fill', '#fff');
                rect.setAttribute('x', rectData.x);
                rect.setAttribute('y', rectData.y);
                rect.setAttribute('stroke', '#4f4f4f');
                rect.setAttribute('stroke-width', '2');
                polygon.replaceWith(rect);
            });
        };
        this.createHtmlLabel = (text, maxWidth = RectMaxWidth) => {
            const textWidth = this.measurementCanvas.measureText(text).width + 25;
            const width = Math.min(Math.max(textWidth, 100), maxWidth);
            const wrappedText = text.replace(new RegExp(`(.{1,${Math.floor(width / 6)}})(\\s|$)`, 'g'), '$1<br/>');
            return `<
			<table border="0" cellborder="0" cellpadding="0" cellspacing="0" width="${width}">
			  <tr>
				<td style="padding: 5px 10px; min-width: 100px;" width="${width}" fixedsize="true" valign="middle" align="center">${wrappedText}</td>
			  </tr>
			</table>
  		>`;
        };
        this.getRectDataFromPolygon = (polygon) => {
            const points = (0, ts_common_1._values)(polygon.points);
            const minX = Math.min(...points.map(point => point.x));
            const maxX = Math.max(...points.map(point => point.x));
            const minY = Math.min(...points.map(point => point.y));
            const maxY = Math.max(...points.map(point => point.y));
            const data = {
                x: String(minX),
                y: String(minY),
                width: String(maxX - minX),
                height: String(maxY - minY),
            };
            return data;
        };
        this.setStateOnClick = () => {
            const states = d3.select(this.graphContainerRef.current).selectAll(`.${this.nodeClass}`);
            states.on('click', (e) => {
                const nodeId = e.currentTarget.id.replace(util_1.IDGeneratorPrefix_State, '');
                if (nodeId === newItemId)
                    return;
                dispatchers_1.dispatch_PathwayStateSelected.dispatchUI(nodeId);
            });
        };
        this.setTransitionOnClick = () => {
            const transitions = d3.select(this.graphContainerRef.current).selectAll(`.${this.transitionNodeClass}`);
            transitions.on('click', (e) => {
                const transitionId = e.currentTarget.id
                    .replace(util_1.IDGeneratorPrefix_TransitionPoint, '');
                if (transitionId === newItemId)
                    return;
                dispatchers_1.dispatch_PathwayTransitionSelected.dispatchUI(transitionId);
            });
        };
        this.renderGraph = () => {
            const graphString = this.getGraphString();
            const rect = this.graphContainerRef.current.getBoundingClientRect();
            const subGraph = document.getElementById('graph0');
            if (subGraph)
                subGraph.innerHTML = '';
            (0, d3_graphviz_1.graphviz)(this.graphContainerRef.current, {
                useWorker: false,
                zoomScaleExtent: [0.5, 2],
                width: rect.width,
                height: rect.height
            })
                .renderDot(graphString)
                .on('renderEnd', () => {
                this.convertPolygonsToRects();
                this.setStateOnClick();
                this.setTransitionOnClick();
            });
        };
        this.renderFocusUsers = () => {
            return React.createElement(Component_FocusedEntityRef_1.Component_FocusedEntityRef, { focusedEntities: [{ dbKey: shared_1.DBDef_Pathway.dbKey, itemId: this.state.pathwayId }] });
        };
        this.renderTransitionAB = () => {
            return React.createElement(frontend_1.TS_Checkbox, { className: 'pathway-graph-view__a-b', checked: this.state.transitionMidPoints, onCheck: () => this.setState({ transitionMidPoints: !this.state.transitionMidPoints }) }, "Show Transitions");
        };
    }
    // ######################## Lifecycle ########################
    __onPathwayTransitionUpdated(...params) {
        if (!this.props.pathwayId)
            return;
        (0, util_1.updateOnSync)(this.props.pathwayId, params, 'pathwayId', () => this.reDeriveState(), () => this.reDeriveState());
    }
    __onPathwayUpdated(...params) {
        if (!this.props.pathwayId)
            return;
        (0, util_1.updateOnSync)(this.props.pathwayId, params, '_id', () => this.reDeriveState(), ts_common_1.voidFunction);
    }
    __onPathwayStateUpdated(...params) {
        if (!this.props.pathwayId)
            return;
        (0, util_1.updateOnSync)(this.props.pathwayId, params, 'pathwayId', () => this.reDeriveState(), () => this.reDeriveState());
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state.pathwayId = nextProps.pathwayId;
        state.editablePathwayState = nextProps.editablePathwayState;
        state.editablePathwayTransition = nextProps.editablePathwayTransition;
        state.pathwayStates = _entity_1.ModuleFE_PathwayState.cache.filter(ps => ps.pathwayId === nextProps.pathwayId);
        state.pathwayTransitions = _entity_1.ModuleFE_PathwayTransition.cache.filter(pt => pt.pathwayId === nextProps.pathwayId);
        (_a = state.transitionMidPoints) !== null && _a !== void 0 ? _a : (state.transitionMidPoints = true);
        if (!this.measurementCanvas) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = '12px Arial';
            this.measurementCanvas = context;
        }
        return state;
    }
    componentDidMount() {
        if (!this.graphContainerRef.current) {
            this.logWarning('Finished mounting but no ref to container');
            return;
        }
        this.renderGraph();
        this.forceUpdate();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.graphContainerRef.current) {
            this.logWarning('Somehow lost container ref between renders');
            return;
        }
        this.renderGraph();
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        //First render, of course it should
        if (!this.state)
            return true;
        //Pathway Changed, so graph needs re-rendering
        if (this.state.pathwayId !== nextState.pathwayId)
            return true;
        //Transition render method changed, needs re-render
        if (this.state.transitionMidPoints !== nextState.transitionMidPoints)
            return true;
        //State have changes
        if (!(0, ts_common_1.compare)(this.generateItemsUpdatedMap(nextState.pathwayStates), this.generateItemsUpdatedMap(this.state.pathwayStates)))
            return true;
        //Transitions have changes
        if (!(0, ts_common_1.compare)(this.generateItemsUpdatedMap(nextState.pathwayTransitions), this.generateItemsUpdatedMap(this.state.pathwayTransitions)))
            return true;
        let shouldUpdate = false;
        //Selected pathway state has changed
        if (!(0, ts_common_1.compare)((_a = this.state.editablePathwayState) === null || _a === void 0 ? void 0 : _a.item, (_b = nextState.editablePathwayState) === null || _b === void 0 ? void 0 : _b.item)) {
            const lastPSId = (_c = this.state.editablePathwayState) === null || _c === void 0 ? void 0 : _c.item._id;
            const nextPSId = (_d = nextState.editablePathwayState) === null || _d === void 0 ? void 0 : _d.item._id;
            const lastPSWasNew = (0, ts_common_1.exists)(this.state.editablePathwayState) && !lastPSId;
            const nextPSIsNew = (0, ts_common_1.exists)(nextState.editablePathwayState) && !nextPSId;
            //If the last or next pathway state are new the graph needs to re-render
            const lastSelectedStateExists = _entity_1.ModuleFE_PathwayState.cache.unique((_e = nextState.editablePathwayState) === null || _e === void 0 ? void 0 : _e.item._id);
            if (lastPSWasNew || nextPSIsNew || !lastSelectedStateExists)
                shouldUpdate = true;
            //Clear styling on last selected pathway state
            if (lastPSId)
                this.setSelectedById(this.generateId(util_1.IDGeneratorPrefix_State, lastPSId), 'remove');
            //Add styling on new selected state
            if (nextPSId)
                this.setSelectedById(this.generateId(util_1.IDGeneratorPrefix_State, nextPSId), 'add');
        }
        //Selected pathway transition has changed
        if (!(0, ts_common_1.compare)((_f = this.state.editablePathwayTransition) === null || _f === void 0 ? void 0 : _f.item, (_g = nextState.editablePathwayTransition) === null || _g === void 0 ? void 0 : _g.item)) {
            const lastPTId = (_h = this.state.editablePathwayTransition) === null || _h === void 0 ? void 0 : _h.item._id;
            const nextPTId = (_j = nextState.editablePathwayTransition) === null || _j === void 0 ? void 0 : _j.item._id;
            const lastPTWasNew = (0, ts_common_1.exists)(this.state.editablePathwayTransition) && !lastPTId;
            const nextPTIsNew = (0, ts_common_1.exists)(nextState.editablePathwayTransition) && !nextPTId;
            //If the last or next pathway transition are new the graph needs to re-render
            const lastSelectedTransitionExists = _entity_1.ModuleFE_PathwayTransition.cache.unique((_k = nextState.editablePathwayTransition) === null || _k === void 0 ? void 0 : _k.item._id);
            if (lastPTWasNew || nextPTIsNew || !lastSelectedTransitionExists)
                shouldUpdate = true;
            //Clear styling on last selected pathway transition
            if (lastPTId && this.state.transitionMidPoints) {
                this.setSelectedById(this.generateId(util_1.IDGeneratorPrefix_Transition, lastPTId, util_1.IDGeneratorSuffix_Start), 'remove');
                this.setSelectedById(this.generateId(util_1.IDGeneratorPrefix_Transition, lastPTId, util_1.IDGeneratorSuffix_End), 'remove');
            }
            //Add styling on new selected transition
            if (nextPTId) {
                this.setSelectedById(this.generateId(util_1.IDGeneratorPrefix_Transition, nextPTId, util_1.IDGeneratorSuffix_Start), 'add');
                this.setSelectedById(this.generateId(util_1.IDGeneratorPrefix_Transition, nextPTId, util_1.IDGeneratorSuffix_End), 'add');
            }
        }
        return shouldUpdate;
    }
    // ######################## Render ########################
    render() {
        return React.createElement("div", { className: 'pathway-graph-view', ref: this.graphContainerRef },
            this.renderFocusUsers(),
            this.renderTransitionAB());
    }
}
exports.Pathway_GraphView = Pathway_GraphView;
