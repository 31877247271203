"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_Resource = exports.DropDown_ResourceType = exports.DropDown_Resource = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const shared_1 = require("../shared");
const ModuleFE_Resource_1 = require("./ModuleFE_Resource");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const styles_1 = require("@app/styles");
const consts_1 = require("@app/styles/frontend/ui/consts");
const Props_DropDown = {
    module: ModuleFE_Resource_1.ModuleFE_Resource,
    modules: [ModuleFE_Resource_1.ModuleFE_Resource],
    mapper: item => [item.label],
    placeholder: 'Choose a Resource',
    renderer: item => React.createElement(React.Fragment, null, item.label)
};
exports.DropDown_Resource = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_ResourceTypeDD = {
    adapter: (0, frontend_1.SimpleListAdapter)(shared_1.ResourceTypesArray, item => React.createElement(React.Fragment, null, item.item)),
    placeholder: 'Select Resource Type',
    caret: consts_1.DropDown_DefaultCaret
};
exports.DropDown_ResourceType = frontend_1.TS_DropDown.prepare(Props_ResourceTypeDD);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Resource_1.ModuleFE_Resource,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }),
            item.label);
    },
    uiSelector: exports.DropDown_Resource.selectable,
});
exports.MultiSelect_Resource = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
