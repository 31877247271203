"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reference_isICD = exports.reference_isUpToDate = exports.reference_isArticle = exports.reference_isBook = exports.reference_isLoinc = exports.reference_isSnomed = void 0;
const reference_isSnomed = (reference) => {
    return (reference === null || reference === void 0 ? void 0 : reference.type) === 'snomed';
};
exports.reference_isSnomed = reference_isSnomed;
const reference_isLoinc = (reference) => {
    return (reference === null || reference === void 0 ? void 0 : reference.type) === 'loinc';
};
exports.reference_isLoinc = reference_isLoinc;
const reference_isBook = (reference) => {
    return (reference === null || reference === void 0 ? void 0 : reference.type) === 'book';
};
exports.reference_isBook = reference_isBook;
const reference_isArticle = (reference) => {
    return (reference === null || reference === void 0 ? void 0 : reference.type) === 'article';
};
exports.reference_isArticle = reference_isArticle;
const reference_isUpToDate = (reference) => {
    return (reference === null || reference === void 0 ? void 0 : reference.type) === 'upToDate';
};
exports.reference_isUpToDate = reference_isUpToDate;
const reference_isICD = (reference) => {
    return (reference === null || reference === void 0 ? void 0 : reference.type) === 'icdCode';
};
exports.reference_isICD = reference_isICD;
