"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Manager_DiseaseProfile = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../../shared");
const styles_1 = require("@app/styles");
require("./Manager_DiseaseProfile.scss");
const printable_1 = require("../printable");
const frontend_2 = require("@app/hcs/frontend");
const Renderer_GeneralInfo_1 = require("./renderers/Renderer_GeneralInfo");
const Renderer_DiseaseVariableData_1 = require("./renderers/Renderer_DiseaseVariableData");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const _entity_1 = require("../../_entity");
const varOptions = ['Overview', 'Disease Info', 'Snomed Info', 'Epidemiology', 'Prognosis', 'Scores', 'References'];
const editModeVarOptions = ['Overview', 'Epidemiology', 'Prognosis', 'Scores', 'References'];
class Manager_DiseaseProfile extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.DP_PermissionKey_Edit = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getPermissionKey(shared_1.PermissionKey_DiseaseProfile_Edit);
        this.DP_PermissionKey_Delete = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getPermissionKey(shared_1.PermissionKey_DiseaseProfile_Delete);
        // ######################## Logic ########################
        this.headerAction__ExportPDF = () => {
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
            if (!this.state.printableRef)
                return;
            frontend_1.ModuleFE_Thunderstorm.printDiv(this.state.printableRef, [{ key: 'theme', value: 'light' }]);
        };
        this.headerAction__editDisease = () => {
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
            const varIds = this.state.selectedViewId === 'general' ? editModeVarOptions : this.state.varIds;
            const selectedVarId = varIds.includes(this.state.selectedVarId) ? this.state.selectedVarId : varIds[0];
            this.setState({ editMode: true, varIds, selectedVarId });
        };
        this.headerAction__addVariable = () => {
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
        };
        this.headerAction__PublishDisease = async () => {
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
            const dp = this.state.diseaseProfile;
            this.state.diseaseProfile.set('completed', !dp.item.completed);
            await (0, frontend_1.genericNotificationAction)(async () => {
                await dp.save();
                this.forceUpdate();
            }, {
                inProgress: 'Saving Disease Profile',
                success: 'Save Disease Profile - Success',
                failed: 'Save Disease Profile - Failed',
            });
        };
        this.headerAction__DeleteDisease = () => {
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
        };
        this.headerAction__ExitEdit = () => {
            const varIds = this.state.selectedViewId === 'general' ? varOptions : this.state.varIds;
            this.setState({ editMode: false, varIds });
        };
        this.getOrderedViews = () => {
            const views = _entity_1.ModuleFE_DiseaseView.cache.allMutable();
            const order = _entity_1.ConfigKeyFE_DPViewOrder.get();
            return (0, ts_common_1.sortArray)(views, view => order.indexOf(view._id));
        };
        this.setView = (selectedViewId) => {
            if (selectedViewId === this.state.selectedViewId)
                return;
            let varIds;
            if (selectedViewId === 'general')
                varIds = this.state.editMode ? editModeVarOptions : varOptions;
            else {
                const view = _entity_1.ModuleFE_DiseaseView.cache.unique(selectedViewId);
                varIds = _entity_1.ModuleFE_MultiVar.cache.filter(mv => mv.diseaseProfileId === this.state.diseaseProfile.item._id
                    && _entity_1.ModuleFE_DiseaseView.filterMultiVarByEnforcedTag(view, mv)).map(ts_common_1.dbObjectToId);
            }
            this.setState({
                selectedViewId,
                varIds,
                selectedVarId: varIds[0]
            });
        };
        this.triggerChange = (dp) => {
            if (dp === this.state.diseaseProfile)
                return this.forceUpdate();
            this.setState({ diseaseProfile: dp });
        };
        // ######################## Render - Header ########################
        this.renderHeader = () => {
            const dp = this.state.diseaseProfile.item;
            const editMode = this.state.editMode;
            const className = (0, frontend_1._className)('manager__header', editMode && 'edit-mode');
            return React.createElement(frontend_1.LL_H_C, { className: className },
                React.createElement(frontend_1.LL_V_L, { className: 'manager__header__main' },
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__title' },
                        React.createElement("span", { className: 'manager__header__title__label' }, dp.label),
                        this.renderDPColor(),
                        !editMode &&
                            React.createElement(styles_1.ICONS.more.component, Object.assign({}, frontend_1.openContent.popUp.bottom('disease-profile-manager__header-actions', this.renderHeaderActions)))),
                    React.createElement(frontend_1.LL_H_C, { className: 'manager__header__sub-title' },
                        React.createElement("span", { className: 'manager__header__sub-title__item' }, "Disease"),
                        "in",
                        React.createElement("span", { className: 'manager__header__sub-title__hierarchy' }, "Disease Profiles")),
                    editMode &&
                        React.createElement("div", { className: 'manager__header__exit-edit', onClick: this.headerAction__ExitEdit }, "Exit Edit")));
        };
        this.renderDPColor = () => {
            const dp = this.state.diseaseProfile.item;
            if (!dp.colorGroup)
                return;
            const dpColor = _entity_1.ModuleFE_ColorGroup.cache.unique(dp.colorGroup);
            return React.createElement("span", { className: 'manager__header__title__color', style: { background: dpColor['criticality-00'][0] } },
                React.createElement(styles_1.ICONS.v.component, { className: dp.completed ? 'completed' : undefined }));
        };
        this.renderHeaderActions = () => {
            const dp = this.state.diseaseProfile.item;
            return React.createElement(React.Fragment, null,
                React.createElement("div", { onClick: this.headerAction__ExportPDF }, "Export PDF"),
                ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(this.DP_PermissionKey_Edit) === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess && React.createElement(React.Fragment, null,
                    React.createElement("div", { onClick: this.headerAction__editDisease }, "Edit Disease"),
                    React.createElement("div", { onClick: this.headerAction__addVariable }, "Add Variable")),
                ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAccessLevel(this.DP_PermissionKey_Delete) === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess && React.createElement(React.Fragment, null,
                    React.createElement("div", { onClick: this.headerAction__PublishDisease },
                        dp.completed ? 'Unp' : 'P',
                        "ublish Disease"),
                    React.createElement("div", { onClick: this.headerAction__DeleteDisease }, "Delete Disease")));
        };
        // ######################## Render - Body ########################
        this.renderViews = () => {
            const views = this.getOrderedViews();
            const viewProps = (viewId) => {
                return {
                    className: (0, frontend_1._className)('manager__disease-profile__views__item', viewId === this.state.selectedViewId && 'selected'),
                    onClick: () => this.setView(viewId)
                };
            };
            return React.createElement(frontend_1.LL_V_L, { className: 'manager__disease-profile__views' },
                React.createElement("div", Object.assign({}, viewProps('general')), "General"),
                views.map(view => {
                    return React.createElement("div", Object.assign({ key: view._id }, viewProps(view._id)), view.label);
                }));
        };
        this.renderVars = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'manager__disease-profile__vars' }, this.state.varIds.map(varId => {
                const className = (0, frontend_1._className)('manager__disease-profile__vars__item', this.state.selectedVarId === varId && 'selected');
                return React.createElement(frontend_1.LL_V_L, { key: varId, className: className, onClick: () => this.setState({ selectedVarId: varId }) }, this.renderVarContent(varId));
            }));
        };
        this.renderVarContent = (varId) => {
            var _a, _b, _c, _d;
            const dp = this.state.diseaseProfile.item;
            if (this.state.selectedViewId === 'general')
                return React.createElement(React.Fragment, null, varId);
            const mv = _entity_1.ModuleFE_MultiVar.cache.unique(varId);
            const mainVar = frontend_2.ModuleFE_Variable.cache.unique(mv._firstVarId);
            const attribute = ((_a = mv.variables[1]) === null || _a === void 0 ? void 0 : _a.attributeeId) === mv._firstVarId ? frontend_2.ModuleFE_Variable.cache.unique(mv.variables[1].variableId) : undefined;
            const variantIds = (_c = (_b = mv.variables[0].tagIds) === null || _b === void 0 ? void 0 : _b.find(i => i.key === dp.variantsGroupId)) === null || _c === void 0 ? void 0 : _c.value;
            const ccColor = ((_d = dp.chiefComplaintIds) === null || _d === void 0 ? void 0 : _d.includes(mv._firstVarId)) ? '#fb8c4b' : 'transparent';
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.LL_H_C, { className: 'manager__disease-profile__vars__item__label' },
                    React.createElement("div", { className: 'manager__disease-profile__vars__item__label__cc', style: { background: ccColor } }),
                    mainVar.name,
                    attribute && `.${attribute.name}`),
                variantIds &&
                    React.createElement(frontend_1.LL_V_L, { className: 'manager__disease-profile__vars__item__variants' }, variantIds.map(id => {
                        const tag = frontend_2.ModuleFE_Tag.cache.unique(id);
                        return React.createElement("div", { key: id, className: 'manager__disease-profile__vars__item__variants__item' }, tag.label);
                    })));
        };
        this.renderData = () => {
            if (this.state.selectedViewId === 'general')
                return React.createElement(Renderer_GeneralInfo_1.Renderer_GeneralInfo, { diseaseProfileId: this.state.diseaseProfile.item._id, varOption: this.state.selectedVarId, editMode: this.state.editMode, triggerChange: this.triggerChange });
            return React.createElement(Renderer_DiseaseVariableData_1.Renderer_DiseaseVariableData, { diseaseProfileId: this.state.diseaseProfile.item._id, diseaseVariableId: this.state.selectedVarId, editMode: this.state.editMode, triggerChange: this.triggerChange });
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c, _d, _e;
        const firstDerive = !((_a = this.state) === null || _a === void 0 ? void 0 : _a.diseaseProfile);
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        //Set initial disease profile or if the id has changed
        if (!state.diseaseProfile || state.diseaseProfile.item._id !== nextProps.diseaseProfileId)
            state.diseaseProfile = new frontend_1.EditableDBItemV3(_entity_1.ModuleFE_DiseaseProfile.cache.unique(nextProps.diseaseProfileId), _entity_1.ModuleFE_DiseaseProfile);
        //Set initial edit mode
        (_b = state.editMode) !== null && _b !== void 0 ? _b : (state.editMode = false);
        //Set initial view
        (_c = state.selectedViewId) !== null && _c !== void 0 ? _c : (state.selectedViewId = 'general');
        //Set initial varIds
        (_d = state.varIds) !== null && _d !== void 0 ? _d : (state.varIds = varOptions);
        (_e = state.selectedVarId) !== null && _e !== void 0 ? _e : (state.selectedVarId = state.varIds[0]);
        //If a multiVar id is given
        if (nextProps.multiVarId) {
            //If not the first derive or the multiVar id hasn't changed
            if (!firstDerive && this.props.multiVarId === nextProps.multiVarId)
                return state;
            const views = _entity_1.ModuleFE_DiseaseView.cache.all();
            for (const view of views) {
                const varIds = _entity_1.ModuleFE_MultiVar.cache.filter(mv => mv.diseaseProfileId === state.diseaseProfile.item._id
                    && _entity_1.ModuleFE_DiseaseView.filterMultiVarByEnforcedTag(view, mv)).map(ts_common_1.dbObjectToId);
                //If this view holds the given multiVar id
                if (varIds.includes(nextProps.multiVarId)) {
                    state.selectedViewId = view._id;
                    state.varIds = varIds;
                    state.selectedVarId = nextProps.multiVarId;
                    break;
                }
            }
        }
        return state;
    }
    // ######################## Render - Structure ########################
    render() {
        const dp = this.state.diseaseProfile.item;
        return React.createElement(React.Fragment, null,
            React.createElement(frontend_1.Grid, { id: 'manager__disease-profile', className: 'quai-item-manager' },
                this.renderHeader(),
                this.renderViews(),
                this.renderVars(),
                this.renderData()),
            dp._id &&
                React.createElement(frontend_1.TS_Printable, { printOnly: true, printable: async (printableRef) => this.setState({ printableRef }) },
                    React.createElement(printable_1.Printable_DiseaseProfile, { diseaseProfileId: dp._id })));
    }
}
Manager_DiseaseProfile.defaultProps = {
    modules: [_entity_1.ModuleFE_DiseaseView, _entity_1.ModuleFE_ColorGroup]
};
exports.Manager_DiseaseProfile = Manager_DiseaseProfile;
