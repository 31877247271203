"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_Organization_GeneralInformation = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@app/styles/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const consts_1 = require("../../../consts");
const permission_keys_1 = require("../../../../../permission-keys");
const Component_SwitchView_1 = require("@nu-art/permissions/frontend/ui/Component_SwitchView");
const ui_components_1 = require("../../../../../../_entity/organization/frontend/ui-components");
require("./Editor_Organization_GeneralInformation.scss");
const ui_components_2 = require("@app/styles/frontend/ui-manager/ui-components");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const viewKey = 'view';
const editKey = 'edit';
class Editor_Organization_GeneralInformation extends TS_EditableItemComponent_1.TS_EditableItemComponentProto {
    constructor() {
        // ######################## Lifecycle ########################
        super(...arguments);
        this.permissionKey_OrganizationEdit = permission_keys_1.PermissionKeyFE_OrganizationEdit;
        // ######################## Logic ########################
        this.getCountryOption = (country) => {
            const option = consts_1.countryOptions.find(option => option.country === country);
            if (!option)
                throw new ts_common_1.MUSTNeverHappenException('Chose a country option not from the list???');
            return option;
        };
        this.updateAddress = async (key, value) => {
            var _a;
            const org = this.state.editable;
            const address = org.item.address ? Object.assign({}, org.item.address) : {};
            address[key] = value;
            if (key === 'country') {
                const option = this.getCountryOption(value);
                address['stateRequired'] = !!((_a = option.states) === null || _a === void 0 ? void 0 : _a.length);
                if (!address['stateRequired'])
                    delete address['state'];
            }
            await this.updateOrg('address', address);
        };
        this.updateOrg = async (key, value) => {
            const org = this.state.editable;
            try {
                await org.updateObj({ [key]: value });
            }
            catch (err) {
                this.logError(err);
            }
            finally {
                this.forceUpdate();
            }
        };
        this.getModeKey = () => {
            return this.state.editMode ? editKey : viewKey;
        };
        this.renderName = () => {
            const org = this.state.editable;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Name', error: org.hasError('name') },
                    React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                            { key: viewKey, renderer: () => React.createElement("div", { className: 'grid-data' }, org.item.name) },
                            {
                                key: editKey,
                                permissionKey: this.permissionKey_OrganizationEdit,
                                renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText, { editable: org, prop: 'name', onChange: value => this.updateOrg('name', value) })
                            },
                        ] })));
        };
        this.renderAlias = () => {
            const org = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Alias', error: org.hasError('alias') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                        { key: viewKey, renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = org.item.alias) !== null && _a !== void 0 ? _a : '-'); } },
                        {
                            key: editKey, permissionKey: this.permissionKey_OrganizationEdit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText_Optional, { editable: org, prop: 'alias', onChange: value => this.updateOrg('alias', value) })
                        }
                    ] }));
        };
        this.renderAddress = (addressEditable) => {
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Address', error: addressEditable.hasError('address') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                        {
                            key: viewKey,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = addressEditable.item.address) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: editKey, permissionKey: this.permissionKey_OrganizationEdit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText_Optional, { editable: addressEditable, prop: 'address', onChange: value => this.updateAddress('address', value) })
                        }
                    ] }));
        };
        this.renderCity = (addressEditable) => {
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'City', error: addressEditable.hasError('city') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                        {
                            key: viewKey,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = addressEditable.item.city) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: editKey, permissionKey: this.permissionKey_OrganizationEdit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText, { editable: addressEditable, prop: 'city', onChange: value => this.updateAddress('city', value) })
                        }
                    ] }));
        };
        this.renderZipcode = (addressEditable) => {
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Zip', error: addressEditable.hasError('zipCode') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                        {
                            key: viewKey,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = addressEditable.item.zipCode) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: editKey, permissionKey: this.permissionKey_OrganizationEdit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputNumeric_Optional, { editable: addressEditable, prop: 'zipCode', type: 'number', onChange: value => this.updateAddress('zipCode', Number(value)) })
                        }
                    ] }));
        };
        this.renderWebsite = () => {
            const org = this.state.editable;
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Website', error: org.hasError('webUrl') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                        { key: viewKey, renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = org.item.webUrl) !== null && _a !== void 0 ? _a : '-'); } },
                        {
                            key: editKey, permissionKey: this.permissionKey_OrganizationEdit,
                            renderer: () => React.createElement(ui_components_2.DefaultEditor_InputText, { editable: org, prop: 'webUrl', onChange: value => this.updateOrg('webUrl', value) })
                        }
                    ] }));
        };
        this.renderCountry = (addressEditable) => {
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'Country', error: addressEditable.hasError('country') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                        {
                            key: viewKey,
                            renderer: () => { var _a; return React.createElement("div", { className: 'grid-data' }, (_a = addressEditable.item.country) !== null && _a !== void 0 ? _a : '-'); }
                        },
                        {
                            key: editKey, permissionKey: this.permissionKey_OrganizationEdit,
                            renderer: () => React.createElement(ui_components_1.DropDown_OrgCountry.editable, { editable: addressEditable, prop: 'country', onSelected: async (countryOption) => await this.updateAddress('country', countryOption) })
                        }
                    ] }));
        };
        this.renderState = (addressEditable) => {
            if (!addressEditable.item.stateRequired || !addressEditable.item.country)
                return;
            const countryOption = this.getCountryOption(addressEditable.item.country);
            const adapter = (0, frontend_1.SimpleListAdapter)(countryOption.states, op => { var _a; return React.createElement(React.Fragment, null, (_a = op.item.initials) !== null && _a !== void 0 ? _a : op.item.label); });
            return React.createElement(frontend_1.TS_PropRenderer.Flat, { label: 'State', error: addressEditable.hasError('state') },
                React.createElement(Component_SwitchView_1.Component_SwitchView, { mode: this.getModeKey(), modes: [
                        {
                            key: viewKey,
                            renderer: () => {
                                var _a, _b, _c, _d;
                                return React.createElement("div", { className: 'grid-data' }, (_d = (_b = (_a = addressEditable.item.state) === null || _a === void 0 ? void 0 : _a.initials) !== null && _b !== void 0 ? _b : (_c = addressEditable.item.state) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : '-');
                            }
                        },
                        {
                            key: editKey, permissionKey: this.permissionKey_OrganizationEdit,
                            renderer: () => React.createElement(frontend_1.TS_DropDown, { caret: frontend_2.QuaiManager_DropDownCaret, adapter: adapter, selected: addressEditable.item.state, onSelected: async (state) => await this.updateAddress('state', state) })
                        }
                    ] }));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Render ########################
    render() {
        const className = (0, frontend_1._className)(this.state.editMode && 'edit-mode');
        const addressEditable = this.state.editable.editProp('address', {});
        return React.createElement(frontend_1.LL_V_L, { id: 'editor-organization-general-information', className: className },
            React.createElement("div", { className: 'editor-organization__info-title' }, "General Information"),
            React.createElement(frontend_1.Grid, null,
                this.renderName(),
                this.renderAlias(),
                this.renderAddress(addressEditable),
                this.renderCity(addressEditable),
                this.renderState(addressEditable),
                this.renderZipcode(addressEditable),
                this.renderCountry(addressEditable),
                this.renderWebsite()));
    }
}
exports.Editor_Organization_GeneralInformation = Editor_Organization_GeneralInformation;
