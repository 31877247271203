"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Action = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const consts_1 = require("./consts");
const utils_1 = require("./utils");
const hcs_1 = require("@app/hcs");
const Validator_Action_General = {
    //General
    name: (0, ts_common_1.tsValidateString)(),
    properties: (0, ts_common_1.tsValidateDynamicObject)(ts_common_1.tsValidateUniqueId, ts_common_1.tsValidateUniqueId, false),
    variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    resolution: (0, ts_common_1.tsValidateValue)([...consts_1.resolutionTypes]),
    resolutionDialog: (0, ts_common_1.tsValidateNonMandatoryObject)({
        clinicalFormKey: (0, ts_common_1.tsValidateString)(),
        isOrderSpecific: (0, ts_common_1.tsValidateBoolean)()
    }),
    resolutionFindings: (0, ts_common_1.tsValidateArray)({ valueId: ts_common_1.tsValidateUniqueId, variableId: ts_common_1.tsValidateUniqueId }, false),
    metadata: (0, ts_common_1.tsValidateNonMandatoryObject)({
        orderType: (0, ts_common_1.tsValidateValue)([...consts_1.orderTypes], false),
        disposition: (0, ts_common_1.tsValidateBoolean)(false),
        inbox: (0, ts_common_1.tsValidateNonMandatoryObject)({
            shouldNotAppear: (0, ts_common_1.tsValidateBoolean)(false),
            canNurseResolve: (0, ts_common_1.tsValidateBoolean)(false),
        }),
    }),
};
const Validator_Action_Order = Object.assign(Object.assign({}, Validator_Action_General), { 
    //Type Specific
    type: (0, ts_common_1.tsValidateValue)([types_1.ActionType_Order]), subType: (0, ts_common_1.tsValidateValue)([...types_1.ActionSubTypes_Order]), variableId: ts_common_1.tsValidateOptionalId, snippetId: ts_common_1.tsValidateOptionalId, resourceId: ts_common_1.tsValidateOptionalId });
const Validator_Action_Interflow = Object.assign(Object.assign({}, Validator_Action_General), { 
    //Type Specific
    type: (0, ts_common_1.tsValidateValue)([types_1.ActionType_Interflow]), subType: (0, ts_common_1.tsValidateValue)([...types_1.ActionSubTypes_Interflow]) });
const Validator_Action = (instance, parentObject) => {
    const __toValidate = (0, ts_common_1.deleteKeysObject)((0, ts_common_1.removeDBObjectKeys)(instance), ['__resourceId', '__variableIds', '__valueIds',]);
    if ((0, utils_1.pathwayAction_isOrder)(__toValidate))
        return (0, ts_common_1.tsValidateResult)(__toValidate, Validator_Action_Order);
    if ((0, utils_1.pathwayAction_isInterflow)(__toValidate))
        return (0, ts_common_1.tsValidateResult)(__toValidate, Validator_Action_Interflow);
    throw new ts_common_1.ImplementationMissingException(`Missing validator for action of type ${instance.type}`);
};
const Validator_GeneratedProps = {
    __resourceId: ts_common_1.tsValidateOptionalId,
    __variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
    __valueIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
exports.DBDef_Action = {
    modifiablePropsValidator: Validator_Action,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.2', '1.0.1', '1.0.0'],
    dbKey: 'orders',
    entityName: 'Action',
    frontend: {
        group: hcs_1.knowledgeManagerDBGroup,
        name: 'action'
    },
    backend: {
        name: 'orders',
    },
    dependencies: {
        '__resourceId': { dbKey: 'resources', fieldType: 'string' },
        '__variableIds': { dbKey: 'variables', fieldType: 'string[]' },
        '__valueIds': { dbKey: 'values', fieldType: 'string[]' },
    }
};
