"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect_Reference = exports.DropDown_Reference = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
const ModuleFE_Reference_1 = require("./ModuleFE_Reference");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const resolve_reference_display_1 = require("./resolve-reference-display");
const styles_1 = require("@app/styles");
const Props_DropDown = {
    module: ModuleFE_Reference_1.ModuleFE_Reference,
    modules: [ModuleFE_Reference_1.ModuleFE_Reference],
    mapper: item => [(0, resolve_reference_display_1.resolveReferenceDisplay)(item)],
    placeholder: 'Choose a Reference',
    renderer: item => React.createElement(React.Fragment, null, (0, resolve_reference_display_1.resolveReferenceDisplay)(item))
};
exports.DropDown_Reference = frontend_1.GenericDropDownV3.prepare(Props_DropDown);
const Props_MultiSelect = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: ModuleFE_Reference_1.ModuleFE_Reference,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(styles_1.ICONS.x.component, { onClick: onDelete }),
            (0, resolve_reference_display_1.resolveReferenceDisplay)(item));
    },
    uiSelector: exports.DropDown_Reference.selectable,
});
exports.MultiSelect_Reference = frontend_1.TS_MultiSelect_V2.prepare(Props_MultiSelect);
