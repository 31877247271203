"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor_ExternalParamsEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../../../../../_enum/log-template/shared");
class Editor_ExternalParamsEditor extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        state = super.deriveStateFromProps(nextProps, state);
        state.params = nextProps.externalParams;
        return state;
    }
    render() {
        if (!this.state.params.length)
            return React.createElement("div", null, "No external parameters found for template");
        return React.createElement(frontend_1.Grid, { id: 'log-templates-external-params-editor' }, this.state.params.map((param, index) => {
            return React.createElement(frontend_1.LL_H_C, { key: index, className: 'params-editor__row external' },
                React.createElement("div", { className: 'parameter-key key' },
                    param.externalParamKey,
                    ":"),
                React.createElement("div", { className: 'parameter-key' }, shared_1.LogTemplateExternalParams_ValueToLabelMapper[param.paramType]));
        }));
    }
}
exports.Editor_ExternalParamsEditor = Editor_ExternalParamsEditor;
