"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_Artifact = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const db_def_1 = require("./db-def");
exports.ApiDef_Artifact = {
    _v1: {
        'register-version': { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_Artifact.dbKey}/register-version` },
        'check-version-exists': { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_Artifact.dbKey}/check-version-exists` },
        'get-artifact-data': { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_Artifact.dbKey}/get-artifact-data` },
        'get-artifact-backup-path': { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_Artifact.dbKey}/get-artifact-backup-path` },
        'get-backup-stream': { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_Artifact.dbKey}/get-backup-stream` },
    }
};
