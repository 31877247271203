"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_DiseaseProfile = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_DiseaseProfile = {
    _v1: {
        deleteChiefComplaint: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/disease-profile/delete-chief-complaint' },
        export: { method: thunderstorm_1.HttpMethod.GET, path: '/v1/disease-profile/export' },
        import: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/disease-profile/import' },
        deleteWholeDiseaseProfile: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/disease-profile/delete-whole-disease-profile' },
    }
};
