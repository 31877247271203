"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOnSync = exports.IDGeneratorSuffix_End = exports.IDGeneratorSuffix_Start = exports.IDGeneratorPrefix_TransitionPoint = exports.IDGeneratorPrefix_Transition = exports.IDGeneratorPrefix_State = void 0;
//Prefixes
const ts_common_1 = require("@nu-art/ts-common");
exports.IDGeneratorPrefix_State = 'state-';
exports.IDGeneratorPrefix_Transition = 'transition-';
exports.IDGeneratorPrefix_TransitionPoint = 'transition-point-';
const IDGeneratorPrefixes = [exports.IDGeneratorPrefix_State, exports.IDGeneratorPrefix_Transition, exports.IDGeneratorPrefix_TransitionPoint];
//Suffixes
exports.IDGeneratorSuffix_Start = '-start';
exports.IDGeneratorSuffix_End = '-end';
const IDGeneratorSuffixes = [exports.IDGeneratorSuffix_Start, exports.IDGeneratorSuffix_End];
const updateOnSync = (pathwayId, params, keyToCompare, updateCallback, deleteCallback) => {
    if (params[0] === 'delete' || params[0] === 'delete-multi')
        return deleteCallback();
    const changedPathwayTransitions = (0, ts_common_1.asArray)(params[1]);
    if (changedPathwayTransitions.some(transition => transition[keyToCompare] === pathwayId))
        updateCallback();
};
exports.updateOnSync = updateOnSync;
