"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionGroupData_All = exports.PermissionGroupData_MedicalStaff = exports.PermissionGroupData_MedicalAnalyst = exports.PermissionGroupData_GeneralQuaiStaff = exports.PermissionGroupData_MedicalTeamMember = exports.PermissionGroupData_DevTeamMember = exports.PermissionGroupData_SystemAdmin = void 0;
exports.PermissionGroupData_SystemAdmin = {
    _id: 'a08d464b0cf42c4e42bcb67d03007df6',
    name: `System Admin`,
};
exports.PermissionGroupData_DevTeamMember = {
    _id: '4f169dbe6574ed75cc7b04a4c20da1a1',
    name: `Dev Team Member`,
};
exports.PermissionGroupData_MedicalTeamMember = {
    _id: 'e6822acf068dca8015b0a235a6a481dd',
    name: `Medical Team Member`,
};
exports.PermissionGroupData_GeneralQuaiStaff = {
    _id: '290d18681bef8264797dc516b836a37b',
    name: `General Quai Staff`,
};
exports.PermissionGroupData_MedicalAnalyst = {
    _id: '9469fd3305aee9fee266528ce4aab961',
    name: `Medical Analyst`,
};
exports.PermissionGroupData_MedicalStaff = {
    _id: '6ecbbe59447ba5c84ee834f0273909eb',
    name: `Medical Staff`,
};
exports.PermissionGroupData_All = [
    exports.PermissionGroupData_SystemAdmin,
    exports.PermissionGroupData_DevTeamMember,
    exports.PermissionGroupData_MedicalTeamMember,
    exports.PermissionGroupData_GeneralQuaiStaff,
    exports.PermissionGroupData_MedicalAnalyst,
    exports.PermissionGroupData_MedicalStaff,
];
