"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_VariableSelector = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Component_VariableSelector.scss");
const Dialogs_1 = require("../Dialogs");
const styles_1 = require("@app/styles");
const shared_components_1 = require("../../../shared-components");
const _entity_1 = require("../../../../_entity");
class Component_VariableSelector extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.getVariable = () => {
            return _entity_1.ModuleFE_Variable.cache.unique(this.state.selectedVariableId);
        };
        this.renderTail = () => {
            const renderer = this.props.tailRenderer;
            if (!renderer)
                return '';
            return typeof renderer === 'function' ? renderer() : renderer;
        };
    }
    __onVariableUpdated() {
        this.forceUpdate();
    }
    deriveStateFromProps(nextProps, state) {
        state.selectedVariableId = nextProps.selectedVariableId;
        state.queryFilter = nextProps.queryFilter;
        return state;
    }
    renderEditButton() {
        var _a;
        const disabled = !this.props.selectedVariableId;
        const variable = this.getVariable();
        const sourceTag = _entity_1.ConfigKeyFE_SourceTagId.get();
        const tagId = (_a = variable === null || variable === void 0 ? void 0 : variable.tagIds.find(i => i.key === sourceTag)) === null || _a === void 0 ? void 0 : _a.value[0];
        return React.createElement(frontend_1.TS_Button, { disabled: disabled, className: 'edit-button', onClick: () => {
                Dialogs_1.Dialog_VariableEditor.show(tagId, variable);
            } },
            React.createElement(styles_1.ICONS.edit.component, null));
    }
    render() {
        var _a;
        if (this.props.allowSelection)
            return React.createElement(frontend_1.LL_H_C, { className: 'variable-selector' },
                React.createElement(shared_components_1.DropDown_Variable, { queryFilter: this.props.queryFilter, onSelected: this.props.onVariableSelected, selected: this.state.selectedVariableId, sortBy: ['name'], placeholder: this.props.placeholder }),
                this.renderEditButton(),
                this.renderTail());
        return React.createElement(frontend_1.LL_H_C, { className: 'variable-selector' },
            React.createElement("div", { className: 'variable-name' }, (_a = this.getVariable()) === null || _a === void 0 ? void 0 : _a.name),
            this.renderEditButton(),
            this.renderTail());
    }
}
exports.Component_VariableSelector = Component_VariableSelector;
