"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.composeUserDisplay = void 0;
const frontend_1 = require("@nu-art/user-account/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const composeUserDisplay = (user) => {
    var _a;
    let str = '';
    if (user.title)
        str += user.title;
    if (user.firstName)
        str += ` ${user.firstName}`;
    if (user.surname)
        str += ` ${user.surname}`;
    //If no display was gathered for user, use the connected account
    if (!str.length) {
        const account = frontend_1.ModuleFE_Account.cache.unique(user._id);
        if (!account)
            throw new ts_common_1.MUSTNeverHappenException(`Could not get account for id ${user._id}`);
        return (_a = account.displayName) !== null && _a !== void 0 ? _a : account.email;
    }
    return str.trim();
};
exports.composeUserDisplay = composeUserDisplay;
