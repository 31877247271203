"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.termPrinter = void 0;
const React = require("react");
const consts_1 = require("../../../../../../_entity/expression/shared/consts");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const atomPrinter_1 = require("./atomPrinter");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../../../../../_entity/value/shared");
const formatSubterms = (atoms, subTerms) => {
    return subTerms.map(subTerm => {
        return subTerm.atoms.map(option => {
            var _a;
            if (!option.leftHand)
                return option;
            if (((_a = option.leftHand) === null || _a === void 0 ? void 0 : _a.type) !== 'atom')
                throw new ts_common_1.BadImplementationException('in sub-terms all atom left hand should be type atom');
            if (option.cases[0].caseOperator && [consts_1.LogicType_IsFalse, consts_1.LogicType_IsTrue].includes(option.cases[0].caseOperator))
                option.cases[0] = Object.assign(Object.assign({}, option.cases[0]), { caseOperator: consts_1.LogicType_Equals, rightHand: [option.cases[0].caseOperator === consts_1.LogicType_IsFalse ? shared_1.valueID_No : shared_1.valueID_Yes] });
            return Object.assign(Object.assign({}, option), { leftHand: atoms[Number(option.leftHand.id) - 1].leftHand });
        });
    });
};
const termPrinter = (term) => {
    if (!term)
        return 'Term not found';
    if (term.atomsLogic === consts_1.LogicType_Complex) {
        const formattedSubTerms = formatSubterms(term.atoms, term.subTerms);
        return React.createElement(frontend_1.LL_V_L, { className: 'pretty-print__term-info' },
            React.createElement("ul", { className: 'pretty-print__atoms-list' },
                React.createElement("li", { className: 'pretty-print__atom-container' },
                    React.createElement("div", { className: 'pretty-print__term-info__title' }, "Any of the following options:"),
                    React.createElement("ul", { className: 'pretty-print__atom-cases' }, formattedSubTerms.map((option, index) => {
                        const subTerm = term.subTerms[index];
                        return (React.createElement("li", { key: index },
                            React.createElement(frontend_1.LL_H_C, { className: 'pretty-print_case-container' },
                                React.createElement("div", { className: 'pretty-print__atom-container content' },
                                    "Option ",
                                    index + 1,
                                    ":"),
                                (0, ts_common_1.exists)(subTerm.value) &&
                                    React.createElement("div", { className: 'pretty-print__atom-container value' },
                                        "return: ",
                                        React.createElement("i", null, subTerm.value[0] === subTerm.value[1]
                                            ? subTerm.value[0] : (0, ts_common_1.__stringify)(subTerm.value)))),
                            React.createElement("ul", { className: 'pretty-print__atom-cases' }, option.map((atom, _index) => React.createElement("li", { key: _index }, (0, atomPrinter_1.atomPrinter)(atom))))));
                    })),
                    (0, ts_common_1.exists)(term.fallbackValue) &&
                        React.createElement("div", { className: 'pretty-print__term-info__title' },
                            "Otherwise return: ",
                            term.fallbackValue[0] === term.fallbackValue[1]
                                ? term.fallbackValue[0] : (0, ts_common_1.__stringify)(term.fallbackValue)))));
    }
    let termRelationTitle;
    switch (term.atomsLogic) {
        case consts_1.LogicType_Add:
            termRelationTitle = 'Sum of:';
            break;
        case consts_1.LogicType_Or:
            termRelationTitle = 'Any of:';
            break;
        default:
        case consts_1.LogicType_And:
            termRelationTitle = 'All of:';
            break;
    }
    const renderTermValues = () => {
        return React.createElement(React.Fragment, null,
            (0, ts_common_1.exists)(term.value) &&
                React.createElement("div", { className: 'pretty-print__term-info__title' },
                    "Then return: ",
                    term.value[0] === term.value[1]
                        ? term.value[0] : (0, ts_common_1.__stringify)(term.value)),
            (0, ts_common_1.exists)(term.fallbackValue) &&
                React.createElement("div", { className: 'pretty-print__term-info__title' },
                    "Otherwise return: ",
                    term.fallbackValue[0] === term.fallbackValue[1]
                        ? term.fallbackValue[0] : (0, ts_common_1.__stringify)(term.fallbackValue)));
    };
    return React.createElement(frontend_1.LL_V_L, { className: 'pretty-print__term-info' },
        term.atoms.length > 1 && React.createElement("div", { className: 'pretty-print__term-info__title' }, termRelationTitle),
        React.createElement("ul", { className: 'pretty-print__atoms-list' }, term.atoms.map((atom, index) => React.createElement("li", { key: index, className: 'pretty-print__atom-container' }, (0, atomPrinter_1.atomPrinter)(atom)))),
        renderTermValues());
};
exports.termPrinter = termPrinter;
