"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchSelector_BaseManagerUI = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const React = require("react");
require("./SearchSelector_BaseManagerUI.scss");
const Component_SearchAndResults_1 = require("../../search-v2/Component_SearchAndResults");
class SearchSelector_BaseManagerUI extends frontend_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
        state.selectedItem = nextProps.selectedItem;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'search-selector' },
            React.createElement(frontend_1.LL_H_C, { className: 'button-container' }, this.props.buttons.map((data, i) => {
                return React.createElement(frontend_1.TS_Button, { className: 'btn-create preferred-action', onClick: data.action, key: i }, data.label);
            })),
            React.createElement(Component_SearchAndResults_1.Component_SearchAndResults, { searchItems: this.props.searchItems, selectedItem: this.props.selectedItem, onSearchItemSelected: this.props.onSearchItemSelected }));
    }
}
exports.SearchSelector_BaseManagerUI = SearchSelector_BaseManagerUI;
