"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header_PathwayTransition = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const Header_PathwayState_1 = require("./Header_PathwayState");
const styles_1 = require("@app/styles");
require("./Header_PathwayTransition.scss");
const frontend_2 = require("@app/styles/frontend");
const _entity_1 = require("../../../_entity");
const Header_PathwayTransition = (props) => {
    var _a, _b;
    const originState = _entity_1.ModuleFE_PathwayState.cache.unique((_a = props.pathwayTransition.item.sourceIds) === null || _a === void 0 ? void 0 : _a[0]);
    if (!originState)
        throw new ts_common_1.MUSTNeverHappenException('Trying to render a pathway transition header with no origin state');
    const targetState = _entity_1.ModuleFE_PathwayState.cache.unique((_b = props.pathwayTransition.item.targetIds) === null || _b === void 0 ? void 0 : _b[0]);
    return React.createElement(frontend_2.Header_WithOptionMenu, { menuActions: props.actions, title: React.createElement(frontend_1.LL_H_C, { className: 'pathway__transition-header' },
            React.createElement(Header_PathwayState_1.Header_PathwayState, { pathwayState: new frontend_1.EditableDBItemV3(originState, _entity_1.ModuleFE_PathwayState), functions: props.functions, clickableTitle: props.clickableTitle }),
            React.createElement(styles_1.ICONS.circleArrow.component, { className: 'pathway__transition-header__icon' }),
            targetState &&
                React.createElement(Header_PathwayState_1.Header_PathwayState, { headerText: props.pathwayTransition.get('label'), pathwayState: new frontend_1.EditableDBItemV3(targetState, _entity_1.ModuleFE_PathwayState), functions: props.functions, clickableTitle: props.clickableTitle })) });
};
exports.Header_PathwayTransition = Header_PathwayTransition;
