"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_Transition = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const styles_1 = require("@app/styles");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Renderer_Transition.scss");
const _entity_1 = require("../../../_entity");
const Renderer_Transition = (props) => {
    const transition = typeof props.transition === 'string'
        ? _entity_1.ModuleFE_PathwayTransition.cache.unique(props.transition)
        : props.transition;
    if (!transition)
        throw new ts_common_1.MUSTNeverHappenException(`Could not resolve transition from props ${props.transition}`);
    const origin = _entity_1.ModuleFE_PathwayState.cache.unique(transition.sourceIds[0]);
    const target = _entity_1.ModuleFE_PathwayState.cache.unique(transition.targetIds[0]);
    if (!origin)
        throw new ts_common_1.MUSTNeverHappenException(`Could not get origin state for id ${transition.sourceIds[0]}`);
    if (!target)
        throw new ts_common_1.MUSTNeverHappenException(`Could not get origin state for id ${transition.targetIds[0]}`);
    const className = (0, frontend_1._className)('pathway__transition', props.className, props.onClick && 'clickable');
    return React.createElement(frontend_1.LL_H_C, { className: className, onClick: props.onClick },
        React.createElement("div", { className: 'pathway__transition__source' }, origin.name),
        React.createElement(styles_1.ICONS.circleArrow.component, null),
        React.createElement("div", { className: 'pathway__transition__target' }, (0, ts_common_1.exists)(transition.label) ? transition.label : target.name));
};
exports.Renderer_Transition = Renderer_Transition;
