"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Score = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const shared_1 = require("../../shared");
const Validator_ModifiableProps = {
    variableId: ts_common_1.tsValidateUniqueId,
    diseaseProfileId: ts_common_1.tsValidateUniqueId,
    expression: ts_common_1.tsValidateOptional,
    type: (0, ts_common_1.tsValidateValue)([...types_1.ScoreTypes]),
    defaultScoreCondition: (0, ts_common_1.tsValidateBoolean)(),
    dialogKey: (0, ts_common_1.tsValidateString)(undefined, false),
};
const Validator_GeneratedProps = {
    _variableIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId),
};
exports.DBDef_Score = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbKey: 'scores',
    entityName: 'Score',
    frontend: {
        group: shared_1.knowledgeManagerDBGroup,
        name: 'score'
    },
    backend: {
        name: 'scores'
    },
    dependencies: {
        variableId: { dbKey: 'variables', fieldType: 'string' },
        _variableIds: { dbKey: 'variables', fieldType: 'string[]' }
    }
};
