"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Panel_Values = void 0;
const React = require("react");
require("./Panel_Values.scss");
const _shared_1 = require("../../../_shared");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const Editors_1 = require("./Editors");
const shared_components_1 = require("../../shared-components");
const styles_1 = require("@app/styles");
const references_1 = require("../references");
const utils_1 = require("../../../utils");
const _entity_1 = require("../../../_entity");
const expressionToValue_1 = require("./expressionToValue/expressionToValue");
class Panel_Values extends frontend_1.ProtoComponent {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        this.resolveHeaderDisplay = () => {
            var _a, _b;
            if (((_a = this.state.parentValue) === null || _a === void 0 ? void 0 : _a.type) === _shared_1.valueType_Time)
                return 'Time Ranges';
            if (!this.state.parentValue || this.state.parentValue.type !== _entity_1.valueType_Range)
                return 'Scope';
            const fullRange = this.state.parentValue.data.scope.join(' ... ');
            const measurementUnitAsString = (_b = this.state.parentValue.data.measurementUnit) === null || _b === void 0 ? void 0 : _b.unitAsString;
            return `Full range: ${fullRange}${measurementUnitAsString ? ` (${measurementUnitAsString})` : ''}`;
        };
        this.renderAttributes = () => {
            var _a;
            const selectedVariableId = this.getQueryParam('selected', {})[_shared_1.DBDef_Variable.dbKey];
            if (!selectedVariableId)
                return this.renderMessage('No variable selected');
            const selectedVariable = _entity_1.ModuleFE_Variable.cache.unique(selectedVariableId);
            if (!selectedVariable)
                return this.renderMessage(`Variable with id ${selectedVariableId} not in cache`);
            return React.createElement(frontend_1.LL_V_L, { className: 'attributes-tab' }, (_a = selectedVariable === null || selectedVariable === void 0 ? void 0 : selectedVariable.attributes) === null || _a === void 0 ? void 0 : _a.map(att => {
                var _a;
                const variable = this.state.attributeVariablesMap[att.varId];
                const value = this.state.attributeValuesMap[att.valueId];
                return React.createElement(frontend_1.TS_CollapsableContainer, { key: att.varId, customCaret: React.createElement(styles_1.ICONS.advisorArrowDown.component, null), headerRenderer: variable.name, containerRenderer: React.createElement(frontend_1.LL_H_C, { className: 'values' }, (_a = value.data) === null || _a === void 0 ? void 0 : _a.scope.map((val, i) => {
                        var _a;
                        return React.createElement("div", { key: i, className: 'value-tag' }, (_a = val.data) === null || _a === void 0 ? void 0 : _a.value);
                    })) });
            }));
        };
    }
    // ######################### Life Cycle #########################
    __onExpressionUpdated(...params) {
        const selectedVarId = this.getQueryParam('selected', {})[_shared_1.DBDef_Variable.dbKey];
        if ((0, ts_common_1.asArray)(params[1])[0].associatedVar !== selectedVarId)
            return;
        switch (params[0]) {
            case frontend_1.EventType_Query:
                return;
            case frontend_1.EventType_Create:
            case frontend_1.EventType_Delete:
            case frontend_1.EventType_Update:
                break;
        }
        this.reDeriveState();
    }
    __onValueUpdated(...params) {
        switch (params[0]) {
            case frontend_1.EventType_Query:
                return;
            case frontend_1.EventType_Create:
            case frontend_1.EventType_Delete:
            case frontend_1.EventType_Update:
                break;
        }
        this.reDeriveState();
    }
    __onVariableUpdated(...params) {
        const selectedVarId = this.getQueryParam('selected', {})[_shared_1.DBDef_Variable.dbKey];
        if (params[0] === frontend_1.EventType_Update && params[1]._id === selectedVarId)
            this.forceUpdate();
    }
    deriveStateFromProps(nextProps, state) {
        var _a, _b, _c, _d;
        state.attributeValuesMap = {};
        state.attributeVariablesMap = {};
        const selectedVarId = this.getQueryParam('selected', {})[_shared_1.DBDef_Variable.dbKey];
        const selectedVariable = _entity_1.ModuleFE_Variable.cache.unique(selectedVarId);
        if (!selectedVarId || !selectedVariable)
            return state;
        const filter = this.getQueryParam('filterValues');
        state.valuesMap = _entity_1.ModuleFE_Value.cache.arrayToMap(ts_common_1.dbObjectToId);
        const dbValue = state.valuesMap[selectedVariable.valueId];
        const measurementUnitsMap = (0, ts_common_1.arrayToMap)(_entity_1.ModuleFE_MeasurementUnit.cache.all(), ts_common_1.dbObjectToId);
        state.values = [];
        state.parentValue = (0, _entity_1.convertValueDBToUI)(dbValue, {
            values: state.valuesMap,
            measurementUnits: measurementUnitsMap
        });
        if (!state.parentValue)
            return state;
        state.values = ((state.parentValue.type === _entity_1.valueType_Enumerated || state.parentValue.type === _entity_1.valueType_Tuple) ? (_a = state.parentValue.data) === null || _a === void 0 ? void 0 : _a.scope : state.parentValue.data.subsets);
        if (state.parentValue.type === _shared_1.valueType_Time)
            state.values = state.parentValue.data.timeRanges;
        if (state.parentValue.type === _entity_1.valueType_StandardRange)
            state.values = [state.parentValue];
        //Filter results if filter exists
        if (filter && state.values) {
            const _filter = new ts_common_1.Filter((item) => {
                var _a, _b;
                if (item.type === _entity_1.valueType_SubRange)
                    return [(_a = item.data) === null || _a === void 0 ? void 0 : _a.label];
                return [(_b = item.data) === null || _b === void 0 ? void 0 : _b.value];
            });
            state.values = _filter.filter(state.values, filter);
        }
        //Sort Values
        if (!((_b = state.values) === null || _b === void 0 ? void 0 : _b.some(value => value.type !== _entity_1.valueType_SubRange)))
            state.values = (0, ts_common_1.sortArray)((0, ts_common_1.sortArray)(state.values, v => v.data.scope[1]), v => v.data.scope[0]);
        //Get references for the selected variable
        state.references = _entity_1.ModuleFE_Reference.cache.filter(ref => {
            return (ref.type === 'loinc' || ref.type === 'snomed') &&
                selectedVariable.referenceIds.includes(ref._id);
        });
        const attVarIds = ((_c = selectedVariable.attributes) === null || _c === void 0 ? void 0 : _c.map(att => att.varId)) || [];
        const attValueIds = ((_d = selectedVariable.attributes) === null || _d === void 0 ? void 0 : _d.map(att => att.valueId)) || [];
        state.attributeVariablesMap = (0, ts_common_1.arrayToMap)(_entity_1.ModuleFE_Variable.cache.filter(item => attVarIds.includes(item._id)), ts_common_1.dbObjectToId);
        state.attributeValuesMap = (0, ts_common_1.arrayToMap)(attValueIds.map(id => (0, _entity_1.convertValueDBToUI)(state.valuesMap[id], {
            values: state.valuesMap,
            measurementUnits: measurementUnitsMap
        })), i => i._id);
        return state;
    }
    // ######################### Logic #########################
    async isReady() {
        return _entity_1.ModuleFE_Variable.getDataStatus() === frontend_1.DataStatus.ContainsData && _entity_1.ModuleFE_Value.getDataStatus() === frontend_1.DataStatus.ContainsData;
    }
    // ######################### Render #########################
    renderHeader() {
        const selectedVariableId = this.getQueryParam('selected', {})[_shared_1.DBDef_Variable.dbKey];
        const selectedVariable = _entity_1.ModuleFE_Variable.cache.unique(selectedVariableId);
        return React.createElement(shared_components_1.Panel_Header, { config: { filter: this.getQueryParam('filterValues') }, onFilterChanged: (filter) => {
                this.setQueryParam('filterValues', filter);
            }, headerClassName: 'value-area__header', title: { label: (selectedVariable === null || selectedVariable === void 0 ? void 0 : selectedVariable.name) || 'Values', className: 'value-area__header__title' }, qsearchId: 'value-area' });
    }
    renderValuesList() {
        const rows = this.state.values || [];
        if (!rows.length)
            return this.renderMessage('No values found');
        return React.createElement(frontend_1.TS_Table, { rows: rows, header: ['data'], headerRenderer: columnKey => React.createElement(frontend_1.LL_H_C, { className: 'th-data unselectable' }, this.resolveHeaderDisplay()), cellRenderer: (prop, item, index) => {
                if (prop === 'data') {
                    return React.createElement("div", { className: 'td-data unselectable' }, (0, _shared_1.resolveValueDisplay)(item));
                }
            } });
    }
    renderTabs() {
        const selectedVariableId = this.getQueryParam('selected', {})[_shared_1.DBDef_Variable.dbKey];
        if (!selectedVariableId)
            return this.renderMessage('No variable selected');
        const selectedVariable = _entity_1.ModuleFE_Variable.cache.unique(selectedVariableId);
        if (!selectedVariable)
            this.logError(`No variable found in cache for id ${selectedVariableId}`);
        const tabs = [
            {
                title: 'Values',
                content: () => {
                    var _a, _b, _c;
                    let isNormal = false;
                    if (((_b = (_a = this.state.values) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.type) === _entity_1.valueType_StandardRange)
                        isNormal = true;
                    const value = Object.assign({}, this.state.valuesMap[(_c = this.state.values[0]) === null || _c === void 0 ? void 0 : _c._id]);
                    if (value.type === _entity_1.valueType_StandardRange) {
                        const expression = _entity_1.ModuleFE_Expression.cache.find(expr => expr.associatedVar === selectedVariableId);
                        if (!expression)
                            return;
                        const rangesValueData = (0, expressionToValue_1.expressionToValue)(expression);
                        if (!rangesValueData || !expression)
                            return React.createElement("div", null, "Cannot present ranges due of complex range expression");
                        value.data = Object.assign(Object.assign({}, value.data), rangesValueData);
                    }
                    return React.createElement("div", { className: 'values-list custom-scrollbar__vertical' },
                        isNormal && React.createElement(Editors_1.NormalValuesTable, { value: value, editable: false }),
                        !isNormal && this.renderValuesList());
                },
                uid: 'values'
            },
            {
                title: 'References',
                content: () => {
                    const onReferenceUpsert = async (reference, originRef) => {
                        if (!selectedVariable)
                            return;
                        if (originRef._id && originRef._id !== reference._id) {
                            const originIndex = selectedVariable.referenceIds.findIndex(id => id === originRef._id);
                            selectedVariable.referenceIds.splice(originIndex, 1);
                        }
                        const index = selectedVariable.referenceIds.findIndex(id => id === reference._id);
                        if (index === -1) {
                            selectedVariable.referenceIds.push(reference._id);
                            try {
                                await _entity_1.ModuleFE_Variable.v1.upsert(selectedVariable).executeSync();
                            }
                            catch (e) {
                                (0, utils_1.handleError)(e);
                            }
                        }
                        this.forceUpdate();
                    };
                    const onReferenceDeleted = async (referenceId) => {
                        if (!selectedVariable)
                            return;
                        const index = selectedVariable.referenceIds.findIndex(id => id === referenceId);
                        if (index !== -1) {
                            selectedVariable.referenceIds.splice(index, 1);
                            try {
                                await _entity_1.ModuleFE_Variable.v1.upsert(selectedVariable).executeSync();
                            }
                            catch (e) {
                                (0, utils_1.handleError)(e);
                            }
                            this.forceUpdate();
                        }
                    };
                    return React.createElement("div", { className: 'list-wrapper custom-scrollbar__vertical' }, selectedVariable && React.createElement(references_1.Component_ReferencesTable, { blockEdit: true, referenceIds: selectedVariable.referenceIds, onReferenceUpsertCallback: onReferenceUpsert, onReferenceDeletedCallback: onReferenceDeleted, allowedRefs: ['snomed', 'loinc'] }));
                },
                uid: 'refs'
            },
            {
                title: 'Attributes',
                uid: 'attributes',
                content: this.renderAttributes,
            }
        ];
        return React.createElement(frontend_1.TS_Tabs, { tabs: tabs });
    }
    renderMessage(message) {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_parent', style: { justifyContent: 'center', alignItems: 'center' } }, message);
    }
    render() {
        return React.createElement(frontend_1.LL_V_C, { className: 'value-area' },
            this.renderHeader(),
            this.renderTabs());
    }
}
Panel_Values.defaultProps = {
    keys: ['selected', 'filterValues'],
};
exports.Panel_Values = Panel_Values;
