"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Book = exports.ModuleFE_Book_Class = exports.dispatch_onBooksUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../shared");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
exports.dispatch_onBooksUpdated = new types_1.ThunderDispatcherV3('__onBooksUpdated');
class ModuleFE_Book_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Book, exports.dispatch_onBooksUpdated);
    }
}
exports.ModuleFE_Book_Class = ModuleFE_Book_Class;
exports.ModuleFE_Book = new ModuleFE_Book_Class();
