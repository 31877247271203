"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_GeneralInfo = void 0;
const React = __importStar(require("react"));
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../../../shared");
const frontend_2 = require("@app/hcs/frontend");
const frontend_3 = require("@nu-art/permissions/frontend");
const ModuleFE_PermissionsAssert_1 = require("@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../_entity");
const ui_components_1 = require("../../../../_entity/color-group/frontend/ui-components");
const _enum_1 = require("../../../_enum");
const dd_carets = {
    open: React.createElement(styles_1.ICONS.advisorArrowDown.component, null),
    close: React.createElement(styles_1.ICONS.advisorArrowDown.component, { className: 'close' })
};
class Renderer_GeneralInfo extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Static ########################
        super(...arguments);
        this.dpPermissionKey_Edit = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getPermissionKey(shared_1.PermissionKey_DiseaseProfile_Edit);
        this.saveDP = async () => {
            await (0, frontend_1.genericNotificationAction)(async () => {
                await this.state.diseaseProfile.save();
            }, {
                inProgress: 'Saving Disease Profile',
                failed: 'Failed to save disease profile',
                success: 'Disease Profile Saved'
            });
            this.props.triggerChange(this.state.diseaseProfile);
        };
        this.renderHeader = () => {
            return React.createElement("div", { className: 'manager__disease-profile__general-info__header' }, this.state.varOption);
        };
        this.renderBody = () => {
            return React.createElement(frontend_1.Grid, { className: 'manager__data-grid manager__disease-profile__general-info__body' },
                this.renderOverview(),
                this.renderDiseaseInfo(),
                this.renderSnomedInfo(),
                this.renderReferences(),
                this.renderScores());
        };
        // ######################## Render - Sections ########################
        this.renderOverview = () => {
            var _a, _b, _c;
            if (this.state.varOption !== 'Overview')
                return;
            //Data for rendering
            const editMode = this.state.editMode;
            const dp = this.state.diseaseProfile;
            const dpVar = frontend_2.ModuleFE_Variable.cache.unique(dp.item.variableId);
            const variantGroupName = frontend_2.ModuleFE_Tag.cache.unique(dp.item.variantsGroupId);
            const currentColorGroup = _entity_1.ModuleFE_ColorGroup.cache.unique(dp.item.colorGroup);
            //Adapters
            const variantGroupAdapter = (0, frontend_1.SimpleListAdapter)((0, ts_common_1.sortArray)(frontend_2.ModuleFE_Tag.cache.filter(i => i.tagIds.length === 0), i => i.label), i => React.createElement(React.Fragment, null, i.item.label));
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'data-grid__label' }, "Disease Variable"),
                React.createElement("div", { className: 'data-grid__data' }, dpVar === null || dpVar === void 0 ? void 0 : dpVar.name),
                editMode && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'data-grid__label' }, "Label"),
                    React.createElement(frontend_3.PermissionsEditableComponent, { value: dp.item.label, permissionKey: this.dpPermissionKey_Edit, className: 'data-grid__data' },
                        React.createElement(frontend_1.TS_Input, { type: 'text', value: dp.item.label, onBlur: this.saveDP, onChange: value => {
                                dp.set('label', value);
                                this.props.triggerChange(dp);
                            } }))),
                React.createElement("div", { className: 'data-grid__label' }, "Profile Abbreviation"),
                React.createElement(frontend_3.PermissionsEditableComponent, { value: dp.item.initials, permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, className: 'data-grid__data' },
                    React.createElement(frontend_1.TS_Input, { type: 'text', value: dp.item.initials, onBlur: this.saveDP, onChange: value => dp.set('initials', value) })),
                React.createElement("div", { className: 'data-grid__label' }, "Color Group"),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, className: 'data-grid__data', value: (_a = currentColorGroup === null || currentColorGroup === void 0 ? void 0 : currentColorGroup.label) !== null && _a !== void 0 ? _a : '-' },
                    React.createElement(frontend_1.LL_V_L, { className: 'match_width', style: { gap: '10px' } },
                        React.createElement(ui_components_1.DropDown_ColorGroup.editable, { editable: dp, prop: 'colorGroup', caret: dd_carets, onSelected: group => {
                                dp.set('colorGroup', group === null || group === void 0 ? void 0 : group._id);
                                // @ts-ignore
                                delete dp.item.color;
                                // @ts-ignore
                                delete dp.item.backgroundColor;
                                this.saveDP();
                            } }),
                        currentColorGroup &&
                            React.createElement(frontend_1.LL_H_C, { className: 'match_width', style: { gap: '10px' } },
                                React.createElement("div", { className: 'dp-color-group-example', style: { background: currentColorGroup['criticality-00'][0] } }),
                                React.createElement("div", { className: 'dp-color-group-example', style: { background: currentColorGroup['criticality-10'][0] } }),
                                React.createElement("div", { className: 'dp-color-group-example', style: { background: currentColorGroup['criticality-20'][0] } })))),
                React.createElement("div", { className: 'data-grid__label' }, "Variant Group"),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: (_b = variantGroupName === null || variantGroupName === void 0 ? void 0 : variantGroupName.label) !== null && _b !== void 0 ? _b : '-', className: 'data-grid__data' },
                    React.createElement(frontend_1.TS_DropDown, { adapter: variantGroupAdapter, onSelected: tag => {
                            dp.set('variantsGroupId', tag._id);
                            this.saveDP();
                        }, selected: variantGroupName, caret: dd_carets })),
                React.createElement("div", { className: 'data-grid__label' }, "Tags"),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: '-', className: 'data-grid__data' },
                    React.createElement("div", null)),
                React.createElement("div", { className: 'data-grid__label' }, "Criticality"),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: dp.item.criticality ? _enum_1.CriticalityLabelMap[dp.item.criticality] : '-', className: 'data-grid__data' },
                    React.createElement(_enum_1.DropDown_Criticality.editable, { editable: dp, prop: 'criticality', onSelected: criticality => {
                            dp.set('criticality', criticality);
                            this.saveDP();
                        } })),
                React.createElement("div", { className: 'data-grid__label' }, "General Description"),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: (_c = dp.item.profileDescription) !== null && _c !== void 0 ? _c : '-', className: 'data-grid__data' },
                    React.createElement(frontend_1.TS_TextArea, { type: 'text', value: dp.item.profileDescription, onBlur: this.saveDP, onChange: value => dp.set('profileDescription', value) })),
                React.createElement("div", { className: 'data-grid__label' }, "General Notes"),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: dp.item.profileNotes || '-', className: 'data-grid__data' },
                    React.createElement(frontend_1.TS_TextArea, { type: 'text', value: dp.item.profileNotes, onBlur: this.saveDP, onChange: value => dp.set('profileNotes', value) })));
        };
        this.renderDiseaseInfo = () => {
            var _a, _b, _c;
            if (this.state.varOption !== 'Disease Info')
                return;
            const dp = this.state.diseaseProfile;
            const dpVar = frontend_2.ModuleFE_Variable.cache.unique(dp.item.variableId);
            const nlpData = frontend_2.ModuleFE_NLPData.cache.unique(dpVar.nlpDataId);
            return React.createElement(React.Fragment, null,
                dpVar.tagIds.map(tagGroup => {
                    const mainTag = frontend_2.ModuleFE_Tag.cache.unique(tagGroup.key);
                    return React.createElement(React.Fragment, { key: mainTag._id },
                        React.createElement("div", { className: 'data-grid__label' }, mainTag.label),
                        React.createElement(frontend_1.LL_V_L, null, tagGroup.value.map(id => React.createElement("div", { key: id, className: 'data-grid__data' }, frontend_2.ModuleFE_Tag.cache.unique(id).label))));
                }),
                React.createElement("div", { className: 'data-grid__label' }, "Synonyms"),
                !((_a = nlpData === null || nlpData === void 0 ? void 0 : nlpData.synonyms) === null || _a === void 0 ? void 0 : _a.length) ? '-' : React.createElement(frontend_1.LL_V_L, null, nlpData.synonyms.map(synonym => React.createElement("div", { className: 'data-grid__data' }, synonym))),
                React.createElement("div", { className: 'data-grid__label' }, "Perspective"),
                React.createElement("div", { className: 'data-grid__data' }, (_b = dpVar.perspective) !== null && _b !== void 0 ? _b : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "Role"),
                React.createElement("div", { className: 'data-grid__data' }, (_c = dpVar.role) !== null && _c !== void 0 ? _c : '-'));
        };
        this.renderSnomedInfo = () => {
            var _a, _b, _c, _d, _e;
            if (this.state.varOption !== 'Snomed Info')
                return;
            const dp = this.state.diseaseProfile;
            const dpVar = frontend_2.ModuleFE_Variable.cache.unique(dp.item.variableId);
            const references = dpVar.referenceIds.map(i => frontend_2.ModuleFE_Reference.cache.unique(i));
            const snomedRef = references.find(i => i.type === 'snomed');
            const icdRef = references.find(i => i.type === 'icdCode');
            const icd10 = (_a = snomedRef === null || snomedRef === void 0 ? void 0 : snomedRef.data.icd10) === null || _a === void 0 ? void 0 : _a.trim().replace(/\s+/g, ', ');
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'data-grid__label' }, "Snomed ID"),
                React.createElement("div", { className: 'data-grid__data' }, (_b = snomedRef === null || snomedRef === void 0 ? void 0 : snomedRef.data.id) !== null && _b !== void 0 ? _b : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "Snomed Name"),
                React.createElement("div", { className: 'data-grid__data' }, (_d = (_c = snomedRef === null || snomedRef === void 0 ? void 0 : snomedRef.data.names) === null || _c === void 0 ? void 0 : _c.join(', ')) !== null && _d !== void 0 ? _d : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "Snomed ICD10"),
                React.createElement("div", { className: 'data-grid__data' }, icd10 !== null && icd10 !== void 0 ? icd10 : '-'),
                React.createElement("div", { className: 'data-grid__label' }, "ICD Code"),
                React.createElement("div", { className: 'data-grid__data' }, (_e = icdRef === null || icdRef === void 0 ? void 0 : icdRef.data.id) !== null && _e !== void 0 ? _e : '-'));
        };
        this.renderReferences = () => {
            var _a, _b;
            if (this.state.varOption !== 'References')
                return;
            const dp = this.state.diseaseProfile;
            const references = (_b = (_a = dp.item.referenceIds) === null || _a === void 0 ? void 0 : _a.map(i => frontend_2.ModuleFE_Reference.cache.unique(i))) !== null && _b !== void 0 ? _b : [];
            return references.map(ref => {
                return React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'data-grid__label' }, ref.type),
                    React.createElement("div", { className: 'data-grid__data' }, (0, frontend_2.resolveReferenceDisplay)(ref)));
            });
        };
        this.renderScores = () => {
            var _a, _b;
            if (this.state.varOption !== 'Scores')
                return;
            const editMode = this.state.editMode;
            const dp = this.state.diseaseProfile;
            const scoresTag = frontend_2.ConfigKeyFE_Scores.get();
            const existingScoreVarIds = (0, ts_common_1.filterInstances)(((_a = dp.item.scoreReferences) === null || _a === void 0 ? void 0 : _a.map(i => i.variableId)) || []);
            return React.createElement(React.Fragment, null, (_b = dp.item.scoreReferences) === null || _b === void 0 ? void 0 :
                _b.map(score => {
                    const scoreVar = frontend_2.ModuleFE_Variable.cache.unique(score.variableId);
                    return React.createElement(React.Fragment, null,
                        React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: scoreVar === null || scoreVar === void 0 ? void 0 : scoreVar.name, className: 'data-grid__data' },
                            React.createElement(frontend_2.DropDown_Variable, { canUnselect: true, selected: scoreVar, onSelected: async (variable) => {
                                    var _a;
                                    if (!variable)
                                        dp.item.scoreReferences = (_a = dp.item.scoreReferences) === null || _a === void 0 ? void 0 : _a.filter(i => i.variableId !== score.variableId);
                                    else
                                        score.variableId = variable._id;
                                    await this.saveDP();
                                }, queryFilter: i => !existingScoreVarIds.includes(i._id) && i.tagIds.some(group => group.value.includes(scoresTag)), sortBy: ['name'], placeholder: 'Score Name' })),
                        this.renderScoreLink(score));
                }),
                React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: '', className: 'data-grid__data' },
                    React.createElement(frontend_2.DropDown_Variable, { selected: undefined, onSelected: async (variable) => {
                            if (!dp.item.scoreReferences)
                                dp.item.scoreReferences = [];
                            dp.item.scoreReferences.push({
                                variableId: variable._id,
                            });
                            await this.saveDP();
                        }, queryFilter: i => !existingScoreVarIds.includes(i._id) && i.tagIds.some(group => group.value.includes(scoresTag)), sortBy: ['name'], placeholder: 'Score Name' })));
        };
        this.renderScoreLink = (score) => {
            const editMode = this.state.editMode;
            if (!editMode) {
                if (!score.mdCalcUrl)
                    return React.createElement("div", { className: 'data-grid__data' });
                return React.createElement(frontend_1.TS_Link, { url: score.mdCalcUrl, target: '_blank', className: 'data-grid__data' }, score.mdCalcUrl);
            }
            return React.createElement(frontend_3.PermissionsEditableComponent, { permissionKey: this.dpPermissionKey_Edit, forceLock: !editMode, value: score.mdCalcUrl, className: 'data-grid__data' },
                React.createElement(frontend_1.TS_Input, { type: 'text', value: score.mdCalcUrl, onBlur: this.saveDP, onChange: val => score.mdCalcUrl = val }));
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        if (((_b = (_a = state.diseaseProfile) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b._id) !== nextProps.diseaseProfileId)
            state.diseaseProfile = this.getEditableDP(_entity_1.ModuleFE_DiseaseProfile.cache.unique(nextProps.diseaseProfileId));
        if (state.varOption !== nextProps.varOption)
            state.varOption = nextProps.varOption;
        state.editMode = nextProps.editMode;
        return state;
    }
    // ######################## Logic ########################
    getEditableDP(dp) {
        return new frontend_1.EditableDBItemV3(dp, _entity_1.ModuleFE_DiseaseProfile)
            .setOnChanged(async (editable) => this.setState({ diseaseProfile: editable }));
    }
    // ######################## Render - Structure ########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'manager__disease-profile__general-info' },
            this.renderHeader(),
            this.renderBody());
    }
}
exports.Renderer_GeneralInfo = Renderer_GeneralInfo;
