"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_ArticleEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const q_components_1 = require("../../../q-components");
const styles_1 = require("@app/styles");
const _entity_1 = require("../../../../_entity");
class Dialog_ArticleEditor extends frontend_1.TS_Dialog {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        // ######################### Logic #########################
        this.linkIsDOI = (link) => {
            return true;
        };
        this.handleSave = async () => {
            await (0, frontend_1.performAction)(async () => {
                this.setState({ dialogIsBusy: true });
                try {
                    const ref = this.props.reference && (0, ts_common_1.cloneObj)(this.props.reference) || {
                        type: 'article',
                        uid: '',
                    };
                    ref.data = this.state.data;
                    const newRef = await _entity_1.ModuleFE_Reference.v1.upsert(ref).executeSync();
                    this.props.onRefUpsertCallback(newRef, newRef);
                    frontend_1.ModuleFE_Dialog.close();
                }
                catch (e) {
                    this.logError(e);
                }
                finally {
                    this.setState({ dialogIsBusy: false });
                }
            }, { type: 'notification', notificationLabels: 'Saving Article Reference' });
        };
        this.renderHeader = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: "header__title" },
                    this.props.reference ? 'Edit' : 'Add',
                    " Article"),
                React.createElement("div", { className: "header__buttons" },
                    React.createElement(styles_1.ICONS.x.component, { className: "exit_icon", onClick: () => frontend_1.ModuleFE_Dialog.close() })));
        };
        this.renderBody = () => {
            return React.createElement(React.Fragment, null,
                this.renderLinkInput(),
                this.renderName(),
                this.renderJournal(),
                this.renderPublicationYear());
        };
        this.buttons = () => {
            return {
                right: [
                    {
                        content: 'Cancel',
                        onClick: () => frontend_1.ModuleFE_Dialog.close(),
                    },
                    {
                        content: 'Save',
                        onClick: this.handleSave,
                        disabled: !this.canBeSaved() || this.state.dialogIsBusy,
                        className: (0, frontend_1._className)(this.canBeSaved() ? 'desired-action-button' : ''),
                        renderer: frontend_1.TS_Dialog.busyButton,
                    }
                ],
            };
        };
    }
    static show(onRefUpsertCallback, reference) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_ArticleEditor, { onRefUpsertCallback: onRefUpsertCallback, reference: reference }), () => true);
    }
    // ######################### Life Cycle #########################
    deriveStateFromProps(nextProps, state) {
        var _a, _b;
        state.data = (_b = (_a = nextProps.reference) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : {
            name: '',
            url: '',
            journal: '',
            publicationYear: 0,
        };
        return state;
    }
    canBeSaved() {
        return this.linkIsDOI(this.state.data.url);
    }
    // ######################### Render #########################
    renderLinkInput() {
        return React.createElement(frontend_1.LL_V_L, { className: 'match_width' },
            React.createElement("span", { className: 'dialog__field-title' }, "Link"),
            React.createElement(frontend_1.TS_Input, { type: 'text', placeholder: 'Enter Link', value: this.state.data.url, tabIndex: 0, onKeyDown: (e) => {
                    if (!((e.ctrlKey || e.metaKey) && e.key === 'v')) {
                        (0, frontend_1.stopPropagation)(e);
                    }
                    if (e.key === 'Backspace') {
                        this.state.data.url = '';
                        this.forceUpdate();
                    }
                }, onChange: link => {
                    if (!this.linkIsDOI(link)) {
                        new frontend_1.ToastBuilder().setDuration(6 * ts_common_1.Second)
                            .setContent((0, q_components_1.QToastContent)({ iconKey: 'x', content: 'Link is not an UpToDate link', toastType: 'error' }))
                            .show();
                    }
                    else {
                        const cleanLink = link.split('?')[0];
                        const split = cleanLink.split('/');
                        this.state.data.url = cleanLink;
                        this.state.data.name = split[split.length - 1];
                        this.forceUpdate();
                    }
                } }));
    }
    renderName() {
        return React.createElement(frontend_1.LL_V_L, null,
            React.createElement("span", { className: 'dialog__field-title' }, "Name"),
            React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.data.name, onChange: (value) => {
                    this.state.data.name = value;
                    this.forceUpdate();
                } }));
    }
    renderPublicationYear() {
        return React.createElement(frontend_1.LL_V_L, null,
            React.createElement("span", { className: 'dialog__field-title' }, "Publication Year"),
            React.createElement(frontend_1.TS_Input, { type: 'number', value: String(this.state.data.publicationYear), onChange: (value) => {
                    this.state.data.publicationYear = Number(value);
                    this.forceUpdate();
                } }));
    }
    renderJournal() {
        return React.createElement(frontend_1.LL_V_L, null,
            React.createElement("span", { className: 'dialog__field-title' }, "Journal"),
            React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.data.journal, onChange: (value) => {
                    this.state.data.journal = value;
                    this.forceUpdate();
                } }));
    }
}
Dialog_ArticleEditor.defaultProps = {
    dialogId: 'dialog-article-editor',
};
exports.Dialog_ArticleEditor = Dialog_ArticleEditor;
