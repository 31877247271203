"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QKeyBinder = void 0;
const React = require("react");
require("./QKeyBinder.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
/**
 * encapsulate the children with a container that traps focus tabbing
 */
class QKeyBinder extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.firstTabBinder = React.createRef();
        this.lastTabBinder = React.createRef();
    }
    deriveStateFromProps(nextProps) {
        return {};
    }
    renderFirstTabBounder() {
        return React.createElement("div", { tabIndex: 0, ref: this.firstTabBinder, className: 'q-key-binder__tab-binder', role: 'tab-binder', onKeyDown: (e) => {
                if (e.key === 'Tab' && e.shiftKey) {
                    (0, frontend_1.stopPropagation)(e);
                    return this.lastTabBinder.current.focus();
                }
            } });
    }
    renderLastTabBounder() {
        return React.createElement("div", { tabIndex: 0, ref: this.lastTabBinder, className: 'q-key-binder__tab-binder', role: 'tab-binder', onKeyDown: (e) => {
                if (e.key === 'Tab' && !e.shiftKey) {
                    (0, frontend_1.stopPropagation)(e);
                    return this.firstTabBinder.current.focus();
                }
            } });
    }
    renderChildren() {
        if (!this.props.children)
            return '';
        return React.Children.map(this.props.children, (child) => {
            return React.createElement("div", { className: 'q-key-binder__child-wrapper' }, child);
        });
    }
    render() {
        const className = (0, frontend_1._className)('q-key-binder', this.props.className);
        return React.createElement("div", { className: className },
            this.renderFirstTabBounder(),
            this.renderChildren(),
            this.renderLastTabBounder());
    }
}
exports.QKeyBinder = QKeyBinder;
