"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompoundEnumeratedEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./Editors.scss");
const styles_1 = require("@app/styles");
const q_components_1 = require("../../../q-components");
const _entity_1 = require("../../../../_entity");
class CompoundEnumeratedEditor extends frontend_1.ComponentSync {
    constructor() {
        // ######################## Life Cycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.addNewInnerValue = (valueStr) => {
            if (!valueStr)
                return;
            //Don't add a value that already exists
            if (this.props.value.data.scope.find(item => item.data.value === valueStr))
                return;
            const value = {
                type: _entity_1.valueType_EnumElement,
                data: {
                    value: valueStr,
                }
            };
            this.props.value.data.scope.push(value);
            this.forceUpdate();
        };
        this.addExistingInnerValue = (innerValue) => {
            if (!innerValue)
                return;
            this.props.value.data.scope.push(innerValue);
            this.forceUpdate();
        };
        this.removeInnerValue = (innerValue) => {
            this.props.value.data.scope = this.props.value.data.scope.filter(item => item !== innerValue);
            this.forceUpdate();
        };
        // ######################## Render ########################
        this.renderInnerValues = () => {
            var _a;
            const innerValueIds = (0, ts_common_1.filterInstances)((_a = this.props.value.data) === null || _a === void 0 ? void 0 : _a.scope.map(item => item._id)) || [];
            const innerValues = (0, ts_common_1.sortArray)(this.props.value.data.scope, item => item._id ? 0 : 1);
            const values = this.state.enumeratedValues.filter(item => !innerValueIds.includes(item._id));
            const listAdapter = (0, frontend_1.SimpleListAdapter)(values, props => React.createElement("div", { className: 'node-data' }, props.item.data.value));
            const filter = new ts_common_1.Filter(item => [item.data.value]);
            return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section-item values' },
                React.createElement("div", { className: 'dialog__field-title' }, "Inner Values"),
                React.createElement(q_components_1.QScrollWrapper, { flex: true },
                    React.createElement(frontend_1.LL_H_C, { className: 'inner-values' },
                        (0, ts_common_1.sortArray)(innerValues, value => { var _a; return (_a = value.data) === null || _a === void 0 ? void 0 : _a.value; }).map(value => {
                            const className = (0, frontend_1._className)('inner-value', value._id ? 'existing' : undefined);
                            return React.createElement("div", { className: className, key: value.data.value },
                                React.createElement(frontend_1.TS_Button, { onClick: () => this.removeInnerValue(value) },
                                    React.createElement(styles_1.ICONS.x.component, null)),
                                React.createElement("span", null, value.data.value));
                        }),
                        React.createElement(frontend_1.TS_DropDown, { selected: undefined, tabIndex: 0, onSelected: this.addExistingInnerValue, placeholder: 'Select Value', filter: filter, boundingParentSelector: '.dialog__main', noOptionsRenderer: 'Press Enter to create new value', onNoMatchingSelectionForString: this.addNewInnerValue, adapter: listAdapter }))));
        };
        this.renderAttributes = () => {
            const attributes = this.props.value.data.props;
            return React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section-item' },
                React.createElement("div", { className: 'dialog__field-title' }, "Attributes"),
                React.createElement(frontend_1.LL_H_C, { className: 'checkbox-container' },
                    React.createElement(frontend_1.TS_Checkbox, { checked: attributes.isExclusive, onCheck: () => {
                            attributes.isExclusive = !attributes.isExclusive;
                            this.forceUpdate();
                        } }),
                    React.createElement("span", null, "Exclusive")),
                React.createElement(frontend_1.LL_H_C, { className: 'checkbox-container' },
                    React.createElement(frontend_1.TS_Checkbox, { checked: attributes.isOrdered, onCheck: () => {
                            attributes.isOrdered = !attributes.isOrdered;
                            this.forceUpdate();
                        } }),
                    React.createElement("span", null, "Ordered")));
        };
    }
    deriveStateFromProps(nextProps, state) {
        if (!nextProps.value.data) {
            nextProps.value.data = { scope: [], props: {}, subsetIds: [], subsets: [] };
        }
        const allValues = _entity_1.ModuleFE_Value.cache.all();
        const valuesMap = (0, ts_common_1.arrayToMap)(allValues, ts_common_1.dbObjectToId);
        state.enumeratedValues = (allValues.filter(item => item.type === _entity_1.valueType_EnumElement)).map(item => (0, _entity_1.convertValueDBToUI)(item, {
            values: valuesMap,
            measurementUnits: {}
        }));
        return state;
    }
    render() {
        return React.createElement("div", { className: 'enumerated-editor' },
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section' }, this.renderAttributes()),
            React.createElement(frontend_1.LL_V_L, { className: 'dialog__main__section' }, this.renderInnerValues()));
    }
}
exports.CompoundEnumeratedEditor = CompoundEnumeratedEditor;
