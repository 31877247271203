"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer_Editor__FormDataGroup = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const TS_EditableItemComponent_1 = require("@nu-art/thunderstorm/frontend/components/TS_EditableItemComponent/TS_EditableItemComponent");
const React = require("react");
const styles_1 = require("@app/styles");
require("./Renderer_Editor__FormDataGroup.scss");
const ui_components_1 = require("../ui-components");
const ModuleFE_AdvisorContext_1 = require("../ModuleFE_AdvisorContext");
const ts_common_1 = require("@nu-art/ts-common");
class Renderer_Editor__FormDataGroup extends TS_EditableItemComponent_1.TS_EditableItemComponent {
    render() {
        var _a;
        return React.createElement(frontend_1.TS_Card, { className: 'formdata-group' },
            React.createElement(frontend_1.LL_H_C, { className: 'top-prop' },
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Label' }, this.props.editMode ? React.createElement(frontend_1.TS_Input, { type: 'text', onBlur: async (value) => await this.state.editable.update('label', value), value: this.state.editable.item.label })
                    : React.createElement("div", null, this.state.editable.item.label)),
                this.props.editMode && React.createElement(styles_1.ICONS.x.component, { onClick: () => this.props.removeSelf() })),
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Advisor Contexts' }, this.props.editMode ? React.createElement(ui_components_1.MultiSelect_AdvisorContext, { itemFilter: item => {
                    const snippet = ModuleFE_AdvisorContext_1.ModuleFE_AdvisorContext.cache.unique(item);
                    if (!snippet)
                        throw new ts_common_1.MUSTNeverHappenException(`missing snippet with id ${item}`);
                    return snippet.type !== 'multi';
                }, editable: this.state.editable, prop: 'formDataIds' })
                : React.createElement(frontend_1.LL_V_L, null, (_a = this.state.editable.item.formDataIds) === null || _a === void 0 ? void 0 : _a.map(id => { var _a, _b; return React.createElement("div", null, (_b = (_a = ModuleFE_AdvisorContext_1.ModuleFE_AdvisorContext.cache.unique(id)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : 'Not found'); }))));
    }
}
exports.Renderer_Editor__FormDataGroup = Renderer_Editor__FormDataGroup;
