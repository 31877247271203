"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_DiseaseValue = exports.ModuleFE_DiseaseValue_Class = exports.dispatch_onDiseaseValueChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/types");
const shared_1 = require("../shared");
exports.dispatch_onDiseaseValueChanged = new types_1.ThunderDispatcherV3('__onDiseaseValueUpdated');
class ModuleFE_DiseaseValue_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_DiseaseValue, exports.dispatch_onDiseaseValueChanged);
    }
}
exports.ModuleFE_DiseaseValue_Class = ModuleFE_DiseaseValue_Class;
exports.ModuleFE_DiseaseValue = new ModuleFE_DiseaseValue_Class();
