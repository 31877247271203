"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogTemplateExternalParams_ValueToLabelMapper = exports.LogTemplateExternalParamLabel_VarId = exports.LogTemplateType_ValueToLabelMapper = exports.LogTemplateTypeLabel_Timestamp = exports.LogTemplateTypeLabel_String = exports.LogTemplateTypeLabel_NameOf = exports.LogTemplateTypeLabel_UnitsOf = exports.LogTemplateTypeLabel_TimeOf = exports.LogTemplateTypeLabel_ValueOf = void 0;
const types_1 = require("./types");
exports.LogTemplateTypeLabel_ValueOf = 'Value Of';
exports.LogTemplateTypeLabel_TimeOf = 'Time Of';
exports.LogTemplateTypeLabel_UnitsOf = 'Units Of';
exports.LogTemplateTypeLabel_NameOf = 'Name Of';
exports.LogTemplateTypeLabel_String = '<string>';
exports.LogTemplateTypeLabel_Timestamp = 'Timestamp';
exports.LogTemplateType_ValueToLabelMapper = {
    [types_1.LogTemplateType_ValueOf.dbKey]: exports.LogTemplateTypeLabel_ValueOf,
    [types_1.LogTemplateType_UnitsOf.dbKey]: exports.LogTemplateTypeLabel_UnitsOf,
    [types_1.LogTemplateType_TimeOf.dbKey]: exports.LogTemplateTypeLabel_TimeOf,
    [types_1.LogTemplateType_Timestamp.dbKey]: exports.LogTemplateTypeLabel_Timestamp,
    [types_1.LogTemplateType_NameOf.dbKey]: exports.LogTemplateTypeLabel_NameOf,
    [types_1.LogTemplateType_String.dbKey]: exports.LogTemplateTypeLabel_String
};
exports.LogTemplateExternalParamLabel_VarId = '<varId>';
exports.LogTemplateExternalParams_ValueToLabelMapper = {
    [types_1.ParamTypeValue_VarId]: exports.LogTemplateExternalParamLabel_VarId,
    [types_1.ParamTypeValue_String]: exports.LogTemplateTypeLabel_String
};
