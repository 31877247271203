import * as React from 'react';
import {DB_User, DBProto_User} from '@app/hcs';
import {
	Page_BaseManagerUI_SingleType_Proto,
	Renderer_SearchResult,
	SearchItemV3,
	SearchSelector_BaseManagerUI
} from '@app/styles/frontend';
import {TS_HorizontalWorkspace} from '@nu-art/ts-workspace/frontend';
import {MUSTNeverHappenException, UniqueId} from '@nu-art/ts-common';
import {EditorWrapper_Accounts} from '@km/managers/Manager_Permissions/editors/EditorWrapper_Accounts';
import {ModuleFE_User, QPageMessage} from '@app/hcs/frontend';
import {ModuleFE_Account} from '@nu-art/user-account/frontend';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {WorkspaceKey_Permissions} from './workspace';

export const searchItemKey_Users = ModuleFE_User.dbDef.dbKey;

export class Manager_Permissions
	extends Page_BaseManagerUI_SingleType_Proto<DBProto_User> {

	// ######################## Static ########################

	static Route: TS_Route = {
		key: WorkspaceKey_Permissions,
		path: WorkspaceKey_Permissions,
		Component: this,
		modulesToAwait: [ModuleFE_Account, ModuleFE_User],
	};

	static SearchableItem_User: SearchItemV3<DBProto_User> = {
		module: ModuleFE_User,
		type: searchItemKey_Users,
		route: Manager_Permissions.Route,
		entityName: ModuleFE_User.dbDef.dbKey,
		entityLabel: 'User',
		groupId: ['Administrative'],
		mapper: (item) => {
			const account = ModuleFE_Account.cache.unique(item._id);

			return [account?.email ?? 'Failed to find account'];
		},
		tags: [],
		renderer: (item: DB_User, selected?: boolean, onClickCallback?: () => void) => {
			const account = ModuleFE_Account.cache.unique(item._id);
			const entityLabel = account?.type === 'service' ? 'Service Account' : 'User';
			return <Renderer_SearchResult
				itemId={item._id}
				itemKey={searchItemKey_Users}
				itemLabel={account?.email ?? 'Failed to find account'}
				entityLabel={entityLabel}
				hierarchy={'Users'}
				onClickCallback={onClickCallback}
				selected={selected}
				style={{textTransform: 'none'}}
			/>;
		},
		resultRenderer: (item: UniqueId) => {
			const account = ModuleFE_Account.cache.unique(item)!;
			return <EditorWrapper_Accounts account={account}/>;
		}
	};

	static defaultProps = {
		searchItems: [Manager_Permissions.SearchableItem_User]
	};

	// ######################## Lifecycle ########################

	protected getInitialObject(): Partial<DB_User> {
		return {};
	}

	// ######################## Render ########################

	private renderSearch = () => {
		return <SearchSelector_BaseManagerUI
			onSearchItemSelected={(item) => this.onSearchResultSelected(item.id, item.type)}
			searchItems={[Manager_Permissions.SearchableItem_User]}
			selectedItem={this.state.selectedResult}
			buttons={[]}
		/>;
	};

	private renderEditor = () => {
		if (!this.state.selectedItem)
			return <QPageMessage message={'Permissions Editor'}/>;

		const account = ModuleFE_Account.cache.unique(this.state.selectedItem.item._id);
		if (!account)
			throw new MUSTNeverHappenException(`Could not find account with same user id ${this.state.selectedItem.item._id}`);

		return <EditorWrapper_Accounts account={account}/>;
	};

	// ######################## Logic ########################

	protected workspace = {
		id: WorkspaceKey_Permissions,
		key: WorkspaceKey_Permissions,
		renderers: {
			horizontalSpace: TS_HorizontalWorkspace,
		},
		instances: {
			selector: this.renderSearch.bind(this),
			presenter: this.renderEditor.bind(this),
		}
	};
}